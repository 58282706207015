import React, {Component} from 'react';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../../../../Utils/utils';
import {TextValidator} from 'react-material-ui-form-validator';
import MovieIcon from '@material-ui/icons/Movie';

class VideoUrl extends Component {
    render() {
        const {videoUrl, handleChangeVideoUrl} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="options-container video-url-input-container">
                <div onDragStart={preventDefaultDrag} className="single-option-container">
                    <TextValidator
                        className="setting-input"
                        label="YouTube or Vimeo video URL for the welcome screen"
                        type="text"
                        name={'videoUrl'}
                        index={0}
                        value={videoUrl}
                        onChange={handleChangeVideoUrl}
                        validators={[ 'isYoutubeOrVimeoLink']}
                        errorMessages={[
                            'Please enter a valid YouTube (https://www.youtube.com) or Vimeo (https://vimeo.com/) link',
                        ]}
                        multiline={false}
                        fullWidth={true}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            endAdornment: <MovieIcon />,
                        }}
                    />
                </div>
                <div onDragStart={preventDefaultDrag} className="input-description">
                    <p>
                        Insert a direct YouTube video (not a playlist) or a Vimeo URL. In case you added a YouTube
                        video, make sure that the “Allow embedding” option is enabled for the video
                    </p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(VideoUrl);
