import {ReactComponent as AnnouncementsIcon} from 'Images/svg/announcements.svg'

const AnnouncementsDrawerButton = ({handleOpen, hasWall}) => {
    return (
        <div onClick={handleOpen} className={`announcements-icon ${hasWall ? 'side-navigation-opened' : ''}`}>
            <AnnouncementsIcon/>
        </div>
    )
}

export default AnnouncementsDrawerButton;