import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import Confirm from '../../../Dialogs/Confirm';
import {preventDefaultDrag, fireClickEvent, hasOnlyEmptySpaces} from '../../../Utils/utils';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {CircularProgress} from "@material-ui/core";

class LobbyClosedDoorTexts extends React.Component {
    wrapperRefFirst = React.createRef();
    wrapperRefSecond = React.createRef();
    handleClickOutsideFirst = this.handleClickOutsideFirst.bind(this);
    handleClickOutsideSecond = this.handleClickOutsideSecond.bind(this);

    state = {
        auditoriumFields: [
            {
                name: 'closedAuditoriumTitle',
                description: 'This message appears when "Close Auditorium" is enabled',
                adornment: '',
                value: '',
                label: 'Title',
                multiline: 0,
                maxCharacters: 30,
                validators: ['required', 'minStringLength: 2', 'maxStringLength: 30', 'onlyEmptySpaces'],
                errorMessages: [
                    'Field is required',
                    'The title is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (30 characters)',
                    'Field is required',
                ],
            },
            {
                name: 'closedAuditoriumText',
                description: '',
                adornment: '',
                value: '',
                multiline: 6,
                maxCharacters: 200,
                label: 'Description',
                validators: ['minStringLength: 2', 'maxStringLength: 200', 'onlyEmptySpaces'],
                errorMessages: [
                    'The description is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (200 characters)',
                    'Field is required',
                ],
            },
        ],
        showFloorFields: [
            {
                name: 'closedShowfloorTitle',
                description: 'This message appears when the "Close Exhibit hall" is enabled',
                adornment: '',
                value: '',
                label: 'Title',
                multiline: 0,
                maxCharacters: 30,
                validators: ['required', 'minStringLength: 2', 'maxStringLength: 30', 'onlyEmptySpaces'],
                errorMessages: [
                    'Field is required',
                    'The title is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (30 characters)',
                    'Field is required',
                ],
            },
            {
                name: 'closedShowfloorText',
                description: '',
                adornment: '',
                value: '',
                multiline: 6,
                maxCharacters: 200,
                label: 'Description',
                validators: ['minStringLength: 2', 'maxStringLength: 200', 'onlyEmptySpaces'],
                errorMessages: [
                    'The description is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (200 characters)',
                    'Field is required',
                ],
            },
        ],
        buttonDisabledAuditorium: true,
        buttonDisabledShowfloor: true,
        buttonDisabledPosters: true,
        navigationElement: null,

        openConfirmUnsavedChangesFirst: false,
        openConfirmUnsavedChangesSecond: false,
    };

    componentDidMount() {
        this.initState();
        document.addEventListener('mousedown', this.handleClickOutsideFirst);
        document.addEventListener('mousedown', this.handleClickOutsideSecond);
        ValidatorForm.addValidationRule('onlyEmptySpaces', value => hasOnlyEmptySpaces(value));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.branding?.loading && !this.props.branding?.loading) {
            this.setEventBranding();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideFirst);
        document.removeEventListener('mousedown', this.handleClickOutsideSecond);
        ValidatorForm.removeValidationRule('onlyEmptySpaces');
    }

    initState = () => {
        const {branding} = this.props;
        if (branding?.data) {
            this.setEventBranding();
        }
    }

    handleChange = (fields, index, buttonDisabled, ref) => (e) => {
        let updatedFields = [...fields];
        updatedFields[index].value = e.target.value;
        this.setState({[fields]: updatedFields}, () => {
            this.refs[ref].isFormValid().then((isValid) => {
                this.setState({[buttonDisabled]: !isValid});
            });
        });
    };

    saveClosedEventInfo = (fields) => () => {
        const {navigationElement} = this.state;

        let data = {};
        this.state[fields].forEach((field) => {
            data[field.name] = field.value;
        });
        this.props.saveClosedDoorsInfo(data);
        this.setState({
            openConfirmUnsavedChangesFirst: false,
            openConfirmUnsavedChangesSecond: false,
        });

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.setState({
            openConfirmUnsavedChangesFirst: false,
            openConfirmUnsavedChangesSecond: false,
        });
        this.initState();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    setEventBranding = () => {
        const {branding} = this.props;
        const brandingData = branding?.data;
        this.setState({
            auditoriumFields: [
                {...this.state.auditoriumFields[0], value: brandingData.closedAuditoriumTitle},
                {...this.state.auditoriumFields[1], value: brandingData.closedAuditoriumText},
            ],
            showFloorFields: [
                {...this.state.showFloorFields[0], value: brandingData.closedShowfloorTitle},
                {...this.state.showFloorFields[1], value: brandingData.closedShowfloorText},
            ],
            buttonDisabledAuditorium: true,
            buttonDisabledShowfloor: true,
            buttonDisabledPosters: true,
        });
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChangesFirst, openConfirmUnsavedChangesSecond} = this.state;
        const isEventTargetNavigationElement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChangesFirst || openConfirmUnsavedChangesSecond) {
            return navigationElement;
        }

        if (isEventTargetNavigationElement) {
            return e.target;
        }

        return null;
    };

    handleClickOutsideFirst(e) {
        if (this.wrapperRefFirst && !this.wrapperRefFirst.current.contains(e.target)) {
            if (!this.state.buttonDisabledAuditorium) {
                this.setState({openConfirmUnsavedChangesFirst: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    handleClickOutsideSecond(e) {
        if (this.wrapperRefSecond && !this.wrapperRefSecond.current.contains(e.target)) {
            if (!this.state.buttonDisabledShowfloor) {
                this.setState({openConfirmUnsavedChangesSecond: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    handleClickOutsideThird(e) {
        if (this.wrapperRefThird && !this.wrapperRefThird.current.contains(e.target)) {
            if (!this.state.buttonDisabledPosters) {
                this.setState({openConfirmUnsavedChangesThird: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    closeClickOutsideFirst = () => {
        this.setState({openConfirmUnsavedChangesFirst: false});
    };

    closeClickOutsideSecond = () => {
        this.setState({openConfirmUnsavedChangesSecond: false});
    };

    closeClickOutsideThird = () => {
        this.setState({openConfirmUnsavedChangesThird: false});
    };

    render() {
        let {
            auditoriumFields,
            showFloorFields,
            buttonDisabledAuditorium,
            buttonDisabledShowfloor,
            openConfirmUnsavedChangesFirst,
            openConfirmUnsavedChangesSecond,
        } = this.state;
        return (
            <>
                <div onDragStart={preventDefaultDrag} className="branding-disable-login">
                    <h4 className="advanced-options-title">CLOSED DOOR TEXTS</h4>
                    <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                        <p onDragStart={preventDefaultDrag} className="inner-options-title padding-10">
                            Closed auditorium
                        </p>
                        <div onDragStart={preventDefaultDrag} ref={this.wrapperRefFirst}>
                            {this.props.loadingSaveEventBranding ?
                                <div className="d-flex align-items-center justify-content-center">
                                    <CircularProgress color="primary"/>
                                </div>
                                :
                                <ValidatorForm ref="formFirst" onSubmit={this.saveClosedEventInfo(auditoriumFields)}>
                                    {auditoriumFields.map((field, index) => {
                                        return (
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="options-container"
                                                key={field.name}
                                            >
                                                <div
                                                    onDragStart={preventDefaultDrag}
                                                    className={`single-option-container ${
                                                        field.multiline !== 0 ? 'textarea-wrapper multiline-input' : ''
                                                    }`}
                                                >
                                                    <TextValidator
                                                        className="setting-input"
                                                        label={field.label}
                                                        type="text"
                                                        name={field.name}
                                                        index={index}
                                                        value={field.value}
                                                        onChange={this.handleChange(
                                                            auditoriumFields,
                                                            index,
                                                            'buttonDisabledAuditorium',
                                                            'formFirst'
                                                        )}
                                                        validators={field.validators}
                                                        errorMessages={field.errorMessages}
                                                        multiline={field.multiline > 0}
                                                        rows={8}
                                                        fullWidth={true}
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: !field.multiline &&
                                                                <span className='char-count'>
                                                            {field.value?.length}/{field.maxCharacters}
                                                        </span>
                                                        }}
                                                    />
                                                    {!!field.multiline && <span className={'char-count'}>
                                                    {field.value?.length}/{field.maxCharacters}
                                                </span>}

                                                </div>
                                                <div onDragStart={preventDefaultDrag} className="input-description">
                                                    <p>{field.description}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div onDragStart={preventDefaultDrag} className="action-container">
                                        <Button
                                            type="submit"
                                            disabled={buttonDisabledAuditorium || this.props.loadingSaveEventBranding}
                                            startIcon={<SaveOutlinedIcon/>}
                                            variant="contained"
                                            color={'secondary'}
                                            disableElevation
                                        >
                                            SAVE
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            }
                        </div>
                    </div>

                    <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                        <p onDragStart={preventDefaultDrag} className="inner-options-title padding-10">
                            Closed exhibit hall
                        </p>
                        <div onDragStart={preventDefaultDrag} ref={this.wrapperRefSecond}>
                            {this.props.loadingSaveEventBranding ?
                                <div className="d-flex align-items-center justify-content-center">
                                    <CircularProgress color="primary"/>
                                </div> :
                                <ValidatorForm ref="formSecond" onSubmit={this.saveClosedEventInfo(showFloorFields)}>
                                    {showFloorFields.map((field, index) => {
                                        return (
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="options-container"
                                                key={field.name}
                                            >
                                                <div
                                                    onDragStart={preventDefaultDrag}
                                                    className={`single-option-container ${
                                                        field.multiline !== 0 ? 'textarea-wrapper multiline-input' : ''
                                                    }`}
                                                >
                                                    <TextValidator
                                                        className="setting-input"
                                                        label={field.label}
                                                        type="text"
                                                        name={field.name}
                                                        index={index}
                                                        value={field.value}
                                                        onChange={this.handleChange(
                                                            showFloorFields,
                                                            index,
                                                            'buttonDisabledShowfloor',
                                                            'formSecond'
                                                        )}
                                                        validators={field.validators}
                                                        errorMessages={field.errorMessages}
                                                        multiline={field.multiline > 0}
                                                        rows={8}
                                                        fullWidth={true}
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: !field.multiline &&
                                                                <span className='char-count'>
                                                            {field.value?.length}/{field.maxCharacters}
                                                        </span>
                                                        }}
                                                    />
                                                    {!!field.multiline && <span className={'char-count'}>
                                                    {field.value?.length}/{field.maxCharacters}
                                                </span>}
                                                </div>
                                                <div onDragStart={preventDefaultDrag} className="input-description">
                                                    <p>{field.description}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div onDragStart={preventDefaultDrag} className="action-container">
                                        <Button
                                            type="submit"
                                            variant={"contained"}
                                            disabled={buttonDisabledShowfloor || this.props.loadingSaveEventBranding}
                                            startIcon={<SaveOutlinedIcon/>}
                                            disableElevation
                                            color={"secondary"}
                                        >
                                            SAVE
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            }
                        </div>
                    </div>
                </div>
                {openConfirmUnsavedChangesFirst && (
                    <Confirm
                        open={openConfirmUnsavedChangesFirst}
                        closeConfirm={this.closeClickOutsideFirst}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveClosedEventInfo(auditoriumFields)}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
                {openConfirmUnsavedChangesSecond && (
                    <Confirm
                        open={openConfirmUnsavedChangesSecond}
                        closeConfirm={this.closeClickOutsideSecond}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveClosedEventInfo(showFloorFields)}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        branding: state.event.branding,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LobbyClosedDoorTexts);
