import React, {useEffect, useRef} from 'react';
import {getLongMonthTranslated, getShortDaysTranslated} from '../../../../Utils/utils';
import {useSelector} from 'react-redux';

const DayItem = ({day, isFirstDayInMonth, isLastDayInMonth, isNewYear, selectedDay, navigateToDay, daysNavigationListRef}) => {

    const languages = useSelector(state =>  state.languages);
    const currentDate = new Date(day);
    const dayRef = useRef();


    // doesn't seem to work as expected
    // useEffect(()=>{
    //     const observer = new IntersectionObserver(([entry]) => {
    //         console.log(entry);
    //         if(!entry.isIntersecting && selectedDay === day){
    //             dayRef.current.scrollIntoView({
    //                 behavior: "smooth",
    //                 block: "nearest",
    //                 inline: "start"
    //             });
    //         }
    //     }, {
    //         root: daysNavigationListRef.current,
    //         rootMargin: '0px',
    //         threshold: 1.0
    //     });
    //
    //     observer.observe(dayRef.current);
    //
    //     return () => {
    //         observer.disconnect();
    //     };
    // }, [])

    return (
        <div key={day} ref={dayRef}>
            {isFirstDayInMonth &&
                <div className={'month-name'}>
                    {getLongMonthTranslated(day, languages)}
                    {isNewYear && ','}
                    {isNewYear &&
                        <span className={'year'}>
                                        {currentDate.toLocaleString('default', {year: 'numeric'})}
                                    </span>
                    }
                </div>
            }
            <div className={`day-container ${isFirstDayInMonth ? 'first-day' : ''} ${isLastDayInMonth ? 'last-day' : ''} ${selectedDay === day ? 'active' : ''}`}
                 onClick={navigateToDay(day)}
            >
                <p className={'day'}>
                    {getShortDaysTranslated(day, languages.platformLanguage)}
                    {', '}
                    {currentDate.toLocaleString('default', {day: 'numeric'})}
                </p>
            </div>
        </div>
    );
};

export default DayItem;