import React from 'react';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../store/actions';
import {languagesArray} from '../../Utils/Translations/availableLanguages';
import {preventDefaultDrag} from '../../Utils/utils';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import classnames from 'classnames';

class PlatformLanguage extends React.Component {
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        const {eventInfo} = props;
        let sortedLanguages = [];
        eventInfo.brandingTranslations.map((item) => {
            return sortedLanguages.push(item.language);
        });
        // filter GDPR
        if (
            this.props.location.pathname.includes('/event-privacy-policy/') ||
            this.props.location.pathname.includes('/event-terms-and-conditions/')
        ) {
            sortedLanguages = [];
            eventInfo.brandingTranslations.map((item) => {
                let getLang = languagesArray.find((lang) => lang.value === item.language);
                return getLang.isGDRP && sortedLanguages.push(item.language);
            });
        }
        this.state = {
            showLanguageList: false,
            listLanguages: sortedLanguages.sort(),
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.props.target === 'exhibitorsOverlay') {
                this.props.hideExhibitorsOverlay();
            } else if (this.props.target === 'auditoriumsSlider') {
                this.props.hideAuditoriumsSlider();
            } else {
                this.setState({
                    showLanguageList: false,
                });
            }
        }
    }

    toggleShowLanguageList = () => {
        this.setState({
            showLanguageList: !this.state.showLanguageList,
        });
    };

    handleChangeLanguage = (language) => (e) => {
        this.props.onSetEventLanguage(language);
        this.setState({
            showLanguageList: false,
        });
    };

    render() {
        const {eventInfo, languages, eventSlug} = this.props;
        const {showLanguageList, listLanguages} = this.state;
        const eventHasTranslations = eventInfo?.brandingTranslations && eventInfo?.brandingTranslations.length > 1;
        const primaryColor = this.props.eventInfo?.brandingData?.primaryColor;

        //check if there is anything in the url after eventSlug

        const urlAfterEventSlug = this.props.location?.pathname?.split(`${eventSlug}/` || eventSlug)[1]?.length > 0;

        // if the event has just one language, don't display the select language dropdown
        if (!eventHasTranslations) {
            return null;
        }
        return (
            <div onDragStart={preventDefaultDrag} className="select-language-container" ref={this.setWrapperRef}>
                <div
                    onDragStart={preventDefaultDrag}
                    className={classnames('selected-language', {
                        'color-white': !urlAfterEventSlug,
                    })}
                    onClick={this.toggleShowLanguageList}
                >
                    {languages.eventLanguage} <ExpandMoreRoundedIcon />
                </div>
                {showLanguageList && (
                    <div onDragStart={preventDefaultDrag} className="languages-list-container">
                        <ul>
                            {listLanguages.map((translation) => {
                                return (
                                    <li
                                        key={translation}
                                        value={translation}
                                        onClick={this.handleChangeLanguage(translation)}
                                        className={classnames({
                                            active: translation === languages.eventLanguage,
                                        })}
                                        style={{
                                            background:
                                                translation === languages.eventLanguage && primaryColor
                                                    ? primaryColor
                                                    : '',
                                        }}
                                    >
                                        {translation}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        eventInfo: state.event.eventInfo,
        eventSlug: state.event.eventSlug,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onSetEventLanguage: (languageCode) => dispatch(actions.setEventLanguage(languageCode)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlatformLanguage));
