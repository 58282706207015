import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from '../../store/axios-instance';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import './DeleteMyAccountStyles.scss';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Spinner from '../../SmallLayoutComponents/Spinner';


class DeleteMyAccount extends React.Component {

    state = {
        userDeleted: false,
        canDeleteAccount: true,
        showUndoDialog: false,
        loading: false
    };

    componentDidMount() {
        this.checkCanDeleteAccount();
    }

    checkCanDeleteAccount = () => {
        const {user} = this.props;

        let canDeleteAccount = true;
        user.eventRoles.forEach((eventRole) => {
            if (eventRole.roles.includes('organizer')) {
                canDeleteAccount = false;
            }
        });

        this.setState({canDeleteAccount: canDeleteAccount});
    };


    handleDeleteMyAccount = () => {
        const {user} = this.props;
        this.setState({loading: true});
        axios({method: 'delete', url: `/users/${user._id}?delay=true`})
            .then((response) => {
                this.setState({
                    userDeleted: true,
                    showUndoDialog: true,
                    loading: false
                });
            })
            .catch((error) => {
                this.setState({loading: false});
            });
    };

    closeDialog = () => {
        const {userDeleted} = this.state;
        if (userDeleted) {
            // this.props.onLogoutUser();
        } else {
            this.props.closeDialog();
        }
    };

    undoDeleteMyAccount = () => {
        const {user} = this.props;

        axios({method: 'delete', url: `/users/${user._id}/cancel`})
            .then((response) => {
                this.props.closeDialog();
            })
            .catch((error) => {

            });
    }

    closeUndoDialog = (event, reason) => {
        if (reason !== 'backdropClick') {
            window.location.href = '/';
        }

    }

    renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            // this.props.closeDialog();
        }

        return remainingTime;
    };


    handleTimerComplete = () => {
        // redirect the user to the platform homepage
        window.location.href = '/';
        this.closeDialog();
        return { shouldRepeat: false, delay: 0 }
    }

    render() {
        const {userDeleted, canDeleteAccount, showUndoDialog, loading} = this.state;
        const {mobile, opened, translation, defaultTranslation} = this.props;
        return (
            <>
                <Dialog
                    open={opened && !showUndoDialog}
                    onClose={this.closeDialog}
                    aria-labelledby="form-dialog-title"
                    className={`${mobile ? 'dialog-mobile' : ''}`}
                >
                    {loading && <Spinner/>}
                    <DialogTitle id="form-dialog-title">{translation?.myAccountPage.deleteTitle || defaultTranslation?.myAccountPage.deleteTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="form-dialog-secondary">

                            {canDeleteAccount ? (
                                <span>
                                    {userDeleted
                                        ? translation?.myAccountPage.deleteSuccess || defaultTranslation?.myAccountPage.deleteSuccess
                                        : translation?.myAccountPage.deleteConfirm || defaultTranslation?.myAccountPage.deleteConfirm
                                    }
                                </span>
                            ) : (
                                <span>{translation?.myAccountPage.deleteError || defaultTranslation?.myAccountPage.deleteError}</span>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={this.closeDialog}>
                            {translation?.generalText.cancel}
                        </Button>
                        {!this.state.userDeleted && this.state.canDeleteAccount && (
                            <Button
                                type="button"
                                classes={{root: 'button-helper'}}
                                onClick={this.handleDeleteMyAccount}
                            >
                                {translation?.generalText.delete || defaultTranslation?.generalText.delete}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>



                <Dialog
                    open={showUndoDialog}
                    onClose={this.closeUndoDialog}
                    aria-labelledby="form-dialog-title"
                    className={`${mobile ? 'dialog-mobile' : ''}`}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                >
                    <DialogTitle id="form-dialog-title">{translation?.myAccountPage.waitTitle || defaultTranslation?.myAccountPage.waitTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="form-dialog-secondary">
                            <span>{translation?.myAccountPage.waitMessage || defaultTranslation?.myAccountPage.waitMessage}</span>
                            <div className="undo-timer-container">
                                <CountdownCircleTimer
                                    isPlaying
                                    duration={30}
                                    colors={["#5AB453"]}
                                    colorsTime={[30]}
                                    onComplete={this.handleTimerComplete}
                                    rotation="counterclockwise"
                                    size={54}
                                    strokeWidth={5}
                                >
                                    {this.renderTime}
                                </CountdownCircleTimer>
                            </div>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="button"
                            classes={{root: 'button-helper'}}
                            onClick={this.undoDeleteMyAccount}
                        >
                            {translation?.generalText.undo || defaultTranslation?.generalText.undo}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMyAccount);
