import * as actionTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    loading: false,
    users: [],
    error: false,
    prevPage: 1,
    hasPrevPage: false,
    nextPage: 1,
    hasNextPage: false,
    totalPages: 1,
    currentPage: 1,
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_EVENT_USERS_WITH_ADDITIONAL_DATA_START:
                draft.loading = true
                draft.error = false
                break;

            case actionTypes.GET_EVENT_USERS_WITH_ADDITIONAL_DATA_FAIL:
                draft.loading = false;
                draft.error = action.payload
                break;

            case actionTypes.GET_EVENT_USERS_WITH_ADDITIONAL_DATA_SUCCESS:
                draft.users = action.payload.users
                draft.prevPage = action.payload.prevPage
                draft.hasPrevPage = action.payload.hasPrevPage
                draft.nextPage = action.payload.nextPage
                draft.hasNextPage = action.payload.hasNextPage
                draft.totalPages  = action.payload.totalPages
                draft.currentPage = action.payload.page
                draft.loading = false
                break;

            case actionTypes.USER_PACKAGE_UPDATED_SUCCESS:
                const currentUser = draft.users.find(user => user._id === action.payload.userId)
                currentUser.packageId = action.payload.packageId;
                currentUser.packageName = action.payload.packageName;
                break;
            case actionTypes.REMOVE_USER_FROM_EVENT_SUCCESS:
                const removedUserIndex = draft.users.findIndex(user => user._id === action.payload)
                if(removedUserIndex !== -1) draft.users.splice(removedUserIndex, 1)
                break;
            default:
                break;
        }
    })

export default reducer;
