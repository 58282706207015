import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import HomepageBackground from '../Images/backgrounds/homepage-background.jpg';
import MainLogo from '../SmallLayoutComponents/MainLogo';
import {preventDefaultDrag} from '../Utils/utils';

import {useRouteMatch} from 'react-router-dom';

const Spinner = (props) => {
    const windowWidth = window.screen.width;
    const match = useRouteMatch();
    const param = match.path.toString();
    const result = param.includes('lobby');
    return (
        <>
            {windowWidth < 1025 && result ? (
                <div className="mobile-splash-screen-helper"></div>
            ) : (
                <div
                    onDragStart={preventDefaultDrag}
                    className="full-page-spinner"
                    style={{backgroundImage: `url('${HomepageBackground}')`}}
                >
                    <div onDragStart={preventDefaultDrag} className="logo-container">
                        <MainLogo width="117" height="28" alt="Onvent Logo" />
                    </div>
                    <div onDragStart={preventDefaultDrag} className="homepage-overlay"></div>
                    <div onDragStart={preventDefaultDrag} className="spinner-container">
                        <CircularProgress />
                    </div>
                </div>
            )}
        </>
    );
};

export default Spinner;
