import Layout from '../../components/Layout';
import {useSelector} from 'react-redux';
import RegistrationProgressbar from '../../components/Progressbar/RegistrationProgressbar';
import Roles from '../../components/Roles/Roles';
import {useHistory} from 'react-router-dom';
import React, {useMemo, useState, useEffect} from 'react';
import EventInformation from '../../components/EventInformation';
import Button from '@material-ui/core/Button';
import {isMobile} from 'react-device-detect';
const SelectRole = () => {
    const history = useHistory();
    const token = localStorage.getItem('token');

    const user = useSelector((state) => state.user);
    const {slug} = useSelector((state) => state.event.eventInfo);
    const allEventUsers = useSelector((state) => state.eventUsers?.allEventUsers);
    const eventId = useSelector((state) => state.event.eventId);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const eventSlug = useSelector((state) => state.event.eventSlug);
    const event = useSelector((state) => state.event);
    const {
        restrictExhibitorRegistration,
        restrictParticipantRegistration,
        restrictScholarRegistration,
        brandingTranslations,
        companyExhibitorsType,
        scholarExhibitorsType,
    } = event?.eventInfo || {};

    const languages = useSelector((state) => state.languages);
    const {eventLanguage} = languages;

    const eventInfo = useSelector((state) => state.event.eventInfo);

    const primaryColor = eventInfo?.brandingData?.primaryColor;

    const brandingTranslation = brandingTranslations.find((translation) => translation.language === eventLanguage);

    const [availableRoles, setAvailableRoles] = useState([
        {
            slug: 'participant',
            value: restrictParticipantRegistration,
            checked: false,
            url: 'participant-registration',
        },
        {
            slug: 'exhibitor',
            value: !(!restrictExhibitorRegistration && companyExhibitorsType),
            checked: false,
            url: 'exhibitor-registration',
        },
        {
            slug: 'poster-study',
            value: !(!restrictScholarRegistration && scholarExhibitorsType),
            checked: false,
            url: 'scholar-registration',
        },
    ]);







    const [showBottomStickyButton, setShowBottomStickyButton] = useState(true);

    const selectedRoleFromStorage = JSON.parse(localStorage.getItem('selectedRole'));

    const handleEventRoleChecked = (name) => () => {
        const newAvailableRoles = availableRoles.map((role) => {
            if (role.slug === name) {
                return {...role, checked: !role.checked};
            } else {
                return {...role, checked: false};
            }
        });
        setAvailableRoles(newAvailableRoles);
    };

    const selectedRole = availableRoles.find((role) => role.checked === true);

    const handleSelectedRole = () => {
        localStorage.setItem('selectedRole', JSON.stringify(selectedRole));
        history.push(`${selectedRole.url}`);
    };

    //if there is no token redirect to login page
    if (!token) {
        history.push('/login');
    }

    let filteredRoles = useMemo(() => availableRoles.filter((role) => role.value === false), [availableRoles]);

    const roleDescription = useMemo(() => {
        const selectedRole = availableRoles.find((role) => role.checked === true);
        const {participantsRegDescription, exhibitorsRegDescription, scholarsRegDescription} = brandingTranslation;
        switch (selectedRole?.slug) {
            case 'participant':
                return participantsRegDescription;
            case 'exhibitor':
                return exhibitorsRegDescription;
            case 'poster-study':
                return scholarsRegDescription;
            default:
                return participantsRegDescription;
        }
    }, [brandingTranslation, availableRoles]);

    useEffect(() => {
        selectedRoleFromStorage &&
            setAvailableRoles((prev) =>
                prev.map((role) => (role.slug === selectedRoleFromStorage.slug ? {...role, checked: true} : role))
            );
    }, []);

    useEffect(() => {
        const userExistInPlatformAndRegisteredToEvent = user?.data?.eventRoles
            .map((eventRole) => eventRole.event._id)
            .includes(eventId);

        userExistInPlatformAndRegisteredToEvent && history.push(`/event/${slug}`);
    }, [allEventUsers, slug, history, user?.data?._id, user?.data?.eventRoles, eventId]);

    useEffect(() => localStorage.removeItem('tempEmail'), []);

    useEffect(() => {
        if (!selectedRoleFromStorage) {
            setAvailableRoles((prevRoles) => {
                const updatedRoles = [...prevRoles];
                if (updatedRoles.length > 0) {
                    updatedRoles[0].checked = true;
                }
                return updatedRoles;
            });
        }
    }, [selectedRoleFromStorage]);

    useEffect(() => {
        const reDirectArr = {
            participant: restrictParticipantRegistration,
            exhibitor: !(!restrictExhibitorRegistration && companyExhibitorsType),
            scholar: !(!restrictScholarRegistration && scholarExhibitorsType),
        };

        const falseCount = Object.values(reDirectArr)?.filter((item) => !item).length;
        if (falseCount <= 1) {
            const role = Object.keys(reDirectArr)?.find((key) => reDirectArr[key] === false);
            if (role !== undefined) {
                history.push(`/event/${eventSlug}/${role}-registration`);
            } else {
                history.push(`/event/${eventSlug}`);
            }
        }
    }, []);

    useEffect(() => {
        let isKeyboardActive = false;

        const handleResize = () => {
            const {innerHeight, outerHeight} = window;

            if (outerHeight - innerHeight > 200) {
                // The difference between outerHeight and innerHeight indicates the presence of the virtual keyboard
                isKeyboardActive = true;
                setShowBottomStickyButton(false);
            } else if (isKeyboardActive) {
                isKeyboardActive = false;
                setShowBottomStickyButton(true);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Layout childrenMargin={'-46px'} childrenPadding={`0 8.5%`}>
            <RegistrationProgressbar stepsStatuses={['completed', 'active', 'passive']} />
            <h2 className={`select-role-title`}>
                {translation.newLoginRegistrations.imHereAs || defaultTranslation.newLoginRegistrations.imHereAs}
            </h2>
            {availableRoles.length === 0 ? (
                <div className="alert alert-danger" role="alert">
                    {translation.newLoginRegistrations.registrationIsClosed ||
                        defaultTranslation.newLoginRegistrations.registrationIsClosed}
                </div>
            ) : (
                <Roles
                    brandingTranslation={brandingTranslation}
                    availableRoles={filteredRoles}
                    handleEventRoleChecked={handleEventRoleChecked}
                />
            )}
            <EventInformation description={roleDescription} />
            {isMobile ? (
                <div className={`container-btn-mobile`}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className="continue-button-register"
                        style={{
                            background: primaryColor.length > 0 && primaryColor,
                        }}
                        onClick={handleSelectedRole}
                        disabled={availableRoles.filter((role) => role.checked === true).length === 0}
                    >
                        {translation.newLoginRegistrations.continueButton ||
                            defaultTranslation.newLoginRegistrations.continueButton}
                    </Button>
                </div>
            ) : (
                <>
                    {isMobile ? (
                        showBottomStickyButton && (
                            <Button
                                variant="contained"
                                color="secondary"
                                className="continue-button-register"
                                style={{
                                    background: primaryColor.length > 0 && primaryColor,
                                }}
                                onClick={handleSelectedRole}
                                disabled={availableRoles.filter((role) => role.checked === true).length === 0}
                            >
                                {translation.newLoginRegistrations.continueButton ||
                                    defaultTranslation.newLoginRegistrations.continueButton}
                            </Button>
                        )
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            className="continue-button-register"
                            style={{
                                background: primaryColor.length > 0 && primaryColor,
                            }}
                            onClick={handleSelectedRole}
                            disabled={availableRoles.filter((role) => role.checked === true).length === 0}
                        >
                            {translation.newLoginRegistrations.continueButton ||
                                defaultTranslation.newLoginRegistrations.continueButton}
                        </Button>
                    )}
                </>
            )}
        </Layout>
    );
};
export default SelectRole;
