import {useSelector, useDispatch} from 'react-redux';
import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import axios from 'store/axios-instance';
import Layout from '../components/Layout';
import PageHeadline from '../components/PageHeadline';
import {preventDefaultDrag} from 'Utils/utils';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import {handleStrongPassword} from '../Utils';
import StrongPassword from '../components/StrongPassword';
import {useHistory} from 'react-router-dom';
import * as actions from 'store/actions';
import classnames from 'classnames';

const PasswordRecovery = () => {
    const dispatch = useDispatch();
    const formRef = useRef();
    const history = useHistory();
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();

    const loggedIn = useSelector((state) => state.user.loggedIn);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    //states
    const [token, setToken] = useState('');
    const [passwords, setPasswords] = useState({password: '', passwordConfirmation: ''});
    const [showPassword, setShowPassword] = useState({password: false, passwordConfirmation: false});
    const [error, setError] = useState('');
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

    const [strongPassword, setStrongPassword] = useState({
        isPasswordLengthBetween8and64: false,
        isPasswordContainsOneOrMoreLowercaseLetters: false,
        isPasswordContainsOneOrMoreUppercaseLetters: false,
        isPasswordContainsOneOrMoreNumbers: false,
        isPasswordContainsOneOrMoreSpecialCharacters: false,
    });

    const [allValidations, setAllValidations] = useState({
        password: false,
        passwordConfirmation: false,
    });

    const pageHeadLine = [
        {
            heading:
                translation.newLoginRegistrations.createNewPassword ||
                defaultTranslation.newLoginRegistrations.createNewPassword,
            subHeading:
                translation.newLoginRegistrations.createNewPasswordText ||
                defaultTranslation.newLoginRegistrations.createNewPasswordText,
        },
        {
            heading:
                translation.newLoginRegistrations.passwordReset ||
                defaultTranslation.newLoginRegistrations.passwordReset,
            subHeading:
                translation.newLoginRegistrations.passwordResetText ||
                defaultTranslation.newLoginRegistrations.passwordResetText,
        },
    ];

    const pageTitle = passwordResetSuccess ? pageHeadLine[1] : pageHeadLine[0];

    //HANDLERS
    const handleResetPassword = async () => {
        const {data} = await axios.post('/auth/reset-password', {password: passwords.password, token: token});
        if (data.success) {
            localStorage.setItem('token', data.token);
            setPasswordResetSuccess(true);

            dispatch(actions.autoLoginWithToken());
        } else {
            setError(data.message);
        }
    };

    const handleShowPasswordToggle = (el) => () => {
        setShowPassword({
            ...showPassword,
            [el]: !showPassword[el],
        });
    };

    const handleKeydown = (e) => {
        if (e.keyCode === 32) {
            e.preventDefault();
            return;
        }
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        setPasswords((prevPasswords) => ({
            ...prevPasswords,
            [name]: value,
        }));
    };
    const redirectToEventHomepage = () => {
        if (loggedIn) {
            history.push('/');
        } else {
            history.push('/login');
        }
    };
    const handlePasswordMatchValidation = (value) => {
        if (value !== passwords.password || value !== passwords.passwordConfirmation) {
            if (passwords.passwordConfirmation.length > 0 && passwords.password.length > 0) {
                setAllValidations((prevData) => ({...prevData, password: false, passwordConfirmation: false}));
                return false;
            }
        }
        return true;
    };
    //END HANDLERS

    //everytime when allValidations are changed, isRegistrationFormValid will produce a new value
    const isRegistrationFormValid = useMemo(() => {
        return Object.values(allValidations).every((item) => item === true);
    }, [allValidations]);

    useEffect(() => {
        // get the token from url, save it to state so that we can send it with the request
        const query = new URLSearchParams(window.location.search);
        const tokenFromQuery = query.get('token');

        setToken(tokenFromQuery);
    }, []);

    //this is for password validation
    useEffect(() => {
        const isMatched = passwords.passwordConfirmation === passwords.password;
        const strongPassword = handleStrongPassword(passwords.password);
        const isStrongPassword = Object.values(strongPassword).every((item) => item === true);
        setStrongPassword(strongPassword);
        setAllValidations((prevValidations) => ({
            ...prevValidations,
            password: isStrongPassword && isMatched,
            passwordConfirmation: isMatched,
        }));
        if (passwords.password.length === 0) {
            setAllValidations((prevValidations) => ({
                ...prevValidations,
                password: false,
            }));
        }
        formRef.current?.isFormValid(true);
    }, [passwords, setAllValidations]);

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', handlePasswordMatchValidation);

        return () => {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        };
    }, [passwords.passwordConfirmation, passwords.password]);

    useEffect(() => {
        const {password, passwordConfirmation} = passwords;
        const passwordElement = passwordRef.current;
        const passwordConfirmationElement = passwordConfirmationRef.current;

        const shouldRemoveErrorClass = password === passwordConfirmation;
        passwordElement?.classList?.toggle('remove-error', shouldRemoveErrorClass);
        passwordConfirmationElement?.classList?.toggle('remove-error', shouldRemoveErrorClass);
    }, [passwords]);

    return (
        <Layout childrenWidth={`392px`}>
            <PageHeadline heading={pageTitle.heading} subHeading={pageTitle.subHeading} />
            {passwordResetSuccess ? (
                <div className={`submit-and-secondary-actions`}>
                    <Button onClick={redirectToEventHomepage} className={`send-form-fc mt-70`}>
                        {translation.newLoginRegistrations.continueButton ||
                            defaultTranslation.newLoginRegistrations.continueButton}
                    </Button>
                </div>
            ) : (
                <ValidatorForm
                    ref={formRef}
                    className={`login-form-fc mt-55`}
                    onSubmit={handleResetPassword}
                    onError={(errors) => console.log(errors)}
                >
                    <div ref={passwordRef}>
                        <TextValidator
                            label={
                                translation.newLoginRegistrations.password ||
                                defaultTranslation.newLoginRegistrations.password
                            }
                            onChange={handlePasswordChange}
                            onKeyDown={handleKeydown}
                            className="password-text-field withEndAdornment password-element"
                            name="password"
                            onCopy={preventDefaultDrag}
                            type={`${showPassword.password ? 'text' : 'password'}`}
                            value={passwords.password}
                            validators={['required', 'isPasswordMatch']}
                            errorMessages={[
                                'Password must comply with the requirements listed below',
                                'Password is not matching',
                            ]}
                            InputProps={{
                                endAdornment: (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="show-pass"
                                        onClick={handleShowPasswordToggle('password')}
                                    >
                                        {showPassword.password ? <Visibility /> : <VisibilityOff />}
                                    </div>
                                ),
                            }}
                        />
                    </div>
                    <div ref={passwordConfirmationRef}>
                        <TextValidator
                            label={
                                translation.newLoginRegistrations.confirmPassword ||
                                defaultTranslation.newLoginRegistrations.confirmPassword
                            }
                            onChange={handlePasswordChange}
                            onKeyDown={handleKeydown}
                            className="password-text-field withEndAdornment passwordConfirmation-element"
                            name="passwordConfirmation"
                            onCopy={preventDefaultDrag}
                            type={`${showPassword.passwordConfirmation ? 'text' : 'password'}`}
                            value={passwords.passwordConfirmation}
                            validators={['isPasswordMatch', 'required']}
                            errorMessages={[
                                'Password is not matching',
                                'Password must comply with the requirements listed below',
                            ]}
                            InputProps={{
                                endAdornment: (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="show-pass"
                                        onClick={handleShowPasswordToggle('passwordConfirmation')}
                                    >
                                        {showPassword.passwordConfirmation ? <Visibility /> : <VisibilityOff />}
                                    </div>
                                ),
                            }}
                        />
                    </div>
                    <StrongPassword strongPassword={strongPassword} />
                    <div className={`submit-and-secondary-actions`}>
                        <Button
                            type="submit"
                            disabled={!isRegistrationFormValid}
                            className={classnames('send-form-fc', {
                                disabled: !isRegistrationFormValid,
                            })}
                        >
                            {translation.newLoginRegistrations.resetPasswordButton ||
                                defaultTranslation.newLoginRegistrations.resetPasswordButton}
                        </Button>
                    </div>
                </ValidatorForm>
            )}
        </Layout>
    );
};
export default PasswordRecovery;
