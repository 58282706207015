import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import HPBackgroundImage from './LayoutComponents/HPBackgroundImage';
import InfoDesktopImage from '../../../Images/info_desktop.png';
import InfoTabletImage from '../../../Images/info_tablet.png';
import InfoMobileImage from '../../../Images/info_mobile.png';
import {preventDefaultDrag} from '../../../Utils/utils';

class HPBackground extends PureComponent {
    render() {
        const {handleOpenSuccessSnackbar, handleOpenErrorSnackbar, branding} = this.props;
        return (
            <div>
                <p onDragStart={preventDefaultDrag} className="inner-options-title">
                    Homepage Background
                </p>
                <div onDragStart={preventDefaultDrag} className="branding-background-image-container">
                    <HPBackgroundImage
                        device={'desktop'}
                        for={'homepageBackground'}
                        label={'Uploaded'}
                        image={branding.data?.homepageBackground}
                        filesUrl={branding.data?.filesUrl}
                        openSuccessSnackbar={handleOpenSuccessSnackbar}
                        openErrorSnackbar={handleOpenErrorSnackbar}
                        resize={'resize1080'}
                        description={
                            'Upload an image that will be displayed as a background for the main screen of the landing page on Desktop. The image must be a 16:6 aspect ratio (a.e. 1920x720 px). Content space must be 60% of the width, 60% of the height, centered.'
                        }
                        imageRatio={0.375}
                        infoBulletImage={InfoDesktopImage}
                    />
                    <HPBackgroundImage
                        device={'tablet'}
                        for={'homepageBackgroundTablet'}
                        label={'Uploaded'}
                        image={branding.data?.homepageBackgroundTablet}
                        filesUrl={branding.data?.filesUrl}
                        openSuccessSnackbar={handleOpenSuccessSnackbar}
                        openErrorSnackbar={handleOpenErrorSnackbar}
                        resize={'resize900'}
                        description={
                            'Upload an image that will be displayed as a background for the main screen of the landing page on Tablet. The image must be a 4:3 aspect ratio (a.e. 1024x768 px). Content space must be 75% of the width, 75% of the height, centered.'
                        }
                        imageRatio={0.75}
                        infoBulletImage={InfoTabletImage}
                    />
                    <HPBackgroundImage
                        device={'mobile'}
                        for={'homepageBackgroundMobile'}
                        label={'Uploaded'}
                        image={branding.data?.homepageBackgroundMobile}
                        filesUrl={branding.data?.filesUrl}
                        openSuccessSnackbar={handleOpenSuccessSnackbar}
                        openErrorSnackbar={handleOpenErrorSnackbar}
                        resize={'resize768'}
                        description={
                            'Upload an image that will be displayed as a background for the main screen of the landing page on Mobile. The image must be a 1:1 aspect ratio (a.e. 1024x1024 px). Content space must be 70% of the width, 70% of the height, centered.'
                        }
                        imageRatio={1}
                        infoBulletImage={InfoMobileImage}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        brandingLanguage: state.languages.organizerBrandingLanguage,
        eventSlug: state.event.eventSlug,
        branding: state.event.branding,
    };
};

export default connect(mapStateToProps)(HPBackground);
