import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {preventDefaultDrag} from 'Utils/utils';
import {ReactComponent as Test} from '../../images/back.svg';
import Email from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import React, {useCallback, useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import axios from 'store/axios-instance';

const ForgotPassword = ({handlePasswordSent, passwordSent}) => {
    const formRef = useRef();

    //redux
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);

    //states
    const [values, setValues] = useState({
        email: '',
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [didSentAgainClicked, setDidSentAgainClicked] = useState(false);
    const [apiError, setApiError] = useState(false);

    //handlers
    const handleChange = useCallback(
        (e) => {
            const {name, value} = e.target;

            setValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        },
        [setValues]
    );

    const handleKeydown = useCallback(
        async (e) => {
            if (e.keyCode === 32) {
                e.preventDefault();
                return;
            }
        },
        [formRef, setIsButtonDisabled]
    );

    const handleOnBlur = useCallback(async () => {
        const isFormValid = await formRef.current.isFormValid(true);
        setIsButtonDisabled(!isFormValid);
    }, [formRef, setIsButtonDisabled]);

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const {data} = await axios.post('/auth/recover-password', {email: values.email});
            if (data.success) {
                handlePasswordSent(true, values.email);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setApiError(true);
        }
    };

    const handleSentAgain = async (e) => {
        e.preventDefault();
        try {
            const {data} = await axios.post('/auth/recover-password', {email: values.email});
            if (data.success) {
                handlePasswordSent(true, values.email);
                setDidSentAgainClicked(true);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setApiError(true);
        }
    };

    const handleError = () => {
        setApiError(true);
    };

    useEffect(() => {
        const tempEmail = localStorage.getItem('tempEmail');
        setValues((prevValues) => ({
            ...prevValues,
            email: tempEmail,
        }));
    }, []);

    return (
        <>
            {passwordSent.success ? (
                <div className={`emailReceived`}>
                    {!didSentAgainClicked && (
                        <>
                            <p>
                                {translation.newLoginRegistrations.didntReceiveEmail ||
                                    defaultTranslation.newLoginRegistrations.didntReceiveEmail}
                            </p>

                            <Button
                                variant={'text'}
                                disabled={false}
                                disableElevation={false}
                                disableRipple={false}
                                onClick={handleSentAgain}
                                color={'secondary'}
                            >
                                {translation.newLoginRegistrations.sendAgain ||
                                    defaultTranslation.newLoginRegistrations.sendAgain}
                            </Button>
                        </>
                    )}
                </div>
            ) : (
                <ValidatorForm
                    ref={formRef}
                    className={`login-form-fc form-margin`}
                    onSubmit={onSubmit}
                    onError={handleError}
                >
                    <TextValidator
                        label="Email"
                        className={`email-text-field field-container withEndAdornment`}
                        onChange={handleChange}
                        onBlur={handleOnBlur}
                        onKeyDown={handleKeydown}
                        name="email"
                        type="email"
                        keyboardIcon={<Test classes={{root: 'dark-color'}} />}
                        value={values.email}
                        validators={['required', 'isEmail']}
                        errorMessages={[
                            translation.errors.required || defaultTranslation.errors.required,
                            translation.errors.emailNotValid || defaultTranslation.errors.emailNotValid,
                        ]}
                        InputProps={{
                            endAdornment: (
                                <div onDragStart={preventDefaultDrag} className="show-pass email">
                                    <Email />
                                </div>
                            ),
                        }}
                    />

                    <div className={`submit-and-secondary-actions`}>
                        <Button
                            type="submit"
                            disabled={isButtonDisabled}
                            className={`send-form-fc ${isButtonDisabled ? 'disabled' : ''}`}
                        >
                            {translation.newLoginRegistrations.recoveryPassword ||
                                defaultTranslation.newLoginRegistrations.recoveryPassword}
                        </Button>
                    </div>

                    {apiError && (
                        <p className={`error-message forgot-pass`}>
                            Please double-check that the emails address is correct. <br /> Try again or
                            <a href="/" target="_blank">
                                {' '}
                                get in touch{' '}
                            </a>
                            with us
                        </p>
                    )}
                </ValidatorForm>
            )}
        </>
    );
};
export default ForgotPassword;
