import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactPlayer from 'react-player';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import {connect} from 'react-redux';
import {isMobile} from 'react-device-detect';
import {preventDefaultDrag} from '../../Utils/utils';
import moment from 'moment';
import Slider from '@material-ui/core/Slider';
import Spinner from '../../SmallLayoutComponents/Spinner';
import './BoothVideoStyles.scss';

class BoothVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false,
            muteVideo: isMobile ? true : false,
            loaded: false,
            connectTimestamp: null,
            isVideoPlaying: true,
            progressTime: 0,
            isLoading: true,
        };
        this.videoPlayer = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
        document.addEventListener('webkitfullscreenchange', this.escFunction, false);
        document.addEventListener('mozfullscreenchange', this.escFunction, false);
        document.addEventListener('fullscreenchange', this.escFunction, false);
        document.addEventListener('MSFullscreenChange', this.escFunction, false);
        this.setState({loaded: true});
    }

    componentDidUpdate(prevProps) {
        // update thumbnail everytime the exhibitor changes the videoUrl
        if (prevProps.videoUrl !== this.props.videoUrl) {
            this.setState(
                {
                    progressTime: 0,
                },
                () => {
                    this.videoPlayer.current && this.videoPlayer.current.seekTo(0, 'seconds');
                }
            );
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
        if (document.fullscreenElement) {
            document.exitFullscreen().then(() => {});
        }
        document.removeEventListener('webkitfullscreenchange', this.escFunction, false);
        document.removeEventListener('mozfullscreenchange', this.escFunction, false);
        document.removeEventListener('fullscreenchange', this.escFunction, false);
        document.removeEventListener('MSFullscreenChange', this.escFunction, false);

        clearTimeout(this.timeoutId);
        // booth statistics
        const {onVideoExit} = this.props;
        const {connectTimestamp} = this.state;
        if (onVideoExit && connectTimestamp) {
            this.props.onVideoExit(connectTimestamp);
            this.setState({connectTimestamp: null});
        }
    }

    handleVideoReady = () => {
        const {lobby} = this.props;
        this.videoPlayer.current.getInternalPlayer().setLoop(true);
        if (lobby) {
            this.setState({connectTimestamp: moment.utc()}, () => {
                this.props.handleCloseSpinner();
            });
        }
        this.setState({
            connectTimestamp: moment.utc(),
            isLoading: false,
        });
    };

    escFunction = (e) => {
        if (e.key === 'Escape') {
            this.removeFullScreen();
            this.setState({fullscreen: false});
        }
    };

    handleProgressChange = (e, value) => {
        e.preventDefault();
        this.videoPlayer.current.seekTo(value / 100.5, 'fraction');
    };

    handleProgressBar = (progress) => {
        this.setState({
            progressTime: parseFloat((progress?.played * 100).toFixed(1)),
        });
    };

    toggleMute = () => this.setState({muteVideo: !this.state.muteVideo});
    removeFullScreen = async () => {
        const reactPlayer = document.querySelector('.react-player');
        const controls = document.querySelector('.booth-video-actions-container');
        const muiCloseButton = document.querySelector('.MuiDialogActions-root.MuiDialogActions-spacing');

        document.querySelector('body').classList.remove('fullscreen-bg');
        reactPlayer.classList.remove('full-screen-all');
        controls.classList.remove('full-screen-all');
        muiCloseButton.classList.remove('d-none');
        var isInFullScreen =
            (document.fullscreenElement && document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement && document.msFullscreenElement !== null);
        if (isInFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            return;
        }
    };
    fullScreen = () => {
        const reactPlayer = document.querySelector('.react-player');
        const controls = document.querySelector('.booth-video-actions-container');
        const muiCloseButton = document.querySelector('.MuiDialogActions-root.MuiDialogActions-spacing');
        document.querySelector('body').classList.toggle('fullscreen-bg');
        reactPlayer.classList.toggle('full-screen-all');
        muiCloseButton.classList.toggle('d-none');
        controls.classList.toggle('full-screen-all');

        var isInFullScreen =
            (document.fullscreenElement && document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement && document.msFullscreenElement !== null);

        var docElm = document.documentElement;
        if (!isInFullScreen) {
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    toggleFullscreen = () => {
        this.fullScreen();
        this.setState({fullscreen: !this.state.fullscreen});
    };

    toggleRewind = () => {
        const {streamingType} = this.props;
        let currentTime = Math.floor(this.videoPlayer.current.getCurrentTime());
        if (streamingType === 'youtube') {
            this.videoPlayer.current.seekTo(Math.floor(this.videoPlayer.current.getCurrentTime()) - 30, 'seconds');
        } else {
            if (currentTime - 30 > 0) {
                this.videoPlayer.current.seekTo(currentTime - 30, 'seconds');
            } else {
                this.videoPlayer.current.seekTo(0);
            }
        }
    };

    toggleForward = () => {
        const {streamingType} = this.props;
        let currentTime = Math.floor(this.videoPlayer.current.getCurrentTime());
        if (streamingType === 'youtube') {
            this.videoPlayer.current.seekTo(Math.floor(this.videoPlayer.current.getCurrentTime()) + 30, 'seconds');
        } else {
            this.videoPlayer.current
                .getInternalPlayer()
                .getDuration()
                .then((duration) => {
                    if (currentTime + 30 < duration) {
                        this.videoPlayer.current.seekTo(currentTime + 30, 'seconds');
                    } else {
                        this.videoPlayer.current.seekTo(duration);
                    }
                });
        }
    };

    togglePause = () => {
        const {streamingType,videoUrl} = this.props;

        let ruleVimeo = new RegExp('^https://vimeo.com/((?!event).).+', 'i');
        if (ruleVimeo.test(videoUrl) && isMobile) {
            if (this.state.isVideoPlaying) {
                this.videoPlayer?.current?.getInternalPlayer().pause();
            } else {
                this.videoPlayer?.current?.getInternalPlayer().play();
            }
        }
         this.setState({isVideoPlaying: !this.state.isVideoPlaying}, () => {
            if (streamingType === 'youtube') {
                if (this.state.isVideoPlaying) {
                    this.videoPlayer?.current?.getInternalPlayer().playVideo();
                } else {
                    this.videoPlayer?.current?.getInternalPlayer().pauseVideo();
                }
            }
        });
    };

    closeDialog = () => {
        this.removeFullScreen();
        this.setState({fullscreen: false});
        if (isMobile) {
            this.setState({muteVideo: true});
        }
        this.props.closeDialog();
    };

    isVimeo = (videoUrl) => {
        let ruleVimeo = new RegExp('^https://vimeo.com/((?!event).).+', 'i');
        if (ruleVimeo.test(videoUrl) && isMobile) {
            return true;
        } else return false;
    };
    // isMouseUp = () => {
    //     const reactPlayer = document.querySelector('.react-player');
    //     const controls = document.querySelector('.booth-video-actions-container');
    //     if (reactPlayer) {
    //         setTimeout(() => {
    //             controls.classList.remove('vjs-fade-out');
    //         }, 1500);
    //     }
    // };
    // isMouseDown = () => {
    //     const reactPlayer = document.querySelector('.react-player');
    //     const controls = document.querySelector('.booth-video-actions-container');
    //     if (reactPlayer) {
    //         setTimeout(() => {
    //             controls.classList.add('vjs-fade-out');
    //         }, 1500);

    //         reactPlayer.onmousemove = () => controls.classList.remove('vjs-fade-out');
    //     }
    // };
    render() {
        const {fullscreen, loaded, muteVideo, isVideoPlaying, progressTime, isLoading} = this.state;
        const {opened, classes, videoUrl, videoImage, videoLoading, translation} = this.props;

        return (
            <Dialog
                open={opened}
                onClose={this.closeDialog}
                // onMouseMove={this.isMouseUp}
                // onMouseDown={this.isMouseDown}
                fullScreen={fullscreen}
                aria-labelledby="form-dialog-title"
                disableEscapeKeyDown={true}
                id="booth-videos-container"
                className={`booth-video-dialog-container booth-dialog ${fullscreen ? 'fullscreen' : ''} ${
                    classes !== undefined ? classes : ''
                }`}
            >
                {loaded ? (
                    <DialogContent className="booth-dialog-content booth-video-dialog-container">
                        <DialogTitle className="mobile-helper" id="form-dialog-title">
                            {translation?.showfloor.boothVideoDialogTitle}
                        </DialogTitle>

                        <div onDragStart={preventDefaultDrag} className="booth-video-container mobile-helper">
                            <div className={`auditoriumOverlayPlayer desktop-helper`} onClick={this.toggleMute}></div>

                            {(videoLoading || isLoading) && <Spinner />}
                            {this.isVimeo(videoUrl) === false && (
                                <img
                                    src={videoImage}
                                    alt="Video thumbnail"
                                    className={`booth-thumbnail ${isVideoPlaying ? 'hide' : ''}`}
                                />
                            )}

                            <ReactPlayer
                                ref={this.videoPlayer}
                                url={videoUrl}
                                onReady={this.handleVideoReady}
                                onProgress={this.handleProgressBar}
                                playing={isVideoPlaying}
                                loop
                                playsinline
                                playIcon={<></>}
                                controls={false}
                                width="100%"
                                height="100%"
                                volume={muteVideo ? 0 : 1}
                                muted={muteVideo}
                                className="react-player"
                            />
                            <Slider
                                classes={{root: `progress-bar ${!isVideoPlaying && 'hide'}`}}
                                onChange={this.handleProgressChange}
                                value={progressTime}
                                color="secondary"
                            />
                            {/* <div onDragStart={preventDefaultDrag} className="auditoriumOverlayPlayer"/> */}
                        </div>
                        <div onDragStart={preventDefaultDrag} className="booth-video-actions-container mobile-helper">
                            <button onClick={this.toggleFullscreen} className="fullscreen-button">
                                {fullscreen ? (
                                    <FullscreenExitIcon fontSize="inherit" />
                                ) : (
                                    <FullscreenIcon fontSize="inherit" />
                                )}
                            </button>
                            <button onClick={this.toggleRewind} className="fullscreen-button mute-button">
                                <FastRewindIcon fontSize="inherit" />
                            </button>
                            <button onClick={this.togglePause} className="fullscreen-button mute-button">
                                {isVideoPlaying ? (
                                    <PauseIcon fontSize="inherit" />
                                ) : (
                                    <PlayArrowIcon fontSize="inherit" />
                                )}
                            </button>
                            <button onClick={this.toggleForward} className="fullscreen-button mute-button">
                                <FastForwardIcon fontSize="inherit" />
                            </button>
                            <button onClick={this.toggleMute} className="fullscreen-button mute-button">
                                {muteVideo ? <VolumeOffIcon fontSize="inherit" /> : <VolumeUpIcon fontSize="inherit" />}
                            </button>
                        </div>
                    </DialogContent>
                ) : null}

                <DialogActions>
                    <Button type="button" onClick={this.closeDialog}>
                        {translation?.generalText.close}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(BoothVideo);

