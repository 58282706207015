import React from "react";
import {useSelector} from "react-redux";

export function LiveWallTabButton({handleSeeLiveWall, handleEnterKeyWall}) {
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const sideNavigation = useSelector((state) => state.user.sideNavigation);
    const eventHasResources = useSelector((state) => state.event.data.hasResources);

    return <div
        tabIndex="0"
        className={
            "live-wall-tab button-tab tab " +
            (sideNavigation.seeLiveWall ? "active " : " ") +
            (eventHasResources ? "small-tab" : "")
        }
        onClick={handleSeeLiveWall}
        onKeyDown={handleEnterKeyWall}
    >
        {translation?.sideMenu.liveWall}
    </div>;
}