import React from 'react';
import ConfirmDelete from '../../../Dialogs/ConfirmDelete';
import {connect} from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import axios from '../../../store/axios-instance';
import * as actions from '../../../store/actions';
import {preventDefaultDrag} from '../../../Utils/utils';
import AccordionLayout from './LayoutComponents/AccordionLayout';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Button from '@material-ui/core/Button';

class HomepageExhibitors extends React.Component {
    state = {
        openAddEditExhibitorDialog: false,
        showConfirmDeleteDialog: false,
        editedExhibitorId: null,
        currentExhibitorIndex: null,
        openAddExhibitor: false,
        showConfirmCloseAccordion: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // after we add a new partner, open the accordion to him
        if(prevProps.branding?.data?.exhibitors?.length < this.props.branding?.data?.exhibitors?.length){
            this.setState({
                currentExhibitorIndex: this.props.branding?.data?.exhibitors?.length - 1
            });
        }
    }

    handleOpenAddExhibitorDialog = () =>
        this.setState({
            openAddEditExhibitorDialog: true,
            currentExhibitorIndex: null,
            openAddExhibitor: true,
        });

    handleCloseDialog = () =>
        this.setState({
            openAddEditExhibitorDialog: false,
            showConfirmCloseAccordion: false,
            openAddExhibitor: false,
        });

    handleAddNewExhibitor = () =>
        this.setState((prevState) => ({
            openAddExhibitor: !prevState.openAddExhibitor,
            currentExhibitorIndex: null,
        }));

    handleCloseDeletePopup = () =>
        this.setState({
            showConfirmCloseAccordion: true,
        });

    openConfirmDeleteDialog = (id) => () =>
        this.setState({
            editedExhibitorId: id,
            showConfirmDeleteDialog: true,
        });

    closeConfirmDeleteDialog = () =>
        this.setState({
            editedExhibitorId: null,
            showConfirmDeleteDialog: false,
            showConfirmCloseAccordion: false,
            openAddExhibitor: true,
        });

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return this.handleOpenAddExhibitorDialog();
    };

    setExhibitorIndex = (exhibitorIndex) => (e) => {
        let newIndex = this.state.currentExhibitorIndex === exhibitorIndex ? null : exhibitorIndex;
        this.setState({
            currentExhibitorIndex: newIndex,
            openAddExhibitor: false,
        });
    };

    handleAddExhibitor = (exhibitorData) => {
        const {eventId} = this.props;
        axios({method: 'post', url: `/event/${eventId}/branding/add-exhibitor`, data: exhibitorData})
            .then((response) => {
                this.handleCloseDialog();
                const brandingData = response.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    handleDeleteExhibitor = () => {
        const {eventId} = this.props;
        axios({method: 'delete', url: `/event/v2/${eventId}/branding/remove-exhibitor/${this.state.editedExhibitorId}`})
            .then((response) => {
                this.closeConfirmDeleteDialog();
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    render() {
        const {
            openAddEditExhibitorDialog,
            openAddExhibitor,
            showConfirmDeleteDialog,
            showConfirmCloseAccordion,
        } = this.state;
        const {handleOpenSuccessSnackbar, handleOpenErrorSnackbar, branding} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="branding-homepage-exhibitors new-accordion-design">
                <h4 className="advanced-options-title">
                    EXHIBITORS
                    <Button
                        onClick={this.handleOpenAddExhibitorDialog}
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD EXHIBITOR
                    </Button>
                </h4>
                <div onDragStart={preventDefaultDrag} className="list-wrapper">
                    {branding?.data?.exhibitors?.length > 0 ? (
                        <div onDragStart={preventDefaultDrag} className="accordion-container new-role-container">
                            {branding.data.exhibitors.map((exhibitor, exhibitorIndex) => {
                                const isActiveExhibitor = exhibitorIndex === this.state.currentExhibitorIndex;
                                return (
                                    <AccordionLayout
                                        isActive={isActiveExhibitor}
                                        role={exhibitor}
                                        roles={branding.data.exhibitors}
                                        roleTitle={'exhibitor'}
                                        roleIndex={exhibitorIndex}
                                        openConfirmDeleteDialog={this.openConfirmDeleteDialog(exhibitor._id)}
                                        handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                                        handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                                        handleAddRole={this.handleAddExhibitor}
                                        onChange={this.setExhibitorIndex(exhibitorIndex)}
                                        filesUrl={branding.data.filesUrl}
                                        showSection={true}
                                        key={exhibitor._id}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div
                            onDragStart={preventDefaultDrag}
                            className={`advanced-options-container ${openAddEditExhibitorDialog ? 'd-none' : ''}`}
                        >
                            <p onDragStart={preventDefaultDrag} className="list-empty-text">
                                You currently don't have any exhibitors added
                            </p>
                        </div>
                    )}
                </div>
                {openAddEditExhibitorDialog && (
                    <div onDragStart={preventDefaultDrag} className="accordion-container new-role-container">
                        <AccordionLayout
                            isActive={openAddExhibitor}
                            roleTitle={'exhibitor'}
                            onChange={this.handleAddNewExhibitor}
                            openConfirmDeleteDialog={this.handleCloseDeletePopup}
                            handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                            handleAddRole={this.handleAddExhibitor}
                            showSection={false}
                        />
                    </div>
                )}
                <div onDragStart={preventDefaultDrag} className="actions-container">
                    <Button
                        onClick={this.handleOpenAddExhibitorDialog}
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD EXHIBITOR
                    </Button>
                </div>
                {showConfirmDeleteDialog && (
                    <ConfirmDelete
                        open={showConfirmDeleteDialog}
                        closeConfirmDeleteDialog={this.closeConfirmDeleteDialog}
                        dialogTitle={'Delete Exhibitor'}
                        dialogDescription={'Are you sure you want to delete this exhibitor?'}
                        dialogConfirmButtonLabel={'Delete'}
                        handleConfirmDelete={this.handleDeleteExhibitor}
                    />
                )}
                {showConfirmCloseAccordion && (
                    <ConfirmDelete
                        open={showConfirmCloseAccordion}
                        closeConfirmDeleteDialog={this.closeConfirmDeleteDialog}
                        dialogTitle={'Delete Exhibitor'}
                        dialogDescription={'Are you sure you want to delete this exhibitor?'}
                        dialogConfirmButtonLabel={'Delete'}
                        handleConfirmDelete={this.handleCloseDialog}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        branding: state.event.branding,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageExhibitors);
