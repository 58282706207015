import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as DragIcon} from '../../Images/svg/drag.svg';
import './DragAndDrop.scss';

function DragAndDrop({onDrop, onDropAccepted, onDropRejected, accept, maxSize, multiple, validator, inputRef}) {
    const [isDragActive, setIsDragActive] = useState(false);

    const {getRootProps, getInputProps} = useDropzone({
        validator: validator || null,
        onDrop: onDrop,
        accept: accept,
        maxSize: maxSize,
        onDragEnter: () => setIsDragActive(true),
        onDragLeave: () => setIsDragActive(false),
        onDropRejected: (evt) => {
            setIsDragActive(false);
            onDropRejected(evt);
        },
        onDropAccepted: (evt) => {
            setIsDragActive(false);
            onDropAccepted(evt);
        },
        multiple: multiple,
    });

    return (
        <section
            className={`${
                isDragActive
                    ? 'drag-file-wrapper drag-file-wrapper-active upload-program-drag'
                    : 'drag-file-wrapper upload-program-drag'
            }`}
        >
            <div {...getRootProps({className: 'dropzone'})} ref={inputRef}>
                <input {...getInputProps()} />
                <div className="d-flex">
                    <DragIcon fill={colors.secondary} />

                    <p className="drag-text">Simply drag the file here</p>
                </div>
            </div>
        </section>
    );
}

export default DragAndDrop;
