import React from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {preventDefaultDrag} from "Utils/utils";
import Button from "@material-ui/core/Button";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import LinkIcon from '@material-ui/icons/Link';

export class NewResourceLink extends React.Component {
    render() {
        return <ValidatorForm ref="form" className="resource-container" onSubmit={this.props.onSubmit}>
            <div onDragStart={preventDefaultDrag} className="resource-inputs links">
                <div onDragStart={preventDefaultDrag}>
                    <div onDragStart={preventDefaultDrag}>
                        <TextValidator
                            id="newLink"
                            label="Add new URL"
                            type="text"
                            classes={{root: "input-padding-right"}}
                            value={this.props.linkURL}
                            onChange={this.props.changeURL}
                            validators={["isLink"]}
                            errorMessages={["Please enter a valid link (https://site.com)"]}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <LinkIcon/>,
                            }}
                        />
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag}>
                    <div onDragStart={preventDefaultDrag}>
                        <TextValidator
                            id="newLinkLabel"
                            label="Add new label"
                            classes={{root: "input-padding-right"}}
                            type="text"
                            value={this.props.linkLabel}
                            onChange={this.props.changeLabel}
                            fullWidth={true}
                            variant="outlined"
                            validators={["maxStringLength: 100"]}
                            errorMessages={["You have reached the maximum limit of characters allowed (100 characters)"]}
                            // InputProps={{
                            //     endAdornment: <That icon we don't have/>,
                            // }}
                        />
                    </div>
                </div>
            </div>

            <div onDragStart={preventDefaultDrag} className='resource-button'>
                <div>
                    <Button
                        type="submit"
                        disabled={this.props.newLinkError || this.props.newLinkLabelError || !this.props.linkURL || !this.props.linkLabel.trim()}
                        startIcon={<AddOutlinedIcon/>}
                    >
                        ADD
                    </Button>
                </div>
            </div>
        </ValidatorForm>;
    }
}