import React, {Component} from 'react';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as AccountIcon} from '../../Images/pages/platform-homepage/account-icon.svg';
import {ReactComponent as DirectionsIcon} from '../../Images/pages/platform-homepage/directions-icon.svg';
import {ReactComponent as EventNoteIcon} from '../../Images/pages/platform-homepage/event-note-icon.svg';
import {ReactComponent as HelpIcon} from '../../Images/pages/platform-homepage/help-icon.svg';
import {ReactComponent as VoiceIcon} from '../../Images/pages/platform-homepage/voice-icon.svg';
import {ReactComponent as WorkIcon} from '../../Images/pages/platform-homepage/work-icon.svg';
import {preventDefaultDrag} from '../../Utils/utils';

class PlatformCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: [
                {
                    icon: DirectionsIcon,
                    header: 'Conferences & summits',
                    text:
                        'All you need: Multi-track program stage. For one day and multi-day events. Break-out sessions and workshops. Pre-recorded and live streams. Single speaker or panels. Focus group chats. Interactive and engaging format.',
                },
                {
                    icon: AccountIcon,
                    header: 'Fairs & expos',
                    text:
                        'Excellent branding, leads generation, and networking opportunities for your job/career fair, trade show, or industry expo.',
                },
                {
                    icon: EventNoteIcon,
                    header: 'Hybrid events',
                    text:
                        'Physical and online events should go hand-in-hand. myOnvent makes sure it does. Extend your reach with adding an online event to your physical event. Combine a number of physical events with your online event to create a new event experience. Think outside the box!',
                },
                {
                    icon: HelpIcon,
                    header: 'Membership hub',
                    text:
                        'Offer your members online access to all your events as well as the other members. 24/7/365 access. Build an archive of relevant presentations and past events – and offer your members a service which is accessible anytime from anywhere.',
                },
                {
                    icon: WorkIcon,
                    header: 'Company meetings',
                    text:
                        'Ideal for home office workers, remote staff, and international teams. Login from anywhere, and make meetings available on-demand afterwards.',
                },
                {
                    icon: VoiceIcon,
                    header: 'Online courses',
                    text:
                        '24/7/365 on-demand access for online courses and education. Multiply the ROI on your investment in courses and offer your audiences an unbeatable on-demand service.',
                },
            ],
        };
    }

    render() {
        const {cards} = this.state;

        return (
            <div onDragStart={preventDefaultDrag} className="cards-container">
                {cards.map((card) => {
                    const Image = card.icon;
                    return (
                        <div onDragStart={preventDefaultDrag} className="single-card-container" key={card.header}>
                            <div onDragStart={preventDefaultDrag} className="card">
                                <Image fill={colors.secondary} />
                                <h5>{card.header}</h5>
                                <p>{card.text}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default PlatformCards;
