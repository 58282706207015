import Button from "@material-ui/core/Button";
import React from "react";
import './EmailDomains.scss'
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "store/actions";
import {preventDefaultDrag} from "Utils/utils";
import {ReactComponent as RectanglePurple} from "Images/svg/bck_small.svg";

const CantJoinEventDialog = ({exhibitorType, participantType, scholarType}) => {

    const dispatch = useDispatch();
    const translation = useSelector(state =>  state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector(state => state.languages.translations['en']);

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(logoutUser())
    }

    const switchUserType = () => {
        let secondText;
        if (participantType) {
           secondText = translation?.login.logInAsParticipantSecondText || defaultTranslation?.login.logInAsParticipantSecondText
        } else if (exhibitorType){
            secondText = translation?.login.logInAsExhibitorSecondText || defaultTranslation?.login.logInAsExhibitorSecondText
        } else if (scholarType) {
            secondText = translation?.login.logInAsPosterSecondText || defaultTranslation?.login.logInAsPosterSecondText
        }

        return secondText;
    }

    return (
        <div onDragStart={preventDefaultDrag} className="form-container d-flex">
            <div
                onDragStart={preventDefaultDrag}
                className="form-header left-log-in-description is-sign-up"
            >
                <h1 className="form-title banner-text">
                    <div>
                        <RectanglePurple />
                        <span>
                            {translation?.register.registerButton || defaultTranslation?.register.registerButton}
                        </span>
                    </div>
                    <span>
                        {' ' +
                            switchUserType()}
                    </span>
                </h1>
            </div>
            <div className='d-flex second-part'>
                <div className='title-description-container'>
                    <h1 className='description-title'>
                        {translation?.register.emailRestrictionTitle || defaultTranslation?.register.emailRestrictionTitle}
                    </h1>
                    <div>
                        {translation?.register.emailRestrictionInfoText || defaultTranslation?.register.emailRestrictionInfoText}
                    </div>
                    <div>
                        <span>{translation?.register.tip || defaultTranslation?.register.tip} </span>
                        {translation?.register.TipInfoText || defaultTranslation?.register.TipInfoText}
                    </div>
                </div>
                <div className='bottom-container'>
                    <div className='part-bottom d-flex'>
                        <div className='d-flex item-one'>
                            <div className='steps-container'>
                                {translation?.register.step2From2Text || defaultTranslation?.register.step2From2Text}
                            </div>
                        </div>
                        <div className='d-flex item-two'>
                            <Button onClick={handleLogout}>{translation?.menu.logOut}</Button>
                        </div>
                    </div>
                </div>
            </div>
      </div>

    )
}

export default CantJoinEventDialog;