import Switch from '@material-ui/core/Switch';
import React, {useEffect, useState} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';
import ConfirmDelete from 'Dialogs/ConfirmDelete';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {ReactComponent as DragDrop} from '../Helpers/Icons/DragDrop.svg';
import {DeleteOutlineOutlined} from '@material-ui/icons';
import {FormControlLabel} from '@material-ui/core';
const SpeakerAccordion = ({
    children,
    speaker,
    isExpanded,
    activeSpeaker,
    handleDeleteSpeaker,
    updateSpeakerStatus,
    changeStatusSpeakerLocalState,
    provided,
    speakerId,
}) => {
    const dispatch = useDispatch();
    const currentlyEvent = useSelector((state) => state.event);
    const {eventId} = currentlyEvent;

    const [showSpeaker, setShowSpeaker] = useState(!speaker?.hidden);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const openConfirmDeleteDialog = (_) => {
        activeSpeaker();
        setShowConfirmDeleteDialog(true);
    };
    const closeConfirmDeleteDialog = () => {
        setShowConfirmDeleteDialog(false);
    };
    const handleShowSpeaker = (e) => {
        const {checked} = e.target;
        setShowSpeaker(checked);
        if (speaker !== undefined) {
            dispatch(updateSpeakerStatus(eventId, speaker?._id, {hidden: !checked}));
            changeStatusSpeakerLocalState(speaker._id, !checked);
        }
    };

    useEffect(() => {
        if (speaker) {
            setShowSpeaker(!speaker?.hidden);
        } else {
            setShowSpeaker(true);
        }
    }, [speaker]);

    return (
        <Accordion
            expanded={isExpanded}
            className={`advanced-options-container  accordion-wrapper ${isExpanded ? 'active' : ''}`}
        >
            <div className="speaker-accordion">
                <div className="speaker-information">
                    {speakerId === speaker?._id ? (
                        <span className="dragDrop-element">
                            <DragDrop />
                        </span>
                    ) : (
                        <span
                            className="dragDrop-element"
                            {...{
                                ...provided.dragHandleProps,
                                tabIndex: -1,
                            }}
                        >
                            <DragDrop />
                        </span>
                    )}
                    <h3 className="speaker-title">{speaker ? speaker.name : 'NEW SPEAKER'}</h3>
                </div>

                <div className="form-controller">
                    <div className="show-switch-container">
                        <FormControlLabel
                            disabled={speaker ? false : true}
                            label={showSpeaker ? 'SHOW' : 'HIDE'}
                            classes={{label: 'switch-label'}}
                            control={
                                <Switch
                                    tabIndex={speaker ? speaker._id : 0}
                                    color="secondary"
                                    name={'showTimezone'}
                                    onChange={handleShowSpeaker}
                                    checked={showSpeaker}
                                />
                            }
                        />
                    </div>
                    {isExpanded && (
                        <Button type="button" onClick={openConfirmDeleteDialog} startIcon={<DeleteOutlineOutlined />}>
                            DELETE
                        </Button>
                    )}
                    <div
                        className={`button-container with-speaker-prop ${isExpanded ? 'add-border-to-left-side' : ''}`}
                    >
                        <Button
                            onClick={activeSpeaker}
                            type="label"
                            startIcon={<>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>}
                        >
                            {isExpanded ? 'COLLAPSE' : 'EXPAND'}
                        </Button>
                    </div>
                </div>
            </div>
            {isExpanded && <AccordionDetails>{children}</AccordionDetails>}
            {showConfirmDeleteDialog && (
                <ConfirmDelete
                    open={showConfirmDeleteDialog}
                    closeConfirmDeleteDialog={closeConfirmDeleteDialog}
                    dialogTitle={'Delete Speaker'}
                    dialogDescription={'Are you sure you want to delete this speaker?'}
                    dialogConfirmButtonLabel={'Delete'}
                    handleConfirmDelete={handleDeleteSpeaker}
                />
            )}
        </Accordion>
    );
};

export default SpeakerAccordion;
