import React from 'react';
import ReactPlayer from 'react-player';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import CachedIcon from '@material-ui/icons/Cached';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import {isChrome, isSafari, isMobile, isIOS} from 'react-device-detect';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../Utils/utils';
import axios from '../../store/axios-instance';
import Slider from '@material-ui/core/Slider';
import callAxios from 'axios';
import Spinner from '../../SmallLayoutComponents/Spinner';
import * as actions from '../../store/actions';
import './AuditoriumVideoPlayerStyles.scss';

const FullscreenExitIconComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return <FullscreenExitIcon fontSize="inherit" {...props} ref={ref} />;
});

const FullscreenIconComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return <FullscreenIcon fontSize="inherit" {...props} ref={ref} />;
});

class AuditoriumVideoPlayer extends React.Component {
    fullscreenTimerId = 0;
    state = {
        playing: true,
        fullscreen: false,
        showRefreshButton: false,

        // we need this property to know if we need to start the videos with mute
        // isLandingPage can be checked using this.props.history?.action === 'POP'
        muteVideo:
            this.props.history?.action === 'POP' || (isMobile && isIOS)
                ? true
                : this.props.auditorium?.isAuditoriumMuted,
        showAudioOverlay: this.props.history?.action === 'POP' || (isMobile && isIOS) ? true : false,
        videoEnded: false,
        fullscreenTooltipOpened: false,
        thumbnailImage: '',
        isLoading: true,
        duration: 0,
        progressTime: 0,
        videoUrl: '',
        avcoreRecords: [],
        showPlayButton: false,
        videoAlreadyEnded: false,
        iosFullScreen: false,
        videoReady: false,
    };

    componentDidMount() {
        document.addEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);

        this.setVideoUrl();
        this.setThumbnailImage();
    }

    componentWillUnmount() {
        if (document.fullscreenElement) {
            document.exitFullscreen().then(()=>{});
        }
        document.removeEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);

        clearTimeout(this.timeoutId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.timeSlot.streamingEngine === 'ETX Engine') {
        }
        if (prevProps.videoStreamingUrl !== this.props.videoStreamingUrl) {
            this.setThumbnailImage();
            this.setState(
                {
                    progressTime: 0,
                    videoEnded: false,
                    muteVideo: isMobile ? true : prevState.muteVideo,
                    showAudioOverlay: isMobile ? true : false,
                    isLoading: true,
                },
                this.setVideoUrl
            );

            if (this.props.greyVideoProgressBar) {
                this.player?.seekTo(0, 'seconds');
            }
        }

        if (prevProps.currentAuditorium !== this.props.currentAuditorium) {
            this.setState({
                isLoading: false,
            });
        }
        if (prevState.videoEnded !== this.state.videoEnded && isIOS && isMobile && this.state.iosFullScreen) {
            this.toggleFullScreen();
        }
    }

    isVideoYoutube = () => {
        const {timeSlot} = this.props;
        let videoUrl = timeSlot?.video || timeSlot?.url || '';
        let ruleYTB = new RegExp('^(http(s)?://)?((w){3}.)?youtu(be|.be)?(.com)?/.+', 'i');
        let match = ruleYTB.test(videoUrl);
        return match;
    };

    setThumbnailImage = () => {
        const {timeSlot} = this.props;
        let videoId;
        let thumbnailImage;
        let videoUrl = timeSlot?.video || timeSlot?.url || '';
        let ruleYTB = new RegExp('^(http(s)?://)?((w){3}.)?youtu(be|.be)?(.com)?/.+', 'i');
        let ruleVimeo = new RegExp('^https://vimeo.com/((?!event).).+', 'i');
        let ruleWistia = new RegExp(/https:\/\/[A-Za-z0-9_-]+\.(wistia\.com|wi\.st)\/(medias|embed)\/.*/, 'i');
        videoId = getVideoIdFromYoutubeUrl(timeSlot?.video || timeSlot?.url || '');

        if (ruleYTB.test(videoUrl)) {
            thumbnailImage = `https://img.youtube.com/vi/${videoId}/0.jpg`;
            this.setState({
                thumbnailImage: thumbnailImage,
            });
        } else if (ruleVimeo.test(videoUrl)) {
            if (videoUrl) {
                callAxios.get(`https://vimeo.com/api/oembed.json?url=${videoUrl}`).then((res) => {
                    this.setState({
                        thumbnailImage: `${res.data.thumbnail_url}.jpg`,
                    });
                });
            }
        } else if (ruleWistia.test(videoUrl)) {
            let n = videoUrl.lastIndexOf('/');
            let result = videoUrl.substring(n + 1);
            videoUrl = result;
            if (videoUrl) {
                callAxios
                    .get(
                        `https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${videoUrl}?embedType=async_popover`
                    )
                    .then((res) => {
                        this.setState({
                            thumbnailImage: `${res.data.thumbnail_url}`,
                        });
                    });
            }
        }
    };

    toggleFullScreenEscKey = () => {
        let fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullscreenElement ||
            document.msFullscreenElement;
        if (!fullscreenElement) {
            this.exitFullscreen();
        }
    };

    onIOSFullScreen = () => {
        this.setState({iosFullScreen: this.state.iosFullScreen ? false : true, playing: true});
        if (!this.state.videoEnded) {
            document.querySelector('.video-actions-container').classList.toggle('fullscreen-ios-controls');
        }

        document.querySelector('.react-player').classList.toggle('fullscreen-ios');
        document.querySelector('.top-container').classList.toggle('d-none');
        document.querySelector('#menu-bottom').classList.toggle('d-none');
        document.querySelector('body').classList.toggle('fullscreen-bg');
        const isOnDemandRuns = document.querySelector(
            '#js-auditorium-page.mobile-page-container.auditorium-page.wall-tab-active'
        );
        const isLiveOrArcRun = document.querySelector('#js-auditorium-page.mobile-page-container.auditorium-page');

        if (isOnDemandRuns) {
            isOnDemandRuns.classList.toggle('h100vh');
        } else {
            isLiveOrArcRun.classList.toggle('h100vh');
        }
    };

    toggleFullScreen = () => {
        if (this.state.fullscreenTooltipOpened) {
            setTimeout(() => {
                this.setState({fullscreenTooltipOpened: false});
            }, 1500);
        }

        if (isIOS && isMobile) {
            this.onIOSFullScreen();
        } else {
            if (isSafari) {
                if (this.state.fullscreen) {
                    this.exitFullscreen();
                } else {
                    this.startFullscreen();
                }
            } else {
                if (document.fullscreenElement) {
                    document
                        .exitFullscreen()
                        .then(() => this.exitFullscreen())
                        .catch((err) => console.error(err));
                } else {
                    document.documentElement.requestFullscreen().then(() => this.startFullscreen());
                }
            }
        }
    };

    startFullscreen = () => {
        this.setState({fullscreen: true, fullscreenTooltipOpened: false});
        this.hideVideoButtons();

        document.body.classList.add('fullscreen-video');
        document.getElementById('js-auditorium-page').classList.add('fullscreen');
        window.addEventListener('mousemove', this.mouseMove);
    };

    exitFullscreen = () => {
        this.setState({fullscreen: false, fullscreenTooltipOpened: false});
        this.showVideoButtons();
        clearTimeout(this.fullscreenTimerId);

        document.body.classList.remove('fullscreen-video');
        document.getElementById('js-auditorium-page').classList.remove('fullscreen');
        window.removeEventListener('mousemove', this.mouseMove);
    };

    videoHasEnded = () => {
        const {auditorium} = this.props;
        this.exitFullscreen();
        this.setState({videoEnded: true, muteVideo: auditorium.isAuditoriumMuted});
    };

    mouseMove = () => {
        clearTimeout(this.fullscreenTimerId);
        this.showVideoButtons();
        this.fullscreenTimerId = setTimeout(this.hideVideoButtons, 3000);
    };

    showVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.remove('hide');
        }
    };

    hideVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.add('hide');
        }
    };

    refreshPage = () => {
        window.location.reload(true);
    };

    isPlayingError = (err) => {
        const {timeSlot} = this.props;

        if (timeSlot.streamingEngine === 'Vimeo' && timeSlot.isLiveStreaming) {
            if (this.player?.player?.isReady) {
                this.setState({
                    isLoading: false,
                    showRefreshButton: false,
                });
            } else {
                this.setState({
                    isLoading: false,
                    showRefreshButton: true,
                });
            }
        } else {
            this.setState({
                isLoading: false,
                showRefreshButton: true,
            });
        }
    };

    toggleSound = () => {
        const {showAudioOverlay} = this.state;
        this.setState(
            (prevState) => ({
                muteVideo: !prevState.muteVideo,
            }),
            () => {
                if (this.state.muteVideo) {
                    this.props.muteAuditorium();
                } else {
                    this.props.unMuteAuditorium();
                }
            }
        );
        if (showAudioOverlay) {
            this.setState({
                showAudioOverlay: false,
            });
        }
    };

    unmuteVideo = () => {
        localStorage.setItem('auditoriumMuted', false);
        const {showAudioOverlay} = this.state;
        if (showAudioOverlay) {
            this.setState({
                muteVideo: false,
                showAudioOverlay: false,
            });
        }
    };

    ref = (player) => {
        this.player = player;
    };

    skipForward = (e) => {
        const {timeSlot} = this.props;
        let currentTime = Math.floor(this.player.getCurrentTime());
        if (timeSlot.streamingEngine === 'Vimeo') {
            this.player
                .getInternalPlayer()
                .getDuration()
                .then((duration) => {
                    if (currentTime + 30 < duration) {
                        this.player.seekTo(currentTime + 30, 'seconds');
                    } else {
                        this.player.seekTo(duration);
                        this.videoHasEnded();
                    }
                });
        } else {
            this.player.seekTo(currentTime + 30, 'seconds');
        }
    };

    skipBackward = (e) => {
        const {timeSlot} = this.props;
        let currentTime = Math.floor(this.player.getCurrentTime());
        if (timeSlot.streamingEngine === 'Vimeo') {
            if (currentTime - 30 > 0) {
                this.player.seekTo(currentTime - 30, 'seconds');
            } else {
                this.player.seekTo(0);
            }
        } else {
            this.player.seekTo(currentTime - 30, 'seconds');
        }
    };

    setFullscreenTooltipOpen = (e) => {
        this.setState({fullscreenTooltipOpened: true});
    };
    setFullscreenTooltipHide = (e) => {
        this.setState({fullscreenTooltipOpened: false});
    };

    _handleVideoReady = () => {
        const {timeSlot, videoStreamingStartAt, auditorium} = this.props;
        if (isMobile) {
            this.setState({
                isLoading: false,
            });
        }
        if (auditorium) {
            let videoUrl = timeSlot?.video || timeSlot?.url || '';
            let ruleYTB = new RegExp('^(http(s)?://)?((w){3}.)?youtu(be|.be)?(.com)?/.+', 'i');
            let ruleVimeo = new RegExp('^https://vimeo.com/((?!event).).+', 'i');
            let ruleWistia = new RegExp(/https:\/\/[A-Za-z0-9_-]+\.(wistia\.com|wi\.st)\/(medias|embed)\/.*/, 'i');

            if (ruleVimeo.test(videoUrl)) {
                this.player
                    ?.getInternalPlayer()
                    ?.getDuration()
                    ?.then((duration) => {
                        if (videoStreamingStartAt > 0) {
                            if (videoStreamingStartAt < duration) {
                                this.player.seekTo(videoStreamingStartAt);
                            } else {
                                this.player.seekTo(duration);
                                this.videoHasEnded();
                            }
                        } else {
                            this.player.seekTo(0);
                        }
                    });
            } else if (ruleWistia.test(videoUrl)) {
                let isFirstAuditorium = /\d/.test(this.props.history?.location?.pathname);
                if (isSafari && !isFirstAuditorium) this.setState({muteVideo: true, showAudioOverlay: true});

                let duration = this.player?.getDuration();
                if (duration) {
                    if (videoStreamingStartAt > 0) {
                        if (videoStreamingStartAt < duration) {
                            this.player.seekTo(videoStreamingStartAt);
                        } else {
                            this.player.seekTo(duration);
                            this.videoHasEnded();
                        }
                    } else {
                        this.player.seekTo(0);
                    }
                }
                // this.setState({muteVideo:false,showAudioOverlay:false})
            } else if (ruleYTB.test(videoUrl)) {
                // fix video has ended slide on ios only
                if (isIOS) {
                    if (videoStreamingStartAt > 0) {
                        const player = this.player?.getInternalPlayer()?.playerInfo;
                        if (videoStreamingStartAt >= player?.duration) {
                            this.videoHasEnded();
                        }
                    }
                }
                this.setState({
                    showRefreshButton: false,
                });
                if (timeSlot.isLiveStreaming) {
                    const videoPlayer = this.player.getInternalPlayer().playerInfo;
                    if (videoPlayer.mediaReferenceTime === 0) {
                        this.setState({
                            isLoading: false,
                        });
                    }
                }
            }
            if (this.props.singleArchiveVideo) {
                this.setState({
                    isLoading: false,
                });
            }
        }
    };

    handleVideoWhenIsStartPlaying = () => {
        this.setState({
            showPlayButton: false,
            isLoading: false,
        });
    };

    handleProgressBar = (progress) => {
        this.setState({
            progressTime: parseFloat((progress?.played * 100).toFixed(1)),
        });
    };

    handleProgressChange = (e, value) => {
        e.preventDefault();
        const {timeSlot} = this.props;
        if (timeSlot.streamingEngine === 'ETX Engine') {
            // Test only, we need duration of video to go back to fraction
            this.player.seekTo(30, 'seconds');
        } else {
            this.player.seekTo(value / 100.5, 'fraction');
        }
    };

    togglePlayingVideo = () => {
        const {timeSlot} = this.props;
        this.setState((prevState) => ({
            playing: !prevState.playing,
        }));
        if (this.state.playing && timeSlot.streamingEngine === 'Youtube') {
            this.player.getInternalPlayer().pauseVideo();
        }
    };

    setVideoUrl = () => {
        const {videoStreamingStartAt, timeSlot, videoStreamingUrl, isOnDemandVideo} = this.props;

        const session = timeSlot.session;
        let videoUrl = '';
        if (isOnDemandVideo) {
            if (timeSlot?.session?.type === 'recorded' && timeSlot?.session?.link) {
                // case of uploadedVideo
                videoUrl = `${process.env.REACT_APP_SESSION_FOLDER}${timeSlot.session.link}`;
            } else if (timeSlot?.session?.type === 'zoom' || timeSlot?.session?.type === 'live') {
                // a RTMP link, example ZOOM session
                this.loadSessionRecords(timeSlot?.session._id).then((records) => {
                    videoUrl = records;
                    this.setState({
                        videoUrl: records,
                    });
                });
            } else if (timeSlot.video) {
                // a normal URL link, example YOUTUBE url
                videoUrl = timeSlot?.video;
            }
        } else {
            if (!session || session.link) {
                if (timeSlot.streamingEngine === 'Wistia') {
                    // videoUrl = `${timeSlot.video}`;
                    videoUrl = `${videoStreamingUrl}`;
                } else if (timeSlot.streamingEngine === 'Vimeo') {
                    videoUrl = `${videoStreamingUrl}`;
                } else {
                    videoUrl = `${videoStreamingUrl}`;
                    if (videoUrl.toString().includes('wistia')) {
                        videoUrl = `${videoStreamingUrl}`;
                    } else {
                        videoUrl = `${videoStreamingUrl}?t=${videoStreamingStartAt}`;
                    }
                }
            } else {
                videoUrl = `${videoStreamingUrl}?t=${videoStreamingStartAt}s`;
            }
        }
        this.setState({
            videoUrl: videoUrl,
        });
    };

    loadSessionRecords = async (sessionId) => {
        const result = await axios.get(`/sessions/${sessionId}/records`);
        const records = result.data.recordings.map((r) => r.url);
        return records;
    };

    handleOnPlaying = () => {
        this.setState({
            isLoading: false,
        });
    };

    handlePlayVideo = () => {
        const videoObject = this.player.getInternalPlayer();

        if (this.props.loadCurrentTimeSlot) {
            this.setState({
                isLoading: true,
            });

            const promise1 = Promise.resolve(this.props.loadCurrentTimeSlot());
            const promise2 = new Promise((resolve) => {
                this.timeoutId = setTimeout(() => {
                    this.setVideoUrl();
                });
            });

            Promise.all([promise1, promise2])
                .then(
                    this.setState(
                        {
                            isLoading: false,
                            showPlayButton: false,
                        },
                        () => {
                            videoObject.playVideo();
                        }
                    )
                )
                .catch((error) => console.log(error));
        } else {
            videoObject.playVideo();
        }
    };

    render() {
        const {
            timeSlot,
            showFastForwardButtons,
            showPlayPauseButtons,
            greyVideoProgressBar,
            translation,
            defaultTranslation,
        } = this.props;

        // 31.08.2020 currently the fullscreen api is not supported on Safari iPhone
        // 18.02.2022 we wrote our own fullscreen code for Safari iPhone
        // const hideFullscreenButton = isMobileSafari || isOpera || isIOS;
        const {showAudioOverlay, progressTime, videoUrl, showRefreshButton, videoReady} = this.state;
        let {videoEnded, showPlayButton, isLoading, muteVideo} = this.state;

        if (!isMobile && timeSlot.streamingEngine === 'Youtube') {
            // YTB player states values and what it means
            // https://developers.google.com/youtube/iframe_api_reference#Playback_status
            //-1 – unstarted
            // 0 – ended
            // 1 – playing
            // 2 – paused
            // 3 – buffering
            // 5 – video cued
            let playerState = this.player?.getInternalPlayer()?.playerInfo?.playerState;
            if (playerState && playerState === 2) {
                if (this.state.playing) {
                    showPlayButton = true;
                }
            }
        }

        return (
            <div
                className={
                    'videoWrapper ' +
                    (this.state.fullscreen ? 'fullscreen ' : '') +
                    (videoEnded ? 'video-ended ' : '') +
                    (greyVideoProgressBar ? 'grey-bar' : '')
                }
            >
                <div
                    onDragStart={preventDefaultDrag}
                    className={`video-container ${videoEnded ? 'video-has-ended' : ''}`}
                >
                    {isLoading && <Spinner />}
                    {videoEnded && this.props.timeSlot.streamingEngine === 'Wistia' ? null : (
                        <ReactPlayer
                            key={timeSlot.streamingEngine !== 'Vimeo' && timeSlot?._id}
                            ref={this.ref}
                            url={videoUrl}
                            playing={this.state.playing}
                            className="react-player bg-saver"
                            controls={false}
                            width="100%"
                            height="100%"
                            volume={muteVideo ? 0 : 1}
                            muted={muteVideo}
                            onReady={this._handleVideoReady}
                            onContextMenu={(e) => e.preventDefault()}
                            onEnded={this.videoHasEnded}
                            onError={this.isPlayingError}
                            onProgress={this.handleProgressBar}
                            onStart={this.handleVideoWhenIsStartPlaying}
                            onPlay={this.handleOnPlaying}
                            playsinline={isMobile}
                        />
                    )}

                    <div onDragStart={preventDefaultDrag} className="auditoriumOverlayPlayer">
                        <div
                            onDragStart={preventDefaultDrag}
                            className={`${!this.state.playing || (showPlayButton && !showRefreshButton) ? 'show' : ''}`}
                        >
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={this.state.thumbnailImage}
                                alt="preview video"
                            />
                            {showPlayButton && !showRefreshButton ? (
                                <button onClick={this.handlePlayVideo}>
                                    <PlayArrowIcon fontSize="large" />
                                </button>
                            ) : (
                                <button onClick={() => this.setState({playing: !this.state.playing})}>
                                    <PlayArrowIcon fontSize="large" />
                                </button>
                            )}
                        </div>
                    </div>
                    <div
                        className={`auditoriumOverlayPlayer ${showAudioOverlay ? 'audio-overlay' : ''}`}
                        onClick={this.unmuteVideo}
                    >
                        {showAudioOverlay && <VolumeUpIcon style={{zIndex: 9, cursor: 'pointer'}} fontSize="inherit" />}
                    </div>
                    {greyVideoProgressBar && !showPlayButton ? (
                        <Slider
                            classes={{root: `progress-bar ${!this.state.playing ? 'hide' : ''}`}}
                            onChange={this.handleProgressChange}
                            value={progressTime}
                            color="secondary"
                        />
                    ) : null}
                </div>
                {videoEnded ? (
                    <div onDragStart={preventDefaultDrag} className="image-ended-video">
                        {timeSlot.imageUrl ? (
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={timeSlot.imageUrl}
                                alt="current program"
                            />
                        ) : (
                            <p className="video-ended-text">
                                {translation?.videoPlayer.videoEndedTextFirst}
                                <br />
                                {translation?.videoPlayer.videoEndedTextSecond}
                            </p>
                        )}
                    </div>
                ) : (
                    <>
                        {showRefreshButton ? (
                            <div onDragStart={preventDefaultDrag} className="video-error-container">
                                <div onDragStart={preventDefaultDrag} onClick={this.refreshPage}>
                                    <p>{translation?.videoPlayer.videoNotPlayingError}</p>
                                    <CachedIcon fontSize="inherit" />
                                </div>
                            </div>
                        ) : null}
                        <div onDragStart={preventDefaultDrag} className="video-actions-container" id="videoButtons">
                            <button
                                onClick={this.toggleFullScreen}
                                // className={`${hideFullscreenButton ? 'd-none' : ''}`}
                                onMouseOver={this.setFullscreenTooltipOpen}
                                onMouseOut={this.setFullscreenTooltipHide}
                                disabled={isLoading || showPlayButton}
                            >
                                {isLoading || showPlayButton ? (
                                    <>
                                        {this.state.fullscreen ? (
                                            <FullscreenExitIconComponent />
                                        ) : (
                                            <FullscreenIconComponent />
                                        )}
                                    </>
                                ) : (
                                    <Tooltip
                                        arrow
                                        title={
                                            this.state.fullscreen
                                                ? translation?.videoPlayer.tooltipMinimize
                                                : translation?.videoPlayer.tooltipFullscreen
                                        }
                                        disableFocusListener
                                        open={this.state.fullscreenTooltipOpened}
                                    >
                                        {this.state.fullscreen ? (
                                            <FullscreenExitIconComponent />
                                        ) : (
                                            <FullscreenIconComponent />
                                        )}
                                    </Tooltip>
                                )}
                            </button>
                            {showFastForwardButtons && (
                                <>
                                    {isLoading || showPlayButton ? (
                                        <button disabled={isLoading || showPlayButton}>
                                            <FastRewindIcon fontSize="inherit" />
                                        </button>
                                    ) : (
                                        <Tooltip
                                            arrow
                                            title={`-30 ${
                                                translation?.time.secondsShort || defaultTranslation?.time.secondsShort
                                            }`}
                                        >
                                            <button onClick={() => this.skipBackward()}>
                                                <FastRewindIcon fontSize="inherit" />
                                            </button>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                            {showPlayPauseButtons && (
                                <button onClick={this.togglePlayingVideo} disabled={isLoading || showPlayButton}>
                                    {showPlayButton ? (
                                        <PlayArrowIcon fontSize="inherit" />
                                    ) : this.state.playing ? (
                                        <>
                                            {isLoading ? (
                                                <PauseIcon fontSize="inherit" />
                                            ) : (
                                                <Tooltip arrow title={translation?.videoPlayer.tooltipPause}>
                                                    <PauseIcon fontSize="inherit" />
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : (
                                        <Tooltip arrow title={translation?.videoPlayer.tooltipPlay}>
                                            <PlayArrowIcon fontSize="inherit" />
                                        </Tooltip>
                                    )}
                                </button>
                            )}
                            {showFastForwardButtons && (
                                <>
                                    {isLoading || showPlayButton ? (
                                        <button disabled={isLoading || showPlayButton}>
                                            <FastForwardIcon fontSize="inherit" />
                                        </button>
                                    ) : (
                                        <Tooltip
                                            arrow
                                            title={`+30 ${
                                                translation?.time.secondsShort || defaultTranslation?.time.secondsShort
                                            }`}
                                        >
                                            <button onClick={() => this.skipForward()}>
                                                <FastForwardIcon fontSize="inherit" />
                                            </button>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                            <button onClick={this.toggleSound} disabled={isLoading || showPlayButton}>
                                {muteVideo ? (
                                    <>
                                        {isLoading ? (
                                            <VolumeOffIcon fontSize="inherit" />
                                        ) : (
                                            <Tooltip arrow title={translation?.videoPlayer.tooltipUnmute || ''}>
                                                <VolumeOffIcon fontSize="inherit" />
                                            </Tooltip>
                                        )}
                                    </>
                                ) : showPlayButton ? (
                                    <VolumeUpIcon fontSize="inherit" />
                                ) : (
                                    <Tooltip arrow title={translation?.videoPlayer.tooltipMute || ''}>
                                        <VolumeUpIcon fontSize="inherit" />
                                    </Tooltip>
                                )}
                            </button>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auditorium: state.auditorium,
        languages: state.languages,
        isLargeScreen: state.layout.isLargeScreen,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        muteAuditorium: () => dispatch(actions.setAuditoriumMuted()),
        unMuteAuditorium: () => dispatch(actions.setAuditoriumUnMuted()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditoriumVideoPlayer));
