import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
// import {Utils} from "avcore/client";
import {startPlayback} from "../../Utils/videoConference";


class UserVideoStream extends Component {

    state = {
        userPlayback: null,
        userMediaStream: null,
        playing: false
    }

    constructor(props) {
        super(props);
        this.userStream = React.createRef();
    }

    componentDidMount() {
        this.startUserPlayback();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if current activeUser changed the videoStream

        if(prevProps.activeUser.video !== this.props.activeUser.video){
            if(this.props.activeUser.video){
                this.stopUserPlayback().then(()=>{
                    this.startUserPlayback();
                })
            } else{
                this.stopUserPlayback().then(()=>{
                    this.startUserPlayback();
                })
            }
        }

        if(prevProps.activeUser.screenSharing !== this.props.activeUser.screenSharing){
            if(this.props.activeUser.screenSharing){
                this.stopUserPlayback().then(()=>{
                    this.startUserPlayback();
                })
            } else{
                this.stopUserPlayback().then(()=>{
                    this.startUserPlayback();
                })
            }
        }
    }

    componentWillUnmount() {
        // whenever the user leaves the page, make sure you stop his stream and also his playback
        if(this.state.userPlayback){
            this.stopUserPlayback();
        }
    }

    calculateGridSize = () => {
        const {liveSessionActiveUsersLength} = this.props;
        const xSize = Math.ceil(Math.sqrt(liveSessionActiveUsersLength));
        const ySize = xSize * (xSize - 1) >= liveSessionActiveUsersLength ? xSize - 1 : xSize;
        return {
            width: `${100 / xSize}%`,
            height: `${100 / ySize}%`,
        };
    };

    getActiveUserKinds = () => {
        const {activeUser} = this.props;

        // always get the audio kind, we just mute it or not in the video HTML element
        const kinds = ['audio'];
        if(activeUser.video || activeUser.screenSharing){kinds.push('video')};

        return kinds;
    }

    startUserPlayback = async () => {
        const {activeUser} = this.props;

        const kinds = this.getActiveUserKinds();
        const userPlayback = await startPlayback(activeUser.userId, kinds);

        this.setState({
            userPlayback: userPlayback
        })

        // get the media stream from my stream data and play it
        const mediaStream = await userPlayback.subscribe();

        this.setState({
            userMediaStream: mediaStream
        })
        this.startUserVideo(mediaStream);
        //
        // userPlayback.on('addtrack', newStream => this.updateMediaStream(newStream)).on('removetrack', newStream => this.updateMediaStream(newStream));

    }

    startUserVideo = async mediaStream => {
        this.userStream.current.srcObject = mediaStream;
        await this.userStream.current.play();
    }

    updateMediaStream = (newStream) => {
        const updatedMediaStream = new MediaStream(newStream.getTracks());

        const hasPlayingTracks = newStream.getTracks();
        this.userStream.current.srcObject = updatedMediaStream;

        if(hasPlayingTracks){
            this.userStream.current.play();
        }
    }

    stopUserPlayback = async() => {
        const userPlaybackClosed = await this.state.userPlayback.close();
        if(this.userStream?.current?.srcObject){
            this.userStream.current.srcObject = null;
        }
        return userPlaybackClosed;
    }

    render() {
        const {activeUser, liveSessionUser} = this.props

        //always play on mute my personal stream
        const muted = activeUser.userId === liveSessionUser.userId || !this.props.activeUser.audio;

        return (
            <div className={`session-user-playback ${activeUser.screenSharing ? 'is-screen-sharing' : ''}`} key={activeUser.userId} style={this.calculateGridSize()}>
                <video
                    className={`user-stream`}
                    ref={this.userStream}
                    playsInline={true}
                    muted={muted}
                />
                {!activeUser.video && !activeUser.screenSharing &&
                    <div className={`session-video-avatar`}>
                        <span>{activeUser.name.charAt(0)}</span>
                    </div>
                }
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        liveSessionActiveUsersLength: state.liveSession?.liveSessionActiveUsers?.length
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveSession: (sessionId) => dispatch(actions.getLiveSession(sessionId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserVideoStream);