import produce from 'immer';
import {
    CANCEL_SINGLE_MEETING_SUCCESS,
    CANCEL_SINGLE_MEETING_REQUEST,
    CANCEL_SINGLE_MEETING_FAIL,
    ACCEPT_SINGLE_MEETING_SUCCESS,
    ACCEPT_SINGLE_MEETING_REQUEST,
    ACCEPT_SINGLE_MEETING_FAIL,
    ACCEPT_ALL_MEETINGS_FAIL,
    ACCEPT_ALL_MEETINGS_SUCCESS,
    ACCEPT_ALL_MEETINGS_REQUEST,
    ADD_TIMESLOT_TO_MY_AGENDA_REQUEST,
    ADD_TIMESLOT_TO_MY_AGENDA_SUCCESS,
    ADD_TIMESLOT_TO_MY_AGENDA_FAIL,
    GET_MY_AGENDA_REQUEST,
    GET_MY_AGENDA_SUCCESS,
    GET_MY_AGENDA_FAIL,
    GET_SESSIONS_MY_AGENDA_REQUEST,
    GET_SESSIONS_MY_AGENDA_SUCCESS,
    GET_SESSIONS_MY_AGENDA_FAIL,
    DELETE_TIMESLOT_FROM_MY_AGENDA_REQUEST,
    DELETE_TIMESLOT_FROM_MY_AGENDA_SUCCESS,
    DELETE_TIMESLOT_FROM_MY_AGENDA_FAIL,
    GET_MEETINGS_MY_AGENDA_REQUEST,
    GET_MEETINGS_MY_AGENDA_SUCCESS,
    GET_MEETINGS_MY_AGENDA_FAIL,
} from '../actions/actionTypes';

const initialState = {
    canceledMeeting: {success: false},
    acceptedMeeting: {success: false},
    acceptedALLMeetings: {success: false},
    agenda: {},
    agendaSessions: {},
    agendaMeetings: {},
    loading: false,
    error: false,
};

export const myAgendaReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CANCEL_SINGLE_MEETING_REQUEST:
                draft.loading = true;
                break;

            case CANCEL_SINGLE_MEETING_SUCCESS:
                draft.loading = false;
                draft.canceledMeeting = action.payload;
                break;

            case CANCEL_SINGLE_MEETING_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            case ACCEPT_SINGLE_MEETING_REQUEST:
                draft.loading = true;
                break;

            case ACCEPT_SINGLE_MEETING_SUCCESS:
                draft.loading = false;
                draft.acceptedMeeting = action.payload;
                break;

            case ACCEPT_SINGLE_MEETING_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            case ACCEPT_ALL_MEETINGS_REQUEST:
                draft.loading = true;
                break;

            case ACCEPT_ALL_MEETINGS_SUCCESS:
                draft.loading = false;
                draft.acceptedALLMeetings = action.payload;
                break;

            case ACCEPT_ALL_MEETINGS_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            case GET_MY_AGENDA_REQUEST:
                draft.loading = true;
                break;

            case GET_MY_AGENDA_SUCCESS:
                draft.loading = false;
                draft.agenda = action.payload;
                break;

            case GET_MY_AGENDA_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            case GET_SESSIONS_MY_AGENDA_REQUEST:
                draft.loading = true;
                break;

            case GET_SESSIONS_MY_AGENDA_SUCCESS:
                draft.loading = false;
                draft.agendaSessions = action.payload;
                break;

            case GET_SESSIONS_MY_AGENDA_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            //
            case GET_MEETINGS_MY_AGENDA_REQUEST:
                draft.loading = true;
                break;

            case GET_MEETINGS_MY_AGENDA_SUCCESS:
                draft.loading = false;
                draft.agendaMeetings = action.payload;
                break;

            case GET_MEETINGS_MY_AGENDA_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;
            //

            case ADD_TIMESLOT_TO_MY_AGENDA_REQUEST:
                draft.loading = true;
                break;

            case ADD_TIMESLOT_TO_MY_AGENDA_SUCCESS:
                draft.loading = false;
                draft.agendaSessions.data.push(action.payload.data);
                break;

            case ADD_TIMESLOT_TO_MY_AGENDA_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            case DELETE_TIMESLOT_FROM_MY_AGENDA_REQUEST:
                draft.loading = true;
                break;

            case DELETE_TIMESLOT_FROM_MY_AGENDA_SUCCESS:
                draft.loading = false;
                draft.agendaSessions.data = action.payload?.data?.sessions;
                break;

            case DELETE_TIMESLOT_FROM_MY_AGENDA_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            default:
                break;
        }
    });
