import React, {useState, useMemo, useEffect} from 'react';
import moment from 'moment';
import {getProgramFormattedTitleDate, getTimeslotDescriptionFormatted, getTimeslotDuration} from 'Utils/utils';
import {useSelector} from 'react-redux';
import {ReactComponent as CalendarStyle} from '../svgs/calendar.svg';
import {ReactComponent as ListStyle} from '../svgs/list.svg';
import {ReactComponent as Prev} from '../svgs/prev.svg';
import {ReactComponent as Next} from '../svgs/next.svg';
import SingleCalendarListItem from './SingleCalendarListItem';
import DialogAddToExternalCalendar from '../../DialogAddToExternalCalendar';
import SingleCalendarDialog from './SingleCalendarDialog';
import {useHistory} from 'react-router-dom';

const TimeSlotsListCalendar = ({
    addDeleteAgenda,
    isElementAddedToTimeslot,
    timeslots,
    selectedDay,
    layoutType,
    handleLayoutType,
    displayNumberOfSearchResult,
    layoutList,
    searchMode,
}) => {
    const history = useHistory();
    //redux states
    const event = useSelector((state) => state.event.data);
    const user = useSelector((state) => state.user.data);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const languages = useSelector((state) => state.languages);

    const eventSlug = useSelector((state) => state.event.data.slug);
    //states
    const [sliceData, setSliceData] = useState({
        start: 0,
        end: 3,
    });
    const [externalCalendarDialog, setExternalCalendarDialog] = useState(false);
    const [calendarData, setCalendarData] = useState(null);
    const [calendarTimeslot, setCalendarTimeslot] = useState(null);
    const [activeTimeslotCalendar, setActiveTimeslotCalendar] = useState(null);
    const [openTimeslotDialog, setOpenTimeslotDialog] = useState(false);

    //START - Data formatting for calendar

    //get the selected date and reformat it to the format required by the calendar
    const activeDate = moment(selectedDay || timeslots[0]?.date).format('YYYY-MM-DD');

    //get the dates from timeslots and make a new array from them
    const datesOfTimeslots = timeslots?.map((timeslot) => timeslot.date);

    //remove duplicates from the array
    const uniqueDates = [...new Set(datesOfTimeslots)];

    //create a new array of objects with the date and the timeslots of that date
    const timeslotsByDate = uniqueDates.map((date) => {
        const timeslotsOfDate = timeslots?.filter((timeslot) => timeslot.date === date);
        return {date, timeslots: timeslotsOfDate};
    });
    //get the timeslots of the selected date
    const timeslotsOfActiveDate = timeslotsByDate?.find((timeslot) => timeslot?.date === activeDate);

    //sort the timeslots by auditoriumIndex of the selected date
    const sortedTimeslotsOfActiveDate = timeslotsOfActiveDate?.timeslots?.sort(
        (a, b) => a.auditoriumIndex - b.auditoriumIndex
    );

    //get the auditoriumNames of the selected date
    const auditoriumNames = sortedTimeslotsOfActiveDate?.map((timeslot) => timeslot.auditoriumName);

    //remove duplicates from the array
    const uniqueAuditoriumNames = [...new Set(auditoriumNames)];

    //create a new array of objects with the auditoriumName, the date and the timeslots of that auditorium
    const sortedTimeslotsOfActiveDateByAuditoriumName = uniqueAuditoriumNames?.map((auditoriumName) => {
        const timeslotsOfAuditoriumName = sortedTimeslotsOfActiveDate?.filter(
            (timeslot) => timeslot.auditoriumName === auditoriumName
        );
        return {
            auditoriumName,
            auditoriumIndex: timeslotsOfAuditoriumName[0]?.auditoriumIndex,
            auditoriumDate: sortedTimeslotsOfActiveDate[0]?.startTimestamp,
            timeslots: timeslotsOfAuditoriumName,
        };
    });

    //get the current day
    const currentDay = (startDate) => new Date(startDate);

    //length of the list
    const listLength = sortedTimeslotsOfActiveDateByAuditoriumName?.slice(sliceData.start, sliceData.end).length;
    //END - Data formatting for calendar

    //go to the next list
    const setNextList = () => {
        if (sortedTimeslotsOfActiveDateByAuditoriumName.length >= sliceData.end + 1)
            setSliceData({
                start: sliceData.start + 1,
                end: sliceData.end + 1,
            });
    };

    //go to the previous list
    const setPrevList = () => {
        if (sliceData.start > 0)
            setSliceData({
                start: sliceData.start - 1,
                end: sliceData.end - 1,
            });
    };

    //check if there is a next &prev list available
    const isNextListAvailable = () => sortedTimeslotsOfActiveDateByAuditoriumName.length <= sliceData.end;
    const isPrevListAvailable = () => sliceData.start <= 0;

    //check if there is a live stream after the current auditorium
    const isThereAliveStreamAfterTheCurrentAuditorium = () => {
        return sortedTimeslotsOfActiveDateByAuditoriumName.slice(sliceData.end).some((auditorium) => {
            return auditorium.timeslots.some((timeslot) => {
                return timeslot.isLive;
            });
        });
    };
    //check if there is a live stream before the current auditorium
    const isThereAliveStreamBeforeTheCurrentAuditorium = () => {
        return sortedTimeslotsOfActiveDateByAuditoriumName.slice(0, sliceData.start).some((auditorium) => {
            return auditorium.timeslots.some((timeslot) => {
                return timeslot.isLive;
            });
        });
    };

    //if sliceData changes, reset the sliceData
    /*
     * I used useMemo hook here as a dependency for the resetSliceData function.
     * The reason for this, I want to reset the sliceData only when the selectedDay changes.
     * If you come from a react class component background,
     * you might be familiar with the componentDidUpdate lifecycle method.
     * I simulated the same behavior with the useMemo hook.
     * */
    const resetSliceData = useMemo(() => {
        setSliceData({
            start: 0,
            end: 3,
        });
    }, [selectedDay]);

    //add to calendar functions
    const hideAddToExternalCalendarDialog = () => {
        setExternalCalendarDialog(false);
    };

    const handleSetCalendarTimeslot = (timeslot) => (e) => {
        setCalendarTimeslot(timeslot);
        setExternalCalendarDialog(true);
    };
    const createTimeslotCalendarData = () => {
        return {
            title: `${calendarTimeslot.title}`,
            description: getTimeslotDescriptionFormatted(calendarTimeslot, translation),
            location: `${window.location.origin}/event/${event.slug}/`,
            start: calendarTimeslot.startTimestamp,
            end: calendarTimeslot.endTimestamp,
        };
    };

    useEffect(() => {
        if (calendarTimeslot) {
            const calendarData = createTimeslotCalendarData();
            setCalendarData(calendarData);
            setExternalCalendarDialog(true);
        }
    }, [calendarTimeslot]);
    //end - add to calendar functions

    const handleActiveTimeslot = (timeslot) => (e) => {
        setActiveTimeslotCalendar(timeslot);
        setOpenTimeslotDialog(true);
    };

    const handleCloseActiveTimeslot = () => {
        setOpenTimeslotDialog(false);
        setActiveTimeslotCalendar(null);
    };

    const handleOpenAuditorium = (auditoriumIndex) => {
        history.push(`/event/${eventSlug}/auditorium/${auditoriumIndex}`);
    };

    const stopPropagation = (auditoriumIndex) => (e) => {
        // we don't want to bubble the event until it reaches the timeslot <li> element
        // so that we don't open the timeslot details window
        e.stopPropagation();
        handleOpenAuditorium(auditoriumIndex);
    };

    const auditoriumCount = sortedTimeslotsOfActiveDateByAuditoriumName?.length;

    return (
        <>
            <div className={`sticky-calendar`}>
                <div className={`item-list-style-icons date-title`}>
                    <div className={`${auditoriumCount <= 1 ? 'date-calendar' : ''}`}>
                        {getProgramFormattedTitleDate(
                            currentDay(sortedTimeslotsOfActiveDateByAuditoriumName[0]?.auditoriumDate),
                            languages
                        )}
                    </div>
                    <div className={`auditorium-numbers ${auditoriumCount <= 1 ? 'single-auditorium-padding' : ''}`}>
                        {translation?.auditorium.auditoriums || defaultTranslation?.auditorium.auditoriums}:{' '}
                        {auditoriumCount >= 3 ? sliceData.end + '/' + auditoriumCount : auditoriumCount}
                    </div>
                    {!displayNumberOfSearchResult && (
                        <div className={`item-list-styles-container`}>
                            {layoutList?.length > 1 && (
                                <>
                                    <CalendarStyle
                                        className={`${layoutType === 'calendar' ? 'active' : ''}`}
                                        onClick={handleLayoutType('calendar')}
                                    />
                                    <ListStyle
                                        className={`${layoutType === 'list' ? 'active' : ''}`}
                                        onClick={handleLayoutType('list')}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </div>

                {auditoriumCount >= 3 && <div></div>}
                <div className={`auditoriums-pager`}>
                    {auditoriumCount > 3 && (
                        <button
                            onClick={setPrevList}
                            className={`${isPrevListAvailable() ? 'passive' : ''} pager-prev cursor-pointer`}
                        >
                            <Prev />
                            {isThereAliveStreamBeforeTheCurrentAuditorium() && (
                                <div className={`isLiveBall setLeft`}></div>
                            )}
                        </button>
                    )}

                    {sortedTimeslotsOfActiveDateByAuditoriumName
                        .slice(sliceData.start, sliceData.end)
                        .map((auditorium, index) => (
                            <>
                                <div
                                    key={auditorium?.auditoriumName}
                                    className={`column-element ${
                                        listLength - 1 === index ? 'last-child' : ''
                                    } ellipsis-second-line cursor-pointer`}
                                    onClick={stopPropagation(auditorium.auditoriumIndex)}
                                >
                                    {auditorium?.auditoriumName}
                                </div>
                            </>
                        ))}
                    {auditoriumCount > 3 && (
                        <button
                            onClick={setNextList}
                            className={`${isNextListAvailable() ? 'passive' : ''} pager-next cursor-pointer`}
                        >
                            <Next />
                            {isThereAliveStreamAfterTheCurrentAuditorium() && (
                                <div className={`isLiveBall setRight`}></div>
                            )}
                        </button>
                    )}
                </div>
            </div>
            <div className={`lists`}>
                {sortedTimeslotsOfActiveDateByAuditoriumName.slice(sliceData.start, sliceData.end).map((auditorium) => (
                    <>
                        <div key={auditorium?.auditoriumName} className={`column-element`}>
                            <ul>
                                {auditorium?.timeslots.map((timeslot) => {
                                    return (
                                        <SingleCalendarListItem
                                            auditoriumCount={auditoriumCount}
                                            activeTimeslotCalendar={activeTimeslotCalendar}
                                            key={timeslot._id}
                                            stopPropagation={stopPropagation}
                                            handleActiveTimeslot={handleActiveTimeslot}
                                            timeslot={timeslot}
                                            addDeleteAgenda={addDeleteAgenda}
                                            isElementAddedToTimeslot={isElementAddedToTimeslot}
                                            handleSetCalendarTimeslot={handleSetCalendarTimeslot}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                    </>
                ))}
            </div>
            {externalCalendarDialog && (
                <DialogAddToExternalCalendar
                    open={externalCalendarDialog}
                    onClose={hideAddToExternalCalendarDialog}
                    eventCalendarData={calendarData}
                    title={
                        translation?.programDropdown.addTimeslotToYourCalendar ||
                        defaultTranslation?.programDropdown.addTimeslotToYourCalendar
                    }
                    timeslotTitle={
                        translation?.programDropdown.timeslotTitle || defaultTranslation?.programDropdown.timeslotTitle
                    }
                    timeslotDescripion={calendarTimeslot.title}
                    timeslotSpeakers={calendarTimeslot.speakers}
                    durationTitle={
                        translation?.programDropdown.slotScheduled || defaultTranslation?.programDropdown.slotScheduled
                    }
                    duration={getTimeslotDuration(calendarTimeslot, languages, event, user)}
                    tip={
                        translation?.programDropdown.tipTimeslotDescription ||
                        defaultTranslation?.programDropdown.tipTimeslotDescription
                    }
                />
            )}
            {openTimeslotDialog && (
                <SingleCalendarDialog
                    open={openTimeslotDialog}
                    onClose={handleCloseActiveTimeslot}
                    timeslot={activeTimeslotCalendar}
                    addDeleteAgenda={addDeleteAgenda}
                    isElementAddedToTimeslot={isElementAddedToTimeslot}
                    handleSetCalendarTimeslot={handleSetCalendarTimeslot}
                />
            )}
        </>
    );
};
export default TimeSlotsListCalendar;
