import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {connect} from 'react-redux';
import colors from '../../../../CSS/_variables.module.scss';
import Confirm from '../../../../Dialogs/Confirm';
import {ReactComponent as Close} from '../../../../Images/svg/close-red.svg';
import PublishIcon from '@material-ui/icons/Publish';
import Spinner from '../../../../SmallLayoutComponents/Spinner';
import * as actions from '../../../../store/actions/index';
import axios from '../../../../store/axios-instance';
import {preventDefaultDrag, fireClickEvent, checkIfLeftOrRightWindowIsUploaded, getWindowView} from 'Utils/utils';
import {verifyFileType} from 'Utils/verifyFileType';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

class BrandingBackgroundImage extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            newLogoFile: null,
            imageLogoPreviewUrl: null,
            imageLogoErrorText: '',
            imageLogoWarningText: '',
            openConfirmUnsavedChanges: false,
            isPreviewOpen: false,
            updateLogoLoading: false,
            navigationElement: null,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handlePreviewOpen = () => this.setState({isPreviewOpen: true});
    handlePreviewClose = () => this.setState({isPreviewOpen: false});
    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
        ////this.props.setIsAnyConfirmUnsavedChangesOpen(false);
    };

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return document.getElementById('upload-image-input').click();
    };

    getNewNavigationElement = (e) => {
        const {isAnyConfirmUnsavedChangesOpen} = this.props;
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) =>
            pathElem.getAttribute?.('data-is-navigation')
        );

        if (openConfirmUnsavedChanges || isAnyConfirmUnsavedChangesOpen) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        const {imageLogoPreviewUrl, imageLogoErrorText} = this.state;
        const {image, hasPreview} = this.props;
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (imageLogoPreviewUrl && !imageLogoErrorText.length > 0 && (hasPreview ? true : image !== null)) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
                // this.props.setIsAnyConfirmUnsavedChangesOpen(true);
            }
        }
    }

    checkBannerDimensions = (e) => {
        if (this.props.imageRatio) {
            const imageWidth = e.target.naturalWidth;
            const imageHeight = e.target.naturalHeight;
            if (
                imageHeight / imageWidth > this.props.imageRatio - 0.01 &&
                imageHeight / imageWidth < this.props.imageRatio + 0.01
            ) {
                this.setState({imageLogoWarningText: ''});
            } else {
                this.setState({imageLogoWarningText: 'Warning: image does not have the recommended ratio.'});
            }
        }
    };

    removeLogo = () => {
        this.setState({
            updateLogoLoading: true,
        });
        const {eventId} = this.props;
        const data = {
            brandingProperty: this.props.for,
        };

        let deleteUrl = this.props.deleteUrl ? this.props.deleteUrl : 'branding/delete-background-image';

        axios({method: 'delete', url: `/event/v2/${eventId}/${deleteUrl}`, data: data})
            .then((response) => {
                // some properties are not saved in brandingData so we need to refresh the "eventData"
                if (
                    this.props.for === 'stageBanner' ||
                    this.props.for === 'auditoriumRender' ||
                    this.props.for === 'lobbyRender' ||
                    this.props.for === 'villageRender'
                ) {
                    this.props.reloadEventData(eventId);
                } else {
                    const brandingData = response.data.data.brandingData;
                    this.props.getEventBrandingSuccess(brandingData);
                }
                this.setState({
                    newLogoFile: null,
                    imageLogoPreviewUrl: null,
                    imageLogoWarningText: '',
                    openConfirmUnsavedChanges: false,
                    updateLogoLoading: false,
                });
                ////this.props.setIsAnyConfirmUnsavedChangesOpen(false);
                this.props.openSuccessSnackbar();
            })
            .catch(() => {
                this.props.openErrorSnackbar();
                this.setState({
                    updateLogoLoading: false,
                });
            });
    };

    updateLogo = () => {
        const {eventId} = this.props;
        const {navigationElement} = this.state;

        const formData = new FormData();
        formData.append('brandingProperty', this.props.for);
        formData.append('image', this.state.newLogoFile, this.state.newLogoFile.name, this.state.newLogoFile.type);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        let uploadUrl = this.props.uploadUrl ? this.props.uploadUrl : 'branding/upload-background-image';
        this.setState({
            updateLogoLoading: true,
        });
        axios({method: 'post', url: `/event/${eventId}/${uploadUrl}`, data: formData, config})
            .then((response) => {
                // update branding data from response if the data is available
                const brandingData = response.data.brandingData;
                if (brandingData) {
                    this.props.getEventBrandingSuccess(brandingData);
                }

                // some properties are not saved in brandingData so we need to refresh the "eventData"
                if (
                    this.props.for === 'stageBanner' ||
                    this.props.for === 'auditoriumRender' ||
                    this.props.for === 'lobbyRender' ||
                    this.props.for === 'villageRender'
                ) {
                    this.props.reloadEventData(eventId);
                }
                this.setState(
                    {
                        newLogoFile: null,
                        imageLogoPreviewUrl: null,
                        openConfirmUnsavedChanges: false,
                        updateLogoLoading: false,
                    },
                    () => this.props.openSuccessSnackbar()
                );
                ////this.props.setIsAnyConfirmUnsavedChangesOpen(false);

                if (navigationElement) {
                    fireClickEvent(navigationElement);
                }
            })
            .catch(() => {
                this.setState(
                    {
                        openConfirmUnsavedChanges: false,
                        updateLogoLoading: false,
                    },
                    () => this.props.openErrorSnackbar()
                );
                ////this.props.setIsAnyConfirmUnsavedChangesOpen(false);
            });
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.setState({
            newLogoFile: null,
            imageLogoPreviewUrl: null,
            imageLogoErrorText: '',
            imageLogoWarningText: '',
            isPreviewOpen: false,
            updateLogoLoading: false,
        });

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleLogoChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        let isValid = true;

        if (!file) {
            return;
        }

        let uploadLimit = 2;
        if (this.props.uploadLimit) {
            uploadLimit = this.props.uploadLimit;
        }

        isValid = file.size < uploadLimit * 1024 * 1024 && isValid;
        if (!isValid) {
            this.setState({
                imageLogoPreviewUrl: null,
                imageLogoErrorText: `File too large. ${uploadLimit}Mb max file size.`,
            });
        }

        const typeValid = verifyFileType(file.type, 'image');
        isValid = typeValid && isValid;
        if (!typeValid) {
            this.setState({
                imageLogoPreviewUrl: null,
                imageLogoErrorText:
                    'File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.',
            });
        }

        if (isValid && this.props.imageRatio) {
            const scope = this;

            reader.onload = (e) => {
                let img = new Image();
                img.src = e.target.result;
                img.onload = function () {
                    const imageWidth = this.width;
                    const imageHeight = this.height;

                    if (
                        imageHeight / imageWidth > scope.props.imageRatio - 0.01 &&
                        imageHeight / imageWidth < scope.props.imageRatio + 0.01
                    ) {
                        scope.setState({imageLogoWarningText: ''});
                    } else {
                        scope.setState({imageLogoWarningText: 'Warning: image does not have the recommended ratio.'});
                    }
                };
            };
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    newLogoFile: file,
                    imageLogoPreviewUrl: reader.result,
                    imageLogoErrorText: '',
                });
            }
        };

        reader.readAsDataURL(file);
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    render() {
        const {
            newLogoFile,
            imageLogoPreviewUrl,
            imageLogoErrorText,
            imageLogoWarningText,
            openConfirmUnsavedChanges,
            isPreviewOpen,
            updateLogoLoading,
        } = this.state;
        const {image, filesUrl, description, subLabel, dragDropOrientation, hasPreview, email, event, lobby} =
            this.props;
        let hasAtLeastOneWindowUploaded = checkIfLeftOrRightWindowIsUploaded(event);
        return (
            <>
                <div
                    ref={this.wrapperRef}
                    className={dragDropOrientation === 'portrait' ? 'options-container-portrait' : 'options-container'}
                >
                    <div
                        className={
                            dragDropOrientation === 'portrait'
                                ? 'single-option-container-portrait'
                                : 'single-option-container'
                        }
                    >
                        <div
                            className={
                                dragDropOrientation === 'portrait' ? 'actions-container-portrait' : 'actions-container'
                            }
                        >
                            {newLogoFile && (
                                <Button
                                    type="button"
                                    onClick={this.updateLogo}
                                    disabled={!imageLogoPreviewUrl || imageLogoErrorText.length > 0}
                                    startIcon={<SaveOutlinedIcon />}
                                    variant="contained"
                                    color={'secondary'}
                                    disableElevation
                                >
                                    Save image
                                </Button>
                            )}

                            {imageLogoPreviewUrl ? (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    component="label"
                                    onKeyDown={this.handleEnterKey}
                                    startIcon={<EditOutlinedIcon />}
                                >
                                    Edit
                                    <input
                                        id="upload-image-input"
                                        type="file"
                                        onChange={this.handleLogoChange}
                                        className="upload-image-button d-none"
                                        hidden
                                    />
                                </Button>
                            ) : (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    component="label"
                                    onKeyDown={this.handleEnterKey}
                                    startIcon={<CloudUploadIcon />}
                                >
                                    Upload image
                                    <input
                                        id="upload-image-input"
                                        type="file"
                                        onChange={this.handleLogoChange}
                                        className="upload-image-button d-none"
                                        hidden
                                    />
                                </Button>
                            )}

                            {image && !newLogoFile && (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    onClick={this.removeLogo}
                                    disabled={imageLogoPreviewUrl}
                                    startIcon={<DeleteOutlineIcon />}
                                >
                                    Remove
                                </Button>
                            )}
                            {image && hasPreview && (
                                <Button
                                    type="button"
                                    onClick={this.handlePreviewOpen}
                                    color={'primary'}
                                    startIcon={<VisibilityIcon />}
                                >
                                    Preview
                                </Button>
                            )}
                        </div>
                        <div
                            className={
                                dragDropOrientation === 'portrait'
                                    ? 'manage-partner-logo-portrait'
                                    : 'manage-partner-logo'
                            }
                        >
                            <div
                                className={
                                    dragDropOrientation === 'portrait' ? 'logo-container-portrait' : 'logo-container'
                                }
                            >
                                <div
                                    className={
                                        dragDropOrientation === 'portrait' ? 'image-wrapper-portrait' : 'image-wrapper'
                                    }
                                >
                                    {updateLogoLoading && !openConfirmUnsavedChanges ? (
                                        <div
                                            className={`current-banner-container ${
                                                dragDropOrientation === 'portrait'
                                                    ? 'cover-img-container-portrait'
                                                    : 'cover-img-container'
                                            }`}
                                        >
                                            <label htmlFor={this.props.for} className="upload-label">
                                                <Spinner />
                                            </label>
                                        </div>
                                    ) : image ? (
                                        <div
                                            className={`current-logo-container ${
                                                dragDropOrientation === 'portrait'
                                                    ? 'cover-img-container-portrait'
                                                    : 'cover-img-container'
                                            } ${hasAtLeastOneWindowUploaded && 'position-relative z-index-1'}`}
                                        >
                                            {imageLogoPreviewUrl ? (
                                                <img
                                                    draggable="false"
                                                    onDragStart={preventDefaultDrag}
                                                    src={imageLogoPreviewUrl}
                                                    alt="preview"
                                                />
                                            ) : (
                                                <>
                                                    {hasAtLeastOneWindowUploaded && lobby && (
                                                        <div className={'small-preview-window-view left-side-position'}>
                                                            <img
                                                                alt="left window"
                                                                className={'left-window-preview'}
                                                                src={getWindowView('left', event)}
                                                            />
                                                        </div>
                                                    )}
                                                    <img
                                                        src={filesUrl + (image.resize1080 ? image.resize1080 : image)}
                                                        alt=""
                                                        className={`${hasPreview && 'cursor-pointer'} ${
                                                            hasAtLeastOneWindowUploaded && 'position-absolute'
                                                        }`}
                                                        onClick={hasPreview && this.handlePreviewOpen}
                                                        onLoad={this.checkBannerDimensions}
                                                    />
                                                    {hasAtLeastOneWindowUploaded && lobby && (
                                                        <div
                                                            className={'small-preview-window-view right-side-position'}
                                                        >
                                                            <img
                                                                alt="right window"
                                                                className={'right-window-preview'}
                                                                src={getWindowView('right', event)}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            className={`current-banner-container ${
                                                dragDropOrientation === 'portrait'
                                                    ? 'cover-img-container-portrait'
                                                    : 'cover-img-container'
                                            }`}
                                        >
                                            {imageLogoPreviewUrl ? (
                                                <img
                                                    draggable="false"
                                                    onDragStart={preventDefaultDrag}
                                                    src={imageLogoPreviewUrl}
                                                    alt="preview"
                                                />
                                            ) : (
                                                <label htmlFor={this.props.for} className="upload-label">
                                                    <PublishIcon />
                                                    <span>Click here to upload</span>
                                                </label>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <Grid item xs={12}>
                                    <input
                                        id={this.props.for}
                                        type="file"
                                        onChange={this.handleLogoChange}
                                        className="upload-image-button d-none"
                                    />
                                </Grid>
                            </div>
                            {dragDropOrientation === 'portrait' ? (
                                <div onDragStart={preventDefaultDrag} className="input-description-portrait">
                                    {description ? (
                                        <p>{description}</p>
                                    ) : (
                                        <p>
                                            Upload an image that will be displayed as a background for the main screen
                                            of landing page. Suggested 270*768 px resolution for better image quality.
                                        </p>
                                    )}
                                    {subLabel && (
                                        <span onDragStart={preventDefaultDrag} draggable="false" className="sublabel">
                                            {subLabel}
                                        </span>
                                    )}
                                    <p onDragStart={preventDefaultDrag} className="image-status-container">
                                        <span>
                                            {newLogoFile || (imageLogoErrorText.length > 0 && image) ? (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="form-upload-picture"
                                                >
                                                    Uploaded:{' '}
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="secondary-color"
                                                    >
                                                        {imageLogoErrorText.length > 0 ? (
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="error-light"
                                                            >
                                                                error
                                                            </span>
                                                        ) : (
                                                            newLogoFile.name
                                                        )}
                                                    </span>
                                                </span>
                                            ) : null}
                                            {!newLogoFile && !image ? (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="form-upload-picture"
                                                >
                                                    Uploaded:{' '}
                                                    {imageLogoErrorText.length > 0 ? (
                                                        <span
                                                            onDragStart={preventDefaultDrag}
                                                            draggable="false"
                                                            className="error-light"
                                                        >
                                                            error
                                                        </span>
                                                    ) : (
                                                        <span
                                                            onDragStart={preventDefaultDrag}
                                                            draggable="false"
                                                            className="grey-color"
                                                        >
                                                            no image
                                                        </span>
                                                    )}
                                                </span>
                                            ) : null}
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="error-message"
                                            >
                                                {imageLogoErrorText}
                                            </span>
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="error-message"
                                            >
                                                {imageLogoWarningText}
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {dragDropOrientation === 'portrait' ? null : (
                        <div onDragStart={preventDefaultDrag} className="input-description">
                            {description ? (
                                <>
                                    <span>{description}</span>{' '}
                                    {email ? (
                                        <a className="link-focus" href={`mailto:${email}`}>
                                            {email}
                                        </a>
                                    ) : null}
                                </>
                            ) : (
                                <p>
                                    Upload an image that will be displayed as a background for the main screen of
                                    landing page. Suggested 270*768 px resolution for better image quality.
                                </p>
                            )}
                            {subLabel && (
                                <span onDragStart={preventDefaultDrag} draggable="false" className="sublabel">
                                    {subLabel}
                                </span>
                            )}
                            <p onDragStart={preventDefaultDrag} className="image-status-container">
                                <span>
                                    {newLogoFile || (imageLogoErrorText.length > 0 && image) ? (
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="form-upload-picture"
                                        >
                                            Uploaded:{' '}
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="secondary-color"
                                            >
                                                {imageLogoErrorText.length > 0 ? (
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="error-light"
                                                    >
                                                        error
                                                    </span>
                                                ) : (
                                                    newLogoFile.name
                                                )}
                                            </span>
                                        </span>
                                    ) : null}
                                    {!newLogoFile && !image ? (
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="form-upload-picture"
                                        >
                                            Uploaded:{' '}
                                            {imageLogoErrorText.length > 0 ? (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="error-light"
                                                >
                                                    error
                                                </span>
                                            ) : (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="grey-color"
                                                >
                                                    no image
                                                </span>
                                            )}
                                        </span>
                                    ) : null}
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="error-message">
                                        {imageLogoErrorText}
                                    </span>
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="error-message">
                                        {imageLogoWarningText}
                                    </span>
                                </span>
                            </p>
                        </div>
                    )}
                </div>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        isLoading={updateLogoLoading}
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.updateLogo}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
                <Dialog
                    fullScreen
                    disableRestoreFocus
                    open={isPreviewOpen}
                    onClose={this.handlePreviewClose}
                    classes={{paperFullScreen: 'img-review-dialog'}}
                >
                    {lobby ? (
                        <div className="position-relative full-lobby-preview">
                            {hasAtLeastOneWindowUploaded && (
                                <div className={'full-preview-left'}>
                                    <img
                                        alt="left window"
                                        className={'position-relative'}
                                        src={getWindowView('left', event)}
                                    />
                                </div>
                            )}

                            <img
                                className={`${hasAtLeastOneWindowUploaded && 'position-absolute'}`}
                                src={filesUrl + (image && image)}
                                alt="Custom design"
                                onLoad={this.checkBannerDimensions}
                            />

                            {hasAtLeastOneWindowUploaded && (
                                <div className={'full-preview-right'}>
                                    <img
                                        alt="right window"
                                        className={'position-relative'}
                                        src={getWindowView('right', event)}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <img
                            src={filesUrl + (image && image)}
                            alt="Custom design"
                            onLoad={this.checkBannerDimensions}
                        />
                    )}

                    <DialogActions className="sm-popup-close-container">
                        <Button
                            classes={{label: 'sm-close-btn-label'}}
                            className="sm-popup-close-btn"
                            disableTouchRipple
                            disableFocusRipple
                            disableRipple
                            type="button"
                            onClick={this.handlePreviewClose}
                        >
                            <Close fill={colors.white} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
        reloadEventData: (eventId) => dispatch(actions.reloadEventData(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandingBackgroundImage);
