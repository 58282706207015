import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import '../../CSS/liveSession.scss';
import {withRouter} from 'react-router-dom';

import {
    connectToSession,
    disconnectFromSession,
    sessionChanged,
    sessionUserApproved,
    sessionUserCreated,
    sessionUserKicked,
    sessionUserMuted,
    sessionUserPermissionUpdated,
    sessionUserSharingDisabled,
    sessionUserSharingEnabled,
    sessionUserUnmuted,
    sessionUserVideoDisabled,
    sessionUserVideoEnabled,
    deregisterSessionUserCreated,
    sessionUserLeft,
    sessionUserReady,
    invitationAccepted,
    invitationDeclined,
    deregisterSessionUserApproved,
    deregisterSessionUserReady,
    deregisterSessionUserKicked,
    deregisterSessionUserLeft,
    sessionCanRequestMicrophone,
} from '../../Api/socketApi';
import SessionLinks from './SessionLinks';
import SessionUsers from './SessionUsers';
import Spinner from '../../SmallLayoutComponents/Spinner';
import UserVideoStream from './UserVideoStream';
import SessionChat from './SessionChat/SessionChat';
import PersonalStreamControls from './PersonalStreamControls';
import {NotificationManager} from 'react-notifications';
import {Button, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import DownloadRecords from './DownloadRecords';
import Confirm from '../../Dialogs/Confirm';
import NotFound from '../NotFound';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import {isMobile} from 'react-device-detect';
import PlatformNotAvailable from '../../SmallLayoutComponents/PlatformNotAvailable';
import LiveSessionSidebar from './LiveSessionSidebar';
import SessionSettings from './SessionSettings';
import './LiveSessionStyles.scss';
class LiveSession extends Component {
    state = {
        processingRequest: false,
        confirmStartStreamingDialog: false,
        confirmStopStreamingDialog: false,
        activeTab: 'sessionLinks',
    };

    componentDidMount() {
        // if the user is a sessionGuest, set the properMenu
        if (!this.props.userIsLoggedIn && !this.props.eventData) {
            this.props.setSessionGuestMenu();
        } else {
            this.props.setEventProtectedMenu();
        }

        const sessionId = this.props.match.params.sessionId;

        // connect the user to session socket events
        this.handleConnectToSession();

        // get liveSessionUser data from the server
        this.props.getLiveSessionUser(sessionId);

        // get liveSession only if we allready have the liveSessionUser data
        // need it when a speaker is Redirected from the register page for example
        // SHOULD NOT BE REQUIRED ANYMORE because from liveSessionRegistration component I use window.reload not the internal react-router
        if (this.props.liveSessionUser) {
            this.props.getLiveSession(sessionId);
        }

        sessionChanged((session) => {
            this.props.getLiveSessionSuccess(session);
        });

        sessionUserCreated((data) => {
            const {translation, defaultTranslation} = this.props;
            const sessionData = data.session;
            const newCreatedUser = data.session.users.find((user) => user.userId === data.userId);
            this.props.getLiveSessionSuccess(sessionData);
            // only if the current liveSessionUser is a moderator we will open the react-notification
            const canModerate = this.checkCanModerate();
            if (canModerate && sessionData._id === this.props.match.params.sessionId) {
                NotificationManager.info(
                    `${newCreatedUser.name} ${
                        translation?.sessions.isTryingToRegister || defaultTranslation?.sessions.isTryingToRegister
                    } ${
                        newCreatedUser.role === 'speaker'
                            ? `${translation?.sessions.speakerSingular || defaultTranslation?.sessions.speakerSingular}`
                            : `${
                                  translation?.sessions.moderatorSingular ||
                                  defaultTranslation?.sessions.moderatorSingular
                              }`
                    }`
                );
            }
        });

        sessionUserApproved((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserReady((data) => {
            const {translation, defaultTranslation} = this.props;
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
            const newCreatedUser = data.session.users.find((user) => user.userId === data.userId);
            const canModerate = this.checkCanModerate();
            if (canModerate && sessionData._id === this.props.match.params.sessionId) {
                NotificationManager.info(
                    `${newCreatedUser.name} ${translation?.sessions.isReady || defaultTranslation?.sessions.isReady}`
                );
            }
        });

        invitationAccepted((data) => {
            const {translation, defaultTranslation} = this.props;
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
            const updatedUser = data.session.users.find((user) => user.userId === data.userId);
            const canModerate = this.checkCanModerate();
            if (canModerate && sessionData._id === this.props.match.params.sessionId) {
                NotificationManager.info(
                    `${updatedUser.name} ${
                        translation?.sessions.invitationAccepted || defaultTranslation?.sessions.invitationAccepted
                    }`
                );
            }
        });

        invitationDeclined((data) => {
            const {translation, defaultTranslation} = this.props;
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);

            // only if the current liveSessionUser is a moderator we will open the react-notification
            const canModerate = this.checkCanModerate();
            if (canModerate && sessionData._id === this.props.match.params.sessionId) {
                NotificationManager.info(
                    `${data.userName} ${
                        translation?.sessions.declinedInvitation || defaultTranslation?.sessions.declinedInvitation
                    }`
                );
            }
        });

        sessionUserKicked((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserLeft((data) => {
            const {translation, defaultTranslation} = this.props;
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);

            // only if the current liveSessionUser is a moderator we will open the react-notification
            const canModerate = this.checkCanModerate();
            if (canModerate && sessionData._id === this.props.match.params.sessionId) {
                NotificationManager.info(
                    `${data.userName} ${
                        translation?.sessions.hasLeftTheSession || defaultTranslation?.sessions.hasLeftTheSession
                    }`
                );
            }
        });

        // triggered whenever a moderator toggles a speaker permission to stream audio/video/screenShare
        sessionUserPermissionUpdated((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserMuted((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserUnmuted((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserVideoEnabled((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserVideoDisabled((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserSharingEnabled((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });
        sessionUserSharingDisabled((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionCanRequestMicrophone((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionCanRequestMicrophone((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const sessionId = this.props.match.params.sessionId;

        // we got the session user
        if (prevProps.liveSessionUser?.userId !== this.props.liveSessionUser?.userId) {
            this.props.getLiveSession(sessionId);
        }
        if (prevProps?.liveSession?._id !== this.props?.liveSession?._id && this.props.liveSession) {
            // check if user is organizer and set the profile data
            if (this.props.liveSession.videoWall) {
                const wallData = {
                    wallId: this.props.liveSession.videoWall,
                    highlightUsers: [],
                };

                this.props.setActiveWall(wallData);
            }
        }

        // fix for when organizer refreshes the page
        if (prevProps.eventRoles.isOrganizer === false && this.props.eventRoles.isOrganizer) {
            this.handleConnectToSession();
        }
    }

    componentWillUnmount() {
        // disconnect the user from session socket events
        this.handleDisconnectFromSession();

        // reset the sessionData
        this.props.getLiveSessionSuccess(null);

        deregisterSessionUserCreated();
        deregisterSessionUserApproved();
        deregisterSessionUserReady();
        deregisterSessionUserKicked();
        deregisterSessionUserLeft();
    }

    handleConnectToSession = () => {
        const sessionId = this.props.match.params.sessionId;
        const {eventRoles} = this.props;
        let sessionToken = localStorage.sessionToken;

        // if we have userData, it means that the user is the organizer and we will use the normal userToken
        // this will not work when the organizer refreshes the liveSession dashboar page
        // but I fixed this is componentDidUpdate
        if (eventRoles.isOrganizer) {
            sessionToken = localStorage.token;
        }
        connectToSession(sessionId, sessionToken);
    };

    handleDisconnectFromSession = () => {
        const sessionId = this.props.match.params.sessionId;
        const {eventRoles} = this.props;
        let sessionToken = localStorage.sessionToken;
        // if we have userData, it means that the user is the organizer and we will use the normal userToken
        if (eventRoles.isOrganizer) {
            sessionToken = localStorage.token;
        }
        disconnectFromSession(sessionId, sessionToken);
    };

    handleOpenStartStreamingDialog = () => {
        this.setState({
            confirmStartStreamingDialog: true,
        });
    };

    handleCloseStartStreamingDialog = () => {
        this.setState({
            confirmStartStreamingDialog: false,
        });
    };

    handleOpenStopStreamingDialog = () => {
        this.setState({
            confirmStopStreamingDialog: true,
        });
    };

    handleCloseStopStreamingDialog = () => {
        this.setState({
            confirmStopStreamingDialog: false,
        });
    };

    startStreaming = () => {
        const {liveSession} = this.props;
        const sessionId = liveSession._id;
        this.setState({
            processingRequest: true,
        });
        this.props.startLiveSessionStream(sessionId).then(() => {
            this.setState({
                processingRequest: false,
                confirmStartStreamingDialog: false,
            });
        });
    };

    stopStreaming = () => {
        const {liveSession} = this.props;
        const sessionId = liveSession._id;
        this.setState({
            processingRequest: true,
        });
        this.props.stopLiveSessionStream(sessionId).then(() => {
            this.setState({
                processingRequest: false,
                confirmStopStreamingDialog: false,
            });
        });
    };

    checkCanModerate = () => {
        const {liveSessionUser} = this.props;
        const canModerate = liveSessionUser.role === 'organizer' || liveSessionUser.role === 'moderator';
        return canModerate;
    };

    handleRedirect = () => {
        const eventSlug = this.props.match.params.eventSlug;

        // redirect the user to the eventHomepage
        const redirectLink = `${window.location.origin}/event/${eventSlug}`;
        window.location.href = redirectLink;
    };

    setActiveTab = (tab) => (e) => {
        this.setState({
            activeTab: tab,
        });
    };

    render() {
        const {processingRequest, confirmStartStreamingDialog, confirmStopStreamingDialog, activeTab} = this.state;
        const {liveSession, liveSessionUser, liveSessionActiveUsers, eventData, translation, defaultTranslation} =
            this.props;

        // display a message if the user is trying to access the sessionDashboard from mobile
        if (isMobile) {
            return (
                <PlatformNotAvailable
                    classes={'private-page'}
                    message={`The ${process.env.REACT_APP_PLATFORM_NAME} Live Session Backstage is not currently optimized for the device you are using. Please access the Live Session Backstage from a Computer`}
                />
            );
        }

        if (!liveSession || !liveSessionUser) {
            return <Spinner />;
        }

        if (liveSessionUser.kicked) {
            return (
                <Dialog open={true}>
                    <DialogContent>
                        <h3>You were removed from the Live Session</h3>
                        <p>
                            We are sorry, but you were removed from this Live Session by one of the Moderators. For more
                            details, please contact the Event Organizer
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRedirect}>OK</Button>
                    </DialogActions>
                </Dialog>
            );
        }

        const canModerate = this.checkCanModerate();
        const sessionHasEnded = liveSession.records.length > 0 && !liveSession.isActive;

        // return not found page if the current event
        // doesn't have access to sessions functionality from master admin dashboard
        // TODO: I need to always know the !eventData?.sessionsStreaming, related to this link https://trello.com/c/i3tPJh38/5254-bug-se-ma-access-to-sessions-tab-with-link
        // fix so that I won't always display the NotFound page to speakers and moderators that don't have access to the get Event api call
        if (eventData && !eventData?.sessionsStreaming) {
            return <NotFound />;
        }

        return (
            <div className="live-session-dashboard-page">
                <div className="live-session-dashboard-container">
                    <div className="column">
                        <SessionUsers
                            toggleAudio={this.toggleAudio}
                            toggleVideo={this.toggleVideo}
                            toggleScreenShare={this.toggleScreenShare}
                        />
                    </div>
                    <div className="column live-session-video-streaming-container">
                        <div className="module streams-container">
                            <div className="module-container">
                                <div className="module-header">
                                    <span className={'module-title'}>{liveSession?.name}</span>
                                </div>
                                <div className="aspect-ratio-wrapper">
                                    <div className="aspect-ratio-container">
                                        <div className="video-player-container">
                                            {liveSession.isActive && (
                                                <div className={'is-live-info'}>
                                                    <div className="red-circle" />
                                                    <p>
                                                        {translation?.sessions.rec || defaultTranslation?.sessions.rec}
                                                    </p>
                                                </div>
                                            )}

                                            {processingRequest && <Spinner />}

                                            {liveSessionActiveUsers.map((activeUser, activeUserIndex) => {
                                                return (
                                                    <UserVideoStream
                                                        key={activeUser._id}
                                                        activeUser={activeUser}
                                                        activeUserIndex={activeUserIndex}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <PersonalStreamControls />
                            </div>
                            {!sessionHasEnded && canModerate && (
                                <div className={`timeslot-stream-controls`}>
                                    {liveSession.isActive ? (
                                        <Button
                                            onClick={this.handleOpenStopStreamingDialog}
                                            disabled={processingRequest}
                                            variant={"text"}
                                            startIcon={<StopIcon />}
                                        >
                                            {translation?.sessions.stopStream ||
                                                defaultTranslation?.sessions.stopStream}
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={this.handleOpenStartStreamingDialog}
                                            disabled={processingRequest}
                                            variant={"text"}
                                            startIcon={<PlayArrowIcon />}
                                        >
                                            {translation?.sessions.startStream ||
                                                defaultTranslation?.sessions.startStream}
                                        </Button>
                                    )}
                                </div>
                            )}
                            {sessionHasEnded && liveSessionUser.role === 'organizer' && (
                                <span className={`session-has-ended-message`}>
                                    {translation?.sessions.sessionHasEnded ||
                                        defaultTranslation?.sessions.sessionHasEnded}
                                    .
                                    <br />
                                    <DownloadRecords />
                                </span>
                            )}
                        </div>

                        {canModerate && (
                            <div className="module session-settings">
                                <div className="module-container">
                                    <div className="module-header">
                                        <p
                                            className={`tab ${activeTab === 'sessionLinks' ? '-active' : ''}`}
                                            onClick={this.setActiveTab('sessionLinks')}
                                        >
                                            {translation?.sessions.sessionLinks ||
                                                defaultTranslation?.sessions.sessionLinks}
                                        </p>
                                        <p
                                            className={`tab ${activeTab === 'sessionSettings' ? '-active' : ''}`}
                                            onClick={this.setActiveTab('sessionSettings')}
                                        >
                                            {translation?.sessions.sessionSettings ||
                                                defaultTranslation?.sessions.sessionSettings}
                                        </p>
                                    </div>
                                    {activeTab === 'sessionLinks' && (
                                        <SessionLinks
                                            translation={translation}
                                            defaultTranslation={defaultTranslation}
                                        />
                                    )}

                                    {activeTab === 'sessionSettings' && (
                                        <SessionSettings
                                            translation={translation}
                                            defaultTranslation={defaultTranslation}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="column session-chat-container">
                        {liveSession?.chat._id ? (
                            <SessionChat />
                        ) : (
                            <p>
                                {translation?.sessions.noAvailableChat || defaultTranslation?.sessions.noAvailableChat}
                            </p>
                        )}
                    </div>

                    {liveSession?.videoWall && <LiveSessionSidebar />}
                </div>
                {confirmStartStreamingDialog && (
                    <Confirm
                        open={confirmStartStreamingDialog}
                        closeConfirm={this.handleCloseStartStreamingDialog}
                        dialogTitle={
                            translation?.sessions.startLiveSessionStreamTitle ||
                            defaultTranslation?.sessions.startLiveSessionStreamTitle
                        }
                        dialogDescription={
                            translation?.sessions.startLiveSessionDescription ||
                            defaultTranslation?.sessions.startLiveSessionDescription
                        }
                        dialogConfirmButtonLabel={
                            translation?.generalText.start || defaultTranslation?.generalText.start
                        }
                        dialogCancelButtonLabel={
                            translation?.generalText.cancel || defaultTranslation?.generalText.cancel
                        }
                        handleConfirm={this.startStreaming}
                    />
                )}
                {confirmStopStreamingDialog && (
                    <Confirm
                        open={confirmStopStreamingDialog}
                        closeConfirm={this.handleCloseStopStreamingDialog}
                        dialogTitle={
                            translation?.sessions.stopLiveSessionStreamTitle ||
                            defaultTranslation?.sessions.stopLiveSessionStreamTitle
                        }
                        dialogDescription={
                            translation?.sessions.stopLiveSessionStreamDescription ||
                            defaultTranslation?.sessions.stopLiveSessionStreamDescription
                        }
                        dialogConfirmButtonLabel={translation?.generalText.stop || defaultTranslation?.generalText.stop}
                        dialogCancelButtonLabel={
                            translation?.generalText.cancel || defaultTranslation?.generalText.cancel
                        }
                        handleConfirm={this.stopStreaming}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        liveSessionActiveUsers: state.liveSession.liveSessionActiveUsers,
        userData: state.user.data,
        eventRoles: state.user.eventRoles,
        userIsLoggedIn: state.user.loggedIn,
        eventData: state.event.data,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveSession: (sessionId) => dispatch(actions.getLiveSession(sessionId)),
        getLiveSessionSuccess: (session) => dispatch(actions.getLiveSessionSuccess(session)),
        getLiveSessionUser: (sessionId) => dispatch(actions.getLiveSessionUser(sessionId)),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        startLiveSessionStream: (sessionId) => dispatch(actions.startLiveSessionStream(sessionId)),
        stopLiveSessionStream: (sessionId) => dispatch(actions.stopLiveSessionStream(sessionId)),
        setLiveSessionIsActive: (isActive) => dispatch(actions.setLiveSessionIsActive(isActive)),
        setLiveSessionRecords: (records) => dispatch(actions.setLiveSessionRecords(records)),
        setSessionGuestMenu: () => dispatch(actions.setSessionGuestMenu()),
        setEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveSession));
