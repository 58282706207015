import React from 'react';
import {ReactComponent as MainLogoImageWhite} from '../Images/svg/logo_mobile_white.svg';
import {ReactComponent as MainLogoImagePurple} from '../Images/svg/logo_mobile_purple.svg';

const MainLogoMobile = (props) => {
    const {logInTab} = props;
    if (logInTab) {
        return <MainLogoImageWhite />;
    }
    return <MainLogoImagePurple />;
};

export default MainLogoMobile;
