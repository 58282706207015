import React from 'react';
import {preventDefaultDrag} from '../../../Utils/utils';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch} from 'react-redux';
import {topNavClose} from '../../../store/actions';

const CloseProgramButton = () => {

    const dispatch = useDispatch();

    const closeProgram = () => {
        dispatch(topNavClose());
    }

    return (
        <div
            onDragStart={preventDefaultDrag}
            className="close action-button"
            onClick={closeProgram}
        >
            <CloseIcon />
        </div>
    );
};

export default CloseProgramButton;