import React, {PureComponent} from 'react';
import SinglePost from './SinglePost';
import {withRouter} from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';

class PostsList extends PureComponent {
    getAlreadyLike = (post) => {
        let allreadyLiked = false;
        if (post.likes.length) {
            allreadyLiked = post.likes.findIndex((like) => like.user === this.props.currentUserId);
            allreadyLiked = allreadyLiked !== -1;
        }
        return allreadyLiked;
    };

    getAlreadyComments = (post) => {
        const {currentUserId} = this.props;
        let allreadyCommented = false;
        if (post.comments.length) {
            allreadyCommented = post.comments.findIndex((comment) => comment.user === currentUserId);
            allreadyCommented = allreadyCommented !== -1;
        }
        return allreadyCommented;
    };

    checkHighlightedPost = (post) => {
        const {sideNavigationDetails} = this.props;
        return sideNavigationDetails.highlightPostsForUsers.includes(post.user);
    };

    render() {
        const {
            posts,
            pinPost,
            unpinPost,
            likePost,
            unlikePost,
            deletePost,
            addComment,
            showActions,
            handleOpenActions,
            handleCloseActions,
            isNotAbleToPost,
            enterPostEditMode,
            postEditMode,
            isActiveModeration,
            hasNotApprovedRights,
            onApprovePost,
            onApprovePostComment,
            focusInput,
        } = this.props;

        return (
            <>
                {posts.map((post) => {
                    return (
                        <SinglePost
                            key={post._id}
                            currentPost={post}
                            userId={post.user}
                            likePost={likePost(post._id)}
                            unlikePost={unlikePost(post._id)}
                            deletePost={deletePost}
                            allreadyLiked={this.getAlreadyLike(post)}
                            allreadyCommented={this.getAlreadyComments(post)}
                            addComment={addComment}
                            hightlighPost={this.checkHighlightedPost(post)}
                            pinPost={pinPost}
                            unpinPost={unpinPost}
                            isPinned={post.isPinned}
                            showActions={showActions}
                            handleOpenActions={handleOpenActions}
                            handleCloseActions={handleCloseActions}
                            isNotAbleToPost={isNotAbleToPost}
                            enterPostEditMode={enterPostEditMode}
                            postEditMode={postEditMode}
                            isActiveModeration={isActiveModeration}
                            hasNotApprovedRights={hasNotApprovedRights}
                            onApprovePost={onApprovePost}
                            onApprovePostComment={onApprovePostComment}
                            numberOfComments={post.comments?.length}
                            focusInput={focusInput}
                        />
                    );
                })}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUserId: state.user.data._id,
        sideNavigationDetails: state.event.sideNavigationDetails,
    };
};

export default withRouter(connect(mapStateToProps)(PostsList));
