import React from "react";


const EventSpeakers = ({speakers, translation, defaultTranslation}) => {
    return (
        <>
            {speakers &&
                <div className='homepage-speakers-container'>
                    <span className={'homepage-speakers-title'}>{translation?.programDropdown.speakers ||
                        defaultTranslation?.programDropdown.speakers}: </span>
                    <span className={'homepage-speakers-names'}>{speakers}</span>
                </div>
            }
        </>
    )
}

export default EventSpeakers;