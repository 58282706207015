import {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {deleteSpeaker, listSpeakers, updateSpeakerStatus, updateSpeakerOrder} from 'store/actions/eventSpeakersActions';
import SpeakerAccordion from './Components/SpeakerAccordion';
import SpeakerForm from './Components/SpeakerForm';
import './HomepageSpeakersStyles.scss';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Button from '@material-ui/core/Button';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import {preventDefaultDrag} from 'Utils/utils';
import {CircularProgress} from '@material-ui/core';

const HomepageSpeakers = () => {
    const dispatch = useDispatch();
    const pageEndRef = useRef();

    //SPEAKERS STORE
    const speakersEvent = useSelector((state) => state.speakers);
    const {
        loading: loadingAllSpeakers,
        addedSpeaker,
        speakerImageSuccess,
        speakerUpdated,
        eventSpeakers,
    } = speakersEvent;

    //CURRENTLY EVENT
    const currentlyEvent = useSelector((state) => state.event);
    const {eventId} = currentlyEvent;

    //STATES
    const [isNewSpeaker, setIsNewSpeaker] = useState(false);
    const [localSpeakers, setLocalSpeakers] = useState([]);
    const [speakerId, setSpeakerId] = useState();

    //DELETE SPEAKER
    const handleDeleteSpeaker = (eventId, speakerId) => async (e) => {
        // dispatch({type: DELETE_EVENT_SPEAKER_IMAGE_RESET});
        if (speakerId === 0) {
            setIsNewSpeaker(false);
        } else {
            dispatch(deleteSpeaker(eventId, speakerId));
        }
        setLocalSpeakers(localSpeakers.filter((item) => item._id !== speakerId));
    };

    //SET ACTIVE SPEAKER FOR THE CARD
    const activeSpeaker = (userId) => (e) => {
        setSpeakerId(speakerId === userId ? null : userId);
    };
    //CLOSE ALL CARDS
    const closeAllAccordions = (_) => {
        setSpeakerId(null);
    };

    //IF THERE IS NO SPEAKER, ALERT.
    const emptyListWarning = (_) => {
        if (eventSpeakers?.data?.length === 0) {
            if (!isNewSpeaker) {
                return (
                    <div className="no-item">
                        <h3>You currently don't have any speakers added</h3>
                    </div>
                );
            } else {
                return;
            }
        }
    };

    //ADD NEW SPEAKER
    const handleNewSpeaker = (e) => {
        setSpeakerId(0);
        setIsNewSpeaker(true);
        pageEndRef.current.scrollIntoView({behavior: 'smooth'});
    };

    const closeNewSpeakerDialog = (_) => {
        setIsNewSpeaker(false);
    };

    //DRAG-DROP FEATURE FOR THE SPEAKERS

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the localSpeakers look a bit nicer
        userSelect: 'none',
        margin: `0 0 25px 0`,
        ...draggableStyle,
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const localSpeakersReordered = reorder(localSpeakers, result.source.index, result.destination.index);

        localSpeakersReordered.map((listItem, index) => {
            return dispatch(
                updateSpeakerOrder(eventId, listItem._id, {
                    order: index,
                })
            );
        });

        setLocalSpeakers(
            localSpeakersReordered.map((item, index) => {
                return {...item, order: index};
            })
        );
    };

    const addNewSpeakerToLocalState = (speaker) => {
        return setLocalSpeakers([...localSpeakers, speaker]);
    };
    const updateSpeakerToLocalState = (speakerId, data) => {
        const speakerCurrent = localSpeakers.find((item) => item._id === speakerId);
        const reMap = (_) => {
            return localSpeakers.map((it) => {
                return it._id === speakerCurrent._id ? data : it;
            });
        };
        return setLocalSpeakers(reMap);
    };

    const uploadSpeakerImageLocalState = (speakerId, image) => {
        const speakerCurrent = localSpeakers?.find((item) => item._id === speakerId);
        const reMap = (_) => {
            return localSpeakers.map((it) => {
                return it._id === speakerCurrent?._id ? {...speakerCurrent, image: image} : it;
            });
        };
        return setLocalSpeakers(reMap);
    };
    const removeSpeakerImageLocalState = (speakerId) => {
        const speakerCurrent = localSpeakers?.find((item) => item._id === speakerId);
        const reMap = (_) => {
            return localSpeakers?.map((it) => {
                return it._id === speakerCurrent?._id ? {...speakerCurrent, image: ''} : it;
            });
        };
        return setLocalSpeakers(reMap);
    };

    const changeStatusSpeakerLocalState = (speakerId, showStatus) => {
        const speakerCurrent = localSpeakers?.find((item) => item._id === speakerId);
        const reMap = (_) => {
            return localSpeakers?.map((it) => {
                return it?._id === speakerCurrent?._id ? {...speakerCurrent, hidden: showStatus} : it;
            });
        };
        return setLocalSpeakers(reMap);
    };
    //END- DRAG-DROP FEATURE FOR THE SPEAKERS

    useEffect(() => {
        if (eventSpeakers?.data) {
            setLocalSpeakers(eventSpeakers.data);
        }
    }, [eventSpeakers]);

    useEffect(() => {
        dispatch(listSpeakers(eventId));
    }, [eventId]);

    useEffect(() => {
        if (addedSpeaker) {
            setSpeakerId(addedSpeaker?._id);
        }
        if (speakerUpdated) {
            setSpeakerId(speakerUpdated?._id);
        }
    }, [dispatch, eventId, speakerImageSuccess, addedSpeaker, speakerUpdated]);

    const shouldShowSpeakers = () =>
        eventSpeakers?.success && eventSpeakers.data && localSpeakers && localSpeakers.length > 0;
    return (
        <>
            <div className="main-speakers">
                <div className="speaker-headline">
                    <h4 className="advanced-options-title">SPEAKERS</h4>
                    <Button onClick={handleNewSpeaker} startIcon={<AddOutlinedIcon />}>
                        ADD SPEAKER
                    </Button>
                </div>
                <div className="speaker-list">
                    {loadingAllSpeakers ? (
                        <div className={'advanced-options-container d-flex align-items-center justify-content-center'}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : shouldShowSpeakers() ? (
                        <>
                            {' '}
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {localSpeakers &&
                                                localSpeakers.map((speaker, index) => (
                                                    <Draggable
                                                        key={speaker._id}
                                                        draggableId={speaker._id}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                {' '}
                                                                <SpeakerAccordion
                                                                    key={index}
                                                                    changeStatusSpeakerLocalState={
                                                                        changeStatusSpeakerLocalState
                                                                    }
                                                                    provided={provided}
                                                                    speakerId={speakerId}
                                                                    eventId={eventId}
                                                                    speaker={speaker}
                                                                    closeAllAccordions={closeAllAccordions}
                                                                    isExpanded={speakerId === speaker._id}
                                                                    activeSpeaker={activeSpeaker(speaker._id)}
                                                                    handleDeleteSpeaker={handleDeleteSpeaker(
                                                                        eventId,
                                                                        speaker._id
                                                                    )}
                                                                    updateSpeakerStatus={updateSpeakerStatus}
                                                                >
                                                                    <SpeakerForm
                                                                        speaker={speaker}
                                                                        totalSpeakers={localSpeakers.length}
                                                                        eventId={eventId}
                                                                        updateSpeakerToLocalState={
                                                                            updateSpeakerToLocalState
                                                                        }
                                                                        activeSpeaker={activeSpeaker}
                                                                        closeNewSpeakerDialog={closeNewSpeakerDialog}
                                                                        uploadSpeakerImageLocalState={
                                                                            uploadSpeakerImageLocalState
                                                                        }
                                                                        removeSpeakerImageLocalState={
                                                                            removeSpeakerImageLocalState
                                                                        }
                                                                    />
                                                                </SpeakerAccordion>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </>
                    ) : (
                        emptyListWarning()
                    )}
                    {isNewSpeaker ? (
                        <SpeakerAccordion
                            eventId={eventId}
                            isExpanded={speakerId === 0 ? true : false}
                            activeSpeaker={activeSpeaker(0)}
                            handleDeleteSpeaker={handleDeleteSpeaker(eventId, 0)}
                            closeAllAccordions={closeAllAccordions}
                            changeStatusSpeakerLocalState={changeStatusSpeakerLocalState}
                        >
                            <SpeakerForm
                                eventId={eventId}
                                totalSpeakers={localSpeakers.length}
                                addNewSpeakerToLocalState={addNewSpeakerToLocalState}
                                activeSpeaker={activeSpeaker}
                                closeNewSpeakerDialog={closeNewSpeakerDialog}
                            />
                        </SpeakerAccordion>
                    ) : null}
                </div>
                <div onDragStart={preventDefaultDrag} className="actions-container">
                    <Button onClick={handleNewSpeaker} startIcon={<AddOutlinedIcon />}>
                        ADD SPEAKER
                    </Button>
                </div>
            </div>

            <div ref={pageEndRef} />
        </>
    );
};

export default HomepageSpeakers;
