import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BackupIcon from '@material-ui/icons/Backup';
import CloseIcon from '@material-ui/icons/Close';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import PublishIcon from '@material-ui/icons/Publish';
import {deleteLiveSessionThumbnail, updateLiveSessionThumbnail} from '../../../store/actions';
import {verifyFileType} from 'Utils/verifyFileType';
import './thumbnailImage.scss';
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const ThumbnailImage = (props) => {
    const dispatch = useDispatch();
    const [newImageFile, setNewImageFile] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [imageError, setImageError] = useState('');
    const [thumbnailDialogOpened, setThumbnailDialogOpened] = useState(false);
    const translation = props.translation;
    const defaultTranslation = props.defaultTranslation;

    const session = useSelector((state) => state.liveSession.liveSession);
    const routeParams = useParams();
    const {sessionId} = routeParams;

    useEffect(() => {
        if (session?.thumbnail) {
            setImagePreviewUrl(`${process.env.REACT_APP_SESSION_FOLDER}${session.thumbnail}`);
            setNewImageFile('');
        } else {
            setImagePreviewUrl('');
            setNewImageFile('');
        }
        return () => {
            setImageError('');
        };
    }, [session]);

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;
        if (!file) {
            return;
        }

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        const typeValid = verifyFileType(file.type, 'image');
        if (file) {
            if (!isValid) {
                // setImagePreviewUrl(null);
                setImageError(translation?.errors.fileTooLarge || defaultTranslation?.errors.fileTooLarge);
            }
            if (!typeValid) {
                // setImagePreviewUrl(null);
                setImageError(
                    `${
                        translation?.errors.imageFileNotSupported || defaultTranslation?.errors.imageFileNotSupported
                    } jpeg, jpg, jfif, gif or png.`
                );
            }
            isValid = typeValid && isValid;

            reader.onloadend = () => {
                if (isValid) {
                    setNewImageFile(file);
                    setImagePreviewUrl(reader.result);
                    setImageError('');
                }
            };

            reader.readAsDataURL(file);
        }
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    const handleSetImage = async () => {
        dispatch(updateLiveSessionThumbnail(session._id, newImageFile));
    };

    const handleDeleteImage = async () => {
        dispatch(deleteLiveSessionThumbnail(session._id));
    };

    const openUploadThumbnailDialog = () => {
        setThumbnailDialogOpened(true);
    };
    const closeUploadThumbnailDialog = () => {
        setThumbnailDialogOpened(false);
        setImageError('');
        if (session?.thumbnail) {
            setImagePreviewUrl(`${process.env.REACT_APP_SESSION_FOLDER}${session.thumbnail}`);
            setNewImageFile('');
        } else {
            setImagePreviewUrl('');
            setNewImageFile('');
        }
      
    };

    return (
        <div className="module-body">
            <div className="module-link_blocks">
                <div className="module-link_block" onClick={openUploadThumbnailDialog}>
                    <span>{translation?.sessions.thumbnailImage || defaultTranslation?.sessions.thumbnailImage}</span>
                    <IconButton>
                        <CloudUploadIcon />
                    </IconButton>
                </div>
            </div>
            <Dialog
                open={thumbnailDialogOpened}
                onClose={closeUploadThumbnailDialog}
                className={'session-thumbnail-dialog'}
            >
                <DialogTitle>
                    {translation?.sessions.uploadThumbnail || defaultTranslation?.sessions.uploadThumbnail}
                    <IconButton>
                        <CloseIcon onClick={closeUploadThumbnailDialog} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <input
                        id={`thumbnailImage${sessionId}`}
                        type="file"
                        className="upload-image-button d-none"
                        onChange={handleImageChange}
                    />
                    <div className="image-container">
                        {!imagePreviewUrl && (
                            <label className="upload-label" htmlFor={`thumbnailImage${sessionId}`}>
                                <PublishIcon />
                                <span>
                                    {translation?.sessions.clickToUpload || defaultTranslation?.sessions.clickToUpload}
                                </span>
                            </label>
                        )}
                        {imagePreviewUrl && (
                            <label htmlFor={`thumbnailImage${sessionId}`}>
                                <img
                                    draggable="false"
                                    onDragStart={(e) => e.preventDefault()}
                                    src={imagePreviewUrl}
                                    alt="preview"
                                />
                            </label>
                        )}
                    </div>
                    {imageError && <p className="error-text error-max-w">{imageError}</p>}
                </DialogContent>
                <DialogActions>
                    <div className="buttons-container">
                        {session?.thumbnail && !newImageFile ? (
                            <Button
                                onClick={handleDeleteImage}
                                disabled={!session?.thumbnail}
                                variant={"contained"}
                                disableElevation
                                color={'primary'}
                                disableRipple={false}
                            >
                                {translation?.generalText.remove || defaultTranslation?.generalText.remove}
                            </Button>
                        ) : (
                            <Button
                                onClick={handleSetImage}
                                disabled={!!(!imagePreviewUrl || imageError)}
                                variant={"contained"}
                                disableElevation
                                color={"secondary"}
                            >
                                {translation?.generalText.save || defaultTranslation?.generalText.save}
                            </Button>
                        )}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ThumbnailImage;
