import {isMobile} from 'react-device-detect';

const EmailChangeHeader = ({...props}) => {
    return (
        <div className={'email-change-header'}>
            {!isMobile && <h2>{props.title}</h2>}

            <p>{props.description}</p>
        </div>
    );
};

export default EmailChangeHeader;
