import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {preventDefaultDrag, urlifyString} from '../../Utils/utils';
import axios from '../../store/axios-instance';
import InputBase from '@material-ui/core/InputBase';
import MenuActionsPost from './MenuActionsPost';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SMPopup from '../../Dialogs/SMPopup';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as LikeIcon} from '../../Images/svg/like.svg';
import {ReactComponent as LikedIcon} from '../../Images/svg/like_active.svg';
import {ReactComponent as Plus} from '../../Images/svg/plus.svg';
import {ReactComponent as CommentsIcon} from '../../Images/svg/comments.svg';
import {ReactComponent as CommentsIconActive} from '../../Images/svg/comment_active.svg';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {ReactComponent as Share} from '../../Images/svg/sm-share-full.svg';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '../../Images/svg/WarningIcon';
import SinglePostComment from './SinglePostComment';
import TimeAgo from './TimeAgo';
import Spinner from '../../SmallLayoutComponents/Spinner';
import Button from '@material-ui/core/Button';

class SinglePost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            sharedPost: '',
            editPostId: '',
            editCommentId: '',
            sharePopup: false,
            showComments: false,
            commentEditMode: false,
            loadingCommentApiCall: false,
        };

        this.inputRef = React.createRef();
        this.newCommentRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    componentWillUnmount() {
        const eventPage = document.getElementsByClassName('event-page hide-new-comment');
        if (eventPage.length !== 0) {
            eventPage[0].classList.remove('hide-new-comment');
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
            maxStringLengthError: event.target.value.length > 1200,
        });
    };

    onAddComment = async () => {
        // we don't want to make duplicate api calls for adding a comment
        const {loadingCommentApiCall} = this.state;
        if (loadingCommentApiCall) return;

        const {activeWallId, eventId, currentPost} = this.props;
        if (this.state.comment.trim().length) {
            //this.setState({loadingCommentApiCall: true}, () => this.setState({loadingCommentApiCall: true}));
            this.setState({loadingCommentApiCall: true});
            if (this.state.commentEditMode) {
                axios({
                    method: 'put',
                    url: `/event/${eventId}/live-wall/${activeWallId}/post/${this.state.editPostId}/comment/${this.state.editCommentId}`,
                    data: {text: this.state.comment},
                })
                    .then(() =>
                        this.setState(
                            {
                                commentEditMode: false,
                                comment: '',
                            },
                            () => {
                                this.inputRef.current.blur();
                                this.setState({loadingCommentApiCall: false});
                            }
                        )
                    )
                    .catch(() => {
                        this.setState({loadingCommentApiCall: false});
                    });
            } else {
                await this.props.addComment(this.state.comment, currentPost._id);
                this.setState(
                    {
                        comment: '',
                    },
                    () => {
                        this.inputRef.current.blur();
                        this.setState({loadingCommentApiCall: false});
                    }
                );
            }
        }
    };

    enterCommentEditMode = (postId, commentId, text) => () => {
        if (!this.state.commentEditMode) {
            this.setState({commentEditMode: true, editPostId: postId, editCommentId: commentId, comment: text}, () => {
                this.props.handleCloseActions();
                this.inputRef.current.focus();
            });
        }
    };

    onExitEditMode = () => this.setState({commentEditMode: false, comment: ''}, () => this.inputRef.current.blur());

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!this.state.maxStringLengthError) {
                this.onAddComment();
            }
        }
        if (e.key === 'Escape' && this.state.commentEditMode) {
            e.preventDefault();
            this.onExitEditMode();
        }
    };

    checkCanDeletePost = () => {
        const {user, sideNavigationDetails, isVideoWall, currentPost, eventRoles} = this.props;
        const isOrganizer = eventRoles.isOrganizer;
        const isActiveCoOrganizer = eventRoles.isCoOrganizer && eventRoles.isCoOrganizerActive;

        if (isVideoWall) {
            return user._id === currentPost.user || isOrganizer || isActiveCoOrganizer;
        } else {
            if (sideNavigationDetails.canModerateChat) {
                return (
                    user._id === currentPost.user ||
                    sideNavigationDetails.highlightPostsForUsers.includes(user._id) ||
                    sideNavigationDetails.canModerateChat.includes(user._id) ||
                    isOrganizer ||
                    isActiveCoOrganizer
                );
            } else {
                return (
                    user._id === currentPost.user ||
                    sideNavigationDetails.highlightPostsForUsers.includes(user._id) ||
                    isOrganizer ||
                    isActiveCoOrganizer
                );
            }
        }
    };

    checkCanDeleteComment = (comment) => {
        // if is an organizer or it is your own comment you can delete it
        // return (this.props.userId === comment.user._id || this.props.isOrganizer);

        const {user, sideNavigationDetails, eventRoles} = this.props;
        const isOrganizer = eventRoles.isOrganizer;
        const isActiveCoOrganizer = eventRoles.isCoOrganizer && eventRoles.isCoOrganizerActive;

        if (this.props.sideNavigationDetails.canModerateChat) {
            return (
                user._id === comment.user ||
                sideNavigationDetails.highlightPostsForUsers.includes(user.id) ||
                sideNavigationDetails.canModerateChat.includes(user._id) ||
                isOrganizer ||
                isActiveCoOrganizer
            );
        } else {
            return user._id === comment.user || isOrganizer || isActiveCoOrganizer;
        }
    };

    handleDeleteComment = (commentId) => (e) => {
        const {activeWallId, currentPost, eventId} = this.props;
        this.setState(
            {
                comment: '',
                commentEditMode: false,
            },
            async () => {
                await axios({
                    method: 'delete',
                    url: `/event/${eventId}/live-wall/${activeWallId}/post/${currentPost._id}/comment/${commentId}`,
                })
                    .then(() => {})
                    .catch(() => {});
            }
        );
    };

    checkCanPinPost = () => {
        const {user, exhibitor, sideNavigationDetails, isVideoWall, eventRoles, currentPost} = this.props;
        const isOrganizer = eventRoles.isOrganizer;
        const isActiveCoOrganizer = eventRoles.isCoOrganizer && eventRoles.isCoOrganizerActive;
        const isSpeaker = sideNavigationDetails.currentTimeSlotVideoWallSpeakers.includes(user?._id);

        if (currentPost.approved === false) {
            return false;
        } else if (isOrganizer) {
            // is organizer
            return isOrganizer;
        } else if (isActiveCoOrganizer) {
            // is active co-organizer
            return isActiveCoOrganizer;
        } else if (isVideoWall && isSpeaker) {
            return isSpeaker;
        } else if (sideNavigationDetails.canModerateChat) {
            if (exhibitor?.user === user?._id) {
                // is booth owner
                return eventRoles?.exhibitorId === exhibitor?._id;
            } else if (exhibitor?.booth.presentingRepresentative?.user?._id === user?._id) {
                // is exhibitor representive - PS
                return exhibitor?.booth.presentingRepresentative?.user?._id === user?._id;
            } else if (
                exhibitor?.booth.deskRepresentativeLeft?.user?._id === user?._id ||
                exhibitor?.booth.deskRepresentativeRight?.user?._id === user?._id
            ) {
                // is exhibitor representative - Exhibitor company
                return (
                    exhibitor?.booth.deskRepresentativeLeft?.user?._id === user?._id ||
                    exhibitor?.booth.deskRepresentativeRight?.user?._id === user?._id
                );
            }
        }
    };

    toggleViewComments = () => {
        const {isMobile, isNotAbleToPost} = this.props;
        this.setState({showComments: !this.state.showComments}, () => {
            if (this.state.showComments && !isMobile && !isNotAbleToPost) {
                this.newCommentRef.current.focus();
            }
        });
    };

    handleInputFocus = () => {
        const eventPage = document.getElementsByClassName('event-page');
        if (eventPage) {
            eventPage[0].classList.add('hide-new-comment');
        }

        // move input cursor at the end of string when focusing
        this.inputRef.current.selectionStart = this.inputRef.current.value.length;
        this.inputRef.current.selectionEnd = this.inputRef.current.value.length;

        const isInputFocused = true;
        this.props.focusInput(isInputFocused);
    };

    handleInputBlur = () => {
        const eventPage = document.getElementsByClassName('event-page hide-new-comment');
        if (eventPage.length !== 0) {
            eventPage[0].classList.remove('hide-new-comment');
        }

        const isInputFocused = false;
        this.props.focusInput(isInputFocused);
    };

    getUserData = (userId) => {
        const {allEventUsersObject} = this.props;
        return allEventUsersObject[userId];
    };

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.props.handleCloseActions();
        }
    }

    openSharePopup = (post) => () => {
        const {text} = post;
        this.setState({sharePopup: true, sharedPost: text});
    };

    closeSharePopup = () => this.setState({sharePopup: false});

    characterLimiter = (platform, limit, text) => {
        switch (platform) {
            // Facebook quote limit (02.04.2021): 280
            case 'facebook':
                return `"${text && text.trim().length > limit ? text.slice(0, limit - 100) + '...' : text}"`;
            // Twitter message limit (02.04.2021): 500
            case 'twitter':
                return `"${text && text.trim().length > limit ? text.slice(0, limit - 100) + '...' : text}"`;
            // Email message limit (02.04.2021): 1200 (myOnvent comment limit)
            case 'email':
                return `"${text && text.trim().length > limit ? text.slice(0, limit - 100) + '...' : text}"`;
            default:
                return text;
        }
    };

    decodeHtml = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    handleEnterKey = (type, itemId, data) => (e) => {
        if (e.key === 'Enter') {
            if (type === 'post') {
                if (this.props.showActions === itemId) return this.props.handleCloseActions();
                if (this.props.showActions !== itemId) return this.props.handleOpenActions(itemId)();
            }
            if (type === 'post-share') return this.openSharePopup(data)();
            if (type === 'post-edit') return this.props.enterPostEditMode(itemId, data)();
            if (type === 'post-delete') return this.props.deletePost(itemId)();
            if (type === 'comment') {
                if (this.props.showActions === itemId) return this.props.handleCloseActions();
                if (this.props.showActions !== itemId) return this.props.handleOpenActions(itemId)();
            }
            if (type === 'comment-edit') return this.enterCommentEditMode(itemId.postId, itemId.commentId, data)();
            if (type === 'comment-delete') return this.handleDeleteComment(itemId)();
        }

        if (e.key === 'Escape') {
            if (type === 'post') {
                if (this.props.showActions === itemId) return this.props.handleCloseActions();
            }
            if (type === 'comment') {
                if (this.props.showActions === itemId) return this.props.handleCloseActions();
            }
        }
    };

    getAllPostCommentsModeration = () => {
        const {user, currentPost, hasNotApprovedRights} = this.props;
        let post = currentPost;
        let postComments = post.comments;
        if (hasNotApprovedRights) {
            return postComments.filter(
                (comment) => (comment.approved === true || user?._id === comment.user) && this.getUserData(comment.user)
            );
        } else {
            return postComments.filter((comment) => this.getUserData(comment.user));
        }
    };

    render() {
        const {
            user,
            event,
            currentPost,
            translation,
            defaultTranslation,
            pinPost,
            isPinned,
            unpinPost,
            deletePost,
            showActions,
            handleOpenActions,
            handleCloseActions,
            allreadyCommented,
            isNotAbleToPost,
            userId,
            enterPostEditMode,
            languages,
            postEditMode,
            isActiveModeration,
            hasNotApprovedRights,
            onApprovePost,
            onApprovePostComment,
        } = this.props;
        let post = currentPost;
        const isPostOwner = this.checkCanDeletePost();
        const isAbleToPin = this.checkCanPinPost();
        let {text} = currentPost;
        let postText = urlifyString(text);
        const eventURL = `${window.location.origin}${event.slug ? '/event/' + event.slug : ''}`;

        let currentUser = this.getUserData(userId);
        if (typeof currentUser?.user === 'object') {
            currentUser = currentUser?.user;
        }

        let postComments = this.getAllPostCommentsModeration();
        let postHasUnApprovedComments = postComments.filter((comment) => comment.approved === false);

        return (
            <>
                <div
                    key={post._id}
                    className={`single-post-container ${this.props.hightlighPost ? 'highlight' : ''} ${
                        isPinned ? 'is-pinned' : ''
                    }`}
                >
                    <div onDragStart={preventDefaultDrag} className="post-header">
                        <div
                            className="user-avatar"
                            style={{
                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${currentUser?.avatarSmall}')`,
                            }}
                        />
                        <div onDragStart={preventDefaultDrag} className="user-name">
                            <p className="long-name">
                                {currentUser?.first} {currentUser?.last}
                            </p>
                            <TimeAgo createdAt={post.createdAt} updatedAt={post.updatedAt} />
                        </div>
                        <div className="postActions">
                            {!isNotAbleToPost && (
                                <>
                                    {isAbleToPin ? (
                                        <MenuActionsPost
                                            translation={translation}
                                            defaultTranslation={defaultTranslation}
                                            deletePost={deletePost}
                                            onPinPost={pinPost}
                                            onUnpinPost={unpinPost}
                                            isPinned={isPinned}
                                            postId={post._id}
                                            showActions={showActions}
                                            handleOpenActions={handleOpenActions}
                                            handleCloseActions={handleCloseActions}
                                            isPostOwner={isPostOwner}
                                            currentPost={currentPost}
                                            enterPostEditMode={enterPostEditMode}
                                            postEditMode={postEditMode}
                                        />
                                    ) : (
                                        <>
                                            {post.approved === false ? (
                                                <button
                                                    className="delete-button single-button wall-action"
                                                    onClick={deletePost(post._id)}
                                                >
                                                    <DeleteOutlineOutlinedIcon width="18" height="18" />
                                                </button>
                                            ) : (
                                                isPostOwner && (
                                                    <div
                                                        tabIndex="0"
                                                        onDragStart={preventDefaultDrag}
                                                        className="menu-actions"
                                                        onKeyDown={this.handleEnterKey('post', post._id)}
                                                    >
                                                        <button
                                                            onDragStart={preventDefaultDrag}
                                                            className="wall-action more-button"
                                                            onClick={handleOpenActions(post._id)}
                                                        >
                                                            <MoreVertIcon />
                                                        </button>
                                                        {showActions === post._id ? (
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="actions-list"
                                                                ref={this.setWrapperRef}
                                                            >
                                                                <ul>
                                                                    {isPostOwner ? (
                                                                        <>
                                                                            {process.env.REACT_APP_SM_SHARE ===
                                                                                'true' && (
                                                                                <li
                                                                                    tabIndex="0"
                                                                                    onClick={this.openSharePopup(
                                                                                        currentPost
                                                                                    )}
                                                                                    onKeyDown={this.handleEnterKey(
                                                                                        'post-share',
                                                                                        post._id,
                                                                                        currentPost
                                                                                    )}
                                                                                >
                                                                                    <Share
                                                                                        fill={colors.dark}
                                                                                        width="18"
                                                                                        height="18"
                                                                                    />
                                                                                    <span>
                                                                                        {translation?.socialMediaShare
                                                                                            ?.shareLabel ||
                                                                                            defaultTranslation
                                                                                                ?.socialMediaShare
                                                                                                ?.shareLabel}
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                            {!(
                                                                                isActiveModeration ||
                                                                                post.approved === false
                                                                            ) &&
                                                                                user._id === post.user && (
                                                                                    <li
                                                                                        tabIndex="0"
                                                                                        className={`${
                                                                                            postEditMode
                                                                                                ? 'edit-disabled'
                                                                                                : ''
                                                                                        }`}
                                                                                        onClick={enterPostEditMode(
                                                                                            post._id,
                                                                                            this.decodeHtml(text)
                                                                                        )}
                                                                                        onKeyDown={this.handleEnterKey(
                                                                                            'post-edit',
                                                                                            post._id,
                                                                                            this.decodeHtml(text)
                                                                                        )}
                                                                                    >
                                                                                        <EditOutlinedIcon
                                                                                            width="18"
                                                                                            height="18"
                                                                                        />
                                                                                        <span>
                                                                                            {translation?.sessions
                                                                                                ?.edit ||
                                                                                                defaultTranslation
                                                                                                    ?.sessions?.edit}
                                                                                        </span>
                                                                                    </li>
                                                                                )}
                                                                            <li
                                                                                tabIndex="0"
                                                                                onClick={deletePost(post._id)}
                                                                                onKeyDown={this.handleEnterKey(
                                                                                    'post-delete',
                                                                                    post._id
                                                                                )}
                                                                            >
                                                                                <DeleteOutlineOutlinedIcon
                                                                                    width="18"
                                                                                    height="18"
                                                                                />
                                                                                <span>
                                                                                    {translation?.generalText.delete ||
                                                                                        defaultTranslation?.generalText
                                                                                            .delete}
                                                                                </span>
                                                                            </li>
                                                                        </>
                                                                    ) : null}
                                                                </ul>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {!(post.approved === false) && (
                                <>
                                    <button
                                        className="comments-button wall-action"
                                        aria-label="Like"
                                        onClick={this.toggleViewComments}
                                    >
                                        {allreadyCommented ? (
                                            <CommentsIconActive width="17" height="17" fill={colors.secondary} />
                                        ) : (
                                            <CommentsIcon width="17" height="17" fill={colors.grey} />
                                        )}
                                        <div>
                                            <span
                                                className={`flex-center ${
                                                    postComments?.length >= 99
                                                        ? 'fs7'
                                                        : postComments?.length >= 9
                                                        ? 'fs9'
                                                        : 'fs11'
                                                }`}
                                            >
                                                {postComments?.length >= 99 ? '99+' : postComments?.length}
                                            </span>
                                        </div>
                                    </button>
                                    {this.props.allreadyLiked ? (
                                        <button
                                            className="like-button wall-action"
                                            aria-label="Like"
                                            onClick={this.props.unlikePost}
                                        >
                                            <LikedIcon width="18" height="18" fill={colors.secondary} />
                                            <div>
                                                <span
                                                    className={`flex-center ${
                                                        post.likes.length >= 99
                                                            ? 'fs7'
                                                            : post.likes.length >= 9
                                                            ? 'fs9'
                                                            : 'fs11'
                                                    }`}
                                                >
                                                    {post.likes.length >= 99 ? '99+' : post.likes.length}
                                                </span>
                                            </div>
                                        </button>
                                    ) : (
                                        <button
                                            className="like-button wall-action"
                                            aria-label="Like"
                                            onClick={this.props.likePost}
                                            disabled={this.props.allreadyLiked}
                                        >
                                            <LikeIcon width="18" height="18" fill={colors.grey} />
                                            <div>
                                                <span
                                                    className={`flex-center ${
                                                        post.likes.length >= 99
                                                            ? 'fs7'
                                                            : post.likes.length >= 9
                                                            ? 'fs9'
                                                            : 'fs11'
                                                    }`}
                                                >
                                                    {post.likes.length >= 99 ? '99+' : post.likes.length}
                                                </span>
                                            </div>
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="post-container">
                        <p onDragStart={preventDefaultDrag}>{postText}</p>
                    </div>
                    {post.approved === false ? (
                        !hasNotApprovedRights ? (
                            <div className="approve-button">
                                <Button
                                    variant={'contained'}
                                    disableElevation
                                    disableRipple
                                    color={'secondary'}
                                    onClick={onApprovePost(post._id)}
                                    size={'small'}
                                >
                                    {translation?.wall.approvePost || defaultTranslation?.wall.approvePost}
                                </Button>
                            </div>
                        ) : (
                            <div className="pending-approval">
                                <span>
                                    {translation?.wall.pendingApproval || defaultTranslation?.wall.pendingApproval}
                                </span>
                                <WarningIcon fillSecondary={colors.secondary} />
                            </div>
                        )
                    ) : null}
                    {postHasUnApprovedComments?.length > 0 ? (
                        !hasNotApprovedRights ? (
                            <div className="pending-approval">
                                <span>
                                    {(translation?.wall.commentsPendingApproval ||
                                        defaultTranslation?.wall.commentsPendingApproval) + ' '}
                                    ({postHasUnApprovedComments?.length})
                                </span>
                                <WarningIcon fillSecondary={colors.secondary} />
                            </div>
                        ) : null
                    ) : null}
                    {this.state.showComments && (
                        <div
                            onDragStart={preventDefaultDrag}
                            className="post-comments"
                            data-empty={post.comments.length}
                        >
                            {postComments?.length ? (
                                <ul className="comments-list-container">
                                    {postComments?.map((comment) => {
                                        let commentUser = this.getUserData(comment.user);
                                        if (commentUser && typeof commentUser.user === 'object') {
                                            commentUser = commentUser.user;
                                        }
                                        let canDeleteComment = this.checkCanDeleteComment(comment);
                                        let commentText = comment.text;
                                        commentText = urlifyString(commentText);
                                        return (
                                            <SinglePostComment
                                                key={comment._id}
                                                user={user}
                                                post={post}
                                                comment={comment}
                                                commentUser={commentUser}
                                                commentText={commentText}
                                                canDeleteComment={canDeleteComment}
                                                handleEnterKey={this.handleEnterKey}
                                                handleOpenActions={handleOpenActions}
                                                showActions={showActions}
                                                languages={languages}
                                                commentEditMode={this.state.commentEditMode}
                                                translation={translation}
                                                defaultTranslation={defaultTranslation}
                                                handleDeleteComment={this.handleDeleteComment}
                                                enterCommentEditMode={this.enterCommentEditMode}
                                                decodeHtml={this.decodeHtml}
                                                setWrapperRef={this.setWrapperRef}
                                                isActiveModeration={isActiveModeration}
                                                hasNotApprovedRights={hasNotApprovedRights}
                                                onApprovePostComment={onApprovePostComment}
                                            />
                                        );
                                    })}
                                </ul>
                            ) : null}
                        </div>
                    )}
                    {this.state.showComments && this.state.commentEditMode && (
                        <div className="edit-mode-container-comment">
                            <p className="edit-mode-text-comment">
                                <span onClick={this.onExitEditMode} className="edit-close-comment">
                                    <HighlightOffIcon />
                                </span>{' '}
                                {translation?.wall.editingState || defaultTranslation?.wall.editingState}
                            </p>
                        </div>
                    )}
                    {this.state.showComments && !isNotAbleToPost && (
                        <div
                            onDragStart={preventDefaultDrag}
                            className={`new-comment-container ${this.state.maxStringLengthError ? 'input-error' : ''}`}
                        >
                            <div
                                className="user-avatar"
                                style={{
                                    backgroundImage: `url(' ${process.env.REACT_APP_AVATAR_FOLDER}${this.props.userAvatarSmall}')`,
                                }}
                            />
                            <InputBase
                                inputRef={this.inputRef}
                                className="newComment"
                                multiline
                                maxRows={10}
                                placeholder={
                                    translation?.wall.newCommentInput || defaultTranslation?.wall.newCommentInput
                                }
                                value={this.state.comment}
                                onChange={this.handleChange('comment')}
                                onKeyDown={this.handleKeyDown}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputBlur}
                                ref={this.newCommentRef}
                            />
                            {this.state.loadingCommentApiCall ? (
                                <Spinner custom="wall-spinner" />
                            ) : (
                                <button
                                    className="add-new-post"
                                    onClick={this.onAddComment}
                                    disabled={!this.state.comment.trim().length || this.state.maxStringLengthError}
                                >
                                    <Plus fill={colors.primary} />
                                </button>
                            )}
                            {this.state.maxStringLengthError && (
                                <p className={'error-text'}>
                                    {translation?.errors?.maxWallInputLength ||
                                        defaultTranslation?.errors?.maxWallInputLength}
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <SMPopup
                    open={this.state.sharePopup}
                    closeConfirm={this.closeSharePopup}
                    eventName={event.name}
                    dialogTitle={
                        translation?.socialMediaShare?.dialogTitlePost ||
                        defaultTranslation?.socialMediaShare?.dialogTitlePost
                    }
                    popupLanguageLevel="platform"
                    clipboardMessage={this.characterLimiter(null, null, this.state.sharedPost)}
                    facebookQuote={`${this.characterLimiter('facebook', 500, this.state.sharedPost)} - ${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${event.name ? `"${event.name}"` : ''} ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }. ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    }`}
                    twitterMessage={`${this.characterLimiter('twitter', 280, this.state.sharedPost)} -  ${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${event.name ? `"${event.name}"` : ''} ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }. ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    }`}
                    emailSubject={`${process.env.REACT_APP_PLATFORM_NAME} - ${event.name}`}
                    emailBody={`${this.characterLimiter('email', 1200, this.state.sharedPost)} - ${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${event.name ? `"${event.name}"` : ''} ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }. ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    } ${eventURL}`}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        userAvatarSmall: state.user.data.avatarSmall,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        exhibitor: state.exhibitor.data,
        activeWallId: state.event.sideNavigationDetails.activeWallId,
        sideNavigationDetails: state.event.sideNavigationDetails,
        isVideoWall: state.user.sideNavigation.seeVideoWall,
        isMobile: state.layout.isMobile,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        allEventUsersObject: state.eventUsers.allEventUsersObject,
    };
};

export default connect(mapStateToProps)(SinglePost);
