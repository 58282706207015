import React, {useEffect, useState} from 'react';
import Select from '@material-ui/core/Select';
import {preventDefaultDrag, timezonesList, timezonesListDST} from 'Utils/utils';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {useDispatch, useSelector} from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import PublicIcon from '@material-ui/icons/Public';
import {makeStyles} from "@material-ui/core/styles";

const SelectTimezone = ({setActiveTimeslot, setUserTimezone}) => {

    const user = useSelector((state) => state?.user?.data);
    const event = useSelector((state) => state?.event?.data);
    const isLargeScreen = useSelector(state => state.layout.isLargeScreen);
    const isTablet = useSelector(state => state.layout.isTablet);
    const isMobile = useSelector(state => state.layout.isMobile);

    const translation = useSelector(state =>  state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector(state => state.languages.translations['en']);

    const [selectedTimezoneIndex, setSelectedTimezoneIndex] = useState(0);
    const [daylightSavingsTime, setDaylightSavingsTime] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);


    useEffect(() => {
        // Daylight Savings Time check
        // eslint-disable-next-line
        Date.prototype.stdTimezoneOffset = function () {
            let jan = new Date(this.getFullYear(), 0, 1);
            let jul = new Date(this.getFullYear(), 6, 1);
            return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
        };
        // eslint-disable-next-line
        Date.prototype.isDstObserved = function () {
            return this.getTimezoneOffset() < this.stdTimezoneOffset();
        };

        let selectedTimezoneIndex;
        let today = new Date();
        if (today.isDstObserved()) {
            setDaylightSavingsTime(true);
            selectedTimezoneIndex = timezonesListDST.findIndex(
                (timezone) => {
                    if(user.timezoneName){return timezone.label === user.timezoneName}
                    return timezone.label === event.timezoneName
                }
            );
        } else {
            selectedTimezoneIndex = timezonesList.findIndex(
                (timezone) => {
                    if(user.timezoneName){return timezone.label === user.timezoneName}
                    return timezone.label === event.timezoneName
                }
            );
        }
        setSelectedTimezoneIndex(selectedTimezoneIndex)
    }, [event, user]);

    const handleChange = (e) => {
        const selectedTimezoneIndex = e.target.value;
        setActiveTimeslot(null);

        let selectedTimezone = daylightSavingsTime
            ? timezonesListDST[selectedTimezoneIndex]
            : timezonesList[selectedTimezoneIndex];

        const data = {
            timezoneName: selectedTimezone.label,
            timezoneValue: selectedTimezone.value,
        }
        setSelectedTimezoneIndex(selectedTimezoneIndex);

        setUserTimezone(data);

    }

    const renderSelectedTimezone = () => {
        const selectedTimezone = user.timezoneValue || event.timezoneValue
        return isLargeScreen || isTablet ?
            (<span className={'selected-timezone-value'}><PublicIcon/> GMT {selectedTimezone>0 ? '+' : ''}{selectedTimezone}</span>) :
            (<><div id={'active-timezone-area-mobile'} className="active-timezone-area-mobile-visibility"></div><span className={'selected-timezone-value selected-timezone-value-container-mobile'}><PublicIcon/></span> <div className={'selected-timezone-value-mobile'}>GMT {selectedTimezone>0 ? '+' : ''}{selectedTimezone}</div></>)
    }

    const handleTooltip = bool => e => {
        if (!isLargeScreen) return;
        setTooltipOpen(bool)
    }



    const useStyles = makeStyles((theme) => ({
        customWidth: {
            maxWidth: 300,
            wordBreak: "unset",
        },
    }));


    const classes = useStyles();

    return (
        <div className={`select-timezone-container ${isMobile && ' select-timezone-container-mobile'}`}>
            <Tooltip
                arrow
                id={'timezone-tooltip-text'}
                classes={{ tooltip: classes.customWidth }}
                placement="bottom-end"
                open={tooltipOpen}
                title={!user.timezoneValue
                    ? (translation?.programDropdown.timezoneTooltipText || defaultTranslation?.programDropdown.timezoneTooltipText)
                    : ''}
            >
                <FormControl variant="outlined" fullWidth={true} className={'timezone-ar-helper'}>
                    <Select
                        // onOpen={this.onDateOpen}
                        // onClose={this.onDateClose}
                        onMouseEnter={handleTooltip(true)}
                        onMouseLeave={handleTooltip(false)}
                        onOpen={handleTooltip(false)}
                        IconComponent={ExpandMoreIcon}
                        onChange={handleChange}
                        value={selectedTimezoneIndex}
                        color={'secondary'}
                        MenuProps={{
                            className: `color-secondary preserveHeaderDropdown firefox-scrollbar-helper`,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "right"
                            },
                            getContentAnchorEl: null,
                        }}
                        variant={'standard'}
                        renderValue={renderSelectedTimezone}
                    >
                        {daylightSavingsTime
                            ? timezonesListDST.map((timezone, index) => {
                                return (
                                    <MenuItem key={timezone.label} value={index}>
                                        {timezone.label.length > 47 && isLargeScreen ?  (
                                            <Tooltip
                                                arrow
                                                id="timezone"
                                                placement="bottom"
                                                title={timezone.label}
                                            >
                                                <span>
                                                    {timezone.label.substring(0, 47) + '...'}
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            isLargeScreen ? timezone.label : timezone.label.length > 33 ? timezone.label.substring(0, 33) + '...' : timezone.label
                                        )}
                                    </MenuItem>
                                );
                            })
                            : timezonesList.map((timezone, index) => {
                                return (
                                    <MenuItem key={timezone.label} value={index}>
                                        {timezone.label.length > 47 ? (
                                            <Tooltip
                                                arrow
                                                id="timezone"
                                                placement="bottom"
                                                title={timezone.label}
                                            >
                                            <span>
                                                {timezone.label.substring(0, 47) + '...'}
                                            </span>
                                            </Tooltip>
                                        ) : (
                                            isLargeScreen ? timezone.label : timezone.label.length > 33 ? timezone.label.substring(0, 33) + '...' : timezone.label
                                        )}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            </Tooltip>
        </div>
    );
};

export default SelectTimezone;