import React from 'react';
import '../../CSS/village.scss';
import SideMenu from '../../Components/SideMenu/SideMenu';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import NotificationPresentationStarts from '../../Components/NotificationPresentationStarts';
import '../../CSS/lobby.scss';
import {disconnectFromNotifications} from '../../Api/socketApi';
import {getVillageBackground} from '../../Utils/getBackgrounds';
import {getVillageBackgroundUploaded} from '../../Utils/getBackgroundsUploaded';
import InformDialog from '../../Dialogs/InformDialog';
import BreadcrumbsNavigation from '../../Components/BreadcrumbsNavigation';
import {preventDefaultDrag} from '../../Utils/utils';

class Village extends React.Component {
    state = {
        showClosedBuildingDialog: false,
        showNoBuildingAccessDialog: false,
    };

    componentDidMount() {
        this.props.onSetEventProtectedMenu();
        this.handleSeeLiveWall();
        this.props.setHasVideoWall(false);
        // reset the buildingId data
        this.props.onSetBuildingId(null);
    }

    componentWillUnmount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.event._id !== this.props.event._id) {
            this.handleSeeLiveWall();
        }
    }

    handleSeeLiveWall = () => {
        const wallData = {
            wallId: this.props.event.liveWallId,
            highlightUsers: [],
        };

        this.props.setActiveWall(wallData);
        this.props.seeLiveWall();
    };

    closeDialog = () => {
        this.setState({
            showClosedBuildingDialog: false,
            showNoBuildingAccessDialog: false,
        });
    };

    handleLogoutUser = () => {
        // if user logged out we should also disconnect him from notifications
        disconnectFromNotifications(this.props.user._id);
        this.props.onLogoutUser();
    };

    handleRedirectToBuilding = (building) => (e) => {
        const {event, resourcesAccess} = this.props;

        if (building.restrictedAccess) {
            this.setState({showClosedBuildingDialog: true});
        } else {
            if (event.hasAccessManagement && resourcesAccess) {
                // check if the user has access to the current building
                const hasAccessToBuilding = resourcesAccess.buildings.includes(building._id);
                if (hasAccessToBuilding) {
                    this.redirectToBuilding(building);
                } else {
                    this.setState({showNoBuildingAccessDialog: true});
                }
            } else {
                this.redirectToBuilding(building);
            }
        }
    };

    redirectToBuilding = (building) => {
        const {eventSlug} = this.props;
        this.props.onSetBuildingId(building._id);
        this.props.history.push(`/event/${eventSlug}/village/building/${building._id}/lobby`);
    };

    render() {
        const {event, isLargeScreen} = this.props;
        const {showClosedBuildingDialog, showNoBuildingAccessDialog} = this.state;

        let EventVillageImage;
        if ('villageRender' in event.brandingData && event.brandingData.villageRender) {
            EventVillageImage = getVillageBackgroundUploaded(event.brandingData);
        } else {
            EventVillageImage = getVillageBackground(event.language);
        }

        return (
            <>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`position-background ${!isLargeScreen ? 'village-mobile' : ''}`}
                >
                    <BreadcrumbsNavigation />
                    <SideMenu />
                    <div
                        className={`village-page setup${event.buildings.length}`}
                        style={{
                            backgroundImage: `url('${EventVillageImage}')`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div onDragStart={preventDefaultDrag} className="centerOfPage">
                            <div onDragStart={preventDefaultDrag} className="relativeCenter">
                                {event.buildings.map((building, buildingIndex) => {
                                    return (
                                        <div
                                            className={`clickable-element building${buildingIndex + 1} building`}
                                            key={building._id}
                                            onClick={this.handleRedirectToBuilding(building)}
                                        >
                                            <div onDragStart={preventDefaultDrag} className="positioning-container">
                                                <div onDragStart={preventDefaultDrag} className="building-overlay" />
                                                <div onDragStart={preventDefaultDrag} className="building-data">
                                                    <div
                                                        onDragStart={preventDefaultDrag}
                                                        className="building-info-container"
                                                    >
                                                        <p
                                                            onDragStart={preventDefaultDrag}
                                                            className="building-data-name"
                                                        >
                                                            {building.name}
                                                        </p>
                                                        <p
                                                            onDragStart={preventDefaultDrag}
                                                            className="building-data-description"
                                                        >
                                                            {building.description}
                                                        </p>
                                                    </div>
                                                    <span
                                                        className="building-data-link"
                                                        onClick={this.handleRedirectToBuilding(building)}
                                                    >
                                                        Click to open
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {showClosedBuildingDialog && (
                    <InformDialog
                        open={showClosedBuildingDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Building information'}
                        dialogDescription={'Access to the Building has been restricted.'}
                        dialogButtonLabel={'OK'}
                    />
                )}
                {showNoBuildingAccessDialog && (
                    <InformDialog
                        open={showNoBuildingAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Building information'}
                        dialogDescription={"Your access package doesn't have access to this building."}
                        dialogButtonLabel={'OK'}
                    />
                )}
                <NotificationPresentationStarts />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        loadingEvent: state.event.loading,
        event: state.event.data,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        isLargeScreen: state.layout.isLargeScreen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHasVideoWall: (booleanValue) => dispatch(actions.sideNavHasVideoWall(booleanValue)),
        onLogoutUser: () => dispatch(actions.logoutUser()),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeLiveWall: () => dispatch(actions.sideNavSeeLiveWall()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        onSetBuildingId: (buildingId) => dispatch(actions.setBuildingId(buildingId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Village);
