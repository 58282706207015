import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import EllipsisTooltip from "../../../SmallLayoutComponents/EllipsisTextTooltip";
import {preventDefaultDrag} from "Utils/utils";
import Button from "@material-ui/core/Button";
import React from "react";
import ClearIcon from '@material-ui/icons/Clear';
import 'Components/EventManagement/CommonComponents/resourcesStyles.scss';
import {TextField} from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {useSelector} from "react-redux";

const ResourceDocument = ({document, removeDocument}) => {
    const eventId = useSelector((state) => state.event.eventId);

    return (
        <li key={document?._id} className='resource-container'>
            <div className="resource-inputs">
                <a href={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${document.file}`} target="_blank" rel="noopener noreferrer">
                    <TextField
                        label={'Document name'}
                        type="text"
                        value={document.displayName}
                        fullWidth={true}
                        variant="outlined"
                        notched
                        InputProps={{endAdornment: <CloudDownloadIcon />, classes: {root: 'cursor-pointer'}}}
                        disabled
                    />
                </a>

                <div className="MuiButton-label dialog-btn-uppercase ml-10 mt-25  mb-20">
                    <span className="mr-8">
                        <CloudUploadOutlinedIcon/>
                    </span>
                    <span className="uploaded-text">UPLOADED:</span>
                    <span id={`file-name-with-tooltip-${document?._id}`}
                          className="ellipsis text-lowercase secondary-color">
                        <EllipsisTooltip
                            title={document.file}
                            elementId={`file-name-with-tooltip-${document?._id}`}
                        />
                    </span>
                </div>
            </div>

            <div onDragStart={preventDefaultDrag} className="removefile-prop">
                <Button
                    variant={"text"}
                    startIcon={<ClearIcon />}
                    disableElevation={false}
                    disableRipple={false}
                    onClick={removeDocument}
                >
                    REMOVE
                </Button>
            </div>
        </li>
    )
}

export default ResourceDocument;