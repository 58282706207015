import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import HomepageDescription from './HomepageDescription';
import HomepagePartners from './HomepagePartners';
import HomepageExhibitors from './HomepageExhibitors';
import HomepageBanners from './HomepageBanners';
import HomepagePreviousEvents from './HomepagePreviousEvents';
import HPColors from './HPColors';
import HomepageSponsors from './HomepageSponsors';
import HomepageSpeakers from './Speakers/HomepageSpeakers';
import {preventDefaultDrag} from 'Utils/utils';

class BrandingHomepage extends React.Component {
    render() {
        const {eventSlug, availableLanguages, loadingSaveEventBranding} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="organizer-branding-homepage">
                <Switch>
                    <Route exact path="/event/:eventSlug/dashboard/branding/homepage/description">
                        <HomepageDescription
                            handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                            saveEventBranding={this.props.saveEventBranding}
                            saveEventTranslation={this.props.saveEventTranslation}
                            availableLanguages={availableLanguages}
                            loadingSaveEventBranding={loadingSaveEventBranding}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/homepage/speakers">
                        <HomepageSpeakers
                            handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/homepage/sponsors">
                        <HomepageSponsors
                            handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/homepage/partners">
                        <HomepagePartners
                            handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/homepage/exhibitors">
                        <HomepageExhibitors
                            handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/homepage/banners">
                        <HomepageBanners
                            handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/homepage/previous-events">
                        <HomepagePreviousEvents
                            handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/homepage/colors">
                        <HPColors
                            saveEventBranding={this.props.saveEventBranding}
                            handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                            loadingSaveEventBranding={loadingSaveEventBranding}
                        />
                    </Route>
                    <Redirect to={`/event/${eventSlug}/dashboard/branding/homepage/description`} />
                </Switch>
            </div>
        );
    }
}

export default BrandingHomepage;
