const no = {
    generalText: {
        upload: 'Laste opp',
        save: 'Lagre',
        add: 'Legg til',
        remove: 'Fjern',
        close: 'Lukk',
        update: 'Oppdater',
        cancel: 'Avbryt',
        confirm: 'Bekreft',
        download: 'Last ned',
        refresh: 'Oppdater',
        back: 'Tilbake',
        submit: 'Send inn',
        send: 'Send',
        leave: 'Forlat',
        step1: 'Steg 1',
        step2: 'Steg 2',
        or: 'eller',
        delete: 'Slett',
        please: 'Vær så snill',
        before: 'før',
        approve: 'Aksepter',
        decline: 'Avslå',
        start: 'Start',
        stop: 'Stopp',
        resetFilter: 'Nullstill Filter',
        apply: 'Bruk',
        filters: 'Filter',
        unsavedChangesTitle: 'Ulagrede endringer',
        unsavedChangesText: 'Du har ulagrede endringer. Vil du lagre dem?',
        selectAll: 'Velg Alle',
        unselectAll: 'Klarere Alle',
    },
    time: {
        justNow: 'akkurat nå',
        minute: 'minutt',
        minutes: 'minutter',
        hour: 'time',
        hours: 'timer',
        day: 'dag',
        days: 'dager',
        month: 'måned',
        months: 'måneder',
        yesterday: 'i går',
        today: 'i dag',
        tomorrow: 'i morgen',
        monday: 'mandag',
        tuesday: 'tirsdag',
        wednesday: 'onsdag',
        thursday: 'torsdag',
        friday: 'fredag',
        saturday: 'lørdag',
        sunday: 'søndag',
        january: 'januar',
        february: 'februar',
        march: 'mars',
        april: 'april',
        may: 'mai',
        june: 'juni',
        july: 'juli',
        august: 'august',
        september: 'september',
        october: 'oktober',
        november: 'november',
        december: 'desember',
        januaryShort: 'jan',
        februaryShort: 'feb',
        marchShort: 'mars',
        aprilShort: 'april',
        mayShort: 'mai',
        juneShort: 'jun',
        julyShort: 'jul',
        augustShort: 'aug',
        septemberShort: 'sept',
        octoberShort: 'okt',
        novemberShort: 'nov',
        decemberShort: 'des',
    },
    errors: {
        noFile: 'Ingen fil valg',
        required: 'Feltet er påkrevet',
        emailNotValid: 'e-posten er ikke gyldig',
        passwordMinLength: 'Passordet må ha min 8 karakterer',
        passwordMismatch: 'Passordene stemmer ikke overens',
        passwordIsSame: 'Ditt nye passord må være ulikt',
        noInvitationCode: 'Vennligst skriv inn invitasjonskoden din',
        imageFileNotSupported: 'Filtypen støttes ikke. Vennligst bruk en av de følgende:',
        phoneInvalid: 'Vennligst angi et gyldig telefonnummer. F.eks.: +447517663925',
        linkInvalid: 'Vennligst angi en gyldig link (https://site.com)',
        facebookLinkInvalid: 'Vennligst angi en gyldig link (https://facebook.com/)',
        linkedinLinkInvalid: 'Vennligst angi en gyldig link (https://linkedin.com/)',
        twitterLinkInvalid: 'Vennligst angi en gyldig link (https://twitter.com/)',
        youtubeLinkInvalid: 'Vennligst anni en gyldig link (https://www.youtube.com/)',
        youtubeOrVimeoLinkInvalid:
            'Vennligst angi en gyldig YouTube- (https://www.youtube.com) eller Vimeo-link (https://vimeo.com)',
        googleScholarLinkInvalid: 'Vennligst angi en gyldig link (https://scholar.google.com/)',
        researchGateLinkInvalid: 'Vennligst angi en gyldig link (https://www.researchgate.net/)',
        connectionProblemTextFirst: 'Det ser ut til å være et tilkoblingsproblem. Vennligst',
        connectionProblemButton: 'KLIKK',
        connectionProblemTextSecond: 'eller oppdater siden.',
        fileTooLarge: 'Filen er for stor. Maksimalt tillatt størrelse er 2MB.',
        fileTooLargeTextFirst: 'Filen er for stor.',
        fileTooLargeTextSecond: 'maks. filstørrelse.',
        fileNameTooLongTextFirst: 'Filnavnet er for langt. Grensen er',
        fileNameTooLongTextSecond: 'tegn.',
        maxWallInputLength: 'Du nådde maksimalt 1200 tegn.',
    },
    footer: {
        cookiesText:
            'Dette nettstedet bruker essensielle informasjonskapsler som er nødvendige for at nettsidene skal fungere. Det brukes også tredjeparts informasjonskapsler, som er nødvendige for at enkelte av funksjonene på nettstedet vårt skal fungere. Hvis du vil lære mer eller trekke tilbake samtykket til bruken av enkelte informasjonskapsler, kan du se våre',
        cookiesLink: 'retningslinjer for informasjonskapsler',
        cookiesButton: 'Godta',
        privacyPolicy: 'Personvernerklæring',
        cookies: 'Informasjonskapsler',
        termsAndConditions: 'Vilkår og betingelser',
        socialLinksTitle: 'Følg oss:',
    },
    menu: {
        logIn: 'Logg inn',
        logOut: 'Logg ut',
        scholarRegistration: 'Påmelding akademisk utstiller',
        exhibitorRegistration: 'Påmelding utstiller',
        participantRegistration: 'Påmelding deltaker',
        createEvent: 'Organiser arrangement',
        loginDisabledInfoText: 'Organisatoren har lukket påloggingen. Kom tilbake senere',
    },
    homepage: {
        organizedBy: 'ORGANISERT AV',
        date: 'DATO',
        location: 'DOMENE OG STED',
        price: 'PRIS',
        tabIntro: 'INTRO',
        tabProgram: 'PROGRAM',
        tabSponsors: 'SPONSORER',
        tabPartners: 'PARTNERE',
        tabExhibitors: 'UTSTILLERE',
        previousEventsSectionTitle: 'TIDLIGERE ARRANGEMENT',
    },
    socialButtons: {
        linkedinButton: 'Logg inn med LinkedIn',
        loginTabLinkedinButton: 'med LinkedIn',
        facebookButton: 'Logg inn med Facebook',
        loginTabFacebookButton: 'med Facebook',
        googleButton: 'Logg inn med Google',
        loginTabGoogleButton: 'med Google',
        twitterButton: 'Logg inn med Twitter',
        loginTabTwitterButton: 'med Twitter',
        socialButtonsLogInTitle: 'Registrer deg med din sosiale medieprofil',
        socialButtonsSignUpTitle: 'Registrer deg med din sosiale medieprofil',
    },
    login: {
        inputEmail: 'E-post',
        inputPassword: 'Passord',
        rememberMeButton: 'Husk meg',
        forgotPasswordButton: 'Glemt passord?',
        loginButton: 'Logg inn',
        loginClosedButton: 'Pålogging lukket',
        loginTitleLowerCase: 'logg inn',
        recoverPasswordDialogTitle: 'GJENOPPRETT PASSORD',
        recoverPasswordDialogRecoverButton: 'GJENOPPRETT',
        errorIncorrectDates: 'Feil e-post eller passord',
        orLogInWithEmail: 'eller logg inn med e-post',
        logInAsParticipantSecondText: 'som deltaker',
        logInAsExhibitorSecondText: 'som utstiller',
        logInAsPosterSecondText: 'som akademisk utstiller',
        errorAccountNotFound: 'Vi kunne ikke finne din myOnvent-profil',
    },
    register: {
        inputFirstName: 'Fornavn',
        inputLastName: 'Etternavn',
        inputEmail: 'E-post',
        inputPassword: 'Passord',
        inputConfirmPassword: 'Bekreft passordet',
        gdprTextFirst: 'Ved å fortsette, godtar du våre',
        gdprTextTerms: 'Vilkår for bruk',
        gdprTextSecond: 'og bekrefter at du har lest våre',
        gdprTextCookies: 'Informasjonskapsler',
        gdprTextPrivacy: 'Personvernerklæring',
        gdprTextAgree: 'Vennligst godta personvernreglene',
        registerButton: 'Registrer deg',
        signUpTitleLowerCase: 'Registrer deg',
        invitationCode: 'Invitasjonskode',
        registerNotAllowed: 'Registreringen er stengt',
        logInTitleFirst: 'Bli med',
        logInTitleSecond: 'arrangement',
        signUpTitleTextFirst: 'Første gang hos',
        signUpTitleTextSecond: 'myOnvent',
        orFillInTheForm: 'eller fyll inn skjemaet under',
        uploadProfilePicture: 'Last opp profilbilde',
        editProfilePicture: 'Rediger profilbildet',
        errorAlreadyHaveMOAccount: 'Du har allerede en myOnvent-konto',
        step1From2Text: 'trinn 1/2',
        step2From2Text: 'trinn 2/2',
        metaTitleScholarRegistration: 'Akademisk utstiller registrering',
    },
    newLoginRegistrations: {
        joinEvent: 'BLI MED PÅ ARRANGEMENTET',
        loginWelcome: 'Velkommen til myOnvent! Velg en påloggingsmetode for å fortsette',
        continueButton: 'Fortsett',
        seemsLikeYouDontHaveMyOnventAccount: 'Det ser ut som du ikke har en myOnvent-konto',
        uploadProfilePictureOptional: 'Last opp profilbilde (valgfritt)',
        createPlatformAccount: 'Opprett Plattformkonto',
        platformAccount: 'Plattformkonto',
        selectRole: 'Velg rolle',
        eventAccount: 'Arrangementskonto',
        password: 'Passord',
        confirmPassword: 'Bekreft passord',
        email: 'E-post',
        passwordMustContain: 'Passordet må inneholde:',
        minimum8Characters: 'Minimum 8 tegn',
        aLowerCaseLetter: 'En liten bokstav',
        anUppercaseLetter: 'En stor bokstav',
        aNumber: 'Et tall',
        aSpecialCharacter: 'Et spesialtegn',
        firstName: 'Fornavn',
        lastName: 'Etternavn',
        weHaveSentAVerificationCodeToYourEmail: 'Vi har sendt en bekreftelseskode til din e-postadresse',
        continueRegistration: 'For å fortsette registreringen,',
        enterCodeBelow: 'skriv inn bekreftelseskoden nedenfor:',
        codeWillExpire: 'Denne koden vil utløpe om',
        codeExpired: 'Koden utløp',
        codeNotReceived: 'Har du ikke mottatt koden? Har du sjekket søppelposten?',
        resendTimer: 'Send igjen om:',
        resendLabel: 'Send igjen',
        resendCodeLabel: 'Send koden på nytt',
        codeError: 'Feil kode ble skrevet inn.',
        codeErrorFix: 'Skriv inn riktig kode fra e-posten eller send en ny kode på nytt',
        congrats: 'Gratulerer!',
        emailConfirmed: 'E-posten din er bekreftet:',
        imHereAs: 'Jeg er her som:',
        participant: 'Deltaker',
        participantText: 'For å se strømming, kommunisere, besøke utstillere og postere/studier.',
        exhibitor: 'Utstiller',
        exhibitorText: 'For å presentere bedriften din og kommunisere med deltakere på arrangementet',
        poster: 'Poster/Studie',
        posterText: 'For å presentere forskning for en akademisk eller profesjonell konferanse',
        emailIsNotAllowed: 'Denne e-postadressen kan ikke registreres for dette arrangementet',
        didntReceiveEmail: 'Fikk du ikke e-posten?',
        sendAgain: 'Send igjen ',
        incorrectPassword: 'Feil passord',
        fileTypeError: 'Filtype støttes ikke. Bruk en av følgende: jpeg, jpg, jfif, gif eller png.',
        fileSizeError: 'Fil for stor. Maksimal filstørrelse: 2 MB.',
        pleaseCompleteAllRequiredFields: 'Vennligst fyll ut alle obligatoriske felt',
        joinTheEvent: 'Delta på arrangementet',
        joinTheEventSub: 'For å starte din virtuelle online arrangement opplevelse',
        changeEmail: 'ENDRE E-POST',
        changeRole: 'ENDRE ROLLE',
        logout: 'LOGG UT',
        headingUser: 'Velkommen tilbake',
        subHeadingUser: 'Det ser ut til at du allerede har en plattformkonto',
        forgotPasswordHeadline: 'Glemt passord',
        forgotPasswordText: 'Ikke bekymre deg, vi vil sende deg ytterligere instruksjoner',
        checkYourEmail: 'Sjekk din e-post',
        checkYourEmailText: 'Vi har sendt en lenke for tilbakestilling av passordet til deg',
        headingNoUser: 'Logg inn',
        subHeadingNoUser: 'Velkommen til myOnvent! For å fortsette, velg en påloggingsmetode',
        resetPasswordButton: 'tilbakestill passord',
        createNewPassword: 'Opprett nytt passord',
        createNewPasswordText: 'Ditt nye passord må være forskjellig fra tidligere brukte passord',
        passwordReset: 'Tilbakestilling av passord',
        passwordResetText: 'Ditt passord har blitt tilbakestilt. Klikk nedenfor for å logge inn igjen.',
        registrationIsClosed: 'Registrering for alle roller er stengt.',
        chooseAnOption: 'Velg et alternativ',
        yourAnswer: 'Svaret ditt',
        firstNameError: 'Fornavnet er for kort. Minimumslengde: 2',
        lastNameError: 'Etternavnet er for kort. Minimumslengde: 2',
        recoveryPassword: 'Gjenopprett passord',
        incorrectPasswordError: 'Feil passord. Prøv igjen eller klikk på "Glemt passord" for å endre det.',
        optional: 'Valgfritt',
        spamEmailError: 'Det har oppstått en feil under opprettingen av kontoen din. Vennligst prøv igjen senere.',
        invalidInput:
            "Ugyldig inndata. Bruk kun bokstaver (A-Z, a-z), tall (0-9), punktum (.), apostrof ('), bindestrek (-) og mellomrom. Første tegn må være en bokstav.",
    },
    emailUpdate: {
        changeEmail: 'Endre e-post',
        changeEmailDescription:
            'Før vi tilordner den nye e-postadressen til kontoen din, vil vi forsikre oss om at det er en ny e-postadresse og tilhører ingen andre.',
        emailTakenError: 'Denne e-postadressen er allerede i bruk. Prøv en annen.',
        enterTheConfirmationCode: 'Skriv inn bekreftelseskoden',
        verificationCodeSent: 'Bekreftelseskode sendt:',
        checkYourSpamFolder: 'Finner ikke koden? Sjekk mappen for søppelpost',
        emailSuccess: 'Suksess! Du har endret e-postadressen tilknyttet kontoen din',
        emailError: 'Feil. Prøv igjen senere',
        confirmPassword: 'Bekreft passord',
        confirmPasswordDescription: 'For å endre e-postadressen, bekreft passordet ditt:',
        newEmail: 'Ny e-postadresse',
        password: 'Passord',
        confirm: 'Bekreft',
        cancel: 'Avbryt',
        change: 'Endre',
    },

    agendaTranslations: {
        myAgenda: 'MIN AGENDA',
        all: 'Alle',
        sessions: 'Økter',
        meetings: 'Møter',
        pastSlots: 'Tidligere spor',
        meetingWith: 'Møte med',
        pending: 'Ventende',
        accepted: 'Godkjent',
        requested: 'Etterspurt',
        canceled: 'Avlyst',
        now: 'Nå',
        live: 'Direkte',
        noMeetings: 'Ingen planlagte møter ennå',
        noSessions: 'Ingen økter i agendaen din ennå.',
        noUpcomingSessions: 'Ingen kommende økter i agendaen din ennå',
        noUpcomingMeetings: 'Ingen kommende møter i agendaen din ennå.',
        noUpcomingAgenda: 'Ingenting kommende på agendaen din ennå.',
        noAgendaItem: 'Ingenting på agendaen din ennå.',
        meetingRequests: 'MØTEFORESPØRSLER',
        finished: 'Ferdig',
        past: 'Tidligere',
        goToMeeting: 'GÅ TIL MØTE',
        addToMyAgenda: 'Legg til i min agenda',
        addedToYourAgenda: 'Lagt til i din agenda',
        acceptedAllMeetings: 'Godta alle møter',
    },
    participantRegistration: {
        metaTitle: 'Deltakerregistrering',
        choosePackageTitle: 'VELG TILGANGSPAKKE',
        noPackageText: 'Vennligst velg en pakke',
        gdprTextFirst: 'Ved å fortsette, godtar du våre',
        gdprTextPrivacy: 'Personvernregler for arrangementet',
        gdprTextSecond: 'og',
        gdprTextTerms: 'vilkår og betingelser for arrangementet',
        gdprTextAgree: 'Vennligst godta personvernreglene',
        registerButtonFree: 'Fullfør registreringen',
        registerButtonPay: 'Fortsett til betaling',
    },
    exhibitorRegistration: {
        metaTitle: 'Utstiller registrering',
        choosePackageTitle: 'VELG TILGANGSPAKKE',
        gdprTextFirst: 'Ved å fortsette, godtar du våre',
        gdprTextPrivacy: 'personvernregler for arrangementet',
        gdprTextSecond: 'og',
        gdprTextTerms: 'vilkår og betingelser for arrangementet',
        gdprTextAgree: 'Vennligst godta personvernreglene',
        registerButtonFree: 'Fullfør registreringen',
        registerButtonPay: 'Fortsett til betaling',
    },
    stripePayment: {
        cardNumber: 'Kortnummer',
        expirationDate: 'Utløpsdato (MM/ÅÅ)',
        cvc: 'CVC',
        disclaimerText:
            'Denne betalingsløsningen er kryptert fra ende til ende. Sikkerhet og personvern garanteres av Stripe Services.',
        disclaimerTextLink: 'Trykk her for å lære mer.',
        cancelButton: 'Avbryt',
        payButton: 'Betal',
    },
    recoverPassword: {
        recoverTextSend: 'E-post for gjenoppretting av passord er sendt.',
    },
    unsubscribe: {
        unsubscribeTitle: 'Avslutte abonnementet?',
        unsubscribeHomepage: 'Gå til hjemmesiden',
    },
    notAvailable: {
        title: 'Arrangementet er utilgjengelig',
        description:
            'Arrangementet du ser etter er utilgjengelig. Det har enten blitt lukket av arrangøren eller deaktivert av en administrator',
        fullOverlayEvents: 'Sjekk ut dine andre arrangementer',
        fullOverlayNoEvents: 'Ingen andre arrangement funnet',
    },
    notFound: {
        notFoundDescription: 'Ops ... Ingen arrangementer å finne her!',
        notFoundHomepage: 'Gå til hjemmesiden',
    },
    eventMenu: {
        organizerButton: 'ARRANGØR',
        coOrganizerButton: 'TILPASS',
        exhibitorButton: 'UTSTILLER',
        posterStudyButton: 'AKADEMISK UTSTILLER',
        boothButton: 'STAND',
        boothsButton: 'Stands',
        homeButton: 'HJEM',
        contactsButton: 'KONTAKTER',
        meetingsButton: 'MØTER',
        chatsButton: 'CHATTER',
        programButton: 'PROGRAM',
        archiveButton: 'ARKIV',
        myAccountButton: 'MIN KONTO',
        lobbyButton: 'Lobby',
        roomsButton: 'ROM',
        connectionProblemTextFirst: 'Det ser ut til å være et tilkoblingsproblem. Vennligst klikk',
        connectionProblemButton: 'HER',
        connectionProblemTextSecond: 'eller oppdater siden.',
        sessionProblemTextFirst: 'Økten din har utløpt. Vennligst klikk',
        sessionProblemButton: 'HER',
        sessionProblemTextSecond: 'for å logge inn igjen.',
    },
    myEventsDropdown: {
        title: 'MINE ARRANGEMENTER',
        createButtonMobile: 'Opprett',
    },
    myBoothsDropdown: {
        title: 'MINE STANDS',
        emptyStudyTitle: 'Akademisk utstillertavle',
    },
    contactsDropdown: {
        title: 'MINE KONTAKTER',
        requestsTitle: 'Kontaktforespørsler',
        rejectButton: 'Avvis',
        acceptButton: 'Godta',
        pendingButton: 'Avventer',
        addButton: 'Legg til',
        noContacts:
            'Du har for øyeblikket ingen kontakter. Vennligst besøk nettverkseksjonen for å legge til en ny kontakt',
    },
    meetingsDropdown: {
        meetingWith: 'Møte med',
        pending: 'Venter',
        accepted: 'Godtatt',
        canceled: 'Kansellert',
        noMeetings: 'Ingen møter er planlagt for øyeblikket',
        finished: 'Ferdig',
    },
    programDropdown: {
        stages: 'Stadier',
        with: 'med',
        noProgram: 'Ingen programoppføringer tilgjengelig for øyeblikket.',
        fullProgramButton: 'Fullt program', // new translation
        addToCalendarButton: 'Legg til i kalenderen', // new translation
        addedToYourAgenda: 'LAGT TIL I DIN AGENDA',
        addEventButton: 'Legg til hendelse', // new translation
        addToMyAgenda: 'Legg til i min agenda', // new translation
        addEventToYourCalendarDialogTitle: 'Legg til hendelse i kalenderen din', // new translation
        addTimeslotToYourCalendar: 'Legg til tidsspor i kalenderen din', // new translation
        tip: 'TIPS: ', // new translation
        tipDescription:
            'Du kan også legge til separate tidsspor i kalenderen din ved å klikke på kalendersymbolet ved siden av hvert tidsspor.', // new translation
        tipTimeslotDescription:
            'Du kan også legge til hele arrangementet ved å klikke på knappen "Legg til i kalenderen".', // new translation
        durationEventTitle: 'Varighet for arrangementet: ', // new translation
        timeslotTitle: 'Tittel: ', // new translation
        slotScheduled: 'Tidsspor planlagt: ', // new translation
        addSlotToCalendar: 'Legg til tidsspor i kalenderen', // new translation
        speakers: 'Talere', // new translation
        auditoriumName: 'Navn på auditorium', // new translation
        timezoneTooltipText:
            'Programmet vises i arrangørens tidssone. Du kan endre visningstidssonen for å se øktene i ønsket tidssone', // new translation
        liveLabel: 'Direkte', // new translation
        liveLabelTooltipText: 'Dette tidssporet er nå direkte i auditorium', // new translation
        openAuditorium: 'ÅPNE AUDITORIET', // new translation
    },
    notificationsDropdown: {
        title: 'VARSLER',
        contactRequestReceived: 'sendte deg en kontaktforespørsel. Ta en titt!',
        contactRequestAccepted: 'godtok din kontaktforespørsel. Start en samtale!',
        meetingRequestReceived: 'sendte deg en møteforespørsel. Ta en titt!',
        meetingRequestAccepted: 'godtok din møteforespørsel. Start en samtale!',
        meetingRequestCanceled: 'kansellerte en møteforespørsel. Ta en titt!',
        meetingStarting: 'Et møte starter snart. Ta en titt!',
        newBoothPost: 'har publisert noe på utstillerveggen din. Sjekk det ut!',
        noNotifications: 'Du har ingen varsler for øyeblikket.',
        seeAll: 'SE ALLE',
        hideAll: 'SKJUL ALLE',
        meetingWillStartTextSecond: 'vil begynne snart. Ta en titt!',
    },
    chatsDropdown: {
        title: 'CHATTER',
        newChatProvideText: 'Oppgi et navn på chatten din. Du vil kunne redigere navnet etterpå:',
        noChats: 'Opprett minst én chat for å snakke med andre deltakere',
        members: 'Medlemmer',
        noOpenedChatText: 'Trykk på chat for å fortsette samtalen.',
        noMessagesInChatTextFirst: 'Bryt isen.',
        noMessagesInChatTextSecond: 'Vær den som starter denne samtalen.',
        newChatNameInput: 'Chat navn (valgfritt)',
        newChatAddMembers: 'Legg til kontakter og andre deltakere til denne chatten:',
        editChatAddMembers:
            'Vennligst søk etter brukere fra dette arrangementet som du ønsker å legge til i gruppechatten.',
        newChatSearchInput: 'Søk etter brukere',
        newChatNoUsersMessage: 'Ingen brukere funnet',
        newChatRemoveUserButton: 'FJERN',
        newChatCancelButton: 'LUKK',
        newChatCreateButton: 'OPPRETT',
        membersLeaveButton: 'FORLAT',
        membersUpdateButton: 'OPPDATER',
        membersChatButton: 'CHAT',
        chatSettingsButton: 'Innstillinger',
        newMessageInput: 'Skriv melding her',
        newVideoCallTooltipText: 'Klikk her for å starte en videosamtale',
        leaveVideoMeetingTitle: 'Forlate videomøtet',
        leaveVideoMeetingDescription: 'Du er i ferd med å forlate videomøtet. Er du sikker på at du vil fortsette?',
        leaveChatConfirmationTitle: 'Forlat videoanrop',
        leaveChatConfirmationText: 'Er du sikker på at du vil forlate det nåværende videoanropet?',
    },
    myAccountDropdown: {
        title: 'MIN KONTO',
        viewProfileButton: 'Se profil',
        logoutButton: 'Logg ut',
    },
    myAccountPage: {
        changePasswordButton: 'ENDRE PASSORD',
        deleteAccountButton: 'SLETT KONTOEN MIN',
        platformLanguage: 'Plattformspråk:',
        privacyPolicyLink: 'Personvernerklæring',
        cookiesLink: 'informasjonskapsler',
        termsAndConditionsLink: 'Vilkår og betingelser',
        eventProfileSectionTitle: 'Arrangementprofil',
        eventPackage: 'Arrangementpakke',
        unregisterFromEventButton: 'AVREGISTRER DEG FRA ARRANGEMENTET',
        unregisterCurrentEventButton: 'Avregistrer deg fra gjeldende arrangement',
        unregisterCurrentEventConfirmation: 'Vennligst bekreft at du ønsker å avregistrere fra arrangementet',
        enableParticipantProfile: 'Aktiver deltakerprofil',
        editUserDialogTitle: 'Rediger brukeropplysninger',
        editExhibitorDialogTitle: 'Rediger selskapets kontaktinformasjon',
        editScholarDialogTitle: 'Rediger akademisk utstillers kontaktinformasjon',
        editParticipantDialogTitle: 'Rediger deltakeropplysninger',
        firstName: 'Fornavn',
        lastName: 'Etternavn',
        uploadImage: 'Last opp bilde',
        removeImage: 'Fjern bilde',
        deleteError:
            'Du kan ikke slette kontoen din. Vennligst slett alle arrangementene du har opprettet for å kunne slette kontoen din.',
        deleteSuccess: 'Kontoen din har blitt slettet',
        reviewEventPrivacyPolicy: 'Se gjennom vår arrangement-personvernerklæring',
        title: 'Tittel',
        company: 'Selskap',
        phone: 'Telefon',
        platformProfileSectionTitleTextSecond: 'profil',
        createParticipantProfile: 'Opprett deltakerprofil',
        reviewPlatformConditions: 'Les våre plattformbetingelser',
        reviewEventConditions: 'Les våre arrangementbetingelser',
    },
    changePassword: {
        title: 'ENDRE PASSORD',
        oldPassword: 'Gammelt passord',
        oldPasswordError: 'Gammelt passord samsvarer ikke!',
        inputPassword: 'Nytt Passord',
        inputConfirmPassword: 'Bekreft passordet',
    },
    roomsDropdown: {
        auditoriumButton: 'Auditorium',
        archiveButton: 'ARKIV',
        onDemandButton: 'ON-DEMAND',
    },
    sideMenu: {
        liveWall: 'LIVE VEGG',
        networking: 'Nettverk',
        eventInfo: 'INFO',
        eventDescription: 'BESKRIVELSE ARRANGEMENT',
        eventFiles: 'FILER',
        eventLinks: 'LENKER',
        audience: 'DELTAKER',
        info: 'INFO',
        videoWall: 'VIDEOVEGG',
        booth: 'STAND',
        boothWall: 'Utstillervegg',
        networkingTooltip: 'Du har ikke tilgang til alle nettverksbygging-funksjonene.',
        attendees: 'Deltaker',
    },
    wall: {
        sortBy: 'Sorter etter:',
        popularity: 'Populæritet',
        time: 'TID',
        emptyWallText: 'Vær den første til å legge ut et innlegg her.',
        newPostInput: 'Hva tenker du på?',
        newCommentInput: 'Kommentar',
        resourcesVideoDescription: 'VIDEOBESKRIVELSE',
        resourcesLinks: 'Lenker',
        resourcesFiles: 'Filer',
        pinTitle: 'Feste',
        unPinTitle: 'Løsne',
        deleteDialogTitle: 'Slette innlegg?',
        pinDialogDescription: 'Det er allerede festet et innlegg. Kun ett innlegg kan være festet av gangen.',
        edited: 'Rediger',
        escapeToCancel: 'klikk escape for å avbryte',
        enterToSave: 'klikk enter for å lagre',
        editingState: 'Rediger melding',
        bannerModeration: 'Poster og kommentarer på denne veggen blir moderert for å sikre kvalitetsinnhold',
        pendingApproval: 'Avventer godkjenning',
        approvePost: 'Godkjenn post',
        approvePostComment: 'Godkjenn kommentar',
        commentsPendingApproval: 'Kommentarer avventer godkjenning',
    },
    networking: {
        searchInput: 'Søk etter brukeren',
        emptyNetworkingSearchResults: 'Personen du leter etter har ikke registrert seg til dette arrangementet',
        emptyAuditoriumNetworkingSearchResults: 'Personen du leter etter har enda ikke deltatt på presentasjonen',
        alphabetical: 'alfabetisk',
        online: 'digitalt',
        matching: 'matcher',
        matchingAlgo: 'Matching algoritmen jobber. Gi oss et minutt mens vi finner dine beste matcher!',
        searchFilters: 'Søke Filter',
        applyFilterTooltip: 'For å slå på filter velg minst 1 alternativ',
        chat: 'Chat',
    },
    closedAuditoriumDialog: {
        title: 'Auditoriumsinformasjon',
        contentPackage: 'Din tilgangspakke inkluderer ikke tilgang til noen auditoriumer.',
    },
    closedShowfloorDialog: {
        title: 'Informasjon for utstillerområdet',
        contentPackage: 'Din tilgangspakke inkluderer ikke tilgang til utstillerområdet.',
        button: 'OK',
    },
    auditorium: {
        willStart: 'Neste økt starter om ...',
        noAccess: 'Din tilgangspakke inkluderer ikke tilgang til dette auditoriumet.',
        noVideos: 'Det er ikke planlagt noen videoavspillinger i dette auditoriumet.',
        stageTab: 'SCENE',
        videoWallButton: 'VIDEOVEGG',
        resourcesButton: 'RESSURSER',
        slotEndsTextFirst: 'Dette programmet slutter om',
        slotEndsTextSecondUpdated: 'Presentasjonen vil være tilgjengelig on demand.',
        slotTitleWith: 'med',
        allAuditoriums: 'Alle auditorium',
        onDemandButton: 'ON-DEMAND',
        hide: 'Skjul',
        leavePopupTitle: 'Forlate scenen',
        leavePopupDescription: 'Ved å forlate auditoriumet vil du ble fjernet fra denne sesjonen',
        leaveSession: 'Forlat scene',
        cancelDescription: 'Ved å forlate vil du ikke lenger være en foredragsholde. Ønsker du å fortsette?',
        settings: 'Innstillinger',
        minimize: 'Minimer',
        microphone: 'Mikrofon',
        camera: 'Kamera',
        auditoriums: 'Auditorier',
    },
    auditoriumArchive: {
        title: 'Auditorium arkiv',
        titleEvent: 'Arrangementsarkiv',
        with: 'med',
        noVideosAvailable: 'Alle økter er ferdige',
    },
    videoPlayer: {
        videoNotPlayingError: 'Har du problemer med videostrømmen? Vennligst last inn på nytt.',
        videoEndedTextFirst: 'Videoen er ferdig. Takk for at du så på!',
        videoEndedTextSecond: 'Videoveggen er åpen for spørsmål og svar.',
        tooltipPlay: 'Spill av',
        tooltipPause: 'Pause',
        tooltipMinimize: 'Minimer',
        tooltipFullscreen: 'Fullskjerm',
        tooltipMute: 'Slå på lyd',
        tooltipUnmute: 'Slå av lyd',
        videoStreamNotStarted: 'Vi går live snart. Følg med!',
    },
    showfloor: {
        allExhibitorsButton: 'ALLE UTSTILLERE',
        featuredExhibitorsText: 'UTVALGTE UTSTILLERE',
        boothLinksDialogTitle: 'UTSTILLERLENKER',
        boothFilesDialogTitle: 'UTSTILLERFILER',
        boothVideoDialogTitle: 'VIDEO',
        mobileTabChat: 'CHAT',
        mobileTabPoster: 'PLAKAT',
        mobileTabFiles: 'FILER',
        mobileTabLinks: 'LENKER',
        mobileTabVideo: 'VIDEO',
        noExhibitorsTextFirst: 'UTSTILLERNE JOBBER MED STANDENE SINE',
        noPresentersTextFirst: 'Utstillerene jobber med standene sine',
        noExhibitorsTextSecond: 'FØLG MED',
        showShowfloorsButton: 'ALLE UTSTILLEROMRÅDER',
        showShowfloorsPosterButton: 'ALLE AKADEMISKE UTSTILLERE',
        showShowfloorsExhibitorButton: 'ALLE UTSTILLERE',
        searchExhibitors: 'Søk etter utstillere',
        searchPosters: 'Søk etter tittel eller forfatterens navn',
        searchExhibitorsMultiple: 'Søk etter utstillere i denne utstillerhallen',
        searchPostersMultiple: 'Søk etter tittel eller forfatterens navn i denne utstillerhallen',
        showfloorsTitle: 'Utstillerområde',
        collapseList: 'Skjul liste',
        seeFullList: 'Se full liste',
        emptyExhibitorsResults: 'Utstilleren du søkte etter er ikke tilstede. Men vi vil invitere dem neste gang',
        emptyPostersResults:
            'Den akademiske utstilleren du søkte etter ikke tilstede. Men vi vil invitere dem neste gang',
        posters: 'plakat',
        allPostersStudiesTitle: 'Alle akademiske utstillere',
        authors: 'forfattere',
        titleNotAvailable: 'Tittel er ikke tilgjengelig',
        abstractNotAvailable: 'Abstrakt ikke tilgjengelig',
        authorsNotAvailable: 'Forfatteren er ikke tilgjenglig',
        openPosterFile: 'Åpne plakat-fil',
        fileIsNotAvailable: 'Filen er ikke tilgjengelig',
        posterBooth: {
            study: 'Akademisk utstiller',
            authors: 'Forfattere',
            highlights: 'Abstrakt',
            scrollInfoText: 'SKROLL NEDOVER FOR Å LESE DET FULLE ABSTRAKTET',
        },
    },
    eventArchive: {
        title: 'Videoarkiv',
        searchText: 'Trykk for å søke',
        noArchivedEvents: 'Det er for øyeblikket ingen arkiverte arrangementer.',
    },
    exhibitorDashboard: {
        event: 'Arrangement',
        title: 'Administrer din utstillerplass',
        visitorsButton: 'Besøkende',
        goToBoothPreview: 'Stand forhåndsvisning',
        previewButton: 'Forhåndsvisning',
        tabStyle: 'Utforming',
        tabLogo: 'Logo',
        tabStudyDetails: 'Akademisk utstiller',
        tabFiles: 'Filer',
        tabStudyFile: 'Fil',
        tabLinks: 'Lenker',
        tabAvatars: 'Avatarer',
        tabStudyAuthors: 'Forfattere',
        tabVideo: 'Video',
        shareText: 'KOPIER URL',
        theBoothUrlCopiedText: 'Booth-URLen ble kopiert til utklippstavlen',
        optionDisabledText: 'Dette alternativet er for øyeblikket utilgjengelig for denne standen',
        boothStyleTab: {
            optionOne: 'Valg 1 - Skandinavisk',
            optionTwo: 'Valg 2 - Moderne',
            optionThree: 'Valg 3 - Dynamisk',
            optionFour: 'Valg 4 - Egentilpasset',
            optionScholarOne: 'Valg 1 - Einstein',
            optionScholarTwo: 'Valg 2 - Curie',
            optionScholarThree: 'Valg 3 - da Vinci',
        },
        logoTab: {
            fileUploadButton: 'Trykk her for å laste opp',
            noFile: 'Ingen fil valg',
        },
        posterStudyTab: {
            posterStudyTitle: 'Tittel',
            studyTitleInputLabel: 'Akademisk utstiller tittel',
            highlightAbstractTitle: 'Høydepunkter/abstrakter',
            studyHighlightsInputLabel: 'Høydepunkter/akstrakter',
            highlightAbstractError: 'Du har nådd maksimum antall tegn! (500 tegn)',
            authorNameLabel: 'Forfatternavn',
            authorAffiliationLabel: 'Tilhørighet',
        },
        filesTab: {
            title: 'Dokumenter:',
            fileNameInput: 'Dokumentnavn (synlig for alle deltakere)',
            chooseFileButton: 'Velg fil',
            noDocAdded: 'Ingen dokumenter lagt til',
            fileError: 'Vennligst legg til et dokumentnavn',
        },
        fileStudyTab: {
            title: 'Last opp pdf-fil',
            description:
                'Last opp en plakat: oppgaven eller presentasjonen vil bli tilgjengelig på din tavle (20Mb. er er filgrense)',
            uploadPdfInputLabel: 'Last opp pdf-fil',
            uploadNewPdfInputLabel: 'Last opp en ny PDF-fil',
            uploadedFile: 'Opplastet',
            dragContainerTitle: 'Dra filen hit',
            dragContainerNewTitle: 'Dra den nye filen hit',
        },
        linksTab: {
            social: {
                title: 'Sosiale media lenker',
                subtitle: '(synlig for alle deltakere)',
                facebookInput: 'Legg til Facebook lenken din',
                linkedinInput: 'Legg til LinkedIn lenken din',
                twitterInput: 'Legg til Twitter lenken din',
                googleScholarInput: 'Legg til Google Scholar din',
                researchGateInput: 'Legg til Research Gate din',
            },
            other: {
                linkInput: 'Lenke',
                linkLabel: 'Navngi lenken',
            },
        },
        linksStudyTab: {
            authorProfiles: 'Forfatterprofil',
            additionalLinks: 'Flere lenker',
        },
        avatarsTab: {
            mainRepresentatives: {
                title: 'Utstillerrepresentanter',
                leftRepresentative: 'Venstre representant',
                rightRepresentative: 'Høyre representant',
                addDialog: {
                    title: 'Legg til avatar',
                    titleScholar: 'Legg til forfatter',
                    editAvatarsTitle: 'Rediger avatar',
                    editAuthorTitle: 'Rediger forfatter',
                    contentLine1: 'Velg en avatar fra galleriet nedenfor',
                    contentLine2: 'Last opp din personaliserte avatar.',
                    contentLine3: 'Trenger du hjelp? Ta kontakt med',
                    assignButtonText: 'Tildel',
                    assignText: 'Vennligst velg en arrangementdeltaker som skal knyttes til representanten ovenfor.',
                    assignErrorBooth: 'Brukeren er allerede en utstillerrepresentant',
                    assignErrorAdditional: 'Brukeren er allerede lagt til som ytterlig representant',
                    input: 'Søk etter navn',
                },
            },
            additionalRepresentatives: {
                title: 'Flere representanter',
                subtitle: '(valgfritt, vil bli vist med profilbilde)',
                addDialog: {
                    title: 'Velg flere representanter',
                    titleScholar: 'Velg medforfatter',
                    assignText:
                        'Vennligst begynn å søke etter arrangementsdeltaker for å legge ham/henne til som en representant.',
                    assignError: 'Brukeren er allerede en utstillerrepresentant!',
                },
            },
            author: {
                title: 'Hovedforfatter',
                subtitle: 'Kun legg til en forfatter som er tilknyttet arrangementet. Du kan legge til én forfatter.',
                mainRepresentative: 'Hovedrepresentant',
            },
            coAuthors: {
                title: 'Medforfatter',
                singularTitle: 'Medforfatter',
                subtitle:
                    'Kun legg til medforfattere som er registrert til arrangementet. Du kan legge til opptil 4 medforfattere.',
            },
        },
        videoTab: {
            video: {
                title: 'Stand video',
                subtitle: '(Kun YouTube video link)',
                youtubeOrVimeoOnly: '(Kun YouTube- eller Vimeo-link)',
                input: 'Skriv inn YouTube URL',
                inputYoutubeOrVimeo: 'Skriv inn YourTube- eller Vimeo-videoURL',
            },
            image: {
                subtitle:
                    '(Bildet vises på standen din i tilfelle du ikke har lastet opp en video link i seksjonen over)',
            },
        },
    },
    boothDashboard: {
        event: 'Arrangement',
        title: 'Din stand',
        manage: 'Administrer',
        statistics: 'Statistikk',
        uniqueVisitors: 'Unike Besøkende', // new translation
        currentOnlineVisitors: 'Nåværende Online Besøkende', // new translation
        totalNumberOfVisits: 'Totalt Antall Besøk', // new translation
        totalDocumentClicks: 'Totalt Dokumentklikk', // new translation
        totalLinkClicks: 'Totalt Likerklikk', // new translation
        totalRepresentativesClicks: 'Totalt Antall Representanter Klikk', // new translation
        totalVideoViews: 'Totalt Antall Videovisninger', // new translation
        totalPostsCount: 'Totalt Antall Innlegg', // new translation
        totalLikesCount: 'Totalt Antall Likes', // new translation
        totalCommentsCount: 'Totalt Antall Kommentarer', // new translation
        showUsers: 'Vis Brukere', // new translation
        dataType: 'Data-type', // new translation
        value: 'Verdi', // new translation
        posts: 'Innlegg', // new translation
        likes: 'Liker', // new translation
        comments: 'Kommentarer', // new translation
        topPostLikes: 'Mest Populære Innlegg Som Liker', // new translation
        topPostComments: 'Topp Postkommentarer', // new translation
        totalVotes: 'Totalt Antall Stemmer', // new translation
    },
    polls: {
        polls: 'MENINGSMÅLING',
        noPolls: 'Ingen meningsmålinger har blitt lagt til for øyeblikket.',
        pollProgress: 'Meningsmåling pågår',
        pollClosed: 'Meningsmåling stengt',
        pollVoted: 'av detalkerne stemte',
    },
    onDemandVideos: {
        sectionTitle: 'ON-DEMAND',
        noOnDemandVideos: 'Ingen on demand-videoer har blitt lagt til enda.',
    },
    meetings: {
        meeting: 'MØTE',
        createDialog: {
            createTitle: 'Be om møte med',
            inputDate: 'Dato',
            inputStart: 'Starter',
            inputEnd: 'Slutt',
            inputTimezone: 'Tidssone',
            inputNote: 'Merk: Kun ett møte kan planlegges om gangen med samme person.',
            meetingError: 'Møtedatoen eller tidspunkt er ugyldig. Vennligst sjekk detaljene!',
            buttonDisabled: 'Du kan planlegge et møte etter at du starter samtalen!',
        },
        banner: {
            pending: 'Møteforespørsel',
            accepted: 'Møte planlagt for',
            progress: 'Møte pågår',
            cancelTitle: 'Kansellere møtet?',
            cancelDescription: 'Er du sikker på at du vil kansellere dette møtet?',
            startCall: 'START SAMTALE',
            joinCall: 'BLI MED',
            pendingOwner: 'Avventende møteforespørsel for',
            videoCallStarted: 'Videomøtet har startet',
            videoCallStartedInfo: 'Videmøtet vil fungere best med opptil 10 personer',
        },
        title: 'Dine møter',
        meetingsInfo: 'Møter kan planlegges fra chat. Start en prat med brukeren du vil planlegge et møte med.',
    },
    socialMediaShare: {
        event: 'arrangement',
        shareLabel: 'Dele',
        dialogTitleGeneral: 'Del med ditt nettverk',
        dialogTitlePost: 'Del ditt innlegg',
        linkedinLabel: 'LinkedIn',
        facebookLabel: 'Facebook',
        twitterLabel: 'Twitter',
        emailLabel: 'E-post',
        clipboardLabel: 'Utklippstavle',
        clipboardSnackbarMessage: 'Venstre representant',
        linkedinButtonTitle: 'Del på LinkedIn',
        facebookButtonTitle: 'Del på Facebook',
        twitterButtonTitle: 'Del på Twitter',
        emailButtonTitle: 'Del via e-post',
        clipboardButtonTitle: 'Kopier til utklippstavlen',
        imAttending: 0,
        interestedClick: 'Interessert i å delta? Klikk her',
    },
    videoConferences: {
        fullScreen: 'ÅPNE FULLSKJERM',
        minimizeScreen: 'MINIMER SKJERM',
        startedVideoChat: 'start en videosamtale',
        timeAgo: 'siden',
        videoCallStarted: 'Videosamtalen har startet',
    },
    sessions: {
        edit: 'REDIGER',
        speakers: 'FOREDRAGSHOLDER',
        speakerSingular: 'foredragsholder',
        moderators: 'MODERATOR',
        moderatorSingular: 'moderator',
        video: 'VIDEO',
        startStream: 'START STRØMMING',
        stopStream: 'STOP STRØMMING',
        sessionChat: 'CHAT',
        speakerUrl: 'Foredragsholder URL',
        moderatorUrl: 'Moderator URL',
        copyUrl: 'kopier URL',
        noSpeakersJoined: 'Ingen foredragsholdere har blitt med enda',
        allFilter: 'alle',
        waitingRoom: 'Venterom',
        isReady: 'Er Klar',
        invitationAccepted: 'Aksepter invitasjonen',
        requestPending: 'Forespørsel venter',
        gettingReady: 'Bli klar',
        inviteToSpeak: 'Inviter til å snakke',
        fromAuditorium: 'fra Auditoriumet',
        mic: 'Mikrofon',
        screen: 'Skjerm',
        rec: 'Filme',
        sessionHasEnded: 'Skjerm',
        sessionLinks: 'Sesjonen er avsluttet',
        sessionSettings: 'SESJONSLENKER',
        noAvailableChat: 'Ingen chat tilgjengelig. For å se chatten, vennligst koble denne sesjonen til tidsprogrammet',
        startLiveSessionStreamTitle: 'Start Live Sesjon Strømming',
        startLiveSessionDescription: 'Opptaket av sesjonen vil automatisk starte når du starter strømmingen',
        stopLiveSessionStreamTitle: 'Stopp Live Sesjon Strømming',
        stopLiveSessionStreamDescription:
            'Opptak av sesjonen vil automatisk stoppe når du stopper strømmingen. Opptaket vil være tilgjengelig snart',
        thumbnailImage: 'Forhåndsbilde',
        uploadThumbnail: 'Last opp forhåndsbilde',
        clickToUpload: 'Klikk her for å laste opp',
        requestMic: 'BE OM MIKROFONEN',
        acceptInvitationTitle: 'Du har fått invitasjonen',
        acceptInvitationSubTitle: 'Moderatoren har invitert deg til å snakke. Hva sier du?',
        acceptInvitationButton: 'Aksepter invitasjonen',
        requestPendingTitle: 'Forespørsel venter...',
        requestPendingSubTitle: 'Vennligst vent til moderatoren slipper deg inn på strømmingen',
        cancelRequest: 'Kansellere forespørsel',
        approvedTitle: 'Vennligs sjekk mikrofonen og videoen og klikk Jeg Er Klar',
        approvedSubTitle: 'Denne sesjonen blir filmet. Ved å bli med godtar du prosessering av dine personlige dataer.',
        connectedToTheBackstage: 'Du er nå live og koblet til backstage',
        removedFromTheSession: 'Du har blitt tatt ut av sesjonen, vennligst ta kontakt med en moderator',
        imReady: 'Jeg er klar',
        isTryingToRegister: 'prøver å registrere som',
        hasLeftTheSession: 'har forlatt sesjonen',
        stopVideo: 'Stopp Video',
        startVideo: 'Start Video',
        disabledScreenSharing: 'Slå av skjermdeling før du starter video',
        disabledByModerator: 'Slått av av moderator',
        disabledUntilReady: 'Slått av inntil klar',
        stopShareScreen: 'Stop skjermdeling',
        shareScreen: 'Dele skjerm',
        disabledVideoSharing: 'Slå av video før du deler skjerm',
        anotherUserIsScreenSharing: 'Noen andre deler allerede skjerm',
        removeRoleDescription: 'Er du sikker på at du vil fjerne',
        declinedInvitation: 'avslå invitasjon til å snakke',
    },
    gdpr: {
        disclaimerTextFirst:
            'Dette dokumentet ble oversatt av kunstig intelligens. Skulle dette være inkonsekvens, vennligst les den engelske versonen av dokumentet',
        disclaimerTextSecond: 'HER',
        metaTitlePrivacyPolicy: 'Personvernerklæring',
        metaTitleCookiesPolicy: 'Informasjonskapsler',
        metaTitleTerms: 0,
        updateCookieConsent: 'Oppdater informasjonskapsel samtykke',
        changeYourConsent: 'Endre samtykke',
        withdrawYourConsent: 'Fjerne samtykke',
        withdrawSnackbarText: 'Innformasjonskapsel samtykke har blitt fjernet',
    },
    infoMessages: {
        imageExplain420x200: 'Anbefalt bildeforhold 1.8:1 (landskap, f.eks. 420 x 200 piksler)',
        imageExplainTB230x320:
            'Anbefalt bildeforhold 1:1.4 (portrett, f.eks. 230 x 320 piksler) med gjennomsiktig bakgrunn',
        imageExplainTB230x875:
            'Anbefalt bildeforhold 1:3.8 (portrett, f.eks. 230 x 875 piksler) med gjennomsiktig bakgrunn',
    },
    matching: {
        matchingQuestionnaireTitle: 'Matching spørsmål',
        matchingQuestionnaireIntro:
            'La oss hjelpe deg å finne relevante kontakter for deg! Utrolige ting skjer når sammenfallende sinn treffes, fullfør spørsmålene for å komme i gang',
        matchingQuestionnaireIntroSkip:
            'Ved å hoppe over spørsmålene vil vi ikke kunne matche deg med resten av deltakerne. Du kan alltid fullføre spørsmålene senere i Nettverk seksjonen. Alle svarene kan endres fra Min Konto.',
        doItLaterBtn: 'FULLFØR SENERE',
        nextBtn: 'Neste',
        backBtn: 'Tilbake',
        finishBtn: 'Ferdig',
        seeMatchesBtn: 'SE ALLE MATCHER',
        tryMatchingBtn: 'PRØV SMART MATCHING',
        optionalQuestion: 'Frivillig',
        requiredQuestion: 'Obligatorisk',
        singleSelect: 'Velg én',
        multiSelect: 'Flervalg',
        mediumMatch: 'Medium Match',
        highMatch: 'Høy Match',
        matchLabel: 'Match',
        matchesLabel: 'Matcher',
        infoTooltipJump: 'Klikk her for å se dine matcher',
        infoTooltipClickToSee1: 'Klikk',
        infoTooltipClickToSee2: 'for å se hvem du bør snakke med!',
        loadingAlgoText: 'Matching algoritmen jobber. Gi oss et minutt mens vi finner dine beste matcher!',
        matchingDetailsFormTitle: 'Smart matching detaljer',
        tryMatchingBanner: 'Møt noen meningsfulle kontakter, prøv vår Smart Matching funksjon!',
        matchingBannerClosed: 'Du kan alltid fylle ut Smart Matching spørsmålene i Nettverk seksjonen under Matches',
        resultsTitle: 'Her er dine topp matcher',
        resultsNoteLabel: 'OBS:',
        resultsNoteText: 'Du kan alltid fylle ut Smart Matching spørsmålene i Nettverk seksjonen',
        resultsNoteMyAccount: 'All svarene kan endres i Min Konto',
        btwResultsResponsive: 'Forresten, du kan finne alle matchene dine i NETTVERK',
        noMoreMatches:
            'Uff da! Vi kan ikke finne flere matcher for deg for tiden. Vennligst kom tilbake senere når flere deltakere har blitt med.',
        noResultsTitle: 'Gratulerer, du gjorde det! Vi leter etter de beste matchene for deg!',
        noResults:
            'Takk for at du prøver Smart Matching funksjonen! Vi leter etter dine beste matcher så fort nye deltakere blir med. Du vil finne matchene dine under Match fanen i Nettverk',
        clickStartChat: 'Klikk chat for å starte en samtale',
        closeMatchingTitle: 'Lukke matching spørsmålene?',
        closeMatchingTextA:
            'Hvis du lukker dette vinduet før du har fullført vil vi ikke lagre noen av svarene dine og Smart Matching resultatene vil ikke være tilgjengelig',
        closeMatchingTextB:
            'Din profil vil ikke være en del av Smart Matching prosessen og noen vil bli matchet med deg.',
        matchingTooltip: 'Klikk på Match for å se hvem du bør snakke med!',
        myAccountMatchingSectionTitle: 'Smart matching detaljer',
        matchingRequiredError: 'Du har fullført alle obligatoriske spørsmål',
        rejectMatchingTitle: 'Avslå Matching deltakelse',
        rejectMatchingText:
            'Dine svar vil bli slettet og du vil ikke bli matchet med andre brukere. Du kan fullføre Matching spørsmålene på nytt, når som helst.',
        rejectMatchingBtn: 'AVSLÅ MATCHING DELTAKELSE',
    },
    welcomeScreen: {
        goToEvent: 'Gå til arrangement',
    },
    emailCodeVerification: {
        codeSent: 'Vi har sendt en verifiseringskode til e-posten din:',
        continueRegistration: 'For å fortsette registreringen',
        enterCodeBelow: 'skriv inn verifiseringskoden nedenfor:',
        codeWillExpire: 'Denne koden vil utløpe om',
        codeExpired: 'Koden utløpte',
        codeNotReceived: 'Mottok du ikke koden? Har du sjekket søppelpostmappen?',
        resendTimer: 'Send på nytt om:',
        resendLabel: 'Send på nytt',
        resendCodeLabel: 'Send koden på nytt',
        codeError: 'Angitt kode er feil.',
        codeErrorFix: 'Skriv inn korrekt kode fra e-posten din, eller send en ny kode',
        congrats: 'Gratulerer!',
        emailConfirmed: 'E-posten din er bekreftet:',
    },
    privacyPolicyUpdates: {
        learnMore: 'Learn more', // new translation
        lastUpdated: 'Last updated', // new translation
        publishedBy: 'Published by', // new translation
        ppuLinkLabel: 'Updated privacy policy', // new translation
        ppuEventLinkLabel: 'Updated event privacy policy', // new translation
        acceptAllButtonLabel: 'Accept all updates', // new translation
    },
    voting: {
        votingText: 'AVSTEMNING',
        enableVoting: 'AKTIVERING AV AVSTEMNING',
        enableVotingDescription: 'Klikk på Oppdater-ikonet for å få de mest nøyaktige resultatene',
        displayVotingNumbersForExhibitors: 'VIS STEMMEANTALL FOR UTSTILLERE OG POSTER/STUDIES',
        displayVotingNumbersForExhibitorsDescription: 'Vil vises i utstillerdashbordet',
        displayVotingNumbersForParticipants: 'VIS STEMMEANTALL FOR DELTAKERE',
        displayVotingNumbersForParticipantsDescription: 'Vil vises for alle deltakerne',
        characterError80: 'Du har nådd maksgrensen på tillatte tegn (80 tegn)',
        characterDescription:
            'Tekst er begrenset til 80 symboler. Teksten vil vises som hovedtittel på avstemningsbanneren. Gjør den klar og inspirerende for deltakerne',
        deleteAllVotes: 'SLETT ALLE STEMMER',
        downloadVotingResults: 'LAST NED STEMMERESULTATER',
        voteText: 'Stemme',
        votedText: 'Stemte',
        votesText: 'Stemmer',
        participant: 'deltager',
        participants: 'deltakere',
        deleteVotesTextFirst: 'ADVARSEL:',
        deleteVotesTextSecond: 'dette er en ikke-reversibel handling.',
        deleteVotesTextThird: 'Ikke glem å laste ned avstemningsresultatene før du sletter dem.',
        checkTheBoxToCastYourVote: 'Merk av i boksen for å avgi din stemme',
        toVotePleaseEnableParticipantProfile: 'For å stemme, vennligst aktiver deltakerprofilen din',
    },
};

export default no;
