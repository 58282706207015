import React, {Component} from 'react';

export default class CoOrganizerFilled extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="14" y="10" width="9" height="12" rx="2" fill="url(#gradient8)"/>
                <rect x="2" y="2" width="9" height="12" rx="1" fill="url(#gradient9)"/>
                <rect x="14" y="2" width="9" height="6" rx="1" fill="url(#gradient10)"/>
                <rect x="2" y="16" width="9" height="6" rx="1" fill="url(#gradient11)"/>
                <path d="M9.9001 13.6H3.5001C2.7001 13.6 2.1001 13 2.1001 12.2V3.70005C2.1001 3.00005 2.7001 2.30005 3.5001 2.30005H9.9001C10.7001 2.30005 11.3001 2.90005 11.3001 3.70005V12.2C11.3001 13 10.7001 13.6 9.9001 13.6Z" stroke="url(#gradient12)" strokeMiterlimit="10"/>
                <path d="M9.9001 21.7H3.5001C2.7001 21.7 2.1001 21.1 2.1001 20.3V17.5C2.1001 16.7 2.7001 16 3.5001 16H9.9001C10.7001 16 11.3001 16.6 11.3001 17.4V20.2C11.3001 21 10.7001 21.7 9.9001 21.7Z" stroke="url(#gradient13)" strokeMiterlimit="10"/>
                <path d="M15.1002 10.4H21.5002C22.3002 10.4 22.9002 11 22.9002 11.8V20.3C22.9002 21.1 22.3002 21.7 21.5002 21.7H15.1002C14.3002 21.7 13.7002 21.1 13.7002 20.3V11.8C13.7002 11 14.3002 10.4 15.1002 10.4Z" stroke="url(#gradient14)" strokeMiterlimit="10"/>
                <path d="M15.1002 2.30005H21.5002C22.3002 2.30005 22.9002 2.90005 22.9002 3.70005V6.50005C22.9002 7.30005 22.3002 8.00005 21.5002 8.00005H15.1002C14.3002 8.00005 13.7002 7.40005 13.7002 6.60005V3.70005C13.7002 3.00005 14.3002 2.30005 15.1002 2.30005Z" stroke="url(#gradient15)" strokeMiterlimit="10"/>
                <defs>
                    <linearGradient id="gradient8" x1="18.23" y1="1.28571" x2="31.4907" y2="12.2284" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                    <linearGradient id="gradient9" x1="6.23" y1="-6.71429" x2="19.4907" y2="4.22837" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                    <linearGradient id="gradient10" x1="18.23" y1="-2.35714" x2="24.216" y2="7.52204" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                    <linearGradient id="gradient11" x1="6.23" y1="11.6429" x2="12.216" y2="21.522" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                    <linearGradient id="gradient12" x1="6.4241" y1="-5.9059" x2="19.0658" y2="5.41837" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                    <linearGradient id="gradient13" x1="6.4241" y1="11.8607" x2="11.9098" y2="21.6025" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                    <linearGradient id="gradient14" x1="18.0242" y1="2.19407" x2="30.6659" y2="13.5183" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                    <linearGradient id="gradient15" x1="18.0242" y1="-1.83924" x2="23.5099" y2="7.90259" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                </defs>
            </svg>
        );
    }
}
