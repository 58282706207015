import React, {PureComponent} from 'react';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {preventDefaultDrag} from '../../Utils/utils';

class SelectLanguageDashboard extends PureComponent {
    render() {
        const {
            setWrapperRef,
            languagesTranslations,
            activeLanguage,
            showLanguageList,
            toggleShowLanguageList,
            handleChangeLanguage,
        } = this.props;
        return (
            <div onDragStart={preventDefaultDrag} ref={setWrapperRef}>
                <div onDragStart={preventDefaultDrag} className="selected-language" onClick={toggleShowLanguageList}>
                    <span>{activeLanguage}</span>
                    <ExpandMoreRoundedIcon />
                </div>
                {showLanguageList && (
                    <div onDragStart={preventDefaultDrag} className="languages-list-container">
                        <ul>
                            {languagesTranslations.map((translation) => {
                                return (
                                    <li
                                        key={translation.language}
                                        value={translation.language}
                                        onClick={handleChangeLanguage(translation.language)}
                                        className={`${translation.language === activeLanguage ? 'active' : ''}`}
                                    >
                                        {translation.language}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}

export default SelectLanguageDashboard;
