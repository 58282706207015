import React from 'react';
import EditGroupChatDialog from '../../../../Dialogs/EditGroupChat';
import {
    connectToGroupChat,
    groupMessageReceived,
    disconnectFromGroupChat,
    connectToGroupChatStream,
    disconnectFromGroupChatStream,
    deregisterGroupMessageReceived,
} from '../../../../Api/socketApi';
import axios from '../../../../store/axios-instance';
import * as actions from '../../../../store/actions/index';
import {connect} from 'react-redux';
import {checkPlatformLanguage, getPrivateChatId, getTimeAgoString, preventDefaultDrag} from '../../../../Utils/utils';
import xorBy from 'lodash/xorBy';
import VideoConference from '../VideoConference/VideoConference';
import ColoredScrollbars from '../../../../SmallLayoutComponents/ColoredScrollbars';
import SendGroupChatMessage from './SendGroupChatMessage';
import RenderChatMessage from '../RenderChatMessage';
import {VideoMeetingJoinBanner} from '../VideoMeetingJoinBanner';
import colors from '../../../../CSS/_variables.module.scss';
import {ReactComponent as RemovePerson} from '../../../../Images/svg/remove-user.svg';
import {ReactComponent as MembersIcon} from '../../../../Images/svg/members_icon.svg';
import {ReactComponent as SettingsIcon} from '../../../../Images/svg/settings_icon.svg';
import {ReactComponent as ChatNowICon} from '../../../../Images/svg/chat_ico.svg';
import {ReactComponent as BackButtonIcon} from '../../../../Images/svg/back-btn.svg';
import cloneDeep from 'lodash/cloneDeep';
import { Tooltip } from '@material-ui/core';
import Spinner from "../../../../SmallLayoutComponents/Spinner";

class GroupChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timestamp: 'no timestamp yet',
            messages: [],
            displayChatMembers: false,
            editGroupChatDialog: false,
            chatMembers: [],
            chatName: '',
            isOwner: false,
            userSentNewMessage: 'no',
            groupChatAvatarString: '',
        };
        this.newMessageRef = React.createRef();
    }

    componentDidMount() {
        // set the chat members and check if current user is the owner. Save them to state
        this.setChatData();
        // connect to chat By Socket
        connectToGroupChat(this.props.activeChatRoomId, this.props.user._id);
        // load all the messages though API call. Save them to state.
        this.onGetGroupChatMessages();
        this.props.readMessagesForGroupChat(this.props.activeChatRoomId);
        // listen to socket for a new message and save it to state
        groupMessageReceived((err, data) => {
            const newMessage = data.message;
            this.setState(
                {
                    messages: [...this.state.messages, newMessage],
                },
                () => {
                    this.scrollToBottom();
                }
            );
            if (data.message.user === this.props.user._id) {
                this.setState(
                    {
                        userSentNewMessage: 'yes',
                    },
                    () => {
                        this.setState({
                            userSentNewMessage: 'no',
                        });
                    },
                    1000
                );
            } else {
                this.setState({
                    userSentNewMessage: 'no',
                });
            }
        });
        this.interval = setInterval(() => this.forceUpdate(), 60000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            // close the conference
            this.props.closeVideoConference(this.props.videoConference);
            // disconnect from previous chat & chat stream
            disconnectFromGroupChat(prevProps.activeChatRoomId, this.props.user._id);
            disconnectFromGroupChatStream(this.props.user._id, prevProps.activeChatRoomId);
            // connect to new chat by Socket
            connectToGroupChat(this.props.activeChatRoomId, this.props.user._id);
            this.props.readMessagesForGroupChat(this.props.activeChatRoomId);
            // get the chat members and check if current user is the owner. Save them to state
            this.setChatData();
            // load all the messages though API call. Save them to state.
            this.onGetGroupChatMessages();
            // hide members
            this.setState({displayChatMembers: false});
            this.setState({editGroupChatDialog: false});
        }

        let previousGroupChat = prevProps.user.groupChats.find(
            (groupChat) => groupChat?.chat?._id === this.props?.activeChatRoomId
        );
        let previousGroupChatData = previousGroupChat?.chat;
        let currentGroupChat = this.props.user.groupChats.find(
            (groupChat) => groupChat?.chat?._id === this.props?.activeChatRoomId
        );
        let currentGroupChatData = currentGroupChat?.chat;

        if (previousGroupChatData?.users !== currentGroupChatData?.users) {
            this.setChatData();
        }

        const {isActive} = this.props.videoConference;
        if (isActive && prevState.chatMembers.length !== this.state.chatMembers.length) {
            const {user, videoConference, activeChatRoomId} = this.props;
            const members = xorBy(this.state.chatMembers, prevState.chatMembers, '_id');
            this.props.changePlaybacks(videoConference, user, members, activeChatRoomId);
        }

        document.body.classList.add('no-meeting');
    }

    componentWillUnmount() {
        deregisterGroupMessageReceived();
        this.props.closeVideoConference(this.props.videoConference);
        disconnectFromGroupChatStream(this.props.user._id, this.props.activeChatRoomId);
        disconnectFromGroupChat(this.props.activeChatRoomId, this.props.user._id);
        clearInterval(this.interval);
    }

    editGroupGoBack = () => this.setState({editGroupChatDialog: false});
    groupMembersGoBack = () => this.setState({displayChatMembers: false});
    handleCloseEditGroupChat = () => this.setState({editGroupChatDialog: false, displayChatMembers: false});

    handleChange = (name) => (e) =>
        this.setState({
            [name]: e.target.value,
        });

    scrollToBottom = () => {
        let messagesContainer = document.getElementById('chat-messages-container');
        if (messagesContainer) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    };

    editGroupChatDialog = () =>
        this.setState({
            editGroupChatDialog: true,
            displayChatMembers: false,
        });

    listGroupMembers = () =>
        this.setState({
            displayChatMembers: !this.state.displayChatMembers,
            editGroupChatDialog: false,
        });

    onGetGroupChatMessages = () =>
        axios({method: 'get', url: '/group-chat/' + this.props.activeChatRoomId + '/messages'}).then((response) => {
            let groupChatMessages = response.data.messages;
            this.setState({messages: groupChatMessages});
            this.scrollToBottom();
        });

    getUserMessageData = (userId) => {
        const {chatMembers} = this.state;
        const userData = chatMembers.find((chatMember) => chatMember._id === userId);
        return userData;
    };

    openVideoConference = () => {
        const {user, videoConference, activeChatRoomId, stopCalling} = this.props;
        stopCalling();
        const members = this.state.chatMembers.map((member) => member._id).filter((memberId) => memberId !== user._id);
        // connect to private chat stream in order to update the stream-participants-updated
        // list when starting a videocall then start the call
        connectToGroupChatStream(user._id, activeChatRoomId);
        this.props.openVideoConference(videoConference, user, members, activeChatRoomId);
    };

    handleRemoveUserFromGroupChat = (userId) => () => {
        this.setState({loading: true});
        this.props.removeUserFromGroupChat(userId, this.props.activeChatRoomId).then(() => {
            this.setState({loading: false});
        }).catch(() => {
            this.setState({loading: false});
        })
    };

    handleOpenPrivateChat = (targetUserId) => () => {
        const privateChatId = getPrivateChatId(this.props.user.privateChats, targetUserId);

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (targetUserId !== this.props.user._id) {
                this.props.onCreatePrivateChat(targetUserId);
            }
        }
    };

    leaveGroupChat = (userId) => () => {
        axios({
            method: 'delete',
            url: '/group-chat/' + this.props.activeChatRoomId + '/leave',
            data: {targetUserId: userId},
        }).then(() => {
            disconnectFromGroupChat(this.props.activeChatRoomId, this.props.user._id);
            clearInterval(this.interval);
            this.props.closeChats();
        });
    };

    setChatData = () => {
        let chatData = this.props.user.groupChats.find(
            (groupChat) => groupChat?.chat?._id === this.props?.activeChatRoomId
        );
        if(!chatData) {
            this.props.closeChats();
            return;
        }
        const groupChatData = cloneDeep(chatData.chat);
        let isOwner = groupChatData.owner === this.props.user._id;
        let groupChatAvatarString = groupChatData.users[0].first[0] + groupChatData.users[0].last[0];
        
        groupChatData.users.sort(function (x, y) {
            if (x.first.toLowerCase() === y.first.toLowerCase()) {
                return x.last.toLowerCase() < y.last.toLowerCase() ? -1 : 1;
            }
            return x.first.toLowerCase() < y.first.toLowerCase() ? -1 : 1;
        });
        this.setState({
            chatMembers: groupChatData.users,
            isOwner: isOwner,
            chatName: groupChatData.name,
            groupChatAvatarString: groupChatAvatarString,
        });
    };

    render() {
        const {
            user,
            allEventUsersObject,
            translation,
            languages,
            videoConference,
            loading,
            showBanner,
            activeChatRoomId,
            openCreateGroupChatDialog,
            platformLanguage,
        } = this.props;
        const {
            messages,
            chatMembers,
            displayChatMembers,
            editGroupChatDialog,
            chatName,
            groupChatAvatarString,
            isOwner,
        } = this.state;

        const usersListString = chatMembers.map((user) => user.first+' '+user.last).join(', ');

        const listMessages = messages.map((message, index) => {
            const {text} = message;
            const codedQuotationMark = '&#x27;';
            const videoCallMessage = `I${codedQuotationMark}ve set up a Video Meeting room. Please click here to join it.`;
            const timeAgoString = getTimeAgoString(message.createdAt, languages.platformLanguage);
            const userMessageData = this.getUserMessageData(message.user);
            let showUserDetails = true;
            // if we have consecutive messages from the same user
            // don't display their details all over again
            // also if the user that initiated a video call sends a new ChatMessage
            // dispay the user details after the "{user} started a video call" message
            if (
                index > 0 &&
                messages[index - 1].user === message.user &&
                messages[index - 1].text !== videoCallMessage
            ) {
                showUserDetails = false;
            }
            // this makes videoCallMessage active [clickable, coloured, pointer cursor]
            // if it's the last one in the conversation, if the JOIN meeting banner is shown
            // and if the user has NOT joined the conference yet
            // else when the user joins the conference or there is no conference available
            // all videoCallMessages will be deactivated [non-clickable, black]
            const lastChatMessageActive =
                messages.map((el) => el.text).lastIndexOf(videoCallMessage) === index &&
                showBanner &&
                !videoConference.isActive;

            return (
                <li key={message._id} className={user._id === message.user ? 'message me' : 'message'}>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`comment-header ${text === videoCallMessage ? 'videocall-message' : ''}`}
                    >
                        <RenderChatMessage
                            message={message}
                            timeString={timeAgoString}
                            messageData={userMessageData}
                            showUserDetails={showUserDetails}
                            lastChatMessageActive={lastChatMessageActive}
                            startConference={this.openVideoConference}
                        />
                    </div>
                </li>
            );
        });

        return (
            <>
                {/* send the banner is shown prop and adjust the height of 
                single-chat-container by applying the join-banner class */}
                {showBanner &&
                    !openCreateGroupChatDialog &&
                    !(displayChatMembers || editGroupChatDialog) &&
                    !videoConference.isActive && <VideoMeetingJoinBanner startConference={this.openVideoConference} />}
                <div
                    onDragStart={preventDefaultDrag}
                    className={`single-chat-container ${
                        showBanner && !openCreateGroupChatDialog && !videoConference.isActive ? 'join-banner' : ''
                    }`}
                >
                    {videoConference.isActive && (
                        <VideoConference
                            translation={translation}
                            members={chatMembers}
                            hidden={displayChatMembers || editGroupChatDialog}
                            userId={user._id}
                            chatId={activeChatRoomId}
                        />
                    )}
                    <div onDragStart={preventDefaultDrag} className="single-chat-header">
                        <div>
                            {chatName ? (
                                <>
                                    <div onDragStart={preventDefaultDrag} className="group-chat-button">
                                        {displayChatMembers ? (
                                            <BackButtonIcon
                                                width="28"
                                                height="28"
                                                fill={colors.dark}
                                                className="back-button"
                                                onClick={this.groupMembersGoBack}
                                            />
                                        ) : (
                                            <>
                                                {editGroupChatDialog ? (
                                                    <BackButtonIcon
                                                        width="28"
                                                        height="28"
                                                        fill={colors.dark}
                                                        className="back-button"
                                                        onClick={this.editGroupGoBack}
                                                    />
                                                ) : (
                                                    <div onDragStart={preventDefaultDrag} className="avatar">
                                                        <span>{groupChatAvatarString}</span>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <Tooltip arrow title={chatName.length > 57 ? chatName : ''}>
                                            <p onDragStart={preventDefaultDrag}
                                               className="group-chat-name ellipsis"
                                               id={`${checkPlatformLanguage(platformLanguage, ['fr', 'no', 'de', 'pt', 'ru', 'es', 'ua']) && 'group-chat-name-ml-helper'}`}>
                                                {chatName}
                                            </p>
                                        </Tooltip>

                                        <span onDragStart={preventDefaultDrag} draggable="false" className="members">
                                            {chatMembers.length + ' ' + translation?.chatsDropdown.members}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div onDragStart={preventDefaultDrag} className="group-chat-button">
                                        {displayChatMembers ? (
                                            <BackButtonIcon
                                                width="28"
                                                height="28"
                                                fill={colors.dark}
                                                className="back-button"
                                                onClick={this.groupMembersGoBack}
                                            />
                                        ) : (
                                            <div onDragStart={preventDefaultDrag} className="avatar">
                                                <span>{groupChatAvatarString}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <Tooltip arrow title={usersListString.length > 57 ? usersListString : ''}>
                                            <p onDragStart={preventDefaultDrag}
                                               className="group-chat-name ellipsis"
                                               id={`${checkPlatformLanguage(platformLanguage, ['fr', 'no', 'de', 'pt', 'ru', 'es', 'ua']) && 'group-chat-name-ml-helper'}`}>
                                                {usersListString}
                                            </p>
                                        </Tooltip>

                                        <span onDragStart={preventDefaultDrag} draggable="false" className="members">
                                            {chatMembers.length + ' ' + translation?.chatsDropdown.members}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div onDragStart={preventDefaultDrag} className="button-wrapper">
                            <button
                                className="see-members"
                                color={displayChatMembers ? 'see' : 'hide'}
                                onClick={this.listGroupMembers}
                            >
                                {displayChatMembers ? (
                                    <MembersIcon fill={colors.primary} />
                                ) : (
                                    <MembersIcon fill={colors.greyVariant} />
                                )}
                                <p>{translation?.chatsDropdown.members}</p>
                            </button>
                        </div>
                        <div onDragStart={preventDefaultDrag} className="button-wrapper">
                            {isOwner && (
                                <button
                                    className="manage-chat"
                                    color={editGroupChatDialog ? 'see' : 'hide'}
                                    onClick={this.editGroupChatDialog}
                                >
                                    {editGroupChatDialog ? (
                                        <SettingsIcon fill={colors.primary} />
                                    ) : (
                                        <SettingsIcon fill={colors.greyVariant} />
                                    )}
                                    <p>{translation?.chatsDropdown.chatSettingsButton}</p>
                                </button>
                            )}
                            {editGroupChatDialog && (
                                <EditGroupChatDialog
                                    opened={editGroupChatDialog}
                                    closeGroupChatDialog={this.handleCloseEditGroupChat}
                                />
                            )}
                        </div>
                    </div>
                    {messages.length > 0 ? (
                        <div
                            onDragStart={preventDefaultDrag}
                            className={`group-chat-wrapper ${videoConference.isActive ? 'is-active' : ''}`}
                        >
                            <div onDragStart={preventDefaultDrag} className="scrollbar">
                                <div onDragStart={preventDefaultDrag} className="box">
                                    <div>
                                        <ul
                                            className="live-wall"
                                            id="chat-messages-container"
                                            data-length={messages.length >= 9 ? 'maximum-responsive' : ''}
                                        >
                                            <li className="empty-space-container" />
                                            {listMessages}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            onDragStart={preventDefaultDrag}
                            className={`group-chat-wrapper empty-chat-conversion ${
                                videoConference.isActive ? 'is-active' : ''
                            }`}
                        >
                            <div>
                                <p>
                                    {translation?.chatsDropdown.noMessagesInChatTextFirst}
                                    <br />
                                    {translation?.chatsDropdown.noMessagesInChatTextSecond}
                                </p>
                            </div>
                        </div>
                    )}
                    <SendGroupChatMessage
                        joinBannerActive={showBanner && !openCreateGroupChatDialog}
                        videoConferenceActive={videoConference?.isActive}
                        startConference={this.openVideoConference}
                    />
                    {displayChatMembers ? (
                        <div onDragStart={preventDefaultDrag} className="chats-members-container">
                            <div>
                                <ColoredScrollbars>
                                    <ul className="group-chat-members-container">
                                        {loading && <Spinner />}
                                        {chatMembers.map((chatMember, chatMemberIndex) => {
                                            let userData = allEventUsersObject[chatMember._id];
                                            return (
                                                <React.Fragment key={chatMemberIndex}>
                                                    <li>
                                                        <div
                                                            className="user-avatar"
                                                            style={{
                                                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${chatMember.avatarSmall}')`,
                                                            }}
                                                        />
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="chat-member-details"
                                                        >
                                                            <p>
                                                                {chatMember.first} {chatMember.last}
                                                            </p>
                                                            {userData && (
                                                                <span>
                                                                    {userData.title}{' '}
                                                                    {userData.title && userData.company ? 'at' : null}{' '}
                                                                    {userData.company}
                                                                </span>
                                                            )}
                                                        </div>

                                                        {chatMember._id !== user._id && isOwner && (
                                                            <button
                                                                className="remove-member"
                                                                onClick={this.handleRemoveUserFromGroupChat(
                                                                    chatMember._id
                                                                )}
                                                                disabled={loading}
                                                            >
                                                                <RemovePerson
                                                                    fill={colors.greyVariant}
                                                                    width="24"
                                                                    height="24"
                                                                />
                                                                <p>
                                                                    {translation?.chatsDropdown.newChatRemoveUserButton}
                                                                </p>
                                                            </button>
                                                        )}

                                                        {chatMember._id === user._id && !isOwner && (
                                                            <button
                                                                className="leave-member"
                                                                onClick={this.leaveGroupChat(chatMember._id)}
                                                                disabled={loading}
                                                            >
                                                                <p>{translation?.chatsDropdown.membersLeaveButton}</p>
                                                            </button>
                                                        )}

                                                        {chatMember._id !== user._id && (
                                                            <button
                                                                className="manage-chat"
                                                                onClick={this.handleOpenPrivateChat(chatMember._id)}
                                                                disabled={loading}
                                                            >
                                                                <ChatNowICon fill={colors.greyVariant} />
                                                                <p>{translation?.chatsDropdown.membersChatButton}</p>
                                                            </button>
                                                        )}
                                                    </li>
                                                </React.Fragment>
                                            );
                                        })}
                                    </ul>
                                </ColoredScrollbars>
                            </div>
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        allEventUsersObject: state.eventUsers.allEventUsersObject,
        loading: state.user.loading,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        videoConference: state.videoConference,
        platformLanguage: state.languages.platformLanguage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId) => dispatch(actions.createPrivateChat(targetUserId)),
        newGroupChatMessage: (data) => dispatch(actions.newGroupChatMessage(data)),
        readMessagesForGroupChat: (chatId) => dispatch(actions.readMessagesForGroupChat(chatId)),
        removeUserFromGroupChat: (userId, chatId) => dispatch(actions.removeUserFromGroupChat({userId, chatId})),
        changePlaybacks: (conference, user, members, chatId) =>
            dispatch(actions.changePlaybacks(conference, user, members, chatId)),
        openVideoConference: (conference, user, members, chatId) =>
            dispatch(actions.openVideoConference(conference, user, members, chatId)),
        closeVideoConference: (conference) => dispatch(actions.closeVideoConference(conference)),
        stopCalling: () => dispatch(actions.stopCalling()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupChat);
