import React from 'react'
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

class MatchingBanners extends React.Component {
    state = {
        showVideo: false,
        showNoShowfloorDialog: false,
        showNoShowfloorAccessDialog: false,
        showNoAuditoriumDialog: false,
        showNoAuditoriumAccessDialog: false,
        brandingTranslation: null,
        matchingBannerClosed: false,
    };

    componentDidMount() {
        this.getMatchingBannerClosed();
    }

    componentDidUpdate(prevProps) {
        const {event, requestCloseBanner} = this.props;
        if (prevProps.event._id !== event._id) {
            this.getMatchingBannerClosed();
        }
        if (prevProps.requestCloseBanner !== requestCloseBanner && !!requestCloseBanner) {
            this.handleCloseMatchingBanner();
        }
        if(this.props.bannerShouldClose === true && prevProps.bannerShouldClose === false) {
            this.setState({matchingBannerClosed: true});
        }
    }

    getMatchingBannerClosed = () => {
        const {eventSlug, user} = this.props;
        const matchingBannerData = localStorage.getItem('matchingBannerData');
        if (matchingBannerData !== null) {
            const matchingBannerDataArray = JSON.parse(matchingBannerData);
            let data = matchingBannerDataArray
                ?.filter((bannerData) => bannerData.event === eventSlug && bannerData.userId === user?._id)
                ?.shift();
            if (data !== undefined && data['userId'] === user?._id) {
                this.setState({matchingBannerClosed: data['matchingBannerClosed']});
            } else {
                this.setState({matchingBannerClosed: false});
            }
        }
    };

    handleCloseMatchingBanner = () => {
        const {eventSlug, user} = this.props;
        let dataToStorage = [];
        const setData = {
            event: eventSlug,
            userId: user?._id,
            matchingBannerClosed: true,
            alreadyShowed: true,
        };
        const matchingBannerData = localStorage.getItem('matchingBannerData');
        if (matchingBannerData !== null && JSON.parse(matchingBannerData).length > 0) {
            const matchingBannerDataArray = JSON.parse(matchingBannerData);
            let spreadArray = matchingBannerDataArray;
            spreadArray.push(setData);
            dataToStorage = spreadArray;
        } else {
            dataToStorage.push(setData);
        }
        localStorage.setItem('matchingBannerData', JSON.stringify(dataToStorage));
        this.setState({matchingBannerClosed: true});
    };

    render() {
        const {matchingBannerClosed} = this.state;
        const {
            event,
            eventId,
            user,
            userEventProfile,
            translation,
            defaultTranslation,
            sideNavigation,
            openMatchingModal,
            mobile,
            bannerDisableOnFinish,
        } = this.props;
        // CHECKS
        const hasUserSeenMatchingForm = userEventProfile?.matchingFormSeen;
        const displayTryMatchingBanner = !matchingBannerClosed && !hasUserSeenMatchingForm;
        // checks socket or event.hasMatching
        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        // check for users w/ participant / exhibitor profile [those available in networking]
        // will have access to engage in matching
        const userRoles = user?.eventRoles?.filter((roles) => roles.event._id === eventId)?.shift()?.roles;
        const hasParticipantExhibitorProfile =
            userRoles.indexOf('participant') >= 0 || userRoles.indexOf('exhibitor') >= 0;

        if (!bannerDisableOnFinish && hasParticipantExhibitorProfile && hasMatchingEnabled && displayTryMatchingBanner) {
            return (
                <div
                    className={`matching-banner ${sideNavigation.isOpened && !mobile ? 'wall-networking-isOpen' : ''}`}
                >
                    {mobile ? (
                        <>
                            <div className="top-text">
                                {translation?.matching?.tryMatchingBanner ||
                                    defaultTranslation?.matching?.tryMatchingBanner}
                            </div>
                            <div className="bottom-actions">
                                <Button
                                    type="button"
                                    onClick={this.handleCloseMatchingBanner}
                                    className={'light'}
                                >
                                    <CloseIcon/>
                                </Button>
                                <Button
                                    type="button"
                                    onClick={openMatchingModal}
                                    className={"light"}
                                    variant={'outlined'}
                                >
                                    {translation?.matching?.tryMatchingBtn ||
                                        defaultTranslation?.matching?.tryMatchingBtn}
                                </Button>

                            </div>
                        </>
                    ) : (
                        <>
                            <div className="left-side">
                                <Button
                                    type="button"
                                    onClick={this.handleCloseMatchingBanner}
                                    className={'light'}
                                >
                                    <CloseIcon/>
                                </Button>
                                {translation?.matching?.tryMatchingBanner ||
                                    defaultTranslation?.matching?.tryMatchingBanner}
                            </div>
                            <Button type="button"
                                    onClick={openMatchingModal}
                                    className={"light"}
                                    variant={'outlined'}
                            >
                                {translation?.matching?.tryMatchingBtn || defaultTranslation?.matching?.tryMatchingBtn}
                            </Button>
                        </>
                    )}
                </div>
            );
        }
        if (
            !bannerDisableOnFinish &&
            hasParticipantExhibitorProfile &&
            matchingBannerClosed &&
            event.hasMatching
        ) {
            return (
                <div
                    className={`matching-banner-closed ${
                        sideNavigation.isOpened && !mobile ? 'wall-networking-isOpen' : ''
                    }`}
                >
                    {translation?.matching?.matchingBannerClosed ||
                        defaultTranslation?.matching?.matchingBannerClosed}
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        user: state.user.data,
        userEventProfile: state.userEventProfile.data,
        sideNavigation: state.user.sideNavigation,
        eventRoles: state.user.eventRoles,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(MatchingBanners);
