import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import EventLanguage from '../../Components/EventMenuDropdowns/EventLanguage';
import '../../CSS/myAccount.scss';
import '../../CSS/myAccountMobile.scss';
import variables from '../../CSS/_variables.module.scss';
import ConfirmDialog from '../../Dialogs/Confirm';
import ChangePasswordMobile from '../../Dialogs/MyAccount/ChangePasswordMobile';
import CreateOrganizerParticipantProfileMobile from '../../Dialogs/MyAccount/CreateOrganizerParticipantProfileMobile';
import DeleteMyAccount from '../../Dialogs/MyAccount/DeleteMyAccount';
import EditExhibitorContactPersonInformationMobile from '../../Dialogs/MyAccount/EditExhibitorContactPersonInformationMobile';
import EditParticipantInformationMobile from '../../Dialogs/MyAccount/EditParticipantInformationMobile';
import EditScholarContactPersonInformation from '../../Dialogs/MyAccount/EditScholarInformation';
import EditSmartMatchingAnswersMobile from '../../Dialogs/MyAccount/EditSmartMatchingAnswersMobile';
import EditUserInformationMobile from '../../Dialogs/MyAccount/EditUserInformationMobile';
import {ReactComponent as ArrowRightIcon} from '../../Images/svg/arrow_right.svg';
import {ReactComponent as EditIcon} from '../../Images/svg/edit-image.svg';
import * as actions from '../../store/actions';
import axios from '../../store/axios-instance';
import {getExhibitorData, preventDefaultDrag} from '../../Utils/utils';
import {EXHIBITOR_TYPES} from '../../Utils/constants/shared';
import {EventProfileItem} from './EventProfileItem';
import EmailChangeMobile from './EmailChange/EmailChangeMobile';
import ConfirmPassword from './EmailChange/components/ConfirmPassword';
import CheckIcon from '@material-ui/icons/Check';
import SnackbarGlobal from '../../SmallLayoutComponents/Snackbars/SnackbarGlobal';

class MyAccountMobile extends React.Component {
    state = {
        editUserDialog: false,
        editParticipantDialog: false,
        smartMatchingDialog: false,
        editExhibitorContactPersonDialog: false,
        editScholarContactPersonDialog: false,
        createParticipantProfileDialog: false,
        changeUserPasswordDialog: false,
        deleteMyAccountDialog: false,
        unregisterFromEventDialog: false,
        changePasswordServerMessage: '',
        canUnregisterEvent: true,
        openConfirmUnsavedChanges: false,
        changeUserEmailDialog: false,
        isPasswordCheckOn: false,
        isPasswordCorrect: false,
        password: '',
        passwordError: '',
        emailChangeSuccess: false,
    };

    componentDidMount() {
        this._handleSeeOnventProfile();
    }

    _handleSeeOnventProfile = () => this.props.seeOnventProfile();
    _handleSeeEventProfile = () => this.props.seeEventProfile();
    handleOpenEditUserDialog = () => this.setState({editUserDialog: true});
    handleOpenEditParticipantDialog = () => this.setState({editParticipantDialog: true});
    handleOpenSmartMatchingDialog = () => this.setState({smartMatchingDialog: true});
    handleOpenEditExhibitorContactPersonDialog = () => this.setState({editExhibitorContactPersonDialog: true});
    handleOpenEditScholarContactPersonDialog = () => this.setState({editScholarContactPersonDialog: true});
    handleOpenCreateEventProfileDialog = () => this.setState({createParticipantProfileDialog: true});
    handleChangePassword = () => this.setState({changeUserPasswordDialog: true});
    handleChangeEmail = () => this.setState({isPasswordCheckOn: true});
    handleChangePasswordData = (e) => {
        const {passwordError} = this.state;
        if (passwordError?.length > 0) {
            this.setState({passwordError: ''});
        }
        this.setState({password: e.target.value});
    };

    handleDeleteUser = () => this.setState({deleteMyAccountDialog: true});

    handleUnregisterFromEvent = () =>
        this.setState({
            unregisterFromEventDialog: true,
        });

    handleCloseDialogUnregisterFromEvent = () =>
        this.setState({
            unregisterFromEventDialog: false,
        });

    handleRemoveMatchingData = () => {
        const {eventId, onGetUsers, userId, onGetUserEventProfile, onRemoveUserMatchingData} = this.props;
        onRemoveUserMatchingData(eventId)
            .then(() => {
                this.setState({smartMatchingDialog: false}, () => {
                    onGetUsers(eventId);
                    onGetUserEventProfile(eventId, userId);
                });
            })
            .catch(() => {});
    };

    handleOpenEditEventProfileDialog = () => {
        const {eventRoles, exhibitorData, participantData} = this.props;
        if (eventRoles.isExhibitor && exhibitorData && exhibitorData.type === EXHIBITOR_TYPES.scholar) {
            this.handleOpenEditScholarContactPersonDialog();
        } else if (eventRoles.isExhibitor && exhibitorData && exhibitorData.type === EXHIBITOR_TYPES.company) {
            this.handleOpenEditExhibitorContactPersonDialog();
        } else if (!eventRoles.isExhibitor && participantData) {
            this.handleOpenEditParticipantDialog();
        }
    };

    handleCloseDialog = (serverMessage) => {
        let message = '';
        if (serverMessage) {
            message = serverMessage;
        }
        this.setState({
            editUserDialog: false,
            deleteMyAccountDialog: false,
            editParticipantDialog: false,
            smartMatchingDialog: false,
            editExhibitorContactPersonDialog: false,
            editScholarContactPersonDialog: false,
            createParticipantProfileDialog: false,
            changeUserPasswordDialog: false,
            unregisterFromEventDialog: false,
            changePasswordServerMessage: message,
            changeUserEmailDialog: false,
            isPasswordCheckOn: false,
            isPasswordCorrect: false,
            password: '',
        });
    };
    handlePasswordCorrection = async () => {
        const {password} = this.state;
        try {
            const {data} = await axios.post('users/me/confirm-password', {password});
            if (data.success === true) {
                this.setState({
                    isPasswordCorrect: true,
                    isPasswordCheckOn: false,
                    passwordError: null,
                    changeUserEmailDialog: true,
                });
            } else {
                this.setState({passwordError: 'Passwords do not match!'});
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                this.setState({passwordError: error.response.data.error});
            } else {
                // Handle other types of errors (e.g., network error)
                console.error('An error occurred:', error);
            }
            console.log(error);
        }
    };
    unregisterFromEvent = async () => {
        const {user, eventSlug, eventId, history, eventRoles} = this.props;

        if (eventRoles.isCoOrganizer) {
            await this.props.onDeleteCoOrganizer(eventId, user._id);
        }
        await axios({method: 'delete', url: `/event/v2/${eventId}/unregister-user/${user._id}`})
            .then(() => {
                const {user, eventId} = this.props;
                const userRolesInEvent = user.eventRoles.find((role) => role.event._id === eventId);
                const userIsOrganizer = userRolesInEvent && userRolesInEvent.roles.includes('organizer');
                if (userIsOrganizer) {
                    this.props.onRefreshUserUserData().then(() =>
                        history.push({
                            pathname: `/event/${eventSlug}/lobby`,
                            state: {isUnregisteredOrganizer: true},
                        })
                    );
                } else {
                    this.props.resetEventData();
                    this.props.onRefreshUserUserData().then(() => history.push(`/event/${eventSlug}`));
                }
            })
            .catch(() => {});
    };

    setExhibitorData = () => {
        const {event, eventUsers, user} = this.props;
        let exhibitorData = getExhibitorData(eventUsers.exhibitors, user._id);
        this.setState({exhibitorData: exhibitorData});

        if (exhibitorData?.type === EXHIBITOR_TYPES.scholar) {
            // get scholar predefined fields
            const {scholarPredefinedRegistrationFields} = event;
            let predefinedRegistrationFields = [];
            Object.keys(scholarPredefinedRegistrationFields).map((key) => {
                const scholarPredefinedField = scholarPredefinedRegistrationFields[key];
                if (scholarPredefinedField.isEnabled) {
                    let fieldLabel = scholarPredefinedField.label;
                    predefinedRegistrationFields.push({label: fieldLabel, value: key});
                }
                return null;
            });
            this.setState({predefinedRegistrationFields: predefinedRegistrationFields});
        } else {
            // get exhibitor predefined fields
            const {exhibitorPredefinedRegistrationFields} = event;
            let predefinedRegistrationFields = [];
            Object.keys(exhibitorPredefinedRegistrationFields).map((key) => {
                const exhibitorPredefinedField = exhibitorPredefinedRegistrationFields[key];
                if (exhibitorPredefinedField.isEnabled) {
                    let fieldLabel = exhibitorPredefinedField.label;
                    predefinedRegistrationFields.push({label: fieldLabel, value: key});
                }
                return null;
            });
            this.setState({predefinedRegistrationFields: predefinedRegistrationFields});
        }
    };

    closeConfirmPassword = () => {
        this.setState({changeUserEmailDialog: false, isPasswordCheckOn: false, isPasswordCorrect: false, password: ''});
    };
    emailChangeSuccess = (value) => {
        this.setState({emailChangeSuccess: value});
    };
    render() {
        const {
            changePasswordServerMessage,
            createParticipantProfileDialog,
            editScholarContactPersonDialog,
            editExhibitorContactPersonDialog,
            editParticipantDialog,
            smartMatchingDialog,
            changeUserPasswordDialog,
            deleteMyAccountDialog,
            editUserDialog,
            unregisterFromEventDialog,
            canUnregisterEvent,
            changeUserEmailDialog,
            isPasswordCheckOn,
            password,
            passwordError,
            emailChangeSuccess,
        } = this.state;
        const {
            user,
            event,
            eventRoles,
            eventSlug,
            registrationType,
            exhibitorData,
            participantData,
            predefinedRegistrationFields,
            accessPackageData,
            exhibitorAccessPackageData,
            userEmail,
            hasMatchingEnabled,
            matchingFormSeen,
            profileTabs,
            imgErrorText,
            languages,
            defaultTranslation,
            translation,
        } = this.props;

        return (
            <div
                onDragStart={preventDefaultDrag}
                id="my-account-mobile"
                className={`my-account-page event-mobile-page ${
                    editParticipantDialog || smartMatchingDialog ? 'hide-overflow' : ''
                }`}
            >
                <div onDragStart={preventDefaultDrag} className="tabs-container">
                    <div>
                        <div
                            className={`tab d-flex justify-content-center align-items-center ${
                                profileTabs.seeOnventProfile ? 'active' : ''
                            }`}
                            onClick={this._handleSeeOnventProfile}
                        >
                            <h3>
                                {process.env.REACT_APP_PLATFORM_NAME}{' '}
                                {translation?.myAccountPage.platformProfileSectionTitleTextSecond ||
                                    defaultTranslation?.myAccountPage.platformProfileSectionTitleTextSecond}
                            </h3>
                        </div>
                        {(exhibitorData || participantData) && (
                            <div
                                className={`tab d-flex justify-content-center align-items-center ${
                                    profileTabs.seeEventProfile ? 'active' : ''
                                }`}
                                onClick={this._handleSeeEventProfile}
                            >
                                <h3>{translation?.myAccountPage.eventProfileSectionTitle}</h3>
                            </div>
                        )}
                    </div>
                </div>
                {profileTabs.seeOnventProfile ? (
                    <div onDragStart={preventDefaultDrag} className="edit-section-container onvent-profile">
                        <div>
                            <div onDragStart={preventDefaultDrag} className="profile-list">
                                <div onDragStart={preventDefaultDrag} className="profile-list-item">
                                    <div
                                        className="avatar"
                                        style={{
                                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarBig}')`,
                                        }}
                                    />
                                    <div onDragStart={preventDefaultDrag} className="user-details">
                                        <h3>
                                            {user.first} {user.last}
                                        </h3>
                                        <p className="ar-helper-my-account-mobile word-breaker">
                                            <span>
                                                {translation?.login.inputEmail || defaultTranslation?.login.inputEmail}:{' '}
                                            </span>
                                            {userEmail}
                                        </p>
                                    </div>
                                    <div>
                                        <button onClick={this.handleOpenEditUserDialog}>
                                            <EditIcon fill={variables.primary} />
                                        </button>
                                    </div>
                                </div>
                                {(registrationType === '' || registrationType === 'email') && (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        onClick={this.handleChangePassword}
                                        className="profile-list-item"
                                    >
                                        <h4>{translation?.myAccountPage.changePasswordButton}</h4>
                                        <ArrowRightIcon
                                            width="24"
                                            height="24"
                                            fill={variables.greyVariant}
                                            className="arabic-rotate"
                                        />
                                    </div>
                                )}
                                <div
                                    onDragStart={preventDefaultDrag}
                                    onClick={this.handleChangeEmail}
                                    className="profile-list-item"
                                >
                                    <h4>{translation?.emailUpdate.changeEmail}</h4>
                                    <ArrowRightIcon
                                        width="24"
                                        height="24"
                                        fill={variables.greyVariant}
                                        className="arabic-rotate"
                                    />
                                </div>
                                <div
                                    onDragStart={preventDefaultDrag}
                                    onClick={this.handleDeleteUser}
                                    className="profile-list-item"
                                >
                                    <h4>{translation?.myAccountPage.deleteAccountButton}</h4>
                                    <ArrowRightIcon
                                        width="24"
                                        height="24"
                                        fill={variables.greyVariant}
                                        className="arabic-rotate"
                                    />
                                </div>
                                {!participantData && eventRoles.isOrganizer && event.status === 'public' && (
                                    <div
                                        onClick={this.handleOpenCreateEventProfileDialog}
                                        className="profile-list-item"
                                    >
                                        <h4>{translation?.myAccountPage.enableParticipantProfile}</h4>
                                        <ArrowRightIcon
                                            width="24"
                                            height="24"
                                            fill={variables.greyVariant}
                                            className="arabic-rotate"
                                        />
                                    </div>
                                )}
                                <div onDragStart={preventDefaultDrag} className="profile-list-item">
                                    <EventLanguage translation={translation} />
                                </div>
                            </div>
                            <div onDragStart={preventDefaultDrag} className="user-profile-information">
                                <div>
                                    {changePasswordServerMessage ? (
                                        <p onDragStart={preventDefaultDrag} className="server-message">
                                            {changePasswordServerMessage ? changePasswordServerMessage : ''}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div onDragStart={preventDefaultDrag} className="gdpr-links">
                                <p>
                                    {translation?.myAccountPage.reviewPlatformConditions ||
                                        defaultTranslation?.myAccountPage.reviewPlatformConditions}
                                    :
                                </p>
                                <ul>
                                    <li>
                                        <Link to={{pathname: '/privacy-policy'}} target={'_blank'}>
                                            {translation?.myAccountPage.privacyPolicyLink}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={{pathname: '/cookies'}} target={'_blank'}>
                                            {translation?.myAccountPage.cookiesLink}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={{pathname: '/terms-and-conditions'}} target={'_blank'}>
                                            {translation?.myAccountPage.termsAndConditionsLink}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : null}

                {profileTabs.seeEventProfile && (
                    <div onDragStart={preventDefaultDrag} className="edit-section-container event-profile">
                        {!eventRoles.isExhibitor && participantData && (
                            <div onDragStart={preventDefaultDrag} className="edit-participant-info-container">
                                <button onClick={this.handleOpenEditEventProfileDialog}>
                                    <EditIcon fill={variables.primary} />
                                </button>
                                <ul>
                                    {predefinedRegistrationFields.map((field) => {
                                        return (
                                            <EventProfileItem
                                                label={field.label}
                                                value={participantData[`${field.value}`] ?? '-'}
                                                key={`predefinedRegistrationField${field.value}`}
                                            />
                                        );
                                    })}
                                    {participantData.customFields.map((field) => {
                                        let value = '';
                                        if (field.value) {
                                            value = field.value;
                                        } else if (field.values) {
                                            value = field.values.filter((el) => el.length).join(', ');
                                        }
                                        return (
                                            <EventProfileItem
                                                label={field.label}
                                                value={value}
                                                key={`customField${value}`}
                                            />
                                        );
                                    })}
                                    {!eventRoles?.isOrganizer && event.hasAccessManagement && accessPackageData && (
                                        <EventProfileItem
                                            key={`field${translation?.myAccountPage.eventPackage}`}
                                            label={translation?.myAccountPage.eventPackage}
                                            value={accessPackageData.name}
                                        />
                                    )}
                                </ul>
                            </div>
                        )}
                        {eventRoles.isExhibitor && exhibitorData && (
                            <div onDragStart={preventDefaultDrag} className="edit-participant-info-container">
                                <button onClick={this.handleOpenEditEventProfileDialog}>
                                    <EditIcon fill={variables.primary} />
                                </button>
                                <ul>
                                    {predefinedRegistrationFields.map((field) => {
                                        return (
                                            <EventProfileItem
                                                label={field.label}
                                                value={exhibitorData[`${field.value}`]}
                                                key={`predefinedRegistrationField${field.value}`}
                                            />
                                        );
                                    })}
                                    {exhibitorData.customFields.map((field) => {
                                        let value = '';
                                        if (field.value) {
                                            value = field.value;
                                        } else if (field.values) {
                                            value = field.values.filter((el) => el.length).join(', ');
                                        }
                                        return (
                                            <EventProfileItem
                                                label={field.label}
                                                value={value}
                                                key={`customField${value}`}
                                            />
                                        );
                                    })}
                                    {(event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
                                        exhibitorAccessPackageData && (
                                            <li>
                                                <p>
                                                    <span>{translation?.myAccountPage.eventPackage}: </span>
                                                    {exhibitorAccessPackageData.name}
                                                </p>
                                            </li>
                                        )}
                                </ul>
                            </div>
                        )}
                        {hasMatchingEnabled && matchingFormSeen && (
                            <div onClick={this.handleOpenSmartMatchingDialog} className="smart-matching-button">
                                <h4>
                                    {translation?.matching?.myAccountMatchingSectionTitle ||
                                        defaultTranslation?.matching?.myAccountMatchingSectionTitle}
                                </h4>
                                <ArrowRightIcon width="24" height="24" fill={variables.greyVariant} />
                            </div>
                        )}
                        {canUnregisterEvent && (
                            <div
                                onDragStart={preventDefaultDrag}
                                onClick={this.handleUnregisterFromEvent}
                                className="change-password-btn"
                            >
                                <h4>{translation?.myAccountPage.unregisterFromEventButton}</h4>
                                <ArrowRightIcon width="24" height="24" fill={variables.greyVariant} />
                            </div>
                        )}
                        <div onDragStart={preventDefaultDrag} className="gdpr-links">
                            <p>
                                {translation?.myAccountPage.reviewEventConditions ||
                                    defaultTranslation?.myAccountPage.reviewEventConditions}
                                :
                            </p>
                            <ul>
                                <li>
                                    <Link
                                        to={{
                                            pathname: `/event/${eventSlug}/event-privacy-policy`,
                                            search: `?lang=${languages.platformLanguage}`,
                                        }}
                                        target={'_blank'}
                                    >
                                        {translation?.myAccountPage.privacyPolicyLink}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{
                                            pathname: `/event/${eventSlug}/event-terms-and-conditions`,
                                            search: `?lang=${languages.platformLanguage}`,
                                        }}
                                        target={'_blank'}
                                    >
                                        {translation?.myAccountPage.termsAndConditionsLink}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}

                {editUserDialog && (
                    <EditUserInformationMobile
                        opened={editUserDialog}
                        closeDialog={this.handleCloseDialog}
                        handleImageChange={this.props.handleImageChange}
                        handleRemoveUserAvatar={this.props.handleRemoveUserAvatar}
                        imgErrorText={imgErrorText}
                        loading={this.props.loadingProfilePicture}
                    />
                )}

                {deleteMyAccountDialog && (
                    <DeleteMyAccount opened={deleteMyAccountDialog} closeDialog={this.handleCloseDialog} mobile />
                )}

                {changeUserPasswordDialog && (
                    <ChangePasswordMobile opened={changeUserPasswordDialog} closeDialog={this.handleCloseDialog} />
                )}

                {changeUserEmailDialog && (
                    <EmailChangeMobile
                        translation={this.props.translation}
                        opened={changeUserEmailDialog}
                        emailChangeSuccess={this.emailChangeSuccess}
                        closeDialog={this.closeConfirmPassword}
                    />
                )}

                {editParticipantDialog && (
                    <EditParticipantInformationMobile
                        opened={editParticipantDialog}
                        closeDialog={this.handleCloseDialog}
                        participant={participantData}
                    />
                )}

                {smartMatchingDialog && (
                    <EditSmartMatchingAnswersMobile
                        opened={smartMatchingDialog}
                        closeDialog={this.handleCloseDialog}
                        handleRemoveMatchingData={this.handleRemoveMatchingData}
                    />
                )}

                {editExhibitorContactPersonDialog && (
                    <EditExhibitorContactPersonInformationMobile
                        opened={editExhibitorContactPersonDialog}
                        closeDialog={this.handleCloseDialog}
                        exhibitor={exhibitorData}
                    />
                )}

                {editScholarContactPersonDialog && (
                    <EditScholarContactPersonInformation
                        opened={editScholarContactPersonDialog}
                        closeDialog={this.handleCloseDialog}
                        exhibitor={exhibitorData}
                    />
                )}

                {createParticipantProfileDialog && (
                    <CreateOrganizerParticipantProfileMobile
                        opened={createParticipantProfileDialog}
                        closeDialog={this.handleCloseDialog}
                    />
                )}

                {unregisterFromEventDialog && (
                    <ConfirmDialog
                        open={unregisterFromEventDialog}
                        closeConfirm={this.handleCloseDialogUnregisterFromEvent}
                        dialogTitle={`${translation?.myAccountPage.unregisterCurrentEventButton}`}
                        dialogDescription={`${translation?.myAccountPage.unregisterCurrentEventConfirmation} ${event.name}`}
                        handleConfirm={this.unregisterFromEvent}
                        dialogCancelButtonLabel={`${translation?.generalText.cancel}`}
                        dialogConfirmButtonLabel={`${translation?.generalText.confirm}`}
                        mobile
                    />
                )}

                {isPasswordCheckOn && (
                    <>
                        <ConfirmPassword
                            open={isPasswordCheckOn}
                            password={password}
                            passwordError={passwordError}
                            handleChangePassword={this.handleChangePasswordData}
                            dialogTitle={
                                translation.emailUpdate.confirmPassword ||
                                defaultTranslation.emailUpdate.confirmPassword
                            }
                            handleConfirm={this.handlePasswordCorrection}
                            closeConfirm={this.closeConfirmPassword}
                            dialogContent={
                                translation.emailUpdate.confirmPasswordDescription ||
                                defaultTranslation.emailUpdate.confirmPasswordDescription
                            }
                        />
                    </>
                )}
                {emailChangeSuccess && (
                    <SnackbarGlobal
                        message={'Success! You changed the email assigned to your account\n'}
                        snackbarOpen={emailChangeSuccess}
                        handleCloseSnackbar={() => this.emailChangeSuccess(false)}
                        icon={<CheckIcon />}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        event: state.event.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        eventUsers: state.eventUsers,
        profileTabs: state.user.profileTabs,
        languages: state.languages,
        defaultTranslation: state.languages.translations['en'],
        translation: state.languages.translations[state.languages.platformLanguage],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUsers: (eventId) => dispatch(actions.getUsers(eventId)),
        onRefreshUserUserData: () => dispatch(actions.refreshUserData()),
        closeTopNavigation: () => dispatch(actions.topNavClose()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        seeOnventProfile: () => dispatch(actions.topNavOnventProfile()),
        seeEventProfile: () => dispatch(actions.topNavEventProfile()),
        onSetPlatformLanguage: (languageCode) => dispatch(actions.setPlatformLanguage(languageCode)),
        onRemoveUserMatchingData: (eventId) => dispatch(actions.removeUserMatchingData(eventId)),
        resetEventData: () => dispatch(actions.resetEventData()),
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
        onDeleteCoOrganizer: (eventId, userId) => dispatch(actions.deleteCoOrganizer(eventId, userId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccountMobile));
