import Button from '@material-ui/core/Button';
import {ReactComponent as Users} from './helpers/svgs/meeting.svg';
import {ReactComponent as Decline} from './helpers/svgs/decline.svg';
import React, {useState} from 'react';
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect';
import Confirm from '../../../../Dialogs/Confirm';
import {useSelector} from 'react-redux';
import {useCalculateDateByTimezoneDifference} from './helpers/customHooks';
import {Tooltip} from '@material-ui/core';
import {monthNames, getAllMonthsTranslated} from '../../../../Utils/utils';
import {usePlatformTranslation} from 'services/hooks';

const SingleMeetingItemAgenda = ({
    singleMeeting,
    openPrivateChatMyAgenda,
    cancelMeetingRequest,
    acceptMeetingRequest,
}) => {
    //REDUX
    const userMe = useSelector((state) => state.user);
    const languages = useSelector((state) => state?.languages);
    const platformLanguage = useSelector((state) => state.languages?.platformLanguage);

    //LOCAL STATES
    const [openConfirmCancelMeeting, setOpenConfirmCancelMeeting] = useState(false);

    /*translations*/
    const pastTranslation = usePlatformTranslation((state) => state?.agendaTranslations?.past);
    const pendingTranslation = usePlatformTranslation((state) => state?.meetingsDropdown?.pending);
    const requestedTranslation = usePlatformTranslation((state) => state?.agendaTranslations?.requested);
    const acceptedTranslation = usePlatformTranslation((state) => state?.meetingsDropdown?.accepted);
    const nowTranslation = usePlatformTranslation((state) => state?.agendaTranslations?.now);
    const meetingWithTranslation = usePlatformTranslation((state) => state?.meetingsDropdown?.meetingWith);
    const declineTranslation = usePlatformTranslation((state) => state?.generalText?.decline);
    const acceptTranslation = usePlatformTranslation((state) => state?.generalText?.accept);
    const gotoMeetingTranslation = usePlatformTranslation((state) => state?.agendaTranslations?.goToMeeting);
    const cancelTranslation = usePlatformTranslation((state) => state?.generalText?.cancel);
    const confirmTranslation = usePlatformTranslation((state) => state?.generalText?.confirm);
    const cancelTitleTranslation = usePlatformTranslation((state) => state?.meetings.banner.cancelTitle);
    const cancelDescriptionTranslation = usePlatformTranslation((state) => state?.meetings.banner.cancelDescription);

    /* end translations*/

    //FORMATED ACCORDING TO TIMEZONE
    let meetingDateFix = useCalculateDateByTimezoneDifference(singleMeeting?.start);

    //FORMATED MEETING DATE- RETURNS A STRING
    const meetingDate = () => {
        let monthsInAllLangs = getAllMonthsTranslated(languages);
        let day = meetingDateFix?.format('DD');
        let month = monthNames.findIndex((el) => el === meetingDateFix.format('MMMM'));
        let newMonth = monthsInAllLangs[month].charAt(0).toUpperCase() + monthsInAllLangs[month].slice(1);
        let year = meetingDateFix.format('YYYY');
        return `${day} ${newMonth}, ${year} `;
    };

    //BEFORE REMOVE A MEETING, A POPUP APPEARS - THIS IS THE TRIGGER EVENT
    const handlePopUp = () => {
        setOpenConfirmCancelMeeting((prevState) => !prevState);
    };

    let accepted = singleMeeting?.status === 'accepted';

    //TO SHOW USER IS MEETING IS REQUESTED TO HIM OR HE REQUEST MEETING FROM SOMEONE OTHER
    let meetingWithWhom = () => {
        return singleMeeting?.owner?.user?._id === userMe?.data?._id
            ? `${singleMeeting?.partner?.user?.first} ${singleMeeting?.partner?.user?.last}`
            : `${singleMeeting?.owner?.user?.first} ${singleMeeting?.owner?.user?.last}`;
    };

    return (
        <>
            <div className="element-control">
                <div
                    className={`single-agenda-item-container ${singleMeeting?.isPast && 'pastslot-color'} ${
                        platformLanguage === 'ar' && 'lang-ar'
                    } ${isMobileOnly ? 'mobile' : ''}`}
                >
                    {isMobile ? (
                        <>
                            {isTablet ? (
                                <>
                                    <div className="time-block">
                                        <span
                                            className={`action-icon meeting-req ${
                                                singleMeeting?.isPast && 'pastslot-color'
                                            }`}
                                        >
                                            <Users />
                                        </span>
                                        <span
                                            className={`start-time-of-req ${singleMeeting?.isPast && 'pastslot-color'}`}
                                        >
                                            {singleMeeting?.startHours}
                                        </span>
                                        <span
                                            className={`end-time-of-req ${singleMeeting?.isPast && 'pastslot-color'}`}
                                        >
                                            {singleMeeting?.endHours}
                                        </span>
                                    </div>
                                    <div
                                        className={`${platformLanguage === 'ar' && 'line-ar'} information-block ${
                                            singleMeeting?.status === 'pending' && 'border-color-req'
                                        }`}
                                    >
                                        <div className="state-of-req-container">
                                            <span
                                                className={`state-of-req  ${
                                                    singleMeeting?.isPast
                                                        ? 'past'
                                                        : !accepted
                                                        ? 'requested'
                                                        : singleMeeting?.isLive
                                                        ? 'live'
                                                        : 'accepted'
                                                }`}
                                            >
                                                {singleMeeting?.isPast
                                                    ? pastTranslation
                                                    : !accepted
                                                    ? singleMeeting?.owner?.user?._id === userMe?.data?._id
                                                        ? pendingTranslation
                                                        : requestedTranslation
                                                    : singleMeeting?.isLive
                                                    ? singleMeeting?.status === 'pending'
                                                        ? pendingTranslation
                                                        : nowTranslation
                                                    : acceptedTranslation}
                                            </span>
                                        </div>
                                        {singleMeeting?.status === 'pending' &&
                                            singleMeeting?.owner?.user?._id !== userMe?.data?._id && (
                                                <span className="date-of-req">{meetingDate()}</span>
                                            )}
                                        <span
                                            className={`requested-by meeting-add ${
                                                singleMeeting?.isPast && 'pastslot-color'
                                            }`}
                                        >
                                            {meetingWithWhom().length >= 30 ? (
                                                <Tooltip arrow title={meetingWithWhom()} placement="bottom-end">
                                                    <span className={'ellipsis max-w260 d-block'}>
                                                        {meetingWithTranslation} {meetingWithWhom()}
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <>
                                                    {meetingWithTranslation} {meetingWithWhom()}
                                                </>
                                            )}
                                        </span>
                                    </div>

                                    <div
                                        className={`${
                                            accepted && !singleMeeting?.isLive && 'accepted-upcoming'
                                        } action-block ${
                                            !accepted &&
                                            singleMeeting?.status === 'pending' &&
                                            singleMeeting?.owner?.user?._id === userMe?.data?._id &&
                                            'padding-add-action-block'
                                        }`}
                                    >
                                        {accepted && !singleMeeting?.isLive && !singleMeeting?.isPast ? (
                                            <div
                                                className={`mobile-action-container ${
                                                    isTablet && 'tablet-support-action-container'
                                                }`}
                                            >
                                                <button
                                                    className={`action-btn cancel`}
                                                    onClick={handlePopUp}
                                                    onKeyPress={handlePopUp}
                                                >
                                                    CANCEL
                                                </button>
                                                <button
                                                    className={`action-btn go-to-meeting`}
                                                    onKeyPress={openPrivateChatMyAgenda}
                                                    onClick={openPrivateChatMyAgenda}
                                                >
                                                    OPEN CHAT
                                                </button>
                                            </div>
                                        ) : (
                                            <button
                                                className={'x-decline-btn'}
                                                onClick={handlePopUp}
                                                onKeyPress={handlePopUp}
                                            >
                                                <Decline />
                                            </button>
                                        )}

                                        {!accepted ? (
                                            <>
                                                {singleMeeting?.owner?.user?._id === userMe?.data?._id ? (
                                                    <button
                                                        className={'x-decline-btn'}
                                                        onClick={handlePopUp}
                                                        onKeyPress={handlePopUp}
                                                    >
                                                        <Decline />
                                                    </button>
                                                ) : (
                                                    <Button
                                                        className="item-decline-button"
                                                        onClick={handlePopUp}
                                                        onKeyPress={handlePopUp}
                                                    >
                                                        {declineTranslation}
                                                    </Button>
                                                )}

                                                {singleMeeting?.owner?.user?._id !== userMe?.data?._id && (
                                                    <Button
                                                        className="item-accept-button"
                                                        onKeyPress={acceptMeetingRequest(singleMeeting)}
                                                        onClick={acceptMeetingRequest(singleMeeting)}
                                                    >
                                                        {acceptTranslation}
                                                    </Button>
                                                )}
                                            </>
                                        ) : singleMeeting?.isLive && singleMeeting?.status !== 'pending' ? (
                                            <Button
                                                className={`item-accept-button mr10agenda ${
                                                    platformLanguage === 'ar' && 'ar-sup'
                                                }`}
                                                onKeyPress={openPrivateChatMyAgenda}
                                                onClick={openPrivateChatMyAgenda}
                                            >
                                                {gotoMeetingTranslation}
                                            </Button>
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className={`${platformLanguage === 'ar' && 'lang-ar'} header-time-status ${
                                            singleMeeting?.isPast && 'pastslot-color'
                                        }`}
                                    >
                                        <Users />
                                        <span
                                            className={`${platformLanguage === 'ar' && 'lang-ar'} time-of-meeting ${
                                                singleMeeting?.isPast && 'pastslot-color'
                                            }`}
                                        >
                                            {singleMeeting?.startHours} - {singleMeeting?.endHours}{' '}
                                            {singleMeeting?.status === 'pending' &&
                                                singleMeeting?.owner?.user?._id !== userMe?.data?._id &&
                                                `| ${meetingDate()}`}
                                        </span>
                                        <span
                                            className={`status-mobile ${platformLanguage === 'ar' && 'lang-ar'} ${
                                                singleMeeting?.isPast
                                                    ? 'past'
                                                    : !accepted
                                                    ? 'requested'
                                                    : singleMeeting?.isLive
                                                    ? 'live'
                                                    : 'accepted'
                                            } `}
                                        >
                                            {singleMeeting?.isPast
                                                ? pastTranslation
                                                : !accepted
                                                ? singleMeeting?.owner?.user?._id === userMe?.data?._id
                                                    ? pendingTranslation
                                                    : requestedTranslation
                                                : singleMeeting?.isLive
                                                ? singleMeeting?.status === 'pending'
                                                    ? pendingTranslation
                                                    : nowTranslation
                                                : acceptedTranslation}
                                        </span>

                                        {!singleMeeting.isLive &&
                                            singleMeeting?.status === 'pending' &&
                                            singleMeeting?.owner?.user?._id === userMe?.data?._id && (
                                                <button
                                                    className={`x-decline-btn ${
                                                        platformLanguage === 'ar' ? 'lang-ar' : ''
                                                    } ${isMobile ? 'mobile-support' : ''}`}
                                                    onClick={handlePopUp}
                                                    onKeyPress={handlePopUp}
                                                >
                                                    <Decline />
                                                </button>
                                            )}
                                    </div>
                                    <h3
                                        className={`header-of-meeting ${singleMeeting?.isPast && 'pastslot-color'} ${
                                            platformLanguage === 'ar' && 'lang-ar'
                                        }`}
                                    >
                                        {meetingWithWhom().length >= 30 ? (
                                            <Tooltip
                                                title={meetingWithWhom()}
                                                className={`${singleMeeting?.isPast && 'pastslot-color'}`}
                                                placement="bottom-end"
                                                arrow
                                            >
                                                <span className={`ellipsis max-w260 d-block `}>
                                                    {meetingWithTranslation} {meetingWithWhom()}
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <>
                                                {meetingWithTranslation} {meetingWithWhom()}
                                            </>
                                        )}
                                    </h3>

                                    <div
                                        className={`${platformLanguage === 'ar' && 'lang-ar'} ${
                                            accepted && !singleMeeting?.isLive && 'accepted-upcoming'
                                        } action-block ${
                                            singleMeeting?.status !== 'pending' && 'padding-add-action-block'
                                        } ${isMobile ? 'mobile-support-action-btns' : ''}`}
                                    >
                                        {!isMobile && accepted && !singleMeeting?.isLive && (
                                            <button
                                                className={'x-decline-btn'}
                                                onClick={handlePopUp}
                                                onKeyPress={handlePopUp}
                                            >
                                                <Decline />
                                            </button>
                                        )}
                                        {!accepted ? (
                                            <>
                                                {singleMeeting?.owner?.user?._id === userMe?.data?._id ? null : (
                                                    <Button
                                                        className="item-decline-button"
                                                        onClick={handlePopUp}
                                                        onKeyPress={handlePopUp}
                                                    >
                                                        {gotoMeetingTranslation}
                                                    </Button>
                                                )}

                                                {singleMeeting?.owner?.user?._id !== userMe?.data?._id && (
                                                    <Button
                                                        className="item-accept-button"
                                                        onClick={acceptMeetingRequest(singleMeeting)}
                                                        onKeyPress={acceptMeetingRequest(singleMeeting)}
                                                    >
                                                        {acceptTranslation}
                                                    </Button>
                                                )}
                                            </>
                                        ) : singleMeeting?.isLive && singleMeeting?.status !== 'pending' ? (
                                            <Button
                                                className={`item-accept-button mr10agenda ${
                                                    isMobile ? 'phone-support' : ''
                                                } ${platformLanguage === 'ar' ? 'ar-sup' : ''}`}
                                                onClick={openPrivateChatMyAgenda}
                                                onKeyPress={openPrivateChatMyAgenda}
                                            >
                                                {gotoMeetingTranslation}
                                            </Button>
                                        ) : null}
                                    </div>
                                    {accepted && !singleMeeting?.isLive && (
                                        <div
                                            className={`mobile-action-container ${
                                                isTablet && 'tablet-support-action-container'
                                            }`}
                                        >
                                            <button
                                                className={`action-btn cancel`}
                                                onClick={handlePopUp}
                                                onKeyPress={handlePopUp}
                                            >
                                                CANCEL
                                            </button>
                                            <button
                                                className={`action-btn go-to-meeting`}
                                                onClick={openPrivateChatMyAgenda}
                                                onKeyPress={openPrivateChatMyAgenda}
                                            >
                                                OPEN CHAT
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        //desktop starts
                        <>
                            <div className="time-block">
                                <span
                                    className={`action-icon meeting-req ${singleMeeting?.isPast && 'pastslot-color'}`}
                                >
                                    <Users />
                                </span>
                                <span className={`start-time-of-req ${singleMeeting?.isPast && 'pastslot-color'}`}>
                                    {singleMeeting?.startHours}
                                </span>
                                <span className={`end-time-of-req ${singleMeeting?.isPast && 'pastslot-color'}`}>
                                    {singleMeeting?.endHours}
                                </span>
                            </div>
                            <div
                                className={`information-block ${
                                    singleMeeting?.status === 'pending' && 'border-color-req'
                                } ${platformLanguage === 'ar' && 'line-ar'}`}
                            >
                                <div className="state-of-req-container">
                                    <span
                                        className={`state-of-req ${
                                            singleMeeting?.isPast
                                                ? 'past'
                                                : !accepted
                                                ? 'requested'
                                                : singleMeeting?.isLive
                                                ? 'live'
                                                : 'accepted'
                                        }`}
                                    >
                                        {singleMeeting?.isPast
                                            ? pastTranslation
                                            : !accepted
                                            ? singleMeeting?.owner?.user?._id === userMe?.data?._id
                                                ? pendingTranslation
                                                : requestedTranslation
                                            : singleMeeting?.isLive
                                            ? singleMeeting?.status === 'pending'
                                                ? pendingTranslation
                                                : nowTranslation
                                            : acceptedTranslation}
                                    </span>
                                </div>

                                {singleMeeting?.status === 'pending' &&
                                    singleMeeting?.owner?.user?._id !== userMe?.data?._id && (
                                        <span className="date-of-req">{meetingDate()}</span>
                                    )}
                                <span
                                    className={`requested-by meeting-add ${singleMeeting?.isPast && 'pastslot-color'} ${
                                        singleMeeting?.isPast && 'pastslot-color'
                                    }
                                    `}
                                >
                                    {/*
                                    <span className={'meeting-with'}></span>
*/}
                                    {meetingWithWhom().length >= 30 ? (
                                        <Tooltip title={meetingWithWhom()} placement="bottom-end" arrow>
                                            <span
                                                className={`ellipsis max-w260 d-block ${
                                                    singleMeeting?.isPast && 'pastslot-color'
                                                }`}
                                            >
                                                {meetingWithTranslation} {meetingWithWhom()}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <>
                                            {meetingWithTranslation} {meetingWithWhom()}
                                        </>
                                    )}
                                </span>
                            </div>
                            <div
                                className={`${accepted && !singleMeeting?.isLive && 'accepted-upcoming'} action-block ${
                                    !accepted &&
                                    singleMeeting?.status === 'pending' &&
                                    singleMeeting?.owner?.user?._id === userMe?.data?._id &&
                                    'padding-add-action-block'
                                }`}
                            >
                                {accepted && !singleMeeting?.isLive && (
                                    //x bir
                                    <button className={'x-decline-btn'} onClick={handlePopUp} onKeyPress={handlePopUp}>
                                        <Decline />
                                    </button>
                                )}
                                {!accepted ? (
                                    <>
                                        {singleMeeting?.owner?.user?._id === userMe?.data?._id ? (
                                            <button
                                                className={'x-decline-btn'}
                                                onClick={handlePopUp}
                                                onKeyPress={handlePopUp}
                                            >
                                                <Decline />
                                            </button>
                                        ) : (
                                            <Button
                                                className="item-decline-button"
                                                onClick={handlePopUp}
                                                onKeyPress={handlePopUp}
                                            >
                                                {declineTranslation}
                                            </Button>
                                        )}

                                        {singleMeeting?.owner?.user?._id !== userMe?.data?._id && (
                                            <Button
                                                className="item-accept-button"
                                                onClick={acceptMeetingRequest(singleMeeting)}
                                                onKeyPress={acceptMeetingRequest(singleMeeting)}
                                            >
                                                {acceptTranslation}
                                            </Button>
                                        )}
                                    </>
                                ) : singleMeeting?.isLive && singleMeeting?.status !== 'pending' ? (
                                    <>
                                        <button
                                            className={'x-decline-btn'}
                                            onClick={handlePopUp}
                                            onKeyPress={handlePopUp}
                                        >
                                            <Decline />
                                        </button>
                                        <Button
                                            className={`item-accept-button liveMeeting mr10agenda ${
                                                platformLanguage === 'ar' && 'ar-lang-desktop'
                                            }`}
                                            onClick={openPrivateChatMyAgenda}
                                            onKeyPress={openPrivateChatMyAgenda}
                                        >
                                            {gotoMeetingTranslation}
                                        </Button>
                                    </>
                                ) : null}
                            </div>
                        </>
                    )}
                </div>
                <div className={`single-agenda-border-bottom ${isMobile ? 'set-for-mobile' : ''}`}></div>
            </div>

            {openConfirmCancelMeeting && (
                <Confirm
                    open={singleMeeting ? openConfirmCancelMeeting : false}
                    closeConfirm={handlePopUp}
                    dialogTitle={cancelTitleTranslation}
                    dialogDescription={cancelDescriptionTranslation}
                    handleConfirm={cancelMeetingRequest(singleMeeting)}
                    dialogCancelButtonLabel={cancelTranslation}
                    dialogConfirmButtonLabel={confirmTranslation}
                    classList={['preserveHeaderDropdown']}
                />
            )}
        </>
    );
};
SingleMeetingItemAgenda.defaultProps = {
    isLive: false,
    accepted: false,
};
export default SingleMeetingItemAgenda;
