import React from 'react';
import Radio from '@material-ui/core/Radio';
import {getCurrencySymbol, preventDefaultDrag} from 'Utils/utils';
import {ReactComponent as Arrow} from '../LoginRegistration/images/arrow_up_down.svg';
import classnames from 'classnames';
import {hpColorWithCustomOpacity} from '../LoginRegistration/Utils';
import {useSelector} from 'react-redux';

const AccessPackage = ({
    translation,
    eventInfo,
    accessPackages,
    hasAccessManagement,
    paidEvent,
    preselectedAccessPackageId,
    selectedPackageId,
    selectPackage,
    errorAccessPackageNotSelected,
}) => {
    const customColor = eventInfo && hpColorWithCustomOpacity(0.1, eventInfo, 'primary');

    return (
        <>
            {eventInfo[hasAccessManagement] && !preselectedAccessPackageId && (
                <div onDragStart={preventDefaultDrag} className="choose-package">
                    <h3 onDragStart={preventDefaultDrag}>
                        {translation?.participantRegistration.choosePackageTitle.charAt(0).toUpperCase() +
                            translation?.participantRegistration.choosePackageTitle.slice(1).toLowerCase()}
                    </h3>
                    {accessPackages.map((accessPackage) => {
                        if (!accessPackage.isActive || !accessPackage.showOnRegistration) {
                            return null;
                        }
                        const isSelected = accessPackage._id === selectedPackageId;

                        return (
                            <div
                                style={{
                                    backgroundColor: isSelected && customColor,
                                }}
                                className={classnames('package-container', {
                                    'active-package': isSelected,
                                })}
                                onDragStart={preventDefaultDrag}
                                key={accessPackage._id}
                            >
                                <div
                                    className={'package ' + (isSelected ? 'selected' : '')}
                                    key={accessPackage._id}
                                    onClick={selectPackage(accessPackage._id)}
                                >
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="name-container">
                                        <Radio checked={isSelected} color={'primary'} />
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className={classnames(
                                                'name',
                                                'ellipsis',
                                                'single-package-headline-container',
                                                {
                                                    'active-package-padding': isSelected,
                                                }
                                            )}
                                        >
                                            <span className={`package-name`}>{accessPackage.name}</span>
                                            {eventInfo[paidEvent] && (
                                                <span className={`package-price`}>
                                                    {accessPackage.isFree
                                                        ? eventInfo[paidEvent] && 'FREE'
                                                        : `${getCurrencySymbol(eventInfo.currency)} ${
                                                              accessPackage.price
                                                          }`}
                                                </span>
                                            )}
                                        </div>
                                        <Arrow className={classnames({'selected-package': isSelected})} />
                                    </span>
                                </div>
                                {isSelected && (
                                    <p onDragStart={preventDefaultDrag} className="package-description">
                                        {accessPackage.description}
                                    </p>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
            {errorAccessPackageNotSelected && (
                <p onDragStart={preventDefaultDrag} className="error-text">
                    {translation?.participantRegistration.noPackageText}
                </p>
            )}
        </>
    );
};

export default AccessPackage;
