import React from 'react';
import {connect} from 'react-redux';
import SamplePdf from '../../../Assets/samplePdf2.pdf';
import BoothPdfPreview from '../../../Dialogs/ExhibitorBooth/BoothPdfPreview';

// import { Document, Page } from 'react-pdf';

// fix for no Worker
import {Document, Page, pdfjs} from 'react-pdf';
import {checkBoothOwnerOrRepresentative, preventDefaultDrag} from '../../../Utils/utils';
import moment from 'moment';
import axios from '../../../store/axios-instance';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PdfPreview extends React.Component {
    state = {
        modalOpened: false,
    };

    getPdfUrl = () => {
        const {exhibitor} = this.props;
        if (window.location.hostname === 'localhost') {
            return SamplePdf;
        } else {
            return `${exhibitor.filesUrl}${exhibitor.booth.pdfPresentation}`;
        }
    };

    getPdfFileWidth = () => {
        let width, boothRenderHeight;
        let windowHeight = window.innerHeight;
        boothRenderHeight = windowHeight - 315;
        if (windowHeight < 700) {
            boothRenderHeight = windowHeight - 210;
        }
        width = boothRenderHeight * 0.76;
        return width;
    };

    handleOpenPdfModal = () => {
        const {exhibitor} = this.props;
        if (exhibitor?.booth?.pdfPresentation) {
            this.handleClickOnDocument(exhibitor.booth.pdfPresentation, exhibitor.booth.pdfPresentation);
        }
        this.setState({
            modalOpened: true,
        });
    };

    handleClosePdfModal = () => {
        this.setState({
            modalOpened: false,
        });
    };

    handleClickOnDocument = (documentId, documentName) => {
        const actionType = 'boothDocuments';
        const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
        let trackBoothStatistics = !isRepresentative;

        const {event, user} = this.props;
        const {owner} = event;
        if (owner._id === user._id) {
            trackBoothStatistics = false;
            let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
            if (findEventRoles) {
                if (findEventRoles?.roles?.includes('participant')) {
                    trackBoothStatistics = true;
                }
            }
        }

        if (documentId && documentName && trackBoothStatistics) {
            const data = {
                userId: this.props.user._id,
                documentId: documentId,
                documentName: documentName,
                actionType: actionType,
                timestamp: moment.utc(),
            };
            axios({
                method: 'post',
                url: '/exhibitor/' + this.props.exhibitor._id + '/booth-statistics',
                data: data,
            })
                .then((res) => {})
                .catch((err) => {});
        }
    };

    render() {
        const {exhibitor, isLargeScreen, translation, defaultTranslation} = this.props;
        const width = this.getPdfFileWidth();
        if (!exhibitor.booth.pdfPresentation && isLargeScreen) {
            return null;
        }

        return (
            <>
                {!isLargeScreen ? (
                    <>
                        {exhibitor.booth.pdfPresentation.length > 0 ? (
                            <div
                                id="pdfPresentation"
                                className="clickable-element booth-pdf-presentation"
                                onClick={this.handleOpenPdfModal}
                            >
                                <div onDragStart={preventDefaultDrag} className="open-file">
                                    <span>
                                        {translation?.showfloor.openPosterFile ||
                                            defaultTranslation?.showfloor.openPosterFile}
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <button
                                id="pdfPresentation"
                                disabled={exhibitor.booth.pdfPresentation.length === 0}
                                onClick={this.handleOpenPdfModal}
                            >
                                <div onDragStart={preventDefaultDrag} className="open-file">
                                    <span>
                                        {translation?.showfloor.fileIsNotAvailable ||
                                            defaultTranslation?.showfloor.fileIsNotAvailable}
                                    </span>
                                </div>
                            </button>
                        )}
                    </>
                ) : (
                    <div
                        id="pdfPresentation"
                        className="clickable-element booth-pdf-presentation"
                        onClick={this.handleOpenPdfModal}
                    >
                        <Document file={this.getPdfUrl()} onLoadError={console.error}>
                            <Page pageNumber={1} width={width} />
                            <Page pageNumber={2} width={width} />
                            <Page pageNumber={3} width={width} />
                        </Document>
                    </div>
                )}
                <BoothPdfPreview opened={this.state.modalOpened} closeDialog={this.handleClosePdfModal} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        exhibitor: state.exhibitor.data,
        loadingExhibitor: state.exhibitor.loading,
        isLargeScreen: state.layout.isLargeScreen,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        user: state.user.data,
        event: state.event.data,
    };
};

export default connect(mapStateToProps)(PdfPreview);
