import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import {preventDefaultDrag} from '../../../../Utils/utils';
import Switch from '@material-ui/core/Switch';
import {DeleteOutlineOutlined} from '@material-ui/icons';
class RoleTitle extends React.Component {
    render() {
        const {role, isActive, handleDeleteRoleDialog, roleTitle} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className={`accordion-title-details ${isActive ? 'expanded' : ''}`}>
                <p onDragStart={preventDefaultDrag} className="title">
                    {role?.name ? role?.name : `New ${roleTitle}`}
                </p>
                {isActive ? (
                    <div onDragStart={preventDefaultDrag} className="expand-label">
                        {roleTitle === 'speaker' && (
                            <>
                                <Switch tabIndex="-1" color="secondary" name={'showTimezone'} />
                                <span style={{fontWeight: 'bold'}}>SHOW</span>
                            </>
                        )}

                        <Button
                            type="button"
                            onClick={handleDeleteRoleDialog}
                            startIcon={<DeleteOutlineOutlined />}
                        >
                            Delete
                        </Button>
                        <Button
                            type="label"
                            startIcon={<ExpandLessIcon />}
                        >
                            COLLAPSE
                        </Button>
                    </div>
                ) : (
                    <Button
                        type="label"
                        startIcon={<ExpandMoreIcon />}
                    >
                        EXPAND
                    </Button>
                )}
            </div>
        );
    }
}

export default RoleTitle;
