import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import {getPrivateChatId, preventDefaultDrag} from '../Utils/utils';
import colors from '../CSS/_variables.module.scss';
import AcceptButton from '../Images/svg/AcceptButton';
import RequestSent from '../Images/svg/RequestSent';
import RejectRequest from '../Images/svg/RejectRequest';
import {ReactComponent as FacebookIcon} from '../Images/svg/facebook-logo.svg';
import {ReactComponent as LinkedinIcon} from '../Images/svg/linkedin-sign.svg';
import {ReactComponent as TwitterIcon} from '../Images/svg/twitter.svg';
import {ReactComponent as Connect} from '../Images/svg/add-button.svg';
import OpenChatIcon from '../Images/svg/OpenChat';

class SingleUser extends React.Component {
    handleOpenPrivateChat = () => {
        const targetUserId = this.props.user.user;
        const privateChatId = getPrivateChatId(this.props.privateChats, targetUserId);

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (targetUserId !== this.props.userLoggedIn._id && !this.props.loading) {
                this.props.onCreatePrivateChat(targetUserId);
            }
        }
    };

    handleAcceptFriendRequest = () => {
        this.props.onAcceptContactRequest(this.props.requestId);
    };

    handleDeclineFriendRequest = () => {
        this.props.onDeclineContactRequest(this.props.requestId);
    };

    handleSendFriendConnection = () => {
        const targetUserId = this.props.user.user;
        this.props.onSendContactRequest(targetUserId);
    };

    render() {
        const {user, userLoggedIn, defaultTranslation} = this.props;
        return (
            <li>
                <div
                    onDragStart={preventDefaultDrag}
                    className={'user-container ' + (this.props.isOnline ? 'is-online' : 'is-offline')}
                >
                    <div
                        className="participant-avatar"
                        style={{
                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarSmall}')`,
                        }}
                    />
                    <div onDragStart={preventDefaultDrag} className="user-details">
                        <div>
                            <div onDragStart={preventDefaultDrag} className="user-name">
                                <p onDragStart={preventDefaultDrag} className="user-name-top">
                                    {user.first} {user.last}
                                </p>
                                <p >
                                    {user.title}
                                    <span className="user-company-span">
                                        {user.title && (user.company || user.affiliation) ? ' at' : null}{' '}
                                    </span>
                                    {user.company || user.affiliation}
                                </p>
                            </div>
                            <div>
                                {'socialMediaLinks' in user &&
                                    user.socialMediaLinks.map((social) => {
                                        if (social.platform === 'LinkedIn') {
                                            return (
                                                <a
                                                    href={social.link}
                                                    target="_blank"
                                                    className="social-button"
                                                    key={social._id}
                                                    rel="noopener noreferrer"
                                                >
                                                    <div>
                                                        <LinkedinIcon fill={colors.grey} />
                                                    </div>
                                                </a>
                                            );
                                        }
                                        if (social.platform === 'Facebook') {
                                            return (
                                                <a
                                                    href={social.link}
                                                    target="_blank"
                                                    className="social-button"
                                                    key={social._id}
                                                    rel="noopener noreferrer"
                                                >
                                                    <div>
                                                        <FacebookIcon fill={colors.grey} />
                                                    </div>
                                                </a>
                                            );
                                        }
                                        if (social.platform === 'Twitter') {
                                            return (
                                                <a
                                                    href={social.link}
                                                    target="_blank"
                                                    className="social-button"
                                                    key={social._id}
                                                    rel="noopener noreferrer"
                                                >
                                                    <div>
                                                        <TwitterIcon fill={colors.grey} />
                                                    </div>
                                                </a>
                                            );
                                        }
                                        return null;
                                    })}
                            </div>
                        </div>
                        <div onDragStart={preventDefaultDrag} className="actions">
                            <div
                                onDragStart={preventDefaultDrag}
                                className={'connection ' + (this.props.friendConnectionReceived ? null : 'no-action')}
                            >
                                {user.user !== userLoggedIn._id && (
                                    <button
                                        className="participant-button open-chat"
                                        onClick={this.handleOpenPrivateChat}
                                        disabled={this.props.loading}
                                    >
                                        <div>
                                            <OpenChatIcon primary={colors.primary} secondary={colors.secondary} />
                                            <span>{defaultTranslation?.networking.chat}</span>
                                        </div>
                                    </button>
                                )}
                            </div>
                            {this.props.friendConnectionReceived ? (
                                <div>
                                    <button className="accept-request" onClick={this.handleAcceptFriendRequest}>
                                        <AcceptButton
                                            fillSecondary={colors.secondary}
                                            strokeSecondary={colors.secondary}
                                        />
                                        <span>Add</span>
                                    </button>
                                    <button className="reject-request" onClick={this.handleDeclineFriendRequest}>
                                        <RejectRequest
                                            fillErrorLight={colors.errorLight}
                                            strokeErrorLight={colors.errorLight}
                                        />
                                        <span>Reject</span>
                                    </button>
                                </div>
                            ) : null}
                            {this.props.friendConnectionSent ? (
                                <div onDragStart={preventDefaultDrag} className="connection">
                                    <button className="participant-button request-sent" disabled>
                                        <RequestSent fillWarning={colors.warning} />
                                    </button>
                                </div>
                            ) : null}
                            {this.props.canConnectTo ? (
                                <div onDragStart={preventDefaultDrag} className="connection">
                                    <button
                                        className="participant-button add-button"
                                        onClick={this.handleSendFriendConnection}
                                    >
                                        <Connect fill={colors.secondary} />
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        privateChats: state.user.data.privateChats,
        loading: state.user.loading,
        userLoggedIn: state.user.data,
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSendContactRequest: (to) => dispatch(actions.sendContactRequest(to)),
        onAcceptContactRequest: (requestId) => dispatch(actions.acceptContactRequest(requestId)),
        onDeclineContactRequest: (requestId) => dispatch(actions.declineContactRequest(requestId)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId) => dispatch(actions.createPrivateChat(targetUserId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleUser);
