import {random} from 'lodash';
import './EventProfileItem.scss';

export const EventProfileItem = ({label, value, id = random(100000, 999999), rtl = false}) => {
    if (!value) {
        value = '-';
    }
    return (
        <li key={id} className={'event-profile-item'}>
            <span className="event-profile-item-label">{label}</span>
            <span className="event-profile-item-colon">:</span>
            <span className="event-profile-item-value">{value}</span>
        </li>
    );
};
