import EmailChangeForm from './components/EmailChangeForm';
import EmailChangeHeader from './components/EmailChangeHeader';
import {isMobile} from 'react-device-detect';
import React, {useState} from 'react';
import CheckIcon from '@material-ui/icons/Check';
import {ReactComponent as Alert} from './svg/error.svg';

import SnackbarGlobal from 'SmallLayoutComponents/Snackbars/SnackbarGlobal';
import {useSelector} from 'react-redux';
const EmailChange = ({closeConfirmPassword, passwordCorrected, emailChangeSuccess: emailChangeSuccessMobile}) => {
    //redux
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const platformLanguage = useSelector((state) => state.languages.platformLanguage);

    const [emailChangedSuccess, setEmailChangedSuccess] = useState(false);
    const [emailChangedFailed, setEmailChangedFailed] = useState(false);
    const [activeStep, setActiveStep] = useState('emailChange');

    const emailChangeSuccess = (result) => {
        setEmailChangedSuccess(result);
        isMobile && emailChangeSuccessMobile(result);
    };
    const emailChangeFailed = (result) => {
        setEmailChangedFailed(result);
    };

    const handleActiveStep = (step) => {
        setActiveStep(step);
    };

    return (
        <div
            className={`email-change-container ${isMobile ? 'mobile-support' : ''} ${
                passwordCorrected ? 'success-light' : ''
            } ${platformLanguage === 'ar' ? 'arabic-support' : ''}`}
        >
            <EmailChangeHeader
                title={
                    activeStep === 'emailChange'
                        ? translation.emailUpdate.changeEmail || defaultTranslation.emailUpdate.changeEmail
                        : translation.emailUpdate.enterTheConfirmationCode ||
                          defaultTranslation.emailUpdate.enterTheConfirmationCode
                }
                description={
                    activeStep === 'emailChange'
                        ? translation.emailUpdate.changeEmailDescription ||
                          defaultTranslation.emailUpdate.changeEmailDescription
                        : ''
                }
            />
            <EmailChangeForm
                closeConfirmPassword={closeConfirmPassword}
                handleActiveStep={handleActiveStep}
                emailChangeSuccess={() => emailChangeSuccess(true)}
                emailChangeFailed={() => emailChangeFailed(true)}
            />

            <SnackbarGlobal
                message={'Success! You changed the email assigned to your account\n'}
                snackbarOpen={emailChangedSuccess && !isMobile}
                handleCloseSnackbar={() => emailChangeSuccess(false)}
                icon={<CheckIcon />}
            />

            <SnackbarGlobal
                message={'Error. Try again later'}
                snackbarOpen={emailChangedFailed}
                handleCloseSnackbar={() => emailChangeFailed(false)}
                icon={<Alert />}
                isError={true}
            />
        </div>
    );
};

export default EmailChange;
