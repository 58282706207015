import React from 'react';
import {preventDefaultDrag} from '../../Utils/utils';

class AuditoriumBanners extends React.Component {
    render() {
        const {filesUrl, image} = this.props;
        return (
            <>
                <div onDragStart={preventDefaultDrag} className="clickable-element no-hover" id="bannerLeft">
                    {image ? (
                        <img
                            draggable="false"
                            src={filesUrl + image}
                            alt="Logo"
                            className="auditorium-banner-image"
                        />
                    ) : null}
                </div>
                <div onDragStart={preventDefaultDrag} className="clickable-element no-hover" id="bannerRight">
                    {image ? (
                        <img
                            draggable="false"
                            src={filesUrl + image}
                            alt="Logo"
                            className="auditorium-banner-image"
                        />
                    ) : null}
                </div>
            </>
        );
    }
}

export default AuditoriumBanners;
