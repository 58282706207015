import React from 'react';
import {Link} from 'react-router-dom';
import SideMenu from '../../Components/SideMenu/SideMenu';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import NotificationPresentationStarts from '../../Components/NotificationPresentationStarts';
import NoVideoImage from '../../Images/no-video.png';
import Slider from 'react-slick';
import FullOverlay from '../../SmallLayoutComponents/FullOverlay';
import BoothVideo from '../../Dialogs/ExhibitorBooth/BoothVideo';
import '../../CSS/lobby.scss';
import {ReactComponent as PlayIcon} from '../../Images/svg/play.svg';
import {
    checkIfEventHasEnded,
    checkIfLeftOrRightWindowIsUploaded,
    checkIfLeftPhotoViewIsCustomOrDefault,
    getPrivateChatId,
    getVideoIdFromYoutubeUrl,
    getWindowView,
    preventDefaultDrag,
} from '../../Utils/utils';
import {EXHIBITOR_TYPES} from '../../Utils/constants/shared';
import {disconnectFromNotifications} from '../../Api/socketApi';
import {getLobbyBackground} from '../../Utils/getBackgrounds';
import {getLobbyBackgroundUploaded} from '../../Utils/getBackgroundsUploaded';
import AuditoriumLink from '../../Components/EventMenu/Rooms/AuditoriumLink';
import ShowfloorLink from '../../Components/EventMenu/Rooms/ShowfloorLink';
import {Helmet} from 'react-helmet';
import axios from '../../store/axios-instance';
import MatchingDialog from '../../Components/EventManagement/Matching/MatchingDialog';
import MatchingBanners from '../../Components/EventManagement/Matching/MatchingBanners';
import MatchingDialogConfirm from '../../Components/EventManagement/Matching/MatchingDialogConfirm';
import callAxios from 'axios';
import {isYoutubeLinkRule} from 'Utils/validationRules';
import AnnouncementsBannerOrIcon from 'Components/AnnouncementsBanner/AnnouncementsBannerOrIcon';
import EventWelcomeScreen from 'Components/EventWelcomeScreen/EventWelcomeScreen';
import eventBranding from '../../Components/EventManagement/EventBranding';

class Lobby extends React.Component {
    state = {
        showVideo: false,
        showNoShowfloorDialog: false,
        showNoShowfloorAccessDialog: false,
        showNoAuditoriumDialog: false,
        showNoAuditoriumAccessDialog: false,
        brandingTranslation: null,
        alreadyShowed: false,
        openMatchingCloseDialog: false,
        bannerDisableOnFinish: false,
        thumbnailImage: '',
        streaming: '',
        videoLoading: true,
        invalidVideoId: false,
        bannerShouldClose: false,
    };

    componentDidMount() {
        const {event} = this.props;
        this.props.onSetEventProtectedMenu();
        this.handleSeeLiveWall();
        this.props.setHasVideoWall(false);
        this.setLanguagesData();
        this.refreshExhibitorData();
        this.getMatchingBannerShowed();
        // const {user} = this.props;

        if (event.brandingData.lobbyVideoUrl) {
            this.setThumbnailImage();
        }
    }

    componentDidUpdate(prevProps) {
        const {event} = this.props;
        if (prevProps.event._id !== event._id) {
            this.handleSeeLiveWall();
            this.setLanguagesData();
            this.refreshExhibitorData();
            this.getMatchingBannerShowed();
            this.setThumbnailImage();
        }
        if (prevProps.event.brandingData.lobbyVideoUrl !== event.brandingData.lobbyVideoUrl) {
            this.setThumbnailImage();
        }
    }

    componentWillUnmount() {
        // reset wall data to null
        const wallData = {
            wallId: null,
            highlightUsers: [],
        };
        this.props.setActiveWall(wallData);
    }

    handleOpenLobbyVideo = () => {
        if (this.state.invalidVideoId) {
            return null;
        }

        this.setState({showVideo: true});
    };
    handleCloseSpinner = () => this.setState({videoLoading: false});
    handleOpenNoShowfloorDialog = () => this.setState({showNoShowfloorDialog: true});
    handleOpenNoShowfloorAccess = () => this.setState({showNoShowfloorAccessDialog: true});
    handleOpenNoAuditoriumDialog = () => this.setState({showNoAuditoriumDialog: true});
    handleOpenNoAuditoriumAccess = () => this.setState({showNoAuditoriumAccessDialog: true});
    handleRequestCloseMatchingBanner = () => {
        this.setState({bannerDisableOnFinish: true});
        this.props.onRequestCloseMatchingBanner(true);
    };

    openMatchingCloseDialog = () => this.setState({openMatchingCloseDialog: !this.state.openMatchingCloseDialog});

    closeMatchingModal = () => {
        this.setState({openMatchingCloseDialog: false});
        this.props.onToggleMatchingDialog(false);
    };
    closeMatchingModalAndBanner = () => {
        let dataToStorage = [];
        const setData = {
            event: this.props.eventSlug,
            userId: this.props.user?._id,
            matchingBannerClosed: true,
            alreadyShowed: true,
        };
        const matchingBannerData = localStorage.getItem('matchingBannerData');
        if (matchingBannerData !== null && JSON.parse(matchingBannerData).length > 0) {
            const matchingBannerDataArray = JSON.parse(matchingBannerData);
            let spreadArray = matchingBannerDataArray;
            spreadArray.push(setData);
            dataToStorage = spreadArray;
        } else {
            dataToStorage.push(setData);
        }
        localStorage.setItem('matchingBannerData', JSON.stringify(dataToStorage));

        this.setState({bannerShouldClose: true});

        this.closeMatchingModal();
    };
    openMatchingModal = () => {
        const {eventId, onGetQuestionnaires, onToggleMatchingDialog} = this.props;
        onGetQuestionnaires(eventId);
        onToggleMatchingDialog(true);
    };

    getMatchingBannerShowed = () => {
        const {eventSlug, user} = this.props;
        const matchingBannerData = localStorage.getItem('matchingBannerData');
        if (matchingBannerData !== null) {
            const matchingBannerDataArray = JSON.parse(matchingBannerData);
            let data = matchingBannerDataArray?.filter((bannerData) => bannerData?.event === eventSlug)?.shift();
            if (data !== undefined && data['userId'] === user?._id) {
                this.setState({alreadyShowed: data['alreadyShowed']});
            }
        }
    };

    checkIfEventHasEnded = () => {
        const {event} = this.props;
        return checkIfEventHasEnded(event, event.timezoneValue);
    };

    handleEnterKey = (tab) => (e) => {
        if (e.key === 'Enter') {
            if (tab === 'reception') return this.handleOpenPrivateChatWithRepresentative();
            if (tab === 'lobbyVideo') return this.setState({showVideo: true});
        }
    };

    refreshExhibitorData = () => {
        const {eventSlug, user} = this.props;
        const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
        const exhibitorId = userRolesInEvent?.exhibitor?._id;
        if (exhibitorId) {
            this.props.onGetExhibitor(exhibitorId);
            this.props.onSetActiveExhibitorId(exhibitorId);
        }
    };

    closeDialog = () =>
        this.setState({
            showVideo: false,
            videoLoading: true,
            showNoShowfloorDialog: false,
            showNoShowfloorAccessDialog: false,
            showNoAuditoriumDialog: false,
            showNoAuditoriumAccessDialog: false,
        });

    handleLogoutUser = () => {
        const {user} = this.props;

        // if user logged out we should also disconnect him from notifications
        disconnectFromNotifications(user._id);
        this.props.onLogoutUser();
    };

    setLanguagesData = () => {
        const brandingTranslation = this.props.event.brandingTranslations.find(
            (translation) => translation.language === this.props.eventLanguage
        );
        this.setState({
            brandingTranslation: brandingTranslation,
        });
    };

    handleSeeLiveWall = () => {
        const {event} = this.props;
        const wallData = {
            wallId: event.liveWallId,
            highlightUsers: [],
        };
        this.props.setActiveWall(wallData);
        this.props.seeLiveWall();
    };

    checkExhibitorsCanLogin = () => {
        const {exhibitorData, event} = this.props;
        let canLogin = true;
        if (exhibitorData?.type === EXHIBITOR_TYPES.company) {
            canLogin = event.exhibitorsLogin;
        }
        return canLogin;
    };

    checkScholarCanLogin = () => {
        const {exhibitorData, event} = this.props;
        let canLogin = true;
        if (exhibitorData?.type === EXHIBITOR_TYPES.scholar) {
            canLogin = event.scholarsLogin;
        }
        return canLogin;
    };

    checkIsScholar = () => {
        const {exhibitorData} = this.props;
        let isScholar = false;
        if (exhibitorData?.type === EXHIBITOR_TYPES.scholar) {
            isScholar = true;
        }
        return isScholar;
    };

    handleOpenPrivateChatWithRepresentative = () => {
        const {eventId, eventRoles, user} = this.props;
        let userPrivateChats = user.privateChats;
        // we check if we have allready talked with an organizer representative
        let representativeChatId = null;
        this.props.event.representatives.forEach((representative) => {
            let privateChatId = getPrivateChatId(userPrivateChats, representative.user._id);
            if (privateChatId) {
                representativeChatId = privateChatId;
            }
        });
        // if we allready talked with one, we open the same private chat with him
        if (representativeChatId) {
            this.props.onOpenPrivateChat(representativeChatId);
        } else {
            // if we didn't talk with a representative, we make an API call to the server
            // to alocate us a random representative
            let data;
            if (eventRoles.isExhibitor) {
                data = {exhibitorId: eventRoles.exhibitorId};
            } else {
                data = {participantId: eventRoles.participantId};
            }

            if (!this.state.loading) {
                axios({method: 'post', url: `/event/${eventId}/chat-with-organizer-representative`, data: data})
                    .then((response) => {
                        const newOrganizerChatId = response.data.privateChatId;
                        this.props.getPrivateChatsAndOpenPrivateChat(newOrganizerChatId);
                    })
                    .catch(() => {});
            }
        }
    };

    checkHasAccessToAuditoriums = () => {
        const {event, eventRoles, resourcesAccess} = this.props;
        let hasAccessToAuditoriums = true;
        const userIsCompanyExhibitor = eventRoles.isExhibitor && eventRoles?.exhibitorType === 'company';
        const userIsScholarExhibitor = eventRoles.isExhibitor && eventRoles?.exhibitorType === 'scholar';

        // participant
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
        }
        // company exhibitor
        if (event.exhibitorHasAccessManagement && userIsCompanyExhibitor && resourcesAccess) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
        }
        // scholar exhibitor
        if (event.scholarHasAccessManagement && userIsScholarExhibitor && resourcesAccess) {
            if (!resourcesAccess.auditoriums.length) {
                hasAccessToAuditoriums = false;
            }
        }

        return hasAccessToAuditoriums;
    };

    checkHasAccessToShowfloors = () => {
        const {event, eventRoles, resourcesAccess} = this.props;
        let hasAccessToShowfloor = true;

        const userIsCompanyExhibitor = eventRoles.isExhibitor && eventRoles?.exhibitorType === 'company';
        const userIsScholarExhibitor = eventRoles.isExhibitor && eventRoles?.exhibitorType === 'scholar';

        // participant
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess) {
            if (!resourcesAccess.showfloors?.length) {
                hasAccessToShowfloor = false;
            }
        }
        // company exhibitor
        if (event.exhibitorHasAccessManagement && userIsCompanyExhibitor && resourcesAccess) {
            if (!resourcesAccess.showfloors?.length) {
                hasAccessToShowfloor = false;
            }
        }
        // scholar exhibitor
        if (event.scholarHasAccessManagement && userIsScholarExhibitor && resourcesAccess) {
            if (!resourcesAccess.showfloors?.length) {
                hasAccessToShowfloor = false;
            }
        }

        return hasAccessToShowfloor;
    };

    setThumbnailImage = () => {
        const {event} = this.props;
        if (!event.brandingData.lobbyVideoUrl) return;

        let lobbyVideoId = null;
        let thumbnailImage;
        let ruleYTB = isYoutubeLinkRule;
        let match = ruleYTB.test(event.brandingData.lobbyVideoUrl);
        if (match) {
            lobbyVideoId = getVideoIdFromYoutubeUrl(event.brandingData.lobbyVideoUrl);
            thumbnailImage = `https://img.youtube.com/vi/${lobbyVideoId}/0.jpg`;
            this.setState({
                thumbnailImage: thumbnailImage,
                streaming: 'youtube',
            });

            // check if video is valid
            // if video is invalid, set a variable in the state and prevent default when click on the video
            var img = new Image();
            img.src = 'http://img.youtube.com/vi/' + lobbyVideoId + '/mqdefault.jpg';

            const componentReferenace = this;
            img.onload = function () {
                if (this.width === 120) {
                    componentReferenace.setState({
                        invalidVideoId: true,
                    });
                } else {
                    componentReferenace.setState({
                        invalidVideoId: false,
                    });
                }
            };
        } else {
            callAxios
                .get(`https://vimeo.com/api/oembed.json?url=${event.brandingData.lobbyVideoUrl}`)
                .then((res) => {
                    this.setState({
                        thumbnailImage: `${res.data.thumbnail_url}.jpg`,
                        invalidVideoId: false,
                    });
                })
                .catch((err) => {
                    this.setState({
                        invalidVideoId: true,
                    });
                });
        }
    };

    render() {
        const {
            showNoShowfloorDialog,
            showNoShowfloorAccessDialog,
            showNoAuditoriumDialog,
            showNoAuditoriumAccessDialog,
            showVideo,
            brandingTranslation,
            alreadyShowed,
            openMatchingCloseDialog,
            bannerDisableOnFinish,
            // splashScreenState,
            thumbnailImage,
            streaming,
            videoLoading,
        } = this.state;
        const {event, userEventProfile, eventSlug, eventRoles, isLargeScreen, translation, defaultTranslation} =
            this.props;
        const eventHasEnded = this.checkIfEventHasEnded();
        const exhibitorCanLogin = this.checkExhibitorsCanLogin();
        const scholarCanLogin = this.checkScholarCanLogin();
        const isScholar = this.checkIsScholar();

        let sliderSettings = {
            dots: false,
            arrows: false,
            autoplay: true,
            draggable: false,
            pauseOnHover: false,
            swipe: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        let hasAtLeastOneWindowUploaded = checkIfLeftOrRightWindowIsUploaded(event);
        let lobbyVideoImage = '';
        if ('lobbyVideoImage' in event.brandingData && event.brandingData.lobbyVideoImage) {
            lobbyVideoImage = event.brandingData.filesUrl + event.brandingData.lobbyVideoImage;
        }

        let EventLobyImage;
        if (event.brandingData && event.brandingData.lobbyRenderType === 'custom') {
            // Custom uploaded
            EventLobyImage = getLobbyBackgroundUploaded(hasAtLeastOneWindowUploaded, event.brandingData);
        } else if (event.brandingData.lobbyRenderType === 'v2') {
            // International
            EventLobyImage = getLobbyBackgroundUploaded(
                hasAtLeastOneWindowUploaded,
                event.brandingData,
                event.brandingData.lobbyRenderType
            );
        } else if (event.brandingData.lobbyRenderType === 'v3') {
            // Academic
            EventLobyImage = getLobbyBackgroundUploaded(
                hasAtLeastOneWindowUploaded,
                event.brandingData,
                event.brandingData.lobbyRenderType
            );
        } else if (event.brandingData.lobbyRenderType === 'v4') {
            // Green
            EventLobyImage = getLobbyBackgroundUploaded(
                hasAtLeastOneWindowUploaded,
                event.brandingData,
                event.brandingData.lobbyRenderType
            );
        } else {
            // Default
            EventLobyImage = getLobbyBackground(hasAtLeastOneWindowUploaded);
        }

        let hasAccessToAuditoriums = this.checkHasAccessToAuditoriums();
        let hasAccessToShowfloor = this.checkHasAccessToShowfloors();

        const fullOverlayParticipant = () => {
            const {
                user: userCarrier,
                eventRoles: eventRolesCarrier,
                event: eventCarrier,
                translation: translationCarrier,
            } = this.props;

            let event = {...eventCarrier};
            let user = {...userCarrier};
            let eventRoles = {...eventRolesCarrier};
            let translation = {...translationCarrier};

            const userIsActiveCoorganizer = user?.eventRoles.find((role) => role.event.slug === eventSlug)?.accessRights
                ?.isActive;

            if (!userIsActiveCoorganizer && eventRoles.isCoOrganizer && !event.participantsLogin) {
                return (
                    <FullOverlay
                        title={event.brandingData.participantsClosedEventTitle}
                        text={event.brandingData.participantsClosedEventInfo}
                        buttonText={translation?.myAccountDropdown.logoutButton}
                        handleClick={this.handleLogoutUser}
                    />
                );
            } else if (userIsActiveCoorganizer && eventRoles.isCoOrganizer && !event.participantsLogin) {
                return null;
            } else if (
                !event.participantsLogin &&
                !eventRoles.isOrganizer &&
                !eventRoles.isExhibitor &&
                !eventRoles.isCoOrganizer
            ) {
                return (
                    <FullOverlay
                        title={event.brandingData.participantsClosedEventTitle}
                        text={event.brandingData.participantsClosedEventInfo}
                        buttonText={translation?.myAccountDropdown.logoutButton}
                        handleClick={this.handleLogoutUser}
                    />
                );
            }
        };

        const hasUserSeenMatchingForm = userEventProfile?.matchingFormSeen;
        const isLeftPhotoDefault = checkIfLeftPhotoViewIsCustomOrDefault(event);

        return (
            <>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${
                        brandingTranslation?.homepageEventName ? brandingTranslation?.homepageEventName : event.title
                    }`}</title>
                    <meta property="og:image" content={`${EventLobyImage}`} />
                    <meta
                        property="og:title"
                        content={`${process.env.REACT_APP_PLATFORM_NAME} - ${brandingTranslation?.homepageEventName}`}
                    />
                </Helmet>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`position-background ${!isLargeScreen ? 'lobby-mobile' : ''}`}
                >
                    {' '}
                    {!hasUserSeenMatchingForm && !alreadyShowed && userEventProfile && (
                        <MatchingBanners
                            bannerDisableOnFinish={bannerDisableOnFinish}
                            requestCloseBanner={event?.requestCloseBanner}
                            openMatchingModal={this.openMatchingModal}
                            bannerShouldClose={this.state.bannerShouldClose}
                        />
                    )}
                    <SideMenu openMatchingDialog={this.openMatchingModal} />
                    <div
                        className="lobby-page"
                        style={{
                            backgroundImage: `url('${EventLobyImage}')`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div onDragStart={preventDefaultDrag} className="centerOfPage">
                            <div onDragStart={preventDefaultDrag} className="relativeCenter">
                                {hasAtLeastOneWindowUploaded && (
                                    <>
                                        <div className="centerOfPage">
                                            <div className="relativeCenter">
                                                <div className="clickable-element clickable-element-window-left">
                                                    <img
                                                        className={`${
                                                            isLeftPhotoDefault && 'flip-photo'
                                                        } left-window window`}
                                                        src={getWindowView('left', event)}
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="centerOfPage">
                                            <div className="relativeCenter">
                                                <div className="clickable-element clickable-element-window-right">
                                                    <img
                                                        className="right-window window"
                                                        src={getWindowView('right', event)}
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className={`clickable-element ${
                                        event.hideShowfloorDoor ? 'disable-lobby-door' : ''
                                    }`}
                                    id="showfloor"
                                >
                                    {event.hasVillage ? (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className={`relativeCenter ${
                                                event.hideShowfloorDoor ? 'disable-lobby-door' : ''
                                            }`}
                                        >
                                            <Link to={{pathname: `/event/${eventSlug}/village/`}} />
                                        </div>
                                    ) : (
                                        !event.hideShowfloorDoor && (
                                            <ShowfloorLink
                                                lobby
                                                classes="relativeCenter"
                                                translation={translation}
                                                hasAccessToShowfloor={hasAccessToShowfloor}
                                                showNoShowfloorDialog={showNoShowfloorDialog}
                                                handleOpenNoShowfloorDialog={this.handleOpenNoShowfloorDialog}
                                                showNoShowfloorAccessDialog={showNoShowfloorAccessDialog}
                                                handleOpenNoShowfloorAccess={this.handleOpenNoShowfloorAccess}
                                                closeDialog={this.closeDialog}
                                            />
                                        )
                                    )}
                                </div>
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className={`clickable-element ${
                                        event.hideAuditoriumDoor ? 'disable-lobby-door' : ''
                                    }`}
                                    id="auditorium"
                                >
                                    {event.hasVillage ? (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className={`relativeCenter ${
                                                event.hideAuditoriumDoor ? 'disable-lobby-door' : ''
                                            }`}
                                        >
                                            <Link to={{pathname: `/event/${eventSlug}/village/`}} />
                                        </div>
                                    ) : (
                                        !event.hideAuditoriumDoor && (
                                            <AuditoriumLink
                                                lobby
                                                classes="relativeCenter"
                                                translation={translation}
                                                eventHasEnded={eventHasEnded}
                                                hasAccessToAuditoriums={hasAccessToAuditoriums}
                                                showNoAuditoriumDialog={showNoAuditoriumDialog}
                                                handleOpenNoAuditoriumDialog={this.handleOpenNoAuditoriumDialog}
                                                showNoAuditoriumAccessDialog={showNoAuditoriumAccessDialog}
                                                handleOpenNoAuditoriumAccess={this.handleOpenNoAuditoriumAccess}
                                                closeDialog={this.closeDialog}
                                            />
                                        )
                                    )}
                                </div>
                                {!eventRoles.isOrganizerRep && event.representatives.length > 0 && (
                                    <div
                                        tabIndex="0"
                                        onDragStart={preventDefaultDrag}
                                        className="clickable-element"
                                        id="info-booth"
                                        onKeyDown={this.handleEnterKey('reception')}
                                    >
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            onClick={this.handleOpenPrivateChatWithRepresentative}
                                            className="w-100 h-100"
                                        />
                                        <div onDragStart={preventDefaultDrag} className="hovering-container" />
                                    </div>
                                )}
                                <div
                                    tabIndex={event.brandingData.lobbyVideoUrl ? '0' : '-1'}
                                    onDragStart={preventDefaultDrag}
                                    className={`clickable-element ${!event.brandingData.lobbyVideoUrl && 'no-hover'}`}
                                    id="show-lobby-video"
                                    onKeyDown={this.handleEnterKey('lobbyVideo')}
                                >
                                    {event.brandingData.lobbyVideoUrl ? (
                                        <div
                                            className="image-preview-video"
                                            style={{
                                                backgroundImage: `url('${thumbnailImage}')`,
                                            }}
                                            onClick={this.handleOpenLobbyVideo}
                                        >
                                            <div onDragStart={preventDefaultDrag} className="play-button">
                                                <PlayIcon />
                                            </div>
                                        </div>
                                    ) : lobbyVideoImage.length ? (
                                        <div
                                            className="image-preview-video no-video"
                                            style={{backgroundImage: `url('${lobbyVideoImage}')`}}
                                        />
                                    ) : (
                                        <div
                                            className="image-preview-video no-video"
                                            style={{backgroundImage: `url('${NoVideoImage}')`}}
                                        />
                                    )}
                                </div>
                                <BoothVideo
                                    lobby
                                    opened={showVideo}
                                    closeDialog={this.closeDialog}
                                    videoUrl={event.brandingData.lobbyVideoUrl}
                                    videoImage={thumbnailImage}
                                    streamingType={streaming}
                                    handleCloseSpinner={this.handleCloseSpinner}
                                    videoLoading={videoLoading}
                                />
                                {event.brandingData.lobbyLogos.length > 0 && (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="clickable-element"
                                        id="slider-logos"
                                    >
                                        <Slider {...sliderSettings}>
                                            {event.brandingData.lobbyLogos.map((lobbyLogo) => {
                                                return (
                                                    <div onDragStart={preventDefaultDrag} key={lobbyLogo._id}>
                                                        <img
                                                            draggable="false"
                                                            onDragStart={preventDefaultDrag}
                                                            src={event.brandingData.filesUrl + lobbyLogo.image}
                                                            alt="Logo"
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                )}
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="clickable-element no-hover"
                                    id="banner"
                                >
                                    {event.brandingData.lobbyBanner ? (
                                        <img
                                            draggable="false"
                                            onDragStart={preventDefaultDrag}
                                            src={event.brandingData.filesUrl + event.brandingData.lobbyBanner}
                                            alt="Logo"
                                            className="lobby-banner-image"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {event?.matchingDialog && (
                    <MatchingDialog
                        noOpenNetworking
                        open={event?.matchingDialog}
                        close={this.openMatchingCloseDialog}
                        closeNoPrompt={this.closeMatchingModalAndBanner}
                        closeBannerOnFinish={this.handleRequestCloseMatchingBanner}
                    />
                )}
                {openMatchingCloseDialog && (
                    <MatchingDialogConfirm
                        hasCloseButton
                        open={openMatchingCloseDialog}
                        dialogTitle={
                            translation?.matching?.closeMatchingTitle ||
                            defaultTranslation?.matching?.closeMatchingTitle
                        }
                        dialogDescription={`${
                            translation?.matching?.closeMatchingTextA ||
                            defaultTranslation?.matching?.closeMatchingTextA
                        } <br><br> ${
                            translation?.matching?.closeMatchingTextB ||
                            defaultTranslation?.matching?.closeMatchingTextB
                        }`}
                        cancelBtnLabel={translation?.generalText?.cancel || defaultTranslation?.generalText?.cancel}
                        confirmBtnLabel={translation?.generalText?.close || defaultTranslation?.generalText?.close}
                        closeConfirm={this.openMatchingCloseDialog}
                        handleConfirm={this.closeMatchingModal}
                    />
                )}

                {fullOverlayParticipant()}
                {/* if exhibitor or poster login is closed then display the logout dialog
                on lobby for mobile and tablets */}
                {eventRoles.isExhibitor && !eventRoles.isOrganizer && !exhibitorCanLogin && (
                    <FullOverlay
                        title={event.brandingData.exhibitorsClosedEventTitle}
                        text={event.brandingData.exhibitorsClosedEventInfo}
                        buttonText={`${translation?.myAccountDropdown.logoutButton}`}
                        handleClick={this.handleLogoutUser}
                    />
                )}
                {isScholar && !eventRoles.isOrganizer && !scholarCanLogin && (
                    <FullOverlay
                        title={event.brandingData.scholarsClosedEventTitle}
                        text={event.brandingData.scholarsClosedEventInfo}
                        buttonText={`${translation?.myAccountDropdown.logoutButton}`}
                        handleClick={this.handleLogoutUser}
                    />
                )}
                <EventWelcomeScreen />
                <NotificationPresentationStarts />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        loadingEvent: state.event.loading,
        event: state.event.data,
        user: state.user.data,
        userEventProfile: state.userEventProfile.data,
        eventMatching: state.organizer.eventMatching,
        eventRoles: state.user.eventRoles,
        exhibitorData: state.exhibitor.data,
        resourcesAccess: state.user.resourcesAccess,
        isLargeScreen: state.layout.isLargeScreen,
        eventLanguage: state.languages.eventLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHasVideoWall: (booleanValue) => dispatch(actions.sideNavHasVideoWall(booleanValue)),
        onLogoutUser: () => dispatch(actions.logoutUser()),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeLiveWall: () => dispatch(actions.sideNavSeeLiveWall()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        onGetExhibitor: (exhibitorId) => dispatch(actions.getExhibitor(exhibitorId)),
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
        getPrivateChatsAndOpenPrivateChat: (privateChatId) => dispatch(actions.getPrivateChats(privateChatId)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onGetQuestionnaires: (eventId) => dispatch(actions.getQuestionnaires(eventId)),
        onToggleMatchingDialog: (bool) => dispatch(actions.toggleMatchingDialog(bool)),
        onRequestCloseMatchingBanner: (bool) => dispatch(actions.requestCloseMatchingBanner(bool)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lobby);
