import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import colors from '../../../CSS/_variables.module.scss';
// import NewChat from '../../../Images/svg/new-chat.svg';
import {ReactComponent as BackButtonIcon} from '../../../Images/svg/back-btn.svg';
import OpenChatIcon from '../../../Images/svg/OpenChat';
import {preventDefaultDrag} from '../../../Utils/utils';

class ChatsHeader extends React.Component {
    state = {windowWidth: window.innerWidth};

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => this.setState({windowWidth: window.innerWidth});
    closeActiveChat = () => this.props.onResetActiveChatId();

    render() {
        const {
            translation,
            event,
            eventRoles,
            resourcesAccess,
            openCreateGroupChatDialog,
            chatsProps,
            handleCloseCreateGroupChat,
            handleOpenCreateGroupChat,
        } = this.props;

        let restrictedNetwork = false;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess && !resourcesAccess.networking) {
            restrictedNetwork = true;
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess &&
            !resourcesAccess.networking
        ) {
            restrictedNetwork = true;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="chat-header-container">
                {openCreateGroupChatDialog && (
                    <>
                        <BackButtonIcon
                            width="28"
                            height="28"
                            fill={this.state.windowWidth < 1024 ? colors.white : colors.dark}
                            className="create-new-chat back-button desktop"
                            onClick={handleCloseCreateGroupChat}
                        />
                    </>
                )}
                <div>
                    <p onDragStart={preventDefaultDrag} className="dropdown-title">
                        {!openCreateGroupChatDialog && (
                            <>
                                {chatsProps.activeChatRoomId ? (
                                    <BackButtonIcon
                                        width="25"
                                        height="25"
                                        fill={this.state.windowWidth < 1024 ? colors.white : colors.dark}
                                        className="close-active-chat-button"
                                        onClick={this.closeActiveChat}
                                    />
                                ) : null}
                            </>
                        )}
                        <span>{translation?.chatsDropdown.title}</span>
                    </p>
                    <button onClick={handleOpenCreateGroupChat} disabled={restrictedNetwork}>
                        {restrictedNetwork ? (
                            <Tooltip arrow title="Restricted" id="new-chat-tooltip" enterTouchDelay={1}>
                                <div>
                                    <OpenChatIcon primary={colors.primary} secondary={colors.secondary} />
                                    <span>New Chat</span>
                                </div>
                            </Tooltip>
                        ) : (
                            <div>
                                <OpenChatIcon primary={colors.primary} secondary={colors.secondary} />
                                <span>New Chat</span>
                            </div>
                        )}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onResetActiveChatId: () => dispatch(actions.resetActiveChatId()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatsHeader);
