import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import LobbyBanner from './LobbyBanner';
import LobbyClosedDoorTexts from './LobbyClosedDoorTexts';
import LobbyDoorTexts from './LobbyDoorTexts';
import LobbyInfoScreen from './LobbyInfoScreen';
import LobbyLogos from './LobbyLogos';
import WelcomeScreen from "./WelcomeScreen/WelcomeScreen";
import {LobbyUploadProgram} from './LobbyUploadProgram';
import {preventDefaultDrag} from 'Utils/utils';
import LobbyResources from "./LobbyResources/Index";

class BrandingLobby extends React.Component {
    render() {
        const {
            eventSlug,
            saveClosedDoorsInfo,
            loadingSaveEventBranding,
            handleOpenSuccessSnackbar,
            handleOpenErrorSnackbar,
            saveEventBranding
        } = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="branding-lobby">
                <Switch>
                    <Route exact path="/event/:eventSlug/dashboard/branding/lobby/welcome-screen">
                        <WelcomeScreen
                            handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                            saveEventBranding={saveEventBranding}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/lobby/info-screen">
                        <LobbyInfoScreen
                            handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                            saveEventBranding={saveEventBranding}
                            loadingSaveEventBranding={loadingSaveEventBranding}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/lobby/door-texts">
                        <LobbyDoorTexts
                            saveEventBranding={saveEventBranding}
                            loadingSaveEventBranding={loadingSaveEventBranding}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/lobby/banner">
                        <LobbyBanner
                            handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/lobby/logos">
                        <LobbyLogos
                            handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/lobby/closed-door-texts">
                        <LobbyClosedDoorTexts
                            saveClosedDoorsInfo={saveClosedDoorsInfo}
                            handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                            loadingSaveEventBranding={loadingSaveEventBranding}
                        />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/lobby/program">
                        <LobbyUploadProgram />
                    </Route>
                    <Route exact path="/event/:eventSlug/dashboard/branding/lobby/resources">
                        <LobbyResources />
                    </Route>
                    <Redirect to={`/event/${eventSlug}/dashboard/branding/lobby/welcome-screen`} />
                </Switch>
            </div>
        );
    }
}

export default BrandingLobby;
