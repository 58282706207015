import React, {useState, useRef, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, RichUtils, convertToRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import Button from '@material-ui/core/Button';
import SpeakerPhoto from './SpeakerPhoto';
import Confirm from 'Dialogs/Confirm';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkIcon from '@material-ui/icons/Link';
import {fireClickEvent, hasOnlyEmptySpaces} from 'Utils/utils';
import {isLinkedinLinkRule, isFacebookLinkRule, isTwitterLinkRule, isLinkRule} from 'Utils/validationRules';
import {
    addNewSpeaker,
    deleteImageSpeaker,
    addNewImageSpeaker,
    updateSpeaker,
    listSpeakers,
} from 'store/actions/eventSpeakersActions';
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ManualError} from './../../../../ManualError/ManualError';
import {verifyFileType} from 'Utils/verifyFileType';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const removeValidationRules = () => {
    ValidatorForm.removeValidationRule('onlyEmptySpaces');
    ValidatorForm.removeValidationRule('isFacebookLink');
    ValidatorForm.removeValidationRule('isLinkedinLink');
    ValidatorForm.removeValidationRule('isTwitterLink');
    ValidatorForm.removeValidationRule('isWebPageUrl');
};

// VALIDATIONS-----------------------------------------
const validateSocialNetworkFields = () => {
    ValidatorForm.addValidationRule('onlyEmptySpaces', (value) => hasOnlyEmptySpaces(value));
    ValidatorForm.addValidationRule('isFacebookLink', (value) => {
        const rule = isFacebookLinkRule;
        let match = rule.test(value);

        if (value === undefined || value?.length === 0) {
            match = true;
        }
        if (!match) {
            return false;
        }
        return true;
    });
    ValidatorForm.addValidationRule('isLinkedinLink', (value) => {
        const rule = isLinkedinLinkRule;
        let match = rule.test(value);

        if (value === undefined || value?.length === 0) {
            match = true;
        }
        if (!match) {
            return false;
        }
        return true;
    });
    ValidatorForm.addValidationRule('isTwitterLink', (value) => {
        const rule = isTwitterLinkRule;
        let match = rule.test(value);

        if (value === undefined || value?.length === 0) {
            match = true;
        }
        if (!match) {
            return false;
        }
        return true;
    });
    ValidatorForm.addValidationRule('isWebPageUrl', (value) => {
        const rule = isLinkRule;
        let match = rule.test(value);

        if (value === undefined || value?.length === 0) {
            match = true;
        }
        if (!match) {
            return false;
        }

        return true;
    });
};

const validations = {
    name: ['required', 'minStringLength: 1', 'maxStringLength: 50', 'onlyEmptySpaces'],
    title: ['maxStringLength: 100'],
    description: ['maxStringLength: 2000'], // is not used
    facebook: ['isFacebookLink'],
    linkedIn: ['isLinkedinLink'],
    twitter: ['isTwitterLink'],
    webpage: ['isWebPageUrl'],
};

const errors = {
    name: [
        'Name is required',
        'Name is required',
        'You have reached the maximum limit of characters allowed (50 characters)',
        'Field is required',
    ],
    title: ['You have reached the maximum limit of characters allowed (100 characters)'],
    description: ['You have reached the maximum limit of characters allowed (2000 characters)'],
    facebook: ['Please enter a valid link (https://facebook.com/)'],
    twitter: ['Please enter a valid link (https://twitter.com/)'],
    linkedIn: ['Please enter a valid link (https://linkedin.com/)'],
    webpage: ['Please enter a valid link (https://example.com/)'],
};

// VALIDATIONS END-------------------------------------

const SpeakerForm = ({
    speaker,
    totalSpeakers,
    eventId,
    closeNewSpeakerDialog,
    removeSpeakerImageLocalState,
    activeSpeaker,
    addNewSpeakerToLocalState,
    uploadSpeakerImageLocalState,
    updateSpeakerToLocalState,
}) => {
    const dispatch = useDispatch();
    const formRef = useRef();
    const editor = useRef();
    const wrapperRef = useRef();
    const wrapperImageRef = useRef();

    const speakersEvent = useSelector((state) => state.speakers);
    const {eventSpeakers, successSpeakerUpdate} = speakersEvent;

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [characterCount, setCharacterCount] = useState(0);
    const [newLogoFile, setNewLogoFile] = useState(null);
    const [imageLogoPreviewUrl, setImageLogoPreviewUrl] = useState(null);
    const [imageLogoErrorText, setImageLogoErrorText] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [openConfirmUnsavedChanges, setOpenConfirmUnsavedChanges] = useState(false);
    const [openConfirmUnsavedImageChanges, setOpenConfirmUnsavedImageChanges] = useState(false);
    const [navigationElement, setNavigationElement] = useState(null);
    const [data, setData] = useState({});
    const [newSpeakerValidation, setNewSpeakerValidation] = useState(false);

    useEffect(() => {
        removeValidationRules();
    }, []);

    useEffect(() => {
        setSpeakerFieldsData();
        setButtonDisabled(true);
        if (!!speaker) {
            validateSocialNetworkFields();
        }
    }, [speaker]);

    useEffect(() => {
        if (newSpeakerValidation) {
            validateSocialNetworkFields();
        }
    }, [newSpeakerValidation]);

    useEffect(() => {
        (async () => {
            let areAnyChanges = false;
            if (speaker) {
                areAnyChanges = Object.keys(data).some((key) => {
                    if (key === 'title' && data.title === '' && speaker.title === undefined) {
                        return false;
                    }
                    return data[key] !== speaker[key];
                });
            } else {
                areAnyChanges = Object.keys(data).some((key) => {
                    if (key === 'description' && (data.description === '' || data.description === '<p><br></p>')) {
                        return false;
                    }
                    return data[key] !== '';
                });
            }

            const isFormValid = await formRef.current.isFormValid(true);

            const isButtonDisabled =
                !areAnyChanges || !isFormValid || data.name.trim().length <= 0 || characterCount > 2000;

            setButtonDisabled(isButtonDisabled);
        })();
    }, [data, characterCount, speaker]);

    const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            if (!buttonDisabled && !openConfirmUnsavedChanges) {
                editor.current.editor.blur();
                setOpenConfirmUnsavedChanges(true);
                setNavigationElement(getNewNavigationElement(e));
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [buttonDisabled, getNewNavigationElement, wrapperRef, openConfirmUnsavedChanges]);

    const handleClickOutsideImage = (e) => {
        if (wrapperImageRef.current && !wrapperImageRef.current.contains(e.target)) {
            if (
                imageLogoPreviewUrl &&
                !openConfirmUnsavedImageChanges &&
                !imageLogoErrorText.length &&
                speaker.image !== null
            ) {
                setOpenConfirmUnsavedImageChanges(true);
                setNavigationElement(getNewNavigationElement(e));
            }
        }
    };

    const getNewNavigationElement = useCallback(
        (e) => {
            const isEventTargetNavigationElement = e.path?.find((pathElem) =>
                pathElem.getAttribute?.('data-is-navigation')
            );

            if (openConfirmUnsavedChanges || openConfirmUnsavedImageChanges) {
                return navigationElement;
            }

            if (isEventTargetNavigationElement) {
                return e.target;
            }

            return null;
        },
        [navigationElement, openConfirmUnsavedChanges, openConfirmUnsavedImageChanges]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideImage);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideImage);
        };
    }, [
        getNewNavigationElement,
        imageLogoErrorText,
        imageLogoPreviewUrl,
        speaker,
        wrapperImageRef,
        openConfirmUnsavedImageChanges,
    ]);

    const setSpeakerFieldsData = useCallback(() => {
        if (speaker) {
            const contentState = stateFromHTML(speaker.description === undefined ? '' : speaker.description);
            let characterCount = convertToRaw(contentState)
                .blocks.map((obj) => obj.text)
                .join(' ').length;
            setData({
                description: speaker.description,
                facebook: speaker.facebook,
                hidden: speaker.hidden,
                linkedIn: speaker.linkedIn,
                name: speaker.name,
                order: speaker.order,
                title: speaker.title ?? '',
                twitter: speaker.twitter,
                webpage: speaker.webpage,
            });

            setEditorState(EditorState.createWithContent(contentState));
            setCharacterCount(characterCount);
        } else {
            setData({
                description: '<p><br></p>',
                facebook: '',
                hidden: false,
                linkedIn: '',
                name: '',
                order: eventSpeakers && eventSpeakers?.data?.length === 0 ? 1 : totalSpeakers + 1,
                title: '',
                twitter: '',
                webpage: '',
            });
            setEditorState(EditorState.createEmpty());
            setCharacterCount(0);
        }
    }, [eventSpeakers, speaker, totalSpeakers]);

    const cleanEmptyValues = (data) => {
        for (var propName in data) {
            if (data[propName] === null || data[propName] === undefined || data[propName] === '') {
                delete data[propName];
            }
        }
        return data;
    };

    const addUpdateSpeaker = async () => {
        closeNewSpeakerDialog();
        const updatedLocalData = () => {
            return {...data, _id: speaker._id, event: speaker.event};
        };

        const addedNewSpeakerState = () => {
            return {...data, event: eventId};
        };
        speaker
            ? updateSpeakerToLocalState(speaker._id, updatedLocalData())
            : addNewSpeakerToLocalState(cleanEmptyValues(addedNewSpeakerState()));
        activeSpeaker(speaker ? speaker._id : 0);
        speaker
            ? dispatch(updateSpeaker(eventId, speaker._id, data))
            : dispatch(addNewSpeaker(eventId, cleanEmptyValues(data)));
        setButtonDisabled(true);
        setOpenConfirmUnsavedChanges(false);

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    const handleDiscardChanges = () => {
        closeClickOutside();
        setSpeakerFieldsData();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    const handleDiscardImageChanges = () => {
        closeClickOutside();
        setNewLogoFile(null);
        setImageLogoPreviewUrl(null);
        setImageLogoErrorText('');

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
        setNewSpeakerValidation(true);
    };

    const closeClickOutside = () => {
        setOpenConfirmUnsavedChanges(false);
        setOpenConfirmUnsavedImageChanges(false);
    };

    const checkFirstLetter = (input) => (e) => {
        if (input.length <= 0 && e.keyCode === 32) {
            e.preventDefault();
        }
    };

    const onChangeRichTextEditor = (editorState) => {
        let contentState = stateFromHTML(stateToHTML(editorState.getCurrentContent()));
        let characterCount = convertToRaw(contentState)
            .blocks.map((obj) => obj.text)
            .join(' ').length;
        setEditorState(editorState);
        setData({...data, description: stateToHTML(editorState.getCurrentContent())});
        setCharacterCount(characterCount);
        setNewSpeakerValidation(true);
    };

    const handleReturn = (e) => {
        if (e.shiftKey) {
            setEditorState(RichUtils.insertSoftNewline(editorState));
            return 'handled';
        }
        return 'not-handled';
    };

    const focusEditor = () => {
        if (editor) {
            editor.current.focusEditor();
        }
    };

    const readyForUploadHandler = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;
        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (!isValid) {
            setImageLogoPreviewUrl(null);
            setImageLogoErrorText('File too large. 2Mb max file size.');
        }
        const typeValid = verifyFileType(file.type, 'image');
        isValid = typeValid && isValid;
        if (!typeValid) {
            setImageLogoPreviewUrl(null);
            setImageLogoErrorText(
                'File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.'
            );
        }

        reader.onloadend = () => {
            if (isValid) {
                setNewLogoFile(file);
                setImageLogoPreviewUrl(reader.result);
                setImageLogoErrorText('');
            }
        };
        reader.readAsDataURL(file);
        e.target.value = '';
    };

    const uploadFileHandler = async () => {
        const formData = new FormData();

        if (newLogoFile) {
            formData.append('image', newLogoFile, newLogoFile.name, newLogoFile.type);

            dispatch(addNewImageSpeaker(eventId, speaker?._id, formData));

            setNewLogoFile(null);
            setImageLogoPreviewUrl(null);
            uploadSpeakerImageLocalState(speaker?._id, speaker.image);

            if (navigationElement) {
                fireClickEvent(navigationElement);
            }
        }
    };

    const deleteFileHandler = async () => {
        dispatch(deleteImageSpeaker(eventId, speaker._id));
        setNewLogoFile(null);
        setImageLogoPreviewUrl(null);
        removeSpeakerImageLocalState(speaker._id);
    };

    return (
        <div data-content className="speaker-form-container-wrapper" ref={wrapperRef}>
            <ValidatorForm ref={formRef} onSubmit={addUpdateSpeaker}>
                <div className="addDeleteFormItem">
                    <TextValidator
                        label="Speaker name"
                        type="text"
                        name="name"
                        index="0"
                        value={data?.name || ''}
                        onKeyDown={checkFirstLetter(data.name)}
                        onChange={onChangeHandler}
                        validators={validations.name}
                        errorMessages={errors.name}
                        fullWidth={true}
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                            endAdornment: <span className="char-count">{data.name ? data.name.length : 0}/50</span>,
                        }}
                    />
                </div>
                <div className="addDeleteFormItem">
                    <TextValidator
                        label="Title"
                        type="text"
                        name="title"
                        index="1"
                        value={data.title || ''}
                        onKeyDown={checkFirstLetter(data.title)}
                        onChange={onChangeHandler}
                        validators={validations.title}
                        errorMessages={errors.title}
                        fullWidth={true}
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                            endAdornment: <span className="char-count">{data.title ? data.title.length : 0}/100</span>,
                        }}
                    />
                </div>
                <h3 className="form-h">DESCRIPTION</h3>
                <div className={`editor-wrapper speaker-prop multiline-input`} onClick={focusEditor}>
                    <Editor
                        ref={editor}
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onEditorStateChange={onChangeRichTextEditor}
                        handleReturn={handleReturn}
                        toolbar={{
                            options: ['link', 'inline', 'list', 'emoji'],
                            inline: {
                                options: ['bold', 'italic', 'underline'],
                            },
                            list: {
                                options: ['unordered', 'ordered'],
                            },
                        }}
                    />
                    <span className={'char-count'}>{`${characterCount === 0 ? 0 : characterCount}/2000`}</span>
                </div>
                <ManualError maxLength={2000} valueLength={characterCount} />
                <div className="social-networks-container">
                    <TextValidator
                        label="LinkedIn"
                        type="text"
                        name="linkedIn"
                        index="3"
                        validators={validations.linkedIn}
                        value={data.linkedIn || ''}
                        onChange={onChangeHandler}
                        errorMessages={errors.linkedIn}
                        fullWidth={true}
                        variant="outlined"
                        margin="normal"
                        InputProps={{endAdornment: <LinkedInIcon />}}
                    />
                    <TextValidator
                        label="Twitter"
                        type="text"
                        name="twitter"
                        index="4"
                        errorMessages={errors.twitter}
                        value={data.twitter || ''}
                        validators={validations.twitter}
                        onChange={onChangeHandler}
                        fullWidth={true}
                        variant="outlined"
                        margin="normal"
                        InputProps={{endAdornment: <TwitterIcon />}}
                    />
                    <TextValidator
                        label="Facebook"
                        type="text"
                        name="facebook"
                        index="5"
                        validators={validations.facebook}
                        errorMessages={errors.facebook}
                        value={data.facebook || ''}
                        onChange={onChangeHandler}
                        fullWidth={true}
                        variant="outlined"
                        margin="normal"
                        InputProps={{endAdornment: <FacebookIcon />}}
                    />
                    <TextValidator
                        label="Webpage"
                        type="text"
                        name="webpage"
                        index="6"
                        validators={validations.webpage}
                        value={data.webpage || ''}
                        errorMessages={errors.webpage}
                        onChange={onChangeHandler}
                        fullWidth={true}
                        variant="outlined"
                        margin="normal"
                        InputProps={{endAdornment: <LinkIcon />}}
                    />
                </div>
                <Button
                    type="submit"
                    disabled={buttonDisabled}
                    startIcon={<SaveOutlinedIcon />}
                    variant="contained"
                    color={'secondary'}
                    disableElevation
                >
                    SAVE
                </Button>
            </ValidatorForm>
            {speaker?._id ? (
                <SpeakerPhoto
                    ref={wrapperImageRef}
                    deleteFileHandler={deleteFileHandler}
                    uploadFileHandler={uploadFileHandler}
                    readyForUploadHandler={readyForUploadHandler}
                    eventId={eventId}
                    speaker={speaker}
                    imageLogoPreviewUrl={imageLogoPreviewUrl}
                    newLogoFile={newLogoFile}
                    imageLogoErrorText={imageLogoErrorText}
                />
            ) : (
                <p className="no-saved-warning">You can upload an image after saving the Speaker </p>
            )}
            {openConfirmUnsavedChanges && (
                <Confirm
                    open={openConfirmUnsavedChanges}
                    closeConfirm={closeClickOutside}
                    dialogTitle={'Unsaved changes'}
                    dialogDescription={'You have unsaved changes. Do you want to save them?'}
                    dialogConfirmButtonLabel={'Save'}
                    dialogCancelButtonLabel={'Cancel'}
                    handleConfirm={addUpdateSpeaker}
                    handleDiscardChanges={handleDiscardChanges}
                />
            )}
            {openConfirmUnsavedImageChanges && (
                <Confirm
                    open={openConfirmUnsavedImageChanges}
                    closeConfirm={closeClickOutside}
                    dialogTitle={'Unsaved changes'}
                    dialogDescription={'You have unsaved changes. Do you want to save them?'}
                    dialogConfirmButtonLabel={'Save'}
                    dialogCancelButtonLabel={'Cancel'}
                    handleConfirm={uploadFileHandler}
                    handleDiscardChanges={handleDiscardImageChanges}
                />
            )}
        </div>
    );
};

export default SpeakerForm;
