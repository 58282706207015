import React from 'react';
import {connect} from 'react-redux';
import CreateGroupChat from './CreateGroupChat';
import PrivateChat from './PrivateChat/PrivateChat';
import GroupChat from './GroupChat/GroupChat';
import * as actions from '../../../store/actions/index';
import '../../../CSS/chats.scss';
import SideButtons from './SideButtons';
import ChatsHeader from './ChatsHeader';
import ChatsList from './ChatsList/ChatsList';
import NoChatsOverlay from './NoChatsOverlay';
import {UsersStatusesContext} from '../../../Stores/users-statuses';
import {preventDefaultDrag} from '../../../Utils/utils';
import {
    disconnectFromGroupChatStream,
    disconnectFromPrivateChatStream,
    usersConnectedToVideoStream,
} from '../../../Api/socketApi';
import Confirm from '../../../Dialogs/Confirm';

class Chats extends React.Component {
    state = {
        openCreateGroupChatDialog: false,
        openNewChatCloseConfirmation: false,
        sortedChats: [],
        close: false,
        leave: false,
        timeAgoPrivateChat: '',
        showBanner: false,
    };

    componentDidMount() {
        // every time the chat dropdown is opened, we get all the chats (private and group)
        // we also get the online users
        this.props.onGetPrivateChats();
        this.props.onGetGroupChats();

        // we sort the chats based on the timestamp
        this.sortChats();
        this.interval = setInterval(() => this.forceUpdate(), 60000);

        // listen to users connecting to video call stream and send the
        // show banner state to the group / private chat component
        const {isActive} = this.props.videoConference;
        usersConnectedToVideoStream(({users}) => {
            if (users.length >= 1 && !isActive) {
                this.setState({showBanner: true});
            } else {
                this.setState({showBanner: false});
            }
        });
    }

    componentDidUpdate(prevProps) {
        // after we got the chats in componentDidMount
        // we sort them and also,
        // only on desktop, we automatically open the first chat in list
        if (
            prevProps.user.privateChats !== this.props.user.privateChats ||
            prevProps.user.groupChats !== this.props.user.groupChats
        ) {
            this.sortChats();
            if (this.props.isLargeScreen || this.props.isTablet) {
                this.openFirstChatInList();
            }
        }

        if (prevProps.activeChatRoomId !== this.props.activeChatRoomId && this.props.videoConference.isActive) {
            this.props.closeVideoConference(this.props.videoConference);
        }
    }

    componentWillUnmount() {
        // when the user closes the chats dropdown, also reset the current active chatId
        this.props.onResetActiveChatId();
        clearInterval(this.interval);
    }

    handleOpenCreateGroupChat = () => {
        this.setState(
            {
                openCreateGroupChatDialog: true,
            },
            () => {
                this.props.onResetActiveChatId();
            }
        );
    };
    handleCloseCreateGroupChat = () => this.setState({openCreateGroupChatDialog: false});
    handleOpenNewChatCloseConfirmation = () => this.setState({openNewChatCloseConfirmation: true});
    handleCloseNewChatCloseConfirmation = () => this.setState({openNewChatCloseConfirmation: false});
    // if video call is open
    handleLeaveAndOpenNewChat = () => {
        const {user, activeChatRoomId, videoConference, closeVideoConference} = this.props;
        // close video conference
        closeVideoConference(videoConference);
        // disconnect from private if private
        disconnectFromPrivateChatStream(user._id, activeChatRoomId);
        // disconnect from group if group
        disconnectFromGroupChatStream(user._id, activeChatRoomId);
        // display create NEW CHAT
        this.handleOpenCreateGroupChat();
        // close popup
        this.handleCloseNewChatCloseConfirmation();
    };

    closeComponent = () => {
        this.props.onResetActiveChatId();
        clearInterval(this.interval);
        this.props.onGetGroupChats();
        this.setState({leave: true});
    };

    sortChats = () => {
        let sortedChats = this.props.user?.privateChats?.concat(this.props.user?.groupChats);
        sortedChats.sort(function (x, y) {
            let date1 = new Date(x?.chat?.lastMessageAt);
            let date2 = new Date(y?.chat?.lastMessageAt);
            return date2 - date1;
        });
        this.setState({sortedChats: sortedChats});
    };

    openFirstChatInList = () => {
        // only if we dont't have an active chat we open the first one in the list
        if (!this.props.activeChatRoomId && this.state.sortedChats.length && !this.state.leave) {
            const firstChatInList = this.state.sortedChats[0].chat;
            if (firstChatInList.users) {
                // is a group chat and we should handle opening that type of chat
                this.props.onOpenGroupChat(firstChatInList._id);
            } else {
                // is a private chat and we should handle opening that type of chat
                this.props.onOpenPrivateChat(firstChatInList._id);
            }
        } else if (!this.props.activeChatRoomId && this.state.sortedChats.length > 1 && this.state.leave) {
            const firstChatInList = this.state.sortedChats[1].chat;
            if (firstChatInList.users) {
                // is a group chat and we should handle opening that type of chat
                this.props.onOpenGroupChat(firstChatInList._id);
            } else {
                // is a private chat and we should handle opening that type of chat
                this.props.onOpenPrivateChat(firstChatInList._id);
            }
            this.setState({leave: false});
        }
    };

    render() {
        const {activeChatRoomId, isLargeScreen, isTablet, isMobile, videoConference, translation, defaultTranslation} =
            this.props;
        const {showBanner, openCreateGroupChatDialog, openNewChatCloseConfirmation, sortedChats} = this.state;
        const totalChats = this.props.user.groupChats.length + this.props.user.privateChats.length;
        const expandedChat = (this.props.maximizeChats.seeExpandedVideoConference || isTablet) && !isMobile;
        return (
            <div
                className={`chat-page dropdown-component ${!activeChatRoomId ? 'no-active-chat' : ''} ${
                    expandedChat ? 'expanded' : ''
                } ${totalChats === 0 ? 'no-chats' : ''} ${true ? 'has-banner' : ''}`}
                data-close={this.state.close ? 'true' : ''}
            >
                {isLargeScreen && <SideButtons videoConference={videoConference.isActive} translation={translation} />}
                <ChatsHeader
                    translation={translation}
                    chatsProps={this.props}
                    openCreateGroupChatDialog={openCreateGroupChatDialog}
                    // if conference isActive display prompt else open New Chat window
                    handleOpenCreateGroupChat={
                        videoConference.isActive
                            ? this.handleOpenNewChatCloseConfirmation
                            : this.handleOpenCreateGroupChat
                    }
                    handleCloseCreateGroupChat={this.handleCloseCreateGroupChat}
                />

                <div onDragStart={preventDefaultDrag} className="chats-container" data-number-chats={totalChats}>
                    <div onDragStart={preventDefaultDrag} className="chatsPage">
                        <div
                            onDragStart={preventDefaultDrag}
                            className="chats-list-container"
                            data-number-chats={totalChats}
                        >
                            {this.state.openCreateGroupChatDialog && (
                                <CreateGroupChat handleCloseCreateGroupChat={this.handleCloseCreateGroupChat} />
                            )}
                            <ChatsList
                                sortedChats={sortedChats}
                                handleCloseCreateGroupChat={this.handleCloseCreateGroupChat}
                                videoConference={videoConference.isActive}
                                translation={translation}
                            />
                        </div>
                        <div
                            className={`opened-chat-container `}
                            data-number-chats={this.props.user.groupChats.length + this.props.user.privateChats.length}
                        >
                            {sortedChats.length === 0 ? (
                                <NoChatsOverlay translation={translation} />
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="opened-chat-wrapper">
                                    {!activeChatRoomId &&
                                        (this.props.maximizeChats.seeExpandedVideoConference ||
                                            !isLargeScreen ||
                                            !isTablet) && (
                                            <p onDragStart={preventDefaultDrag} className="no-active-chat-message">
                                                {translation?.chatsDropdown.noOpenedChatText}
                                            </p>
                                        )}
                                    {activeChatRoomId && this.props.isGroupChat ? (
                                        <GroupChat
                                            openCreateGroupChatDialog={openCreateGroupChatDialog}
                                            showBanner={showBanner}
                                            closeChats={this.closeComponent}
                                        />
                                    ) : null}
                                    {activeChatRoomId && !this.props.isGroupChat ? (
                                        <PrivateChat
                                            openCreateGroupChatDialog={openCreateGroupChatDialog}
                                            showBanner={showBanner}
                                        />
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Confirm
                    open={openNewChatCloseConfirmation}
                    closeConfirm={this.handleCloseNewChatCloseConfirmation}
                    dialogTitle={
                        translation?.chatsDropdown.leaveChatConfirmationTitle ||
                        defaultTranslation?.chatsDropdown.leaveChatConfirmationTitle
                    }
                    dialogDescription={
                        translation?.chatsDropdown.leaveChatConfirmationText ||
                        defaultTranslation?.chatsDropdown.leaveChatConfirmationText
                    }
                    dialogConfirmButtonLabel={translation?.generalText.leave || defaultTranslation?.generalText.leave}
                    handleConfirm={this.handleLeaveAndOpenNewChat}
                />
            </div>
        );
    }
}

Chats.contextType = UsersStatusesContext;

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        isGroupChat: state.user.topNavigation.isGroupChat,
        maximizeChats: state.user.maximizeChats,
        isLargeScreen: state.layout.isLargeScreen,
        isTablet: state.layout.isTablet,
        isMobile: state.layout.isMobile,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        videoConference: state.videoConference,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetPrivateChats: () => dispatch(actions.getPrivateChats()),
        onGetGroupChats: () => dispatch(actions.getGroupChats()),
        onOpenGroupChat: (groupChatId) => dispatch(actions.openGroupChat(groupChatId)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onResetActiveChatId: () => dispatch(actions.resetActiveChatId()),
        closeVideoConference: (videoConference) => dispatch(actions.closeVideoConference(videoConference)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chats);
