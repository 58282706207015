import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {SelectValidator} from 'react-material-ui-form-validator';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import TextWithTooltipOnOverflow from "../TextWithTooltipOnOverflow/TextWithTooltipOnOverflow";
import {changeSelectArrows} from "../../Utils/utils";

class SelectFieldProfile extends ValidatorComponent {
    componentDidMount () {
        changeSelectArrows();
    }

    componentWillUnmount() {}

    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    renderValidatorComponent() {
        const {field} = this.props;

        return (
            <FormControl id='select-field-profile' variant="outlined" fullWidth>
                {/*In order for the select arrow to be as in the design system:*/}
                {/*- add className scaled-select-arrow to the SelectValidator*/}
                {/*- call changeSelectArrows()*/}
                <SelectValidator
                    className={'scaled-select-arrow'}
                    onChange={this.handleChange}
                    name={field.name}
                    value={field.value ? field.value : ''}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    label={field.label}
                    variant="outlined"
                    margin="normal"
                    MenuProps={{
                        className: `color-primary`,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                    }}
                    IconComponent={props => (
                        <ExpandMoreRoundedIcon {...props} className={`material-icons ${props.className}`}/>
                    )}
                >
                    {field.options.map((option) => {
                        return (
                            <MenuItem key={option} value={option}>
                                <TextWithTooltipOnOverflow text={option} />
                            </MenuItem>
                        );
                    })}
                </SelectValidator>
            </FormControl>
        );
    }
}

export default SelectFieldProfile;
