import {preventDefaultDrag} from "../../../Utils/utils";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import {TextField} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

export function ResourceLink({link, handleRemoveLink}) {
    return <li className="resource-container">
        <div className="resource-inputs links">
            <a href={link.link} target="_blank" rel="noopener noreferrer">
                <TextField
                    label={'Link URL'}
                    type="text"
                    value={link.link}
                    fullWidth={true}
                    variant="outlined"
                    notched
                    InputProps={{endAdornment: <LinkIcon/>, classes: {root: 'cursor-pointer'}}}
                    disabled
                />
            </a>
            <TextField
                label={'Link label'}
                type="text"
                value={link.displayName}
                fullWidth={true}
                variant="outlined"
                notched
                InputProps={<LinkIcon />}
                disabled
            />
        </div>

        <div onDragStart={preventDefaultDrag} className="removefile-prop">
            <Button
                variant={"text"}
                type="button"
                onClick={handleRemoveLink}
                startIcon={<ClearIcon/>}
            >
                REMOVE
            </Button>
        </div>
    </li>;
}