import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    announcementsLoading: false,
    announcementLoading: false,
    statisticsLoading: false,
    announcementsDisabled: false,
    announcementsBannerOpen: false,
    announcements: [],
    runningAnnouncements: [],
    auditoriumsAndExhibitBooths: {},
    statistics: [],
    error: '',
};

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.GET_ANNOUNCEMENTS_START:
                draft.announcementsLoading = true;
                break;

            case actionTypes.ADD_ANNOUNCEMENT_START:
            case actionTypes.DELETE_ANNOUNCEMENT_START:
            case actionTypes.UPDATE_ANNOUNCEMENT_START:
            case actionTypes.STOP_ANNOUNCEMENT_START:
            case actionTypes.RUN_ANNOUNCEMENT_START:
                draft.announcementLoading = true;
                break;

            case actionTypes.GET_ANNOUNCEMENTS_FAIL:
                draft.error = action?.error;
                draft.announcementsLoading = false;
                break;

            case actionTypes.GET_EVENT_ANNOUNCEMENT_STATISTICS_START:
                draft.statisticsLoading = true;
                break;

            case actionTypes.GET_EVENT_ANNOUNCEMENT_STATISTICS_SUCCESS:
                draft.announcementsDisabled = false;
                draft.statistics = action?.payload.data;
                draft.statisticsLoading = false;
                break;

            case actionTypes.GET_EVENT_ANNOUNCEMENT_STATISTICS_FAIL:
                draft.statisticsLoading = false;
                draft.error = action?.error;
                draft.statisticsLoading = false;
                break;

            case actionTypes.UPDATE_ANNOUNCEMENT_FAIL:
            case actionTypes.DELETE_ANNOUNCEMENT_FAIL:
            case actionTypes.RUN_ANNOUNCEMENT_FAIL:
            case actionTypes.STOP_ANNOUNCEMENT_FAIL:
                draft.error = action?.error;
                draft.announcementLoading = false;
                break;

            case actionTypes.ADD_ANNOUNCEMENT_FAIL:
            case actionTypes.GET_RUNNING_ANNOUNCEMENTS_FAIL:
            case actionTypes.GET_AUDITORIUMS_AND_EXHIBIT_BOOTHS_FAIL:
                draft.error = action?.error;
                draft.announcementLoading = false;
                break;
            case actionTypes.INCREASE_ANNOUNCEMENT_CLICK_COUNT_FAIL:
                break;

            case actionTypes.GET_ANNOUNCEMENTS_SUCCESS:
                draft.announcementsDisabled = false;
                draft.announcementsLoading = false;
                draft.announcements = action.payload;
                draft.error = '';
                break;

            case actionTypes.ANNOUNCEMENTS_DISABLED:
                draft.announcementsDisabled = true;
                break;

            case actionTypes.ADD_ANNOUNCEMENT_SUCCESS:
                draft.announcements.push(action.payload.data);
                draft.announcementLoading = false;
                break;

            case actionTypes.UPDATE_ANNOUNCEMENT_SUCCESS:
                // find announcement in existing list
                const updatedAnnouncement = action.payload.data;
                const updatedAnnouncementIndex = draft.announcements.findIndex(
                    (announcement) => announcement._id === action.payload.data._id
                );

                if (updatedAnnouncementIndex !== -1) {
                    draft.announcements[updatedAnnouncementIndex] = {...updatedAnnouncement};
                }

                draft.announcementLoading = false;
                break;

            case actionTypes.RUN_ANNOUNCEMENT_SUCCESS:
                // find announcement in existing list
                const runAnnouncementIndex = draft.announcements.findIndex(
                    (announcement) => announcement._id === action.announcementId
                );

                if (runAnnouncementIndex !== -1) {
                    draft.announcements[runAnnouncementIndex].isRunning = true;
                    draft.announcements[runAnnouncementIndex].secondsLeft = action.secondsLeft;
                    draft.announcements[runAnnouncementIndex].infiniteTime = action.infiniteTime;
                }

                draft.announcementLoading = false;
                break;

            case actionTypes.STOP_ANNOUNCEMENT_SUCCESS:
                // find announcement in existing list
                const stopAnnouncementIndex = draft.announcements.findIndex(
                    (announcement) => announcement._id === action.payload
                );

                if (stopAnnouncementIndex !== -1) {
                    draft.announcements[stopAnnouncementIndex].isRunning = false;
                }

                draft.announcementLoading = false;
                break;

            case actionTypes.DELETE_ANNOUNCEMENT_SUCCESS:
                draft.announcements = draft.announcements.filter(
                    (announcement) => announcement._id !== action.announcementId
                );

                draft.announcementLoading = false;
                break;

            case actionTypes.GET_RUNNING_ANNOUNCEMENTS_SUCCESS:
                draft.runningAnnouncements = action.payload;
                draft.announcementsLoading = false;
                break;

            case actionTypes.INCREASE_ANNOUNCEMENT_CLICK_COUNT_SUCCESS:
                break;

            case actionTypes.GET_AUDITORIUMS_AND_EXHIBIT_BOOTHS_SUCCESS:
                draft.auditoriumsAndExhibitBooths = action.payload.data;
                break;

            case actionTypes.SET_ANNOUNCEMENTS_BANNER_OPEN:
                draft.announcementsBannerOpen = action.value;
                break;

            default:
                break;
        }
    });

export default reducer;
