import React, {useEffect, useRef} from 'react';
import EventIcon from '@material-ui/icons/Event';
import {useSelector} from 'react-redux';
import {getProgramFormattedTitleDate} from '../../../../Utils/utils';
import moment from 'moment';
import {Tooltip} from '@material-ui/core';
import LiveLabel from '../../ProgramComponents/LiveLabel';
import {ReactComponent as AddToMyAgenda} from '../Agenda/helpers/svgs/add-to-my-agenda.svg';
import {ReactComponent as Discard} from '../Agenda/helpers/svgs/discard.svg';
import {ReactComponent as PlayIcon} from '../Agenda/helpers/svgs/session-icon.svg';
import {ReactComponent as ListStyle} from './svgs/list.svg';
import {ReactComponent as CalendarStyle} from './svgs/calendar.svg';
import './TimeslotStyles.scss';
import {isMobile} from 'react-device-detect';

const Timeslot = ({
    timeslot,
    isNewDay,
    handleSetActiveTimeslot,
    activeTimeslot,
    handleSetCalendarTimeslot,
    refTimeslotsList,
    updateTimeslotsInViewport,
    addDeleteAgenda,
    isElementAddedToTimeslot,
    timeslotIndex,
}) => {
    const eventSlug = useSelector((state) => state?.event?.data?.slug);
    const languages = useSelector((state) => state.languages);
    const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const refTimeslot = useRef();

    const stopPropagation = (e) => {
        // we don't want to bubble the event until it reaches the timeslot <li> element
        // so that we don't open the timeslot details window
        e.stopPropagation();
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                updateTimeslotsInViewport(entry.target.id, entry.isIntersecting);
            },
            {
                root: refTimeslotsList.current,
                rootMargin: '0px',
                threshold: 1.0,
            }
        );

        observer.observe(refTimeslot.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    const getFormattedIdForDate = (timestamp) => {
        return moment(timestamp).format('l');
    };

    const currentDay = new Date(timeslot?.startTimestamp);

    return (
        <>
            {isNewDay && (
                <div
                    className={`${timeslotIndex === 0 ? 'item-list-style-icons' : ''} date-title`}
                    id={getFormattedIdForDate(timeslot.startTimestamp)}
                >
                    <div>{getProgramFormattedTitleDate(currentDay, languages)}</div>
                </div>
            )}
            <div
                className={`timeslot ${activeTimeslot?._id === timeslot._id ? 'active' : ''}`}
                id={timeslot._id}
                ref={refTimeslot}
            >
                <div className={`timeslot-time `}>
                    <PlayIcon className={'play-icon'} />
                    <p>{timeslot.start}</p>
                    <p>{timeslot.end}</p>
                </div>
                <div className={'timeslot-details'}>
                    <div className={'live-timeslot-container'}>
                        <>
                            <p className={'auditorium-name'}>
                                <a
                                    href={`/event/${eventSlug}/auditorium/${timeslot.auditoriumIndex}`}
                                    onClick={stopPropagation}
                                >
                                    {timeslot.auditoriumName}
                                </a>
                            </p>
                            {timeslot.isLive && <LiveLabel />}
                        </>
                        {!isMobile && (
                            <div className={`content-filler`} onClick={handleSetActiveTimeslot(timeslot)}></div>
                        )}
                    </div>
                    <div className={'keeper-sub'}>
                        <h5 className={'timeslot-title'} onClick={handleSetActiveTimeslot(timeslot)}>
                            {timeslot.title}
                        </h5>
                        <p className={'timeslot-description'}>{timeslot.speakers}</p>
                    </div>
                </div>
                <div className="container-support-addtomyagenda add-to-calendar-button-container add-timeslot-to-calendar">
                    {isLargeScreen ? (
                        <Tooltip arrow title={'Add slot to calendar'} placement="bottom-end">
                            <EventIcon onClick={handleSetCalendarTimeslot(timeslot)} />
                        </Tooltip>
                    ) : (
                        <EventIcon onClick={handleSetCalendarTimeslot(timeslot)} />
                    )}
                    {isLargeScreen ? (
                        <Tooltip
                            arrow
                            title={`${
                                isElementAddedToTimeslot(timeslot)
                                    ? translation?.agendaTranslations?.addedToYourAgenda ||
                                      defaultTranslation.agendaTranslations?.addedToYourAgenda
                                    : translation?.agendaTranslations?.addToMyAgenda ||
                                      defaultTranslation.agendaTranslations?.addToMyAgenda
                            }`}
                            placement="bottom-end"
                        >
                            <button className="add-to-my-agenda-btn" onClick={addDeleteAgenda(timeslot)}>
                                {isElementAddedToTimeslot(timeslot) ? (
                                    <Discard className={'discard'} />
                                ) : (
                                    <AddToMyAgenda />
                                )}
                            </button>
                        </Tooltip>
                    ) : (
                        <button className="add-to-my-agenda-btn" onClick={addDeleteAgenda(timeslot)}>
                            {isElementAddedToTimeslot(timeslot) ? <Discard className={'discard'} /> : <AddToMyAgenda />}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default Timeslot;
