import {preventDefaultDrag} from "../../../Utils/utils";
import ResourceDocument from "./ResourceDocument";
import Spinner from "../../../SmallLayoutComponents/Spinner";
import React from "react";

export const ExistingResourceDocuments = ({removeDocument, documents, loading}) => {
    const handleRemoveDocument = (documentId) => (e) => removeDocument(documentId);

    return <div onDragStart={preventDefaultDrag} className="options-container">
        <div onDragStart={preventDefaultDrag} className="single-option-container resources">
            {documents && documents.length > 0 && (
                <ul className={'resources-list'}>
                    {documents.map((document) => {
                        return (
                            <ResourceDocument
                                document={document}
                                removeDocument={handleRemoveDocument(document._id)}
                            />
                        );
                    })}
                    {loading && (
                        <li className="spinner-container">
                            <Spinner/>
                        </li>
                    )}
                </ul>
            )}
        </div>
    </div>;
}