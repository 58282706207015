import React from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as BackIcon} from '../../Images/svg/back.svg';
import {preventDefaultDrag} from '../../Utils/utils';

class BackButton extends React.Component {
    state = {
        buttonLink: '',
        hideButtonLink: true,
    };

    componentDidMount() {
        this.setButtonLink();
        this.props.history.listen(() => {
            this.setButtonLink();
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.eventSlug !== this.props.eventSlug || prevProps.buildingId !== this.props.buildingId) {
            this.setButtonLink();
        }
    }

    setButtonLink = () => {
        // we can't use the goBack method from the History
        // because we always want to get just a levelBack in the App,
        // we can't use the "react router" default back functionality

        const {eventSlug, buildingId} = this.props;

        const currentPathname = this.props.history?.location?.pathname;
        const eventRoot = `/event/${eventSlug}`;
        const buildingRoot = `/event/${eventSlug}/village/building/${buildingId}`;

        const mainLobbyPagePathname = `${eventRoot}/lobby`;

        // Building Links LOGIC START

        // links that redirect to Building Auditorium Archive
        const buildingAuditoriumArchiveTimeslot = `${buildingRoot}/auditorium-archive/timeslot/`;
        const goToBuildingAuditoriumArchiveLinks = [buildingAuditoriumArchiveTimeslot];

        // links that redirect to BUILDING Lobby
        const buildingShowfloor = `${buildingRoot}/showfloor`;
        const buildingAuditorium = `${buildingRoot}/auditorium`;
        const buildingAuditoriumArchive = `${buildingRoot}/auditorium-archive`;
        const goToBuildingLobbyLinks = [buildingShowfloor, buildingAuditorium, buildingAuditoriumArchive];

        // links that redirect to village
        const lobbyBuilding = `${eventRoot}/village/building/${buildingId}/lobby`;
        const goToVillageLinks = [lobbyBuilding];

        // Building Links LOGIC END

        // Event Links LOGIC START

        // links that redirect to Event Archive
        const eventArchiveTimeslot = `${eventRoot}/event-archive/event/`;
        const goToEventArchiveLinks = [eventArchiveTimeslot];

        // links that redirect to Event Auditorium Archive
        const eventAuditoriumArchiveTimeslot = `${eventRoot}/auditorium-archive/`;
        const goToEventAuditoriumArchiveLinks = [eventAuditoriumArchiveTimeslot];

        // links that redirect to session
        const zoomSession = `${eventRoot}/rtmp-session`;
        const videoEngineSession = `${eventRoot}/session`;
        const liveSession = `${eventRoot}/live-session`;
        const goToSessionsDashboardLinks = [zoomSession, videoEngineSession, liveSession];

        // links that redirect to Event Lobby
        const myAccount = `${eventRoot}/my-account`;
        const organizerDashboard = `${eventRoot}/dashboard`;
        const exhibitorDashboard = `${eventRoot}/exhibitor-dashboard`;
        const exhibitorDashboardBoothPage = `${eventRoot}/exhibitor-booth-view`;
        const eventShowfloor = `${eventRoot}/showfloor`;
        const eventAuditorium = `${eventRoot}/auditorium`;
        const eventAuditoriumArchive = `${eventRoot}/auditorium-archive`;
        const eventArchive = `${eventRoot}/event-archive`;
        const eventVillage = `${eventRoot}/village`;
        const goToMainLobbyLinks = [
            myAccount,
            organizerDashboard,
            exhibitorDashboard,
            exhibitorDashboardBoothPage,
            eventShowfloor,
            eventAuditorium,
            eventAuditoriumArchive,
            eventArchive,
            eventVillage,
        ];

        // links that redirect to Poster_Study/exhibitor dashboard
        const exhibitorDashboardBoothPreview = `${eventRoot}/exhibitor-booth-preview`;

        const goToExhibitorDashboardLinks = [
            exhibitorDashboardBoothPreview,
        ];

        // Event Links LOGIC END

        if (currentPathname.includes(mainLobbyPagePathname)) {
            // we are on the lobby page and we don't want to display the backButton
            this.setState({
                hideButtonLink: true,
            });
        } else {
            let buttonLink = '';
            if (goToBuildingAuditoriumArchiveLinks.some((link) => currentPathname.includes(link))) {
                buttonLink = `${buildingAuditoriumArchive}`;
            } else if (goToBuildingLobbyLinks.some((link) => currentPathname.includes(link))) {
                buttonLink = `${lobbyBuilding}`;
            } else if (goToVillageLinks.some((link) => currentPathname.includes(link))) {
                buttonLink = `${eventRoot}/village`;
            } else if (goToEventArchiveLinks.some((link) => currentPathname.includes(link))) {
                buttonLink = `${eventArchive}`;
            } else if (
                goToEventAuditoriumArchiveLinks.some(
                    (link) => currentPathname.includes(link) && currentPathname.includes(`/timeslot/`)
                )
            ) {
                buttonLink = `${eventAuditoriumArchive}`;
            } else if (goToMainLobbyLinks.some((link) => currentPathname.includes(link))) {
                buttonLink = `${eventRoot}/lobby`;
            } else if (goToSessionsDashboardLinks.some((link) => currentPathname.includes(link))) {
                buttonLink = `${eventRoot}/dashboard/event-sessions`;
            } else if (goToExhibitorDashboardLinks.some((link) => currentPathname.includes(link))) {
                buttonLink = `${eventRoot}/exhibitor-dashboard`;
            }

            this.setState({
                hideButtonLink: false,
                buttonLink: buttonLink,
            });
        }
    };

    render() {
        const {translation, defaultTranslation} = this.props;
        const {buttonLink, hideButtonLink} = this.state;
        if (hideButtonLink) {
            return null;
        }

        return (
            <Link className="back-button" tabIndex="0" to={{pathname: `${buttonLink}`}} data-is-navigation>
                <BackIcon fill={colors.grey} />
                <p onDragStart={preventDefaultDrag} className="button-link-label">
                    {translation?.generalText.back || defaultTranslation?.generalText.back}
                </p>
            </Link>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventSlug: state.event.eventSlug,
        buildingId: state.building.buildingId,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BackButton));
