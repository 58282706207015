import {useSelector} from 'react-redux';

const ErrorBar = () => {
    //redux
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);

    return (
        <div className="error-bar" id={'error-bar'}>
            <p>
                {translation.newLoginRegistrations.pleaseCompleteAllRequiredFields ||
                    defaultTranslation.newLoginRegistrations.pleaseCompleteAllRequiredFields}
            </p>
        </div>
    );
};
export default ErrorBar;
