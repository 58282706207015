import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import colors from '../CSS/_variables.module.scss';
import {ReactComponent as Close} from '../Images/svg/close-red.svg';
import {ReactComponent as PreviewIcon} from '../Images/svg/preview.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {checkIfLeftOrRightWindowIsUploaded, getWindowView} from "../Utils/utils";

export default class PreviewComponent extends React.Component {
    handleOption = (type, option) => {
        if (type === 'auditorium') {
            switch (option) {
                case 'v1':
                    return 'auditorium/auditorium_768.jpg';
                case 'v2':
                    return 'auditorium/academic_auditorium_768.jpg';
                case 'v3':
                    return 'auditorium/green_auditorium_768.jpg';
                default:
                    return 'auditorium/auditorium_768.jpg';
            }
        } else {
            let {event} = this.props;
            let hasAtLeastOneWindowUploaded = checkIfLeftOrRightWindowIsUploaded(event);
            if (hasAtLeastOneWindowUploaded) {
                switch (option) {
                    case 'v1':
                        return `lobby/windows_lobby_768.png`;
                    case 'v2':
                        return `lobby/windows_international_lobby_768.png`;
                    case 'v3':
                        return `lobby/windows_academic_lobby_768.png`;
                    case 'v4':
                        return `lobby/windows_green_lobby_768.png`;
                    default:
                        return `lobby/windows_lobby_nl_768.png`;
                }
            } else {
                switch (option) {
                    case 'v1':
                        return `lobby/lobby_nl_768.jpg`;
                    case 'v2':
                        return `lobby/international_lobby_768.jpg`;
                    case 'v3':
                        return `lobby/academic_lobby_768.jpg`;
                    case 'v4':
                        return `lobby/green_lobby_768.jpg`;
                    default:
                        return `lobby/lobby_nl_768.jpg`;
                }
            }
        }
    };
    render() {
        const {filesUrl, optionType, selectedOption, isPreviewOpen, handlePreviewOpen, handlePreviewClose, lobby, event} = this.props;
        let hasAtLeastOneWindowUploaded = checkIfLeftOrRightWindowIsUploaded(event);
        return (
            <div className="position-relative current-logo-container cover-img-container preview-only-image">

                {(hasAtLeastOneWindowUploaded && lobby) &&
                    <div className={'small-preview-window-view left-side-position'}>
                        <img alt="left window" className={'left-window-preview'} src={getWindowView('left', event)}/>
                    </div>

                }
                <img
                    src={filesUrl + this.handleOption(optionType, selectedOption)}
                    alt="Custom design"
                    style={{cursor: 'pointer'}}
                    onClick={handlePreviewOpen}
                    className={'position-absolute'}
                />

                {(hasAtLeastOneWindowUploaded && lobby) &&
                    <div className={'small-preview-window-view right-side-position'}>
                        <img alt="right window" className={'right-window-preview'} src={getWindowView('right', event)}/>
                    </div>

                }
                <Button
                    type="button"
                    color={'primary'}
                    classes={{root: 'preview-only-btn'}}
                    onClick={handlePreviewOpen}
                    startIcon={<VisibilityIcon />}
                >
                    Preview
                </Button>
                <Dialog
                    fullScreen
                    disableRestoreFocus
                    open={isPreviewOpen}
                    onClose={handlePreviewClose}
                    classes={{paperFullScreen: 'img-review-dialog'}}
                >
                    {lobby ?
                        (<div className="position-relative full-lobby-preview">
                            {hasAtLeastOneWindowUploaded &&
                                <div className={'full-preview-left'}>
                                    <img alt="left window" className={'position-relative'} src={getWindowView('left', event)}/>
                                </div>

                            }

                            <img className={`${hasAtLeastOneWindowUploaded && 'position-absolute'}`} src={filesUrl + this.handleOption(optionType, selectedOption)} alt="Custom design" />

                            {hasAtLeastOneWindowUploaded &&
                                <div className={'full-preview-right'}>
                                    <img alt="right window" className={'position-relative'} src={getWindowView('right', event)}/>
                                </div>

                            }
                        </div>
                        ) : (<img src={filesUrl + this.handleOption(optionType, selectedOption)} alt="Custom design" />)
                    }
                    <DialogActions className="sm-popup-close-container">
                        <Button
                            classes={{label: 'sm-close-btn-label'}}
                            className="sm-popup-close-btn"
                            disableTouchRipple
                            disableFocusRipple
                            disableRipple
                            type="button"
                            onClick={handlePreviewClose}
                        >
                            <Close fill={colors.white} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
