// This file exports common mime type constants. The list is not finished and new types need to be added in future.
// Add mime types from here https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// TODO: add other mime types
export const JPEG = 'image/jpeg';
export const JPG = 'image/jpg';
export const PNG = 'image/png';
export const PDF = 'application/pdf';
export const DOC = 'application/msword';
export const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const XLS = 'application/vnd.ms-excel';
export const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

/**
 * This hook returns string of supported mime types
 * NOTE: list is not complete, pls add new mime types
 * @param {*} mimeTypes ['jpeg', 'jpg', 'pdf', 'doc', 'docx', 'xls', 'xlsx']
 * @returns string of supported mime types
 */
export const useMimeTypes = (mimeTypes) => {
    if (!Array.isArray(mimeTypes)) {
        throw Error(`argument ${mimeTypes} should be an array`);
    }
    // TODO: add other mime types to the list as well
    const allMimeTypesMap = new Map([
        ['jpeg', JPEG],
        ['jpg', JPG],
        ['png', PNG],
        ['pdf', PDF],
        ['doc', DOC],
        ['docx', DOCX],
        ['xls', XLS],
        ['xlsx', XLSX],
    ]);
    const supportedMimeTypes = mimeTypes.map((type) => allMimeTypesMap.get(type)).join(',');
    return supportedMimeTypes;
};
