import React from 'react';
import HeaderMenu from '../../Components/HeaderMenu';
import '../../CSS/otherPages.scss';
import Footer from '../../SmallLayoutComponents/Footer';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {preventDefaultDrag} from '../../Utils/utils';
import InfoIcon from '@material-ui/icons/Info';

class PrivacyPolicy extends React.Component {
    state = {
        brandingTranslation: '',
        gdprText: '',
        showDisclaimer: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.languages.eventLanguage !== this.props.languages.eventLanguage ||
            prevProps.location.search !== this.props.location.search
        ) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const {brandingTranslations} = this.props.eventInfo;

        let selectedLang = this.props.eventInfo?.language;
        if (this.props.location.search.includes('?lang=')) {
            selectedLang = this.props.location.search.split('=')[1];
            this.setState({language: selectedLang});
        }
        let brandingTranslation = brandingTranslations.find((translation) => translation.language === selectedLang);
        if (!brandingTranslation) {
            brandingTranslation = brandingTranslations.find(
                (translation) => translation.language === this.props.eventInfo?.language
            );
        }
        let gdprText = brandingTranslation?.eventTermsAndConditions;
        let showDisclaimer = brandingTranslation?.eventTermsAndConditionsDisclaimer || false;

        this.setState({
            brandingTranslation: brandingTranslation,
            gdprText: gdprText,
            showDisclaimer: showDisclaimer,
        });
    };

    render() {
        const {brandingTranslation, gdprText, showDisclaimer} = this.state;

        return (
            <div onDragStart={preventDefaultDrag} className="gdpr-page">
                <HeaderMenu className={'white'} coloredLogo disabledLanguageSelector />
                <div onDragStart={preventDefaultDrag} className="header-spacing-container">
                    <div onDragStart={preventDefaultDrag} className="gdpr-container">
                        {showDisclaimer && (
                            <React.Fragment>
                                <div onDragStart={preventDefaultDrag} className="translated-ai-info">
                                    <InfoIcon color="primary" />
                                    <span>
                                        {this.props.languages.translations[brandingTranslation.language].gdpr
                                            .disclaimerTextFirst ||
                                            this.props.languages.translations['en'].gdpr.disclaimerTextFirst}{' '}
                                        <Link
                                            to={{
                                                pathname: `${this.props.location.pathname}`,
                                                search: `?lang=en`,
                                            }}
                                        >
                                            <span>
                                                {this.props.languages.translations[brandingTranslation.language].gdpr
                                                    .disclaimerTextSecond ||
                                                    this.props.languages.translations['en'].gdpr.disclaimerTextSecond}
                                            </span>
                                        </Link>
                                    </span>
                                </div>
                            </React.Fragment>
                        )}
                        <h1>Event Terms and Conditions</h1>
                        <p>
                            {brandingTranslation &&
                                gdprText &&
                                gdprText.split('\n').map((item, key) => {
                                    return (
                                        <span onDragStart={preventDefaultDrag} draggable="false" key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                        </p>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventInfo: state.event.eventInfo,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
    };
};

export default withRouter(connect(mapStateToProps)(PrivacyPolicy));
