import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    data: null,
};

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_EVENT_ARCHIVE_START:
                draft.loading = true;
                draft.error = false;
                draft.errorMessage = '';
                break;

            case actionTypes.GET_EVENT_ARCHIVE_SUCCESS:
                draft.loading = false;
                draft.data = action.payload;
                break;
                
            case actionTypes.GET_EVENT_ARCHIVE_FAIL:
                draft.loading = false;
                draft.error = true;
                draft.errorMessage = action.payload;
                draft.data = null;
                break;
                
            case actionTypes.GET_EVENT_ARCHIVE_RESET:
                draft.loading = false;
                draft.error = true;
                draft.errorMessage = '';
                draft.data = null;
                break;
                
            case actionTypes.UPDATE_SINGLE_EVENT_ARCHIVE_SUCCESS:
                const updatedEvent = action.payload;
                const updatedEventIndex = draft.data.findIndex((event) => event._id === updatedEvent._id);
                if (updatedEventIndex !== -1) {
                    draft.data[updatedEventIndex] = updatedEvent;
                }
                break;
    
            default:
                break;
        }
    });

export default reducer;
