import React, {PureComponent} from 'react';
import {preventDefaultDrag} from '../../../../Utils/utils';
import {connect} from 'react-redux';
import {FormControlLabel, Switch, Tooltip} from "@material-ui/core";
import {toggleEnableWelcomeScreen} from "../../../../store/actions/organizerActions";
import ConfirmDialog from "../../../../Dialogs/Confirm";
import { isYoutubeOrVimeoLinkRule } from './../../../../Utils/validationRules';

class WelcomeScreenHeader extends PureComponent {
    state = {
        confirmEnableDialogOpened: false,
        confirmDisableDialogOpened: false,
    };

    toggleEnableWelcomeScreen = e => {
        // if the organizer wants to enable the welcomeScreen
        // display the confirm dialog
        if(e.target.checked){
            this.setState({
                confirmEnableDialogOpened: true
            })
        } else{
            // organizer wants to disable the welcome screen
            this.setState({
                confirmDisableDialogOpened: true
            })
        }
    };

    enableWelcomeScreen = () => {
        const {eventId} = this.props;
        this.props.toggleEnableWelcomeScreen(eventId, true)
            .then(this.closeDialogs)
    };

    disableWelcomeScreen = () => {
        const {eventId} = this.props;
        this.props.toggleEnableWelcomeScreen(eventId, false)
            .then(this.closeDialogs)
    };

    getSwitchButtonTooltipMessage = () => {
        const {eventWelcomeScreen} = this.props;
        let message = '';

        if(eventWelcomeScreen.data?.type === 'video' && !eventWelcomeScreen.data?.videoUrl){
            message = 'Please save a video URL before enabling the Welcome screen';
        }
        if(eventWelcomeScreen.data?.type === 'picture' && !eventWelcomeScreen.data?.desktopImage){
            message = 'Please save a desktop picture before enabling the Welcome screen'
        }

        return message;
    };

    checkCanEnableWelcomeScreen = () => {
        const {eventWelcomeScreen} = this.props;
        let canEnableWelcomeScreen = false;
        if(eventWelcomeScreen.data?.type === 'video' && eventWelcomeScreen.data?.videoUrl && this.checkVideoUrlIsValid()){
            canEnableWelcomeScreen = true;
        }
        if(eventWelcomeScreen.data?.type === 'picture' && eventWelcomeScreen.data?.desktopImage){
            canEnableWelcomeScreen = true;
        }
        return canEnableWelcomeScreen
    };

    checkVideoUrlIsValid = () => {
        const videoUrl = this.props.eventWelcomeScreen.data?.videoUrl;
        let rule = isYoutubeOrVimeoLinkRule;
        let match = rule.test(videoUrl);
        return match;
    };

    closeDialogs = () => {
        this.setState({
            confirmEnableDialogOpened: false,
            confirmDisableDialogOpened: false,
        })
    };

    render() {
        const {confirmEnableDialogOpened, confirmDisableDialogOpened} = this.state;
        const {event} = this.props;

        return (
            <h4 className="advanced-options-title">
                WELCOME SCREEN
                <Tooltip
                    arrow
                    classes={{tooltip: 'ignore-rtl'}}
                    tabIndex="0"
                    title={this.getSwitchButtonTooltipMessage()}
                >
                    <div
                        tabIndex={
                            event.hasWelcomeScreen
                                ? '-1'
                                : '0'
                        }
                        className={`switch-container dialog-btn-uppercase`}
                        onKeyDown={event.hasWelcomeScreen
                            ? preventDefaultDrag
                            : this.handleEnterKey
                        }
                    >
                        <FormControlLabel
                            disabled={!this.checkCanEnableWelcomeScreen()}
                            label={event.hasWelcomeScreen ? 'Enabled' : 'Disabled'}
                            classes={{label: 'switch-label'}}
                            control={
                                <Switch
                                    tabIndex="-1"
                                    color="secondary"
                                    name="hasWelcomeScreen"
                                    onChange={this.toggleEnableWelcomeScreen}
                                    checked={event.hasWelcomeScreen}
                                />}
                        />
                    </div>
                </Tooltip>
                <ConfirmDialog
                    open={confirmEnableDialogOpened}
                    closeConfirm={this.closeDialogs}
                    dialogTitle={`Enable welcome screen`}
                    dialogDescription={`By enabling this feature, the Welcome image/video will be displayed in the event lobby`}
                    handleConfirm={this.enableWelcomeScreen}
                    dialogCancelButtonLabel={`CANCEL`}
                    dialogConfirmButtonLabel={`ENABLE`}
                />
                <ConfirmDialog
                    open={confirmDisableDialogOpened}
                    closeConfirm={this.closeDialogs}
                    dialogTitle={`Disable welcome screen`}
                    dialogDescription={`By disabling this feature, the Welcome image/video will not be displayed in the event lobby anymore. You can re-enable this feature anytime`}
                    handleConfirm={this.disableWelcomeScreen}
                    dialogCancelButtonLabel={`CANCEL`}
                    dialogConfirmButtonLabel={`DISABLE`}
                />
            </h4>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        eventWelcomeScreen: state.eventWelcomeScreen
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleEnableWelcomeScreen: (eventId, hasWelcomeScreen) => dispatch(toggleEnableWelcomeScreen(eventId, hasWelcomeScreen)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreenHeader);