import {ReactComponent as CloseIcon} from 'Images/svg/close-red.svg';
import Button from '@material-ui/core/Button';

const AnnouncementsBannerMobile = ({announcement, handleCollapse, handleCallToActionButtonClicked}) => {
    const handleButtonClicked = (announcement) => e => {
        handleCallToActionButtonClicked(announcement)
    }
    return (
        <div className="announcements-banner-mobile single-announcement-banner">
            <div className="announcement">
                <div className="announcement-title">{announcement.title}</div>
                <div className="announcement-description">{announcement.description}</div>
            </div>
            <div className="bottom-menus">
                <div className="collapse-icon">
                    <CloseIcon onClick={handleCollapse} />
                </div>
                {announcement?.callToAction && (
                    <Button 
                        variant={"outlined"}
                        disableElevation
                        className={"light call-to-action-button"}
                        onClick={handleButtonClicked(announcement)}
                    >
                            {announcement.buttonLabel}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default AnnouncementsBannerMobile;
