import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {connect} from 'react-redux';
import Confirm from '../../../../../Dialogs/Confirm';
import PublishIcon from '@material-ui/icons/Publish';
import * as actions from '../../../../../store/actions';
import axios from '../../../../../store/axios-instance';
import {preventDefaultDrag, fireClickEvent} from '../../../../../Utils/utils';
import { verifyFileType } from 'Utils/verifyFileType';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

class SinglePartnerLogo extends React.Component {
    state = {
        newLogoFile: null,
        imageLogoPreviewUrl: null,
        imageLogoErrorText: '',
        openConfirmUnsavedChanges: false,
        navigationElement: null,
    };

    wrapperRef = React.createRef();
    handleClickOutside = this.handleClickOutside.bind(this);

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleLogoChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        let isValid = true;

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (!isValid) {
            this.setState({imageLogoPreviewUrl: null, imageLogoErrorText: 'File too large. 2Mb max file size.'});
        }
        const typeValid = verifyFileType(file.type, 'image');
        isValid = typeValid && isValid;
        if (!typeValid) {
            this.setState({
                imageLogoPreviewUrl: null,
                imageLogoErrorText:
                    'File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.',
            });
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    newLogoFile: file,
                    imageLogoPreviewUrl: reader.result,
                    imageLogoErrorText: '',
                });
            }
        };

        reader.readAsDataURL(file);
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    updateLogo = () => {
        const {eventId, partner} = this.props;
        const {navigationElement} = this.state;

        const formData = new FormData();
        formData.append('image', this.state.newLogoFile, this.state.newLogoFile.name, this.state.newLogoFile.type);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios({method: 'post', url: `/event/v2/${eventId}/branding/edit-partner/${partner._id}`, data: formData, config})
            .then((response) => {
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.setState({
                    newLogoFile: null,
                    imageLogoPreviewUrl: null,
                    openConfirmUnsavedChanges: false,
                });
                this.props.openSuccessSnackbar();

                if (navigationElement) {
                    fireClickEvent(navigationElement);
                }
            })
            .catch((error) => {
                this.props.openErrorSnackbar();
            });
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.setState({
            newLogoFile: null,
            imageLogoPreviewUrl: null,
            imageLogoErrorText: '',
        });

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    removeLogo = () => {
        const {eventId, partner} = this.props;
        axios({method: 'delete', url: `/event/v2/${eventId}/branding/delete-partner-image/${partner._id}`})
            .then((response) => {
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.setState({
                    newLogoFile: null,
                    imageLogoPreviewUrl: null,
                });
                this.props.openSuccessSnackbar();
            })
            .catch((error) => {
                this.props.openErrorSnackbar();
            });
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (
                this.state.imageLogoPreviewUrl &&
                !this.state.imageLogoErrorText.length > 0 &&
                this.props.partner.image !== null
            ) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
    };

    render() {
        let {newLogoFile, imageLogoPreviewUrl, imageLogoErrorText, openConfirmUnsavedChanges} = this.state;
        const {partner, filesUrl} = this.props;
        return (
            <>
                <h4>Partner's Logo</h4>
                <div onDragStart={preventDefaultDrag} ref={this.wrapperRef} className="options-container">
                    <div onDragStart={preventDefaultDrag} className="single-option-container">
                        <div onDragStart={preventDefaultDrag} className="actions-container">
                            {newLogoFile && (
                                <Button
                                    type="button"
                                    onClick={this.updateLogo}
                                    disabled={!imageLogoPreviewUrl || imageLogoErrorText.length > 0}
                                    startIcon={<SaveOutlinedIcon />}
                                    variant="contained"
                                    color={'secondary'}
                                    disableElevation
                                >
                                    Save image
                                </Button>
                            )}
                            {imageLogoPreviewUrl ? (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    component="label"
                                    startIcon={<EditOutlinedIcon />}
                                >
                                    Edit
                                    <input
                                        type="file"
                                        onChange={this.handleLogoChange}
                                        className="upload-image-button d-none"
                                        hidden
                                    />
                                </Button>
                            ) : (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    component="label"
                                    startIcon={<CloudUploadIcon />}
                                >
                                    Upload image
                                    <input
                                        type="file"
                                        onChange={this.handleLogoChange}
                                        className="upload-image-button d-none"
                                        hidden
                                    />
                                </Button>
                            )}
                            {partner?.image && !newLogoFile && (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    onClick={this.removeLogo}
                                    disabled={imageLogoPreviewUrl}
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                >
                                    Remove
                                </Button>
                            )}
                        </div>
                        <div onDragStart={preventDefaultDrag} className="manage-partner-logo">
                            <div onDragStart={preventDefaultDrag} className="logo-container">
                                <div onDragStart={preventDefaultDrag} className="image-wrapper">
                                    {partner?.image ? (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="current-logo-container cover-img-container"
                                        >
                                            {imageLogoPreviewUrl ? (
                                                <img
                                                    draggable="false"
                                                    onDragStart={preventDefaultDrag}
                                                    src={imageLogoPreviewUrl}
                                                    alt="preview"
                                                />
                                            ) : (
                                                <img
                                                    draggable="false"
                                                    onDragStart={preventDefaultDrag}
                                                    src={filesUrl + partner?.image}
                                                    alt="logo"
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="current-banner-container cover-img-container"
                                        >
                                            {imageLogoPreviewUrl ? (
                                                <img
                                                    draggable="false"
                                                    onDragStart={preventDefaultDrag}
                                                    src={imageLogoPreviewUrl}
                                                    alt="preview"
                                                />
                                            ) : (
                                                <label htmlFor={'logoUpload' + partner?._id} className="upload-label">
                                                    <PublishIcon />
                                                    <span>Click here to upload</span>
                                                </label>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <Grid item xs={12}>
                                    <input
                                        id={'logoUpload' + partner?._id}
                                        type="file"
                                        onChange={this.handleLogoChange}
                                        className="upload-image-button d-none"
                                    />
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="input-description">
                        <p className="image-explain">
                            Recommended image ratio 1.3:1 (landscape-oriented, e.g. 450 x 345 pixels)
                        </p>
                        <p onDragStart={preventDefaultDrag} className="image-status-container">
                            <span>
                                {newLogoFile || (imageLogoErrorText.length > 0 && partner?.image) ? (
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="form-upload-picture"
                                    >
                                        Uploaded:{' '}
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="secondary-color"
                                        >
                                            {imageLogoErrorText.length > 0 ? (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="error-light"
                                                >
                                                    error
                                                </span>
                                            ) : (
                                                newLogoFile.name
                                            )}
                                        </span>
                                    </span>
                                ) : null}
                                {!newLogoFile && !partner?.image ? (
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="form-upload-picture"
                                    >
                                        Uploaded:{' '}
                                        {imageLogoErrorText.length > 0 ? (
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="error-light"
                                            >
                                                error
                                            </span>
                                        ) : (
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="grey-color"
                                            >
                                                no image
                                            </span>
                                        )}
                                    </span>
                                ) : null}
                                <span onDragStart={preventDefaultDrag} draggable="false" className="error-message">
                                    {imageLogoErrorText}
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.updateLogo}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SinglePartnerLogo);
