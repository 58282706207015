import {ReactComponent as EmptyAgenda} from '../Agenda/helpers/svgs/empty.svg';

const EmptyList = ({message}) => {
    return (
        <div className="empty-list-my-agenda">
            <EmptyAgenda />
            <h3>{message}</h3>
        </div>
    );
};

EmptyList.defaultProps = {
    message: 'Nothing on your agenda yet',
};
export default EmptyList;
