import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import * as actions from '../store/actions';
import axios from '../store/axios-instance';
import ColoredScrollbars from '../SmallLayoutComponents/ColoredScrollbars';
import Spinner from '../SmallLayoutComponents/Spinner';
import VideoMeetingBanner from '../Components/EventMenuDropdowns/Chats/VideoMeetingBanner';
import Button from '@material-ui/core/Button';
import colors from '../CSS/_variables.module.scss';
import {ReactComponent as MeetingsInfoIcon} from '../Images/svg/counter.svg';
import {preventDefaultDrag} from '../Utils/utils';
import {isIOS} from 'react-device-detect';

class EditGroupChat extends React.Component {
    state = {
        search: '',
        chatName: '',
        chat: null,
        loading: false,
        users: [],
        filterUsers: [],
        chatMembers: [],
        usersToAdd: [],
        videoBannerShow: false,
        userToAddId: null,
    };

    componentDidMount() {
        const {eventUsers, userContacts} = this.props;
        // we also set the true parameter to only set up at load the chatName
        this.getChatData(true);
        let users = [...eventUsers.allEventUsers, ...this.mapContactsAsEventUsers(userContacts)];
        users = this.removeUserDuplicates(users);
        this.setState({users: users});
    }

    componentWillUnmount() {
        this.props.onGetGroupChats();
    }

    // because for event users the userId is stored in [user].user
    // and for contacts is stored in [user].user._id
    mapContactsAsEventUsers = (contacts) => {
        return contacts.map((user) => {
            return {...user.user, user: user.user?._id};
        });
    };

    removeUserDuplicates = (users) => {
        return users.reduce((acc, current) => {
            const x = acc.find((item) => item.user === current.user);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
    };

    handleRemoveUserFromGroupChat = (userId) =>
        this.setState({usersToAdd: this.state.usersToAdd.filter((userToAdd) => userToAdd.user !== userId)});

    _showInfoBanner = () =>
        this.setState({
            videoBannerShow: true,
        });

    _closeVideoBanner = () =>
        this.setState({
            videoBannerShow: false,
        });

    handleInputFocus = () => {
        const eventPage = document.getElementsByClassName('event-page');
        if (eventPage && !isIOS) {
            eventPage[0].classList.add('hide');
        }
    };

    handleInputBlur = () => {
        const eventPage = document.getElementsByClassName('event-page hide');
        if (eventPage.length !== 0 && !isIOS) {
            eventPage[0].classList.remove('hide');
        }
    };

    handleKeyDown = (e) => {
        const {search} = this.state;
        if (e.key === 'Enter' && search.trim()) {
            e.target.blur();
        }
    };

    handleAddUserToGroupChat = (newUser) => {
        const {usersToAdd} = this.state;
        let filteredPerson = usersToAdd.filter((user) => user._id !== newUser._id);
        const updatedUsers = [...filteredPerson, newUser];
        this.setState({usersToAdd: updatedUsers});
    };

    getChatData = (setInitialChatName) => {
        this.setState({loading: true});
        axios({method: 'get', url: '/group-chat/' + this.props.activeChatRoomId}).then((response) => {
            let data = response.data.groupChat;
            if (setInitialChatName) {
                this.setState({chatName: data.name});
            }

            let chatMembers = data.users.map((el) => el._id);
            this.setState({chat: data, loading: false, chatMembers: chatMembers});
        });
    };

    onSearchChange = (e) => {
        this.setState(
            {
                search: e.target.value,
            },
            () => {
                if (this.state.search.trim().length >= 1) this.filterUsers();
                if (this.state.search.trim().length < 1) this.setState({filterUsers: []});
            }
        );
    };

    filterUsers = () => {
        let filterUsers = this.state.users.filter((obj) => {
            let fullName = `${obj.first} ${obj.last}`;
            if (fullName.toLowerCase().includes(this.state.search.toLowerCase())) {
                return obj;
            }
            return false;
        });

        // hide chat members from search results
        filterUsers = filterUsers.filter((obj) => {
            if (!this.state.chatMembers.includes(obj.user)) {
                return obj;
            }
            return false;
        });

        this.setState({filterUsers: filterUsers});
    };

    handleUpdateChat = async () => {
        // add to group chat
        this.setState({loading: true});

        const addUsersPromises = this.state.usersToAdd.map((userToAdd) =>
            axios({
                method: 'post',
                url: '/group-chat/' + this.props.activeChatRoomId + '/add-user',
                data: {targetUserId: userToAdd.user},
            })
        );

        await Promise.all(addUsersPromises).catch((err) => {});

        // update chat name
        if (this.state.chatName !== this.state.chat.name) {
            await axios({
                method: 'put',
                url: '/group-chat/' + this.props.activeChatRoomId,
                data: {name: this.state.chatName},
            }).catch((error) => {});
        }

        this.setState(
            {
                search: '',
                chatName: '',
                chat: null,
                loading: false,
                users: [],
                filterUsers: [],
                chatMembers: [],
                usersToAdd: [],
            },
            this.props.closeGroupChatDialog
        );
    };

    render() {
        const {usersToAdd, chat, chatName, search, filterUsers, loading, videoBannerShow} = this.state;
        const {translation, defaultTranslation} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="chat-page edit">
                {loading && <Spinner />}
                <div>
                    <VideoMeetingBanner
                        videoBannerShow={videoBannerShow}
                        translation={translation}
                        defaultTranslation={defaultTranslation}
                        closeBanner={this._closeVideoBanner}
                    />
                    <div onDragStart={preventDefaultDrag} className="new-chat-wrapper">
                        {chat ? (
                            <div>
                                <div onDragStart={preventDefaultDrag} className="input-container">
                                    <div onDragStart={preventDefaultDrag} className="chat-name-container d-flex">
                                        <TextField
                                            id="chatName"
                                            name="chatName"
                                            label={translation?.chatsDropdown.newChatNameInput}
                                            value={chatName}
                                            onChange={(e) => this.setState({chatName: e.target.value})}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            onFocus={this.handleInputFocus}
                                            onBlur={this.handleInputBlur}
                                        />
                                    </div>
                                    <p>{translation?.chatsDropdown.editChatAddMembers}</p>
                                    <TextField
                                        id="search"
                                        label={translation?.chatsDropdown.newChatSearchInput}
                                        type="search"
                                        margin="normal"
                                        className="m-bottom"
                                        value={search}
                                        onChange={this.onSearchChange}
                                        onFocus={this.handleInputFocus}
                                        onBlur={this.handleInputBlur}
                                        onKeyDown={this.handleKeyDown}
                                        variant="outlined"
                                    />
                                </div>
                                <div onDragStart={preventDefaultDrag} className="members-chat-wrapper">
                                    <ColoredScrollbars>
                                        <ul className="members-list-event">
                                            {filterUsers.map((participant, participantIndex) => {
                                                let participantUser = participant;
                                                if (typeof participant.user === 'object') {
                                                    participantUser = participant.user;
                                                }
                                                return (
                                                    <React.Fragment key={participantIndex}>
                                                        <li>
                                                            <div
                                                                className="user-avatar"
                                                                style={{
                                                                    backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${participantUser.avatarSmall}')`,
                                                                }}
                                                            />
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="chat-member-details"
                                                            >
                                                                <p>
                                                                    {participantUser.first} {participantUser.last}
                                                                </p>
                                                                {participantUser.company !== '' ? (
                                                                    <span>{participantUser.company}</span>
                                                                ) : null}
                                                            </div>
                                                            {usersToAdd.some((user) => user._id === participant._id) ? (
                                                                <button
                                                                    className="remove-member"
                                                                    onClick={() =>
                                                                        this.handleRemoveUserFromGroupChat(
                                                                            participant.user
                                                                        )
                                                                    }
                                                                >
                                                                    {translation?.generalText.remove}
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="add-member"
                                                                    onClick={() =>
                                                                        this.handleAddUserToGroupChat(participant)
                                                                    }
                                                                >
                                                                    {translation?.generalText.add}
                                                                </button>
                                                            )}
                                                        </li>
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="user-wrapper-separator"
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}

                                            {search.length > 2 && !filterUsers.length && (
                                                <li>{translation?.chatsDropdown.newChatNoUsersMessage}</li>
                                            )}
                                        </ul>
                                    </ColoredScrollbars>
                                </div>
                            </div>
                        ) : null}
                        {chat ? (
                            <div
                                onDragStart={preventDefaultDrag}
                                className="new-chat-buttons-container edit-group-chat"
                            >
                                <div>
                                    <MeetingsInfoIcon
                                        fill={colors.primary}
                                        className="info-button"
                                        onClick={this._showInfoBanner}
                                    />
                                    <Button type="button" onClick={this.props.closeGroupChatDialog}>
                                        {translation?.chatsDropdown.newChatCancelButton}
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button
                                        type="button"
                                        onClick={() => this.handleUpdateChat()}
                                        disabled={chatName === chat.name && !usersToAdd.length}
                                    >
                                        {translation?.chatsDropdown.membersUpdateButton}
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                </div>
                                <div onDragStart={preventDefaultDrag} className="video-meetings-info-wrapper">
                                    <div>
                                        <MeetingsInfoIcon fill={colors.primary} />
                                        <p>
                                            {translation?.meetings.banner.videoCallStartedInfo ||
                                                defaultTranslation?.meetings.banner.videoCallStartedInfo}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventUsers: state.eventUsers,
        userContacts: state.user.data?.contacts,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        eventId: state.event.data._id,
        eventOwner: state.event.data.owner,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetGroupChats: () => dispatch(actions.getGroupChats()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupChat);
