import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import axios from '../../../../store/axios-instance';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {preventDefaultDrag} from '../../../../Utils/utils';
import {KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';
import EventIcon from '@material-ui/icons/Event';
import './MeetingsStyles.scss';

class CreateMeeting extends Component {
    formRef = createRef();
    state = {
        partnerData: '',
        timezone: '',
        date: '',
        start: null,
        end: null,
        error: '',
        startPopover: false,
        endPopover: false,
    };

    componentDidMount() {
        this.setPartnerData();
        this.setDates();
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            this.setPartnerData();
        }
    }

    setPartnerData = () => {
        const {user, activeChatRoomId} = this.props;

        let privateChatData = user.privateChats.find((el) => el.chat._id === activeChatRoomId).chat;
        let privateChatPartnerData = '';

        if (privateChatData.user_1._id !== user._id) {
            privateChatPartnerData = privateChatData.user_1;
        } else {
            privateChatPartnerData = privateChatData.user_2;
        }

        this.setState({partnerData: privateChatPartnerData});
    };

    setDates = () => {
        const eventStartDate = new Date();

        // benji...
        let timezoneText = eventStartDate.toTimeString().slice(9).split('(')[0].trim();
        if (timezoneText.charAt(4) === '0' && timezoneText.charAt(6) === '0' && timezoneText.charAt(7) === '0') {
            timezoneText = timezoneText.slice(0, 6);
            timezoneText = timezoneText.slice(0, 4) + timezoneText.slice(5);
        } else if (timezoneText.charAt(6) === '0' && timezoneText.charAt(7) === '0') {
            timezoneText = timezoneText.slice(0, 6);
        }

        this.setState({
            date: eventStartDate.toISOString().slice(0, 10),
            timezone: timezoneText,
        });
    };

    handleChangeDate = (e) => {
        this.setState({date: e, error: ''});
    };

    handleStartTime = (data) => {
        this.setState({
            start: moment(moment(data).format('HH:mm'), 'HH:mm'),
            error: '',
        });
    };

    handleEndTime = (data) => {
        this.setState({
            end: moment(moment(data).format('HH:mm'), 'HH:mm'),
            error: '',
        });
    };

    checkHoursValidity = () => {
        const {start, end} = this.state;
        const {translation, defaultTranslation} = this.props;
        let dateStart = moment(start).format('HH:mm');
        let dateEnd = moment(end).format('HH:mm');
        let now = moment().format('HH:mm');
        if (dateStart >= dateEnd || now > dateStart) {
            this.setState({
                error: `${
                    translation?.meetings.createDialog.meetingError ||
                    defaultTranslation?.meetings.createDialog.meetingError
                }`,
            });
        } else {
            this.setState({
                error: '',
            });
        }
    };

    handleSaveMeeting = () => {
        const {start, end, date} = this.state;
        const {translation, defaultTranslation} = this.props;

        let dateTime = moment(date).format('YYYY-MM-DD');
        let dateStart = moment(start).format('HH:mm');
        let dateEnd = moment(end).format('HH:mm');

        let timeAndDateStart = moment(dateTime + ' ' + dateStart).toDate();
        let timeAndDateEnd = moment(dateTime + ' ' + dateEnd).toDate();

        let data = {
            start: timeAndDateStart.toUTCString(),
            end: timeAndDateEnd.toUTCString(),
            privateChatId: this.props.activeChatRoomId,
            partnerId: this.state.partnerData._id,
        };

        let now = new Date();
        if (timeAndDateStart.getTime() >= timeAndDateEnd.getTime() || now.getTime() > timeAndDateStart.getTime()) {
            this.setState({
                error: `${
                    translation?.meetings.createDialog.meetingError ||
                    defaultTranslation?.meetings.createDialog.meetingError
                }`,
            });
        } else {
            this.setState({error: ''});
            axios({method: 'post', url: `/users/send-meeting-request`, data: data})
                .then((response) => {
                    this.props.onRefreshUserUserData();
                    this.props.onClose();
                })
                .catch((error) => {});
        }
    };

    render() {
        const {partnerData, error, start, end, date, timezone} = this.state;
        const {translation, defaultTranslation, isTablet, isMobile, open, onClose} = this.props;
        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                className="create-meeting-dialog preserveHeaderDropdown"
                fullScreen={isMobile || isTablet}
            >
                <div onDragStart={preventDefaultDrag} className="create-meeting">
                    <div onDragStart={preventDefaultDrag} className="create-meeting-header">
                        <p onDragStart={preventDefaultDrag} className="title meeting-create-title">
                            {translation?.meetings.createDialog.createTitle ||
                                defaultTranslation?.meetings.createDialog.createTitle}
                        </p>
                        <div onDragStart={preventDefaultDrag} className="user-container user-container-create-meeting">
                            <div onDragStart={preventDefaultDrag} className="avatar">
                                <img
                                    src={`${process.env.REACT_APP_AVATAR_FOLDER}${partnerData.avatarSmall}`}
                                    alt={`${partnerData.first} ${partnerData.last}`}
                                />
                            </div>
                            <p
                                onDragStart={preventDefaultDrag}
                                className="name"
                            >{`${partnerData.first} ${partnerData.last}`}</p>
                        </div>
                    </div>
                    <ValidatorForm className="create-meeting-form" ref="form" onSubmit={this.handleSaveMeeting}>
                        <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                            <div onDragStart={preventDefaultDrag} className="options-container">
                                <div onDragStart={preventDefaultDrag} className="single-option-container">
                                    <KeyboardDatePicker
                                        label={
                                            translation?.meetings.createDialog.inputDate ||
                                            defaultTranslation?.meetings.createDialog.inputDate
                                        }
                                        disableToolbar
                                        fullWidth={true}
                                        variant="inline" // inline/dialog/static
                                        inputVariant="outlined"
                                        showTodayButton
                                        format="DD/MM/YYYY"
                                        value={date}
                                        onChange={this.handleChangeDate}
                                        className={'mt-10'}
                                        color={'secondary'}
                                        DialogProps={{
                                            PaperProps: {classes: {root: 'secondary'}},
                                        }}
                                        KeyboardButtonProps={{
                                            edge: 'end',
                                            tabIndex: '-1',
                                        }}
                                        keyboardIcon={<EventIcon classes={{root: 'dark-color'}} />}
                                        errorMessages={[`${translation?.errors.required}`]}
                                        PopoverProps={{
                                            classes: {root: 'preserveHeaderDropdown'},
                                        }}
                                    />
                                </div>
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className={`hours-container ${isMobile || isTablet ? 'mobile' : ''}`}
                                >
                                    <div onDragStart={preventDefaultDrag} className="single-option-container">
                                        <KeyboardTimePicker
                                            className="setting-input input-start"
                                            margin="normal"
                                            inputVariant="outlined"
                                            format="HH:mm"
                                            placeholder="--:--"
                                            label={
                                                translation?.meetings.createDialog.inputStart ||
                                                defaultTranslation?.meetings.createDialog.inputStart
                                            }
                                            value={start}
                                            onChange={this.handleStartTime}
                                            invalidDateMessage={
                                                translation?.meetings.createDialog.invalidTimeFormat ||
                                                defaultTranslation?.meetings.createDialog.invalidTimeFormat
                                            }
                                            DialogProps={{
                                                PaperProps: {classes: {root: 'reverse-rtl preserveHeaderDropdown'}},
                                                TransitionProps: {
                                                    onEntered: () => this.setState({startPopover: true}),
                                                    onExited: () => this.setState({startPopover: false}),
                                                },
                                            }}
                                            InputProps={{
                                                classes: {input: 'font-family'},
                                            }}
                                            KeyboardButtonProps={{
                                                edge: 'end',
                                                tabIndex: '-1',
                                            }}
                                            fullWidth
                                            keyboardIcon={<AccessTimeIcon classes={{root: 'dark-color'}} />}
                                            cancelLabel={
                                                translation?.generalText.cancel ||
                                                defaultTranslation?.generalText.cancel
                                            }
                                            okLabel={translation?.generalText.ok || defaultTranslation?.generalText.ok}
                                        />
                                    </div>
                                    <div onDragStart={preventDefaultDrag} className="single-option-container">
                                        <KeyboardTimePicker
                                            className="setting-input input-end"
                                            margin="normal"
                                            inputVariant="outlined"
                                            label={
                                                translation?.meetings.createDialog.inputEnd ||
                                                defaultTranslation?.meetings.createDialog.inputEnd
                                            }
                                            format="HH:mm"
                                            placeholder="--:--"
                                            value={end}
                                            onChange={this.handleEndTime}
                                            invalidDateMessage={
                                                translation?.meetings.createDialog.invalidTimeFormat ||
                                                defaultTranslation?.meetings.createDialog.invalidTimeFormat
                                            }
                                            KeyboardButtonProps={{
                                                edge: 'end',
                                                tabIndex: '-1',
                                            }}
                                            InputProps={{
                                                classes: {input: 'font-family'},
                                            }}
                                            DialogProps={{
                                                PaperProps: {classes: {root: 'reverse-rtl preserveHeaderDropdown'}},
                                                TransitionProps: {
                                                    onEntered: () => this.setState({startPopover: true}),
                                                    onExited: () => this.setState({startPopover: false}),
                                                },
                                            }}
                                            fullWidth
                                            keyboardIcon={<AccessTimeIcon classes={{root: 'dark-color'}} />}
                                            cancelLabel={
                                                translation?.generalText.cancel ||
                                                defaultTranslation?.generalText.cancel
                                            }
                                            okLabel={translation?.generalText.ok || defaultTranslation?.generalText.ok}
                                        />
                                    </div>
                                </div>
                            </div>
                            <p onDragStart={preventDefaultDrag} className="timezone">
                                {translation?.meetings.createDialog.inputTimezone ||
                                    defaultTranslation?.meetings.createDialog.inputTimezone}
                                : {timezone}
                            </p>
                        </div>
                        {error && (
                            <p onDragStart={preventDefaultDrag} className="error-message">
                                {error}
                            </p>
                        )}
                        <p onDragStart={preventDefaultDrag} className="note">
                            {translation?.meetings.createDialog.inputNote ||
                                defaultTranslation?.meetings.createDialog.inputNote}
                        </p>
                        <div onDragStart={preventDefaultDrag} className="action-container">
                            <Button type="button" onClick={onClose}>
                                {translation?.generalText.cancel || defaultTranslation?.generalText.cancel}
                            </Button>
                            <Button
                                type="submit"
                                disabled={
                                    error.length > 0 ||
                                    !(this.state.start !== null && moment(this.state.start).isValid()) ||
                                    !(this.state.end !== null && moment(this.state.end).isValid())
                                }
                            >
                                {translation?.generalText.send || defaultTranslation?.generalText.send}
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        event: state.event.data,
        isTablet: state.layout.isTablet,
        isMobile: state.layout.isMobile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMeeting);
