import MeetingRequests from './MeetingRequests';
import SingleMeetingItemAgenda from './SingleMeetingItemAgenda';
import SingleSessionItemAgenda from './SingleSessionItemAgenda';
import EmptyList from './EmptyList';
import React, {useEffect, useMemo, useState} from 'react';
import {
    d,
    dayNames,
    getAllDaysTranslated,
    getAllMonthsTranslated,
    getTimeslotDescriptionFormatted,
    getTimeslotDuration,
    monthNames,
} from '../../../../Utils/utils';
import {acceptSingleMeeting, allMeetingsAccept, cancelSingleMeeting, getMyAgenda} from 'store/actions/myAgendaActions';
import {useDispatch, useSelector} from 'react-redux';
import DialogAddToExternalCalendar from '../DialogAddToExternalCalendar';
import LoadingTimeslots from '../LoadingTimeslots';
import * as actions from 'store/actions';
import moment from 'moment';
import {isMobileOnly} from 'react-device-detect';
import {usePlatformTranslation} from 'services/hooks';

const MeetingAndSessionList = ({
    agenda,
    deleteFromMyAgenda,
    isComponentActive,
    loading,
    pastSlots,
    activeTimeslot,
    handleSetActiveTimeslot,
    setDateForMobile,
}) => {
    const dispatch = useDispatch();

    //LOCAL
    const [allAgenda, setAllAgenda] = useState([]);
    const [pendingReqs, setPendingReqs] = useState([]);
    const [calendarTimeslot, setCalendarTimeslot] = useState(null);
    const [externalCalendarDialog, setExternalCalendarDialog] = useState(false);
    const [calendarData, setCalendarData] = useState(null);
    const [dateIds, setDateIds] = useState([]);
    const [activeId, setActiveId] = useState();

    //REDUX
    const user = useSelector((state) => state?.user?.data);
    const event = useSelector((state) => state?.event?.data);
    const languages = useSelector((state) => state?.languages);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    /*translations*/
    const platformLanguage = useSelector((state) => state.languages?.platformLanguage);
    const timeslotTitle = usePlatformTranslation((state) => state?.programDropdown?.timeslotTitle);
    const addTimeslotToYourCalendar = usePlatformTranslation(
        (state) => state?.programDropdown?.addTimeslotToYourCalendar
    );
    const slotScheduled = usePlatformTranslation((state) => state?.programDropdown?.slotScheduled);
    const tipTimeslotDescription = usePlatformTranslation((state) => state?.programDropdown?.tipTimeslotDescription);
    /* end translations*/

    //REQUESTED MEETING
    //d is a function if its required value is empty it returns you a new Date()
    // if it's filled. It returns a formated date
    const userMeetingListRequested = useMemo(() => {
        let meetingsUserReq = [
            ...agenda.filter(
                (item) => item.type === 'meeting' && item.status === 'pending' && item?.owner?.user?._id !== user?._id
            ),
        ];

        let sorted = meetingsUserReq.sort(function (a, b) {
            return d(a.start).getTime() - d(b.start).getTime();
        });
        sorted = sorted.filter((el) => d(el.end).getTime() >= d().getTime());

        setPendingReqs(sorted);
    }, [isComponentActive, agenda]);

    //calculation of time zone
    const getTimezoneDifference = () => {
        if (user?.timezoneValue !== '') {
            return +user?.timezoneValue;
        }
        return event?.timezoneValue;
    };

    //It gives you a formatted a time. Ex: Wednesday, 13 April 2022
    const getFormattedDateByTimezoneDifference = (date) => {
        let monthsInAllLangs = getAllMonthsTranslated(languages);
        let daysInAllLangs = getAllDaysTranslated(languages);
        const timezoneDifference = getTimezoneDifference();
        const timestampWithoutLocalTimezone = moment.utc(date).format('l LT');
        const calculatedTimestampBasedOnTimezoneDifference = moment(timestampWithoutLocalTimezone).add(
            timezoneDifference,
            'hours'
        );
        let day = calculatedTimestampBasedOnTimezoneDifference.format('DD');
        let dayOrder = dayNames.findIndex((el) => el === calculatedTimestampBasedOnTimezoneDifference.format('dddd'));
        let month = monthNames.findIndex((el) => el === calculatedTimestampBasedOnTimezoneDifference.format('MMMM'));
        let newMonth = monthsInAllLangs[month].charAt(0).toUpperCase() + monthsInAllLangs[month].slice(1);
        let year = calculatedTimestampBasedOnTimezoneDifference.format('YYYY');

        if (isMobileOnly) {
            return `${daysInAllLangs[dayOrder]}, ${day} ${newMonth}, ${year} `;
        } else {
            return `${day} ${newMonth}, ${year} `;
        }
    };
    //FULL AGENDA
    //It is an expensive calculation. Because of this we used useMemo hook
    //It won't render when the page loads. Only it will be rendered and changed if its depencies changes.
    //useMemo produces a value - useCallback reproduce a function.
    const myAgendaAll = useMemo(async () => {
        let fullAgenda = [...agenda];
        if (!pastSlots) {
            fullAgenda = fullAgenda?.filter(
                (item) => (d(item?.endTimestamp).getTime() || d(item?.end).getTime()) >= d().getTime()
            );
        }
        let newList = [];
        fullAgenda.forEach((item) => {
            let end = d(item?.type === 'meeting' ? item?.end : item?.endTimestamp).getTime();
            let now = d().getTime();
            const datePropertyBasedOnItemType = d(item?.type === 'meeting' ? item?.start : item?.startTimestamp);
            const itemDate = getFormattedDateByTimezoneDifference(datePropertyBasedOnItemType);

            let isObjExist = newList.find((el) => el.date === itemDate);

            if (isObjExist) {
                if (item?.type === 'meeting') {
                    if (
                        (item?.status === 'pending' && item?.owner?.user?._id === user?._id) ||
                        item?.status === 'accepted'
                    )
                        if (pastSlots) {
                            isObjExist.items = [...isObjExist.items, item];
                        } else {
                            if (now <= end) isObjExist.items = [...isObjExist.items, item];
                        }
                } else {
                    isObjExist.items = [...isObjExist.items, item];
                }
            } else {
                if (item?.type === 'meeting') {
                    if (
                        (item?.status === 'pending' && item?.owner?.user?._id === user?._id) ||
                        item?.status === 'accepted'
                    )
                        if (pastSlots) {
                            newList.push({date: itemDate, itemDateAsNumber: d(item.start).getTime(), items: [item]});
                        } else {
                            if (now <= end)
                                newList.push({
                                    date: itemDate,
                                    itemDateAsNumber: d(item.start).getTime(),
                                    items: [item],
                                });
                        }
                } else {
                    newList.push({date: itemDate, itemDateAsNumber: d(item.startTimestamp).getTime(), items: [item]});
                }
            }
        });
        newList = newList.sort(function (a, b) {
            return a.itemDateAsNumber - b.itemDateAsNumber;
        });
        setAllAgenda(newList);
        setDateIds(newList.map((day) => day.itemDateAsNumber));
    }, [isComponentActive, agenda, platformLanguage]);

    //FN : ACCEPTED ALL MEETINGS
    const acceptAllMeetings = () => () => {
        let meetingsUserReq = [...agenda.filter((item) => item.type === 'meeting')];
        let meetingIds = [];
        let reqList = meetingsUserReq.filter((el) => el.status === 'pending');

        reqList.forEach((item) => {
            meetingIds.push(item._id);
        });

        allMeetingsAccept(meetingIds)(dispatch).then(() =>
            getMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch)
        );
    };
    //CALENDAR FUNCTIONALITY
    const createTimeslotCalendarData = () => {
        const timeslotCalendarData = {
            title: `${calendarTimeslot.title}`,
            description: getTimeslotDescriptionFormatted(calendarTimeslot, translation),
            location: `${window.location.origin}/event/${event.slug}/`,
            start: calendarTimeslot.startTimestamp,
            end: calendarTimeslot.endTimestamp,
        };
        return timeslotCalendarData;
    };

    useEffect(() => {
        if (calendarTimeslot) {
            const calendarData = createTimeslotCalendarData();
            setCalendarData(calendarData);
            setExternalCalendarDialog(true);
        }
    }, [calendarTimeslot]);

    const hideAddToExternalCalendarDialog = () => {
        setExternalCalendarDialog(false);
    };

    const handleSetCalendarTimeslot = (timeslot) => () => {
        setCalendarTimeslot(timeslot);
        setExternalCalendarDialog(true);
    };

    //END - CALENDAR FUNCTIONALITY

    //FN : CANCEL MEETING
    const cancelMeetingRequest = (singleMeeting) => () => {
        let meetingData = {
            meetingId: singleMeeting._id,
            firstUserId: singleMeeting.owner.user._id,
            secondUserId: singleMeeting.partner.user._id,
        };
        cancelSingleMeeting(meetingData)(dispatch).then(() =>
            getMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch)
        );
    };

    //FN : ACCEPT MEETING
    const acceptMeetingRequest = (singleMeeting) => () => {
        let meetingData = {
            meetingId: singleMeeting._id,
            firstUserId: singleMeeting.partner?.user?._id,
            secondUserId: singleMeeting.owner?.user?._id,
        };
        acceptSingleMeeting(meetingData)(dispatch).then(() =>
            getMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch)
        );
    };

    //OPEN PRIVATE CHAT FUNCTION
    const openPrivateChatMyAgenda = (privateChatId) => () => {
        dispatch(actions.openPrivateChat(privateChatId));
    };

    //This is a 2 factor validation
    /*
     * 1- Is agenda length is 0 - show no activity
     * 2- Is agenda length is bigger than 0
     *     if you remove past sessions and meeting, is it still bigger than 0
     *       yes-> do not show EmptyList component
     *       no-> It is 0 after removing the past, then there is no upcoming activity
     * */
    const isAgendaHasElement = () => {
        let agendaCopy = [...agenda];
        let sessionsCopy = agendaCopy?.filter((item) => item.type === 'session');
        let meetingsCopy = agendaCopy?.filter(
            (item) =>
                (item.type === 'meeting' && item?.status !== 'pending') ||
                (item.type === 'meeting' && item?.status === 'pending' && !item?.isPast)
        );

        meetingsCopy = meetingsCopy.filter((item) => !item?.isPast);
        sessionsCopy = sessionsCopy.filter((item) => !item?.isPast);

        if (!pastSlots) {
            return meetingsCopy.length <= 0 && sessionsCopy.length <= 0;
        }

        return (
            agendaCopy.filter((item) => item?.status !== 'pending' || (item?.status === 'pending' && !item?.isPast)) <=
            0
        );
    };

    /*Helper function of isAgendaHasElement
     * It changes the EmptyList component's message.
     * */
    const noUpcoming = () => {
        let agendaCopy = [...agenda].filter((item) => item?.status !== 'pending');
        return agendaCopy.some((item) => item?.isPast);
    };

    /*For the mobile headline date.
     * Every time when you scroll, you should see the first date which is in viewport of the user.
     * */
    const isInViewport = (el) => {
        if (el !== null) {
            let rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    };
    const elementInViewport = () => {
        let newIds = dateIds.filter((id) => isInViewport(document.getElementById(id)));
        setActiveId(newIds[0]);
    };

    let mainContainer = document.querySelector('.items-main-container');
    if (mainContainer !== null && isMobileOnly) {
        mainContainer.addEventListener(
            'scroll',
            () => {
                elementInViewport();
            },
            {
                passive: true,
            }
        );
    }
    const sortedDays = (day) => {
        return day.items.sort(function (a, b) {
            return d(a.startTimestamp).getTime() - d(b.startTimestamp).getTime();
        });
    };
    /*END of the auto change headline mobile*/
    return (
        <>
            {isAgendaHasElement() ? (
                <EmptyList
                    message={`${
                        noUpcoming()
                            ? translation.agendaTranslations.noUpcomingAgenda ||
                              defaultTranslation.agendaTranslations.noUpcomingAgenda
                            : translation.agendaTranslations.noAgendaItem ||
                              defaultTranslation.agendaTranslations.noAgendaItem
                    }`}
                />
            ) : loading ? (
                <LoadingTimeslots />
            ) : (
                <>
                    {pendingReqs.length > 0 && (
                        <MeetingRequests
                            userMeetingListRequested={userMeetingListRequested}
                            pendingReqs={pendingReqs}
                            acceptAllMeetings={acceptAllMeetings}
                            cancelMeetingRequest={cancelMeetingRequest}
                            acceptMeetingRequest={acceptMeetingRequest}
                        />
                    )}
                    <div className="all-sessions-and-meetings">
                        {allAgenda?.map((day, index) => {
                            /*VERY IMPORTANT!
                             * Each day is an object!
                             * With {date, itemDateAsNumber and items}.
                             * First we map the day.
                             * And inside of day there is an items array.
                             * For each day, we render the items. Which are mixed of sessions and meetings.
                             * EVERYTHING IS SORTED.
                             * */
                            sortedDays(day);

                            return (
                                <div key={day?.itemDateAsNumber} className="related-date-items">
                                    <h3 id={`${day?.itemDateAsNumber}`}>
                                        {day?.date[0]?.toUpperCase() + day?.date?.slice(1)}
                                    </h3>
                                    {index === 0 &&
                                        setDateForMobile(
                                            allAgenda?.find((item) => item?.itemDateAsNumber === activeId)?.date ||
                                                day?.date
                                        )}
                                    <div className="item-list">
                                        {sortedDays(day)?.map((singeAgendaItem) => {
                                            if (singeAgendaItem?.type === 'meeting') {
                                                return (
                                                    <SingleMeetingItemAgenda
                                                        openPrivateChatMyAgenda={openPrivateChatMyAgenda(
                                                            singeAgendaItem?.privateChat
                                                        )}
                                                        singleMeeting={singeAgendaItem}
                                                        key={singeAgendaItem._id}
                                                        date={day.date}
                                                        cancelMeetingRequest={cancelMeetingRequest}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <SingleSessionItemAgenda
                                                        activeTimeslot={activeTimeslot}
                                                        deleteFromMyAgenda={deleteFromMyAgenda}
                                                        singleSession={singeAgendaItem}
                                                        key={singeAgendaItem?._id}
                                                        handleSetActiveTimeslot={handleSetActiveTimeslot}
                                                        handleSetCalendarTimeslot={handleSetCalendarTimeslot}
                                                    />
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {externalCalendarDialog && (
                        <DialogAddToExternalCalendar
                            open={externalCalendarDialog}
                            onClose={hideAddToExternalCalendarDialog}
                            eventCalendarData={calendarData}
                            title={addTimeslotToYourCalendar}
                            timeslotTitle={timeslotTitle}
                            timeslotDescripion={calendarTimeslot.title}
                            timeslotSpeakers={calendarTimeslot.speakers}
                            durationTitle={slotScheduled}
                            duration={getTimeslotDuration(calendarTimeslot, languages, event, user)}
                            tip={tipTimeslotDescription}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default MeetingAndSessionList;
