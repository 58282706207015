import React from 'react';
import {connect} from 'react-redux';
import {connectToEventShowFloor, disconnectFromEventShowFloor, eventShowFloorUpdated} from '../../Api/socketApi';
import NotificationPresentationStarts from '../../Components/NotificationPresentationStarts';
import '../../CSS/boothManagement.scss';
import '../../CSS/boothParticipantView.scss';
import DetectOutsideClick from '../../HOC/DetectOutsideClick';
import Wall from '../../HOC/Wall/Wall';
import ShowfloorNoExhibitors from '../../SmallLayoutComponents/ShowfloorNoExhibitors';
import ShowfloorNoScholars from '../../SmallLayoutComponents/ShowfloorNoScholars';
import Spinner from '../../SmallLayoutComponents/Spinner';
import * as actions from '../../store/actions/index';
import axios from '../../store/axios-instance';
import {EXHIBITOR_TYPES} from '../../Utils/constants/shared';
import {preventDefaultDrag} from '../../Utils/utils';
import ExhibitorBooth from '../Event/ExhibitorBooth';
import VoteBanner from '../Event/ExhibitorVote/VoteBanner';
import {AccessDeniedShowfloor} from './AccessDeniedShowfloor';
import ExhibitorBoothMobile from './ExhibitorBoothMobile';
import ExhibitorsOverlay from './ExhibitorsOverlay/ExhibitorsOverlay';
import ExhibitorsOverlayMobile from './ExhibitorsOverlayMobile';
import FeaturedExhibitorsSlider from './FeaturedExhibitorsSlider/FeaturedExhibitorsSlider';
import PosterDetails from './PosterDetails/PosterDetails';

class Showfloor extends React.Component {
    state = {
        previousExhibitorLogoUrl: '',
        nextExhibitorLogoUrl: '',
        previousExhibitorCompany: '',
        nextExhibitorCompany: '',
        showExhibitorsOverlay: false,
        currentMobileTab: 'booth',
        currentSubTab: 'representatives',
        activeShowfloorData: null,
        isPOP: true,
        isVotingEnabled: false,
        votingPrivalages: false,
        votingData: null,
        votingText: null,
        singleExhibitorsVoteCount: null,
        voteLoading: false,
        exhibitorInProgress: null,
    };
    firstRender = React.createRef();

    componentDidMount() {
        const {showfloorId} = this.props.match.params;

        this.firstRender.current = true;

        this.props.onSetEventProtectedMenu();

        // make sure we close the top navigation when the user lands on this page
        this.props.closeTopNavigation();

        // set the initialActiveShowfloor
        this.setInitialShowfloorId(showfloorId).then(() => {});

        connectToEventShowFloor(this.props.activeShowfloorData?._id);
        eventShowFloorUpdated((err, data) => {
            if (err) {
                console.error(err);
            } else {
                this.setState({singleExhibitorsVoteCount: data?.exhibitorsVoteCount});
                this.setState({isVotingEnabled: data?.votingEnabled});
                this.setState({votingPrivalages: data?.displayVotingNumbersForParticipants});
                this.setState({votingText: data?.votingText});
            }
        });

        if (this.firstRender.current) {
            this.setVotingData();
        }
    }

    componentDidUpdate(prevProps) {
        const {eventRoles} = this.props;

        if (prevProps.activeShowfloorId !== this.props.activeShowfloorId && this.props.activeShowfloorId !== null) {
            disconnectFromEventShowFloor(prevProps.activeShowfloorId);
            connectToEventShowFloor(this.props.activeShowfloorId);

            this.props.onGetShowfloorExhibitors(this.props.eventId, this.props.activeShowfloorId);
            const activeShowfloorData = this.props.event.showfloors.find(
                (showfloor) => showfloor._id === this.props.activeShowfloorId
            );
            // in active activeShowfloorData we will have the name, image, type and Id of the current showfloor
            this.setState({activeShowfloorData: activeShowfloorData});
            !this.firstRender.current && this.setVotingData();
        }

        // whenever the activeExhibitorId changes we get the new exhibitor data
        if (prevProps.activeExhibitorId !== this.props.activeExhibitorId) {
            this.props.onGetExhibitor(this.props.activeExhibitorId);
            this.setActiveExhibitorSiblingLogos();
        }

        // after we get the currentExhibitors of the current Showfloor
        // this will also be triggered by the socket notification "reloadEventData"
        // because in reloadEventData function we also call this.props.onGetExhibitors(eventId);
        if (prevProps.loadingExhibitors === true && this.props.loadingExhibitors === false) {
            // we got the event showfloor exhibitors

            // always set a random activeExhibitorId or load the one from the localStorage if the conditions are met
            this.setExhibitorId();

            if (eventRoles.isParticipant && eventRoles.participantId) {
                this.checkParticipantInfoScreen();
            }

            if (eventRoles.isExhibitor && eventRoles.exhibitorId && this.props.exhibitors) {
                this.checkExhibitorInfoScreen();
            }
        }

        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
        this.firstRender.current = false;
    }

    componentWillUnmount() {
        // reset the active exhibitor ID when the user navigates to another page
        this.props.onSetActiveExhibitorId(null);
        this.props.onSetActiveShowfloorId(null);
        clearTimeout(this.timeoutId);
        clearTimeout(this.timeoutId2);
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    checkParticipantInfoScreen = () => {
        const {event, resourcesAccess, eventProfile} = this.props;
        if (!eventProfile?.showfloorInfoSeen) {
            this.handleSeeInfoScreen();

            let hasAccessToShowfloor = true;
            if (event.hasAccessManagement && resourcesAccess && !resourcesAccess.showfloors.length) {
                hasAccessToShowfloor = false;
            }
            if (hasAccessToShowfloor) {
                this.timeoutId = setTimeout(this.showExhibitorsOverlay, 3000);
            }
        }
    };

    checkExhibitorInfoScreen = () => {
        const {event, resourcesAccess, exhibitors} = this.props;
        const exhibitorInformation = exhibitors.find((exhibitor) => exhibitor.user._id === this.props.user._id);
        if (exhibitorInformation && !exhibitorInformation.showfloorInfoSeen) {
            const isExhibitor = true;
            this.handleSeeInfoScreen(isExhibitor);

            let hasAccessToShowfloor = true;
            if (
                (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
                resourcesAccess &&
                !resourcesAccess.showfloors.length
            ) {
                hasAccessToShowfloor = false;
            }
            if (hasAccessToShowfloor) {
                this.timeoutId2 = setTimeout(this.showExhibitorsOverlay, 3000);
            }
        }
    };

    setInitialShowfloorId = async (showfloorIdParam) => {
        const {event, showfloors} = this.props;
        const localStorageShowfloorId = showfloorIdParam ? showfloorIdParam : localStorage.getItem('showfloorId');

        // set up the default initial showfloorId
        let showfloorId = event.showfloors[0]._id;
        if (this.props.resourcesAccess) {
            //TODO: we also need to take into account the possibility that the user doesn't have access to any showfloor and redirect him to lobby
            showfloorId = this.props.resourcesAccess.showfloors[0];
        }

        // we need to take into account the user refreshing the page
        // we need to force loading the same showfloorId
        if (localStorageShowfloorId !== 'null') {
            const showfloorIdExistsInEvent = event.showfloors.some(
                (showfloor) => showfloor._id === localStorageShowfloorId
            );
            if (showfloorIdExistsInEvent) {
                if (!this.props.resourcesAccess) {
                    showfloorId = localStorageShowfloorId;
                } else {
                    let hasAccessToShowfloor = this.props.resourcesAccess.showfloors.some(
                        (showfloorId) => showfloorId === localStorageShowfloorId
                    );
                    if (hasAccessToShowfloor) {
                        showfloorId = localStorageShowfloorId;
                    }
                }
                this.setVotingData(showfloorId);
            }
            this.props.onSetActiveShowfloorId(showfloorId);
        } else {
            let min = Math.min(...showfloors.map((showfloor) => showfloor.position));
            const firstPosition = await showfloors.find((showfloor) => showfloor?.position === min);

            if (firstPosition) {
                this.props.onSetActiveShowfloorId(firstPosition._id);
                this.setVotingData(firstPosition._id);
            } else {
                this.props.onSetActiveShowfloorId(showfloorId);
                this.setVotingData(showfloorId);
            }
        }
    };

    findSmallestExhibitorId = () => {
        const {exhibitors} = this.props;

        let sortExhibitors = [...exhibitors];
        let counter = 0;

        const sorted = sortExhibitors.sort(function (a, b) {
            return a.booth?.position - b.booth?.position;
        });

        for (let i = 0; i < sorted.length; i++) {
            if (sorted[i]?.isFeatured && sorted[i].booth?.position === counter) {
                return sorted[i];
            }
            counter++;
        }

        let min = Math.min(...exhibitors.map((exhibitor) => exhibitor.booth.position));
        return exhibitors.find((exhibitor) => exhibitor?.booth?.position === min);
    };

    setExhibitorId = () => {
        const {boothId} = this.props.match.params;
        const {exhibitors, showfloors, activeShowfloorId} = this.props;
        const {isPOP} = this.state;
        const localStorageExhibitorId = localStorage.getItem('exhibitorId');
        const activeShowFloor = showfloors.find((showfloor) => showfloor._id === activeShowfloorId);

        // this means exibitor is approved and listed in the showfloor
        const exhibitor = exhibitors.find(({_id}) => _id === boothId);

        if (activeShowFloor && exhibitor) {
            this.props.onSetActiveExhibitorId(boothId);
            this.setState({isPOP: isPOP});
            return;
        }

        if (!activeShowFloor?.randomBoothSelector && exhibitors.length > 0) {
            this.props.onSetActiveExhibitorId(this.findSmallestExhibitorId()._id);
            if (localStorageExhibitorId !== 'null' && isPOP) {
                const exhibitorIdExistsInExhibitors = exhibitors?.some(
                    (exhibitor) => exhibitor._id === localStorageExhibitorId
                );
                if (exhibitorIdExistsInExhibitors) {
                    this.props.onSetActiveExhibitorId(localStorageExhibitorId);
                    this.setState({isPOP: false});
                }
            }
        }
        if (activeShowFloor?.randomBoothSelector) {
            this.setRandomActiveExhibitorId();
            if (localStorageExhibitorId !== 'null' && isPOP) {
                const exhibitorIdExistsInExhibitors = exhibitors?.some(
                    (exhibitor) => exhibitor._id === localStorageExhibitorId
                );
                if (exhibitorIdExistsInExhibitors) {
                    this.props.onSetActiveExhibitorId(localStorageExhibitorId);
                    this.setState({isPOP: false});
                }
            }
        }
    };

    setRandomActiveExhibitorId = () => {
        const {exhibitors, featuredExhibitors} = this.props;
        let sortExhibitors = [...exhibitors];
        const sorted = sortExhibitors.sort(function (a, b) {
            return a.booth?.position - b.booth?.position;
        });

        let randomExhibitorId;
        let exhibitorsLength = sorted.length;
        let featuredExhibitorsLength = this.props.featuredExhibitors.length;

        if (featuredExhibitorsLength > 0) {
            let randomFeaturedExhibitorIndex = Math.floor(Math.random() * featuredExhibitorsLength);
            randomExhibitorId = featuredExhibitors[randomFeaturedExhibitorIndex]._id;
        } else {
            if (exhibitorsLength > 0) {
                let randomExhibitorIndex = Math.floor(Math.random() * exhibitorsLength);
                randomExhibitorId = sorted[randomExhibitorIndex]._id;
            }
        }
        if (randomExhibitorId) {
            this.props.onSetActiveExhibitorId(randomExhibitorId);
        }
    };

    handleSeeInfoScreen = (isExhibitor) => {
        const {eventId, user} = this.props;
        axios({method: 'put', url: `/event/${eventId}/showfloor-info-seen`}).then(() => {
            if (isExhibitor) {
                this.props.onGetExhibitors(eventId);
            } else {
                this.props.onGetUserEventProfile(eventId, user?._id);
            }
        });
    };

    getPreviousExhibitorIndex = () => {
        let currentExhibitorIndex = this.props.exhibitors.findIndex(
            (exhibitor) => exhibitor._id === this.props.activeExhibitorId
        );
        if (currentExhibitorIndex === 0) {
            return this.props.exhibitors.length - 1;
        }
        return currentExhibitorIndex - 1;
    };

    getNextExhibitorIndex = () => {
        let currentExhibitorIndex = this.props.exhibitors.findIndex(
            (exhibitor) => exhibitor._id === this.props.activeExhibitorId
        );
        if (currentExhibitorIndex === this.props.exhibitors.length - 1) {
            return 0;
        }
        return currentExhibitorIndex + 1;
    };

    setActiveExhibitorSiblingLogos = () => {
        let previousExhibitorIndex = this.getPreviousExhibitorIndex();
        let nextExhibitorIndex = this.getNextExhibitorIndex();

        let previousExhibitor = this.props.exhibitors[previousExhibitorIndex];
        let nextExhibitor = this.props.exhibitors[nextExhibitorIndex];

        let previousExhibitorLogoUrl = previousExhibitor?.filesUrl + previousExhibitor?.booth?.logo;
        let nextExhibitorLogoUrl = nextExhibitor?.filesUrl + nextExhibitor?.booth.logo;

        this.setState({
            previousExhibitorLogoUrl: previousExhibitorLogoUrl,
            nextExhibitorLogoUrl: nextExhibitorLogoUrl,
            previousExhibitorCompany: previousExhibitor?.company,
            nextExhibitorCompany: nextExhibitor?.company,
            previousExhibitorHasLogo: previousExhibitor?.booth?.logo,
            nextExhibitorHasLogo: nextExhibitor?.booth?.logo,
        });
    };

    previousExhibitor = () => {
        let previousExhibitorIndex = this.getPreviousExhibitorIndex();
        let previousExhibitorId = this.props.exhibitors[previousExhibitorIndex]._id;
        this.props.onSetActiveExhibitorId(previousExhibitorId);
    };

    nextExhibitor = () => {
        let nextExhibitorIndex = this.getNextExhibitorIndex();
        let nextExhibitorId = this.props.exhibitors[nextExhibitorIndex]._id;
        this.props.onSetActiveExhibitorId(nextExhibitorId);
    };

    showExhibitorsOverlay = () => {
        this.setState({showExhibitorsOverlay: true});
    };
    hideExhibitorsOverlay = () => {
        this.setState({showExhibitorsOverlay: false});
    };

    handleChangeMobileTab = (tabName) => (e) => {
        this.setState({
            currentMobileTab: tabName,
        });
    };

    getCurrentExhibitor = () => {
        const {exhibitors, activeExhibitorId} = this.props;
        return exhibitors.find((exhibitor) => exhibitor._id === activeExhibitorId);
    };

    setVotingData = async (showfloorId) => {
        const {eventId, activeShowfloorId} = this.props;
        let currShowfloorId = showfloorId ? showfloorId : activeShowfloorId;
        if (this.firstRender.current) {
            currShowfloorId = localStorage.getItem('showfloorId');
        }

        const {data} = await axios.get(`/event/${eventId}/showfloor/${currShowfloorId}/voting`, {});
        this.setState({votingData: data?.data});
        this.setState({isVotingEnabled: data?.data?.votingEnabled});
        this.setState({singleExhibitorsVoteCount: data?.data?.exhibitorsVoteCount});
        this.setState({votingText: data?.data?.votingText});
        this.setState({votingPrivalages: data?.data?.displayVotingNumbersForParticipants});
    };

    handleChangeVote = (exhibitorId, isVoteBannerComponent) => async () => {
        this.setState({exhibitorInProgress: exhibitorId});
        this.setState({voteLoading: true});

        const {votingData} = this.state;
        const {eventId, activeShowfloorId, exhibitors} = this.props;

        const exhibitor = exhibitors.find((exhibitor) => exhibitor._id === exhibitorId);
        const {data} = await axios.post(
            `/event/${eventId}/showfloor/${activeShowfloorId}/exhibitor/${exhibitor?._id}/voting/${votingData?._id}/vote`
        );
        if (data?.success) {
            this.setState({votingData: data?.data});
            this.setState({voteLoading: false});
            this.setState({singleExhibitorsVoteCount: data?.data?.exhibitorsVoteCount});
        }
    };
    render() {
        const {currentMobileTab, activeShowfloorData} = this.state;
        const {event, resourcesAccess, eventRoles, isLargeScreen, translation, defaultTranslation} = this.props;

        let hasAccessToShowfloor = true;
        let hasAccessToCurrentBooth = true;

        if (
            event.hasAccessManagement &&
            !eventRoles.isOrganizer &&
            resourcesAccess &&
            !resourcesAccess?.showfloors?.length
        ) {
            hasAccessToShowfloor = false;

            // no access to showfloor
            // check access own booth
            let isBoothExhibitor = eventRoles?.isExhibitor && eventRoles.exhibitorId === this.props.activeExhibitorId;
            let isBoothRepresentative =
                eventRoles?.isExhibitorRep && eventRoles.exhibitorRepIds.includes(this.props.activeExhibitorId);

            hasAccessToCurrentBooth = isBoothExhibitor || isBoothRepresentative;
        }

        const isAccessDenied = () => {
            if (!hasAccessToShowfloor && !hasAccessToCurrentBooth)
                return (
                    <AccessDeniedShowfloor
                        title={'Exhibit Hall Information'}
                        message={translation?.closedShowfloorDialog.contentPackage}
                    />
                );
        };

        const showWall = isLargeScreen || (!isLargeScreen && currentMobileTab === 'wall');

        const exhibitorActive = this.getCurrentExhibitor();

        return (
            <>
                {isAccessDenied()}
                <div
                    className={
                        'showfloor-page event-mobile-page ' +
                        (!this.props.user.showfloorInfoSeen ? 'show-info-screen' : '')
                    }
                >
                    {this.props.loadingExhibitors && <Spinner />}
                    {this.props.exhibitors?.length ? (
                        <>
                            {isLargeScreen ? (
                                <div onDragStart={preventDefaultDrag} className="booth">
                                    {this.props.activeExhibitorId && (
                                        <ExhibitorBooth
                                            singleExhibitorsVoteCount={this.state.singleExhibitorsVoteCount}
                                            voteLoading={this.state.voteLoading}
                                            exhibitorInProgress={this.state.exhibitorInProgress}
                                            votingPrivalages={this.state.votingPrivalages}
                                            votingData={this.state.votingData}
                                            votingText={this.state.votingText}
                                            isVotingEnabled={this.state.isVotingEnabled}
                                            handleChangeVote={this.handleChangeVote}
                                            previousExhibitor={this.previousExhibitor}
                                            nextExhibitor={this.nextExhibitor}
                                            previousExhibitorLogo={this.state.previousExhibitorLogoUrl}
                                            previousExhibitorCompany={this.state.previousExhibitorCompany}
                                            previousExhibitorHasLogo={this.state.previousExhibitorHasLogo}
                                            nextExhibitorLogo={this.state.nextExhibitorLogoUrl}
                                            nextExhibitorCompany={this.state.nextExhibitorCompany}
                                            nextExhibitorHasLogo={this.state.nextExhibitorHasLogo}
                                            hasAccessToShowfloor={hasAccessToShowfloor}
                                            showfloorData={activeShowfloorData}
                                            showExhibitorsOverlay={this.state.showExhibitorsOverlay}
                                        />
                                    )}
                                    {hasAccessToShowfloor && (
                                        <>
                                            {activeShowfloorData?.type === 'exhibitors' && (
                                                <FeaturedExhibitorsSlider
                                                    showExhibitorsOverlay={this.showExhibitorsOverlay}
                                                    exhibitorActive={exhibitorActive}
                                                />
                                            )}
                                            {activeShowfloorData?.type === 'posters' && (
                                                <PosterDetails
                                                    showExhibitorsOverlay={this.showExhibitorsOverlay}
                                                    exhibitorActive={exhibitorActive}
                                                />
                                            )}
                                        </>
                                    )}
                                    <DetectOutsideClick
                                        hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                        target="exhibitorsOverlay"
                                    >
                                        <ExhibitorsOverlay
                                            hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                            showExhibitorsOverlay={this.state.showExhibitorsOverlay}
                                            votingPrivalages={this.state.votingPrivalages}
                                            votingData={this.state.votingData}
                                            singleExhibitorsVoteCount={this.state.singleExhibitorsVoteCount}
                                            voteLoading={this.state.voteLoading}
                                            exhibitorInProgress={this.state.exhibitorInProgress}
                                            isVotingEnabled={this.state.isVotingEnabled}
                                            handleChangeVote={this.handleChangeVote}
                                        />
                                    </DetectOutsideClick>
                                </div>
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="booth-mobile-header">
                                    <ExhibitorsOverlayMobile
                                        singleExhibitorsVoteCount={this.state.singleExhibitorsVoteCount}
                                        translation={translation}
                                        defaultTranslation={defaultTranslation}
                                        hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                        openExhibitorsOverlay={this.showExhibitorsOverlay}
                                        showExhibitorsOverlay={this.state.showExhibitorsOverlay}
                                        votingPrivalages={this.state.votingPrivalages}
                                        isVotingEnabled={this.state.isVotingEnabled}
                                        votingData={this.state.votingData}
                                        handleChangeVote={this.handleChangeVote}
                                        voteLoading={this.state.voteLoading}
                                        exhibitorInProgress={this.state.exhibitorInProgress}
                                    />
                                    {this.state.isVotingEnabled && (
                                        <VoteBanner
                                            voteLoading={this.state.voteLoading}
                                            singleExhibitorsVoteCount={this.state.singleExhibitorsVoteCount}
                                            exhibitorInProgress={this.state.exhibitorInProgress}
                                            eventId={this.props.eventId}
                                            exhibitor={exhibitorActive}
                                            showfloorId={exhibitorActive?.showfloor}
                                            votingPrivalages={this.state.votingPrivalages}
                                            votingData={this.state.votingData}
                                            isVotingEnabled={this.state.isVotingEnabled}
                                            handleChangeVote={this.handleChangeVote}
                                            previousExhibitor={this.previousExhibitor}
                                            votingText={this.state.votingText}
                                        />
                                    )}

                                    <div onDragStart={preventDefaultDrag} className="tabs-container">
                                        <div
                                            className={`button-tab tab ${currentMobileTab === 'booth' ? 'active' : ''}`}
                                            onClick={this.handleChangeMobileTab('booth')}
                                        >
                                            {translation?.sideMenu.booth}
                                        </div>
                                        <div
                                            className={`button-tab tab ${currentMobileTab === 'wall' ? 'active' : ''}`}
                                            onClick={this.handleChangeMobileTab('wall')}
                                        >
                                            {translation?.sideMenu.boothWall}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isLargeScreen && currentMobileTab === 'booth' && this.props.activeExhibitorId && (
                                <ExhibitorBoothMobile />
                            )}
                            {showWall && (
                                <div onDragStart={preventDefaultDrag} className="booth-wall-container">
                                    <div onDragStart={preventDefaultDrag} className="booth-wall-header">
                                        <span>{translation?.sideMenu.boothWall}</span>
                                    </div>
                                    <Wall
                                        defaultTranslation={defaultTranslation}
                                        scholar={exhibitorActive?.type === EXHIBITOR_TYPES.scholar}
                                        isBoothWall
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {isLargeScreen ? (
                                <>
                                    {activeShowfloorData?.type === 'posters' ? (
                                        <ShowfloorNoScholars />
                                    ) : (
                                        <ShowfloorNoExhibitors hasAccessToShowfloor={hasAccessToShowfloor} />
                                    )}
                                    {hasAccessToShowfloor && (
                                        <FeaturedExhibitorsSlider
                                            showExhibitorsOverlay={this.showExhibitorsOverlay}
                                            hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                            noExhibitors
                                        />
                                    )}
                                    <DetectOutsideClick
                                        hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                        target="exhibitorsOverlay"
                                    >
                                        <ExhibitorsOverlay
                                            hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                            showExhibitorsOverlay={this.state.showExhibitorsOverlay}
                                        />
                                    </DetectOutsideClick>
                                </>
                            ) : (
                                <>
                                    <ExhibitorsOverlayMobile hide />
                                    {activeShowfloorData?.type === 'posters' ? (
                                        <ShowfloorNoScholars />
                                    ) : (
                                        <ShowfloorNoExhibitors />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                <NotificationPresentationStarts />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        loadingEvent: state.event.loading,
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        user: state.user.data,
        eventProfile: state.userEventProfile.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        exhibitors: state.exhibitors.approvedExhibitors,
        loadingExhibitors: state.exhibitors.loading,
        featuredExhibitors: state.exhibitors.featuredExhibitors,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        showfloors: state.showfloors.showfloors,
        loadingShowfloors: state.showfloors.loading,
        activeShowfloorId: state.showfloors.activeShowfloorId,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetExhibitors: (eventId) => dispatch(actions.getExhibitors(eventId)),
        onGetShowfloorExhibitors: (eventId, showfloorId) =>
            dispatch(actions.getShowfloorExhibitors(eventId, showfloorId)),
        onGetExhibitor: (exhibitorId) => dispatch(actions.getExhibitor(exhibitorId)),
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
        closeTopNavigation: () => dispatch(actions.topNavClose()),
        onRefreshUserUserData: () => dispatch(actions.refreshUserData()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        onGetShowfloors: (eventId) => dispatch(actions.getShowfloors(eventId)),
        onSetActiveShowfloorId: (showfloorId) => dispatch(actions.setActiveShowfloorId(showfloorId)),
        onGetShowfloor: (eventId, showfloorId) => dispatch(actions.getShowfloor(eventId, showfloorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Showfloor);
