import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import './NetworkFilters.scss';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearIcon from '@material-ui/icons/Clear';
import {usePlatformTranslation} from '../../services/hooks';
import {
    NetworkFiltersAccordion,
    NetworkFiltersAccordionSummary,
    NetworkFiltersAccordionDetails,
    NetworkFilterApplyButton,
    NetworkFilterResetButton,
    NetworkFilterTooltip,
    NetworkFilterFormControlLabel,
} from './Components/NetworkFilterStyledComponents';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

export default function NetworkFilter({
    show,
    filters,
    selectedOptions,
    onApplyFilters,
    onResetFilters,
    onCloseFilters,
    selectedAllNetworkFilters,
    atLeastOneNetworFilterOptionSelected,
    onOptionSelected,
    platformLanguage,
}) {
    const filtersUiText = usePlatformTranslation((state) => state.generalText.filters);
    const closeUiText = usePlatformTranslation((state) => state.generalText.close);
    const resetFilterUiText = usePlatformTranslation((state) => state.generalText.resetFilter);
    const unselectAllUiText = usePlatformTranslation((state) => state.generalText.unselectAll);
    const selectAllUiText = usePlatformTranslation((state) => state.generalText.selectAll);
    const applyUiText = usePlatformTranslation((state) => state.generalText.apply);
    const applyFilterTooltipUiText = usePlatformTranslation((state) => state.networking.applyFilterTooltip);
    const languages = useSelector((state) => state.languages);
    const [expandedFilters, setExpandedFilters] = useState([]);
    const [selectedValues, setSelectedValues] = useState(selectedOptions);
    const [selectedAllOptions, setSelectedAllOptions] = useState(selectedAllNetworkFilters);
    const [atLeastOneOptionSelected] = useState(atLeastOneNetworFilterOptionSelected);

    const handleSelectedOption = (option, value, checked) => {
        const updatedOptions = {...selectedOptions};

        updatedOptions[`${option.filterTitle}-${value}`] = {
            label: option.label,
            _id: option._id,
            filterTitle: option.filterTitle,
            value,
            checked,
        };

        const allOptionsSelected = option.values.every(
            (optionValue) =>
                updatedOptions[`${option.filterTitle}-${optionValue.value}`] &&
                updatedOptions[`${option.filterTitle}-${optionValue.value}`].checked
        );

        const atLeastOneOptionIsSelected = option.values.filter(
            (optionValue) =>
                updatedOptions[`${option.filterTitle}-${optionValue.value}`] &&
                updatedOptions[`${option.filterTitle}-${optionValue.value}`].checked
        );

        selectedAllOptions[option.filterTitle] = allOptionsSelected;
        atLeastOneOptionSelected[option.filterTitle] = atLeastOneOptionIsSelected.length;
        const count = getSelectedFilters(updatedOptions);

        setSelectedFiltersCount(count);
        setSelectedValues(updatedOptions);
        onOptionSelected(updatedOptions);
    };

    const getSelectedFilters = (filters) => {
        const filteredOptions = Object.keys(filters)
            .filter((key) => filters[key].checked === true)
            .map((key) => filters[key].filterTitle ? filters[key].filterTitle : filters[key].label);
        const uniqueFilters = [...new Set(filteredOptions)];
        return uniqueFilters.length;
    };

    const handleSelectAll = (option) => {
        const updatedOptions = {...selectedOptions};
        const checked = !selectedAllOptions[option.filterTitle];
        option.values.forEach((optionValue) => {
            updatedOptions[`${option.filterTitle}-${optionValue.value}`] = {
                _id: option._id,
                label: option.label,
                filterTitle: option.filterTitle,
                value: optionValue.value,
                checked,
            };
        });
        atLeastOneOptionSelected[option.filterTitle] = checked ? option.values.length : 0;
        selectedAllOptions[option.filterTitle] = checked;
        setSelectedAllOptions(selectedAllOptions);
        setSelectedFiltersCount(getSelectedFilters(updatedOptions));
        setSelectedValues(updatedOptions);
        onOptionSelected(updatedOptions);
    };

    const [selectedFiltersCount, setSelectedFiltersCount] = useState(getSelectedFilters(selectedOptions));

    const FilterOption = ({option, index}) => {
        let expanded = expandedFilters.indexOf(index) > -1;

        const handleAccordionClick = () => {
            const updatedExpandedFilters = [...expandedFilters];
            if (!expanded) {
                updatedExpandedFilters.push(index);
            } else {
                updatedExpandedFilters.splice(expandedFilters.indexOf(index), 1);
            }
            setExpandedFilters(updatedExpandedFilters);
        };
        return (
            <div className={`network-filter ${platformLanguage === 'ar' ? 'ar-lang' : ''}`}>
                <NetworkFiltersAccordion className="network-filter__accordion" expanded={expanded}>
                    <NetworkFiltersAccordionSummary tabIndex="-1" className="summary" onClick={handleAccordionClick}>
                        <div className="field-title-details network-filter__title-details">
                            <p
                                className={`title ${
                                    atLeastOneOptionSelected[option.filterTitle] > 0 && 'title--active'
                                } `}
                            >
                                {option.filterTitle}
                            </p>

                            {!expanded && atLeastOneOptionSelected[option.filterTitle] > 0 && (
                                <span className="network-filter__title-counter">
                                    {atLeastOneOptionSelected[option.filterTitle]}
                                </span>
                            )}
                            <Button type="label" onClick={handleAccordionClick}>
                                {expanded ? (
                                    <ExpandLessIcon fontSize="large" className="expanded-less-icon" />
                                ) : (
                                    <ExpandMoreIcon fontSize="large" />
                                )}
                            </Button>
                        </div>
                    </NetworkFiltersAccordionSummary>
                    <NetworkFiltersAccordionDetails>
                        <div className="d-flex flex-col">
                            <NetworkFilterFormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checkedIcon={<IndeterminateCheckBoxIcon />}
                                        color="default"
                                        checked={selectedAllOptions[option.filterTitle] || false}
                                        onChange={(e) => handleSelectAll(option)}
                                        name={option.filterTitle}
                                    />
                                }
                                label={selectedAllOptions[option.filterTitle] ? unselectAllUiText : selectAllUiText}
                            />
                            {option.values.map((value, index) => (
                                <NetworkFilterFormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            color="secondary"
                                            checked={
                                                selectedValues[`${option.filterTitle}-${value.value}`] &&
                                                selectedValues[`${option.filterTitle}-${value.value}`].checked
                                            }
                                            onChange={(e) =>
                                                handleSelectedOption(option, value.value, e.target.checked)
                                            }
                                            name={value.value}
                                        />
                                    }
                                    label={value.value}
                                />
                            ))}
                        </div>
                    </NetworkFiltersAccordionDetails>
                </NetworkFiltersAccordion>
            </div>
        );
    };

    return (
        <div className={`${show ? 'network-filters network-filters--show' : 'network-filters'}`}>
            <div className="network-filters__header">
                <p>{filtersUiText}</p>
                <Button
                    variant={"text"}
                    startIcon={<ClearIcon />}
                    disableRipple
                    size={'small'}
                    onClick={() => onCloseFilters(selectedFiltersCount)}
                >
                    {closeUiText}
                </Button>
            </div>
            <div className="network-filters__body scroll-left-container">
                {filters.map((flt, index) => (
                    <FilterOption key={index} index={index} option={flt} />
                ))}
            </div>
            <div className={`${selectedFiltersCount<=0&& 'no-filter-add'} network-filters__footer 
            ${selectedFiltersCount>0 && 
            (languages?.platformLanguage === 'ru' || 
                languages?.platformLanguage === 'ua' || 
                languages?.platformLanguage === 'de' || 
                languages?.platformLanguage === 'fr') ? 'ml-helper': ''}  ${selectedFiltersCount<=0 &&  languages?.platformLanguage === 'de' ? 'ml-helper' : ''}`}>
                <NetworkFilterResetButton
                    type="label"
                    className={`network-filters__reset-btn ${platformLanguage === 'ar' ? 'ar-lang-reset' : ''} 
                    ${selectedFiltersCount>0 && 
                    (languages?.platformLanguage === 'ru' || 
                        languages?.platformLanguage === 'ua' || 
                        languages?.platformLanguage === 'de' || 
                        languages?.platformLanguage === 'fr') ? 'mb-5': ''} ${selectedFiltersCount<=0 &&  languages?.platformLanguage === 'de' ? 'mb-5' : ''}`}
                    disabled={selectedFiltersCount === 0}
                    onClick={onResetFilters}
                >
                    {resetFilterUiText}
                </NetworkFilterResetButton>
                {selectedFiltersCount !== 0 && (
                    <Button
                        type="label"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            onApplyFilters(
                                filters,
                                selectedValues,
                                selectedFiltersCount,
                                selectedAllOptions,
                                atLeastOneOptionSelected
                            )
                        }
                    >
                        {applyUiText}
                        {selectedFiltersCount > 0 ? <span>({selectedFiltersCount})</span> : null}
                    </Button>
                )}
                {selectedFiltersCount === 0 && (
                    <NetworkFilterTooltip
                        placement="bottom-end"
                        enterDelay={1000}
                        arrow
                        title={applyFilterTooltipUiText}
                    >
                        <span className={"apply-filters-button-container"}>
                            <NetworkFilterApplyButton
                                type="label"
                                className="network-filters__apply-btn"
                                disabled={true}
                                variant="contained"
                                color="secondary"
                                onClick={() => onApplyFilters(filters, selectedValues, selectedFiltersCount)}
                            >
                                {applyUiText}
                            </NetworkFilterApplyButton>
                        </span>
                    </NetworkFilterTooltip>
                )}
            </div>
        </div>
    );
}
