import React from 'react';
import SingleUser from '../../../HOC/SingleUser';
import {TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ColoredScrollbars from '../../../SmallLayoutComponents/ColoredScrollbars';
import {connect} from 'react-redux';
import colors from '../../../CSS/_variables.module.scss';
import EmptyUsers from '../../../Images/svg/EmptyWall';
import {preventDefaultDrag} from '../../../Utils/utils';
import isEqual from "lodash/isEqual";
import DownloadUsersExcel from '../DownloadUsersExcel';
import {IconButton} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import "./StatisticsUserTable.scss"
import {debounce} from "lodash";

class StatisticsUsersTable extends React.Component {
    state = {
        searchValue: '',
        filteredUsers: [],
    };

    componentDidMount() {
        this.setFilteredUsersList();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.usersList, this.props.usersList)) {
            this.setFilteredUsersList();
        }
    }

    setFilteredUsersList = () => {
        const {searchValue} = this.state;
        const {usersList} = this.props;

        let filteredUsers = [...usersList];

        if (searchValue) {
            filteredUsers = usersList.filter(
                (userData) => {
                    const fullName = `${userData.first.toLowerCase()} ${userData.last.toLowerCase()}`;
                    const fullNameBackwards = `${userData.last.toLowerCase()} ${userData.first.toLowerCase()}`;
                    return fullName.includes(searchValue.toLowerCase()) || fullNameBackwards.includes(searchValue.toLowerCase());
                }
            );
        }

        this.setState({filteredUsers: filteredUsers});
    };

    checkIsOnline = (userId) => {
        const {onlineUsersObject} = this.props;
        return onlineUsersObject[userId];
    };

    onSearch = (e) => {
        const {value} = e.target;
        this.setState({searchValue: value}, () => this.debouncedFilterUsers());
    };

    debouncedFilterUsers = debounce(() => {
        this.setFilteredUsersList()
    }, 500)

    getExcelFilters = () => {
        const {searchValue} = this.state
        const {downloadUsers, announcementId} = this.props
       
        let data = {};

        // only add properties to data object if needed
        // ex: if search = "" we don't want to add a data.search property at all
        searchValue && (data.search = searchValue);
        announcementId && (data.announcementId = announcementId);
        downloadUsers?.length > 0 && (data.usersList  = downloadUsers)

        return data
    }

    clearSearchValue = () => this.setState({searchValue: ''}, () => this.debouncedFilterUsers());
    
    render() {
        const {searchValue, filteredUsers} = this.state;

        const {
            statisticLabel,
            downloadUsers,
            enhancedStatistics,
            statisticName,
            auditoriums,
            slotTitle,
            isNewTrackVersion,
            eventUsersVisitedBooth,
        } = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="statistics-table table-details">
                <div onDragStart={preventDefaultDrag} className="StatisticUsers" data-empty={true}>
                    <div onDragStart={preventDefaultDrag} className="statistics-header">
                        <span onDragStart={preventDefaultDrag} draggable="false" className="StatisticUsers__title">
                          {statisticLabel}
                        </span>
                        {filteredUsers?.length > 0 &&
                        (!window?.location?.pathname?.includes('exhibitor-booth-view') || isNewTrackVersion) &&
                            <DownloadUsersExcel
                                params={this.getExcelFilters()}
                                fileName={`${slotTitle}`}
                            />
                        }
                    </div>

                    <div onDragStart={preventDefaultDrag} className="StatisticUsers__content">
                        <div onDragStart={preventDefaultDrag} className="StatisticUsers__input">
                            <TextField
                                placeholder="Search by the user name"
                                type="text"
                                value={searchValue}
                                fullWidth={true}
                                variant="outlined"
                                InputLabelProps={{shrink: false}}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            {searchValue && (
                                                <IconButton
                                                    disableRipple
                                                    disableTouchRipple
                                                    disableFocusRipple
                                                    onClick={this.clearSearchValue}
                                                >
                                                    <ClearIcon
                                                        classes={{
                                                            root:
                                                            searchValue.length > 0
                                                                    ? 'dark-color'
                                                                    : 'grey-color',
                                                        }}
                                                    />
                                                </IconButton>
                                            )}
                                            <SearchIcon />
                                        </>
                                    )
                                }}
                                onChange={this.onSearch}
                            />
                        </div>
                        {auditoriums ? (
                            <>
                                {filteredUsers?.length > 0 ? (
                                    <div onDragStart={preventDefaultDrag} className="StatisticUsers__userListHolder">
                                        <ColoredScrollbars>
                                            <ul className="StatisticUsers__userList">
                                                {filteredUsers.map((eventUser) => (
                                                    <SingleUser
                                                        key={eventUser._id}
                                                        id={eventUser._id}
                                                        user={eventUser}
                                                        isOnline={this.checkIsOnline(eventUser.user)}
                                                    />
                                                ))}
                                            </ul>
                                        </ColoredScrollbars>
                                    </div>
                                ) : (
                                    <div onDragStart={preventDefaultDrag} className="empty-statistics-users">
                                        <div>
                                            <EmptyUsers strokefill={colors.primary} fillPrimary={colors.primary} />
                                            <p>The person you are looking for has not attended yet this presentation</p>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div
                                onDragStart={preventDefaultDrag}
                                className={`StatisticUsers__userListHolder ${
                                    filteredUsers?.length === 0 ? 'empty' : ''
                                }`}
                            >
                                <ColoredScrollbars>
                                    <ul className="StatisticUsers__userList">
                                        {filteredUsers.map((eventUser) => (
                                            <SingleUser
                                                key={eventUser._id}
                                                id={eventUser._id}
                                                user={eventUser}
                                                isOnline={this.checkIsOnline(eventUser.user)}
                                            />
                                        ))}
                                    </ul>
                                </ColoredScrollbars>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        onlineUsersObject: state.onlineUsers.onlineUsersObject,
        event: state.event.data,
    };
};

export default connect(mapStateToProps)(StatisticsUsersTable);
