import {ReactComponent as Info} from '../images/info.svg';
import {useSelector} from 'react-redux';
import classnames from 'classnames';

const EventInformation = ({description}) => {
    const currentLanguage = useSelector((state) => state.languages.eventLanguage);
    const langArabic = currentLanguage === 'ar';

    return (
        <div className={classnames('event-information-container', {'lang-ar': langArabic})}>
            <Info color={'secondary'} />
            <div className={'columns-2'} dangerouslySetInnerHTML={{__html: description}} />
        </div>
    );
};
export default EventInformation;
