/**
 * function is design detect a webview
 * used to detect if web app is loaded inside flutter webview mobile app
 * @date 19/04/2023
 * @export
 * @return {*}  {boolean}
 */
export function isWebview() {
    const navigator = window.navigator;
    const userAgent = navigator.userAgent;
    const normalizedUserAgent = userAgent.toLowerCase();
    const standalone = navigator.standalone;

    const isIos =
        /ip(ad|hone|od)/.test(normalizedUserAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const isAndroid = /android/.test(normalizedUserAgent);
    const isSafari = /safari/.test(normalizedUserAgent);
    const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);

    return isWebview;
}
