import React from 'react';
import {getPrivateChatId, preventDefaultDrag} from '../Utils/utils';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import colors from '../CSS/_variables.module.scss';
import RequestSent from '../Images/svg/RequestSent';
import RejectRequest from '../Images/svg/RejectRequest';
import {ReactComponent as Connect} from '../Images/svg/add-button.svg';
import OpenChatIcon from '../Images/svg/OpenChat';
class SingleUserContact extends React.PureComponent {
    handleOpenPrivateChat = () => {
        const {user} = this.props;

        const targetUserId = user._id;
        const privateChatId = getPrivateChatId(this.props.privateChats, targetUserId);

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (targetUserId !== this.props.userLoggedIn._id && !this.props.loading) {
                this.props.onCreatePrivateChat(targetUserId);
            }
        }
    };

    handleAcceptFriendRequest = () => {
        this.props.onAcceptContactRequest(this.props.requestId);
    };

    handleDeclineFriendRequest = () => {
        this.props.onDeclineContactRequest(this.props.requestId);
    };

    handleSendFriendConnection = () => {
        const {user} = this.props;
        const targetUserId = user._id;
        this.props.onSendContactRequest(targetUserId);
    };

    handleOpenPrivateChatOnEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleOpenPrivateChat();
            this.props.seeContacts();
        }
    }

    render() {
        const {user, translation, defaultTranslation} = this.props;
        return (
            <div>
                <div
                    onDragStart={preventDefaultDrag}
                    className={'user-container ' + (this.props.isOnline ? 'is-online' : 'is-offline')}
                >
                    <div
                        className="participant-avatar"
                        style={{backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user?.avatarSmall}')`}}
                    />
                    <div onDragStart={preventDefaultDrag} className="user-details">
                        <div>
                            <div onDragStart={preventDefaultDrag} className="user-name">
                                <p onDragStart={preventDefaultDrag} className="user-name-top">
                                    {user?.first} {user?.last}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div
                                onDragStart={preventDefaultDrag}
                                className={'connection ' + (this.props.friendConnectionReceived ? null : 'no-action')}
                            >
                                <button
                                    tabIndex="0"
                                    className="participant-button open-chat"
                                    onClick={this.handleOpenPrivateChat}
                                    onKeyDown={this.handleOpenPrivateChatOnEnter}
                                    disabled={this.props.loading}
                                >
                                    <div>
                                        <OpenChatIcon
                                            primary={colors.primary}
                                            secondary={colors.secondary}
                                        />
                                        <span>{defaultTranslation?.networking.chat}</span>
                                    </div>
                                </button>
                            </div>
                            {this.props.friendConnectionReceived ? (
                                <div>
                                    <button className="accept-request" onClick={this.handleAcceptFriendRequest}>
                                        <Connect fill={colors.secondary} />
                                        <span>{translation?.contactsDropdown.acceptButton}</span>
                                    </button>
                                    <button className="reject-request" onClick={this.handleDeclineFriendRequest}>
                                        <RejectRequest
                                            fillErrorLight={colors.errorLight}
                                            strokeErrorLight={colors.errorLight}
                                        />
                                        <span>{translation?.contactsDropdown.rejectButton}</span>
                                    </button>
                                </div>
                            ) : null}
                            {this.props.friendConnectionSent ? (
                                <div onDragStart={preventDefaultDrag} className="connection">
                                    <button className="participant-button request-sent" disabled>
                                        <RequestSent fillWarning={colors.warning} />
                                        <span>{translation?.contactsDropdown.pendingButton}</span>
                                    </button>
                                </div>
                            ) : null}
                            {this.props.canConnectTo ? (
                                <div onDragStart={preventDefaultDrag} className="connection">
                                    <button
                                        className="participant-button add-button"
                                        onClick={this.handleSendFriendConnection}
                                    >
                                        <Connect fill={colors.secondary} />
                                        <span>{translation?.contactsDropdown.addButton}</span>
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="user-wrapper-separator" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        privateChats: state.user.data.privateChats,
        loading: state.user.loading,
        userLoggedIn: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeContacts: () => dispatch(actions.topNavSeeContacts()),
        onSendContactRequest: (to) => dispatch(actions.sendContactRequest(to)),
        onAcceptContactRequest: (requestId) => dispatch(actions.acceptContactRequest(requestId)),
        onDeclineContactRequest: (requestId) => dispatch(actions.declineContactRequest(requestId)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId) => dispatch(actions.createPrivateChat(targetUserId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleUserContact);
