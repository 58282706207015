import Announcement from './Announcement';
import Slider from 'react-slick';
import {ReactComponent as CloseIcon} from 'Images/svg/close-red.svg';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {ReactComponent as SliderArrow} from 'Images/svg/slider-arrow.svg';

export const AnnouncementsBanner = ({
                                        announcements,
                                        handleCollapse,
                                        handleCallToActionButtonClicked,
                                        hasWall,
                                        ready,
                                        exit,
                                    }) => {
    const [slider, setSlider] = useState();
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    const changeSlideIndex = (slick, currentSlide, nextSlide) => {
        setCurrentSlideIndex(currentSlide);
    };

    // bugfix for wrong slide index value when switching between rooms
    // the index needs to reset because the slider resets because of ready (line 80)
    useEffect(()=> {
        if(!ready) {
            setCurrentSlideIndex(0)
        }
    },[ready])

    const sliderSettings = {
        className: 'announcements-slider',
        dots: false,
        arrows: false,
        draggable: false,
        pauseOnHover: false,
        swipe: true,
        infinite: true,
        speed: 400,
        autoplay: true,
        autoplaySpeed: 15000,
        adaptiveHeight: true,
        variableWidth: false,
        centerMode: false,
        centerPadding: 0,
        responsiveDisplay: true,
        rtl: isRtlLanguage,
        // used beforeChange because afterChange is not triggered when adaptiveHeight is true
        // (react-slick issue)
        beforeChange: changeSlideIndex,
    };

    const goToPreviousSlide = () => {
        slider.slickPrev();
    };

    const goToNextSlide = () => {
        slider.slickNext();
    };

    return (
        <div
            className={
                `announcements-banner 
                ${hasWall ? 'side-navigation-opened' : 'full-screen'} 
                ${exit ? 'banner-exit' : ''}`}
            >
            <div className={`collapse-icon ${ready ? '' : 'not-ready'}`}>
                <CloseIcon onClick={handleCollapse}/>
            </div>
            <div className={`banner-content ${ready ? '' : 'not-ready'}`}>
                {announcements.length === 1 ? (
                    <Announcement
                        announcement={announcements[0]}
                        handleCallToActionButtonClicked={handleCallToActionButtonClicked}
                    />
                ) : (
                    ready &&
                    <Slider {...sliderSettings} ref={(slider) => setSlider(slider)}>
                        {announcements.map((announcement) => {
                            return (
                                <Announcement
                                    key={announcement._id}
                                    announcement={announcement}
                                    handleCallToActionButtonClicked={handleCallToActionButtonClicked}
                                />
                            );
                        })}
                    </Slider>
                )}
                {announcements.length > 1 && (
                    <div className="announcements-slider-control">
                        <div className="arrow previous" onClick={goToPreviousSlide}>
                            <SliderArrow/>
                        </div>
                        <div className="announcement-number">
                            {currentSlideIndex + 1}/{announcements?.length}
                        </div>
                        <div className="arrow next" onClick={goToNextSlide}>
                            <SliderArrow/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
