import React from 'react';
import {useSelector} from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import {preventDefaultDrag} from '../../../Utils/utils';

export const VideoMeetingJoinBanner = ({startConference}) => {
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    return (
        <div onDragStart={preventDefaultDrag} className="chat-banner">
            <div onDragStart={preventDefaultDrag} className="chat-banner-info-container">
                <div onDragStart={preventDefaultDrag} className="video-started-container">
                    {translation?.meetings.banner.videoCallStarted ||
                        defaultTranslation?.meetings.banner.videoCallStarted}
                    <Tooltip
                        arrow
                        id="tooltip-video-started"
                        title={
                            translation?.meetings.banner.videoCallStartedInfo ||
                            defaultTranslation?.meetings.banner.videoCallStartedInfo
                        }
                    >
                        <InfoIcon color="secondary" />
                    </Tooltip>
                </div>
            </div>
            <div onDragStart={preventDefaultDrag} className="chat-banner-actions-container">
                <div onDragStart={preventDefaultDrag} onClick={startConference} className="button">
                    {translation?.meetings.banner.joinCall || defaultTranslation?.meetings.banner.joinCall}
                </div>
            </div>
        </div>
    );
};
