import React, {useEffect, useState, useRef, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import * as actions from 'store/actions';
import {Link} from 'react-router-dom';
import Spinner from 'SmallLayoutComponents/Spinner';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {getCurrencySymbol, preventDefaultDrag, setErrorMessageForInvitationCodes} from 'Utils/utils';
import StripePaymentDialog from 'Dialogs/StripePaymentDialog/StripePaymentDialog';
import SelectCountry from 'Components/InputFields/SelectCountry';
import SelectFieldLoginRegistration from 'Pages/LoginRegistration/components/Inputs/SelectFieldLoginRegistration';
import Checkbox from '@material-ui/core/Checkbox';
import RegisterMenuLinks from 'Components/RegisterMenuLinks';
import AccessPackage from '../../../Event/AccessPackage';
import {Helmet} from 'react-helmet';
import {addValidationRulesScholar, removeValidationRulesScholar, handleOnError} from '../../Utils';
import CantJoinEventDialog from 'Components/EventManagement/EmailDomains/CantJoinEventDialog';
import Layout from '../../components/Layout';
import RegistrationProgressbar from '../../components/Progressbar/RegistrationProgressbar';
import Roles from '../../components/Roles/Roles';
import EventInformation from '../../components/EventInformation';
import TextFieldInfoPointLoginRegistration from '../../components/Inputs/TextFieldInfoPointLoginRegistration';
import NotFound from '../../../NotFound';
import {EXHIBITOR_TYPES} from '../../../../Utils/constants/shared';
import RadioFieldLoginRegistration from '../../components/Inputs/RadioFieldLoginRegistration';
import CheckboxFieldLoginRegistration from '../../components/Inputs/CheckboxFieldLoginRegistration';
import classnames from 'classnames';
import GeneralTextFieldInfoPointLoginRegistration from '../../components/Inputs/GeneralTextFieldInfoPointLoginRegistration';

const RegisterAsScholar = () => {
    const dispatch = useDispatch();

    //refs
    const prevStripeSecret = useRef('');
    const prevEventLanguage = useRef('');

    const formRef = useRef(null);

    //redux
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const languages = useSelector((state) => state.languages);

    const event = useSelector((state) => state.event);
    const {eventId, eventSlug, eventInfo, loading: loadingEvent} = event;
    const {restrictScholarRegistration} = event?.eventInfo;

    const user = useSelector((state) => state.user);
    const {loadingUser, data, preselectedAccessPackageId, stripeSecret} = user;
    const userEmail = data.email;

    const hasRestrictedEmailAccess = useSelector((state) => state.emailDomains.hasRestrictedEmailAccess);

    const invitations = useSelector((state) => state.invitations);
    const {loadingInvitationCode} = invitations;

    const {isMobile, isLargeScreen} = useSelector((state) => state.layout);

    const currentLanguage = useSelector((state) => state.languages.eventLanguage);
    const langArabic = currentLanguage === 'ar';

    //states
    const [defaultFields, setDefaultFields] = useState({
        fields: [
            {
                isEnabled: true,
                name: 'affiliation',
                type: 'text',
                value: '',
                label: 'Affiliation',
                multiline: 0,
                validators: [],
                errorMessages: [],
            },
            {
                isEnabled: true,
                name: 'title',
                type: 'text',
                value: '',
                label: 'Academic Title',
                multiline: 0,
                validators: [],
                errorMessages: [],
            },
            {
                isEnabled: true,
                name: 'phone',
                type: 'tel',
                value: '',
                label: 'Mobile',
                multiline: 0,
                validators: ['isPhone'],
                errorMessages: [translation?.errors?.phoneInvalid || defaultTranslation.errors.phoneInvalid],
            },
            {
                isEnabled: true,
                name: 'googleScholar',
                type: 'text',
                value: '',
                label: 'Google Scholar profile',
                multiline: 0,
                validators: ['isGoogleScholarLink'],
                errorMessages: [
                    translation?.errors?.googleScholarLinkInvalid || defaultTranslation.errors.googleScholarLinkInvalid,
                ],
            },
            {
                isEnabled: true,
                name: 'researchGate',
                type: 'text',
                value: '',
                label: 'Research Gate profile',
                multiline: 0,
                validators: ['isResearchGateLink'],
                errorMessages: [
                    translation?.errors?.researchGateLinkInvalid || defaultTranslation.errors.researchGateLinkInvalid,
                ],
            },
            {
                isEnabled: true,
                name: 'facebook',
                type: 'text',
                value: '',
                label: 'Facebook profile',
                multiline: 0,
                validators: ['isFacebookLink'],
                errorMessages: [
                    translation.errors.facebookLinkInvalid || defaultTranslation.errors.facebookLinkInvalid,
                ],
            },
            {
                isEnabled: true,
                name: 'linkedin',
                type: 'text',
                value: '',
                label: 'Linkedin',
                multiline: 0,
                validators: ['isLinkedinLink'],
                errorMessages: [
                    translation.errors.linkedinLinkInvalid || defaultTranslation.errors.linkedinLinkInvalid,
                ],
            },
            {
                isEnabled: true,
                name: 'twitter',
                type: 'text',
                value: '',
                label: 'Twitter',
                multiline: 0,
                validators: ['isTwitterLink'],
                errorMessages: [translation.errors.twitterLinkInvalid || defaultTranslation.errors.twitterLinkInvalid],
            },
            {
                isEnabled: true,
                name: 'country',
                type: 'text',
                value: '',
                label: 'Country',
                multiline: 0,
                validators: [],
                errorMessages: [],
            },
            {
                isEnabled: true,
                name: 'website',
                type: 'url',
                value: '',
                label: 'Organisation’s  website',
                multiline: 0,
                validators: ['isLink'],
                errorMessages: [translation.errors.linkInvalid || defaultTranslation.errors.linkInvalid],
            },
        ],
        invitationCode: '',
        country: '',
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [showCodeInvalidErrorMessage, setShowCodeInvalidErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [agreeToEventPrivacyPolicy, setAgreeToEventPrivacyPolicy] = useState(false);
    const [displayAgreeToPrivacyPolicyMessage, setDisplayAgreeToPrivacyPolicyMessage] = useState(false);
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const [errorAccessPackageNotSelected, setErrorAccessPackageNotSelected] = useState(false);
    const [brandingTranslation, setBrandingTranslation] = useState(null);
    const [unregisterInProgressError, setUnregisterInProgressError] = useState(false);

    const [showBottomStickyButton, setShowBottomStickyButton] = useState(true);

    const availableRoles = useMemo(
        () => [
            {
                name: translation.newLoginRegistrations.poster || defaultTranslation.newLoginRegistrations.poster,
                slug: 'poster-study',
                value: restrictScholarRegistration,
                description:
                    translation.newLoginRegistrations.posterText || defaultTranslation.newLoginRegistrations.posterText,
                checked: true,
                url: 'scholar-registration',
            },
        ],
        [restrictScholarRegistration, translation, defaultTranslation]
    );
    const setLanguagesData = () => {
        setBrandingTranslation(
            eventInfo.brandingTranslations.find((translation) => translation.language === languages.eventLanguage)
        );
    };
    const updateFieldsErrorsLanguage = () => {
        const updatedFields = defaultFields.fields.map((field) => {
            const updatedValidators = field.validators.map((validator) => {
                switch (validator) {
                    case 'required':
                        return translation?.errors.required;
                    case 'isPhone':
                        return translation?.errors.phoneInvalid;
                    case 'isFacebookLink':
                        return translation?.errors.facebookLinkInvalid;
                    case 'isLinkedinLink':
                        return translation?.errors.linkedinLinkInvalid;
                    case 'isTwitterLink':
                        return translation?.errors.twitterLinkInvalid;
                    case 'isLink':
                        return translation?.errors.linkInvalid;
                    default:
                        return validator;
                }
            });

            return {
                ...field,
                errorMessages: updatedValidators,
            };
        });

        setDefaultFields({
            ...defaultFields,
            fields: updatedFields,
        });
    };
    //START FORM VALIDATION FIELDS

    const handleFieldChange = useCallback(
        async (fieldName, fieldValue) => {
            const updatedFields = [...defaultFields.fields];
            const updatedFieldIndex = updatedFields.findIndex((field) => field.name === fieldName);
            updatedFields[updatedFieldIndex].value = fieldValue;

            setDefaultFields((prevState) => ({...prevState, fields: updatedFields}));
        },
        [defaultFields]
    );

    const checkFormValidity = async () => {
        await formRef.current?.isFormValid(true);
    };

    useEffect(() => {
        checkFormValidity();
    }, [formRef, defaultFields]);

    const handleChangeInvitationCode = useCallback(async (e) => {
        const {value} = e.target;
        setDefaultFields((prevState) => ({...prevState, invitationCode: value}));
    }, []);

    //END FORM VALIDATION FIELDS
    const handleRegisterApiError = (error) => {
        const errorMessage = error.response.data.error;
        if (errorMessage === 'cannot register while account is still being deleted.') {
            setUnregisterInProgressError(true);
        }
    };

    const handleAgreeToEventPrivacyPolicy = (e) => {
        const {checked} = e.target;
        setAgreeToEventPrivacyPolicy(checked);
        setDisplayAgreeToPrivacyPolicyMessage(!checked);
    };
    const cancelPaymentIntent = () => {
        dispatch(actions.cancelPaymentIntent());
    };
    const selectPackage = (packageId) => () => {
        setSelectedPackageId(packageId);
        setErrorAccessPackageNotSelected(false);
    };
    const registerUser = () => {
        // create user data Object to send with the Api Call
        let userWillBeSent = {};
        userWillBeSent.type = EXHIBITOR_TYPES.scholar;

        defaultFields.fields.forEach((field) => {
            let fieldValue = field.value;
            if (typeof field.value === 'string') {
                fieldValue = field.value?.trim();
            }
            userWillBeSent[field.name] = fieldValue;
        });
        userWillBeSent.invitationCode = defaultFields.invitationCode.trim();

        if (!agreeToEventPrivacyPolicy) {
            setDisplayAgreeToPrivacyPolicyMessage(true);
        } else {
            if (eventInfo.scholarHasAccessManagement) {
                if (selectedPackageId) {
                    const paymentData = {
                        userId: user?.data._id,
                        eventId: eventId,
                        packageId: selectedPackageId,
                    };

                    // fullData is an object containing the user data and payment data
                    const fullData = {...paymentData, ...userWillBeSent};

                    // if the selectedPackage is Free, we should directly register the user
                    const selectedPackageData = eventInfo.exhibitorAccessPackages.find(
                        (accessPackage) => accessPackage._id === selectedPackageId
                    );
                    if (selectedPackageData.isFree) {
                        // automatically register the user
                        userWillBeSent.accessPackage = selectedPackageId;
                        dispatch(actions.registerExhibitor(eventId, userWillBeSent)).then(() =>
                            dispatch(actions.autoLoginWithToken()).catch((error) => handleRegisterApiError(error))
                        );
                    } else {
                        dispatch(actions.createPaymentIntent(fullData));
                    }
                } else {
                    setErrorAccessPackageNotSelected(true);
                }
            } else {
                dispatch(actions.registerExhibitor(eventId, userWillBeSent)).then(() =>
                    dispatch(actions.autoLoginWithToken()).catch((error) => handleRegisterApiError(error))
                );
            }
        }
    };
    const handleRegister = async () => {
        if (!agreeToEventPrivacyPolicy) {
            return setDisplayAgreeToPrivacyPolicyMessage(true);
        }

        //we need to check first if current user email domain is approved by the organizer
        const {data} = await dispatch(actions.checkIfHasRestrictedEmailAccess(eventId, userEmail, true));
        let emailIsRestricted = data.data.data;

        if (emailIsRestricted) {
            return null;
        }

        if (!eventInfo.closedEvent) {
            localStorage.removeItem('showErrorBar');
            localStorage.removeItem('selectedRole');
            localStorage.removeItem('isEmailExist');
            localStorage.removeItem('previousPath');
            localStorage.removeItem('packageIdAndRegisterAs');
            return registerUser();
        }

        //if event is closed
        dispatch(actions.checkInvitationCode(eventId, defaultFields.invitationCode.trim())).then((response) => {
            if (response.isCodeValid) {
                setShowCodeInvalidErrorMessage(false);
                setErrorMessage('');
                localStorage.removeItem('showErrorBar');
                localStorage.removeItem('selectedRole');
                localStorage.removeItem('isEmailExist');
                localStorage.removeItem('previousPath');
                localStorage.removeItem('packageIdAndRegisterAs');
                return registerUser();
            } else {
                let errCode = response.errCode;
                setShowCodeInvalidErrorMessage(true);
                setErrorMessage(setErrorMessageForInvitationCodes(errCode, translation, defaultTranslation));
            }
        });
    };
    const setRegistrationFields = async () => {
        // update the required fields with the settings from the current event
        let updatedFields = [...defaultFields.fields];
        await updatedFields.forEach((field) => {
            const eventInfoField = eventInfo?.scholarPredefinedRegistrationFields[field.name];
            field.isExtraField = false;
            field.label = eventInfoField.label;
            field.label = eventInfoField.label;
            field.isEnabled = eventInfoField.isEnabled;
            field.order = eventInfoField.order;

            if (eventInfoField.isRequired) {
                field.validators.push('required');
                field.errorMessages.push(languages.translations[languages.eventLanguage].errors.required);
            }
        });
        await eventInfo.scholarRegistrationFields.forEach((extraField) => {
            const field = {
                isEnabled: true,
                name: extraField._id,
                type: extraField.type,
                value: '',
                isExtraField: true,
                label: extraField.label,
                multiline: 0,
                validators: extraField.isRequired ? ['required'] : [],
                errorMessages: extraField.isRequired
                    ? [languages.translations[languages.eventLanguage].errors.required]
                    : [],
                options: extraField.options,
            };

            updatedFields.push(field);
        });

        let enabledUpdatedFields = updatedFields.filter((field) => field.isEnabled);
        setDefaultFields({...defaultFields, fields: enabledUpdatedFields});
    };
    const handleEventRoleChecked = (e) => () => {
        e.preventDefault();
    };

    const onErrorHandler = () => {
        localStorage.setItem('showErrorBar', 'true');
        document.getElementById('error-bar')?.classList?.add('show');
        document.getElementById('error-bar')?.classList?.remove('hide');
        if (localStorage.getItem('showErrorBar')) {
            setTimeout(() => {
                const errorBar = document.getElementById('error-bar');
                if (errorBar) {
                    errorBar.classList?.add('hide');
                    errorBar.classList?.remove('show');
                }
                localStorage.removeItem('showErrorBar');
            }, 3000);
        }
        setDefaultFields((prevState) => ({...prevState, hiddenErr: ''}));
        handleOnError();
    };
    //COMPONENT DID MOUNT
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(actions.setEventPublicMenu());
        addValidationRulesScholar();
        dispatch(actions.checkIfHasRestrictedEmailAccess(eventId, userEmail, true));

        // if the event has access management, select a package
        if (eventInfo.scholarHasAccessManagement) {
            const accessPackages = eventInfo.exhibitorAccessPackages.filter(
                (accessPackage) => accessPackage.type === 'scholar'
            );
            if (preselectedAccessPackageId) {
                // check if the package exists and if it is active
                // if it is not active, just reload the page without the packageId in the URL
                // this way we can display all the packages again and also clear the URL

                const selectedPackage = accessPackages.find(
                    (accessPackage) => accessPackage._id === preselectedAccessPackageId
                );
                if (selectedPackage && selectedPackage.isActive) {
                    setSelectedPackageId(preselectedAccessPackageId);
                } else {
                    let redirectLink = `${window.location.origin}${window.location.pathname}`;
                    window.location.replace(redirectLink);
                }
            } else {
                //select the first active && visible package
                const activeVisiblePackages = accessPackages.filter(
                    (accessPackage) => accessPackage.showOnRegistration && accessPackage.isActive
                );
                if (activeVisiblePackages.length > 0) {
                    const firstPackageId = activeVisiblePackages[0]._id;
                    setSelectedPackageId(firstPackageId);
                }
            }
        }
        setRegistrationFields();

        setLanguagesData();

        dispatch(actions.seeSignUpForm());

        document.addEventListener(
            'invalid',
            (e) => {
                e.preventDefault();
            },
            true
        );
        //please do not update deps cause you see yellow lightbulb. It's not a mistake.
        // we want to run this effect only once when the component is mounted
    }, []);

    //END COMPONENT DID MOUNT

    //COMPONENT DID UPDATES

    useEffect(() => {
        const isStripeSecretEmpty = prevStripeSecret.current === '';
        const isStripeSecretSet = stripeSecret !== '';

        if (isStripeSecretEmpty && isStripeSecretSet) {
            setOpenPaymentDialog(true);
        } else if (!isStripeSecretEmpty && !isStripeSecretSet) {
            setOpenPaymentDialog(false);
        }

        prevStripeSecret.current = stripeSecret;
    }, [stripeSecret]);

    useEffect(() => {
        //prevEventLanguage.current is representing the prevProp of eventLanguage.
        //It works like componentDidUpdate(prevProps, prevState) in class components
        if (prevEventLanguage.current !== languages.eventLanguage) {
            setLanguagesData();
            updateFieldsErrorsLanguage();
        }
        prevEventLanguage.current = languages.eventLanguage;
    }, [languages.eventLanguage]);

    //END COMPONENT DID UPDATES

    //COMPONENT WILL UNMOUNT
    useEffect(() => {
        const handleInvalid = (e) => {
            e.preventDefault();
        };
        return () => {
            removeValidationRulesScholar();
            document.removeEventListener('invalid', handleInvalid, true);
        };
    }, []);
    //END COMPONENT WILL UNMOUNT

    useEffect(() => {
        let isKeyboardActive = false;

        const handleResize = () => {
            const {innerHeight, outerHeight} = window;

            if (outerHeight - innerHeight > 200) {
                // The difference between outerHeight and innerHeight indicates the presence of the virtual keyboard
                isKeyboardActive = true;
                setShowBottomStickyButton(false);
            } else if (isKeyboardActive) {
                isKeyboardActive = false;
                setShowBottomStickyButton(true);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let filteredRoles = useMemo(() => availableRoles.filter((role) => role.value === false), [availableRoles]);

    const accessPackages = eventInfo.exhibitorAccessPackages.filter(
        (accessPackage) => accessPackage.type === 'scholar'
    );

    let selectedPackageData = null;

    if (eventInfo.scholarHasAccessManagement) {
        if (selectedPackageId) {
            selectedPackageData = accessPackages.find((accessPackage) => accessPackage._id === selectedPackageId);
        }
    }

    // if the event doesn't support scholars, return NotFound page
    if (!eventInfo.scholarExhibitorsType) {
        return <NotFound />;
    }

    return (
        <>
            <Layout onDragStart={preventDefaultDrag} childrenMargin={'-46px'} childrenPadding={`0 8.5%`}>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${
                        brandingTranslation?.homepageEventName
                            ? brandingTranslation?.homepageEventName
                            : eventInfo.title
                    }: ${
                        translation?.exhibitorRegistration.metaTitle ||
                        defaultTranslation?.exhibitorRegistration.metaTitle
                    }`}</title>
                </Helmet>

                <RegistrationProgressbar stepsStatuses={['completed', 'completed', 'active']} />
                {availableRoles.length === 0 ? (
                    <div className="alert alert-danger" role="alert">
                        {translation.newLoginRegistrations.registrationIsClosed ||
                            defaultTranslation.newLoginRegistrations.registrationIsClosed}{' '}
                    </div>
                ) : (
                    <Roles
                        brandingTranslation={brandingTranslation}
                        availableRoles={filteredRoles}
                        marginTop={'37px'}
                        handleEventRoleChecked={handleEventRoleChecked}
                    />
                )}
                <EventInformation description={brandingTranslation?.scholarsRegDescription} />

                <div className={`full-container-form`} onDragStart={preventDefaultDrag}>
                    <div onDragStart={preventDefaultDrag} />
                    <div onDragStart={preventDefaultDrag}>
                        <div
                            onDragStart={preventDefaultDrag}
                            className={classnames('w-100 d-flex justify-content-center align-items-center', {
                                'lang-ar': langArabic,
                            })}
                        >
                            {eventInfo ? (
                                !hasRestrictedEmailAccess ? (
                                    <div onDragStart={preventDefaultDrag}>
                                        <div onDragStart={preventDefaultDrag} className="right-form ">
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="register-form-wrapper version-2"
                                            >
                                                <ValidatorForm
                                                    ref={formRef}
                                                    onSubmit={handleRegister}
                                                    onError={onErrorHandler}
                                                >
                                                    <div className={`default-fields-and-invitation-code`}>
                                                        {defaultFields.fields
                                                            .filter((filteredField) => !filteredField.isExtraField)
                                                            .sort((a, b) => a.order - b.order)
                                                            .map((field) => {
                                                                if (field.name === 'country') {
                                                                    return (
                                                                        <SelectCountry
                                                                            field={field}
                                                                            handleChange={handleFieldChange}
                                                                            key={field.name}
                                                                        />
                                                                    );
                                                                }
                                                                return (
                                                                    <GeneralTextFieldInfoPointLoginRegistration
                                                                        field={field}
                                                                        isTooltipDisabled={true}
                                                                        handleChange={handleFieldChange}
                                                                        key={field.name}
                                                                        disabled={isButtonDisabled}
                                                                        isLargeScreen={isLargeScreen}
                                                                        placeholderWidthRestrict={302}
                                                                    />
                                                                );
                                                            })}
                                                        {eventInfo?.closedEvent && (
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="invitation-code-wrapper"
                                                            >
                                                                <TextValidator
                                                                    label={
                                                                        translation?.register.invitationCode ||
                                                                        defaultTranslation?.register.invitationCode
                                                                    }
                                                                    placeholder={
                                                                        translation?.register.invitationCode ||
                                                                        defaultTranslation?.register.invitationCode
                                                                    }
                                                                    onChange={handleChangeInvitationCode}
                                                                    name="invitationCode"
                                                                    value={defaultFields.invitationCode}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    className="field-container invitation-code-input"
                                                                    validators={['required']}
                                                                    errorMessages={[
                                                                        `${translation?.errors.noInvitationCode}`,
                                                                    ]}
                                                                />
                                                                {showCodeInvalidErrorMessage && (
                                                                    <p
                                                                        onDragStart={preventDefaultDrag}
                                                                        className="error-message"
                                                                    >
                                                                        {errorMessage}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className={`extra-fields-without-invitation-code`}>
                                                        {defaultFields.fields
                                                            .filter((filteredField) => filteredField.isExtraField)
                                                            .map((field) => {
                                                                if (field.type === 'select') {
                                                                    return (
                                                                        <SelectFieldLoginRegistration
                                                                            field={field}
                                                                            isTooltipDisabled={true}
                                                                            handleChange={handleFieldChange}
                                                                            key={field.name}
                                                                        />
                                                                    );
                                                                }
                                                                if (field.type === 'radio') {
                                                                    return (
                                                                        <RadioFieldLoginRegistration
                                                                            isTooltipDisabled={true}
                                                                            field={field}
                                                                            handleChange={handleFieldChange}
                                                                            key={field.name}
                                                                        />
                                                                    );
                                                                }
                                                                if (field.type === 'checkbox') {
                                                                    return (
                                                                        <CheckboxFieldLoginRegistration
                                                                            isTooltipDisabled={true}
                                                                            field={field}
                                                                            handleChange={handleFieldChange}
                                                                            key={field.name}
                                                                            fieldValues={field.value}
                                                                        />
                                                                    );
                                                                }
                                                                return (
                                                                    <TextFieldInfoPointLoginRegistration
                                                                        isTooltipDisabled={true}
                                                                        field={field}
                                                                        handleChange={handleFieldChange}
                                                                        key={field.name}
                                                                        disabled={isButtonDisabled}
                                                                        isLargeScreen={isLargeScreen}
                                                                        placeholderWidthRestrict={302}
                                                                    />
                                                                );
                                                            })}
                                                    </div>
                                                    <div className={`access-packages-container`}>
                                                        <AccessPackage
                                                            translation={translation}
                                                            eventInfo={eventInfo}
                                                            accessPackages={accessPackages}
                                                            hasAccessManagement={'scholarHasAccessManagement'}
                                                            paidEvent={'scholarPaidEvent'}
                                                            preselectedAccessPackageId={preselectedAccessPackageId}
                                                            selectedPackageId={selectedPackageId}
                                                            selectPackage={selectPackage}
                                                            errorAccessPackageNotSelected={
                                                                errorAccessPackageNotSelected
                                                            }
                                                        />
                                                    </div>
                                                    {isMobile ? (
                                                        showBottomStickyButton && (
                                                            <div
                                                                className={classnames(
                                                                    'agree-terms',
                                                                    'small-margin-top',
                                                                    {
                                                                        'bottom-push': accessPackages.length <= 0,
                                                                        'lang-ar': langArabic,
                                                                    }
                                                                )}
                                                            >
                                                                <div className={'area-protect'}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={agreeToEventPrivacyPolicy}
                                                                                onChange={
                                                                                    handleAgreeToEventPrivacyPolicy
                                                                                }
                                                                                value="agreeToEventPrivacyPolicy"
                                                                            />
                                                                        }
                                                                        label={
                                                                            <span
                                                                                draggable="false"
                                                                                className="agree-terms-message"
                                                                            >
                                                                                {
                                                                                    translation?.exhibitorRegistration
                                                                                        .gdprTextFirst
                                                                                }
                                                                                <Link
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    to={{
                                                                                        pathname: `/event/${eventSlug}/event-privacy-policy/`,
                                                                                        search: `?lang=${languages.eventLanguage}`,
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        {' ' +
                                                                                            translation
                                                                                                ?.exhibitorRegistration
                                                                                                .gdprTextPrivacy}
                                                                                    </span>
                                                                                </Link>
                                                                                {' ' +
                                                                                    translation?.exhibitorRegistration
                                                                                        .gdprTextSecond}
                                                                                <Link
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    to={{
                                                                                        pathname: `/event/${eventSlug}/event-terms-and-conditions/`,
                                                                                        search: `?lang=${languages.eventLanguage}`,
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        {' ' +
                                                                                            translation
                                                                                                ?.exhibitorRegistration
                                                                                                .gdprTextTerms}
                                                                                    </span>
                                                                                </Link>
                                                                                .
                                                                            </span>
                                                                        }
                                                                    />

                                                                    <div
                                                                        onDragStart={preventDefaultDrag}
                                                                        className="buttons-wrapper"
                                                                    >
                                                                        <button
                                                                            type="submit"
                                                                            disabled={
                                                                                eventInfo.restrictScholarRegistration ||
                                                                                loadingInvitationCode ||
                                                                                !agreeToEventPrivacyPolicy
                                                                            }
                                                                            className={classnames({
                                                                                disabled:
                                                                                    eventInfo.restrictScholarRegistration ||
                                                                                    loadingInvitationCode ||
                                                                                    !agreeToEventPrivacyPolicy,
                                                                            })}
                                                                        >
                                                                            {eventInfo.hasAccessManagement ? (
                                                                                <>
                                                                                    {selectedPackageData &&
                                                                                    selectedPackageData.isFree
                                                                                        ? translation
                                                                                              ?.exhibitorRegistration
                                                                                              .registerButtonFree
                                                                                        : translation
                                                                                              ?.exhibitorRegistration
                                                                                              .registerButtonPay}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {eventInfo.restrictScholarRegistration
                                                                                        ? translation?.register
                                                                                              .registerNotAllowed ||
                                                                                          defaultTranslation?.register
                                                                                              .registerNotAllowed
                                                                                        : translation
                                                                                              ?.exhibitorRegistration
                                                                                              .registerButtonFree}
                                                                                </>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div
                                                            className={classnames('agree-terms', 'small-margin-top', {
                                                                'bottom-push': accessPackages.length <= 0,
                                                                'lang-ar': langArabic,
                                                            })}
                                                        >
                                                            <div className={'area-protect'}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={agreeToEventPrivacyPolicy}
                                                                            onChange={handleAgreeToEventPrivacyPolicy}
                                                                            value="agreeToEventPrivacyPolicy"
                                                                        />
                                                                    }
                                                                    label={
                                                                        <span
                                                                            draggable="false"
                                                                            className="agree-terms-message"
                                                                        >
                                                                            {
                                                                                translation?.exhibitorRegistration
                                                                                    .gdprTextFirst
                                                                            }
                                                                            <Link
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                to={{
                                                                                    pathname: `/event/${eventSlug}/event-privacy-policy/`,
                                                                                    search: `?lang=${languages.eventLanguage}`,
                                                                                }}
                                                                            >
                                                                                <span>
                                                                                    {' ' +
                                                                                        translation
                                                                                            ?.exhibitorRegistration
                                                                                            .gdprTextPrivacy}
                                                                                </span>
                                                                            </Link>
                                                                            {' ' +
                                                                                translation?.exhibitorRegistration
                                                                                    .gdprTextSecond}
                                                                            <Link
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                to={{
                                                                                    pathname: `/event/${eventSlug}/event-terms-and-conditions/`,
                                                                                    search: `?lang=${languages.eventLanguage}`,
                                                                                }}
                                                                            >
                                                                                <span>
                                                                                    {' ' +
                                                                                        translation
                                                                                            ?.exhibitorRegistration
                                                                                            .gdprTextTerms}
                                                                                </span>
                                                                            </Link>
                                                                            .
                                                                        </span>
                                                                    }
                                                                />

                                                                <div
                                                                    onDragStart={preventDefaultDrag}
                                                                    className="buttons-wrapper"
                                                                >
                                                                    <button
                                                                        type="submit"
                                                                        disabled={
                                                                            eventInfo.restrictScholarRegistration ||
                                                                            loadingInvitationCode ||
                                                                            !agreeToEventPrivacyPolicy
                                                                        }
                                                                        className={classnames({
                                                                            disabled:
                                                                                eventInfo.restrictScholarRegistration ||
                                                                                loadingInvitationCode ||
                                                                                !agreeToEventPrivacyPolicy,
                                                                        })}
                                                                    >
                                                                        {eventInfo.hasAccessManagement ? (
                                                                            <>
                                                                                {selectedPackageData &&
                                                                                selectedPackageData.isFree
                                                                                    ? translation?.exhibitorRegistration
                                                                                          .registerButtonFree
                                                                                    : translation?.exhibitorRegistration
                                                                                          .registerButtonPay}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {eventInfo.restrictScholarRegistration
                                                                                    ? translation?.register
                                                                                          .registerNotAllowed ||
                                                                                      defaultTranslation?.register
                                                                                          .registerNotAllowed
                                                                                    : translation?.exhibitorRegistration
                                                                                          .registerButtonFree}
                                                                            </>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {unregisterInProgressError && (
                                                        <p
                                                            onDragStart={preventDefaultDrag}
                                                            className="error-text unregister-in-progress-error"
                                                        >
                                                            {translation?.register.unregisterInProgressError ||
                                                                defaultTranslation.register.unregisterInProgressError}
                                                        </p>
                                                    )}
                                                </ValidatorForm>
                                            </div>
                                            {(loadingUser || (loadingEvent && eventInfo)) && <Spinner />}
                                            {isMobile && <RegisterMenuLinks />}
                                        </div>
                                    </div>
                                ) : (
                                    <CantJoinEventDialog participantType={true} />
                                )
                            ) : null}
                        </div>
                    </div>
                    {openPaymentDialog && (
                        <StripePaymentDialog
                            open={openPaymentDialog}
                            onClose={cancelPaymentIntent}
                            stripeSecret={stripeSecret}
                            organizerStripeAccountId={eventInfo.owner.stripeConnect?.connectedAccountId}
                            selectedPackageData={selectedPackageData}
                            currency={getCurrencySymbol(eventInfo.currency)}
                        />
                    )}
                </div>
            </Layout>
        </>
    );
};
export default RegisterAsScholar;
