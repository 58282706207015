import React from 'react';
import '../CSS/headerMenu.scss';
import {isIE, isYandex} from 'react-device-detect';
import RedirectOnLogin from '../HOC/RedirectOnLogin';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import PlatformMenu from './PlatformMenu';
import HeaderMenu from './HeaderMenu';
import EventMenu from './EventMenu';
import AdminMenu from './AdminMenu';
import {
    connectToNotifications,
    disconnectFromNotifications,
    notificationReceived,
    flashNotificationReceived,
    sessionUpdated,
} from 'Api/socketApi';
import FullOverlay from 'SmallLayoutComponents/FullOverlay';
import {preventDefaultDrag} from 'Utils/utils';
import SessionGuestMenu from './SessionGuestMenu';
import AnnouncementsBannerOrIcon from 'Components/AnnouncementsBanner/AnnouncementsBannerOrIcon';
import {announcementsUpdated, deregisterAnnouncements} from 'Api/socketApi';
import {setAnnouncementsBannerOpen} from 'store/actions/announcementsActions';
import './MenuStyles.scss';
import SnackbarGlobal from '../SmallLayoutComponents/Snackbars/SnackbarGlobal';

class Menu extends React.Component {
    eventReloadTimer = null;
    clearCacheAndReloadAppTimer = null;

    state = {
        snackbar: {
            message: 'You have been removed from the group chat.',
            open: false,
        },
        showRemovedFromEventDialog: false,
        announcementsCollapsed: true,
        filteredAnnouncements: [],
        location: 'other',
        userDidLogIn: true,
        eventChanged: true,
    };

    componentDidMount() {
        // always connect to notifications at platform level
        localStorage.setItem('triggeredEventReload', 'false');
        notificationReceived((err, data) => {
            const notification = data.notification;

            if (notification.type === 'clearCacheAndReloadApp') {
                this.clearCacheAndReloadAppNotification();
            } else if (notification.type === 'reloadConsentCollection') {
                this.reloadConsentCollection();
            } else if (notification.type === 'reloadEventData') {
                this.reloadEventDataNotification(notification);
            } else if (notification.type === 'reloadExhibitorData') {
                this.reloadExhibitorDataNotification(notification);
            } else if (notification.type === 'newPrivateChatMessage' || notification.type === 'newGroupChatMessage') {
                if (notification.type === 'newPrivateChatMessage') {
                    this.newPrivateChatMessageNotification(data);
                } else {
                    this.newGroupChatMessageNotification(data);
                }
            } else if (notification.type === 'removedFromGroupChat') {
                this.removedFromGroupChatNotification(notification);
            } else if (notification.type === 'removedFromEvent') {
                this.removedFromEventNotification(notification);
            } else if (
                notification.type === 'eventParticipantProfileCreated' ||
                notification.type === 'eventExhibitorProfileCreated'
            ) {
                // after the payment was completed
                // we listen for the server notification that informs us that the payment was completed
                // and the profile was created
                this.props.onRefreshUserUserData();
            } else {
                this.props.onNewNotification(notification);
            }
        });

        flashNotificationReceived((err, data) => {
            this.props.onFlashNotificationReceived(data);
        });

        sessionUpdated((err, data) => {
            // whenever the Organizer changes something to the session we receive a socket notification
            // Organizer starts/stops the sessionStream
            // Organizer toggles the canRequestMic option
            // so that I can update in Redux the timeslot=>Session=>isActive (true/false)
            this.props.onUpdateTimeslotSession(data);
        });

        this.updateLocation();
        announcementsUpdated((data) => {
            const newAnnouncements = data.announcements.payload;
            this.props.onGetRunningAnnouncementsSuccess(newAnnouncements);
            // runningAnnouncements are updated by redux -> filteredAnnouncements to be updated
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // we always connect the user to notifications AFTER HE LOGGED IN
        // WE CONNECT HIM ONLY ONCE
        if (prevProps.loggedIn === false && this.props.loggedIn === true) {
            if (!this.props.userConnectedToNotifications) {
                connectToNotifications(this.props.user._id);
                this.props.onSetUserConnectedToNotifications();
            }
        }
        const brandingPrimaryColor = this.props.eventInfo?.brandingData?.primaryColor;
        if (brandingPrimaryColor) {
            document.documentElement.style.setProperty('--li-background-color', `${brandingPrimaryColor}`);
        } else {
            document.documentElement.style.setProperty('--li-background-color', `#208918`);
        }

        // if the user switched to a new event and someone modified something to the old event, we clear the eventReloadTimer
        // so that we make sure that when he gets to the new event we will not get the old event data
        if (prevProps.eventId !== this.props.eventId && this.eventReloadTimer) {
            localStorage.setItem('triggeredEventReload', 'false');
            clearTimeout(this.eventReloadTimer);
        }

        this.updateLocation(prevProps);

        const userDidLogIn = this.props.loggedIn === true && prevProps.loggedIn === false;
        if (userDidLogIn) {
            this.setState({userDidLogIn: true});
        }
        const eventChanged = this.props.eventId !== prevProps.eventId;
        if (eventChanged) {
            this.setState({eventChanged: true, filteredAnnouncements: []});
        }

        if (this.state.userDidLogIn || this.state.eventChanged) {
            this.getAnnouncements(prevProps, prevState);
        }

        const announcementsChange = this.props.runningAnnouncements !== prevProps.runningAnnouncements;
        if (announcementsChange) {
            this.filterAnnouncements(false);
        }
    }

    componentWillUnmount() {
        if (this.clearCacheAndReloadAppTimer) {
            clearTimeout(this.clearCacheAndReloadAppTimer);
        }
        if (this.eventReloadTimer) {
            localStorage.setItem('triggeredEventReload', 'false');
            clearTimeout(this.eventReloadTimer);
        }
        deregisterAnnouncements();
    }

    updateLocation = (prevProps = null) => {
        const thisLocation = this.props.location.pathname;
        if (thisLocation !== prevProps?.location?.pathname || prevProps === null) {
            if (thisLocation.includes('dashboard')) {
                this.setState({location: 'other'});
                return;
            }
            if (thisLocation.includes('lobby')) {
                this.setState({location: 'lobby'});
                this.filterAnnouncements(true, 'lobby');
                return;
            }
            if (thisLocation.includes('auditorium')) {
                this.setState({location: 'auditoriums'});
                this.filterAnnouncements(true, 'auditoriums');
                return;
            }
            if (thisLocation.includes('showfloor')) {
                this.setState({location: 'exhibitHalls'});
                this.filterAnnouncements(true, 'exhibitHalls');
                return;
            }
            if (thisLocation.includes('event-archive')) {
                this.setState({location: 'archive'});
                this.filterAnnouncements(true, 'archive');
                return;
            }
            this.setState({location: 'other'});
        }
    };

    getAnnouncements = (prevProps, prevState) => {
        // because eventId changes before all event data, from where we get announcementsEnabled
        const eventDataFinishedLoading = this.props.eventId && this.props.event?._id === this.props.eventId;

        if (eventDataFinishedLoading && this.props.announcementsEnabled) {
            this.props.onGetRunningAnnouncements(this.props.eventId);
            this.setState({eventChanged: false, userDidLogIn: false});
        }
    };

    filterAnnouncements = (locationChanged, location = this.state.location) => {
        if (this.props.announcementsEnabled === false) {
            this.setState({filteredAnnouncements: []});
            return;
        }
        const newFilteredAnnouncements = [];

        this.props.runningAnnouncements?.forEach((announcement) => {
            if (announcement?.areasOfDisplay.includes(location)) {
                newFilteredAnnouncements.push(announcement);
            }
        });
        this.setState({filteredAnnouncements: newFilteredAnnouncements});
        // open banner when there is a new announcement
        if (!locationChanged && newFilteredAnnouncements.length > this.state.filteredAnnouncements.length) {
            this.openBanner();
        }
    };

    clearCacheAndReloadAppNotification = () => {
        // set a timeout in milliseconds (1 ms  ...  2 minutes)
        let randomIntervalInMilliseconds = Math.floor(Math.random() * (120000 - 1 + 1)) + 1;
        this.clearCacheAndReloadAppTimer = setTimeout(() => window.location.reload(true), randomIntervalInMilliseconds);
    };

    reloadConsentCollection = () => {
        // reload the page only if we are not on the /consent-collection page
        if (this.props.location.pathname === '/consent-collection') {
            return null;
        }
        window.location.reload(); // reload the page
    };

    reloadEventDataNotification = (notification) => {
        const {eventId} = this.props;
        // this.props.onGetEvent(eventId);
        // set a timeout in milliseconds (1 ms  ...  2 minutes)
        if (notification.eventId === eventId) {
            let triggeredEventRefresh = localStorage.getItem('triggeredEventReload');
            if (triggeredEventRefresh !== 'true') {
                localStorage.setItem('triggeredEventReload', 'true');
                let randomIntervalInMilliseconds = Math.floor(Math.random() * (120000 - 1 + 1)) + 1;
                this.eventReloadTimer = setTimeout(this.reloadEventData, randomIntervalInMilliseconds);
            }
        }
    };

    reloadExhibitorDataNotification = (notification) => {
        if (notification.exhibitorId === this.props.activeExhibitorId) {
            this.props.onGetExhibitor(this.props.activeExhibitorId);
        }
    };

    newPrivateChatMessageNotification = (data) => {
        // if we have the private chat, we update the last message of that chat
        // if we don't have the private chat, it means it is a newly created chat that we don't have
        // and we refresh all the user chats
        // we do the same for the group chats
        let privateChatExists = this.props.user.privateChats.find(
            (chat) => chat.chat._id === data.notification.objectId
        );
        if (privateChatExists) {
            this.props.newPrivateChatMessage(data);
        } else {
            this.props.onGetPrivateChats();
        }
    };

    newGroupChatMessageNotification = (data) => {
        let groupChatExists = this.props.user.groupChats.find((chat) => chat.chat._id === data.notification.objectId);
        if (groupChatExists) {
            this.props.newGroupChatMessage(data);
        } else {
            this.props.onGetGroupChats();
        }
    };

    removedFromGroupChatNotification = (notification) => {
        if (notification.objectId === this.props.activeChatRoomId) {
            this.props.onCloseTopNav();
            // show a snackbar to inform the user he has been removed from the group chat
            // Benji request: do not show snackbar notification, kept the rest of the functionality
            // this.setState({snackbar: {...this.state.snackbar, open: true}});
            this.props.onGetGroupChats();
        } else {
            this.props.onGetGroupChats();
        }
    };

    removedFromEventNotification = (notification) => {
        if (notification.eventId === this.props.eventId && this.props.event.owner._id !== this.props.user._id) {
            this.setState({showRemovedFromEventDialog: true});
        } else {
            this.props.onRefreshUserUserData();
        }
    };

    reloadEventData = () => {
        const {eventId} = this.props;
        localStorage.setItem('triggeredEventReload', 'false');
        this.props.onReloadEventData(eventId);
    };

    handleCloseSnackbar = () => {
        this.setState({snackbar: {...this.state.snackbar, open: false}});
    };

    handleLogoutUser = () => {
        // if user logged out we should also disconnect him from notifications
        this.setState({showRemovedFromEventDialog: false});
        disconnectFromNotifications(this.props.user._id);
        this.props.onLogoutUser();
    };

    handleCloseModal = () => {
        this.props.onRefreshUserUserData();
        this.setState({showRemovedFromEventDialog: false});
    };

    collapseBanner = () => {
        this.props.onSetAnnouncementsBannerOpen(false);
        this.setState({announcementsCollapsed: true});
    };

    openBanner = () => {
        this.props.onSetAnnouncementsBannerOpen(true);
        this.setState({announcementsCollapsed: false});
    };

    render() {
        const {layout, loggedIn, eventId, event, location} = this.props;
        if (layout.menu.noMenu) {
            return null;
        }
        const isLoginOrRegisterPage =
            location.pathname.includes('/login') ||
            location.pathname.includes('/register') ||
            location.pathname.includes('/password-recovery') ||
            location.pathname.includes('/select-role') ||
            location.pathname.includes('/participant-registration') ||
            location.pathname.includes('/exhibitor-registration') ||
            location.pathname.includes('/scholar-registration');

        return (
            <div onDragStart={preventDefaultDrag} className={`menu ${isLoginOrRegisterPage ? 'hidden' : ''}`}>
                {layout.menu.platformMenu && <PlatformMenu />}
                {layout.menu.eventPublicMenu && <HeaderMenu />}
                {layout.menu.eventProtectedMenu && loggedIn && eventId && event && !isYandex && !isIE && <EventMenu />}
                {layout.menu.adminMenu && loggedIn && <AdminMenu />}
                {layout.menu.sessionGuestMenu && <SessionGuestMenu />}
                <RedirectOnLogin />
                {/*<CookieBanner />*/}
                <SnackbarGlobal
                    snackbarOpen={this.state.snackbar.open}
                    handleCloseSnackbar={this.handleCloseSnackbar}
                    message={this.state.snackbar.message}
                />

                {this.state.showRemovedFromEventDialog && (
                    <FullOverlay
                        title="Event is unavailable"
                        text={'You have been removed from this event.'}
                        buttonText="Log out"
                        closeOnRedirect={true}
                        handleClose={this.handleCloseModal}
                        handleClick={this.handleLogoutUser}
                    />
                )}
                {layout.isLargeScreen && (
                    <AnnouncementsBannerOrIcon
                        announcements={this.state.filteredAnnouncements}
                        isCollapsed={this.state.announcementsCollapsed}
                        handleOpen={this.openBanner}
                        handleCollapse={this.collapseBanner}
                        location={this.state.location}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        layout: state.layout,
        eventId: state.event.eventId,
        announcementsEnabled: state.event?.data?.announcementsEnabled,
        event: state.event.data,
        userConnectedToNotifications: state.user.connectedToNotifications,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        eventInfo: state.event.eventInfo,
        runningAnnouncements: state.announcements.runningAnnouncements,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserConnectedToNotifications: () => dispatch(actions.setUserConnectedToNotifications()),
        onNewNotification: (notification) => dispatch(actions.newNotification(notification)),
        onFlashNotificationReceived: (notification) => dispatch(actions.newFlashNotification(notification)),
        newPrivateChatMessage: (data) => dispatch(actions.newPrivateChatMessage(data)),
        newGroupChatMessage: (data) => dispatch(actions.newGroupChatMessage(data)),
        onGetPrivateChats: () => dispatch(actions.getPrivateChats()),
        onGetGroupChats: () => dispatch(actions.getGroupChats()),
        onReloadEventData: (eventId) => dispatch(actions.reloadEventData(eventId)),
        onCloseTopNav: () => dispatch(actions.topNavClose()),
        onRefreshUserUserData: () => dispatch(actions.refreshUserData()),
        onLogoutUser: () => dispatch(actions.logoutUser()),
        onGetExhibitor: (exhibitorId) => dispatch(actions.getExhibitor(exhibitorId)),
        onUpdateTimeslotSession: (data) => dispatch(actions.updateTimeslotSession(data)),
        onGetRunningAnnouncements: (eventId) => dispatch(actions.getRunningAnnouncements(eventId)),
        onGetRunningAnnouncementsSuccess: (newAnnouncements) =>
            dispatch(actions.getRunningAnnouncementsSuccess(newAnnouncements)),
        announcementsUpdated: () => dispatch(announcementsUpdated()),
        onSetAnnouncementsBannerOpen: (open) => dispatch(setAnnouncementsBannerOpen(open)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
