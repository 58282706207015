import React, {Component} from 'react';
import RectangleGreen from '../Images/pages/platform-homepage/animations/rectangle-green.png';
import RectangleHighlight from '../Images/pages/platform-homepage/animations/rectangle-highlight.png';

class HighlightRectangleText extends Component {
    constructor(props) {
        super(props);
        this.textRef = React.createRef();
    }
    setHoverBackground = (e) => {
        this.textRef.current.style.backgroundImage = `url(${RectangleHighlight})`;
    };

    setOriginalBackground = (e) => {
        this.textRef.current.style.backgroundImage = `url(${RectangleGreen})`;
    };

    render() {
        return (
            <span
                style={{backgroundImage: `url(${RectangleGreen})`}}
                ref={this.textRef}
                onMouseOver={this.setHoverBackground}
                onMouseOut={this.setOriginalBackground}
                className="highlight-rectangle-background"
            >
                {this.props.children}
            </span>
        );
    }
}

export default HighlightRectangleText;
