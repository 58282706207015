import React, {PureComponent} from 'react';
import Button from '@material-ui/core/Button';
import DeleteMyAccount from '../../Dialogs/MyAccount/DeleteMyAccount';

class DeleteAccount extends PureComponent {
    state = {
        deleteMyAccountDialog: false,
    };

    handleDeleteUser = () => {
        this.setState({deleteMyAccountDialog: true});
    };

    handleCloseDialog = () => {
        this.setState({deleteMyAccountDialog: false});
    };

    render() {
        const {deleteMyAccountDialog} = this.state;
        const {translation} = this.props;
        return (
            <>
                <Button
                    onClick={this.handleDeleteUser}
                    type="button"
                    variant="text"
                    className={'error-text change-password-btn'}
                >
                    {translation?.myAccountPage.deleteAccountButton}
                </Button>
                {this.state.deleteMyAccountDialog && (
                    <DeleteMyAccount opened={deleteMyAccountDialog} closeDialog={this.handleCloseDialog} />
                )}
            </>
        );
    }
}

export default DeleteAccount;
