import React, {forwardRef} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import {preventDefaultDrag} from '../../../../../Utils/utils';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
const SpeakerPhoto = forwardRef(({
    uploadFileHandler,
    speaker,
    eventId,
    deleteFileHandler,
    readyForUploadHandler,
    imageLogoPreviewUrl,
    newLogoFile,
    imageLogoErrorText,
}, ref) => {
    return (
        <>
            <h3 className="form-h speaker-image">SPEAKER'S IMAGE</h3>
            <div onDragStart={preventDefaultDrag} ref={ref} className="options-container speaker-img-container">
                <div onDragStart={preventDefaultDrag} className="single-option-container speaker-img">
                    <div onDragStart={preventDefaultDrag} className="actions-container speaker-helper">
                        {newLogoFile && (
                            <Button
                                type="button"
                                onClick={uploadFileHandler}
                                disabled={!imageLogoPreviewUrl || imageLogoErrorText.length > 0}
                                startIcon={<SaveOutlinedIcon />}
                                variant="contained"
                                color={'secondary'}
                                disableElevation
                            >
                                Save image
                            </Button>
                        )}
                        {imageLogoPreviewUrl ? (
                            <Button
                                type="button"
                                variant="outlined"
                                component="label"
                                startIcon={<EditOutlinedIcon />}
                            >
                                Edit
                                <input
                                    type="file"
                                    onChange={readyForUploadHandler}
                                    className="upload-image-button d-none"
                                    hidden
                                />
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                variant="outlined"
                                component="label"
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload image
                                <input
                                    type="file"
                                    onChange={readyForUploadHandler}
                                    className="upload-image-button d-none"
                                    hidden
                                />
                            </Button>
                        )}

                        {speaker?.image && !newLogoFile && (
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={deleteFileHandler}
                                startIcon={<DeleteOutlineOutlinedIcon />}
                            >
                                Remove
                            </Button>
                        )
                        }
                    </div>
                    <div onDragStart={preventDefaultDrag} className="manage-partner-logo">
                        <div onDragStart={preventDefaultDrag} className="logo-container">
                            <div onDragStart={preventDefaultDrag} className="image-wrapper">
                                {speaker?.image ? (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="current-banner-container cover-img-container speaker-prop"
                                    >
                                        {imageLogoPreviewUrl ? (
                                            <img
                                                draggable="false"
                                                onDragStart={preventDefaultDrag}
                                                src={imageLogoPreviewUrl}
                                                alt="preview"
                                            />
                                        ) : (
                                            <img
                                                draggable="false"
                                                onDragStart={preventDefaultDrag}
                                                src={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${speaker.image}`}
                                                alt="logo"
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="current-banner-container cover-img-container speaker-prop"
                                    >
                                        {imageLogoPreviewUrl ? (
                                            <img
                                                draggable="false"
                                                onDragStart={preventDefaultDrag}
                                                src={imageLogoPreviewUrl}
                                                alt="preview"
                                            />
                                        ) : (
                                            <label
                                                htmlFor={'logoUpload' + speaker && speaker?._id}
                                                className="upload-label"
                                            >
                                                <PublishIcon />
                                                <span>Click here to upload</span>
                                            </label>
                                        )}
                                    </div>
                                )}
                            </div>
                            <Grid item xs={12}>
                                <input
                                    id={'logoUpload' + speaker && speaker?._id}
                                    type="file"
                                    onChange={readyForUploadHandler}
                                    className="upload-image-button d-none"
                                />
                            </Grid>
                        </div>
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="input-description prop-speakers">
                    <p className="image-explain def-prop-img">Recommended image ratio 1:1 (e.g. 100 x 100 pixels)</p>
                    <p onDragStart={preventDefaultDrag} className="image-status-container">
                        <span>
                            {newLogoFile || (imageLogoErrorText.length > 0 && speaker?.image) ? (
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="form-upload-picture"
                                >
                                    Uploaded:{' '}
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="secondary-color"
                                    >
                                        {imageLogoErrorText.length > 0 ? (
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="error-light"
                                            >
                                                error
                                            </span>
                                        ) : (
                                            newLogoFile.name
                                        )}
                                    </span>
                                </span>
                            ) : null}
                            {!newLogoFile && !speaker?.image ? (
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="form-upload-picture"
                                >
                                    Uploaded:{' '}
                                    {imageLogoErrorText.length > 0 ? (
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="error-light"
                                        >
                                            error
                                        </span>
                                    ) : (
                                        <span onDragStart={preventDefaultDrag} draggable="false" className="grey-color">
                                            no image
                                        </span>
                                    )}
                                </span>
                            ) : null}
                            <span onDragStart={preventDefaultDrag} draggable="false" className="error-message">
                                {imageLogoErrorText}
                            </span>
                        </span>
                    </p>
                </div>
            </div>
        </>
    );
});

export default SpeakerPhoto;
