import Layout from '../../components/Layout';
import PageHeadline from '../../components/PageHeadline';
import RegistrationForm from '../../components/Forms/RegistrationForm';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

const RegisterPlatform = () => {
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const [showEmailValidationSection, setShowEmailValidationSection] = useState(false);
    const [verificationEmail, setVerificationEmail] = useState('');

    const handleEmailValidation = () => {
        setShowEmailValidationSection(true);
    };

    const handleVerificationEmail = (email) => {
        setVerificationEmail(email);
    };

    const pageTitles = [
        {
            title:
                translation.newLoginRegistrations.seemsLikeYouDontHaveMyOnventAccount ||
                defaultTranslation.newLoginRegistrations.seemsLikeYouDontHaveMyOnventAccount,
            subject:
                translation.newLoginRegistrations.createPlatformAccount ||
                defaultTranslation.newLoginRegistrations.createPlatformAccount,
        },
        {
            title:
                translation.newLoginRegistrations.weHaveSentAVerificationCodeToYourEmail ||
                defaultTranslation.newLoginRegistrations.weHaveSentAVerificationCodeToYourEmail,
            subject: '',
        },
    ];

    return (
        <Layout childrenPadding={`0 11.116%`}>
            <PageHeadline
                containerWidth={`464px`}
                containerMarginTop={`-87px`}
                heading={pageTitles[showEmailValidationSection ? 1 : 0].title}
                subHeading={showEmailValidationSection ? verificationEmail : pageTitles[0].subject}
            />
            <RegistrationForm
                showEmailValidationSection={showEmailValidationSection}
                handleEmailValidation={handleEmailValidation}
                handleVerificationEmail={handleVerificationEmail}
            />
        </Layout>
    );
};

export default RegisterPlatform;
