import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LinkedinLoginIcon from '../Images/svg/linkedin-login.svg';
import FacebookLoginIcon from '../Images/svg/facebook-login.svg';
import GoogleLoginIcon from '../Images/svg/google-login.svg';
import TwitterLoginIcon from '../Images/svg/twitter-login.svg';
import {connect} from 'react-redux';
import {preventDefaultDrag} from 'Utils/utils';

class SocialNetworkButtons extends React.Component {
    openLoginLink = (platform) => (e) => {
        const {eventId, registerAsExhibitor, registerAsScholar, registerAsParticipant, preselectedAccessPackageId} =
            this.props;
        const link = `${process.env.REACT_APP_REQUESTS_API}/auth/${platform}?eventId=${eventId}&registerAsExhibitor=${registerAsExhibitor}&registerAsScholar=${registerAsScholar}&registerAsParticipant=${registerAsParticipant}&packageId=${preselectedAccessPackageId}`;
        /*
        window.location.href = link;
*/
        console.log(link);
    };

    socialButtonsText = () => {
        const {translation, logInTab, defaultTranslation} = this.props;
        let title;
        let linkedInButtonText;
        let facebookButtonText;
        let googleButtonText;
        let twitterButtonText;
        let orText;

        if (logInTab) {
            title =
                translation?.socialButtons.socialButtonsLogInTitle ||
                defaultTranslation?.socialButtons.socialButtonsLogInTitle;
            linkedInButtonText =
                translation?.socialButtons.loginTabLinkedinButton ||
                defaultTranslation?.socialButtons.loginTabLinkedinButton;
            facebookButtonText =
                translation?.socialButtons.loginTabFacebookButton ||
                defaultTranslation?.socialButtons.loginTabFacebookButton;
            googleButtonText =
                translation?.socialButtons.loginTabGoogleButton ||
                defaultTranslation?.socialButtons.loginTabGoogleButton;
            twitterButtonText =
                translation?.socialButtons.loginTabTwitterButton ||
                defaultTranslation?.socialButtons.loginTabTwitterButton;
            orText = translation?.login.orLogInWithEmail || defaultTranslation?.login.orLogInWithEmail;
        } else {
            title =
                translation?.socialButtons.socialButtonsSignUpTitle ||
                defaultTranslation?.socialButtons.socialButtonsSignUpTitle;
            linkedInButtonText =
                translation?.login.loginButton ||
                defaultTranslation?.login.loginButton + translation?.socialButtons.linkedinButton ||
                defaultTranslation?.socialButtons.linkedinButton;
            facebookButtonText =
                translation?.login.loginButton ||
                defaultTranslation?.login.loginButton + translation?.socialButtons.facebookButton ||
                defaultTranslation?.socialButtons.facebookButton;
            googleButtonText =
                translation?.login.loginButton ||
                defaultTranslation?.login.loginButton + translation?.socialButtons.googleButton ||
                defaultTranslation?.socialButtons.googleButton;
            twitterButtonText =
                translation?.login.loginButton ||
                defaultTranslation?.login.loginButton + translation?.socialButtons.twitterButton ||
                defaultTranslation?.socialButtons.twitterButton;
            orText = translation?.register.orFillInTheForm || defaultTranslation?.register.orFillInTheForm;
        }

        return {title, linkedInButtonText, facebookButtonText, googleButtonText, twitterButtonText, orText};
    };

    render() {
        const {disableButtons, isMobile} = this.props;
        const {title, linkedInButtonText, facebookButtonText, googleButtonText, twitterButtonText, orText} =
            this.socialButtonsText();

        return (
            <div onDragStart={preventDefaultDrag} className="social-buttons-container">
                <p onDragStart={preventDefaultDrag} className="social-title">
                    {title}
                </p>

                {this.props.logInTab && !isMobile ? (
                    <Grid item xs={12}>
                        <Button
                            variant={'outlined'}
                            disabled={disableButtons}
                            startIcon={
                                <img
                                    draggable="false"
                                    onDragStart={preventDefaultDrag}
                                    src={LinkedinLoginIcon}
                                    width="13"
                                    height="26"
                                    alt="Linkedin logo"
                                />
                            }
                            disableElevation
                            className={'social-btn linkedin-button'}
                            onClick={this.openLoginLink('linkedin')}
                        >
                            {linkedInButtonText}
                        </Button>

                        <Button
                            variant={'outlined'}
                            disabled={disableButtons}
                            startIcon={
                                <img
                                    draggable="false"
                                    onDragStart={preventDefaultDrag}
                                    src={FacebookLoginIcon}
                                    width="13"
                                    height="26"
                                    alt="Facebook logo"
                                />
                            }
                            disableElevation
                            className={'social-btn facebook-button'}
                            onClick={this.openLoginLink('facebook')}
                        >
                            {facebookButtonText}
                        </Button>

                        <Button
                            variant={'outlined'}
                            disabled={disableButtons}
                            startIcon={
                                <img
                                    draggable="false"
                                    onDragStart={preventDefaultDrag}
                                    src={GoogleLoginIcon}
                                    width="26"
                                    height="26"
                                    alt="Google logo"
                                />
                            }
                            disableElevation
                            className={'social-btn google-button text-social-btn'}
                            onClick={this.openLoginLink('google')}
                        >
                            {googleButtonText}
                        </Button>

                        <Button
                            variant={'outlined'}
                            disabled={disableButtons}
                            startIcon={
                                <img
                                    draggable="false"
                                    onDragStart={preventDefaultDrag}
                                    src={TwitterLoginIcon}
                                    width="26"
                                    height="26"
                                    alt="Google logo"
                                />
                            }
                            disableElevation
                            className={'social-btn twitter-button'}
                            onClick={this.openLoginLink('twitter')}
                        >
                            {twitterButtonText}
                        </Button>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Button
                            variant={'outlined'}
                            disabled={disableButtons}
                            disableElevation
                            className={'social-btn linkedin-button'}
                            size={'large'}
                            onClick={this.openLoginLink('linkedin')}
                        >
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={LinkedinLoginIcon}
                                width="26"
                                height="26"
                                alt="Linkedin logo"
                            />
                        </Button>

                        <Button
                            variant={'outlined'}
                            disabled={disableButtons}
                            disableElevation
                            className={'social-btn facebook-button'}
                            size={'large'}
                            onClick={this.openLoginLink('facebook')}
                        >
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={FacebookLoginIcon}
                                width="13"
                                height="26"
                                alt="Facebook logo"
                            />
                        </Button>

                        <Button
                            variant={'outlined'}
                            disabled={disableButtons}
                            disableElevation
                            className={'social-btn google-button'}
                            size={'large'}
                            onClick={this.openLoginLink('google')}
                        >
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={GoogleLoginIcon}
                                width="26"
                                height="26"
                                alt="Google logo"
                            />
                        </Button>

                        <Button
                            variant={'outlined'}
                            disabled={disableButtons}
                            disableElevation
                            className={'social-btn twitter-button'}
                            size={'large'}
                            onClick={this.openLoginLink('twitter')}
                        >
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={TwitterLoginIcon}
                                width="26"
                                height="26"
                                alt="Google logo"
                            />
                        </Button>
                    </Grid>
                )}

                <div onDragStart={preventDefaultDrag} className="or-text">
                    <span className="or-text-middle">{orText}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        preselectedAccessPackageId: state.user.preselectedAccessPackageId,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
        isMobile: state.layout.isMobile,
    };
};

export default connect(mapStateToProps)(SocialNetworkButtons);
