import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {useState, useCallback} from 'react';

const TextWithTooltipOnOverflow = ({text, tooltipClasses = '', textClasses = ''}) => {
    const [textEllipsis, setTextEllipsis] = useState(false);

    const textRef = useCallback((node) => {
        if (!node) return false;
        const isActive = node?.offsetWidth < node?.scrollWidth;
        setTextEllipsis(isActive);
    }, []);

    return (
        <Tooltip arrow className={tooltipClasses} title={textEllipsis ? text : ''}>
            <span className={`ellipsis ${textClasses}`} ref={textRef}>
                {text}
            </span>
        </Tooltip>
    );
};

export default TextWithTooltipOnOverflow;
