import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {TextValidator} from 'react-material-ui-form-validator';

const CheckboxField = ({field, fieldValues, handleChange}) => {
    const handleCheckboxChange = (e) => {
        let updatedOptions = [...field.value];

        if (e.target.checked) {
            updatedOptions.push(e.target.value);
        } else {
            updatedOptions = updatedOptions.filter((option) => option !== e.target.value);
        }

        handleChange(field.name, updatedOptions);
    };

    const options = field.options.map((option) => {
        let checked = false;
        if (fieldValues.includes(option)) {
            checked = true;
        }
        return (
            <div className={`input-wrapper ${option.length > 25 ? 'show-tooltip' : ''}`} key={option}>
                <div className="tooltip">
                    <div>
                        <p>{option}</p>
                    </div>
                </div>
                <FormControlLabel
                    value={option}
                    control={<Checkbox onChange={handleCheckboxChange} color="secondary" />}
                    label={option}
                    checked={checked}
                />
            </div>
        );
    });

    return (
        <>
            <FormControl className="break-word" component="fieldset" fullWidth={true}>
                <FormLabel component="legend">{field.label}</FormLabel>
                <FormGroup
                    aria-label={field.label}
                    name={field.name}
                    value={field.value}
                    className="registration-radio-group"
                >
                    {options}
                </FormGroup>
            </FormControl>
            {/* This input is "hidden" but we need it to be able to display the error message */}
            <TextValidator
                value={field.value}
                validators={field.validators}
                errorMessages={field.errorMessages}
                className={'hidden-input-and-only-display-error-message'}
            />
        </>
    );
};

export default CheckboxField;
