import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {ReactComponent as TwitterIconFooter} from '../Images/svg/twitter-footer.svg';
import {ReactComponent as FacebookIconFooter} from '../Images/svg/facebook-footer.svg';
import {ReactComponent as LinkedinIconFooter} from '../Images/svg/linkedin-footer.svg';
import {ReactComponent as MainLogoImage} from '../Images/svg/logo_header.svg';
import {preventDefaultDrag} from '../Utils/utils';
import variables from '../CSS/_variables.module.scss';

class Footer extends Component {
    render() {
        const {eventSlug} = this.props.match.params;
        let {translation, previewTranslation} = this.props;
        if (previewTranslation) {
            translation = previewTranslation;
        }

        return (
            <footer>
                <div onDragStart={preventDefaultDrag} className="footer-container">
                    <div onDragStart={preventDefaultDrag} className="links-container">
                        <div onDragStart={preventDefaultDrag} className="inner-links">
                            <ul>
                                <li>
                                    {eventSlug ? (
                                        <Link
                                            to={{
                                                pathname: `/event/${eventSlug}/privacy-policy/`,
                                                search: `?lang=${this.props.languages.eventLanguage}`,
                                            }}
                                        >
                                            {translation?.footer.privacyPolicy}
                                        </Link>
                                    ) : (
                                        <Link to={{pathname: '/privacy-policy/'}}>
                                            {translation?.footer.privacyPolicy}
                                        </Link>
                                    )}
                                </li>
                                <li>
                                    {eventSlug ? (
                                        <Link
                                            to={{
                                                pathname: `/event/${eventSlug}/cookies/`,
                                                search: `?lang=${this.props.languages.eventLanguage}`,
                                            }}
                                        >
                                            {translation?.footer.cookies}
                                        </Link>
                                    ) : (
                                        <Link to={{pathname: '/cookies/'}}>{translation?.footer.cookies}</Link>
                                    )}
                                </li>
                                <li>
                                    {eventSlug ? (
                                        <Link
                                            to={{
                                                pathname: `/event/${eventSlug}/terms-and-conditions/`,
                                                search: `?lang=${this.props.languages.eventLanguage}`,
                                            }}
                                        >
                                            {translation?.footer.termsAndConditions}
                                        </Link>
                                    ) : (
                                        <Link to={{pathname: '/terms-and-conditions/'}}>
                                            {translation?.footer.termsAndConditions}
                                        </Link>
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div onDragStart={preventDefaultDrag} className="social-buttons-wrapper">
                            <p>{translation?.footer.socialLinksTitle}</p>
                            <ul>
                                <li>
                                    <a
                                        href={`https://twitter.com/${variables.twitterLink}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <TwitterIconFooter />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={`https://www.facebook.com/${variables.facebookLink}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FacebookIconFooter />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={`https://www.linkedin.com/${variables.linkedInLink}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <LinkedinIconFooter />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="logo-wrapper">
                        {eventSlug ? (
                            <Link to={{pathname: `/event/${eventSlug}/`}}>
                                <MainLogoImage />
                            </Link>
                        ) : (
                            <a href="/">
                                <MainLogoImage />
                            </a>
                        )}
                        <span>&copy; 2017 - {new Date().getFullYear()}</span>
                    </div>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default withRouter(connect(mapStateToProps)(Footer));
