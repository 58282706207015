import React, {useCallback, useMemo, useState, useRef} from 'react';
import {TextValidator} from 'react-material-ui-form-validator';

import variables from 'CSS/_variables.module.scss';
import '../../../../Components/InputFields/TextFieldInfoPointStyles.scss';
import {useSelector} from 'react-redux';
import classnames from 'classnames';

const GeneralTextFieldInfoPointLoginRegistration = ({
    field,
    handleChange,
    isLargeScreen,
    placeholderWidthRestrict,
    isTooltipDisabled = false,
}) => {
    const inputValidator = useRef(null);

    const currentLanguage = useSelector((state) => state.languages.eventLanguage);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const langArabic = currentLanguage === 'ar';

    const [isFilled, setIsFilled] = useState(false);

    const getTextWidth = useCallback((text) => {
        // re-use canvas object for better performance
        let canvas = (getTextWidth.canvas = document.createElement('canvas'));
        let context = canvas.getContext('2d');
        context.font = `normal 16px ${variables.fontFamily}`;
        let metrics = context.measureText(text);
        return Math.ceil(metrics.width);
    }, []);

    const handleLongPlaceholderOnMobile = useCallback(
        (e) => {
            setIsFilled(false);
            let placeholder = e.target.getAttribute('placeholder');
            let placeholderWidth = getTextWidth(placeholder);
            if (placeholderWidth > e.target.clientWidth && !isLargeScreen) {
                e.target.closest('.input-wrapper').classList.add('show-tooltip-mobile');
            } else {
                e.target.closest('.input-wrapper').classList.remove('show-tooltip-mobile');
            }
        },
        [getTextWidth, isLargeScreen]
    );

    const removeAnimationPlaceholderMobile = useCallback(
        (e) => {
            setIsFilled(field.value.length > 0);
            e.target.closest('.input-wrapper').classList.remove('show-tooltip-mobile');
        },
        [field.value.length]
    );
    const placeholderWidth = useMemo(() => {
        return getTextWidth(field.label);
    }, [field.label, getTextWidth]);

    const isValid = inputValidator?.current?.isValid();
    const isRequired = field.validators.includes('required');

    return (
        <div className={`input-wrapper ${placeholderWidth > placeholderWidthRestrict ? 'show-tooltip' : ''}`}>
            {!isTooltipDisabled && (
                <div className="tooltip">
                    <div>
                        <p>{field.label}</p>
                    </div>
                </div>
            )}
            {isFilled && langArabic && <p className={classnames('ar-label', {error: !isValid})}>{field.label}</p>}
            <TextValidator
                label={
                    field.label +
                    (!isRequired
                        ? ` (${
                              translation.newLoginRegistrations.optional ||
                              defaultTranslation.newLoginRegistrations.optional
                          })`
                        : '')
                }
                key={field.name}
                placeholder={field.label}
                type={field.type}
                name={field.name}
                value={field.value}
                variant="outlined"
                onBlur={removeAnimationPlaceholderMobile}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                validators={field.validators}
                errorMessages={field.errorMessages}
                multiline={field.multiline > 0}
                rows={field.multiline}
                fullWidth={true}
                margin="normal"
                className={`${
                    !isTooltipDisabled ? 'field-container' : 'email-text-field field-container withEndAdornment'
                }`}
                onFocus={handleLongPlaceholderOnMobile}
                ref={inputValidator}
            />
        </div>
    );
};

export default GeneralTextFieldInfoPointLoginRegistration;
