import React from 'react';
import Button from '@material-ui/core/Button';
import {ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import axios from '../../../../store/axios-instance';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import Confirm from '../../../../Dialogs/Confirm';
import {preventDefaultDrag, fireClickEvent, changeSelectArrows} from '../../../../Utils/utils';
import {hasOnlyEmptySpaces} from 'Utils/utils';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {DeleteOutlineOutlined} from '@material-ui/icons';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {FormControlLabel} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {ReactComponent as DragDrop} from '../ParticipantRegistration/dnd-icon.svg';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Spinner from '../../../../SmallLayoutComponents/Spinner';
import classnames from 'classnames';
import InfoIcon from '@material-ui/icons/Info';

class CustomField extends React.Component {
    wrapperRef = React.createRef();
    handleClickOutside = this.handleClickOutside.bind(this);
    emailRef = React.createRef();

    state = {
        label: '',
        filterTitle: '',
        type: 'text',
        isRequired: false,
        options: [],
        buttonDisabled: true,
        isFilteringEnabled: false,
        isSelectOpen: false,
        openConfirmUnsavedChanges: false,
        formError: false,
        navigationElement: null,
        loading: false,
    };

    componentDidMount() {
        changeSelectArrows();
        this.setFieldData();

        ValidatorForm.addValidationRule('unique', (value) => {
            const options = this.state.options;
            let appears = 0;
            for (let i = 0; i < options.length; i++) {
                if (options[i] === value) {
                    appears++;
                }
            }
            return appears < 2;
        });
        ValidatorForm.addValidationRule('onlyEmptySpaces', (value) => hasOnlyEmptySpaces(value));

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.field._id !== this.props.field._id) {
            this.setFieldData();
        }
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('unique');
        ValidatorForm.removeValidationRule('onlyEmptySpaces');

        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleEnterKey = (optionChecked, optionName) => (e) => {
        if (e.key === 'Enter') {
            if (optionName === 'isRequired') {
                return this.handleRequireFieldOnEnter(optionChecked);
            }
            this.handleEnableFilteringField(!optionChecked);
        }
    };

    checkButtonDisabled = () => {
        let {field} = this.props;
        let buttonDisabled;
        buttonDisabled =
            (this.state.type !== 'text' && this.state.options.length <= 0) || !this.filterLabelIsValid()
                ? true
                : this.state.buttonDisabled;

        //disable the save button when the form is not valid
        if (this.state.formError) buttonDisabled = true;

        if (field.options?.length !== this.state.options?.length) {
            const newValues = this.state.options.map((option) => option.newValue);

            if (newValues.every((val) => val !== undefined && val.length > 0)) {
                console.log(this.state.options.filter((option) => option.newValue));
                return false;
            }
            return true;
        }

        return buttonDisabled;
    };

    setFieldData = () => {
        let {field} = this.props;
        let newOptions = field?.options?.map((option, index) => {
            return {id: `${index}`, oldValue: option || '', newValue: option || ''};
        });
        this.setState({
            label: field.label,
            filterTitle: field.filterTitle,
            type: field.type,
            isRequired: field.isRequired,
            isFilteringEnabled: field.isFilteringEnabled,
            options: newOptions,
            buttonDisabled: true,
        });
    };

    checkField = () => {
        let isButtonDisabled = false;
        const options = this.state.options.map(({newValue}) => newValue);

        //check label
        const label = this.state.label;
        if (label.trim().length === 0) {
            isButtonDisabled = true;
        }

        //check options
        for (let i = 0; i < options.length; i++) {
            if (options[i].trim() === '' || options[i].trim().length > 200) {
                isButtonDisabled = true;
            } else {
                for (let j = 0; j < options?.length; j++) {
                    if (i !== j && options[i]?.trim() === options[j].trim()) {
                        isButtonDisabled = true;
                    }
                }
            }
        }

        return isButtonDisabled;
    };

    handleChangeLabel = (e) => {
        this.setState(
            {
                label: e.target.value,
            },
            () => {
                const buttonDisabled = this.checkField();
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({formError: !isValid, buttonDisabled});
                });
            }
        );
    };

    handleChangeFieldType = (e) => {
        const buttonDisabled = this.checkField();
        this.setState({
            type: e.target.value,
            buttonDisabled,
        });
    };

    handleRequireField = (e) => {
        const buttonDisabled = this.checkField();
        this.setState({
            isRequired: e.target.checked,
            buttonDisabled,
        });
    };

    handleEnableFilteringField = (checked) => {
        const buttonDisabled = this.checkField();
        this.setState({
            isFilteringEnabled: checked,
            buttonDisabled,
        });
    };

    handleRequireFieldOnEnter = (optionChecked) => {
        const buttonDisabled = this.checkField();
        this.setState({
            isRequired: !optionChecked,
            buttonDisabled,
        });
    };

    handleChangeOption = (optionIndex) => (e) => {
        let updatedOptions = [...this.state.options];
        let value = e.target.value;
        if (value.charAt(0) === ' ') {
            value = value.charAt(0).trim() + value.substring(1, value.length);
        }
        updatedOptions[optionIndex] = {
            ...updatedOptions[optionIndex], // keep other properties unchanged
            newValue: value, // update only the name property
        };
        this.setState(
            {
                options: updatedOptions,
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    const buttonDisabled = this.checkField();
                    this.setState({formError: !isValid, buttonDisabled});
                });
            }
        );
    };

    deleteOption = (index) => (e) => {
        let updatedOptions = this.state.options.filter((option, optionIndex) => optionIndex !== index);
        this.setState(
            {
                options: updatedOptions,
            },
            () => {
                const buttonDisabled = this.checkField();
                this.setState({buttonDisabled});
            }
        );
    };

    addOption = () => {
        let updatedOptions = [...this.state.options];
        let maxId = 0;
        updatedOptions.forEach((option) => {
            if (option.id > maxId) {
                maxId = option.id;
            }
        });

        updatedOptions.push({id: maxId + 1, name: ''});
        const buttonDisabled = this.checkField();
        this.setState({
            options: updatedOptions,
            buttonDisabled,
        });
    };

    handleFilterTitleChange = (e) => {
        const buttonDisabled = this.checkField();
        this.setState({
            filterTitle: e.target.value,
            buttonDisabled,
        });
    };
    filterLabelIsValid = () => {
        if (this.state.isFilteringEnabled) {
            if (
                this.state.filterTitle &&
                this.state.filterTitle.length > 0 &&
                this.state.filterTitle.length <= 30 &&
                this.state.label &&
                this.state.label.length <= 200
            ) {
                return true;
            }
        } else {
            if (this.state.label && this.state.label.length <= 200) {
                return true;
            }
        }
        return false;
    };
    saveEventField = () => {
        this.setState({loading: true});
        const {eventId, field, fieldIndex} = this.props;
        const {navigationElement} = this.state;

        if (!this.filterLabelIsValid()) {
            this.setState({
                buttonDisabled: true,
            });
            return;
        }
        const modifiedOptions = this.state.options.map(({newValue, oldValue}) => ({newValue, oldValue}));

        const data = {
            label: this.state.label,
            filterTitle: this.state.filterTitle,
            type: this.state.type,
            isRequired: this.state.isRequired,
            isFilteringEnabled: this.state.isFilteringEnabled,
            options: modifiedOptions,
            index: fieldIndex,
        };

        axios({method: 'put', url: `/event/v2/${eventId}/${this.props.role}-custom-fields/${field._id}`, data: data})
            .then((response) => {
                let eventRegistrationFields = response.data.data.eventRegistrationFields;
                this.props.getEventRegistrationFieldsSuccess(eventRegistrationFields);

                this.setState({
                    buttonDisabled: true,
                    openConfirmUnsavedChanges: false,
                    options: this.state.options.map((option) => ({oldValue: option.newValue, ...option})),
                    loading: false,
                });
                this.props.handleOpenSuccessSnackbar();
                this.setFieldData();
                if (navigationElement) {
                    fireClickEvent(navigationElement);
                }
            })
            .catch((error) => {
                this.props.handleOpenErrorSnackbar();
                this.setState({loading: false});
            });
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.setFieldData();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) =>
            pathElem.getAttribute?.('data-is-navigation')
        );

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (!this.state.buttonDisabled && !this.state.isSelectOpen) {
                if (this.filterLabelIsValid()) {
                    this.setState({
                        openConfirmUnsavedChanges: true,
                        navigationElement: this.getNewNavigationElement(e),
                    });
                }
                if (this.state.type !== 'text' && this.state.options.length <= 0) {
                    this.setState({openConfirmUnsavedChanges: false});
                }
            }
        }
    }

    //dragdrop

    getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the localSpeakers look a bit nicer
        userSelect: 'none',
        margin: `0 0 25px 0`,
        ...draggableStyle,
    });

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    setOrderOfTheField = async (field) => {
        /*    const {eventId} = this.props;
        const order = {
            index: field.order,
            label: field.label,
            type: field.type,
        };
        const {data} = await axios.put(`/event/v2/${eventId}/${this.props.role}-custom-fields/${field._id}`, order);

        if (data?.success) {
            this.props.getEventRegistrationFieldsSuccess(data.data.eventRegistrationFields);
        }*/
    };

    onDragEnd = ({destination, source}) => {
        if (!destination) {
            return;
        }

        const localFieldsReordered = this.reorder(this.state.options, source.index, destination.index);

        let reOrderedList = localFieldsReordered.map((item, index) => {
            return item;
        });

        this.setState(
            {
                options: reOrderedList,
                buttonDisabled: false,
            },
            () => {
                //find which field was moved
                const field = this.state.options.find((item, index) => index === destination.index);

                this.setOrderOfTheField(field);
            }
        );
    };

    //dragdrop end

    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
    };

    selectFocusTrue = () => {
        this.setState({isSelectOpen: true});
    };

    selectFocusFalse = () => {
        this.setState({isSelectOpen: false});
    };

    render() {
        const {type, options, openConfirmUnsavedChanges, loading} = this.state;

        return (
            <>
                <div onDragStart={preventDefaultDrag} ref={this.wrapperRef}>
                    {loading && <Spinner />}
                    <ValidatorForm ref="form" onSubmit={this.saveEventField}>
                        <div onDragStart={preventDefaultDrag} className="options-container">
                            <div onDragStart={preventDefaultDrag} className="single-option-container">
                                {/*In order for the select arrow to be as in the design system:*/}
                                {/*- add className scaled-select-arrow to the SelectValidator*/}
                                {/*- call changeSelectArrows()*/}
                                <SelectValidator
                                    className={'scaled-select-arrow'}
                                    onFocus={this.selectFocusTrue}
                                    onBlur={this.selectFocusFalse}
                                    onChange={this.handleChangeFieldType}
                                    variant="outlined"
                                    value={type}
                                    validators={['required']}
                                    errorMessages={['Please select a field type']}
                                    label={'Field Type'}
                                    fullWidth={true}
                                    disabled={false}
                                    IconComponent={(props) => (
                                        <ExpandMoreRoundedIcon
                                            {...props}
                                            className={`material-icons ${props.className}`}
                                        />
                                    )}
                                >
                                    <MenuItem value={'text'}>Text input</MenuItem>
                                    <MenuItem value={'select'}>Dropdown</MenuItem>
                                    <MenuItem value={'radio'}>Radio buttons</MenuItem>
                                    <MenuItem value={'checkbox'}>Checkboxes</MenuItem>
                                </SelectValidator>
                            </div>
                        </div>

                        <div onDragStart={preventDefaultDrag} className="options-container">
                            <div onDragStart={preventDefaultDrag} className="single-option-container">
                                <Tooltip
                                    arrow
                                    classes={{tooltip: 'ignore-rtl'}}
                                    title={this.state.label?.length > 56 ? this.state.label : ''}
                                    placement={'top-start'}
                                >
                                    <TextValidator
                                        className="setting-input"
                                        label={'Label'}
                                        type={'text'}
                                        name={'label'}
                                        value={this.state.label}
                                        onChange={this.handleChangeLabel}
                                        validators={['required', 'minStringLength: 1', 'maxStringLength: 200']}
                                        errorMessages={[
                                            'Field is required',
                                            'Field is required',
                                            'You have reached the maximum limit of characters allowed (200 characters)',
                                        ]}
                                        fullWidth={true}
                                        margin="normal"
                                        variant="outlined"
                                        disabled={false}
                                        InputProps={{
                                            endAdornment: (
                                                <span className="char-count">{this.state.label?.length}/200</span>
                                            ),
                                        }}
                                    />
                                </Tooltip>
                            </div>
                            <div onDragStart={preventDefaultDrag} className="input-description d-flex flex-wrap">
                                <div
                                    tabIndex={'0'}
                                    onKeyDown={this.handleEnterKey(this.state.isRequired, 'isRequired')}
                                >
                                    <FormControlLabel
                                        label={this.state.isRequired ? 'Mandatory' : 'Optional'}
                                        classes={{label: 'switch-label'}}
                                        control={
                                            <Switch
                                                tabIndex="-1"
                                                color="secondary"
                                                onChange={this.handleRequireField}
                                                checked={this.state.isRequired}
                                            />
                                        }
                                    />
                                </div>
                                {type !== 'text' && (
                                    <div
                                        tabIndex={'0'}
                                        onKeyDown={this.handleEnterKey(
                                            this.state.isFilteringEnabled,
                                            'isFilteringEnabled'
                                        )}
                                    >
                                        <FormControlLabel
                                            label="Filtering"
                                            classes={{label: 'switch-label'}}
                                            control={
                                                <Switch
                                                    tabIndex="-1"
                                                    color="secondary"
                                                    onChange={(e) => this.handleEnableFilteringField(e.target.checked)}
                                                    checked={this.state.isFilteringEnabled}
                                                />
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {this.state.isFilteringEnabled && type !== 'text' && (
                            <div className="options-container">
                                <div onDragStart={preventDefaultDrag} className="single-option-container">
                                    <TextValidator
                                        className="setting-input"
                                        label={'Filter Title'}
                                        type={'text'}
                                        name={'filter-title'}
                                        innerRef={this.emailRef}
                                        value={this.state.filterTitle}
                                        onChange={this.handleFilterTitleChange}
                                        validators={[
                                            'required',
                                            'minStringLength:1',
                                            'maxStringLength:30',
                                            'onlyEmptySpaces',
                                        ]}
                                        errorMessages={[
                                            'Field is required',
                                            'Field is required',
                                            'You have reached the maximum limit of characters allowed (30 characters)',
                                            'Field is required',
                                        ]}
                                        fullWidth={true}
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <span className="char-count">{this.state.filterTitle?.length}/30</span>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className="input-description">
                                    Recommended to use up to 2 words describing the filter
                                </div>
                            </div>
                        )}
                        {type !== 'text' && (
                            <div onDragStart={preventDefaultDrag} className="custom-fields-container dnd-support">
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {options.map((option, index) => {
                                                    return (
                                                        <Draggable
                                                            key={option.id}
                                                            draggableId={option.id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    style={this.getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <div
                                                                        onDragStart={preventDefaultDrag}
                                                                        className="options-container"
                                                                        key={`option${index}`}
                                                                    >
                                                                        {' '}
                                                                        <span
                                                                            className={classnames('dragDrop-element', {
                                                                                disabled: options.length <= 1,
                                                                            })}
                                                                            {...{
                                                                                ...provided.dragHandleProps,
                                                                                tabIndex: -1,
                                                                            }}
                                                                        >
                                                                            <DragDrop />
                                                                        </span>
                                                                        <div
                                                                            onDragStart={preventDefaultDrag}
                                                                            className="single-option-container"
                                                                        >
                                                                            <Tooltip
                                                                                arrow
                                                                                classes={{tooltip: 'ignore-rtl'}}
                                                                                title={
                                                                                    option?.newValue?.length > 56
                                                                                        ? option?.newValue
                                                                                        : ''
                                                                                }
                                                                                placement={'top-start'}
                                                                            >
                                                                                <TextValidator
                                                                                    className="setting-input"
                                                                                    label={'Option Name'}
                                                                                    type={'text'}
                                                                                    name={'label'}
                                                                                    value={option.newValue}
                                                                                    onChange={this.handleChangeOption(
                                                                                        index
                                                                                    )}
                                                                                    validators={[
                                                                                        'required',
                                                                                        'unique',
                                                                                        'maxStringLength: 200',
                                                                                    ]}
                                                                                    errorMessages={[
                                                                                        'Field is required',
                                                                                        'Field value must be unique',
                                                                                        'You have reached the maximum limit of characters allowed (200 characters)',
                                                                                    ]}
                                                                                    fullWidth={true}
                                                                                    margin="normal"
                                                                                    variant="outlined"
                                                                                    disabled={false}
                                                                                />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                'tooltip-container-custom-fields'
                                                                            }
                                                                        >
                                                                            {option.oldValue !== undefined &&
                                                                                option.newValue !== undefined &&
                                                                                option.oldValue !== option.newValue && (
                                                                                    <Tooltip
                                                                                        placement="bottom"
                                                                                        arrow={true}
                                                                                        classes={{
                                                                                            tooltip: 'ignore-rtl',
                                                                                        }}
                                                                                        title={
                                                                                            'Customizing registration fields may result in incorrect data storage for existing users. We recommend to instruct such users to update their choices in their account settings'
                                                                                        }
                                                                                    >
                                                                                        <InfoIcon
                                                                                            classes={{
                                                                                                root: 'primary-color',
                                                                                            }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                )}
                                                                        </div>
                                                                        <div
                                                                            onDragStart={preventDefaultDrag}
                                                                            className="input-description tooltip-support"
                                                                        >
                                                                            <Button
                                                                                type="button"
                                                                                onClick={this.deleteOption(index)}
                                                                                startIcon={<DeleteOutlineOutlined />}
                                                                            >
                                                                                DELETE
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>

                                <Button type="button" onClick={this.addOption} startIcon={<AddIcon />}>
                                    Add Option
                                </Button>
                            </div>
                        )}
                        <div onDragStart={preventDefaultDrag} className="action-container">
                            <Button
                                type="submit"
                                disabled={this.checkButtonDisabled()}
                                startIcon={<SaveOutlinedIcon />}
                                variant="contained"
                                color={'secondary'}
                                disableElevation
                            >
                                Save
                            </Button>
                        </div>
                    </ValidatorForm>
                </div>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveEventField}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventRegistrationFieldsSuccess: (registrationFields) =>
            dispatch(actions.getEventRegistrationFieldsSuccess(registrationFields)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomField);
