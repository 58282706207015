const isPfd = (type) => type === 'application/pdf';

const isWord = (type) => {
    switch (type) {
        case 'application/msword':
        case 'application/vnd.ms-word.document.macroenabled.12':
        case 'application/vnd.ms-word.template.macroenabled.12':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
            return true;
        default:
            return false;
    }
};

const isExcel = (type) => {
    switch (type) {
        case 'application/vnd.ms-excel':
        case 'application/excel':
        case 'application/x-excel':
        case 'application/x-msexcel':
        case 'application/vnd.ms-excel.addin.macroenabled.12':
        case 'application/vnd.ms-excel.sheet.binary.macroenabled.12':
        case 'application/vnd.ms-excel.template.macroenabled.12':
        case 'application/vnd.ms-excel.sheet.macroenabled.12':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
            return true;
        default:
            return false;
    }
};

const isPowerPoint = (type) => {
    switch (type) {
        case 'application/vnd.ms-powerpoint':
        case 'application/mspowerpoint':
        case 'application/powerpoint':
        case 'application/x-mspowerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.ms-powerpoint.presentation.macroenabled.12':
        case 'application/vnd.ms-powerpoint.slideshow.macroenabled.12':
            return true;
        default:
            return false;
    }
};

const isUncommonDocFileType = (type) => {
    switch (type) {
        case 'application/vnd.oasis.opendocument.chart':
        case 'application/vnd.oasis.opendocument.database':
        case 'application/vnd.oasis.opendocument.formula':
        case 'application/vnd.oasis.opendocument.formula-template':
        case 'application/vnd.oasis.opendocument.graphics':
        case 'application/vnd.oasis.opendocument.graphics-template':
        case 'application/vnd.oasis.opendocument.image':
        case 'application/vnd.oasis.opendocument.image-template':
        case 'application/vnd.oasis.opendocument.presentation':
        case 'application/vnd.oasis.opendocument.presentation-template':
        case 'application/vnd.oasis.opendocument.spreadsheet':
        case 'application/vnd.oasis.opendocument.spreadsheet-template':
        case 'application/vnd.oasis.opendocument.text':
        case 'application/vnd.oasis.opendocument.text-master':
        case 'application/vnd.oasis.opendocument.text-template':
            return true;
        default:
            return false;
    }
};

const isImage = (type) => {
    switch (type) {
        case 'image/png':
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/gif':
            return true;
        default:
            return false;
    }
};

// If you add new types, be careful with the word used 
// because of the includes() function
export const verifyFileType = (type, acceptedTypesString) => {
    if (acceptedTypesString.includes('pdf') && isPfd(type)) {
        return true;
    }
    if (acceptedTypesString.includes('word') && isWord(type)) {
        return true;
    }
    if (acceptedTypesString.includes('excel') && isExcel(type)) {
        return true;
    }
    if (acceptedTypesString.includes('powerPoint') && isPowerPoint(type)) {
        return true;
    }
    if (acceptedTypesString.includes('image') && isImage(type)) {
        return true;
    }
    if (acceptedTypesString.includes('uncommon') && isUncommonDocFileType(type)) {
        return true;
    }

    return false;
};
