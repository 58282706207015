import React from 'react';
import '../../CSS/myAccount.scss';
import '../../CSS/myEventsMobile.scss';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {preventDefaultDrag} from '../../Utils/utils';

class MyEventsMobile extends React.Component {
    render() {
        const {user, eventId, redirectToEvent, translation} = this.props;
        return (
            <div
                onDragStart={preventDefaultDrag}
                id="account-drop-mobile"
                className="my-account-component dropdown-component"
            >
                <div onDragStart={preventDefaultDrag} className="page-title">
                    <h3>{translation?.myEventsDropdown.title}</h3>
                </div>
                <div onDragStart={preventDefaultDrag} className="myaccount-container">
                    <ul className="my-events-list">
                        {user.eventRoles
                            .slice(0)
                            .reverse()
                            .map((eventRole) => {
                                return (
                                    <li
                                        className={'row ' + (eventId === eventRole.event._id ? 'active' : '')}
                                        key={eventRole.event._id}
                                        onClick={redirectToEvent(eventRole.event.slug, eventRole.event._id)}
                                    >
                                        <span className='long-event-name'>{eventRole.event.name}</span>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default withRouter(connect(mapStateToProps)(MyEventsMobile));
