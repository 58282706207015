import React, {useState} from 'react';
import compact from 'lodash/compact';
import {makeStyles} from '@material-ui/core/styles';
import {Tooltip} from '@material-ui/core';
import {EVENT} from 'avcore';
import {Utils} from 'avcore/client';

import VideoAvatar from '../VideoAvatar/VideoAvatar';

const calculateGridSize = (total) => {
    if (!total) total = 1;
    const xSize = Math.ceil(Math.sqrt(total));
    const ySize = xSize * (xSize - 1) >= total ? xSize - 1 : xSize;
    return {
        width: `${100 / xSize}%`,
        height: `${100 / ySize}%`,
    };
};

const useStyles = makeStyles({
    playbacksList: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexWrap: 'wrap',
    },
    videoWrapper: {
        display: 'flex',
        maxHeight: '100%',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
    },
    videoWrapperSize: ({activeStreamsCount}) => calculateGridSize(activeStreamsCount),
    enlarged: {
        width: '100%',
        height: '100%',
    },
    video: {
        width: '100%',
        objectFit: 'cover',
    },
    videoEnlarged: {
        objectFit: 'contain',
    },
    videoOff: {
        border: '1px solid #fff',
    },
    videoOffPlate: {
        position: 'absolute',
        display: 'flex',
        zIndex: 100,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        backgroundColor: '#000',
    },
    hidden: {
        display: 'none',
    },
});

export const VideoPlayerVideoPlates = ({vidRefs, startVideo, forceUpdate, members, playbacks, activeStreamsCount}) => {
    const [enlargedStream, setEnlargedStream] = useState(null);

    const classes = useStyles({activeStreamsCount});

    const setRef = (el, i) => {
        if (!el) return;
        if (!vidRefs.current[i]) {
            vidRefs.current[i] = React.createRef();
            vidRefs.current[i].current = el;
        }

        if (playbacks[i] && !playbacks[i].mediaStream) {
            setTimeout(async () => {
                await startVideo(vidRefs.current[i], playbacks[i]);
                const reload = ({kind}) => {
                    if (kind === 'video')
                        setTimeout(() => {
                            if (Utils.isFirefox) vidRefs.current[i].current.play();
                            forceUpdate();
                        }, 1000);
                };
                playbacks[i].api._client.on(EVENT.STREAM_STOPPED, reload);
                playbacks[i].api._client.on(EVENT.STREAM_STARTED, reload);
                playbacks[i].mediaStream.oninactive = forceUpdate;
                playbacks[i].mediaStream.onactive = forceUpdate;

                if (playbacks[i].mediaStream.active) forceUpdate();
            });
        }

        return vidRefs.current[i];
    };

    const handlePlateFocused = (memberId) => setEnlargedStream(!enlargedStream ? memberId : null);

    return (
        <div className={[classes.playbacksList, ...(activeStreamsCount > 0 ? [] : [classes.hidden])].join(' ')}>
            {!!playbacks.length &&
                playbacks.map((pb, i) => {
                    const isCameraOff = !pb.availableKinds.includes('video') ? classes.videoOff : null;
                    const memberId = pb.configs.stream;
                    const isAudioOff = !pb.availableKinds.includes('audio');

                    return (
                        <Tooltip
                            arrow
                            classes={{tooltip: 'ignore-rtl'}}
                            title={
                                !enlargedStream
                                    ? 'Click to view the original video size'
                                    : 'Click to return to the default view'
                            }
                            placement="top"
                            key={memberId}
                        >
                            <div
                                onClick={() => handlePlateFocused(memberId)}
                                className={compact([
                                    classes.videoWrapper,
                                    !enlargedStream ? classes.videoWrapperSize : null,
                                    enlargedStream === memberId ? classes.enlarged : null,
                                    !!enlargedStream && enlargedStream !== memberId ? classes.hidden : null,
                                    !pb.mediaStream || !pb.mediaStream.active ? classes.hidden : null,
                                ]).join(' ')}
                            >
                                <video
                                    className={compact([
                                        classes.video,
                                        isCameraOff ? classes.videoOff : null,
                                        memberId === enlargedStream ? classes.videoEnlarged : null,
                                    ]).join(' ')}
                                    ref={(el) => setRef(el, i)}
                                    id={`pb-video-${memberId}`}
                                    playsInline={true}
                                    muted={isAudioOff}
                                />
                                {isCameraOff && (
                                    <div className={classes.videoOffPlate}>
                                        <VideoAvatar
                                            text={'name'}
                                            member={members.find(({userId}) => userId === memberId)}
                                        />
                                    </div>
                                )}
                            </div>
                        </Tooltip>
                    );
                })}
        </div>
    );
};
