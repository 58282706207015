import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import HPHeaderInfo from './HPHeaderInfo';
import HpIntroAndProgram from './HpIntroAndProgram';
import HPBackground from './HPBackground';
import HPBackgroundOverlayColor from './HPBackgroundOverlayColor';
import {preventDefaultDrag} from '../../../Utils/utils';
import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";

class HomepageDescription extends React.Component {
    constructor(props) {
        super(props);

        const {availableLanguages} = props;

        this.state = {
            isMultiLanguage: availableLanguages?.length > 1,
        };
    }

    render() {
        let {isMultiLanguage} = this.state;
        let {
            eventSlug,
            handleOpenSuccessSnackbar,
            handleOpenErrorSnackbar,
            saveEventBranding,
            saveEventTranslation,
            brandingLanguage,
            loadingSaveEventBranding,
        } = this.props;

        return (
            <>
                <div onDragStart={preventDefaultDrag} ref={this.wrapperRef} className="branding-homepage-description">
                    <h4 className="advanced-options-title">
                        HOMEPAGE DESCRIPTION
                        <Link
                            to={{pathname: `/event/${eventSlug}`, search: `?preview=true&lang=${brandingLanguage}`}}
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button
                                variant={"text"}
                                startIcon={<VisibilityRoundedIcon/>}
                                className={"mr-4"}
                                color={"primary"}
                                size={"large"}
                            >
                                Preview
                            </Button>
                        </Link>
                    </h4>
                    {loadingSaveEventBranding ?
                        <div
                            className={"advanced-options-container d-flex align-items-center justify-content-center"}>
                            <CircularProgress color="primary"/>
                        </div> :
                        <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                            <HPHeaderInfo isMultiLanguage={isMultiLanguage}
                                          saveEventTranslation={saveEventTranslation}/>
                            <HpIntroAndProgram
                                isMultiLanguage={isMultiLanguage}
                                saveEventTranslation={saveEventTranslation}
                                saveEventBranding={saveEventBranding}
                            />
                            <HPBackground
                                handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                                handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                            />
                            <HPBackgroundOverlayColor saveEventBranding={saveEventBranding}/>
                        </div>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventSlug: state.event.eventSlug,
        brandingLanguage: state.languages.organizerBrandingLanguage,
    };
};

export default connect(mapStateToProps)(HomepageDescription);
