import React, {Component} from 'react';
import {connect} from 'react-redux';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Checkbox} from "@material-ui/core";
import {toggleIgnoreEventWelcomeScreen} from "../../store/actions/userActions";

class ShowAgainRadioButton extends Component {

    toggleNotShowAgain = (e) => {
        const {eventId} = this.props;
        this.props.toggleIgnoreEventWelcomeScreen(eventId, e.target.checked)
    }

    checkWelcomeScreenIgnored = () => {
        const {user, eventId} = this.props;
        const eventUserData = user.eventRoles.find(role=>role.event._id === eventId);
        return eventUserData?.ignoreWelcomeScreen;
    }

    renderLabel = () => {
        const {translation, defaultTranslation} = this.props;
        return <span className='checkbox-label'>
            {translation?.welcomeScreen?.doNotShowAgain || defaultTranslation?.welcomeScreen?.doNotShowAgain}
        </span>
    }

    render() {
        return (
            <FormControlLabel
                value={true}
                className={'show-again-checkbox'}
                control={<Checkbox
                    checked={this.checkWelcomeScreenIgnored()}
                    onChange={this.toggleNotShowAgain}
                    name="reviewedGDPR"
                    color="secondary"
                />}
                label={this.renderLabel()}
                checked={true}
            />

        );
    }
}

function mapStateToProps(state) {
    return {
        eventId: state.event.eventId,
        user: state.user.data,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleIgnoreEventWelcomeScreen: (eventId, ignoreWelcomeScreen) => dispatch(toggleIgnoreEventWelcomeScreen(eventId, ignoreWelcomeScreen)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ShowAgainRadioButton);
