import React from 'react';
import PlatformMenu from '../../Components/PlatformMenu';
import HeaderMenu from '../../Components/HeaderMenu';
import Footer from '../../SmallLayoutComponents/Footer';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import axios from '../../store/axios-instance';
import {Helmet} from 'react-helmet';
import {getLanguageFromUrl, preventDefaultDrag} from '../../Utils/utils';
import InfoIcon from '@material-ui/icons/Info';

class TermsAndConditions extends React.Component {
    state = {
        gdprText: '',
        language: 'en',
        showDisclaimer: false,
        brandingTranslation: null,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.languages.eventLanguage !== this.props.languages.eventLanguage ||
            prevProps.location.search !== this.props.location.search
        ) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const selectedLang = getLanguageFromUrl() || 'en';
        let selectedTemplate = 'platformTermsAndConditions';
        this.setState({language: selectedLang});
        this.getGDPRText(selectedLang, selectedTemplate);

        if (this.props.match.params.eventSlug) {
            const brandingTranslation = this.props.eventInfo.brandingTranslations.find(
                (translation) => translation.language === this.props.languages.eventLanguage
            );
            this.setState({
                brandingTranslation: brandingTranslation,
            });
        }
    };

    getGDPRText = (lang, template) => {
        axios({
            method: 'get',
            url: `/gdpr/${lang}/${template}/`,
        })
            .then((response) => {
                this.setState({
                    gdprText: response.data.content,
                    showDisclaimer: response.data.showDisclaimer,
                });
                return response.data.content;
            })
            .catch((error) => {});
    };

    render() {
        const {language, gdprText, showDisclaimer, brandingTranslation} = this.state;

        return (
            <div onDragStart={preventDefaultDrag} className="gdpr-page">
                <Helmet>
                    <title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${brandingTranslation ? brandingTranslation?.homepageEventName + ':' : ''} ${
                        this.props.languages.translations[language]?.gdpr.metaTitleTerms ||
                        this.props.languages.translations['en']?.gdpr.metaTitleTerms
                    }`}</title>
                </Helmet>

                {this.props.match.params.eventSlug ? (
                    <HeaderMenu className={'white'} coloredLogo disabledLanguageSelector />
                ) : (
                    <PlatformMenu className={'white'} coloredLogo />
                )}

                <div onDragStart={preventDefaultDrag} className="header-spacing-container">
                    <div onDragStart={preventDefaultDrag} className="gdpr-container">
                        {showDisclaimer && (
                            <React.Fragment>
                                <div onDragStart={preventDefaultDrag} className="translated-ai-info">
                                    <InfoIcon color="primary" />
                                    <span>
                                        {this.props.languages.translations[language].gdpr.disclaimerTextFirst ||
                                            this.props.languages.translations['en'].gdpr.disclaimerTextFirst}{' '}
                                        <Link
                                            to={{
                                                pathname: `${this.props.location.pathname}`,
                                                search: `?lang=en`,
                                            }}
                                        >
                                            <span>
                                                {this.props.languages.translations[language].gdpr
                                                    .disclaimerTextSecond ||
                                                    this.props.languages.translations['en'].gdpr.disclaimerTextSecond}
                                            </span>
                                        </Link>
                                    </span>
                                </div>
                            </React.Fragment>
                        )}

                        <div onDragStart={preventDefaultDrag} dangerouslySetInnerHTML={{__html: gdprText}} />
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        eventInfo: state.event.eventInfo,
    };
};

export default withRouter(connect(mapStateToProps)(TermsAndConditions));
