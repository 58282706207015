import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import {preventDefaultDrag, urlifyString} from '../../../Utils/utils';

class ChatMessage extends React.PureComponent {
    render() {
        let {text} = this.props.message;
        const {userMessageData, translation, defaultTranslation} = this.props;
        text = urlifyString(text);
        return (
            <>
                <div onDragStart={preventDefaultDrag} className="avatar-wrapper">
                    {this.props.showUserDetails ? (
                        userMessageData ? (
                            <div
                                className="user-avatar"
                                style={{
                                    backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${userMessageData?.avatarSmall}')`,
                                }}
                            ></div>
                            ) : (
                            <div
                                className="user-avatar"
                                style={{
                                    backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}default_avatar.png')`,
                                }}
                            ></div>
                        )
                    ) : null}
                </div>
                <div onDragStart={preventDefaultDrag} className="messages-wrappper">
                    {this.props.showUserDetails ? (
                        <p onDragStart={preventDefaultDrag} className="user-details">
                            {userMessageData ?
                                `${userMessageData.first} ${userMessageData.last}` :
                                `${translation?.chatsDropdown.deletedUserLabel || defaultTranslation?.chatsDropdown.deletedUserLabel}`}
                        </p>
                    ) : null}
                    <p onDragStart={preventDefaultDrag}>{text}</p>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        maximizeChats: state.user.maximizeChats,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeExpandedVideoConference: () => dispatch(actions.expandedChatsOnVideoConference()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessage);
