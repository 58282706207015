import React from 'react';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import colors from 'CSS/_variables.module.scss';
import {useSelector} from "react-redux";
import Button from '@material-ui/core/Button';

const DownloadProgramButton = ({eventId, programFile, className}) => {

    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) =>  state.languages.translations['en']);


    return (
        <div className={`${className}`}>
            <a
                href={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${programFile}`}
                target="_blank"
                rel="noreferrer"
            >
                <Button startIcon={<CloudDownloadOutlinedIcon/>} type={'button'}>
                    {translation?.programDropdown?.fullProgramButton || defaultTranslation?.programDropdown?.fullProgramButton}
                </Button>
                <span></span>
            </a>
        </div>
    );
};

export default DownloadProgramButton;