import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import SingleMeetingItemAgenda from './SingleMeetingItemAgenda';
import {isMobile, isMobileOnly} from 'react-device-detect';
import {useSelector} from 'react-redux';
import {usePlatformTranslation} from 'services/hooks';

const MeetingRequests = ({pendingReqs, acceptAllMeetings, cancelMeetingRequest, acceptMeetingRequest}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const userMe = useSelector((state) => state.user);

    /*translations*/
    const meetingRequestsTranslation = usePlatformTranslation((state) => state?.agendaTranslations?.meetingRequests);
    const acceptAllMeetingsTranslation = usePlatformTranslation(
        (state) => state?.agendaTranslations?.acceptedAllMeetings
    );
    /*end translations*/
    const showAcceptAllBtn = () => {
        return pendingReqs?.some((singleMeeting) => singleMeeting?.owner?.user?._id === userMe?.data?._id);
    };

    const meetingRequestsToggle = () => () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`accordion-keeper ${isMobileOnly && 'mb-16'}`}>
            <Accordion expanded={isExpanded} className={`agenda-meeting-requests `}>
                <div className="headline-accordion">
                    <h3>
                        {meetingRequestsTranslation}{' '}
                        <span className="meeting-requests-number">{pendingReqs?.length || 0}</span>
                    </h3>
                    <Button
                        onClick={meetingRequestsToggle()}
                        type="label"
                        className="mobile-support-accordion-button desktop-support"
                        startIcon={
                            <>
                                {isExpanded ? (
                                    <>
                                        <ExpandLessIcon /> {!isMobile && 'COLLAPSE'}
                                    </>
                                ) : (
                                    <>
                                        <ExpandMoreIcon /> {!isMobile && 'EXPAND'}
                                    </>
                                )}
                            </>
                        }
                    ></Button>
                </div>

                {isExpanded && (
                    <AccordionDetails className="d-column">
                        <div className={`req-list-agenda ${pendingReqs.length === 1 && 'req-c-1'}`}>
                            {pendingReqs.length > 0 &&
                                pendingReqs.map((singleMeeting) => (
                                    <SingleMeetingItemAgenda
                                        singleMeeting={singleMeeting}
                                        key={singleMeeting._id}
                                        date={null}
                                        cancelMeetingRequest={cancelMeetingRequest}
                                        acceptMeetingRequest={acceptMeetingRequest}
                                    />
                                ))}
                        </div>
                        {showAcceptAllBtn() || pendingReqs?.length < 2 ? null : (
                            <div className="accept-all-button-container">
                                <Button
                                    onClick={acceptAllMeetings()}
                                    className={`item-accept-all-meetings-button item-decline-button ${
                                        isMobile && 'mobile'
                                    }`}
                                >
                                    {acceptAllMeetingsTranslation}
                                </Button>
                            </div>
                        )}
                    </AccordionDetails>
                )}
            </Accordion>
        </div>
    );
};

export default MeetingRequests;
