import {preventDefaultDrag, doesOrganizerHasParticipantProfile} from 'Utils/utils';
import {ReactComponent as ExhibitorNoPhoto} from '../../Images/svg/showfloor_icon_rooms.svg';
import colors from 'CSS/_variables.module.scss';
import {isMobile} from 'react-device-detect';
import {ReactComponent as Online} from 'Pages/Event/ExhibitorVote/Helpers/svg/online-count-icon.svg';
import {Checkbox, Tooltip} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {usePlatformTranslation} from 'services/hooks';
import {useSelector} from 'react-redux';
import Spinner from '../../SmallLayoutComponents/Spinner';

const SingleExhibitorMobile = ({
    exhibitor,
    showfloorId,
    handleSetActiveExhibitor,
    votingPrivalages,
    isVotingEnabled,
    votingData,
    handleChangeVote,
    voteLoading,
    exhibitorInProgress,
    singleExhibitorsVoteCount,
}) => {
    //local state
    const [isVoted, setIsVoted] = useState(false);

    //redux
    const voteText = usePlatformTranslation((state) => state?.voting?.voteText);
    const votedText = usePlatformTranslation((state) => state?.voting?.votedText);
    const votesText = usePlatformTranslation((state) => state?.voting?.votesText);
    const userEventProfile = useSelector((state) => state.userEventProfile?.data);
    const toVotePleaseEnableParticipantProfile = usePlatformTranslation(
        (state) => state?.voting?.toVotePleaseEnableParticipantProfile
    );

    //get vote data
    const getShowfloorVoteData = () => {
        let isUserVoted = votingData?.userVotes?.find((exhibitorVote) => exhibitorVote === exhibitor?._id);
        setIsVoted(!!isUserVoted);
    };

    //get the number of votes
    useEffect(() => {
        getShowfloorVoteData();
    }, [showfloorId, exhibitor?._id, votingData]);
    return (
        <div onDragStart={preventDefaultDrag} className="exhibitor-container">
            <div className={`with-vote`} onClick={handleSetActiveExhibitor(exhibitor?._id)}>
                <div onDragStart={preventDefaultDrag} className="logo-container">
                    {exhibitor.booth?.logo ? (
                        <img
                            draggable="false"
                            onDragStart={preventDefaultDrag}
                            src={exhibitor.filesUrl + exhibitor.booth?.logo}
                            alt={exhibitor.company}
                        />
                    ) : (
                        <ExhibitorNoPhoto fill={colors.grey} />
                    )}
                </div>
                <p>
                    <span
                        className={`${exhibitor.company.length < 23 ? 'd-flex' : ''} ellipsis-second-line vote-support`}
                    >
                        {' '}
                        {exhibitor.company}
                    </span>
                    {isVotingEnabled && votingPrivalages && (
                        <span className={`vote-number ${isMobile ? 'mobile' : ''}`}>
                            {votingPrivalages && singleExhibitorsVoteCount && (
                                <>
                                    <Online />
                                    {(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id]) || 0}{' '}
                                    {singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id] > 1
                                        ? votesText
                                        : voteText}
                                </>
                            )}
                        </span>
                    )}
                </p>
            </div>

            {isVotingEnabled &&
                (doesOrganizerHasParticipantProfile(userEventProfile) ? (
                    <div className={`vote-action`} onClick={handleChangeVote(exhibitor?._id)}>
                        {voteLoading && exhibitorInProgress === exhibitor?._id && (
                            <div className={`spinner-container-vote vote-banner-top`}>
                                <Spinner />
                            </div>
                        )}
                        <Checkbox checked={isVoted ?? ''} />
                        <span className={`vote-text`}> {isVoted ? votedText : voteText}</span>
                    </div>
                ) : (
                    <Tooltip title={toVotePleaseEnableParticipantProfile} arrow>
                        <div className={`vote-action disabled`}>
                            {voteLoading && exhibitorInProgress === exhibitor?._id && (
                                <div className={`spinner-container-vote vote-banner-top`}>
                                    <Spinner />
                                </div>
                            )}
                            <Checkbox checked={isVoted ?? ''} disabled />
                            <span className={`vote-text`}> {isVoted ? votedText : voteText}</span>
                        </div>
                    </Tooltip>
                ))}
        </div>
    );
};

export default SingleExhibitorMobile;
