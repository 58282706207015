import {useSelector} from 'react-redux';
import {allTranslations} from '../../Utils/Translations/all-translations';

const FALLBACK_TRANSLATION_SLUG = 'en';

const usePlatformTranslation = (selector = (translations) => translations) => {
    const languages = useSelector((state) => state.languages);
    // NOTE: this is bug workaround, platform language should be 'ch', but it comes as 'zh' and then it crashes
    const slug = languages.platformLanguage === 'zh' ? 'ch' : languages.platformLanguage;
    const eventTranslation = allTranslations[slug].content;
    const fallbackTranslation = allTranslations[FALLBACK_TRANSLATION_SLUG].content;
    return selector(eventTranslation) || selector(fallbackTranslation) || selector(fallbackTranslation) || '';
};

export default usePlatformTranslation;
