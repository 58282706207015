import React, {useEffect, useState} from 'react';
import Header from './Header';
import './consentCollection.scss';
import {useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import axios from 'store/axios-instance';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import PPUBackground from './assets/background.jpg';
import {usePlatformTranslation} from '../../../services/hooks';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import {updateHyperlinksFromText} from "../../../Utils/utils";
import {notificationReceived} from '../../../Api/socketApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox} from '@material-ui/core';

const ConsentCollection = () => {

    const [loading, setLoading] = useState(false);
    const [receivedNewNotificationCounter, setReceivedNewNotificationCounter] = useState(0);
    const user = useSelector(state => state.user?.data);
    const eventSlug = useSelector(state => state.event?.eventSlug);
    const history = useHistory();
    const acceptText = usePlatformTranslation((state) => state.generalText.accept);
    const publishedByText = usePlatformTranslation((state) => state.privacyPolicyUpdates.publishedBy);
    const ppuLinkLabel = usePlatformTranslation((state) => state.privacyPolicyUpdates.ppuLinkLabel);
    const ppuEventLinkLabel = usePlatformTranslation((state) => state.privacyPolicyUpdates.ppuEventLinkLabel);
    const acceptAllButtonLabel = usePlatformTranslation((state) => state.privacyPolicyUpdates.acceptAllButtonLabel);
    const agreeCheckboxLabel = usePlatformTranslation((state) => state.privacyPolicyUpdates.agreeCheckboxLabel);

    const [privacyPolicyNotifications, setPrivacyPolicyNotifications] = useState([]);
    const [acceptedPrivacyPolicyNotifications, setAcceptedPrivacyPolicyNotifications] = useState([]);


    useEffect(() => {
        // when the user presses back button we want to redirect him to the landing page
        return () => {
            if (history.action === 'POP') {
                history.go(1);
            }
        };
    }, [history]);

    useEffect(() => {
        const url = eventSlug ? `/privacy-policy-updates?eventSlug=${eventSlug}` : "/privacy-policy-updates";
        setLoading(true);
        axios.get(url)
            .then((res) => {
                setLoading(false);
                const privacyPolicyNotifications = res.data.data;
                // this is in case the user presses "back button"
                if (privacyPolicyNotifications?.length === 0) { return window.location.href = '/'; }
                setPrivacyPolicyNotifications(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
    }, [eventSlug, receivedNewNotificationCounter])

    notificationReceived((err, data) => {
        const notification = data.notification;
        // if we are on the consent collection page and we receive a new notification
        // we want to refetch the privacyPolicyNotifications from the server
        if (notification.type === 'reloadConsentCollection') {
            setReceivedNewNotificationCounter(receivedNewNotificationCounter + 1);
        }
    })

    const handleAcceptPrivacyPolicyUpdates = () => {
        let privacyPolicyIds = []
        privacyPolicyNotifications.forEach((notification) => {
            privacyPolicyIds.push(notification._id);
        })
        setLoading(true);
        axios.post(`/privacy-policy-updates/mark-as-read`, {privacyPolicyIds})
            .then((res) => {
                setLoading(false);
                let redirectUrl = '/';
                if(eventSlug){
                    redirectUrl = `/event/${eventSlug}/lobby`;
                }
                window.location.href = redirectUrl;
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
    }

    const getPrivacyPolicyNotificationLink = (privacyPolicyNotification) => {
        if(privacyPolicyNotification?.event?.slug) {
            return `/event/${privacyPolicyNotification.event.slug}/event-privacy-policy`;
        }
        return '/privacy-policy';
    }

    const getPrivacyPolicyNotificationPublishedDate = (privacyPolicyNotification) => {
        const date = new moment(privacyPolicyNotification.updatedAt);
        return date.format('MMMM DD, YYYY');
    }

    const ppuIsFromEventWhereUserIsOrganizer = (privacyPolicyNotification) => {
        const userIsOrganizer = user?.eventRoles
            .find((role) => role.event.slug === eventSlug)
            ?.roles.includes('organizer');
        return privacyPolicyNotification?.event?.slug === eventSlug && userIsOrganizer;
    }

    // we need to display on privacy policy link
    // for the group of ppus
    // that either have event property or not
    const shouldDisplayPrivacyPolicyLink = (privacyPolicyNotificationIndex) => {
        const privacyPolicyNotificationsLength = privacyPolicyNotifications.length;

        // we need to display the link if we are at the last ppu
        if(privacyPolicyNotificationIndex === privacyPolicyNotificationsLength - 1) {
            return true;
        }

        // or if the previous ppu had an eventSlug property and the current one doesn't
        // or if the previous ppu didn't have an eventSlug property and the current one does
        const currentPrivacyPolicyNotification = privacyPolicyNotifications[privacyPolicyNotificationIndex];
        const nextPrivacyPolicyNotification = privacyPolicyNotifications[privacyPolicyNotificationIndex + 1];
        const currentPrivacyPolicyNotificationHasEventSlug = currentPrivacyPolicyNotification?.event?.slug;
        const nextPrivacyPolicyNotificationHasEventSlug = nextPrivacyPolicyNotification?.event?.slug;
        return currentPrivacyPolicyNotificationHasEventSlug !== nextPrivacyPolicyNotificationHasEventSlug;
    }

    const privacyPolicyNotificationsWithoutTheOnesFromOrganizer = () => {
        return privacyPolicyNotifications.filter((privacyPolicyNotification) => {
            return !ppuIsFromEventWhereUserIsOrganizer(privacyPolicyNotification);
        })
    }

    const toggleAcceptSinglePrivacyPolicyNotification = notificationId => e => {
        const accept = e.target.checked;
        if(accept) {
            setAcceptedPrivacyPolicyNotifications([...acceptedPrivacyPolicyNotifications, notificationId]);
        } else {
            setAcceptedPrivacyPolicyNotifications(acceptedPrivacyPolicyNotifications.filter(id => id !== notificationId));
        }
    }

    const checkIfAllPrivacyPolicyNotificationsAreAccepted = () => {
        return privacyPolicyNotificationsWithoutTheOnesFromOrganizer().length === acceptedPrivacyPolicyNotifications.length;
    }


    return (
        <div className={`user-consent-collection-updates-page`}>
            {loading && <Spinner/>}
            <Header privacyPolicyNotifications={privacyPolicyNotifications}/>
            <div className='user-consent-collection-updates-list'>
                {privacyPolicyNotifications.map((privacyPolicyNotification, privacyPolicyNotificationIndex) => {
                    if (ppuIsFromEventWhereUserIsOrganizer(privacyPolicyNotification)) {
                        return null;
                    }
                    return (
                        <div className={`single-privacy-policy-notification`} key={privacyPolicyNotification._id}>
                            <h3>{privacyPolicyNotification.title}</h3>
                            <p className={'updated-text'}>
                                {publishedByText} {privacyPolicyNotification?.event?.name || 'myOnvent'}
                            </p>
                            <div className={'single-privacy-policy-description-text'}
                                 dangerouslySetInnerHTML={{__html: updateHyperlinksFromText(privacyPolicyNotification.description)}}
                            />
                            <div className={'single-privacy-policy-notification-accept-checkbox'}>
                                <FormControlLabel
                                    control={<Checkbox name="checkedA" color={'secondary'}/>}
                                    label={agreeCheckboxLabel}
                                    value={privacyPolicyNotification._id}
                                    onChange={toggleAcceptSinglePrivacyPolicyNotification(privacyPolicyNotification._id)}
                                />
                            </div>

                            {shouldDisplayPrivacyPolicyLink(privacyPolicyNotificationIndex) && (
                                <a href={getPrivacyPolicyNotificationLink(privacyPolicyNotification)}
                                   target={'_blank'}
                                   className={'single-privacy-policy-link'}
                                >
                                    {privacyPolicyNotification?.event?.name ? ppuEventLinkLabel : ppuLinkLabel}
                                </a>
                            )}

                        </div>
                    )
                })}
            </div>
            <div className='accept-privacy-policy-updates-button-container'>
                <Button variant={'contained'}
                        color={'secondary'}
                        disableElevation={true}
                        type={'button'}
                        onClick={handleAcceptPrivacyPolicyUpdates}
                        disabled={!checkIfAllPrivacyPolicyNotificationsAreAccepted()}
                >
                    {privacyPolicyNotificationsWithoutTheOnesFromOrganizer()?.length > 1 ? acceptAllButtonLabel : acceptText}
                </Button>
            </div>
            <img src={PPUBackground} className={'user-consent-collection-updates-page-background-image'}/>
        </div>
    );
};

export default ConsentCollection;