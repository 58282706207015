import React, {Component} from 'react';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../../../../Utils/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {isSafari} from 'react-device-detect';
import './TypeStyles.scss';

class Type extends Component {
    state = {
        typeOptions: [
            {name: 'video', value: 'video', label: 'Video'},
            {name: 'picture', value: 'picture', label: 'Picture'},
        ],
    };

    render() {
        const {typeOptions} = this.state;
        const {type, handleChangeType} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="options-container">
                <div onDragStart={preventDefaultDrag} className="single-option-container type">
                    <RadioGroup
                        tabIndex="-1"
                        aria-label="role"
                        name="filterRole"
                        value={type}
                        onChange={handleChangeType}
                        className={`${isSafari && 'safari-safe'} types-list`}
                    >
                        {typeOptions.map((typeOption) => {
                            return (
                                <FormControlLabel
                                    tabIndex={'0'}
                                    key={typeOption.value}
                                    value={typeOption.value}
                                    control={<Radio tabIndex="-1" color={'secondary'} />}
                                    label={typeOption.label}
                                />
                            );
                        })}
                    </RadioGroup>
                </div>
                <div onDragStart={preventDefaultDrag} className="input-description"></div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(Type);
