import React, {PureComponent} from 'react';
import {getFormatedDate, getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../Utils/utils';
import {Link} from 'react-router-dom';
import {ReactComponent as PlayIcon} from '../../Images/svg/play.svg';
import callAxios from 'axios';
import {connect} from 'react-redux';
import Spinner from '../../SmallLayoutComponents/Spinner';
import {isVimeoLinkRule, isWistiaLinkRule, isYoutubeLinkRule} from '../../Utils/validationRules';
import './EventArchiveVideoItemStyles.scss';

class EventArchiveVideoItem extends PureComponent {
    state = {
        thumbnailVimeoImage: null,
        thumbnailImage: null,
        spinner: false,
        vimeoStreaming: false,
        wistiaStreaming: false,
    };

    componentDidMount() {
        this.setThumbnailImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.video !== this.props.video) {
            this.setThumbnailImage();
        }
    }

    setThumbnailImage = () => {
        const {video} = this.props;
        const isYtbVideo = this.checkIsYtbVideo();

        // we can't use if (video.streamingEngine === 'Vimeo')
        // because if you manually add a video from the event archive
        // the default streaming engine added is Youbube
        // so to fix this I actually check the video.url to determine the video engine used

        if (!isYtbVideo) {
            let ruleVimeo = isVimeoLinkRule;
            let ruleWistia = isWistiaLinkRule;

            if (ruleVimeo.test(video.url)) {
                this.setState({
                    spinner: true,
                });
                callAxios.get(`https://vimeo.com/api/oembed.json?url=${video?.url}`).then((res) => {
                    this.setState({
                        thumbnailVimeoImage: `${res.data.thumbnail_url}.jpg`,
                        spinner: false,
                        vimeoStreaming: true,
                    });
                });
            } else if (ruleWistia.test(video.url)) {
                let videoUrl = video?.video || video?.url || '';

                let n = videoUrl.lastIndexOf('/');
                let result = videoUrl.substring(n + 1);
                videoUrl = result;
                if (videoUrl) {
                    this.setState({
                        spinner: true,
                    });
                    callAxios
                        .get(
                            `https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${videoUrl}?embedType=async_popover`
                        )
                        .then((res) => {
                            this.setState({
                                thumbnailImage: `${res.data.thumbnail_url}`,
                                spinner: false,
                                wistiaStreaming: true,
                            });
                        });
                }
            }
        }
    };

    checkIsYtbVideo = () => {
        const {video} = this.props;
        let ruleYTB = isYoutubeLinkRule;
        let match = ruleYTB.test(video.url);
        return match;
    };

    render() {
        const {spinner, thumbnailVimeoImage, thumbnailImage, wistiaStreaming, vimeoStreaming} = this.state;
        const {video, eventItem} = this.props;
        let imageURL = '';
        if (wistiaStreaming) {
            imageURL = thumbnailImage;
        } else if (vimeoStreaming) {
            imageURL = thumbnailVimeoImage;
        } else {
            const isYtbVideo = this.checkIsYtbVideo();
            if (isYtbVideo) {
                const videoId = getVideoIdFromYoutubeUrl(video.url);
                imageURL = `https://img.youtube.com/vi/${videoId}/0.jpg`;
            }
            // else {
            //     imageURL = thumbnailVimeoImage;
            // }
        }

        return (
            <div onDragStart={preventDefaultDrag} className="archive-video" key={video._id}>
                <div onDragStart={preventDefaultDrag} className="video-preview-container">
                    <Link
                        to={{
                            pathname:
                                '/event/' +
                                this.props.event.slug +
                                '/event-archive/event/' +
                                eventItem._id +
                                '/' +
                                video._id,
                        }}
                    >
                        <div onDragStart={(e) => e.preventDefault()} className="clickable-element">
                            <div onDragStart={(e) => e.preventDefault()} className="image-preview-video">
                                {spinner && <Spinner />}
                                <img src={imageURL} alt="preview video" />
                                {/*remove d-none to show duration*/}
                                <div onDragStart={(e) => e.preventDefault()} className="video-duration d-none">
                                    <span>40:16</span>
                                </div>
                                <div onDragStart={(e) => e.preventDefault()} className="overlay" />
                                <div onDragStart={(e) => e.preventDefault()} className="play-button">
                                    <PlayIcon />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div onDragStart={preventDefaultDrag} className="video-details-container">
                    <div onDragStart={(e) => e.preventDefault()} className="speakers-container ellipsis help-arch">
                        {video.speakers.length
                            ? video.speakers.map((speaker, index) => {
                                  return (
                                      <span className="speaker " key={index}>
                                          {speaker}
                                          {index !== video.speakers.length - 1 ? ', ' : null}
                                      </span>
                                  );
                              })
                            : null}
                    </div>
                    <Link
                        to={{
                            pathname:
                                '/event/' +
                                this.props.event.slug +
                                '/event-archive/event/' +
                                eventItem._id +
                                '/' +
                                video._id,
                        }}
                    >
                        {video.title}
                    </Link>
                    <div onDragStart={(e) => e.preventDefault()} className="program-date-hash">
                        <span draggable="false" className="date">
                            {eventItem.eventDate ? getFormatedDate(eventItem.eventDate) : eventItem.eventDate}
                        </span>
                        <span draggable="false" className="hashtag">
                            {video.hashtags.length > 0 && video.hashtags.map((hashtag) => `${hashtag} `)}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
    };
};

export default connect(mapStateToProps)(EventArchiveVideoItem);
