const ua = {
    generalText: {
        upload: 'Завантажити',
        save: 'Зберегти',
        add: 'Додати',
        remove: 'Видалити',
        close: 'Закрити',
        update: 'Оновити',
        cancel: 'Відмінити',
        confirm: 'Підтвердити',
        download: 'Скачати',
        refresh: 'Оновити',
        back: 'Назад',
        submit: 'Надіслати',
        send: 'Надіслати',
        leave: 'Вийти',
        step1: 'Крок 1',
        step2: 'Крок 2',
        or: 'або',
        delete: 'Видалити',
        please: 'Будь ласка',
        before: 'до',
        approve: 'Затвердити',
        decline: 'Відхилити',
        start: 'Почати',
        stop: 'Завершити',
        resetFilter: 'Скинути фільтр',
        apply: 'Застосувати',
        filters: 'Фільтри',
        unsavedChangesTitle: 'Незбережені зміни',
        unsavedChangesText: 'У вас є незбережені зміни. Бажаєте збереги їх?',
        selectAll: 'Обрати все',
        unselectAll: 'Скасувати вибір усіх',
    },
    time: {
        justNow: 'тільки що',
        minute: 'хвилина',
        minutes: 'хвилини',
        hour: 'година',
        hours: 'години',
        day: 'день',
        days: 'дні',
        month: 'місяць',
        months: 'місяці',
        yesterday: 'вчора',
        today: 'сьогодні',
        tomorrow: 'завтра',
        monday: 'понеділок',
        tuesday: 'вівторок',
        wednesday: 'середа',
        thursday: 'четвер',
        friday: "п'ятниця",
        saturday: 'субота',
        sunday: 'неділя',
        january: 'січень',
        february: 'лютий',
        march: 'березень',
        april: 'квітень',
        may: 'травень',
        june: 'червень',
        july: 'липень',
        august: 'серпень',
        september: 'вересень',
        october: 'жовтень',
        november: 'листопад',
        december: 'грудень',
        januaryShort: 'січ.',
        februaryShort: 'лют.',
        marchShort: 'бер.',
        aprilShort: 'квіт.',
        mayShort: 'трав.',
        juneShort: 'черв.',
        julyShort: 'лип.',
        augustShort: 'серп.',
        septemberShort: 'вер.',
        octoberShort: 'жовт.',
        novemberShort: 'лист.',
        decemberShort: 'груд.',
    },
    errors: {
        noFile: 'Файл не вибрано',
        required: "Поле є обов'язковим",
        emailNotValid: 'Імейл не дійсний',
        passwordMinLength: 'Пароль має містити не менше 8 символів',
        passwordMismatch: 'Пароль не співпадає',
        passwordIsSame: 'Ваш новий пароль має відрізнятися',
        noInvitationCode: 'Будь ласка, введіть код запрошення',
        imageFileNotSupported: 'Тип файлу не підтримується. Будь ласка, оберіть один із наступних:',
        phoneInvalid: 'Будь ласка, введіть дійсний номер телефону. Наприклад: +447517663928',
        linkInvalid: 'Будь ласка, введіть дійсне посилання (https://site.com)',
        facebookLinkInvalid: 'Будь ласка, введіть дійсне посилання (https://facebook.com/)',
        linkedinLinkInvalid: 'Будь ласка, введіть дійсне посилання (https://linkedin.com/)',
        twitterLinkInvalid: 'Будь ласка, введіть дійсне посилання (https://twitter.com/)',
        youtubeLinkInvalid: 'Будь ласка, введіть дійсне посилання (https://www.youtube.com/)',
        youtubeOrVimeoLinkInvalid:
            'Будь ласка, введіть дійсне YouTube (https://www.youtube.com) або Vimeo (https://vimeo.com/) посилання',
        googleScholarLinkInvalid: 'Будь ласка, введіть дійсне посилання (https://scholar.google.com/)',
        researchGateLinkInvalid: 'Будь ласка, введіть дійсне посилання (https://www.researchgate.net/)',
        connectionProblemTextFirst: "Здається, виникла проблема зі з'єднанням. Будь ласка",
        connectionProblemButton: 'НАТИСНІТЬ',
        connectionProblemTextSecond: 'або оновіть сторінку',
        fileTooLarge: 'Файл завеликий. Максимальний розмір файлу - 2Мб',
        fileTooLargeTextFirst: 'Файл завеликий.',
        fileTooLargeTextSecond: 'максимальний розмір файлу.',
        fileNameTooLongTextFirst: 'Назва файлу задовга. Ліміт',
        fileNameTooLongTextSecond: 'символів.',
        maxWallInputLength: 'Ви досягли максимальної кількості в 1200 символів.',
    },
    footer: {
        cookiesText:
            'Цей веб-сайт використовує необхідні файли cookie, що потрібні для його функціонування. Він також використовує сторонні файли cookie, які необхідні для функціонування деяких функцій нашого веб-сайту. Якщо ви хочете дізнатися більше або відкликати свою згоду на деякі файли cookie, зверніться до нашої',
        cookiesLink: 'Політики cookie',
        cookiesButton: 'ПРИЙНЯТИ',
        privacyPolicy: 'Політика конфіденційності',
        cookies: 'Cookies',
        termsAndConditions: 'Правила та умови',
        socialLinksTitle: 'Слідкуйте за нами:',
    },
    menu: {
        logIn: 'Увійти',
        logOut: 'Вийти',
        scholarRegistration: 'Реєстрація авторів робіт/досліджень',
        exhibitorRegistration: 'Реєстрація експонентів',
        participantRegistration: 'Реєстрація учасників',
        createEvent: 'Організувати подію',
        loginDisabledInfoText: 'Організатор закрив вхід на подію. Будь ласка, поверніться пізніше',
    },
    homepage: {
        organizedBy: 'ОРГАНІЗАТОР',
        date: 'ДАТА',
        location: 'СТОРІНКА ПОДІЇ ТА МІСЦЕ ПРОВЕДЕННЯ',
        price: 'ВАРТІСТЬ',
        tabIntro: 'ОПИС ПОДІЇ',
        tabProgram: 'ПРОГРАМА',
        tabSponsors: 'СПОНСОРИ',
        tabPartners: 'ПАРТНЕРИ',
        tabExhibitors: 'ЕКСПОНЕНТИ',
        previousEventsSectionTitle: 'ПОПЕРЕДНІ ПОДІЇ',
    },
    socialButtons: {
        linkedinButton: 'Увійти через Linkedin акаунт',
        loginTabLinkedinButton: 'через LinkedIn',
        facebookButton: 'Увійти через Facebook акаунт',
        loginTabFacebookButton: 'через Facebook',
        googleButton: 'Увійти через Google акаунт',
        loginTabGoogleButton: 'через Google',
        twitterButton: 'Увійти через Twitter акаунт',
        loginTabTwitterButton: 'через Twitter',
        socialButtonsLogInTitle: 'Доєднатися за допомогою профіля у соцмережах',
        socialButtonsSignUpTitle: 'Зареєструватися за допомогою свого профілю в соцмережах',
    },
    login: {
        inputEmail: 'Електронна пошта',
        inputPassword: 'Пароль',
        rememberMeButton: "Запам'ятати",
        forgotPasswordButton: 'Забули',
        loginButton: 'Увійти',
        loginClosedButton: 'Вхід закрито',
        loginTitleLowerCase: 'вхід',
        recoverPasswordDialogTitle: 'ВІДНОВИТИ ПАРОЛЬ',
        recoverPasswordDialogRecoverButton: 'ВІДНОВИТИ',
        errorIncorrectDates: 'Неправильна елекронна пошта чи пароль',
        orLogInWithEmail: 'або увійдіть за допомогою електронної пошти',
        logInAsParticipantSecondText: 'як учасник',
        logInAsExhibitorSecondText: 'як експонент',
        logInAsPosterSecondText: 'як автор роботи/дослідження',
        errorAccountNotFound: 'Ми не змогли знайти ваш myOnvent-акаунт',
    },
    register: {
        inputFirstName: "Ім'я",
        inputLastName: 'Прізвище',
        inputEmail: 'Електронна пошта',
        inputPassword: 'Пароль',
        inputConfirmPassword: 'Підтвердити пароль',
        gdprTextFirst: 'Продовжуючи, ви погоджуєтеся із нашими',
        gdprTextTerms: 'Умовами використання',
        gdprTextSecond: 'та підтверджуєте, що прочитали нашу',
        gdprTextCookies: 'Cookie',
        gdprTextPrivacy: 'Політику конфіденційності',
        gdprTextAgree: 'Будь ласка, погодьтеся із політикою конфіденційності',
        registerButton: 'Зареєструватися',
        signUpTitleLowerCase: 'зареєструватися',
        invitationCode: 'Код запрошення',
        registerNotAllowed: 'Реєстрацію закрито',
        logInTitleFirst: 'Доєднатися',
        logInTitleSecond: 'подія',
        signUpTitleTextFirst: 'Вперше на',
        signUpTitleTextSecond: 'myOnvent',
        orFillInTheForm: 'або заповніть форму нижче',
        uploadProfilePicture: 'Завантажте фото профіля',
        editProfilePicture: 'Відредагуйте фото профіля',
        errorAlreadyHaveMOAccount: 'У вас вже є акаунт на myOnvent',
        step1From2Text: 'Крок 1 із 2',
        step2From2Text: 'Крок 2 із 2',
        metaTitleScholarRegistration: 'Реєстрація робіт/досліджень',
    },
    newLoginRegistrations: {
        joinEvent: 'ПРИЄДНАТИСЯ ДО ПОДІЇ',
        loginWelcome: 'Ласкаво просимо до myOnvent! Щоб продовжити, виберіть спосіб входу',
        continueButton: 'Продовжити',
        seemsLikeYouDontHaveMyOnventAccount: 'Здається, у вас немає облікового запису myOnvent',
        uploadProfilePictureOptional: 'Завантажити фото профілю (необов’язково)',
        createPlatformAccount: 'Створити обліковий запис на платформі',
        platformAccount: 'Обліковий запис на платформі',
        selectRole: 'Виберіть роль',
        eventAccount: 'Обліковий запис на подію',
        password: 'Пароль',
        confirmPassword: 'Підтвердіть пароль',
        email: 'Електронна пошта',
        passwordMustContain: 'Пароль повинен містити:',
        minimum8Characters: 'Мінімум 8 символів',
        aLowerCaseLetter: 'Малу літеру',
        anUppercaseLetter: 'Велику літеру',
        aNumber: 'Цифру',
        aSpecialCharacter: 'Спеціальний символ',
        firstName: 'Ім’я',
        lastName: 'Прізвище',
        weHaveSentAVerificationCodeToYourEmail: 'Ми надіслали вам код підтвердження на електронну пошту',
        continueRegistration: 'Щоб продовжити реєстрацію,',
        enterCodeBelow: 'введіть код підтвердження нижче:',
        codeWillExpire: 'Цей код застаріє через',
        codeExpired: 'Термін дії коду минув',
        codeNotReceived: 'Не отримали код? Перевірте папку Спам',
        resendTimer: 'Надіслати знову через:',
        resendLabel: 'Надіслати знову',
        resendCodeLabel: 'Надіслати код ще раз',
        codeError: 'Введений код неправильний.',
        codeErrorFix: 'Введіть правильний код з електронної пошти або надішліть новий код',
        congrats: 'Вітаємо!',
        emailConfirmed: 'Вашу електронну пошту підтверджено:',
        imHereAs: 'Я тут як:',
        participant: 'Учасник',
        participantText: 'Для перегляду трансляції, спілкування, візиту до виставок і стендів.',
        exhibitor: 'Експонент',
        exhibitorText: 'Презентувати вашу компанію та спілкуватися з учасниками події',
        poster: 'Постер/Дослідження',
        posterText: 'Презентувати дослідження для наукової або професійної конференції',
        emailIsNotAllowed: 'Ця електронна адреса не дозволяється зареєструватися на цю подію',
        didntReceiveEmail: 'Не отримали електронного листа?',
        sendAgain: 'Відправити знову ',
        incorrectPassword: 'Неправильний пароль',
        fileTypeError:
            'Тип файлу не підтримується. Будь ласка, використовуйте один з наступних типів: jpeg, jpg, jfif, gif або png.',
        fileSizeError: 'Файл занадто великий. Максимальний розмір файлу: 2 МБ.',
        pleaseCompleteAllRequiredFields: "Будь ласка, заповніть всі обов'язкові поля",
        joinTheEvent: 'Приєднайтесь до події',
        joinTheEventSub: 'Щоб розпочати вашу віртуальну досвідчення онлайн-події',
        changeEmail: 'ЗМІНИТИ EMAIL',
        changeRole: 'ЗМІНИТИ РОЛЬ',
        logout: 'ВИЙТИ',
        headingUser: 'Ласкаво просимо назад',
        subHeadingUser: 'Здається, ви вже маєте обліковий запис на платформі',
        forgotPasswordHeadline: 'Забули пароль',
        forgotPasswordText: 'Не хвилюйтеся, ми надішлемо вам додаткові інструкції',
        checkYourEmail: 'Перевірте свою електронну пошту',
        checkYourEmailText: 'Ми надіслали посилання для скидання пароля на вашу електронну пошту:',
        headingNoUser: 'Увійти',
        subHeadingNoUser: 'Ласкаво просимо на myOnvent! Щоб продовжити, виберіть метод входу',
        resetPasswordButton: 'скинути пароль',
        createNewPassword: 'створити новий пароль',
        createNewPasswordText: 'Ваш новий пароль повинен відрізнятися від раніше використовуваних паролів',
        passwordReset: 'сброс пароля',
        passwordResetText: 'Ваш пароль успішно скинуто. Клікніть нижче, щоб знову увійти в систему.',
        registrationIsClosed: 'Реєстрація для всіх ролей закрита.',
        chooseAnOption: 'Виберіть варіант',
        yourAnswer: 'Твоя відповідь',
        firstNameError: "Ім'я занадто коротке. Мінімальна довжина: 2",
        lastNameError: 'Прізвище занадто коротке. Мінімальна довжина: 2',
        recoveryPassword: 'Відновити пароль',
        incorrectPasswordError:
            'Неправильний пароль. Спробуйте ще раз або натисніть "Забули пароль?", щоб змінити його.',
        optional: "Необов'язково",
        spamEmailError: 'Під час створення вашого облікового запису виникла проблема. Будь ласка, спробуйте пізніше.',
        invalidInput:
            "Неприпустимий ввід. Використовуйте лише літери (A-Z, a-z), цифри (0-9), крапку (.), апостроф ('), дефіс (-) та пробіли. Перший символ повинен бути літерою.",
    },
    emailUpdate: {
        changeEmail: 'Змінити електронну адресу',
        changeEmailDescription:
            'Перед тим, як ми призначимо нову електронну адресу вашому обліковому запису, ми хочемо бути впевнені, що це нова електронна адреса і вона не належить нікому.',
        emailTakenError: 'Ця електронна адреса вже використовується. Спробуйте іншу.',
        enterTheConfirmationCode: 'Введіть код підтвердження',
        verificationCodeSent: 'Код підтвердження надіслано:',
        checkYourSpamFolder: 'Не можете знайти код? Перевірте папку зі спамом',
        emailSuccess: 'Успіх! Ви змінили електронну адресу, пов’язану з вашим обліковим записом',
        emailError: 'Помилка. Спробуйте пізніше',
        confirmPassword: 'Підтвердити пароль',
        confirmPasswordDescription: 'Щоб змінити електронну адресу, підтвердьте свій пароль:',
        newEmail: 'Нова електронна адреса',
        password: 'Пароль',
        confirm: 'Підтвердити',
        cancel: 'Скасувати',
        change: 'Змінити',
    },

    agendaTranslations: {
        myAgenda: 'МОЯ ПРОГРАМА',
        all: 'Усі',
        sessions: 'Сесії',
        meetings: 'Зустрічі',
        pastSlots: 'Минулі слоти',
        meetingWith: 'Зустріч з',
        pending: 'В очікуванні',
        accepted: 'Прийнято',
        requested: 'Запитано',
        canceled: 'Скасовано',
        now: 'Зараз',
        live: 'Пряма трансляція',
        noMeetings: 'Поки що немає запланованих зустрічей',
        noSessions: 'Поки що немає сесій у вашій програмі.',
        noUpcomingSessions: 'Поки що немає майбутніх сесій у вашій програмі',
        noUpcomingMeetings: 'Поки що немає майбутніх зустрічей у вашій програмі.',
        noUpcomingAgenda: 'Поки що немає запланованих подій у вашій програмі.',
        noAgendaItem: 'Поки що немає подій у вашій програмі.',
        meetingRequests: 'ЗАПИТИ НА ЗУСТРІЧ',
        finished: 'Завершено',
        past: 'Минуле',
        goToMeeting: 'ПЕРЕЙТИ НА ЗУСТРІЧ',
        addToMyAgenda: 'Додати до моєї програми',
        addedToYourAgenda: 'Додано до вашої програми',
        acceptedAllMeetings: 'Прийняти всі зустрічі',
    },
    participantRegistration: {
        metaTitle: 'Реєстрація учасників',
        choosePackageTitle: 'ОБЕРІТЬ ТИП УЧАСТІ',
        noPackageText: 'Будь ласка, оберіть тип участі',
        gdprTextFirst: 'Продовжуючи, ви погоджуєтеся із нашою',
        gdprTextPrivacy: 'Політикою конфіденційності події',
        gdprTextSecond: 'та',
        gdprTextTerms: 'Правилами та умовами події',
        gdprTextAgree: 'Будь ласка, погодьтеся із політикою конфіденційності',
        registerButtonFree: 'Завершити реєстрацію',
        registerButtonPay: 'Перейти на сторінку оплати',
    },
    exhibitorRegistration: {
        metaTitle: 'Реєстрація експонентів',
        choosePackageTitle: 'ОБЕРІТЬ ТИП УЧАСТІ',
        gdprTextFirst: 'Продовжуючи, ви погоджуєтеся із нашою',
        gdprTextPrivacy: 'Політикою конфіденційності події',
        gdprTextSecond: 'та',
        gdprTextTerms: 'Правилами та умовами події',
        gdprTextAgree: 'Будь ласка, погодьтеся із політикою конфіденційності',
        registerButtonFree: 'Завершити реєстрацію',
        registerButtonPay: 'Перейти на сторінку оплати',
    },
    stripePayment: {
        cardNumber: 'Номер карти',
        expirationDate: 'Термін дії (ММ/РР)',
        cvc: 'CVC',
        disclaimerText:
            'Ця платіжна операція зашифрована наскрізним шифруванням. Компанія Stripe Services гарантує безпеку та конфіденційність.',
        disclaimerTextLink: 'Натисніть, щоб дізнатися більше.',
        cancelButton: 'Відмінити',
        payButton: 'Заплатити',
    },
    recoverPassword: {
        recoverTextSend: 'Листа для відновлення паролю успішно надіслано.',
    },
    unsubscribe: {
        unsubscribeTitle: 'Відписатися?',
        unsubscribeHomepage: 'Перейти на домашню сторінку',
    },
    notAvailable: {
        title: 'Ця подія недоступна',
        description: 'Подія, яку ви шукаєте, недоступна. Її було закрито організатором або відмінено адміністратором.',
        fullOverlayEvents: 'Перегляньте ваші інші події',
        fullOverlayNoEvents: 'Жодних інших подій не знайдено',
    },
    notFound: {
        notFoundDescription: 'Упс...  Жодної події не знайдено!',
        notFoundHomepage: 'Перейти на домашню сторінку',
    },
    eventMenu: {
        organizerButton: 'ОРГАНІЗАТОР',
        coOrganizerButton: 'КЕРУВАТИ',
        exhibitorButton: 'ЕКСПОНЕНТ',
        posterStudyButton: 'РОБОТА/ДОСЛІДЖЕННЯ',
        boothButton: 'СТЕНД',
        boothsButton: 'СТЕНДИ',
        homeButton: 'ДОДОМУ',
        contactsButton: 'КОНТАКТИ',
        meetingsButton: 'ЗУСТРІЧІ',
        chatsButton: 'РОЗМОВИ',
        programButton: 'ПРОГРАМА',
        archiveButton: 'АРХІВ',
        myAccountButton: 'МІЙ АКАУНТ',
        lobbyButton: 'ЛОБІ',
        roomsButton: 'КІМНАТИ',
        connectionProblemTextFirst: "Здається, виникла проблема зі з'єднанням. Будь ласка, натисніть",
        connectionProblemButton: 'ТУТ',
        connectionProblemTextSecond: 'або оновіть сторінку.',
        sessionProblemTextFirst: 'Ваша сесія закінчилася. Будь ласка, натисніть',
        sessionProblemButton: 'ТУТ,',
        sessionProblemTextSecond: 'щоб знову увійти.',
    },
    myEventsDropdown: {
        title: 'МОЇ ПОДІЇ',
        createButtonMobile: 'Створити',
    },
    myBoothsDropdown: {
        title: 'МОЇ СТЕНДИ',
        emptyStudyTitle: 'Стенд роботи/дослідження',
    },
    contactsDropdown: {
        title: 'ВАШІ КОНТАКТИ',
        requestsTitle: 'Запити на контакт',
        rejectButton: 'Відхилити',
        acceptButton: 'Приняти',
        pendingButton: 'Очікується',
        addButton: 'Додати',
        noContacts:
            'У вас ще немає жодного контакту. Будь ласка, відвідайте нетворкінг секцію, щоб почати створювати свій список контактів.',
    },
    meetingsDropdown: {
        meetingWith: 'Зустріч із',
        pending: 'На схваленні',
        accepted: 'Прийнято',
        canceled: 'Відмінено',
        noMeetings: 'Жодних зустрічей ще не заплановано',
        finished: 'Закінчено',
    },
    programDropdown: {
        stages: 'ЕТАПИ',
        with: 'з',
        noProgram: 'Наразі немає доступних записів програми.',
        fullProgramButton: 'Повна програма', // new translation
        addToCalendarButton: 'Додати до календаря', // new translation
        addedToYourAgenda: 'ДОДАНО ВАШОМУ РОЗКЛАДУ',
        addEventButton: 'Додати подію', // new translation
        addToMyAgenda: 'Додати до мого розкладу', // new translation
        addEventToYourCalendarDialogTitle: 'Додати подію до вашого календаря', // new translation
        addTimeslotToYourCalendar: 'Додати часовий інтервал до вашого календаря', // new translation
        tip: 'ПОРАДА: ', // new translation
        tipDescription:
            'Ви також можете додавати окремі часові інтервали до вашого календаря, натиснувши на піктограму календаря поруч з кожним часовим інтервалом.', // new translation
        tipTimeslotDescription: 'Ви також можете додати всю подію, натиснувши кнопку "Додати до календаря".', // new translation
        durationEventTitle: 'Тривалість події: ', // new translation
        timeslotTitle: 'Назва: ', // new translation
        slotScheduled: 'Запланований часовий інтервал: ', // new translation
        addSlotToCalendar: 'Додати часовий інтервал до календаря', // new translation
        speakers: 'Доповідачі', // new translation
        auditoriumName: 'Назва аудиторії', // new translation
        timezoneTooltipText:
            'Програма відображається в часовому поясі організатора. Ви можете змінити часовий пояс відображення, щоб переглядати сесії у вибраному вами часовому поясі', // new translation
        liveLabel: 'Прямий ефір', // new translation
        liveLabelTooltipText: 'Цей часовий інтервал зараз транслюється в аудиторії', // new translation
        openAuditorium: 'ВІДКРИТИ АУДИТОРІЮ', // new translation
    },
    notificationsDropdown: {
        title: 'СПОВІЩЕННЯ',
        contactRequestReceived: 'Вам надійшов контактний запит від. Перегляньте його!',
        contactRequestAccepted: 'Ваш контактний запит до прийнято. Почніть спілкування!',
        meetingRequestReceived: 'Вам надійшов запит на зустріч від. Перегляньте його!',
        meetingRequestAccepted: 'Ваш запит на зустріч із прийнято. Перегляньте!',
        meetingRequestCanceled: 'Ваш запит на зустріч із відхилено. Перегляньте!',
        meetingStarting: 'Зустріч скоро почнеться. Перегляньте!',
        newBoothPost: "На стіні вашого стенду з'явився пост від. Перегляньте!",
        noNotifications: 'У вас ще немає жодного сповіщення',
        seeAll: 'ПЕРЕГЛЯНУТИ ВСІ',
        hideAll: 'СХОВАТИ ВСІ',
        meetingWillStartTextSecond: 'почнеться скоро. Перегляньте!',
    },
    chatsDropdown: {
        title: 'РОЗМОВИ',
        newChatProvideText: 'Будь ласка, додайте назву до вашої розмови. В подальшому назву можна змінити:',
        noChats: 'Створіть хоча б одну розмову, щоб поспілкуватися із учасниками події',
        members: 'учасників',
        noOpenedChatText: 'Натисніть на потрібну розмову, щоб продовжити спілкування.',
        noMessagesInChatTextFirst: 'Розтопіть кригу.',
        noMessagesInChatTextSecond: 'Почніть цю розмову.',
        newChatNameInput: "Назва розмови (необов'язково)",
        newChatAddMembers: 'Додайте контакти та інших учасників до цієї розмови:',
        editChatAddMembers:
            'Будь ласка, знайдіть користувачів із цієї події, яких ви хочете додати до колективної розмови.',
        newChatSearchInput: 'Знайти користувачів',
        newChatNoUsersMessage: 'Жодного користувача не знайдено',
        newChatRemoveUserButton: 'ВИДАЛИТИ',
        newChatCancelButton: 'ЗАКРИТИ',
        newChatCreateButton: 'СТВОРИТИ',
        membersLeaveButton: 'ВИЙТИ',
        membersUpdateButton: 'ОНОВИТИ',
        membersChatButton: 'РОЗМОВА',
        chatSettingsButton: 'НАЛАШТУВАННЯ',
        newMessageInput: 'Напишіть повідомлення тут',
        newVideoCallTooltipText: 'Натисніть тут, щоб почати відео-дзвінок',
        leaveVideoMeetingTitle: 'Вийти із відео-зустрічі',
        leaveVideoMeetingDescription: 'Ви збираєтеся покинути цю відео-зустріч. Чи ви впевнені, що хочете вийти?',
        leaveChatConfirmationTitle: 'Вийти із відео-дзвінка',
        leaveChatConfirmationText: 'Чи ви впевнені, що хочете вийти із цього відео-дзвінка?',
    },
    myAccountDropdown: {
        title: 'МІЙ АКАУНТ',
        viewProfileButton: 'Переглянути профіль',
        logoutButton: 'Вийти',
    },
    myAccountPage: {
        changePasswordButton: 'ЗМІНИТИ ПАРОЛЬ',
        deleteAccountButton: 'ВИДАЛИТИ МІЙ АКАУНТ',
        platformLanguage: 'Мова платформи:',
        privacyPolicyLink: 'Повідомлення про конфіденційність / політика',
        cookiesLink: 'Cookies',
        termsAndConditionsLink: 'Правила та умови',
        eventProfileSectionTitle: 'Профіль на події',
        eventPackage: 'Пакет доступу на події',
        unregisterFromEventButton: 'ВІДМІНИТИ РЕЄСТРАЦІЮ НА ПОДІЮ',
        unregisterCurrentEventButton: 'Відмінити реєстрацію на дану подію',
        unregisterCurrentEventConfirmation: 'Будь ласка, підтвердіть, що хочете відмінити реєстрацію на подію',
        enableParticipantProfile: 'Активувати профіль учасника',
        editUserDialogTitle: 'Редагувати дані користувача',
        editExhibitorDialogTitle: 'Редагувати дані контактної особи компанії',
        editScholarDialogTitle: 'Редагувати дані контактної особи роботи/дослідження',
        editParticipantDialogTitle: 'Редагувати дані учасника',
        firstName: "Ім'я",
        lastName: 'Прізвище',
        uploadImage: 'Завантажити зображення',
        removeImage: 'Видалити зображення',
        deleteError:
            'Наразі ви не можете видалити свій акаунт. Будь ласка, видаліть всі створені події, щоб мати змогу видалити акаунт.',
        deleteSuccess: 'Ваш акаунт видалено.',
        reviewEventPrivacyPolicy: 'Переглянути Політику конфіденційності події:',
        title: 'Посада',
        company: 'Компанія',
        phone: 'Номер телефону',
        platformProfileSectionTitleTextSecond: 'профіль',
        createParticipantProfile: 'Створити профіль учасника',
        reviewPlatformConditions: 'Переглянути умови використання платформи',
        reviewEventConditions: 'Переглянути умови відвідування події',
    },
    changePassword: {
        title: 'Змінити пароль',
        oldPassword: 'Старий пароль',
        oldPasswordError: 'Неправильний старий пароль',
        inputPassword: 'Новий пароль',
        inputConfirmPassword: 'Підтвердити пароль',
    },
    roomsDropdown: {
        auditoriumButton: 'АУДИТОРІЯ',
        archiveButton: 'АРХІВ',
        onDemandButton: 'ЗА ЗАПИТОМ',
    },
    sideMenu: {
        liveWall: 'СТІНА ПУБЛІКАЦІЙ',
        networking: 'НЕТВОРКІНГ',
        audience: 'ВІДВІДУВАЧІ',
        info: 'ІНФО',
        videoWall: 'СТІНА ВІДЕО',
        booth: 'СТЕНД',
        boothWall: 'СТІНА СТЕНДУ',
        networkingTooltip: 'У вас немає доступу до нетворкінг-функціоналу',
        attendees: 'Відвідувачі',
    },
    wall: {
        sortBy: 'Сортувати за:',
        popularity: 'ПОПУЛЯРНІСТЮ',
        time: 'ЧАСОМ',
        emptyWallText: 'Зробіть пост першим.',
        newPostInput: 'Про що ви думаєте?',
        newCommentInput: 'Коментувати',
        resourcesVideoDescription: 'ОПИС ВІДЕО',
        resourcesLinks: 'Посилання',
        resourcesFiles: 'Файли',
        pinTitle: 'Закріпити',
        unPinTitle: 'Відкріпити',
        deleteDialogTitle: 'Видалити пост?',
        pinDialogDescription:
            'Вже є закріплений пост. Одночасно можна закріпити лише один пост. Якщо продовжити, попередня публікація буде автоматично відкріплена.',
        edited: 'Відредаговано',
        escapeToCancel: 'натисніть Escape, щоб відмінити',
        enterToSave: 'натисніть Enter, щоб зберегти',
        editingState: 'Редагування повідомлення',
        bannerModeration: 'Пости та коментарі на цій стіні проходять модерацію, щоб забезпечити якість контенту.',
        pendingApproval: 'На схваленні',
        approvePost: 'Схвалити пост',
        approvePostComment: 'Схвалити коментар',
        commentsPendingApproval: 'Коментарі на схваленні',
    },
    networking: {
        searchInput: 'Пошук користувачів',
        emptyNetworkingSearchResults: 'Людина, яку ви шукаєте, не зареєстрована на цю онлайн-подію',
        emptyAuditoriumNetworkingSearchResults: 'Людина, яку ви шукаєте, ще не відвідала цю презентацію',
        alphabetical: 'За алфавітом',
        online: 'онлайн',
        matching: 'співпадіння',
        matchingAlgo:
            'Алгоритм метчінгу в дії. Дайте нам хвилинку, поки ми знаходимо контакти з найвищим рівнем співпадіння для вас.',
        searchFilters: 'Пошукові фільтри',
        applyFilterTooltip: 'Щоб примінити фільтри, оберіть хоча б один варіант',
        chat: 'Написати',
    },
    closedAuditoriumDialog: {
        title: 'Інформація про аудиторію',
        contentPackage: 'Ваш тип участі не надає доступу до аудиторій.',
    },
    closedShowfloorDialog: {
        title: 'Інформація про виставкову залу',
        contentPackage: 'Ваш тип участі не надає доступу до виставкових залів.',
        button: 'Добре',
    },
    auditorium: {
        willStart: 'Наступна сесія почнеться через...',
        noAccess: 'Ваш тип участі не надає доступу до цієї аудиторії.',
        noVideos: 'В цій аудиторії не заплановано жодного відео.',
        stageTab: 'СЦЕНА',
        videoWallButton: 'СТІНА ВІДЕО',
        resourcesButton: 'РЕСУРСИ',
        slotEndsTextFirst: 'Програмний слот закінчується через',
        slotEndsTextSecondUpdated: 'Ця презентація буде доступною за запитом.',
        slotTitleWith: 'із',
        allAuditoriums: 'Всі аудиторії',
        onDemandButton: 'ЗА ЗАПИТОМ',
        hide: 'Сховати',
        leavePopupTitle: 'Вийти зі сцени?',
        leavePopupDescription: 'Якщо ви вийдете із аудиторії, ви автоматично вийдете із сесії',
        leaveSession: 'Вийти зі сцени',
        cancelDescription: 'Якщо вийдете, ви більше не будете спікером. Бажаєте продовжити?',
        settings: 'Налаштування',
        minimize: 'Зменшити',
        microphone: 'Мікрофон',
        camera: 'Камера',
        auditoriums: 'Аудиторії',
    },
    auditoriumArchive: {
        title: 'Архів аудиторій',
        titleEvent: 'Архів події',
        with: 'із',
        noVideosAvailable: 'Всі сесії закінчено',
    },
    videoPlayer: {
        videoNotPlayingError: 'Виникли проблеми із трансляцією? Будь ласка, оновіть сторінку.',
        videoEndedTextFirst: 'Відео закінчилося. Дякуємо за перегляд!',
        videoEndedTextSecond: 'Стіна відео відкрита для запитань та відповідей.',
        tooltipPlay: 'Грати',
        tooltipPause: 'Пауза',
        tooltipMinimize: 'Зменшити',
        tooltipFullscreen: 'На весь екран',
        tooltipMute: 'Вимкнути звук',
        tooltipUnmute: 'Увімкнути звук',
        videoStreamNotStarted: 'Трансляція скоро почнеться. Не перемикайтеся!',
    },
    showfloor: {
        allExhibitorsButton: 'ВСІ ЕКСПОНЕНТИ',
        featuredExhibitorsText: 'ВИСВІТЛЕНІ ЕКСПОНЕНТИ',
        boothLinksDialogTitle: 'ПОСИЛАННЯ СТЕНДУ',
        boothFilesDialogTitle: 'ФАЙЛИ СТЕНДУ',
        boothVideoDialogTitle: 'ВІДЕО',
        mobileTabChat: 'СПІЛКУВАТИСЯ',
        mobileTabPoster: 'ПУБЛІКАЦІЯ',
        mobileTabFiles: 'ФАЙЛИ',
        mobileTabLinks: 'ПОСИЛАННЯ',
        mobileTabVideo: 'ВІДЕО',
        noExhibitorsTextFirst: 'ЕКСПОНЕНТИ ПРАЦЮЮТЬ НАД СВОЇМИ СТЕНДАМИ',
        noPresentersTextFirst: 'Презентери працюють над своїми стендами',
        noExhibitorsTextSecond: 'СТЕЖТЕ ЗА ОНОВЛЕННЯМИ',
        showShowfloorsButton: 'ВСІ ВИСТАВКОВІ ЗАЛИ',
        showShowfloorsPosterButton: 'ВСІ РОБОТИ/ДОСЛІДЖЕННЯ',
        showShowfloorsExhibitorButton: 'ВСІ ЕКСПОНЕНТИ',
        searchExhibitors: 'Пошук експонентів',
        searchPosters: 'Пошук за назвою або іменем автора',
        searchExhibitorsMultiple: 'Пошук експонентів в даній виставковій залі',
        searchPostersMultiple: 'Пошук за назвою або іменем автора в цій виставковій залі',
        showfloorsTitle: 'Виставкові зали',
        collapseList: 'Зменшити список',
        seeFullList: 'Переглянути повний список',
        emptyExhibitorsResults: 'Стенда, який ви шукаєте, немає.',
        emptyPostersResults: 'Роботи/дослідження, яку ви шукаєте, немає.',
        posters: 'роботи',
        allPostersStudiesTitle: 'Всі роботи/дослідження',
        authors: 'автори',
        titleNotAvailable: 'Назва не доступна',
        abstractNotAvailable: 'Анотація не доступна',
        authorsNotAvailable: 'Перелік авторів недоступний',
        openPosterFile: 'Відкрити файл роботи',
        fileIsNotAvailable: 'Файл недоступний',
        posterBooth: {
            study: 'Робота/Дослідження',
            authors: 'Автори',
            highlights: 'Анотація',
            scrollInfoText: 'ПРОКРУТІТЬ ДОНИЗУ, ЩОБ ПРОЧИТАТИ ПОВНУ АНОТАЦІЮ',
        },
    },
    eventArchive: {
        title: 'Відео архів',
        searchText: 'Натисніть для пошуку',
        noArchivedEvents: 'Наразі немає заархівованих подій.',
    },
    exhibitorDashboard: {
        event: 'Подія',
        title: 'Налаштування стенду',
        visitorsButton: 'Відвідувачі стенду',
        goToBoothPreview: 'Попередній перегляд стенду',
        previewButton: 'Попередній перегляд',
        tabStyle: 'Стиль стенду',
        tabLogo: 'Логотип',
        tabStudyDetails: 'Робота / Дослідження',
        tabFiles: 'Файли',
        tabStudyFile: 'Файл',
        tabLinks: 'Посилання',
        tabAvatars: 'Аватари',
        tabStudyAuthors: 'Автори',
        tabVideo: 'Відео',
        shareText: 'КОПІЮВАТИ URL',
        theBoothUrlCopiedText: 'URL-адресу стенду успішно скопійовано в буфер обміну',
        optionDisabledText: 'Ця опція наразі недоступна для цього стенду',
        boothStyleTab: {
            optionOne: 'Варіант 1 - Скандинавський',
            optionTwo: 'Варіант 2 - Сучасний',
            optionThree: 'Варіант 3 - Динамічний',
            optionFour: 'Варіант 4 - Кастомний',
            optionScholarOne: 'Варіант 1 - Ейнштейн',
            optionScholarTwo: 'Варіант 2 - Кюрі',
            optionScholarThree: 'Варіант 3 - да Вінчі',
        },
        logoTab: {
            fileUploadButton: 'Натисніть тут, щоб завантажити',
            noFile: 'Файл не вибрано',
        },
        posterStudyTab: {
            posterStudyTitle: 'Назва',
            studyTitleInputLabel: 'Назва роботи / дослідження',
            highlightAbstractTitle: 'Основні моменти / Анотація',
            studyHighlightsInputLabel: 'Основні моменти дослідження / анотація',
            highlightAbstractError: 'Ви досягли максимальної кількості символів! (500 символів)',
            authorNameLabel: "Ім'я автора",
            authorAffiliationLabel: 'Приналежність',
        },
        filesTab: {
            title: 'Документи:',
            fileNameInput: 'Назва документу (видима для всіх учасників)',
            chooseFileButton: 'Обрати файл',
            noDocAdded: 'Документи не додано',
            fileError: 'Додайте назву документа',
        },
        fileStudyTab: {
            title: 'Завантажити pdf файл',
            description:
                'Завантажити публікацію: роботу або презентацію, що буде доступна на вашому стенді (ліміт 20Мб)',
            uploadPdfInputLabel: 'Завантажити PDF файл',
            uploadNewPdfInputLabel: 'Завантажити новий PDF файл',
            uploadedFile: 'Завантажено',
            dragContainerTitle: 'Просто перетягніть файл сюди',
            dragContainerNewTitle: 'Просто перетягніть новий файл сюди',
        },
        linksTab: {
            social: {
                title: 'Посилання на сторінки в соц. мережах',
                subtitle: '(видимі для всіх учасників)',
                facebookInput: 'Вставте посилання на Facebook-сторінку',
                linkedinInput: 'Вставте посилання на Linkedin-сторінку',
                twitterInput: 'Вставте посилання на Twitter-акаунт',
                googleScholarInput: 'Вставте Google Scholar посилання',
                researchGateInput: 'Вставте Research Gate посилання',
            },
            other: {
                linkInput: 'Посилання',
                linkLabel: 'Назва посилання',
            },
        },
        linksStudyTab: {
            authorProfiles: 'Профілі автора',
            additionalLinks: 'Додаткові посилання',
        },
        avatarsTab: {
            mainRepresentatives: {
                title: 'Представники стенду',
                leftRepresentative: 'Лівий представник',
                rightRepresentative: 'Правий представник',
                addDialog: {
                    title: 'Додати аватар',
                    titleScholar: 'Додати автора',
                    editAvatarsTitle: 'Редагувати аватар',
                    editAuthorTitle: 'Редагувати автора',
                    contentLine1: 'Обрати аватар з галереї нижче',
                    contentLine2: 'Завантажити власний персоналізований аватар',
                    contentLine3: "Потрібна допомога? Зв'яжіться із",
                    assignButtonText: 'Назначити',
                    assignText: 'Асоціювати відвідувача із обраним аватаром.',
                    assignErrorBooth: 'Користувача вже додано як представника стенду',
                    assignErrorAdditional: 'Користувача вже додано як додаткового представника',
                    input: 'Пошук за іменем',
                },
            },
            additionalRepresentatives: {
                title: 'Додаткові представники',
                subtitle: "(необов'язково, відображатимуться разом із фото профілю)",
                addDialog: {
                    title: 'Обрати додаткового представника',
                    titleScholar: 'Обрати співавтора',
                    assignText: 'Почніть пошук учасника події, щоб додати її/його як представника.',
                    assignError: 'Користувача вже додано як представника стенду',
                },
            },
            author: {
                title: 'Презентуючий автор',
                subtitle: 'Додати можна тільки автора, зареєстрованого на подію. Ви можете додати одного автора.',
                mainRepresentative: 'Головний представник',
            },
            coAuthors: {
                title: 'Співавтори',
                singularTitle: 'Співавтор',
                subtitle:
                    'Додати можна тільки співавторів, зареєстрованих на подію. Ви можете додати до чотирьох співавторів.',
            },
        },
        videoTab: {
            video: {
                title: 'Відео стенду',
                subtitle: '(тільки YouTube-посилання на відео)',
                youtubeOrVimeoOnly: '(тільки YouTube або Vimeo-посилання)',
                input: 'Введіть YouTube-посилання',
                inputYoutubeOrVimeo: 'Введіть YouTube або Vimeo-посилання на відео',
            },
            image: {
                subtitle:
                    '(Зображення буде відображатися на вашому стенді, якщо ви не додали відео-посилання у попередній секції)',
            },
        },
    },
    boothDashboard: {
        event: 'Подія',
        title: 'Ваш стенд',
        manage: 'Керувати',
        statistics: 'Статистика',
        uniqueVisitors: 'Унікальні відвідувачі',
        currentOnlineVisitors: 'Поточні онлайн-відвідувачі',
        totalNumberOfVisits: 'Загальна кількість відвідувань',
        totalDocumentClicks: 'Загальна кількість кліків документа',
        totalLinkClicks: 'Загальна кількість кліків посилань',
        totalRepresentativesClicks: 'Загальна кількість кліків представників',
        totalVideoViews: 'Загальна кількість переглядів відео',
        totalPostsCount: 'Загальна кількість дописів',
        totalLikesCount: 'Загальна кількість лайків',
        totalCommentsCount: 'Загальна кількість коментарів',
        showUsers: 'Показати користувачів',
        dataType: 'Тип даних',
        value: 'Значення',
        posts: 'Публікації',
        likes: 'Подобається',
        comments: 'Коментарі',
        topPostLikes: 'Найпопулярніші публікації, які вподобають',
        topPostComments: 'Найпопулярніші коментарі до публікації',
        totalVotes: 'Загальна кількість голосів',
    },
    polls: {
        polls: 'ОПИТУВАННЯ',
        noPolls: 'Опитувань не було додано.',
        pollProgress: 'Опитування триває',
        pollClosed: 'Опитування завершено',
        pollVoted: 'учасників проголосували',
    },
    onDemandVideos: {
        sectionTitle: 'ЗА ЗАПИТОМ',
        noOnDemandVideos: 'Жодних відео за запитом ще не було додано.',
    },
    meetings: {
        meeting: 'ЗУСТРІЧ',
        createDialog: {
            createTitle: 'Запросити на зустріч',
            inputDate: 'Дата',
            inputStart: 'Початок',
            inputEnd: 'Кінець',
            inputTimezone: 'Часовий пояс',
            inputNote: 'Примітка. Одночасно можна запланувати лише одну зустріч з тією самою людиною.',
            meetingError: 'Неправильна дата зустрічі або час. Будь ласка, перевірте!',
            buttonDisabled: 'Ви можете запланувати зустріч після того, як почнете розмову!',
        },
        banner: {
            pending: 'Запит на зустріч',
            accepted: 'Зустріч заплановано на',
            progress: 'Зустріч триває',
            cancelTitle: 'Відмінити зустріч?',
            cancelDescription: 'Ви впевнені, що хочете відмінити цю зустріч?',
            startCall: 'ПОЧАТИ ДЗВІНОК',
            joinCall: 'ДОЄДНАТИСЯ',
            pendingOwner: 'Очікується відповідь на запит на зустріч',
            videoCallStarted: 'Відео зустріч почалася',
            videoCallStartedInfo: 'Відео зустрічі найкраще підходять для груп до 10 людей',
        },
        title: 'Ваші зустрічі',
        meetingsInfo:
            'Зустрічі можуть бути заплановані через функцію Розмов. Будь ласка, почніть розмову із користувачем, з яким ви хочете запланувати зустріч.',
    },
    socialMediaShare: {
        event: 'подію',
        shareLabel: 'Поділитися',
        dialogTitleGeneral: 'Поділитися зі своєю аудиторією',
        dialogTitlePost: 'Поділитися публікацією',
        linkedinLabel: 'LinkedIn',
        facebookLabel: 'Facebook',
        twitterLabel: 'Twitter',
        emailLabel: 'Імейл',
        clipboardLabel: 'Буфер обміну',
        clipboardSnackbarMessage: 'Посилання скопійовано в буфер обміну',
        linkedinButtonTitle: 'Поділитися на LinkedIn',
        facebookButtonTitle: 'Поділитися на Facebook',
        twitterButtonTitle: 'Поділитися у Twitter',
        emailButtonTitle: 'Поділитися через електронну пошту',
        clipboardButtonTitle: 'Скопіювати в буфер обміну',
        imAttending: 'Я відвідую',
        interestedClick: 'Хочете відвідати? Натисніть тут:',
    },
    videoConferences: {
        fullScreen: 'ВІДКРИТИ НА ВЕСЬ ЕКРАН',
        minimizeScreen: 'ЗМЕНШИТИ ЕКРАН',
        startedVideoChat: 'відео-дзвінок розпочато',
        timeAgo: 'тому',
        videoCallStarted: 'Відео-дзвінок розпочато...',
    },
    sessions: {
        edit: 'РЕДАГУВАТИ',
        speakers: 'СПІКЕРИ',
        speakerSingular: 'спікер',
        moderators: 'МОДЕРАТОРИ',
        moderatorSingular: 'модератор',
        video: 'ВІДЕО',
        startStream: 'РОЗПОЧАТИ СТРІМ',
        stopStream: 'ЗУПИНИТИ СТРІМ',
        sessionChat: 'ЧАТ СЕСІЇ',
        speakerUrl: 'URL спікера',
        moderatorUrl: 'URL модератора',
        copyUrl: 'скопіювати URL',
        noSpeakersJoined: 'Спікери ще не доєдналися',
        allFilter: 'всі',
        waitingRoom: 'Кімната очікування',
        isReady: 'Готовий/готова',
        invitationAccepted: 'Запрошення прийнято',
        requestPending: 'Запит на схваленні',
        gettingReady: 'Готується',
        inviteToSpeak: 'Запросити виступити',
        fromAuditorium: 'з Аудиторії',
        mic: 'Мікрофон',
        screen: 'Екран',
        rec: 'запис',
        sessionHasEnded: 'Сесію закінчено',
        sessionLinks: 'ПОСИЛАННЯ СЕСІЇ',
        sessionSettings: 'Налаштування',
        noAvailableChat: 'Чат недоступний. Щоб побачити чат, додайте цю сесію до таймслоту',
        startLiveSessionStreamTitle: 'Почати трансляцію сесії',
        startLiveSessionDescription: 'Запис сесії автоматично почнеться, коли ви почнете трансляцію',
        stopLiveSessionStreamTitle: 'Зупити трансляцію сесії',
        stopLiveSessionStreamDescription:
            'Після зупинки трансляції сесії запис також буде зупинено. Стрім сесії не можна буде почати знову.',
        thumbnailImage: "Прев'ю зображення",
        uploadThumbnail: "Завантажити прев'ю зображення",
        clickToUpload: 'Натисніть сюди, щоб завантажити',
        requestMic: 'ЗАПИТ МІКРОФОНУ',
        acceptInvitationTitle: 'Вам надійшло запрошення',
        acceptInvitationSubTitle: 'Модератор запросив вас стати спікером. Що ви відповісте?',
        acceptInvitationButton: 'Прийняти запрошення',
        requestPendingTitle: 'Запит на схваленні...',
        requestPendingSubTitle: 'Дочекайтеся, коли модератор дозволить Вам доєднатися до трансляції',
        cancelRequest: 'Відмінити запит',
        approvedTitle: 'Перевірте Ваш мікрофон та відео з камери та натисніть "Я готовий/готова"',
        approvedSubTitle:
            'Сесія записується. Доєднуючись до цієї сесії Ви погоджуєтеся на обробку ваших персональних даних.',
        connectedToTheBackstage: 'Тепер Ви доєдналися до трансляції та до бекстейджу.',
        removedFromTheSession: "Вас виключили із сесії, будь ласка, зв'яжіться із модератором.",
        imReady: 'Я готовий/готова',
        isTryingToRegister: 'намагається зареєструватися в якості',
        hasLeftTheSession: 'вийшов/вийшла із сесії',
        stopVideo: 'Вимкнути камеру',
        startVideo: 'Увімкнути камеру',
        disabledScreenSharing: 'Вимкніть трансляцію екрану перед тим, як увімкнути камеру',
        disabledByModerator: 'Деактивовано модератором',
        disabledUntilReady: 'Деактивовано до готовності',
        stopShareScreen: 'Зупинити трансляцію екрану',
        shareScreen: 'Трансляція екрану',
        disabledVideoSharing: 'Вимкніть камеру перед тим, як почати трансляцію екрану',
        anotherUserIsScreenSharing: 'Хтось вже робить трансляцію екрану',
        removeRoleDescription: 'Чи Ви впевнені, що хочете виключити',
        declinedInvitation: 'відхилив запрошення стати спікером',
    },
    gdpr: {
        disclaimerTextFirst:
            'Цей документ було перекладено штучним інтелектом. У разі невідповідності зверніться до англійської версії документа',
        disclaimerTextSecond: 'ТУТ',
        metaTitlePrivacyPolicy: 'Політика конфіденційності',
        metaTitleCookiesPolicy: 'Політика cookie',
        metaTitleTerms: 'Правила та умови',
        updateCookieConsent: 'Оновити згоду на cookie',
        changeYourConsent: 'Змінити згоду',
        withdrawYourConsent: 'Відкликати згоду',
        withdrawSnackbarText: 'Згода на використання файлів cookie успішно відкликана',
    },
    infoMessages: {
        imageExplain420x200:
            'Рекомендоване співвідношення сторін зображення 1.8:1 (горизонтальна орієнтація, наприклад, 420 x 200 пікселів)',
        imageExplainTB230x320:
            'Рекомендоване співвідношення сторін зображення 1:1.4 (вертикальна орієнтація, наприклад, 230 x 320 пікселів) із прозорим фоном',
        imageExplainTB230x875:
            'Рекомендоване співвідношення сторін зображення 1:3.8 (вертикальна орієнтація, наприклад, 230 x 875 пікселів) із прозорим фоном',
    },
    matching: {
        matchingQuestionnaireTitle: 'Метчінг анкета',
        matchingQuestionnaireIntro:
            "Ми допоможемо Вам зв'язатися із людьми, що найбільше підходять до Ваших інтересів! Чудові речі стаються, коли відбуваються правильні зустрічі. Заповніть анкету, щоб розпочати",
        matchingQuestionnaireIntroSkip:
            'Якщо Ви не заповните анкету, ми не зможемо підібрати для Вас підходящі контакти серед відвідувачів даної події. Ви завжди можете заповнити анкету пізніше у Нетворкінг секції. Всі відповіді можна пізніше редагувати у Моєму Акаунті.',
        doItLaterBtn: 'ЗРОБИТИ ЦЕ ПІЗНІШЕ',
        nextBtn: 'Далі',
        backBtn: 'Назад',
        finishBtn: 'Завершити',
        seeMatchesBtn: 'ПОБАЧИТИ ВСІ СПІВПАДІННЯ',
        tryMatchingBtn: 'СПРОБУВАТИ СМАРТ МЕТЧІНГ',
        optionalQuestion: "Необов'язково",
        requiredQuestion: "Обов'язково",
        singleSelect: 'Одна відповідь',
        multiSelect: 'Декілька варіантів відповідей',
        mediumMatch: 'Середній рівень співпадіння',
        highMatch: 'Високий рівень співпадіння',
        matchLabel: 'Співпадіння',
        matchesLabel: 'Співпадіння',
        infoTooltipJump: 'Натисніть тут, щоб побачити Ваші співпадіння',
        infoTooltipClickToSee1: 'Натисніть',
        infoTooltipClickToSee2: 'щоб побачити, із ким Вам варто познайомитися!',
        loadingAlgoText:
            'Алгоритм метчінгу в дії. Дайте нам хвилинку, поки ми підбираємо для Вас контакти з найвищим рівнем співпадіння!',
        matchingDetailsFormTitle: 'Деталі смарт метчінгу',
        tryMatchingBanner: 'Встановлюйте значущі контакти, спробуйте нашу функцію Смарт Метчінгу!',
        matchingBannerClosed: 'Ви завжди можете заповнити анкету Смарт Метчінгу у Нетворкінгу в розділі Співпадіння',
        resultsTitle: 'Ось контакти з найвищим рівнем співпадіння для Вас',
        resultsNoteLabel: 'ПРИМІТКА:',
        resultsNoteText: 'Ви можете знайти всі співпадаючі контакти в секції Нетворкінгу.',
        resultsNoteMyAccount: 'Всі відповіді можна редагувати у меню Мого Акаунту.',
        btwResultsResponsive: 'До речі, Ви можете знайти усі свої співпадіння у НЕТВОРКІНГУ',
        noMoreMatches:
            'На жаль, наразі ми не можемо знайти для Вас більше співпадінь. Будь ласка, перегляньте їх пізніше, коли більша кількість людей доєднається до події.',
        noResultsTitle: 'Вітаємо, Ви це зробили! Ми шукаємо найкращі співпадіння для Вас!',
        noResults:
            '"Дякуємо, що спробували функцію Смарт Метчінгу! Ми шукаємо найбільш підходящі контакти для Вас і поєднаємо Вас із ними, як тільки більше людей доєднається до події. Будь ласка, перевірте вкладку "Співпадіння" в розділі "Нетворкінг".',
        clickStartChat: 'Натисніть "спілкуватися", щоб почати розмову',
        closeMatchingTitle: 'Закрити метчінг форму?',
        closeMatchingTextA:
            'Якщо Ви закриєте форму, не завершивши її заповнення, ми не збережемо жодних деталей, а результати Смарт Метчінгу не будуть доступними для Вас.',
        closeMatchingTextB:
            'Ваш профіль не братиме участь у Смарт Метчінгу, і для Вас не буде підібрано співпадаючих контактів.',
        matchingTooltip: 'Натисніть на Співпадіння, щоб дізнатися, із ким Вам варто познайомитися!',
        myAccountMatchingSectionTitle: 'Деталі смарт метчінгу',
        matchingRequiredError: "Ви маєте відповісти на всі обов'язкові запитання",
        rejectMatchingTitle: 'Відмовитися брати участь у метчінгу?',
        rejectMatchingText:
            'Ваші відповіді буде видалено та Вам не буде підібрано найбільш підходящих користувачів. Ви зможете знову заповнити анкету у будь-який час.',
        rejectMatchingBtn: 'ВІДМОВИТИСЯ БРАТИ УЧАСТЬ',
    },
    welcomeScreen: {
        goToEvent: 'Увійти до події',
    },
    emailCodeVerification: {
        codeSent: 'Ми надіслали код підтвердження на вашу електронну пошту:',
        continueRegistration: 'Щоб продовжити реєстрацію,',
        enterCodeBelow: 'введіть код підтвердження нижче:',
        codeWillExpire: 'Термін дії коду закінчиться через',
        codeExpired: 'Термін дії коду закінчився',
        codeNotReceived: 'Не отримали код? Чи ви перевірили папку Спам?',
        resendTimer: 'Надіслати знову через:',
        resendLabel: 'Надіслати знову',
        resendCodeLabel: 'Надіслати код знову',
        codeError: 'Введений код невірний.',
        codeErrorFix: 'Введіть знову правильний код із електронного листа або отримайте новий код',
        congrats: 'Вітаємо!',
        emailConfirmed: 'Вашу електронну адресу підтверджено:',
    },
    privacyPolicyUpdates: {
        learnMore: 'Learn more',
        lastUpdated: 'Last updated',
        publishedBy: 'Published by',
        ppuLinkLabel: 'Updated privacy policy',
        ppuEventLinkLabel: 'Updated event privacy policy',
        acceptAllButtonLabel: 'Accept all updates',
    },
    voting: {
        votingText: 'ГОЛОСУВАННЯ',
        enableVoting: 'УВІМКНУТИ ГОЛОСУВАННЯ',
        enableVotingDescription: 'Натисніть піктограму Оновити, щоб отримати найточніші результати',
        displayVotingNumbersForExhibitors: 'ПОКАЗУЙТЕ НОМЕРИ ГОЛОСУВАННЯ ДЛЯ УЧАСНИКІВ ТА ПЛАКАТ/ ДОСЛІДЖЕННЯ',
        displayVotingNumbersForExhibitorsDescription: 'Буде відображатися на інформаційній панелі експонента',
        displayVotingNumbersForParticipants: 'ПОКАЗАТИ НОМЕРИ ГОЛОСУВАННЯ ДЛЯ УЧАСНИКІВ',
        displayVotingNumbersForParticipantsDescription: 'Буде відображатися для всіх учасників',
        characterError80: 'Ви досягли максимально допустимої кількості символів (80 символів)',
        characterDescription:
            'Текст обмежений до 80 символів. Текст буде основним заголовком на стрічці голосування. Зробіть його значущим для учасників',
        deleteAllVotes: 'ВИДАЛИТИ ВСІ ГОЛОСУВАННЯ',
        downloadVotingResults: 'ЗАВАНТАЖИТИ РЕЗУЛЬТАТИ ГОЛОСУВАННЯ',
        voteText: 'Голосувати',
        votedText: 'Проголосував',
        votesText: 'Голоси',
        participant: 'учасник',
        participants: 'учасники',
        deleteVotesTextFirst: 'УВАГА:',
        deleteVotesTextSecond: 'це необоротна дія.',
        deleteVotesTextThird: 'Не забудьте завантажити результати голосування перед видаленням',
        checkTheBoxToCastYourVote: 'Поставте прапорець, щоб віддати свій голос',
        toVotePleaseEnableParticipantProfile: 'Щоб проголосувати, увімкніть свій профіль учасника',
    },
};

export default ua;
