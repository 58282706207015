import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import NotFound from 'Pages/NotFound';
import Spinner from 'SmallLayoutComponents/FullPageSpinner';
import EventPrivateComponent from './EventPrivateComponent';

const ParticipantRoute = ({component: Component, loggedIn, user, loadingUser, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            const eventSlug = props.match.params.eventSlug;

            if (!loggedIn && loadingUser) {
                return <Spinner />;
            } else if (loggedIn && user) {
                const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
                const userHasRole = userRolesInEvent && userRolesInEvent.roles.length > 0;
                if (userHasRole) {
                    return (
                        <EventPrivateComponent {...props}>
                            <Component {...props} />
                        </EventPrivateComponent>
                    );
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: `/event/${eventSlug}`,
                            }}
                        />
                    );
                }
            } else if (!loggedIn && !loadingUser) {
                localStorage.setItem('intendedPage', window.location.pathname);
                const anyLogin = localStorage.getItem('anyLogin');
                return (
                    <Route
                        path="/"
                        component={() => {
                            anyLogin
                                ? (window.location.href = `/event/${eventSlug}/register?registerAsParticipant=true`)
                                : (window.location.href = `/event/${eventSlug}`);
                            return null;
                        }}
                    />
                );
            } else {
                localStorage.removeItem('intendedPage');
                return <NotFound />;
            }
        }}
    />
);

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        loadingUser: state.user.loading,
        loadingEvent: state.event.loading,
        eventSlug: state.event.eventSlug,
    };
};

export default connect(mapStateToProps)(ParticipantRoute);
