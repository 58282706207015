import React, {useEffect, useState, useRef, useLayoutEffect} from 'react';
import './program.scss';
import {useDispatch, useSelector} from 'react-redux';
import CloseProgramButton from './CloseProgramButton';
import Topbar from './Topbar';
import ProgramDays from './ProgramDays';
import TimeslotsList from './TimeslotsList';
import {checkIfProgramHasTimeslots, checkIfTimeslotIsLive, d, preventDefaultDrag} from 'Utils/utils';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import DetailedTimeslot from './DetailedTimeslot';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'store/axios-instance';
import LoadingTimeslots from './LoadingTimeslots';
import EmptyState from './EmptyState';
import {refreshUserData, updateUserInformation} from 'store/actions';
import Agenda from './Agenda/Agenda';
import {getMyAgenda} from 'store/actions/myAgendaActions';
import Spinner from 'SmallLayoutComponents/Spinner';

const Program = ({isMeetingsActive}) => {
    //ref
    const isFirstUpdate = useRef(true);

    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const eventId = useSelector((state) => state.event.eventId);

    const agenda = useSelector((state) => state?.myAgenda);
    const {agenda: MyAgenda, agendaMeetings} = agenda;

    const user = useSelector((state) => state?.user?.data);
    const event = useSelector((state) => state?.event?.data);

    const layout = useSelector((state) => state.layout);
    const {isMobile, isTablet, isLargeScreen} = layout;

    const [layoutType, setDefaultLayoutType] = useState('list');
    const [layoutList, setLayoutList] = useState(null);

    const eventAuditoriums = event?.auditoriums;
    const [debouncedSearch, setDebouncedSearch] = useState('');

    const [searchMode, setSearchMode] = useState(false);
    const [timeslots, setTimeslots] = useState([]);
    const [activeTimeslot, setActiveTimeslot] = useState(null);
    const [loadingSearchResults, setLoadingSearchResults] = useState(false);
    const [displayNumberOfSearchResult, setDisplayNumberOfSearchResult] = useState(false);
    const [numberOfSearchResult, setNumberOfSearchResult] = useState(null);
    const [pastSlots, setPastSlots] = useState(false);
    const [loadingProgramView, setLoadingProgramView] = useState(false);

    // states which come with agende implementation to the program
    const [isActiveTab, setIsActiveTab] = useState('program');
    //end

    // activeDays contains an array with all the dates of the program that have at least a timeslot
    const [activeDays, setActiveDays] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [timeslotsInViewport, setTimeslotsInViewport] = useState([]);
    const [isTimeslotInTheFuture, setIsTimeslotInTheFuture] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const timeslotsWithDataInViewport = timeslots.filter((timeslot) => {
            return timeslotsInViewport.includes(timeslot._id);
        });

        if (timeslotsWithDataInViewport.length > 0) {
            const firstTimeslotInViewport = timeslotsWithDataInViewport[0];
            const startTimestamp = moment(new Date(firstTimeslotInViewport.startTimestamp));
            const activeDay = activeDays.find((day) => {
                if (new moment(day).isSame(startTimestamp, 'day')) {
                    return day;
                }
            });

            if (activeDay) {
                if (isTimeslotInTheFuture) return;
                setSelectedDay(activeDay);
            }
        }
    }, [timeslotsInViewport, timeslots]);

    const handleNavigateToDay = (day) => {
        document.getElementById(day)?.scrollIntoView({
            behavior: 'auto',
        });
        setTimeout(() => {
            setSelectedDay(day);
        }, 300);
        setIsTimeslotInTheFuture(false);
    };

    const setActiveTabToProgram = (activeSlot) => () => {
        setIsActiveTab(activeSlot);
        setActiveTimeslot(null);
    };

    useEffect(() => {
        if (isMeetingsActive) {
            setIsActiveTab('agenda');
        }
    }, [isMeetingsActive]);

    useEffect(() => {
        // we need to calculate the current 'selectedDay'
        // it should be the first day in the future that has a timeslot
        if (timeslots?.length === 0) return;

        const now = moment();
        const firstTimeslotInTheFuture = timeslots.find((timeslot) => {
            const endTimestamp = moment(new Date(timeslot.endTimestamp));
            if (now.isBefore(endTimestamp)) return timeslot;
        });

        if (firstTimeslotInTheFuture) {
            // set the activeDay to be the one of the current/future timeslot
            const startTimestamp = moment(new Date(firstTimeslotInTheFuture.startTimestamp));
            const activeDay = activeDays.find((day) => {
                if (new moment(day).isSame(startTimestamp, 'day')) {
                    return day;
                }
            });

            // if the future timeslot is the first timeslot in a day
            // scroll to day
            // instead of timeslot
            // handleNavigateToDay(activeDay)

            const timeslotsInActiveDay = timeslots.filter((timeslot) => {
                return new moment(activeDay).isSame(timeslot.startTimestamp, 'day');
            });

            const singleTimeslotInDay = timeslotsInActiveDay.length === 1;

            if (singleTimeslotInDay) {
                handleNavigateToDay(activeDay);
            } else {
                if (activeDay) {
                    setSelectedDay(activeDay);
                }

                // if we are in searchMode, we want to keep the scrollbar at the top when we get the results
                if (!searchMode) {
                    document.getElementById(firstTimeslotInTheFuture?._id)?.scrollIntoView();
                }
            }
            setIsTimeslotInTheFuture(true);
        }
    }, [activeDays, layoutType, loadingProgramView]);

    useEffect(() => {
        let activeDays = [];
        timeslots.forEach((timeslot) => {
            const day = new Date(timeslot.startTimestamp).toLocaleDateString('en-US');
            if (!activeDays.includes(day)) {
                activeDays.push(day);
            }
        });

        setActiveDays(activeDays);
    }, [timeslots]);

    useEffect(() => {
        formatTimeslots();
    }, [eventAuditoriums, user?.timezoneValue, event?.timezoneValue]);

    useEffect(() => {
        getSearchResults();
    }, [debouncedSearch]);

    const calculateTimezoneDifference = () => {
        const userTimezoneValue = user?.timezoneValue;
        let timezoneDifference = 0;
        if (userTimezoneValue) {
            timezoneDifference = userTimezoneValue - event?.timezoneValue;
        }
        return timezoneDifference;
    };

    const recalculateTimeslotByTimezone = (timeslot, timezoneDifference) => {
        let startTimestampWithoutLocalTimezone = moment.utc(timeslot.startTimestamp).format('l LT');
        let endTimestampWithoutLocalTimezone = moment.utc(timeslot.endTimestamp).format('l LT');
        let timezoneValueToBeAdded = +event?.timezoneValue + timezoneDifference;

        timeslot.startTimestamp = moment(startTimestampWithoutLocalTimezone).add(timezoneValueToBeAdded, 'hours');
        timeslot.endTimestamp = moment(endTimestampWithoutLocalTimezone).add(timezoneValueToBeAdded, 'hours');

        timeslot.start = moment(timeslot.startTimestamp).format('HH:mm');
        timeslot.end = moment(timeslot.endTimestamp).format('HH:mm');

        return timeslot;
    };

    const setUserTimezone = (data) => {
        setLoadingSearchResults(true);
        dispatch(updateUserInformation(data))
            .then((response) => {
                dispatch(refreshUserData()).then(() => {
                    setLoadingSearchResults(false);
                });
            })
            .catch((error) => {});
    };

    const getSearchResults = () => {
        // reset search
        if (debouncedSearch.trim().length === 0) {
            setLoadingSearchResults(false);
            setDisplayNumberOfSearchResult(false);
            setNumberOfSearchResult(null);
            setSearchMode(false);
            formatTimeslots();
            return;
        }

        if (debouncedSearch.trim().length >= 2) {
            setLoadingSearchResults(true);
            // api call
            let params = {search: debouncedSearch};
            axios({method: 'get', url: `/event/${event?._id}/auditoriums/search-program`, params}).then((response) => {
                setLoadingSearchResults(false);
                setSearchMode(true);
                let timeslotsResults = response.data.data.programTimeslots;

                // we don't need to sort theme here because we get them sorted from the backend
                // timeslotsResults = sortProgramTimeslots(timeslotsResults);

                setDisplayNumberOfSearchResult(true);
                setNumberOfSearchResult(timeslotsResults.length);
                let formattedTimeslots = [];

                timeslotsResults.forEach((timeslotResult) => {
                    let newTimeslot = cloneDeep(timeslotResult);
                    newTimeslot.isLive = checkIfTimeslotIsLive(timeslotResult, event.timezoneValue);
                    formattedTimeslots.push(newTimeslot);
                });

                displaySearchResults(formattedTimeslots);
            });
        }
    };

    const displaySearchResults = (timeslotsResults) => {
        let timezoneDifference = calculateTimezoneDifference();

        // if timezone difference is 0
        // we set the api call response as the timeslots
        // if(timezoneDifference === 0) {
        //     return setTimeslots(timeslotsResults)
        // }

        // update timeslot properties (recalculate based on timezone difference)
        let formattedTimeslots = [];

        timeslotsResults.forEach((timeslotResult) => {
            let newTimeslot = cloneDeep(timeslotResult);
            newTimeslot = recalculateTimeslotByTimezone(newTimeslot, timezoneDifference);
            newTimeslot.isLive = checkIfTimeslotIsLive(timeslotResult, event.timezoneValue);
            formattedTimeslots.push(newTimeslot);
        });

        setTimeslots(formattedTimeslots);
    };

    const sortProgramTimeslots = (auditoriums) =>
        auditoriums.sort((a, b) => {
            if (a.startTimestamp > b.startTimestamp) {
                return 1;
            }
            if (a.startTimestamp < b.startTimestamp) {
                return -1;
            }
            // case equal start time
            if (a.auditoriumIndex > b.auditoriumIndex) {
                return 1;
            }
            return -1;
        });

    const formatTimeslots = () => {
        let sortedTimeslots = [];
        let timezoneDifference = calculateTimezoneDifference();
        eventAuditoriums.forEach((auditorium, auditoriumIndex) => {
            auditorium.displayProgram.forEach((displayProgramDay) => {
                displayProgramDay.program.map((timeslot) => {
                    let newTimeslot = cloneDeep(timeslot);
                    newTimeslot.auditoriumName = auditorium.name;
                    newTimeslot.speakers = timeslot?.speakers;
                    newTimeslot.description = timeslot?.description;
                    newTimeslot.date = displayProgramDay?.date;
                    newTimeslot.displayProgram = displayProgramDay?._id;
                    newTimeslot.program = timeslot?._id;
                    newTimeslot.auditoriumIndex = auditoriumIndex + 1;
                    newTimeslot.auditorium = auditorium._id;
                    newTimeslot.isLive = checkIfTimeslotIsLive(timeslot, event?.timezoneValue);
                    newTimeslot = recalculateTimeslotByTimezone(newTimeslot, timezoneDifference);
                    sortedTimeslots.push(newTimeslot);
                });
            });
        });

        sortedTimeslots.sort((a, b) => {
            if (a.startTimestamp > b.startTimestamp) {
                return 1;
            }
            if (a.startTimestamp < b.startTimestamp) {
                return -1;
            }

            // case equal start time
            if (a.auditoriumIndex > b.auditoriumIndex) {
                return 1;
            }
            return -1;
        });

        setTimeslots(sortProgramTimeslots(sortedTimeslots));
    };

    const resetActiveTimeslot = () => {
        setActiveTimeslot(null);
    };

    const isExpanded = isMobile || isTablet;

    const requestedMeetings = () => {
        return Object.values(MyAgenda)?.filter(
            (el) =>
                el.type === 'meeting' &&
                el.status === 'pending' &&
                el?.owner?.user?._id !== user?._id &&
                d(el?.end).getTime() >= d().getTime()
        );
    };

    /*Activates pastslots for all */
    const setPastSlotsState = (e) => {
        const {checked} = e.target;
        setPastSlots(checked);
    };

    const setProgramView = async () => {
        setLoadingProgramView(true);
        const {data} = await axios.get(`/event/${eventId}/options`);
        if (data?.success) {
            setDefaultLayoutType(data?.data?.programOptions?.defaultView === 'listView' ? 'list' : 'calendar');
            const activeOptions = Object.keys(data?.data?.programOptions).filter(
                (key) => data?.data?.programOptions[key] && key !== 'defaultView'
            );

            setLayoutList(activeOptions);
        }
        setLoadingProgramView(false);
    };
    useEffect(() => {
        setProgramView();
    }, []);
    useLayoutEffect(() => {
        if (isFirstUpdate.current) {
            isFirstUpdate.current = false;
            return;
        }
        !isFirstUpdate.current &&
            getMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch);
    }, [agendaMeetings]);

    const handleLayoutType = (layout) => async () => {
        setDefaultLayoutType(layout);
        setActiveTimeslot(null);
    };

    return (
        <div
            className={`${layoutType === 'calendar' ? 'calendar-list-style' : ''} program-dropdown-wrapper ${
                isExpanded ? 'expanded' : ''
            } ${searchMode ? 'search-mode' : ''}`}
        >
            {!loadingProgramView && (
                <div className={'program-dropdown-container'}>
                    <CloseProgramButton />

                    {activeTimeslot && (
                        <div
                            onDragStart={preventDefaultDrag}
                            className="collapse action-button"
                            onClick={resetActiveTimeslot}
                        >
                            <UnfoldLessIcon />
                        </div>
                    )}

                    <div className={'program-container'}>
                        <div className="program-titles">
                            <button
                                onClick={setActiveTabToProgram('program')}
                                className={`title ${isActiveTab === 'program' ? 'active-slot' : ''}`}
                            >
                                {translation?.eventMenu.programButton || defaultTranslation.eventMenu.programButton}
                            </button>
                            <button
                                onClick={setActiveTabToProgram('agenda')}
                                className={`title ${isActiveTab === 'agenda' && 'active-slot'}`}
                            >
                                {translation?.agendaTranslations?.myAgenda ||
                                    defaultTranslation.agendaTranslations?.myAgenda}{' '}
                                {requestedMeetings().length > 0 && (
                                    <span className="meeting-requests-number">
                                        <span>{requestedMeetings()?.length}</span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {isActiveTab === 'program' ? (
                            checkIfProgramHasTimeslots(event) ? (
                                <>
                                    <Topbar
                                        setActiveTimeslot={setActiveTimeslot}
                                        setDebouncedSearch={setDebouncedSearch}
                                        setDisplayNumberOfSearchResult={setDisplayNumberOfSearchResult}
                                        setNumberOfSearchResult={setNumberOfSearchResult}
                                        displayNumberOfSearchResult={displayNumberOfSearchResult}
                                        numberOfSearchResult={numberOfSearchResult}
                                        selectedDay={selectedDay}
                                        layoutType={layoutType}
                                        layoutList={layoutList}
                                        searchMode={searchMode}
                                        handleLayoutType={handleLayoutType}
                                        setUserTimezone={setUserTimezone}
                                    />

                                    {loadingSearchResults ? (
                                        <LoadingTimeslots />
                                    ) : (
                                        <>
                                            {numberOfSearchResult === 0 ? (
                                                <EmptyState
                                                    textOne={'Nothing found here'}
                                                    textTwo={'Try searching again'}
                                                />
                                            ) : (
                                                <div className="program-details-container">
                                                    {activeDays.length > 1 && !isMobile && !searchMode && (
                                                        <ProgramDays
                                                            layoutType={layoutType}
                                                            activeDays={activeDays}
                                                            selectedDay={selectedDay}
                                                            handleNavigateToDay={handleNavigateToDay}
                                                        />
                                                    )}
                                                    <TimeslotsList
                                                        searchMode={searchMode}
                                                        selectedDay={selectedDay}
                                                        handleLayoutType={handleLayoutType}
                                                        layoutType={layoutType}
                                                        layoutList={layoutList}
                                                        timeslots={timeslots}
                                                        setActiveTimeslot={setActiveTimeslot}
                                                        activeTimeslot={activeTimeslot}
                                                        setTimeslotsInViewport={setTimeslotsInViewport}
                                                        displayNumberOfSearchResult={displayNumberOfSearchResult}
                                                        activeDays={activeDays}
                                                        setIsTimeslotInTheFuture={setIsTimeslotInTheFuture}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <EmptyState
                                    textOne={
                                        translation.programDropdown.noProgram ||
                                        defaultTranslation.programDropdown.noProgram
                                    }
                                    textTwo={''}
                                />
                            )
                        ) : (
                            <Agenda
                                activeTimeslot={activeTimeslot}
                                setActiveTimeslot={setActiveTimeslot}
                                pastSlots={pastSlots}
                                setPastSlotsState={setPastSlotsState}
                            />
                        )}
                    </div>

                    {activeTimeslot && isLargeScreen && <DetailedTimeslot activeTimeslot={activeTimeslot} />}
                </div>
            )}
        </div>
    );
};

export default Program;
