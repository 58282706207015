import {ReactComponent as Facebook} from '../images/facebook.svg';
import {ReactComponent as Linkedin} from '../images/linkedin.svg';
import {ReactComponent as Google} from '../images/google.svg';
import Button from '@material-ui/core/Button';
const SocialMedias = ({
    eventId,
    registerAsExhibitor,
    registerAsScholar,
    registerAsParticipant,
    preselectedAccessPackageId,
}) => {
    //facebook icon from material ui
    const openLoginLink = (platform) => (e) => {
        localStorage.setItem('isSocialMediaLR', true);
        localStorage.setItem('isSocialMediaLogin', true);
        window.location.href = `${process.env.REACT_APP_REQUESTS_API}/auth/${platform}?eventId=${eventId}&registerAsExhibitor=${registerAsExhibitor}&registerAsScholar=${registerAsScholar}&registerAsParticipant=${registerAsParticipant}&packageId=${preselectedAccessPackageId}`;
    };

    return (
        <ul className="social-media-connections">
            <li>
                <Button onClick={openLoginLink('linkedin')}>
                    <Linkedin />
                </Button>
            </li>
            <li>
                <Button onClick={openLoginLink('google')}>
                    <Google />
                </Button>
            </li>

            <li>
                <Button onClick={openLoginLink('facebook')}>
                    <Facebook />
                </Button>
            </li>
        </ul>
    );
};

SocialMedias.defaultProps = {
    eventId: null,
    registerAsExhibitor: false,
    registerAsScholar: false,
    registerAsParticipant: false,
    preselectedAccessPackageId: null,
};
export default SocialMedias;
