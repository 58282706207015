import React from 'react';
import {connect} from 'react-redux';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {preventDefaultDrag} from '../../../../Utils/utils';
import AddIcon from '@material-ui/icons/Add';
import TextWithTooltipOnOverflow from 'Components/TextWithTooltipOnOverflow/TextWithTooltipOnOverflow';
import MeetingButton from './MeetingButton';

class ChatTitle extends React.PureComponent {
    state = {
        chatName: '',
        avatarSrc: '',
        nameEllipsis: false,
    };

    componentDidMount() {
        this.setChatName();
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            this.setChatName();
        }
    }

    isEllipsisActive = (node) => {
        return (node?.offsetWidth < node?.scrollWidth);
    }

    nameRef = (node) => {
        if(!node){
            return false;
        }
        const isActive = this.isEllipsisActive(node);
        this.setState({nameEllipsis: isActive});
    }

    setChatName = () => {
        const {privateChatPartnerData} = this.props;

        const chatName = privateChatPartnerData.first + ' ' + privateChatPartnerData.last;
        const avatarSrc = privateChatPartnerData?.avatarSmall;
        this.setState({
            chatName: chatName,
            avatarSrc: avatarSrc,
        });
    };

    render() {
        const {hasMeeting, lastChatMessage} = this.props;
        const {chatName, avatarSrc} = this.state;
        return (
            <div onDragStart={preventDefaultDrag} className="single-chat-header">
                <div>
                    <div
                        className="user-avatar"
                        style={{
                            backgroundImage: avatarSrc && `url('${process.env.REACT_APP_AVATAR_FOLDER}${avatarSrc}')`,
                        }}
                    />

                    <p
                        onDragStart={preventDefaultDrag}
                        className={`group-chat-name ellipsis privateChat ${!hasMeeting ? 'without-meeting' : ''}`}
                    >
                        {/* <Tooltip arrow title={this.state.nameEllipsis ? chatName : ''}> */}
                        <Tooltip arrow title={chatName.length > 57 ? chatName : ''}>
                            <span className="ellipsis" ref={this.nameRef}>
                                {chatName}{' '}
                            </span>
                        </Tooltip>
                    </p>
                </div>
                {!hasMeeting && (
                    <MeetingButton
                        disabled={!lastChatMessage}
                        clickAction={this.props.handleOpenCreateMeetingDialog}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
    };
};

export default connect(mapStateToProps)(ChatTitle);
