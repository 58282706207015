import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import axios from '../../store/axios-instance';
import {preventDefaultDrag} from '../../Utils/utils';
import Button from '@material-ui/core/Button';
import Spinner from "../../SmallLayoutComponents/Spinner";

class ChangePasswordMobile extends React.Component {
    state = {
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
        disabled: true,
        error: false,
        loading: false,
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                error: false,
            },
        );
    };

    handleUpdatePassword = () => {
        this.setState({loading: true});
        axios({
            method: 'put',
            url: '/users',
            data: {oldPassword: this.state.oldPassword, password: this.state.password},
        })
            .then((response) => {
                this.props.onRefreshUserUserData();
                this.props.closeDialog();
                this.setState({loading: false});
            })
            .catch((error) => {
                this.setState({error: true, loading: false});
            });
    };

    checkIsButtonDisabled = () => this.state.oldPassword !== ''
        && this.state.password !== ''
        && this.state.passwordConfirmation !== '';

    render() {
        const {opened, translation} = this.props;
        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`edit-user-information small-padding-top ${opened ? 'opened' : ''}`}
            >
                {this.state.loading && <Spinner />}
                <div>
                    <div onDragStart={preventDefaultDrag} className="page-title">
                        <p>{translation?.changePassword.title}</p>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="content-wrapper">
                        <div onDragStart={preventDefaultDrag} className="form-wrapper">
                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleUpdatePassword}
                                className="change-password-form"
                            >
                                <TextValidator
                                    label={translation?.changePassword.oldPassword}
                                    onChange={this.handleChange}
                                    name="oldPassword"
                                    value={this.state.oldPassword}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    validators={['required', 'minStringLength:8']}
                                    errorMessages={[
                                        `${translation?.errors.required}`,
                                        `${translation?.errors.passwordMinLength}`,
                                    ]}
                                />
                                <TextValidator
                                    label={translation?.changePassword.inputPassword}
                                    onChange={this.handleChange}
                                    name="password"
                                    value={this.state.password}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    validators={['required', 'minStringLength:8']}
                                    errorMessages={[
                                        `${translation?.errors.required}`,
                                        `${translation?.errors.passwordMinLength}`,
                                    ]}
                                />
                                <TextValidator
                                    label={translation?.changePassword.inputConfirmPassword}
                                    onChange={this.handleChange}
                                    name="passwordConfirmation"
                                    value={this.state.passwordConfirmation}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    validators={['isPasswordMatch', 'required', 'minStringLength:8']}
                                    errorMessages={[
                                        `${translation?.errors.passwordMismatch}`,
                                        `${translation?.errors.required}`,
                                        `${translation?.errors.passwordMinLength}`,
                                    ]}
                                />
                                {this.state.error && (
                                    <p onDragStart={preventDefaultDrag} className="error-message">
                                        {translation?.changePassword.oldPasswordError}
                                    </p>
                                )}
                            </ValidatorForm>
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="buttons-actions">
                        <Button onClick={() => this.props.closeDialog('')}>
                            {translation?.generalText.close}
                        </Button>
                        <Button
                            onClick={this.handleUpdatePassword}
                            disabled={!this.checkIsButtonDisabled()}
                            color={'secondary'}
                        >
                            {translation?.generalText.update}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordMobile);
