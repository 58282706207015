import {preventDefaultDrag} from "../../../Utils/utils";
import React from "react";
import {NewResourceDocument} from "./NewResourceDocument";
import {ExistingResourceDocuments} from "./ExistingResourceDocuments";

export class ResourceDocuments extends React.Component {
    render() {
        let {
            wrapperRef,
            uploadDocument,
            textFieldRef,
            newDocumentName,
            changeDocumentName,
            fileErrorText,
            newDocumentFile,
            handleDocumentChange,
            loading,
            documents,
            removeDocument,
        } = this.props;

        return <div onDragStart={preventDefaultDrag} ref={wrapperRef} className="documents timeslot-documents">
            <p onDragStart={preventDefaultDrag} className="resource-title">
                DOCUMENTS
            </p>
            <NewResourceDocument
                uploadDocument={uploadDocument}
                textFieldRef={textFieldRef}
                newDocumentName={newDocumentName}
                changeDocumentName={changeDocumentName}
                fileErrorText={fileErrorText}
                newDocumentFile={newDocumentFile}
                handleDocumentChange={handleDocumentChange}
                loading={loading}
            />

            <ExistingResourceDocuments
                documents={documents}
                loading={loading}
                removeDocument={removeDocument}
            />
        </div>;
    }
}