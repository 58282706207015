import React, {PureComponent} from 'react';
import {preventDefaultDrag} from '../../../Utils/utils';
import BrandingBackgroundImage from './LayoutComponents/BrandingBackgroundImage';
import {connect} from 'react-redux';
// import * as actions from '../../../store/actions';

class LobbyBanner extends PureComponent {
    render() {
        const {handleOpenErrorSnackbar, handleOpenSuccessSnackbar, branding} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="branding-lobby-content">
                <h4 className="advanced-options-title">LOBBY & AUDITORIUM BANNER</h4>
                <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                    <div onDragStart={preventDefaultDrag} className="branding-background-image-container">
                        <BrandingBackgroundImage
                            for={'lobbyBanner'}
                            label={'Lobby & Auditorium Banner'}
                            description={
                                'Upload an image that will be displayed as the banner of the event in the Lobby and Auditorium.'
                            }
                            subLabel={
                                'Recommended image ratio 1 : 1.91 (portrait-oriented, e.g. 537 x 1024 pixels).'
                            }
                            imageRatio={1.91}
                            dragDropOrientation="portrait"
                            image={branding?.data?.lobbyBanner}
                            filesUrl={branding?.data?.filesUrl}
                            openSuccessSnackbar={handleOpenSuccessSnackbar}
                            openErrorSnackbar={handleOpenErrorSnackbar}
                            uploadUrl={'branding/upload-image'}
                            deleteUrl={'branding/delete-image/'}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        branding: state.event.branding,
    };
};

export default connect(mapStateToProps, null)(LobbyBanner);