import React from 'react';
import {ReactComponent as MainLogoImage} from '../Images/svg/logo_header.svg';
import {ReactComponent as MainLogoImageColored} from '../Images/svg/logo_mobile_purple.svg';
import {ReactComponent as MainLogoImageColoredMobile} from '../Images/svg/logo_scrolled.svg';

const MainLogo = (props) => {
    const {isMobile} = props;
    if (props.colored) {
        if (isMobile) {
            return <MainLogoImageColoredMobile />;
        }
        return <MainLogoImageColored />;
    }
    return <MainLogoImage />;
};

export default MainLogo;
