import {useRef} from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import variables from 'CSS/_variables.module.scss';
import '../../../../Components/InputFields/TextFieldInfoPointStyles.scss';
import classnames from 'classnames';
import FormLabel from '@material-ui/core/FormLabel';
import {useSelector} from 'react-redux';

const TextFieldInfoPointLoginRegistration = ({
    field,
    placeholderWidthRestrict,
    handleChange,
    isTooltipDisabled = false,
}) => {
    const inputRef = useRef(null);

    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const getTextWidth = (text) => {
        // re-use canvas object for better performance
        const canvas = (getTextWidth.canvas = document.createElement('canvas'));
        const context = canvas.getContext('2d');
        context.font = `normal 16px ${variables.fontFamily}`;
        const metrics = context.measureText(text);
        return Math.ceil(metrics.width);
    };

    const placeholderWidth = getTextWidth(field.label);
    const isValid = inputRef?.current?.isValid();
    const isRequired = field.validators.includes('required');

    return (
        <div
            className={classnames(
                'input-wrapper',
                {
                    'show-tooltip': placeholderWidth > placeholderWidthRestrict,
                    'text-field-width': isTooltipDisabled,
                },
                {
                    'error-border': isValid === false,
                }
            )}
        >
            <FormLabel className="break-word registration-usage select-field-v2" component="legend">
                {field.label}
            </FormLabel>

            <TextValidator
                label={
                    field.value === '' &&
                    (translation.newLoginRegistrations.yourAnswer ||
                        defaultTranslation.newLoginRegistrations.yourAnswer) +
                        (!isRequired
                            ? ` (${
                                  translation.newLoginRegistrations.optional ||
                                  defaultTranslation.newLoginRegistrations.optional
                              })`
                            : '')
                }
                key={field.name}
                placeholder={field.label}
                type={field.type}
                name={field.name}
                value={field.value}
                variant="outlined"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                validators={field.validators}
                errorMessages={field.errorMessages}
                multiline={field.multiline > 0}
                rows={field.multiline}
                fullWidth={true}
                margin="normal"
                className={classnames(`${field.type}-${field.name}-mo-error`, {
                    'field-container': !isTooltipDisabled,
                    'email-text-field': isTooltipDisabled,
                    withEndAdornment: isTooltipDisabled,
                })}
                ref={inputRef}
            />
        </div>
    );
};

export default TextFieldInfoPointLoginRegistration;
