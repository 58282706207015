import React from 'react';
import ConfirmDelete from '../../../Dialogs/ConfirmDelete';
import {connect} from 'react-redux';
import axios from '../../../store/axios-instance';
import * as actions from '../../../store/actions';
import AddIcon from '@material-ui/icons/Add';
import {preventDefaultDrag} from '../../../Utils/utils';
import AccordionLayout from './LayoutComponents/AccordionLayout';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Button from '@material-ui/core/Button';

class HomepagePartners extends React.Component {
    state = {
        openAddEditPartnerDialog: false,
        showConfirmDeleteDialog: false,
        editedPartnerId: null,
        currentPartnerIndex: null,
        openAddPartner: false,
        showConfirmCloseAccordion: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // after we add a new partner, open the accordion to him
        if(prevProps.branding?.data?.partners?.length < this.props.branding?.data?.partners?.length){
            this.setState({
                currentPartnerIndex: this.props.branding?.data?.partners?.length - 1
            });
        }
    }

    handleCloseDeletePopup = () =>
        this.setState({
            showConfirmCloseAccordion: true,
        });

    handleOpenAddPartnerDialog = () =>
        this.setState({
            openAddEditPartnerDialog: true,
            currentPartnerIndex: null,
            openAddPartner: true,
        });

    handleCloseDialog = () =>
        this.setState({
            showConfirmCloseAccordion: false,
            openAddEditPartnerDialog: false,
            openAddPartner: false,
        });

    handleAddNewPartner = () =>
        this.setState((prevState) => ({
            openAddPartner: !prevState.openAddPartner,
            currentPartnerIndex: null,
        }));

    openConfirmDeleteDialog = (id) => () =>
        this.setState({
            editedPartnerId: id,
            showConfirmDeleteDialog: true,
        });

    closeConfirmDeleteDialog = () =>
        this.setState({
            editedPartnerId: null,
            showConfirmDeleteDialog: false,
            showConfirmCloseAccordion: false,
            openAddPartner: true,
        });

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return this.handleOpenAddPartnerDialog();
    };

    setPartnerIndex = (partnerIndex) => (e) => {
        let newIndex = this.state.currentPartnerIndex === partnerIndex ? null : partnerIndex;
        this.setState({
            currentPartnerIndex: newIndex,
            openAddPartner: false,
        });
    };

    handleAddPartner = (partnerData) => {
        const {eventId} = this.props;
        axios({method: 'post', url: `/event/${eventId}/branding/add-partner`, data: partnerData})
            .then((response) => {
                this.handleCloseDialog();
                const brandingData = response.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    handleDeletePartner = () => {
        const {eventId} = this.props;
        axios({method: 'delete', url: `/event/v2/${eventId}/branding/remove-partner/${this.state.editedPartnerId}`})
            .then((response) => {
                this.closeConfirmDeleteDialog();
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    render() {
        let {openAddEditPartnerDialog, openAddPartner, showConfirmDeleteDialog, showConfirmCloseAccordion} = this.state;
        const {branding, handleOpenSuccessSnackbar, handleOpenErrorSnackbar} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="branding-homepage-partners new-accordion-design">
                <h4 className="advanced-options-title">
                    PARTNERS
                    <Button
                        onClick={this.handleOpenAddPartnerDialog}
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD PARTNER
                    </Button>
                </h4>
                <div onDragStart={preventDefaultDrag} className="list-wrapper">
                    {branding.data?.partners.length > 0 ? (
                        <div onDragStart={preventDefaultDrag} className="accordion-container new-role-container">
                            {branding.data.partners.map((partner, partnerIndex) => {
                                const isActivePartner = partnerIndex === this.state.currentPartnerIndex;
                                return (
                                    <AccordionLayout
                                        isActive={isActivePartner}
                                        role={partner}
                                        roles={branding.data.partners}
                                        roleTitle={'partner'}
                                        roleIndex={partnerIndex}
                                        openConfirmDeleteDialog={this.openConfirmDeleteDialog(partner._id)}
                                        handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                                        handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                                        handleAddRole={this.handleAddPartner}
                                        onChange={this.setPartnerIndex(partnerIndex)}
                                        filesUrl={branding.data.filesUrl}
                                        showSection={true}
                                        key={partner._id}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div
                            onDragStart={preventDefaultDrag}
                            className={`advanced-options-container ${openAddEditPartnerDialog ? 'd-none' : ''}`}
                        >
                            <p onDragStart={preventDefaultDrag} className="list-empty-text">
                                You currently don't have any partners added
                            </p>
                        </div>
                    )}
                </div>
                {openAddEditPartnerDialog && (
                    <div onDragStart={preventDefaultDrag} className="accordion-container new-role-container">
                        <AccordionLayout
                            isActive={openAddPartner}
                            roleTitle={'partner'}
                            onChange={this.handleAddNewPartner}
                            openConfirmDeleteDialog={this.handleCloseDeletePopup}
                            handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                            handleAddRole={this.handleAddPartner}
                            showSection={false}
                        />
                    </div>
                )}
                <div onDragStart={preventDefaultDrag} className="actions-container">
                    <Button
                        onClick={this.handleOpenAddPartnerDialog}
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD PARTNER
                    </Button>
                </div>
                {showConfirmDeleteDialog && (
                    <ConfirmDelete
                        open={showConfirmDeleteDialog}
                        closeConfirmDeleteDialog={this.closeConfirmDeleteDialog}
                        dialogTitle={'Delete Partner'}
                        dialogDescription={'Are you sure you want to delete this partner?'}
                        dialogConfirmButtonLabel={'Delete'}
                        handleConfirmDelete={this.handleDeletePartner}
                    />
                )}
                {showConfirmCloseAccordion && (
                    <ConfirmDelete
                        open={showConfirmCloseAccordion}
                        closeConfirmDeleteDialog={this.closeConfirmDeleteDialog}
                        dialogTitle={'Delete Partner'}
                        dialogDescription={'Are you sure you want to delete this partner?'}
                        dialogConfirmButtonLabel={'Delete'}
                        handleConfirmDelete={this.handleCloseDialog}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        branding: state.event.branding
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepagePartners);
