import {createBrowserHistory} from 'history';
import Menu from 'Components/Menu';
import ScrollToTop from 'Components/ScrollToTop';
import EventPublicRoute from './EventPublicRoute';
import {Component, lazy, Suspense} from 'react';
import {connect} from 'react-redux';
import {Route, Router as BrowserRouter, Switch} from 'react-router-dom';
import CustomizedSpinner from 'SmallLayoutComponents/CustomizedSpinner';
import * as actions from 'store/actions/index';
import EventPrivateRoute from './EventPrivateRoute';
import ExhibitorRepRoute from './ExhibitorRepRoute';
import ExhibitorRoute from './ExhibitorRoute';
import OrganizerRoute from './OrganizerRoute';
import ParticipantRoute from './ParticipantRoute';
import PrivateRoute from './PrivateRoute';
import SuperAdminRoute from './SuperAdminRoute';
import RegisterAsExhibitor from 'Pages/LoginRegistration/pages/registration/RegisterAsExhibitor';
import RegisterAsParticipant from 'Pages/LoginRegistration/pages/registration/RegisterAsParticipant';
import RegisterAsScholar from 'Pages/LoginRegistration/pages/registration/RegisterAsScholar';
/*
import RegisterEvent from 'Pages/Event/RegisterEvent';
*/
import RegisterEvent from 'Pages/LoginRegistration/pages/registration/RegisterEvent';

import Auditorium from 'Pages/Auditorium/Auditorium';
import CreateEvent from 'Pages/CreateEvent';
import AuditoriumArhive from 'Pages/Event/AuditoriumArhive';
import AuditoriumArhiveSingleVideo from 'Pages/Event/AuditoriumArhiveSingleVideo';
import HomepageEvent from 'Pages/Event/HomepageEvent';
import Lobby from 'Pages/Event/Lobby';
import HomepagePlatform from 'Pages/HomepagePlatform';

import MyAccount from 'Pages/MyAccount/MyAccount';
import NewsletterUnsubscribe from 'Pages/NewsletterUnsubscribe';
import NotFound from 'Pages/NotFound';
import SelectRole from 'Pages/LoginRegistration/pages/registration/SelectRole';
import PasswordRecovery from 'Pages/LoginRegistration/pages/PasswordRecovery';
import RegisterPlatform from 'Pages/LoginRegistration/pages/registration/RegisterPlatform';
import Showfloor from 'Pages/Showfloor/Showfloor';
import LoginPlatform from 'Pages/LoginRegistration/pages/login/LoginPlatform';
import LoginEvent from 'Pages/LoginRegistration/pages/login/LoginEvent';
import BuildingAuditorium from 'Pages/Building/BuildingAuditorium';
import BuildingAuditoriumArchive from 'Pages/Building/BuildingAuditoriumArchive';
import BuildingAuditoriumArchiveSingleVideo from 'Pages/Building/BuildingAuditoriumArchiveSingleVideo';
import BuildingLobby from 'Pages/Building/BuildingLobby';
import BuildingShowfloor from 'Pages/Building/BuildingShowfloor';
import Village from 'Pages/Event/Village';
import AdminStatistics from 'Pages/Admin/AdminStatistics/AdminStatistics';
import AllEvents from 'Pages/Admin/AllEvents/AllEvents';
import AdminEventStatistics from 'Pages/Admin/AllEvents/SingleEvent/SingleEvent.js';
import EventArchive from 'Pages/Event/EventArchive';
import EventArchiveVideo from 'Pages/Event/EventArchiveVideo';
import EventPrivacyPolicy from 'Pages/Event/EventPrivacyPolicy';
import EventTermsAndConditions from 'Pages/Event/EventTermsAndConditions';
import SingleLoginEventPage from 'Pages/Event/SingleLoginEventPage';
import Cookies from 'Pages/GDPR/Cookies';
import PrivacyPolicy from 'Pages/GDPR/PrivacyPolicy';
import TermsAndConditions from 'Pages/GDPR/TermsAndConditions';
import EventsToday from 'Pages/Admin/EventsToday/EventsToday';
import ConsentCollection from 'Pages/GDPR/ConsentCollection';
import LiveSession from 'Pages/LiveSession/LiveSession';
import LiveSessionRegistration from 'Pages/LiveSession/LiveSessionRegistration';
import SessionDashboard from 'Pages/SessionDashboard';
import SuccessIframeRegistration from 'Pages/SuccessIframeRegistration/SuccessIframeRegistration';
import ZoomSessionDashboard from 'Pages/ZoomSessionDashboard/ZoomSessionDashboard';

const DesignSystemComponents = lazy(() => import('DesignSystemComponents/DesignSystemComponents'));
const ExhibitorDashboard = lazy(() => import('Pages/Event/ExhibitorDashboard'));
const ExhibitorBoothView = lazy(() => import('Pages/Event/ExhibitorBoothView'));
const ExhibitorFullPreview = lazy(() => import('Components/BoothManagement/BoothFullPreview'));

const EventBuildings = lazy(() => import('Components/EventManagement/EventBuildings'));
const EventProgramDashboard = lazy(() => import('Components/EventManagement/EventProgramDashboard'));
const EventRoomsDashboard = lazy(() => import('Components/EventManagement/EventRoomsDashboard'));
const EventShowfloorDashboard = lazy(() => import('Components/EventManagement/EventShowfloorDashboard'));
const EventSetup = lazy(() => import('Components/EventManagement/EventSetup/EventSetup'));
const EventBranding = lazy(() => import('Components/EventManagement/EventBranding'));
const EventStatistics = lazy(() => import('Components/EventManagement/EventStatistics'));
const EventNewsletters = lazy(() => import('Components/EventManagement/EventNewsletters'));
const EventUsersDashboard = lazy(() => import('Components/EventManagement/UsersDashboard'));
const ArchivedEvents = lazy(() => import('Components/EventManagement/ArchivedEvents'));
const EventSessions = lazy(() => import('Components/EventManagement/EventSessions/EventSessions'));

const EventGDPR = lazy(() => import('Components/EventManagement/Legal/EventGDPR'));
const AddEditAudience = lazy(() =>
    import('Components/EventManagement/Communication/Audiences/SingleAudience/EditAudience')
);
const EventPayment = lazy(() => import('Components/EventManagement/Payment/EventPayment.js'));

const Dashboard = lazy(() => import('Pages/Admin/Dashboard'));
const OrganizerDetails = lazy(() => import('Pages/Admin/OrganizerDetails'));
const MasterAdminGDPRPlatform = lazy(() => import('Pages/Admin/GDPR/GDPRPlatform'));
const MasterAdminGDPREvent = lazy(() => import('Pages/Admin/GDPR/GDPREvent'));
const MasterAdminLegal = lazy(() => import('Pages/Admin/Legal/Legal'));
const MaterAdminEmailCampaigns = lazy(() => import('Pages/Admin/Legal/LegalEmailCampaign'));
const LegalConsentCollection = lazy(() => import('Pages/Admin/Legal/LegalConsentCollection'));

const history = createBrowserHistory();

class Routes extends Component {
    componentDidMount() {
        this.props.onAutoLoginWithToken();
        let prevLocation;

        this.unlisten = history.listen((location, action) => {
            const {eventSlug} = this.props;
            const urlEventSlug = location.pathname.replace('/event/', '').split('/')[0];

            if (eventSlug !== urlEventSlug) {
                // if we are on the /consent-collection page we don't want to reset the "eventSlug" in the redux store
                if (location.pathname === '/consent-collection') {
                    return null;
                }

                this.props.setEventSlug(urlEventSlug);
            }

            if (window.consentedToStatistics) {
                let _paq = (window._paq = window._paq || []);

                if (prevLocation?.pathname) {
                    _paq.push(['setReferrerUrl', prevLocation?.pathname]);
                }
                _paq.push(['setDocumentTitle', document.domain + '/' + document.title]);
                _paq.push(['setCustomUrl', location.pathname]);
                _paq.push(['trackPageView']);
            }
            prevLocation = location;
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const {user, event, eventSlug} = this.props;
        const userRolesInEvent = user?.eventRoles.find((role) => {
            if (!role.event) {
            }
            return role.event?.slug === eventSlug;
        });

        return (
            <BrowserRouter history={history}>
                <Menu />
                <ScrollToTop />
                <Suspense fallback={<CustomizedSpinner />}>
                    <Switch>
                        <Route path="/login" component={LoginPlatform} exact />
                        <Route path="/forgot-password" component={LoginPlatform} exact />
                        <Route path="/register" component={RegisterPlatform} exact />

                        {(window.location.hostname === 'staging.onvent.online' ||
                            window.location.hostname === 'localhost') && (
                            <Route path="/design-system-components" component={DesignSystemComponents} exact />
                        )}

                        <PrivateRoute path="/create-event" component={CreateEvent} exact />
                        <Route path="/password-recovery" component={PasswordRecovery} exact />
                        <Route path="/unsubscribe" component={NewsletterUnsubscribe} exact />
                        <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                        <Route path="/cookies" component={Cookies} exact />
                        <Route path="/terms-and-conditions" component={TermsAndConditions} exact />

                        <PrivateRoute path="/consent-collection" component={ConsentCollection} exact />
                        <EventPublicRoute path="/event/:eventSlug/select-role" component={SelectRole} exact />

                        <EventPublicRoute path="/event/:eventSlug/privacy-policy" component={PrivacyPolicy} exact />

                        <EventPublicRoute path="/event/:eventSlug/cookies" component={Cookies} exact />

                        <EventPublicRoute path="/event/:eventSlug" component={HomepageEvent} exact />
                        {/* we don't have a Login page anymore, socialLogin is now redirected to /register pages*/}
                        {/*
                        <EventPublicRoute path="/event/:eventSlug/login" component={SingleLoginEventPage} exact />
*/}
                        <EventPublicRoute path="/event/:eventSlug/login" component={LoginEvent} exact />
                        <EventPublicRoute path="/event/:eventSlug/register" component={RegisterEvent} exact />

                        <EventPublicRoute
                            path="/event/:eventSlug/event-privacy-policy"
                            component={EventPrivacyPolicy}
                            exact
                        />
                        <EventPublicRoute
                            path={`/event/:eventSlug/terms-and-conditions`}
                            component={EventTermsAndConditions}
                            exact
                        />
                        <EventPublicRoute
                            path="/event/:eventSlug/event-terms-and-conditions"
                            component={EventTermsAndConditions}
                            exact
                        />

                        <EventPublicRoute
                            path="/event/:eventSlug/iframe-registration"
                            component={SuccessIframeRegistration}
                            exact
                        />

                        <EventPrivateRoute
                            path="/event/:eventSlug/participant-registration"
                            component={RegisterAsParticipant}
                            exact
                        />
                        <EventPrivateRoute
                            path="/event/:eventSlug/exhibitor-registration"
                            component={RegisterAsExhibitor}
                            exact
                        />
                        <EventPrivateRoute
                            path="/event/:eventSlug/scholar-registration"
                            component={RegisterAsScholar}
                            exact
                        />

                        <ParticipantRoute path="/event/:eventSlug/lobby" component={Lobby} exact />
                        <ParticipantRoute path="/event/:eventSlug/showfloor" component={Showfloor} exact />
                        <ParticipantRoute
                            path="/event/:eventSlug/showfloor/:showfloorId/booth/:boothId"
                            component={Showfloor}
                            exact
                        />
                        <ParticipantRoute
                            path="/event/:eventSlug/auditorium/:auditoriumIndex?"
                            component={Auditorium}
                            exact
                        />
                        <ParticipantRoute
                            path="/event/:eventSlug/auditorium-archive"
                            component={AuditoriumArhive}
                            exact
                        />
                        <ParticipantRoute
                            path="/event/:eventSlug/auditorium-archive/:auditoriumId/timeslot/:timeslotId"
                            component={AuditoriumArhiveSingleVideo}
                            exact
                        />

                        <ParticipantRoute path="/event/:eventSlug/event-archive" component={EventArchive} exact />
                        <ParticipantRoute
                            path="/event/:eventSlug/event-archive/event/:archivedEventId/:videoId"
                            component={EventArchiveVideo}
                            exact
                        />
                        <ParticipantRoute path="/event/:eventSlug/village" component={Village} exact />
                        <ParticipantRoute
                            path="/event/:eventSlug/village/building/:buildingId/lobby"
                            component={BuildingLobby}
                            exact
                        />
                        <ParticipantRoute
                            path="/event/:eventSlug/village/building/:buildingId/auditorium"
                            component={BuildingAuditorium}
                            exact
                        />
                        <ParticipantRoute
                            path="/event/:eventSlug/village/building/:buildingId/showfloor"
                            component={BuildingShowfloor}
                            exact
                        />
                        <ParticipantRoute
                            path="/event/:eventSlug/village/building/:buildingId/auditorium-archive"
                            component={BuildingAuditoriumArchive}
                            exact
                        />
                        <ParticipantRoute
                            path="/event/:eventSlug/village/building/:buildingId/auditorium-archive/timeslot/:timeslotId"
                            component={BuildingAuditoriumArchiveSingleVideo}
                            exact
                        />
                        <ParticipantRoute path="/event/:eventSlug/my-account" component={MyAccount} exact />

                        <ExhibitorRoute
                            path="/event/:eventSlug/exhibitor-dashboard"
                            component={ExhibitorDashboard}
                            exact
                        />
                        <ExhibitorRepRoute
                            path="/event/:eventSlug/exhibitor-booth-view"
                            component={ExhibitorBoothView}
                            exact
                        />

                        <ExhibitorRepRoute
                            path="/event/:eventSlug/exhibitor-booth-view/:exhibitorId"
                            component={ExhibitorBoothView}
                            exact
                        />

                        <ExhibitorRepRoute
                            path="/event/:eventSlug/exhibitor-booth-preview"
                            component={ExhibitorFullPreview}
                            exact
                        />

                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/auditoriums"
                            component={EventRoomsDashboard}
                            hasAccess={userRolesInEvent?.accessRights?.auditoriums}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/program"
                            component={EventProgramDashboard}
                            hasAccess={userRolesInEvent?.accessRights?.program}
                        />
                        <OrganizerRoute path="/event/:eventSlug/dashboard/buildings" component={EventBuildings} exact />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/showfloor"
                            component={EventShowfloorDashboard}
                            hasAccess={userRolesInEvent?.accessRights?.showfloors}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/settings"
                            component={EventSetup}
                            hasAccess={userRolesInEvent?.accessRights?.setup}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/branding"
                            component={EventBranding}
                            hasAccess={userRolesInEvent?.accessRights?.branding}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/statistics"
                            component={EventStatistics}
                            hasAccess={userRolesInEvent?.accessRights?.statistics}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/participants"
                            component={EventUsersDashboard}
                            hasAccess={userRolesInEvent?.accessRights?.users}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/archived-events"
                            component={ArchivedEvents}
                            hasAccess={userRolesInEvent?.accessRights?.archive}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/event-sessions"
                            component={EventSessions}
                            exact
                            hasAccess={userRolesInEvent?.accessRights?.sessions && event?.sessionsStreaming}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/rtmp-session/:sessionId"
                            component={ZoomSessionDashboard}
                            exact
                        />
                        <ParticipantRoute
                            path="/event/:eventSlug/live-session/:sessionId/dashboard"
                            component={LiveSession}
                            exact
                        />

                        <Route strict path="/event/:eventSlug/live-session/:sessionId" component={LiveSession} exact />
                        <Route
                            strict
                            path="/event/:eventSlug/live-session/:sessionId/registration/:role"
                            component={LiveSessionRegistration}
                            exact
                        />

                        <Route
                            strict
                            path="/event/:eventSlug/session/:sessionId/:invitation?"
                            component={SessionDashboard}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/communication"
                            component={EventNewsletters}
                            hasAccess={userRolesInEvent?.accessRights?.communication}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/event-gdpr"
                            component={EventGDPR}
                            hasAccess={userRolesInEvent?.accessRights?.gdpr}
                        />
                        <OrganizerRoute
                            path="/event/:eventSlug/dashboard/event-payment"
                            component={EventPayment}
                            hasAccess={userRolesInEvent?.accessRights?.accessManagement}
                        />

                        <SuperAdminRoute path="/dashboard/organizers" component={Dashboard} exact />
                        <SuperAdminRoute path="/dashboard/organizers/statistics" component={AdminStatistics} exact />
                        <SuperAdminRoute path="/dashboard/organizers/all-events" component={AllEvents} exact />
                        <SuperAdminRoute
                            path="/dashboard/organizers/events/:eventId"
                            component={AdminEventStatistics}
                            exact
                        />
                        <SuperAdminRoute path="/dashboard/organizers/events-today" component={EventsToday} exact />
                        <SuperAdminRoute
                            path="/dashboard/organizers/all-events/page/:page"
                            component={AllEvents}
                            exact
                        />
                        <SuperAdminRoute path="/dashboard/organizer/:organizerId" component={OrganizerDetails} exact />
                        <SuperAdminRoute path="/dashboard/gdpr-platform" component={MasterAdminGDPRPlatform} exact />
                        <SuperAdminRoute path="/dashboard/gdpr-event" component={MasterAdminGDPREvent} exact />
                        <SuperAdminRoute path="/dashboard/legal" component={MasterAdminLegal} exact />
                        <SuperAdminRoute path="/dashboard/legal/events" component={MasterAdminLegal} exact />
                        <SuperAdminRoute
                            path="/dashboard/legal/events/:pageNumber"
                            component={MasterAdminLegal}
                            exact
                        />
                        <SuperAdminRoute
                            path="/dashboard/legal/email-campaigns"
                            component={MaterAdminEmailCampaigns}
                            exact
                        />
                        <SuperAdminRoute
                            path="/dashboard/legal/consent-collection"
                            component={LegalConsentCollection}
                            exact
                        />
                        <Route path="/" component={HomepagePlatform} exact />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user.data,
    event: state.event.data,
    loggedIn: state.user.loggedIn,
    eventSlug: state.event.eventSlug,
});
const mapDispatchToProps = (dispatch) => {
    return {
        onAutoLoginWithToken: () => dispatch(actions.autoLoginWithToken()),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
