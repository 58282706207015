import React, {Component} from 'react';
import TopMainImage from '../../Images/pages/platform-homepage/top-background-animation.jpg';
import SecondMainImage from '../../Images/pages/platform-homepage/fullscreen-top.jpg';
import {ReactComponent as LogoGreen} from '../../Images/pages/platform-homepage/animations/logo-green.svg';
import {ReactComponent as LogoPurple} from '../../Images/pages/platform-homepage/animations/logo-purple.svg';
import {ReactComponent as FullLogo} from '../../Images/pages/platform-homepage/animations/full-logo.svg';

import RectangleGreen from '../../Images/pages/platform-homepage/animations/rectangle-green.png';
import RectanglePurple from '../../Images/pages/platform-homepage/animations/rectangle-purple.png';
import {preventDefaultDrag} from '../../Utils/utils';

class AnimatedTopSection extends Component {
    state = {
        initialTimeoutRef: null,
        firstTimeoutRef: null,
        secondTimeoutRef: null,
        sectionClasses: '',
    };

    componentDidMount() {
        const body = document.body;
        body.classList.remove('show-all-page');
        body.classList.add('no-scroll');
        body.classList.add('platform-homepage');
        const initialTimeoutRef = window.setTimeout(this.startFirstAnimation, 1500);
        this.setState({initialTimeoutRef: initialTimeoutRef});
    }

    componentWillUnmount() {
        const body = document.body;
        body.classList.remove('no-scroll');
        body.classList.remove('platform-homepage');
        if (this.state.initialTimeoutRef) {
            window.clearTimeout(this.state.initialTimeoutRef);
        }
        if (this.state.firstTimeoutRef) {
            window.clearTimeout(this.state.firstTimeoutRef);
        }
        if (this.state.secondTimeoutRef) {
            window.clearTimeout(this.state.secondTimeoutRef);
        }
    }

    startFirstAnimation = () => {
        if (!this.state.sectionClasses.includes('animate')) {
            const firstTimeoutRef = window.setTimeout(this.startSecondAnimation, 1600);
            this.setState({sectionClasses: 'animate', firstTimeoutRef: firstTimeoutRef});
        }
    };

    startSecondAnimation = () => {
        this.setState({sectionClasses: 'animate second'});
    };

    startThirdAnimation = (e) => {
        e.stopPropagation();
        if (!this.state.sectionClasses.includes('third')) {
            const secondTimeoutRef = window.setTimeout(this.startFourthAnimation, 1600);
            this.setState({sectionClasses: 'animate second third', secondTimeoutRef: secondTimeoutRef});
        }
    };
    startFourthAnimation = () => {
        const body = document.body;
        body.classList.add('show-all-page');
        this.setState({sectionClasses: 'animate second third fourth'});
    };

    render() {
        return (
            <section className={`animated-top-section ${this.state.sectionClasses}`}>
                <img
                    draggable="false"
                    onDragStart={preventDefaultDrag}
                    src={TopMainImage}
                    className="full-image first"
                    alt=""
                />
                <img
                    draggable="false"
                    onDragStart={preventDefaultDrag}
                    src={SecondMainImage}
                    className="full-image second"
                    alt=""
                />
                <div onDragStart={preventDefaultDrag} className="image-gradient-overlay-black"></div>
                <div onDragStart={preventDefaultDrag} className="image-gradient-overlay-purple"></div>
                <div onDragStart={preventDefaultDrag} className="section-heading">
                    <div onDragStart={preventDefaultDrag} className="elements-container">
                        <div onDragStart={preventDefaultDrag} className="logo-animation-container">
                            <div onDragStart={preventDefaultDrag} className="green">
                                <LogoGreen />
                            </div>
                            <div onDragStart={preventDefaultDrag} className="purple">
                                <LogoPurple />
                            </div>
                            <div onDragStart={preventDefaultDrag} className="full-logo">
                                <FullLogo />
                            </div>
                        </div>
                        <div
                            onDragStart={preventDefaultDrag}
                            className="start-button"
                            onClick={this.startThirdAnimation}
                        >
                            <span>Start Journey</span>
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="titles-container">
                        <div onDragStart={preventDefaultDrag} className="first-title">
                            <div onDragStart={preventDefaultDrag} className="img-container">
                                <img draggable="false" onDragStart={preventDefaultDrag} src={RectanglePurple} alt="" />
                            </div>
                            <h1>Next generation</h1>
                        </div>
                        <div onDragStart={preventDefaultDrag} className="second-title">
                            <div onDragStart={preventDefaultDrag} className="img-container">
                                <img draggable="false" onDragStart={preventDefaultDrag} src={RectangleGreen} alt="" />
                            </div>
                            <h2>online events</h2>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AnimatedTopSection;
