import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import axios from '../../store/axios-instance';
import ColoredScrollbars from '../../SmallLayoutComponents/ColoredScrollbars';
import {getTimeAgoString, preventDefaultDrag} from '../../Utils/utils';
import CloseTopNavButton from '../../SmallLayoutComponents/CloseTopNavButton';
import {withRouter} from 'react-router-dom';
import '../../CSS/notifications.scss';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as EmptyNotificationsIcon} from '../../Images/svg/notifications.svg';
import Spinner from 'SmallLayoutComponents/Spinner';

class Notifications extends React.Component {
    state = {
        isActive: false,
    };

    componentDidMount() {
        axios({method: 'post', url: '/users/me/see-all-notifications'})
            .then(() => this.props.onGetNotifications())
            .catch(() => {});
    }

    addActiveClass = () =>
        this.setState({
            isActive: !this.state.isActive,
        });

    sortNotifications = () => {
        let orderedNotifications = this.props.notifications.slice(0).reverse();
        let filteredNotifications = orderedNotifications.filter(
            (notification) =>
                notification.type !== 'newPrivateChatMessage' && notification.type !== 'newGroupChatMessage'
        );

        // TODO: remove meetingStartingSoon from 'new-notifications' and DB user model
        // this filter is 'mizerie' and unnecessary

        // remove meetingStartingSoon notification older than one hour
        if (filteredNotifications.filter((el) => el.type === 'meetingStartingSoon').length > 0) {
            let indexesToRemove = [];
            filteredNotifications.forEach((notEl, index) => {
                if (notEl.type === 'meetingStartingSoon') {
                    const now = new Date().getTime();
                    const notificationTime = new Date(notEl.createdAt).getTime();
                    if (now - notificationTime > 60 * 60 * 1000) {
                        indexesToRemove.push(index);
                    }
                }
            });
            if (indexesToRemove.length) {
                filteredNotifications = filteredNotifications.filter(
                    (el, index) => indexesToRemove.indexOf(index) === -1
                );
            }
        }

        return filteredNotifications;
    };

    getNotificationText = (notificationType) => {
        const {translation, defaultTranslation} = this.props;
        let notificationText;
        switch (notificationType) {
            case 'contactRequestReceived':
                notificationText =
                    translation?.notificationsDropdown.contactRequestReceived ||
                    defaultTranslation?.notificationsDropdown.contactRequestReceived;
                break;
            case 'contactRequestAccepted':
                notificationText =
                    translation?.notificationsDropdown.contactRequestAccepted ||
                    defaultTranslation?.notificationsDropdown.contactRequestAccepted;
                break;
            case 'meetingRequestReceived':
                notificationText =
                    translation?.notificationsDropdown.meetingRequestReceived ||
                    defaultTranslation?.notificationsDropdown.meetingRequestReceived;
                break;
            case 'meetingRequestAccepted':
                notificationText =
                    translation?.notificationsDropdown.meetingRequestAccepted ||
                    defaultTranslation?.notificationsDropdown.meetingRequestAccepted;
                break;
            case 'meetingRequestCanceled':
                notificationText =
                    translation?.notificationsDropdown.meetingRequestCanceled ||
                    defaultTranslation?.notificationsDropdown.meetingRequestCanceled;
                break;
            case 'meetingStartingSoon':
                notificationText =
                    translation?.notificationsDropdown.meetingStarting ||
                    defaultTranslation?.notificationsDropdown.meetingStarting;
                break;
            case 'newBoothPost':
                notificationText =
                    translation?.notificationsDropdown.newBoothPost ||
                    defaultTranslation?.notificationsDropdown.newBoothPost;
                break;
            default:
        }
        return notificationText;
    };

    getPartnerName = (meetingId) => {
        const {userMeetings, userId} = this.props;
        const meetingData = userMeetings.find((el) => el._id === meetingId);

        // check deleted users...
        if (meetingData && meetingData.owner.user !== null && meetingData.partner.user !== null) {
            if (meetingData && meetingData.owner.user._id === userId) {
                return meetingData.partner.user.first + ' ' + meetingData.partner.user.last;
            } else if (meetingData) {
                return meetingData.owner.user.first + ' ' + meetingData.owner.user.last;
            }
        } else {
            return null;
        }
    };

    handleNotificationClick = (notification) => (e) => {
        const {mobile} = this.props;
        let notificationType = notification.type;
        switch (notificationType) {
            case 'newBoothPost':
                this.props.history.push(
                    `/event/${notification?.eventSlug}/exhibitor-booth-view/${notification.objectId}`
                );
                break;
            case 'contactRequestReceived':
            case 'contactRequestAccepted':
                if (mobile) {
                    this.props.seeContactsMobile();
                } else {
                    this.props.seeContacts();
                }
                break;
            case 'meetingRequestReceived':
            case 'meetingRequestAccepted':
            case 'meetingRequestCanceled':
                if (mobile) {
                    this.props.seeMeetingsMobile();
                } else {
                    this.props.seeMeetings();
                }
                break;
            case 'meetingStartingSoon':
                this.props.onOpenPrivateChat(notification.privateChat);
                break;
            default:
        }
    };

    handleEnterKey = (e) => {
        e.stopPropagation()
        if (e.key === 'Enter') return this.addActiveClass();
    };

    render() {
        let notifications = this.sortNotifications();
        notifications = notifications.filter((el) => el.user !== null);

        const {mobile, translation, defaultTranslation, platformLanguage, loadingEventUsers} = this.props;
        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`notifications-page dropdown-component ${mobile ? 'notifications-mobile' : ''}`}
            >
                <CloseTopNavButton />
                <div onDragStart={preventDefaultDrag} className="page-title">
                    <h3>{translation?.notificationsDropdown.title}</h3>
                </div>
                {notifications.length > 0 ? (
                    <div
                        className={`notifications-container ${this.state.isActive ? 'active' : ''}`}
                        style={this.state.isActive ? {height: notifications.length * 43 + 20} : {height: 5 * 43 + 20}}
                    >
                        {loadingEventUsers && <Spinner/>}
                        {this.state.isActive ? (
                            <ColoredScrollbars>
                                <ul
                                    className={`notifications-list active-scrollbar ${
                                        this.state.isActive ? 'active' : ''
                                    }`}
                                >
                                    {notifications.map((notification) => {
                                        const timeAgoString = getTimeAgoString(
                                            notification.createdAt,
                                            platformLanguage
                                        );
                                        const notificationText = this.getNotificationText(notification.type);

                                        let meetingPartnerName = '';
                                        if (notification.type === 'meetingStartingSoon') {
                                            meetingPartnerName = this.getPartnerName(notification.meeting);
                                        }
                                        // check deleted users...
                                        if (notification.type === 'meetingStartingSoon' && !meetingPartnerName) {
                                            return null;
                                        }

                                        return (
                                            <li key={notification._id}>
                                                <div
                                                    className="single-notification-text"
                                                    onClick={this.handleNotificationClick(notification)}
                                                >
                                                    <div onDragStart={preventDefaultDrag} className="contacts-wrapper">
                                                        {notification.user ? (
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="user-name"
                                                            >
                                                                {notification.user.first} {notification.user.last}
                                                            </span>
                                                        ) : null}
                                                        {notification.type === 'meetingStartingSoon' ? (
                                                            <>
                                                                {translation?.notificationsDropdown
                                                                    .meetingWillStartTextFirst ||
                                                                    defaultTranslation?.notificationsDropdown
                                                                        .meetingWillStartTextFirst}{' '}
                                                                <span
                                                                    onDragStart={preventDefaultDrag}
                                                                    draggable="false"
                                                                    className="user-name"
                                                                >
                                                                    {meetingPartnerName}{' '}
                                                                </span>
                                                                {translation?.notificationsDropdown
                                                                    .meetingWillStartTextSecond ||
                                                                    defaultTranslation?.notificationsDropdown
                                                                        .meetingWillStartTextSecond}
                                                            </>
                                                        ) : (
                                                            <span>{notificationText}</span>
                                                        )}
                                                    </div>
                                                    <div onDragStart={preventDefaultDrag} className="date-time">
                                                        <span>{timeAgoString}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </ColoredScrollbars>
                        ) : (
                            <ul className="notifications-list">
                                {notifications.map((notification) => {
                                    const timeAgoString = getTimeAgoString(
                                        notification.createdAt,
                                        platformLanguage
                                    );
                                    const notificationText = this.getNotificationText(notification.type);

                                    let meetingPartnerName = '';
                                    if (notification.type === 'meetingStartingSoon') {
                                        meetingPartnerName = this.getPartnerName(notification.meeting);
                                    }
                                    // check deleted users...
                                    if (notification.type === 'meetingStartingSoon' && !meetingPartnerName) {
                                        return null;
                                    }

                                    return (
                                        <li key={notification._id}>
                                            <div
                                                className="single-notification-text"
                                                onClick={this.handleNotificationClick(notification)}
                                            >
                                                <div onDragStart={preventDefaultDrag} className="contacts-wrapper">
                                                    {notification.user ? (
                                                        <span
                                                            onDragStart={preventDefaultDrag}
                                                            draggable="false"
                                                            className="user-name"
                                                        >
                                                            {notification.user.first} {notification.user.last}
                                                        </span>
                                                    ) : null}
                                                    {notification.type === 'meetingStartingSoon' ? (
                                                        <>
                                                            {translation?.notificationsDropdown
                                                                .meetingWillStartTextFirst ||
                                                                defaultTranslation?.notificationsDropdown
                                                                    .meetingWillStartTextFirst}{' '}
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="user-name"
                                                            >
                                                                {meetingPartnerName}{' '}
                                                            </span>
                                                            {translation?.notificationsDropdown
                                                                .meetingWillStartTextSecond ||
                                                                defaultTranslation?.notificationsDropdown
                                                                    .meetingWillStartTextSecond}
                                                        </>
                                                    ) : (
                                                        <span>{notificationText}</span>
                                                    )}
                                                </div>
                                                <div onDragStart={preventDefaultDrag} className="date-time">
                                                    <span>{timeAgoString}</span>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                ) : (
                    <div onDragStart={preventDefaultDrag} className="empty-notifications">
                        <p>
                            {translation?.notificationsDropdown.noNotifications ||
                                defaultTranslation?.notificationsDropdown.noNotifications}
                        </p>
                        <EmptyNotificationsIcon fill={colors.primary} width="48" height="48" />
                    </div>
                )}

                {notifications.length >= 6 ? (
                    <div
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        onDragStart={preventDefaultDrag}
                        className="see-all-notifications"
                    >
                        <p onDragStart={preventDefaultDrag} onClick={this.addActiveClass}>
                            {this.state.isActive
                                ? translation?.notificationsDropdown.hideAll ||
                                  defaultTranslation?.notificationsDropdown.hideAll
                                : translation?.notificationsDropdown.seeAll ||
                                  defaultTranslation?.notificationsDropdown.seeAll}
                        </p>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.user.data.notifications,
        userId: state.user.data._id,
        userMeetings: state.user.data.meetings,
        eventSlug: state.event.eventSlug,
        platformLanguage: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        loadingEventUsers: state.eventUsers.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetNotifications: () => dispatch(actions.getNotifications()),
        seeContacts: () => dispatch(actions.topNavSeeContacts()),
        seeMeetings: () => dispatch(actions.topNavSeeMeetings()),
        seeContactsMobile: () => dispatch(actions.topNavSeeContactsMobile()),
        seeMeetingsMobile: () => dispatch(actions.topNavSeeMeetingsMobile()),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
