import React from 'react';
import {checkTimeslotHasResources, preventDefaultDrag} from '../../../Utils/utils';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import MovieIcon from '@material-ui/icons/Movie';
import './AuditoriumSideButtons.scss';

class AuditoriumSideButtons extends React.Component {
    render() {
        const {timeSlot, translation, defaultTranslation, hasOnDemandVideos, currentTab, hasPolls, hasResources} =
            this.props;

        // checkTimeslotHasResources it is used on auditoriumArchive and eventArchive
        // hasResources that comes from this.props is for Auditorium page and works in realTime
        // we need to fix this and work for all pages
        let timeslotHasResources;
        if (hasResources === undefined) {
            timeslotHasResources = checkTimeslotHasResources(timeSlot);
        } else {
            timeslotHasResources = hasResources;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="side-buttons-outer">
                <div onDragStart={preventDefaultDrag} className="side-buttons-middle">
                    <div onDragStart={preventDefaultDrag} className="side-buttons-inner">
                        {timeSlot && (
                            <div
                                onClick={this.props.handleChangeTab('polls')}
                                className={`side-button-container ${hasPolls ? 'animate-in' : 'animate-out'} ${
                                    currentTab === 'polls' ? 'active' : ''
                                }`}
                            >
                                <span>{translation?.polls?.polls || defaultTranslation?.polls?.polls}</span>
                                <PollOutlinedIcon />
                            </div>
                        )}
                        {timeslotHasResources && (
                            <div
                                onClick={this.props.handleChangeTab('resources')}
                                className={`side-button-container ${currentTab === 'resources' ? 'active' : ''}`}
                            >
                                <span>{translation?.auditorium.resourcesButton}</span>
                                <AttachFileIcon />
                            </div>
                        )}
                        {hasOnDemandVideos && (
                            <div
                                onClick={this.props.handleChangeTab('onDemand')}
                                className={`side-button-container second-color ${
                                    currentTab === 'onDemand' ? 'active' : ''
                                }`}
                            >
                                <span>{translation?.auditorium.onDemandButton || 'ON-DEMAND'}</span>
                                <MovieIcon />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default AuditoriumSideButtons;
