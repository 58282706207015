import React from 'react';
import {Link} from 'react-router-dom';
import './BackToLive.scss';

import {ReactComponent as LiveStreamIcon} from '../../../Images/icons/live_streaming.svg';

const BackToLive = (props) => (
    <Link to={{pathname: props.linkTo}} className={'back-to-live-button'}>
        <LiveStreamIcon />
        <span>
            {props.translation?.auditoriumArchive.backToLive ||
                props.defaultTranslation?.auditoriumArchive.backToLive}
        </span>
    </Link>
);

export default BackToLive;
