import React, {useEffect, useMemo, useState} from 'react';
import SingleSessionItemAgenda from './SingleSessionItemAgenda';
import {useSelector} from 'react-redux';
import {
    d,
    dayNames,
    getAllDaysTranslated,
    getAllMonthsTranslated,
    getTimeslotDescriptionFormatted,
    getTimeslotDuration,
    monthNames,
} from 'Utils/utils';
import DialogAddToExternalCalendar from '../DialogAddToExternalCalendar';
import EmptyList from './EmptyList';
import LoadingTimeslots from '../LoadingTimeslots';
import {isMobileOnly} from 'react-device-detect';
import moment from 'moment/moment';
import {usePlatformTranslation} from 'services/hooks';

const Sessions = ({
    isComponentActive,
    sessions: sessionsFromAgenda,
    deleteFromMyAgenda,
    loading,
    activeTimeslot,
    handleSetActiveTimeslot,
    pastSlots,
    setDateForMobile,
}) => {
    //REDUX STATES
    const user = useSelector((state) => state?.user?.data);
    const event = useSelector((state) => state?.event?.data);
    const languages = useSelector((state) => state?.languages);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);

    /*translations*/
    const timeslotTitle = usePlatformTranslation((state) => state?.programDropdown?.timeslotTitle);
    const addTimeslotToYourCalendar = usePlatformTranslation(
        (state) => state?.programDropdown?.addTimeslotToYourCalendar
    );
    const noUpcomingSessionsTranslation = usePlatformTranslation(
        (state) => state?.agendaTranslations?.noUpcomingSessions
    );
    const slotScheduled = usePlatformTranslation((state) => state?.programDropdown?.slotScheduled);
    const tipTimeslotDescription = usePlatformTranslation((state) => state?.programDropdown?.tipTimeslotDescription);

    /* end translations*/

    //LOCAL STATES
    const [sessions, setSessions] = useState([]);
    const [calendarTimeslot, setCalendarTimeslot] = useState(null);
    const [externalCalendarDialog, setExternalCalendarDialog] = useState(false);
    const [calendarData, setCalendarData] = useState(null);
    const [emptyMessage, setEmptyMessage] = useState(noUpcomingSessionsTranslation);
    const [dateIds, setDateIds] = useState([]);
    const [activeId, setActiveId] = useState();

    const getTimezoneDifference = () => {
        if (user?.timezoneValue !== '') {
            return +user?.timezoneValue;
        }
        return event?.timezoneValue;
    };

    const getFormattedDateByTimezoneDifference = (date) => {
        let monthsInAllLangs = getAllMonthsTranslated(languages);
        let daysInAllLangs = getAllDaysTranslated(languages);
        const timezoneDifference = getTimezoneDifference();
        const timestampWithoutLocalTimezone = moment.utc(date).format('l LT');
        const calculatedTimestampBasedOnTimezoneDifference = moment(timestampWithoutLocalTimezone).add(
            timezoneDifference,
            'hours'
        );
        let day = calculatedTimestampBasedOnTimezoneDifference.format('DD');
        let dayOrder = dayNames.findIndex((el) => el === calculatedTimestampBasedOnTimezoneDifference.format('dddd'));
        let month = monthNames.findIndex((el) => el === calculatedTimestampBasedOnTimezoneDifference.format('MMMM'));
        let newMonth = monthsInAllLangs[month].charAt(0).toUpperCase() + monthsInAllLangs[month].slice(1);
        let year = calculatedTimestampBasedOnTimezoneDifference.format('YYYY');

        if (isMobileOnly) {
            return `${daysInAllLangs[dayOrder]}, ${day} ${newMonth}, ${year} `;
        } else {
            return `${day} ${newMonth}, ${year} `;
        }
    };

    //SESSIONS
    const getSessions = useMemo(() => {
        let newSessions = {...sessionsFromAgenda};
        newSessions = Object.values(newSessions);

        if (!pastSlots) {
            newSessions = newSessions?.filter((item) => d(item?.endTimestamp).getTime() >= d().getTime());
        }
        let newList = [];
        newSessions.forEach((item) => {
            const itemDate = getFormattedDateByTimezoneDifference(item.startTimestamp);

            let isObjExist = newList.find((el) => el.date === itemDate);
            if (isObjExist) {
                isObjExist.items = [...isObjExist.items, item];
            } else {
                newList.push({date: itemDate, itemDateAsNumber: d(item.startTimestamp).getTime(), items: [item]});
            }
        });

        newList = newList.sort(function (a, b) {
            return a.itemDateAsNumber - b.itemDateAsNumber;
        });

        setSessions(newList);
        setDateIds(newList.map((day) => day.itemDateAsNumber));
    }, [isComponentActive, sessionsFromAgenda]);
    //END - SESSIONS

    //CALENDAR FUNCTIONALITY
    const createTimeslotCalendarData = () => {
        const timeslotCalendarData = {
            title: `${calendarTimeslot.title}`,
            description: getTimeslotDescriptionFormatted(calendarTimeslot, translation),
            location: `${window.location.origin}/event/${event.slug}/`,
            start: calendarTimeslot.startTimestamp,
            end: calendarTimeslot.endTimestamp,
        };
        return timeslotCalendarData;
    };

    useEffect(() => {
        if (calendarTimeslot) {
            const calendarData = createTimeslotCalendarData();
            setCalendarData(calendarData);
            setExternalCalendarDialog(true);
        }
    }, [calendarTimeslot]);

    const hideAddToExternalCalendarDialog = () => {
        setExternalCalendarDialog(false);
    };

    const handleSetCalendarTimeslot = (timeslot) => () => {
        setCalendarTimeslot(timeslot);
        setExternalCalendarDialog(true);
    };

    //HEADLINE CHANGES WITH SCROLL MOVES
    const isInViewport = (el) => {
        if (el !== null) {
            let rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    };
    const elementInViewport = () => {
        let newIds = dateIds.filter((id) => isInViewport(document.getElementById(id)));
        setActiveId(newIds[0]);
    };

    let mainContainer = document.querySelector('.items-main-container');
    if (mainContainer !== null && isMobileOnly) {
        mainContainer.addEventListener(
            'scroll',
            () => {
                elementInViewport();
            },
            {
                passive: true,
            }
        );
    }
    //END - HEADLINE CHANGES WITH SCROLL MOVES

    const sortedDays = (day) => {
        return day.items.sort(function (a, b) {
            return d(a.startTimestamp).getTime() - d(b.startTimestamp).getTime();
        });
    };

    const isSessionsEmptyOrLoading = () => {
        if (loading) {
            return <LoadingTimeslots />;
        } else {
            return <EmptyList message={emptyMessage} />;
        }
    };

    return (
        <>
            {sessions.length <= 0 ? (
                isSessionsEmptyOrLoading()
            ) : (
                <>
                    <div className="all-sessions-and-meetings">
                        {sessions?.map((day, index) => {
                            sortedDays(day);

                            return (
                                <div key={day?.itemDateAsNumber} className="related-date-items">
                                    <h3 id={`${day?.itemDateAsNumber}`}>
                                        {day?.date[0]?.toUpperCase() + day?.date?.slice(1)}
                                    </h3>
                                    {index === 0 &&
                                        setDateForMobile(
                                            sessions?.find((item) => item?.itemDateAsNumber === activeId)?.date ||
                                                day?.date
                                        )}
                                    <div className="item-list">
                                        {sortedDays(day)?.map((singleSession, index) => {
                                            return (
                                                <SingleSessionItemAgenda
                                                    activeTimeslot={activeTimeslot}
                                                    deleteFromMyAgenda={deleteFromMyAgenda}
                                                    singleSession={singleSession}
                                                    key={singleSession._id}
                                                    handleSetActiveTimeslot={handleSetActiveTimeslot}
                                                    handleSetCalendarTimeslot={handleSetCalendarTimeslot}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {externalCalendarDialog && (
                        <DialogAddToExternalCalendar
                            open={externalCalendarDialog}
                            onClose={hideAddToExternalCalendarDialog}
                            eventCalendarData={calendarData}
                            title={addTimeslotToYourCalendar}
                            timeslotTitle={timeslotTitle}
                            timeslotDescripion={calendarTimeslot.title}
                            timeslotSpeakers={calendarTimeslot.speakers}
                            durationTitle={slotScheduled}
                            duration={getTimeslotDuration(calendarTimeslot, languages, event, user)}
                            tip={tipTimeslotDescription}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Sessions;
