import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = (props) => {
    return (
        <div className={props.custom ?? 'spinner'}>
            <CircularProgress color="primary"/>
        </div>
    );
};

export default Spinner;
