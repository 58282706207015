import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {EventAvailable as EventAvailableIcon, PlayArrow as PlayArrowIcon} from '@material-ui/icons';
import {zoomSessionStreamStart, zoomSessionStreamStop} from '../../../../store/actions';
import {Button} from "@material-ui/core";

const ZoomStreamControls = ({session, loadingZoomSession, zoomSessionStreamStart, zoomSessionStreamStop, translation, defaultTranslation}) => {
    const translatedError = translation?.sessions.liveStreamError || defaultTranslation?.sessions.liveStreamError;
    const handleStartStream = useCallback(() => zoomSessionStreamStart(session._id, translatedError), [
        session._id,
        zoomSessionStreamStart,
    ]);

    const handleStopStream = useCallback(() => zoomSessionStreamStop(session._id), [
        session._id,
        zoomSessionStreamStop,
    ]);

    const sessionHasRecords = session.records.length > 0;

    return (
        <div className="buttons_group">
            {!session.isActive && !sessionHasRecords && (
                <Button
                    onClick={handleStartStream}
                    disabled={loadingZoomSession}
                    variant={"text"}
                    startIcon={<PlayArrowIcon/>}
                >
                    {loadingZoomSession ? `${translation?.sessions.startingStream || defaultTranslation?.sessions.startingStream}` : `${translation?.sessions.startStream || defaultTranslation?.sessions.startStream}`}
                </Button>
            )}

            {session.isActive && (
                <React.Fragment>
                    <Button
                        onClick={handleStopStream}
                        variant={"text"}
                        startIcon={<EventAvailableIcon/>}
                    >
                        {translation?.sessions.stopStream || defaultTranslation?.sessions.stopStream}
                    </Button>
                </React.Fragment>
            )}

            {sessionHasRecords && !session.isActive && (
                <span>The session has ended. You can download the recording from above.</span>
            )}
        </div>
    );
};

export default connect(
    (state) => ({
        session: state.sessions.zoomSessionDashboard.session,
        loadingZoomSession: state.sessions.zoomSessionDashboard.isLoading,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    }),
    {
        zoomSessionStreamStart,
        zoomSessionStreamStop,
    }
)(ZoomStreamControls);
