export let organizerEmailTemplate = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN"
        "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang="en">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style type='text/css'>
        body {
            margin: 0;
            background-color: #FFFFFF;
            font-family: Montserrat, Verdana, sans-serif;
            font-style: normal;
            font-size: 16px;
            line-height: 24px;
        }
        u + .body {
            margin: 0;
            background-color: #FFFFFF;
            font-family: Montserrat, Verdana, sans-serif;
            font-size: 16px;
            line-height: 24px;
        }
        table {
            border-spacing: 0;
        }
        td {
            padding: 0;
        }
        img {
            border: 0;
        }
        a {
            text-decoration: none;
            font-weight: 700;
            color: unset;
        }
        .wrapper-email-template {
            width: 100%;
            table-layout: fixed;
            background-color: #FFFFFF;
        }
        .main-email-template {
            background-color: #FFFFFF;
            margin: 0 auto;
            width: 100%;
            max-width: 600px;
            border-spacing: 0;
            font-family: Montserrat, Verdana, sans-serif;
        }
        p.margin-0 {
            margin: 0 !important;
        }
        p.margin1em {
            margin-top: 1em !important;
            margin-bottom: 1em !important;
        }
        p.mb-1em {
            margin-bottom: 1em !important;
        }
        .font-styles {
        	font-size: 16px !important;
        	font-weight: 400;
        	line-height: 24px !important;
        	color: #2B2B2B;
        }
        #body-links-style a {
            color: #208918;
            font-weight: 700;
            text-decoration: none;
        }
        @media screen and (max-width: 600px) {
            .six-digit-code {
               font-size: 24px !important;
            }
            .mobile-header {
                font-size: 20px !important;
                line-height: 18px !important;
                padding-bottom: 12px;
            }
            .main-email-template {
            	max-width: 360px !important;
            }
            .header-section-mobile {
            	max-width: 360px !important;
            }
            .body-section-mobile {
            	max-width: 360px !important;
            }
            .body-paddings-mobile {
            	padding-left: 24px !important;
            	padding-right: 24px !important;
            }
        }
    </style>
</head>
<body class="body">
    <center class="wrapper-email-template" style="width: 100%;table-layout: fixed;background-color: #FFFFFF;">
        <table class="main-email-template" width='100%' style="background-color: #FFFFFF;width: 100% !important; max-width: 600px; border-spacing: 0; font-family: Montserrat, Verdana, sans-serif !important;">
            <tr align="center">
                <td height='56' style="text-align: center; height: 56px">
                <p class="margin-0" style='font-size: 10px;font-family: Montserrat, Verdana, sans-serif;line-height: 12px;color: #000000;font-weight: 600; padding-bottom: 6px;'>POWERED BY</p>
                    <a rel="noopener" href="https://myonvent.com/" target="_blank">
                        <img src='https://myonvent-s3bucket.s3.eu-central-1.amazonaws.com/files/email-template-pictures/myonvent_logo_horizontal_black.png' alt='myOnvent-logo' style='width: 130px; height: 16px;' width="130" height="16">
                    </a>
                </td>
            </tr>
            <!-- header section -->
            <tr>
                <td style="text-align: center">
                    <table class="header-section-mobile" width="100%" style="text-align: center; width: 100% !important; max-width: 600px">
                        <tr>
                            <td height='183' style="width: 100% !important; height: 183px; background-color: '[BG-COLOR-HEADER]'; text-align: center" bgcolor="[BG-COLOR-HEADER]">
                                <a rel="noopener" href="https://myonvent.com/" target="_blank">
                                    <img src='https://myonvent-s3bucket.s3.eu-central-1.amazonaws.com/files/email-template-pictures/megaphone.png' alt='header-image' style='width: 178px; height: 128px;' width="178" height="128">
                                </a>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <!-- spacing -->
            <tr>
                <td style='height: 23px' height="23">

                </td>
            </tr>

            <!-- body section -->
            <tr>
                <td>
                    <table class="body-section-mobile font-styles" width="100%" style="max-width: 600px;">
                        <tr>
                            <td class="body-paddings-mobile" id="body-links-style" style='font-family: Montserrat, Verdana, sans-serif !important; padding-left: 32px;padding-right: 32px;'>
                                [BODY_TEXT]
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <!-- spacing -->
            <tr>
                <td style='height: 46px' height="46">
                </td>
            </tr>
            <!-- text section -->
            <tr>
                <td style="text-align: center;">
                    <table style="text-align:center; margin: 0 auto;width: 346px" width="346px">
                        <tr>
                            <td style='height: 42px;text-align: center;margin: auto;font-family: Montserrat, Verdana, sans-serif;'>
                                <p class="margin-0" style='font-family: Montserrat, Verdana, sans-serif;font-weight: 400;font-size: 12px;line-height: 14px;text-align: center;margin: auto;color: #777081;'>You receive this email because you have previously signed up to one of the events at myOnvent platform. This is an informative email. No reply is required.</p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <tr>
                <td style='height: 12px' height="12">
                </td>
            </tr>
            <!-- unsubscribe section -->
            <tr>
                <td style="text-align: center;">
                    <table style='text-align:center; margin: 0 auto; width:346px;' width='346px'>
                        <tr>
                            <td height="17" style='text-transform: uppercase;text-align: center;height: 17px;margin: auto;'>
                                <p style='font-family: Montserrat, Verdana, sans-serif;font-weight: 600;font-size: 12px;line-height: 14px;text-align: center;color: #777081;'>
                                    UNSUBSCRIBE
                                </p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            
            <tr>
                <td style='height: 29px' height="29">
                </td>
            </tr>
            <!-- copyright section -->
            <tr>
                <td style="text-align: center;">
                    <table style='text-align:center; margin: 0 auto; width:346px;' width='346px'>
                        <tr>
                            <td style='text-transform: uppercase;text-align: center;height: 52px;margin: auto;'>
                                <p style='font-family: Montserrat, Verdana, sans-serif;font-weight: 600;font-size: 12px;line-height: 14px;text-align: center;margin: auto;color: #777081;'>PRIVACY POLICY</p>
                                <p class="margin1em" style='margin-top: 1em !important; margin-bottom: 1em !important; font-family: Montserrat, Verdana, sans-serif;font-weight: 500;font-size: 12px;line-height: 14px;text-align: center;color: #777081;'>&copy; [YEAR]</p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </center>
</body>
</html>
`