import React from 'react';
import './OnDemandVideos.scss';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import ColoredScrollbars from '../../SmallLayoutComponents/ColoredScrollbars';
import * as actions from '../../store/actions';
import OnDemandVideosAuditorium from './OnDemandVideosAuditorium';
import colors from '../../CSS/_variables.module.scss';
import EmptyBoxIcon from '../../Images/svg/EmptyBoxIcon';
import {preventDefaultDrag} from '../../Utils/utils';
import Spinner from '../../SmallLayoutComponents/Spinner';

class OnDemandVideos extends React.Component {
    componentDidMount() {
        const {eventId} = this.props;
        this.props.onGetOnDemandVideos(eventId);
    }
    render() {
        const {onDemandVideos, translation} = this.props;

        if (onDemandVideos.loading) {
            return (
                <div onDragStart={preventDefaultDrag} className="wall-container loading-on-demand">
                    <Spinner/>
                </div>
            );
        }

        return (
            <div onDragStart={preventDefaultDrag} className="wall-container">
                <h4>{translation?.onDemandVideos?.sectionTitle}</h4>
                <ColoredScrollbars>
                    <div onDragStart={preventDefaultDrag} className="on-demand-videos-container">
                        {onDemandVideos.auditoriums.length === 0 ? (
                            <div onDragStart={preventDefaultDrag} className="no-videos">
                                <p>{translation?.onDemandVideos?.noOnDemandVideos}</p>
                                <EmptyBoxIcon
                                    fillPrimary={colors.primary}
                                    fillPrimaryDark={colors.primaryDark}
                                    fillGreyLight={colors.lightGrey}
                                    fillGrey={colors.grey}
                                />
                            </div>
                        ) : (
                            <div onDragStart={preventDefaultDrag} className="on-demand-videos-list">
                                {onDemandVideos.auditoriums.map((auditorium) => {
                                    return <OnDemandVideosAuditorium auditorium={auditorium} key={auditorium._id} />;
                                })}
                            </div>
                        )}
                    </div>
                </ColoredScrollbars>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        onDemandVideos: state.onDemandVideos,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onGetOnDemandVideos: (eventId) => dispatch(actions.getOnDemandVideos(eventId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnDemandVideos));
