import React from 'react';
import {useSelector} from 'react-redux';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import {preventDefaultDrag} from 'Utils/utils';
import Button from "@material-ui/core/Button";

const MeetingButton = ({disabled, clickAction}) => {
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    if (!disabled) {
        return (
            // <button className="new-meeting-button" onClick={clickAction}>
            //     <AddIcon fontSize={'inherit'} />
            //     <span>{translation?.meetings?.meeting || defaultTranslation?.meetings.meeting}</span>
            // </button>
            <Button
                onClick={clickAction}
                variant={"contained"}
                color={"primary"}
                startIcon={<AddIcon />}
                size={"small"}
            >
                {translation?.meetings?.meeting || defaultTranslation?.meetings.meeting}
            </Button>
        );
    }
    return (
        <div onDragStart={preventDefaultDrag} className="meeting-button-wrapper">
            <Tooltip
                arrow
                title={
                    translation?.meetings?.createDialog.buttonDisabled ||
                    defaultTranslation?.meetings.createDialog.buttonDisabled
                }
            >
                <div className="new-meeting-button disabled">
                    <Button
                        disabled
                        variant={"contained"}
                        color={"primary"}
                        startIcon={<AddIcon />}
                        size={"small"}
                    >
                        {translation?.meetings?.meeting || defaultTranslation?.meetings.meeting}
                    </Button>
                </div>
            </Tooltip>
        </div>
    );
};

export default MeetingButton;