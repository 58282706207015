import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import * as actions from 'store/actions/index';
import Switch from '@material-ui/core/Switch';
import axios from 'store/axios-instance';
import Confirm from 'Dialogs/Confirm';
import {preventDefaultDrag, fireClickEvent} from 'Utils/utils';
import isEqual from 'lodash/isEqual';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {CircularProgress, FormControlLabel} from '@material-ui/core';
import {ReactComponent as DragDrop} from './dnd-icon.svg';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

class PredefinedFields extends React.Component {
    state = {
        fields: [],
        buttonDisabled: true,
        openConfirmUnsavedChanges: false,
        navigationElement: null,
        loading: false,
    };

    wrapperRef = React.createRef();
    handleClickOutside = this.handleClickOutside.bind(this);

    componentDidMount() {
        this.setFields();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (
            !isEqual(
                prevProps.registrationFields?.data?.participantPredefinedRegistrationFields,
                this.props.registrationFields?.data?.participantPredefinedRegistrationFields
            )
        ) {
            this.setFields();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
        this.props.setIsAnyConfirmUnsavedChangesOpen(false);
    };

    handleEnterKey = (tab, index, optionValue) => (e) => {
        if (e.key === 'Enter') {
            if (tab === 'enable') return this.handleEnablePredefinedFieldOnEnter(index, optionValue);
            if (tab === 'require') return this.handleRequirePredefinedFieldOnEnter(index, optionValue);
        }
    };

    getNewNavigationElement = (e) => {
        const {isAnyConfirmUnsavedChangesOpen} = this.props;
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) =>
            pathElem.getAttribute?.('data-is-navigation')
        );

        if (openConfirmUnsavedChanges || isAnyConfirmUnsavedChangesOpen) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (!this.state.buttonDisabled) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
                this.props.setIsAnyConfirmUnsavedChangesOpen(true);
            }
        }
    }

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({
            fields: updatedFields,
            buttonDisabled: false,
        });
    };

    handleEnablePredefinedField = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].isEnabled = e.target.checked;
        this.setState({
            fields: updatedFields,
            buttonDisabled: false,
        });
    };

    handleRequirePredefinedField = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].isRequired = e.target.checked;
        this.setState({
            fields: updatedFields,
            buttonDisabled: false,
        });
    };

    handleEnableFilteringField = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].isFilteringEnabled = e.target.checked;
        this.setState({
            fields: updatedFields,
            buttonDisabled: false,
        });
    };

    handleEnablePredefinedFieldOnEnter = (index, optionValue) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].isEnabled = !optionValue;
        this.setState({
            fields: updatedFields,
            buttonDisabled: false,
        });
    };

    handleRequirePredefinedFieldOnEnter = (index, optionValue) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].isRequired = !optionValue;
        this.setState({
            fields: updatedFields,
            buttonDisabled: false,
        });
    };

    saveEventPredefinedFields = () => {
        this.setState({loading: true});
        const {eventId} = this.props;
        const {navigationElement} = this.state;

        let data = {};
        this.state.fields.forEach((field) => {
            data[field.name] = {
                label: field.value,
                isEnabled: field.isEnabled,
                isRequired: field.isRequired,
                isFilteringEnabled: field.isFilteringEnabled,
                order: field.order,
            };
        });
        axios({method: 'put', url: `/event/v2/${eventId}/participant-predefined-fields`, data: data})
            .then((response) => {
                let eventRegistrationFields = response.data.data.eventRegistrationFields;
                this.props.getEventRegistrationFieldsSuccess(eventRegistrationFields);
                this.setState({
                    buttonDisabled: true,
                    openConfirmUnsavedChanges: false,
                    loading: false,
                });
                this.props.setIsAnyConfirmUnsavedChangesOpen(false);
                this.props.handleOpenSuccessSnackbar();

                if (navigationElement) {
                    fireClickEvent(navigationElement);
                }
            })
            .catch(() => {
                this.props.handleOpenErrorSnackbar();
                this.setState({loading: false});
            });
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.setFields();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the localSpeakers look a bit nicer
        userSelect: 'none',
        margin: `0 0 25px 0`,
        ...draggableStyle,
    });

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const localFieldsReordered = this.reorder(this.state.fields, result.source.index, result.destination.index);

        this.setState({
            fields: localFieldsReordered.map((item, index) => {
                return {...item, order: index};
            }),
            buttonDisabled: false,
        });
    };

    setFields = () => {
        const {registrationFields} = this.props;
        const predefinedFieldsObject = registrationFields?.data?.participantPredefinedRegistrationFields;

        if (!predefinedFieldsObject) {
            return;
        }

        const predefinedFields = Object.keys(predefinedFieldsObject)?.map((key) => {
            const fieldValues = predefinedFieldsObject[key];
            const label = key.charAt(0).toUpperCase() + key.slice(1);

            return {
                order: fieldValues.order,
                name: key,
                label: label,
                value: fieldValues.label,
                isEnabled: fieldValues.isEnabled,
                isRequired: fieldValues.isRequired,
                isFilteringEnabled: fieldValues.isFilteringEnabled,
                multiline: 0,
                validators: [],
                errorMessages: [],
            };
        });

        predefinedFields.sort((a, b) => a.order - b.order);

        this.setState({
            fields: predefinedFields,
            buttonDisabled: true,
        });
    };

    render() {
        return (
            <>
                <h4 className="advanced-options-title">SECOND STEP</h4>
                <div
                    onDragStart={preventDefaultDrag}
                    ref={this.wrapperRef}
                    className="advanced-options-container predefined-fields-container"
                >
                    <p onDragStart={preventDefaultDrag} className="inner-options-title">
                        PREDEFINED FIELDS
                    </p>
                    {this.state.loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                            <CircularProgress color="primary" />
                        </div>
                    ) : (
                        <ValidatorForm onSubmit={this.saveEventPredefinedFields}>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {this.state.fields.map((field, index) => (
                                                <Draggable key={field.name} draggableId={field.name} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            style={this.getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="options-container"
                                                                key={field.name}
                                                            >
                                                                <span
                                                                    className="dragDrop-element"
                                                                    {...{
                                                                        ...provided.dragHandleProps,
                                                                        tabIndex: -1,
                                                                    }}
                                                                >
                                                                    <DragDrop className={'mr-20'} />
                                                                </span>

                                                                <div
                                                                    onDragStart={preventDefaultDrag}
                                                                    className="single-option-container input-bg-white"
                                                                >
                                                                    <TextValidator
                                                                        className="setting-input"
                                                                        label={field.label}
                                                                        type="text"
                                                                        name={field.name}
                                                                        index={index}
                                                                        value={field.value}
                                                                        onChange={this.handleChange(index)}
                                                                        validators={field.validators}
                                                                        errorMessages={field.errorMessages}
                                                                        multiline={field.multiline > 0}
                                                                        rows={field.multiline}
                                                                        fullWidth={true}
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        disabled={false}
                                                                    />
                                                                </div>
                                                                <div
                                                                    onDragStart={preventDefaultDrag}
                                                                    className="input-description d-flex flex-wrap"
                                                                >
                                                                    <div
                                                                        tabIndex={'0'}
                                                                        onKeyDown={this.handleEnterKey(
                                                                            'enable',
                                                                            index,
                                                                            field.isEnabled
                                                                        )}
                                                                    >
                                                                        <FormControlLabel
                                                                            disabled={false}
                                                                            label={
                                                                                field.isEnabled ? 'Enabled' : 'Disabled'
                                                                            }
                                                                            classes={{label: 'switch-label'}}
                                                                            control={
                                                                                <Switch
                                                                                    tabIndex="-1"
                                                                                    color="secondary"
                                                                                    onChange={this.handleEnablePredefinedField(
                                                                                        index
                                                                                    )}
                                                                                    checked={field.isEnabled}
                                                                                />
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        //  Added !field.isEnabled property to queries ,
                                                                        //  if the property is not enabled,
                                                                        //  then optional/mandatory won't work
                                                                        tabIndex={'0'}
                                                                        onKeyDown={
                                                                            !field.isEnabled
                                                                                ? preventDefaultDrag
                                                                                : this.handleEnterKey(
                                                                                      'require',
                                                                                      index,
                                                                                      field.isEnabled
                                                                                  )
                                                                        }
                                                                    >
                                                                        <FormControlLabel
                                                                            disabled={!field.isEnabled}
                                                                            label={
                                                                                field.isRequired
                                                                                    ? 'Mandatory'
                                                                                    : 'Optional'
                                                                            }
                                                                            classes={{label: 'switch-label'}}
                                                                            control={
                                                                                <Switch
                                                                                    tabIndex="-1"
                                                                                    color="secondary"
                                                                                    onChange={this.handleRequirePredefinedField(
                                                                                        index
                                                                                    )}
                                                                                    checked={field.isRequired}
                                                                                />
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {field.name === 'country' && (
                                                                        <div
                                                                            //  Added !field.isEnabled property to queries ,
                                                                            //  if the property is not enabled,
                                                                            //  then optional/mandatory won't work
                                                                            tabIndex={'0'}
                                                                            onKeyDown={
                                                                                !field.isEnabled
                                                                                    ? preventDefaultDrag
                                                                                    : this.handleEnterKey(
                                                                                          'require',
                                                                                          index,
                                                                                          field.isFilteringEnabled
                                                                                      )
                                                                            }
                                                                        >
                                                                            <FormControlLabel
                                                                                disabled={!field.isEnabled}
                                                                                label="Filtering"
                                                                                classes={{label: 'switch-label'}}
                                                                                control={
                                                                                    <Switch
                                                                                        tabIndex="-1"
                                                                                        color="secondary"
                                                                                        onChange={this.handleEnableFilteringField(
                                                                                            index
                                                                                        )}
                                                                                        checked={
                                                                                            field.isFilteringEnabled
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            <div onDragStart={preventDefaultDrag} className="action-container">
                                <Button
                                    type="submit"
                                    disabled={this.state.buttonDisabled}
                                    startIcon={<SaveOutlinedIcon />}
                                    variant="contained"
                                    color={'secondary'}
                                    disableElevation
                                >
                                    Save
                                </Button>
                            </div>
                        </ValidatorForm>
                    )}
                </div>
                {this.state.openConfirmUnsavedChanges && (
                    <Confirm
                        open={this.state.openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveEventPredefinedFields}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        registrationFields: state.event.registrationFields,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventRegistrationFieldsSuccess: (registrationFields) =>
            dispatch(actions.getEventRegistrationFieldsSuccess(registrationFields)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PredefinedFields);
