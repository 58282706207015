import React from 'react';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {useSelector} from "react-redux";
import Button from '@material-ui/core/Button';
import {Tooltip} from '@material-ui/core';


const AddToCalendarButton = ({className, showAddToExternalCalendarDialog}) => {

    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) =>  state.languages.translations['en']);


    return (
        <div className={`${className}`}>
            <Tooltip arrow
                     title={'Add the whole event to calendar'}
                     placement="bottom-center"
            >
            <Button type={'button'}
                    onClick={showAddToExternalCalendarDialog}
                    startIcon={<CalendarTodayIcon />}
            >
                {translation?.programDropdown.addEventButton || defaultTranslation?.programDropdown.addEventButton}
            </Button>
            </Tooltip>
        </div>
    );
};

export default AddToCalendarButton;