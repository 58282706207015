import React from 'react';
import SinglePreviousEventDetails from './LayoutComponents/SinglePreviousEventDetails';
import AddEditHomepagePreviousEvent from '../../../Dialogs/Organizer/Branding/AddEditHomepagePreviousEvent';
import ConfirmDelete from '../../../Dialogs/ConfirmDelete';
import {connect} from 'react-redux';
import axios from '../../../store/axios-instance';
import * as actions from '../../../store/actions';
import AddIcon from '@material-ui/icons/Add';
import {preventDefaultDrag} from '../../../Utils/utils';
import './HomepageBannersStyles.scss';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Button from '@material-ui/core/Button';

class HomepagePreviousEvents extends React.Component {
    state = {
        openAddEditPreviousEventDialog: false,
        showConfirmDeleteDialog: false,
        editedPreviousEventId: null,
    };

    handleOpenAddPreviousEventDialog = () => this.setState({openAddEditPreviousEventDialog: true});
    handleCloseDialog = () => this.setState({editedPreviousEventId: null, openAddEditPreviousEventDialog: false});
    handleOpenEditPreviousEventDialog = (previousEventId) => () =>
        this.setState({editedPreviousEventId: previousEventId, openAddEditPreviousEventDialog: true});

    openConfirmDeleteDialog = (id) => () =>
        this.setState({
            editedPreviousEventId: id,
            showConfirmDeleteDialog: true,
        });

    closeConfirmDeleteDialog = () =>
        this.setState({
            editedPreviousEventId: null,
            showConfirmDeleteDialog: false,
        });

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return this.handleOpenAddPreviousEventDialog();
    };

    handleAddPreviousEvent = (previousEventData) => {
        const {eventId} = this.props;
        axios({method: 'post', url: `/event/${eventId}/branding/add-past-event`, data: previousEventData})
            .then((response) => {
                this.handleCloseDialog();
                const brandingData = response.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    handleEditPreviousEvent = (previousEventData) => {
        const {eventId} = this.props;
        axios({
            method: 'post',
            url: `/event/v2/${eventId}/branding/edit-past-event/${this.state.editedPreviousEventId}`,
            data: previousEventData,
        })
            .then((response) => {
                this.handleCloseDialog();
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    handleDeletePreviousEvent = () => {
        const {eventId} = this.props;
        axios({
            method: 'delete',
            url: `/event/v2/${eventId}/branding/remove-past-event/${this.state.editedPreviousEventId}`,
        })
            .then((response) => {
                this.closeConfirmDeleteDialog();
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    render() {
        const {branding} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="branding-homepage-partners">
                <h4 className="advanced-options-title">
                    PREVIOUS EVENTS
                    <Button
                        onClick={this.handleOpenAddPreviousEventDialog}
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD EVENT
                    </Button>
                </h4>
                <div onDragStart={preventDefaultDrag} className="list-wrapper">
                    {branding?.data?.homepagePreviousEvents.length > 0 ? (
                        <div onDragStart={preventDefaultDrag} className="previous-events-container">
                            {branding?.data?.homepagePreviousEvents.map((previousEvent, index) => {
                                return (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        key={index}
                                        className="advanced-options-container"
                                    >
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="single-previous-event-container"
                                            key={previousEvent._id}
                                        >
                                            <SinglePreviousEventDetails
                                                previousEvent={previousEvent}
                                                handleOpenEditPreviousEventDialog={this.handleOpenEditPreviousEventDialog(
                                                    previousEvent._id
                                                )}
                                                handleDeletePreviousEvent={this.openConfirmDeleteDialog(
                                                    previousEvent._id
                                                )}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div onDragStart={preventDefaultDrag} className="advanced-options-container banner-helper">
                            <p onDragStart={preventDefaultDrag} className="list-empty-text">
                                You currently don't have any previous events added
                            </p>
                        </div>
                    )}
                </div>
                <div onDragStart={preventDefaultDrag} className="actions-container">
                    <Button
                        onClick={this.handleOpenAddPreviousEventDialog}
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD EVENT
                    </Button>
                </div>
                {this.state.openAddEditPreviousEventDialog && (
                    <AddEditHomepagePreviousEvent
                        open={this.state.openAddEditPreviousEventDialog}
                        previousEventId={this.state.editedPreviousEventId}
                        previousEvents={branding?.data?.homepagePreviousEvents}
                        handleCloseDialog={this.handleCloseDialog}
                        addNewPreviousEvent={this.handleAddPreviousEvent}
                        editPreviousEvent={this.handleEditPreviousEvent}
                    />
                )}
                {this.state.showConfirmDeleteDialog && (
                    <ConfirmDelete
                        open={this.state.showConfirmDeleteDialog}
                        closeConfirmDeleteDialog={this.closeConfirmDeleteDialog}
                        dialogTitle={'Delete Previous Event'}
                        dialogDescription={'Are you sure you want to delete this previous event?'}
                        dialogConfirmButtonLabel={'Delete'}
                        handleConfirmDelete={this.handleDeletePreviousEvent}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        branding: state.event.branding,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepagePreviousEvents);
