import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import Confirm from '../../../Dialogs/Confirm';
import {preventDefaultDrag, fireClickEvent, hasOnlyEmptySpaces} from '../../../Utils/utils';
import './BrandingDisableLoginInfoStyles.scss';
import isEqual from "lodash/isEqual";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { minCharactersAndOptional } from './../../../Utils/utils';
import Spinner from "../../../SmallLayoutComponents/Spinner";

class BrandingDisableLoginInfo extends React.Component {
    wrapperRefFirst = React.createRef();
    wrapperRefSecond = React.createRef();
    wrapperRefThird = React.createRef();
    handleClickOutsideFirst = this.handleClickOutsideFirst.bind(this);
    handleClickOutsideSecond = this.handleClickOutsideSecond.bind(this);
    handleClickOutsideThird = this.handleClickOutsideThird.bind(this);

    state = {
        participantsFields: [
            {
                name: 'participantsClosedEventTitle',
                description: 'This message appears when "Participants can login" is disabled',
                adornment: '',
                value: '',
                initialValue: '',
                label: 'Title',
                multiline: 0,
                maxCharacters: 30,
                validators: ['required', 'minStringLength: 2', 'maxStringLength: 30', 'onlyEmptySpaces'],
                errorMessages: [
                    'Field is required',
                    'The title is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (30 characters)',
                    'Field is required',
                ],
            },
            {
                name: 'participantsClosedEventInfo',
                description: '',
                adornment: '',
                value: '',
                initialValue: '',
                multiline: 6,
                maxCharacters: 200,
                label: 'Additional info text',
                validators: ['minLength2Optional', 'maxStringLength: 200', 'onlyEmptySpaces'],
                errorMessages: [
                    'The description is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (200 characters)',
                    'Field is required',
                ],
            },
        ],
        exhibitorsFields: [
            {
                name: 'exhibitorsClosedEventTitle',
                description: 'This message appears when the "Exhibitors can login" is disabled',
                adornment: '',
                value: '',
                initialValue: '',
                label: 'Title',
                multiline: 0,
                maxCharacters: 30,
                validators: ['required', 'minStringLength: 2', 'maxStringLength: 30', 'onlyEmptySpaces'],
                errorMessages: [
                    'Field is required',
                    'The title is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (30 characters)',
                    'Field is required',
                ],
            },
            {
                name: 'exhibitorsClosedEventInfo',
                description: '',
                adornment: '',
                value: '',
                initialValue: '',
                multiline: 6,
                maxCharacters: 200,
                label: 'Additional info text',
                validators: ['minLength2Optional', 'maxStringLength: 200', 'onlyEmptySpaces'],
                errorMessages: [
                    'The description is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (200 characters)',
                    'Field is required',
                ],
            },
        ],
        postersFields: [
            {
                name: 'scholarsClosedEventTitle',
                description: 'This message appears when "Poster / Study can login" is disabled',
                adornment: '',
                value: '',
                initialValue: '',
                label: 'Title',
                multiline: 0,
                maxCharacters: 30,
                validators: ['required', 'minStringLength: 2', 'maxStringLength: 30', 'onlyEmptySpaces'],
                errorMessages: [
                    'Field is required',
                    'The title is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (30 characters)',
                    'Field is required',
                ],
            },
            {
                name: 'scholarsClosedEventInfo',
                description: '',
                adornment: '',
                value: '',
                initialValue: '',
                multiline: 6,
                maxCharacters: 200,
                label: 'Additional info text',
                validators: ['minLength2Optional', 'maxStringLength: 200', 'onlyEmptySpaces'],
                errorMessages: [
                    'The description is too short. Minimum length:  2',
                    'You have reached the maximum limit of characters allowed (200 characters)',
                    'Field is required',
                ],
            },
        ],
        buttonDisabledParticipants: true,
        buttonDisabledExhibitors: true,
        buttonDisabledPosters: true,
        navigationElement: null,

        openConfirmUnsavedChangesFirst: false,
        openConfirmUnsavedChangesSecond: false,
        openConfirmUnsavedChangesThird: false,
    };

    componentDidMount() {
        const {event} = this.props;
        if (event) {
            this.setEventBranding();
        }
        document.addEventListener('mousedown', this.handleClickOutsideFirst);
        if (event?.companyExhibitorsType) {
            document.addEventListener('mousedown', this.handleClickOutsideSecond);
        }
        if (event?.scholarExhibitorsType) {
            document.addEventListener('mousedown', this.handleClickOutsideThird);
        }
        ValidatorForm.addValidationRule('onlyEmptySpaces', (value) => hasOnlyEmptySpaces(value));
        ValidatorForm.addValidationRule('minLength2Optional', (value) => minCharactersAndOptional(value, 2));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.event, this.props.event)) {
            this.setEventBranding();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideFirst);
        document.removeEventListener('mousedown', this.handleClickOutsideSecond);
        document.removeEventListener('mousedown', this.handleClickOutsideThird);
        ValidatorForm.removeValidationRule('onlyEmptySpaces');
        ValidatorForm.removeValidationRule('minLength2Optional');
    }

    handleChange = (fields, index, buttonDisabled, ref) => (e) => {
        let updatedFields = [...fields];
        updatedFields[index].value = e.target.value;
        this.setState({[fields]: updatedFields}, () => {
            this.refs[ref].isFormValid().then((isValid) => {
                const fieldValueAsInitial = updatedFields[index].value.trim() === updatedFields[index].initialValue.trim();
                this.setState({[buttonDisabled]: !isValid || fieldValueAsInitial});
            });
        });
    };

    saveClosedEventInfo = (fields) => () => {
        const {navigationElement} = this.state;

        let data = {};
        this.state[fields].forEach((field) => {
            data[field.name] = field.value;
        });
        this.props.saveClosedEventInfo(data);
        this.setState({
            openConfirmUnsavedChangesFirst: false,
            openConfirmUnsavedChangesSecond: false,
            openConfirmUnsavedChangesThird: false,
        });

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.setState({
            openConfirmUnsavedChangesFirst: false,
            openConfirmUnsavedChangesSecond: false,
            openConfirmUnsavedChangesThird: false,
        });
        this.setEventBranding();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    setEventBranding = () => {
        const brandingData = this.props.event.brandingData;
        this.setState({
            participantsFields: [
                {
                    ...this.state.participantsFields[0],
                    value: brandingData.participantsClosedEventTitle,
                    initialValue: brandingData.participantsClosedEventTitle,
                },
                {
                    ...this.state.participantsFields[1],
                    value: brandingData.participantsClosedEventInfo,
                    initialValue: brandingData.participantsClosedEventInfo,
                },
            ],
            exhibitorsFields: [
                {
                    ...this.state.exhibitorsFields[0],
                    value: brandingData.exhibitorsClosedEventTitle,
                    initialValue: brandingData.exhibitorsClosedEventTitle,
                },
                {
                    ...this.state.exhibitorsFields[1],
                    value: brandingData.exhibitorsClosedEventInfo,
                    initialValue: brandingData.exhibitorsClosedEventInfo,
                },
            ],
            postersFields: [
                {
                    ...this.state.postersFields[0],
                    value: brandingData.scholarsClosedEventTitle,
                    initialValue: brandingData.scholarsClosedEventTitle,
                },
                {
                    ...this.state.postersFields[1],
                    value: brandingData.scholarsClosedEventInfo,
                    initialValue: brandingData.scholarsClosedEventInfo,
                },
            ],
            buttonDisabledParticipants: true,
            buttonDisabledExhibitors: true,
            buttonDisabledPosters: true,
        });
    };

    getNewNavigationElement = (e) => {
        const {
            navigationElement,
            openConfirmUnsavedChangesFirst,
            openConfirmUnsavedChangesSecond,
            openConfirmUnsavedChangesThird,
        } = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChangesFirst || openConfirmUnsavedChangesSecond || openConfirmUnsavedChangesThird) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutsideFirst(e) {
        if (this.wrapperRefFirst && !this.wrapperRefFirst.current.contains(e.target)) {
            if (!this.state.buttonDisabledParticipants) {
                this.setState({
                    openConfirmUnsavedChangesFirst: true,
                    navigationElement: this.getNewNavigationElement(e),
                });
            }
        }
    }

    handleClickOutsideSecond(e) {
        if (this.wrapperRefSecond && !this.wrapperRefSecond.current.contains(e.target)) {
            if (!this.state.buttonDisabledExhibitors) {
                this.setState({
                    openConfirmUnsavedChangesSecond: true,
                    navigationElement: this.getNewNavigationElement(e),
                });
            }
        }
    }

    handleClickOutsideThird(e) {
        if (this.wrapperRefThird && !this.wrapperRefThird.current.contains(e.target)) {
            if (!this.state.buttonDisabledPosters) {
                this.setState({
                    openConfirmUnsavedChangesThird: true,
                    navigationElement: this.getNewNavigationElement(e),
                });
            }
        }
    }

    closeClickOutsideFirst = () => {
        this.setState({openConfirmUnsavedChangesFirst: false});
    };

    closeClickOutsideSecond = () => {
        this.setState({openConfirmUnsavedChangesSecond: false});
    };

    closeClickOutsideThird = () => {
        this.setState({openConfirmUnsavedChangesThird: false});
    };

    render() {
        let {
            participantsFields,
            exhibitorsFields,
            postersFields,
            buttonDisabledParticipants,
            buttonDisabledExhibitors,
            buttonDisabledPosters,
            openConfirmUnsavedChangesFirst,
            openConfirmUnsavedChangesSecond,
            openConfirmUnsavedChangesThird,
        } = this.state;
        const {event, loadingSaveEventBranding} = this.props;

        if(loadingSaveEventBranding) {
            return <Spinner />
        }

        return (
            <>
                <div onDragStart={preventDefaultDrag} className="branding-disable-login">
                    <h4 className="advanced-options-title">DISABLED LOGIN INFO</h4>

                    <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                        <p onDragStart={preventDefaultDrag} className="inner-options-title padding-10">
                            For participants
                        </p>
                        <div onDragStart={preventDefaultDrag} ref={this.wrapperRefFirst}>
                            <ValidatorForm ref="formFirst" onSubmit={this.saveClosedEventInfo(participantsFields)}>
                                {participantsFields.map((field, index) => {
                                    return (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="options-container"
                                            key={field.name}
                                        >
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className={`single-option-container ${field.multiline ? 'multiline-input ' : ''}`}
                                            >
                                                <TextValidator
                                                    label={field.label}
                                                    type="text"
                                                    name={field.name}
                                                    index={index}
                                                    color={'primary'}
                                                    value={field.value}
                                                    onChange={this.handleChange(
                                                        participantsFields,
                                                        index,
                                                        'buttonDisabledParticipants',
                                                        'formFirst'
                                                    )}
                                                    validators={field.validators}
                                                    errorMessages={field.errorMessages}
                                                    multiline={field.multiline > 0}
                                                    rows={8}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: !field.multiline && <span className='char-count'>
                                                                  {field.value?.length}/{field.maxCharacters}
                                                            </span>,
                                                    }}
                                                />
                                                {!!field.multiline && <span className={'char-count'}>
                                                    {field.value?.length}/{field.maxCharacters}
                                                </span>}
                                            </div>
                                            <div onDragStart={preventDefaultDrag} className="input-description">
                                                <p>{field.description}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div onDragStart={preventDefaultDrag} className="action-container">
                                    <Button 
                                        type="submit"
                                        disabled={buttonDisabledParticipants}
                                        startIcon={<SaveOutlinedIcon />}
                                        variant="contained"
                                        color={'secondary'}
                                        disableElevation
                                    >
                                        SAVE
                                    </Button>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>

                    {event.companyExhibitorsType && (
                        <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                            <p onDragStart={preventDefaultDrag} className="inner-options-title padding-10">
                                For exhibitors
                            </p>
                            <div onDragStart={preventDefaultDrag} ref={this.wrapperRefSecond}>
                                <ValidatorForm ref="formSecond" onSubmit={this.saveClosedEventInfo(exhibitorsFields)}>
                                    {exhibitorsFields.map((field, index) => {
                                        return (
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="options-container"
                                                key={field.name}
                                            >
                                                <div
                                                    onDragStart={preventDefaultDrag}
                                                    className={`single-option-container ${field.multiline ? 'multiline-input ' : ''}`}
                                                >
                                                    <TextValidator
                                                        label={field.label}
                                                        type="text"
                                                        name={field.name}
                                                        index={index}
                                                        value={field.value}
                                                        onChange={this.handleChange(
                                                            exhibitorsFields,
                                                            index,
                                                            'buttonDisabledExhibitors',
                                                            'formSecond'
                                                        )}
                                                        validators={field.validators}
                                                        errorMessages={field.errorMessages}
                                                        multiline={field.multiline > 0}
                                                        rows={8}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: !field.multiline && <span className='char-count'>
                                                                  {field.value?.length}/{field.maxCharacters}
                                                            </span>,
                                                        }}
                                                    />
                                                    {!!field.multiline && <span className={'char-count'}>
                                                        {field.value?.length}/{field.maxCharacters}
                                                    </span>}
                                                </div>
                                                <div onDragStart={preventDefaultDrag} className="input-description">
                                                    <p>{field.description}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div onDragStart={preventDefaultDrag} className="action-container">
                                        <Button 
                                            type="submit"
                                            disabled={buttonDisabledExhibitors}
                                            startIcon={<SaveOutlinedIcon />}
                                            variant="contained"
                                            color={'secondary'}
                                            disableElevation
                                        >
                                            SAVE
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </div>
                    )}

                    {event.scholarExhibitorsType && (
                        <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                            <p onDragStart={preventDefaultDrag} className="inner-options-title padding-10">
                                For poster / study
                            </p>
                            <div onDragStart={preventDefaultDrag} ref={this.wrapperRefThird}>
                                <ValidatorForm ref="formThird" onSubmit={this.saveClosedEventInfo(postersFields)}>
                                    {postersFields.map((field, index) => {
                                        return (
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="options-container"
                                                key={field.name}
                                            >
                                                <div
                                                    onDragStart={preventDefaultDrag}
                                                    className={`single-option-container ${!!field.multiline ? 'multiline-input ' : ''}`}
                                                >
                                                    <TextValidator
                                                        label={field.label}
                                                        type="text"
                                                        name={field.name}
                                                        index={index}
                                                        color={'primary'}
                                                        value={field.value}
                                                        onChange={this.handleChange(
                                                            postersFields,
                                                            index,
                                                            'buttonDisabledPosters',
                                                            'formThird'
                                                        )}
                                                        validators={field.validators}
                                                        errorMessages={field.errorMessages}
                                                        multiline={field.multiline > 0}
                                                        rows={8}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: !field.multiline && <span className='char-count'>
                                                                  {field.value?.length}/{field.maxCharacters}
                                                            </span>,
                                                        }}
                                                    />
                                                    {!!field.multiline && <span className={'char-count'}>
                                                        {field.value?.length}/{field.maxCharacters}
                                                    </span>}
                                                </div>
                                                <div onDragStart={preventDefaultDrag} className="input-description">
                                                    <p>{field.description}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div onDragStart={preventDefaultDrag} className="action-container ">
                                        <Button 
                                            type="submit"
                                            variant={"contained"}
                                            disabled={buttonDisabledPosters}
                                            startIcon={<SaveOutlinedIcon />}
                                            disableElevation
                                            color={"secondary"}
                                        >
                                            SAVE
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </div>
                    )}
                </div>
                {openConfirmUnsavedChangesFirst && (
                    <Confirm
                        open={openConfirmUnsavedChangesFirst}
                        closeConfirm={this.closeClickOutsideFirst}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveClosedEventInfo(participantsFields)}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
                {openConfirmUnsavedChangesSecond && (
                    <Confirm
                        open={openConfirmUnsavedChangesSecond}
                        closeConfirm={this.closeClickOutsideSecond}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveClosedEventInfo(exhibitorsFields)}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
                {openConfirmUnsavedChangesThird && (
                    <Confirm
                        open={openConfirmUnsavedChangesThird}
                        closeConfirm={this.closeClickOutsideThird}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveClosedEventInfo(postersFields)}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEvent: (eventId) => dispatch(actions.getEvent(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandingDisableLoginInfo);
