import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {Button, CircularProgress, Divider} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MatchingParticipantQuestion from '../../Components/EventManagement/Matching/MatchingParticipantQuestion';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as ArrowLeftIcon} from '../../Images/svg/arrow_left.svg';
import {preventDefaultDrag} from '../../Utils/utils';
import Confirm from '../Confirm';
import {isIOS} from 'react-device-detect';
import cloneDeep from 'lodash/cloneDeep';


class EditSmartMatchingAnswersMobile extends React.Component {
    state = {
        answers: [],
        answersClone: [],
        matchingDetailsPrecompleted: false,
        submitMatchingAnswersDisabled: true,
        submitMatchingAnswersRequired: false,
        openConfirmUnsavedChanges: false,
        confirmRemoveMatchingData: false,
    };

    componentDidMount() {
        this.getMatchingData();
        const myAccount = document.getElementById('my-account-mobile')
        myAccount.scrollTo(0,0)
    }

    componentWillUnmount() {
        document.body.classList.remove('sticky-menu');
    }

    componentDidUpdate(prevProps) {
        const {event} = this.props;
        if (prevProps.event._id !== event._id) {
            this.getMatchingData();
        }
    }

    closeClickOutside = () => this.setState({openConfirmUnsavedChanges: false});
    openConfirmRemoveMatchingData = () => this.setState({confirmRemoveMatchingData: true});
    closeConfirmRemoveMatchingData = () => this.setState({confirmRemoveMatchingData: false});

    handleCloseDialog = () => {
        const {submitMatchingAnswersDisabled, submitMatchingAnswersRequired} = this.state;
        if (!submitMatchingAnswersDisabled && !submitMatchingAnswersRequired) {
            this.setState({openConfirmUnsavedChanges: true});
        } else {
            this.props.closeDialog();
        }
    };

    handleClickOutside(e) {
        const {submitMatchingAnswersDisabled, submitMatchingAnswersRequired} = this.state;
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
            if (!submitMatchingAnswersDisabled && !submitMatchingAnswersRequired) {
                this.setState({openConfirmUnsavedChanges: true});
            }
        }
    }

    getMatchingData = () => {
        const {eventId, onGetQuestionnaires} = this.props;
        onGetQuestionnaires(eventId).then(() => this.setEmptyQuestions());
    };

    getQuestionsIdsFromMatchingForm = () => {
        const {eventMatching} = this.props;

        if(!eventMatching?.questions) return [];

        // create an array of questions ids
        let questionsIds = []
        eventMatching?.questions.forEach( question => questionsIds.push(question._id) )

        return questionsIds
    }

    setEmptyQuestions = () => {
        const {eventId, eventMatching, onGetUserMatchingAnswers} = this.props;
        let questionsClone = JSON.parse(JSON.stringify(eventMatching?.questions));
        // trim down and rename current eventMatching questions
        let questionAllowed = ['questionId', 'answerIds'];
        let reduceQuestions = questionsClone?.map((el) => {
            delete Object.assign(el, {questionId: el['_id']})['_id'];
            delete Object.assign(el, {answerIds: el['answers']})['answers'];
            return Object.keys(el)
                .filter((key) => questionAllowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = el[key];
                    if (key === 'answerIds') obj[key] = [];
                    return obj;
                }, {});
        });
        // clone for arrays equality comparation, set state then in setState callback
        // get urrent participant answered questions then
        // populate the answers in state
        let questionsEqualityClone = JSON.parse(JSON.stringify(reduceQuestions));

        const questionIds = this.getQuestionsIdsFromMatchingForm();
        const data = {
            questionIds
        }

        this.setState({answers: reduceQuestions, answersClone: questionsEqualityClone}, () =>
            onGetUserMatchingAnswers(eventId, eventMatching?._id, data).then(() => this.setParticipantAnswers())
        );
    };

    setParticipantAnswers = () => {
        const {user} = this.props;
        const {answers} = this.state;
        let predefinedAnswers = cloneDeep(answers);

        // userAnswersByQuestionId is an array of objects
        // each object is {questionId: questionId, answerIds:[answerId1, answerId2 etc]}
        const userAnswersByQuestionId = [];

        const userAnswersFromDb = cloneDeep(user?.matchingAnswers);

        predefinedAnswers.forEach(question => {
            const questionId = question.questionId;
            const userAnswers = userAnswersFromDb.filter(userAnswer => userAnswer.question === questionId);

            // here we want to create only an array of answerIds
            const answerIds = Array.from(Object.values(userAnswers), answer => answer.answer);
            userAnswersByQuestionId.push({
                questionId,
                answerIds
            })
        })

        // clone for arrays equality comparation then set answers and clone to state
        let equalityClone = JSON.parse(JSON.stringify(userAnswersByQuestionId));
        this.setState({answers: userAnswersByQuestionId, answersClone: equalityClone}, () =>
            this.setState({matchingDetailsPrecompleted: true})
        );
    };

    handleAddChangeAnswers = (questionId, type) => (e) => {
        const {eventMatching} = this.props;
        const {answers, answersClone} = this.state;
        let updatedAnswers = [...answers];
        // handle keep answers object in state
        let questionIndex = updatedAnswers.findIndex((answer) => answer.questionId === questionId);
        let qIdx = eventMatching?.questions?.findIndex((question) => question._id === questionId);
        let aIdx = eventMatching?.questions[qIdx]?.answers?.findIndex((answer) => answer.title === e.target.value);
        let answerId = eventMatching?.questions[qIdx]?.answers[aIdx]?._id;

        let data = {};
        data.questionId = questionId;
        data.answerIds = [];

        if (type === 'checkbox') {
            // if question answer is ALREADY in state then check if option was unchecked and remove
            // from answerIds array, else push the newly checked answer
            if (questionIndex !== -1) {
                let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex((aId) => aId === answerId);
                if (answerIndex !== -1) updatedAnswers[questionIndex].answerIds.splice(answerIndex, 1);
                if (answerIndex === -1) updatedAnswers[questionIndex].answerIds.push(answerId);
            } else {
                data.answerIds.push(answerId);
                updatedAnswers.push(data);
            }
        } else {
            // if question answer is ALREADY in state then check if option was unchecked and remove
            // from answerIds array, else push the newly checked answer
            if (questionIndex !== -1) {
                let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex((aId) => aId === answerId);
                if (answerIndex !== -1) updatedAnswers[questionIndex].answerIds = [];
                if (answerIndex === -1) updatedAnswers[questionIndex].answerIds = [answerId];
            } else {
                data.answerIds.push(answerId);
                updatedAnswers.push(data);
            }
        }

        // check required questions
        let count = 0;
        for (let i = 0; i < eventMatching?.questions?.length; i++) {
            if (eventMatching?.questions[i].isRequired) updatedAnswers[i].answerIds.length === 0 && count++;
        }

        this.setState({answers: updatedAnswers}, () =>
            this.setState({
                submitMatchingAnswersDisabled: JSON.stringify(answers) === JSON.stringify(answersClone),
                submitMatchingAnswersRequired: count > 0,
            })
        );
    };

    handleSubmitAnswers = () => {
        const {answers} = this.state;
        const {eventId, eventMatching, onSaveUpdateUserAnswers} = this.props;
        let changedEqualityClone = JSON.parse(JSON.stringify(answers));
        let data = {};
        data.answers = answers;
        onSaveUpdateUserAnswers(eventId, eventMatching?._id, data)
            .then(() =>
                this.setState({
                    submitMatchingAnswersDisabled: true,
                    submitMatchingAnswersRequired: false,
                    openConfirmUnsavedChanges: false,
                    answersClone: changedEqualityClone,
                })
            )
            .catch(() => {});
    };

    handleRemoveMatchingDataConfirm = () => {
        const {handleRemoveMatchingData} = this.props;
        this.closeConfirmRemoveMatchingData();
        handleRemoveMatchingData();
    };

    render() {
        const {opened, eventMatching, translation, defaultTranslation, loadingMatchingAnswers} = this.props;
        const {
            answers,
            matchingDetailsPrecompleted,
            submitMatchingAnswersDisabled,
            submitMatchingAnswersRequired,
            openConfirmUnsavedChanges,
            confirmRemoveMatchingData,
        } = this.state;
        return (
            <div
                className={`edit-user-information smart-matching-container small-padding-top ${opened ? 'opened' : ''}`}
            >
                <div className="smart-matching">
                    <div onDragStart={preventDefaultDrag} className="page-title">
                        <button className="cancel-button" onClick={this.handleCloseDialog}>
                            <ArrowLeftIcon width="26" height="26" fill={colors.white} />
                        </button>
                        <p>
                            {translation?.matching?.myAccountMatchingSectionTitle ||
                                defaultTranslation?.matching?.myAccountMatchingSectionTitle}
                        </p>
                    </div>
                    {loadingMatchingAnswers || !matchingDetailsPrecompleted ? (
                        <div className="matching-loading">
                            <CircularProgress color="primary" />
                        </div>
                    ) : (
                        <div onDragStart={preventDefaultDrag} className="content-wrapper">
                            <Button
                                type="button"
                                classes={{root: 'reject-participation-btn'}}
                                disableFocusRipple
                                onClick={this.openConfirmRemoveMatchingData}
                            >
                                <CloseIcon />
                                {translation?.matching?.rejectMatchingBtn ||
                                    defaultTranslation?.matching?.rejectMatchingBtn}
                            </Button>
                            <div className="matching-questions-container">
                                {matchingDetailsPrecompleted &&
                                    !!eventMatching.questions &&
                                    eventMatching?.questions?.map((question, questionIndex) => {
                                        return (
                                            <React.Fragment key={questionIndex}>
                                                <MatchingParticipantQuestion
                                                    isMyAccount
                                                    answers={answers}
                                                    question={question}
                                                    questionIndex={questionIndex}
                                                    handleChange={this.handleAddChangeAnswers}
                                                />
                                                {questionIndex !== eventMatching?.questions?.length - 1 && <Divider />}
                                            </React.Fragment>
                                        );
                                    })}
                                {submitMatchingAnswersRequired && (
                                    <span className={`matching-required-error ${isIOS ? 'isiOS' : ''}`}>
                                        {translation?.matching?.matchingRequiredError ||
                                            defaultTranslation?.matching?.matchingRequiredError}
                                    </span>
                                )}
                            </div>
                            <div onDragStart={preventDefaultDrag} className="buttons-actions">
                                <button className="cancel-button" onClick={this.handleCloseDialog}>
                                    {translation?.generalText.close}
                                </button>
                                <button
                                    className="update-button"
                                    onClick={this.handleSubmitAnswers}
                                    disabled={submitMatchingAnswersDisabled || submitMatchingAnswersRequired}
                                >
                                    {translation?.generalText.update}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={
                            translation?.generalText?.unsavedChangesTitle ||
                            defaultTranslation?.generalText?.unsavedChangesTitle
                        }
                        dialogDescription={
                            translation?.generalText?.unsavedChangesText ||
                            defaultTranslation?.generalText?.unsavedChangesText
                        }
                        dialogConfirmButtonLabel={
                            translation?.generalText?.save || defaultTranslation?.generalText?.save
                        }
                        dialogCancelButtonLabel={
                            translation?.generalText?.cancel || defaultTranslation?.generalText?.cancel
                        }
                        handleConfirm={this.handleSubmitAnswers}
                    />
                )}
                {confirmRemoveMatchingData && (
                    <Confirm
                        open={confirmRemoveMatchingData}
                        closeConfirm={this.closeConfirmRemoveMatchingData}
                        dialogTitle={
                            translation?.matching?.rejectMatchingTitle ||
                            defaultTranslation?.matching?.rejectMatchingTitle
                        }
                        dialogDescription={
                            translation?.matching?.rejectMatchingText ||
                            defaultTranslation?.matching?.rejectMatchingText
                        }
                        dialogCancelButtonLabel={
                            translation?.generalText?.cancel || defaultTranslation?.generalText?.cancel
                        }
                        dialogConfirmButtonLabel={
                            translation?.contactsDropdown?.rejectButton ||
                            defaultTranslation?.contactsDropdown?.rejectButton
                        }
                        handleConfirm={this.handleRemoveMatchingDataConfirm}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        eventId: state.event.eventId,
        eventUsers: state.eventUsers,
        eventSlug: state.event.eventSlug,
        eventRoles: state.user.eventRoles,
        eventMatching: state.organizer.eventMatching,
        loadingMatchingAnswers: state.user.loadingGetMatches,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEvent: (eventId) => dispatch(actions.getEvent(eventId)),
        onGetQuestionnaires: (eventId) => dispatch(actions.getQuestionnaires(eventId)),
        onGetUserMatchingAnswers: (eventId, questionnaireId, data) =>
            dispatch(actions.getUserMatchingAnswers(eventId, questionnaireId, data)),
        onSaveUpdateUserAnswers: (eventId, questionnaireId, data) =>
            dispatch(actions.saveUpdateUserAnswers(eventId, questionnaireId, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSmartMatchingAnswersMobile);
