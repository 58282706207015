import React from 'react';
import Wall from '../../HOC/Wall/Wall';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import AuditoriumBackgroundImage from '../../SmallLayoutComponents/AuditoriumBackgroundImage';
import Resources from '../../HOC/Wall/VideoResources';
import {
    checkIfEventHasEnded,
    checkPlatformLanguage,
    checkTimeslotHasResources,
    preventDefaultDrag,
} from '../../Utils/utils';
import AuditoriumBanners from '../Auditorium/AuditoriumBanners';
import {AuditorumPollsOutput} from '../../Components/AuditorumPollsOutput';
import BackToLive from '../../Components/Buttons/BackToLive/BackToLive.js';
import AuditoriumVideoPlayer from '../Auditorium/AuditoriumVideoPlayer';
import AuditoriumTabs from '../Auditorium/AuditoriumTabs';
import AuditoriumSideButtons from '../Auditorium/AuditoriumSideButtons/AuditoriumSideButtons';
import OnDemandVideos from '../../HOC/Wall/OnDemandVideos';
import AuditoriumNetworking from '../Auditorium/AuditoriumNetworking';
import {
    connectUserToTimeslot,
    disconnectUserFromTimeslot,
    handlePollsUpdated,
    handleAuditoriumResourcesUpdate,
} from '../../Api/socketApi';
import axios from '../../store/axios-instance';
import '../../CSS/auditorium.scss';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as BackIcon} from '../../Images/svg/back.svg';
import {ReactComponent as GreaterArrow} from '../../Images/svg/greater-arrow.svg';
import './AuditoriumArhiveSingleVideoStyles.scss';

class Auditorium extends React.Component {
    state = {
        auditoriumRoomIndex: 0,
        auditoriumRoomId: 0,
        auditoriumId: null,
        timeSlotId: null,
        timeSlot: null,
        timeSlotIndex: null,
        currentTab: 'wall',
        hasPolls: false,
        auditorium: null,
    };

    componentDidMount() {
        const {isLargeScreen} = this.props;
        const {auditoriumId, timeslotId} = this.props.match.params;
        this.setState({
            auditoriumId: auditoriumId,
            timeSlotId: timeslotId,
        });
        if (!isLargeScreen) {
            this.setState({currentTab: 'video'});
        }

        handlePollsUpdated(({hasPolls}) => {
            this.setState({hasPolls});
            if (this.state.currentTab === 'polls' && !hasPolls) {
                if (!isLargeScreen) {
                    this.setState({currentTab: 'video'});
                } else {
                    this.setState({
                        currentTab: 'wall',
                    });
                }
            }
        });

        handleAuditoriumResourcesUpdate((resourcesData) => {
            if (this.state.timeSlot._id === resourcesData.programId) {
                this.updateAuditoriumResources(resourcesData, (hasResources) => {
                    if (this.state.currentTab === 'resources' && !hasResources) {
                        if (!isLargeScreen) {
                            this.setState({currentTab: 'video'});
                        } else {
                            this.setState({
                                currentTab: 'wall',
                            });
                        }
                    }
                });
            }
        });

        this.props.onSetEventProtectedMenu();
        this.loadCurrentTimeSlot(timeslotId);
        let auditorium = this.getAuditoriumBasedOnId(auditoriumId);
        if (auditorium) {
            this.setState({
                auditorium: auditorium[0],
            });
        }
        window.addEventListener('beforeunload', this.beforeUnload);
    }

    componentDidUpdate(prevProps, prevState) {
        const {timeslotId, auditoriumId} = this.props.match.params;
        if (this.state.timeSlotId !== timeslotId) {
            this.setState({
                timeSlotId: timeslotId,
                auditoriumId: auditoriumId,
            });
            this.loadCurrentTimeSlot(timeslotId);
        }
        if (prevState.timeSlotId !== this.state.timeSlotId) {
            if (prevState?.timeSlotId) {
                this.disconnectFromTimeslotSocket(prevState?.timeSlotId);
            }
            this.connectToTimeslotSocket();
        }

        if (prevProps.event.hideAuditoriumNetworking !== this.props.event.hideAuditoriumNetworking) {
            if (this.props.event.hideAuditoriumNetworking && this.state.currentTab === 'networking') {
                // if is on tablet/mobile, we handle the automatic tabChange from the AuditoriumTabs component
                if (this.props.isLargeScreen) {
                    this.handleChangeTab('wall')();
                }
            }
        }
    }

    componentWillUnmount() {
        this.beforeUnload();
        window.removeEventListener('beforeunload', this.beforeUnload);
    }

    updateAuditoriumResources = (resourcesData, callback) => {
        const {documents, links, description} = resourcesData;

        const hasResources = checkTimeslotHasResources({
            ...this.state.timeSlot,
            documents,
            links,
            description,
        });

        this.setState(
            (prevState) => ({
                hasResources,
                timeSlot: {...prevState.timeSlot, documents, links, description},
            }),
            callback(hasResources)
        );
    };

    getAuditoriumBasedOnId = (auditoriumId) => {
        return this.props.event.auditoriums.filter((auditorium) => auditorium._id === auditoriumId);
    };

    beforeUnload = () => {
        this.props.closeSidebar();
        if (this.state.timeSlot?._id) {
            this.disconnectFromTimeslotSocket(this.state.timeSlot._id);
        }
    };

    getAuditoriumTimeslotId = (id) => {
        this.setState({auditoriumRoomId: id});
    };

    connectToTimeslotSocket = () => {
        const {eventRoles} = this.props;
        // if the user isOrganizer and doesn't have the participant profile enabled, don't connect him to the socketTimeslot
        if (eventRoles.isOrganizer && !eventRoles.isParticipant) {
            return null;
        }

        const {timeSlotId, auditoriumId} = this.state;
        if (timeSlotId && auditoriumId) {
            const {user, event} = this.props;
            const userId = user._id;
            const eventId = event._id;
            connectUserToTimeslot(userId, timeSlotId, auditoriumId, eventId);
        }
    };

    disconnectFromTimeslotSocket = (timeSlotId) => {
        const {eventRoles} = this.props;
        // if the user isOrganizer and doesn't have the participant profile enabled, don't connect him to the socketTimeslot
        if (eventRoles.isOrganizer && !eventRoles.isParticipant) {
            return null;
        }

        const {user} = this.props;
        const userId = user._id;
        disconnectUserFromTimeslot(userId, timeSlotId);
    };

    loadCurrentTimeSlot = (timeslotId) => {
        if (this.props.event !== null) {
            let allAuditoriumsProgram = [];

            this.props.event.auditoriums.forEach((auditorium) => {
                auditorium.dailyProgram.forEach((dayProgram) => {
                    allAuditoriumsProgram = allAuditoriumsProgram.concat(dayProgram.program);
                });
            });

            let timeSlot = allAuditoriumsProgram.find((timeSlot) => timeSlot._id === timeslotId);

            let link = `${window.location.origin}/event/${this.props.event.slug}/auditorium-archive`

            let timeSlotIndex = allAuditoriumsProgram.findIndex((timeSlot) => timeSlot._id === timeslotId);

            this.setState(
                {
                    timeSlot: timeSlot,
                    timeSlotIndex: timeSlotIndex,
                },
                async () => {
                    // Check if current timeslot has active polls before handlePollsUpdated socket
                    this.setState({hasPolls: false});
                    if (timeSlot !== null) {
                        try {
                            const response = await axios.get(
                                `/polls/event/${this.props.event._id}/auditoriums/${this.props.match.params.auditoriumId}/program/${timeSlot._id}`
                            )
                            let hasPolls = response.data.polls.map((poll) => poll.hidden).includes(false);
                            this.setState({hasPolls});
                        } catch (err) {
                            // when the organizer deletes the timeslot while the user is on auditorium-archive page
                            return window.location.replace(link);
                        }

                    }
                }
            );

            this.setVideoWallData(timeSlot);
        }
    };

    setVideoWallData = (timeSlot) => {
        // when the organizer deletes the timeslot while the user watch the video
        let link = `${window.location.origin}/event/${this.props.event.slug}/auditorium-archive`
        if (!timeSlot) return window.location.replace(link);

        // we set up the sideMenu to have videoWall
        this.props.setHasVideoWall(true);
        // we set up the current time slot video Id
        const activeVideoWallId = timeSlot.videoWall;

        let highlightUsers = [];
        timeSlot.speakers.forEach((speaker) => {
            highlightUsers.push(speaker.user._id);
        });

        // we set up the current wall Id to be the video Wall Id
        const wallData = {
            wallId: activeVideoWallId,
            highlightUsers: highlightUsers,
        };
        this.props.setTimeSlotVideoWall(wallData);
        this.props.setActiveWall(wallData);

        // we open the sideBar to the videoWall
        this.props.seeVideoWall();
    };

    handleChangeTab = (tabName) => (e) => {
        this.setState({
            currentTab: tabName,
        });
    };

    pollsUpdated = (polls) => {
        // here we always get the latest polls for the current timeslot
    };

    renderTimeslotTitle = () => {
        const {timeSlot} = this.state;
        const {event, translation} = this.props;
        let eventHasEnded = checkIfEventHasEnded(event, event.timezoneValue);

        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`slot-title ${timeSlot ? '' : 'd-none'} ${!eventHasEnded ? 'more-right-spacing' : ''}`}
            >
                <div>
                    <p onDragStart={preventDefaultDrag} className={'title ellipsis'}>
                        <span>
                            {timeSlot.speakersExtended.length ? (
                                <>
                                    {timeSlot.speakersExtended.map((speaker, index) => {
                                        return (
                                            <span onDragStart={preventDefaultDrag} draggable="false" key={speaker._id}>
                                                {speaker.fullName}
                                                {index === timeSlot.speakersExtended.length - 1 ? (
                                                    <span> {translation?.auditoriumArchive.with} </span>
                                                ) : (
                                                    ', '
                                                )}
                                            </span>
                                        );
                                    })}
                                </>
                            ) : null}
                            {timeSlot.title}
                        </span>
                    </p>
                </div>
            </div>
        );
    };

    render() {
        const {event, eventId, isLargeScreen, translation, defaultTranslation, isRtlLanguage, platformLanguage} =
            this.props;
        const {timeSlot, currentTab, hasPolls, auditorium} = this.state;
        const hideVideo = !isLargeScreen && currentTab !== 'video';
        const sideContainerHidden = currentTab === '';
        const displaySideBar = isLargeScreen || (!isLargeScreen && currentTab !== '' && currentTab !== 'video');

        let eventHasEnded = checkIfEventHasEnded(event, event.timezoneValue);

        if (!timeSlot) {
            return null;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="position-background test">
                <div
                    id="js-auditorium-page"
                    className={`auditorium-page mobile-page-container on-demand-video-page ${
                        currentTab !== 'video ' ? 'wall-tab-active ' : ''
                    } ${!eventHasEnded ? 'has-live-button' : ''}`}
                >
                    {/*SHOW BACKGROUND IMAGE ONLY ON LARGE SCREENS*/}
                    {isLargeScreen && (
                        <AuditoriumBackgroundImage eventTitle={event.title} brandingData={event.brandingData} />
                    )}

                    {!isLargeScreen && (
                        <div onDragStart={preventDefaultDrag} className="page-title">
                            {translation?.auditoriumArchive.title}
                        </div>
                    )}
                    {isLargeScreen && this.renderTimeslotTitle()}
                    {!isLargeScreen && (
                        <AuditoriumTabs
                            timeSlot={timeSlot}
                            hasVideoWall={true}
                            hasPolls={hasPolls}
                            hasResources={timeSlot?.links?.length > 0 || timeSlot?.documents?.length > 0}
                            currentTab={currentTab}
                            handleChangeTab={this.handleChangeTab}
                        />
                    )}
                    <div onDragStart={preventDefaultDrag} className={`centerOfPage ${hideVideo ? 'hide' : ''}`}>
                        <div onDragStart={preventDefaultDrag} className="relativeCenter">
                            {isLargeScreen && (
                                <AuditoriumBanners
                                    filesUrl={
                                        auditorium.stageBanner
                                            ? `${process.env.REACT_APP_EVENT_FOLDER}${eventId}/`
                                            : event.brandingData.filesUrl
                                    }
                                    image={
                                        auditorium.stageBanner ? auditorium.stageBanner : event.brandingData.lobbyBanner
                                    }
                                />
                            )}
                            <AuditoriumVideoPlayer
                                timeSlot={timeSlot}
                                videoStreamingUrl={timeSlot.video}
                                showFastForwardButtons={true}
                                showPlayPauseButtons={true}
                                greyVideoProgressBar
                                isOnDemandVideo={true}
                                autoplayFix
                                singleArchiveVideo
                            />
                            {!isLargeScreen && this.renderTimeslotTitle()}
                        </div>
                    </div>
                    {displaySideBar && (
                        <div
                            onDragStart={preventDefaultDrag}
                            className={`booth-wall-container ${sideContainerHidden ? 'hidden' : ''}`}
                        >
                            <div
                                onDragStart={preventDefaultDrag}
                                className="booth-wall-header"
                                style={{justifyContent: 'center'}}
                            >
                                {timeSlot ? (
                                    <>
                                        <div
                                            onClick={this.handleChangeTab('wall')}
                                            className={`tab wall-tab ${
                                                checkPlatformLanguage(platformLanguage, ['es', 'pt']) ? 'pl-10 ' : ''
                                            } ${currentTab === 'wall' ? 'active' : ''}`}
                                        >
                                            <span>{translation?.auditorium.videoWallButton}</span>
                                        </div>
                                        {!event.hideAuditoriumNetworking && (
                                            <div
                                                onClick={this.handleChangeTab('networking')}
                                                className={`tab networking-tab ${
                                                    currentTab === 'networking' ? 'active' : ''
                                                }`}
                                            >
                                                <span>
                                                    {translation?.sideMenu.audience ||
                                                        defaultTranslation?.sideMenu.audience}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <span>{translation?.auditorium.onDemandButton}</span>
                                )}
                                {!timeSlot && currentTab === 'onDemand' ? (
                                    <div
                                        className="close-dropdown auditorium-sidebar-close-dropdown"
                                        onClick={this.handleChangeTab('')}
                                    >
                                        <GreaterArrow fill={colors.whiteVariant} />
                                    </div>
                                ) : (
                                    <AuditoriumSideButtons
                                        timeSlot={timeSlot}
                                        translation={translation}
                                        defaultTranslation={defaultTranslation}
                                        handleChangeTab={this.handleChangeTab}
                                        hasOnDemandVideos={true}
                                        currentTab={currentTab}
                                        hasPolls={hasPolls}
                                    />
                                )}
                                {!eventHasEnded && (
                                    <BackToLive
                                        translation={translation}
                                        defaultTranslation={defaultTranslation}
                                        linkTo={`/event/${event.slug}/auditorium`}
                                    />
                                )}
                            </div>
                            {currentTab !== 'wall' && currentTab !== 'networking' && timeSlot && (
                                <div onDragStart={preventDefaultDrag} className="booth-wall-subheader">
                                    <BackIcon fill={colors.grey} onClick={this.handleChangeTab('wall')} />
                                    <span className="subheader-text">
                                        {currentTab === 'polls' &&
                                            (translation?.polls?.polls || defaultTranslation?.polls?.polls)}
                                        {currentTab === 'resources' &&
                                            (translation?.auditorium?.resourcesButton ||
                                                defaultTranslation?.auditorium?.resourcesButton)}
                                        {currentTab === 'onDemand' &&
                                            (translation?.auditorium?.onDemandButton ||
                                                defaultTranslation?.auditorium?.onDemandButton)}
                                    </span>
                                </div>
                            )}
                            {currentTab === 'wall' && timeSlot && <Wall isVideoWall />}
                            {currentTab === 'networking' && (
                                <AuditoriumNetworking
                                    timeSlotId={timeSlot?._id}
                                    auditoriumId={this.state.auditoriumId}
                                />
                            )}
                            {currentTab === 'polls' && timeSlot && (
                                <AuditorumPollsOutput
                                    auditoriumId={this.state.auditoriumId}
                                    programId={timeSlot._id}
                                    pollsUpdate={this.pollsUpdated}
                                    translation={translation}
                                    isRtlLanguage={isRtlLanguage}
                                />
                            )}
                            {currentTab === 'resources' && timeSlot && <Resources currentTimeslot={timeSlot} />}
                            {currentTab === 'onDemand' && <OnDemandVideos currentTimeslot={timeSlot} />}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        isRtlLanguage: state.languages.isRtlLanguage,
        platformLanguage: state.languages.platformLanguage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeSidebar: () => dispatch(actions.sideNavClose()),
        setHasVideoWall: (booleanValue) => dispatch(actions.sideNavHasVideoWall(booleanValue)),
        setTimeSlotVideoWall: (wallData) => dispatch(actions.setTimeSlotVideoWall(wallData)),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeVideoWall: () => dispatch(actions.sideNavSeeVideoWall()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auditorium);
