import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import {preventDefaultDrag} from '../Utils/utils';

/**
 * Component that alerts if you click outside of it
 */
class DetectOutsideClick extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        const {isLargeScreen} = this.props;
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.props.target === 'exhibitorsOverlay') {
                this.props.hideExhibitorsOverlay();
            } else if (this.props.target === 'auditoriumsSlider') {
                this.props.hideAuditoriumsSlider();
            } else if (event.target.closest('.preserveHeaderDropdown')) {
                // we don't want to do nothing here, we want to maintain the top nav opened
            } else if (isLargeScreen) {
                this.props.onCloseTopNav();
            } else {
                if (
                    event.target.classList.contains('meetings-mobile') ||
                    event.target.classList.contains('auditorium-page')
                ) {
                    this.props.seeProgramSubmenu();
                } else {
                    this.props.onCloseTopNavMobile();
                }
            }
        }
    }

    render() {
        return (
            <div onDragStart={preventDefaultDrag} className="detect-wrapper" ref={this.setWrapperRef}>
                {this.props.children}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLargeScreen: state.layout.isLargeScreen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseTopNav: () => dispatch(actions.topNavClose()),
        onCloseTopNavMobile: () => dispatch(actions.topNavCloseMobile()),
        seeProgramSubmenu: () => dispatch(actions.bottomSeeProgramSubmenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetectOutsideClick);
