import classnames from 'classnames';

export const Step = ({status, number, text, onlyOneRole}) => {
    return (
        <div className={classnames(`step ${status}`, {'two-steps': onlyOneRole})}>
            <div className={`step-ball`}>{number}</div>
            <div className={`step-text`}>{text}</div>
        </div>
    );
};

export const StepLine = ({status, position, onlyOneRole}) => {
    return (
        <div
            className={classnames('step-line', status, position, {
                'only-one-role-width': onlyOneRole,
                'two-steps': onlyOneRole,
            })}
        ></div>
    );
};
