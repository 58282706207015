import React, {Component} from 'react';
import {LinkOutlined as LinkOutlinedIcon} from "@material-ui/icons";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {NotificationManager} from "react-notifications";
import {withRouter} from "react-router-dom";
import Button from '@material-ui/core/Button';

class SessionLinks extends Component {

    state={
        name: '',
        eventSlug: this.props.match.params.eventSlug,
        sessionId: this.props.match.params.sessionId,
        sessionLinks:[]
    }

    componentDidMount() {
        this.setSesstionLinks();
    }

    setSesstionLinks = () => {
        const {translation, defaultTranslation} = this.props;
        const sessionId = this.props.match.params.sessionId;
        const eventSlug = this.props.match.params.eventSlug;
        const baseSessionLocation = `${window.location.origin}/event/${eventSlug}/live-session/${sessionId}`;

        const sessionLinks = [
            {
                title: (translation?.sessions.speakerUrl || defaultTranslation?.sessions.speakerUrl),
                url: `${baseSessionLocation}/registration/speaker`,
            },
            {
                title: (translation?.sessions.moderatorUrl || defaultTranslation?.sessions.moderatorUrl),
                url: `${baseSessionLocation}/registration/moderator`,
            },
        ];

        this.setState({
            sessionLinks: sessionLinks
        })
    }

    render() {
        const {sessionLinks} = this.state;
        const {translation, defaultTranslation} = this.props;
        return (
            <div className="module-body">
                <div className="module-link_blocks">
                    {sessionLinks.map((link, linkIndex) => {
                        return (
                            <CopyToClipboard
                                key={`link${linkIndex}`}
                                text={link.url}
                                onCopy={() => NotificationManager.info(`${translation?.socialMediaShare.clipboardSnackbarMessage || defaultTranslation?.socialMediaShare.clipboardSnackbarMessage}`)}
                            >
                                <div className="module-link_block">
                                    <span>{link.title}</span>
                                    <Button type="button"
                                            startIcon={<LinkOutlinedIcon />}
                                    >
                                        {translation?.sessions.copyUrl || defaultTranslation?.sessions.copyUrl}
                                    </Button>
                                </div>
                            </CopyToClipboard>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withRouter(SessionLinks);