import React, {useEffect, useState} from 'react';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';

const EllipsisTooltip = ({title, elementId, spanClassName = ''}) => {
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);

    useEffect(() => {
        const ellipsisActive = getEllipsisActive(elementId);
        setIsEllipsisActive(ellipsisActive);
    }, [elementId]);

    const getEllipsisActive = (id) => {
        const element = document.getElementById(id);
        if (!element) return false;
        
        const copy = element.cloneNode(true);
        copy.id = `${element.id}-copy`;
        copy.style.display = 'inline';
        copy.style.width = 'auto';
        copy.style.visibility = 'hidden';
        document.body.appendChild(copy);
        const isTruncated = copy.offsetWidth >= element.clientWidth;
        copy.remove();

        return isTruncated;
    };

    return (
        <Tooltip
            arrow
            title={title}
            placement="top"
            disableHoverListener={!isEllipsisActive}
        >
            <span className={spanClassName}>{title}</span>
        </Tooltip>
    );
};

export default EllipsisTooltip;
