import React from 'react';
import ReactPlayer from 'react-player';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import CachedIcon from '@material-ui/icons/Cached';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import {connect} from 'react-redux';
import {isMobileSafari, isOpera, isIOS} from 'react-device-detect';
import {checkBoothOwnerOrRepresentative, getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../../Utils/utils';
import Slider from '@material-ui/core/Slider';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import {isMobile, isSafari} from 'react-device-detect';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import moment from 'moment';
import axios from '../../../store/axios-instance';
import callAxios from 'axios';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import {isYoutubeLinkRule} from '../../../Utils/validationRules';
import './VideoStyles.scss';
class Video extends React.Component {
    fullscreenTimerId = 0;
    state = {
        fullscreen: false,
        showRefreshButton: false,
        muteVideo: true,
        isVideoPlaying: true,
        progressTime: 0,
        connectTimestamp: null,
        thumbnailImage: '',
        streaming: '',
        videoLoading: true,
    };
    videoPlayer = React.createRef();

    componentDidMount() {
        window.addEventListener('resize', this.setActiveElementsPositions);
        document.addEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);
        this.setThumbnailImage();
        this.setState({connectTimestamp: moment.utc()});
    }

    componentDidUpdate(prevProps) {
        // update thumbnail everytime the organizer changes the videoUrl
        if (prevProps.exhibitor.booth.video !== this.props.exhibitor.booth.video) {
            this.setState(
                {
                    progressTime: 0,
                },
                () => {
                    this.setThumbnailImage();
                    this.videoPlayer.current.seekTo(0, 'seconds');
                }
            );
        }
    }

    componentWillUnmount() {
        if (document.fullscreenElement) {
            document.exitFullscreen().then(()=>{});
        }

        window.removeEventListener('resize', this.setActiveElementsPositions);

        document.removeEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);

        const {connectTimestamp} = this.state;
        if (connectTimestamp) {
            this.handleVideoExit(connectTimestamp, () => {
                this.setState({connectTimestamp: null});
            });
        }
    }

    handleVideoExit = (connectTimestamp) => {
        const {event, exhibitor, user} = this.props;
        const {owner} = event;

        const actionType = 'boothVideos';
        const isRepresentative = checkBoothOwnerOrRepresentative(exhibitor, user._id);
        let trackBoothStatistics = !isRepresentative;

        if (owner._id === user._id) {
            trackBoothStatistics = false;
            let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
            if (findEventRoles) {
                if (findEventRoles?.roles?.includes('participant')) {
                    trackBoothStatistics = true;
                }
            }
        }

        if (connectTimestamp && trackBoothStatistics) {
            const data = {
                userId: this.props.user._id,
                connectTimestamp: connectTimestamp,
                disconnectTimestamp: moment.utc(),
                actionType: actionType,
            };
            axios({
                method: 'post',
                url: '/exhibitor/' + this.props.exhibitor._id + '/booth-statistics',
                data: data,
            })
                .then((res) => {})
                .catch((err) => {});
        }
    };

    setThumbnailImage = () => {
        const {exhibitor} = this.props;
        let boothVideoId = null;
        let thumbnailImage;
        let ruleYTB = isYoutubeLinkRule;
        let match = ruleYTB.test(exhibitor.booth.video || '');
        if (match) {
            boothVideoId = getVideoIdFromYoutubeUrl(exhibitor.booth.video || '');
            thumbnailImage = `https://img.youtube.com/vi/${boothVideoId}/0.jpg`;
            this.setState({
                thumbnailImage: thumbnailImage,
                streaming: 'youtube',
            });
        } else {
            callAxios.get(`https://vimeo.com/api/oembed.json?url=${exhibitor.booth.video || ''}`).then((res) => {
                this.setState({
                    thumbnailImage: `${res.data.thumbnail_url}.jpg`,
                });
            });
        }
    };

    handleVideoReady = () => {
        this.setState({
            videoLoading: false,
        });
    };

    toggleFullScreenEscKey = () => {
        let fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullscreenElement ||
            document.msFullscreenElement;
        if (!fullscreenElement) {
            this.exitFullscreen();
        }
    };

    onIOSFullScreen = () => {
        this.setState({fullscreen: this.state.fullscreen ? false : true});
        document.querySelector('.react-player').classList.toggle('fullscreen-ios');
        document.querySelector('.top-container').classList.toggle('d-none');
        document.querySelector('#menu-bottom').classList.toggle('d-none');
        document.querySelector('body').classList.toggle('fullscreen-bg');
        document.querySelector('.video-actions-container').classList.toggle('fullscreen-ios-controls');
        document.querySelector('.showfloor-page.event-mobile-page.show-info-screen').classList.toggle('h100vh');
    };

    toggleFullScreen = () => {
        const isFullscreenActive = document.fullscreenElement !== null;
        if (isSafari && isMobile) {
            this.onIOSFullScreen();
        } else {
            if (isFullscreenActive) {
                document
                    .exitFullscreen()
                    .then(() => this.exitFullscreen())
                    .catch((err) => console.error(err));
            } else {
                document.documentElement.requestFullscreen().then(() => this.startFullscreen());
            }
        }
    };

    startFullscreen = () => {
        this.setState({fullscreen: true});
        this.hideVideoButtons();

        document.body.classList.add('fullscreen-video');
        // document.getElementById('js-auditorium-page').classList.add('fullscreen');
        window.addEventListener('mousemove', this.mouseMove);
    };

    exitFullscreen = () => {
        this.setState({fullscreen: false});
        this.showVideoButtons();
        clearTimeout(this.fullscreenTimerId);

        document.body.classList.remove('fullscreen-video');
        // document.getElementById('js-auditorium-page').classList.remove('fullscreen');
        window.removeEventListener('mousemove', this.mouseMove);
    };

    videoHasEnded = () => {
        this.exitFullscreen();
        if (isSafari && isMobile) {
            this.onIOSFullScreen();
        }

        this.setState({videoEnded: true, muteVideo: false});
    };

    mouseMove = () => {
        clearTimeout(this.fullscreenTimerId);
        this.showVideoButtons();
        this.fullscreenTimerId = setTimeout(this.hideVideoButtons, 3000);
    };

    showVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.remove('hide');
        }
    };

    hideVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.add('hide');
        }
    };

    refreshPage = () => window.location.reload(true);

    isPlayingError = () =>
        this.setState({
            showRefreshButton: true,
        });

    handleProgressChange = (e, value) => {
        e.preventDefault();
        this.videoPlayer.current.seekTo(value / 100.5, 'fraction');
    };

    handleProgressBar = (progress) => {
        this.setState({
            progressTime: parseFloat((progress?.played * 100).toFixed(1)),
        });
    };

    toggleMute = () => this.setState({muteVideo: !this.state.muteVideo});

    toggleRewind = () => {
        const {streaming} = this.state;
        let currentTime = Math.floor(this.videoPlayer.current.getCurrentTime());
        if (streaming === 'youtube') {
            this.videoPlayer.current.seekTo(Math.floor(this.videoPlayer.current.getCurrentTime()) - 30, 'seconds');
        } else {
            if (currentTime - 30 > 0) {
                this.videoPlayer.current.seekTo(currentTime - 30, 'seconds');
            } else {
                this.videoPlayer.current.seekTo(0);
            }
        }
    };

    toggleForward = () => {
        const {streaming} = this.state;
        let currentTime = Math.floor(this.videoPlayer.current.getCurrentTime());
        if (streaming === 'youtube') {
            this.videoPlayer.current.seekTo(Math.floor(this.videoPlayer.current.getCurrentTime()) + 30, 'seconds');
        } else {
            this.videoPlayer.current
                .getInternalPlayer()
                .getDuration()
                .then((duration) => {
                    if (currentTime + 30 < duration) {
                        this.videoPlayer.current.seekTo(currentTime + 30, 'seconds');
                    } else {
                        this.videoPlayer.current.seekTo(duration);
                    }
                });
        }
    };

    togglePause = () => {
        const {streaming} = this.state;
        this.setState({isVideoPlaying: !this.state.isVideoPlaying}, () => {
            if (streaming === 'youtube') {
                if (this.state.isVideoPlaying) {
                    this.videoPlayer.current.getInternalPlayer().playVideo();
                } else {
                    this.videoPlayer.current.getInternalPlayer().pauseVideo();
                }
            }
        });
    };

    render() {
        const {exhibitor, translation} = this.props;
        const {fullscreen, muteVideo, showRefreshButton, thumbnailImage, isVideoPlaying, progressTime, videoLoading} =
            this.state;
        const hideFullscreenButton = isMobileSafari || isOpera || isIOS;

        return (
            <div onDragStart={preventDefaultDrag} className={'videoWrapper ' + (fullscreen ? 'fullscreen' : '')}>
                <div onDragStart={preventDefaultDrag} className="video-container">
                    {videoLoading && <Spinner custom="spinner" />}
                    <img
                        src={thumbnailImage}
                        alt="Video thumbnail"
                        className={`booth-thumbnail ${!isVideoPlaying && 'show'}`}
                    />
                    <ReactPlayer
                        ref={this.videoPlayer}
                        url={exhibitor.booth.video}
                        className="react-player"
                        controls={false}
                        width="100%"
                        height="100%"
                        volume={muteVideo ? 0 : 1}
                        muted={muteVideo}
                        loop
                        playing={isVideoPlaying}
                        playsinline
                        playIcon={<></>}
                        onReady={this.handleVideoReady}
                        onProgress={this.handleProgressBar}
                        onContextMenu={preventDefaultDrag}
                        onEnded={this.videoHasEnded}
                        onError={this.isPlayingError}
                    />
                    <Slider
                        classes={{root: `progress-bar ${!isVideoPlaying && 'hide'}`}}
                        onChange={this.handleProgressChange}
                        value={progressTime}
                        color="secondary"
                    />

                    <div
                        className={`auditoriumOverlayPlayer ${(muteVideo && isVideoPlaying) ? 'audio-overlay' : ''}`}
                        onClick={this.toggleMute}
                    >
                        {muteVideo && <VolumeUpIcon  style={{zIndex:9,cursor:'pointer'}} fontSize="inherit" />}
                    </div>

                </div>
                {showRefreshButton ? (
                    <div onDragStart={preventDefaultDrag} className="video-error-container">
                        <p onDragStart={preventDefaultDrag} onClick={this.refreshPage}>
                            {translation?.videoPlayer.videoNotPlayingError}
                        </p>
                        <button onClick={this.refreshPage}>
                            <CachedIcon fontSize="inherit" />
                        </button>
                    </div>
                ) : null}
                <div onDragStart={preventDefaultDrag} className="video-actions-container" id="videoButtons">
                    {/* className={`${hideFullscreenButton ? 'd-none' : ''}`} */}
                    <button onClick={this.toggleFullScreen}>
                        {fullscreen ? (
                            <Tooltip arrow title={translation?.videoPlayer.tooltipMinimize}>
                                <FullscreenExitIcon fontSize="inherit" />
                            </Tooltip>
                        ) : (
                            <Tooltip arrow title={translation?.videoPlayer.tooltipFullscreen}>
                                <FullscreenIcon fontSize="inherit" />
                            </Tooltip>
                        )}
                    </button>
                    <button onClick={this.toggleRewind} className="fullscreen-button mute-button">
                        <FastRewindIcon fontSize="inherit" />
                    </button>
                    <button onClick={this.togglePause} className="fullscreen-button mute-button">
                        {isVideoPlaying ? <PauseIcon fontSize="inherit" /> : <PlayArrowIcon fontSize="inherit" />}
                    </button>
                    <button onClick={this.toggleForward} className="fullscreen-button mute-button">
                        <FastForwardIcon fontSize="inherit" />
                    </button>
                    <button onClick={this.toggleMute}>
                        {muteVideo ? (
                            <Tooltip arrow title={translation?.videoPlayer.tooltipUnmute}>
                                <VolumeOffIcon fontSize="inherit" />
                            </Tooltip>
                        ) : (
                            <Tooltip arrow title={translation?.videoPlayer.tooltipMute}>
                                <VolumeUpIcon fontSize="inherit" />
                            </Tooltip>
                        )}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        event: state.event.data,
        exhibitor: state.exhibitor.data,
        user: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Video);
