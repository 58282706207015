import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import CloseIcon from '@material-ui/icons/Close';
import Confirm from '../../../Dialogs/Confirm';
import {preventDefaultDrag} from '../../../Utils/utils';

class SideButtons extends React.Component {
    state = {
        openLeaveVideoMeetingDialog: false,
    };

    _closeDialog = () => {
        this.setState({
            openLeaveVideoMeetingDialog: false,
        });
    };

    _handleCloseChatsComponent = () => {
        const {videoConference} = this.props;
        if (videoConference) {
            this.setState({
                openLeaveVideoMeetingDialog: true,
            });
        } else {
            this.props.seeChats();
        }
    };

    render() {
        const {openLeaveVideoMeetingDialog} = this.state;
        const {translation, defaultTranslation} = this.props;

        return (
            <>
                <div onDragStart={preventDefaultDrag} className="dropdown-actions-chats">
                    <div>
                        {this.props.chatsDesktop.seeChats && (
                            <div
                                onDragStart={preventDefaultDrag}
                                className="close-dropdown close"
                                onClick={this._handleCloseChatsComponent}
                            >
                                <CloseIcon />
                            </div>
                        )}
                        <div
                            onDragStart={preventDefaultDrag}
                            className="close-dropdown expand"
                            onClick={this.props.seeExpandedVideoConference}
                        >
                            {this.props.maximizeChats.seeExpandedVideoConference ? (
                                <UnfoldLessIcon />
                            ) : (
                                <UnfoldMoreIcon />
                            )}
                        </div>
                    </div>
                </div>
                <Confirm
                    open={openLeaveVideoMeetingDialog}
                    closeConfirm={this._closeDialog}
                    dialogTitle={
                        translation?.chatsDropdown.leaveVideoMeetingTitle ||
                        defaultTranslation?.chatsDropdown.leaveVideoMeetingTitle
                    }
                    dialogDescription={
                        translation?.chatsDropdown.leaveVideoMeetingDescription ||
                        defaultTranslation?.chatsDropdown.leaveVideoMeetingDescription
                    }
                    dialogConfirmButtonLabel={translation?.generalText.leave || defaultTranslation?.generalText.leave}
                    handleConfirm={this.props.seeChats}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        chatsDesktop: state.user.chatsDesktop,
        maximizeChats: state.user.maximizeChats,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeChats: () => dispatch(actions.topNavSeeChats()),
        seeExpandedVideoConference: () => dispatch(actions.expandedChatsOnVideoConference()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideButtons);
