const zh = {
    generalText: {
        upload: '上传',
        save: '保存',
        add: '添加',
        remove: '移除',
        close: '关闭',
        update: '更新',
        cancel: '取消',
        confirm: '确认',
        download: '下载',
        refresh: '刷新',
        back: '返回',
        submit: '提交',
        send: '发送',
        leave: '离开',
        step1: '第1步',
        step2: '第2步',
        or: '或',
        delete: '删除',
        please: '请',
        before: '在……之前',
        approve: '批准',
        decline: '拒绝',
        start: '开始',
        stop: '停止',
        resetFilter: '重置筛选器',
        apply: '应用',
        filters: '筛选器',
        unsavedChangesTitle: '未保存的变更',
        unsavedChangesText: '您有未保存的变更。是否保存？',
        selectAll: '选择全部',
        unselectAll: '取消选择全部',
    },
    time: {
        justNow: '刚才',
        minute: '分钟',
        minutes: '分钟',
        hour: '小时',
        hours: '小时',
        day: '天',
        days: '天',
        month: '月',
        months: '月',
        yesterday: '昨天',
        today: '今天',
        tomorrow: '明天',
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期五',
        saturday: '星期六',
        sunday: '星期日',
        january: '一月',
        february: '二月',
        march: '三月',
        april: '四月',
        may: '五月',
        june: '六月',
        july: '七月',
        august: '八月',
        september: '九月',
        october: '十月',
        november: '十一月',
        december: '十二月',
        januaryShort: '一月',
        februaryShort: '二月',
        marchShort: '三月',
        aprilShort: '四月',
        mayShort: '五月',
        juneShort: '六月',
        julyShort: '七月',
        augustShort: '八月',
        septemberShort: '九月',
        octoberShort: '十月',
        novemberShort: '十一月',
        decemberShort: '十二月',
    },
    errors: {
        noFile: '没有选中任何文件',
        required: '必填项',
        emailNotValid: '电子邮件无效',
        passwordMinLength: '密码必须至少8个字符',
        passwordMismatch: '密码不匹配',
        passwordIsSame: '您的新密码必须不同',
        noInvitationCode: '请输入您的邀请码',
        imageFileNotSupported: '不支持的文件类型。 请使用以下之一：',
        phoneInvalid: '请输入一个有效的电话号码。 例如：+447517663928',
        linkInvalid: '请输入一个有效的链接 (https://site.com/)',
        facebookLinkInvalid: '请输入一个有效链接(https://facebook.com/)',
        linkedinLinkInvalid: '请输入一个有效链接(https://linkedin.com/)',
        twitterLinkInvalid: '请输入一个有效链接(https://twitter.com/)',
        youtubeLinkInvalid: '请输入有效的链接(https://www.youtube.com/)',
        youtubeOrVimeoLinkInvalid: '请输入有效的YouTube(https://www.youtube.com)或Vimeo(https://vimeo.com/_链接',
        googleScholarLinkInvalid: '请输入有效的链接(https://scholar.google.com/)',
        researchGateLinkInvalid: '请输入有效的链接(https://www.researchgate.net/)',
        connectionProblemTextFirst: '似乎存在连接问题。 请',
        connectionProblemButton: '点击',
        connectionProblemTextSecond: '或刷新页面。',
        fileTooLarge: '文件过大。 档案大小上限为2Mb。',
        fileTooLargeTextFirst: '文件过大。',
        fileTooLargeTextSecond: '最大文件大小。',
        fileNameTooLongTextFirst: '文件名过长。限制范围为',
        fileNameTooLongTextSecond: '字符数。',
        maxWallInputLength: '您已达到最大字符数1200个。',
    },
    footer: {
        cookiesText:
            '本网站使用必要的信息记录程序，这是网站运作所必需的。本网站也使用第三方信息记录程序，这对于我们网站的某些功能的运作是必要的。如果您想了解更多信息或撤销您对某些信息记录程序的同意，请参考我们的',
        cookiesLink: '信息记录程序的政策。',
        cookiesButton: '接收',
        privacyPolicy: '私隐政策',
        cookies: '信息记录程序',
        termsAndConditions: '条款和条件',
        socialLinksTitle: '关注我们：',
    },
    menu: {
        logIn: '登录',
        logOut: '登出',
        scholarRegistration: '作为发贴者/研究者加入',
        exhibitorRegistration: '作为参展商加入',
        participantRegistration: '作为参与者加入',
        createEvent: '举办活动',
        loginDisabledInfoText: '组织者已关闭登录。请稍后访问',
    },
    homepage: {
        organizedBy: '举办方',
        date: '日期',
        location: '域和位置',
        price: '价格',
        tabIntro: '介绍',
        tabProgram: '活动内容',
        tabSponsors: '赞助方',
        tabPartners: '合作伙伴',
        tabExhibitors: '参展商',
        previousEventsSectionTitle: '历届活动',
    },
    socialButtons: {
        linkedinButton: '通过领英登录',
        loginTabLinkedinButton: '通过领英',
        facebookButton: '通过脸书登录',
        loginTabFacebookButton: '通过脸书',
        googleButton: '通过谷歌登录',
        loginTabGoogleButton: '通过谷歌',
        twitterButton: '通过推特登录',
        loginTabTwitterButton: '通过推特',
        socialButtonsLogInTitle: '通过您的社交媒体资料加入',
        socialButtonsSignUpTitle: '通过您的社交媒体资料注册',
    },
    login: {
        inputEmail: '邮箱地址',
        inputPassword: '密码',
        rememberMeButton: '记住我',
        forgotPasswordButton: '忘记密码？',
        loginButton: '登录',
        loginClosedButton: '登录已关闭',
        loginTitleLowerCase: '登录',
        recoverPasswordDialogTitle: '恢复密码',
        recoverPasswordDialogRecoverButton: '恢复',
        errorIncorrectDates: '电子邮箱或密码错误',
        orLogInWithEmail: '或通过电子邮箱登录',
        logInAsParticipantSecondText: '作为参与者',
        logInAsExhibitorSecondText: '作为参展商',
        logInAsPosterSecondText: '作为发贴者/研究者',
        errorAccountNotFound: '我们无法找到您的myOnvent账户',
    },
    register: {
        inputFirstName: '名字',
        inputLastName: '姓氏',
        inputEmail: '邮箱地址',
        inputPassword: '密码',
        inputConfirmPassword: '确认密码',
        gdprTextFirst: '继续进行，即表示您同意我们的',
        gdprTextTerms: '使用条款',
        gdprTextSecond: '并确认您已阅读我们的',
        gdprTextCookies: '信息记录程序',
        gdprTextPrivacy: '私隐政策',
        gdprTextAgree: '请同意隐私政策',
        registerButton: '注册',
        signUpTitleLowerCase: '注册',
        invitationCode: '邀请码',
        registerNotAllowed: '注册已关闭',
        logInTitleFirst: '加入',
        logInTitleSecond: '活动',
        signUpTitleTextFirst: '首次访问',
        signUpTitleTextSecond: 'myOnvent',
        orFillInTheForm: '或填写下表',
        uploadProfilePicture: '上传个人照片',
        editProfilePicture: '编辑个人照片',
        errorAlreadyHaveMOAccount: '您已有一个myOnvent账户',
        step1From2Text: '2步中的第1步',
        step2From2Text: '2步中的第2步',
        metaTitleScholarRegistration: '发贴者/研究者注册',
    },
    newLoginRegistrations: {
        joinEvent: '参加活动',
        loginWelcome: '欢迎来到 myOnvent！请选择登录方式。',
        continueButton: '继续',
        seemsLikeYouDontHaveMyOnventAccount: '好像您没有 myOnvent 账户',
        uploadProfilePictureOptional: '上传个人头像（可选）',
        createPlatformAccount: '创建平台账户',
        platformAccount: '平台账户',
        selectRole: '选择角色',
        eventAccount: '活动账户',
        password: '密码',
        confirmPassword: '确认密码',
        email: '电子邮件',
        passwordMustContain: '密码必须包含：',
        minimum8Characters: '至少8个字符',
        aLowerCaseLetter: '一个小写字母',
        anUppercaseLetter: '一个大写字母',
        aNumber: '一个数字',
        aSpecialCharacter: '一个特殊字符',
        firstName: '名字',
        lastName: '姓氏',
        weHaveSentAVerificationCodeToYourEmail: '我们已将验证代码发送至您的电子邮件。',
        continueRegistration: '要继续注册，请',
        enterCodeBelow: '在下面输入验证码：',
        codeWillExpire: '此代码将在',
        codeExpired: '代码已过期',
        codeNotReceived: '没有收到验证码？您检查过垃圾邮件文件夹了吗？',
        resendTimer: '重新发送倒计时：',
        resendLabel: '重新发送',
        resendCodeLabel: '重新发送验证码',
        codeError: '输入的验证码不正确。',
        codeErrorFix: '重新输入来自您的电子邮件的正确验证码或重新发送新的验证码。',
        congrats: '恭喜！',
        emailConfirmed: '您的电子邮件已确认：',
        imHereAs: '我在这里的身份是：',
        participant: '参与者',
        participantText: '观看直播、交流、访问展商和海报/研究。',
        exhibitor: '展商',
        exhibitorText: '展示您的公司并与活动参与者交流。',
        poster: '海报/研究',
        posterText: '为学术或专业会议展示研究。',
        emailIsNotAllowed: '此电子邮件不允许注册此活动。',
        didntReceiveEmail: '没有收到电子邮件吗？',
        sendAgain: '重新发送 ',
        incorrectPassword: '密码错误',
        fileTypeError: '文件类型不支持。请使用以下之一：jpeg、jpg、jfif、gif 或 png。',
        fileSizeError: '文件太大。最大文件大小为 2MB。',
        pleaseCompleteAllRequiredFields: '请填写所有必填字段',
        joinTheEvent: '加入活动',
        joinTheEventSub: '开始您的虚拟在线活动体验',
        changeEmail: '更改电子邮件',
        changeRole: '更改角色',
        logout: '退出',
        headingUser: '欢迎回来',
        subHeadingUser: '看起来你已经拥有平台账户了',
        forgotPasswordHeadline: '忘记密码',
        forgotPasswordText: '别担心，我们将给您发送进一步的说明',
        checkYourEmail: '查看您的电子邮件',
        checkYourEmailText: '我们已向您发送密码重置链接：',
        headingNoUser: '登录',
        subHeadingNoUser: '欢迎来到myOnvent！为了继续，请选择一种登录方法',
        resetPasswordButton: '重置密码',
        createNewPassword: '创建新密码',
        createNewPasswordText: '您的新密码必须与以前使用过的密码不同',
        passwordReset: '密码重置',
        passwordResetText: '您的密码已成功重置。请单击下方以再次登录。',
        registrationIsClosed: '所有角色的注册已关闭。',
        chooseAnOption: '选择一个选项',
        yourAnswer: '你的回答',
        firstNameError: '名字太短。最小长度：2',
        lastNameError: '姓氏太短。最小长度：2',
        recoveryPassword: '找回密码',
        incorrectPasswordError: '密码错误。请重试或点击“忘记密码”以更改。',
        optional: '可选的',
        spamEmailError: '在创建您的帐户时出现问题。请稍后再试。',
        invalidInput:
            "无效输入。只能使用字母（A-Z，a-z）、数字（0-9）、句点（.）、撇号（'）、连字符（-）和空格。第一个字符必须是字母。",
    },
    emailUpdate: {
        changeEmail: '更改电子邮件',
        changeEmailDescription: '在我们将新电子邮件分配给您的帐户之前，我们希望确保它是一个新的电子邮件，属于没有人。',
        emailTakenError: '该电子邮件已被使用。请尝试另一个。',
        enterTheConfirmationCode: '输入确认码',
        verificationCodeSent: '已发送确认码：',
        checkYourSpamFolder: '找不到确认码？请检查您的垃圾邮件文件夹',
        emailSuccess: '成功！您已更改分配给您的帐户的电子邮件',
        emailError: '错误。稍后再试',
        confirmPassword: '确认密码',
        confirmPasswordDescription: '为了更改电子邮件，请确认您的密码：',
        newEmail: '新电子邮件',
        password: '密码',
        confirm: '确认',
        cancel: '取消',
        change: '更改',
    },

    agendaTranslations: {
        myAgenda: '我的日程',
        all: '全部',
        sessions: '会议',
        meetings: '会议',
        pastSlots: '过去的时间段',
        meetingWith: '与之会面',
        pending: '待定',
        accepted: '已接受',
        requested: '已请求',
        canceled: '已取消',
        now: '现在',
        live: '直播',
        noMeetings: '目前没有安排任何会议',
        noSessions: '您的日程中尚无会议。',
        noUpcomingSessions: '您的日程中尚无即将开始的会议。',
        noUpcomingMeetings: '您的日程中尚无即将到来的会议。',
        noUpcomingAgenda: '您的日程中目前没有即将发生的事项。',
        noAgendaItem: '您的日程中目前没有事项。',
        meetingRequests: '会议请求',
        finished: '已完成',
        past: '已过期',
        goToMeeting: '进入会议',
        addToMyAgenda: '添加到我的日程',
        addedToYourAgenda: '已添加到您的日程',
        acceptedAllMeetings: '接受所有会议',
    },
    participantRegistration: {
        metaTitle: '参与者注册',
        choosePackageTitle: '选择访问套餐',
        noPackageText: '请选择一个套餐',
        gdprTextFirst: '继续进行，即表示您同意我们的',
        gdprTextPrivacy: '私隐政策',
        gdprTextSecond: '和',
        gdprTextTerms: '活动条款和条件',
        gdprTextAgree: '请同意隐私政策',
        registerButtonFree: '完成注册',
        registerButtonPay: '继续付款',
    },
    exhibitorRegistration: {
        metaTitle: '参展商注册',
        choosePackageTitle: '选择访问套餐',
        gdprTextFirst: '继续进行，即表示您同意我们的',
        gdprTextPrivacy: '活动隐私权政策',
        gdprTextSecond: '和',
        gdprTextTerms: '活动条款和条件',
        gdprTextAgree: '请同意隐私政策',
        registerButtonFree: '完成注册',
        registerButtonPay: '继续付款',
    },
    stripePayment: {
        cardNumber: '卡号',
        expirationDate: '到期日期（月/日）',
        cvc: '安全码',
        disclaimerText: '此支付操作是全程加密的。Stripe 服务公司保证安全和隐私。',
        disclaimerTextLink: '点击这里了解更多。',
        cancelButton: '取消',
        payButton: '付款',
    },
    recoverPassword: {
        recoverTextSend: '恢复密码的邮件已成功发送',
    },
    unsubscribe: {
        unsubscribeTitle: '取消订阅？',
        unsubscribeHomepage: '去主页',
    },
    notAvailable: {
        title: '没有活动',
        description: '没有您正寻找的活动。它已被组织者关闭或被管理员禁用',
        fullOverlayEvents: '注销您的其他活动',
        fullOverlayNoEvents: '找不到其他活动',
    },
    notFound: {
        notFoundDescription: '糟糕...在这里找不到活动！',
        notFoundHomepage: '去主页',
    },
    eventMenu: {
        organizerButton: '组织者',
        coOrganizerButton: '管理',
        exhibitorButton: '参展商',
        posterStudyButton: '发贴者/研究者',
        boothButton: '展位',
        boothsButton: '展位',
        homeButton: '主页',
        contactsButton: '联系人',
        meetingsButton: '会议',
        chatsButton: '聊天',
        programButton: '活动内容',
        archiveButton: '存档',
        myAccountButton: '我的帐户',
        lobbyButton: '大堂',
        roomsButton: '房间',
        connectionProblemTextFirst: '似乎存在连接问题。 请点击',
        connectionProblemButton: '这里',
        connectionProblemTextSecond: '或刷新页面。',
        sessionProblemTextFirst: '您的会议已过期。 请点击',
        sessionProblemButton: '这里',
        sessionProblemTextSecond: '重新登录。',
    },
    myEventsDropdown: {
        title: '我的活动',
        createButtonMobile: '创建',
    },
    myBoothsDropdown: {
        title: '我的展位',
        emptyStudyTitle: '发贴者/研究者展位',
    },
    contactsDropdown: {
        title: '您的联系人',
        requestsTitle: '联络邀请',
        rejectButton: '拒绝',
        acceptButton: '接收',
        pendingButton: '待定',
        addButton: '添加',
        noContacts: '您还没有任何联系人。 请访问交流沟通区域以开始建立您的联系人列表。',
    },
    meetingsDropdown: {
        meetingWith: '和…进行会议',
        pending: '待定',
        accepted: '接收',
        canceled: '取消',
        noMeetings: '尚未安排会议',
        finished: '完成',
    },
    programDropdown: {
        stages: '舞台',
        with: '与',
        noProgram: '暂无可用的节目条目。',
        fullProgramButton: '完整节目', // new translation
        addToCalendarButton: '添加至日历', // new translation
        addedToYourAgenda: '已添加至您的日程',
        addEventButton: '添加事件', // new translation
        addToMyAgenda: '添加至我的日程', // new translation
        addEventToYourCalendarDialogTitle: '将事件添加至您的日历', // new translation
        addTimeslotToYourCalendar: '将时间段添加至您的日历', // new translation
        tip: '提示：', // new translation
        tipDescription: '您也可以通过点击每个时间段旁边的日历图标将单独的时间段添加至您的日历。', // new translation
        tipTimeslotDescription: '您也可以通过点击“添加至日历”按钮将整个事件添加进去。', // new translation
        durationEventTitle: '事件时长：', // new translation
        timeslotTitle: '标题：', // new translation
        slotScheduled: '时间段安排：', // new translation
        addSlotToCalendar: '将时间段添加至日历', // new translation
        speakers: '演讲者', // new translation
        auditoriumName: '礼堂名称', // new translation
        timezoneTooltipText: '节目以组织者的时区显示。您可以更改显示时区，以查看所选时区的会议。', // new translation
        liveLabel: '直播', // new translation
        liveLabelTooltipText: '该时间段正在礼堂中直播', // new translation
        openAuditorium: '打开礼堂', // new translation
    },

    notificationsDropdown: {
        title: '消息通知',
        contactRequestReceived: '向您发送了联系请求。 查看一下！',
        contactRequestAccepted: '接受了您的联系请求。 开始聊天！',
        meetingRequestReceived: '向您发送了会议请求。 请查看',
        meetingRequestAccepted: '接受了您的会议请求。 请查看',
        meetingRequestCanceled: '取消了会议请求。 请查看',
        meetingStarting: '会议即将开始。 请查看',
        newBoothPost: '已公布在您的展位墙上。 查看一下！',
        noNotifications: '您还没有任何通知。',
        seeAll: '查看全部',
        hideAll: '隐藏全部',
        meetingWillStartTextSecond: '即将开始。 一探究竟！',
    },
    chatsDropdown: {
        title: '聊天',
        newChatProvideText: '请提供您的聊天名称。 之后，您可以编辑名称：',
        noChats: '创建至少一个聊天室来和活动参与者交谈',
        members: '成员',
        noOpenedChatText: '单击聊天继续对话。',
        noMessagesInChatTextFirst: '打破僵局。',
        noMessagesInChatTextSecond: '成为发起对话的人。',
        newChatNameInput: '聊天名称（可选）',
        newChatAddMembers: '将联系人和其他参会者添加到此聊天中：',
        editChatAddMembers: '请搜索您在这个活动中要添加到群聊中的用户。',
        newChatSearchInput: '搜索用户',
        newChatNoUsersMessage: '没有搜索到用户',
        newChatRemoveUserButton: '移除',
        newChatCancelButton: '关闭',
        newChatCreateButton: '创建',
        membersLeaveButton: '离开',
        membersUpdateButton: '更新',
        membersChatButton: '聊天',
        chatSettingsButton: '设置',
        newMessageInput: '在此处输入消息',
        newVideoCallTooltipText: '点击此处开始视频聊天',
        leaveVideoMeetingTitle: '离开视频会议',
        leaveVideoMeetingDescription: '您将离开视频会议。是否继续进行？',
        leaveChatConfirmationTitle: '离开视频通话',
        leaveChatConfirmationText: '是否离开当前视频通话？',
    },
    myAccountDropdown: {
        title: '我的帐户',
        viewProfileButton: '查看资料',
        logoutButton: '登出',
    },
    myAccountPage: {
        changePasswordButton: '更改密码',
        deleteAccountButton: '删除我的账户',
        platformLanguage: '平台语言：',
        privacyPolicyLink: '隐私权声明/政策',
        cookiesLink: '信息记录程序',
        termsAndConditionsLink: '条款和条件',
        eventProfileSectionTitle: '活动简介',
        eventPackage: '活动套餐',
        unregisterFromEventButton: '从活动取消注册',
        unregisterCurrentEventButton: '从当前活动中注销',
        unregisterCurrentEventConfirmation: '请确认您要注销活动',
        enableParticipantProfile: '启用参与者资料',
        editUserDialogTitle: '编辑用户详细信息',
        editExhibitorDialogTitle: '编辑公司联系人详细信息',
        editScholarDialogTitle: '编辑发贴者/研究者联系人详细信息',
        editParticipantDialogTitle: '编辑参加者详细信息',
        firstName: '名字',
        lastName: '姓氏',
        uploadImage: '上传图像',
        removeImage: '移除图像',
        deleteError: '您无法删除您的帐户。 请删除所有创建的活动，以便能够删除您的帐户。',
        deleteSuccess: '您的帐户已被删除。',
        reviewEventPrivacyPolicy: '查看我们的活动隐私政策：',
        title: '职称',
        company: '公司',
        phone: '电话号码',
        platformProfileSectionTitleTextSecond: '个人资料',
        createParticipantProfile: '创建参与者个人资料',
        reviewPlatformConditions: '查看我们的平台条件',
        reviewEventConditions: '查看我们的活动条件',
    },
    changePassword: {
        title: '更改密码',
        oldPassword: '旧密码',
        oldPasswordError: '旧密码不匹配！',
        inputPassword: '新密码',
        inputConfirmPassword: '确认密码',
    },
    roomsDropdown: {
        auditoriumButton: '会议礼堂',
        archiveButton: '存档',
        onDemandButton: '按需',
    },
    sideMenu: {
        liveWall: '直播墙',
        networking: '沟通交流',
        audience: '观众',
        info: '信息',
        videoWall: '视频墙',
        booth: '展位',
        boothWall: '展位墙',
        networkingTooltip: '您无法访问所有沟通交流功能。',
        attendees: '与会者',
    },
    wall: {
        sortBy: '排序方式：',
        popularity: '热门度',
        time: '时间',
        emptyWallText: '成为第一个在这里添加帖子的人。\n只需说明您的想法或简单介绍您自己',
        newPostInput: '您在想什么？',
        newCommentInput: '评论',
        resourcesVideoDescription: '视频描述',
        resourcesLinks: '链接',
        resourcesFiles: '文件',
        pinTitle: '置顶',
        unPinTitle: '取消置顶',
        deleteDialogTitle: '删除帖子',
        pinDialogDescription: '已有置顶帖子。一次只能有一个帖子置顶。继续进行操作，先前的帖子将自动取消置顶。',
        edited: '已编辑',
        escapeToCancel: '按ESC键取消',
        enterToSave: '按回车键保存',
        editingState: '编辑消息',
        bannerModeration: '为了确保内容优质，该墙上的帖子和评论正被审核',
        pendingApproval: '尚待批准',
        approvePost: '批准帖子',
        approvePostComment: '批准评论',
        commentsPendingApproval: '评论尚待批准',
    },
    networking: {
        searchInput: '按用户搜索',
        emptyNetworkingSearchResults: '您正寻找的人员尚未注册该在线活动',
        emptyAuditoriumNetworkingSearchResults: '您正寻找的人员尚未参加该演示',
        alphabetical: '字母表',
        online: '在线',
        matching: '匹配',
        matchingAlgo: '匹配算法正在运行中。给我们一分钟，让我们帮您实现最佳匹配！',
        searchFilters: '搜索筛选器',
        applyFilterTooltip: '为了应用筛选器，请至少选择1个选项',
        chat: '聊天',
    },
    closedAuditoriumDialog: {
        title: '会议礼堂信息',
        contentPackage: '您的访问套餐无权访问任何会议礼堂。',
    },
    closedShowfloorDialog: {
        title: '展厅信息',
        contentPackage: '您的访问套餐无权访问展厅。',
        button: '好',
    },
    auditorium: {
        willStart: '下一场会议将在...',
        noAccess: '您的访问套餐无权访问此会议礼堂。',
        noVideos: '没有视频在此会议礼堂播放。',
        stageTab: '舞台',
        videoWallButton: '视频墙',
        resourcesButton: '资源',
        slotEndsTextFirst: '该活动将结束在',
        slotEndsTextSecondUpdated: '演示将按需提供',
        slotTitleWith: '与',
        allAuditoriums: '所有会议礼堂',
        onDemandButton: '按需',
        hide: '隐藏',
        leavePopupTitle: '离开舞台',
        leavePopupDescription: '离开会议礼堂后，您将从会话中移除',
        leaveSession: '离开舞台',
        cancelDescription: '离开后，您将不再是一个演讲嘉宾。是否继续？',
        settings: '设置',
        minimize: '最小化',
        microphone: '麦克风',
        camera: '摄像头',
        auditoriums: '会议礼堂',
    },
    auditoriumArchive: {
        title: '会议礼堂存档',
        titleEvent: '活动存档',
        with: '与',
        noVideosAvailable: '所有会话已完成',
    },
    videoPlayer: {
        videoNotPlayingError: '视频播放有问题？ 请重新加载。',
        videoEndedTextFirst: '视频已结束。 感谢您的收看！',
        videoEndedTextSecond: '视频墙开放供问答环节。',
        tooltipPlay: '播放',
        tooltipPause: '暂停',
        tooltipMinimize: '最小化',
        tooltipFullscreen: '全屏',
        tooltipMute: '静音',
        tooltipUnmute: '取消静音',
        videoStreamNotStarted: '很快上线。敬请关注！',
    },
    showfloor: {
        allExhibitorsButton: '所有参展商',
        featuredExhibitorsText: '特别参展商',
        boothLinksDialogTitle: '展位链接',
        boothFilesDialogTitle: '展位文件',
        boothVideoDialogTitle: '视频',
        mobileTabChat: '聊天',
        mobileTabPoster: '发贴者',
        mobileTabFiles: '文件',
        mobileTabLinks: '链接',
        mobileTabVideo: '视频',
        noExhibitorsTextFirst: '参展商正在规划他们的展位',
        noPresentersTextFirst: '演示者正在规划他们的展位',
        noExhibitorsTextSecond: '敬请关注',
        showShowfloorsButton: '所有展厅',
        showShowfloorsPosterButton: '所有发贴者/研究者',
        showShowfloorsExhibitorButton: '所有参展商',
        searchExhibitors: '搜索参展商',
        searchPosters: '按标题或作者姓名搜索',
        searchExhibitorsMultiple: '搜索该展厅中的参展商',
        searchPostersMultiple: '在该展厅中按标题或作者姓名搜索',
        showfloorsTitle: '展厅',
        collapseList: '折叠列表',
        seeFullList: '见完整列表',
        emptyExhibitorsResults: '搜索的展位不存在。但我们下次将邀请他们',
        emptyPostersResults: '搜索的发贴者/研究者不存在。但我们下次将邀请他们',
        posters: '发贴者',
        allPostersStudiesTitle: '所有发贴者/研究者',
        authors: '作者',
        titleNotAvailable: '没有标题',
        abstractNotAvailable: '没有摘要',
        authorsNotAvailable: '没有作者',
        openPosterFile: '打开发贴者文件',
        fileIsNotAvailable: '没有文件',
        posterBooth: {
            study: '发贴者/研究者',
            authors: '作者',
            highlights: '摘要',
            scrollInfoText: '向下滚动以阅读完整摘要',
        },
    },
    eventArchive: {
        title: '视频存档',
        searchText: '点击即可搜索',
        noArchivedEvents: '当前没有存档的活动。',
    },
    exhibitorDashboard: {
        event: '活动',
        title: '管理展位',
        visitorsButton: '展位参观者',
        goToBoothPreview: '展位预览',
        previewButton: '预览',
        tabStyle: '展位风格',
        tabLogo: '商标/商徽',
        tabStudyDetails: '发贴者/研究者',
        tabFiles: '文件',
        tabStudyFile: '文件',
        tabLinks: '链接',
        tabAvatars: '头像',
        tabStudyAuthors: '作者',
        tabVideo: '视频',
        shareText: '复制网址',
        theBoothUrlCopiedText: '展位 URL 已成功复制到您的剪贴板',
        optionDisabledText: '此展位目前无法使用此选项',
        boothStyleTab: {
            optionOne: '选项1-北欧风格',
            optionTwo: '选项2-现代风格',
            optionThree: '选项3-动态风格',
            optionFour: '选项4-自定义',
            optionScholarOne: '选项1-爱因斯坦',
            optionScholarTwo: '选项2-居里',
            optionScholarThree: '选项3-达·芬奇',
        },
        logoTab: {
            fileUploadButton: '点击这里上传',
            noFile: '没有选中任何文件',
        },
        posterStudyTab: {
            posterStudyTitle: '标题',
            studyTitleInputLabel: '发贴者/研究者标题',
            highlightAbstractTitle: '亮点/摘要',
            studyHighlightsInputLabel: '研究亮点/摘要',
            highlightAbstractError: '您已达到最大允许字符数！（500个字符）',
            authorNameLabel: '作者姓名',
            authorAffiliationLabel: '隶属机构',
        },
        filesTab: {
            title: '文档：',
            fileNameInput: '文档名称（所有参会者均可见）',
            chooseFileButton: '选择文件',
            noDocAdded: '没有文件添加',
            fileError: '请添加文件名',
        },
        fileStudyTab: {
            title: '上传PDF文件',
            description: '在您的展厅中上传可用的发贴者论文或演示文稿',
            uploadPdfInputLabel: '上传PDF文件',
            uploadNewPdfInputLabel: '上传新PDF文件',
            uploadedFile: '已上传',
            dragContainerTitle: '将文件拖放到此处即可',
            dragContainerNewTitle: '将新文件拖放到此处即可',
        },
        linksTab: {
            social: {
                title: '社交媒体的链接',
                subtitle: '（所有参与者可见）',
                facebookInput: '添加您的脸书链接',
                linkedinInput: '添加您的领英链接',
                twitterInput: '添加您的推特链接',
                googleScholarInput: '添加您的谷歌学术搜索链接',
                researchGateInput: '添加您的研究之门链接',
            },
            other: {
                linkInput: '添加新的链接',
                linkLabel: '插入新的链接标签',
            },
        },
        linksStudyTab: {
            authorProfiles: '作者个人资料',
            additionalLinks: '附加链接',
        },
        avatarsTab: {
            mainRepresentatives: {
                title: '展位代表',
                leftRepresentative: '左边代表',
                rightRepresentative: '右边代表',
                addDialog: {
                    title: '添加头像',
                    titleScholar: '添加作者',
                    editAvatarsTitle: '编辑头像',
                    editAuthorTitle: '编辑作者',
                    contentLine1: '从以下图片库中选择一个头像',
                    contentLine2: '上传您自己的个性化头像。',
                    contentLine3: '需要帮助？请联系',
                    assignButtonText: '指派',
                    assignText: '请指派活动参会者与上述代表关联',
                    assignErrorBooth: '用户已被添加为展位代表',
                    assignErrorAdditional: '用户已被添加为额外代表',
                    input: '按名字搜索',
                },
            },
            additionalRepresentatives: {
                title: '额外代表',
                subtitle: '（选择性，将与他们的个人资料图片一起显示）',
                addDialog: {
                    title: '选择其他代表',
                    titleScholar: '选择合著者',
                    assignText: '请开始搜索活动参与者以将其添加为代表。',
                    assignError: '用户已被添加为展位代表！',
                },
            },
            author: {
                title: '代表性作者',
                subtitle: '只能添加一名注册到活动的作者。您能添加1名作者。',
                mainRepresentative: '主要代表',
            },
            coAuthors: {
                title: '合著者',
                singularTitle: '合著者',
                subtitle: '只能添加注册到活动的合著者。您能添加高达4名合著者。',
            },
        },
        videoTab: {
            video: {
                title: '展位视频',
                subtitle: '（仅限YouTube视频链接）',
                youtubeOrVimeoOnly: '（仅限YouTube或Vimeo链接）',
                input: '输入YouTube URL链接',
                inputYoutubeOrVimeo: '输入YouTube或Vimeo视频URL链接',
            },
            image: {
                subtitle: '（如果您没有在上面添加视频链接，则图像会显示在您的展位中）',
            },
        },
    },
    boothDashboard: {
        event: '活动',
        title: '您的展位',
        manage: '管理',
        statistics: '数据',
        uniqueVisitors: '独特访客',
        currentOnlineVisitors: '当前在线访客',
        totalNumberOfVisits: '总访问次数',
        totalDocumentClicks: '文档总点击次数',
        totalLinkClicks: '总链接点击次数',
        totalRepresentativesClicks: '代表点击总数',
        totalVideoViews: '总视频浏览量',
        totalPostsCount: '帖子总数',
        totalLikesCount: '总赞数',
        totalCommentsCount: '评论总数',
        showUsers: '显示用户',
        dataType: '数据类型',
        value: '价值',
        posts: '帖子',
        likes: '喜欢',
        comments: '注释',
        topPostLikes: '热门帖子喜欢',
        topPostComments: '热门帖子评论',
        totalVotes: '总票数',
    },
    polls: {
        polls: '问卷投票',
        noPolls: '尚未添加问卷投票',
        pollProgress: '进行中的问卷投票',
        pollClosed: '投票关闭',
        pollVoted: '参与者投票',
    },
    onDemandVideos: {
        sectionTitle: '按需',
        noOnDemandVideos: '尚未添加点播视频',
    },
    meetings: {
        meeting: '会议',
        createDialog: {
            createTitle: '要求与…会面',
            inputDate: '日期',
            inputStart: '开始',
            inputEnd: '结束',
            inputTimezone: '时区',
            inputNote: '注意：同一人一次只能安排一次会议。',
            meetingError: '会议日期或时间无效。 请重新检查！',
            buttonDisabled: '您可以在开始对话后安排会议！',
        },
        banner: {
            pending: '会议要求',
            accepted: '会议定于',
            progress: '会议进行中',
            cancelTitle: '取消会议？',
            cancelDescription: '您确定要取消此会议吗？',
            startCall: '开始通话',
            joinCall: '加入',
            pendingOwner: '等待会议要求',
            videoCallStarted: '视频会议已经开始',
            videoCallStartedInfo: '视频会议最适合高达10人',
        },
        title: '您的会议',
        meetingsInfo: '可以通过聊天安排会议。 请与您要安排会议的用户开始聊天。',
    },
    socialMediaShare: {
        event: '活动',
        shareLabel: '分享',
        dialogTitleGeneral: '与您的网络分享',
        dialogTitlePost: '分享您的帖子',
        linkedinLabel: '领英',
        facebookLabel: '脸书',
        twitterLabel: '推特',
        emailLabel: '电子邮箱',
        clipboardLabel: '剪贴板',
        clipboardSnackbarMessage: '链接已复制到剪贴板',
        linkedinButtonTitle: '在领英上分享',
        facebookButtonTitle: '在脸书上分享',
        twitterButtonTitle: '在推特上分享',
        emailButtonTitle: '通过电子邮件分享',
        clipboardButtonTitle: '复制到剪贴板',
        imAttending: '我正参加',
        interestedClick: '是否有意参加？请点击此处：',
    },
    videoConferences: {
        fullScreen: '全屏显示',
        minimizeScreen: '最小化屏幕',
        startedVideoChat: '开始视频通话',
        timeAgo: '以前',
        videoCallStarted: '视频通话开始……',
    },
    sessions: {
        edit: '编辑',
        speakers: '演讲嘉宾',
        speakerSingular: '演讲嘉宾',
        moderators: '主持人',
        moderatorSingular: '主持人',
        video: '视频',
        startStream: '开始播放',
        stopStream: '停止播放',
        sessionChat: '会话聊天',
        speakerUrl: '演讲嘉宾链接',
        moderatorUrl: '主持人链接',
        copyUrl: '复制网址链接',
        noSpeakersJoined: '尚无演讲嘉宾加入',
        allFilter: '全部',
        waitingRoom: '等候室',
        isReady: '准备就绪',
        invitationAccepted: '接受邀请',
        requestPending: '未决请求',
        gettingReady: '准备就绪',
        inviteToSpeak: '邀请发言',
        fromAuditorium: '从会议礼堂',
        mic: '麦克风',
        screen: '屏幕',
        rec: '录音',
        sessionHasEnded: '会话已结束',
        sessionLinks: '会议链接',
        sessionSettings: '设置',
        noAvailableChat: '没有聊天。若要看见聊天，请将该会话分配到某一时隙。',
        startLiveSessionStreamTitle: '开始会话直播',
        startLiveSessionDescription: '当您开始直播时，会话录音自动启动。',
        stopLiveSessionStreamTitle: '停止会话直播',
        stopLiveSessionStreamDescription: '停止会话直播，录音也会自动停止。该会话直播无法重新开始。',
        thumbnailImage: '缩略图',
        uploadThumbnail: '上传缩略图',
        clickToUpload: '点击此处进行上传',
        requestMic: '请求麦克风',
        acceptInvitationTitle: '您已获得邀请',
        acceptInvitationSubTitle: '主持人已邀请您成为演讲嘉宾。您想说什么？',
        acceptInvitationButton: '接受邀请',
        requestPendingTitle: '未决请求……',
        requestPendingSubTitle: '请等待直到主持人让您进入直播',
        cancelRequest: '取消请求',
        approvedTitle: '请检查您的麦克风和视频，并点击“我已准备就绪”。',
        approvedSubTitle: '该会话正被记录。进入该会话，表示您同意对您的个人数据的处理。',
        connectedToTheBackstage: '您现在正在直播，已连接到后台。',
        removedFromTheSession: '您已从会话中踢出，请联系主持人。',
        imReady: '我已准备就绪',
        isTryingToRegister: '正试图注册为',
        hasLeftTheSession: '已离开会话',
        stopVideo: '停止视频',
        startVideo: '开始视频',
        disabledScreenSharing: '开始视频前，请关闭屏幕共享',
        disabledByModerator: '被主持人禁用',
        disabledUntilReady: '禁用直到准备就绪',
        stopShareScreen: '停止屏幕共享',
        shareScreen: '屏幕共享',
        disabledVideoSharing: '在共享您的屏幕前，请关闭视频',
        anotherUserIsScreenSharing: '别人正在共享屏幕',
        removeRoleDescription: '是否移除',
        declinedInvitation: '拒绝邀请为演讲嘉宾',
    },
    gdpr: {
        disclaimerTextFirst: '本文档通过人工智能翻译。若不一致，请参考英文版文档',
        disclaimerTextSecond: '此处',
        metaTitlePrivacyPolicy: '隐私政策',
        metaTitleCookiesPolicy: '信息记录程序的政策',
        metaTitleTerms: '条款和条件',
        updateCookieConsent: '更新对信息记录程序的同意',
        changeYourConsent: '变更您的同意',
        withdrawYourConsent: '撤消您的同意',
        withdrawSnackbarText: '成功撤消对信息记录程序的同意',
    },
    infoMessages: {
        imageExplain420x200: '推荐的图像比例1.8:1（面向景观的，比如420×200像素）',
        imageExplainTB230x320: '推荐的带透明背景的图像比例1:1.4（面向肖像的，比如230×320像素）',
        imageExplainTB230x875: '推荐的带透明背景的图像比例1:3.8（面向肖像的，比如230×875像素）',
    },
    matching: {
        matchingQuestionnaireTitle: '匹配调查问卷',
        matchingQuestionnaireIntro: '让我们帮助您连接到与您最相关的人！心有灵犀一点通，完成调查问卷得以开始',
        matchingQuestionnaireIntroSkip:
            '若跳过调查问卷，我们将无法在今天的活动中将您与其他与会人匹配。您以后始终能在“沟通交流”部分中完成调查问卷。可在“我的账户”中编辑所有答案。',
        doItLaterBtn: '以后再做',
        nextBtn: '下一步',
        backBtn: '后退',
        finishBtn: '完成',
        seeMatchesBtn: '见所有匹配',
        tryMatchingBtn: '试试智能匹配',
        optionalQuestion: '可选',
        requiredQuestion: '必答',
        singleSelect: '单选',
        multiSelect: '多选',
        mediumMatch: '中等匹配',
        highMatch: '高度匹配',
        matchLabel: '匹配',
        matchesLabel: '匹配',
        infoTooltipJump: '跳转到此处，可以看见您的匹配',
        infoTooltipClickToSee1: '点击',
        infoTooltipClickToSee2: '可以看见您应连接之人！',
        loadingAlgoText: '匹配算法正在运行中。给我们一分钟，让我们帮您实现最佳匹配！',
        matchingDetailsFormTitle: '智能匹配详细情况',
        tryMatchingBanner: '请进行有意义的连接，试用我们的智能匹配功能！',
        matchingBannerClosed: '您始终能在“匹配”下的“沟通交流”中填写智能匹配表',
        resultsTitle: '这里是您的最佳匹配',
        resultsNoteLabel: '注意：',
        resultsNoteText: '您能在“沟通交流”部分找到您的所有匹配。',
        resultsNoteMyAccount: '在“我的账户”菜单中可以编辑所有答案。',
        btwResultsResponsive: '顺便说一下，您能在“沟通交流”中找到您的所有匹配',
        noMoreMatches: '哦！我们此时无法为您找到更多匹配。当更多参与者已加入时，请再次核对。',
        noResultsTitle: '恭喜，您成功了！我们正在搜索您的最佳匹配！',
        noResults:
            '谢谢您试用智能匹配功能！我们正在搜索您的最佳匹配，一有更多参与者加入活动，我们就会为您进行配对。请检查“沟通交流”部分中的“匹配”选项卡',
        clickStartChat: '点击聊天开始对话',
        closeMatchingTitle: '关闭匹配表？',
        closeMatchingTextA: '如果您在完成之前关闭该表，我们将不会存储详细情况，您也不会得到智能匹配结果。',
        closeMatchingTextB: '您的个人资料将不是智能匹配过程的一部分，没有人将与您匹配。',
        matchingTooltip: '点击“匹配”可以看见您应连接之人！',
        myAccountMatchingSectionTitle: '智能匹配详细情况',
        matchingRequiredError: '您必须完成所有必答问题',
        rejectMatchingTitle: '拒绝匹配参与？',
        rejectMatchingText: '您的答案将被删除，您将不会与其他用户匹配。您能随时重新回答匹配调查问卷。',
        rejectMatchingBtn: '拒绝参与',
    },
    welcomeScreen: {
        goToEvent: '跳转到活动',
    },
    emailCodeVerification: {
        codeSent: '我们已将验证码发送到您的电子邮箱：',
        continueRegistration: '如需继续注册，',
        enterCodeBelow: '请输入以下验证码：',
        codeWillExpire: '该验证码到期时间为',
        codeExpired: '代码已到期',
        codeNotReceived: '未收到代码？您是否检查了垃圾箱？',
        resendTimer: '重发于：',
        resendLabel: '重发',
        resendCodeLabel: '重发代码',
        codeError: '输入的代码错误',
        codeErrorFix: '重新输入您的电子邮箱中的正确代码或重发新代码',
        congrats: '恭喜！',
        emailConfirmed: '您的电子邮箱已被确认：',
    },
    privacyPolicyUpdates: {
        learnMore: 'Learn more',
        lastUpdated: 'Last updated',
        publishedBy: 'Published by',
        ppuLinkLabel: 'Updated privacy policy',
        ppuEventLinkLabel: 'Updated event privacy policy',
        acceptAllButtonLabel: 'Accept all updates',
    },
    voting: {
        votingText: '表决',
        enableVoting: '启用投票',
        enableVotingDescription: '点击刷新图标以获得最准确的结果',
        displayVotingNumbersForExhibitors: '显示参展商和海报/研究的投票号码',
        displayVotingNumbersForExhibitorsDescription: '将显示在参展商仪表板中',
        displayVotingNumbersForParticipants: '显示参与者的投票号码',
        displayVotingNumbersForParticipantsDescription: '将为所有参与者显示',
        characterError80: '您已达到允许的最大字符数限制（80 个字符）',
        characterDescription: '文本最多可包含 80 个符号。文本将作为主标题出现在投票功能区上。使其对参与者有意义',
        deleteAllVotes: '删除所有投票',
        downloadVotingResults: '下载投票结果',
        voteText: '投票',
        votedText: '投票',
        votesText: '投票',
        participant: '参与者',
        participants: '参与者',
        deleteVotesTextFirst: '警告：',
        deleteVotesTextSecond: '这是不可逆的行动。',
        deleteVotesTextThird: '不要忘记在删除之前下载投票结果。',
        checkTheBoxToCastYourVote: '勾选方框进行投票',
        toVotePleaseEnableParticipantProfile: '要投票,请启用您的参与者资料',
    },
};

export default zh;
