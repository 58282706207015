import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import {preventDefaultDrag} from 'Utils/utils';

class BoothLinks extends React.Component {
    handleOpenLink = (link, label) => (e) => {
        this.props.onClickOnLink(link._id, label)
        window.open(link.link, '_blank', 'noopener,noreferrer');
    }

    boothLink = (link, label) => {
        return <Button
            variant={"text"}
            disableElevation={true}
            color={"primary"}
            key={`booth-link-${label}`}
            onClick={this.handleOpenLink(link, label)}
        >
            {label}
        </Button>
    }

    render() {
        const {translation} = this.props;
        return (
            <Dialog
                open={this.props.opened}
                onClose={this.props.closeDialog}
                aria-labelledby="form-dialog-title"
                className="booth-links-dialog-container booth-dialog"
            >
                <DialogContent className="booth-dialog-content">
                    <DialogTitle id="form-dialog-title">{translation?.showfloor.boothLinksDialogTitle}</DialogTitle>
                    <div onDragStart={preventDefaultDrag} className="content-container">
                        {this.props.socialLinks.map((link) => (
                            this.boothLink(link, link.platform)
                        ))}
                        {this.props.links.map((link) => (
                            this.boothLink(link, link.label)
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={this.props.closeDialog}>
                        {translation?.generalText.close}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BoothLinks);
