import React from 'react';
import {connect} from 'react-redux';
import '../../../../CSS/chats.scss';
import {getTimeAgoString, preventDefaultDrag} from '../../../../Utils/utils';

class PrivateChatListItem extends React.PureComponent {
    render() {
        const {chatData, translation, defaultTranslation, platformLanguage} = this.props;
        const timeAgoString = getTimeAgoString(chatData.chat.lastMessageAt, platformLanguage);
        const chat = this.props.chatData.chat;
        let privateChatPartnerData = null;
        if (this.props.userId === chat.user_1?._id) {
            privateChatPartnerData = chat.user_2;
        } else {
            privateChatPartnerData = chat.user_1;
        }

        let isOnline = this.props.onlineUsersObject[privateChatPartnerData?._id];

        let chatName = privateChatPartnerData?.first + ' ' + privateChatPartnerData?.last.charAt(0) + '.';
        let fullName = privateChatPartnerData?.first + ' ' + privateChatPartnerData?.last;

        // sometimes, in the response from the backend, the partner user is null
        // in this case we don't want to display "undefined undefined"
        // so we added this fallback
        if(privateChatPartnerData === null){
            chatName="D U";
            fullName="Deleted User"
        }
        const lastMessageText = chatData.chat.lastMessageText;
        const codedQuotationMark = '&#x27;';
        const videoCallMessage = `I${codedQuotationMark}ve set up a Video Meeting room. Please click here to join it.`;
        const renderLastMessage = lastMessageText === videoCallMessage ? translation?.videoConferences.videoCallStarted || defaultTranslation?.videoConferences.videoCallStarted : lastMessageText;

        return (
            <div
                className={'private-chat ' + (isOnline ? 'is-online' : 'is-offline')}
                color={chat._id === this.props.activeChatRoomId ? 'primary' : 'default'}
                id={chat._id}
                key={chat._id}
                onClick={this.props.handleOpenPrivateChat(chat._id)}
            >
                <div
                    className="avatar"
                    style={{
                        backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${privateChatPartnerData?.avatarSmall}')`,
                    }}
                >
                    {chatData.unread !== 0 ? (
                        <div
                            className={`online-button flex-center ${
                                chatData.unread.toString().length >= 3 ? 'fs9' : 'fs10'
                            }`}
                        >
                            <span>{chatData.unread.toString().length >= 3 ? '99+' : chatData.unread}</span>
                        </div>
                    ) : null}
                </div>
                <div onDragStart={preventDefaultDrag} className="chat-name">
                    <p onDragStart={preventDefaultDrag} className="private-chat-name ellipsis">
                        {chatName}
                    </p>
                    <p onDragStart={preventDefaultDrag} className="private-chat-name full-name ellipsis">
                        {fullName}
                    </p>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: renderLastMessage,
                        }}
                        className={`last-message ellipsis ${chatData.unread !== 0 ? 'bold' : ''}`}
                    />
                </div>
                <div>
                    <p onDragStart={preventDefaultDrag} className="time-ago">
                        {timeAgoString}
                    </p>
                </div>
                <div onDragStart={preventDefaultDrag} className="user-wrapper-separator" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.user.data?._id,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        onlineUsersObject: state.onlineUsers.onlineUsersObject,
        platformLanguage: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(PrivateChatListItem);
