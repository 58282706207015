import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {TextValidator} from 'react-material-ui-form-validator';

const RadioField = ({field, handleChange}) => {
    const handleRadioChange = (e) => {
        const {name, value} = e.target;
        handleChange(name, value);
    };

    return (
        <>
            <FormControl component="fieldset" fullWidth={true}>
                <FormLabel className="break-word" component="legend">
                    {field.label}
                </FormLabel>
                <div className="registration-radio-group">
                    <RadioGroup
                        aria-label={field.label}
                        name={field.name}
                        value={field.value}
                        onChange={handleRadioChange}
                    >
                        {field.options.map((option) => {
                            return (
                                <div className={`input-wrapper ${option.length > 25 ? 'show-tooltip' : ''}`}>
                                    <div className="tooltip">
                                        <div>
                                            <p>{option}</p>
                                        </div>
                                    </div>
                                    <FormControlLabel
                                        value={option}
                                        control={<Radio color="secondary" />}
                                        label={option}
                                        key={option}
                                    />
                                </div>
                            );
                        })}
                    </RadioGroup>
                </div>
            </FormControl>

            {/* This input is "hidden" but we need it to be able to display the error message. */}
            <TextValidator
                value={field.value}
                validators={field.validators}
                errorMessages={field.errorMessages}
                className={'hidden-input-and-only-display-error-message'}
            />
        </>
    );
};

export default RadioField;
