import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import UsersList from './VirtualisedNetworking/UsersList';
import SearchIcon from '@material-ui/icons/Search';
import Spinner from '../SmallLayoutComponents/Spinner';
import '../CSS/networking.scss';
import './NetworkingStyles.scss';
import colors from '../CSS/_variables.module.scss';
import {ReactComponent as EmptyResults} from '../Images/svg/search_results.svg';
import {ReactComponent as MatchingLoader} from '../Images/svg/matching-loader.svg';
import {ReactComponent as DeleteFilled} from '../Components/Icons/Svg/delete_filled.svg';
import {preventDefaultDrag, sortABC, sortFn} from '../Utils/utils';
import Tooltip from '@material-ui/core/Tooltip';
import NetworkFilters from '../Components/NetworkFilters/NetworkFIlters';
import axios from '../store/axios-instance';
import IconFilter from '../Components/Icons/Filter/Filter';
import groupBy from 'lodash/groupBy';
import flow from 'lodash/flow';
import values from 'lodash/values';
import {NetworkFilterTooltip} from '../Components/NetworkFilters/Components/NetworkFilterStyledComponents';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import cloneDeep from 'lodash/cloneDeep';
import {debounce} from 'lodash';
import {isMobile} from 'react-device-detect';

let matchesTooltipTimeout;

class Networking extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            sortedEventUsers: [],
            sortedBy: 'online',
            sortedByOnline: false,
            visualViewportHeight: window.innerHeight,
            displayMatchingTooltip: false,
            showNetworkFilters: false,
            showNetworkFilterButton: false,
            selectedNetworkFilterOptions: {},
            selectedFiltersCount: 0,
            networkFilters: [],
            selectedAllNetworkFilters: {},
            atLeastOneNetworFilterOptionSelected: {},
            isFilterVisited: false,
            isFilterActive: false,
            loadingFilters: false,
        };
        // throttle is used when the online users is constantly changing (from socket events)
        this.throttleSortEventUsers = throttle(this.sortEventUsers, 15000);
    }

    matchesRef = React.createRef();
    handleClickOutsideMatches = this.handleClickOutsideMatches.bind(this);

    componentDidMount() {
        /*     this.props.onGetUsers(this.props.eventId);
        console.log(this.props.eventId, 'calisti');
        this.throttleSortEventUsers();*/
        this._isMounted = true;
        const {userEventProfile, isLargeScreen} = this.props;
        this.sortEventUsers();
        window.visualViewport &&
            window.visualViewport.addEventListener('resize', (event) => {
                this.setState({visualViewportHeight: event.target.height});
            });
        document.addEventListener('mousedown', this.handleClickOutsideMatches);
        // if liveSession networking is active
        // then set sortedBy: all when mounting component
        if (this.props.showInviteToStageButton) this.handleSortBy('all')();
        // if matching is enabled and user entered NETWORKING tab but not clicked the
        // Matches filter yet, display tooltip after 3 seconds if displayMatchingTooltip
        // is not already true in localStorage
        if (this.state.sortedBy !== 'matching' && !this.handleGetMatchingTooltip()) this.startTooltipTimeout();
        this.setshowNetworkFilterButton();

        if (!isLargeScreen && this.props.seeMatches) {
            const matchingFormSeen = userEventProfile?.matchingFormSeen;
            if (matchingFormSeen) {
                this.handleSortBy('matching')();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {sortedBy} = this.state;
        const {userEventProfile, event, eventMatching, onRecalculateUserMatchingScore} = this.props;
        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        const matchingFormSeen = userEventProfile?.matchingFormSeen;

        if (
            !isEqual(prevProps.allEventUsers, this.props.allEventUsers) ||
            !isEqual(prevProps.user, this.props.user) ||
            !isEqual(prevProps.attendees, this.props.attendees) ||
            !isEqual(prevProps.user.receivedRequests, this.props.user.receivedRequests) ||
            !isEqual(prevProps.liveSession?.users, this.props.liveSession?.users) ||
            prevProps.onlineUsers.onlineUsersNumber !== this.props.onlineUsers.onlineUsersNumber
        ) {
            this.throttleSortEventUsers();
        }

        if (prevProps.loadingAttendees && !this.props.loadingAttendees) {
            this.sortEventUsers();
        }

        if (prevState.sortedBy !== 'matching' && sortedBy === 'matching') {
            const {eventId, onClearMatchesArray} = this.props;
            if (matchingFormSeen) {
                if (hasMatchingEnabled) onRecalculateUserMatchingScore(eventId, event.matchingForm);
            } else {
                // if matching is enabled, when entering NETWORKING > Matching tab recalculate matchings
                // if (hasMatchingEnabled) onRecalculateUserMatchingScore(eventId, eventMatching?._id);
                onClearMatchesArray();
            }
            // if Matches tooltip was not already hidden then hide for the current user / event
            // when visiting the Matches filter
            this.handleHideMatchingTooltip();
        }
        // hide Matches tooltip and clear timeout if user opens questionnaire Dialog
        // from banner while in NETWORKING online / abc tabs
        if (prevProps.event.matchingDialog !== event.matchingDialog && (sortedBy === 'online' || sortedBy === 'all')) {
            this.stopTooltipTimeout();
            this.setState({displayMatchingTooltip: false});
        }
        // when socket matching gets disabled and user is on matching tab
        // reset filter to sortBy online
        if (sortedBy === 'matching' && !hasMatchingEnabled) {
            this.handleSortBy('online')();
        }
        if (prevProps?.event?.matchingDialog && !event?.matchingDialog && !matchingFormSeen) {
            this.handleSortBy('online')();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        const {isLargeScreen} = this.props;
        window.visualViewport &&
            window.visualViewport.removeEventListener('resize', (event) => {
                this.setState({visualViewportHeight: event.target.height});
            });
        document.removeEventListener('mousedown', this.handleClickOutsideMatches);

        if (!isLargeScreen && this.props.seeMatches) {
            this.props.handleHideMatchesTab();
        }
        this.stopTooltipTimeout();
    }

    startTooltipTimeout = () => {
        matchesTooltipTimeout = setTimeout(() => {
            this.setState({displayMatchingTooltip: true});
        }, 3000);
    };

    stopTooltipTimeout() {
        clearTimeout(matchesTooltipTimeout);
    }

    handleClickOutsideMatches(e) {
        if (this.matchesRef && this.matchesRef.current && !this.matchesRef.current.contains(e.target)) {
            this.stopTooltipTimeout();
            this.setState({displayMatchingTooltip: false});
        }
    }

    // once tooltip was displayed append tooltipDisplayed: true to current localStorage
    // user object in order to not display it again for the current user / event
    handleGetMatchingTooltip = () => {
        const {eventSlug, user} = this.props;
        const matchingTooltipData = localStorage.getItem('matchingTooltipData');
        if (matchingTooltipData !== null) {
            const matchingTooltipDataArray = JSON.parse(matchingTooltipData);
            let data = matchingTooltipDataArray
                ?.filter((tooltipData) => tooltipData.event === eventSlug && tooltipData.userId === user?._id)
                ?.shift();
            if (data !== undefined && data['userId'] === user?._id && data['tooltipDisplayed'] !== undefined) {
                return true;
            }
            return false;
        }
        return false;
    };

    //adds the correct mongo custom filter id to user object
    addMongoIdToUser = (users, groupedFilters) => {
        users.forEach((usr) => {
            for (const fltGroup of groupedFilters) {
                fltGroup.forEach((flt) => {
                    for (const property in usr) {
                        if (Array.isArray(usr[property])) {
                            if (usr[property].includes(flt.value)) {
                                //checks if user object doesn't already contain the custom field id
                                if (!usr.hasOwnProperty(flt._id)) {
                                    usr[flt._id] = usr[property];
                                }
                            }
                        }
                    }
                });
            }
        });
    };

    handleHideMatchingTooltip = () => {
        this.stopTooltipTimeout();
        const {eventSlug, user} = this.props;
        let dataToStorage = [];
        const setData = {
            event: eventSlug,
            userId: user?._id,
            tooltipDisplayed: true,
        };
        const matchingTooltipData = localStorage.getItem('matchingTooltipData');
        if (matchingTooltipData !== null && JSON.parse(matchingTooltipData).length > 0) {
            const matchingTooltipDataArray = JSON.parse(matchingTooltipData);
            let spreadArray = matchingTooltipDataArray;
            spreadArray.push(setData);
            dataToStorage = spreadArray;
        } else {
            dataToStorage.push(setData);
        }
        localStorage.setItem('matchingTooltipData', JSON.stringify(dataToStorage));
        this.setState({displayMatchingTooltip: false});
    };

    filterUsers = (allEventUsers, networkFilters) => {
        const start = performance.now();
        let users = cloneDeep(allEventUsers).map((usr, usrIndex) => {
            usr.customFields.forEach((field) => {
                if (field.value) {
                    usr[field.customFieldId] = field.value;
                }
                if (field.values.length > 0) {
                    usr[field.customFieldId] = field.values;
                }
            });
            return usr;
        });
        // get checked options and map it to array
        const appliedFilters = Object.keys(networkFilters)
            .map((key) => networkFilters[key])
            .filter((v) => v.checked);

        // get only country options
        const appliedCountryFilters = appliedFilters.filter((f) => f.label === 'Country');

        // get only custom filter options
        const appliedCustomFilters = appliedFilters.filter((f) => f.label !== 'Country');

        // filter users by country
        if (appliedCountryFilters.length > 0) {
            users = users.filter((user) => appliedCountryFilters.some(({value}) => value === user.country));
        }

        const groupedFilters = flow(groupBy, values)(appliedCustomFilters, (x) => x.filterTitle);

        this.addMongoIdToUser(users, groupedFilters);

        for (let fltGroup of groupedFilters) {
            users = users.filter((usr) => {
                return fltGroup.some((flt) => {
                    if (Array.isArray(usr[flt._id])) {
                        return usr[flt._id].some((v) => v === flt.value);
                    }
                    return usr[flt._id] === flt.value;
                });
            });
        }

        const finalResult = users.filter((usr) => allEventUsers.find(({_id}) => usr._id === _id));
        const end = performance.now();
        // console.log(end-start, 'ms to filter users');
        // console.log(finalResult);
        return finalResult;
    };

    // once every 5s
    sortEventUsers = () => {
        const {sortedBy, selectedNetworkFilterOptions} = this.state;
        const {user, attendees, allEventUsers} = this.props;

        const start = performance.now();
        let filteredEventUsers = [];

        if (attendees) {
            // we can get in props an array of usersId that should only be displayed (for ex in auditorium we only need to show the attendees)
            // TOO SLOW, I refactored to use a "for loop" and it works a lot faster
            const startFilteringAttendees = performance.now();

            // Version 1 - slow
            // filteredEventUsers = allEventUsers.filter((eventUser) => attendees.includes(eventUser.user));

            // Version 2 - slow
            // const allEventUsersLength = allEventUsers.length;
            // for (let i = 0; i < allEventUsersLength; i++) {
            //     if(attendees.includes(allEventUsers[i].user)){
            //         filteredEventUsers.push(allEventUsers[i]);
            //     }
            // }

            // Version 3 - super fast
            const attendeesLength = attendees.length;
            for (let i = 0; i < attendeesLength; i++) {
                if (this.props.allEventUsersObject[attendees[i]]) {
                    filteredEventUsers.push(this.props.allEventUsersObject[attendees[i]]);
                }
            }

            // because we made a "for loop" on "attendees"
            // and "attendees" is just an array of userIds
            // that are not sorted alphabetically
            // we need to manually sort them here
            // because we don't use the "allEventUsers" and go through each of them
            // but we actually go through the attendees and use "allEventUsersObject"
            filteredEventUsers.sort((a, b) => {
                return sortABC(a, b);
            });

            const endFilteringAttendees = performance.now();
            // console.log(endFilteringAttendees-startFilteringAttendees, 'ms to filter by attendees', attendees.length);
        } else {
            filteredEventUsers = allEventUsers;
        }

        if (Object.keys(selectedNetworkFilterOptions).length > 0) {
            filteredEventUsers = this.filterUsers(filteredEventUsers, selectedNetworkFilterOptions);
        }

        if (this.state.search.toLowerCase().length) {
            filteredEventUsers = filteredEventUsers.filter((eventUser) =>
                this.checkIfParticipantIsInSearchResults(eventUser)
            );
        }

        // extract user, score and matchingLevel from the recalculated matching results
        const matchesArray = user?.matchingUsers?.map((e) => {
            return {
                user: e.user,
                score: e.score,
                matchingLevel: e.matchingLevel,
            };
        });
        // merge the eventUsersInSearchResults with the matchesArray
        // and add the score and matchingLevel property if the userId's match
        const mergeArrays = (arr1 = [], arr2 = []) => {
            let res = [];
            res = arr1.map((array1) => {
                const index = arr2.findIndex((el) => el['user'] === array1['user']);
                const {score, matchingLevel} = index !== -1 ? arr2[index] : {};
                return {
                    ...array1,
                    score,
                    matchingLevel,
                };
            });
            return res;
        };
        // assign merged array results
        let eventUsersInSearchResults = mergeArrays(filteredEventUsers, matchesArray);

        if (sortedBy === 'online') {
            let usersOnline = eventUsersInSearchResults.filter((user) => this.checkIfIsOnline(user));
            let usersOffline = eventUsersInSearchResults.filter((user) => !this.checkIfIsOnline(user));
            eventUsersInSearchResults = usersOnline.concat(usersOffline);
        }

        if (sortedBy === 'waitingRoom') {
            const {liveSession} = this.props;
            let filteredUsers = eventUsersInSearchResults.filter((user) =>
                liveSession?.users.some((sessionUser) => {
                    return sessionUser.user === user.user && sessionUser.isGuest && sessionUser.status === 'new';
                })
            );
            eventUsersInSearchResults = filteredUsers;
        }

        if (sortedBy === 'matching') {
            const {user} = this.props;
            let usersOnline = eventUsersInSearchResults.filter((user) => this.checkIfIsOnline(user));
            let usersOffline = eventUsersInSearchResults.filter((user) => !this.checkIfIsOnline(user));
            eventUsersInSearchResults = usersOnline.concat(usersOffline).filter((users) => users.user !== user._id);
            // apply matching filter sort:
            // first by highest to lowest score then alphabetically
            eventUsersInSearchResults = eventUsersInSearchResults.sort((a, b) => {
                return sortFn(Math.floor(b.score), Math.floor(a.score)) || sortABC(a, b);
            });
        }
        const end = performance.now();

        // console.log(end-start, 'ms to sort + filter users');
        if (this._isMounted) {
            this.setState({sortedEventUsers: eventUsersInSearchResults});
        }
    };

    handleSortBy = (sortMethod) => () => {
        this.setState(
            {
                sortedBy: sortMethod,
            },
            () => {
                this.sortEventUsers();
            }
        );
    };

    checkIfParticipantIsInSearchResults = (eventUser) => {
        const fullName = `${eventUser.first} ${eventUser.last} ${eventUser.first} ${eventUser.last}`;
        return (
            fullName.toLowerCase().includes(this.state.search.toLowerCase()) ||
            (eventUser.company && eventUser.company.toLowerCase().includes(this.state.search.toLowerCase())) ||
            (eventUser.title && eventUser.title.toLowerCase().includes(this.state.search.toLowerCase()))
        );
    };

    checkIfIsContact = (eventUser) => {
        let isContact = this.props.user.contacts.find((contact) => contact.user._id === eventUser.user);
        return isContact !== undefined;
    };

    checkIfIsFriendConnectionSent = (eventUser) => {
        let isFriendConnectionSent = this.props.user.sentRequests.find(
            (request) =>
                request.user === eventUser.user && (request.status === 'pending' || request.status === 'rejected')
        );
        return isFriendConnectionSent !== undefined;
    };

    checkIfIsFriendConnectionReceived = (eventUser) => {
        let isFriendConnectionReceived = this.props.user.receivedRequests.find(
            (request) =>
                request.user === eventUser.user && (request.status === 'pending' || request.status === 'rejected')
        );
        return isFriendConnectionReceived !== undefined ? isFriendConnectionReceived._id : false;
    };

    checkIfIsOnline = (eventUser) => {
        const {onlineUsersObject} = this.props.onlineUsers;
        return onlineUsersObject[eventUser.user];
    };

    handleInputFocus = () => {
        let intViewportWidth = window.innerWidth;
        const eventPage = document.getElementsByClassName('event-page');
        if (intViewportWidth > 1080 && eventPage && isMobile) {
            eventPage[0].classList.add('hide');
        }
    };

    handleInputBlur = () => {
        // const eventPage = document.getElementsByClassName('event-page hide');
        // if (eventPage.length !== 0) {
        //     eventPage[0].classList.remove('hide');
        // }
    };

    handleKeyDown = (e) => {
        const {search} = this.state;
        if (e.key === 'Enter' && search.trim()) {
            e.target.blur();
        }
    };

    setshowNetworkFilterButton = async () => {
        const networkFilters = await this.fetchNetworkFilters();
        if (networkFilters?.length > 0) {
            this.setState({showNetworkFilterButton: true});
        }
    };

    fetchNetworkFilters = async () => {
        const {eventId} = this.props;
        const {data: networkFilters} = await axios({
            method: 'get',
            url: `/event/${eventId}/networking-filters`,
        });
        return networkFilters;
    };

    handleShowNetworkFilters = async () => {
        this.setState({loadingFilters: true});
        const networkFilters = await this.fetchNetworkFilters();

        let mappedFilters = networkFilters.map((flt) => ({
            ...flt,
            values: flt.values.map((v) => ({value: v, checked: false})),
        }));

        this.setState({
            showNetworkFilters: true,
            networkFilters: mappedFilters,
            isFilterActive: true,
            isFilterVisited: true,
            loadingFilters: false,
        });
    };

    handleCloseNetworkFilters = (selectedFiltersCount) => {
        this.setState({isFilterActive: false});
        let isFilterVisited = true;
        if (selectedFiltersCount === 0) {
            this.setState({isFilterVisited: false});
            return this.handleResetNetworkFilters();
        }
        return this.setState({showNetworkFilters: false, isFilterVisited});
    };

    handleOptionSelected = (selectedNetworkFilterOptions) => {
        this.setState({selectedNetworkFilterOptions});
    };

    handleResetNetworkFilters = () => {
        this.setState(
            {
                selectedNetworkFilterOptions: {},
                selectedFiltersCount: 0,
                selectedAllNetworkFilters: {},
                atLeastOneNetworFilterOptionSelected: {},
                showNetworkFilters: false,
                isFilterActive: false,
                isFilterVisited: false,
            },
            () => {
                this.sortEventUsers();
            }
        );
    };

    handleApplyNetworkFilters = (
        networkFilters,
        selectedNetworkFilterOptions,
        selectedFiltersCount,
        selectedAllOptions,
        atLeastOneNetworFilterOptionSelected
    ) => {
        this.setState(
            {
                networkFilters,
                selectedNetworkFilterOptions,
                selectedFiltersCount,
                selectedAllNetworkFilters: selectedAllOptions,
                atLeastOneNetworFilterOptionSelected,
            },
            () => {
                this.handleCloseNetworkFilters(selectedFiltersCount);
                this.sortEventUsers();
            }
        );
    };

    getEmptyNetworkingMessage = () => {
        const {translation, defaultTranslation} = this.props;
        const {isAuditoriumNetworking} = this.props;
        let message = '';
        if (isAuditoriumNetworking) {
            message =
                translation?.networking.emptyAuditoriumNetworkingSearchResults ||
                defaultTranslation?.networking.emptyAuditoriumNetworkingSearchResults;
        } else {
            message =
                translation?.networking.emptyNetworkingSearchResults ||
                defaultTranslation?.networking.emptyNetworkingSearchResults;
        }
        return message;
    };

    handleEnterKey = (tab) => (e) => {
        if (e.key === 'Enter') {
            if (tab === 'alphabetical') return this.handleSortBy('all')();
            if (tab === 'online') return this.handleSortBy('online')();
            if (tab === 'waitingRoom') return this.handleSortBy('waitingRoom')();
            if (tab === 'matching') return this.handleMatchesFilterDialog();
        }
    };

    handleSearchChange = (e) => {
        const {value} = e.target;
        this.setState({search: value}, () => this.debouncedSortUsers());
    };

    handleSearchDelete = () => {
        this.setState({search: ''}, () => this.debouncedSortUsers());
    };

    debouncedSortUsers = debounce(() => {
        this.sortEventUsers();
    }, 500);

    handleMatchesFilterDialog = () => {
        const {userEventProfile, openMatchingDialog} = this.props;
        const matchingFormSeen = userEventProfile?.matchingFormSeen;

        if (matchingFormSeen) {
            this.handleSortBy('matching')();
        } else {
            openMatchingDialog();
            this.handleSortBy('matching')();
        }
    };
    render() {
        const {sortedEventUsers, sortedBy, visualViewportHeight, displayMatchingTooltip, loadingFilters} = this.state;
        const {
            event,
            eventId,
            user,
            eventRoles,
            resourcesAccess,
            isRtlLanguage,
            defaultTranslation,
            loadingAttendees,
            translation,
            eventUsersLoading,
            showInviteToStageButton,
            liveSessionGuestUsers,
            loadingGetMatches,
            languages,
        } = this.props;

        let restrictedNetwork = false;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess && !resourcesAccess.networking) {
            restrictedNetwork = true;
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess &&
            !resourcesAccess.networking
        ) {
            restrictedNetwork = true;
        }

        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        // check for users w/ participant / exhibitor profile [those available in networking]
        // will have access to engage in matching
        const userRoles = user?.eventRoles?.filter((role) => role.event._id === eventId)?.shift()?.roles;
        const hasParticipantExhibitorProfile =
            userRoles.indexOf('participant') >= 0 || userRoles.indexOf('exhibitor') >= 0;
        const {isFilterVisited, isFilterActive, search} = this.state;
        const sortContainer = () => {
            return (
                <div
                    onDragStart={preventDefaultDrag}
                    className={`sort-container mobile-helper ${this.state.showNetworkFilters ? 'is-filtering' : ''}`}
                >
                    {loadingFilters && <Spinner custom={'wall-spinner'} />}
                    {!showInviteToStageButton && (
                        <span
                            tabIndex="0"
                            onClick={this.handleSortBy('online')}
                            onKeyDown={this.handleEnterKey('online')}
                            className={'button ' + (sortedBy === 'online' ? 'active' : '')}
                        >
                            {translation?.networking?.online || defaultTranslation?.networking?.online}
                        </span>
                    )}

                    <span
                        tabIndex="0"
                        onClick={this.handleSortBy('all')}
                        onKeyDown={this.handleEnterKey('alphabetical')}
                        className={'button ' + (sortedBy === 'all' ? 'active' : '')}
                    >
                        {showInviteToStageButton ? (
                            <>{translation?.sessions.allFilter || defaultTranslation?.sessions.allFilter}</>
                        ) : (
                            <>{translation?.networking?.alphabetical || defaultTranslation?.networking?.alphabetical}</>
                        )}
                    </span>

                    {hasMatchingEnabled && hasParticipantExhibitorProfile && (
                        <Tooltip
                            arrow
                            id="matching-tooltip"
                            placement="bottom-end"
                            open
                            title={
                                displayMatchingTooltip
                                    ? translation?.matching?.matchingTooltip ||
                                      defaultTranslation?.matching?.matchingTooltip
                                    : ''
                            }
                        >
                            <span
                                ref={this.matchesRef}
                                tabIndex="0"
                                onClick={this.handleMatchesFilterDialog}
                                onKeyDown={this.handleEnterKey('matching')}
                                className={`button ${sortedBy === 'matching' ? 'active' : ''} ${
                                    displayMatchingTooltip ? 'matching-tooltip-border' : ''
                                }`}
                            >
                                {translation?.networking?.matching || defaultTranslation?.networking?.matching}
                            </span>
                        </Tooltip>
                    )}

                    {showInviteToStageButton && (
                        <span
                            tabIndex="0"
                            onClick={this.handleSortBy('waitingRoom')}
                            onKeyDown={this.handleEnterKey('waitingRoom')}
                            className={'button ' + (sortedBy === 'waitingRoom' ? 'active' : '')}
                        >
                            {translation?.sessions?.waitingRoom || defaultTranslation?.sessions?.waitingRoom}
                            {liveSessionGuestUsers?.length > 0 && (
                                <span className="users-number">{liveSessionGuestUsers?.length}</span>
                            )}
                        </span>
                    )}
                </div>
            );
        };
        const searchUserContainer = () => {
            const {showNetworkFilterButton} = this.state;
            return (
                <div
                    onDragStart={preventDefaultDrag}
                    tabIndex={1}
                    className={`search-user-container networking-helper ${isFilterActive && 'add-helper'} ${
                        showNetworkFilterButton ? '' : 'no-filter-button'
                    }`}
                >
                    <div className="search-container-helper">
                        <div className="search-input-container">
                            <input
                                id="standard-search"
                                placeholder={translation?.networking.searchInput}
                                type="search"
                                value={this.state.search}
                                onChange={this.handleSearchChange}
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputBlur}
                                onKeyDown={this.handleKeyDown}
                            />
                            <DeleteFilled
                                id="filled-cancel"
                                className={`filled-cancel ${search.length > 0 ? 'd-flex' : ''}`}
                                onClick={this.handleSearchDelete}
                            />
                        </div>

                        {!this.state.search.length && (
                            <div
                                tabIndex={1}
                                className={`${this.state.showNetworkFilterButton ? 'search-icon-container' : ''} ${
                                    languages.platformLanguage === 'ar' ? 'ar-helper' : ''
                                }`}
                            >
                                <SearchIcon style={{color: '#777081'}} />
                            </div>
                        )}
                    </div>
                    {showNetworkFilterButton && (
                        <div className="search-filters-icon-container">
                            <NetworkFilterTooltip
                                placement="top-end"
                                arrow
                                leaveDelay={this.props.isLargeScreen ? null : 2000}
                                title={
                                    this.props.isLargeScreen
                                        ? translation?.networking?.searchFilters ||
                                          defaultTranslation?.networking?.searchFilters
                                        : ''
                                }
                            >
                                <span
                                    tabIndex="0"
                                    onClick={this.handleShowNetworkFilters}
                                    onKeyDown={this.handleShowNetworkFilters}
                                    className={`cursor-pointer network-filters-show-btn ${
                                        isFilterVisited && 'filters-active'
                                    }`}
                                >
                                    <IconFilter fill={colors.greyDark} />
                                    {this.state.selectedFiltersCount > 0 && (
                                        <span className="selected-filters-count">
                                            {this.state.selectedFiltersCount}
                                        </span>
                                    )}
                                </span>
                            </NetworkFilterTooltip>
                        </div>
                    )}
                </div>
            );
        };

        const sortComponents = () => {
            if (innerWidth <= 1080) {
                return (
                    <>
                        {searchUserContainer()}
                        {sortContainer()}
                    </>
                );
            } else {
                return (
                    <>
                        {sortContainer()}
                        {searchUserContainer()}
                    </>
                );
            }
        };
        const {innerWidth} = window;

        return (
            <div onDragStart={preventDefaultDrag} className="networking-container  wall-container">
                <div onDragStart={preventDefaultDrag} className="participants-page">
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`${
                            sortedEventUsers?.length === 0
                                ? 'no-results network-filters-wrapper'
                                : 'network-filters-wrapper'
                        }`}
                    >
                        {this.state.showNetworkFilters && (
                            <NetworkFilters
                                show={this.state.showNetworkFilters}
                                filters={this.state.networkFilters}
                                selectedOptions={this.state.selectedNetworkFilterOptions}
                                selectedAllNetworkFilters={this.state.selectedAllNetworkFilters}
                                atLeastOneNetworFilterOptionSelected={this.state.atLeastOneNetworFilterOptionSelected}
                                onApplyFilters={this.handleApplyNetworkFilters}
                                onResetFilters={this.handleResetNetworkFilters}
                                onCloseFilters={this.handleCloseNetworkFilters}
                                onOptionSelected={this.handleOptionSelected}
                                platformLanguage={languages.platformLanguage}
                            />
                        )}
                        {sortComponents()}
                        {sortedBy === 'matching' ? (
                            loadingGetMatches || event?.matchingDialog ? (
                                <div onDragStart={preventDefaultDrag} className="matching-spinner">
                                    <MatchingLoader />
                                    <p>
                                        {translation?.networking?.matchingAlgo ||
                                            defaultTranslation?.networking?.matchingAlgo}
                                    </p>
                                </div>
                            ) : sortedEventUsers?.length > 0 ? (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="custom-scroll-container networking-wrapper matching-container"
                                >
                                    <UsersList
                                        sortedByMatching
                                        eventUsers={sortedEventUsers}
                                        isRtlLanguage={isRtlLanguage}
                                        restrictedNetwork={restrictedNetwork}
                                        showInviteToStageButton={showInviteToStageButton}
                                    />
                                </div>
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="empty-results">
                                    <div
                                        className={`empty-artwork ${
                                            navigator.userAgent.match(/iPhone/i) === null &&
                                            visualViewportHeight !== window.innerHeight &&
                                            window.innerWidth <= 1024
                                                ? 'move-artwork'
                                                : ''
                                        }`}
                                    >
                                        <EmptyResults fill={colors.primary} />
                                        <p>{this.getEmptyNetworkingMessage()}</p>
                                    </div>
                                </div>
                            )
                        ) : eventUsersLoading || loadingAttendees ? (
                            <div onDragStart={preventDefaultDrag} className="custom-scroll-container spinner-wrapper">
                                <Spinner />
                            </div>
                        ) : sortedEventUsers?.length > 0 ? (
                            <div
                                onDragStart={preventDefaultDrag}
                                className="custom-scroll-container networking-wrapper"
                            >
                                <UsersList
                                    eventUsers={sortedEventUsers}
                                    restrictedNetwork={restrictedNetwork}
                                    isRtlLanguage={isRtlLanguage}
                                    sortedByOnline={sortedBy === 'online'}
                                    sortedByWaitingRoom={sortedBy === 'waitingRoom'}
                                    showInviteToStageButton={showInviteToStageButton}
                                />
                            </div>
                        ) : (
                            <div onDragStart={preventDefaultDrag} className="empty-results">
                                <div
                                    className={`empty-artwork ${
                                        navigator.userAgent.match(/iPhone/i) === null &&
                                        visualViewportHeight !== window.innerHeight &&
                                        window.innerWidth <= 1024
                                            ? 'move-artwork'
                                            : ''
                                    }`}
                                >
                                    <EmptyResults fill={colors.primary} />
                                    <p>{this.getEmptyNetworkingMessage()}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        allEventUsers: state.eventUsers.allEventUsers,
        allEventUsersObject: state.eventUsers.allEventUsersObject,
        eventUsersLoading: state.eventUsers.loading || state.onlineUsers.loading,
        onlineUsers: state.onlineUsers,
        eventSlug: state.event.eventSlug,
        user: state.user.data,
        userEventProfile: state.userEventProfile.data,
        eventMatching: state.organizer.eventMatching,
        loadingGetMatches: state.user.loadingGetMatches,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        languages: state.languages,
        isRtlLanguage: state.languages.isRtlLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        liveSession: state.liveSession.liveSession,
        isLargeScreen: state.layout.isLargeScreen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClearMatchesArray: () => dispatch(actions.clearMatchesArray()),
        onRecalculateUserMatchingScore: (eventId, questionnaireId) =>
            dispatch(actions.recalculateUserMatchingScore(eventId, questionnaireId)),
        /*
        onGetUsers: (eventId) => dispatch(actions.getUsers(eventId)),
*/
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Networking);
