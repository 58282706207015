import React, {useEffect, useRef} from 'react';
import DayItem from './DayItem';

const ProgramDays = ({activeDays, selectedDay: selectedDayFromProgram, handleNavigateToDay, layoutType}) => {
    const selectedDay = layoutType === 'calendar' ? selectedDayFromProgram || activeDays[0] : selectedDayFromProgram;
    const daysNavigationListRef = useRef();
    useEffect(() => {
        if (selectedDay) {
            const activeDayElement = document.querySelector(
                '.program-dropdown-wrapper .program-details-container .days-list .day-container.active'
            );
            activeDayElement?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [selectedDay]);

    const checkIsMonthDifferent = (dayIndex) => {
        if (dayIndex === 0) {
            return true;
        }
        const currentDay = new Date(activeDays[dayIndex]);
        const previousDay = activeDays[dayIndex - 1];
        if (
            currentDay.getMonth() !== new Date(previousDay).getMonth() ||
            currentDay.getFullYear() !== new Date(previousDay).getFullYear()
        ) {
            return true;
        }
    };

    const checkIsLastDayInMonth = (dayIndex) => {
        // if is last day in array, for sure is last day in month
        if (dayIndex === activeDays.length - 1) {
            return true;
        }
        const currentDay = new Date(activeDays[dayIndex]);
        const nextDay = activeDays[dayIndex + 1];
        if (currentDay.getMonth() < new Date(nextDay).getMonth()) {
            return true;
        }
    };

    const checkIsNewYear = (dayIndex) => {
        if (dayIndex === 0) {
            return true;
        }
        const currentDay = new Date(activeDays[dayIndex]);
        const previousDay = activeDays[dayIndex - 1];
        if (currentDay.getFullYear() !== new Date(previousDay).getFullYear()) {
            return true;
        }
    };

    const navigateToDay = (day) => (e) => {
        handleNavigateToDay(day);
    };

    return (
        <div className={'days-list scroll-left-container'} ref={daysNavigationListRef}>
            {activeDays.map((day, dayIndex) => {
                const isFirstDayInMonth = checkIsMonthDifferent(dayIndex);
                const isLastDayInMonth = checkIsLastDayInMonth(dayIndex);
                const isNewYear = checkIsNewYear(dayIndex);
                return (
                    <DayItem
                        key={day}
                        day={day}
                        isFirstDayInMonth={isFirstDayInMonth}
                        isLastDayInMonth={isLastDayInMonth}
                        isNewYear={isNewYear}
                        selectedDay={selectedDay}
                        navigateToDay={navigateToDay}
                        daysNavigationListRef={daysNavigationListRef}
                    />
                );
            })}
        </div>
    );
};

export default ProgramDays;
