import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import Pagination from '../Pagination';
import MasterAdminNavigation from '../MasterAdminNavigation';
import AdminAccordionNavigation from '../AdminAccordionNavigation';
import '../../../CSS/adminDashboard.scss';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import SortMenuEvents from './SortMenuEvents';
import {preventDefaultDrag} from '../../../Utils/utils';
import Button from '@material-ui/core/Button';

class AllEvents extends React.Component {
    state = {
        currentPage: 1,
        tabsList: [
            {name: 'organizersList', label: 'Organizers list', link: '/dashboard/organizers'},
            {name: 'statistics', label: 'Statistics', link: '/dashboard/organizers/statistics'},
            {name: 'allEvents', label: 'All events', link: '/dashboard/organizers/all-events'},
            {name: 'eventsToday', label: 'Events today', link: '/dashboard/organizers/events-today'},
        ],
        selectedTab: 'allEvents',
        perPage: 20,
        sortBy: null,
        sortOrder: null,
        searchValue: '',
    };

    componentDidMount() {
        this.props.onSetAdminMenu();
        this.setPageURL();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let currentPage = window.location.pathname.split('/')[5];
        if (currentPage && prevState.currentPage !== currentPage) {
            this.setPageURL();
        }
    }

    setPageURL = () => {
        const {perPage, sortBy, sortOrder, searchValue} = this.state;
        const urlString = window.location.pathname.split('/');
        const page = urlString[5];
        this.setState(
            {
                currentPage: page ? page : 1,
            },
            () => {
                this.props.onGetAllEvents(page || 1, perPage, sortBy, sortOrder, searchValue);
            }
        );
    };

    refreshAllEventsData = () => {
        const {currentPage, perPage, sortBy, sortOrder, searchValue} = this.state;
        this.props.onGetAllEvents(currentPage || 1, perPage, sortBy, sortOrder, searchValue);
    };

    returnAllEventsList = () => {
        const {allEvents} = this.props;
        return allEvents?.events?.map((event) => {
            return (
                <div onDragStart={(e) => e.preventDefault()} className="single-organizer-container" key={event._id}>
                    <div onDragStart={(e) => e.preventDefault()} className="row single-organizer-row">
                        <div onDragStart={(e) => e.preventDefault()} className="event-name-header ellipsis">
                            <Link to={{pathname: `/dashboard/organizers/events/${event._id}`}}>{`${event.name}`}</Link>
                        </div>
                        {/*<div onDragStart={(e) => e.preventDefault()} className="event-name-header">*/}
                        {/*    {event.name}*/}
                        {/*</div>*/}
                        <div onDragStart={(e) => e.preventDefault()} className="event-date-header ellipsis">
                            {event.eventStartDate}
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-date-header ellipsis">
                            {event.eventEndDate}
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-organizer-header ellipsis">
                            {event.owner.first + ' ' + event.owner.last}
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-organizer-header ellipsis">
                            <Link to={{pathname: `/event/${event.slug}`}} target="_blank" rel="noopener noreferrer">
                                {`/${event.slug}`}
                            </Link>
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-no-participants-header ellipsis">
                            {event.participantsCount}
                        </div>
                        <div onDragStart={(e) => e.preventDefault()} className="event-no-exhibitors-header ellipsis">
                            {event?.exhibitorsCount}
                        </div>
                    </div>
                </div>
            );
        });
    };

    getPreviousPage = () => {
        const {currentPage} = this.state;
        if (parseInt(currentPage) === 1) {
            return '';
        }
        if (parseInt(currentPage) === 2) {
            return '';
        }
        return `/page/${parseInt(currentPage) - 1}`;
    };

    getNextPage = () => {
        const {currentPage} = this.state;
        const {allEvents} = this.props;
        if (parseInt(currentPage) === parseInt(allEvents?.totalPageCount)) {
            return '';
        }
        return `/page/${parseInt(currentPage) + 1}`;
    };

    setInitialPage = () => {
        let currentPage = window.location.pathname.split('/')[4];
        if (!currentPage) {
            this.setPageURL();
        }
    };

    sortEvents = (sortBy, order) => {
        const {currentPage, perPage, searchValue} = this.state;
        this.setState({
            sortBy: sortBy,
            sortOrder: order,
        });
        this.props.onGetAllEvents(currentPage || 1, perPage, sortBy, order, searchValue);
    };

    onSearchChange = (e) => {
        this.setState({
            searchValue: e.target.value,
        });
    };

    resetSearchInput = () => {
        const {perPage, sortBy, sortOrder} = this.state;
        this.setState(
            {
                searchValue: '',
                currentPage: 1,
            },
            () => {
                this.props.onGetAllEvents(1, perPage, sortBy, sortOrder, null);
                this.props.history.push('/dashboard/organizers/all-events');
            }
        );
    };

    getSearchedEvents = () => {
        const {perPage, sortBy, sortOrder, searchValue} = this.state;
        let reCreateTheText = '';
        reCreateTheText = searchValue.replaceAll('/', '');
        let encodedSearchValue = encodeURIComponent(reCreateTheText);
        this.setState(
            {
                currentPage: 1,
            },
            () => {
                this.props.onGetAllEvents(1, perPage, sortBy, sortOrder, encodedSearchValue);
                this.props.history.push('/dashboard/organizers/all-events');
            }
        );
    };

    setDownloadDataExcel = () => {
        const {sortBy, sortOrder, searchValue} = this.state;
        this.props.onSetDownloadDataExcel(sortBy, sortOrder, searchValue).then(() => this.parseExcel());
    };

    parseExcel = () => {
        const {downloadData} = this.props;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(downloadData);
        link.download = 'All-Events.xlsx';
        link.click();
    };

    render() {
        const {tabsList, selectedTab, currentPage, searchValue} = this.state;
        const {loading, allEvents} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="superadmin-dashboard">
                <MasterAdminNavigation />
                <section className="top-section">
                    <div onDragStart={preventDefaultDrag} className="admin-dashboard">
                        <div className="admin-sidebar">
                            <AdminAccordionNavigation tabsList={tabsList} selectedTab={selectedTab} />
                        </div>
                        <div className={`admin-content-container ${loading ? 'loading-section' : ''}`}>
                            <div className="all-events-container">
                                <div className="admin-download-header">
                                    <h1>All Events</h1>
                                    <div onDragStart={preventDefaultDrag} className="d-flex">
                                        {allEvents?.events?.length ? (
                                            <Pagination
                                                currentPage={currentPage}
                                                previousURL={this.getPreviousPage()}
                                                nextURL={this.getNextPage()}
                                                setInitialPage={this.setInitialPage}
                                                totalPageCount={allEvents.totalPageCount}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div className="admin-download-header">
                                    <div className="search-wrapper">
                                        <TextField
                                            label="Search for event name, organizer, slug..."
                                            type="text"
                                            value={searchValue}
                                            onChange={(e) => {
                                                this.onSearchChange(e);
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        disableRipple
                                                        disableTouchRipple
                                                        disableFocusRipple
                                                        onClick={this.resetSearchInput}
                                                    >
                                                        <ClearIcon
                                                            classes={{
                                                                root:
                                                                    searchValue.length > 0
                                                                        ? 'dark-color'
                                                                        : 'grey-color',
                                                            }}
                                                        />
                                                    </IconButton>
                                                ),
                                            }}
                                            fullWidth={true}
                                            variant={'outlined'}
                                        />
                                        <Button disabled={searchValue.length === 0} onClick={this.getSearchedEvents}>
                                            Search
                                        </Button>
                                    </div>
                                    <div onDragStart={preventDefaultDrag} className="d-flex">
                                        <Button onClick={this.setDownloadDataExcel} startIcon={<GetAppIcon />}>
                                            DOWNLOAD
                                        </Button>
                                        <Button onClick={this.refreshAllEventsData} startIcon={<RefreshIcon />}>
                                            REFRESH
                                        </Button>
                                    </div>
                                </div>
                                <div className="all-events-list">
                                    <div onDragStart={preventDefaultDrag} className="organizers-list">
                                        <div onDragStart={preventDefaultDrag} className="organizers-list-header">
                                            <div onDragStart={preventDefaultDrag} className="event-name-header">
                                                Event Name
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="label-image event-date-header"
                                            >
                                                <SortMenuEvents
                                                    text={'Event Start Date'}
                                                    image
                                                    arrowDown
                                                    sortBy="eventStartDate"
                                                    sortArray={this.sortEvents}
                                                />
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="label-image event-date-header"
                                            >
                                                <SortMenuEvents
                                                    text={'Event End Date'}
                                                    image
                                                    arrowDown
                                                    sortBy="eventEndDate"
                                                    sortArray={this.sortEvents}
                                                />
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="event-organizer event-organizer-header"
                                            >
                                                Event Organizer
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="event-organizer event-organizer-header"
                                            >
                                                Event slug
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="label-image event-no-participants-header"
                                            >
                                                <SortMenuEvents
                                                    text={'No. of Participants'}
                                                    image
                                                    arrowDown
                                                    sortBy="participantsCount"
                                                    sortArray={this.sortEvents}
                                                />
                                            </div>
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="label-image event-no-exhibitors-header"
                                            >
                                                <SortMenuEvents
                                                    text={'No. of Booths'}
                                                    image
                                                    arrowDown
                                                    sortBy="exhibitorsCount"
                                                    sortArray={this.sortEvents}
                                                />
                                            </div>
                                        </div>
                                        {loading && <Spinner />}
                                        {allEvents?.events?.length ? (
                                            this.returnAllEventsList()
                                        ) : (
                                            <p>{loading ? '' : 'No results found'}</p>
                                        )}
                                    </div>
                                </div>
                                {allEvents?.events?.length ? (
                                    <Pagination
                                        currentPage={currentPage}
                                        previousURL={this.getPreviousPage()}
                                        nextURL={this.getNextPage()}
                                        setInitialPage={this.setInitialPage}
                                        totalPageCount={allEvents.totalPageCount}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        allEvents: state.admin.allEvents,
        downloadData: state.admin.downloadData,
        loading: state.admin.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAdminMenu: () => dispatch(actions.setAdminMenu()),
        onGetAllEvents: (page, perPage, sortBy, sortOrder, search) =>
            dispatch(actions.getAdminAllEvents(page, perPage, sortBy, sortOrder, search)),
        onSetDownloadDataExcel: (sortBy, sortOrder, search) =>
            dispatch(actions.setDataForDownloadExcel(sortBy, sortOrder, search)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllEvents));
