import {useEffect, useState} from 'react';
import { hasOnlyEmptySpaces } from 'Utils/utils';
import {useSelector} from "react-redux";

export const ManualError = ({
    minLength,
    maxLength,
    required,
    mustBeUnique,
    isUnique,
    value,
    valueLength,
    classnames = '',
    onlySpaceRule,
    getError= () => null,
}) => {
    const [message, setMessage] = useState('');
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const muiErrorClasses = 'MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense';

    useEffect(() => {
        if (required && !valueLength) {
            getError(true);
            setMessage(`${translation?.errors.required || defaultTranslation?.errors?.required}`);
        } else if (minLength && minLength > valueLength) {
            getError(true);
            setMessage(`${translation?.errors?.genericTooShort || defaultTranslation?.errors?.genericTooShort} ${minLength}`);
        } else if (maxLength && maxLength < valueLength) {
            getError(true);
            setMessage(`${translation?.errors?.genericTooLongErrorFirst || defaultTranslation?.errors.genericTooLongErrorFirst}${maxLength}  
                              ${translation?.errors.genericTooLongErrorSecond || defaultTranslation?.errors.genericTooLongErrorSecond}`);
        } else if(mustBeUnique && !isUnique) {
            getError(true);
            setMessage(`${translation?.errors?.unique || defaultTranslation?.errors?.unique}`);
        } else if(onlySpaceRule && (!hasOnlyEmptySpaces(value) || !value.trim().length )) {

            getError(true);
            setMessage(`${translation?.errors?.required || defaultTranslation?.errors?.required}`);
        } else {

            getError(false);
            setMessage('');
        }
        // eslint-disable-next-line
    }, [valueLength, value]);

    if (message) {
        return <span className={`${muiErrorClasses} ${classnames}`}>{message}</span>;
    }
    return null;
};
