import axios from '../axios-instance';
import * as actionTypes from './actionTypes';



export const checkIfHasRestrictedEmailAccess = (eventId, userEmail, isRegistering) => {
    return (dispatch) => {
        dispatch(checkIfHasRestrictedEmailAccessStart());
        const url = `/event/${eventId}/user-has-restricted-email-domain-access`;

        const params = {
            filter: {name: userEmail, isRegistering: isRegistering}
        }

        return axios
            .get(url, {params: params})
            .then((response) => {
                let data = response.data.data;
                dispatch(checkIfHasRestrictedEmailAccessSuccess(data));
                return response;
            })
            .catch((error) => {
                let errorMessage = '';
                if(error && error.response && error.response.data) {
                    errorMessage = error.response.data.error;
                }
                dispatch(checkIfHasRestrictedEmailAccessFail(errorMessage));
                throw error;
            })
    }
}

export const checkIfHasRestrictedEmailAccessStart = () => {
    return {
        type: actionTypes.CHECK_RESTRICTED_EMAIL_ACCESS_START,
    };
};
export const checkIfHasRestrictedEmailAccessSuccess = (data) => {
    return {
        type: actionTypes.CHECK_RESTRICTED_EMAIL_ACCESS_SUCCESS,
        payload: data,
    };
};
export const checkIfHasRestrictedEmailAccessFail = (err) => {
    return {
        type: actionTypes.CHECK_RESTRICTED_EMAIL_ACCESS_FAIL,
        payload: err,
    };
};

// for organizer dashboard
export const getEmailDomainsWithUsers = (eventId) => {
    return (dispatch) => {
        dispatch(getEmailDomainsWithUsersStart());
        return axios.get(`/event/${eventId}/email-domains-with-users`)
            .then((response) => {
                let data = response.data.data;
                dispatch(getEmailDomainsWithUsersSuccess(data));
                return data
            })
            .catch((error) => {
                let errorMessage = '';
                if(error && error.response && error.response.data) {
                    errorMessage = error.response.data.error;
                }
                dispatch(getEmailDomainsWithUsersFail(errorMessage));
            })
    }
}

export const getEmailDomainsWithUsersStart = () => {
    return {
        type: actionTypes.GET_EMAIL_DOMAINS_WITH_USERS_START,
    };
};
export const getEmailDomainsWithUsersSuccess = (data) => {
    return {
        type: actionTypes.GET_EMAIL_DOMAINS_WITH_USERS_SUCCESS,
        payload: data,
    };
};
export const getEmailDomainsWithUsersFail = (err) => {
    return {
        type: actionTypes.GET_EMAIL_DOMAINS_WITH_USERS_FAIL,
        payload: err,
    };
};

export const addEmailDomain = (eventId, data) => {
    return (dispatch) => {
        dispatch(addEmailDomainStart());

        return axios
            .post(`/event/${eventId}/email-domain`, data)
            .then((response) => {
               let data = response.data.data;
               dispatch(addEmailDomainSuccess(data));
            })
            .catch((error) => {
                let errorMessage = '';
                if (error.response && error.response.data && error.response.data.error === 'Email domain name already exists') {
                    errorMessage = error.response.data.error;
                }
                dispatch(addEmailDomainFail(errorMessage));
                throw errorMessage;
            });
    }
}

export const addEmailDomainStart = () => {
    return {
        type: actionTypes.ADD_EMAIL_DOMAIN_START,
    }
}

export const addEmailDomainSuccess = (emailDomainData) => {
    return {
        type: actionTypes.ADD_EMAIL_DOMAIN_SUCCESS,
        payload: emailDomainData,
    }
}

export const addEmailDomainFail = (err) => {
    return {
        type: actionTypes.ADD_EMAIL_DOMAIN_FAIL,
        payload: err,
    }
}

export const updateEmailDomain = (eventId, emailDomainId, data) => {
    return (dispatch) => {
        dispatch(updateEmailDomainStart());

        return axios
            .patch(`event/${eventId}/email-domain/${emailDomainId}`, data)
            .then((response) => {
                let data = response.data.data;
                dispatch(updateEmailDomainSuccess(data));
            })
            .catch((error) => {
                dispatch(updateEmailDomainFail(error));
                throw error;
            })
    }
}

export const updateEmailDomainStart = () => {
    return {
        type: actionTypes.UPDATE_EMAIL_DOMAIN_START,
    };
};
export const updateEmailDomainSuccess = (emailDomainData) => {
    return {
        type: actionTypes.UPDATE_EMAIL_DOMAIN_SUCCESS,
        payload: emailDomainData,
    };
};
export const updateEmailDomainFail = (err) => {
    return {
        type: actionTypes.UPDATE_EMAIL_DOMAIN_FAIL,
        payload: err,
    };
};

export const deleteEmailDomain = (eventId, emailDomainId) => {
    return (dispatch) => {
        dispatch(deleteEmailDomainStart());

        return axios
            .delete(`/event/${eventId}/email-domain/${emailDomainId}`)
            .then((response) => {
                dispatch(deleteEmailDomainSuccess(emailDomainId));
            })
            .catch((error) => {
                let errorMessage = '';
                if (error.response && error.response.data) {
                    errorMessage = error.response.data.error;
                }
                dispatch(deleteEmailDomainFail(errorMessage));
                throw error;
            })
    }
}

export const deleteEmailDomainStart = () => {
    return {
        type: actionTypes.DELETE_EMAIL_DOMAIN_START,
    };
};
export const deleteEmailDomainSuccess = (emailDomainId) => {
    return {
        type: actionTypes.DELETE_EMAIL_DOMAIN_SUCCESS,
        payload: emailDomainId,
    };
};
export const deleteEmailDomainFail = (err) => {
    return {
        type: actionTypes.DELETE_EMAIL_DOMAIN_FAIL,
        payload: err,
    };
};

