import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import EventIcon from '@material-ui/icons/Event';
import React from 'react';
import {connect} from 'react-redux';
import '../../../CSS/rooms.scss';
import {isWebview} from '../../../Utils/is-webview';
import {
    checkIfProgramHasTimeslots,
    createExternalEventCalendarData,
    getEndHourOfLastTimeslot,
    getEventDescriptionFormattedBasedOnTimezoneValue,
    getEventStartOrEndDate,
    getStartHourOfFirstTimeslot,
    preventDefaultDrag,
} from '../../../Utils/utils';
import * as actions from '../../../store/actions/index';
import DialogAddToExternalCalendar from '../../EventMenuDropdowns/ProgramComponents/DialogAddToExternalCalendar/DialogAddToExternalCalendar';
class ProgramSubmenu extends React.Component {
    state = {
        submenuLinks: [
            {
                name: 'programButton',
                topNavigation: 'seeProgramMobile',
                action: this.props.seeProgramMobile,
                icon: <AssignmentOutlinedIcon />,
                activeIcon: <AssignmentOutlinedIcon />,
            },
            /*  {
                name: 'meetingsButton',
                topNavigation: 'seeMeetingsMobile',
                action: this.props.seeMeetingsMobile,
                icon: <PeopleOutlineOutlinedIcon />,
                activeIcon: <PeopleOutlineOutlinedIcon fillprimary={colors.primary} strokefill={colors.primary} />,
            },*/
        ],

        eventCalendarData: null,
        showAddToExternalCalendarDialog: false,
    };

    componentDidMount() {
        const {topNavigation} = this.props;
        if (topNavigation.seeProgramSubmenu) {
            document.body.classList.add('menu-slow-index');
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('menu-slow-index');
    }

    handleSubmenuLinkClick = (handleSubmenuLinkAction) => (e) => {
        handleSubmenuLinkAction();
    };

    showAddToExternalCalendarDialog = (calendarData) => {
        this.setState({
            showAddToExternalCalendarDialog: true,
            eventCalendarData: calendarData,
        });
    };

    hideAddToExternalCalendarDialog = () => {
        this.setState({
            showAddToExternalCalendarDialog: false,
        });
    };

    createExternalCalendarData = (languages) => (e) => {
        const {event, user} = this.props;

        const eventCalendarData = createExternalEventCalendarData(
            event,
            languages,
            user,
            event.eventStartDate,
            event.eventEndDate
        );
        this.showAddToExternalCalendarDialog(eventCalendarData);
    };

    render() {
        const {submenuLinks, showAddToExternalCalendarDialog, eventCalendarData} = this.state;
        const {topNavigation, translation, defaultTranslation, event, languages, user} = this.props;
        const eventId = event._id;
        const programFile = event.brandingData.programFile;

        let eventStartDate = getEventStartOrEndDate(event.eventStartDate, languages);
        let eventEndDate = getEventStartOrEndDate(event.eventEndDate, languages);
        let startHourOfFirstTimeslot = getStartHourOfFirstTimeslot(event, event.timezoneValue);
        let endHourOfLastTimeSlot = getEndHourOfLastTimeslot(event, event.timezoneValue);

        let eventDescription = getEventDescriptionFormattedBasedOnTimezoneValue(
            user,
            event,
            eventStartDate,
            eventEndDate,
            startHourOfFirstTimeslot,
            endHourOfLastTimeSlot,
            languages
        );

        return (
            <div
                onDragStart={preventDefaultDrag}
                id="submenu-dropdown"
                className={isWebview() ? 'submenu is-webview' : 'submenu'}
            >
                <div onDragStart={preventDefaultDrag} className="submenu-container">
                    <div onDragStart={preventDefaultDrag} className="submenu-container-title">
                        <h3>{translation?.eventMenu.programButton}</h3>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="rooms-container submenu-container-content">
                        <ul className="submenu-container-links">
                            {submenuLinks.map((submenuLink) => {
                                const isActive = topNavigation[submenuLink.topNavigation];
                                return (
                                    <li key={submenuLink.name} className={`${isActive ? 'active' : ''}`}>
                                        {isActive ? submenuLink.activeIcon : submenuLink.icon}
                                        <p
                                            onDragStart={preventDefaultDrag}
                                            onClick={this.handleSubmenuLinkClick(submenuLink.action)}
                                        >
                                            {translation?.eventMenu[submenuLink.name]}
                                        </p>
                                    </li>
                                );
                            })}

                            {programFile && (
                                <li key="downloadProgram">
                                    <a
                                        href={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${programFile}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="download-program-submenu-link"
                                    >
                                        <CloudDownloadOutlinedIcon />
                                        <p onDragStart={preventDefaultDrag}>
                                            {translation?.eventMenu?.downloadProgram ||
                                                defaultTranslation?.eventMenu?.downloadProgram}
                                        </p>
                                    </a>
                                </li>
                            )}

                            {checkIfProgramHasTimeslots(event) && (
                                <li key="addEventToCalendar" onClick={this.createExternalCalendarData(languages)}>
                                    <EventIcon />
                                    <p className="text-uppercase">
                                        {translation?.programDropdown.addToCalendarButton ||
                                            defaultTranslation?.programDropdown.addToCalendarButton}
                                    </p>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                {showAddToExternalCalendarDialog && (
                    <DialogAddToExternalCalendar
                        open={showAddToExternalCalendarDialog}
                        onClose={this.hideAddToExternalCalendarDialog}
                        eventCalendarData={eventCalendarData}
                        durationTitle={
                            translation?.programDropdown.durationEventTitle ||
                            defaultTranslation?.programDropdown.durationEventTitle
                        }
                        duration={eventDescription}
                        title={
                            translation?.programDropdown.addEventToYourCalendarDialogTitle ||
                            defaultTranslation?.programDropdown.addEventToYourCalendarDialogTitle
                        }
                        tip={
                            translation?.programDropdown.tipDescription ||
                            defaultTranslation?.programDropdown.tipDescription
                        }
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        topNavigation: state.user.topNavigation,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        event: state.event.data,
        languages: state.languages,
        user: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeMeetingsMobile: () => dispatch(actions.topNavSeeMeetingsMobile()),
        seeProgramMobile: () => dispatch(actions.topNavSeeProgramMobile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramSubmenu);
