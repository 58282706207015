import React from 'react';
import isEqual from 'lodash/isEqual';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {Button, Grid, MenuItem, Select, Divider, CircularProgress} from '@material-ui/core';
import 'CSS/myAccount.scss';
import variables from 'CSS/_variables.module.scss';
import CreateOrganizerParticipantProfile from 'Dialogs/MyAccount/CreateOrganizerParticipantProfile';
import EditExhibitorContactPersonInformation from 'Dialogs/MyAccount/EditExhibitorContactPersonInformation';
import EditParicipantInformation from '../../Dialogs/MyAccount/EditParticipantInformation';
import EditScholarContactPersonInformation from '../../Dialogs/MyAccount/EditScholarInformation';
import EditUserInformation from '../../Dialogs/MyAccount/EditUserInformation';
import * as actions from '../../store/actions/index';
import axios from '../../store/axios-instance';
import {checkIfEventLanguagesIncludeUserLanguage, preventDefaultDrag} from '../../Utils/utils';
import {EXHIBITOR_TYPES} from '../../Utils/constants/shared';
import ChangePasswordAccount from './ChangePasswordAccount';
import DeleteAccount from './DeleteAccount';
import MyAccountMobile from './MyAccountMobile';
import UnregisterEvent from './UnregisterEvent';
import MatchingParticipantQuestion from '../../Components/EventManagement/Matching/MatchingParticipantQuestion';
import Confirm from '../../Dialogs/Confirm';
import cloneDeep from 'lodash/cloneDeep';
import {EventProfileItem} from './EventProfileItem';
import {verifyFileType} from 'Utils/verifyFileType';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import AccountProfilePicture from './AccountProfilePicture';
import Spinner from '../../SmallLayoutComponents/Spinner';
import TextWithTooltipOnOverflow from '../../Components/TextWithTooltipOnOverflow/TextWithTooltipOnOverflow';
import './MyAccount.scss';
import ConfirmPassword from './EmailChange/components/ConfirmPassword';
import EmailChange from './EmailChange/EmailChange';

class MyAccount extends React.Component {
    state = {
        editUserDialog: false,
        editParticipantDialog: false,
        editExhibitorContactPersonDialog: false,
        editScholarContactPersonDialog: false,
        createParticipantProfileDialog: false,
        participantData: null,
        exhibitorData: null,
        imgErrorText: '',
        registrationType: '',
        matchingDetailsPrecompleted: false,
        answers: [],
        answersClone: [],
        submitMatchingAnswersDisabled: true,
        submitMatchingAnswersRequired: false,
        openConfirmUnsavedChanges: false,
        openConfirmRemoveMatchingData: false,
        confirmRemoveMatchingData: false,
        loadingProfilePicture: false,
        loadingLanguage: false,
        isPasswordCheckOn: false,
        isPasswordCorrect: false,
        password: '',
        passwordError: '',
        passwordCorrected: false,
    };

    wrapperRef = React.createRef();
    handleClickOutside = this.handleClickOutside.bind(this);

    componentDidMount() {
        const {event, eventId, user, triggerReloadUserProfile} = this.props;
        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        this.props.onSetEventProtectedMenu();
        this.getRegistrationType();
        if (hasMatchingEnabled) this.getMatchingData();
        if (triggerReloadUserProfile) {
            this.props.onGetUserEventProfile(eventId, user._id);
            this.props.getEventRegistrationFields(eventId);
        }
        this.setRegistrationFieldsData();
        this.props.closeTopNavigation();
        document.body.classList.add('sticky-menu');
        this.setState({matchingDetailsPrecompleted: false});
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const {event, eventRoles, triggerReloadUserProfile, eventId, user} = this.props;
        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        // force a state update if the user changed something in a participant or exhibitor
        if (!prevProps.triggerReloadUserProfile && triggerReloadUserProfile) {
            this.props.onGetUserEventProfile(eventId, user._id);
            this.props.getEventRegistrationFields(eventId);
        }
        if (prevProps.eventRoles.isExhibitor === false && eventRoles.isExhibitor === true) {
            this.setRegistrationFieldsData();
        }
        if (prevProps.event._id !== event._id) {
            if (hasMatchingEnabled) this.getMatchingData();
            this.setRegistrationFieldsData();
        }
        if (!isEqual(prevProps.userEventProfile, this.props.userEventProfile)) {
            this.setRegistrationFieldsData();
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('sticky-menu');
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    closeClickOutside = () => this.setState({openConfirmUnsavedChanges: false});

    handleClickOutside(e) {
        const {submitMatchingAnswersDisabled, submitMatchingAnswersRequired, isPasswordCheckOn, isPasswordCorrect} =
            this.state;
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
            if (!submitMatchingAnswersDisabled && !submitMatchingAnswersRequired) {
                this.setState({openConfirmUnsavedChanges: true});
            }
            this.setState({isPasswordCheckOn: false, isPasswordCorrect: false});
        }
    }

    getRegistrationType = () => {
        axios({method: 'get', url: `/users/get-registration-type`})
            .then((response) => {
                this.setState({registrationType: response.data.registrationType});
            })
            .catch((error) => {});
    };

    setRegistrationFieldsData = () => {
        const {eventRoles, eventId, registrationFields} = this.props;
        // in getEvent we don't receive the

        if (!registrationFields.fetched) {
            this.props.getEventRegistrationFields(eventId).then((response) => {
                if (eventRoles.isExhibitor) {
                    this.setExhibitorData();
                } else {
                    this.setParticipantData();
                }
            });
        } else {
            if (eventRoles.isExhibitor) {
                this.setExhibitorData();
            } else {
                this.setParticipantData();
            }
        }
    };

    setParticipantData = () => {
        const {registrationFields, userEventProfile} = this.props;
        let participantData = cloneDeep(userEventProfile);
        this.setState({participantData: participantData});

        // get participant predefined fields
        const {participantPredefinedRegistrationFields} = registrationFields?.data;
        let predefinedRegistrationFields = [];
        Object.keys(participantPredefinedRegistrationFields).map((key) => {
            const participantPredefinedField = participantPredefinedRegistrationFields[key];
            if (participantPredefinedField.isEnabled) {
                let fieldLabel = participantPredefinedField.label;
                predefinedRegistrationFields.push({label: fieldLabel, value: key});
            }
            return null;
        });
        this.setState({predefinedRegistrationFields: predefinedRegistrationFields});
    };

    setExhibitorData = () => {
        const {registrationFields, userEventProfile} = this.props;
        let exhibitorData = cloneDeep(userEventProfile);
        this.setState({exhibitorData: exhibitorData});

        if (exhibitorData?.type === EXHIBITOR_TYPES.scholar) {
            // get scholar predefined fields
            const {scholarPredefinedRegistrationFields} = registrationFields?.data;
            let predefinedRegistrationFields = [];
            Object.keys(scholarPredefinedRegistrationFields).map((key) => {
                const scholarPredefinedField = scholarPredefinedRegistrationFields[key];
                if (scholarPredefinedField.isEnabled) {
                    let fieldLabel = scholarPredefinedField.label;
                    predefinedRegistrationFields.push({label: fieldLabel, value: key});
                }
                return null;
            });
            this.setState({predefinedRegistrationFields: predefinedRegistrationFields});
        } else {
            // get exhibitor predefined fields
            const {exhibitorPredefinedRegistrationFields} = registrationFields?.data;
            let predefinedRegistrationFields = [];
            Object.keys(exhibitorPredefinedRegistrationFields).map((key) => {
                const exhibitorPredefinedField = exhibitorPredefinedRegistrationFields[key];
                if (exhibitorPredefinedField.isEnabled) {
                    let fieldLabel = exhibitorPredefinedField.label;
                    predefinedRegistrationFields.push({label: fieldLabel, value: key});
                }
                return null;
            });
            this.setState({predefinedRegistrationFields: predefinedRegistrationFields});
        }
    };

    handleOpenEditUserDialog = () => {
        this.setState({editUserDialog: true});
    };

    handleOpenEditEventProfileDialog = () => {
        const {exhibitorData, participantData} = this.state;
        const {eventRoles} = this.props;
        if (eventRoles.isExhibitor && exhibitorData && exhibitorData.type === EXHIBITOR_TYPES.scholar) {
            this.handleOpenEditScholarContactPersonDialog();
        } else if (eventRoles.isExhibitor && exhibitorData && exhibitorData.type === EXHIBITOR_TYPES.company) {
            this.handleOpenEditExhibitorContactPersonDialog();
        } else if (!eventRoles.isExhibitor && participantData) {
            this.handleOpenEditParticipantDialog();
        }
    };

    handleOpenEditParticipantDialog = () => {
        this.setState({editParticipantDialog: true});
    };

    handleOpenEditExhibitorContactPersonDialog = () => {
        this.setState({editExhibitorContactPersonDialog: true});
    };

    handleOpenEditScholarContactPersonDialog = () => {
        this.setState({editScholarContactPersonDialog: true});
    };

    handleOpenCreateEventProfileDialog = () => {
        this.setState({createParticipantProfileDialog: true});
    };

    handleCloseDialog = () => {
        this.setState({
            editUserDialog: false,
            editParticipantDialog: false,
            editExhibitorContactPersonDialog: false,
            editScholarContactPersonDialog: false,
            createParticipantProfileDialog: false,
        });
    };

    handleRemoveUserAvatar = () => {
        this.setState({loadingProfilePicture: true});
        axios({method: 'delete', url: '/users/me/avatar'})
            .then((response) => {
                this.props.onRefreshUserUserData();
                this.setState({loadingProfilePicture: false});
            })
            .catch((error) => {
                this.setState({loadingProfilePicture: false});
            });
    };

    handleImageChange = (e) => {
        const {languages} = this.props;
        e.preventDefault();
        let file = e.target.files[0];

        let isValid = true;

        if (file) {
            isValid = file.size < 2 * 1024 * 1024 && isValid;
            if (!isValid) {
                this.setState({
                    imgErrorText: languages.translations[languages.platformLanguage]?.errors.fileTooLarge,
                });
            }
            const validType = verifyFileType(file.type, 'image');
            isValid = validType && isValid;
            if (!validType) {
                this.setState({
                    imgErrorText: `${
                        languages.translations[languages.platformLanguage]?.errors.imageFileNotSupported
                    } jpeg, jpg, jfif, gif or png.`,
                });
            }
        }

        if (isValid) {
            this.setState({imgErrorText: '', loadingProfilePicture: true});
            const formData = new FormData();

            formData.append('avatar', file, file.name, file.type);
            axios({method: 'post', url: '/users/me/avatar', data: formData})
                .then(() => {
                    this.props.onRefreshUserUserData();
                    this.setState({loadingProfilePicture: false});
                })
                .catch(() => {
                    this.props.onRefreshUserUserData();
                    this.setState({loadingProfilePicture: false});
                });
        }

        e.target.value = '';
    };

    handleChangeUserLanguage = (e) => {
        this.setState({loadingLanguage: true});
        const languageCode = e.target.value;
        axios({method: 'put', url: '/users', data: {language: languageCode}})
            .then((response) => {
                this.props.onSetPlatformLanguage(languageCode);
                this.props.onRefreshUserUserData();
                this.setState({loadingLanguage: false});
            })
            .catch((error) => {
                this.setState({loadingLanguage: false});
            });
    };

    getMatchingData = () => {
        const {eventId, onGetQuestionnaires} = this.props;
        onGetQuestionnaires(eventId).then(() => this.setEmptyQuestions());
    };

    getQuestionsIdsFromMatchingForm = () => {
        const {eventMatching} = this.props;

        if (!eventMatching?.questions) return [];

        // create an array of questions ids
        let questionsIds = [];
        eventMatching?.questions.forEach((question) => questionsIds.push(question._id));

        return questionsIds;
    };

    setEmptyQuestions = () => {
        const {eventId, eventMatching, onGetUserMatchingAnswers} = this.props;
        let questionsClone = JSON.parse(JSON.stringify(eventMatching?.questions));
        // trim down and rename current eventMatching questions
        let questionAllowed = ['questionId', 'answerIds'];
        let reduceQuestions = questionsClone?.map((el) => {
            delete Object.assign(el, {questionId: el['_id']})['_id'];
            delete Object.assign(el, {answerIds: el['answers']})['answers'];
            return Object.keys(el)
                .filter((key) => questionAllowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = el[key];
                    if (key === 'answerIds') obj[key] = [];
                    return obj;
                }, {});
        });
        // clone for arrays equality comparation, set state then in setState callback
        // get urrent participant answered questions then
        // populate the answers in state

        const questionIds = this.getQuestionsIdsFromMatchingForm();
        const data = {
            questionIds,
        };

        let questionsEqualityClone = JSON.parse(JSON.stringify(reduceQuestions));
        this.setState({answers: reduceQuestions, answersClone: questionsEqualityClone}, () =>
            onGetUserMatchingAnswers(eventId, eventMatching?._id, data).then(() => this.setParticipantAnswers())
        );
    };

    setParticipantAnswers = () => {
        const {user} = this.props;
        const {answers} = this.state;
        let predefinedAnswers = cloneDeep(answers);

        // userAnswersByQuestionId is an array of objects
        // each object is {questionId: questionId, answerIds:[answerId1, answerId2 etc]}
        const userAnswersByQuestionId = [];

        const userAnswersFromDb = cloneDeep(user?.matchingAnswers);

        predefinedAnswers.forEach((question) => {
            const questionId = question.questionId;
            const userAnswers = userAnswersFromDb.filter((userAnswer) => userAnswer.question === questionId);

            // here we want to create only an array of answerIds
            const answerIds = Array.from(Object.values(userAnswers), (answer) => answer.answer);
            userAnswersByQuestionId.push({
                questionId,
                answerIds,
            });
        });

        // clone for arrays equality comparation then set answers and clone to state
        let equalityClone = JSON.parse(JSON.stringify(userAnswersByQuestionId));
        this.setState({answers: userAnswersByQuestionId, answersClone: equalityClone}, () =>
            this.setState({matchingDetailsPrecompleted: true})
        );
    };

    handleAddChangeAnswers = (questionId, type) => (e) => {
        const {eventMatching} = this.props;
        const {answers, answersClone} = this.state;
        let updatedAnswers = [...answers];
        // handle keep answers object in state
        let questionIndex = updatedAnswers.findIndex((answer) => answer.questionId === questionId);
        let qIdx = eventMatching?.questions?.findIndex((question) => question._id === questionId);
        let aIdx = eventMatching?.questions[qIdx]?.answers?.findIndex((answer) => answer.title === e.target.value);
        let answerId = eventMatching?.questions[qIdx]?.answers[aIdx]?._id;
        let data = {};
        data.questionId = questionId;
        data.answerIds = [];
        if (type === 'checkbox') {
            // if question answer is ALREADY in state then check if option was unchecked and remove
            // from answerIds array, else push the newly checked answer
            if (questionIndex !== -1) {
                let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex((aId) => aId === answerId);
                if (answerIndex !== -1) updatedAnswers[questionIndex].answerIds.splice(answerIndex, 1);
                if (answerIndex === -1) updatedAnswers[questionIndex].answerIds.push(answerId);
            } else {
                data.answerIds.push(answerId);
                updatedAnswers.push(data);
            }
        } else {
            // if question answer is ALREADY in state then check if option was unchecked and remove
            // from answerIds array, else push the newly checked answer
            if (questionIndex !== -1) {
                let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex((aId) => aId === answerId);
                if (answerIndex !== -1) updatedAnswers[questionIndex].answerIds = [];
                if (answerIndex === -1) updatedAnswers[questionIndex].answerIds = [answerId];
            } else {
                data.answerIds.push(answerId);
                updatedAnswers.push(data);
            }
        }
        // check required questions
        let count = 0;
        for (let i = 0; i < eventMatching?.questions?.length; i++) {
            if (eventMatching?.questions[i].isRequired) updatedAnswers[i].answerIds.length === 0 && count++;
        }
        this.setState({answers: updatedAnswers}, () =>
            this.setState({
                submitMatchingAnswersDisabled: JSON.stringify(answers) === JSON.stringify(answersClone),
                submitMatchingAnswersRequired: count > 0,
            })
        );
    };

    handleSubmitAnswers = () => {
        const {answers} = this.state;
        const {eventId, eventMatching, onSaveUpdateUserAnswers} = this.props;
        let changedEqualityClone = JSON.parse(JSON.stringify(answers));
        let data = {};
        data.answers = answers;
        onSaveUpdateUserAnswers(eventId, eventMatching?._id, data)
            .then(() =>
                this.setState({
                    submitMatchingAnswersDisabled: true,
                    submitMatchingAnswersRequired: false,
                    openConfirmUnsavedChanges: false,
                    answersClone: changedEqualityClone,
                })
            )
            .catch(() => {});
    };

    handleRemoveMatchingData = () => {
        const {eventId, user, onRemoveUserMatchingData} = this.props;
        this.closeConfirmRemoveMatchingData();
        onRemoveUserMatchingData(eventId)
            .then(() => this.props.onGetUserEventProfile(eventId, user._id))
            .catch(() => {});
    };

    openConfirmRemoveMatchingData = () => this.setState({confirmRemoveMatchingData: true});
    closeConfirmRemoveMatchingData = () => this.setState({confirmRemoveMatchingData: false});

    handlePasswordCorrection = async () => {
        const {password} = this.state;
        try {
            const {data} = await axios.post('users/me/confirm-password', {password});
            if (data.success === true) {
                this.setState({
                    isPasswordCorrect: true,
                    isPasswordCheckOn: false,
                    passwordError: null,
                    passwordCorrected: true,
                });
                setTimeout(
                    () =>
                        this.setState(() => ({
                            passwordCorrected: false,
                        })),
                    1000
                );
            } else {
                this.setState({passwordError: 'Passwords do not match!'});
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                this.setState({passwordError: error.response.data.error});
            } else {
                // Handle other types of errors (e.g., network error)
                console.error('An error occurred:', error);
            }
            console.log(error);
        }
    };

    handleChangePassword = (e) => {
        const {passwordError} = this.state;
        if (passwordError?.length > 0) {
            this.setState({passwordError: ''});
        }
        this.setState({password: e.target.value});
    };
    closeConfirmPassword = () => {
        this.setState({isPasswordCorrect: false, isPasswordCheckOn: false, password: ''});
    };

    render() {
        const {
            participantData,
            exhibitorData,
            predefinedRegistrationFields,
            editUserDialog,
            editParticipantDialog,
            editExhibitorContactPersonDialog,
            editScholarContactPersonDialog,
            createParticipantProfileDialog,
            registrationType,
            answers,
            matchingDetailsPrecompleted,
            submitMatchingAnswersDisabled,
            submitMatchingAnswersRequired,
            openConfirmUnsavedChanges,
            confirmRemoveMatchingData,
            imgErrorText,
            loadingLanguage,
            isPasswordCheckOn,
            password,
            passwordError,
            passwordCorrected,
        } = this.state;
        const {
            user,
            event,
            eventSlug,
            eventRoles,
            userEventProfile,
            eventMatching,
            isMobile,
            isTablet,
            languages,
            defaultTranslation,
            translation,
            loadingMatchingAnswers,
            registrationFields,
            platformLanguage,
        } = this.props;
        let accessPackageData = null;
        let exhibitorAccessPackageData = null;
        if (event.hasAccessManagement) {
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === event.slug);
            const userEventAccessPackageId = userRolesInEvent.accessPackage;
            accessPackageData = event.accessPackages.find(
                (accessPackage) => accessPackage._id === userEventAccessPackageId
            );
        }
        if (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) {
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === event.slug);
            const userEventAccessPackageId = userRolesInEvent.exhibitorAccessPackage;
            exhibitorAccessPackageData = event.exhibitorAccessPackages.find(
                (accessPackage) => accessPackage._id === userEventAccessPackageId
            );
        }

        const displayLanguages = languages.platformLanguages;

        // do not display fake emails
        let userEmail = user.email;
        if (registrationType !== '' && registrationType !== 'email') {
            if (
                (userEmail.startsWith('fb') ||
                    userEmail.startsWith('go') ||
                    userEmail.startsWith('tw') ||
                    userEmail.startsWith('li')) &&
                (userEmail.endsWith(variables.emailEndsWith1) || userEmail.endsWith(variables.emailEndsWith2))
            ) {
                userEmail = '-';
            }
        }

        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;
        const matchingFormSeen = userEventProfile?.matchingFormSeen;

        if (!registrationFields.fetched) {
            return <p>Loading</p>;
        }

        if (isMobile || isTablet) {
            return (
                <MyAccountMobile
                    registrationType={registrationType}
                    exhibitorData={exhibitorData}
                    participantData={participantData}
                    predefinedRegistrationFields={predefinedRegistrationFields}
                    accessPackageData={accessPackageData}
                    exhibitorAccessPackageData={exhibitorAccessPackageData}
                    userEmail={userEmail}
                    userId={user._id}
                    hasMatchingEnabled={hasMatchingEnabled}
                    matchingFormSeen={matchingFormSeen}
                    imgErrorText={imgErrorText}
                    handleImageChange={this.handleImageChange}
                    handleRemoveUserAvatar={this.handleRemoveUserAvatar}
                    loadingProfilePicture={this.state.loadingProfilePicture}
                    handleRemoveMatchingData={this.props.handleRemoveMatchingData}
                />
            );
        }

        return (
            <div onDragStart={preventDefaultDrag} className="my-account-page">
                <div onDragStart={preventDefaultDrag} className="edit-section-container onvent-profile">
                    <Grid container alignItems="center" className="smallContainer">
                        <div onDragStart={preventDefaultDrag} className="edit-section-header">
                            <h3>
                                {process.env.REACT_APP_PLATFORM_NAME}{' '}
                                {translation?.myAccountPage.platformProfileSectionTitleTextSecond ||
                                    defaultTranslation?.myAccountPage.platformProfileSectionTitleTextSecond}
                            </h3>
                            <IconButton onClick={this.handleOpenEditUserDialog} color="secondary">
                                <EditOutlinedIcon />
                            </IconButton>
                        </div>
                        <Grid item xs={12} className="edit-user-personal-info-container">
                            <AccountProfilePicture
                                user={user}
                                handleImageChange={this.handleImageChange}
                                handleRemoveUserAvatar={this.handleRemoveUserAvatar}
                                loading={this.state.loadingProfilePicture}
                            />
                            <div onDragStart={preventDefaultDrag} className="user-profile-information">
                                <span>
                                    {user.first} {user.last}
                                </span>
                                <p
                                    className={`${
                                        platformLanguage === 'ar' && 'ar-helper'
                                    } mail-contianer-user-profile`}
                                >
                                    <div className={`user-email-container`}>
                                        <span>
                                            {translation?.login.inputEmail || defaultTranslation?.login.inputEmail}:{' '}
                                        </span>
                                        <TextWithTooltipOnOverflow
                                            text={userEmail}
                                            textClasses={`${platformLanguage === 'ar' && 'ar-helper'} user-mail-helper`}
                                        />
                                    </div>
                                    <Button
                                        variant={'outlined'}
                                        component="label"
                                        color={'secondary'}
                                        disabled={this.state.isPasswordCorrect}
                                        size={'small'}
                                        onClick={() => {
                                            this.setState((prevState) => ({
                                                isPasswordCheckOn: !prevState.isPasswordCheckOn,
                                            }));
                                        }}
                                        startIcon={<EditOutlinedIcon />}
                                    >
                                        {translation.emailUpdate.change || defaultTranslation.emailUpdate.change}
                                    </Button>
                                </p>
                                <div>
                                    <ChangePasswordAccount
                                        translation={translation}
                                        registrationType={registrationType}
                                    />
                                    <DeleteAccount translation={translation} />
                                </div>
                            </div>
                        </Grid>

                        {this.state.isPasswordCorrect && (
                            <EmailChange
                                closeConfirmPassword={this.closeConfirmPassword}
                                passwordCorrected={this.state.passwordCorrected}
                            />
                        )}

                        <span onDragStart={preventDefaultDrag} draggable="false" className="image-error">
                            {imgErrorText}
                        </span>
                        {loadingLanguage && (
                            <div className="d-flex align-items-center justify-content-center">
                                <Spinner custom={'wall-spinner'} />
                            </div>
                        )}
                        <div onDragStart={preventDefaultDrag} className="select-language-container">
                            <span>{translation?.myAccountPage.platformLanguage}</span>
                            <Select
                                onChange={this.handleChangeUserLanguage}
                                value={user.language}
                                variant="outlined"
                                MenuProps={{
                                    className: `color-primary`,
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                IconComponent={(props) => (
                                    <ExpandMoreRoundedIcon {...props} className={`material-icons ${props.className}`} />
                                )}
                            >
                                {displayLanguages.map((language) => {
                                    return (
                                        <MenuItem value={language.value} key={`language${language.value}`}>
                                            {language.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>

                        <div onDragStart={preventDefaultDrag} className="gdpr-links">
                            <p>
                                {translation?.myAccountPage.reviewPlatformConditions ||
                                    defaultTranslation?.myAccountPage.reviewPlatformConditions}
                                :
                            </p>
                            <ul>
                                <li>
                                    <Link to={{pathname: '/privacy-policy'}} target={'_blank'}>
                                        {translation?.myAccountPage.privacyPolicyLink}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{pathname: '/cookies'}} target={'_blank'}>
                                        {translation?.myAccountPage.cookiesLink}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{pathname: '/terms-and-conditions'}} target={'_blank'}>
                                        {translation?.myAccountPage.termsAndConditionsLink}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </div>
                {(participantData || exhibitorData) && (
                    <div onDragStart={preventDefaultDrag} className="edit-section-container event-profile">
                        <Grid container alignItems="center" className="smallContainer">
                            <div onDragStart={preventDefaultDrag} className="edit-section-header">
                                <h3>{translation?.myAccountPage.eventProfileSectionTitle}</h3>
                                <IconButton onClick={this.handleOpenEditEventProfileDialog} color="secondary">
                                    <EditOutlinedIcon />
                                </IconButton>
                            </div>
                            {!eventRoles.isExhibitor && participantData && (
                                <Grid item xs={12} className="edit-participant-info-container">
                                    <ul>
                                        {predefinedRegistrationFields?.map((field) => {
                                            return (
                                                <EventProfileItem
                                                    key={`field${field.label}`}
                                                    label={field.label}
                                                    value={participantData[`${field.value}`] ?? '-'}
                                                />
                                            );
                                        })}
                                        {participantData.customFields.map((field) => {
                                            let value = '';
                                            if (field.value) {
                                                value = field.value;
                                            } else if (field.values) {
                                                value = field.values.filter((el) => el.length).join(', ');
                                            }
                                            return (
                                                <EventProfileItem
                                                    key={`field${field.label}`}
                                                    label={field.label}
                                                    value={value}
                                                />
                                            );
                                        })}

                                        {!eventRoles?.isOrganizer && event.hasAccessManagement && accessPackageData && (
                                            <EventProfileItem
                                                key={`field${translation?.myAccountPage.eventPackage}`}
                                                label={translation?.myAccountPage.eventPackage}
                                                value={accessPackageData.name}
                                            />
                                        )}
                                    </ul>
                                </Grid>
                            )}
                            {eventRoles.isExhibitor && exhibitorData && (
                                <Grid item xs={12} className="edit-participant-info-container">
                                    <ul>
                                        {predefinedRegistrationFields?.map((field) => {
                                            return (
                                                <EventProfileItem
                                                    label={field.label}
                                                    value={exhibitorData[`${field.value}`]}
                                                    key={`predefinedRegistrationField${field.value}`}
                                                />
                                            );
                                        })}
                                        {exhibitorData.customFields.map((field) => {
                                            let value = '';
                                            if (field.value) {
                                                value = field.value;
                                            } else if (field.values) {
                                                value = field.values.filter((el) => el.length).join(', ');
                                            }
                                            return (
                                                <EventProfileItem
                                                    label={field.label}
                                                    value={value}
                                                    key={`customField${value}`}
                                                />
                                            );
                                        })}
                                        {(event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
                                            exhibitorAccessPackageData && (
                                                <EventProfileItem
                                                    key={`field${translation?.myAccountPage.eventPackage}`}
                                                    label={translation?.myAccountPage.eventPackage}
                                                    value={exhibitorAccessPackageData.name}
                                                />
                                            )}
                                    </ul>
                                </Grid>
                            )}
                        </Grid>
                        <div onDragStart={preventDefaultDrag} className="gdpr-links">
                            <p>
                                {translation?.myAccountPage.reviewEventConditions ||
                                    defaultTranslation?.myAccountPage.reviewEventConditions}
                                :
                            </p>
                            <ul>
                                <li>
                                    <Link
                                        to={{
                                            pathname: `/event/${eventSlug}/event-privacy-policy`,
                                            search: `?lang=${
                                                checkIfEventLanguagesIncludeUserLanguage(
                                                    platformLanguage,
                                                    event.brandingTranslations
                                                )
                                                    ? languages.platformLanguage
                                                    : event.language
                                            }`,
                                        }}
                                        target={'_blank'}
                                    >
                                        {translation?.myAccountPage.privacyPolicyLink}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{
                                            pathname: `/event/${eventSlug}/event-terms-and-conditions`,
                                            search: `?lang=${
                                                checkIfEventLanguagesIncludeUserLanguage(
                                                    platformLanguage,
                                                    event.brandingTranslations
                                                )
                                                    ? languages.platformLanguage
                                                    : event.language
                                            }`,
                                        }}
                                        target={'_blank'}
                                    >
                                        {translation?.myAccountPage.termsAndConditionsLink}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <UnregisterEvent />
                    </div>
                )}
                {hasMatchingEnabled &&
                    matchingFormSeen &&
                    (loadingMatchingAnswers || !matchingDetailsPrecompleted ? (
                        <div className="matching-loading">
                            <CircularProgress color="primary" />
                        </div>
                    ) : (
                        <div
                            ref={this.wrapperRef}
                            onDragStart={preventDefaultDrag}
                            className="edit-section-container event-matching"
                        >
                            <Grid container alignItems="center" className="smallContainer">
                                <div onDragStart={preventDefaultDrag} className="matching-section-header">
                                    <h3>
                                        {translation?.matching?.myAccountMatchingSectionTitle ||
                                            defaultTranslation?.matching?.myAccountMatchingSectionTitle}
                                    </h3>
                                    <Button
                                        type="button"
                                        disableFocusRipple
                                        onClick={this.openConfirmRemoveMatchingData}
                                        startIcon={<ClearOutlinedIcon />}
                                    >
                                        {translation?.matching?.rejectMatchingBtn ||
                                            defaultTranslation?.matching?.rejectMatchingBtn}
                                    </Button>
                                </div>
                                <div className="matching-questions-container">
                                    {matchingDetailsPrecompleted &&
                                        !!eventMatching.questions &&
                                        eventMatching?.questions?.map((question, questionIndex) => {
                                            return (
                                                <React.Fragment key={question._id}>
                                                    <MatchingParticipantQuestion
                                                        isMyAccount
                                                        answers={answers}
                                                        question={question}
                                                        questionIndex={questionIndex}
                                                        handleChange={this.handleAddChangeAnswers}
                                                    />
                                                    {/* don't display a divider at the bottom of the last question */}
                                                    {questionIndex !== eventMatching?.questions?.length - 1 && (
                                                        <Divider />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                            </Grid>
                            <Button
                                type="button"
                                disableFocusRipple
                                disabled={submitMatchingAnswersDisabled || submitMatchingAnswersRequired}
                                onClick={this.handleSubmitAnswers}
                                startIcon={<SaveOutlinedIcon />}
                                variant="contained"
                                color={'secondary'}
                                disableElevation
                            >
                                {translation?.generalText?.save || defaultTranslation?.generalText?.save}
                            </Button>
                            {submitMatchingAnswersRequired && (
                                <span className="matching-required-error">
                                    {translation?.matching?.matchingRequiredError ||
                                        defaultTranslation?.matching?.matchingRequiredError}
                                </span>
                            )}
                        </div>
                    ))}
                {/* an organizer can become participant when : event is published and organizer doesn't already have participant profile */}
                {!participantData && eventRoles.isOrganizer && event.status === 'public' && (
                    <div onDragStart={preventDefaultDrag} className="edit-section-container event-profile">
                        <Grid container alignItems="center" className="smallContainer">
                            <div onDragStart={preventDefaultDrag} className="edit-section-header">
                                <h3>{translation?.myAccountPage.enableParticipantProfile}</h3>
                                <IconButton color="secondary" onClick={this.handleOpenCreateEventProfileDialog}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </div>
                        </Grid>
                    </div>
                )}
                {editUserDialog && <EditUserInformation opened={editUserDialog} closeDialog={this.handleCloseDialog} />}
                {editParticipantDialog && (
                    <EditParicipantInformation
                        opened={editParticipantDialog}
                        closeDialog={this.handleCloseDialog}
                        participant={participantData}
                    />
                )}
                {editExhibitorContactPersonDialog && (
                    <EditExhibitorContactPersonInformation
                        opened={editExhibitorContactPersonDialog}
                        closeDialog={this.handleCloseDialog}
                        exhibitor={exhibitorData}
                    />
                )}
                {editScholarContactPersonDialog && (
                    <EditScholarContactPersonInformation
                        opened={editScholarContactPersonDialog}
                        closeDialog={this.handleCloseDialog}
                        exhibitor={exhibitorData}
                    />
                )}
                {createParticipantProfileDialog && (
                    <CreateOrganizerParticipantProfile
                        opened={createParticipantProfileDialog}
                        closeDialog={this.handleCloseDialog}
                    />
                )}
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        c
                        dialogTitle={
                            translation?.generalText?.unsavedChangesTitle ||
                            defaultTranslation?.generalText?.unsavedChangesTitle
                        }
                        dialogDescription={
                            translation?.generalText?.unsavedChangesText ||
                            defaultTranslation?.generalText?.unsavedChangesText
                        }
                        dialogConfirmButtonLabel={
                            translation?.generalText?.save || defaultTranslation?.generalText?.save
                        }
                        dialogCancelButtonLabel={
                            translation?.generalText?.cancel || defaultTranslation?.generalText?.cancel
                        }
                        handleConfirm={this.handleSubmitAnswers}
                    />
                )}

                {isPasswordCheckOn && (
                    <ConfirmPassword
                        open={isPasswordCheckOn}
                        password={password}
                        passwordError={passwordError}
                        platformLanguage={platformLanguage}
                        handleChangePassword={this.handleChangePassword}
                        dialogTitle={
                            translation.emailUpdate.confirmPassword || defaultTranslation.emailUpdate.confirmPassword
                        }
                        handleConfirm={this.handlePasswordCorrection}
                        closeConfirm={this.closeConfirmPassword}
                        dialogContent={
                            translation.emailUpdate.confirmPasswordDescription ||
                            defaultTranslation.emailUpdate.confirmPasswordDescription
                        }
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        event: state.event.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        eventMatching: state.organizer.eventMatching,
        loadingMatchingAnswers: state.user.loadingGetMatches,
        isMobile: state.layout.isMobile,
        isTablet: state.layout.isTablet,
        languages: state.languages,
        platformLanguage: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        registrationFields: state.event.registrationFields,
        userEventProfile: state.userEventProfile.data,
        triggerReloadUserProfile: state.userEventProfile.triggerReloadUserProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserUserData: () => dispatch(actions.refreshUserData()),
        closeTopNavigation: () => dispatch(actions.topNavClose()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        onSetPlatformLanguage: (languageCode) => dispatch(actions.setPlatformLanguage(languageCode)),
        onGetQuestionnaires: (eventId) => dispatch(actions.getQuestionnaires(eventId)),
        onGetUserMatchingAnswers: (eventId, questionnaireId, data) =>
            dispatch(actions.getUserMatchingAnswers(eventId, questionnaireId, data)),
        onSaveUpdateUserAnswers: (eventId, questionnaireId, data) =>
            dispatch(actions.saveUpdateUserAnswers(eventId, questionnaireId, data)),
        onRemoveUserMatchingData: (eventId) => dispatch(actions.removeUserMatchingData(eventId)),
        getEventRegistrationFields: (eventId) => dispatch(actions.getEventRegistrationFields(eventId)),
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccount));
