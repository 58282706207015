import React, {useRef, useState} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {TextValidator} from 'react-material-ui-form-validator';
import classnames from 'classnames';
import {useSelector} from 'react-redux';

const RadioFieldLoginRegistration = (props) => {
    const {field, isTooltipDisabled} = props;
    const inputValidator = useRef(null);

    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const handleChange = (e) => {
        const {name, value} = e.target;
        props.handleChange(name, value);
    };

    const isValid = inputValidator?.current?.isValid();
    const isRequired = field.validators.includes('required');

    return (
        <>
            <TextValidator
                value={field.value}
                validators={field.validators}
                errorMessages={field.errorMessages}
                ref={inputValidator}
                className={`hidden-input-and-only-display-error-message ${field.type}-${field.name}-mo-error`}
            />
            <FormControl component="fieldset" fullWidth={true}>
                {!isTooltipDisabled && (
                    <FormLabel className="break-word " component="legend">
                        {field.label}
                    </FormLabel>
                )}
                <div
                    className={classnames(
                        'registration-radio-group',
                        {'registration-usage': isTooltipDisabled},
                        {
                            'error-border': isValid === false,
                        }
                    )}
                >
                    <RadioGroup aria-label={field.label} name={field.name} value={field.value} onChange={handleChange}>
                        {isTooltipDisabled && (
                            <FormLabel className="break-word registration-usage" component="legend">
                                {field.label}{' '}
                                {!isRequired
                                    ? ` (${
                                          translation.newLoginRegistrations.optional ||
                                          defaultTranslation.newLoginRegistrations.optional
                                      })`
                                    : ''}
                            </FormLabel>
                        )}
                        {field.options.map((option) => {
                            return (
                                <div
                                    className={classnames(
                                        'input-wrapper',
                                        {
                                            'show-tooltip': option.length > 25,
                                        },
                                        {'extra-padding': isTooltipDisabled}
                                    )}
                                >
                                    {!isTooltipDisabled && (
                                        <div className="tooltip">
                                            <div>
                                                <p>{option}</p>
                                            </div>
                                        </div>
                                    )}

                                    <FormControlLabel
                                        value={option}
                                        control={<Radio color="secondary" />}
                                        label={option}
                                        key={option}
                                    />
                                </div>
                            );
                        })}
                    </RadioGroup>
                </div>
            </FormControl>
        </>
    );
};

RadioFieldLoginRegistration.defaultProps = {
    isTooltipDisabled: false,
};

export default RadioFieldLoginRegistration;
