import React, {PureComponent} from 'react';
import moment from 'moment/moment';
import {getEventDaysArray, getFormattedMonth, preventDefaultDrag, getOngoingEventDays} from '../../Utils/utils';
import {connect} from 'react-redux';
import AuditoriumSingleDayProgram from './AuditoriumSingleDayProgram';
import '../../CSS/_layout.scss';
class EventProgram extends PureComponent {
    state = {
        eventDays: [],
        activeBuildingIdPerDay: [],
    };

    componentDidMount() {
        const {eventInfo} = this.props;

        let eventDays = eventInfo.ongoingEvent
            ? getOngoingEventDays(eventInfo).map((e) => e.name)
            : getEventDaysArray(eventInfo.eventStartDate, eventInfo.eventEndDate);

        if (eventInfo.hideProgramEmptyDays) {
            eventDays = this._getEventDaysWithProgram();
        }

        let activeBuildingIdPerDay = [];
        // for each day, we initialize the buildingId with the first Building that has a program in that specific day
        // whenever a user clicks on a building, we modify the buildingId only for that specific day
        if (eventInfo.hasVillage) {
            const showfloorBuildings = eventInfo.buildings.filter((building) => building.type === 'conference');

            eventDays.forEach((day) => {
                let buildingId = '';
                const buildingWithProgram = showfloorBuildings.find((building) => {
                    const isCurrentBuildingDayProgramEmpty = this.isCurrentBuildingDayProgramEmpty(building, day);
                    // if the building has a program in the current day (program is not empty)
                    // we found the first building with program
                    return !isCurrentBuildingDayProgramEmpty;
                });
                if (buildingWithProgram) {
                    buildingId = buildingWithProgram._id;
                }
                activeBuildingIdPerDay.push(buildingId);
            });
        }
        this.setState({
            eventDays: eventDays,
            activeBuildingIdPerDay: activeBuildingIdPerDay,
        });
    }

    _getEventDaysWithProgram = () => {
        const {eventInfo} = this.props;

        let eventDays = eventInfo.ongoingEvent
            ? getOngoingEventDays(eventInfo).map((e) => e.name)
            : getEventDaysArray(eventInfo.eventStartDate, eventInfo.eventEndDate);

        let auditoriums = [];
        if (eventInfo.hasVillage) {
            // for each building of type showfloor, push in the auditoriums array all of the auditoriums of that building
            const showfloorBuildings = eventInfo.buildings.filter((building) => building.type === 'conference');
            showfloorBuildings.forEach((building) => {
                auditoriums.push(...building.auditoriums);
            });
        } else {
            auditoriums = eventInfo.auditoriums;
        }

        let eventDaysWithProgram = [];

        if (auditoriums) {
            eventDays.forEach((day, index) => {
                auditoriums.forEach((auditorium) => {
                    const currentAuditoriumProgram = auditorium.displayProgram[index].program;
                    if (currentAuditoriumProgram.length > 0) {
                        if (!eventDaysWithProgram.includes(day)) {
                            eventDaysWithProgram.push(day);
                        }
                    }
                });
            });
        }

        return eventDaysWithProgram;
    };

    isCurrentDayProgramEmpty = (day) => {
        const {eventInfo} = this.props;
        const currentDayTimeslots = [];

        if (eventInfo.hasVillage) {
            const showfloorBuildings = eventInfo.buildings.filter((building) => building.type === 'conference');
            showfloorBuildings.forEach((building) => {
                building.auditoriums.forEach((auditorium) => {
                    const program = auditorium.displayProgram.find((program) => program.date === day).program;
                    currentDayTimeslots.push(...program);
                });
            });
        } else {
            eventInfo.auditoriums.forEach((auditorium) => {
                const program = auditorium.displayProgram.find((program) => program.date === day).program;
                currentDayTimeslots.push(...program);
            });
        }

        return currentDayTimeslots.length === 0;
    };

    isCurrentBuildingDayProgramEmpty = (building, day) => {
        const currentDayTimeslots = [];
        building.auditoriums.forEach((auditorium) => {
            const program = auditorium.displayProgram.find((program) => program.date === day).program;
            currentDayTimeslots.push(...program);
        });
        return currentDayTimeslots.length === 0;
    };

    isCurrentAuditoriumDayProgramEmpty = (auditorium, day) => {
        const program = auditorium.displayProgram.find((program) => program.date === day).program;
        return program.length === 0;
    };

    setActiveBuildingPerDay = (dayIndex, buildingId) => (e) => {
        let updatedActiveBuildingIdPerDay = [...this.state.activeBuildingIdPerDay];
        updatedActiveBuildingIdPerDay[dayIndex] = buildingId;
        this.setState({
            activeBuildingIdPerDay: updatedActiveBuildingIdPerDay,
        });
    };

    renderBuildingDayTimeslots = (building, isActiveBuilding, day, brandingTranslation) => {
        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`single-building-program ${isActiveBuilding ? 'active' : ''}`}
                key={building._id}
            >
                <h3 className="building-name">{building.name}</h3>
                {building.auditoriums.map((auditorium) => {
                    const isCurrentAuditoriumProgramEmpty = this.isCurrentAuditoriumDayProgramEmpty(auditorium, day);
                    if (isCurrentAuditoriumProgramEmpty) {
                        return null;
                    }
                    return (
                        <AuditoriumSingleDayProgram
                            key={auditorium._id}
                            auditorium={auditorium}
                            displayedDate={day}
                            brandingTranslation={brandingTranslation}
                        />
                    );
                })}
            </div>
        );
    };

    render() {
        const {eventDays} = this.state;
        const {eventInfo, translation, brandingTranslation, isRtlLanguage} = this.props;

        if (!eventInfo.showProgram) {
            return null;
        }

        let showfloorBuildings = [];
        if (eventInfo.hasVillage) {
            showfloorBuildings = eventInfo.buildings.filter((building) => building.type === 'conference');
        }

        return (
            <div onDragStart={preventDefaultDrag} className="homepage-container">
                <div onDragStart={preventDefaultDrag} className="program-wrapper">
                    {this.props.breakpoint !== 'desktop' && (
                        <p onDragStart={preventDefaultDrag} className="event-description-container-title">
                            {translation?.homepage.tabProgram}
                        </p>
                    )}
                    {eventDays?.length > 0 ? (
                        <>
                            {eventDays.map((day, dayIndex) => {
                                const momentDay = moment(day).startOf('day');
                                const isCurrentDayProgramEmpty = this.isCurrentDayProgramEmpty(day);
                                return (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        key={day}
                                        className="single-day-program-wrapper"
                                    >
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className={`program-date ${isRtlLanguage ? 'pl-30' : 'pr-30'}`}
                                        >
                                            <div onDragStart={preventDefaultDrag} className="current-date">
                                                <div onDragStart={preventDefaultDrag} className="first">
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="day"
                                                    >
                                                        {momentDay.date()}
                                                    </span>
                                                </div>
                                                <div onDragStart={preventDefaultDrag} className="second">
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="month"
                                                    >
                                                        {getFormattedMonth(day, translation)}
                                                    </span>
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="year"
                                                    >
                                                        {momentDay.year()}
                                                    </span>
                                                </div>
                                            </div>
                                            {eventInfo.hasVillage && (
                                                <div onDragStart={preventDefaultDrag} className="buildings-list">
                                                    {showfloorBuildings.map((building) => {
                                                        const isCurrentBuildingDayProgramEmpty =
                                                            this.isCurrentBuildingDayProgramEmpty(building, day);
                                                        if (isCurrentBuildingDayProgramEmpty) {
                                                            return null;
                                                        }

                                                        const isActiveBuilding =
                                                            building._id ===
                                                            this.state.activeBuildingIdPerDay[dayIndex];
                                                        return (
                                                            <div
                                                                className={`name ${isActiveBuilding ? 'active' : ''}`}
                                                                key={building._id}
                                                                onClick={this.setActiveBuildingPerDay(
                                                                    dayIndex,
                                                                    building._id
                                                                )}
                                                            >
                                                                <span>{building.name}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                        {isCurrentDayProgramEmpty ? (
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="auditoriums-single-day-program-wrapper"
                                            >
                                                <div
                                                    onDragStart={preventDefaultDrag}
                                                    className="single-auditorium-program"
                                                >
                                                    <p onDragStart={preventDefaultDrag} className="note">
                                                        {brandingTranslation.homepageNoProgramText}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="auditoriums-single-day-program-wrapper"
                                            >
                                                {eventInfo.hasVillage
                                                    ? showfloorBuildings.map((building) => {
                                                          const isCurrentBuildingDayProgramEmpty =
                                                              this.isCurrentBuildingDayProgramEmpty(building, day);
                                                          if (isCurrentBuildingDayProgramEmpty) {
                                                              return null;
                                                          }
                                                          const isActiveBuilding =
                                                              building._id ===
                                                              this.state.activeBuildingIdPerDay[dayIndex];
                                                          if (
                                                              this.props.isMobile ||
                                                              (!this.props.isMobile && isActiveBuilding)
                                                          ) {
                                                              return this.renderBuildingDayTimeslots(
                                                                  building,
                                                                  isActiveBuilding,
                                                                  day,
                                                                  brandingTranslation
                                                              );
                                                          }
                                                          return null;
                                                      })
                                                    : eventInfo.auditoriums.map((auditorium) => {
                                                          return (
                                                              <AuditoriumSingleDayProgram
                                                                  key={auditorium._id}
                                                                  auditorium={auditorium}
                                                                  displayedDate={day}
                                                                  brandingTranslation={brandingTranslation}
                                                              />
                                                          );
                                                      })}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <p onDragStart={preventDefaultDrag} className="note">
                            {eventInfo.brandingData.homepageNoProgramText}
                        </p>
                    )}
                </div>
                {this.props.breakpoint === 'desktop' && (
                    <div
                        className="go-to-tab-button secondary-color-btn"
                        onClick={this.props.setActiveProgramTabIndex(0, true)}
                        tabIndex={1}
                    >
                        <span>{translation?.homepage.tabIntro}</span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventInfo: state.event.eventInfo,
        isMobile: state.layout.isMobile,
        translation: state.languages.translations[state.languages.eventLanguage],
        // defaultTranslation: state.languages.translations['en'],
        isRtlLanguage: state.languages.isRtlLanguage,
    };
};

export default connect(mapStateToProps, null)(EventProgram);
