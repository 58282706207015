import React, {useEffect, useState} from 'react';

import {preventDefaultDrag, doesOrganizerHasParticipantProfile} from 'Utils/utils';
import {isMobile} from 'react-device-detect';
import {ReactComponent as Online} from 'Pages/Event/ExhibitorVote/Helpers/svg/online-count-icon.svg';
import {Checkbox, Tooltip} from '@material-ui/core';
import {usePlatformTranslation} from 'services/hooks';
import {useSelector} from 'react-redux';
import Spinner from '../../SmallLayoutComponents/Spinner';

const SinglePosterMobile = ({
    exhibitor,
    showfloorId,
    handleSetActiveExhibitor,
    translation,
    defaultTranslation,
    votingPrivalages,
    isVotingEnabled,
    votingData,
    handleChangeVote,
    voteLoading,
    exhibitorInProgress,
    singleExhibitorsVoteCount,
}) => {
    //redux
    const voteText = usePlatformTranslation((state) => state?.voting?.voteText);
    const votedText = usePlatformTranslation((state) => state?.voting?.votedText);
    const votesText = usePlatformTranslation((state) => state?.voting?.votesText);
    const userEventProfile = useSelector((state) => state.userEventProfile?.data);
    const toVotePleaseEnableParticipantProfile = usePlatformTranslation(
        (state) => state?.voting?.toVotePleaseEnableParticipantProfile
    );
    const [isVoted, setIsVoted] = useState(false);

    const getShowfloorVoteData = () => {
        let isUserVoted = votingData?.userVotes?.find((exhibitorVote) => exhibitorVote === exhibitor?._id);
        setIsVoted(!!isUserVoted);
    };

    //get the number of votes
    useEffect(() => {
        getShowfloorVoteData();
    }, [showfloorId, exhibitor?._id, votingData]);

    return (
        <div onDragStart={preventDefaultDrag} className="exhibitor-container scholar">
            <div onDragStart={preventDefaultDrag} className={`poster-title-wrapper ${isMobile ? 'mobile' : ''}`}>
                <p onClick={handleSetActiveExhibitor(exhibitor._id)}>
                    <span className={`ellipsis-second-line min-width-250`}>
                        {exhibitor?.booth.studyTitle
                            ? exhibitor?.booth.studyTitle
                            : translation?.showfloor.titleNotAvailable ||
                              defaultTranslation?.showfloor.titleNotAvailable}
                    </span>

                    {isVotingEnabled && votingPrivalages && singleExhibitorsVoteCount && (
                        <span className={`vote-number ${isMobile ? 'mobile' : ''}`}>
                            <Online />
                            {(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id]) || 0}{' '}
                            {singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id] > 1
                                ? votesText
                                : voteText}
                        </span>
                    )}
                </p>
                {isVotingEnabled &&
                    (doesOrganizerHasParticipantProfile(userEventProfile) ? (
                        <div className={`vote-action`} onClick={handleChangeVote(exhibitor?._id)}>
                            {voteLoading && exhibitorInProgress === exhibitor?._id && (
                                <div className={`spinner-container-vote vote-banner-top`}>
                                    <Spinner />
                                </div>
                            )}
                            <Checkbox checked={isVoted ?? ''} />{' '}
                            <span className={`vote-text`}> {isVoted ? votedText : voteText}</span>
                        </div>
                    ) : (
                        <Tooltip title={toVotePleaseEnableParticipantProfile} arrow>
                            <div className={`vote-action disabled`}>
                                {voteLoading && exhibitorInProgress === exhibitor?._id && (
                                    <div className={`spinner-container-vote vote-banner-top`}>
                                        <Spinner />
                                    </div>
                                )}
                                <Checkbox checked={isVoted ?? ''} disabled />{' '}
                                <span className={`vote-text`}> {isVoted ? votedText : voteText}</span>
                            </div>
                        </Tooltip>
                    ))}
            </div>
        </div>
    );
};

export default SinglePosterMobile;
