import React, {useEffect, useState, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import {SinglePoll} from './SinglePoll';
import './AuditorumPollsOutput.scss';
import axios from '../../store/axios-instance';
import Spinner from '../../SmallLayoutComponents/Spinner';
import {
    connectToTimeslotPoll,
    disconnectToTimeslotPoll,
    handlePollUpdate,
    handlePollCreate,
    handlePollDelete,
} from '../../Api/socketApi';
import {Scrollbars} from 'react-custom-scrollbars';
import colors from '../../CSS/_variables.module.scss';
import EmptyBoxIcon from '../../Images/svg/EmptyBoxIcon';
import {preventDefaultDrag} from '../../Utils/utils';

const AuditorumPollsOutput = ({auditoriumId, programId, pollsUpdate, translation, isRtlLanguage}) => {
    const eventId = useSelector((state) => state.event.eventId);
    const userId = useSelector((state) => state.user.data._id);
    const [polls, setPolls] = useState([]);
    const [isLoading, setIsLoaded] = useState(true);
    const pollsRef = useRef(polls);
    pollsRef.current = polls;

    const updatePolls = (polls) => {
        // pollsUpdate function is used to always send the polls to the Auditorium and AuditoriumArchive Components
        pollsUpdate(polls);
        setPolls(polls);
    };

    const pollCreate = useCallback(() => {
        handlePollCreate(({poll: upcomingPoll}) => {
            updatePolls([...pollsRef.current, upcomingPoll]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pollDelete = useCallback(() => {
        handlePollDelete(({pollId: deletedID}) => {
            const newPolls = pollsRef.current.reduce((acc, poll) => {
                if (poll._id !== deletedID) acc.push(poll);
                return acc;
            }, []);
            updatePolls(newPolls);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pollUpdate = useCallback(() => {
        handlePollUpdate(({poll: upcomingPoll}) => {
            if (
                !pollsRef.current.filter((poll) => {
                    return poll._id === upcomingPoll._id;
                })
            ) {
                return false;
            }
            const newPolls = pollsRef.current.map((poll) => {
                return poll._id === upcomingPoll._id ? upcomingPoll : poll;
            });
            updatePolls(newPolls);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getPolls = async () => {
            try {
                const response = await axios.get(
                    `/polls/event/${eventId}/auditoriums/${auditoriumId}/program/${programId}`
                );
                updatePolls(response.data.polls);
                setIsLoaded(false);
            } catch (err) {
                updatePolls([]);
                setIsLoaded(false);
            }
        };
        getPolls();
        // eslint-disable-next-line
    }, [auditoriumId, programId, eventId]);

    useEffect(() => {
        connectToTimeslotPoll(userId, programId);
        return () => disconnectToTimeslotPoll(userId, programId);
    }, [programId, userId]);

    useEffect(() => {
        pollCreate();
        pollUpdate();
        pollDelete();
    }, [pollCreate, pollDelete, pollUpdate, programId, userId]);

    const renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `rgb(137,137,137)`,
            left: 0,
        };
        return (
            <div
                onDragStart={preventDefaultDrag}
                className="grey-scroll"
                style={{...style, ...thumbStyle}}
                {...props}
            />
        );
    };

    const renderView = ({style, ...props}) => {
        let viewStyle = {
            padding: 10,
        };

        if (isRtlLanguage) {
            viewStyle = {...viewStyle, marginLeft: style.marginRight, marginRight: 0};
        }
        return <div onDragStart={preventDefaultDrag} className="box" style={{...style, ...viewStyle}} {...props} />;
    };

    return (
        <div onDragStart={preventDefaultDrag} className="polls-container">
            {isLoading ? (
                <Spinner />
            ) : polls.some((i) => !i.hidden) ? (
                <Scrollbars
                    className="scrollbar"
                    renderThumbVertical={renderThumb}
                    renderView={renderView}
                    style={{width: '100%', height: '100%'}}
                >
                    {polls.map((poll) => {
                        return poll.hidden ? null : (
                            <SinglePoll
                                key={poll._id}
                                poll={poll}
                                userId={userId}
                                programId={programId}
                                translation={translation}
                            />
                        );
                    })}
                </Scrollbars>
            ) : (
                <div onDragStart={preventDefaultDrag} className="no-polls">
                    <p>{translation?.polls?.noPolls || 'No polls were added yet.'}</p>
                    <EmptyBoxIcon
                        fillPrimary={colors.primary}
                        fillPrimaryDark={colors.primaryDark}
                        fillGreyLight={colors.lightGrey}
                        fillGrey={colors.grey}
                    />
                </div>
            )}
        </div>
    );
};

export default AuditorumPollsOutput;
