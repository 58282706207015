import React from 'react';
import {
    connectToPrivateChat,
    privateMessageReceived,
    disconnectFromPrivateChat,
    connectToPrivateChatStream,
    disconnectFromPrivateChatStream,
    deregisterPrivateMessageReceived,
} from '../../../../Api/socketApi';
import axios from '../../../../store/axios-instance';
import * as actions from '../../../../store/actions/index';
import {connect} from 'react-redux';
import {getTimeAgoString, preventDefaultDrag} from '../../../../Utils/utils';
import SendPrivateChatMessage from './SendPrivateChatMessage';
import ChatTitle from './ChatTitle';
import SingleChatNoMessages from '../SingleChatNoMessages';
import VideoConference from '../VideoConference/VideoConference';
import ChatCreateMeeting from '../Meetings/CreateMeeting';
import ChatMeetingBanner from '../Meetings/MeetingBanner';
import RenderChatMessage from '../RenderChatMessage';
import {VideoMeetingJoinBanner} from '../VideoMeetingJoinBanner';
import Spinner from '../../../../SmallLayoutComponents/Spinner';

class PrivateChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timestamp: 'no timestamp yet',
            messages: [],
            chatMembers: [],
            showVideoConference: false,
            displayCreateMeetingDialog: false,
            fullScreenVideoConference: false,
            userSentNewMessage: 'no',
            receivedMessagesForChatId: '',
            capture: null,
            playback: null,
            selfPlayback: null,
            privateChatPartnerData: '',
            meetingData: '',
            hasMeeting: false,
            loadingMessages: false,
        };
        this.newMessageRef = React.createRef();
    }

    componentDidMount() {
        // set the chat members
        this.setChatData();
        // connect to chat By Socket
        connectToPrivateChat(this.props.activeChatRoomId, this.props.user._id);
        // load all the messages though API call. Save them to state.
        this.onGetPrivateChatMessages(this.props.activeChatRoomId);
        this.props.readMessagesForPrivateChat(this.props.activeChatRoomId);
        // listen to socket for a new message and save it to state
        privateMessageReceived((err, data) => {
            this.setState(
                {
                    messages: [...this.state.messages, data.message],
                },
                () => {
                    this.scrollToBottom();
                }
            );
            if (data.message.user === this.props.user._id) {
                this.setState(
                    {
                        userSentNewMessage: 'yes',
                    },
                    () => {
                        this.setState({
                            userSentNewMessage: 'no',
                        });
                    },
                    1000
                );
            } else {
                this.setState({
                    userSentNewMessage: 'no',
                });
            }
        });
        this.setMeetingData();
        // we force update once every 60 seconds so that the "time ago strings" get updated even if the users don't chat between them
        this.interval = setInterval(() => this.forceUpdate(), 60000);
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            // set the chat members
            this.setChatData();
            // close the conference
            this.props.closeVideoConference(this.props.videoConference);
            // disconnect from previous chat & chat stream
            disconnectFromPrivateChat(prevProps.activeChatRoomId, this.props.user._id);
            disconnectFromPrivateChatStream(this.props.user._id, prevProps.activeChatRoomId);
            // connect to new chat
            connectToPrivateChat(this.props.activeChatRoomId, this.props.user._id);
            this.props.readMessagesForPrivateChat(this.props.activeChatRoomId);
            // get the new messages for the current private chat
            this.onGetPrivateChatMessages(this.props.activeChatRoomId);
            this.setState({showVideoConference: false, fullScreenVideoConference: false});
            this.setMeetingData();
        }
        if (this.props.user.meetings !== prevProps.user.meetings) {
            this.setMeetingData();
        }

        if (!this.state.hasMeeting) {
            document.body.classList.add('no-meeting');
        } else {
            document.body.classList.remove('no-meeting');
        }
    }

    componentWillUnmount() {
        deregisterPrivateMessageReceived();
        this.props.closeVideoConference(this.props.videoConference);
        disconnectFromPrivateChatStream(this.props.user._id, this.props.activeChatRoomId);
        disconnectFromPrivateChat(this.props.activeChatRoomId, this.props.user._id);
        clearInterval(this.interval);
        document.body.classList.remove('no-meeting');
    }

    handleCloseDialog = () => this.setState({displayCreateMeetingDialog: false});
    handleOpenCreateMeetingDialog = () => this.setState({displayCreateMeetingDialog: true});

    getUserMessageData = (userId) => {
        const {chatMembers} = this.state;
        const userData = chatMembers.find((chatMember) => chatMember?._id === userId);
        if (userData === undefined) {
            const defaultUserData = {
                first: 'Deleted',
                last: 'User',
            };
            return defaultUserData;
        }
        return userData;
    };

    scrollToBottom = () => {
        let messagesContainer = document.getElementById('chat-messages-container');
        if (messagesContainer) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    };

    onGetPrivateChatMessages = (roomId) => {
        this.setState({loadingMessages: true});
        axios({method: 'get', url: '/private-chat/' + roomId + '/messages'})
            .then((response) => {
                let privateChatMessages = response.data.messages;
                this.setState({messages: privateChatMessages, loadingMessages: false}, () => {
                    this.setState({receivedMessagesForChatId: roomId});
                    this.scrollToBottom();
                });
            })
            .catch(() => {
                this.setState({loadingMessages: false});
            });
    };

    setChatData = () => {
        let chatData = this.props.user.privateChats.find(
            (privateChat) => privateChat.chat._id === this.props.activeChatRoomId
        );
        const privateChatData = chatData.chat;
        const privateChatMembers = [privateChatData.user_1, privateChatData.user_2];
        this.setState({
            chatMembers: privateChatMembers,
        });
    };

    openVideoConference = () => {
        const {isLargeScreen, user, activeChatRoomId, videoConference, stopCalling} = this.props;
        stopCalling();

        this.setState({showVideoConference: true});
        if (isLargeScreen && !this.props.maximizeChats.seeExpandedVideoConference) {
            // only if is on a Large screen and the chat is not expanded
            // we want to expand it
            this.props.seeExpandedVideoConference();
        }
        const currentChat = user.privateChats.find((privateChat) => privateChat.chat._id === activeChatRoomId);
        const companionId =
            currentChat.chat.user_1._id === user._id ? currentChat.chat.user_2._id : currentChat.chat.user_1._id;
        // connect to private chat stream in order to update the stream-participants-updated
        // list when starting a videocall then start the call
        connectToPrivateChatStream(user._id, activeChatRoomId);
        this.props.openVideoConference(videoConference, user, [companionId], activeChatRoomId);
    };

    setMeetingData = () => {
        const meetingData = this.props.user.meetings
            .filter((el) => el.privateChat === this.props.activeChatRoomId)
            .pop();

        let hasMeeting = false;
        if (meetingData) {
            const now = new Date().getTime();
            const end = new Date(meetingData.end).getTime();

            if (now < end && meetingData.status !== 'canceled') {
                hasMeeting = true;
            }
        }

        let privateChatData = this.props.user.privateChats.find(
            (privateChat) => privateChat.chat._id === this.props.activeChatRoomId
        ).chat;

        let privateChatPartnerData = null;
        if (this.props.user?._id === privateChatData.user_1?._id) {
            privateChatPartnerData = privateChatData.user_2;
        } else {
            privateChatPartnerData = privateChatData.user_1;
        }

        this.setState({
            privateChatPartnerData: privateChatPartnerData,
            meetingData: meetingData,
            hasMeeting: hasMeeting,
        });
    };

    render() {
        let lastChatMessage;
        const {
            user,
            translation,
            videoConference,
            platformLanguage,
            activeChatRoomId,
            showBanner,
            openCreateGroupChatDialog,
        } = this.props;
        const {
            messages,
            privateChatPartnerData,
            displayCreateMeetingDialog,
            meetingData,
            hasMeeting,
            showVideoConference,
            loadingMessages,
        } = this.state;

        if (messages.length) {
            lastChatMessage = messages[messages.length - 1].text;
        }

        const listMessages = messages.map((message, index) => {
            const {text} = message;
            const codedQuotationMark = '&#x27;';
            const videoCallMessage = `I${codedQuotationMark}ve set up a Video Meeting room. Please click here to join it.`;
            const timeAgoString = getTimeAgoString(message.createdAt, platformLanguage);
            const userMessageData = this.getUserMessageData(message.user);
            let showUserDetails = true;
            // if we have consecutive messages from the same user
            // don't display their details all over again
            // also if the user that initiated a video call sends a new ChatMessage
            // dispay the user details after the "{user} started a video call" message
            if (
                index > 0 &&
                messages[index - 1].user === message.user &&
                messages[index - 1].text !== videoCallMessage
            ) {
                showUserDetails = false;
            }
            // this makes videoCallMessage active [clickable, coloured, pointer cursor]
            // if it's the last one in the conversation, if the JOIN meeting banner is shown
            // and if the user has NOT joined the conference yet
            // else when the user joins the conference or there is no conference available
            // all videoCallMessages will be deactivated [non-clickable, black]
            const lastChatMessageActive =
                messages.map((el) => el.text).lastIndexOf(videoCallMessage) === index &&
                showBanner &&
                !videoConference.isActive;

            return (
                <li key={message._id} className={user._id === message.user ? 'message me' : 'message'}>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`comment-header ${text === videoCallMessage ? 'videocall-message' : ''}`}
                    >
                        <RenderChatMessage
                            message={message}
                            timeString={timeAgoString}
                            messageData={userMessageData}
                            showUserDetails={showUserDetails}
                            lastChatMessageActive={lastChatMessageActive}
                            startConference={this.openVideoConference}
                        />
                    </div>
                </li>
            );
        });

        return (
            <>
                {/* send the banner is shown prop and adjust the height of 
                single-chat-container by applying the join-banner class  */}
                {showBanner && !openCreateGroupChatDialog && !videoConference.isActive && (
                    <VideoMeetingJoinBanner startConference={this.openVideoConference} />
                )}
                <div
                    onDragStart={preventDefaultDrag}
                    className={`single-chat-container ${
                        showBanner && !openCreateGroupChatDialog && !videoConference.isActive ? 'join-banner' : ''
                    }`}
                >
                    {videoConference.isActive && (
                        <VideoConference
                            translation={translation}
                            members={[privateChatPartnerData]}
                            userId={user._id}
                            chatId={activeChatRoomId}
                        />
                    )}
                    {privateChatPartnerData && (
                        <ChatTitle
                            privateChatPartnerData={privateChatPartnerData}
                            handleOpenCreateMeetingDialog={this.handleOpenCreateMeetingDialog}
                            hasMeeting={hasMeeting}
                            lastChatMessage={lastChatMessage}
                        />
                    )}
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`private-chat-wrapper ${videoConference.isActive ? 'is-active' : ''}`}
                    >
                        {loadingMessages && <Spinner />}
                        {hasMeeting && !openCreateGroupChatDialog && !showBanner && (
                            <ChatMeetingBanner
                                meetingId={meetingData._id}
                                startConference={this.openVideoConference}
                                lastChatMessage={lastChatMessage}
                            />
                        )}
                        {messages.length > 0 ? (
                            <div onDragStart={preventDefaultDrag} className="scrollbar">
                                <div onDragStart={preventDefaultDrag} className="box">
                                    <div>
                                        <ul
                                            className="live-wall"
                                            id="chat-messages-container"
                                            data-length={messages.length >= 9 ? 'maximum-responsive' : ''}
                                        >
                                            <li className="empty-space-container"></li>
                                            {listMessages}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <SingleChatNoMessages translation={translation} />
                        )}
                    </div>
                    <SendPrivateChatMessage
                        joinBannerActive={showBanner && !openCreateGroupChatDialog}
                        videoConferenceActive={videoConference?.isActive}
                        joinConference={showVideoConference}
                        lastChatMessage={lastChatMessage}
                        startConference={this.openVideoConference}
                    />
                    {displayCreateMeetingDialog && (
                        <ChatCreateMeeting onClose={this.handleCloseDialog} open={displayCreateMeetingDialog} />
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        activeChatRoomPartnerId: state.user.topNavigation.activeChatRoomPartnerId,
        maximizeChats: state.user.maximizeChats,
        isLargeScreen: state.layout.isLargeScreen,
        platformLanguage: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        videoConference: state.videoConference,
        isRtlLanguage: state.languages.isRtlLanguage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        newPrivateChatMessage: (data) => dispatch(actions.newPrivateChatMessage(data)),
        readMessagesForPrivateChat: (chatId) => dispatch(actions.readMessagesForPrivateChat(chatId)),
        seeExpandedVideoConference: () => dispatch(actions.expandedChatsOnVideoConference()),
        openVideoConference: (conference, user, members, chatId) =>
            dispatch(actions.openVideoConference(conference, user, members, chatId)),
        closeVideoConference: (conference) => dispatch(actions.closeVideoConference(conference)),
        stopCalling: () => dispatch(actions.stopCalling()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateChat);
