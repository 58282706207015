const ru = {
    generalText: {
        upload: 'Загрузить',
        save: 'Сохранить',
        add: 'Добавить',
        remove: 'Удалить',
        close: 'ЗАКРЫТЬ',
        update: 'ОБНОВИТЬ',
        cancel: 'ОТМЕНИТЬ',
        confirm: 'Подтвердить',
        download: 'Загрузить',
        refresh: 'Обновить',
        back: 'Назад',
        submit: 'Подтвердить',
        send: 'Отправить',
        leave: 'Выйти',
        step1: 'Шаг 1',
        step2: 'Шаг 2',
        or: 'или',
        delete: 'Удалить',
        please: 'Пожалуйста',
        before: 'до',
        approve: 'Подтвердить',
        decline: 'Отклонить',
        start: 'Старт',
        stop: 'Стоп',
        resetFilter: 'Сбросить фильтр',
        apply: 'Применить',
        filters: 'Фильтры',
        unsavedChangesTitle: 'Несохраненные изменения',
        unsavedChangesText: 'У вас есть несохраненные изменения. Хотите сохранить?',
        selectAll: 'Выбрать все',
        unselectAll: 'Снять все ',
    },
    time: {
        justNow: 'только что',
        minute: 'минута',
        minutes: 'минуты',
        hour: 'час',
        hours: 'часы',
        day: 'день',
        days: 'дня',
        month: 'месяц',
        months: 'месяцы',
        yesterday: 'вчера',
        today: 'сегодня',
        tomorrow: 'завтра',
        monday: 'Понедельник',
        tuesday: 'Вторник',
        wednesday: 'Среда',
        thursday: 'Четверг',
        friday: 'Пятница',
        saturday: 'Суббота',
        sunday: 'Воскресение',
        january: 'Январь',
        february: 'Февраль',
        march: 'Март',
        april: 'Апрель',
        may: 'Май',
        june: 'Июнь',
        july: 'Июль',
        august: 'Август',
        september: 'Сентябрь',
        october: 'Октябрь',
        november: 'Ноябрь',
        december: 'Декабрь',
        januaryShort: 'Янв',
        februaryShort: 'Фев',
        marchShort: 'Мар',
        aprilShort: 'Апр',
        mayShort: 'Май',
        juneShort: 'Июнь',
        julyShort: 'Июль',
        augustShort: 'Авг',
        septemberShort: 'Сен',
        octoberShort: 'Окт',
        novemberShort: 'Ноя',
        decemberShort: 'Дек',
    },
    errors: {
        noFile: 'Файл не выбран',
        required: 'Обязательное к заполнению поле',
        emailNotValid: 'Адрес электронной почты недействителен',
        passwordMinLength: 'Пароль должен иметь минимум 8 символов',
        passwordMismatch: 'Несоответствие паролей',
        passwordIsSame: 'Ваш новый пароль должен отличаться от старого',
        noInvitationCode: 'Пожалуйста, введите код приглашения',
        imageFileNotSupported: 'Тип файла не поддерживается. Пожалуйста, используйте один из следующих вариантов:',
        phoneInvalid: 'Пожалуйста, введите действительный номер телефона. Например: +447517663928',
        linkInvalid: 'Пожалуйста, введите действительную ссылку (https://site.com)',
        facebookLinkInvalid: 'Пожалуйста, введите действительную ссылку (https://facebook.com/)',
        linkedinLinkInvalid: 'Пожалуйста, введите действительную ссылку (https://linkedin.com/)',
        twitterLinkInvalid: 'Пожалуйста, введите действительную ссылку (https://twitter.com/)',
        youtubeLinkInvalid: 'Введите действительную ссылку (https://www.youtube.com/)',
        youtubeOrVimeoLinkInvalid:
            'Пожалуйста, введите действительную ссылку на YouTube (https://www.youtube.com) или Vimeo (https://vimeo.com/) ',
        googleScholarLinkInvalid: 'Пожалуйста, введите действительную ссылку (https://scholar.google.com/)',
        researchGateLinkInvalid: 'Пожалуйста, введите действительную ссылку (https://www.researchgate.net/)',
        connectionProblemTextFirst: 'Возможно, у Вас проблема с подключением.',
        connectionProblemButton: 'НАЖМИТЕ ЗДЕСЬ',
        connectionProblemTextSecond: 'или перезагрузите страницу',
        fileTooLarge: 'Файл слишком большой/Максимальный размер файла 2Мб',
        fileTooLargeTextFirst: 'Файо слишком большой.',
        fileTooLargeTextSecond: 'Максимальный размер файла.',
        fileNameTooLongTextFirst: 'Название файла слишком длинное. Максимальная длина',
        fileNameTooLongTextSecond: 'символов.',
        maxWallInputLength: 'Вы использовали максимально допустимое количество символов — 1200.',
    },
    footer: {
        cookiesText:
            'Этот веб-сайт использует основные файлы cookie, которые необходимы для его работы. Он также использует сторонние файлы cookie, которые необходимы для работы некоторых функций нашего веб-сайта. Если вы хотите узнать больше или отозвать свое согласие на использование некоторых файлов cookie, обратитесь к нашей',
        cookiesLink: 'Политика Cookies',
        cookiesButton: 'ПРИНЯТЬ',
        privacyPolicy: 'Политика Конфиденциальности',
        cookies: 'Cookies',
        termsAndConditions: 'Правила и Условия',
        socialLinksTitle: 'Подпишитесь на нас:',
    },
    menu: {
        logIn: 'ВОЙТИ',
        logOut: 'ВЫЙТИ',
        scholarRegistration: 'Присоединиться с плакатом/исследованием',
        exhibitorRegistration: 'Присоединиться как экспонент',
        participantRegistration: 'Присоединиться как участник',
        createEvent: 'Организовать ивент',
        loginDisabledInfoText: 'Организатор закрыл вход в систему. Проверьте позже',
    },
    homepage: {
        organizedBy: 'ОРГАНИЗАТОР',
        date: 'ДАТА',
        location: 'ДОМЕН И МЕСТО ПРОВЕДЕНИЯ',
        price: 'ЦЕНА',
        tabIntro: 'ОБЗОР',
        tabProgram: 'ПРОГРАММА',
        tabSponsors: 'СПОНСОРЫ',
        tabPartners: 'ПАРТНЕРЫ',
        tabExhibitors: 'УЧАСТНИКИ',
        previousEventsSectionTitle: 'ПРЕДЫДУЩИЕ МЕРОПРИЯТИЯ',
    },
    socialButtons: {
        linkedinButton: 'Войти с помощью LinkedIn',
        loginTabLinkedinButton: 'с помощью LinkedIn',
        facebookButton: 'Войти с помощью Facebook',
        loginTabFacebookButton: 'с помощью Facebook',
        googleButton: 'Войти с помощью Google',
        loginTabGoogleButton: 'с помощью Google',
        twitterButton: 'Войти с помощью Twitter',
        loginTabTwitterButton: 'с помощью Twitter',
        socialButtonsLogInTitle: 'Присоединяйтесь, используя свой профиль в социальных сетях',
        socialButtonsSignUpTitle: '\nЗарегистрируйтесь, используя свой профиль в социальных сетях\n',
    },
    login: {
        inputEmail: 'Электронная почта',
        inputPassword: 'Пароль',
        rememberMeButton: 'Запомнить меня',
        forgotPasswordButton: 'Забыли пароль?',
        loginButton: 'ВОЙТИ',
        loginClosedButton: 'Доступ закрыт',
        loginTitleLowerCase: 'войдите',
        recoverPasswordDialogTitle: 'ВОССТАНОВИТЬ ПАРОЛЬ',
        recoverPasswordDialogRecoverButton: 'ВОССТАНОВИТЬ',
        errorIncorrectDates: 'Неправильный адрес электронной почты или пароль',
        orLogInWithEmail: 'или войти с помощью электронной почты',
        logInAsParticipantSecondText: 'как участник',
        logInAsExhibitorSecondText: 'как экспонент',
        logInAsPosterSecondText: 'с плакатом/исследованием',
        errorAccountNotFound: 'Невозможно найти ваш аккаунт myOnvent',
    },
    register: {
        inputFirstName: 'Имя',
        inputLastName: 'Фамилия',
        inputEmail: 'Электронная почта',
        inputPassword: 'Пароль',
        inputConfirmPassword: 'Подтвердить пароль',
        gdprTextFirst: 'Продолжая, вы соглашаетесь с нашей',
        gdprTextTerms: 'Политикой Использования',
        gdprTextSecond: 'и подтверждаете, что вы прочли нашу ',
        gdprTextCookies: 'Политику Cookies',
        gdprTextPrivacy: 'Политику Конфиденциальности',
        gdprTextAgree: 'Пожалуйста, согласитесь с политикой конфиденциальности',
        registerButton: 'Регистрация',
        signUpTitleLowerCase: 'регистрация',
        invitationCode: 'Код приглашения',
        registerNotAllowed: 'Регистрация закрыта',
        logInTitleFirst: 'Присоединиться к',
        logInTitleSecond: 'мероприятию',
        signUpTitleTextFirst: 'Впервые на',
        signUpTitleTextSecond: 'myOnvent',
        orFillInTheForm: 'или заполните форму ниже',
        uploadProfilePicture: 'Загрузить изображение профайла',
        editProfilePicture: 'Редактировать изображение профайла',
        errorAlreadyHaveMOAccount: 'У вас уже есть аккаунт на myOnvent',
        step1From2Text: 'Шаг 1 из 2',
        step2From2Text: 'Шаг 2 из 2',
        metaTitleScholarRegistration: 'Регистрация плаката/исследования',
    },
    newLoginRegistrations: {
        joinEvent: 'ПРИСОЕДИНИТЬСЯ К СОБЫТИЮ',
        loginWelcome: 'Добро пожаловать в myOnvent! Чтобы продолжить, выберите метод входа',
        continueButton: 'Продолжить',
        seemsLikeYouDontHaveMyOnventAccount: 'Похоже, у вас нет аккаунта myOnvent',
        uploadProfilePictureOptional: 'Загрузить фото профиля (необязательно)',
        createPlatformAccount: 'Создать учетную запись платформы',
        platformAccount: 'Учетная запись платформы',
        selectRole: 'Выберите роль',
        eventAccount: 'Учетная запись мероприятия',
        password: 'Пароль',
        confirmPassword: 'Подтвердите пароль',
        email: 'Электронная почта',
        passwordMustContain: 'Пароль должен содержать:',
        minimum8Characters: 'Минимум 8 символов',
        aLowerCaseLetter: 'Строчную букву',
        anUppercaseLetter: 'Заглавную букву',
        aNumber: 'Цифру',
        aSpecialCharacter: 'Специальный символ',
        firstName: 'Имя',
        lastName: 'Фамилия',
        weHaveSentAVerificationCodeToYourEmail: 'Мы отправили код подтверждения на вашу электронную почту',
        continueRegistration: 'Для продолжения регистрации',
        enterCodeBelow: 'введите код подтверждения ниже:',
        codeWillExpire: 'Этот код истекает через',
        codeExpired: 'Срок действия кода истек',
        codeNotReceived: 'Не получили код? Проверьте папку Спам',
        resendTimer: 'Отправить повторно через:',
        resendLabel: 'Отправить повторно',
        resendCodeLabel: 'Отправить код повторно',
        codeError: 'Введенный код неправильный.',
        codeErrorFix: 'Введите правильный код из своей электронной почты или отправьте новый код повторно',
        congrats: 'Поздравляем!',
        emailConfirmed: 'Ваш адрес электронной почты подтвержден:',
        imHereAs: 'Я здесь как:',
        participant: 'Участник',
        participantText: 'Для просмотра трансляции, общения, посещения стендов и плакатов/исследований.',
        exhibitor: 'Экспонент',
        exhibitorText: 'Для представления вашей компании и общения с участниками мероприятия',
        poster: 'Плакат/Исследование',
        posterText: 'Для представления научных исследований на академических или профессиональных конференциях',
        emailIsNotAllowed: 'Данный адрес электронной почты не разрешен для регистрации на данном мероприяти',
        didntReceiveEmail: 'Не получили электронное письмо?',
        sendAgain: 'Отправить снова ',
        incorrectPassword: 'Неправильный пароль',
        fileTypeError:
            'Тип файла не поддерживается. Используйте один из следующих типов: jpeg, jpg, jfif, gif или png.',
        fileSizeError: 'Слишком большой файл. Максимальный размер файла: 2 Мб.',
        pleaseCompleteAllRequiredFields: 'Заполните все обязательные поля',
        joinTheEvent: 'Присоединиться к мероприятию',
        joinTheEventSub: 'Для начала виртуального онлайн-мероприятия',
        changeEmail: 'ИЗМЕНИТЬ АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ',
        changeRole: 'ИЗМЕНИТЬ РОЛЬ',
        logout: 'ВЫЙТИ',
        headingUser: 'Добро пожаловать обратно',
        subHeadingUser: 'Кажется, у вас уже есть учетная запись на платформе',
        forgotPasswordHeadline: 'Забыли пароль',
        forgotPasswordText: 'Не беспокойтесь, мы вышлем вам дополнительные инструкции',
        checkYourEmail: 'Проверьте свою электронную почту',
        checkYourEmailText: 'Мы отправили ссылку для сброса пароля на вашу электронную почту:',
        headingNoUser: 'Вход',
        subHeadingNoUser: 'Добро пожаловать на myOnvent! Чтобы продолжить, выберите метод входа',
        resetPasswordButton: 'сбросить пароль',
        createNewPassword: 'создать новый пароль',
        createNewPasswordText: 'Ваш новый пароль должен отличаться от ранее использованных паролей',
        passwordReset: 'сброс пароля',
        passwordResetText: 'Ваш пароль был успешно сброшен. Щелкните ниже, чтобы снова войти.',
        registrationIsClosed: 'Регистрация для всех ролей закрыта.',
        chooseAnOption: 'Выберите вариант',
        yourAnswer: 'Твой ответ',
        firstNameError: 'Имя слишком короткое. Минимальная длина: 2',
        lastNameError: 'Фамилия слишком короткая. Минимальная длина: 2',
        recoveryPassword: 'Восстановить пароль',
        incorrectPasswordError:
            'Неправильный пароль. Попробуйте еще раз или нажмите "Забыли пароль?", чтобы изменить его.',
        optional: 'Опционально',
        spamEmailError: 'При создании вашей учетной записи возникла проблема. Пожалуйста, попробуйте позже.',
        invalidInput:
            "Недопустимый ввод. Используйте только буквы (A-Z, a-z), цифры (0-9), точку (.), апостроф ('), дефис (-) и пробелы. Первый символ должен быть буквой.",
    },
    emailUpdate: {
        changeEmail: 'Изменить адрес эл. почты',
        changeEmailDescription:
            'Прежде чем мы назначим новый адрес электронной почты вашей учетной записи, мы хотим убедиться, что это новый адрес электронной почты и он никому не принадлежит.',
        emailTakenError: 'Этот адрес электронной почты уже занят. Попробуйте другой.',
        enterTheConfirmationCode: 'Введите код подтверждения',
        verificationCodeSent: 'Код подтверждения отправлен:',
        checkYourSpamFolder: 'Не можете найти код? Проверьте папку со спамом',
        emailSuccess: 'Успех! Вы изменили адрес электронной почты, привязанный к вашей учетной записи',
        emailError: 'Ошибка. Повторите попытку позже',
        confirmPassword: 'Подтвердите пароль',
        confirmPasswordDescription: 'Для изменения адреса электронной почты подтвердите свой пароль:',
        newEmail: 'Новый адрес эл. почты',
        password: 'Пароль',
        confirm: 'Подтвердить',
        cancel: 'Отмена',
        change: 'Изменить',
    },

    agendaTranslations: {
        myAgenda: 'МОЯ ПРОГРАММА',
        all: 'Все',
        sessions: 'Сессии',
        meetings: 'Встречи',
        pastSlots: 'Прошлые слоты',
        meetingWith: 'Встреча с',
        pending: 'Ожидает',
        accepted: 'Принято',
        requested: 'Запрошено',
        canceled: 'Отменено',
        now: 'Сейчас',
        live: 'Прямой эфир',
        noMeetings: 'Пока нет запланированных встреч',
        noSessions: 'Пока нет сессий в вашей программе.',
        noUpcomingSessions: 'Пока нет предстоящих сессий в вашей программе',
        noUpcomingMeetings: 'Пока нет предстоящих встреч в вашей программе.',
        noUpcomingAgenda: 'Пока нет запланированных событий в вашей программе.',
        noAgendaItem: 'Пока нет событий в вашей программе.',
        meetingRequests: 'ЗАПРОСЫ НА ВСТРЕЧУ',
        finished: 'Завершено',
        past: 'Прошлое',
        goToMeeting: 'ПЕРЕЙТИ К ВСТРЕЧЕ',
        addToMyAgenda: 'Добавить в мою программу',
        addedToYourAgenda: 'Добавлено в вашу программу',
        acceptedAllMeetings: 'Принять все встречи',
    },
    participantRegistration: {
        metaTitle: 'Регистрация участника',
        choosePackageTitle: 'ВЫБЕРИТЕ ТАРИФНЫЙ ПАКЕТ',
        noPackageText: 'Пожалуйста, выберите тарифный пакет',
        gdprTextFirst: 'Продолжая, вы соглашаетесь с нашей',
        gdprTextPrivacy: 'Политика Конфиденциальности',
        gdprTextSecond: 'и',
        gdprTextTerms: 'Условиями проведения мероприятия',
        gdprTextAgree: 'Пожалуйста, согласитесь с политикой конфиденциальности',
        registerButtonFree: 'Завершить регистрацию',
        registerButtonPay: 'Перейти к Оплате',
    },
    exhibitorRegistration: {
        metaTitle: 'Регистрация экспонента',
        choosePackageTitle: 'ВЫБЕРИТЕ ТАРИФНЫЙ ПАКЕТ',
        gdprTextFirst: 'Продолжая, вы соглашаетесь с нашей',
        gdprTextPrivacy: '\nПолитикой конфиденциальности мероприятия',
        gdprTextSecond: 'и',
        gdprTextTerms: 'Условиями проведения мероприятия',
        gdprTextAgree: 'Пожалуйста, согласитесь с политикой конфиденциальности',
        registerButtonFree: 'Зарегистрироваться',
        registerButtonPay: 'Перейти к Оплате',
    },
    stripePayment: {
        cardNumber: 'Номер карты',
        expirationDate: 'Срок действия (ММ/ГГ)',
        cvc: 'CVC',
        disclaimerText:
            'Эта платежная операция зашифрована. Безопасность и конфиденциальность гарантируются сервисами Stripe.',
        disclaimerTextLink: 'Нажмите, чтобы узнать больше',
        cancelButton: 'ОТМЕНИТЬ',
        payButton: 'Оплатить',
    },
    recoverPassword: {
        recoverTextSend: 'Заявка восстановления пароля отправлено успешно.',
    },
    unsubscribe: {
        unsubscribeTitle: 'Отписаться от рассылки?',
        unsubscribeHomepage: 'Вернуться на Главную',
    },
    notAvailable: {
        title: 'Мероприятие недоступно',
        description:
            'Мероприятие, которое вы ищете, недоступно. Оно либо закрыто организатором, либо участие в нем закрыто администратором.',
        fullOverlayEvents: 'Посмотрите другие ваши мероприятия',
        fullOverlayNoEvents: 'Других мероприятий не найдено',
    },
    notFound: {
        notFoundDescription: 'Упс ... Здесь нет ивентов!',
        notFoundHomepage: 'Вернуться на Главную',
    },
    eventMenu: {
        organizerButton: 'ОРГАНИЗАТОР',
        coOrganizerButton: 'УПРАВЛЯТЬ',
        exhibitorButton: 'ЭКСПОНЕНТЫ',
        posterStudyButton: 'ПЛАКАТ / ИССЛЕДОВАНИЕ',
        boothButton: 'СТЕНД',
        boothsButton: 'СТЕНДЫ',
        homeButton: 'ГЛАВНАЯ',
        contactsButton: 'КОНТАКТЫ',
        meetingsButton: 'ВСТРЕЧИ',
        chatsButton: 'ЧАТЫ',
        programButton: 'ПРОГРАММА',
        archiveButton: 'АРХИВ',
        myAccountButton: 'МОЙ АККАУНТ',
        lobbyButton: 'ЛОББИ',
        roomsButton: 'ЗАЛЫ',
        connectionProblemTextFirst: 'Возможно, у Вас проблемы с подключением. Пожалуйста, нажмите',
        connectionProblemButton: 'ЗДЕСЬ',
        connectionProblemTextSecond: 'или обновите страницу.',
        sessionProblemTextFirst: 'Время сеанса истекло. Пожалуйста, нажмите',
        sessionProblemButton: 'ЗДЕСЬ',
        sessionProblemTextSecond: 'чтобы войти повторно.',
    },
    myEventsDropdown: {
        title: 'МОИ ИВЕНТЫ',
        createButtonMobile: 'СОЗДАТЬ',
    },
    myBoothsDropdown: {
        title: 'МОЙ СТЕНД',
        emptyStudyTitle: 'Стенд с плакатом / исследованием',
    },
    contactsDropdown: {
        title: 'ВАШИ КОНТАКТЫ',
        requestsTitle: 'Запросы контактов',
        rejectButton: 'ОТКАЗАТЬ',
        acceptButton: 'ПРИНЯТЬ',
        pendingButton: 'Ожидается',
        addButton: 'Добавить',
        noContacts: 'У вас пока нет контактов. Посетите Нетворкинг чтобы завести новые связи',
    },
    meetingsDropdown: {
        meetingWith: 'Встреча с',
        pending: 'В ожидании',
        accepted: 'Подтверждено',
        canceled: 'Отменено',
        noMeetings: 'Встречи не запланированы',
        finished: 'Завершено',
    },
    programDropdown: {
        stages: 'ЗАЛЫ',
        with: 'с',
        noProgram: 'На данный момент нет доступных записей программы.',
        fullProgramButton: 'Полная программа', // new translation
        addToCalendarButton: 'Добавить в календарь', // new translation
        addedToYourAgenda: 'ДОБАВЛЕНО В ВАШЕ РАСПИСАНИЕ',
        addEventButton: 'Добавить событие', // new translation
        addToMyAgenda: 'Добавить в мое расписание', // new translation
        addEventToYourCalendarDialogTitle: 'Добавить событие в ваш календарь', // new translation
        addTimeslotToYourCalendar: 'Добавить временной интервал в ваш календарь', // new translation
        tip: 'СОВЕТ: ', // new translation
        tipDescription:
            'Вы также можете добавить отдельные временные интервалы в ваш календарь, нажав на значок календаря рядом с каждым временным интервалом.', // new translation
        tipTimeslotDescription: 'Вы также можете добавить всё событие, нажав кнопку "Добавить в календарь".', // new translation
        durationEventTitle: 'Продолжительность события: ', // new translation
        timeslotTitle: 'Название: ', // new translation
        slotScheduled: 'Запланированный временной интервал: ', // new translation
        addSlotToCalendar: 'Добавить временной интервал в календарь', // new translation
        speakers: 'Спикеры', // new translation
        auditoriumName: 'Название аудитории', // new translation
        timezoneTooltipText:
            'Программа отображается в часовом поясе организатора. Вы можете изменить часовой пояс отображения, чтобы просматривать сеансы в предпочитаемом часовом поясе', // new translation
        liveLabel: 'Прямой эфир', // new translation
        liveLabelTooltipText: 'Этот временной интервал сейчас проходит в прямом эфире в аудитории', // new translation
        openAuditorium: 'ОТКРЫТЬ АУДИТОРИЮ', // new translation
    },
    notificationsDropdown: {
        title: 'Уведомления',
        contactRequestReceived: 'пригласил вас в сеть контактов',
        contactRequestAccepted: 'принял ваш запрос на контакт. Начните общение сейчас',
        meetingRequestReceived: '\nотправил вам приглашение на встречу',
        meetingRequestAccepted: 'принял ваше приглашение для встречи',
        meetingRequestCanceled: 'отклонил ваш запрос для встречи',
        meetingStarting: 'Встреча скоро начнется. Занимайте места',
        newBoothPost: 'Написал на стене вашего стенда. Откройте чтобы проверить',
        noNotifications: 'У вас нет уведомлений',
        seeAll: 'ПОСМОТРЕТЬ ВСЕ',
        hideAll: 'СВЕРНУТЬ ВСЕ',
        meetingWillStartTextSecond: 'Скоро начнется. Проверьте!',
    },
    chatsDropdown: {
        title: 'ЧАТЫ',
        newChatProvideText: '\nУкажите имя для вашего чата. Впоследствии вы сможете отредактировать имя:',
        noChats: 'Создайте хотя бы один чат для общения с участниками мероприятия',
        members: 'Участники',
        noOpenedChatText: 'Выберите чат, чтобы продолжить беседу.',
        noMessagesInChatTextFirst: 'Разбейте лед!',
        noMessagesInChatTextSecond: 'Будьте первым, кто начнет беседу.',
        newChatNameInput: 'Название чата (по желанию)',
        newChatAddMembers: 'Добавить других участников в этот чат:',
        editChatAddMembers: 'Найдите посетителей этого мероприятия, которых вы хотите добавить в групповой чат.',
        newChatSearchInput: 'Найти посетителей',
        newChatNoUsersMessage: 'Посетители не найдены',
        newChatRemoveUserButton: 'Удалить',
        newChatCancelButton: 'ЗАКРЫТЬ',
        newChatCreateButton: 'СОЗДАТЬ',
        membersLeaveButton: 'ВЫЙТИ',
        membersUpdateButton: 'ОБНОВИТЬ',
        membersChatButton: 'ЧАТ',
        chatSettingsButton: 'НАСТРОЙКИ',
        newMessageInput: 'Напечатать сообщение здесь',
        newVideoCallTooltipText: 'Нажмите здесь, чтобы начать видеочат',
        leaveVideoMeetingTitle: 'Покинуть видеовстречу',
        leaveVideoMeetingDescription: 'Вы собираетесь покинуть видеовстречу. Вы уверены, что хотите продолжить?',
        leaveChatConfirmationTitle: 'Покинуть видеозвонок',
        leaveChatConfirmationText: 'Вы уверены, что хотите покинуть текущий видеозвонок?',
    },
    myAccountDropdown: {
        title: 'МОЙ АККАУНТ',
        viewProfileButton: '\nПросмотреть профиль',
        logoutButton: 'ВЫЙТИ',
    },
    myAccountPage: {
        changePasswordButton: 'ПОМЕНЯТЬ ПАРОЛЬ',
        deleteAccountButton: 'УДАЛИТЬ МОЙ АККАУНТ',
        platformLanguage: '\nЯзык платформы:',
        privacyPolicyLink: 'Уведомление о конфиденциальности / политика',
        cookiesLink: 'Cookies',
        termsAndConditionsLink: 'Правила и Условия',
        eventProfileSectionTitle: 'Профиль мероприятия',
        eventPackage: 'Пакет мероприятия',
        unregisterFromEventButton: 'ОТМЕНА РЕГИСТРАЦИИ НА МЕРОПРИЯТИЕ',
        unregisterCurrentEventButton: 'Отменить регистрацию на текущее мероприятие',
        unregisterCurrentEventConfirmation: 'Подтвердите, что вы хотите отменить регистрацию на мероприятие',
        enableParticipantProfile: 'Включить профиль участника',
        editUserDialogTitle: 'Редактировать данные Пользователя',
        editExhibitorDialogTitle: 'Изменить данные контактного лица компании',
        editScholarDialogTitle: 'Редактировать данные контактного лица по вопросам плаката / исследования',
        editParticipantDialogTitle: 'Редактировать данные Участника',
        firstName: 'Имя',
        lastName: 'Фамилия',
        uploadImage: 'Загрузить изображение',
        removeImage: 'Удалить изображение',
        deleteError:
            'Вы не можете удалить свой аккаунт. Пожалуйста, удалите все созданные мероприятия, чтобы иметь возможность удалить свой аккаунт.',
        deleteSuccess: 'Ваш аккаунт был удален',
        reviewEventPrivacyPolicy: 'Ознакомьтесь с Политикой конфиденциальности мероприятия:',
        title: 'Название',
        company: 'Компания',
        phone: 'Телефон',
        platformProfileSectionTitleTextSecond: 'профиль',
        createParticipantProfile: 'Создать профайл участника',
        reviewPlatformConditions: 'Ознакомиться с условиями нашей платформы',
        reviewEventConditions: 'Ознакомиться с условиями нашего мероприятия',
    },
    changePassword: {
        title: 'ПОМЕНЯТЬ ПАРОЛЬ',
        oldPassword: 'Старый пароль',
        oldPasswordError: 'Старый пароль не совпадает!',
        inputPassword: 'Новый пароль',
        inputConfirmPassword: 'Подтвердить пароль',
    },
    roomsDropdown: {
        auditoriumButton: 'АУДИТОРИЯ',
        archiveButton: 'АРХИВ',
        onDemandButton: 'ПО ТРЕБОВАНИЮ',
    },
    sideMenu: {
        liveWall: 'ЖИВАЯ СТЕНА',
        networking: 'НЕТВОРКИНГ',
        audience: 'АУДИТОРИЯ',
        info: 'ИНФО',
        videoWall: 'ВИДЕО-СТЕНА',
        booth: 'СТЕНД',
        boothWall: 'СТЕНА СТЕНДА',
        networkingTooltip: 'У вас нет доступа к Нетворкингу',
        attendees: 'Участники',
    },
    wall: {
        sortBy: 'Сортировать по:',
        popularity: 'ПОПУЛЯРНОСТИ',
        time: 'НОВИЗНЕ',
        emptyWallText: '\nБудьте первым, кто добавит здесь пост',
        newPostInput: 'О чём вы думаете?',
        newCommentInput: 'Комментарий',
        resourcesVideoDescription: 'ОПИСАНИЕ ВИДЕО',
        resourcesLinks: 'Ссылки',
        resourcesFiles: 'Файлы',
        pinTitle: 'Закрепить',
        unPinTitle: 'Открепить',
        deleteDialogTitle: 'Удалить пост?',
        pinDialogDescription:
            'Этот пост уже закреплен. Одновременно может быть закреплен только один пост. Если вы продолжите, предыдущий пост будет автоматически откреплен.',
        edited: 'Отредактировано',
        escapeToCancel: 'выйти, чтобы отменить',
        enterToSave: 'войти, чтобы сохранить',
        editingState: 'Редактировать сообщение',
        bannerModeration: 'Посты и комментарии на этой стене модерируются для обеспечения качественного контента',
        pendingApproval: 'В процессе утверждения',
        approvePost: 'Утвердить пост',
        approvePostComment: 'Утвердить комментарий',
        commentsPendingApproval: 'Комментарии ожидают одобрения',
    },
    networking: {
        searchInput: 'Искать посетителей',
        emptyNetworkingSearchResults: 'Человек, которого вы ищете, не зарегистрировался на это онлайн-мероприятие',
        emptyAuditoriumNetworkingSearchResults: 'Человек, которого вы ищете, еще не посетил эту презентацию',
        alphabetical: 'в алфавитном порядке',
        online: 'онлайн',
        matching: 'совпадения',
        matchingAlgo: 'Алгоритм подбора в действии. Дайте нам минуту, пока мы подбираем для вас лучшие варианты!',
        searchFilters: 'Фильтры поиска',
        applyFilterTooltip: 'Для применения фильтров выберите хотя бы 1 вариант',
        chat: 'Чат',
    },
    closedAuditoriumDialog: {
        title: 'Информация об Аудитории',
        contentPackage: 'Ваш тарифный пакет не имеет доступа к аудиториям.',
    },
    closedShowfloorDialog: {
        title: 'Информация о Выставке',
        contentPackage: 'В вашем пакете доступа нет доступа в выставочные залы.',
        button: 'ХОРОШО',
    },
    auditorium: {
        willStart: '\nСледующая сессия начнется через ...',
        noAccess: '\nВаш тарифный пакет не имеет доступа к этой аудитории.',
        noVideos: '\nВ этой аудитории не запланировано воспроизведение видео.',
        stageTab: 'СЦЕНА',
        videoWallButton: 'ВИДЕО-СТЕНА',
        resourcesButton: 'РЕСУРСЫ',
        slotEndsTextFirst: '\nЭтот слот заканчивается через...',
        slotEndsTextSecondUpdated: 'Презентация будет доступна по запросу.',
        slotTitleWith: 'с',
        allAuditoriums: 'Все аудитории',
        onDemandButton: 'ПО ТРЕБОВАНИЮ',
        hide: 'Скрыть',
        leavePopupTitle: 'Покинуть сцену?',
        leavePopupDescription: 'Покидая аудиторию, вас исключат из сессии.',
        leaveSession: 'Покинуть сцену',
        cancelDescription: 'Покидая сцену, вы больше не будете спикером. Хотите продолжить?',
        settings: 'Настройки',
        minimize: 'Минимизировать',
        microphone: 'Микрофон',
        camera: 'Камера',
        auditoriums: 'Аудитории',
    },
    auditoriumArchive: {
        title: 'Архиву Аудитории',
        titleEvent: 'Архив Мероприятия',
        with: 'с',
        noVideosAvailable: 'Все законченные сессии',
    },
    videoPlayer: {
        videoNotPlayingError: 'Возникли проблемы с видеопотоком? Пожалуйста, перезагрузите.',
        videoEndedTextFirst: '\nВидео закончилось. Спасибо за просмотр',
        videoEndedTextSecond: 'Видео-стена открыта для вопросов и ответов.',
        tooltipPlay: 'Вопроизвести',
        tooltipPause: 'Пауза',
        tooltipMinimize: 'Свернуть',
        tooltipFullscreen: 'Развернуть',
        tooltipMute: 'Выключить звук',
        tooltipUnmute: 'Включить звук',
        videoStreamNotStarted: 'Скоро начнется. Следите за новостями!',
    },
    showfloor: {
        allExhibitorsButton: 'ВСЕ ЭКСПОНЕНТЫ',
        featuredExhibitorsText: 'РЕКОМЕНДУЕМЫЕ ЭКСПОНЕНТЫ',
        boothLinksDialogTitle: 'ССЫЛКИ НА СТЕНДЫ',
        boothFilesDialogTitle: 'ФАЙЛЫ СТЕНДА',
        boothVideoDialogTitle: 'ВИДЕО',
        mobileTabChat: 'ЧАТ',
        mobileTabPoster: 'ПЛАКАТ',
        mobileTabFiles: 'ФАЙЛЫ',
        mobileTabLinks: 'ССЫЛКИ',
        mobileTabVideo: 'ВИДЕО',
        noExhibitorsTextFirst: '\nУЧАСТНИКИ РАБОТАЮТ НАД СВОИМИ СТЕНДАМИ',
        noPresentersTextFirst: 'Ведущие работают над своими стендами',
        noExhibitorsTextSecond: 'БУДЬТЕ НА СВЯЗИ',
        showShowfloorsButton: 'ВСЕ ВЫСТАВОЧНЫЕ ЗАЛЫ',
        showShowfloorsPosterButton: 'ВСЕ ПЛАКАТЫ/ИССЛЕДОВАНИЯ',
        showShowfloorsExhibitorButton: 'ВСЕ ЭКСПОНЕНТЫ',
        searchExhibitors: 'Искать экспонентов',
        searchPosters: 'Искать по названию или имени автора',
        searchExhibitorsMultiple: 'Искать экспонентов в этом выставочном зале',
        searchPostersMultiple: 'Искать по названию или имени автора в этом выставочном зале',
        showfloorsTitle: 'Выставочные залы',
        collapseList: 'Свернуть список',
        seeFullList: 'Показать весь список',
        emptyExhibitorsResults: 'Стенда, который вы ищете, нет. Но мы пригласим их в следующий раз',
        emptyPostersResults:
            'Плакат/исследование, которое вы ищете, не представлен. Но мы пригласим их в следующий раз ',
        posters: 'плакаты',
        allPostersStudiesTitle: 'Все плакаты/исследования',
        authors: 'авторы',
        titleNotAvailable: 'Название не доступно',
        abstractNotAvailable: 'Аннотация недоступна',
        authorsNotAvailable: 'Авторы не доступны',
        openPosterFile: 'Открыть файл постера',
        fileIsNotAvailable: 'Файн не доступен',
        posterBooth: {
            study: 'Плакат/исследование',
            authors: 'Авторы',
            highlights: 'Аннотация',
            scrollInfoText: 'ПРОКРУТИТЕ ВНИЗ, ЧТОБЫ ПРОЧИТАТЬ ПОЛНУЮ АННОТАЦИЮ',
        },
    },
    eventArchive: {
        title: 'Видео Архив',
        searchText: 'Нажмите для поиска',
        noArchivedEvents: '\nВ настоящее время нет заархивированных ивентов.',
    },
    exhibitorDashboard: {
        event: 'МЕРОПРИЯТИЕ',
        title: 'Настройки стенда',
        visitorsButton: 'Посетители стенда',
        goToBoothPreview: 'Предварительный просмотр стенда',
        previewButton: 'Предварительный просмотр',
        tabStyle: 'Стиль Стенда',
        tabLogo: 'Логотип',
        tabStudyDetails: 'Плакат / Исследование',
        tabFiles: 'Файлы',
        tabStudyFile: 'Файл',
        tabLinks: 'Ссылки',
        tabAvatars: 'Аватары',
        tabStudyAuthors: 'Авторы',
        tabVideo: 'Видео',
        shareText: 'КОПИРОВАТЬ URL',
        theBoothUrlCopiedText: 'URL-адрес стенда успешно скопирован в буфер обмена',
        optionDisabledText: 'Эта опция в настоящее время недоступна для этого стенда',
        boothStyleTab: {
            optionOne: 'Вариант 1 - Скандинавский стиль',
            optionTwo: 'Вариант 2 - Модерн стиль',
            optionThree: 'Вариант 3 - Динамический стиль',
            optionFour: 'Вариант 4 - Пользовательский',
            optionScholarOne: 'Вариант 1 — Эйнштейн',
            optionScholarTwo: 'Вариант 2 — Кюри',
            optionScholarThree: 'Вариант 3 — да Винчи',
        },
        logoTab: {
            fileUploadButton: 'Нажмите чтобы загрузить',
            noFile: 'Файл не выбран',
        },
        posterStudyTab: {
            posterStudyTitle: 'Название',
            studyTitleInputLabel: 'Название плаката / исследования',
            highlightAbstractTitle: 'Основные моменты / аннотация',
            studyHighlightsInputLabel: 'Основные моменты / аннотация исследования',
            highlightAbstractError: 'Вы использовали максимально допустимое количество символов! (500 символов)',
            authorNameLabel: 'Имя автора',
            authorAffiliationLabel: 'Членство',
        },
        filesTab: {
            title: 'Документы:',
            fileNameInput: 'Название документа (доступно для всех посетителей)',
            chooseFileButton: 'Выбрать файл',
            noDocAdded: 'Документы не добавлены',
            fileError: 'Пожалуйста, добавьте название документа',
        },
        fileStudyTab: {
            title: 'Загрузить pdf файл',
            description:
                'Загрузить плакат: документ или презентация будет доступна на вашем стенде (максимальный размер файла - 20 Мб)',
            uploadPdfInputLabel: 'Загрузить PDF файл',
            uploadNewPdfInputLabel: 'Загрузить новый PDF файл',
            uploadedFile: 'Загружено',
            dragContainerTitle: 'Поместите файл сюда',
            dragContainerNewTitle: 'Поместите новый файл сюда',
        },
        linksTab: {
            social: {
                title: 'Ссылки на Социальные сети',
                subtitle: '(видны всем участникам)',
                facebookInput: 'Ваш Facebook профиль',
                linkedinInput: 'Ваш LinkedIn профиль',
                twitterInput: 'Ваш Twitter профиль',
                googleScholarInput: 'Вставьте ссылку на свой профиль в Google Scholar',
                researchGateInput: 'Вставьте ссылку на свой профиль  в Research Gate',
            },
            other: {
                linkInput: 'Введите новую ссылку',
                linkLabel: 'Вставить название ссылки',
            },
        },
        linksStudyTab: {
            authorProfiles: 'Профили автора',
            additionalLinks: 'Дополнительные ссылки',
        },
        avatarsTab: {
            mainRepresentatives: {
                title: 'Представители Стенда',
                leftRepresentative: 'Представитель слева',
                rightRepresentative: 'Представитель справа',
                addDialog: {
                    title: 'Добавить Аватар',
                    titleScholar: 'Добавить автора',
                    editAvatarsTitle: 'Редактировать аватар',
                    editAuthorTitle: 'Редактировать автора',
                    contentLine1: 'Выберите аватар из галереи ниже',
                    contentLine2: 'Загрузите ваш персонализированный аватар',
                    contentLine3: 'Нужна помощь? Свяжитесь с',
                    assignButtonText: 'Назначить',
                    assignText:
                        'Пожалуйста, назначьте ответственного участника мероприятия, который будет связан с вышеуказанным представителем.',
                    assignErrorBooth: 'Пользователь уже добавлен как представитель Стенда',
                    assignErrorAdditional: 'Пользователь уже добавлен как Дополнительный представитель',
                    input: 'Искать по имени здесь',
                },
            },
            additionalRepresentatives: {
                title: 'Дополнительный представитель',
                subtitle: '\n(необязательно, будет отображаться вместе с аватаркой)',
                addDialog: {
                    title: 'Выбрать дополнительного представителя',
                    titleScholar: 'Выбрать со-автора',
                    assignText:
                        'Начните поиск участника мероприятия, чтобы добавить его / ее в качестве представителя.',
                    assignError: '\nПользователь уже добавлен в качестве представителя стенда',
                },
            },
            author: {
                title: 'Представляющий автор',
                subtitle: 'Добавить только автора, зарегистрированного на мероприятие. Вы можете добавить 1 автора.',
                mainRepresentative: 'Основной представитель',
            },
            coAuthors: {
                title: 'Со-авторы',
                singularTitle: 'Со-автор',
                subtitle:
                    'Добавить только со-авторов, зарегистрированных на мероприятие. Вы можете добавить до 4 со-авторов.',
            },
        },
        videoTab: {
            video: {
                title: 'Видео Стенда',
                subtitle: '\n(Ссылка на видео - только YouTube)',
                youtubeOrVimeoOnly: '(Ссылка на YouTube или Vimeo)',
                input: '\nВведите ссылку на видео из YouTube',
                inputYoutubeOrVimeo: 'Введите URL-адрес видео в YouTube или Vimeo',
            },
            image: {
                subtitle:
                    '\n(Изображение отображается на вашем стенде, если вы не добавили ссылку на видео в разделе выше)',
            },
        },
    },
    boothDashboard: {
        event: 'Ивент',
        title: 'Ваш Стенд',
        manage: 'Управление',
        statistics: 'Статистика',
        uniqueVisitors: 'Уникальные посетители', // new translation
        currentOnlineVisitors: 'Текущие онлайн-посетители', // new translation
        totalNumberOfVisits: 'Общее количество посещений', // new translation
        totalDocumentClicks: 'Всего кликов по документам', // new translation
        totalLinkClicks: 'Всего кликов по ссылкам', // new translation
        totalRepresentativesClicks: 'Всего кликов представителей', // new translation
        totalVideoViews: 'Всего просмотров видео', // new translation
        totalPostsCount: 'Общее количество сообщений', // new translation
        totalLikesCount: 'Общее количество лайков', // new translation
        totalCommentsCount: 'Общее количество комментариев', // new translation
        showUsers: 'Показать пользователей', // new translation
        dataType: 'Тип данных', // new translation
        value: 'Value', // new translation
        posts: 'Сообщения', // new translation
        likes: 'Нравится', // new translation
        comments: 'Комментарии', // new translation
        topPostLikes: 'Лучшие лайки', // new translation
        topPostComments: 'Лучшие комментарии к сообщениям', // new translation
        totalVotes: 'Всего голосов', // new translation
    },
    polls: {
        polls: 'ОПРОСЫ',
        noPolls: 'Опросы еще не добавлены',
        pollProgress: 'Опрос в процессе',
        pollClosed: 'Опрос завершен',
        pollVoted: 'проголосовавшие участники',
    },
    onDemandVideos: {
        sectionTitle: 'ПО ТРЕБОВАНИЮ',
        noOnDemandVideos: 'Видео по запросу пока не добавлены.',
    },
    meetings: {
        meeting: 'ВСТРЕЧА',
        createDialog: {
            createTitle: 'Запрос о встрече с',
            inputDate: 'Дата',
            inputStart: 'Начало',
            inputEnd: 'Конец',
            inputTimezone: 'Часовой пояс',
            inputNote:
                'Примечание: с одним и тем же человеком одновременно может быть запланирована только одна встреча',
            meetingError: 'Неверные дата и время. Проверьте!',
            buttonDisabled: 'Вы можете назначить встречу после того, как начнете разговор!',
        },
        banner: {
            pending: 'Приглашение для встречи',
            accepted: 'Встреча назначена на',
            progress: 'Встреча в процессе',
            cancelTitle: 'Отменить встречу?',
            cancelDescription: 'Вы уверены, что хотите отменить встречу?',
            startCall: 'ПОЗВОНИТЬ',
            joinCall: 'ПРИСОЕДИНИТЬСЯ',
            pendingOwner: 'Ожидающий запрос о встрече с',
            videoCallStarted: 'Видео встреча началась',
            videoCallStartedInfo: 'Видеовстречи лучше всего подходят для не более, чем 10 человек',
        },
        title: 'Ваши встречи',
        meetingsInfo:
            'Встречи можно запланировать в чатах. Начните чат с пользователем, с которым хотите назначить встречу.',
    },
    socialMediaShare: {
        event: 'мероприятие',
        shareLabel: 'Поделиться',
        dialogTitleGeneral: 'Поделиться со своей сетью',
        dialogTitlePost: 'Поделиться вашим постом',
        linkedinLabel: 'LinkedIn',
        facebookLabel: 'Facebook',
        twitterLabel: 'Twitter',
        emailLabel: 'Электронная почта',
        clipboardLabel: 'Буфер обмена',
        clipboardSnackbarMessage: 'Ссылка скопирована в буфер обмена',
        linkedinButtonTitle: 'Поделиться в LinkedIn',
        facebookButtonTitle: 'Поделиться в Facebook',
        twitterButtonTitle: 'Поделиться в Twitter',
        emailButtonTitle: 'Поделиться с помощью электронной почты',
        clipboardButtonTitle: 'Скопировать в буфер обмена',
        imAttending: 'Я участвую в',
        interestedClick: 'Заинтересованы в участии? Нажмите здесь:',
    },
    videoConferences: {
        fullScreen: 'РАЗВЕРНУТЬ',
        minimizeScreen: 'МИНИМИЗИРОВАТЬ ЭКРАН',
        startedVideoChat: 'начал видеозвонок',
        timeAgo: 'тому назад',
        videoCallStarted: 'Видеозвонок начался...',
    },
    sessions: {
        edit: 'РЕДАКТИРОВАТЬ',
        speakers: 'СПИКЕРЫ',
        speakerSingular: 'спикер',
        moderators: 'МОДЕРАТОРЫ',
        moderatorSingular: 'модератор',
        video: 'ВИДЕО',
        startStream: 'НАЧАТЬ СТРИМ',
        stopStream: 'ОСТАНОВИТЬ СТРИМ',
        sessionChat: 'ЧАТ СЕССИИ',
        speakerUrl: 'URL Спикера',
        moderatorUrl: 'URL Модератора',
        copyUrl: 'Копировать URL',
        noSpeakersJoined: 'Спикеры еще не присоединились',
        allFilter: 'все',
        waitingRoom: 'Комната ожидания',
        isReady: 'Готова',
        invitationAccepted: 'Принял приглашение',
        requestPending: 'Запрос в процессе',
        gettingReady: 'Готовится',
        inviteToSpeak: 'Дать слово',
        fromAuditorium: 'из аудитории',
        mic: 'Микрофон',
        screen: 'Экран',
        rec: 'запись',
        sessionHasEnded: 'Сессия закончилась',
        sessionLinks: 'ССЫЛКА СЕССИИ',
        sessionSettings: 'Настройки',
        noAvailableChat: 'Чат недоступен. Чтобы увидеть чат, пожалуйста, выделите эту сессию во временном интервале.',
        startLiveSessionStreamTitle: 'Начать прямую трансляцию сессии',
        startLiveSessionDescription: 'Запись сессии автоматически начнется, когда вы запустите прямую трансляцию',
        stopLiveSessionStreamTitle: 'Остановить прямую трансляцию сессии',
        stopLiveSessionStreamDescription:
            'При остановке прямой трансляции сессии запись также автоматически прекращается. Эту прямую трансляцию сессии нельзя будет запустить снова.',
        thumbnailImage: 'Уменьшенное изображение',
        uploadThumbnail: 'Загрузить уменьшенное изображение',
        clickToUpload: 'Нажмите здесь, чтобы загрузить',
        requestMic: 'ТРЕБУЕТСЯ МИКРОФОН',
        acceptInvitationTitle: 'Вы получили приглашение',
        acceptInvitationSubTitle: 'Модератор пригласил вас участвовать в качестве спикера. Каков ваш ответ?',
        acceptInvitationButton: 'Принять приглашение',
        requestPendingTitle: 'Запрос в процессе...',
        requestPendingSubTitle: 'Пожалуйста, подождите, пока модератор разрешит вам присоединиться к прямой трансляции',
        cancelRequest: 'Отменить запрос',
        approvedTitle: 'Проверьте свой микрофон и видео и нажмите “Я готов”.',
        approvedSubTitle:
            'Эта сессия записывается. Подключаясь к этой сессии вы соглашаетесь на обработку ваших персональных данных.',
        connectedToTheBackstage: 'Теперь вы ведете прямую трансляцию и подключены к бэкстейджу.',
        removedFromTheSession: 'Вы были удалены из сессии, свяжитесь с модератором.',
        imReady: 'Я готов',
        isTryingToRegister: 'пытается зарегистрироваться как',
        hasLeftTheSession: 'покинул сессию',
        stopVideo: 'Остановить видео',
        startVideo: 'Начать видео',
        disabledScreenSharing: 'Выключите демонстрацию экрана перед тем, как включить свое видео',
        disabledByModerator: 'Отключено модератором',
        disabledUntilReady: 'Отключено до готовности',
        stopShareScreen: 'Остановить демонстрацию экрана',
        shareScreen: 'Демонстрация экрана',
        disabledVideoSharing: 'Выключите видео перед началом демонстрации экрана',
        anotherUserIsScreenSharing: 'Кто-то уже демонстрирует свой экран',
        removeRoleDescription: 'Вы уверены, что хотите удалить',
        declinedInvitation: 'отклонить приглашение быть спикером',
    },
    gdpr: {
        disclaimerTextFirst:
            'Этот документ переведен искусственным интеллектом. В случае несоответствия, пожалуйста, обратитесь к английской версии документа.',
        disclaimerTextSecond: 'ЗДЕСЬ',
        metaTitlePrivacyPolicy: 'Политика конфиденциальности',
        metaTitleCookiesPolicy: 'Политика в отношении файлов cookie',
        metaTitleTerms: 'Условия',
        updateCookieConsent: 'Обновить согласие на использование файлов cookie',
        changeYourConsent: 'Изменить свое согласие',
        withdrawYourConsent: 'Отозвать свое согласие',
        withdrawSnackbarText: 'Согласие на использование файлов cookie успешно отозвано',
    },
    infoMessages: {
        imageExplain420x200:
            'Рекомендуемое соотношение сторон изображения 1,8:1 (альбомная ориентация, например, 420 x 200 пикселей)',
        imageExplainTB230x320:
            'Рекомендуемое соотношение сторон изображения 1:1,4 (книжная ориентация, например, 230 x 320 пикселей) с прозрачным фоном',
        imageExplainTB230x875:
            'Рекомендуемое соотношение сторон изображения 1:3,8 (книжная ориентация, например, 230 x 875 пикселей) с прозрачным фоном',
    },
    matching: {
        matchingQuestionnaireTitle: 'Соответствующая анкета',
        matchingQuestionnaireIntro:
            'Позвольте помочь вам связаться с наиболее значимыми для вас людьми! Удивительные вещи случаются, когда встречаются правильные умы, заполните анкету, чтобы начать',
        matchingQuestionnaireIntroSkip:
            'Если вы пропустите анкету, мы не сможем сопоставить вас с остальными участниками сегодняшнего мероприятия. Вы всегда можете заполнить анкету позже в разделе Networking. Все ответы доступны для редактирования в Личном кабинете.',
        doItLaterBtn: 'ЗАПОЛНИТЬ ПОЗЖЕ',
        nextBtn: 'Дальше',
        backBtn: 'Назад',
        finishBtn: 'Закончить',
        seeMatchesBtn: 'ПОСМОТРЕТЬ ВСЕ СОВПАДЕНИЯ',
        tryMatchingBtn: 'ПОПРОБУЙТЕ УМНЫЙ ПОДБОР',
        optionalQuestion: 'Необязательно',
        requiredQuestion: 'Обязательно',
        singleSelect: 'Одиночный выбор',
        multiSelect: 'Множественный выбор',
        mediumMatch: 'Средний уровень совпадения',
        highMatch: 'Высокий уровень совпадения',
        matchLabel: 'Совпадение',
        matchesLabel: 'Совпадения',
        infoTooltipJump: 'Перейти сюда, чтобы увидеть ваши совпадения',
        infoTooltipClickToSee1: 'Нажмите на',
        infoTooltipClickToSee2: 'чтобы увидеть, с кем вам стоит связаться!',
        loadingAlgoText: 'Алгоритм подбора в действии. Дайте нам минуту, пока мы подбираем для вас лучшие варианты!',
        matchingDetailsFormTitle: 'Детальнее об Умном подборе',
        tryMatchingBanner: 'Установите значимую связь, попробуйте нашу функцию умного подбора!',
        matchingBannerClosed: 'Вы всегда можете заполнить форму Умный подбор в Networking в разделе Совпадения',
        resultsTitle: 'Ваши лучшие совпадения',
        resultsNoteLabel: 'ПРИМЕЧАНИЕ:',
        resultsNoteText: 'Вы можете найти все свои совпадения в разделе Networking.',
        resultsNoteMyAccount: 'Все ответы доступны для редактирования в меню «Моя учетная запись».',
        btwResultsResponsive: 'Кстати, все свои совпадения вы можете найти в разделе NETWORKING',
        noMoreMatches:
            'О, черт! В данный момент мы не можем найти для вас больше подходящих вариантов. Пожалуйста, загляните снова, когда к нам присоединится больше участников',
        noResultsTitle: 'Поздравляем, вы сделали это! Мы ищем для вас лучшие совпадения! ',
        noResults:
            '“Спасибо, что попробовали функцию Умный подбор! Мы ищем ваши лучшие совпадения и соединим вас, как только к мероприятию присоединится больше участников. Пожалуйста, проверьте вкладку «Совпадения» в разделе «Networking»”',
        clickStartChat: 'Нажмите на кнопку “чат”, чтобы начать разговор',
        closeMatchingTitle: 'Закрыть форму для совпадений?',
        closeMatchingTextA:
            'Если вы закроете форму до завершения, мы не сохраним никаких данных, и результаты Умного подбора будут вам недоступны.',
        closeMatchingTextB: 'Ваш профиль не будет частью процесса Умный подбор, и никто не будет вам подобран.',
        matchingTooltip: 'Нажмите «Совпадения», чтобы узнать, с кем вам стоит связаться!',
        myAccountMatchingSectionTitle: 'Детальнее об Умном подборе',
        matchingRequiredError: 'Вы должны ответить на все обязательные вопросы',
        rejectMatchingTitle: 'Отказаться от участия в подборе?',
        rejectMatchingText:
            'Ваши ответы будут удалены, и вы не сможете сопоставляться с другими пользователями. Вы можете повторно пройти опросник "Соответствие" в любое время.',
        rejectMatchingBtn: 'ОТКАЗАТЬСЯ ОТ УЧАСТИЯ',
    },
    welcomeScreen: {
        goToEvent: 'Перейти к мероприятию',
    },
    emailCodeVerification: {
        codeSent: 'Мы отправили код верификации на вашу электронную почту:',
        continueRegistration: 'Чтобы продолжить регистрацию,',
        enterCodeBelow: 'введите код верификации ниже:',
        codeWillExpire: 'Действие кода истекает через',
        codeExpired: 'Действие кода истекло',
        codeNotReceived: 'Не получили код? Вы проверили папку “Спам”?',
        resendTimer: 'Отправить повторно через:',
        resendLabel: 'Отправить повторно',
        resendCodeLabel: 'Отправить код повторно',
        codeError: 'Введенный код неправильный.',
        codeErrorFix: 'Повторно введите правильный код из вашей электронной почты или повторно отправьте новый код',
        congrats: 'Поздравляем!',
        emailConfirmed: 'Ваш адрес электронной почты подтвержден:',
    },
    privacyPolicyUpdates: {
        learnMore: 'Learn more', // new translation
        lastUpdated: 'Last updated', // new translation
        publishedBy: 'Published by', // new translation
        ppuLinkLabel: 'Updated privacy policy', // new translation
        ppuEventLinkLabel: 'Updated event privacy policy', // new translation
        acceptAllButtonLabel: 'Accept all updates', // new translation
    },
    voting: {
        votingText: 'ГОЛОСОВАНИЕ',
        enableVoting: 'ВКЛЮЧИТЬ ГОЛОСОВАНИЕ',
        enableVotingDescription: 'Щелкните значок «Обновить», чтобы получить наиболее точные результаты.',
        displayVotingNumbersForExhibitors: 'ОТОБРАЖЕНИЕ НОМЕРОВ ГОЛОСОВАНИЯ ДЛЯ УЧАСТНИКОВ И ПОСТЕРА/ИССЛЕДОВАНИЯ',
        displayVotingNumbersForExhibitorsDescription: 'Будет отображаться в личном кабинете экспонента',
        displayVotingNumbersForParticipants: 'ОТОБРАЖЕНИЕ НОМЕРОВ ГОЛОСОВАНИЯ ДЛЯ УЧАСТНИКОВ',
        displayVotingNumbersForParticipantsDescription: 'Будет отображаться для всех участников',
        characterError80: 'Вы достигли максимально допустимого количества символов (80 символов)',
        characterDescription: `Текст ограничен до 80 символов. Текст появится в качестве основного заголовка на ленте голосования. Сделайте это значимым для участников`,
        deleteAllVotes: 'УДАЛИТЬ ВСЕ ГОЛОСОВАНИЯ',
        downloadVotingResults: 'СКАЧАТЬ РЕЗУЛЬТАТЫ ГОЛОСОВАНИЯ',
        voteText: 'Голосование',
        votedText: 'Проголосовали',
        votesText: 'Голоса',
        participant: 'участник',
        participants: 'участники',
        deleteVotesTextFirst: 'ПРЕДУПРЕЖДЕНИЕ:',
        deleteVotesTextSecond: 'это необратимое действие.',
        deleteVotesTextThird: 'Не забудьте скачать результаты голосования перед их удалением.',
        checkTheBoxToCastYourVote: 'Поставьте галочку, чтобы отдать свой голос',
        toVotePleaseEnableParticipantProfile: 'Чтобы проголосовать, включите свой профиль участника',
    },
};

export default ru;
