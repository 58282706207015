import {useState, useEffect, useRef, useCallback} from 'react';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import {hasScroll} from '../../Utils';

const ProgressBar = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const containerRef = useRef(null);
    const scroll = hasScroll();

    const currentLanguage = useSelector((state) => state.languages.eventLanguage);
    const langArabic = currentLanguage === 'ar';

    const eventInfo = useSelector((state) => state.event.eventInfo);

    const primaryColor = eventInfo?.brandingData?.primaryColor;

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const isScrollAtBottom = useCallback(() => {
        const {scrollHeight, scrollTop, clientHeight} = document.documentElement;
        if (scrollTop + clientHeight >= scrollHeight) {
            setScrollPosition(clientHeight + scrollHeight);
        }
    }, [scrollPosition]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const containerWidth = containerRef?.current?.offsetWidth;
        const maxScrollWidth = containerWidth - 10; // subtract some padding
        const totalPageHeight = document.documentElement.scrollHeight - window.innerHeight;
        const containerHeight = containerRef?.current?.offsetHeight;

        if (totalPageHeight <= containerHeight) {
            setScrollPosition(containerWidth);
        } else if (scrollPosition > maxScrollWidth && scrollPosition < totalPageHeight) {
            setScrollPosition(maxScrollWidth);
        } else if (scrollPosition > totalPageHeight) {
            setScrollPosition(totalPageHeight);
        }
        isScrollAtBottom();
    }, [scrollPosition]);

    if (scroll.vertical) {
        return (
            <div className={classnames('registration-progress-bar', {'lang-ar': langArabic})} ref={containerRef}>
                <div
                    className={`scrolled-part`}
                    style={{
                        width: `${scrollPosition}px`,
                        maxWidth: '100%',
                        minWidth: '24px',
                        background: primaryColor.length > 0 && primaryColor,
                    }}
                ></div>
            </div>
        );
    } else {
        return null;
    }
};

export default ProgressBar;
