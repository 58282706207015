import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import colors from '../../../CSS/_variables.module.scss';
import Confirm from '../../../Dialogs/Confirm';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import {preventDefaultDrag, fireClickEvent} from '../../../Utils/utils';
import { isLinkRule } from 'Utils/validationRules';
import { verifyFileType } from 'Utils/verifyFileType';
import IconButton from "@material-ui/core/IconButton";
import Spinner from "../../../SmallLayoutComponents/Spinner";

class AddEditHomepageBanner extends React.Component {
    state = {
        fields: [
            {
                name: 'website',
                value: '',
                label: 'Webpage',
                multiline: 0,
                validators: ['isWebPageUrl'],
                errorMessages: ['Please enter a valid link (https://example.com/)'],
            },
        ],
        buttonDisabled: true,
        image: null,
        newLogoFile: null,
        imageLogoPreviewUrl: null,
        imageLogoErrorText: '',
        openConfirmUnsavedChanges: false,
        navigationElement: null,
    };

    formRef = React.createRef();
    wrapperRef = React.createRef();
    handleClickOutside = this.handleClickOutside.bind(this);

    componentDidMount() {
        if (this.props.bannerId) {
            this.setBannerData();
        }
        ValidatorForm.addValidationRule('isWebPageUrl', (value) => {
            let rule = isLinkRule;
            let match = rule.test(value);
            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isWebPageUrl');
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    closeClickOutside = () => this.setState({openConfirmUnsavedChanges: false});

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return document.getElementById('bannerLogoUpload').click();
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (!this.state.buttonDisabled) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    setBannerData = () => {
        const banner = this.props.banners.find((banner) => banner._id === this.props.bannerId);
        this.setState({
            fields: [{...this.state.fields[0], value: banner.website}],
            image: banner.image,
        });
    };

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields}, this.validateBannerForm);
    };

    validateBannerForm = async () => {
        const {newLogoFile} = this.state;
        const isFormValid = await this.formRef.current.isFormValid(true);

        const isButtonDisabled = !isFormValid || !newLogoFile;

        this.setState({buttonDisabled: isButtonDisabled});
    };

    handleLogoChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        const typeValid = verifyFileType(file.type, 'image');
        if (!isValid) {
            this.setState({imageLogoErrorText: 'File too large. 2Mb max file size.'});
        }

        isValid = typeValid && isValid;
        if (!typeValid) {
            this.setState({
                imageLogoErrorText:
                    'File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.',
            });
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    newLogoFile: file,
                    imageLogoPreviewUrl: reader.result,
                    imageLogoErrorText: '',
                },
                this.validateBannerForm);
            }
        };

        reader.readAsDataURL(file);
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    handleAddEditBanner = () => {
        const {navigationElement} = this.state;

        let data = {};
        this.state.fields.forEach((field) => {
            data[field.name] = field.value;
        });
        if (this.state.newLogoFile) {
            data.image = this.state.newLogoFile;
        }

        if (!this.state.newLogoFile && !this.props.bannerId) {
            // if the user didn't add an image
            // display an error
            this.setState({
                imageLogoErrorText: 'Please upload an image',
            });
        } else {
            if (this.props.bannerId) {
                this.props.editBanner(data);
            } else {
                this.props.addNewBanner(data);
            }
        }
        this.setState({openConfirmUnsavedChanges: false});

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.props.handleCloseDialog();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    render() {
        const {
            newLogoFile,
            imageLogoPreviewUrl,
            imageLogoErrorText,
            buttonDisabled,
            openConfirmUnsavedChanges,
        } =
            this.state;
        const {open, handleCloseDialog, bannerId, image, filesUrl} = this.props;
        return (
            <Dialog
                disableRestoreFocus
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                PaperProps={{classes: {root: 'reverse-rtl'}}}
            >
                <div onDragStart={preventDefaultDrag} ref={this.wrapperRef}>
                    <DialogTitle id="form-dialog-title">{bannerId ? 'Edit' : 'Add'} banner</DialogTitle>
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.handleAddEditBanner}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                    <ValidatorForm onSubmit={this.handleAddEditBanner} ref={this.formRef}>
                        <DialogContent>
                            <DialogContentText id="form-dialog-secondary">
                                Please {bannerId ? 'edit' : 'add'} banner
                            </DialogContentText>
                            <Grid container className="smallContainer" alignItems="center">
                                {this.state.fields.map((field, index) => {
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <TextValidator
                                                label={field.label}
                                                type="text"
                                                name={field.name}
                                                index={index}
                                                value={field.value}
                                                onChange={this.handleChange(index)}
                                                validators={field.validators}
                                                errorMessages={field.errorMessages}
                                                multiline={field.multiline > 0}
                                                rows={field.multiline}
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    <span>
                                        {newLogoFile ? (
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="form-upload-picture"
                                            >
                                                Banner:{' '}
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="secondary-color"
                                                >
                                                    {imageLogoErrorText.length > 0 ||
                                                    (imageLogoErrorText.length > 0 && image) ? (
                                                        <span
                                                            onDragStart={preventDefaultDrag}
                                                            draggable="false"
                                                            className="error-light"
                                                        >
                                                            error
                                                        </span>
                                                    ) : newLogoFile.name.length > 20 ? (
                                                        newLogoFile.name.substring(0, 17) +
                                                        '..' +
                                                        newLogoFile.name.substring(newLogoFile.name.length - 4)
                                                    ) : (
                                                        newLogoFile.name
                                                    )}
                                                </span>
                                            </span>
                                        ) : null}
                                        {!newLogoFile && !image ? (
                                            <>
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="form-upload-picture"
                                                >
                                                    Banner:{' '}
                                                    {imageLogoErrorText.length > 0 ? (
                                                        <span
                                                            onDragStart={preventDefaultDrag}
                                                            draggable="false"
                                                            className="error-light"
                                                        >
                                                            error
                                                        </span>
                                                    ) : (
                                                        <span
                                                            onDragStart={preventDefaultDrag}
                                                            draggable="false"
                                                            className="grey-color"
                                                        >
                                                            no image
                                                        </span>
                                                    )}
                                                </span>
                                            </>
                                        ) : null}
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="error-message"
                                        >
                                            {imageLogoErrorText}
                                        </span>
                                    </span>
                                </p>
                                <p className="image-explain">
                                    Recommended image ratio 1.5:1 (landscape-oriented, e.g. 860 x 590 pixels)
                                </p>
                                {image ? (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="current-logo-container cover-img-container banner-container-500"
                                    >
                                        {imageLogoPreviewUrl ? (
                                            <img
                                                draggable="false"
                                                onDragStart={preventDefaultDrag}
                                                src={imageLogoPreviewUrl}
                                                alt="preview"
                                            />
                                        ) : (
                                            <img
                                                draggable="false"
                                                onDragStart={preventDefaultDrag}
                                                src={filesUrl + image}
                                                alt="logo"
                                            />
                                        )}
                                        <label
                                            tabIndex="0"
                                            onKeyDown={this.handleEnterKey}
                                            htmlFor="bannerLogoUpload"
                                            className="edit-label banner-upload"
                                        >
                                            <IconButton color="secondary">
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </label>
                                    </div>
                                ) : (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="current-banner-container cover-img-container banner-container-500"
                                    >
                                        {imageLogoPreviewUrl ? (
                                            <>
                                                <img
                                                    draggable="false"
                                                    onDragStart={preventDefaultDrag}
                                                    src={imageLogoPreviewUrl}
                                                    alt="preview"
                                                />
                                                <label
                                                    tabIndex="0"
                                                    onKeyDown={this.handleEnterKey}
                                                    htmlFor="bannerLogoUpload"
                                                    className="edit-label banner-upload"
                                                >
                                                    <IconButton color="secondary">
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                </label>
                                            </>
                                        ) : (
                                            <label
                                                tabIndex="0"
                                                onKeyDown={this.handleEnterKey}
                                                htmlFor="bannerLogoUpload"
                                                className="upload-label banner-upload"
                                            >
                                                <PublishIcon />
                                                <span>Click here to upload</span>
                                            </label>
                                        )}
                                    </div>
                                )}
                                <input
                                    id="bannerLogoUpload"
                                    type="file"
                                    onChange={this.handleLogoChange}
                                    className="upload-image-button d-none"
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions className="justify-content-between padding-LR15">
                            <Button type="button" onClick={handleCloseDialog}>
                                Close
                            </Button>
                            <Button disabled={buttonDisabled} type="submit">
                                Save
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

export default AddEditHomepageBanner;
