import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {preventDefaultDrag} from '../Utils/utils';

class EventLanguageModalMobile extends React.Component {
    render() {
        const {mobile, platformLanguages, platformLanguage, open, handleCloseSelectLanguageDialog, handleChangeLanguage} = this.props;

        return (
            <Dialog
                id="select-languages-dialog"
                open={open}
                onClose={handleCloseSelectLanguageDialog}
                aria-labelledby="form-dialog-title"
                className={`${mobile ? 'dialog-mobile' : ''}`}
            >
                <DialogContent>
                    <div onDragStart={preventDefaultDrag} className="languages-list-container">
                        <ul>
                            {platformLanguages.map((language) => {
                                return (
                                    <li
                                        key={language.value}
                                        value={language.value}
                                        onClick={handleChangeLanguage(language.value)}
                                        className={`${language.value === platformLanguage ? 'active' : ''}`}
                                    >
                                        {language.label}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default EventLanguageModalMobile;
