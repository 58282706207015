import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {SelectValidator} from 'react-material-ui-form-validator';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {changeSelectArrows, countries} from '../../Utils/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import './SelectCountryStyles.scss';
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

class SelectCountryProfile extends ValidatorComponent {
    componentDidMount() {
        changeSelectArrows();
    }

    componentWillUnmount () {}

    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    renderValidatorComponent() {
        const {field} = this.props;
        return (
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-country">{field.value ? null : field.label}</InputLabel>
                {/*In order for the select arrow to be as in the design system:*/}
                {/*- add className scaled-select-arrow to the SelectValidator*/}
                {/*- call changeSelectArrows()*/}
                <SelectValidator
                    className={'scaled-select-arrow'}
                    onChange={this.handleChange}
                    name={field.name}
                    label={field.label}
                    value={field.value ? field.value : ''}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    variant="outlined"
                    fullWidth
                    IconComponent={props => (
                        <ExpandMoreRoundedIcon {...props} className={`material-icons ${props.className}`}/>
                    )}
                >
                    {countries.map((country) => {
                        return (
                            <MenuItem key={country.code} value={country.name}>
                                {country.name}
                            </MenuItem>
                        );
                    })}
                </SelectValidator>
            </FormControl>
        );
    }
}

export default SelectCountryProfile;
