import {preventDefaultDrag} from '../../../Utils/utils';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import Button from '@material-ui/core/Button';
import React from 'react';
import EmailChange from './EmailChange';

const EmailChangeMobile = ({...props}) => {
    const loading = false;
    const {translation, opened, closeDialog, emailChangeSuccess} = props;

    return (
        <div>
            <div
                onDragStart={preventDefaultDrag}
                className={`edit-user-information small-padding-top ${opened ? 'opened' : ''}`}
            >
                {loading && <Spinner />}
                <div>
                    <div onDragStart={preventDefaultDrag} className="page-title">
                        <p>{translation?.emailUpdate.changeEmail}</p>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="content-wrapper">
                        <EmailChange closeConfirmPassword={closeDialog} emailChangeSuccess={emailChangeSuccess} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EmailChangeMobile;
