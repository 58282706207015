import React, {PureComponent} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import InformDialog from '../../../Dialogs/InformDialog';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as ShowFloorIcon} from '../../../Images/svg/showfloor_icon_rooms.svg';
import {calculatePxByScreenHeight, preventDefaultDrag} from '../../../Utils/utils';

class ShowfloorLink extends PureComponent {
    state = {
        titlePixels: 0,
    };

    componentDidMount() {
        this.handleTitleFontSize();
        window.addEventListener('resize', this.handleTitleFontSize);
    }

    componentWillUnmount() {
        window.addEventListener('resize', this.handleTitleFontSize);
    }

    handleTitleFontSize = () => {
        let titleSize = calculatePxByScreenHeight();
        this.setState({
            titlePixels: titleSize,
        });
    };

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return this.props.handleOpenNoShowfloorDialog();
    };

    render() {
        const {titlePixels} = this.state;
        const {
            classes,
            translation,
            event,
            eventSlug,
            location,
            hasAccessToShowfloor,
            showNoShowfloorDialog,
            handleOpenNoShowfloorDialog,
            showNoShowfloorAccessDialog,
            handleOpenNoShowfloorAccess,
            closeDialog,
            lobby,
        } = this.props;

        const activeShowfloor = location.pathname.indexOf(`/${eventSlug}/showfloor`) > -1;

        return (
            <>
                <div onDragStart={preventDefaultDrag} className={classes}>
                    {event.restrictShowfloorAccess ? (
                        <>
                            {lobby ? (
                                <div
                                    tabIndex="0"
                                    onDragStart={preventDefaultDrag}
                                    onClick={handleOpenNoShowfloorDialog}
                                    className="show-info-dialog-button"
                                    onKeyDown={this.handleEnterKey}
                                >
                                    {event.brandingData.exhibitorsDoorText ? (
                                        <div className="door-text-wrapper">
                                            <span
                                                style={{
                                                    fontSize: `${titlePixels}px`,
                                                    color: event.brandingData.lobbyDoorsTextColor
                                                        ? event.brandingData.lobbyDoorsTextColor
                                                        : colors.doorColor,
                                                }}
                                            >
                                                {event.brandingData.exhibitorsDoorText}
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <>
                                    <ShowFloorIcon fill={colors.greyVariant} />
                                    <p onDragStart={preventDefaultDrag} onClick={handleOpenNoShowfloorDialog}>
                                        {event.brandingData.exhibitorsDoorText
                                            ? event.brandingData.exhibitorsDoorText
                                            : translation?.showfloor.showfloorsTitle}
                                    </p>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {!hasAccessToShowfloor ? (
                                <>
                                    {lobby ? (
                                        <div onClick={handleOpenNoShowfloorAccess} className="show-info-dialog-button">
                                            {event.brandingData.exhibitorsDoorText ? (
                                                <div className="door-text-wrapper">
                                                    <span
                                                        style={{
                                                            fontSize: `${titlePixels}px`,
                                                            color: event.brandingData.lobbyDoorsTextColor
                                                                ? event.brandingData.lobbyDoorsTextColor
                                                                : colors.doorColor,
                                                        }}
                                                    >
                                                        {event.brandingData.exhibitorsDoorText}
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <>
                                            <ShowFloorIcon fill={colors.greyVariant} />
                                            <p onDragStart={preventDefaultDrag} onClick={handleOpenNoShowfloorAccess}>
                                                {event.brandingData.exhibitorsDoorText
                                                    ? event.brandingData.exhibitorsDoorText
                                                    : translation?.showfloor.showfloorsTitle}
                                            </p>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Link
                                    tabIndex="0"
                                    className={`${activeShowfloor ? 'active' : ''}`}
                                    to={{pathname: `/event/${eventSlug}/showfloor/`}}
                                >
                                    {event.brandingData.exhibitorsDoorText ? (
                                        <div className="door-text-wrapper" id='showfloor-door-text'>
                                            <span
                                                style={{
                                                    fontSize: `${titlePixels}px`,
                                                    color: event.brandingData.lobbyDoorsTextColor
                                                        ? event.brandingData.lobbyDoorsTextColor
                                                        : colors.doorColor,
                                                }}
                                            >
                                                {event.brandingData.exhibitorsDoorText}
                                            </span>
                                        </div>
                                    ) : null}
                                    {!lobby && (
                                        <>
                                            <ShowFloorIcon fill={colors.greyVariant} />
                                            <p>
                                                {event.brandingData.exhibitorsDoorText
                                                    ? event.brandingData.exhibitorsDoorText
                                                    : translation?.showfloor.showfloorsTitle}
                                            </p>
                                        </>
                                    )}
                                </Link>
                            )}
                        </>
                    )}
                </div>
                {showNoShowfloorDialog && (
                    <InformDialog
                        open={showNoShowfloorDialog}
                        onClose={closeDialog}
                        dialogClasses={'closed-doors'}
                        dialogTitle={event.brandingData.closedShowfloorTitle}
                        dialogDescription={event.brandingData.closedShowfloorText}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoShowfloorAccessDialog && (
                    <InformDialog
                        open={showNoShowfloorAccessDialog}
                        onClose={closeDialog}
                        dialogTitle={`${translation?.closedShowfloorDialog.title}`}
                        dialogDescription={`${translation?.closedShowfloorDialog.contentPackage}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        loggedIn: state.user.loggedIn,
        eventArchive: state.eventArchive.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        hasOnDemandVideos: state.onDemandVideos.hasOnDemandVideos,
    };
};

export default withRouter(connect(mapStateToProps)(ShowfloorLink));
