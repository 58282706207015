import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as UploadIcon } from '../../../../Images/svg/upload-file.svg';
import { deleteSessionThumbnail, updateSessionThumbnail } from '../../../../store/actions';
import { verifyFileType } from 'Utils/verifyFileType';
import './thumbnailImage.scss';

const ThumbnailImage = () => {
    const dispatch = useDispatch();
    const [newImageFile, setNewImageFile] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [imageError, setImageError] = useState('');

    const session = useSelector((state) => state.sessions.sessionDashboard.session);
    const routeParams = useParams();
    const {sessionId} = routeParams;

    useEffect(() => {
        if (session?.thumbnail) {
            setImagePreviewUrl(`${process.env.REACT_APP_SESSION_FOLDER}${session.thumbnail}`);
            setNewImageFile('');
        } else {
            setImagePreviewUrl('');
            setNewImageFile('');
        }
        return () => {
            setImageError('')
        }
    }, [session]);

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;
        if (!file) {
            return;
        }

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (file) {
            if (!isValid) {
                setImagePreviewUrl(null);
                setImageError('File too large. 2Mb max file size.');
            }
            const typeValid = verifyFileType(file.type, 'image');
            if (!typeValid) {
                setImagePreviewUrl(null);
                setImageError('File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.');
            }
            isValid = typeValid && isValid;

            reader.onloadend = () => {
                if (isValid) {
                    setNewImageFile(file);
                    setImagePreviewUrl(reader.result);
                    setImageError('');
                }
            };

            reader.readAsDataURL(file);
        }
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    const handleSetImage = async () => {
        await dispatch(updateSessionThumbnail(session._id, newImageFile));
    };

    const handleDeleteImage = async () => {
        await dispatch(deleteSessionThumbnail(session._id));
    };

    return (
        <div className="module session-thumbnail-image">
            <div className="module-container">
                <div className="module-header">
                    <span>THUMBNAIL IMAGE</span>
                </div>
                <div className="module-body">
                    <input
                        id={`thumbnailImage${sessionId}`}
                        type="file"
                        className="upload-image-button d-none"
                        onChange={handleImageChange}
                    />
                    <div className="image-container">
                        {!imagePreviewUrl && (
                            <label className="upload-label" htmlFor={`thumbnailImage${sessionId}`}>
                                <UploadIcon />
                                <span>Click here to upload</span>
                            </label>
                        )}
                        {imagePreviewUrl && (
                            <img
                                draggable="false"
                                onDragStart={(e) => e.preventDefault()}
                                src={imagePreviewUrl}
                                alt="preview"
                            />
                        )}
                    </div>

                    <div className="buttons-container">
                        {session?.thumbnail && !newImageFile && (
                            <Button
                                type="button"
                                onClick={handleDeleteImage}
                                disabled={!session?.thumbnail ? true : false}
                            >
                                Remove image
                            </Button>
                        )}
                        {newImageFile && (
                            <Button
                                type="button"
                                onClick={handleSetImage}
                                disabled={!imagePreviewUrl || imageError ? true : false}
                                variant="contained"
                                color={'secondary'}
                                disableElevation
                            >
                                Save image
                            </Button>
                        )}
                        {imageError && <p className='error-text'>{imageError}</p>}
                        {imagePreviewUrl && (
                            <Button type="button" >
                                <label className="upload-label" htmlFor={`thumbnailImage${sessionId}`}>
                                    Edit
                                </label>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThumbnailImage;
