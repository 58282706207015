import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import '../../CSS/networkAndLiveWall.scss';
import SideMenuMobile from './SideMenuMobile';
import {LobbySideContainer} from "./LobbySideContainer";
import SideMenuDrawerButtons from "./SideMenuDrawerButtons";
import InfoTabButton from "./InfoTabButton";

class SideNav extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.event.hideLobbyNetworking !== this.props.event.hideLobbyNetworking) {
            if (this.props.event.hideLobbyNetworking && this.props.sideNavigation.seeNetworking) {
                this.handleSeeLiveWall();
            }
        }
    }

    handleSeeNetworking = () => this.props.seeNetworking();
    handleCloseSidebar = () => this.props.closeSidebar();
    handleSeeInfoTab = () => this.props.seeInfoTab();

    handleSeeLiveWall = () => {
        const wallData = {
            wallId: this.props.event.liveWallId,
            highlightUsers: [],
        };

        this.props.setActiveWall(wallData);
        this.props.seeLiveWall();
    };

    handleSeeVideoWall = () => {
        const wallData = {
            wallId: this.props.sideNavigationDetails.currentTimeSlotVideoWallId,
            highlightUsers: this.props.sideNavigationDetails.currentTimeSlotVideoWallSpeakers,
        };
        this.props.setActiveWall(wallData);

        this.props.seeVideoWall();
    };

    handleEnterKey = (tab) => (e) => {
        if (e.key === 'Enter') {
            if (tab === 'wall') return this.handleSeeLiveWall();
            if (tab === 'networking') return this.props.seeNetworking();
            if (tab === 'eventInfo') return this.props.seeInfoTab();
        }
    };

    render() {
        const {
            event,
            eventRoles,
            resourcesAccess,
            sideNavigation,
            sideNavigationDetails,
            isLargeScreen,
            openMatchingDialog,
        } = this.props;

        let hasAccessToNetworking = true;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess && !resourcesAccess.networking) {
            hasAccessToNetworking = false;
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess &&
            !resourcesAccess.networking
        ) {
            hasAccessToNetworking = false;
        }

        if (!isLargeScreen) {
            return (
                <SideMenuMobile
                    openMatchingDialog={openMatchingDialog}
                    handleSeeNetworkingTab={this.handleSeeNetworking}
                    handleSeeLiveWall={this.handleSeeLiveWall}
                    handleSeeVideoWall={this.handleSeeVideoWall}
                    handleSeeInfoTab={this.handleSeeInfoTab}
                />
            );
        }

        return (
            <>
                <SideMenuDrawerButtons
                    handleSeeNetworking={this.handleSeeNetworking}
                    sideNavigation={sideNavigation}
                    handleSeeLiveWall={this.handleSeeLiveWall}
                    handleSeeInfoTab={this.handleSeeInfoTab}
                />
                <LobbySideContainer
                    sideNavigation={sideNavigation}
                    handleCloseSidebar={this.handleCloseSidebar}
                    sideNavigationDetails={sideNavigationDetails}
                    handleSeeLiveWall={this.handleSeeLiveWall}
                    handleEnterKeyWall={this.handleEnterKey('wall')}
                    handleSeeVideoWall={this.handleSeeVideoWall}
                    handleSeeNetworking={this.handleSeeNetworking}
                    handleEnterKeyNetworking={this.handleEnterKey('networking')}
                    handleEnterKeyEventInfo={this.handleEnterKey('eventInfo')}
                    hasAccessToNetworking={hasAccessToNetworking}
                    openMatchingDialog={openMatchingDialog}
                    handleSeeInfoTab={this.handleSeeInfoTab}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        user: state.user.data,
        eventMatching: state.organizer.eventMatching,
        sideNavigation: state.user.sideNavigation,
        sideNavigationDetails: state.event.sideNavigationDetails,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        isLargeScreen: state.layout.isLargeScreen,
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeNetworking: () => dispatch(actions.sideNavSeeNetworking()),
        seeLiveWall: () => dispatch(actions.sideNavSeeLiveWall()),
        seeVideoWall: () => dispatch(actions.sideNavSeeVideoWall()),
        closeSidebar: () => dispatch(actions.sideNavClose()),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeInfoTab: () => dispatch(actions.sideNavSeeInfoTab()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
