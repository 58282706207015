import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {getTimeAgoString, preventDefaultDrag, urlifyString} from '../../Utils/utils';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as CommentsIcon} from '../../Images/svg/comments.svg';
// import {ReactComponent as LikedIcon} from "../../Images/svg/like_active.svg";
import {ReactComponent as LikeIcon} from "../../Images/svg/like.svg";
import {Tooltip} from "@material-ui/core";

class SinglePost extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            showComments: false,
        };
    }

    componentWillUnmount() {
        const eventPage = document.getElementsByClassName('event-page hide-new-comment');
        if (eventPage.length !== 0) {
            eventPage[0].classList.remove('hide-new-comment');
        }
    }

    toggleViewComments = () => {
        this.setState({showComments: !this.state.showComments});
    };

    render() {
        const {
            currentPost,
            isPinned,
        } = this.props;
        let post = currentPost;
        let timeAgoString = getTimeAgoString(post.createdAt);
        let {text} = currentPost;
        text = urlifyString(text);
        const postUser = currentPost.user;

        return (
            <>
                <div
                    key={post._id}
                    className={`single-post-container ${ isPinned ? 'is-pinned' : '' }`}
                >
                    <div onDragStart={preventDefaultDrag} className="post-header">
                        <div
                            className="user-avatar"
                            style={{
                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${postUser?.avatarSmall}')`,
                            }}
                        />
                        <div onDragStart={preventDefaultDrag} className="user-name">
                            <p>
                                {postUser?.first} {postUser?.last}
                            </p>
                            <p>{timeAgoString}</p>
                        </div>
                        <div onDragStart={preventDefaultDrag} className="postActions">
                            <button className="comments-button"
                                    aria-label="Like"
                                    onClick={this.toggleViewComments}
                                    disabled={post.comments.length === 0}
                            >
                                <CommentsIcon width="17" height="17" fill={colors.grey} />
                                <div>
                                    <span
                                        className={`flex-center ${
                                            post.comments.length >= 99
                                                ? 'fs7'
                                                : post.comments.length >= 9
                                                ? 'fs9'
                                                : 'fs11'
                                        }`}
                                    >
                                        {post.comments.length >= 99 ? '99+' : post.comments.length}
                                    </span>
                                </div>
                            </button>
                            <Tooltip arrow title="Restricted">
                                <button
                                    className="like-button restricted"
                                    aria-label="Like"
                                    type="button"
                                >
                                    <LikeIcon width="18" height="18" fill={colors.grey} />
                                    <div>
                                            <span
                                                className={`flex-center ${
                                                    post.likes.length >= 99
                                                        ? 'fs7'
                                                        : post.likes.length >= 9
                                                        ? 'fs9'
                                                        : 'fs11'
                                                }`}
                                            >
                                                {post.likes.length >= 99 ? '99+' : post.likes.length}
                                            </span>
                                    </div>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="post-container">
                        <p onDragStart={preventDefaultDrag}>{text}</p>
                    </div>
                    {this.state.showComments && (
                        <div
                            onDragStart={preventDefaultDrag}
                            className="post-comments"
                            data-empty={post.comments.length}
                        >
                            {post.comments.length ? (
                                <ul className="comments-list-container">
                                    {post.comments.map((comment) => {
                                        const commentUser = comment.user;
                                        let timeAgoString = getTimeAgoString(
                                            comment.createdAt,
                                        );
                                        let commentText = comment.text;
                                        commentText = urlifyString(commentText);
                                        return (
                                            <li key={comment._id} className="single-comment-container">
                                                <div onDragStart={preventDefaultDrag} className="comment-header">
                                                    <div
                                                        className="user-avatar"
                                                        style={{
                                                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${commentUser?.avatarSmall}')`,
                                                        }}
                                                    />
                                                    <div onDragStart={preventDefaultDrag} className="comment-container">
                                                        <p onDragStart={preventDefaultDrag}>
                                                            <span draggable="false" className="user-full-name">
                                                                {commentUser?.first} {commentUser?.last}:
                                                            </span>
                                                            {commentText}
                                                        </p>
                                                        <p onDragStart={preventDefaultDrag} className="time-ago">
                                                            {timeAgoString}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : null}
                        </div>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(SinglePost);
