import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import * as actions from '../store/actions';
import Chats from './EventMenuDropdowns/Chats/Chats';
import Contacts from './EventMenuDropdowns/Contacts';
import MyAccount from './EventMenuDropdowns/MyAccount';
import Notifications from './EventMenuDropdowns/Notifications';
import Program from './EventMenuDropdowns/Program/Program';
import React from 'react';
import Button from '@material-ui/core/Button';
import omit from 'lodash/omit';
import throttle from 'lodash/throttle';
import {isSafari} from 'react-device-detect';
import SMPopup from '.././Dialogs/SMPopup';
import {
    connectOrganizerToEvent,
    connectToEvent,
    deregisterReloadUserData,
    deregisterSocketCustomRegFieldsUpdated,
    disconnectFromEvent,
    eventDeleted,
    eventMatching,
    eventNewEmailDomainAdded,
    eventUsersNewExhibitor,
    eventUsersNewParticipant,
    eventUsersRemovedExhibitor,
    eventUsersRemovedParticipant,
    eventUsersUpdatedExhibitor,
    eventUsersUpdatedOrganizer,
    eventUsersUpdatedParticipant,
    handleUserStatusUpdate,
    newMemberAddedForGroupChat,
    reloadUserData,
    socketCustomRegFieldsUpdated,
    socketEventSettingUpdated,
    userEventRolesUpdate,
} from '../Api/socketApi';
import colors from '../CSS/_variables.module.scss';
import '../CSS/headerMenu.scss';
import Confirm from '../Dialogs/Confirm';
import DetectOutsideClick from '../HOC/DetectOutsideClick';
import AccountFilled from '../Images/svg/AccountFilled';
import ArchiveFilled from '../Images/svg/ArchiveFilled';
import ArchiveIcon from '../Images/svg/ArchiveIcon';
import ChatsFilled from '../Images/svg/ChatsFilled';
import CoOrganizerFilled from '../Images/svg/CoOrganizerFilled';
import ContactsFilled from '../Images/svg/ContactsFilled';
import HomeFilled from '../Images/svg/HomeFilled';
import NotificationsFilled from '../Images/svg/NotificationsFilled';
import ProgramFilled from '../Images/svg/ProgramFilled';
import {ReactComponent as AccountIcon} from '../Images/svg/account.svg';
import {ReactComponent as ChatsIcon} from '../Images/svg/chat.svg';
import {ReactComponent as ProgramIcon} from '../Images/svg/checklist.svg';
import {ReactComponent as CoOrganizerEmpty} from '../Images/svg/co-organizer-empty.svg';
import {ReactComponent as DashboardIcon} from '../Images/svg/dashboard.svg';
import {ReactComponent as HomeIcon} from '../Images/svg/home.svg';
import {ReactComponent as LogoImage} from '../Images/svg/logoEventMenu.svg';
import {ReactComponent as MenuDropdownIcon} from '../Images/svg/menu-dropdown-icon.svg';
import {ReactComponent as ContactsIcon} from '../Images/svg/phone-book.svg';
import {ReactComponent as NotificationsIcon} from '../Images/svg/ring.svg';
import {ReactComponent as Share} from '../Images/svg/sm-share.svg';
import FullOverlayEventDeleted from '../SmallLayoutComponents/FullOverlayEventDeleted';
import {EXHIBITOR_TYPES} from '../Utils/constants/shared';
import {
    checkEventRoles,
    checkPlatformLanguage,
    getNotReadChatMessages,
    getSortedNotifications,
    handleModularCoOrganizerMenu,
    preventDefaultDrag,
} from '../Utils/utils';
import axios from '../store/axios-instance';
import BackButton from './EventMenu/BackButton';
import EventMenuMobile from './EventMenu/EventMenuMobile';
import EventLanguage from './EventMenuDropdowns/EventLanguage';
import MyBooths from './EventMenuDropdowns/MyBooths';
import MyEvents from './EventMenuDropdowns/MyEvents';

class EventMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notReadChatMessages: 0,
            active: false,
            sharePopup: false,
            openLeaveVideoMeetingDialog: false,
            showRemovedFromEventDialog: false,
            usersStatusToBeUpdated: [],
        };
        // throttle is used when the online users is constantly changing (from socket events)
        this.throttleUserStatusUpdates = throttle(this.updateOnlineUsersInStore, 5000);
    }

    componentDidMount() {
        const {event, eventId, eventSlug, user} = this.props;

        const userEventRolesObj = user.eventRoles.find((role) => role.event.slug === eventSlug);
        // using this one instead of eventRoles.isOrganizer because that one comes delayed
        const isOrganizer = userEventRolesObj.roles.includes('organizer');
        // only for pages that are in event
        // add a class to app-container <div>
        // to force the screen to be full height
        document.getElementById('app-container').classList.add('event-page');
        connectToEvent(user._id, event._id);
        // listen to any changes of the eventSettings
        socketEventSettingUpdated((err, settingData) => this.props.onEventSettingUpdated(settingData));
        // listen to changes of custom fields
        socketCustomRegFieldsUpdated((err, data) => this.props.onReloadUserProfile());
        // make sure we listen to the socket event when a new user registers to the event
        eventUsersNewParticipant((err, data) => this.props.onAddEventParticipant(data.participant));
        eventUsersNewExhibitor((err, data) => this.props.onAddEventExhibitor(data.exhibitor));
        // make sure we listen to the socket event when a user unregisters from the event
        eventUsersRemovedParticipant((err, data) => this.props.onRemoveEventParticipant(data.participantId));
        eventUsersRemovedExhibitor((err, data) => this.props.onRemoveEventExhibitor(data.exhibitorId));
        eventDeleted((err, data) => this.handleEventDeleted());
        // make sure we listen to the socket event when a user updates his data from myAccount
        eventUsersUpdatedOrganizer((err, data) => this.props.onUpdateEventOrganizer(data.organizer));
        eventUsersUpdatedParticipant((err, data) => this.props.onUpdateEventParticipant(data.participant));
        eventUsersUpdatedExhibitor((err, data) => this.props.onUpdateEventExhibitor(data.exhibitor));
        // listen to the socket event when users are added to a new email domain
        eventNewEmailDomainAdded((err, data) => this.props.onGetNewEmailDomain(data.eventId));
        // after fetching the getUsers api we initialize the following socket which
        // returns the user id & status everytime a user gets ONLINE || goes OFFLINE
        // and add / remove the userId from the onlineUsers array
        handleUserStatusUpdate((data) => this.handleUpdateOnlineUsersInStore(data));

        newMemberAddedForGroupChat((data) => this.props.onGetGroupChats());

        reloadUserData((data) => this.props.onRefreshUserUserData());

        // updates user eventRoles each time the organizer changes something in the Co-Organizer
        userEventRolesUpdate((err, {eventRoles}) => this.props.onUpdateUserEventRoles(eventRoles));
        // # END - ONLY FOR ORGANIZER
        // we only want to set the platform language just after the user entered the event
        const defaultPlatformLanguage = user.language;
        this.props.onSetPlatformLanguage(defaultPlatformLanguage);
        this.setUserEventRoles();
        this.setUserEventAccess();
        this.calculateNotReadChatMessages();
        // get event Archive
        this.getEventArchive();
        // Matching enabled / disabled socket listener will be in event.enableMatching
        eventMatching((err, data) => {
            if (data.name === 'enableMatching') {
                this.props.onSetEnableMatching(data.value);
            }
        });
        // if user is organizer when logging in or refreshing page
        if (isOrganizer) {
            connectOrganizerToEvent(user._id, eventId);
        }

        // GET the current logged in user his event profile
        this.props.onGetUserEventProfile(eventId, user._id);
    }

    componentDidUpdate(prevProps) {
        const {event, eventId, eventSlug, eventRoles, user} = this.props;
        // Co-Organizer active status check
        const userEventRolesObj = user?.eventRoles?.find((role) => role.event.slug === eventSlug);
        // using this one instead of eventRoles.isOrganizer because that one comes delayed
        const isOrganizer = userEventRolesObj?.roles?.includes('organizer');
        const isCoOrganizerActive = userEventRolesObj?.accessRights?.isActive;
        // connect isOrganizer to the socket then get withRoles information once
        // then update array through the connected socket...
        // // //
        // first prev !== current check is for when changing event
        // last prev !== current check is for when changing event from any other role to
        // an event where you are organizer

        if (
            prevProps.eventId !== eventId &&
            !prevProps.eventRoles.isOrganizer &&
            isOrganizer &&
            !(prevProps.eventId === eventId && !prevProps.eventRoles.isOrganizer && isOrganizer)
        ) {
            connectOrganizerToEvent(user._id, eventId);
        }
        // first prev !== current && isOrganizer check is for when changing event from organizer to organizer
        // last 2 prev !== current check are to prevent running this if a second time if the one
        // above already runned once
        if (
            prevProps.eventId !== eventId &&
            isOrganizer &&
            !(prevProps.eventId !== eventId && !prevProps.eventRoles.isOrganizer && isOrganizer) &&
            !(prevProps.eventId === eventId && !prevProps.eventRoles.isOrganizer && isOrganizer)
        ) {
            connectOrganizerToEvent(user._id, eventId);
        }
        // connect isCoOrganizer to the socket then get withRoles information once
        // then update array through the connected socket
        // first prev !== current checks for if eventRoles for isCoOrganizer, isCoOrganizerActive or eventId changed
        // last prev !== current check is for checking if the CoOrganizer was previously NOT active
        // and became active. isCoOrganizerActive makes sure the CoOrganizer is currently active
        if (
            (prevProps.eventRoles.isCoOrganizer !== eventRoles.isCoOrganizer ||
                prevProps.eventRoles.isCoOrganizerActive !== eventRoles.isCoOrganizerActive ||
                (prevProps.eventId !== eventId && eventRoles.isCoOrganizerActive)) &&
            isCoOrganizerActive
        ) {
            connectOrganizerToEvent(user._id, eventId);
            // if when co-organizer becomes active and has access to auditoriums
            // getEvent in order to have the auditorium dailyProgram populated
            if (userEventRolesObj?.accessRights?.auditoriums) this.props.onGetEvent(eventId);
        }
        // END - ORGANIZER / CO-ORGANIZER

        // when current user object changes, double check its roles
        if (prevProps.user !== user) {
            this.setUserEventRoles();
        }

        if (prevProps.event && prevProps.event._id !== event._id && user) {
            this.setUserEventRoles();
            this.setUserEventAccess();
            // GET the current logged in user his event profile
            this.props.onGetUserEventProfile(eventId, user._id);
        }

        if (
            (prevProps.user && prevProps.user.privateChats !== user.privateChats) ||
            prevProps.user.groupChats !== user.groupChats
        ) {
            this.calculateNotReadChatMessages();
        }

        if (prevProps.event._id !== event._id) {
            disconnectFromEvent(user._id, prevProps.event._id);
            connectToEvent(user._id, event._id);
            // get event Archive
            this.getEventArchive();
        }
        if (prevProps.event.name !== event.name) {
            // disconnectFromEvent(user._id, prevProps.event._id);
            // connectToEvent(user._id, event._id);
            // get event Archive
            // this.getEventArchive();
        }
        if (prevProps.event._id !== event._id && prevProps.eventRoles.isCoOrganizer) {
            const {eventSlug} = this.props;
            this.props.history.push(`/event/${eventSlug}/lobby`);
            this.timeoutId = setTimeout(() => {
                // this.props.history.push(`/event/${eventSlug}/dashboard/settings/event-core-details`);
                this.props.history.push(`/event/${eventSlug}/dashboard/settings`);
            }, 50);
        }
        if (isSafari) {
            require('./EventMenuStyles.scss');
        }
    }

    componentWillUnmount() {
        const {event, user} = this.props;
        disconnectFromEvent(user._id, event._id);
        deregisterSocketCustomRegFieldsUpdated();
        deregisterReloadUserData();
        // remove the class that forced the screen to be full height on event pages
        document.getElementById('app-container').classList.remove('event-page');
        clearTimeout(this.timeoutId);
    }

    updateOnlineUsersInStore = () => {
        const {usersStatusToBeUpdated} = this.state;
        this.props.onSetOnlineUsers(usersStatusToBeUpdated);
        this.setState({
            usersStatusToBeUpdated: [],
        });
    };

    // usersStatusToBeUpdated is an array where we keep
    // all the users that have changed the online status
    // until we actually call the function to update them in the store
    handleUpdateOnlineUsersInStore = ({id, status}) => {
        const {usersStatusToBeUpdated} = this.state;
        const user = {id, status};

        this.setState(
            {
                usersStatusToBeUpdated: [...usersStatusToBeUpdated, user],
            },
            () => {
                this.throttleUserStatusUpdates();
            }
        );
    };

    openSharePopup = () => this.setState({sharePopup: true});
    closeSharePopup = () => this.setState({sharePopup: false});
    refreshPage = () => {
        axios
            .delete(`users/${this.props.user._id}/remove-user-from-redis`)
            .then((data) => {
                window.location.reload(true);
            })
            .catch((error) => {
                window.location.reload(true);
            });
    };

    _closeDialog = () =>
        this.setState({
            openLeaveVideoMeetingDialog: false,
        });

    isActive = () =>
        this.setState({
            active: true,
        });

    _handleConfirmCloseChats = () =>
        this.setState(
            {
                openLeaveVideoMeetingDialog: false,
            },
            () => {
                this.props.seeChats();
            }
        );

    getNotReadNotifications = () => {
        const filteredNotifications = getSortedNotifications(this.props.user.notifications);
        return filteredNotifications.filter((notification) => notification.seenAt === null).length;
    };

    checkExhibitorsCanLogin = () => {
        const {exhibitorData, event} = this.props;
        let canLogin = true;
        if (exhibitorData?.type === EXHIBITOR_TYPES.company) {
            canLogin = event.exhibitorsLogin;
        }
        return canLogin;
    };

    checkScholarCanLogin = () => {
        const {exhibitorData, event} = this.props;
        let canLogin = true;
        if (exhibitorData?.type === EXHIBITOR_TYPES.scholar) {
            canLogin = event.scholarsLogin;
        }
        return canLogin;
    };

    checkisScholar = () => {
        const {exhibitorData} = this.props;
        let isScholar = false;
        if (exhibitorData?.type === EXHIBITOR_TYPES.scholar) {
            isScholar = true;
        }
        return isScholar;
    };

    setUserEventAccess = () => {
        const resourcesAccess = this.getUserEventAccess();
        this.props.onSetEventAccess(resourcesAccess);
    };

    calculateNotReadChatMessages = () => {
        let privateChats = [...this.props.user.privateChats];
        let groupChats = [...this.props.user.groupChats];
        let totalMessagesUnread = getNotReadChatMessages(privateChats, groupChats);
        this.props.onSetNotReadChatMessages(totalMessagesUnread);
    };

    _handleCloseChatsComponent = () => {
        const {videoConference} = this.props;
        if (videoConference.isActive) {
            this.setState({
                openLeaveVideoMeetingDialog: true,
            });
        } else {
            this.props.seeChats();
        }
    };

    getEventArchive = () => {
        const {eventId} = this.props;
        const resourcesAccess = this.getUserEventAccess();
        let hasAccessToEventArchive = true;
        if (resourcesAccess && !resourcesAccess.archive) {
            hasAccessToEventArchive = false;
        }
        if (hasAccessToEventArchive) {
            this.props.onGetEventArchive(eventId);
        }
    };

    setUserEventRoles = () => {
        const {eventId, eventSlug, user} = this.props;
        if (eventId && user) {
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
            const userIsExhibitor = userRolesInEvent && userRolesInEvent.roles.includes('exhibitor');
            const userIsExhibitorRep = userRolesInEvent && userRolesInEvent.roles.includes('exhibitorRep');
            const userIsParticipant = userRolesInEvent && userRolesInEvent.roles.includes('participant');
            const userIsOrganizer = userRolesInEvent && userRolesInEvent.roles.includes('organizer');
            const userIsOrganizerRep = userRolesInEvent && userRolesInEvent.roles.includes('organizerRep');
            const userIsCoOrganizer = userRolesInEvent && userRolesInEvent.roles.includes('coOrganizer');
            const userIsCoOrganizerActive = user?.eventRoles?.find((role) => role.event.slug === eventSlug)
                ?.accessRights?.isActive;
            // when you update the eventRoles here, please make sure that you also update
            // in userReducer the initialEventRoles to be exactly the same
            const eventRoles = {
                isOrganizer: userIsOrganizer,
                isCoOrganizer: userIsCoOrganizer,
                isCoOrganizerActive: userIsCoOrganizerActive || false,
                isOrganizerRep: userIsOrganizerRep,
                isExhibitor: userIsExhibitor,
                exhibitorId: userIsExhibitor ? userRolesInEvent.exhibitor._id : null,
                exhibitorType: userIsExhibitor ? userRolesInEvent.exhibitor.type : null,
                isExhibitorRep: userIsExhibitorRep,
                exhibitorRepIds: userIsExhibitorRep ? userRolesInEvent.exhibitorRepresentative : [],
                isParticipant: userIsParticipant,
                participantId: userIsParticipant ? userRolesInEvent.participant : null,
            };
            this.props.onSetUserEventRoles(eventRoles);
        }
    };

    getUserEventAccess = () => {
        const {eventSlug, event, user} = this.props;
        const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
        const userIsOrganizer = userRolesInEvent && userRolesInEvent.roles.includes('organizer');
        const userIsParticipant = userRolesInEvent && userRolesInEvent.roles.includes('participant');
        const userIsExhibitor = userRolesInEvent && userRolesInEvent.roles.includes('exhibitor');

        let resourcesAccess = null;

        let userIsCompanyExhibitor = false;
        let userIsScholarExhibitor = false;

        // if user is organizer, don't set up any resourcesAccess so that he has full access everywhere
        // if resourcesAccess is null, we don't have any access management on the front end
        if (userIsOrganizer) {
            return resourcesAccess;
        }

        if (userIsExhibitor) {
            userIsCompanyExhibitor = userRolesInEvent.exhibitor.type === EXHIBITOR_TYPES.company;
            userIsScholarExhibitor = userRolesInEvent.exhibitor.type === EXHIBITOR_TYPES.scholar;
        }

        // participant access
        if (event.hasAccessManagement && userIsParticipant) {
            const participantPackageId = userRolesInEvent.accessPackage;

            if (participantPackageId) {
                const participantAccessPackage = event.accessPackages.find(
                    (accessPackage) => accessPackage._id === participantPackageId
                );
                resourcesAccess = participantAccessPackage.resourcesAccess;
            }
        }
        // exhibitor COMPANY access
        if (event.exhibitorHasAccessManagement && userIsCompanyExhibitor) {
            const exhibitorPackageId = userRolesInEvent.exhibitorAccessPackage;

            if (exhibitorPackageId) {
                const exhibitorAccessPackage = event.exhibitorAccessPackages.find(
                    (exhibitorAccessPackage) => exhibitorAccessPackage._id === exhibitorPackageId
                );
                resourcesAccess = exhibitorAccessPackage.resourcesAccess;
            }
        }

        // exhibitor SCHOLAR access
        if (event.scholarHasAccessManagement && userIsScholarExhibitor) {
            const exhibitorPackageId = userRolesInEvent.exhibitorAccessPackage;

            if (exhibitorPackageId) {
                const exhibitorAccessPackage = event.exhibitorAccessPackages.find(
                    (exhibitorAccessPackage) => exhibitorAccessPackage._id === exhibitorPackageId
                );
                resourcesAccess = exhibitorAccessPackage.resourcesAccess;
            }
        }
        return resourcesAccess;
    };

    handleEnterKey = (tab) => (e) => {
        const {eventSlug} = this.props;
        if (e.key === 'Enter') {
            if (tab === 'booths') return this.props.seeMyBooths();
            if (tab === 'events') return this.props.seeMyEvents();
            if (tab === 'contacts') return this.props.seeContacts();
            if (tab === 'chats') return this._handleCloseChatsComponent();
            if (tab === 'program') return this.props.seeProgram();
            if (tab === 'meetings') return this.props.seeMeetings();
            if (tab === 'archive') return this.props.history.push(`/event/${eventSlug}/event-archive`);
            if (tab === 'share') return this.openSharePopup();
            if (tab === 'notifications') return this.props.seeNotifications();
            if (tab === 'account') return this.props.seeMyAccount();
        }
    };

    // check if user is Co-Organizer, isActive and has at least 1 truthy accessRights option
    checkShowCoOrganizerMenu = () => {
        let count = 0;
        const {user, event, eventSlug, eventRoles} = this.props;
        const userEventRolesObj = user.eventRoles.find((role) => role.event.slug === eventSlug);
        // dinamically omit certain accessRights booleans from object (loadash)
        const accessRightsWithoutSomeOptions = omit(userEventRolesObj?.accessRights, [
            'isActive',
            !event.sessionsStreaming ? 'sessions' : '',
        ]);
        if (accessRightsWithoutSomeOptions !== undefined) {
            Object.values(accessRightsWithoutSomeOptions).forEach((value) => value === true && count++);
        }

        return eventRoles?.isCoOrganizer && userEventRolesObj?.accessRights?.isActive && count >= 1;
    };

    handleEventDeleted = () => {
        if (this.props.event.owner._id !== this.props.user._id) {
            this.setState({
                showRemovedFromEventDialog: true,
            });
        }
    };

    handleRedirectToHomepage = () => {
        localStorage.clear();
        window.location.href = '/';
    };

    handleCloseModal = () => {
        this.setState({showRemovedFromEventDialog: false});
    };

    render() {
        const {
            eventSlug,
            eventRoles,
            isLargeScreen,
            translation,
            defaultTranslation,
            languages,
            event,
            serverDown,
            sessionExpired,
            seeMyEvents,
            topNavigation,
            user,
            seeMyBooths,
            location,
            seeContacts,
            chatsDesktop,
            notReadChatMessages,
            seeProgram,
            seeMeetings,
            eventArchive,
            seeNotifications,
            seeMyAccount,
            platformLanguage,
        } = this.props;

        const {openLeaveVideoMeetingDialog, sharePopup} = this.state;

        const eventURL = () => {
            const {eventSlug} = this.props;
            const urlRaw = window.location.href;
            const isPageShowfloor = urlRaw.includes('showfloor');
            let exhibitorId = localStorage.getItem('exhibitorId');
            let showfloorId = localStorage.getItem('showfloorId');

            if (isPageShowfloor) {
                return `${window.location.origin}/event/${eventSlug}/showfloor/${showfloorId}/booth/${exhibitorId}`;
            } else {
                return `${window.location.origin}${eventSlug ? '/event/' + eventSlug : ''}`;
            }
        };
        let notificationsNotRead = this.getNotReadNotifications();
        let activeHome = location.pathname.indexOf('lobby') > -1 ? true : false;
        let activeExhibitorDashboard = location.pathname.indexOf('exhibitor-dashboard') > -1 ? true : false;
        let activeExhibitorRepDashboard = location.pathname.indexOf('exhibitor-booth-view') > -1 ? true : false;
        let activeOrganizerDashboard = location.pathname.indexOf('/dashboard/') > -1 ? true : false;
        let activeEventArchive = location.pathname.indexOf('/event-archive') > -1 ? true : false;
        let activeMyAccount = location.pathname.indexOf('/my-account') > -1 ? true : false;
        const exhibitorCanLogin = this.checkExhibitorsCanLogin();
        const scholarCanLogin = this.checkScholarCanLogin();
        const isScholar = this.checkisScholar();
        const showCoOrganizerMenu = this.checkShowCoOrganizerMenu();
        // check which Co-Organizer accesRights are active and direct Co-Organizer to the
        // first active organizer dashboard tab
        let modularCoOrganizerLink = false;
        const userEventRolesObj = user.eventRoles.find((role) => role.event.slug === eventSlug);
        if (userEventRolesObj?.accessRights) {
            modularCoOrganizerLink = handleModularCoOrganizerMenu(userEventRolesObj?.accessRights, event);
        }
        // if participant or exhibitor can NOT login, hide the EventMenuMobile
        if (!isLargeScreen) {
            return (!event.participantsLogin && !eventRoles.isOrganizer && !eventRoles.isExhibitor) ||
                (eventRoles.isExhibitor && !eventRoles.isOrganizer && !exhibitorCanLogin) ||
                (isScholar && !eventRoles.isOrganizer && !scholarCanLogin) ? null : (
                <EventMenuMobile
                    refreshPage={this.refreshPage}
                    notificationsNotRead={notificationsNotRead}
                    activeMyAccount={activeMyAccount}
                    activeHome={activeHome}
                />
            );
        }

        if (serverDown) {
            setTimeout(() => {
                this.refreshPage();
            }, 5000);
        }

        return (
            <>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`top-container ${languages.platformLanguage === 'ch' ? 'ch' : ''}`}
                >
                    {serverDown && (
                        <div onDragStart={preventDefaultDrag} className="server-down-info-container">
                            <p>
                                {translation?.eventMenu.connectionProblemTextFirst}
                                <span onDragStart={preventDefaultDrag} draggable="false" onClick={this.refreshPage}>
                                    {translation?.eventMenu.connectionProblemButton}
                                </span>
                                {translation?.eventMenu.connectionProblemTextSecond}
                            </p>
                        </div>
                    )}
                    {sessionExpired && (
                        <div onDragStart={preventDefaultDrag} className="server-down-info-container">
                            <p>
                                {translation?.eventMenu.sessionProblemTextFirst}
                                <span onDragStart={preventDefaultDrag} draggable="false" onClick={this.refreshPage}>
                                    {translation?.eventMenu.sessionProblemButton}
                                </span>
                                {translation?.eventMenu.sessionProblemTextSecond}
                            </p>
                        </div>
                    )}
                    <header id="header" className="header">
                        <div onDragStart={preventDefaultDrag} className="main-menu-container">
                            <div onDragStart={preventDefaultDrag} className="actions-container">
                                <div onDragStart={preventDefaultDrag} className="left-header-wrapper">
                                    <Link tabIndex="0" to={{pathname: `/event/${eventSlug}/lobby`}} data-is-navigation>
                                        <LogoImage />
                                    </Link>
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        id="my-events"
                                        className="button-link preserveHeaderDropdown"
                                        onKeyDown={this.handleEnterKey('events')}
                                        data-is-navigation
                                    >
                                        <p onDragStart={preventDefaultDrag} onClick={seeMyEvents}>
                                            <span
                                                className={`${
                                                    checkPlatformLanguage(platformLanguage, [
                                                        'no',
                                                        'en',
                                                        'es',
                                                        'fr',
                                                        'ua',
                                                        'tr',
                                                        'ru',
                                                        'pt',
                                                    ]) && checkEventRoles(eventRoles)
                                                        ? ' max-width-event-name'
                                                        : ''
                                                }  
                                            ${
                                                checkPlatformLanguage(platformLanguage, ['de']) &&
                                                checkEventRoles(eventRoles)
                                                    ? ' max-width-event-name-de'
                                                    : ''
                                            }`}
                                            >
                                                <span
                                                    tabIndex="0"
                                                    className={`${topNavigation.seeMyEvents ? 'rotate-arrow' : ''} ${
                                                        event.name.split(' ').length === 1 ? 'single-row' : ''
                                                    }`}
                                                >
                                                    {event.name}
                                                    <MenuDropdownIcon fill={colors.secondary} />
                                                </span>
                                            </span>
                                        </p>
                                        {topNavigation.seeMyEvents && (
                                            <DetectOutsideClick>
                                                <MyEvents />
                                            </DetectOutsideClick>
                                        )}
                                    </div>
                                </div>
                                <div onDragStart={preventDefaultDrag} className="main-header-wrapper">
                                    <BackButton />
                                    {event ? (
                                        <>
                                            {user.isOrganizer && user._id === event.owner._id ? (
                                                <Link
                                                    tabIndex="0"
                                                    onDragStart={preventDefaultDrag}
                                                    className={`button-link ${
                                                        activeOrganizerDashboard ? 'active' : ''
                                                    }`}
                                                    to={{pathname: `/event/${eventSlug}/dashboard/settings`}}
                                                    data-is-navigation
                                                >
                                                    <DashboardIcon
                                                        fill={`${
                                                            activeOrganizerDashboard ? colors.primary : colors.greyDark
                                                        }`}
                                                    />
                                                    <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                        {translation?.eventMenu.organizerButton}
                                                    </p>
                                                </Link>
                                            ) : null}
                                        </>
                                    ) : null}
                                    {event && showCoOrganizerMenu && (
                                        <div tabIndex="-1" onDragStart={preventDefaultDrag}>
                                            <Link
                                                tabIndex="0"
                                                className={`button-link ${activeOrganizerDashboard ? 'active' : ''}`}
                                                to={{
                                                    pathname: `/event/${eventSlug}/${
                                                        modularCoOrganizerLink !== undefined
                                                            ? modularCoOrganizerLink
                                                            : 'lobby'
                                                    }`,
                                                }}
                                                data-is-navigation
                                            >
                                                {activeOrganizerDashboard ? (
                                                    <CoOrganizerFilled
                                                        gradient1={colors.gradientMenu1}
                                                        gradient2={colors.gradientMenu2}
                                                        gradient3={colors.gradientMenu3}
                                                    />
                                                ) : (
                                                    <CoOrganizerEmpty />
                                                )}
                                                <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                    {translation?.eventMenu.coOrganizerButton ||
                                                        defaultTranslation?.eventMenu.coOrganizerButton}
                                                </p>
                                            </Link>
                                        </div>
                                    )}
                                    {eventRoles.isExhibitor ? (
                                        <Link
                                            tabIndex="0"
                                            className={
                                                'button-link exhibitor-dashboard-button ' +
                                                (activeExhibitorDashboard ? 'active' : '')
                                            }
                                            to={{pathname: `/event/${eventSlug}/exhibitor-dashboard`}}
                                            data-is-navigation
                                        >
                                            <DashboardIcon
                                                fill={`${activeExhibitorDashboard ? colors.primary : colors.greyDark}`}
                                            />
                                            <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                {eventRoles.exhibitorType === 'scholar'
                                                    ? translation?.eventMenu.posterStudyButton ||
                                                      defaultTranslation?.eventMenu.posterStudyButton
                                                    : translation?.eventMenu.exhibitorButton ||
                                                      defaultTranslation?.eventMenu.exhibitorButton}
                                            </p>
                                        </Link>
                                    ) : null}
                                    {!eventRoles.isExhibitor &&
                                    eventRoles.isExhibitorRep &&
                                    eventRoles.exhibitorRepIds &&
                                    eventRoles.exhibitorRepIds.length === 1 ? (
                                        <Link
                                            tabIndex="0"
                                            className={
                                                'button-link exhibitor-dashboard-button ' +
                                                (activeExhibitorRepDashboard ? 'active' : '')
                                            }
                                            to={{
                                                pathname: `/event/${eventSlug}/exhibitor-booth-view/${eventRoles.exhibitorRepIds[0]}`,
                                            }}
                                            data-is-navigation
                                        >
                                            <DashboardIcon
                                                fill={`${
                                                    activeExhibitorRepDashboard ? colors.primary : colors.greyDark
                                                }`}
                                            />
                                            <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                {translation?.eventMenu.boothButton}
                                            </p>
                                        </Link>
                                    ) : null}
                                    {!eventRoles.isExhibitor &&
                                    eventRoles.isExhibitorRep &&
                                    eventRoles.exhibitorRepIds &&
                                    eventRoles.exhibitorRepIds.length > 1 ? (
                                        <div
                                            tabIndex="0"
                                            className={
                                                'button-link exhibitor-dashboard-button ' +
                                                (activeExhibitorRepDashboard || topNavigation.seeMyBooths
                                                    ? 'active'
                                                    : '')
                                            }
                                            id="my-booths"
                                            onClick={seeMyBooths}
                                            data-is-navigation
                                            onKeyDown={this.handleEnterKey('booths')}
                                        >
                                            <DashboardIcon
                                                fill={`${
                                                    activeExhibitorRepDashboard || topNavigation.seeMyBooths
                                                        ? colors.primary
                                                        : colors.greyDark
                                                }`}
                                            />
                                            <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                {translation?.eventMenu.boothsButton}
                                            </p>
                                            {topNavigation.seeMyBooths && (
                                                <DetectOutsideClick>
                                                    <MyBooths />
                                                </DetectOutsideClick>
                                            )}
                                        </div>
                                    ) : null}
                                    <Link
                                        tabIndex="0"
                                        onDragStart={preventDefaultDrag}
                                        className={'button-link home-button ' + (activeHome ? 'active' : '')}
                                        to={{pathname: `/event/${eventSlug}/lobby`}}
                                        data-is-navigation
                                    >
                                        {activeHome ? (
                                            <HomeFilled
                                                gradient1={colors.gradientMenu1}
                                                gradient2={colors.gradientMenu2}
                                                gradient3={colors.gradientMenu3}
                                            />
                                        ) : (
                                            <HomeIcon fill={colors.greyDark} />
                                        )}
                                        <p onDragStart={preventDefaultDrag} className="button-link-label">
                                            {translation?.eventMenu.homeButton}
                                        </p>
                                    </Link>
                                    <div
                                        tabIndex="0"
                                        id="contacts"
                                        className={`button-link ${topNavigation.seeContacts ? 'active' : ''}`}
                                        onKeyDown={this.handleEnterKey('contacts')}
                                        data-is-navigation
                                    >
                                        <div onDragStart={preventDefaultDrag} onClick={seeContacts}>
                                            {topNavigation.seeContacts ? (
                                                <ContactsFilled
                                                    gradient1={colors.gradientMenu1}
                                                    gradient2={colors.gradientMenu2}
                                                    gradient3={colors.gradientMenu3}
                                                />
                                            ) : (
                                                <ContactsIcon
                                                    stroke={`${
                                                        topNavigation.seeContacts ? colors.primary : colors.greyDark
                                                    }`}
                                                    fill={`${
                                                        topNavigation.seeContacts ? colors.primary : colors.greyDark
                                                    }`}
                                                />
                                            )}
                                            <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                {translation?.eventMenu.contactsButton}
                                            </p>
                                        </div>
                                        {topNavigation.seeContacts && (
                                            <DetectOutsideClick>
                                                <Contacts />
                                            </DetectOutsideClick>
                                        )}
                                    </div>
                                    <div
                                        tabIndex="0"
                                        id="chats"
                                        onClick={this._handleCloseChatsComponent}
                                        className={`button-link ${chatsDesktop.seeChats ? 'active' : ''}`}
                                        onKeyDown={this.handleEnterKey('chats')}
                                        data-is-navigation
                                    >
                                        {chatsDesktop.seeChats ? (
                                            <span>
                                                <ChatsFilled
                                                    gradient1={colors.gradientMenu1}
                                                    gradient2={colors.gradientMenu2}
                                                    gradient3={colors.gradientMenu3}
                                                />
                                                {notReadChatMessages && (
                                                    <div
                                                        className={`flex-center ${
                                                            notReadChatMessages.toString().length >= 3 ? 'fs8' : 'fs11'
                                                        }`}
                                                    >
                                                        <span>
                                                            {notReadChatMessages.toString().length >= 3
                                                                ? '99+'
                                                                : notReadChatMessages}
                                                        </span>
                                                    </div>
                                                )}
                                            </span>
                                        ) : (
                                            <span>
                                                <ChatsIcon fill={colors.greyDark} />
                                                {notReadChatMessages && (
                                                    <div
                                                        className={`flex-center ${
                                                            notReadChatMessages.toString().length >= 3 ? 'fs8' : 'fs11'
                                                        }`}
                                                    >
                                                        <span>
                                                            {notReadChatMessages.toString().length >= 3
                                                                ? '99+'
                                                                : notReadChatMessages}
                                                        </span>
                                                    </div>
                                                )}
                                            </span>
                                        )}

                                        <p onDragStart={preventDefaultDrag} className="button-link-label">
                                            {translation?.eventMenu.chatsButton}
                                        </p>
                                    </div>
                                    <div
                                        tabIndex="0"
                                        onDragStart={preventDefaultDrag}
                                        id="program"
                                        className={`button-link ${topNavigation.seeProgram ? 'active' : ''}`}
                                        onKeyDown={this.handleEnterKey('program')}
                                        data-is-navigation
                                    >
                                        <div onDragStart={preventDefaultDrag} onClick={seeProgram}>
                                            {topNavigation.seeProgram ? (
                                                <ProgramFilled
                                                    gradient1={colors.gradientMenu1}
                                                    gradient2={colors.gradientMenu2}
                                                    gradient3={colors.gradientMenu3}
                                                />
                                            ) : (
                                                <ProgramIcon fill={colors.greyDark} />
                                            )}

                                            <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                {translation?.eventMenu.programButton}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div
                                        tabIndex="0"
                                        id="meetings"
                                        className={`button-link ${topNavigation.seeMeetings ? 'active' : ''}`}
                                        onKeyDown={this.handleEnterKey('meetings')}
                                        data-is-navigation
                                    >
                                        <div onDragStart={preventDefaultDrag} onClick={seeMeetings}>
                                            {topNavigation.seeMeetings ? (
                                                <MeetingFilled
                                                    gradient1={colors.gradientMenu1}
                                                    gradient2={colors.gradientMenu2}
                                                    gradient3={colors.gradientMenu3}
                                                />
                                            ) : (
                                                <MeetingIcon
                                                    fillPrimary={colors.greyDark}
                                                    strokeFill={colors.greyDark}
                                                />
                                            )}

                                            <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                {translation?.eventMenu.meetingsButton ||
                                                    defaultTranslation?.eventMenu.meetingsButton}
                                            </p>
                                        </div>
                                        {topNavigation.seeMeetings && (
                                            <DetectOutsideClick>
                                                <Meetings />
                                            </DetectOutsideClick>
                                        )}
                                    </div>*/}
                                    {eventArchive && eventArchive.length > 0 && (
                                        <div
                                            tabIndex="0"
                                            id="event-archive"
                                            className={`button-link ${activeEventArchive ? 'active' : ''}`}
                                            onKeyDown={this.handleEnterKey('archive')}
                                            data-is-navigation
                                        >
                                            <Link tabIndex="-1" to={{pathname: `/event/${eventSlug}/event-archive`}}>
                                                <div>
                                                    {activeEventArchive ? (
                                                        <ArchiveFilled
                                                            gradient1={colors.gradientMenu1}
                                                            gradient2={colors.gradientMenu2}
                                                            gradient3={colors.gradientMenu3}
                                                        />
                                                    ) : (
                                                        <ArchiveIcon
                                                            width="24"
                                                            height="24"
                                                            fillGrey={colors.greyDark}
                                                            fillPrimary={colors.greyDark}
                                                        />
                                                    )}

                                                    <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                        {translation?.eventMenu.archiveButton}
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                <div onDragStart={preventDefaultDrag} className="right-header-wrapper">
                                    <EventLanguage />
                                    {false && isLargeScreen && <EventLanguage />}
                                    {process.env.REACT_APP_SM_SHARE === 'true' && (
                                        <Button
                                            variant={'text'}
                                            startIcon={<Share fill={colors.greyDark} className="sm-share-icon" />}
                                            className="sm-share-button"
                                            onClick={this.openSharePopup}
                                            onKeyDown={this.handleEnterKey('share')}
                                            data-is-navigation
                                        />
                                    )}
                                    <div
                                        tabIndex="0"
                                        onKeyDown={this.handleEnterKey('notifications')}
                                        onDragStart={preventDefaultDrag}
                                        id="notifications"
                                        className="button-link"
                                        data-is-navigation
                                    >
                                        <div onDragStart={preventDefaultDrag} onClick={seeNotifications}>
                                            {topNavigation.seeNotifications ? (
                                                <NotificationsFilled
                                                    gradient1={colors.gradientMenu1}
                                                    gradient2={colors.gradientMenu2}
                                                    gradient3={colors.gradientMenu3}
                                                />
                                            ) : (
                                                <NotificationsIcon width="22" height="22" fill={colors.greyDark} />
                                            )}
                                            {notificationsNotRead && (
                                                <div
                                                    className={`not-read-notifications flex-center ${
                                                        notificationsNotRead.toString().length >= 3 ? 'fs8' : 'fs11'
                                                    }`}
                                                >
                                                    <span>
                                                        {notificationsNotRead.toString().length >= 3
                                                            ? '99+'
                                                            : notificationsNotRead}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {topNavigation.seeNotifications && (
                                            <DetectOutsideClick>
                                                <Notifications />
                                            </DetectOutsideClick>
                                        )}
                                    </div>
                                    <div
                                        tabIndex="0"
                                        onKeyDown={this.handleEnterKey('account')}
                                        id="myaccount"
                                        className={`button-link ${
                                            topNavigation.seeMyAccount || activeMyAccount ? 'active' : ''
                                        }`}
                                        data-is-navigation
                                    >
                                        <div onDragStart={preventDefaultDrag} onClick={seeMyAccount}>
                                            {topNavigation.seeMyAccount || activeMyAccount ? (
                                                <AccountFilled
                                                    gradient1={colors.gradientMenu1}
                                                    gradient2={colors.gradientMenu2}
                                                    gradient3={colors.gradientMenu3}
                                                />
                                            ) : (
                                                <AccountIcon fill={colors.greyDark} />
                                            )}
                                            <p onDragStart={preventDefaultDrag} className="button-link-label">
                                                {translation?.eventMenu.myAccountButton}
                                            </p>
                                        </div>
                                        {topNavigation.seeMyAccount && (
                                            <DetectOutsideClick>
                                                <MyAccount history={this.props.history} closeDropdown={seeMyAccount} />
                                            </DetectOutsideClick>
                                        )}
                                        <Confirm
                                            open={openLeaveVideoMeetingDialog}
                                            closeConfirm={this._closeDialog}
                                            dialogTitle={
                                                translation?.chatsDropdown.leaveVideoMeetingTitle ||
                                                defaultTranslation?.chatsDropdown.leaveVideoMeetingTitle
                                            }
                                            dialogDescription={
                                                translation?.chatsDropdown.leaveVideoMeetingDescription ||
                                                defaultTranslation?.chatsDropdown.leaveVideoMeetingDescription
                                            }
                                            dialogConfirmButtonLabel={
                                                translation?.generalText.leave || defaultTranslation?.generalText.leave
                                            }
                                            handleConfirm={this._handleConfirmCloseChats}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {chatsDesktop.seeChats && (
                        <Chats offset={this.state.chatsOffset} offsetWidth={this.state.chatsOffsetWidth} />
                    )}

                    {(topNavigation.seeProgram || topNavigation.seeMeetings) && (
                        <Program isMeetingsActive={topNavigation.seeMeetings} />
                    )}

                    <SMPopup
                        open={sharePopup}
                        closeConfirm={this.closeSharePopup}
                        dialogTitle={
                            translation?.socialMediaShare?.dialogTitleGeneral ||
                            defaultTranslation?.socialMediaShare?.dialogTitleGeneral
                        }
                        eventName={event.name}
                        popupLanguageLevel="platform"
                        facebookQuote={`${
                            translation?.socialMediaShare?.imAttending ||
                            defaultTranslation?.socialMediaShare?.imAttending
                        } ${event.name ? `"${event.name}"` : ''} ${
                            translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                        }. ${
                            translation?.socialMediaShare?.interestedClick ||
                            defaultTranslation?.socialMediaShare?.interestedClick
                        }`}
                        twitterMessage={`${
                            translation?.socialMediaShare?.imAttending ||
                            defaultTranslation?.socialMediaShare?.imAttending
                        } ${event.name ? `"${event.name}"` : ''} ${
                            translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                        }. ${
                            translation?.socialMediaShare?.interestedClick ||
                            defaultTranslation?.socialMediaShare?.interestedClick
                        }`}
                        emailSubject={`${process.env.REACT_APP_PLATFORM_NAME} - ${event.name}`}
                        emailBody={`${
                            translation?.socialMediaShare?.imAttending ||
                            defaultTranslation?.socialMediaShare?.imAttending
                        } ${event.name ? `"${event.name}"` : ''} ${
                            translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                        }. ${
                            translation?.socialMediaShare?.interestedClick ||
                            defaultTranslation?.socialMediaShare?.interestedClick
                        } ${eventURL()}`}
                        eventURL={eventURL()}
                    />
                </div>
                {this.state.showRemovedFromEventDialog && (
                    <FullOverlayEventDeleted
                        title="Event is unavailable"
                        text={'We are sorry, this event is not available anymore'}
                        buttonText="Go to Homepage"
                        closeOnRedirect={true}
                        handleClose={this.handleCloseModal}
                        handleClick={this.handleRedirectToHomepage}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        event: state.event.data,
        eventArchive: state.eventArchive.data,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        exhibitorData: state.exhibitor.data,
        resourcesAccess: state.user.resourcesAccess,
        loggedIn: state.user.loggedIn,
        notReadChatMessages: state.user.notReadChatMessages,
        topNavigation: state.user.topNavigation,
        chatsDesktop: state.user.chatsDesktop,
        serverDown: state.user.serverDown,
        sessionExpired: state.user.sessionExpired,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
        platformLanguage: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        videoConference: state.videoConference,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeContacts: () => dispatch(actions.topNavSeeContacts()),
        seeMeetings: () => dispatch(actions.topNavSeeMeetings()),
        seeProgram: () => dispatch(actions.topNavSeeProgram()),
        seeChats: () => dispatch(actions.topNavSeeChats()),
        seeMyEvents: () => dispatch(actions.topNavSeeMyEvents()),
        seeMyBooths: () => dispatch(actions.topNavSeeMyBooths()),
        seeNotifications: () => dispatch(actions.topNavSeeNotifications()),
        seeMyAccount: () => dispatch(actions.topNavSeeMyAccount()),
        onSetUserEventRoles: (exhibitorData) => dispatch(actions.setUserEventRoles(exhibitorData)),
        onSetNotReadChatMessages: (numberOfNotReadMessages) =>
            dispatch(actions.setNotReadChatMessages(numberOfNotReadMessages)),
        onGetEventArchive: (eventId) => dispatch(actions.getEventArchive(eventId)),
        onSetEventAccess: (resourcesAccess) => dispatch(actions.setEventAccess(resourcesAccess)),
        onSetPlatformLanguage: (language) => dispatch(actions.setPlatformLanguage(language)),
        onAddEventParticipant: (participant) => dispatch(actions.addEventParticipant(participant)),
        onAddEventExhibitor: (exhibitor) => dispatch(actions.addEventExhibitor(exhibitor)),
        onRemoveEventParticipant: (participantId) => dispatch(actions.removeEventParticipant(participantId)),
        onRemoveEventExhibitor: (exhibitorId) => dispatch(actions.removeEventExhibitor(exhibitorId)),
        onUpdateEventParticipant: (participant) => dispatch(actions.updateEventParticipant(participant)),
        onUpdateEventExhibitor: (exhibitor) => dispatch(actions.updateEventExhibitor(exhibitor)),
        onUpdateEventOrganizer: (organizer) => dispatch(actions.updateEventOrganizer(organizer)),
        onSetOnlineUsers: (user) => dispatch(actions.setOnlineUsers(user)),
        onEventSettingUpdated: (settingData) => dispatch(actions.eventSettingUpdated(settingData)),
        onGetExhibitor: (exhibitorId) => dispatch(actions.getExhibitor(exhibitorId)),
        onUpdateUserEventRoles: (eventRoles) => dispatch(actions.userEventRolesUpdated(eventRoles)),
        onGetEvent: (eventId) => dispatch(actions.getEvent(eventId)),
        onSetEnableMatching: (data) => dispatch(actions.setEnableMatching(data)),
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
        onReloadUserProfile: () => dispatch(actions.triggerReloadUserProfile()),
        onGetNewEmailDomain: (eventId) => dispatch(actions.getEmailDomainsWithUsers(eventId)),
        onGetGroupChats: () => dispatch(actions.getGroupChats()),
        onRefreshUserUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventMenu));
