import React from "react";

const EmptyState = ({textOne, textTwo}) => {

    const EmptySearchResultIcon = () => {
        return (
            <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_19338_190283)">
                    <path d="M53.7153 51.0068H52.289L51.7834 50.5193C53.9501 47.9915 55.0695 44.5429 54.4556 40.8776C53.607 35.8582 49.4181 31.8498 44.3626 31.2359C36.7251 30.297 30.2973 36.7248 31.2362 44.3623C31.8501 49.4179 35.8584 53.6068 40.8778 54.4554C44.5431 55.0693 47.9917 53.9498 50.5195 51.7832L51.007 52.2887V53.7151L58.6806 61.3887C59.4209 62.129 60.6306 62.129 61.3709 61.3887C62.1112 60.6484 62.1112 59.4387 61.3709 58.6984L53.7153 51.0068ZM42.882 51.0068C38.3862 51.0068 34.757 47.3776 34.757 42.8818C34.757 38.3859 38.3862 34.7568 42.882 34.7568C47.3778 34.7568 51.007 38.3859 51.007 42.8818C51.007 47.3776 47.3778 51.0068 42.882 51.0068Z" fill="#5AB453"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M57.7761 36.5441C57.0358 36.6498 56.3589 36.1307 56.1603 35.4096C54.9806 31.1245 51.2749 28.3837 47.1421 27.3258C46.4175 27.1403 45.9171 26.4485 46.04 25.7107C46.163 24.973 46.8619 24.4697 47.5884 24.6473C52.5597 25.8624 57.4489 29.2696 58.86 35.0231C59.0381 35.7494 58.5165 36.4383 57.7761 36.5441Z" fill="#5AB453"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M27.0816 49.7926C27.8093 49.6204 28.5312 50.0755 28.7833 50.7796C30.3166 55.0616 34.0479 57.6544 38.396 58.0988C39.14 58.1749 39.7483 58.7736 39.7483 59.5214C39.7483 60.2693 39.1406 60.8818 38.3954 60.8182C32.9045 60.3498 28.011 57.0294 26.1339 51.407C25.8971 50.6976 26.3538 49.9648 27.0816 49.7926Z" fill="#5AB453"/>
                    <path d="M29.2917 7.70833V29.2917H7.70833V7.70833H29.2917ZM29.2917 4.625H7.70833C6.0125 4.625 4.625 6.0125 4.625 7.70833V29.2917C4.625 30.9875 6.0125 32.375 7.70833 32.375H29.2917C30.9875 32.375 32.375 30.9875 32.375 29.2917V7.70833C32.375 6.0125 30.9875 4.625 29.2917 4.625Z" fill="#5AB453"/>
                    <path d="M21.5833 26.2077H10.7916V23.1243H21.5833V26.2077ZM26.2083 20.041H10.7916V16.9577H26.2083V20.041ZM26.2083 13.8743H10.7916V10.791H26.2083V13.8743Z" fill="#5AB453"/>
                    <rect x="10.7916" y="16.959" width="15.4167" height="3.08333" fill="#5AB453"/>
                    <rect x="10.7916" y="23.125" width="10.7917" height="3.08333" fill="#5AB453"/>
                </g>
                <defs>
                    <clipPath id="clip0_19338_190283">
                        <rect width="65" height="65" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <div className='empty-program-container'>
            <div className='empty-program-search'>
                <EmptySearchResultIcon/>
                    <p>{textOne}</p>
                    <p>{textTwo}</p>
            </div>
        </div>
    )

}

export default EmptyState;