import TopBarAgenda from './TopBarAgenda';
import MeetingAndSessionList from './MeetingAndSessionList';
import './agendaStyles.scss';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import Sessions from './Sessions';
import Meetings from './Meetings';
import {useDispatch, useSelector} from 'react-redux';
import {refreshUserData, updateUserInformation} from 'store/actions';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment/moment';
import {
    getMyAgenda,
    getSessionsMyAgenda,
    getMeetingsMyAgenda,
    deleteATimeslotFromMyAgenda,
} from 'store/actions/myAgendaActions';
import {usePlatformTranslation} from 'services/hooks';

const Agenda = ({setActiveTimeslot, activeTimeslot, pastSlots, setPastSlotsState}) => {
    //dispatch
    const dispatch = useDispatch();

    /*If there is a NOT expensive calculation and if we do not want to
    run the function when the page loads for the first time.
    Simply we can use a ref and use effect combination instead of useMemo or similar hook.
    */
    const isFirstUpdate = useRef(true);

    //REDUX
    const user = useSelector((state) => state?.user?.data);
    const event = useSelector((state) => state?.event?.data);
    const agenda = useSelector((state) => state?.myAgenda);
    const topNavigation = useSelector((state) => state?.user?.topNavigation);

    const platformLanguage = useSelector((state) => state.languages?.platformLanguage);

    /*end translations*/

    /*Except spesific situation, we do not need to call every redux state.
     * We call the main state of related functionality. In this case 'agenda'.
     * And we get what we want from 'agenda'.
     * As you see there is an expression. agenda:myAgenda.
     * It means -I gave a new name to the 'agenda' which i'll call it as 'agendaMeetings' from now
     * */
    const {agendaSessions, agenda: myAgenda, agendaMeetings} = agenda;
    const isThereMeetingRequest = Object.values(agendaMeetings)?.some((el) => el.status === 'pending');

    //LOCAL STATES
    /*Lets keep the local states and redux states always separately for a better reading experience */
    const [filterTypes, setFilterTypes] = useState([
        {
            id: 0,
            label: 'all',
            value: !topNavigation?.seeMeetings,
        },
        {
            id: 1,
            label: 'meetings',
            value: !!topNavigation?.seeMeetings,
        },
        {
            id: 2,
            label: 'sessions',
            value: false,
        },
    ]);

    const [loadingSearchResults, setLoadingSearchResults] = useState(false);
    const [formattedAgenda, setFormattedAgenda] = useState(null);
    const [firstDateMobile, setfirstDateMobile] = useState('');

    /*When the page loads or the related depencies affected from any change, we make a action call.
     * Which action call will works conditions will decide it. By checking filter type.
     * */
    useEffect(() => {
        filterTypes[0].value && getMyAgenda(user?._id, true, user?.timezoneValue || event?.timezoneValue)(dispatch);
        filterTypes[1].value &&
            getMeetingsMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValuee)(dispatch);
        filterTypes[2].value &&
            getSessionsMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch);
    }, [filterTypes, pastSlots, user?.timezoneValue]);

    /*Agenda needs to be formated time to time which depens its depencies
     * We do add some new properties to agenda items(localy) and make it useable for FE.
     * */
    useEffect(() => {
        formatMyAgenda();
    }, [myAgenda, agendaMeetings, agendaSessions, user?.timezoneValue, event?.timezoneValue]);

    /*
     * To be able to show gmt correctly we need formated hours according to timezones
     * */
    const getFormattedHourByTimezone = (timestamp, timezoneDifference) => {
        const timestampWithoutLocalTimezone = moment.utc(timestamp).format('l LT');
        const calculatedTimestampBasedOnTimezoneDifference = moment(timestampWithoutLocalTimezone).add(
            timezoneDifference,
            'hours'
        );
        return moment(calculatedTimestampBasedOnTimezoneDifference).format('HH:mm');
    };

    const getTimezoneDifference = () => {
        // we know that all timestamps are in GMT+0
        // so we want to calculate all the timestamps based either on user timezone or event timezone
        // if user selected a timezone, we want to return it
        if (user?.timezoneValue !== '') {
            return +user?.timezoneValue;
        }
        return event?.timezoneValue;
    };
    /*If the related item is live or not.*/
    const checkIfIsLive = (start, end) => {
        const currentTimestamp = moment.utc(new Date());
        return currentTimestamp.isBetween(start, end);
    };
    /*If the related item is past.*/
    const checkIfIsPast = (end) => {
        const currentTimestamp = moment.utc(new Date());
        return currentTimestamp.isAfter(end);
    };

    /*Format the agenda to the shape which we want to use*/
    const formatMyAgenda = () => {
        const timezoneDifference = getTimezoneDifference();
        let formattedAgenda = [];

        let currentData = [];
        if (filterTypes[0].value) {
            currentData = myAgenda;
        } else if (filterTypes[1].value) {
            currentData = agendaMeetings;
        } else if (filterTypes[2].value) {
            currentData = agendaSessions;
        }

        // forEach agendaItem
        // we want to add startHours and endHours so that we can use them in the UI
        // we get a main object with many object inside of it.
        // we create a new array from the currentData's object values.
        // with this values we create new object with the properties which we want.
        // then we push it to formattedAgenda(which we use on UI)
        Object.entries(currentData).forEach(([agendaItemKey, agendaItem]) => {
            const formattedAgendaItem = cloneDeep(agendaItem);
            const startTimestamp =
                agendaItem?.type === 'meeting' ? formattedAgendaItem.start : formattedAgendaItem.startTimestamp;
            const endTimestamp =
                agendaItem?.type === 'meeting' ? formattedAgendaItem.end : formattedAgendaItem.endTimestamp;
            formattedAgendaItem.startHours = getFormattedHourByTimezone(startTimestamp, timezoneDifference);
            formattedAgendaItem.endHours = getFormattedHourByTimezone(endTimestamp, timezoneDifference);
            formattedAgendaItem.isLive = checkIfIsLive(startTimestamp, endTimestamp);
            formattedAgendaItem.isPast = checkIfIsPast(endTimestamp);

            formattedAgenda.push(formattedAgendaItem);
        });
        formattedAgenda = formattedAgenda.filter(
            (item) => (item.type === 'meeting' && item.status !== 'canceled') || item.type === 'session'
        );
        setFormattedAgenda(formattedAgenda);
    };

    /*Filters - All, Sessions, Meetings*/
    const handleChangeFilter = (e) => {
        const {name, checked} = e.target;
        setActiveTimeslot(null);
        setFilterTypes(
            filterTypes.map((filter) =>
                filter.label === name ? {...filter, value: checked} : {...filter, value: false}
            )
        );
        /*In mobile we show a date between menu and gmt
         * And we want to remove the date when the filter changed.
         * */
        setDateForMobile('');
    };

    /* User edit user timezone then we refresh user data to be able to use this information without refreshing the page*/
    const setUserTimezone = (data) => {
        setLoadingSearchResults(true);
        dispatch(updateUserInformation(data))
            .then((response) => {
                dispatch(refreshUserData()).then(() => {
                    setLoadingSearchResults(false);
                });
            })
            .catch((error) => {
                setLoadingSearchResults(false);
            });
    };

    // It removes a timeslot from my agenda
    const deleteFromMyAgenda = (timeslot) => () => {
        setActiveTimeslot(null);
        let timeslotCopy = {...timeslot};
        let newTimeslot = {
            program: timeslotCopy.program,
        };
        deleteATimeslotFromMyAgenda(
            user?._id,
            newTimeslot?.program
        )(dispatch).then(() => {
            filterTypes[0].value &&
                getMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch);
            filterTypes[2].value &&
                getSessionsMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch);
        });
    };

    useLayoutEffect(() => {
        if (isFirstUpdate.current) {
            isFirstUpdate.current = false;
            return;
        }
    }, []);

    //All data we use for the process
    let sessions = formattedAgenda?.filter((el) => el.type === 'session') || [];
    let meetings = formattedAgenda?.filter((el) => el.type === 'meeting') || [];
    let allAgenda = formattedAgenda || [];

    //It affects only mobile
    const setDateForMobile = (date) => {
        setfirstDateMobile(date);
    };

    //Every time when the pastSlots changed, it should clean the date for mobile.
    //It will run in the first render.
    useEffect(() => {
        setDateForMobile('');
    }, [pastSlots]);

    return (
        <div className="agenda-container">
            <TopBarAgenda
                handleChangeFilter={handleChangeFilter}
                setPastSlotsState={setPastSlotsState}
                setActiveTimeslot={setActiveTimeslot}
                setUserTimezone={setUserTimezone}
                pastSlots={pastSlots}
                filterTypes={filterTypes}
                firstDateMobile={firstDateMobile}
            />

            <div className={`items-main-container ${platformLanguage === 'ar' && 'ar-lang-all'}`}>
                {filterTypes[1].value && (
                    <Meetings
                        loading={loadingSearchResults}
                        meetings={meetings}
                        isThereMeetingRequest={isThereMeetingRequest}
                        isComponentActive={filterTypes[1].value}
                        pastSlots={pastSlots}
                        setDateForMobile={setDateForMobile}
                    />
                )}
                {filterTypes[2].value && (
                    <Sessions
                        handleSetActiveTimeslot={setActiveTimeslot}
                        loading={loadingSearchResults}
                        deleteFromMyAgenda={deleteFromMyAgenda}
                        sessions={sessions}
                        pastSlots={pastSlots}
                        activeTimeslot={activeTimeslot}
                        isComponentActive={filterTypes[2].value}
                        setDateForMobile={setDateForMobile}
                    />
                )}

                {filterTypes[0].value && (
                    <MeetingAndSessionList
                        loading={loadingSearchResults}
                        agenda={allAgenda}
                        handleSetActiveTimeslot={setActiveTimeslot}
                        pastSlots={pastSlots}
                        activeTimeslot={activeTimeslot}
                        deleteFromMyAgenda={deleteFromMyAgenda}
                        setDateForMobile={setDateForMobile}
                    />
                )}
            </div>
        </div>
    );
};

export default Agenda;
