export let emailTemplate = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN"
        "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang="en">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style type='text/css'>
    body {
            margin: 0;
            background-color: #FFFFFF;
            font-family: Montserrat, Verdana, sans-serif;
            font-style: normal;
        }
        u + .body {
            margin: 0;
            background-color: #FFFFFF;
            font-family: Montserrat, Verdana, sans-serif;
            font-size: 16px;
            line-height: 24px;
        }
        table {
            border-spacing: 0;
        }
        td {
            padding: 0;
        }
        img {
            border: 0;
        }
        a {
            text-decoration: none;
        }
        .wrapper {
            width: 100%;
            table-layout: fixed;
            background-color: #FFFFFF;
        }
        .main-email-template {
            background-color: #FFFFFF;
            margin: 0 auto;
            width: 100%;
            max-width: 600px;
            border-spacing: 0;
            font-family: Montserrat, Verdana, sans-serif;
        }
        .margin-0 {
            margin: 0 !important;
        }
        .margin1em {
            margin-top: 1em !important;
            margin-bottom: 1em !important;
        }
        @media screen {
            @font-face {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            @font-face {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            @font-face {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
        }
        @media screen and (max-width: 600px) {
            .six-digit-code {
               font-size: 24px !important;
            }
            .mobile-header {
                font-size: 20px !important;
                line-height: 18px !important;
            }
        }
    </style>
</head>
<body class="body">
    <center class="wrapper" style="width: 100%;table-layout: fixed;background-color: #FFFFFF;">
        <table class="main-email-template" width='100%' style="background-color: #FFFFFF;width: 100% !important; max-width: 600px; border-spacing: 0; font-family: Montserrat, Verdana, sans-serif">
            <tr align="center">
                <td height='56' style="text-align: center; height: 56px">
                    <a rel="noopener" href="https://myonvent.com/" target="_blank">
                        <img src='https://myonvent-s3bucket.s3.eu-central-1.amazonaws.com/files/email-template-pictures/myonvent_logo_horizontal_3x.png' alt='myOnvent-logo' style='width: 130px; height: 16px;' width="130" height="16">
                    </a>
                </td>
            </tr>
            <tr>
                <td style="text-align: center">
                    <table width="100%" style="text-align: center; width: 100% !important; max-width: 600px">
                        <tr>
                            <td style="width: 100% !important; height: 181px; background-color: [BG-COLOR]; text-align: center" bgcolor=""[BG-COLOR]"">
                                <h1 class="mobile-header" style='font-family: Montserrat, Verdana, sans-serif;font-size: 24px; font-weight: 700;color:#2B2B2B;padding-top: 24px;line-height:22px;  letter-spacing: -0.015em; margin: 0!important; text-align: center'>[HEADER-TITLE]</h1>
                                <a rel="noopener" href="https://myonvent.com/" target="_blank">
                                    <img src='[HEADER-IMAGE]' alt='image-header' style='width: 178px; height: 128px;' width="178" height="128">
                                </a>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style='height: 23px' height="23"></td>
            </tr>
            <tr>
                <td>
                    <table width="100%" style="max-width: 600px;">
                        <tr>
                            <td style='padding-left: 32px;padding-right: 32px;'>
                                [BODY-TEMPLATE]
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style='height: 46px' height="46"></td>
            </tr>
            <tr>
                <td style="text-align: center;">
                    <table style="text-align:center; margin: 0 auto;width: 346px" width="346px">
                        <tr>
                            <td style='height: 42px;text-align: center;margin: auto;font-family: Montserrat, Verdana, sans-serif;'>
                                <p style='font-family: Montserrat, Verdana, sans-serif;font-weight: 400;font-size: 12px;line-height: 14px;text-align: center;margin: auto;color: #777081;'>You receive this email because you have previously signed up to one of the events at myOnvent platform. This is an informative email. No reply is required</p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style='height: 24px' height="24"></td>
            </tr>
            <tr>
                <td style="text-align: center; font-size: 0">
                    <table width="264px" style="text-align: center; border-collapse: collapse; border-spacing: 0; margin: 0 auto" cellspacing="0">
                        <tr style="margin: 0 !important;">
                            <td style="text-align: center">
                                <a rel="noopener" href='https://www.linkedin.com/company/myonvent' target="_blank">
                                    <img src='https://myonvent-s3bucket.s3.eu-central-1.amazonaws.com/files/email-template-pictures/li_icon_new.png' alt='linkedin-logo' width="54" height="54">
                                </a>
                            </td>
                            <td style="text-align: center">
                                <a rel="noopener" href='https://twitter.com/myonvent' target="_blank">
                                    <img src='https://myonvent-s3bucket.s3.eu-central-1.amazonaws.com/files/email-template-pictures/twitter_icon_new.png' alt='twitter-logo' width="54" height="54">
                                </a>
                            </td>
                            <td style="text-align: center">
                                <a rel="noopener" href="https://www.facebook.com/myonvent/" target="_blank">
                                    <img src='https://myonvent-s3bucket.s3.eu-central-1.amazonaws.com/files/email-template-pictures/fb_icon_new.png' alt='fb-logo' width="54" height="54">
                                </a>
                            </td>
                            <td style="text-align: center">
                                <a rel="noopener" href="https://myonvent.com/blog/" target="_blank">
                                    <img style="width: 54px; height: 54px" src='https://myonvent-s3bucket.s3.eu-central-1.amazonaws.com/files/email-template-pictures/myonvent_blog_new.png' alt='myOnvent-logo' width="54" height="54">
                                </a>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style='height: 24px' height="24"></td>
            </tr>
            <tr>
                <td style="text-align: center;">
                    <table style='text-align:center; margin: 0 auto; width:346px;' width='346px'>
                        <tr>
                            <td style='text-transform: uppercase;text-align: center;height: 52px;margin: auto;'>
                                <p style='font-family: Montserrat, Verdana, sans-serif;font-weight: 400;font-size: 12px;line-height: 14px;text-align: center;margin: auto;color: #777081;'>myOnvent is a global online events company founded in Åsmarka, norway</p>
                                <p class="margin1em" style='font-family: Montserrat, Verdana, sans-serif;font-weight: 500;font-size: 12px;line-height: 14px;text-align: center;margin: auto;color: #777081;'>&copy; <span id="copyright-year">2023</span></p> 
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </center>
    <script>
        document.getElementById('copyright-year').textContent = new Date().getFullYear();
    </script>
</body>
</html>`;
