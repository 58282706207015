import React, {useEffect, useRef, useState} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {SelectValidator} from 'react-material-ui-form-validator';
import {changeSelectArrows} from 'Utils/utils';
import FormControl from '@material-ui/core/FormControl';
import '../../../../Components/InputFields/SelectCountryStyles.scss';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import FormLabel from '@material-ui/core/FormLabel';
import classnames from 'classnames';
import {useSelector} from 'react-redux';

const SelectFieldLoginRegistration = (props) => {
    const inputValidator = useRef(null);

    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const handleChange = (e) => {
        const {name, value} = e.target;
        props.handleChange(name, value);
    };

    const isValid = inputValidator?.current?.isValid();
    const {field} = props;
    const isRequired = field.validators.includes('required');

    useEffect(() => {
        changeSelectArrows();
    }, []);

    return (
        <div
            className={classnames('selectFieldV2-container', {
                'error-border': isValid === false,
            })}
        >
            <FormControl variant="outlined" fullWidth>
                {/*In order for the select arrow to be as in the design system:*/}
                {/*- add className scaled-select-arrow to the SelectValidator*/}
                {/*- call changeSelectArrows()*/}

                <FormLabel className="break-word registration-usage select-field-v2" component="legend">
                    {field.label}
                </FormLabel>

                <SelectValidator
                    className={`${field.type}-${field.name}-mo-error select-field-v2`}
                    label={
                        field.value === '' &&
                        (translation.newLoginRegistrations.chooseAnOption ||
                            defaultTranslation.newLoginRegistrations.chooseAnOption) +
                            (!isRequired
                                ? ` (${
                                      translation.newLoginRegistrations.optional ||
                                      defaultTranslation.newLoginRegistrations.optional
                                  })`
                                : '')
                    }
                    onChange={handleChange}
                    name={field.name}
                    value={field.value ? field.value : ''}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    ref={inputValidator}
                    variant="outlined"
                    fullWidth
                    IconComponent={(props) => (
                        <ExpandMoreRoundedIcon {...props} className={`material-icons ${props.className}`} />
                    )}
                >
                    {field.options.map((option) => {
                        return (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </SelectValidator>
            </FormControl>
        </div>
    );
};

export default SelectFieldLoginRegistration;
