import {preventDefaultDrag} from "../../../Utils/utils";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import UploadedTimeslotDocument from "../Auditoriums/UploadedTimeslotDocument";
import Button from "@material-ui/core/Button";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import React from "react";
import * as PropTypes from "prop-types";

export class NewResourceDocument extends React.Component {
    render() {
        let {
            uploadDocument,
            textFieldRef,
            newDocumentName,
            changeDocumentName,
            newDocumentFile,
            fileErrorText,
            handleDocumentChange,
            loading,
        } = this.props;
        return <div onDragStart={preventDefaultDrag} className="resource-container">
            <ValidatorForm
                ref="form"
                onSubmit={uploadDocument}
                className={"d-flex"}>
                <div onDragStart={preventDefaultDrag} className="resource-inputs documents">
                    <div onDragStart={preventDefaultDrag}>
                        <TextValidator
                            inputRef={textFieldRef}
                            classes={{root: "input-padding-right setting-input documentsLabel"}}
                            id="title"
                            label="Document name"
                            type="text"
                            value={newDocumentName}
                            onChange={changeDocumentName}
                            fullWidth={true}
                            margin="normal"
                            variant="outlined"
                            validators={["maxStringLength: 100"]}
                            errorMessages={["You have reached the maximum limit of characters allowed (100 characters)"]}
                        />
                    </div>
                    <div className={`mr-auto ${fileErrorText ? '' : 'hide'}`}>
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="error-message">
                                        {fileErrorText}
                                    </span>
                        {!newDocumentName && newDocumentFile && (
                            <p onDragStart={preventDefaultDrag} className="error-message">
                                Please add a document name
                            </p>
                        )}
                    </div>

                    <UploadedTimeslotDocument
                        newDocumentFile={newDocumentFile}
                        fileErrorTextLength={fileErrorText.length}
                        handleDocumentChange={handleDocumentChange}
                    />
                </div>

                <div onDragStart={preventDefaultDrag} className="uploadfile-prop d-flex align-items-center">
                    <div>
                        <Button
                            type="submit"
                            disabled={!newDocumentName.trim() || !newDocumentFile || loading || fileErrorText.length > 0}
                            startIcon={<AddOutlinedIcon/>}
                        >
                            ADD
                        </Button>
                    </div>
                </div>
            </ValidatorForm>
        </div>;
    }
}

NewResourceDocument.propTypes = {
    uploadDocument: PropTypes.any,
    textFieldRef: PropTypes.any,
    newDocumentName: PropTypes.any,
    changeDocumentName: PropTypes.any,
    newDocumentFile: PropTypes.any,
    fileErrorText: PropTypes.any,
    handleDocumentChange: PropTypes.any,
    loading: PropTypes.any
}