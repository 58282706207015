import React, {PureComponent} from 'react';
import {formatDecimalHoursToHoursAndMinutes, getEventDaysArray, preventDefaultDrag} from '../../Utils/utils';
import {connect} from 'react-redux';
import EventSpeakers from "./EventSpeakers";

class AuditoriumSingleDayProgram extends PureComponent {
    state = {
        eventDays: [],
    };

    componentDidMount() {
        let eventDays = getEventDaysArray(this.props.eventInfo.eventStartDate, this.props.eventInfo.eventEndDate);
        this.setState({
            eventDays: eventDays,
        });
    }

    render() {
        const {auditorium, displayedDate, eventInfo, brandingTranslation} = this.props;
        const currentProgram = auditorium.displayProgram.find((program) => program.date === displayedDate);
        const auditoriumHasProgram = currentProgram.program.length > 0;

        return (
            <div onDragStart={preventDefaultDrag} className="single-auditorium-program">
                <h4 className="auditorium-name">{auditorium.name}</h4>
                {eventInfo.showTimezone && (
                    <span onDragStart={preventDefaultDrag} draggable="false" className="timezone-text">
                        GMT
                        {eventInfo.timezoneValue >= 0 ? '+' : eventInfo.timezoneValue < 0 ? '-' : ''}
                        {formatDecimalHoursToHoursAndMinutes(eventInfo.timezoneValue)}
                    </span>
                )}
                <div onDragStart={preventDefaultDrag} className="auditorium-program-wrapper">
                    {auditoriumHasProgram ? (
                        <ul className="program-list-container">
                            {currentProgram.program.map((programEntry) => {
                                // let {description} = programEntry;
                                // if (description){
                                //     description = linkify(description);
                                // }
                                return (
                                    <li key={programEntry._id} className="program-entry">
                                        <p onDragStart={preventDefaultDrag} className="program-time">
                                            {programEntry.start} - {programEntry.end}
                                        </p>
                                        <h4>{programEntry.title}</h4>
                                        <div
                                            className={'program-entry-description'}
                                            onDragStart={preventDefaultDrag}
                                            dangerouslySetInnerHTML={{
                                                __html: programEntry.description,
                                            }}
                                        />
                                        <EventSpeakers speakers={programEntry.speakers} defaultTranslation={this.props.defaultTranslation} translation={this.props.translation}/>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <p onDragStart={preventDefaultDrag} className="note">
                            {brandingTranslation.homepageNoProgramText}
                        </p>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventInfo: state.event.eventInfo,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps, null)(AuditoriumSingleDayProgram);
