import React, {useCallback} from 'react';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as Arrow} from '../../Images/svg/showfloor-arrow-image.svg';
import {preventDefaultDrag} from '../../Utils/utils';
import {useSelector} from 'react-redux';

const AuditoriumNavigationMobile = (props) => {
    const {auditoriums, auditoriumRoomIndex, timeSlot, setActiveAuditoriumIndex, timezoneName} = props;

    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const getPreviousAuditoriumIndex = useCallback(() => {
        let previousAuditoriumIndex = auditoriumRoomIndex - 1;
        if (auditoriumRoomIndex === 0) {
            previousAuditoriumIndex = auditoriums.length - 1;
        }
        return previousAuditoriumIndex;
    }, [auditoriumRoomIndex, auditoriums]);

    const getNextAuditoriumIndex = useCallback(() => {
        let nextAuditoriumIndex = auditoriumRoomIndex + 1;
        if (auditoriumRoomIndex === auditoriums.length - 1) {
            nextAuditoriumIndex = 0;
        }
        return nextAuditoriumIndex;
    }, [auditoriumRoomIndex, auditoriums]);

    let formatedTimezoneName = timezoneName.split(')')[0].substring(1);

    return (
        <div
            onDragStart={preventDefaultDrag}
            className={'auditoriums-navigation-mobile ' + (timeSlot?.video ? 'has-booth-wall' : '')}
        >
            <div
                onDragStart={preventDefaultDrag}
                className="button previous"
                onClick={() => setActiveAuditoriumIndex(getPreviousAuditoriumIndex())}
            >
                <div onDragStart={preventDefaultDrag} className="button-container">
                    <div onDragStart={preventDefaultDrag} className="details">
                        <Arrow stroke={colors.primary} />
                    </div>
                </div>
            </div>
            <div onDragStart={preventDefaultDrag} className="auditorium-title-container">
                <p onDragStart={preventDefaultDrag} className="name">
                    {auditoriums[auditoriumRoomIndex].name}
                </p>
                <p>
                    <span>{formatedTimezoneName}</span>
                    <span>
                        {auditoriumRoomIndex + 1}/{auditoriums.length}{' '}
                        {translation?.programDropdown.stages || defaultTranslation?.programDropdown.stages}
                    </span>
                </p>
            </div>
            <div
                onDragStart={preventDefaultDrag}
                className="button next"
                onClick={() => setActiveAuditoriumIndex(getNextAuditoriumIndex())}
            >
                <div onDragStart={preventDefaultDrag} className="button-container">
                    <div onDragStart={preventDefaultDrag} className="details">
                        <Arrow stroke={colors.primary} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuditoriumNavigationMobile;
