import React, {Component} from 'react';
import {connect} from 'react-redux';
import {preventDefaultDrag} from "../../../../Utils/utils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

class DisplayFrequency extends Component {

    state={
        displayFrequencyOptions: [
            {name: 'first-time', value: 'first-time', label: 'First time the user enters the event'},
            {name: 'everytime', value: 'everytime', label: 'Every time the user enters the event'},
        ],
    }



    render() {
        const {displayFrequencyOptions} = this.state;
        const {displayFrequency, handleChangeDisplayFrequency, selectDropDownRef} = this.props
        const menuProps = {
            className: `color-primary`,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "left"
            },
            getContentAnchorEl: null,
            ref: selectDropDownRef,
        };
        return (
            <div onDragStart={preventDefaultDrag} className="options-container">
                <div
                    onDragStart={preventDefaultDrag}
                    className="single-option-container"
                >
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                    >
                        <InputLabel id={'display-frequency'} className={'select-label'}>Display frequency</InputLabel>
                        <Select
                            label={'Display frequency'}
                            IconComponent={props => (
                                <ExpandMoreRoundedIcon {...props} className={`material-icons ${props.className}`}/>
                            )}
                            onChange={handleChangeDisplayFrequency}
                            value={displayFrequency}
                            labelId={'display-frequency'}
                            MenuProps={menuProps}
                            className={{root:'welcome-screen-select-component'}}
                            id={'select-frequency'}
                        >
                            {displayFrequencyOptions.map((frequencyOption, index) => {
                                return (
                                    <MenuItem key={frequencyOption.label} value={frequencyOption.value}>
                                        {frequencyOption.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div onDragStart={preventDefaultDrag} className="input-description">
                    <p>Select how often do you want to display your welcome screen</p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(DisplayFrequency);
