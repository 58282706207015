import {useSelector} from 'react-redux';
import moment from 'moment/moment';
import {useEffect, useState} from 'react';

const getTimezoneDifference = (user, event) => {
    // we know that all timestamps are in GMT+0
    // so we want to calculate all the timestamps based either on user timezone or event timezone
    // if user selected a timezone, we want to return it
    if (user?.timezoneValue !== '') {
        return +user?.timezoneValue;
    }
    return event?.timezoneValue;
};

/**
 * Does something nifty.
 *
 * @param   date
 * @returns a moment date
 */
export const useCalculateDateByTimezoneDifference = (date) => {
    const user = useSelector((state) => state?.user?.data);
    const event = useSelector((state) => state?.event?.data);
    const [calculatedDate, setCalculatedDate] = useState(moment(date));

    const timezoneDifference = getTimezoneDifference(user, event);
    const timestampWithoutLocalTimezone = moment.utc(date).format('l LT');
    const calculatedTimestampBasedOnTimezoneDifference = moment(timestampWithoutLocalTimezone).add(
        timezoneDifference,
        'hours'
    );


    useEffect(() => {
        setCalculatedDate(moment(calculatedTimestampBasedOnTimezoneDifference))
    }, [user, event])

    return calculatedDate;
}
