import {preventDefaultDrag} from "Utils/utils";
import {ReactComponent as GreaterArrow} from "Images/svg/greater-arrow.svg";
import colors from "CSS/_variables.module.scss";
import Wall from "HOC/Wall/Wall";
import Networking from "../../HOC/Networking";
import React from "react";
import {useSelector} from "react-redux";
import {TabsContainer} from "./TabsContainer";
import EventInfo from "./EventInfo";
import './eventInfoTabStyles.scss';

export const LobbySideContainer = ({
                                       handleCloseSidebar,
                                       handleSeeLiveWall,
                                       handleEnterKeyWall,
                                       handleSeeVideoWall,
                                       handleSeeNetworking,
                                       handleEnterKeyNetworking,
                                       handleEnterKeyEventInfo,
                                       hasAccessToNetworking,
                                       openMatchingDialog,
                                       handleSeeInfoTab,
                                   }) => {
    const sideNavigation = useSelector((state) => state.user.sideNavigation);

    const getSideMenuTab = () => {
        if (sideNavigation.seeInfoTab) {
            return <div className={'event-info-tab'}>
                <EventInfo/>
            </div>
        }
        if (sideNavigation.seeLiveWall) {
            return <Wall isLiveWall/>
        }
        return <Networking openMatchingDialog={openMatchingDialog}/>
    }

    return <div
        className={"wall-and-networking-container " + (sideNavigation.isOpened ? "open" : "close")}
    >
        {sideNavigation.isOpened && (
            <div>
                <div
                    onDragStart={preventDefaultDrag}
                    className="close-dropdown"
                    onClick={handleCloseSidebar}
                >
                    <GreaterArrow fill={colors.whiteVariant}/>
                </div>
                <TabsContainer
                    handleSeeLiveWall={handleSeeLiveWall}
                    handleEnterKeyWall={handleEnterKeyWall}
                    handleSeeVideoWall={handleSeeVideoWall}
                    handleSeeNetworking={handleSeeNetworking}
                    handleEnterKeyNetworking={handleEnterKeyNetworking}
                    handleEnterKeyEventInfo={handleEnterKeyEventInfo}
                    hasAccessToNetworking={hasAccessToNetworking}
                    handleSeeInfoTab={handleSeeInfoTab}
                />
                <div className='side-menu-tab-content'>
                    {getSideMenuTab()}
                </div>
            </div>
        )}
    </div>;
}