import {useSelector} from "react-redux";
import {usePlatformTranslation} from "../../services/hooks";

const EventInfoTabButton = ({handleSeeInfoTab, handleEnterKeyEventInfo}) => {
    const eventInfoText = usePlatformTranslation((state) => state.sideMenu.eventInfo)
    const seeInfoTab = useSelector((state) => state.user.sideNavigation.seeInfoTab);

    return (
        <div
            tabIndex="0"
            className={
                "live-wall-tab button-tab tab " +
                (seeInfoTab ? "active " : " ") +
                'small-tab'
            }
            onClick={handleSeeInfoTab}
            onKeyDown={handleEnterKeyEventInfo}
        >
            {eventInfoText}
        </div>
    )
}

export default EventInfoTabButton;