import React from 'react';
import {ReactComponent as Info} from '../../Images/svg/information.svg';
import './InfoTooltip.scss';

const InfoTooltip = (props) => {
    const {message, position} = props
    return(
        <div className={`info-tooltip position-${position}`}>
            <div className='info-tooltip-inner-container'>
                {position === "bottom" &&
                    <div className='tooltip-arrow'>
                        <div className={`arrow arrow-${position}`} />
                    </div>
                }
                <div className={`text-container d-flex`}>
                    <div className='info-icon-container'>
                        <Info />
                    </div>
                    <p>{message}</p>
                </div>
                {position === "top" &&
                    <div className='tooltip-arrow'>
                        <div className={`arrow arrow-${position}`} />
                    </div>
                }
            </div>
        </div>
    )
}

export default InfoTooltip;
