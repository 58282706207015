import React, {Component} from 'react';
import RectanglePurple from '../../Images/pages/platform-homepage/animations/rectangle-purple.png';

class PurpleRectangleText extends Component {
    render() {
        return (
            <span
                draggable="false"
                style={{backgroundImage: `url(${RectanglePurple})`}}
                className="purple-rectangle-background"
            >
                {this.props.children}
            </span>
        );
    }
}

export default PurpleRectangleText;
