import {useEffect, useState} from "react";
import AnnouncementsDrawerButton from "./AnnouncementsDrawerButton";
import {AnnouncementsBanner} from "./AnnouncementsBanner";

const BannerWithTransition = ({
                                  isCollapsed,
                                  handleOpen,
                                  handleCollapse,
                                  hasWall,
                                  announcements,
                                  handleCallToActionButtonClicked
                              }) => {

    const [bannerVisible, setBannerVisible] = useState(!isCollapsed);
    const [drawerButtonVisible, setDrawerButtonVisible] = useState(isCollapsed)
    const [bannerReady, setBannerReady] = useState(!isCollapsed);
    const [bannerExit, setBannerExit] = useState(false);

    // used for animations
    useEffect(() => {
        if (isCollapsed) {
            exitBanner();
        } else {
            enterBanner();
        }
    }, [isCollapsed])

    useEffect(()=>{
        if(!isCollapsed) {
            setBannerReady(false);
            setTimeout(() => {
                setBannerReady(true);
            }, 1000);
        }
    }, [hasWall])

    const enterBanner = () => {
        setBannerReady(false);
        setBannerExit(false);
        setBannerVisible(true);
        setTimeout(() => {
            setDrawerButtonVisible(false);
            setBannerReady(true);
        }, 1000);
    }

    const exitBanner = () => {
        setBannerExit(true);
        setBannerReady(false);
        setDrawerButtonVisible(true);
        setTimeout(() => setBannerVisible(false), 990);
    }

    return (
        <>
            {bannerVisible && <AnnouncementsBanner announcements={announcements}
                                                   handleCollapse={handleCollapse}
                                                   handleCallToActionButtonClicked={handleCallToActionButtonClicked}
                                                   hasWall={hasWall}
                                                   ready={bannerReady}
                                                   exit={bannerExit}
            />}
            {drawerButtonVisible && <AnnouncementsDrawerButton handleOpen={handleOpen} hasWall={hasWall}/>}
        </>
    )
}

export default BannerWithTransition;