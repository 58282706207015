import React, {Component} from 'react';
import RectangleGreen from '../../Images/pages/platform-homepage/animations/rectangle-green.png';

class GreenRectangleText extends Component {
    render() {
        return (
            <span
                draggable="false"
                style={{backgroundImage: `url(${RectangleGreen})`}}
                className="green-rectangle-background"
            >
                {this.props.children}
            </span>
        );
    }
}

export default GreenRectangleText;
