import axios from '../axios-instance';

import {
    CANCEL_SINGLE_MEETING_SUCCESS,
    CANCEL_SINGLE_MEETING_REQUEST,
    CANCEL_SINGLE_MEETING_FAIL,
    ACCEPT_SINGLE_MEETING_SUCCESS,
    ACCEPT_SINGLE_MEETING_REQUEST,
    ACCEPT_SINGLE_MEETING_FAIL,
    ACCEPT_ALL_MEETINGS_FAIL,
    ACCEPT_ALL_MEETINGS_SUCCESS,
    ACCEPT_ALL_MEETINGS_REQUEST,
    ADD_TIMESLOT_TO_MY_AGENDA_REQUEST,
    ADD_TIMESLOT_TO_MY_AGENDA_SUCCESS,
    ADD_TIMESLOT_TO_MY_AGENDA_FAIL,
    GET_MY_AGENDA_REQUEST,
    GET_MY_AGENDA_SUCCESS,
    GET_MY_AGENDA_FAIL,
    GET_SESSIONS_MY_AGENDA_REQUEST,
    GET_SESSIONS_MY_AGENDA_SUCCESS,
    GET_SESSIONS_MY_AGENDA_FAIL,
    DELETE_TIMESLOT_FROM_MY_AGENDA_REQUEST,
    DELETE_TIMESLOT_FROM_MY_AGENDA_SUCCESS,
    DELETE_TIMESLOT_FROM_MY_AGENDA_FAIL,
    GET_MEETINGS_MY_AGENDA_REQUEST,
    GET_MEETINGS_MY_AGENDA_SUCCESS,
    GET_MEETINGS_MY_AGENDA_FAIL,
} from './actionTypes';

//it cancels a single meeting
export const cancelSingleMeeting = (meetingData) => async (dispatch) => {
    try {
        dispatch({type: CANCEL_SINGLE_MEETING_REQUEST});
        const {data} = await axios.post(`/users/cancel-meeting-request`, meetingData, {});
        dispatch({
            type: CANCEL_SINGLE_MEETING_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CANCEL_SINGLE_MEETING_FAIL,
            payload: error.response?.data?.message ? error.response?.data?.message : error.message,
        });
    }
};

//it accepts a single meeting
export const acceptSingleMeeting = (meetingData) => async (dispatch) => {
    try {
        dispatch({type: ACCEPT_SINGLE_MEETING_REQUEST});
        const {data} = await axios.post(`/users/accept-meeting-request`, meetingData, {});
        dispatch({
            type: ACCEPT_SINGLE_MEETING_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ACCEPT_SINGLE_MEETING_FAIL,
            payload: error.response?.data?.message ? error.response?.data?.message : error.message,
        });
    }
};

//it accepts all meetings
export const allMeetingsAccept = (meetingIds) => async (dispatch) => {
    try {
        dispatch({type: ACCEPT_ALL_MEETINGS_REQUEST});
        const {data} = await axios.post(`/users/accept-all-meeting-request`, meetingIds, {});
        dispatch({
            type: ACCEPT_ALL_MEETINGS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ACCEPT_ALL_MEETINGS_FAIL,
            payload: error.response?.data?.message ? error.response?.data?.message : error.message,
        });
    }
};

//it adds a timeslot to the agenda
export const addTimeslotToMyAgenda = (userId, timeslot) => async (dispatch) => {
    try {
        dispatch({type: ADD_TIMESLOT_TO_MY_AGENDA_REQUEST});
        const {data} = await axios.post(`/my-agenda/${userId}/timeslot`, timeslot, {});
        dispatch({
            type: ADD_TIMESLOT_TO_MY_AGENDA_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ADD_TIMESLOT_TO_MY_AGENDA_FAIL,
            payload: error.response?.data?.message ? error.response?.data?.message : error.message,
        });
    }
};

//it removes a timeslot from the agenda
export const deleteATimeslotFromMyAgenda = (userId, programId) => async (dispatch) => {
    try {
        dispatch({type: DELETE_TIMESLOT_FROM_MY_AGENDA_REQUEST});
        const {data} = await axios.delete(`/my-agenda/${userId}/timeslot/${programId}`, {});
        dispatch({
            type: DELETE_TIMESLOT_FROM_MY_AGENDA_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DELETE_TIMESLOT_FROM_MY_AGENDA_FAIL,
            payload: error.response?.data?.message ? error.response?.data?.message : error.message,
        });
    }
};

//it gets all
export const getMyAgenda = (userId, pastSlots, timeZoneValue) => async (dispatch) => {
    try {
        dispatch({type: GET_MY_AGENDA_REQUEST});
        const {data} = await axios.get(
            `/my-agenda/${userId}?meetings=true&sessions=true&pastSlots=${pastSlots}&timeZoneValue=${timeZoneValue}`,
            {}
        );
        dispatch({
            type: GET_MY_AGENDA_SUCCESS,
            payload: data?.data,
        });
    } catch (error) {
        dispatch({
            type: GET_MY_AGENDA_FAIL,
            payload: error.response?.data?.message ? error.response?.data?.message : error.message,
        });
    }
};

//it gets only sessions
export const getSessionsMyAgenda = (userId, pastSlots, timeZoneValue) => async (dispatch) => {
    try {
        dispatch({type: GET_SESSIONS_MY_AGENDA_REQUEST});
        const {data} = await axios.get(
            `/my-agenda/${userId}?meetings=false&sessions=true&pastSlots=${pastSlots}&timeZoneValue=${timeZoneValue}`,
            {}
        );
        dispatch({
            type: GET_SESSIONS_MY_AGENDA_SUCCESS,
            payload: data?.data,
        });
    } catch (error) {
        dispatch({
            type: GET_SESSIONS_MY_AGENDA_FAIL,
            payload: error.response?.data?.message ? error.response?.data?.message : error.message,
        });
    }
};

export const getMeetingsMyAgenda = (userId, pastSlots, timeZoneValue) => async (dispatch) => {
    try {
        dispatch({type: GET_MEETINGS_MY_AGENDA_REQUEST});
        const {data} = await axios.get(
            `/my-agenda/${userId}?meetings=true&sessions=false&pastSlots=${pastSlots}&timeZoneValue=${timeZoneValue}`,
            {}
        );
        dispatch({
            type: GET_MEETINGS_MY_AGENDA_SUCCESS,
            payload: data?.data,
        });
    } catch (error) {
        dispatch({
            type: GET_MEETINGS_MY_AGENDA_FAIL,
            payload: error.response?.data?.message ? error.response?.data?.message : error.message,
        });
    }
};
