import React from 'react';
import {connect} from 'react-redux';
import '../../../../CSS/chats.scss';
import {getTimeAgoString, preventDefaultDrag} from '../../../../Utils/utils';

class GroupChatListItem extends React.PureComponent {
    render() {
        const {chatData, lastMessageAt, translation, defaultTranslation, platformLanguage} = this.props;
        const chat = chatData.chat;
        const usersListString = chat.users.map((user) => user.first + ' ' + user.last).join(', ');
        const timeAgoString = getTimeAgoString(lastMessageAt, platformLanguage);
        const codedQuotationMark = '&#x27;';
        const videoCallMessage = `I${codedQuotationMark}ve set up a Video Meeting room. Please click here to join it.`;
        const renderLastMessage =
            chat.lastMessageText === videoCallMessage
                ? translation?.videoConferences.videoCallStarted ||
                  defaultTranslation?.videoConferences.videoCallStarted
                : chat.lastMessageText;
        return (
            <div
                color={chat._id === this.props.activeChatRoomId ? 'primary' : 'default'}
                className="group-chat-button"
                id={chat._id}
                onClick={this.props.handleOpenGroupChat(chat._id)}
            >
                <div onDragStart={preventDefaultDrag} className="avatar">
                    {chat.users.length ? (
                        <span>
                            {chat.users[0].first[0]}
                            {chat.users[0].last[0]}
                        </span>
                    ) : null}
                    {chatData.unread !== 0 ? (
                        <div
                            className={`online-button flex-center ${
                                chatData.unread.toString().length >= 3 ? 'fs9' : 'fs10'
                            }`}
                        >
                            <span>{chatData.unread.toString().length >= 3 ? '99+' : chatData.unread}</span>
                        </div>
                    ) : null}
                </div>
                <div onDragStart={preventDefaultDrag} className="chat-details">
                    {chat.name ? (
                        <p onDragStart={preventDefaultDrag} className="group-chat-name ellipsis">
                            {chat.name}
                        </p>
                    ) : (
                        <div>
                            {chat.users.length ? (
                                <p onDragStart={preventDefaultDrag} className="group-chat-name ellipsis">
                                    <span>{usersListString}</span>
                                </p>
                            ) : null}
                        </div>
                    )}
                    {chat.users.length ? (
                        <span onDragStart={preventDefaultDrag} draggable="false" className="members-count">
                            {' '}
                            {chat.users.length}{' '}
                            {translation?.chatsDropdown.members || defaultTranslation?.chatsDropdown.members}
                        </span>
                    ) : null}
                    <p
                        dangerouslySetInnerHTML={{
                            __html: renderLastMessage,
                        }}
                        className={`last-message ellipsis ${chatData.unread !== 0 ? 'bold' : ''}`}
                    />
                </div>
                <div>
                    <p onDragStart={preventDefaultDrag} className="time-ago">
                        {timeAgoString}
                    </p>
                </div>
                <div onDragStart={preventDefaultDrag} className="user-wrapper-separator" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeChatRoomId: state.user.topNavigation.activeChatRoomId,
        platformLanguage: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(GroupChatListItem);
