import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
} from '@material-ui/core';
import Spinner from '../SmallLayoutComponents/Spinner';
class Confirm extends React.Component {
    state = {
        agree: true,
    };

    componentDidMount() {
        const {dialogAgreeText, coOrganizerTermsAgreement} = this.props;
        if (dialogAgreeText || coOrganizerTermsAgreement) {
            this.setState({agree: false});
        }
    }

    onChangeAgree = (e) => {
        this.setState({
            agree: e.target.checked,
        });
    };

    render() {
        const {agree} = this.state;
        const {
            open,
            mobile,
            dialogAgreeText,
            isLoading,
            closeConfirm,
            classList,
            dialogTitle,
            ignoreRTL,
            dialogDescription,
            dialogCancelButtonLabel,
            handleConfirm,
            dialogConfirmButtonLabel,
            handleDiscardChanges,
            dialogDiscardButtonLabel,
            coOrganizerTermsAgreement,
            isDisabled,
            useHTML = false,
        } = this.props;

        /* READ PLEASE! 
            I added useHTML
            If you want to use HTML code in your confirm pop-up, while sending it as a prop, please make it true.
            As default it is false.

            For a better understanding,
            Check the EventSettings.js 
            Look for following function ->

            getConfirmChangeEventSettingsDialogText
        */

        const isWithDiscardButton = !!handleDiscardChanges;

        return (
            <Dialog
                open={open}
                onClose={isLoading ? null : closeConfirm}
                aria-labelledby="form-dialog-title"
                className={`${mobile ? 'dialog-mobile' : ''} ${classList ? [...classList] : ''}`}
                // todo: this HAS to be refactored. Just send an ignoreRtl prop from the parent component instead of having this if statement here.
                PaperProps={{
                    classes: {
                        root:
                            ignoreRTL ||
                            dialogTitle === 'Unsaved changes' ||
                            dialogTitle === 'Import program' ||
                            dialogTitle === 'Create new Exhibit hall' ||
                            dialogTitle === 'Change access' ||
                            dialogTitle === 'Confirm disable package' ||
                            dialogTitle === 'ENABLE CO-ORGANIZER FUNCTIONALITY' ||
                            dialogTitle === 'Change Event Date' ||
                            dialogTitle === 'Change Event Name' ||
                            dialogTitle === 'Change Event Name and Date' ||
                            dialogTitle === 'Close Login' ||
                            dialogTitle === 'Close Participant registration' ||
                            dialogTitle === 'Close Exhibitor registration' ||
                            dialogTitle === 'Close Poster/Study registration' ||
                            dialogTitle === 'Enable Live Wall moderation' ||
                            dialogTitle === 'Enable Video Wall moderation' ||
                            dialogTitle === 'Enable Booth Wall moderation' ||
                            dialogTitle === 'Update event languages' ||
                            dialogTitle === 'Remove Receptionist' ||
                            dialogTitle === 'Deactivate co-organizer' ||
                            dialogTitle === 'Remove Co-Organizer' ||
                            dialogTitle === 'Enable Enhanced Booth Statistics' ||
                            dialogTitle === 'Enhanced Booth Statistics' ||
                            dialogTitle === 'Activate co-organizer'
                                ? `reverse-rtl ${coOrganizerTermsAgreement ? 'corg-gdpr-width' : ''}`
                                : '',
                    },
                }}
            >
                {isLoading && <Spinner />}
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent className="scroll-left-container">
                    {useHTML ? (
                        <DialogContentText id="form-dialog-secondary">{dialogDescription}</DialogContentText>
                    ) : (
                        <DialogContentText
                            id="form-dialog-secondary"
                            dangerouslySetInnerHTML={{__html: dialogDescription}}
                        />
                    )}

                    {dialogAgreeText && (
                        <DialogContentText className="agree-container">
                            <Checkbox
                                checked={agree}
                                onChange={this.onChangeAgree}
                                name="reviewedGDPR"
                                color="secondary"
                            />
                            <span
                                onDragStart={(e) => e.preventDefault()}
                                draggable="false"
                                className="confirm-event-privacy-policy-text"
                            >
                                I read the description above and agree to enable Enhanced Booth Statistics
                                functionality. I understand that by doing so, I am making a decision on behalf of the
                                event organizer to share event participants' personal data with event exhibitors and
                                hereby accept all liability and risks related to such sharing.
                            </span>
                        </DialogContentText>
                    )}
                    {coOrganizerTermsAgreement && (
                        <DialogContentText className="agree-container">
                            <Checkbox
                                checked={agree}
                                onChange={this.onChangeAgree}
                                className="corg-terms-checkbox"
                                name="reviewedGDPR"
                                color="secondary"
                                disableRipple
                                disableFocusRipple={true}
                            />
                            <span
                                onDragStart={(e) => e.preventDefault()}
                                draggable="false"
                                className="confirm-event-privacy-policy-text"
                            >
                                I read the description above and agree to enable Co-organizer functionality. I
                                understand that by doing so (1) I may provide certain participants with access to event
                                management functionalities and preferences; (2) I am making a decision on behalf of the
                                event organizer to enable functionality that may allow sharing event participants'
                                personal data with users who will have co-organizer access at the determined privilege
                                level. I hereby accept, on behalf of the event organizer, all liability and risks
                                associated with such decisions and sharing. I understand that it is the event organizers
                                responsibility to manage the event and to ensure proper processing of personal data
                                through Co-organizer functionality.
                            </span>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions className={isWithDiscardButton ? 'dialog-actions-space-between' : ''}>
                    {isWithDiscardButton ? (
                        <>
                            <div>
                                <Button type="button" onClick={closeConfirm}>
                                    {dialogCancelButtonLabel ? dialogCancelButtonLabel : 'Cancel'}
                                </Button>
                            </div>
                            <div>
                                <Button type="button" onClick={handleDiscardChanges}>
                                    {dialogDiscardButtonLabel ? dialogDiscardButtonLabel : "Don't save"}
                                </Button>
                                <Button type="button" onClick={handleConfirm} disabled={!agree}>
                                    {dialogConfirmButtonLabel}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Button type="button" onClick={closeConfirm}>
                                {dialogCancelButtonLabel ? dialogCancelButtonLabel : 'Cancel'}
                            </Button>
                            {!isDisabled && (
                                <Button type="button" onClick={handleConfirm} disabled={!agree}>
                                    {dialogConfirmButtonLabel}
                                </Button>
                            )}
                        </>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

Confirm.defaultProps = {
    isDisabled: false,
};

export default Confirm;
