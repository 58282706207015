import React from 'react';
import Button from '@material-ui/core/Button';
import {preventDefaultDrag} from '../Utils/utils';

function FullOverlayEventDeleted({title, text, buttonText, handleClick}) {
    return (
        <div onDragStart={preventDefaultDrag} className="full-overlay">
            <div onDragStart={preventDefaultDrag} className="content">
                <h3>{title}</h3>
                <p>{text}</p>
                <div className="wrapper-event-deleted">
                    <Button className="m-0" variant="text" onClick={handleClick}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default FullOverlayEventDeleted;
