import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import React, {useState, useRef, useEffect, useCallback, useLayoutEffect} from 'react';
import {ReactComponent as Test} from '../../images/back.svg';
import {preventDefaultDrag} from 'Utils/utils';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as actions from 'store/actions';
import {useDispatch, useSelector} from 'react-redux';
import Email from '@material-ui/icons/Email';
import Spinner from 'SmallLayoutComponents/Spinner';
import {useHistory} from 'react-router-dom';
import classnames from 'classnames';
import axios from 'store/axios-instance';
import {isSpamEmailDomain} from '../../Utils';

const LoginForm = ({userExistButNotLoggedIn, handleIfUserExistButNotLoggedIn, handleDialogToggle}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const formRef = useRef();
    const isFirstRender = useRef(true);
    const prevPassword = useRef(null);
    const passwordRef = useRef(null);
    const emailRef = useRef(null);

    //redux
    const user = useSelector((state) => state.user);
    const eventInfo = useSelector((state) => state.event.eventInfo);
    const eventId = useSelector((state) => state.event.eventId);
    const allEventUsers = useSelector((state) => state.eventUsers?.allEventUsers);
    const {error: passwordError} = user;
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const currentLanguage = useSelector((state) => state.languages.eventLanguage);
    const langArabic = currentLanguage === 'ar';

    const primaryColor = eventInfo?.brandingData?.primaryColor;

    //states
    const [values, setValues] = useState({
        email: '',
        password: '',
    });
    const [isPrevPasswordChanged, setIsPrevPasswordChanged] = useState(false);
    const [emailDomainError, setEmailDomainError] = useState(false);
    const [spamEmailError, setSpamEmailError] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordGeneralError, setPasswordGeneralError] = useState(false);

    //handlers

    //handleChange and validation of email
    const handleChange = useCallback(
        (e) => {
            setIsPrevPasswordChanged(false);
            setPasswordGeneralError(false);
            setIsButtonDisabled(false);
            const {name, value} = e.target;

            setValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        },
        [setValues]
    );

    //if user push the space button, it will not add this space to the input
    const handleKeydown = useCallback(
        async (e) => {
            if (e.keyCode === 32) {
                e.preventDefault();
                return;
            }
            const isThereEmptyInput = Object.values(values).some((element) => element === '');
            if (!isThereEmptyInput) {
                const isFormValid = await formRef.current.isFormValid(true);
                setIsButtonDisabled(!isFormValid);
            }
        },
        [formRef, setIsButtonDisabled, userExistButNotLoggedIn]
    );

    //END - handleChange and validation of email

    //checkbox handler

    const handleIsUserExist = async (e) => {
        setIsPrevPasswordChanged(prevPassword.current !== values.password);
        setLoading(true);
        e.preventDefault();
        //it checks if the user has an account in the platform
        if (isSpamEmailDomain(values.email)) {
            setSpamEmailError(true);
        } else {
            setSpamEmailError(false);
            const {data} = await axios.post('users/user-has-account', {email: values.email});
            if (data.user?.email) {
                handleIfUserExistButNotLoggedIn(true);
                localStorage.setItem('isEmailExist', 'true');
                localStorage.setItem('tempEmail', values.email);
                setEmailDomainError(false);
            } else {
                if (eventId) {
                    const {data} = await dispatch(actions.checkIfHasRestrictedEmailAccess(eventId, values.email, true));
                    let emailIsRestricted = data.data;

                    if (emailIsRestricted) {
                        setEmailDomainError(true);
                    } else {
                        setEmailDomainError(false);

                        localStorage.setItem('isEmailExist', 'false');
                        localStorage.setItem('tempEmail', values.email);
                        let url = history.location.pathname;
                        url = url.replace('login', 'register');
                        if (url.includes('event')) {
                            history.push(url);
                        } else {
                            localStorage.setItem('tempEmail', values.email);
                            history.push('/register');
                        }
                    }
                } else {
                    localStorage.setItem('tempEmail', values.email);
                    history.push('/register');
                }
            }
        }

        setIsButtonDisabled(true);
        prevPassword.current = values.password;
        setLoading(false);
    };

    const handleLogin = async (e) => {
        setIsButtonDisabled(true);
        e.preventDefault();
        setIsPrevPasswordChanged(prevPassword.current !== values.password);

        let loginUserData = {
            email: values.email,
            password: values.password,
        };
        if (isSpamEmailDomain(values.email)) {
            setSpamEmailError(true);
        } else {
            setSpamEmailError(false);
            try {
                await dispatch(actions.loginUser(loginUserData));

                if (user?.data?._id) {
                    localStorage.removeItem('isSocialMediaLR');
                    const userExistInPlatformAndRegisteredToEvent = user?.data?.eventRoles
                        .map((eventRole) => eventRole.event._id)
                        .includes(eventId);
                    if (!userExistInPlatformAndRegisteredToEvent && eventInfo?.slug) {
                        history.push(`/event/${eventInfo?.slug}/select-role`);
                    }
                }
            } catch (error) {
                setIsButtonDisabled(true);
            }
        }

        prevPassword.current = values.password;
    };

    const handleShowPasswordToggle = () => {
        setShowPassword((prevState) => !prevState);
    };

    const onInputHandler = () => {
        setIsButtonDisabled(false);
    };

    //end handlers

    //to be able to redirect to user to correct by using back button, we save the previous path to localstorage
    const previousPath = localStorage.getItem('previousPath');

    const handleOnError = (_) => {
        setPasswordGeneralError(true);
    };

    const passwordForgotHandler = () => {
        handleDialogToggle();
        localStorage.setItem('tempEmail', values.email);
    };

    useEffect(() => {
        previousPath && localStorage.removeItem('isEmailExist');
    }, []);

    useEffect(() => {
        const tempEmail = localStorage.getItem('tempEmail');
        if (tempEmail) {
            setValues({
                ...values,
                email: tempEmail,
            });
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('previousPath', window.location.pathname);
    }, []);

    useEffect(() => (isFirstRender.current = false), []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const isSocialMediaLR = JSON.parse(localStorage.getItem('isSocialMediaLR'));
        const userExistInPlatformAndRegisteredToEvent = user?.data?.eventRoles
            .map((eventRole) => eventRole.event._id)
            .includes(eventId);

        if (token && isSocialMediaLR && eventInfo?.slug) {
            if (userExistInPlatformAndRegisteredToEvent !== undefined) {
                localStorage.removeItem('isSocialMediaLR');
                history.push(`/event/${eventInfo?.slug}`);
            } else {
                localStorage.removeItem('isSocialMediaLR');
                history.push(`/event/${eventInfo?.slug}`);
            }
        }
    }, [allEventUsers, eventId, eventInfo?.slug, history, user?.data?._id, user?.data?.eventRoles]);

    useLayoutEffect(() => {
        const tempEmail = localStorage.getItem('tempEmail');
        if (tempEmail) {
            setIsButtonDisabled(false);
        }
    }, []);

    return (
        <>
            {loading && !isFirstRender && <Spinner />}
            <ValidatorForm
                ref={formRef}
                className={classnames('login-form-fc', {'lang-ar': langArabic})}
                onSubmit={userExistButNotLoggedIn ? handleLogin : handleIsUserExist}
                onError={handleOnError}
            >
                <TextValidator
                    label={translation.newLoginRegistrations.email || defaultTranslation.newLoginRegistrations.email}
                    className={classnames('email-text-field', 'field-container', 'withEndAdornment', {
                        disabled: userExistButNotLoggedIn,
                    })}
                    onChange={handleChange}
                    onKeyDown={handleKeydown}
                    onInput={onInputHandler}
                    name="email"
                    type="email"
                    disabled={userExistButNotLoggedIn}
                    keyboardIcon={<Test classes={{root: 'dark-color'}} />}
                    value={values.email}
                    ref={emailRef}
                    validators={['required', 'isEmail']}
                    errorMessages={[
                        translation.errors.required || defaultTranslation.errors.required,
                        translation.errors.emailNotValid || defaultTranslation.errors.emailNotValid,
                    ]}
                    InputProps={{
                        endAdornment: (
                            <div onDragStart={preventDefaultDrag} className="show-pass email">
                                <Email />
                            </div>
                        ),
                    }}
                />
                {loading && (
                    <div className={`login-registration-field-login-container`}>
                        <Spinner />
                    </div>
                )}
                {!loading && userExistButNotLoggedIn && (
                    <div className={classnames({'there-error': passwordError && isPrevPasswordChanged})}>
                        <TextValidator
                            label={
                                translation.newLoginRegistrations.password ||
                                defaultTranslation.newLoginRegistrations.password
                            }
                            onChange={handleChange}
                            onKeyDown={handleKeydown}
                            onInput={onInputHandler}
                            ref={passwordRef}
                            className={classnames('password-text-field withEndAdornment', {
                                'error-helper': passwordError && isPrevPasswordChanged,
                                'red-border': passwordGeneralError,
                            })}
                            name="password"
                            type={`${showPassword ? 'text' : 'password'}`}
                            value={values.password}
                            validators={['required']}
                            errorMessages={[translation.errors.required || defaultTranslation.errors.required]}
                            InputProps={{
                                endAdornment: (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="show-pass"
                                        onClick={handleShowPasswordToggle}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </div>
                                ),
                            }}
                        />
                        {passwordError && isPrevPasswordChanged && (
                            <span className={`private-error`}>
                                {translation.newLoginRegistrations.incorrectPasswordError ||
                                    defaultTranslation.newLoginRegistrations.incorrectPasswordError}
                            </span>
                        )}
                    </div>
                )}

                <div className={`submit-and-secondary-actions`}>
                    {(emailDomainError || spamEmailError) && (
                        <h3 className={`error-pass`}>
                            {!loading && emailDomainError && spamEmailError
                                ? translation.newLoginRegistrations.spamEmailError ||
                                  defaultTranslation.newLoginRegistrations.spamEmailError
                                : emailDomainError
                                ? translation.newLoginRegistrations.emailIsNotAllowed ||
                                  defaultTranslation.newLoginRegistrations.emailIsNotAllowed
                                : translation.newLoginRegistrations.spamEmailError ||
                                  defaultTranslation.newLoginRegistrations.spamEmailError}
                        </h3>
                    )}
                    <Button
                        type="submit"
                        disabled={isButtonDisabled}
                        className={classnames('send-form-fc', {
                            disabled: isButtonDisabled,
                        })}
                        style={{
                            background: primaryColor?.length > 0 && !isButtonDisabled && primaryColor,
                        }}
                    >
                        {translation.newLoginRegistrations.continueButton ||
                            defaultTranslation.newLoginRegistrations.continueButton}
                    </Button>
                    {!loading && userExistButNotLoggedIn && (
                        <div className={`secondary-form-actions`}>
                            <Button size={'medium'} onClick={passwordForgotHandler}>
                                <span>
                                    {translation.login.forgotPasswordButton ||
                                        defaultTranslation.login.forgotPasswordButton}
                                </span>
                            </Button>
                        </div>
                    )}
                </div>
            </ValidatorForm>
        </>
    );
};
export default LoginForm;
