import React, {useEffect, useRef, useState} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {InputAdornment} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import {useDebounce} from 'use-debounce';
import {useSelector} from 'react-redux';
import IconButton from "@material-ui/core/IconButton";


const Search = ({showSearchInput, setShowSearchInput, setDebouncedSearch, setDisplayNumberOfSearchResult, setNumberOfSearchResult, numberOfSearchResult, displayNumberOfSearchResult}) => {

    const translation = useSelector(state =>  state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector(state => state.languages.translations['en']);
    const isLargeScreen = useSelector(state => state.layout.isLargeScreen);
    const isTablet = useSelector(state => state.layout.isTablet);


    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue] = useDebounce(searchValue, 500);


    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    }

    useEffect(()=>{
        setDebouncedSearch(debouncedSearchValue)
    }, [debouncedSearchValue])

    const handleToggleSearchMode = () => {
        setSearchValue("");
        setDisplayNumberOfSearchResult(false);
        setNumberOfSearchResult(null);
        setShowSearchInput(!showSearchInput);
    }

    const onSubmit = () => {

    }

    const formatSearchResultsString = () => {
        let word = 'results found';
        if (numberOfSearchResult === 1) word = 'result found';
        return word;
    }

    return (
        <div className={'search-container'}>
            <div className='search-button' onClick={handleToggleSearchMode}>
                <SearchIcon />
            </div>

            {showSearchInput &&
                <ValidatorForm onSubmit={onSubmit}>
                    <div className='search-input-container'>
                        <div className='input'>
                            <TextValidator
                                autoFocus={true}
                                placeholder={translation?.generalText.search || defaultTranslation.generalText.search}
                                size={'small'}
                                type="search "
                                fullWidth={true}
                                variant="outlined"
                                value={searchValue}
                                onChange={handleSearch}
                                name={'Search Timeslot value'}
                                color={'primary'}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        {isLargeScreen || isTablet ?
                            <Button variant={'text'}
                                    type={'button'}
                                    onClick={handleToggleSearchMode}
                                    startIcon={<CloseIcon />}
                                    size={'small'}
                            >
                                {translation?.generalText.close || defaultTranslation.generalText.close}
                            </Button> :
                            <IconButton  onClick={handleToggleSearchMode} className={'close-icon-mobile'}>
                                <CloseIcon />
                            </IconButton>
                        }
                    </div>
                </ValidatorForm>
            }

            {displayNumberOfSearchResult ? <p className='display-number-of-search-result'>{numberOfSearchResult} {formatSearchResultsString()}</p> : null}

        </div>
    );
};

export default Search;