import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import axios from '../../../../store/axios-instance';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Spinner from "../../../../SmallLayoutComponents/Spinner";

class AddFieldDialog extends React.Component {
    state = {
        label: '',
        type: 'text',
        isRequired: false,
        loading: false,
    };

    handleChange = (name) => (event) => {
        let val = event.target.value;
        if (val.charAt(0) === ' ') {
            val = val.charAt(0).trim() + val.substring(1, val.length);}
        else{
        this.setState({
            [name]: event.target.value,   
        });}
    };

    handleAddField = () => {
        this.setState({loading: true});
        const {eventId} = this.props;
        const apiDataObject = { label: this.state.label, type: this.state.type, isRequired: this.state.isRequired };
        axios({method: 'post', url: `/event/v2/${eventId}/${this.props.role}-custom-fields`, data: apiDataObject})
            .then((response) => {
                let eventRegistrationFields = response.data.data.eventRegistrationFields;
                this.props.getEventRegistrationFieldsSuccess(eventRegistrationFields);
                this.setState({
                    label: '',
                    loading: false,
                });
                this.props.closeDialog();
            })
            .catch((error) => {this.setState({loading: false});});
    };

    closeDialog = () => {
        this.props.closeDialog();
    };
 
    saveDisabled = (label) => {
        return !label.length > 0 || label.length > 200;
    }

    render() {
        const {label, loading} = this.state;

        if(loading){
            return <Spinner />;
        }
        return (
            <Dialog
                open={this.props.open}
                className='add-field-dialog'
                onClose={this.closeDialog}
                aria-labelledby="form-dialog-title"
                PaperProps={{classes: {root: 'reverse-rtl'}}}
            >
                <ValidatorForm ref="form" onSubmit={this.handleAddField}>
                    <DialogTitle id="form-dialog-title">{this.props.addFieldMessage}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="form-dialog-secondary">Add a new custom field.</DialogContentText>
                        <Grid container className="smallContainer" alignItems="center">
                            <Grid item xs={12}>
                                <TextValidator
                                    id="label"
                                    label="Title"
                                    type="text"
                                    value={this.state.label}
                                    onChange={this.handleChange('label')}
                                    variant="outlined"
                                    fullWidth={true}
                                    autoFocus={true}
                                    margin="normal"
                                    validators={['maxStringLength:200']}
                                    errorMessages={['You have reached the maximum limit of characters allowed (200 characters)']}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={this.closeDialog}>
                            Close
                        </Button>
                        <Button type="submit" disabled={this.saveDisabled(label)}>
                            Add field
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventRegistrationFieldsSuccess: (registrationFields) => dispatch(actions.getEventRegistrationFieldsSuccess(registrationFields)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFieldDialog);
