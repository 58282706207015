import {Button, Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import LiveLabel from '../../../ProgramComponents/LiveLabel';
import {ReactComponent as GoToAuditorium} from '../svgs/go-to-auditorium.svg';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {ReactComponent as Discard} from '../../Agenda/helpers/svgs/discard.svg';
import {ReactComponent as AddToMyAgenda} from '../../Agenda/helpers/svgs/add-to-my-agenda.svg';
import EventIcon from '@material-ui/icons/Event';

const SingleCalendarDialog = ({
    timeslot,
    open,
    onClose,
    addDeleteAgenda,
    isElementAddedToTimeslot,
    handleSetCalendarTimeslot,
}) => {
    //redux state
    const eventSlug = useSelector((state) => state.event.data.slug);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const history = useHistory();
    const handleOpenAuditorium = () => {
        history.push(`/event/${eventSlug}/auditorium/${timeslot.auditoriumIndex}`);
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <div className={`dialog-title-calendar-style`}>
                    <div className={`date-and-isLive-dialog`}>
                        <span>
                            {timeslot.start} - {timeslot.end}
                        </span>
                        {timeslot?.isLive && <LiveLabel />}
                    </div>
                    <div className="close-icon">
                        <Button className="only-icon-button" onClick={onClose}>
                            <ClearIcon />
                        </Button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={`single-calendar-dialog-content`}>
                    <h3 className={`dialog-title-calendar`}>{timeslot?.title}</h3>
                    <div className={`btn-cover`}>
                        <Button
                            onClick={handleOpenAuditorium}
                            variant={'contained'}
                            disabled={false}
                            startIcon={<GoToAuditorium />}
                            disableElevation={false}
                            disableRipple={false}
                            color={'primary'}
                            size={'small'}
                        >
                            {translation?.programDropdown.openAuditorium ||
                                defaultTranslation?.programDropdown.openAuditorium}
                        </Button>
                    </div>
                </div>
                {timeslot?.speakers?.length > 0 && (
                    <div className={`speakers-list-calendar-list`}>
                        {translation?.programDropdown.speakers || defaultTranslation?.programDropdown.speakers}
                        {': '}
                        <span>{timeslot?.speakers}</span>
                    </div>
                )}
                {timeslot?.description?.replace(/<[^>]*>?/gm, '').length > 0 && (
                    <p
                        className={`dialog-description-calendar`}
                        dangerouslySetInnerHTML={{
                            __html: timeslot?.description,
                        }}
                    />
                )}

                <div className={`bottom-actions`}>
                    <Button
                        variant={'outlined'}
                        disabled={false}
                        startIcon={<EventIcon />}
                        disableElevation={false}
                        disableRipple={false}
                        color={'primary'}
                        onClick={handleSetCalendarTimeslot(timeslot)}
                        size={'small'}
                    >
                        {translation?.programDropdown.addToCalendarButton ||
                            defaultTranslation?.programDropdown.addToCalendarButton}
                    </Button>
                    <Button
                        variant={'outlined'}
                        onClick={addDeleteAgenda(timeslot)}
                        disabled={false}
                        startIcon={isElementAddedToTimeslot(timeslot) ? <Discard /> : <AddToMyAgenda />}
                        disableElevation={false}
                        disableRipple={false}
                        color={'primary'}
                        size={'small'}
                    >
                        {isElementAddedToTimeslot(timeslot)
                            ? translation?.programDropdown.addedToYourAgenda ||
                              defaultTranslation?.programDropdown.addedToYourAgenda
                            : translation?.programDropdown.addToMyAgenda ||
                              defaultTranslation?.programDropdown.addToMyAgenda}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SingleCalendarDialog;
