import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import ExhibitorsEnhanced from './Components/ExhibitorsEnhanced';
import {preventDefaultDrag} from '../../../Utils/utils';

class StatisticsPartChart extends React.Component {
    render() {
        const {
            statistics,
            statisticsEnhanced,
            statisticsChartData,
            currentOnlineUsers,
            organizerDashboard,
            oldStatistics,
            statisticIndex,
            numberOfOldStatistics,
        } = this.props;
        if (statisticsEnhanced) {
            return (
                <ExhibitorsEnhanced
                    statistics={statistics}
                    statisticsEnhanced={statisticsEnhanced}
                    statisticsChartData={statisticsChartData}
                    currentOnlineUsers={currentOnlineUsers}
                    organizerDashboard={organizerDashboard}
                    oldStatistics={oldStatistics}
                    statisticIndex={statisticIndex}
                    numberOfOldStatistics={numberOfOldStatistics}
                />
            );
        } else {
            return (
                <div onDragStart={preventDefaultDrag} className="statistics-part">
                    <div onDragStart={preventDefaultDrag} className="statistics-table">
                        <ul>
                            <li className="statistics-header">
                                <span>Data type</span>
                                <span>Value</span>
                            </li>
                            {statistics.map((statistic, index) => {
                                let value = statistic.value;
                                if (statistic.name === 'uniqueVisitors') {
                                    value = statistic.value.length;
                                }
                                return (
                                    <li key={'usageStatistic' + index}>
                                        <span>{statistic.name}</span>
                                        <span onDragStart={preventDefaultDrag} draggable="false" className="counter">
                                            {value}
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    {statisticsChartData && (
                        <div onDragStart={preventDefaultDrag} className="statistics-chart">
                            <div onDragStart={preventDefaultDrag} className="chart">
                                <Doughnut
                                    data={statisticsChartData}
                                    legend={{
                                        position: 'bottom',
                                    }}
                                    options={{
                                        padding: '16',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    }
}

export default StatisticsPartChart;
