import {Step, StepLine} from './Components';
import {useSelector} from 'react-redux';

const RegistrationProgressbar = ({stepsStatuses}) => {
    // redux
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const event = useSelector((state) => state.event);
    const {
        restrictExhibitorRegistration,
        restrictParticipantRegistration,
        restrictScholarRegistration,
        companyExhibitorsType,
        scholarExhibitorsType,
    } = event?.eventInfo || {};

    // state
    const steps = [
        {
            status: stepsStatuses[0],
            number: '1',
            text:
                translation.newLoginRegistrations.platformAccount ||
                defaultTranslation.newLoginRegistrations.platformAccount,
        },
        {
            status: stepsStatuses[1],
            number: '2',
            text: translation.newLoginRegistrations.selectRole || defaultTranslation.newLoginRegistrations.selectRole,
        },
        {
            status: stepsStatuses[2],
            number: '3',
            text:
                translation.newLoginRegistrations.eventAccount || defaultTranslation.newLoginRegistrations.eventAccount,
        },
    ];

    const reDirectArr = [
        restrictParticipantRegistration,
        !(!restrictExhibitorRegistration && companyExhibitorsType),
        !(!restrictScholarRegistration && scholarExhibitorsType),
    ];
    const falseCount = reDirectArr.filter((item) => !item).length;
    const onlyOneRole = falseCount <= 1;

    return (
        <div className="progress-container">
            {steps.map((step, index) => {
                if (index === 1 && onlyOneRole) {
                    return null;
                }
                return (
                    <div key={index}>
                        <Step
                            status={step.status}
                            number={onlyOneRole && index > 0 ? 2 : step.number}
                            text={step.text}
                            onlyOneRole={onlyOneRole}
                        />
                        {index <= steps.length ? (
                            index < 2 ? (
                                <StepLine status={steps[0].status} onlyOneRole={onlyOneRole} position={'left'} />
                            ) : (
                                <StepLine status={step.status} onlyOneRole={onlyOneRole} position={'right'} />
                            )
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};

export default RegistrationProgressbar;

RegistrationProgressbar.defaultProps = {
    stepsStatuses: ['active', 'passive', 'passive'],
};
