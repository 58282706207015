import React, {Component} from 'react';
import Slider from 'react-slick';
import {ReactComponent as SliderArrow} from '../../Images/svg/slider-arrow.svg';
import Andre from '../../Images/pages/platform-homepage/Andre.jpg';
import Carla from '../../Images/pages/platform-homepage/Carla.jpg';
import Christian from '../../Images/pages/platform-homepage/Christian.jpg';
import Marie from '../../Images/pages/platform-homepage/Marie.jpg';
import Svebak from '../../Images/pages/platform-homepage/Svebak.jpg';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as GreenQuoteIcon} from '../../Images/svg/quote-icon.svg';
import {preventDefaultDrag} from '../../Utils/utils';

class OnventTestimonialsSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slider: null,
            slides: [
                {
                    avatar: Christian,
                    text:
                        'This was very, very good, and we will definitely continue to participate on this kind of arrangements… It’s is without a doubt easier to meet up at an online fair.',
                    clientName: 'Christian Scheen',
                    clientPosition: 'Head of Recruitment, attract and employer branding at Skanska Norge',
                },
                {
                    avatar: Andre,
                    text:
                        'This is a new and exciting concept for us to participate in. This is an excellent way of meeting lots of people that can potentially be new employees, and I also think this is good marketing for our company.',
                    clientName: 'Andre Stene-Larsen',
                    clientPosition: 'Founder & CEO DotNet Internals',
                },
                {
                    avatar: Svebak,
                    text:
                        'As an exhibitor you get to meet highly skilled and highly motivated candidates with an international background. In addition a large percentage of the candidates we attracted were professionals which is a huge benefit for us.',
                    clientName: 'Håkon Svebak',
                    clientPosition: 'Employer Branding Manager DNV GL',
                },
                {
                    avatar: Carla,
                    text:
                        'I really like the concept of the virtual career fair. We saved time but still managed to connect with a lot of people.',
                    clientName: 'Carla Carsenzuola',
                    clientPosition: 'Global Recruitment Manager at Opera Software',
                },
                {
                    avatar: Marie,
                    text: '…totally brilliant. Well structured, time saving and innovative.',
                    clientName: 'Solveig Marie Bjørnstad ',
                    clientPosition: 'Markeds & kommunikasjonsrådgiver, Finansforbundet ',
                },
            ],
        };
    }

    goToPreviousSlide = () => {
        this.slider.slickPrev();
    };

    goToNextSlide = () => {
        this.slider.slickNext();
    };

    render() {
        let sliderSettings = {
            className: 'navigation-slider',
            dots: true,
            arrows: false,
            autoplay: false,
            draggable: true,
            pauseOnHover: false,
            swipe: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect: true,
            swipeToSlide: true,
        };

        const {slides} = this.state;

        return (
            <div onDragStart={preventDefaultDrag} className="onvent-testimonials-slider">
                <div onDragStart={preventDefaultDrag} className="onvent-testimonials-slider-wrapper">
                    <div onDragStart={preventDefaultDrag} className="arrow previous" onClick={this.goToPreviousSlide}>
                        <SliderArrow />
                    </div>
                    <div onDragStart={preventDefaultDrag} className="slider">
                        <Slider {...sliderSettings} ref={(slider) => (this.slider = slider)}>
                            {slides.map((slide) => {
                                return (
                                    <div onDragStart={preventDefaultDrag} key={slide.clientName}>
                                        <div onDragStart={preventDefaultDrag} className="testimonial-wrapper">
                                            <div onDragStart={preventDefaultDrag} className="single-testimonial">
                                                <div onDragStart={preventDefaultDrag} className="testimonial-container">
                                                    <div>
                                                        <GreenQuoteIcon fill={colors.primary} />
                                                    </div>
                                                    <p>{slide.text}</p>
                                                    <img
                                                        draggable="false"
                                                        className="client-avatar"
                                                        src={slide.avatar}
                                                        alt=""
                                                    />
                                                </div>
                                                <div onDragStart={preventDefaultDrag} className="client-details">
                                                    <img
                                                        draggable="false"
                                                        className="client-avatar"
                                                        src={slide.avatar}
                                                        alt=""
                                                    />
                                                    <p onDragStart={preventDefaultDrag} className="client-name">
                                                        {slide.clientName}
                                                    </p>
                                                    <p onDragStart={preventDefaultDrag} className="client-position">
                                                        {slide.clientPosition}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="arrow next" onClick={this.goToNextSlide}>
                        <SliderArrow />
                    </div>
                </div>
            </div>
        );
    }
}

export default OnventTestimonialsSlider;
