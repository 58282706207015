import produce from 'immer';
import {
    GET_EVENT_SPEAKERS_REQUEST,
    GET_EVENT_SPEAKERS_SUCCESS,
    GET_EVENT_SPEAKERS_FAIL,
    ADD_EVENT_SPEAKER_REQUEST,
    ADD_EVENT_SPEAKER_SUCCESS,
    ADD_EVENT_SPEAKER_FAIL,
    DELETE_EVENT_SPEAKER_REQUEST,
    DELETE_EVENT_SPEAKER_SUCCESS,
    DELETE_EVENT_SPEAKER_FAIL,
    UPLOAD_EVENT_SPEAKER_IMAGE_REQUEST,
    UPLOAD_EVENT_SPEAKER_IMAGE_SUCCESS,
    UPLOAD_EVENT_SPEAKER_IMAGE_FAIL,
    DELETE_EVENT_SPEAKER_IMAGE_REQUEST,
    DELETE_EVENT_SPEAKER_IMAGE_SUCCESS,
    DELETE_EVENT_SPEAKER_IMAGE_FAIL,
    DELETE_EVENT_SPEAKER_IMAGE_RESET,
    UPDATE_EVENT_SPEAKER_REQUEST,
    UPDATE_EVENT_SPEAKER_SUCCESS,
    UPDATE_EVENT_SPEAKER_FAIL,
} from '../actions/actionTypes';

const initialState = {
    eventSpeakers: {},
    newSpeakerImg: {},
    addedSpeaker: null,
    speakerUpdated: null,
    deleteSuccess: false,
    speakerImageSuccess: false,
    successSpeakerUpdate: false,
    data: null,
    loading: false,
    error: false,
    successSpeakerStatusUpdate: false,
};

export const evenSpeakertListReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_EVENT_SPEAKERS_REQUEST:
                draft.loading = true;
                break;

            case GET_EVENT_SPEAKERS_SUCCESS:
                draft.loading = false;
                draft.eventSpeakers = action.payload;
                break;

            case GET_EVENT_SPEAKERS_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            //add new speaker
            case ADD_EVENT_SPEAKER_REQUEST:
                draft.loading = true;
                break;

            case ADD_EVENT_SPEAKER_SUCCESS:
                draft.loading = false;
                draft.addedSpeaker = action.payload.data;
                draft.speakerUpdated = null;
                draft.eventSpeakers.data.push(action.payload.data);
                break;

            case ADD_EVENT_SPEAKER_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            // DELETE SPEAKER
            case DELETE_EVENT_SPEAKER_REQUEST:
                draft.loading = true;
                break;

            case DELETE_EVENT_SPEAKER_SUCCESS:
                draft.loading = false;
                draft.deleteSuccess = true;
                draft.eventSpeakers.data = draft.eventSpeakers.data.filter((speaker) => speaker._id !== action.payload);
                break;

            case DELETE_EVENT_SPEAKER_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            //UPLOAD IMAGE
            case UPLOAD_EVENT_SPEAKER_IMAGE_REQUEST:
                draft.loading = true;
                break;

            case UPLOAD_EVENT_SPEAKER_IMAGE_SUCCESS:
                const updatedSpeakerIndex = draft.eventSpeakers.data.findIndex(
                    (speaker) => speaker._id === action.speaker
                );
                draft.eventSpeakers.data[updatedSpeakerIndex].image = action.payload;
                draft.loading = false;
                draft.speakerImageSuccess = true;
                break;

            case UPLOAD_EVENT_SPEAKER_IMAGE_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            //DELETE SPEAKER IMAGE
            case DELETE_EVENT_SPEAKER_IMAGE_REQUEST:
                draft.loading = true;
                break;

            case DELETE_EVENT_SPEAKER_IMAGE_SUCCESS:
                const deletedSpeakerIndex = draft.eventSpeakers.data.findIndex(
                    (speaker) => speaker._id === action.speaker
                );
                draft.eventSpeakers.data[deletedSpeakerIndex].image = '';
                draft.loading = false;
                draft.speakerImageSuccess = false;
                break;

            case DELETE_EVENT_SPEAKER_IMAGE_RESET:
                draft.loading = false;
                break;

            case DELETE_EVENT_SPEAKER_IMAGE_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            //UPDATE SPEAKER
            case UPDATE_EVENT_SPEAKER_REQUEST:
                draft.loading = true;
                break;

            case UPDATE_EVENT_SPEAKER_SUCCESS:
                const updatedUserState = draft.eventSpeakers?.data?.map((speaker) => {
                    return speaker._id === action.payload?.data?._id ? action.payload?.data : speaker;
                });
                draft.loading = false;
                draft.successSpeakerUpdate = action.payload.success;
                draft.speakerUpdated = action.payload.data;
                draft.addedSpeaker = null;
                draft.eventSpeakers.data = updatedUserState;
                break;

            case UPDATE_EVENT_SPEAKER_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            default:
                break;
        }
    });
