import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import '../../../CSS/organizerDashboard.scss';
import colors from '../../../CSS/_variables.module.scss';
import Confirm from '../../../Dialogs/Confirm';
import DragAndDrop from '../../../SmallLayoutComponents/DragAndDrop/DragAndDrop';
import axios from '../../../store/axios-instance';
import {useMimeTypes} from '../../../Utils/common-mime-types';
import {preventDefaultDrag, fireClickEvent} from '../../../Utils/utils';
import {CircularProgress, Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import SnackbarGlobal from '../../../SmallLayoutComponents/Snackbars/SnackbarGlobal';

export const LobbyUploadProgram = () => {
    const acceptFileTypes = useMimeTypes(['jpeg', 'jpg', 'png', 'pdf']);
    const eventData = useSelector((state) => state.event.data);
    const eventBranding = useSelector((state) => state.event.branding.data);
    const [fileName, setFileName] = useState(eventData.brandingData.programFile);
    const [isSaved, setIsSaved] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [file, setFile] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [errors, setErrors] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState('Successfully uploaded event program.');
    const [openConfirmUnsavedChanges, setOpenConfirmUnsavedChanges] = useState(false);
    const [navigationElement, setNavigationElement] = useState(null);
    const [loading, setLoading] = useState(false);
    const wrapperRef = useRef();
    const dragAndDropRef = useRef();

    const onDropAccepted = (acceptedFilesArr) => {
        const file = acceptedFilesArr[0];
        setFile(file);
        setButtonDisabled(false);
        setFileName(file.name);
        setErrors([]);
        setIsSaved(false);
    };

    const saveFile = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        const {data: response} = await axios({
            method: 'post',
            url: `/event/${eventData._id}/upload-program`,
            data: formData,
            config,
        }).catch(() => setSnackbarMessage('Something went wrong!'));
        setSnackbarMessage(response.message);
        setFileName(response.programFile);
        setShowSnackbar(true);
        setButtonDisabled(true);
        setIsSaved(true);
        setIsDeleted(false);
        setOpenConfirmUnsavedChanges(false);
        setLoading(false);

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    const handleDiscardChanges = () => {
        setOpenConfirmUnsavedChanges(false);
        setButtonDisabled(true);
        setFileName(isDeleted ? null : eventData.brandingData.programFile);
        setFile('');
        setErrors([]);
        setIsSaved(true);

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    const onDropRejected = (rejectedFilesArr) => {
        const file = rejectedFilesArr[0];
        const errors = file.errors;
        setErrors(errors);
    };

    const removeDocument = async () => {
        // send api request only if file was already saved
        if (eventBranding.programFile) {
            const {data: response} = await axios
                .delete(`/event/${eventData._id}/delete-program`)
                .catch(() => setSnackbarMessage('Something went wrong!'));

            setSnackbarMessage(response.message);
            setShowSnackbar(true);
        }
        setButtonDisabled(true);
        setFileName('');
        setFile('');
        setErrors([]);
        setIsSaved(true);
        setIsDeleted(true);
    };

    const getNewNavigationElement = (e) => {
        const isEventTargetNavigationELement = e.path?.find((pathElem) =>
            pathElem.getAttribute?.('data-is-navigation')
        );

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    const handleClickOutside = (e) => {
        if (fileName !== '' && wrapperRef && !wrapperRef.current.contains(e.target)) {
            setOpenConfirmUnsavedChanges(!isSaved);
            setNavigationElement(getNewNavigationElement(e));
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaved]);

    function uploadedFileValidator(file) {
        const maxSize = 20000000;
        if (file.size > maxSize) {
            return {
                code: 'file-too-large',
                message: `File too large. 20MB max file size.`,
            };
        }

        const extension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

        if (
            !acceptFileTypes.includes(file.type) ||
            (extension !== '.jpeg' && extension !== '.jpg' && extension !== '.png' && extension !== '.pdf')
        ) {
            // setFileName('');
            return {
                code: 'file-type-not-supported',
                message: `File type not supported. Please use one of the following: jpg, jpeg, png, pdf`,
            };
        }

        return null;
    }

    const FileName = () => {
        return (
            <div className="uploaded-file flex-between drag-and-drop-container">
                <a href={`${eventBranding.filesUrl}/${fileName}`} target="_blank" rel="noreferrer">
                    <Tooltip arrow classes={{tooltip: 'ignore-rtl'}} title={fileName}>
                        <p className="d-flex">
                            Uploaded: <span className="uploaded-file-name ellipsis">{fileName}</span>
                        </p>
                    </Tooltip>
                </a>
                <IconButton onClick={removeDocument} color="primary">
                    <DeleteOutlineIcon />
                </IconButton>
            </div>
        );
    };

    const fileUploadReady = () => {
        dragAndDropRef && dragAndDropRef.current.click();
    };

    return (
        <div ref={wrapperRef}>
            <h4 className="advanced-options-title">PROGRAM</h4>
            <div className="advanced-options-container upload-program-container">
                <p className="inner-options-title padding-10">Upload program document</p>
                <div className="options-container align-items-flex-start">
                    <div className="single-option-container drag-and-drop-container">
                        {loading ? (
                            <div className="d-flex align-items-center justify-content-center">
                                <CircularProgress color="primary" />
                            </div>
                        ) : (
                            <DragAndDrop
                                onDropAccepted={onDropAccepted}
                                accept={acceptFileTypes}
                                onDropRejected={onDropRejected}
                                maxFiles={1}
                                validator={uploadedFileValidator}
                                inputRef={dragAndDropRef}
                            />
                        )}
                    </div>
                    <div className="input-description">
                        <p>
                            All users will be able to download the file from the top menu - Program.
                            <br />
                            Supported formats: .PDF, .JPG, .JPEG or .PNG files, size limit: 20MB.
                        </p>
                        {errors
                            .filter((err) => err.code !== 'file-invalid-type')
                            .map((err, i) => (
                                <p className="error-message" key={i}>
                                    {err.message}
                                </p>
                            ))}
                    </div>
                </div>
                <div className="single-option-container drag-and-drop-container program-upload-file-button-container">
                    <div onDragStart={preventDefaultDrag} className="or-wrapper">
                        <span>OR</span>
                    </div>
                    <Button
                        onClick={fileUploadReady}
                        variant="contained"
                        color={'primary'}
                        startIcon={<CloudUploadIcon fill={colors.white} />}
                        disableElevation
                        disabled={loading}
                    >
                        Upload File
                    </Button>
                </div>
                {fileName && <FileName />}

                <div className="action-container d-flex upload-program-actions">
                    <Button
                        type="submit"
                        disabled={buttonDisabled || loading}
                        onClick={saveFile}
                        startIcon={<SaveOutlinedIcon />}
                        variant="contained"
                        color={'secondary'}
                        disableElevation
                    >
                        Save
                    </Button>
                </div>
            </div>

            <SnackbarGlobal
                message={snackbarMessage}
                snackbarOpen={showSnackbar}
                handleCloseSnackbar={() => setShowSnackbar(false)}
            />

            {openConfirmUnsavedChanges && (
                <Confirm
                    open={openConfirmUnsavedChanges}
                    closeConfirm={() => setOpenConfirmUnsavedChanges(false)}
                    dialogTitle={'Unsaved changes'}
                    dialogDescription={'You have unsaved changes. Do you want to save them?'}
                    dialogConfirmButtonLabel={'Save'}
                    dialogCancelButtonLabel={'Cancel'}
                    handleConfirm={saveFile}
                    handleDiscardChanges={handleDiscardChanges}
                />
            )}
        </div>
    );
};
