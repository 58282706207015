import {preventDefaultDrag} from 'Utils/utils';
import {Checkbox, Tooltip} from '@material-ui/core';
import {ReactComponent as Online} from '../../Event/ExhibitorVote/Helpers/svg/online-count-icon.svg';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {usePlatformTranslation} from 'services/hooks';
import {doesOrganizerHasParticipantProfile} from 'Utils/utils';
import Spinner from 'SmallLayoutComponents/Spinner';
import './SinglePostersStyles.scss';

const SinglePoster = ({
    exhibitor,
    showfloorId,
    isActive,
    translation,
    defaultTranslation,
    handleSetActiveExhibitor,
    renderAuthors,
    isVotingEnabled,
    votingPrivalages,
    votingData,
    handleChangeVote,
    voteLoading,
    exhibitorInProgress,
    singleExhibitorsVoteCount,
}) => {
    //redux translations
    const voteText = usePlatformTranslation((state) => state?.voting?.voteText);
    const votedText = usePlatformTranslation((state) => state?.voting?.votedText);
    const checkTheBoxToCastYourVote = usePlatformTranslation((state) => state?.voting?.checkTheBoxToCastYourVote);
    const toVotePleaseEnableParticipantProfile = usePlatformTranslation(
        (state) => state?.voting?.toVotePleaseEnableParticipantProfile
    );

    const [isVoted, setIsVoted] = useState(false);

    const userEventProfile = useSelector((state) => state.userEventProfile?.data);

    const getShowfloorVoteData = () => {
        let isUserVoted = votingData?.userVotes?.find((exhibitorVote) => exhibitorVote === exhibitor?._id);
        setIsVoted(!!isUserVoted);
    };

    //get the number of votes
    useEffect(() => {
        getShowfloorVoteData();
    }, [showfloorId, exhibitor?._id, votingData]);

    return (
        <div className={`single-poster ${isActive ? 'active' : ''}`} key={exhibitor._id}>
            <div
                onDragStart={preventDefaultDrag}
                onClick={handleSetActiveExhibitor(exhibitor._id)}
                className="poster-container"
            >
                <p onDragStart={preventDefaultDrag} className="poster-title">
                    {exhibitor.booth.studyTitle
                        ? exhibitor.booth.studyTitle
                        : translation?.showfloor.titleNotAvailable || defaultTranslation?.showfloor.titleNotAvailable}
                </p>
                <p onDragStart={preventDefaultDrag} className="poster-authors">
                    {renderAuthors(exhibitor)}
                </p>
                <p onDragStart={preventDefaultDrag} className="poster-highlights">
                    {exhibitor.booth.highlights
                        ? exhibitor.booth.highlights
                        : translation?.showfloor.abstractNotAvailable ||
                          defaultTranslation?.showfloor.abstractNotAvailable}
                </p>
            </div>

            <div className={`vote-actions-poster desktop`}>
                {isVotingEnabled ? (
                    <>
                        {doesOrganizerHasParticipantProfile(userEventProfile) ? (
                            isVoted ? (
                                <div className={`vote-action`}>
                                    {voteLoading && exhibitorInProgress === exhibitor?._id && (
                                        <div className={`spinner-container-vote vote-banner-top poster-support`}>
                                            <Spinner />
                                        </div>
                                    )}
                                    <Checkbox checked={isVoted ?? ''} onClick={handleChangeVote(exhibitor?._id)} />
                                    <span
                                        className={`vote-text text-uppercase cursor-pointer`}
                                        onClick={handleChangeVote(exhibitor?._id)}
                                    >
                                        {votedText}
                                    </span>
                                </div>
                            ) : (
                                <Tooltip title={checkTheBoxToCastYourVote} arrow placement="top">
                                    <div className={`vote-action`}>
                                        {voteLoading && exhibitorInProgress === exhibitor?._id && (
                                            <div className={`spinner-container-vote vote-banner-top poster-support`}>
                                                <Spinner />
                                            </div>
                                        )}
                                        <Checkbox checked={isVoted ?? ''} onClick={handleChangeVote(exhibitor?._id)} />
                                        <span
                                            className={`vote-text text-uppercase cursor-pointer`}
                                            onClick={handleChangeVote(exhibitor?._id)}
                                        >
                                            {voteText}
                                        </span>
                                    </div>
                                </Tooltip>
                            )
                        ) : (
                            <Tooltip title={toVotePleaseEnableParticipantProfile} arrow placement="top">
                                <div className={`vote-action disabled`}>
                                    {voteLoading && exhibitorInProgress === exhibitor?._id && (
                                        <div className={`spinner-container-vote vote-banner-top poster-support`}>
                                            <Spinner />
                                        </div>
                                    )}
                                    <Checkbox
                                        checked={isVoted ?? ''}
                                        disabled
                                        onClick={handleChangeVote(exhibitor?._id)}
                                    />
                                    <span className={`vote-text text-uppercase`}>{voteText}</span>
                                </div>
                            </Tooltip>
                        )}

                        <span className={`vote-number`}>
                            {votingPrivalages && singleExhibitorsVoteCount && (
                                <>
                                    <Online className={`primary-online`} />
                                    {(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id]) || 0}
                                </>
                            )}
                        </span>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default SinglePoster;
