import React from 'react';
import ReactExport from 'react-export-excel';
import {connect} from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import axios from '../../../store/axios-instance';
import StatisticsPartChart from './StatisticsPartChart';
import colors from '../../../CSS/_variables.module.scss';
import DownloadPosts from '../../../HOC/Wall/DownloadPosts';
import {cleanProhibitedCharactersInFileName, preventDefaultDrag} from '../../../Utils/utils';
import {Button, Tooltip} from '@material-ui/core';
import {NotificationManager} from 'react-notifications';
import * as actions from '../../../store/actions';
import {withRouter} from 'react-router-dom';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Exhibitors extends React.Component {
    componentDidMount() {
        this.getStatistics();
    }

    state = {
        loadingStatistics: false,
        errorStatistics: '',
        totalBoothStatistics: [],
        reportBoothWallRawData: null,
        oldStatistics: null,
    };

    getStatistics = () => {
        this.getWallsStatistics();
    };

    refreshStatistics = () => {
        this.setState(
            {
                totalBoothStatistics: [],
                boothWallsStatistics: [],
                boothUsageStatistics: [],
                reportBoothWallRawData: null,
                reportBoothUsageRawData: null,
            },
            this.getStatistics
        );
    };

    getWallsStatistics = () => {
        const {eventId, eventSlug} = this.props;
        this.setState({loadingStatistics: true});
        axios({method: 'get', url: `/event/${eventId}/fetch-exhibitors-report`})
            .then((response) => {
                let boothWallsReports = response.data.report;
                let boothWallsStatistics = [];
                let oldStatistics = [];
                boothWallsReports.forEach((wallReport) => {
                    let boothStat = {};
                    boothStat.wallId = wallReport.wallId;
                    boothStat.company = wallReport.company;
                    boothStat.exhibitorId = wallReport.exhibitorId;
                    let statistics = [];

                    // users
                    boothStat['allUsers'] = wallReport.allUsers;
                    statistics.push({
                        name: 'Unique Visitors',
                        label: 'uniqueVisitors',
                        value: wallReport.allUsers.users.length,
                    });
                    statistics.push({
                        name: 'Total Number Of Visits',
                        label: 'totalVisits',
                        value: wallReport.allUsers.totalVisits,
                    });
                    /*
                    statistics.push({
                        name: 'Total dwell time',
                        label: 'totalDwellTime',
                        value: getFormatedTimeFromSecondsToHMS(wallReport.allUsers.totalDwellTime),
                    });
                     */

                    // documents
                    statistics.push({
                        name: 'Total Document Clicks',
                        label: 'documentClicks',
                        value: wallReport.allDocuments.clicks,
                    });
                    boothStat['allDocuments'] = wallReport.allDocuments;
                    for (let i = 0; i < wallReport.allDocuments.documents; i++) {
                        let propertyName = `documentXX${i}`;
                        statistics.push({
                            name: `~ ${wallReport[propertyName].documentName}`,
                            label: propertyName,
                            value: wallReport[propertyName].clicks,
                        });
                        boothStat[propertyName] = wallReport[propertyName];
                    }

                    // links
                    statistics.push({
                        name: 'Total Link Clicks',
                        label: 'linkClicks',
                        value: wallReport.allLinks.clicks,
                    });
                    boothStat['allLinks'] = wallReport.allLinks;
                    for (let i = 0; i < wallReport.allLinks.links; i++) {
                        let propertyName = `linkXX${i}`;
                        statistics.push({
                            name: `~ ${wallReport[propertyName].linkName}`,
                            label: propertyName,
                            value: wallReport[propertyName].clicks,
                        });
                        boothStat[propertyName] = wallReport[propertyName];
                    }

                    // representatives
                    statistics.push({
                        name: 'Total Representatives Clicks',
                        label: 'representativeClicks',
                        value: wallReport.allRepresentatives.clicks,
                    });
                    boothStat['allRepresentatives'] = wallReport.allRepresentatives;
                    for (let i = 0; i < wallReport.allRepresentatives.representatives; i++) {
                        let propertyName = `representativeXX${i}`;
                        statistics.push({
                            name: `~ ${wallReport[propertyName].representativeName}`,
                            label: propertyName,
                            value: wallReport[propertyName].clicks,
                        });
                        boothStat[propertyName] = wallReport[propertyName];
                    }

                    // video users
                    boothStat['allVideoUsers'] = wallReport.allVideoUsers;
                    statistics.push({
                        name: 'Total Video Views',
                        label: 'uniqueVideoVisitors',
                        value: wallReport.allVideoUsers.totalVideoVisits,
                    });
                    /*
                    statistics.push({
                        name: 'Total Video dwell time',
                        label: 'totalVideoDwellTime',
                        value: getFormatedTimeFromSecondsToHMS(wallReport.allVideoUsers.totalVideoDwellTime),
                    });
                     */

                    // wall
                    statistics.push({
                        name: 'Total Posts Count',
                        label: 'totalPostsCount',
                        value: wallReport.totalPostsCount,
                    });
                    statistics.push({
                        name: 'Total Likes Count',
                        label: 'totalLikesCount',
                        value: wallReport.totalLikesCount,
                    });
                    statistics.push({
                        name: 'Total Comments Count',
                        label: 'totalCommentsCount',
                        value: wallReport.totalCommentsCount,
                    });

                    boothStat.statistics = statistics;
                    boothWallsStatistics.push(boothStat);

                    let oldBoothStatistics = [];
                    let oldBoothStat = {};
                    oldBoothStat.wallId = wallReport.wallId;
                    oldBoothStat.company = wallReport.company;
                    oldBoothStat.exhibitorId = wallReport.exhibitorId;

                    oldBoothStatistics.push({
                        name: 'Attendants All Time',
                        label: 'oldTotalVisits',
                        value: wallReport.oldVisitors,
                    });
                    oldBoothStatistics.push({
                        name: 'Total Document Clicks',
                        label: 'oldDocumentClicks',
                        value: wallReport.oldDocumentClicks,
                    });
                    oldBoothStatistics.push({
                        name: 'Total Link Clicks',
                        label: 'oldLinkClicks',
                        value: wallReport.oldLinkClicks,
                    });
                    oldBoothStatistics.push({
                        name: 'Total Video Views',
                        label: 'oldUniqueVideoVisitors',
                        value: wallReport.oldVideoViews,
                    });
                    oldBoothStat.statistics = oldBoothStatistics;
                    oldStatistics.push(oldBoothStat);

                    // // old statistics
                    // oldStatistics.push({
                    //     name: 'Attendants all time',
                    //     label: 'oldTotalVisits',
                    //     value: wallReport.oldVisitors,
                    // });
                    // oldStatistics.push({
                    //     name: 'Total Document clicks',
                    //     label: 'oldDocumentClicks',
                    //     value: wallReport.oldDocumentClicks,
                    // });
                    // oldStatistics.push({
                    //     name: 'Total Link clicks',
                    //     label: 'oldLinkClicks',
                    //     value: wallReport.oldLinkClicks,
                    // });
                    // oldStatistics.push({
                    //     name: 'Total Video views',
                    //     label: 'oldUniqueVideoVisitors',
                    //     value: wallReport.oldVideoViews,
                    // });
                });

                this.setState({
                    loadingStatistics: false,
                    errorStatistics: '',
                    totalBoothStatistics: boothWallsStatistics,
                    reportBoothWallRawData: boothWallsReports,
                    oldStatistics: oldStatistics,
                });
            })
            .catch((error) => {
                this.setState({loadingStatistics: false, errorStatistics: 'Unable to get statistics'});
                if (error.response.data.error === 'Statistics are disabled') {
                    NotificationManager.info('This page is currently unavailable');
                    // this.props.history.push(`/event/${eventSlug}/dashboard/settings`);
                    this.props.reloadEventData(eventId);
                }
            });
    };

    getBoothStatisticsChartData = (index) => {
        const {reportBoothWallRawData} = this.state;
        if (!reportBoothWallRawData) {
            return {};
        }

        const currentBoothWallData = reportBoothWallRawData[index];

        const data = {
            labels: ['Posts', 'Likes', 'Comments', 'Top post likes', 'Top post comments'],
            datasets: [
                {
                    data: [
                        currentBoothWallData.totalPostsCount,
                        currentBoothWallData.totalLikesCount,
                        currentBoothWallData.totalCommentsCount,
                        currentBoothWallData.topPostLikesCount,
                        currentBoothWallData.topPostCommentsCount,
                    ],
                    backgroundColor: [colors.lightGrey, colors.secondary, colors.grey, '#ccc', '#ddd'],
                    hoverBackgroundColor: [
                        colors.primary,
                        colors.primary,
                        colors.primary,
                        colors.primary,
                        colors.primary,
                    ],
                },
            ],
        };
        return data;
    };

    checkOldStatisticsData = (index) => {
        const {oldStatistics} = this.state;
        if (oldStatistics && oldStatistics?.length > 0) {
            return oldStatistics?.[index]?.statistics
                ?.map((statistic) => statistic.value)
                .reduce((a, c) => {
                    return a + c;
                });
        } else {
            return 0;
        }
    };

    render() {
        const {name} = this.props.event;
        const {totalBoothStatistics, oldStatistics} = this.state;

        const numbera = 12;

        return (
            <div>
                {this.state.loadingStatistics && <Spinner />}
                <h4 className="advanced-options-title">
                    TOTALS
                    <div onDragStart={preventDefaultDrag} className="d-flex">
                        <ExcelFile
                            filename={`${name} - Exhibitor Statistics`}
                            element={
                                <Button onDragStart={preventDefaultDrag} startIcon={<GetAppIcon />}>
                                    DOWNLOAD
                                </Button>
                            }
                        >
                            {totalBoothStatistics.map((boothUsageStatistic, index) => {
                                let boothUsageStat = [...boothUsageStatistic.statistics];
                                let valueName = boothUsageStatistic?.company?.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '');
                                const slotName = {
                                    name: 'Exhibitor',
                                    value: valueName,
                                };
                                boothUsageStat.unshift(slotName);

                                let sheetName;
                                boothUsageStatistic?.company?.length > 26
                                    ? (sheetName = slotName.value.substring(0, 24).concat('...'))
                                    : (sheetName = slotName.value);

                                sheetName = cleanProhibitedCharactersInFileName(sheetName);

                                return (
                                    <ExcelSheet
                                        key={boothUsageStat[0].value}
                                        data={boothUsageStat}
                                        name={`E${index + 1} ${sheetName}`}
                                    >
                                        <ExcelColumn label="Statistic Name" value="name" />
                                        <ExcelColumn label="Value" value="value" />
                                    </ExcelSheet>
                                );
                            })}
                        </ExcelFile>
                        <Button
                            onDragStart={preventDefaultDrag}
                            onClick={this.refreshStatistics}
                            startIcon={<RefreshIcon />}
                        >
                            REFRESH
                        </Button>
                    </div>
                </h4>
                {totalBoothStatistics.length === 0 && (
                    <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                        <p onDragStart={preventDefaultDrag} id="form-dialog-secondary">
                            No exhibitors statistics found
                        </p>
                    </div>
                )}
                {totalBoothStatistics.map((boothStatistics, index) => {
                    let numberOfOldStatistics = this.checkOldStatisticsData(index);
                    let exhibitorName = boothStatistics?.company?.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '');
                    return (
                        <div key={`${index}exhibitorStatistic`}>
                            <div onDragStart={preventDefaultDrag} className="statistics">
                                <p onDragStart={preventDefaultDrag} className="statistics-title">
                                    <Tooltip
                                        arrow
                                        classes={{tooltip: 'ignore-rtl'}}
                                        tabIndex="0"
                                        id="live-stream"
                                        placement="bottom"
                                        title={exhibitorName}
                                    >
                                        <span className="ellipsis">{exhibitorName}</span>
                                    </Tooltip>

                                    {boothStatistics?.wallId && <DownloadPosts eventWallId={boothStatistics?.wallId} />}
                                </p>
                                <StatisticsPartChart
                                    statistics={boothStatistics.statistics}
                                    statisticsEnhanced={boothStatistics}
                                    statisticsChartData={this.getBoothStatisticsChartData(index)}
                                    organizerDashboard={true}
                                    oldStatistics={oldStatistics}
                                    statisticIndex={index}
                                    numberOfOldStatistics={numberOfOldStatistics}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        eventUsers: state.eventUsers,
        eventSlug: state.event.eventSlug,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        reloadEventData: (eventId) => dispatch(actions.reloadEventData(eventId)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exhibitors));
