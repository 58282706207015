import {preventDefaultDrag} from "../../Utils/utils";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import React from "react";
import {useSelector} from "react-redux";

const DocumentResource = ({document}) => {
    const eventId = useSelector((state) => state.event.eventId);

    return <div className={'resource'}>
        <a
            href={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${document.file}`}
            target="_blank"
            rel="noopener noreferrer"
        >
                <span
                    onDragStart={preventDefaultDrag}
                    draggable="false"
                    className="resource-title ellipsis-second-line break-word"
                >
                    {document.displayName}
                </span>
            <CloudDownloadIcon/>
        </a>
    </div>
}

export default DocumentResource;