import Slider from 'react-slick/lib/slider';
import {ReactComponent as CloseIcon} from 'Images/svg/close-red.svg';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import Button from '@material-ui/core/Button';
import {ReactComponent as SliderArrow} from 'Images/svg/slider-arrow.svg';

const AnnouncementsSliderBannerMobile = ({announcements, handleCollapse, handleCallToActionButtonClicked}) => {
    const [slider, setSlider] = useState();
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    const changeSlideIndex = (slick, currentSlide, nextSlide) => {
        setCurrentSlideIndex(currentSlide);
    };

    const sliderSettings = {
        className: 'announcements-slider',
        dots: false,
        arrows: false,
        draggable: true,
        pauseOnHover: false,
        swipe: true,
        infinite: true,
        speed: 400,
        autoplay: true,
        autoplaySpeed: 15000,
        variableWidth: false,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerPadding: 0,
        responsiveDisplay: true,
        rtl: isRtlLanguage,
        // used beforeChange because afterChange is not triggered when adaptiveHeight is true
        // (react-slick issue)
        beforeChange: changeSlideIndex,
    };

    const goToPreviousSlide = () => {
        slider.slickPrev();
    };

    const goToNextSlide = () => {
        slider.slickNext();
    };

    const handleButtonClicked = (announcement) => e => {
        handleCallToActionButtonClicked(announcement)
    }

    return (
        <div className="announcements-banner-mobile">
            <div className="top-area">
                <div className="close-icon">
                    <CloseIcon onClick={handleCollapse} />
                </div>
                <Slider {...sliderSettings} ref={(slider) => setSlider(slider)}>
                    {announcements.map((announcement, index) => {
                        return (
                            <div className="announcement" key={index}>
                                <div className="announcement-title">{announcement.title}</div>
                                <div className="announcement-description">{announcement.description}</div>
                            </div>
                        );
                    })}
                </Slider>
            </div>

            <div className="bottom-menus">
                <div className="announcements-slider-control">
                    <div className="arrow previous" onClick={goToPreviousSlide}>
                        <SliderArrow />
                    </div>
                    <div className="announcement-number">
                        {currentSlideIndex + 1}/{announcements?.length}
                    </div>
                    <div className="arrow next" onClick={goToNextSlide}>
                        <SliderArrow />
                    </div>
                </div>
                {announcements[currentSlideIndex]?.callToAction && (
                    <Button 
                        variant={"outlined"}
                        disableElevation
                        className={"light call-to-action-button"}
                        onClick={handleButtonClicked(announcements[currentSlideIndex])}
                    >
                            {announcements[currentSlideIndex]?.buttonLabel}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default AnnouncementsSliderBannerMobile;
