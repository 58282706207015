import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as NoResultsExibitors} from '../../../Images/svg/no_search_results.svg';
import {ReactComponent as NoExibitors} from '../../../Images/svg/no_exhibitors.svg';
import {preventDefaultDrag} from '../../../Utils/utils';
import './ExhibitorsListStyles.scss';
import SingleExhibitor from './SingleExhibitor';

class ExhibitorsList extends React.Component {
    state = {
        searchString: '',
        filteredExhibitors: [],
    };

    componentDidMount() {
        // initialize our filtered exhibitors with all the exhibitors in the current showfloor

        const {exhibitors} = this.props;
        this.setState({
            filteredExhibitors: exhibitors,
        });
    }

    componentDidUpdate(prevProps) {
        // whenever a user changes the showfloor, we need to reinitialize the exhibitors
        if (prevProps.loadingExhibitors === true && this.props.loadingExhibitors === false) {
            this.handleFilterExhibitors();
        }
        if (prevProps.activeShowfloorId !== this.props.activeShowfloorId && this.props.activeShowfloorId !== null) {
            this.handleEmptyStringSearch();
        }
    }

    getCurrentShowfloor = () => {
        const {showfloors, activeShowfloorId} = this.props;
        const showfloor = showfloors.find((showfloor) => showfloor._id === activeShowfloorId);
        return showfloor;
    };

    handleFilterExhibitors = async () => {
        const {exhibitors} = this.props;
        const {searchString} = this.state;

        const searchStringLowerCase = searchString.toLowerCase();

        const filteredExhibitors = exhibitors.filter((exhibitor) => {
            let isInSearch = exhibitor?.company?.toLowerCase().includes(searchStringLowerCase);
            if (exhibitor.type === 'scholar') {
                isInSearch = exhibitor.booth.studyTitle?.toLowerCase().includes(searchStringLowerCase);
            }
            return isInSearch;
        });
        if (filteredExhibitors) {
            const sorted = filteredExhibitors.sort(function (a, b) {
                return a.booth?.position - b.booth?.position;
            });
            this.setState({filteredExhibitors: sorted});
        }
        return this.setState({filteredExhibitors: filteredExhibitors});
    };

    handleUpdateSearchString = (e) => {
        const searchString = e.target.value;
        this.setState(
            {
                searchString: searchString,
            },
            this.handleFilterExhibitors
        );
    };

    handleEmptyStringSearch = () => {
        this.setState(
            {
                searchString: '',
            },
            this.handleFilterExhibitors
        );
    };

    render() {
        const {searchString, filteredExhibitors} = this.state;
        const {
            exhibitors,
            activeExhibitorId,
            translation,
            defaultTranslation,
            event,
            showExhibitorsOverlay,
            languages,
            voteLoading,
        } = this.props;

        const showfloor = this.getCurrentShowfloor();
        return (
            <div
                onDragStart={preventDefaultDrag}
                className="exhibitors-container"
                data-content={event.showfloors?.length}
            >
                <div onDragStart={preventDefaultDrag} className="exhibitors-container-title">
                    <div onDragStart={preventDefaultDrag} className="">
                        <p onDragStart={preventDefaultDrag} className="title">
                            {showfloor?.name}
                        </p>
                        <p onDragStart={preventDefaultDrag} className="subtitle">
                            {translation?.showfloor.allExhibitorsButton}
                        </p>
                    </div>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`search-exhibitor-container ${exhibitors.length === 0 ? 'hide' : ''}`}
                    >
                        <TextField
                            label={
                                event?.showfloors?.length > 1
                                    ? translation?.showfloor?.searchExhibitorsMultiple ||
                                      defaultTranslation?.showfloor?.searchExhibitorsMultiple
                                    : translation?.showfloor.searchExhibitors ||
                                      defaultTranslation?.showfloor.searchExhibitors
                            }
                            id="search-in-exhibitors-list"
                            onChange={this.handleUpdateSearchString}
                            name="invitationCode"
                            value={searchString}
                            variant="outlined"
                            fullWidth
                            className={`field-container search-exhibitor ${
                                languages.platformLanguage === 'ar' && 'ar-safe'
                            }`}
                            InputProps={{
                                endAdornment: searchString ? (
                                    <CloseRoundedIcon className="delete-text" onClick={this.handleEmptyStringSearch} />
                                ) : (
                                    <SearchIcon />
                                ),
                            }}
                        />
                    </div>
                </div>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`exhibitors-list ${showExhibitorsOverlay ? 'overflow-auto' : ''}`}
                    data-empty={filteredExhibitors.length}
                >
                    {exhibitors.length > 0 ? (
                        <div onDragStart={preventDefaultDrag} className={`exhibitors-list-inner-container`}>
                            {filteredExhibitors.map((exhibitor) => {
                                const isActive = activeExhibitorId === exhibitor._id;
                                return (
                                    <SingleExhibitor
                                        singleExhibitorsVoteCount={this.props.singleExhibitorsVoteCount}
                                        votingData={this.props.votingData}
                                        isVotingEnabled={this.props.isVotingEnabled}
                                        votingPrivalages={this.props.votingPrivalages}
                                        key={exhibitor._id}
                                        exhibitor={exhibitor}
                                        isActive={isActive}
                                        showfloorId={exhibitor.showfloor}
                                        exhibitorInProgress={this.props.exhibitorInProgress}
                                        eventId={this.props.eventId}
                                        voteLoading={this.props.voteLoading}
                                        handleSetActiveExhibitor={this.props.handleSetActiveExhibitor}
                                        handleChangeVote={this.props.handleChangeVote}
                                    />
                                );
                            })}
                            {filteredExhibitors.length === 0 ? (
                                <div onDragStart={preventDefaultDrag} className="empty-exhibitors-list">
                                    <NoResultsExibitors fill={colors.primary} />
                                    <p>
                                        {translation?.showfloor.emptyExhibitorsResults ||
                                            defaultTranslation?.showfloor.emptyExhibitorsResults}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div onDragStart={preventDefaultDrag} className="empty-exhibitors-list">
                            <NoExibitors fill={colors.primary} />
                            <p>
                                <span>{translation?.showfloor.noExhibitorsTextFirst.toLowerCase()}</span>
                                <span>{translation?.showfloor.noExhibitorsTextSecond.toLowerCase()}</span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        exhibitors: state.exhibitors.approvedExhibitors,
        loadingExhibitors: state.exhibitors.loading,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        languages: state.languages,
        activeShowfloorId: state.showfloors.activeShowfloorId,
        resourcesAccess: state.user.resourcesAccess,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        showfloors: state.showfloors.showfloors,
        showfloor: state.showfloors.showfloor,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
        onGetShowfloor: (eventId, showfloorId) => dispatch(actions.getShowfloor(eventId, showfloorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorsList);
