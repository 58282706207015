import React, {Component} from 'react';

export default class AcceptButton extends Component {
    render() {
        return (
            <svg
                draggable="false"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_d)">
                    <circle cx="18" cy="17" r="12" fill="white" />
                    <circle cx="18" cy="17" r="11.65" stroke={this.props.strokeSecondary} strokeWidth="0.7" />
                </g>
                <path
                    d="M15 16.9566H12.9688V19H12.0312V16.9566H10V16.0548H12.0312V14H12.9688V16.0548H15V16.9566Z"
                    fill={this.props.fillSecondary}
                />
                <path
                    d="M19.1956 11C17.4245 11.0014 15.9898 12.4383 15.9912 14.2095C15.9919 15.0585 16.3291 15.8725 16.9291 16.4732C17.5313 17.0761 18.3486 17.4146 19.2007 17.4139C20.9719 17.4125 22.4065 15.9756 22.4051 14.2044C22.4037 12.4333 20.9667 10.9986 19.1956 11ZM20.927 15.9236C19.9748 16.8771 18.4299 16.8781 17.4764 15.9258C17.018 15.468 16.7605 14.8467 16.7607 14.1989C16.7599 13.5523 17.0167 12.9321 17.4742 12.4752C18.4265 11.5217 19.9714 11.5207 20.9248 12.473C21.8783 13.4253 21.8793 14.9701 20.927 15.9236Z"
                    fill={this.props.fillSecondary}
                />
                <path
                    d="M21.1353 18.022H17.8647C15.7303 18.022 14 19.7523 14 21.8867V22.6341C14 22.8275 14.1568 22.9843 14.3502 22.9843H24.6498C24.8432 22.9843 25 22.8275 25 22.6341V21.8867C25 19.7523 23.2697 18.022 21.1353 18.022ZM24.2995 22.2862H14.7029V21.8867C14.7082 20.1414 16.1218 18.7278 17.8671 18.7225H21.1353C22.8806 18.7278 24.2942 20.1414 24.2995 21.8867V22.2862Z"
                    fill={this.props.fillSecondary}
                />
                <defs>
                    <filter
                        id="filter0_d"
                        x="0"
                        y="0"
                        width="36"
                        height="36"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="3" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                </defs>
            </svg>
        );
    }
}
