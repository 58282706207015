import classnames from 'classnames';
const PageHeadline = ({heading, subHeading, containerWidth, containerMarginTop, passwordSent}) => {
    const isEmail = subHeading.includes('@');
    return (
        <div className={`page-headline`} style={{maxWidth: containerWidth, marginTop: containerMarginTop}}>
            <h2>{heading}</h2>
            <p className={classnames({ellipsis: isEmail && !passwordSent})}>{subHeading}</p>
        </div>
    );
};
export default PageHeadline;

PageHeadline.defaultProps = {
    containerWidth: '100%',
    containerMarginTop: '0',
};
