import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import isEqual from 'lodash/isEqual';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';

import ColorPicker from './LayoutComponents/ColorPicker';
import ConfirmDialog from '../../../Dialogs/Confirm';
import colors from '../../../CSS/_variables.module.scss';
import {preventDefaultDrag, fireClickEvent} from '../../../Utils/utils';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {CircularProgress} from "@material-ui/core";
// import * as actions from '../../../store/actions';

class LobbyDoorTexts extends PureComponent {
    wrapperDoorsTextsRef = React.createRef();
    handleClickOutside = this.handleClickOutside.bind(this);

    componentDidMount() {
        if (this.props.branding?.data) {
            this.setEventBranding();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.branding?.data, this.props.branding?.data)) {
            this.setEventBranding();
        }

        // disable button if door text wasn't changed
        if (
            !this.state.isSaveDoorTextsButtonDisabled &&
            this.state.doorTextsFields.every(({name, value}) => value === this.props.branding.data[name])
        ) {
            this.setState({ isSaveDoorTextsButtonDisabled: true });
        }
    }

    state = {
        isConfirmChangesDialogOpened: false,
        isSaveDoorTextsButtonDisabled: true,
        navigationElement: null,
        doorTextsFields: [
            {
                name: 'auditoriumDoorText',
                description:
                    'This text is displayed above the Auditorium door in the Lobby. Leave empty if no custom text is required',
                infoBulletText:
                    'Please make sure that your Lobby 3D render does not already have an Auditorium door title',
                adornment: '',
                value: '',
                label: 'Auditorium door title',
                multiline: 0,
                validators: ['maxStringLength: 20'],
                errorMessages: ['You have reached the maximum limit of characters allowed (20 characters)'],
            },
            {
                name: 'exhibitorsDoorText',
                description:
                    'This text is displayed above the Exhibit Hall door in the Lobby. Leave empty if no custom text is required',
                infoBulletText:
                    'Please make sure that your Lobby 3D render does not already have an Exhibit Hall door title',
                adornment: '',
                value: '',
                label: 'Exhibit Hall door title',
                multiline: 0,
                validators: ['maxStringLength: 20'],
                errorMessages: ['You have reached the maximum limit of characters allowed (20 characters)'],
            },
        ],
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, isConfirmChangesDialogOpened} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (isConfirmChangesDialogOpened) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        const {isSaveDoorTextsButtonDisabled} = this.state;
        if (this.wrapperDoorsTextsRef && this.wrapperDoorsTextsRef.current && !this.wrapperDoorsTextsRef.current.contains(e.target)) {
            if (!isSaveDoorTextsButtonDisabled) {
                this.setState({isConfirmChangesDialogOpened: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    saveEventCustomDoorTexts = () => {
        const {navigationElement} = this.state;

        const data = {};
        this.state.doorTextsFields.forEach((field) => {
            data[field.name] = field.value;
        });
        this.props.saveEventBranding(data);

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeConfirmDialog();
        this.setEventBranding();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    setEventBranding = () => {
        const {branding} = this.props;
        const brandingData = branding.data;
        this.setState({
            doorTextsFields: this.state.doorTextsFields.map((doorTextField) => ({
                ...doorTextField, value: brandingData[doorTextField.name],
            })),
            isSaveDoorTextsButtonDisabled: true,
            isConfirmChangesDialogOpened: false,
        });
    };

    handleChangeCustomTexts = (index) => (e) => {
        let updatedCustomizeFields = [...this.state.doorTextsFields];
        updatedCustomizeFields[index].value = e.target.value;
        this.setState({doorTextsFields: updatedCustomizeFields}, () => {
            this.refs.formTexts.isFormValid().then((isValid) => {
                this.setState({isSaveDoorTextsButtonDisabled: !isValid});
            });
        });
    };

    closeConfirmDialog = () => this.setState({isConfirmChangesDialogOpened: false});

    render() {
        const {defaultTranslation, loadingSaveEventBranding} = this.props;
        const {doorTextsFields, isSaveDoorTextsButtonDisabled, isConfirmChangesDialogOpened} = this.state;

        return (
            <div onDragStart={preventDefaultDrag} className="branding-lobby-content">
                <h4 className="advanced-options-title">AUDITORIUM AND EXHIBIT HALL DOOR TEXTS</h4>
                {loadingSaveEventBranding ? <div
                        className="d-flex align-items-center justify-content-center">
                        <CircularProgress color="primary"/>
                    </div> :
                    <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                        <div onDragStart={preventDefaultDrag} ref={this.wrapperDoorsTextsRef}>
                            <ValidatorForm ref="formTexts" onSubmit={this.saveEventCustomDoorTexts}>
                                {doorTextsFields.map((customField, index) => {
                                    return (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="options-container"
                                            key={customField.name}
                                        >
                                            <div onDragStart={preventDefaultDrag} className="single-option-container">
                                                <TextValidator
                                                    className="setting-input"
                                                    label={customField.label}
                                                    type="text"
                                                    name={customField.name}
                                                    index={index}
                                                    value={customField.value}
                                                    onChange={this.handleChangeCustomTexts(index)}
                                                    validators={customField.validators}
                                                    errorMessages={customField.errorMessages}
                                                    multiline={customField.multiline > 0}
                                                    rows={customField.multiline}
                                                    fullWidth={true}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div onDragStart={preventDefaultDrag} className="input-description">
                                                <p className='input-description-text'>
                                                    <Tooltip
                                                        arrow
                                                        classes={{tooltip: 'ignore-rtl'}}
                                                        tabIndex="0"
                                                        title={
                                                            <span draggable="false" onDragStart={preventDefaultDrag}>
                                                        {customField.infoBulletText}
                                                    </span>
                                                        }
                                                    >
                                                        <InfoIcon classes={{root: 'primary-color'}}/>
                                                    </Tooltip>
                                                    {customField.description}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div onDragStart={preventDefaultDrag} className="action-container">
                                    <Button
                                        type="submit"
                                        variant={"contained"}
                                        disabled={isSaveDoorTextsButtonDisabled}
                                        startIcon={<SaveOutlinedIcon/>}
                                        disableElevation
                                        color={"secondary"}
                                    >
                                        SAVE
                                    </Button>
                                </div>
                            </ValidatorForm>
                        </div>
                        <p onDragStart={preventDefaultDrag} className="inner-options-title">
                            Text color
                        </p>
                        <ColorPicker
                            for={'lobbyDoorsTextColor'}
                            color={colors.lobbyDoorTextColor}
                            description={
                                'Here you can manually set up the text color. Be sure it has a high level of contrast'
                            }
                            saveEventBranding={this.props.saveEventBranding}
                        />
                    </div>}
                <ConfirmDialog
                    open={isConfirmChangesDialogOpened}
                    closeConfirm={this.closeConfirmDialog}
                    dialogTitle={defaultTranslation?.generalText?.unsavedChangesTitle}
                    dialogDescription={defaultTranslation?.generalText?.unsavedChangesText}
                    dialogConfirmButtonLabel={defaultTranslation?.generalText?.save}
                    dialogCancelButtonLabel={defaultTranslation?.generalText?.cancel}
                    handleConfirm={this.saveEventCustomDoorTexts}
                    handleDiscardChanges={this.handleDiscardChanges}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        branding: state.event.branding,
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps, null)(LobbyDoorTexts);
