import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {Button, DialogActions} from '@material-ui/core';
import React, {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import RegisterEventEmailCodeValidation from '../../../Event/EmailCodeValidation/EmailCodeValidation';

import axios from 'store/axios-instance';
import * as actions from '../../../../store/actions';

const EmailChangeForm = ({closeConfirmPassword, emailChangeSuccess, emailChangeFailed, handleActiveStep}) => {
    const dispatch = useDispatch();
    const formRef = React.useRef(null);
    //redux
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    //states
    const [inputValue, setInputValue] = React.useState('');
    const [displayEmailValidation, setDisplayEmailValidation] = useState(false);
    const [displayUserCreatedScreen, setDisplayUserCreatedScreen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); //['Email is not valid'
    const [codeError, setCodeError] = useState('');

    const onSubmitEmailChange = async () => {
        try {
            const {data} = await axios.post('users/me/change-email', {email: inputValue});
            if (data.success) {
                setDisplayEmailValidation(true);
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.error);
            }
        }
    };

    const handleInputChange = (e) => {
        errorMessage.length > 0 && setErrorMessage('');
        const {value} = e.target;
        setInputValue(value);
    };

    const createRegistrationCode = async () => {};

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This creates the smooth scrolling effect
        });
    };
    const handleUpdate = async (code) => {
        try {
            setDisplayUserCreatedScreen(true);

            const {data} = await axios.post(`users/me/confirm-email-change`, {
                email: inputValue,
                code: Number(code),
            });

            if (data.success) {
                dispatch(actions.refreshUserData());

                setTimeout(() => {
                    setDisplayUserCreatedScreen(false);
                    closeConfirmPassword();
                    scrollToTop();
                }, 1000);
                emailChangeSuccess();
            } else {
                emailChangeFailed();
                setCodeError('Entered code is invalid.');
                setDisplayUserCreatedScreen(false);
            }
        } catch (error) {
            emailChangeFailed();
            setCodeError(error.response?.data?.error);
            setDisplayUserCreatedScreen(false);
        }
    };

    const isButtonDisabled = useMemo(
        () => inputValue.length === 0 || errorMessage.length > 0,
        [errorMessage.length, inputValue.length]
    );

    const isError = errorMessage.length > 0;

    useEffect(() => {
        handleActiveStep(displayEmailValidation ? 'verifyEmail' : 'emailChange');
    }, [displayEmailValidation, handleActiveStep]);

    return (
        <div className={'w-100'} data-testid="email-change-form" data-display-email-validation={displayEmailValidation}>
            {displayEmailValidation ? (
                <RegisterEventEmailCodeValidation
                    title={'Email validation'}
                    displayEmailValidation={displayEmailValidation}
                    message={'Please enter the code sent to your email'}
                    newEmail={inputValue}
                    errorFromComponent={codeError?.length > 0 && codeError}
                    createRegistrationCode={createRegistrationCode}
                    handleRegister={handleUpdate}
                    onSubmitEmailChange={onSubmitEmailChange}
                    comeFrom={'emailChange'}
                    displayUserCreatedScreen={displayUserCreatedScreen}
                />
            ) : (
                <ValidatorForm
                    ref={formRef}
                    onSubmit={onSubmitEmailChange}
                    onError={(errors) => console.log(errors)}
                    className={'control-password-form'}
                    instantValidate={false} // Disable instant validation to show errors only on submit
                >
                    <TextValidator
                        label={translation?.emailUpdate.newEmail || defaultTranslation.emailUpdate.newEmail}
                        type="email"
                        fullWidth={true}
                        className={isError ? 'error-text-field' : 'text-field'}
                        variant="outlined"
                        validators={['required', 'isEmail']}
                        errorMessages={[
                            `${translation?.errors.required || defaultTranslation.errors.required}`,
                            `${translation?.errors.emailNotValid || defaultTranslation.errors.emailNotValid}`,
                        ]}
                        value={inputValue}
                        onChange={handleInputChange}
                        name={'email'}
                        color={'secondary'}
                    />
                    <p className={'error-message'}>{errorMessage}</p>
                    <DialogActions className={'password-control-actions'}>
                        <Button onClick={closeConfirmPassword} color="primary">
                            {translation?.emailUpdate.cancel || defaultTranslation.emailUpdate.cancel}
                        </Button>
                        <Button disabled={isButtonDisabled} type="submit" color="primary">
                            {translation?.emailUpdate.change || defaultTranslation.emailUpdate.change}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            )}
        </div>
    );
};

export default EmailChangeForm;
