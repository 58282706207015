import React, {PureComponent} from 'react';
import Button from '@material-ui/core/Button';
import ChangePassword from '../../Dialogs/MyAccount/ChangePassword';
import {preventDefaultDrag} from '../../Utils/utils';

class ChangePasswordAccount extends PureComponent {
    state = {
        changeUserPasswordDialog: false,
        changePasswordServerMessage: '',
    };

    handleChangePassword = () => this.setState({changeUserPasswordDialog: true});

    handleCloseDialog = (serverMessage) => {
        let message = '';
        if (serverMessage) {
            message = serverMessage;
        }
        this.setState({
            changeUserPasswordDialog: false,
            changePasswordServerMessage: message,
        });
    };

    render() {
        const {changeUserPasswordDialog, changePasswordServerMessage} = this.state;
        const {translation, registrationType} = this.props;
        return (
            <>
                {(registrationType === '' || registrationType === 'email') && (
                    <Button
                        onClick={this.handleChangePassword}
                        type="button"
                        color="primary"
                        variant="text"
                        className="change-password-btn"
                    >
                        {translation?.myAccountPage.changePasswordButton}
                    </Button>
                )}
                {changePasswordServerMessage ? (
                    <p onDragStart={preventDefaultDrag} className="server-message">
                        {changePasswordServerMessage ? changePasswordServerMessage : ''}
                    </p>
                ) : null}

                {changeUserPasswordDialog && (
                    <ChangePassword opened={changeUserPasswordDialog} closeDialog={this.handleCloseDialog} />
                )}
            </>
        );
    }
}

export default ChangePasswordAccount;
