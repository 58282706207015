import React, {PureComponent} from 'react';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EventIcon from '@material-ui/icons/Event';
import RoomIcon from '@material-ui/icons/Room';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import TranslateIcon from '@material-ui/icons/Translate';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import Confirm from 'Dialogs/Confirm';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import {preventDefaultDrag, fireClickEvent} from 'Utils/utils';
import isEqual from 'lodash/isEqual';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

class HPHeaderInfo extends PureComponent {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            fields: [
                {
                    name: 'homepageEventName',
                    description: 'Please limit field to 200 characters',
                    adornment: <EventNoteIcon />,
                    value: '',
                    label: 'Event Name',
                    multiline: 0,
                    validators: ['maxStringLength: 200'],
                    errorMessages: ['You have reached the maximum limit of characters allowed (200 characters)'],
                },
                {
                    name: 'homepageOrganizerName',
                    description: 'Please limit field to 200 characters',
                    adornment: <SupervisorAccountIcon />,
                    value: '',
                    label: 'Organizer',
                    multiline: 0,
                    validators: ['maxStringLength: 200'],
                    errorMessages: ['You have reached the maximum limit of characters allowed (200 characters)'],
                },
                {
                    name: 'homepageDateText',
                    description: 'Please limit field to 200 characters',
                    adornment: <EventIcon />,
                    value: '',
                    label: 'Event Date',
                    multiline: 0,
                    validators: ['maxStringLength: 200'],
                    errorMessages: ['You have reached the maximum limit of characters allowed (200 characters)'],
                },
                {
                    name: 'homepageLocationText',
                    description: 'Please limit field to 200 characters',
                    adornment: <RoomIcon />,
                    value: '',
                    label: 'Event Location',
                    multiline: 0,
                    validators: ['maxStringLength: 200'],
                    errorMessages: ['You have reached the maximum limit of characters allowed (200 characters)'],
                },
                {
                    name: 'homepagePriceText',
                    description:
                        "It's better to highlight the currency and taxes. Please limit field to 200 characters",
                    adornment: <LocalOfferIcon />,
                    value: '',
                    label: 'Event Price',
                    multiline: 0,
                    validators: ['maxStringLength: 200'],
                    errorMessages: ['You have reached the maximum limit of characters allowed (200 characters)'],
                },
            ],
            buttonDisabled: true,
            openConfirmUnsavedChanges: false,
            navigationElement: null,
        };
    }

    componentDidMount() {
        this._setEventHeaderInfoBranding();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const {brandingLanguage, brandingTranslations} = this.props;
        if (
            !isEqual(prevProps.brandingTranslations, brandingTranslations) ||
            prevProps.brandingLanguage !== brandingLanguage
        ) {
            this._setEventHeaderInfoBranding();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) =>
            pathElem.getAttribute?.('data-is-navigation')
        );

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        const {buttonDisabled} = this.state;
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (!buttonDisabled) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
    };

    _setEventHeaderInfoBranding = () => {
        const {brandingLanguage, brandingTranslations} = this.props;
        let brandingData = brandingTranslations.data.find((translation) => translation.language === brandingLanguage);
        if (brandingData) {
            this.setState({
                buttonDisabled: true,
                fields: [
                    {...this.state.fields[0], value: brandingData.homepageEventName},
                    {...this.state.fields[1], value: brandingData.homepageOrganizerName},
                    {...this.state.fields[2], value: brandingData.homepageDateText},
                    {...this.state.fields[3], value: brandingData.homepageLocationText},
                    {...this.state.fields[4], value: brandingData.homepagePriceText},
                ],
            });
        }
    };

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({buttonDisabled: !isValid});
            });
        });
    };

    _saveHeaderInfo = () => {
        const {fields, navigationElement} = this.state;

        let translationData = {};
        fields.forEach((field) => {
            translationData[field.name] = field.value;
        });
        this.props.saveEventTranslation(translationData);

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this._setEventHeaderInfoBranding();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    render() {
        const {fields, buttonDisabled, openConfirmUnsavedChanges} = this.state;
        const {isMultiLanguage} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} ref={this.wrapperRef}>
                <Tooltip
                    arrow
                    classes={{tooltip: 'ignore-rtl'}}
                    id="homepage-description-tooltip"
                    tabIndex="0"
                    title={
                        "Leave this fields empty from this section if you don't want them to be displayed on the Homepage"
                    }
                >
                    <InfoIcon color="secondary" />
                </Tooltip>
                <p onDragStart={preventDefaultDrag} className="inner-options-title">
                    Header info
                </p>
                <ValidatorForm ref="form" onSubmit={this._saveHeaderInfo}>
                    {fields.map((field, index) => {
                        return (
                            <div onDragStart={preventDefaultDrag} className="options-container" key={field.name}>
                                <div onDragStart={preventDefaultDrag} className="single-option-container">
                                    <TextValidator
                                        className="setting-input"
                                        label={field.label}
                                        type="text"
                                        name={field.name}
                                        index={index}
                                        value={field.value}
                                        onChange={this.handleChange(index)}
                                        validators={field.validators}
                                        errorMessages={field.errorMessages}
                                        multiline={field.multiline > 0}
                                        rows={field.multiline}
                                        fullWidth={true}
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment:
                                                isMultiLanguage && field.adornment !== undefined ? (
                                                    <TranslateIcon />
                                                ) : (
                                                    field.adornment
                                                ),
                                        }}
                                    />
                                </div>
                                <div onDragStart={preventDefaultDrag} className="input-description">
                                    <p>{field.description}</p>
                                </div>
                            </div>
                        );
                    })}
                    <div onDragStart={preventDefaultDrag} className="action-container">
                        <Button
                            type="submit"
                            variant={'contained'}
                            disabled={buttonDisabled}
                            startIcon={<SaveOutlinedIcon />}
                            disableElevation
                            color={'secondary'}
                        >
                            SAVE
                        </Button>
                    </div>
                </ValidatorForm>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this._saveHeaderInfo}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        brandingLanguage: state.languages.organizerBrandingLanguage,
        brandingTranslations: state.event.brandingTranslations,
    };
};

export default connect(mapStateToProps)(HPHeaderInfo);
