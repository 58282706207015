import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Portal from '@material-ui/core/Portal';
import colors from '../CSS/_variables.module.scss';
import {ReactComponent as Facebook} from '../Images/svg/facebook-logo.svg';
import {ReactComponent as LinkedIn} from '../Images/svg/linkedin-sign.svg';
import {ReactComponent as Twitter} from '../Images/svg/twitter.svg';
import {ReactComponent as Email} from '../Images/svg/sm-email.svg';
import {ReactComponent as Clipboard} from '../Images/svg/sm-clipboard.svg';
import {connect} from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import {preventDefaultDrag} from '../Utils/utils';
import {Slide} from '@material-ui/core';

class SMPopup extends React.Component {
    state = {
        isSnackbarOpen: false,
    };

    handleOpenSnackbar = () => this.setState({isSnackbarOpen: true});
    handleCloseSnackbar = () => this.setState({isSnackbarOpen: false});

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isSnackbarOpen !== prevState.isSnackbarOpen) {
            this.setTransitioCloseEffect();
        }
    }

    handleClipboard = (url) => {
        const {eventSlug} = this.props;
        const urlRaw = window.location.href;
        const isPageShowfloor = urlRaw.includes('showfloor');
        let showfloorClipboardMessage = url.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
        let exhibitorId = localStorage.getItem('exhibitorId');
        let showfloorId = localStorage.getItem('showfloorId');

        let clipboardLink = `${window.location.origin}/event/${eventSlug}/showfloor/${showfloorId}/booth/${exhibitorId}`;
        showfloorClipboardMessage = showfloorClipboardMessage + clipboardLink;

        navigator.clipboard.writeText(isPageShowfloor ? showfloorClipboardMessage : url).then(() => {
            this.handleOpenSnackbar();
            this.props.closeConfirm();
        });
    };

    fireClickEvent = (el) => {
        const evt = new window.MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        el.dispatchEvent(evt);
    };

    unescapeHTMLURI(text) {
        const decodedString = text
            .replace(/&#x2F;/g, '/')
            .replace(/&#x5C;/g, '\\')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&quot;/g, '"')
            .replace(/&#x27;/g, `'`)
            .replace(/&apos;/g, `'`)
            .replace(/&#96;/g, '`');
        return encodeURIComponent(decodedString);
    }

    unescapeHTML(text) {
        const decodedString = text
            .replace(/&#x2F;/g, '/')
            .replace(/&#x5C;/g, '\\')
            .replace(/&amp;/g, '&')
            .replace(/&gt;/g, '>')
            .replace(/&lt;/g, '<')
            .replace(/&quot;/g, '"')
            .replace(/&#x27;/g, `'`)
            .replace(/&apos;/g, `'`)
            .replace(/&#96;/g, '`');
        return decodedString;
    }

    openSendEmail(subject, body) {
        this.props.closeConfirm();
        const decodedBody = this.unescapeHTMLURI(body);
        const email = `mailto:?subject=${subject}&body=${decodedBody}`;
        const a = document.createElement('a');
        a.href = email;
        a.target = '_blank'; // Open the link in a new tab
        this.fireClickEvent(a);
    }

    transitionUp = (props) => {
        let newProps;
        newProps = {...props, appear: this.state.isSnackbarOpen};
        return <Slide {...newProps} direction="up" />;
    };
    openSharePopup(targetURL) {
        this.props.closeConfirm();
        const w = 550;
        const h = 400;
        const left = window.innerWidth / 2 - w / 2;
        const top = window.innerHeight / 2 - h / 2;
        window.open(targetURL, 'window', `width=${w},height=${h}, top=${top}, left=${left}`);
        return false;
    }
    setTransitioCloseEffect = () => {
        const snackbar = document.getElementById('snackbar-email-domains-clipboard');
        setTimeout(() => {
            if (snackbar) {
                snackbar.classList?.toggle('snackBarClose');
            }
        }, 500);
    };
    render() {
        const {
            open,
            eventName,
            targetURL,
            clipboardMessage,
            popupLanguageLevel,
            dialogTitle,
            translationEvent,
            translationPlatform,
            defaultTranslation,
            facebookQuote,
            twitterMessage,
            emailSubject,
            emailBody,
            closeConfirm,
        } = this.props;
        const {isSnackbarOpen} = this.state;

        const {event} = this.props;
        const eventURL = `${window.location.origin}${event?.slug ? '/event/' + event?.slug : ''}`;

        return (
            <>
                <Dialog
                    open={open}
                    onClose={closeConfirm}
                    PaperProps={{classes: {root: 'sm-popup-dialog'}}}
                    TransitionProps={{timeout: {enter: 500, exit: 1000}}}
                >
                    <DialogTitle id="form-dialog-title" classes={{root: 'sm-dialog-title'}}>
                        {dialogTitle}
                    </DialogTitle>
                    <DialogContent classes={{root: 'sm-dialog-content'}}>
                        <div
                            className="sm-item"
                            title={
                                (popupLanguageLevel === 'event'
                                    ? translationEvent?.socialMediaShare?.linkedinButtonTitle
                                    : translationPlatform?.socialMediaShare?.linkedinButtonTitle) ||
                                defaultTranslation?.socialMediaShare?.linkedinButtonTitle
                            }
                            onClick={this.openSharePopup.bind(
                                this,
                                `https://www.linkedin.com/sharing/share-offsite/?url=${
                                    targetURL ? targetURL : eventURL
                                }`
                            )}
                        >
                            <LinkedIn width="50" height="50" fill={colors.linkedin} />
                            {(popupLanguageLevel === 'event'
                                ? translationEvent?.socialMediaShare?.linkedinLabel
                                : translationPlatform?.socialMediaShare?.linkedinLabel) ||
                                defaultTranslation?.socialMediaShare?.linkedinLabel}
                        </div>
                        <div
                            className="sm-item"
                            title={
                                (popupLanguageLevel === 'event'
                                    ? translationEvent?.socialMediaShare?.facebookButtonTitle
                                    : translationPlatform?.socialMediaShare?.facebookButtonTitle) ||
                                defaultTranslation?.socialMediaShare?.facebookButtonTitle
                            }
                            onClick={this.openSharePopup.bind(
                                this,
                                `https://www.facebook.com/sharer/sharer.php?u=${
                                    targetURL ? targetURL : eventURL
                                }&quote=${facebookQuote ? this.unescapeHTMLURI(facebookQuote) : ''}`
                            )}
                        >
                            <Facebook width="50" height="50" fill={colors.facebook} />
                            {(popupLanguageLevel === 'event'
                                ? translationEvent?.socialMediaShare?.facebookLabel
                                : translationPlatform?.socialMediaShare?.facebookLabel) ||
                                defaultTranslation?.socialMediaShare?.facebookLabel}
                        </div>
                        <div
                            className="sm-item"
                            title={
                                (popupLanguageLevel === 'event'
                                    ? translationEvent?.socialMediaShare?.twitterButtonTitle
                                    : translationPlatform?.socialMediaShare?.twitterButtonTitle) ||
                                defaultTranslation?.socialMediaShare?.twitterButtonTitle
                            }
                            onClick={this.openSharePopup.bind(
                                this,
                                `https://twitter.com/intent/tweet?text=${
                                    twitterMessage ? this.unescapeHTMLURI(twitterMessage) : ''
                                }&url=${targetURL ? targetURL : eventURL}`
                            )}
                        >
                            <Twitter width="50" height="50" fill={colors.twitter} />
                            {(popupLanguageLevel === 'event'
                                ? translationEvent?.socialMediaShare?.twitterLabel
                                : translationPlatform?.socialMediaShare?.twitterLabel) ||
                                defaultTranslation?.socialMediaShare?.twitterLabel}
                        </div>
                        <div
                            className="sm-item"
                            title={
                                (popupLanguageLevel === 'event'
                                    ? translationEvent?.socialMediaShare?.emailButtonTitle
                                    : translationPlatform?.socialMediaShare?.emailButtonTitle) ||
                                defaultTranslation?.socialMediaShare?.emailButtonTitle
                            }
                            onClick={this.openSendEmail.bind(
                                this,
                                emailSubject ? emailSubject : '',
                                emailBody ? emailBody : ''
                            )}
                        >
                            <Email width="50" height="50" fill={colors.dark} />
                            {(popupLanguageLevel === 'event'
                                ? translationEvent?.socialMediaShare?.emailLabel
                                : translationPlatform?.socialMediaShare?.emailLabel) ||
                                defaultTranslation?.socialMediaShare?.emailLabel}
                        </div>
                        <div
                            className="sm-item"
                            title={
                                (popupLanguageLevel === 'event'
                                    ? translationEvent?.socialMediaShare?.clipboardButtonTitle
                                    : translationPlatform?.socialMediaShare?.clipboardButtonTitle) ||
                                defaultTranslation?.socialMediaShare?.clipboardButtonTitle
                            }
                            onClick={this.handleClipboard.bind(
                                this,
                                `${clipboardMessage ? `"${this.unescapeHTML(clipboardMessage)}" - ` : ''}${
                                    (popupLanguageLevel === 'event'
                                        ? translationEvent?.socialMediaShare?.imAttending
                                        : translationPlatform?.socialMediaShare?.imAttending) ||
                                    defaultTranslation?.socialMediaShare?.imAttending
                                } ${eventName ? `"${eventName}"` : ''} ${
                                    (popupLanguageLevel === 'event'
                                        ? translationEvent?.socialMediaShare?.event
                                        : translationPlatform?.socialMediaShare?.event) ||
                                    defaultTranslation?.socialMediaShare?.event
                                }. ${
                                    (popupLanguageLevel === 'event'
                                        ? translationEvent?.socialMediaShare?.interestedClick
                                        : translationPlatform?.socialMediaShare?.interestedClick) ||
                                    defaultTranslation?.socialMediaShare?.interestedClick
                                } ${targetURL ? targetURL : eventURL}`
                            )}
                        >
                            <Clipboard width="50" height="50" fill={colors.dark} />
                            {(popupLanguageLevel === 'event'
                                ? translationEvent?.socialMediaShare?.clipboardLabel
                                : translationPlatform?.socialMediaShare?.clipboardLabel) ||
                                defaultTranslation?.socialMediaShare?.clipboardLabel}
                        </div>
                        <div className="sm-item"></div>
                    </DialogContent>
                    <DialogActions className="sm-popup-close-container">
                        <IconButton disableTouchRipple disableFocusRipple disableRipple onClick={closeConfirm}>
                            <ClearIcon />
                        </IconButton>
                    </DialogActions>
                </Dialog>
                <Portal>
                    {/*<SnackbarGlobal
                        handleCloseSnackbar={this.handleCloseSnackbar}
                        message={
                            (popupLanguageLevel === 'event'
                                ? translationEvent?.socialMediaShare?.clipboardSnackbarMessage
                                : translationPlatform?.socialMediaShare?.clipboardSnackbarMessage) ||
                            defaultTranslation?.socialMediaShare?.clipboardSnackbarMessage
                        }
                        snackbarOpen={isSnackbarOpen}
                        icon={<CheckIcon />}
                    />*/}

                    <Snackbar
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        key={
                            (popupLanguageLevel === 'event'
                                ? translationEvent?.socialMediaShare?.clipboardSnackbarMessage
                                : translationPlatform?.socialMediaShare?.clipboardSnackbarMessage) ||
                            defaultTranslation?.socialMediaShare?.clipboardSnackbarMessage
                        }
                        open={isSnackbarOpen}
                        id={`snackbar-email-domains-clipboard`}
                        onClose={this.handleCloseSnackbar}
                        TransitionComponent={this.transitionUp}
                        ContentProps={{
                            'aria-describedby': 'message-idx',
                        }}
                        className={`snackbar-email-domains`}
                        message={
                            <span
                                onDragStart={preventDefaultDrag}
                                draggable="false"
                                id="message-idx"
                                className="email-domain-snackbar-message"
                            >
                                <span className={`icon-cover`}>
                                    <CheckIcon />
                                </span>{' '}
                                {(popupLanguageLevel === 'event'
                                    ? translationEvent?.socialMediaShare?.clipboardSnackbarMessage
                                    : translationPlatform?.socialMediaShare?.clipboardSnackbarMessage) ||
                                    defaultTranslation?.socialMediaShare?.clipboardSnackbarMessage}
                            </span>
                        }
                    />
                </Portal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventSlug: state.event.eventSlug,
        event: state.event.data,

        translationEvent: state.languages.translations[state.languages.eventLanguage],
        translationPlatform: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(SMPopup);
