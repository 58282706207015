import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import NotFound from '../../Pages/NotFound';
import EventPrivateComponent from './EventPrivateComponent';
import NotApprovedAccount from '../../Components/EventManagement/NotApprovedAccount';
import Spinner from '../../SmallLayoutComponents/FullPageSpinner';
import OrganizerNavigation from '../../SmallLayoutComponents/OrganizerNavigation';
import { isMobile } from 'react-device-detect';
import { handleModularCoOrganizerMenu } from '../../Utils/utils';
import AIChatbot from 'Integrations/AIChatbot/AIChatbot';

const OrganizerRoute = ({
	component: Component,
	loggedIn,
	user,
	event,
	loadingUser,
	hasAccess,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) => {
			const eventSlug = props.match.params.eventSlug;

			if (!loggedIn && loadingUser) {
				return <Spinner />;
			}
			if (loggedIn && user) {
				let modularCoOrganizerLink = false;
				const userRolesInEvent = user.eventRoles.find(
					(role) => role.event.slug === eventSlug
				);
				const userIsOrganizer = userRolesInEvent?.roles.includes('organizer');
				const userIsCoOrganizer =
					userRolesInEvent?.roles.includes('coOrganizer');
				const isActive = userRolesInEvent?.accessRights?.isActive;
				if (userRolesInEvent?.accessRights) {
					modularCoOrganizerLink = handleModularCoOrganizerMenu(
						userRolesInEvent?.accessRights,
						event
					);
				}

				if (userIsOrganizer) {
					if (user.organizerStatus !== 'approved') {
						return (
							<EventPrivateComponent {...props}>
								<OrganizerNavigation />
								<NotApprovedAccount {...props} />
							</EventPrivateComponent>
						);
					}
					if (isMobile) {
						return (
							<Redirect
								to={{
									pathname: `/event/${eventSlug}/lobby`,
								}}
							/>
						);
					}
					return (
						<EventPrivateComponent {...props}>
							<OrganizerNavigation />
							<Component />
							<AIChatbot userRole="organizer" />
						</EventPrivateComponent>
					);
				}
				if (userIsCoOrganizer && isActive) {
					if (hasAccess !== undefined && !hasAccess) {
						return (
							<Redirect
								to={{
									pathname: `/event/${eventSlug}/${
										modularCoOrganizerLink !== undefined
											? modularCoOrganizerLink
											: 'lobby'
									}`,
								}}
							/>
						);
					}
					if (isMobile) {
						return (
							<Redirect
								to={{
									pathname: `/event/${eventSlug}/lobby`,
								}}
							/>
						);
					}
					return (
						<EventPrivateComponent {...props}>
							<OrganizerNavigation />
							<Component />
							<AIChatbot userRole="co-organizer" />
						</EventPrivateComponent>
					);
				}
				return (
					<Redirect
						to={{
							pathname: `/event/${eventSlug}/lobby`,
						}}
					/>
				);
			}
			if (!loggedIn && !loadingUser) {
				return (
					<Redirect
						to={{
							pathname: `/event/${eventSlug}`,
						}}
					/>
				);
			}
			return <NotFound />;
		}}
	/>
);

const mapStateToProps = (state) => {
	return {
		user: state.user.data,
		event: state.event.data,
		loggedIn: state.user.loggedIn,
		loadingUser: state.user.loading,
		loadingEvent: state.event.loading,
		eventSlug: state.event.eventSlug,
	};
};

export default connect(mapStateToProps)(OrganizerRoute);
