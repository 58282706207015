import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import NotFound from '../../Pages/NotFound';
import Spinner from '../../SmallLayoutComponents/FullPageSpinner';
import EventPublicComponent from './EventPublicComponent';

const EventPrivateRoute = ({component: Component, loggedIn, user, loadingUser, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            const eventSlug = props.match.params.eventSlug;

            if (!loggedIn && loadingUser) {
                return <Spinner />;
            } else if (loggedIn && user) {
                return (
                    <EventPublicComponent {...props}>
                        <Component {...props} />
                    </EventPublicComponent>
                );
            } else if (!loggedIn && !loadingUser) {
                return (
                    <Redirect
                        to={{
                            pathname: `/event/${eventSlug}`,
                        }}
                    />
                );
            } else {
                return <NotFound />;
            }
        }}
    />
);

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        loadingUser: state.user.loading,
        loadingEvent: state.event.loading,
        eventSlug: state.event.eventSlug,
    };
};

export default connect(mapStateToProps)(EventPrivateRoute);
