import axios from '../axios-instance';
import * as actionTypes from './actionTypes';
import {NotificationManager} from "react-notifications";

/**
 * In this function we will also update in redux
 * The properties liveSessionUser and liveSessionActiveUsers
 * @param sessionId
 * @returns {function(*): Promise<AxiosResponse<any> | *>}
 */
export const getLiveSession = (sessionId) => {
    return (dispatch) => {
        dispatch(getLiveSessionStart());
        return axios
            .get(`/sessions/${sessionId}`)
            .then((response) => {
                const liveSession = response.data.session;
                dispatch(getLiveSessionSuccess(liveSession));
                return liveSession;
            })
            .catch((err) => dispatch(getLiveSessionFail()));
    };
};

export const getLiveSessionStart = (sessionId) => {
    return {
        type: actionTypes.GET_LIVE_SESSION_START,
        payload: sessionId
    };
};
export const getLiveSessionSuccess = (session) => {
    return {
        type: actionTypes.GET_LIVE_SESSION_SUCCESS,
        payload: session,
    };
};
export const getLiveSessionFail = () => {
    return {
        type: actionTypes.GET_LIVE_SESSION_FAIL,
    };
};


export const getLiveSessionUser = (sessionId) => {
    return (dispatch) => {
        dispatch(getLiveSessionUserStart());

        return axios
            .get(`/sessions/${sessionId}/profile`)
            .then((response) => {
                const liveSessionUser = response.data.profile;
                dispatch(getLiveSessionUserSuccess(liveSessionUser));
                return liveSessionUser;
            })
            .catch((err) => dispatch(getLiveSessionUserFail()));
    };
};

export const getLiveSessionUserStart = () => {
    return {
        type: actionTypes.GET_LIVE_SESSION_USER_START,
    };
};

export const getLiveSessionUserSuccess = (user) => {
    return {
        type: actionTypes.GET_LIVE_SESSION_USER_SUCCESS,
        payload: user,
    };
};
export const getLiveSessionUserFail = () => {
    return {
        type: actionTypes.GET_LIVE_SESSION_USER_FAIL,
    };
};


// remove user from session
export const removeSessionUser = (sessionId, userId) => {
    return (dispatch) => {

        return axios
            .post(`/sessions/${sessionId}/remove/${userId}`)
            .then((response) => {
                // we update the session data when we receive the socket notification
            })
            .catch((err) => {console.log(err)});
    };
};


// approve / kick user action, changes user.status
export const changeLiveSessionUserPermission = (sessionId, permissionType, userId) => {
    return (dispatch) => {

        return axios
            .post(`/sessions/${sessionId}/${permissionType}/${userId}`)
            .then((response) => {
                // we update the session data when we receive the socket notification
            })
            .catch((err) => {console.log(err)});
    };
};


// invite user on stage
export const inviteToStage = (sessionId, userId, userData) => {
    return (dispatch) => {

        return axios
            .post(`/sessions/${sessionId}/invite/${userId}`, userData)
            .then((response) => {
                // we update the session data when we receive the socket notification
                return response
            })
            .catch((err) => {
                NotificationManager.info(`Invitation failed for ${userData.name}. ${err.response.data.error}`);
                return err
            });
    };
};


// user accepts invitation on stage
export const invitationAccepted = (sessionId, userId) => {
    return (dispatch) => {

        return axios
            .post(`/sessions/${sessionId}/accept-invitation/${userId}`)
            .then((response) => {
                // we update the session data when we receive the socket notification
                return response
            })
            .catch((err) => {
            });
    };
};

// change user.allowSreenSharing, user.allowAudio, user.allowVideo liveSessionUser properties
export const changeLiveSessionUserAuthorization = (sessionId, userId, authorization) => {
    return (dispatch) => {
        return axios
            .post(`/sessions/${sessionId}/edit-user-permissions/${userId}`, authorization)
            .then((response) => {
                // we update the session data when we receive the socket notification
            })
            .catch((err) => {console.log(err)});
    };
};


// toggle whatever user is actually streaming (user.audio, user.video, user.screenSharing)
export const changeLiveSessionUserStream = (sessionId, permissionType, userId) => {
    return (dispatch) => {
        return axios
            .post(`/sessions/${sessionId}/${permissionType}/${userId}`)
            .then((response) => {
                // we update the session data when we receive the socket notification
            })
            .catch((err) => {console.log(err)});
    };
};

export const updateLiveSessionThumbnail = (sessionId, image) => {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('thumbnail', image, image.name, image.type);

        const config = {
            headers: {'Content-Type': 'multipart/form-data'},
        };

        return axios
            .post(`/sessions/${sessionId}/thumbnail`, formData, config)
            .then((response) => {
                const liveSession = response.data.session;
                dispatch(getLiveSessionSuccess(liveSession));
                return liveSession;
            })
            .catch((err) => {console.log(err)});
    }

};

export const deleteLiveSessionThumbnail = (sessionId) => {
    return (dispatch) => {
        return axios
            .delete(`/sessions/${sessionId}/thumbnail`)
            .then((response) => {
                const liveSession = response.data.session;
                dispatch(getLiveSessionSuccess(liveSession));
                return liveSession;
            })
            .catch((err) => {console.log(err)});
    }
};


export const sendLiveSessionChatMessage = (sessionId, text) => {
    return (dispatch) => {
        return axios
            .post(`/sessions/${sessionId}/chat`, {text: text})
            .then((response) => {

            })
            .catch((err) => {console.log(err)});
    };
};





export const startLiveSessionStream = (sessionId) => {
    return (dispatch) => {
        return axios
            .post(`/sessions/${sessionId}/stream`)
            .then((response) => {

            })
            .catch((err) => {console.log(err)});
    };
};


export const stopLiveSessionStream = (sessionId) => {
    return (dispatch) => {
        return axios
            .delete(`/sessions/${sessionId}/stream`)
            .then((response) => {

            })
            .catch((err) => {console.log(err)});
    };
};



export const setLiveSessionIsActive = (isActive) => {
    return {
        type: actionTypes.SET_LIVE_SESSION_IS_ACTIVE,
        payload: isActive,
    };
};

export const setLiveSessionRecords = (records) => {
    return {
        type: actionTypes.SET_LIVE_SESSION_RECORDS,
        payload: records,
    };
};


export const toggleCanRequestMic = (sessionId, canRequestMic) => {
    return (dispatch) => {
        return axios
            .post(`/sessions/${sessionId}/microphone`, {canRequestMic: canRequestMic})
            .then((response) => {

            })
            .catch((err) => {console.log(err)});
    };
};