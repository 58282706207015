import './VoteBannerStyles.scss';
import {Checkbox, Tooltip} from '@material-ui/core';
import {ReactComponent as Online} from './Helpers/svg/online-count-icon.svg';
import React, {useEffect, useState} from 'react';
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect';
import Spinner from 'SmallLayoutComponents/Spinner';
import {usePlatformTranslation} from 'services/hooks';
import {useSelector} from 'react-redux';
import {doesOrganizerHasParticipantProfile} from 'Utils/utils';

const VoteBanner = ({
    isVotingEnabled,
    exhibitor,
    showfloorId,
    votingPrivalages,
    votingData,
    handleChangeVote,
    votingText,
    voteLoading,
    exhibitorInProgress,
    singleExhibitorsVoteCount,
}) => {
    //local state
    const [isVoted, setIsVoted] = useState(false);

    //redux
    const voteText = usePlatformTranslation((state) => state?.voting?.voteText);
    const votedText = usePlatformTranslation((state) => state?.voting?.votedText);
    const votesText = usePlatformTranslation((state) => state?.voting?.votesText);
    const platformLanguage = useSelector((state) => state.languages?.platformLanguage);
    const toVotePleaseEnableParticipantProfile = usePlatformTranslation(
        (state) => state?.voting?.toVotePleaseEnableParticipantProfile
    );
    const userEventProfile = useSelector((state) => state.userEventProfile?.data);

    //get vote data
    const getShowfloorVoteData = () => {
        let isUserVoted = votingData?.userVotes?.find((exhibitorVote) => exhibitorVote === exhibitor?._id);
        setIsVoted(!!isUserVoted);
    };

    //get the number of votes
    useEffect(() => {
        getShowfloorVoteData();
    }, [showfloorId, exhibitor?._id, votingData]);
    return (
        <section
            className={`${isVotingEnabled ? 'enabled' : ''} vote-banner-container ${isMobile ? 'mobile' : ''} ${
                isTablet ? 'align-items-center' : ''
            }  ${platformLanguage === 'ar' ? 'direction-rtl' : ''}`}
        >
            <div className={`message-container ${isMobile ? 'mobile' : ''}`}>
                <span className={`message`}>{votingText}</span>

                {isMobileOnly && (
                    <span className={`vote-number`}>
                        {votingPrivalages && singleExhibitorsVoteCount && (
                            <>
                                <Online />
                                {(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id]) || 0}{' '}
                                {(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id] > 1) ||
                                (singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id] === 0)
                                    ? votesText
                                    : voteText}
                            </>
                        )}
                    </span>
                )}
            </div>

            <div className={`vote-actions`}>
                {!isMobileOnly && (
                    <span className={`vote-number`}>
                        {votingPrivalages && singleExhibitorsVoteCount && (
                            <>
                                <Online />
                                {(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id]) || 0}{' '}
                                {(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id] > 1) ||
                                (singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id] === 0)
                                    ? votesText
                                    : voteText}
                            </>
                        )}
                    </span>
                )}
                {doesOrganizerHasParticipantProfile(userEventProfile) ? (
                    <div className={`vote-action`} onClick={handleChangeVote && handleChangeVote(exhibitor?._id)}>
                        {voteLoading && exhibitorInProgress === exhibitor?._id && (
                            <div className={`spinner-container-vote vote-banner-top`}>
                                <Spinner />
                            </div>
                        )}
                        <Checkbox checked={isVoted ?? ''} />{' '}
                        <span className={`vote-text`}>{isVoted ? votedText : voteText}</span>
                    </div>
                ) : (
                    <Tooltip title={toVotePleaseEnableParticipantProfile} arrow>
                        <div className={`vote-action disabled`}>
                            {voteLoading && exhibitorInProgress === exhibitor?._id && (
                                <div className={`spinner-container-vote vote-banner-top`}>
                                    <Spinner />
                                </div>
                            )}
                            <Checkbox checked={isVoted ?? ''} disabled />{' '}
                            <span className={`vote-text`}>{isVoted ? votedText : voteText}</span>
                        </div>
                    </Tooltip>
                )}
            </div>
        </section>
    );
};
export default VoteBanner;
