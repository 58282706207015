import {Accordion, AccordionDetails, CircularProgress} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {fireClickEvent, preventDefaultDrag} from "../../../Utils/utils";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PublishIcon from "@material-ui/icons/Publish";
import {verifyFileType} from "../../../Utils/verifyFileType";
import UploadWindowViewImage from "./UploadWindowViewImage";
import {getEventBrandingSuccess, reloadEventData} from 'store/actions';
import {useDispatch, useSelector} from "react-redux";
import axios from '../../../store/axios-instance';
import Confirm from "../../../Dialogs/Confirm";

const WindowView = ({imageLeft, imageRight, filesUrl}) => {

    const [expand, setExpand] = useState(false);
    const [loadingLeft, setIsLoadingLeft] = useState(false);
    const [loadingRight, setIsLoadingRight] = useState(false);

    const [photoSidesArray, setPhotoSidesArray] = useState([]);


    const [navigationElement, setNavigationElement] = useState(null);
    const [openConfirmUnsavedChanges, setOpenConfirmUnsavedChanges] = useState(false);

    const [leftImagePreviewUrl, setLeftImagePreviewUrl] = useState(null);
    const [rightImagePreviewUrl, setRightImagePreviewUrl] = useState(null);

    const [leftWindowViewImageErrorText, setLeftWindowViewImageErrorText] = useState('');
    const [rightWindowViewImageErrorText, setRightWindowViewImageErrorText] = useState('');

    const [newLeftWindowViewImage, setNewLeftWindowViewImage] = useState(null);
    const [newRightWindowViewImage, setNewRightWindowViewImage] = useState(null);

    const [leftWindowViewWarningText, setLeftWindowViewWarningText] = useState(null);
    const [rightWindowViewWarningText, setRightWindowViewWarningText] = useState(null);

    const eventId = useSelector((state) => state.event.eventId);

    const wrapperRef = useRef();
    const dispatch = useDispatch();

    const toggleAccordion = () => {
        setExpand((prev) => !prev);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        wrapperRef,
        openConfirmUnsavedChanges,
        leftImagePreviewUrl,
        rightImagePreviewUrl,
        leftWindowViewImageErrorText,
        rightWindowViewImageErrorText,
        newLeftWindowViewImage,
        newRightWindowViewImage,
    ]);

    const getNewNavigationElement = useCallback(
        (e) => {
            const isEventTargetNavigationElement = e.path?.find((pathElem) =>
                pathElem.getAttribute?.('data-is-navigation')
            );

            if (openConfirmUnsavedChanges) {
                return navigationElement;
            }

            if (isEventTargetNavigationElement) {
                return e.target;
            }

            return null;
        },
        [navigationElement, openConfirmUnsavedChanges]
    );

    const handleClickOutside = (e) => {
        const isClickOutsideTab = wrapperRef && !wrapperRef?.current?.contains(e.target);

        const sidesArray = [];
        if (leftImagePreviewUrl) {
            sidesArray.push('leftSide');
        }

        if (rightImagePreviewUrl) {
            sidesArray.push('rightSide');
        }

        setPhotoSidesArray(sidesArray);

        if (isClickOutsideTab && !openConfirmUnsavedChanges) {
            if ((leftImagePreviewUrl && !leftWindowViewImageErrorText.length > 0 && newLeftWindowViewImage !== null) ||
                (rightImagePreviewUrl && !rightWindowViewImageErrorText.length > 0 && newRightWindowViewImage !== null)) {
                setOpenConfirmUnsavedChanges(true);
                setNavigationElement(getNewNavigationElement(e));
            }
        }

    };


    const handleImageChange = (photoSide) => (e) => {
        e.preventDefault();
        const leftWindowWidthAndHeight = 2500;
        const rightWindowHeight = 2500;
        const rightWindowWidth = 2800;
        let reader = new FileReader();
        let file = e.target.files[0];

        let isValid = true;

        if (!file) {
            return;
        }
        isValid = file.size < 10 * 1024 * 1024 && isValid;

        if (!isValid) {
            if (photoSide === 'leftSide') {
                setLeftImagePreviewUrl(null);
                setLeftWindowViewImageErrorText('File too large. 10Mb max file size.');
            } else {
                setRightImagePreviewUrl(null);
                setRightWindowViewImageErrorText('File too large. 10Mb max file size.');
            }
        }

        const typeValid = verifyFileType(file.type, 'image');
        isValid = typeValid && isValid;
        if (!typeValid) {
            if (photoSide === 'leftSide') {
                setLeftImagePreviewUrl(null);
                setLeftWindowViewImageErrorText('File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.');
            } else {
                setRightImagePreviewUrl(null);
                setRightWindowViewImageErrorText('File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.')
            }
        }

        //left side photo -> 2500px x 2500px
        if (isValid && photoSide === 'leftSide') {
            reader.onload = (e) => {
                let img = new Image();
                img.src = e.target.result;
                img.onload = function () {
                    const imageWidth = this.width;
                    const imageHeight = this.height;

                    if (imageHeight === leftWindowWidthAndHeight && imageWidth === leftWindowWidthAndHeight) {
                        setLeftWindowViewWarningText('');
                    } else {
                        setLeftWindowViewWarningText('Warning: image does not have the recommended ratio.');
                    }
                }
            }
        }

        //right side photo -> 2800px x 2500px
        if (isValid && photoSide === 'rightSide') {
            reader.onload = (e) => {
                let img = new Image();
                img.src = e.target.result;
                img.onload = function () {
                    const imageWidth = this.width;
                    const imageHeight = this.height;

                    if (imageHeight === rightWindowHeight && imageWidth === rightWindowWidth) {
                        setRightWindowViewWarningText('');
                    } else {
                        setRightWindowViewWarningText('Warning: image does not have the recommended ratio.');
                    }
                }
            }
        }

        reader.onloadend = () => {
            if (isValid) {
                if (photoSide === 'leftSide') {
                    setNewLeftWindowViewImage(file);
                    setLeftImagePreviewUrl(reader.result);
                    setLeftWindowViewImageErrorText('');
                } else {
                    setNewRightWindowViewImage(file);
                    setRightImagePreviewUrl(reader.result);
                    setRightWindowViewImageErrorText('')
                }

            }
        }
        reader.readAsDataURL(file);
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    }
    const ImageLeft = () => {
        if (loadingLeft) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <CircularProgress color="primary"/>
                </div>
            )
        }
        if (leftImagePreviewUrl) {
            return (
                <>
                    <img
                        draggable="false"
                        onDragStart={preventDefaultDrag}
                        src={leftImagePreviewUrl}
                        alt="preview"
                    />
                </>
            )
        }
        if (imageLeft) {
            return (
                <>
                    <img
                        draggable="false"
                        onDragStart={preventDefaultDrag}
                        src={`${filesUrl}${imageLeft}`}
                        alt="preview"
                    />
                </>
            )
        }


        return(
            <label
                tabIndex="0"
                htmlFor="left-window-input-upload"
                className="upload-label banner-upload"
            >
                <PublishIcon />
                <span>Click here to upload</span>
            </label>
        )
    }

        const ImageRight = () => {

            if(loadingRight){
                return(
                    <div className="d-flex align-items-center justify-content-center">
                        <CircularProgress color="primary"/>
                    </div>
                )
            }
            if(rightImagePreviewUrl) {
                return(
                    <>
                        <img
                            draggable="false"
                            onDragStart={preventDefaultDrag}
                            src={rightImagePreviewUrl}
                            alt="preview"
                        />
                    </>
                )
            }
            if (imageRight) {
                return (
                    <>
                        <img
                            draggable="false"
                            onDragStart={preventDefaultDrag}
                            src={`${filesUrl}${imageRight}`}
                            alt="preview"
                        />
                    </>
                )
            }

        return(
            <label
                tabIndex="0"
                htmlFor="right-window-input-upload"
                className="upload-label banner-upload"
            >
                <PublishIcon />
                <span>Click here to upload</span>
            </label>
        )
    }

    const closeClickOutside = () => {setOpenConfirmUnsavedChanges(false)}

    const handleDiscardChanges = () => {
        setOpenConfirmUnsavedChanges(false);
        if (leftImagePreviewUrl) {
            setNewLeftWindowViewImage(null);
            setLeftImagePreviewUrl(null);
            setLeftWindowViewImageErrorText('');
            setLeftWindowViewWarningText('');
            setIsLoadingLeft(false);
        } else if (rightImagePreviewUrl) {
            setNewRightWindowViewImage(null);
            setRightImagePreviewUrl(null);
            setRightWindowViewImageErrorText(null);
            setRightWindowViewWarningText('');
            setIsLoadingRight(false);
        }

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    }

    const updateWindowViewImage = (photoSidesArray) => () => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        let formData;

        for (let photoSide of photoSidesArray) {
            if (photoSide === 'leftSide') {
                setIsLoadingLeft(true);
                formData = new FormData();
                formData.append('brandingProperty', photoSide);
                formData.append('image', newLeftWindowViewImage, newLeftWindowViewImage.name, newLeftWindowViewImage.type);
            }
            if (photoSide === 'rightSide') {
                setIsLoadingRight(true);
                formData = new FormData();
                formData.append('brandingProperty', photoSide);
                formData.append('image',newRightWindowViewImage, newRightWindowViewImage.name, newRightWindowViewImage.type)
            }


            axios({method: 'post', url: `/event/${eventId}/branding/upload-window-view-image`, data: formData, config})
                .then((response) => {
                    const brandingData = response.data.data.brandingData;
                    if (brandingData) {
                        dispatch(getEventBrandingSuccess(brandingData));
                        dispatch(reloadEventData(eventId));
                    }

                    if (photoSide === 'leftSide') {
                        setNewLeftWindowViewImage(null);
                        setLeftImagePreviewUrl(null);
                        setOpenConfirmUnsavedChanges(false);
                        setIsLoadingLeft(false);
                        if (navigationElement) {
                            fireClickEvent(navigationElement);
                        }
                    }
                    if (photoSide === 'rightSide') {
                        setNewRightWindowViewImage(null);
                        setRightImagePreviewUrl(null);
                        setOpenConfirmUnsavedChanges(false);
                        setIsLoadingRight(false);
                        if (navigationElement) {
                            fireClickEvent(navigationElement);
                        }
                    }
                })
                .catch(() => {
                    setOpenConfirmUnsavedChanges(false);
                    if (photoSide === 'leftSide') {
                        setIsLoadingLeft(false);
                    }

                    if (photoSide === 'rightSide') {
                        setIsLoadingRight(false);
                    }
                })
        }
    };

    const removeWindowViewImage = (photoSide) => () => {
        let params = {
            photoSide: photoSide
        }
        axios({method: 'delete', url: `/event/${eventId}/branding/delete-window-view-image`, params})
            .then((response) => {
                const brandingData = response.data.data.brandingData;
                dispatch(getEventBrandingSuccess(brandingData))
                dispatch(reloadEventData(eventId))
                if (photoSide === 'leftSide') {
                    setLeftWindowViewWarningText('');
                    setLeftWindowViewImageErrorText('');
                }

                if (photoSide === 'rightSide') {
                    setRightWindowViewWarningText('');
                    setLeftWindowViewImageErrorText('');
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    return(
        <div className={'window-view-container'} ref={wrapperRef}>
            <Accordion elevation={0} expanded={expand}>
                <AccordionSummary>
                    <div className={'accordion-title-details'}>
                        <p className="title">
                            WINDOW VIEW
                        </p>
                        <div onClick={toggleAccordion}>
                            {expand ? <Button
                                type="label"
                                startIcon={<ExpandLessIcon />}
                            >
                                COLLAPSE
                            </Button> : <Button
                                type="label"
                                startIcon={<ExpandMoreIcon />}
                            >
                                EXPAND
                            </Button>}
                        </div>

                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={'window-view-upload-container'}>
                        <div className={'upload-window-container'}>
                            <div className={'upload-window'}>
                                <p>
                                    Left window
                                </p>
                                <div className="actions-container-window-view">
                                    <UploadWindowViewImage
                                        image={imageLeft}
                                        newWindowViewImage={newLeftWindowViewImage}
                                        windowViewImagePreviewUrl={leftImagePreviewUrl}
                                        updateWindowViewImage={updateWindowViewImage(['leftSide'])}
                                        windowViewImageErrorText={leftWindowViewImageErrorText}
                                        handleImageChange={handleImageChange('leftSide')}
                                        removeWindowViewImage={removeWindowViewImage('leftSide')}
                                    />

                                </div>
                                <div onDragStart={preventDefaultDrag} className="image-wrapper left-image-wrapper">
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="current-image-container window-img-container"
                                    >
                                        {ImageLeft()}
                                    </div>
                                </div>
                                <input
                                    id="left-window-input-upload"
                                    type="file"
                                    onChange={handleImageChange('leftSide')}
                                    className="upload-image-button d-none"
                                />
                            </div>


                            <div className={'upload-window right-upload-window'}>
                                <p>
                                    Right window
                                </p>

                                <div className="actions-container-window-view">
                                    <UploadWindowViewImage
                                        image={imageRight}
                                        newWindowViewImage={newRightWindowViewImage}
                                        windowViewImagePreviewUrl={rightImagePreviewUrl}
                                        updateWindowViewImage={updateWindowViewImage(['rightSide'])}
                                        windowViewImageErrorText={rightWindowViewImageErrorText}
                                        handleImageChange={handleImageChange('rightSide')}
                                        removeWindowViewImage={removeWindowViewImage('rightSide')}
                                    />
                                </div>

                                <div onDragStart={preventDefaultDrag} className="image-wrapper">
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="current-image-container window-img-container"
                                    >
                                        {ImageRight()}
                                    </div>
                                </div>
                                <input
                                    id="right-window-input-upload"
                                    type="file"
                                    onChange={handleImageChange('rightSide')}
                                    className="upload-image-button d-none"
                                />

                            </div>
                        </div>
                        <div className={'window-view-upload-details'}>
                            <div className={'recommended-description'} style={{marginTop: '110px'}}>
                                <p>
                                    Upload an image that will be displayed as a background for the window view in the lobby.
                                </p>
                                <p className={'mb-0'}>
                                    The recommended sizes would be:
                                </p>
                                <p className={'m-0'}>- 2500 px width and 2500 px height for the left window</p>
                                <p className={'m-0'}>- 2800 px width and 2500 px height for the right window</p>
                                <p className={'mtb-24'}>
                                    <span className={'window-view-tip'}>TIP</span>: try to use a bit blur images to achieve a more natural effect
                                </p>
                            </div>
                            <div className={'pictures-names window-view-images-status-container'}>
                                <p className={'picture-left-name mt-0'}>
                                    {newLeftWindowViewImage || (leftWindowViewImageErrorText.length > 0 && imageLeft) ? (
                                        <span className={'window-view-tip'}>
                                            Uploaded left window: {' '}
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="secondary-color">
                                                {leftWindowViewImageErrorText.length > 0 ? (
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="error-light"
                                                    >
                                                                Error
                                                            </span>
                                                ) : (
                                                    newLeftWindowViewImage.name
                                                )}
                                            </span>
                                        </span>
                                    ) : null}
                                    {!newLeftWindowViewImage && !imageLeft ? (
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="window-view-tip">
                                            Uploaded left window:{' '}
                                            {leftWindowViewImageErrorText.length > 0 ? (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="error-light"
                                                >
                                                            Error
                                                        </span>
                                            ) : (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="grey-color"
                                                >
                                                            no file chosen
                                                        </span>
                                            )}
                                        </span>
                                    ) : null}
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="error-message"
                                    >
                                                {leftWindowViewImageErrorText}
                                    </span>
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="error-message"
                                    >
                                                {leftWindowViewWarningText}
                                    </span>

                                </p>

                                <p className={'picture-right-name m-0'}>
                                    {newRightWindowViewImage || (rightWindowViewImageErrorText.length > 0 && imageRight) ? (
                                        <span className={'window-view-tip'}>
                                            Uploaded right window: {' '}
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="secondary-color">
                                                {rightWindowViewImageErrorText.length > 0 ? (
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="error-light"
                                                    >
                                                                Error
                                                            </span>
                                                ) : (
                                                    newRightWindowViewImage.name
                                                )}
                                            </span>
                                        </span>
                                    ) : null}
                                    {!newRightWindowViewImage && !imageRight ? (
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="window-view-tip">
                                            Uploaded right window:{' '}
                                            {rightWindowViewImageErrorText.length > 0 ? (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="error-light"
                                                >
                                                            Error
                                                        </span>
                                            ) : (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="grey-color"
                                                >
                                                            no file chosen
                                                        </span>
                                            )}
                                        </span>
                                    ) : null}
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="error-message"
                                    >
                                                {rightWindowViewImageErrorText}
                                    </span>
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="error-message"
                                    >
                                                {rightWindowViewWarningText}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                </AccordionDetails>

            </Accordion>
            {openConfirmUnsavedChanges && (
                <Confirm
                    open={openConfirmUnsavedChanges}
                    closeConfirm={() => setOpenConfirmUnsavedChanges(false)}
                    dialogTitle={'Unsaved changes'}
                    dialogDescription={'You have unsaved changes. Do you want to save them?'}
                    dialogConfirmButtonLabel={'Save'}
                    dialogCancelButtonLabel={'Cancel'}
                    handleConfirm={updateWindowViewImage(photoSidesArray)}
                    handleDiscardChanges={handleDiscardChanges}
                />
            )}
        </div>
    )
}

export default WindowView;