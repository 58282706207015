import React, {useEffect, useState} from 'react';
import {preventDefaultDrag} from 'Utils/utils';
import MainLogo from 'SmallLayoutComponents/MainLogo';
import Button from '@material-ui/core/Button';
import {ExpandLessRounded} from '@material-ui/icons';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {ListItemText, Menu, MenuItem} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser, removeStripeSecretFromUser, setNoMenu} from 'store/actions';
import DeleteMyAccount from 'Dialogs/MyAccount/DeleteMyAccount';
import ConfirmDialog from 'Dialogs/Confirm';
import {usePlatformTranslation} from 'services/hooks';
import axios from '../../../store/axios-instance';
import {useHistory} from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {isMobileOnly} from 'react-device-detect';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarGlobal from '../../../SmallLayoutComponents/Snackbars/SnackbarGlobal';

const Header = ({privacyPolicyNotifications}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [privacyPolicyEventName, setPrivacyPolicyEventName] = useState('');

    const user = useSelector((state) => state.user.data) || null;
    const eventRoles = useSelector((state) => state.user.eventRoles) || [];
    const loggedIn = useSelector((state) => state.user.loggedIn) || false;
    const eventId = useSelector((state) => state.event.eventId) || null;
    const eventSlug = useSelector((state) => state.event.eventSlug) || null;

    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteMyAccountDialog, setDeleteMyAccountDialog] = useState(false);
    const [unregisterFromEventDialog, setUnregisterFromEventDialog] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const unregisterFromEventDialogTitle = usePlatformTranslation(
        (state) => state.myAccountPage.unregisterFromEventDialogTitle
    );
    const unregisterCurrentEventButton2 = usePlatformTranslation(
        (state) => state.myAccountPage.unregisterCurrentEventButton2
    );
    const unregisterCurrentEventConfirmation2 = usePlatformTranslation(
        (state) => state.myAccountPage.unregisterCurrentEventConfirmation2
    );
    const cancelText = usePlatformTranslation((state) => state.generalText.cancel);
    const learnMore = usePlatformTranslation((state) => state.privacyPolicyUpdates.learnMore);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose =
        (callFunction = () => null) =>
        () => {
            setAnchorEl(null);
            callFunction();
        };

    useEffect(() => {
        dispatch(setNoMenu());
    }, []);

    useEffect(() => {
        const ppu = privacyPolicyNotifications.find((ppu) => ppu?.event?.slug === eventSlug);
        setPrivacyPolicyEventName(ppu?.event?.name);
    }, [privacyPolicyNotifications]);

    const handleOpenUnregisterFromEventDialog = () => {
        setUnregisterFromEventDialog(true);
    };

    const handleCloseUnregisterFromEventDialog = () => {
        setUnregisterFromEventDialog(false);
    };

    const handleLogout = () => {
        dispatch(logoutUser());
        if (eventSlug) {
            window.location.href = `/event/${eventSlug}`;
        } else {
            window.location.href = '/';
        }
    };

    const handleDeleteAccount = () => {
        setDeleteMyAccountDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteMyAccountDialog(false);
    };

    const unregisterFromEvent = () => {
        axios({method: 'delete', url: `/event/v2/${eventId}/unregister-user/${user._id}`})
            .then(() => {
                dispatch(removeStripeSecretFromUser());
                window.location.href = '/';
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackbarMessage('Something went wrong, please try again later');
            });
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const shouldDisplayUnregisterButton = () => {
        // check if the current user is registered in the current event
        const userHasRoleInEvent = user?.eventRoles.some((roles) => roles.event.slug === eventSlug);

        // check if in the current privacy policy updates there is at least one PPU from the current event
        const hasEventPrivacyPolicyUpdate = privacyPolicyNotifications.some((ppu) => ppu?.event?.slug === eventSlug);

        return userHasRoleInEvent && hasEventPrivacyPolicyUpdate;
    };

    return (
        <div
            className="user-consent-collection-updates-page--header d-flex justify-content-between align-items-center"
            onDragStart={preventDefaultDrag}
        >
            <a href={'/'}>
                <MainLogo colored={true} />
            </a>
            {isMobileOnly ? (
                <IconButton
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    onClick={handleClick}
                    type="button"
                    size={'small'}
                >
                    <MoreVertIcon />
                </IconButton>
            ) : (
                <Button
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    type="button"
                    endIcon={anchorEl ? <ExpandLessRounded /> : <ExpandMoreRoundedIcon />}
                >
                    {learnMore}
                </Button>
            )}

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose()}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                className={'color-secondary user-consent-collection-updates-page--header--menu'}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemText primary={`Logout`} />
                </MenuItem>

                {shouldDisplayUnregisterButton() && (
                    <MenuItem onClick={handleOpenUnregisterFromEventDialog}>
                        <ListItemText primary={`Unregister`} />
                    </MenuItem>
                )}

                <MenuItem key={0} onClick={handleDeleteAccount}>
                    <ListItemText primary={`Delete account`} />
                </MenuItem>
            </Menu>
            {loggedIn && deleteMyAccountDialog && (
                <DeleteMyAccount opened={deleteMyAccountDialog} closeDialog={handleCloseDeleteDialog} />
            )}
            {loggedIn && (
                <ConfirmDialog
                    open={unregisterFromEventDialog}
                    closeConfirm={handleCloseUnregisterFromEventDialog}
                    dialogTitle={`${unregisterFromEventDialogTitle} ${privacyPolicyEventName}`}
                    dialogDescription={`${unregisterCurrentEventConfirmation2}`}
                    handleConfirm={unregisterFromEvent}
                    dialogCancelButtonLabel={`${cancelText}`}
                    dialogConfirmButtonLabel={`${unregisterCurrentEventButton2}`}
                    classList={['unregister-dialog']}
                />
            )}

            <SnackbarGlobal
                message={snackbarMessage}
                snackbarOpen={snackbarOpen}
                handleCloseSnackbar={handleCloseSnackbar}
            />
        </div>
    );
};

export default Header;
