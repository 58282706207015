import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    loading: false,
    privacyPolicyNotifications: [],
};

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.PRIVACY_POLICY_NOTIFICATIONS_API_CALL_START:
                draft.loading = true;
                break;

            case actionTypes.PRIVACY_POLICY_NOTIFICATIONS_API_CALL_FAIL:
                draft.loading = false;
                break;

            case actionTypes.GET_PRIVACY_POLICY_NOTIFICATIONS_SUCCESS:
                draft.loading = false;
                draft.privacyPolicyNotifications = action.payload;
                break;

            case actionTypes.SAVE_PRIVACY_POLICY_NOTIFICATION_SUCCESS:
                draft.loading = false;
                draft.privacyPolicyNotifications.unshift(action.payload);
                break;

            case actionTypes.UPDATE_PRIVACY_POLICY_NOTIFICATION_SUCCESS:
                draft.loading = false;
                const updatedPrivacyPolicyNotification = action.payload;
                const updatedPrivacyPolicyNotificationIndex = draft.privacyPolicyNotifications.findIndex(
                    privacyPolicyNotification => privacyPolicyNotification._id === updatedPrivacyPolicyNotification._id
                );
                if(updatedPrivacyPolicyNotificationIndex !== -1) {
                    draft.privacyPolicyNotifications[updatedPrivacyPolicyNotificationIndex] = updatedPrivacyPolicyNotification;
                }
                break;

            case actionTypes.DELETE_PRIVACY_POLICY_NOTIFICATION_SUCCESS:
                draft.loading = false;
                const deletedPrivacyPolicyNotificationId = action.payload;
                const deletedPrivacyPolicyNotificationIndex = draft.privacyPolicyNotifications.findIndex(
                    privacyPolicyNotification => privacyPolicyNotification._id === deletedPrivacyPolicyNotificationId
                );
                if(deletedPrivacyPolicyNotificationIndex !== -1) {
                    draft.privacyPolicyNotifications.splice(deletedPrivacyPolicyNotificationIndex, 1);
                }
                break;

            default:
                break;
        }
    });

export default reducer;
