import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import axios from '../store/axios-instance';
import {preventDefaultDrag} from '../Utils/utils';

class RecoverPassword extends React.Component {
    state = {
        email: '',
        disabled: true,
    };

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleRecoverPassword = () => {
        axios({method: 'post', url: '/auth/recover-password', data: {email: this.state.email}})
            .then((response) => {
                // this.props.onRefreshUserUserData();
                this.props.closeDialog(
                    `${
                        this.props.languages.translations[this.props.languages.eventLanguage].recoverPassword
                            .recoverTextSend
                    }`
                );
            })
            .catch((error) => {
                this.props.closeDialog(
                    `${
                        this.props.languages.translations[this.props.languages.eventLanguage].recoverPassword
                            .recoverTextSend
                    }`
                );
            });
    };

    render() {
        const {translation} = this.props;
        return (
            <Dialog
                open={this.props.opened}
                onClose={() => this.props.closeDialog('')}
                aria-labelledby="form-dialog-title"
                className="booth-dialog recover-password-dialog"
            >
                <DialogContent className="booth-dialog-content booth-video-dialog-container">
                    <DialogTitle id="form-dialog-title">{translation?.login.recoverPasswordDialogTitle}</DialogTitle>
                    <ValidatorForm ref="form" onSubmit={this.handleRecoverPassword}>
                        <div onDragStart={preventDefaultDrag} className="field-container">
                            <TextValidator
                                label={translation?.login.inputEmail}
                                onChange={this.handleChange}
                                name="email"
                                value={this.state.email}
                                variant="outlined"
                                fullWidth
                                validators={['required', 'isEmail']}
                                errorMessages={[
                                    `${translation?.errors.required}`,
                                    `${translation?.errors.emailNotValid}`,
                                ]}
                            />
                        </div>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions className="buttons-actions">
                    <Button
                        type="button"
                        onClick={() => this.props.closeDialog('')}
                    >
                        {translation?.generalText.close}
                    </Button>
                    <Button
                        type="button"
                        onClick={this.handleRecoverPassword}
                        disabled={this.state.disabled}
                    >
                        {translation?.login.recoverPasswordDialogRecoverButton}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
