import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Confirm from '../../../../Dialogs/Confirm';
import { ReactComponent as UploadIcon } from '../../../../Images/svg/upload-file.svg';
import { deleteSessionThumbnail, updateSessionThumbnail } from '../../../../store/actions';
import {fireClickEvent} from 'Utils/utils';
import { verifyFileType } from 'Utils/verifyFileType';
import './thumbnailImage.scss';

const ThumbnailImage = () => {
    const dispatch = useDispatch();
    const [newImageFile, setNewImageFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [imageError, setImageError] = useState('');
    const [openConfirmUnsavedChanges, setOpenConfirmUnsavedChanges] = useState(false);
    const [navigationElement, setNavigationElement] = useState(null);

    const session = useSelector((state) => state.sessions.zoomSessionDashboard.session);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const routeParams = useParams();
    const {sessionId} = routeParams;

    const getNewNavigationElement = (e) => {
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    const handleUserClick = (e) => {
        const parentElementHasPreventConfirmSaveChangesPopupClass = e.target.closest('.preventConfirmSaveChangesPopup');
        if (!parentElementHasPreventConfirmSaveChangesPopupClass) {
            if (newImageFile && !imageError) {
                setOpenConfirmUnsavedChanges(true);
                setNavigationElement(getNewNavigationElement(e));
            }
        }
    };

    const closeClickOutside = () => {
        setOpenConfirmUnsavedChanges(false);
    };

    useEffect(() => {
        window.addEventListener('mousedown', handleUserClick);
        return () => {
            window.removeEventListener('mousedown', handleUserClick);
        };
    });

    useEffect(() => {
        if (session?.thumbnail) {
            setImagePreviewUrl(`${process.env.REACT_APP_SESSION_FOLDER}${session.thumbnail}`);
            setNewImageFile('');
        } else {
            setImagePreviewUrl('');
            setNewImageFile('');
        }
    }, [session]);

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;
        if (!file) {
            return;
        }

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        const typeValid = verifyFileType(file.type, 'image');
        if (file) {
            if (!isValid) {
                setImagePreviewUrl(null);
                setImageError('File too large. 2Mb max file size.');
            }
            if (!typeValid) {
                setImagePreviewUrl(null);
                setImageError('File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.');
            }
            isValid = typeValid && isValid;

            reader.onloadend = () => {
                if (isValid) {
                    setNewImageFile(file);
                    setImagePreviewUrl(reader.result);
                    setImageError('');
                }
            };

            reader.readAsDataURL(file);
        }
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    const handleSetImage = async () => {
        await dispatch(updateSessionThumbnail(session._id, newImageFile));
        setOpenConfirmUnsavedChanges(false);

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    const handleDiscardChanges = () => {
        closeClickOutside();

        if (session?.thumbnail) {
            setImagePreviewUrl(`${process.env.REACT_APP_SESSION_FOLDER}${session.thumbnail}`);
            setNewImageFile('');
        } else {
            setImagePreviewUrl('');
            setNewImageFile('');
        }

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    const handleDeleteImage = async () => {
        await dispatch(deleteSessionThumbnail(session._id));
    };

    return (
        <div className="module session-thumbnail-image">
            <div className="module-container">
                <div className="module-header">
                    <span className='text-uppercase'>{translation?.sessions.thumbnailImage || defaultTranslation?.sessions.thumbnailImage}</span>
                </div>
                <div className="module-body">
                    <input
                        id={`thumbnailImage${sessionId}`}
                        type="file"
                        className="upload-image-button d-none"
                        onChange={handleImageChange}
                    />
                    <div className="image-container">
                        {!imagePreviewUrl && (
                            <label className="upload-label" htmlFor={`thumbnailImage${sessionId}`}>
                                <UploadIcon />
                                <span>{translation?.sessions.clickToUpload || defaultTranslation?.sessions.clickToUpload}</span>
                            </label>
                        )}
                        {imagePreviewUrl && (
                            <img
                                draggable="false"
                                onDragStart={(e) => e.preventDefault()}
                                src={imagePreviewUrl}
                                alt="preview"
                            />
                        )}
                    </div>

                    <div className="buttons-container">
                        {session?.thumbnail && !newImageFile && (
                            <Button
                                type="button"
                                onClick={handleDeleteImage}
                                disabled={!session?.thumbnail ? true : false}
                            >
                                <span>{translation?.myAccountPage.removeImage || defaultTranslation?.myAccountPage.removeImage}</span>
                            </Button>
                        )}
                        {newImageFile && (
                            <Button
                                type="button"
                                onClick={handleSetImage}
                                disabled={!imagePreviewUrl || imageError ? true : false}
                            >
                                <span>{translation?.sessions.saveImage || defaultTranslation?.sessions.saveImage}</span>
                            </Button>
                        )}
                        {imageError && <p className='error-text'>{imageError}</p>}
                        {imagePreviewUrl && (
                            <Button type="button">
                                <label className="upload-label" htmlFor={`thumbnailImage${sessionId}`}>
                                    <span>{translation?.sessions.edit || defaultTranslation?.sessions.edit}</span>
                                </label>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            {openConfirmUnsavedChanges && (
                <Confirm
                    open={openConfirmUnsavedChanges}
                    closeConfirm={closeClickOutside}
                    dialogTitle={'Unsaved changes'}
                    dialogDescription={'You have unsaved changes. Do you want to save them?'}
                    dialogConfirmButtonLabel={'Save'}
                    dialogCancelButtonLabel={'Cancel'}
                    handleConfirm={handleSetImage}
                    handleDiscardChanges={handleDiscardChanges}
                />
            )}
        </div>
    );
};

export default ThumbnailImage;
