import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import * as actions from '../../../../../store/actions';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkIcon from '@material-ui/icons/Link';
import axios from '../../../../../store/axios-instance';
import { isFacebookLinkRule, isLinkedinLinkRule, isTwitterLinkRule, isLinkRule } from './../../../../../Utils/validationRules';
import {fireClickEvent, hasOnlyEmptySpaces} from 'Utils/utils';
import Confirm from '../../../../../Dialogs/Confirm'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Spinner from "../../../../../SmallLayoutComponents/Spinner";

class AddEditExhibitorForm extends React.Component {
    formRef = React.createRef();
    wrapperRef = React.createRef();
    handleClickOutside = this.handleClickOutside.bind(this);
    componentDidMount() {
        if (this.props.exhibitorId) {
            this.setExhibitorData();
        }
        ValidatorForm.addValidationRule('isFacebookLink', (value) => {
            let rule = isFacebookLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLinkedinLink', (value) => {
            let rule = isLinkedinLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isTwitterLink', (value) => {
            let rule = isTwitterLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isWebPageUrl', (value) => {
            let rule = isLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('onlyEmptySpaces', value => hasOnlyEmptySpaces(value));
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isFacebookLink');
        ValidatorForm.removeValidationRule('isLinkedinLink');
        ValidatorForm.removeValidationRule('isTwitterLink');
        ValidatorForm.removeValidationRule('isWebPageUrl');
        ValidatorForm.removeValidationRule('onlyEmptySpaces');
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    state = {
        fields: [
            {
                name: 'name',
                value: '',
                label: 'Exhibitor Name',
                multiline: 0,
                validators: ['minStringLength: 1', 'maxStringLength: 50', 'onlyEmptySpaces'],
                errorMessages: ['Name is required', 'You have reached the maximum limit of characters allowed (50 characters)', 'Field is required'],
            },
            {
                name: 'description',
                value: '',
                label: 'Exhibitor Description',
                multiline: 30,
                validators: ['maxStringLength: 1000'],
                errorMessages: ['You have reached the maximum limit of characters allowed (1000 characters)'],
            },
            {
                name: 'facebook',
                value: '',
                label: 'Facebook',
                adornment: <FacebookIcon />,
                multiline: 0,
                validators: ['isFacebookLink'],
                errorMessages: ['Please enter a valid link (https://facebook.com/)'],
            },
            {
                name: 'linkedin',
                value: '',
                label: 'Linkedin',
                adornment: <LinkedInIcon />,
                multiline: 0,
                validators: ['isLinkedinLink'],
                errorMessages: ['Please enter a valid link (https://linkedin.com/)'],
            },
            {
                name: 'twitter',
                value: '',
                label: 'Twitter',
                adornment: <TwitterIcon />,
                multiline: 0,
                validators: ['isTwitterLink'],
                errorMessages: ['Please enter a valid link (https://twitter.com/)'],
            },
            {
                name: 'webpage',
                value: '',
                label: 'Webpage',
                adornment: <LinkIcon />,
                multiline: 0,
                validators: ['isWebPageUrl'],
                errorMessages: ['Please enter a valid link (https://example.com/)'],
            },
        ],
        buttonDisabled: true,
        openConfirmUnsavedChanges: false,
        navigationElement: null,
        loading: false,
    };

    setExhibitorData = () => {
        const exhibitor = this.props.exhibitors.find((exhibitor) => exhibitor._id === this.props.exhibitorId);
        this.setState({
            fields: [
                {...this.state.fields[0], value: exhibitor.name},
                {...this.state.fields[1], value: exhibitor.description},
                {...this.state.fields[2], value: exhibitor.facebook},
                {...this.state.fields[3], value: exhibitor.linkedin},
                {...this.state.fields[4], value: exhibitor.twitter},
                {...this.state.fields[5], value: exhibitor.webpage},
            ],
        });
    };

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields}, this.validateSponsorForm);
    };

    validateSponsorForm = async () => {
        const {fields} = this.state;
        const {exhibitorId, exhibitors} = this.props;

        let areAnyChanges = false;
        if (exhibitorId && exhibitors) {
            const exhibitor = exhibitors.find((exhibitor) => exhibitor._id === exhibitorId);
            areAnyChanges = fields.some((field) => field.value.trim() !== exhibitor[field.name].trim());
        } else {
            areAnyChanges = fields.some((field) => field.value !== '');
        }

        const isNameFieldEmpty = fields.find((field) => field.name === 'name').value === '';
        const isDescriptionLengthInvalid = fields.find((field) => field.name === 'description').value.length > 1000;

        const isFormValid = await this.formRef.current.isFormValid(true);

        const isButtonDisabled = !areAnyChanges
            || !isFormValid
            || isNameFieldEmpty
            || isDescriptionLengthInvalid;

        this.setState({buttonDisabled: isButtonDisabled});
    };

    handleEditExhibitor = (data) => {
        const {eventId} = this.props;
        axios({
            method: 'post',
            url: `/event/v2/${eventId}/branding/edit-exhibitor/${this.props.exhibitorId}`,
            data: data,
        })
            .then((response) => {
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.openSuccessSnackbar();
                this.setState({loading: false});
            })
            .catch((error) => {
                this.props.openErrorSnackbar();
                this.setState({loading: false});
            });
    };

    handleAddEditExhibitor = () => {
        this.setState({loading: true});
        const {navigationElement} = this.state;

        let data = {};
        this.state.fields.forEach((field) => {
            data[field.name] = field.value;
        });
        if (this.props.exhibitorId) {
            this.handleEditExhibitor(data);
        } else {
            this.props.addNewExhibitor(data).then(() => {
                this.setState({loading: false});
            });
        }
        this.setState({
            buttonDisabled: true,
            openConfirmUnsavedChanges: false,
        });

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.setState({buttonDisabled: true});
        this.setExhibitorData();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (!this.state.buttonDisabled) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
    };
    //First Letter Control - First letter can not be the empty space
    checkFirstLetter = (input) => (e) => {
        if (input.length <= 0 && e.keyCode === 32) {
            e.preventDefault();
        }
        // return;
    };
    //----------------END First Letter Control
    render() {
        let {buttonDisabled, fields, openConfirmUnsavedChanges, loading} = this.state;
        return (
            <div ref={this.wrapperRef}>
                {loading && <Spinner />}
                <ValidatorForm ref={this.formRef} onSubmit={this.handleAddEditExhibitor}>
                    {fields.map((field, index) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                key={field.name}
                                className={`${field.multiline !== 0 ? 'textarea-wrapper ' : ''}${
                                    !!field.multiline && 'multiline-input'
                                }`}
                            >
                                <TextValidator
                                    label={field.label}
                                    type="text"
                                    name={field.name}
                                    index={index}
                                    value={field.value}
                                    onChange={this.handleChange(index)}
                                    onKeyDown={field.name === 'name' ? this.checkFirstLetter(field.value) : undefined}
                                    validators={field.validators}
                                    errorMessages={field.errorMessages}
                                    multiline={field.multiline > 0}
                                    rows={field.multiline > 0 ? 5 : 0}
                                    fullWidth={true}
                                    variant="outlined"
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: field.adornment ? field.adornment : (field.multiline ?
                                            <span className={'char-count'}>
                                        {field.value?.length}/1000
                                    </span> : null),
                                    }}
                                />

                            </Grid>
                        );
                    })}
                    <Button
                        disabled={buttonDisabled}
                        type="submit"
                        startIcon={<SaveOutlinedIcon />}
                        variant="contained"
                        color={'secondary'}
                        disableElevation
                    >
                        SAVE
                    </Button>
                </ValidatorForm>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.handleAddEditExhibitor}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditExhibitorForm);
