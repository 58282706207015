import {preventDefaultDrag} from "Utils/utils";
import {NewResourceLink} from "./NewResourceLink";
import {ExistingResourceLinks} from "./ExistingResourceLinks";
import React from "react";
import './resourcesStyles.scss'

export const ResourceLinks = ({
                                  wrapperRef,
                                  handleAddLink,
                                  linkURL,
                                  changeURL,
                                  linkLabel,
                                  changeLabel,
                                  newLinkError,
                                  newLinkLabelError,
                                  links,
                                  handleRemoveLink
                              }) => {
    return <div onDragStart={preventDefaultDrag} ref={wrapperRef}>
        <p onDragStart={preventDefaultDrag} className="resource-title">
            LINKS
        </p>
        <NewResourceLink onSubmit={handleAddLink}
                         linkURL={linkURL}
                         changeURL={changeURL}
                         linkLabel={linkLabel}
                         changeLabel={changeLabel}
                         newLinkError={newLinkError}
                         newLinkLabelError={newLinkLabelError}/>

        <ExistingResourceLinks links={links} handleRemoveLink={handleRemoveLink}/>
    </div>;
}