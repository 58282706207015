import Button from "@material-ui/core/Button";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import React from "react";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";

const UploadWindowViewImage = ({
   newWindowViewImage,
   windowViewImagePreviewUrl,
   updateWindowViewImage,
   windowViewImageErrorText,
   handleImageChange,
   removeWindowViewImage,
   image,
}) => {
    return (
        <>
            {newWindowViewImage &&  !windowViewImageErrorText.length > 0 && (
                <Button
                    type="button"
                    onClick={updateWindowViewImage}
                    disabled={!windowViewImagePreviewUrl || windowViewImageErrorText.length > 0}
                    startIcon={<SaveOutlinedIcon />}
                    variant="contained"
                    color={'secondary'}
                    disableElevation
                >
                    Save
                </Button>
            )}

            {windowViewImagePreviewUrl ? (
                <Button
                    type="button"
                    variant="outlined"
                    component="label"
                    // onKeyDown={this.handleEnterKey}
                    startIcon={<EditOutlinedIcon />}
                >
                    Edit
                    <input
                        id="upload-image-input"
                        type="file"
                        onChange={handleImageChange}
                        className="upload-image-button d-none"
                        hidden
                    />
                </Button>
            ) : (
                <Button
                    type="button"
                    variant="outlined"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                >
                    Upload image
                    <input
                        id="upload-image-input"
                        type="file"
                        onChange={handleImageChange}
                        className="upload-image-button d-none"
                        hidden
                    />
                </Button>
            )}

            {image && !newWindowViewImage && (
                <span className={'remove-window-view-btn'}>
                    <IconButton
                        color="inherit"
                        size="small"
                        onClick={removeWindowViewImage}
                        disabled={windowViewImagePreviewUrl}>
                        <DeleteOutlineOutlinedIcon/>
                    </IconButton>
                </span>

            )}
        </>
    )
}

export default UploadWindowViewImage;