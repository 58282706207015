import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import {ReactComponent as Participant} from '../../images/participant.svg';
import {ReactComponent as Exhibitor} from '../../images/exhibitor.svg';
import {ReactComponent as School} from '../../images/school.svg';
import React from 'react';
import {isDesktop, isMobile, isTablet} from 'react-device-detect';
import classnames from 'classnames';
import {useSelector} from 'react-redux';
import {hpColorWithCustomOpacity} from '../../Utils';

const RoleItem = ({role, handleEventRoleChecked, brandingTranslation}) => {
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const eventInfo = useSelector((state) => state.event.eventInfo);
    const {participantRegShortDescription, exhibitorRegShortDescription, scholarRegShortDescription} =
        brandingTranslation;

    //for const name, create a switch case to return the name of the role
    const name = () => {
        switch (role?.slug) {
            case 'participant':
                return (
                    translation.newLoginRegistrations.participant ||
                    defaultTranslation.newLoginRegistrations.participant
                );

            case 'exhibitor':
                return (
                    translation.newLoginRegistrations.exhibitor || defaultTranslation.newLoginRegistrations.exhibitor
                );

            case 'poster-study':
                return translation.newLoginRegistrations.poster || defaultTranslation.newLoginRegistrations.poster;
            default:
                console.log('Invalid role');
                break;
        }
    };
    const description = () => {
        switch (role?.slug) {
            case 'participant':
                return participantRegShortDescription;

            case 'exhibitor':
                return exhibitorRegShortDescription;

            case 'poster-study':
                return scholarRegShortDescription;
            default:
                console.log('Invalid role');
                break;
        }
    };

    const customColor = eventInfo && role.checked && hpColorWithCustomOpacity(0.1, eventInfo, 'primary');
    const primaryColor = eventInfo?.brandingData?.primaryColor;
    return (
        <li
            key={role.id}
            onClick={handleEventRoleChecked(role.slug)}
            style={{
                backgroundColor: customColor,
            }}
            className={classnames({selected: role.checked})}
        >
            <RadioGroup tabIndex="-1" aria-label="role" name="filterRole">
                <FormControlLabel
                    tabIndex="0"
                    name={role.slug}
                    checked={role.checked}
                    onClick={handleEventRoleChecked(role.slug)}
                    control={
                        <Radio
                            tabIndex="-1"
                            style={{
                                color: primaryColor, // Replace with your desired custom color
                            }}
                        />
                    }
                />
            </RadioGroup>
            <div className={`role-name-icon`}>
                {isDesktop && role.slug === 'participant' && <Participant />}
                {isDesktop && role.slug === 'exhibitor' && <Exhibitor />}
                {isDesktop && role.slug === 'poster-study' && <School />}
                {isDesktop && <span>{name()}</span>}
                {(isMobile || isTablet) && (
                    <div className={`mobile-role-container`}>
                        <div>
                            {!isDesktop && role.slug === 'participant' && <Participant />}
                            {!isDesktop && role.slug === 'exhibitor' && <Exhibitor />}
                            {!isDesktop && role.slug === 'poster-study' && <School />}
                            <span>{name()}</span>
                        </div>
                        <span className={`role-description`}>{description()}</span>
                    </div>
                )}
            </div>
            {isDesktop && <span className={`role-description`}>{description()}</span>}
        </li>
    );
};
export default RoleItem;
