import React, {Component} from 'react';
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import {ValidatorForm} from "react-material-ui-form-validator";
import Send from "@material-ui/icons/Send";
import {connect} from "react-redux";
import {connectToSessionChat, disconnectFromSessionChat, liveSessionMessageReceived} from "../../../Api/socketApi";
import * as actions from "../../../store/actions";

const Thumb = ({style, ...props}) => (
    <div className="grey-scroll" style={{...style, backgroundColor: `rgb(137,137,137)`, left: 0}} {...props} />
);

class SessionChat extends Component {

    state = {
        messageText: '',
        messages: []
    }

    constructor(props) {
        super(props);
        this.scrollbar = React.createRef();
    }

    componentDidMount() {
        const {liveSession, liveSessionUser, eventRoles} = this.props;
        let sessionToken = localStorage.sessionToken;
        // if we have userData, it means that the user is the organizer and we will use the normal userToken
        if (eventRoles.isOrganizer){
            sessionToken = localStorage.token
        }
        connectToSessionChat(sessionToken, liveSession.chat._id, liveSessionUser.userId, liveSession._id);

        this.setChatMessages();

        liveSessionMessageReceived((data) => {
            this.setState(
                {
                    messages: [...this.state.messages, data.message],
                },
                () => {
                    this.scrollToBottom();
                }
            );
        });
    }

    componentWillUnmount() {
        const {liveSession, liveSessionUser, eventRoles} = this.props;
        let sessionToken = localStorage.sessionToken;
        // if we have userData, it means that the user is the organizer and we will use the normal userToken
        if (eventRoles.isOrganizer){
            sessionToken = localStorage.token
        }
        disconnectFromSessionChat(sessionToken, liveSession.chat._id, liveSessionUser.userId, liveSession._id);
    }

    setChatMessages = () => {
        this.setState({
            messages: this.props.liveSession.chat.messages
        }, ()=>this.scrollToBottom())
    }

    handleMessageTyping = e => {
        this.setState({
            messageText: e.target.value
        })
    }

    handleSendMessage = () => {
        const {liveSession} = this.props;

        this.props.sendLiveSessionChatMessage(liveSession._id, this.state.messageText)
            .then(()=>this.setState({messageText: ''}))
    }

    scrollToBottom = () => {
        this.scrollbar.current.scrollToBottom();
    };

    checkUserRoleAndReturnSpecificLabel = (userRole, translation, defaultTranslation) => {
        let label;
        if (userRole === 'organizer') {
            label = translation?.sessions?.organizer || defaultTranslation?.sessions?.organizer
        } else if (userRole === 'moderator') {
            label = translation?.sessions?.moderatorSingular || defaultTranslation?.sessions?.moderatorSingular
        } else if (userRole === 'speaker') {
            label = translation?.sessions?.speakerSingular || defaultTranslation?.sessions?.speakerSingular
        }

        return label.toUpperCase();
    }

    render() {
        const {messages, messageText} = this.state;
        const {liveSession, translation, defaultTranslation} = this.props;

        return (
            <div className="module -chat">
                <div className="module-container">
                    <div className="module-header">
                        <span>
                            {translation?.sessions.sessionChat || defaultTranslation?.sessions.sessionChat}
                        </span>
                    </div>

                    <div className="module-body">
                        <div className="messages-container">
                            <Scrollbars
                                ref={this.scrollbar}
                                className="scrollbar"
                                renderThumbVertical={Thumb}
                                width="100%"
                                height="100%"
                            >
                                <div className="message-container">
                                    <div className="empty-space-container"></div>
                                    {messages.length > 0 &&
                                    messages.map((message) => {
                                        const user = liveSession.users.find((user) => user.userId === message.userId);

                                        return (
                                            <div className="message" key={message._id}>
                                                <div className="message-text">
                                                    <h4 className="title">
                                                        {user.name} <span className={'user-role'}>({this.checkUserRoleAndReturnSpecificLabel(user.role, translation, defaultTranslation)})</span>
                                                    </h4>
                                                    <span>{message.text}</span>
                                                </div>
                                                <div className="message-time">
                                                    {moment(message.createdAt).format('hh:mm a')}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Scrollbars>
                        </div>
                        <ValidatorForm className="message-input" onSubmit={this.handleSendMessage}>
                            <input
                                placeholder={translation?.chatsDropdown.newMessageInput || defaultTranslation?.chatsDropdown.newMessageInput}
                                value={messageText}
                                onChange={this.handleMessageTyping}
                            />

                            <button type={'submit'} disabled={messageText.length === 0}>
                                <Send />
                            </button>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        eventRoles: state.user.eventRoles,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendLiveSessionChatMessage: (sessionId, text) => dispatch(actions.sendLiveSessionChatMessage(sessionId, text)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionChat);