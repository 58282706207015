import React from 'react';
import './EventWelcomeScreen.scss';
import * as actions from '../../store/actions';
import {isMobile, isSafari} from 'react-device-detect';

import DialogContent from '@material-ui/core/DialogContent';
import ReactPlayer from 'react-player';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import {connect} from 'react-redux';
import {getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../Utils/utils';
import RefreshIcon from '@material-ui/icons/Refresh';
import callAxios from 'axios';
import Spinner from '../../SmallLayoutComponents/Spinner';
import {isYoutubeLinkRule} from './../../Utils/validationRules';
import './WelcomeVideoStyles.scss';
import Button from "@material-ui/core/Button";

class WelcomeVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false,
            //Video has to start muted. Or it won't play automatically.
            //This a normal behavior of browsers.
            muteVideo: true,
            loaded: false,
            isVideoPlaying: false,
            progressTime: 0,
            thumbnailImage: '',
            streaming: '',
            showReplayOverlay: false,
            videoLoading: false,
        };
        this.videoPlayer = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
        this.setState({loaded: true});
        this.setThumbnailImage();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    setThumbnailImage = () => {
        this.setState({videoLoading: true});
        const {eventWelcomeScreen} = this.props;
        let welcomeVideoId = null;
        let thumbnailImage;
        let ruleYTB = isYoutubeLinkRule;
        let match = ruleYTB.test(eventWelcomeScreen?.data?.videoUrl || '');
        if (match) {
            welcomeVideoId = getVideoIdFromYoutubeUrl(eventWelcomeScreen?.data?.videoUrl || '');
            thumbnailImage = `https://img.youtube.com/vi/${welcomeVideoId}/0.jpg`;
            this.setState({
                thumbnailImage: thumbnailImage,
                streaming: 'youtube',
                videoLoading: false,
            });
        } else {
            callAxios
                .get(`https://vimeo.com/api/oembed.json?url=${eventWelcomeScreen?.data?.videoUrl || ''}`)
                .then((res) => {
                    this.setState({
                        thumbnailImage: `${res.data.thumbnail_url}.jpg`,
                        videoLoading: false,
                    });
                });
        }
    };

    handleVideoReady = () => {
        this.videoPlayer.current.getInternalPlayer().playVideo();
    };

    handleOnPlaying = () => {
        this.setState({
            isVideoPlaying: true,
            showReplayOverlay: false,
            videoLoading: false,
        });
    };

    videoHasEnded = () => {
        this.setState({
            isVideoPlaying: false,
            showReplayOverlay: true,
        });
    };

    escFunction = (e) => {
        if (e.key === 'Escape') {
            this.setState({fullscreen: false});
        }
    };

    handleProgressChange = (e, value) => {
        e.preventDefault();
        this.videoPlayer.current.seekTo(value / 100.5, 'fraction');
    };

    handleProgressBar = (progress) => {
        this.setState({
            progressTime: parseFloat((progress?.played * 100).toFixed(1)),
        });
    };

    toggleMute = () => this.setState({muteVideo: !this.state.muteVideo});

    // toggleFullscreen = () => this.setState({fullscreen: !this.state.fullscreen});

    toggleFullscreen = () => {
        const isFullscreenActive =
            (document.fullscreenElement && document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement && document.msFullscreenElement !== null);
        const body = document.querySelector('body');
        const dialogTitle = document.getElementById('form-dialog-title');
        const dontShowAgain = document.getElementById('dont-show-again');
        if (isSafari && isMobile) {
            this.setState({fullscreen: this.state.fullscreen ? false : true});
            body.classList.toggle('bg-black');
            dialogTitle.classList.toggle('d-none');
            dontShowAgain.classList.toggle('d-none');
        } else {
            if (isFullscreenActive) {
                this.exitFullscreen();
            } else {
                this.startFullscreen();
            }
        }
    };

    startFullscreen = () => {
        const video = document.querySelector('#welcome-screen-video');
        if (video.requestFullScreen) {
            video.requestFullScreen();
        } else if (video.webkitRequestFullScreen) {
            video.webkitRequestFullScreen();
        } else if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
        }
    };

    exitFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    };

    toggleRewind = () => {
        const {streaming} = this.state;
        let currentTime = Math.floor(this.videoPlayer.current.getCurrentTime());
        if (streaming === 'youtube') {
            this.videoPlayer.current.seekTo(Math.floor(this.videoPlayer.current.getCurrentTime()) - 30, 'seconds');
        } else {
            if (currentTime - 30 > 0) {
                this.videoPlayer.current.seekTo(currentTime - 30, 'seconds');
            } else {
                this.videoPlayer.current.seekTo(0);
            }
        }
    };

    toggleForward = () => {
        const {streaming} = this.state;
        let currentTime = Math.floor(this.videoPlayer.current.getCurrentTime());
        if (streaming === 'youtube') {
            this.videoPlayer.current.seekTo(Math.floor(this.videoPlayer.current.getCurrentTime()) + 30, 'seconds');
        } else {
            this.videoPlayer.current
                .getInternalPlayer()
                .getDuration()
                .then((duration) => {
                    if (currentTime + 30 < duration) {
                        this.videoPlayer.current.seekTo(currentTime + 30, 'seconds');
                    } else {
                        this.videoPlayer.current.seekTo(duration);
                    }
                });
        }
    };

    togglePause = () => {
        const {streaming} = this.state;
        this.setState(
            {
                isVideoPlaying: !this.state.isVideoPlaying,
            },
            () => {
                if (this.state.isVideoPlaying) {
                    this.setState({
                        videoLoading: true,
                    });
                }
                if (streaming === 'youtube') {
                    if (this.state.isVideoPlaying) {
                        this.videoPlayer.current.getInternalPlayer().playVideo();
                    } else {
                        this.videoPlayer.current.getInternalPlayer().pauseVideo();
                    }
                }
            }
        );
    };

    render() {
        const {fullscreen, muteVideo, isVideoPlaying, thumbnailImage, showReplayOverlay, videoLoading} = this.state;
        const {eventWelcomeScreen} = this.props;
        const videoUrl = eventWelcomeScreen?.data?.videoUrl;
        document.onmousemove = function (event) {};
        return (
            <DialogContent
                id="welcome-screen-video"
                className={`${isSafari && isMobile && fullscreen && 'fullscreen-ios'}`}
            >
                <div onDragStart={preventDefaultDrag} className="booth-video-container">
                    <div className="media-container">
                        {videoLoading && <Spinner custom="spinner" />}
                        {thumbnailImage.length > 0 && (
                            <div className={`thumbnail-image-background ${isVideoPlaying && 'hide'}`}>
                                <img
                                    src={thumbnailImage}
                                    alt="Video thumbnail"
                                    className={`booth-thumbnail ${!isVideoPlaying && 'show'}`}
                                />
                            </div>
                        )}
                        <ReactPlayer
                            ref={this.videoPlayer}
                            url={videoUrl}
                            onReady={this.handleVideoReady}
                            onProgress={this.handleProgressBar}
                            onEnded={this.videoHasEnded}
                            onError={this.isPlayingError}
                            onStart={this.handleVideoWhenIsStartPlaying}
                            onPlay={this.handleOnPlaying}
                            playsinline={isMobile}
                            playIcon={<></>}
                            controls={false}
                            width="100%"
                            height="100%"
                            volume={muteVideo ? 0 : 1}
                            muted={muteVideo}
                            className="react-player"
                            playing={isVideoPlaying}
                        />
                        <div onDragStart={preventDefaultDrag} className="auditoriumOverlayPlayer" />
                        {showReplayOverlay && (
                            <div onDragStart={preventDefaultDrag} className="refresh-overlay">
                                <RefreshIcon fontSize={'inherit'} onClick={this.togglePause} />
                            </div>
                        )}
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="booth-video-actions-container">
                    {/*<button onClick={this.toggleFullscreen} className="fullscreen-button">*/}
                    {/*    {fullscreen ? <FullscreenExitIcon fontSize="inherit" /> : <FullscreenIcon fontSize="inherit" />}*/}
                    {/*</button>*/}
                    <Button
                        variant={"text"}
                        className={"only-icon-button"}
                        onClick={this.toggleFullscreen}
                    >
                        {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon/>}
                    </Button>

                    <Button
                        variant={"text"}
                        className={"only-icon-button"}
                        onClick={this.toggleRewind}>
                        <FastRewindIcon  />
                    </Button>

                    <Button
                        variant={"text"}
                        className={"only-icon-button"}
                        onClick={this.togglePause}>
                        {isVideoPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </Button>
                    <Button
                        variant={"text"}
                        className={"only-icon-button"}
                        onClick={this.toggleForward}>
                        <FastForwardIcon  />
                    </Button>
                    <Button
                        variant={"text"}
                        className={"only-icon-button"}
                        onClick={this.toggleMute}>
                        {muteVideo ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    </Button>
                </div>
            </DialogContent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        eventWelcomeScreen: state.eventWelcomeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventWelcomeScreen: (eventId) => dispatch(actions.getEventWelcomeScreen(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeVideo);
