import React from 'react';
import Button from '@material-ui/core/Button';
import Confirm from '../../../../Dialogs/Confirm';
import {connect} from 'react-redux';
import {ChromePicker} from 'react-color';
import {preventDefaultDrag, fireClickEvent} from '../../../../Utils/utils';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {CircularProgress} from "@material-ui/core";

class ColorPickerSecondary extends React.Component {
    wrapperRef = React.createRef();
    handleClickOutside = this.handleClickOutside.bind(this);
    defaultColorString = this.props.color;
    defaultColorRGBA = {
        r: 102,
        g: 36,
        b: 206,
        a: 0.7,
    };

    state = {
        initialColor: this.defaultColorString,
        color: this.defaultColorString,
        rgba: this.defaultColorRGBA,
        openConfirmUnsavedChanges: false,
        navigationElement: null,
    };

    componentDidMount() {
        this.initState();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps,prevStates) {
       
        let brandingData = this.props.branding.data;
        let currentColor = brandingData[this.props.for];
        let prevBrandingData = prevProps.branding.data;
        let previousColor = prevBrandingData[this.props.for];
        if (currentColor !== previousColor) {
            this.setState({
                initialColor: currentColor === '' ? this.defaultColorString : currentColor,
                color: currentColor === '' ? this.defaultColorString : currentColor,
            });
        }
      
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
     
    }

    initState = () => {
        const brandingData = this.props.branding.data;
        const initialColor =
            brandingData[this.props.for] === '' ? this.defaultColorString : brandingData[this.props.for];

        let rgba = '';
        if (initialColor) {
            rgba = this.getFormattedRGBAColor(initialColor);
        }

        this.setState({
            initialColor: initialColor,
            color: initialColor,
            rgba: rgba,
        });
    };

    getFormattedRGBAColor = (colorString) => {
        let colorArray = colorString.replace('rgba(', '');
        colorArray = colorArray.replace(')', '');
        colorArray = colorArray.split(',');
        return {
            r: colorArray[0],
            g: colorArray[1],
            b: colorArray[2],
            a: colorArray[3],
        };
    };

    handleChangeComplete = (color) => {
        const {isAnyConfirmUnsavedChangesOpen} = this.props;
        const overlay=document.getElementById('overlay-picker-helper')
        if (isAnyConfirmUnsavedChangesOpen) return;

        overlay.style.display = "block";

        this.setState({
            rgba: color.rgb,
            color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
        });
    };

    handleResetColor = () => {
        const overlay=document.getElementById('overlay-picker-helper')
        overlay.style.display = "none";
        const data = {
            [this.props.for]: '',
        };
        this.props.saveEventBranding(data);
        this.setState({
            initialColor: this.defaultColorString,
            color: this.defaultColorString,
        });
    };
//buraya gel
    handleSaveColor = () => {
        const {navigationElement} = this.state;
        const overlay=document.getElementById('overlay-picker-helper')
        overlay.style.display = "none";
        const data = {
            [this.props.for]: this.state.color,
        };
        this.props.saveEventBranding(data);
        this.setState({openConfirmUnsavedChanges: false});
        // this.props.setIsAnyConfirmUnsavedChangesOpen(false);

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;
        const overlay=document.getElementById('overlay-picker-helper')
        overlay.style.display = "none";
        this.closeClickOutside();
        this.initState();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    getNewNavigationElement = (e) => {
        const {isAnyConfirmUnsavedChangesOpen} = this.props;
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChanges || isAnyConfirmUnsavedChangesOpen) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        const {color, initialColor} = this.state;
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (initialColor !== color) {
                this.setState({
                    openConfirmUnsavedChanges: true,
                    navigationElement: this.getNewNavigationElement(e),
                });
               
                // this.props.setIsAnyConfirmUnsavedChangesOpen(true);
            }
        }
    }

    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
        // this.props.setIsAnyConfirmUnsavedChangesOpen(false);
    };

    render() {
        const {color, initialColor, defaultColorString, openConfirmUnsavedChanges} = this.state;
        const {description, loading} = this.props;
        return (
            <>
                <div ref={this.wrapperRef} onDragStart={preventDefaultDrag} className="color-picker options-container">
                    <div onDragStart={preventDefaultDrag} className="single-option-container over-helper">
                        <div id='overlay-color-picker-helper-secondary' className='overlay-color-picker-helper'></div>
                        {loading ?
                            <div className="d-flex align-items-center justify-content-center">
                                <CircularProgress color="primary"/>
                            </div>
                            :
                            <ChromePicker
                                className="overlay-color-picker"
                                color={color}
                                onChangeComplete={this.handleChangeComplete}
                                width="520px"
                            />
                        }
                    </div>
                    <div onDragStart={preventDefaultDrag} className="input-description-alt">
                        <p>{description}</p>
                        <div onDragStart={preventDefaultDrag} className="actions-container">
                            <Button
                                type="button"
                                disabled={initialColor === color || loading}
                                onClick={this.handleSaveColor}
                                startIcon={<SaveOutlinedIcon />}
                                variant="contained"
                                color={'secondary'}
                                disableElevation
                            >
                                Save
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                disabled={initialColor === defaultColorString || loading}
                                onClick={this.handleResetColor}
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                </div>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.handleSaveColor}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        branding: state.event.branding,
    };
};

export default connect(mapStateToProps)(ColorPickerSecondary);
