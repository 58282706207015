import {useSelector} from "react-redux";
import {Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const LiveLabel = () => {

    const translation = useSelector(state =>  state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector(state => state.languages.translations['en']);
    const isLargeScreen = useSelector(state => state.layout.isLargeScreen);
    const isTablet = useSelector(state => state.layout.isTablet);


    const useStyles = makeStyles((theme) => ({
        customWidth: {
            maxWidth: 212,
            maxHeight: 70,
            wordBreak: "unset",
            border: '1px solid #CACBD0',
            borderRadius: 12,
            fontSize: 14,
            fontWeight: 500,
            color: '#2B2B2B',
            fontFamily: 'Montserrat, sans-serif',
            lineHeight: '20px',
            paddingTop: 15,
            paddingBottom: 15,
        },
    }));

    const classes = useStyles();

    return (
        <>
        {isLargeScreen ?
                (<Tooltip
                    arrow
                    classes={{ tooltip: classes.customWidth }}
                    placement="bottom-end"
                    title={translation?.programDropdown.liveLabelTooltipText || defaultTranslation?.programDropdown.liveLabelTooltipText}
                >
                    <div className={`live-label ${isLargeScreen && 'live-label-desktop-ml'}`}>
                        <span className={'live-label-bullet'}></span>
                        <p className={'live-label-text'}>{translation?.programDropdown.liveLabel || defaultTranslation?.programDropdown.liveLabel}</p>
                    </div>
                </Tooltip>) :
                (<div className={`live-label ${isLargeScreen && 'live-label-desktop-ml'} ${isTablet && 'live-label-tablet-mt'}`}>
                    <span className={'live-label-bullet'}></span>
                    <p className={'live-label-text'}>{translation?.programDropdown.liveLabel || defaultTranslation?.programDropdown.liveLabel}</p>
                </div>)
        }
        </>
    )
}

export default LiveLabel;