import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from '../../store/axios-instance';
import * as actions from '../../store/actions';
import EventLanguageModalMobile from '../../Dialogs/EventLanguageModalMobile';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as ArrowRightIcon} from '../../Images/svg/arrow_right.svg';
import {preventDefaultDrag} from '../../Utils/utils';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

class EventLanguage extends PureComponent {
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        const {platformLanguages} = props;
        const sortedLanguages = [];

        platformLanguages.map((item) => {
            return sortedLanguages.push(item.value);
        });

        this.state = {
            showLanguageList: false,
            listLanguages: sortedLanguages.sort(),
            openSelectLanguage: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    toggleShowLanguageList = () =>
        this.setState((prevState) => ({
            showLanguageList: !prevState.showLanguageList,
        }));

    handleOpenSelectLanguageDialog = () =>
        this.setState({
            openSelectLanguage: true,
        });

    handleCloseSelectLanguageDialog = () =>
        this.setState({
            openSelectLanguage: false,
        });

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                showLanguageList: false,
            });
        }
    }

    handleChangeLanguage = (languageCode) => () => {
        const {onSetPlatformLanguage} = this.props;
        axios({method: 'put', url: '/users', data: {language: languageCode}})
            .then(() => {
                onSetPlatformLanguage(languageCode);
            })
            .catch(() => {});
        this.setState({
            showLanguageList: false,
            openSelectLanguage: false,
        });
    };

    handleEnterKey = (tab, language) => (e) => {
        if (e.key === 'Enter') {
            if (tab === 'tab')
                return this.setState((prevState) => ({
                    showLanguageList: !prevState.showLanguageList,
                }));

            if (tab === 'list') return this.handleChangeLanguage(language)();
        }
    };

    render() {
        const {showLanguageList, listLanguages, openSelectLanguage} = this.state;
        const {platformLanguages, platformLanguage, isMobile, isTablet, translation} = this.props;

        return (
            <>
                <div
                    tabIndex="0"
                    onDragStart={preventDefaultDrag}
                    className="select-language-container"
                    ref={this.setWrapperRef}
                    onKeyDown={this.handleEnterKey('tab')}
                    data-is-navigation
                >
                    {isMobile || isTablet ? (
                        <div
                            onDragStart={preventDefaultDrag}
                            className="selected-language"
                            onClick={this.handleOpenSelectLanguageDialog}
                        >
                            <h4>
                                {translation?.myAccountPage.platformLanguage} <span>{platformLanguage}</span>
                            </h4>
                            <div>
                                <ArrowRightIcon
                                    width="24"
                                    height="24"
                                    fill={colors.greyDark}
                                    className="arabic-rotate"
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            onDragStart={preventDefaultDrag}
                            className="selected-language"
                            onClick={this.toggleShowLanguageList}
                        >
                            <span>{platformLanguage}</span>
                            <ExpandMoreRoundedIcon className={'language-arrow'} />
                        </div>
                    )}
                    {showLanguageList && (
                        <div onDragStart={preventDefaultDrag} className="languages-list-container">
                            <ul>
                                {listLanguages.map((language) => {
                                    return (
                                        <li
                                            tabIndex="0"
                                            key={language}
                                            value={language}
                                            onClick={this.handleChangeLanguage(language)}
                                            onKeyDown={this.handleEnterKey('list', language)}
                                            className={`${language === platformLanguage ? 'active' : ''}`}
                                        >
                                            {language}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
                <EventLanguageModalMobile
                    open={openSelectLanguage}
                    platformLanguages={platformLanguages}
                    platformLanguage={platformLanguage}
                    handleChangeLanguage={this.handleChangeLanguage}
                    handleCloseSelectLanguageDialog={this.handleCloseSelectLanguageDialog}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        platformLanguages: state.languages.platformLanguages,
        platformLanguage: state.languages.platformLanguage,
        eventId: state.event.eventId,
        isMobile: state.layout.isMobile,
        isTablet: state.layout.isTablet,
        eventMatching: state.organizer.eventMatching,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetPlatformLanguage: (languageCode) => dispatch(actions.setPlatformLanguage(languageCode)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventLanguage));
