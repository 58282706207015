import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spinner from "../SmallLayoutComponents/Spinner";
class ConfirmDelete extends React.Component {
    state = {
        loading: false,
    }

    handleDelete = () => {
        this.setState({loading: true});
        this.props.handleConfirmDelete()?.then(() => {
            this.setState({loading: false});
        }).catch(() => {
            this.setState({loading: false});
        })
    }

    render() {
        const {
            classes,
            disabledConfirm,
            open,
            closeConfirmDeleteDialog,
            dialogTitle,
            dialogDescription,
            dialogConfirmButtonLabel,
            dialogCancelButtonLabel,
            extraLongWord,
        } = this.props;

        return (
            <Dialog
                disableRestoreFocus
                className={classes}
                open={open}
                onClose={closeConfirmDeleteDialog}
                aria-labelledby="form-dialog-title"
                PaperProps={{classes: {root: 'reverse-rtl'}}}
            >
                {this.state.loading && <Spinner />}

                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="form-dialog-secondary">
                        {dialogDescription}
                        <span className='extra-long-word'>{extraLongWord}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={closeConfirmDeleteDialog}>
                        {dialogCancelButtonLabel || "Cancel"}
                    </Button>
                    <Button
                        type="button"
                        disabled={disabledConfirm}
                        onClick={this.handleDelete}
                    >
                        {dialogConfirmButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ConfirmDelete;
