import { useEffect } from 'react';

const AIChatbot = ({ userRole }) => {


  useEffect(() => {
	const scriptContainer = document.getElementById('chat-widget-container');

    const loadScript = (src) => {
      const script = document.createElement('script');
      script.id = 'openwidget';
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      document.body.appendChild(script);
    };

    const getScriptSrc = (role) => {
      switch (role) {
        case 'organizer':
        case 'co-organizer':
          return `${process.env.PUBLIC_URL}/open-widget-organizer-script-23473.js`;
        case 'company':
          return `${process.env.PUBLIC_URL}/open-widget-exhibitor-script-98253.js`;
        case 'scholar':
          return `${process.env.PUBLIC_URL}/open-widget-poster-study-script-07572.js`;
        default:
          return null;
      }
    };

    const scriptSrc = getScriptSrc(userRole);

    if (scriptContainer) {
      scriptContainer.style.visibility = 'visible';
    } else if (scriptSrc) {
      loadScript(scriptSrc);
    }

    return () => {
      const openwidgetScript = document.getElementById('openwidget');
      if (openwidgetScript) {
        document.body.removeChild(openwidgetScript);
      }

	  const scriptContainerCleanup = document.getElementById('chat-widget-container');
      if (scriptContainerCleanup) {
        scriptContainerCleanup.style.visibility = 'hidden';
      }
    };
  }, [userRole]);

  return null;
};

export default AIChatbot;
