const fr = {
    generalText: {
        upload: 'Télécharger',
        save: 'Sauvegarder',
        add: 'Ajouter',
        remove: 'Supprimer',
        close: 'FERMER',
        update: 'MISE À JOUR',
        cancel: 'ANNULER',
        confirm: 'Confirmer',
        download: 'Télécharger',
        refresh: 'Rafraîchir',
        back: 'Retour',
        submit: 'Soumettre',
        send: 'Envoyer',
        leave: 'Quitter',
        step1: 'Étape 1',
        step2: 'Étape 2',
        or: 'ou',
        delete: 'Supprimer',
        please: "S'il vous plaît",
        before: 'avant',
        approve: 'Accepter',
        decline: 'Refuser',
        start: 'Commencer',
        stop: 'Arrêter',
        resetFilter: 'Réinitialiser Filtre',
        apply: 'Appliquer',
        filters: 'Filtres',
        unsavedChangesTitle: 'Changement non enregistrés',
        unsavedChangesText: 'Vous avez des changements non enregistrés. Voulez-vous les enregistrer ?',
        selectAll: 'Tout sélectionner',
        unselectAll: 'Tout déselectionner',
    },
    time: {
        justNow: 'à l’instant',
        minute: 'minute',
        minutes: 'minutes',
        hour: 'heure',
        hours: 'heures',
        day: 'jour',
        days: 'jours',
        month: 'mois',
        months: 'mois',
        yesterday: 'hier',
        today: "aujourd'hui",
        tomorrow: 'demain',
        monday: 'lundi',
        tuesday: 'mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'samedi',
        sunday: 'dimanche',
        january: 'janvier',
        february: 'février',
        march: 'Mars',
        april: 'avril',
        may: 'Mai',
        june: 'juin',
        july: 'juillet',
        august: 'août',
        september: 'septembre',
        october: 'octobre',
        november: 'novembre',
        december: 'décembre',
        januaryShort: 'jan',
        februaryShort: 'fév',
        marchShort: 'Mar',
        aprilShort: 'avr',
        mayShort: 'Mai',
        juneShort: 'jui',
        julyShort: 'jui',
        augustShort: 'aoû',
        septemberShort: 'sep',
        octoberShort: 'oct',
        novemberShort: 'nov',
        decemberShort: 'déc',
    },
    errors: {
        noFile: 'Aucun dossier choisi',
        required: 'Champ obligatoire',
        emailNotValid: 'Courriel non valide',
        passwordMinLength: 'Le mot de passe doit comporter au moins 8 caractères',
        passwordMismatch: 'Erreur de mot de passe',
        passwordIsSame: 'Your new password must be different',
        noInvitationCode: "Veuillez entrer votre code d'invitation",
        imageFileNotSupported:
            "Type de fichier non pris en charge. Veuillez utiliser l'un des types de fichiers suivants :",
        phoneInvalid: 'Veuillez entrer un numéro de téléphone valide. Par exemple +447517663928',
        linkInvalid: 'Veuillez entrer un lien valide (https://site.com)',
        facebookLinkInvalid: 'Veuillez entrer un lien valide (https://facebook.com/)',
        linkedinLinkInvalid: 'Veuillez entrer un lien valide (https://linkedin.com/)',
        twitterLinkInvalid: 'Veuillez entrer un lien valide (https://twitter.com/)',
        youtubeLinkInvalid: 'Veuillez entrer un lien valide (https://www.youtube.com/)',
        youtubeOrVimeoLinkInvalid:
            'Veuillez entrer un lien YouTube (https://www.youtube.com) ou Vimeo (https://vimeo.com/) valide.\n',
        googleScholarLinkInvalid: 'Veuillez entrer un lien valide (https://scholar.google.com/)',
        researchGateLinkInvalid: 'Veuillez entrer un lien valide (https://www.researchgate.net/)',
        connectionProblemTextFirst: 'Il semble y avoir un problème de connexion. Veuillez consulter le site',
        connectionProblemButton: 'CLIQUEZ',
        connectionProblemTextSecond: 'ou rafraîchir la page.',
        fileTooLarge: 'Fichier trop large. 2Mb taille maximale du fichier',
        fileTooLargeTextFirst: 'Fichier trop large.',
        fileTooLargeTextSecond: 'taille maximale du fichier.',
        fileNameTooLongTextFirst: 'Le nom du fichier est trop long. La limite est de',
        fileNameTooLongTextSecond: 'caractères.',
        maxWallInputLength: 'Vous avez atteint le nombre maximum de 1200 caractères.',
    },
    footer: {
        cookiesText:
            'Ce site web utilise des cookies essentiels,qui sont nécessaires à son fonctionnement elle utilise également des cookies de tiers,  qui sont nécessaires au fonctionnement de certaines fonctionnalités de notre site web. Si vous souhaitez en savoir plus ou retirer votre consentement à certains cookies, veuillez vous référer à notre',
        cookiesLink: 'Paramètrer les Cookies',
        cookiesButton: 'ACCEPTER',
        privacyPolicy: 'Politique de confidentialité',
        cookies: 'Cookies',
        termsAndConditions: 'Conditions générales',
        socialLinksTitle: 'Suivez-nous :',
    },
    menu: {
        logIn: 'CONNEXION',
        logOut: 'DÉCONNEXION',
        scholarRegistration: 'Etre ÉTUDE / AFFICHE',
        exhibitorRegistration: 'Etre exposant',
        participantRegistration: "S'INSCRIRE",
        createEvent: "Organiser l'événement",
        loginDisabledInfoText: "L'organisateur a clôturé la connexion. Revenez plus tard",
    },
    homepage: {
        organizedBy: 'ORGANISÉ PAR',
        date: 'DATE',
        location: 'DOMAINE ET LIEU',
        price: 'PRIX',
        tabIntro: 'INTRO',
        tabProgram: 'PROGRAMME',
        tabSponsors: 'SPONSORS',
        tabPartners: 'PARTENAIRES',
        tabExhibitors: 'EXPOSANTS',
        previousEventsSectionTitle: 'ÉVÉNEMENTS PRÉCÉDENTS',
    },
    socialButtons: {
        linkedinButton: 'Connectez-vous avec LinkedIn',
        loginTabLinkedinButton: 'avec LinkedIn',
        facebookButton: 'Connectez-vous avec Facebook',
        loginTabFacebookButton: 'avec Facebook',
        googleButton: 'Connectez-vous avec Google',
        loginTabGoogleButton: 'avec Google',
        twitterButton: 'Connectez-vous avec Twitter',
        loginTabTwitterButton: 'avec Twitter',
        socialButtonsLogInTitle: 'Connectez-vous avec votre profile de réseau social',
        socialButtonsSignUpTitle: 'Inscrivez-vous avec votre profil de réseau social',
    },
    login: {
        inputEmail: 'Courriel',
        inputPassword: 'Mot de passe',
        rememberMeButton: 'Se souvenir de moi',
        forgotPasswordButton: 'Mot de passe oublié ?',
        loginButton: 'CONNEXION',
        loginClosedButton: 'Connexion clôturée',
        loginTitleLowerCase: 'connexion',
        recoverPasswordDialogTitle: 'RÉCUPÉRER LE MOT DE PASSE',
        recoverPasswordDialogRecoverButton: 'RÉCUPÉRER',
        errorIncorrectDates: 'Email ou mot de passe incorrect',
        orLogInWithEmail: 'ou connecter avec email',
        logInAsParticipantSecondText: 'en tant que participant',
        logInAsExhibitorSecondText: "en tant qu'exhibitant",
        logInAsPosterSecondText: "en tant qu'étude / affiche",
        errorAccountNotFound: 'Nous ne trouvons pas votre compte myOnvent',
    },
    register: {
        inputFirstName: 'Prénom',
        inputLastName: 'Nom de famille',
        inputEmail: 'Courriel',
        inputPassword: 'Mot de passe',
        inputConfirmPassword: 'Confirmer le mot de passe',
        gdprTextFirst: 'En procédant ainsi, vous acceptez notre',
        gdprTextTerms: "Conditions d'utilisation",
        gdprTextSecond: 'et confirmez que vous avez lu notre',
        gdprTextCookies: 'Cookie',
        gdprTextPrivacy: 'Politique de confidentialité',
        gdprTextAgree: 'Veuillez accepter la politique de confidentialité',
        registerButton: "S'inscrire",
        signUpTitleLowerCase: "s'inscrire",
        invitationCode: "Code d'invitation",
        registerNotAllowed: 'Inscription fermée',
        logInTitleFirst: 'Rejoindre',
        logInTitleSecond: 'événement',
        signUpTitleTextFirst: 'Première fois à',
        signUpTitleTextSecond: 'myOnvent',
        orFillInTheForm: 'ou remplissez le formulaire ci-dessous',
        uploadProfilePicture: 'Télécharger photo de profile',
        editProfilePicture: 'Modifier photo de profile',
        errorAlreadyHaveMOAccount: 'Vous avez déjà un compte myOnvent.',
        step1From2Text: 'Étape 1/2',
        step2From2Text: 'Étape 2/2',
        metaTitleScholarRegistration: 'Inscription pour Étude / Affiche',
    },
    newLoginRegistrations: {
        joinEvent: "REJOINDRE L'ÉVÉNEMENT",
        loginWelcome: 'Bienvenue sur myOnvent! Pour continuer, veuillez sélectionner une méthode de connexion',
        continueButton: 'Continuer',
        seemsLikeYouDontHaveMyOnventAccount: "Il semble que vous n'ayez pas de compte myOnvent",
        uploadProfilePictureOptional: 'Télécharger une photo de profil (facultatif)',
        createPlatformAccount: 'Créer un compte plateforme',
        platformAccount: 'Compte plateforme',
        selectRole: 'Sélectionner un rôle',
        eventAccount: "Compte d'événement",
        password: 'Mot de passe',
        confirmPassword: 'Confirmer le mot de passe',
        email: 'E-mail',
        passwordMustContain: 'Le mot de passe doit contenir:',
        minimum8Characters: 'Minimum 8 caractères',
        aLowerCaseLetter: 'Une lettre minuscule',
        anUppercaseLetter: 'Une lettre majuscule',
        aNumber: 'Un chiffre',
        aSpecialCharacter: 'Un caractère spécial',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        weHaveSentAVerificationCodeToYourEmail: 'Nous avons envoyé un code de vérification à votre adresse e-mail',
        continueRegistration: "Pour continuer l'inscription,",
        enterCodeBelow: 'saisissez le code de vérification ci-dessous :',
        codeWillExpire: 'Ce code expirera dans',
        codeExpired: 'Le code a expiré',
        codeNotReceived: "Vous n'avez pas reçu le code ? Avez-vous vérifié votre dossier spam ?",
        resendTimer: 'Renvoyer dans :',
        resendLabel: 'Renvoyer',
        resendCodeLabel: 'Renvoyer le code',
        codeError: 'Le code saisi est incorrect.',
        codeErrorFix: 'Entrez à nouveau le code correct de votre e-mail ou renvoyez un nouveau code',
        congrats: 'Félicitations !',
        emailConfirmed: 'Votre e-mail est confirmé :',
        imHereAs: 'Je suis ici en tant que :',
        participant: 'Participant',
        participantText: 'Pour regarder en streaming, communiquer, visiter des exposants et des affiches/études.',
        exhibitor: 'Exposant',
        exhibitorText: "Pour présenter votre entreprise et communiquer avec les participants à l'événement",
        poster: 'Affiche / Étude',
        posterText: 'Pour présenter une recherche pour une conférence académique ou professionnelle',
        emailIsNotAllowed: "Cet e-mail n'est pas autorisé à s'inscrire à cet événement",
        didntReceiveEmail: '¿No recibiste el correo electrónico?',
        sendAgain: 'Renvoyer',
        incorrectPassword: 'Mot de passe incorrect',
        fileTypeError:
            "Type de fichier non pris en charge. Veuillez utiliser l'un des formats suivants : jpeg, jpg, jfif, gif ou png.",
        fileSizeError: 'Fichier trop volumineux. Taille maximale de fichier : 2 Mo.',
        pleaseCompleteAllRequiredFields: 'Veuillez remplir tous les champs obligatoires',
        joinTheEvent: "Rejoindre l'événement",
        joinTheEventSub: "Pour commencer votre expérience d'événement virtuel en ligne",
        changeEmail: "CHANGER D'ADRESSE E-MAIL",
        changeRole: 'CHANGER DE RÔLE',
        logout: 'SE DÉCONNECTER',
        headingUser: 'Bienvenue de retour',
        subHeadingUser: 'Il semble que vous ayez déjà un compte sur la plateforme',
        forgotPasswordHeadline: 'Mot de passe oublié',
        forgotPasswordText: 'Pas de soucis, nous vous enverrons des instructions supplémentaires',
        checkYourEmail: 'Vérifiez votre boîte mail',
        checkYourEmailText: 'Nous avons envoyé un lien de réinitialisation de mot de passe à',
        headingNoUser: 'Connexion',
        subHeadingNoUser: 'Bienvenue sur myOnvent ! Pour continuer, sélectionnez une méthode de connexion',
        resetPasswordButton: 'réinitialiser le mot de passe',
        createNewPassword: 'Créer un nouveau mot de passe',
        createNewPasswordText: 'Votre nouveau mot de passe doit être différent des mots de passe précédemment utilisés',
        passwordReset: 'Réinitialisation du mot de passe',
        passwordResetText:
            'Votre mot de passe a été réinitialisé avec succès. Cliquez ci-dessous pour vous connecter à nouveau.',
        registrationIsClosed: "L'inscription pour tous les rôles est fermée.",
        chooseAnOption: 'Choisissez une option',
        yourAnswer: 'Ta réponse',
        firstNameError: 'Le prénom est trop court. Longueur minimale : 2',
        lastNameError: 'Le nom de famille est trop court. Longueur minimale : 2',
        recoveryPassword: 'Récupérer le mot de passe',
        incorrectPasswordError:
            'Mot de passe incorrect. Réessayez ou cliquez sur "Mot de passe oublié" pour le changer.',
        optional: 'Optionnel',
        spamEmailError:
            'Un problème est survenu lors de la création de votre compte. Veuillez réessayer ultérieurement.',
        invalidInput:
            "Saisie invalide. Utilisez uniquement des lettres (A-Z, a-z), des chiffres (0-9), un point (.), une apostrophe ('), un tiret (-) et des espaces. Le premier caractère doit être une lettre.",
    },
    emailUpdate: {
        changeEmail: "Changer l'adresse e-mail",
        changeEmailDescription:
            "Avant d'attribuer la nouvelle adresse e-mail à votre compte, nous voulons être sûrs qu'il s'agit d'une nouvelle adresse e-mail et qu'elle n'appartient à personne.",
        emailTakenError: 'Cette adresse e-mail est déjà utilisée. Essayez-en une autre.',
        enterTheConfirmationCode: 'Entrez le code de confirmation',
        verificationCodeSent: 'Code de vérification envoyé :',
        checkYourSpamFolder: 'Impossible de trouver le code ? Vérifiez votre dossier de courrier indésirable',
        emailSuccess: "Succès ! Vous avez changé l'adresse e-mail associée à votre compte",
        emailError: 'Erreur. Réessayez plus tard',
        confirmPassword: 'Confirmez le mot de passe',
        confirmPasswordDescription: "Pour changer l'adresse e-mail, veuillez confirmer votre mot de passe :",
        newEmail: 'Nouvelle adresse e-mail',
        password: 'Mot de passe',
        confirm: 'Confirmer',
        cancel: 'Annuler',
        change: 'Changer',
    },

    agendaTranslations: {
        myAgenda: 'MON AGENDA',
        all: 'Tout',
        sessions: 'Séances',
        meetings: 'Réunions',
        pastSlots: 'Créneaux passés',
        meetingWith: 'Réunion avec',
        pending: 'En attente',
        accepted: 'Accepté',
        requested: 'Demandé',
        canceled: 'Annulé',
        now: 'Maintenant',
        live: 'En direct',
        noMeetings: 'Aucune réunion programmée pour le moment',
        noSessions: 'Aucune séance dans votre agenda pour le moment.',
        noUpcomingSessions: 'Aucune séance à venir dans votre agenda pour le moment',
        noUpcomingMeetings: 'Aucune réunion à venir dans votre agenda pour le moment.',
        noUpcomingAgenda: 'Rien de prévu dans votre agenda pour le moment.',
        noAgendaItem: 'Rien dans votre agenda pour le moment.',
        meetingRequests: 'DEMANDES DE RÉUNION',
        finished: 'Terminé',
        past: 'Passé',
        goToMeeting: 'ALLER À LA RÉUNION',
        addToMyAgenda: 'Ajouter à mon agenda',
        addedToYourAgenda: 'Ajouté à votre agenda',
        acceptedAllMeetings: 'Accepter toutes les réunions',
    },
    participantRegistration: {
        metaTitle: 'Inscription pour participant',
        choosePackageTitle: "CHOISIR LE PACKAGE D'ACCÈS",
        noPackageText: 'Veuillez sélectionner un paquet',
        gdprTextFirst: 'En procédant ainsi, vous acceptez notre',
        gdprTextPrivacy: 'Politique de confidentialité',
        gdprTextSecond: 'et',
        gdprTextTerms: "Conditions de l'événement",
        gdprTextAgree: 'Veuillez accepter la politique de confidentialité',
        registerButtonFree: 'Inscription complète',
        registerButtonPay: 'Procéder au paiement',
    },
    exhibitorRegistration: {
        metaTitle: 'Inscription pour exposant',
        choosePackageTitle: "CHOISIR LE PACKAGE D'ACCÈS",
        gdprTextFirst: 'En procédant ainsi, vous acceptez notre',
        gdprTextPrivacy: "Paramètrer de confidentialité L'événements",
        gdprTextSecond: 'et',
        gdprTextTerms: "Conditions de l'événement",
        gdprTextAgree: 'Veuillez accepter la politique de confidentialité',
        registerButtonFree: 'Inscription complète',
        registerButtonPay: 'Procéder au paiement',
    },
    stripePayment: {
        cardNumber: 'Numéro de la carte',
        expirationDate: "Date d'expiration  (MM/YY)",
        cvc: 'CVC',
        disclaimerText:
            'Cette opération de paiement est cryptée de bout en bout. La sécurité et la confidentialité sont garanties par Stripe Services.',
        disclaimerTextLink: 'Cliquez ici pour en savoir plus.',
        cancelButton: 'ANNULER',
        payButton: 'Payer',
    },
    recoverPassword: {
        recoverTextSend: 'Le courriel de récupération du mot de passe a été envoyé avec succès.',
    },
    unsubscribe: {
        unsubscribeTitle: 'Se désinscrire ?',
        unsubscribeHomepage: "Aller sur la page d'accueil",
    },
    notAvailable: {
        title: "Cet événement n'est pas disponible",
        description:
            "L'événement que vous recherchez n'est pas disponible. Il a soit été fermé par l'organisateur, soit désactivé par un administrateur",
        fullOverlayEvents: 'Voir vos autres événements',
        fullOverlayNoEvents: 'Aucun autre événement trouvé',
    },
    notFound: {
        notFoundDescription: 'Oups... Aucun événement à trouver ici !',
        notFoundHomepage: "Aller sur la page d'accueil",
    },
    eventMenu: {
        organizerButton: 'ORGANISATEUR',
        coOrganizerButton: 'GÉRER',
        exhibitorButton: 'EXPOSANT',
        posterStudyButton: 'ÉTUDE / AFFICHE',
        boothButton: 'BOOTH',
        boothsButton: 'BOOTHS',
        homeButton: 'ACCUEIL',
        contactsButton: 'CONTACTS',
        meetingsButton: 'RÉUNIONS',
        chatsButton: 'CHATS',
        programButton: 'PROGRAMME',
        archiveButton: 'ARCHIVE',
        myAccountButton: 'MON COMPTE',
        lobbyButton: 'Lobby',
        roomsButton: 'SALLES',
        connectionProblemTextFirst: 'Il semble y avoir un problème de connexion. Veuillez cliquer sur',
        connectionProblemButton: 'ici',
        connectionProblemTextSecond: 'ou rafraîchir la page.',
        sessionProblemTextFirst: 'Votre session est terminée. Veuillez cliquer sur',
        sessionProblemButton: 'ici',
        sessionProblemTextSecond: 'pour se reconnecter.',
    },
    myEventsDropdown: {
        title: 'MES ÉVÉNEMENTS',
        createButtonMobile: 'CREER',
    },
    myBoothsDropdown: {
        title: 'MES STANDS',
        emptyStudyTitle: "STAND D'ÉTUDE / AFFICHE",
    },
    contactsDropdown: {
        title: 'VOS CONTACTS',
        requestsTitle: 'Demandes de contact',
        rejectButton: 'REJETER',
        acceptButton: 'ACCEPTER',
        pendingButton: 'En attente',
        addButton: 'Ajouter',
        noContacts:
            'Vous n\\\'avez pas encore de contacts. Veuillez consulter la section "Mise en réseau" pour commencer à établir votre liste de contacts.',
    },
    meetingsDropdown: {
        meetingWith: 'Réunion avec',
        pending: 'En attente',
        accepted: 'Accepté',
        canceled: 'Annulé',
        noMeetings: "Aucune réunion prévue pour l'instant",
        finished: 'Finie',
    },
    programDropdown: {
        stages: 'Étapes',
        with: 'avec',
        noProgram: 'Aucune entrée de programme disponible pour le moment.',
        fullProgramButton: 'Programme complet', // new translation
        addToCalendarButton: 'Ajouter au calendrier', // new translation
        addedToYourAgenda: 'AJOUTÉ À VOTRE AGENDA',
        addEventButton: 'Ajouter un événement', // new translation
        addToMyAgenda: 'Ajouter à mon agenda', // new translation
        addEventToYourCalendarDialogTitle: 'Ajouter un événement à votre calendrier', // new translation
        addTimeslotToYourCalendar: 'Ajouter un créneau à votre calendrier', // new translation
        tip: 'CONSEIL : ', // new translation
        tipDescription:
            "Vous pouvez également ajouter des créneaux horaires séparés à votre calendrier en cliquant sur l'icône du calendrier à côté de chaque créneau horaire.", // new translation
        tipTimeslotDescription:
            "Vous pouvez également ajouter l'événement complet en cliquant sur le bouton 'Ajouter au calendrier'.", // new translation
        durationEventTitle: "Durée de l'événement : ", // new translation
        timeslotTitle: 'Titre : ', // new translation
        slotScheduled: 'Créneau horaire planifié : ', // new translation
        addSlotToCalendar: 'Ajouter le créneau au calendrier', // new translation
        speakers: 'Orateurs', // new translation
        auditoriumName: "Nom de l'auditorium", // new translation
        timezoneTooltipText:
            "Le programme est affiché dans le fuseau horaire de l'organisateur. Vous pouvez modifier le fuseau horaire d'affichage pour voir les sessions dans votre fuseau horaire préféré", // new translation
        liveLabel: 'En direct', // new translation
        liveLabelTooltipText: "Ce créneau horaire est en direct dans l'auditorium", // new translation
        openAuditorium: "OUVRIR L'AUDITORIUM", // new translation
    },
    notificationsDropdown: {
        title: 'Notifications',
        contactRequestReceived: 'vous a envoyé une demande de contact. Consultez le site !',
        contactRequestAccepted: 'a accepté votre demande de contact. Commencez à discuter !',
        meetingRequestReceived: 'vous a envoyé une demande de rendez-vous. Consultez le site !',
        meetingRequestAccepted: 'a accepté votre demande de réunion. Vérifiez !',
        meetingRequestCanceled: 'a annulé une demande de réunion. Regardez ça !',
        meetingStarting: 'Une réunion va bientôt commencer. Regardez ça !',
        newBoothPost: 'a affiché sur le mur de votre stand. Vérifiez !',
        noNotifications: "Vous n'avez pas encore de notifications.",
        seeAll: 'VOIR TOUT',
        hideAll: 'CACHER TOUT',
        meetingWillStartTextSecond: 'va bientôt commencer. Vérifiez-le!',
    },
    chatsDropdown: {
        title: 'CHATS',
        newChatProvideText: 'Veuillez donner un nom pour votre chat. Vous pourrez modifier le nom par la suite :',
        noChats: "Créer au moins un chat pour parler aux participants à l'événement",
        members: 'Membres',
        noOpenedChatText: 'Cliquez sur un chat pour poursuivre la conversation.',
        noMessagesInChatTextFirst: 'Brisez la glace.',
        noMessagesInChatTextSecond: 'Soyez celui qui commcence cette conversation.',
        newChatNameInput: 'Nom du chat (facultatif)',
        newChatAddMembers: "Ajoutez des contacts et d'autres participants à ce chat :",
        editChatAddMembers:
            'Veuillez rechercher les utilisateurs de cet événement que vous souhaitez ajouter à la discussion de groupe.',
        newChatSearchInput: 'Rechercher des utilisateurs',
        newChatNoUsersMessage: 'Aucun utilisateur trouvé',
        newChatRemoveUserButton: 'Supprimer',
        newChatCancelButton: 'FERMER',
        newChatCreateButton: 'CREER',
        membersLeaveButton: 'QUITTER',
        membersUpdateButton: 'MISE À JOUR',
        membersChatButton: 'Chat',
        chatSettingsButton: 'Paramètres',
        newMessageInput: 'Tapez votre message ici',
        newVideoCallTooltipText: 'Cliquez ici pour démarrer un chat vidéo',
        leaveVideoMeetingTitle: 'Quitter la conférence vidéo',
        leaveVideoMeetingDescription: 'Vous allez quitter la conférence vidéo. Êtes vous sur de vouloir quitter?',
        leaveChatConfirmationTitle: "Quitter l'appel vidéo",
        leaveChatConfirmationText: "Êtes-vous sûr de vouloir quitter l'appel vidéo en cours ?",
    },
    myAccountDropdown: {
        title: 'MON COMPTE',
        viewProfileButton: 'Voir profil',
        logoutButton: 'DÉCONNEXION',
    },
    myAccountPage: {
        changePasswordButton: 'CHANGER DE MOT DE PASSE',
        deleteAccountButton: 'SUPPRIMER MON COMPTE',
        platformLanguage: 'Choisissez votre langue :',
        privacyPolicyLink: 'Politique de confidentialité',
        cookiesLink: 'Cookies',
        termsAndConditionsLink: 'Conditions générales',
        eventProfileSectionTitle: 'Récapitulatif de mon profil',
        eventPackage: "Paquet d'événements",
        unregisterFromEventButton: "SE DÉSINSCRIRE DE L'ÉVÉNEMENT",
        unregisterCurrentEventButton: "Se désinscrire de l'événement en cours",
        unregisterCurrentEventConfirmation: "Veuillez confirmer que vous souhaitez vous désinscrire de l'événement",
        enableParticipantProfile: 'Activer le profil du participant',
        editUserDialogTitle: "Modifier les détails de l'utilisateur",
        editExhibitorDialogTitle: 'Modifier les coordonnées de la personne de contact de la société',
        editScholarDialogTitle: "Modifier les coordonnées de la personne de contact de l'Étude / Affiche",
        editParticipantDialogTitle: 'Modifier les détails du participant',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        uploadImage: "Télécharger l'image",
        removeImage: "Supprimer l'image",
        deleteError:
            'Vous ne pouvez pas supprimer votre compte. Veuillez supprimer tous les événements créés pour pouvoir supprimer votre compte.',
        deleteSuccess: 'Votre compte a été supprimé.',
        reviewEventPrivacyPolicy: 'Consultez notre politique de confidentialité :',
        title: 'Titre',
        company: 'Entreprise',
        phone: 'Téléphone',
        platformProfileSectionTitleTextSecond: 'profil',
        createParticipantProfile: 'Créer un profil de participant',
        reviewPlatformConditions: 'Consultez les conditions de notre platforme',
        reviewEventConditions: 'Consultez les conditions de cet événement',
    },
    changePassword: {
        title: 'CHANGER DE MOT DE PASSE',
        oldPassword: 'Ancien mot de passe',
        oldPasswordError: "L'ancien mot de passe ne correspond pas !",
        inputPassword: 'Nouveau mot de passe',
        inputConfirmPassword: 'Confirmer le mot de passe',
    },
    roomsDropdown: {
        auditoriumButton: 'Auditorium',
        archiveButton: 'ARCHIVE',
        onDemandButton: 'SUR DEMANDE',
    },
    sideMenu: {
        liveWall: 'Mur en direct',
        networking: 'Mise en réseau',
        audience: 'AUDITOIRE',
        info: 'INFO',
        videoWall: 'Mur vidéo',
        booth: 'BOOTH',
        boothWall: 'Mur des conversation',
        networkingTooltip: "Vous n'avez pas accès à toutes les fonctionnalités de mise en réseau.",
        attendees: 'Participants',
    },
    wall: {
        sortBy: 'Trier par :',
        popularity: 'POPULARITÉ',
        time: 'TEMPS',
        emptyWallText: 'Soyez le premier à ajouter un poste ici',
        newPostInput: 'À quoi pensez-vous ?',
        newCommentInput: 'Commentaire',
        resourcesVideoDescription: 'VIDÉO DESCRIPTION',
        resourcesLinks: 'Liens',
        resourcesFiles: 'Fichiers',
        pinTitle: 'Épinglé',
        unPinTitle: 'Désépinglé',
        deleteDialogTitle: 'Supprimer le message?',
        pinDialogDescription:
            "Il y a déjà un message épinglé. Qu'un seul message peut être épinglé à la fois. En continuant, le message précédent sera automatiquement désépinglé.",
        edited: 'Édité',
        escapeToCancel: 'échap. pour annuler',
        enterToSave: 'entrée pour sauver',
        editingState: 'Modification du message',
        bannerModeration: 'Les messages et commentaires sur ce mur sont modérés pour garantir un contenu de qualité',
        pendingApproval: 'En attente de validation',
        approvePost: 'Valider la publication',
        approvePostComment: 'Valider le commentaire',
        commentsPendingApproval: 'Commentaire en attente de validation',
    },
    networking: {
        searchInput: 'Recherche par utilisateur',
        emptyNetworkingSearchResults: "La personne que vous recherchez ne s'est pas inscrite à cet événement en ligne",
        emptyAuditoriumNetworkingSearchResults:
            "La personne que vous recherchez n'a pas encore assisté à cette présentation",
        alphabetical: 'abc',
        online: 'en ligne',
        matching: 'connexions',
        matchingAlgo:
            "Algorithme de connexion en action. Veuillez patienter une minute pendant que nous trouvons d'autres profils qui vous correspond le mieux.",
        searchFilters: 'Filtres de recherche',
        applyFilterTooltip: 'Pour appliquer des filtres, sélectionnez au moins 1 option.',
        chat: 'Chat',
    },
    closedAuditoriumDialog: {
        title: "Informations sur l'auditorium",
        contentPackage: "Votre package d'accès n'a pas accès aux auditoriums.",
    },
    closedShowfloorDialog: {
        title: 'Informations sur le showfloor',
        contentPackage: "Votre package d'accès n'a pas accès au showfloor.",
        button: 'OK',
    },
    auditorium: {
        willStart: 'La prochaine séance débutera dans...',
        noAccess: "Votre package d'accès n'a pas accès à cet auditorium.",
        noVideos: "Aucune vidéo n'est prévue dans cet auditorium.",
        stageTab: 'STAGE',
        videoWallButton: 'Mur vidéo',
        resourcesButton: 'RESSOURCES',
        slotEndsTextFirst: 'Ce programme se termine par',
        slotEndsTextSecondUpdated: 'Cette presentation sera disponible sur demande.',
        slotTitleWith: 'avec',
        allAuditoriums: 'Tout les auditorium',
        onDemandButton: 'SUR DEMANDE',
        hide: 'Cacher',
        leavePopupTitle: "Quitter l'auditorium?",
        leavePopupDescription: "En quittant l'auditorium, vous quitterez la session",
        leaveSession: "Quitter l'auditorium",
        cancelDescription: 'En quittant, vous ne serez plus conférencier. Voulez-vous continuer?',
        settings: 'Paramètres',
        minimize: 'Minimiser',
        microphone: 'Microphone',
        camera: 'Camera',
        auditoriums: 'Auditoriums',
    },
    auditoriumArchive: {
        title: "Archives de l'auditorium",
        titleEvent: 'Archives des événements',
        with: 'avec',
        noVideosAvailable: 'Toutes les séances sont terminées',
    },
    videoPlayer: {
        videoNotPlayingError: "Des problèmes avec le flux vidéo ? Rechargez s'il vous plaît.",
        videoEndedTextFirst: "La vidéo est terminée. Merci d'avoir regardé !",
        videoEndedTextSecond: 'Le mur vidéo est ouvert aux questions et réponses.',
        tooltipPlay: 'Jouer',
        tooltipPause: 'Pause',
        tooltipMinimize: 'Réduire',
        tooltipFullscreen: 'Plein écran',
        tooltipMute: 'Mute',
        tooltipUnmute: 'Non-mute',
        videoStreamNotStarted: 'Bientôt en direct. Restez connectés!',
    },
    showfloor: {
        allExhibitorsButton: 'TOUS LES EXPOSANTS',
        featuredExhibitorsText: 'EXPOSANTS VEDETTES',
        boothLinksDialogTitle: 'LIENS',
        boothFilesDialogTitle: 'FICHIERS',
        boothVideoDialogTitle: 'VIDÉO',
        mobileTabChat: 'Chat',
        mobileTabPoster: 'AFFICHE',
        mobileTabFiles: 'Fichiers',
        mobileTabLinks: 'Liens',
        mobileTabVideo: 'VIDÉO',
        noExhibitorsTextFirst: 'LES EXPOSANTS TRAVAILLENT SUR LEURS STANDS',
        noPresentersTextFirst: 'Les exposants travaillent sur leurs stands',
        noExhibitorsTextSecond: 'RESTEZ CONNECTÉS',
        showShowfloorsButton: 'ALL EXHIBIT HALLS',
        showShowfloorsPosterButton: 'TOUTES LES ÉTUDES / AFFICHES',
        showShowfloorsExhibitorButton: 'TOUS LES EXPOSANTS',
        searchExhibitors: 'Rechercher exposants',
        searchPosters: 'Rechercher par titre ou auteur',
        searchExhibitorsMultiple: "Rechercher exposants dans cette salle d'exposition",
        searchPostersMultiple: "Rechercher par titre ou auteur dans cette salle d'exposition",
        showfloorsTitle: 'Exhibit Halls',
        collapseList: 'Réduire la liste',
        seeFullList: 'Voir liste entière',
        emptyExhibitorsResults: "Cet exposant n'est pas disponible",
        emptyPostersResults: "Cette étude / affiche n'est pas disponible",
        posters: 'affiches',
        allPostersStudiesTitle: 'Toutes les Études / Affiches',
        authors: 'auteurs',
        titleNotAvailable: "Ce titre n'est pas disponible",
        abstractNotAvailable: "Ce résumé n'est pas disponible",
        authorsNotAvailable: "Cet auteur n'est pas disponible",
        openPosterFile: 'Ouvrir affiche',
        fileIsNotAvailable: "Ce fichier n'est pas disponible",
        posterBooth: {
            study: 'Étude / Affiche',
            authors: 'Auteurs',
            highlights: 'Résumé',
            scrollInfoText: 'DÉFILER VERS LE BAS POUR LIRE LE RÉSUMÉ',
        },
    },
    eventArchive: {
        title: 'Archives vidéo',
        searchText: 'Appuyez sur pour rechercher',
        noArchivedEvents: "Il n'y a actuellement aucun événement archivé.",
    },
    exhibitorDashboard: {
        event: 'Événement',
        title: 'Gérer votre stand',
        visitorsButton: 'Visiteurs du stand',
        goToBoothPreview: 'Aperçu du stand',
        previewButton: 'Aperçu',
        tabStyle: 'Style de la cabine',
        tabLogo: 'Logo',
        tabStudyDetails: 'Étude / Affiche',
        tabFiles: 'Fichiers',
        tabStudyFile: 'Fichier',
        tabLinks: 'Liens',
        tabAvatars: 'Avatars',
        tabStudyAuthors: 'Auteurs',
        tabVideo: 'Vidéo',
        shareText: 'COPIER LE LIEN',
        theBoothUrlCopiedText: "L'URL du stand a été copiée avec succès dans votre presse-papiers",
        optionDisabledText: 'Cette option est actuellement indisponible pour ce stand',
        boothStyleTab: {
            optionOne: 'Option 1 - Scandinave',
            optionTwo: 'Option 2 - Moderne',
            optionThree: 'Option 3 - Dynamique',
            optionFour: 'Option 4 - Personnalisé',
            optionScholarOne: 'Option 1 - Einstein',
            optionScholarTwo: 'Option 2 - Curie',
            optionScholarThree: 'Option 3 - da Vinci',
        },
        logoTab: {
            fileUploadButton: 'Cliquez ici pour télécharger',
            noFile: 'Aucun dossier choisi',
        },
        posterStudyTab: {
            posterStudyTitle: 'Titre',
            studyTitleInputLabel: "Titre de l'Étude / Affiche",
            highlightAbstractTitle: 'Résumé',
            studyHighlightsInputLabel: 'Résumé de la recherche',
            highlightAbstractError: 'Vous avez atteint le nombre maximum caractères! (500)',
            authorNameLabel: "Nom de l'auteur",
            authorAffiliationLabel: "Affiliation de l'auteur",
        },
        filesTab: {
            title: 'Documents :',
            fileNameInput: 'Nom du document (visible par tous les participants)',
            chooseFileButton: 'Choisissez un fichier',
            noDocAdded: 'Aucun document ajouté',
            fileError: 'Veuillez ajouter un nom de document',
        },
        fileStudyTab: {
            title: 'Télécharger fichier pdf',
            description:
                'Téléchargez le poster : document ou présentation qui sera disponible dans votre stand (limite de 20 Mb. de fichier)',
            uploadPdfInputLabel: 'Télécharger fichier PDF',
            uploadNewPdfInputLabel: 'Télécharger un nouveau fichier PDF',
            uploadedFile: 'Téléchargé',
            dragContainerTitle: 'Simplement faites glisser le fichier içi',
            dragContainerNewTitle: 'Simplement faites glisser le nouveau fichier içi',
        },
        linksTab: {
            social: {
                title: 'Liens vers les médias sociaux',
                subtitle: '(visible par tous les participants)',
                facebookInput: 'Insérez votre lien Facebook',
                linkedinInput: 'Insérez votre lien LinkedIn',
                twitterInput: 'Insérez votre lien Twitter',
                googleScholarInput: 'Insérer votre lien Google Scholar',
                researchGateInput: 'Insérer votre lien Research Gate',
            },
            other: {
                linkInput: 'Insérer un nouveau lien',
                linkLabel: 'Insérer une nouvelle étiquette de lien',
            },
        },
        linksStudyTab: {
            authorProfiles: "Profils d'auteurs",
            additionalLinks: 'Liens supplémentairs',
        },
        avatarsTab: {
            mainRepresentatives: {
                title: 'Représentants des stands',
                leftRepresentative: 'Représentant de gauche',
                rightRepresentative: 'Représentant de droite',
                addDialog: {
                    title: 'Ajouter un avatar',
                    titleScholar: 'Ajouter un auteur',
                    editAvatarsTitle: 'Editer un avatar',
                    editAuthorTitle: 'Editer un auteur',
                    contentLine1: 'Choisissez un avatar dans la galerie ci-dessous',
                    contentLine2: 'Téléchargé votre propre avatar personnalizé',
                    contentLine3: "Besoin d'aide? Entrez en contact avec",
                    assignButtonText: 'Désigner',
                    assignText:
                        "Veuillez désigner un participant à l'événement qui sera associé au représentant ci-dessus.",
                    assignErrorBooth: "L'utilisateur est déjà ajouté comme représentant de Booth",
                    assignErrorAdditional: "L'utilisateur est déjà ajouté comme représentant supplémentaire",
                    input: 'Recherche par nom ici',
                },
            },
            additionalRepresentatives: {
                title: 'Représentants supplémentaires',
                subtitle: '(optionnel, sera affiché avec leur photo de profil)',
                addDialog: {
                    title: 'Choisir un représentant supplémentaire',
                    titleScholar: 'Choose Co-Author',
                    assignText:
                        "Veuillez commencer à chercher un participant à l'événement pour l'ajouter en tant que représentant.",
                    assignError: "L'utilisateur est déjà ajouté comme représentant de Booth !",
                },
            },
            author: {
                title: 'Presenting Author',
                subtitle: 'Add only an author that registered to the event. You can add 1 author.',
                mainRepresentative: 'Main representative',
            },
            coAuthors: {
                title: 'Coauteurs',
                singularTitle: 'Coauteur',
                subtitle: 'Add only co-authors that registered to the event. You can add up to 4 co-authors.',
            },
        },
        videoTab: {
            video: {
                title: 'Vidéo du stand',
                subtitle: '(Lien vidéo YouTube uniquement)',
                youtubeOrVimeoOnly: '(lien YouTube ou Vimeo uniquement)',
                input: "Entrez l'URL YouTube",
                inputYoutubeOrVimeo: "Entrez l'URL de la vidéo YouTube ou Vimeo",
            },
            image: {
                subtitle:
                    "(L'image est affichée dans votre stand au cas où vous n'auriez pas ajouté un lien vidéo dans la section ci-dessus)",
            },
        },
    },
    boothDashboard: {
        event: 'Événement',
        title: 'Votre stand',
        manage: 'Gérer',
        statistics: 'Statistiques',
        uniqueVisitors: 'Visiteurs Uniques', // new translation
        currentOnlineVisitors: 'Visiteurs En Ligne Actuels', // new translation
        totalNumberOfVisits: 'Nombre Total De Visites', // new translation
        totalDocumentClicks: 'Nombre Total De Clics Sur Le Document', // new translation
        totalLinkClicks: 'Nombre Total De Clics Sur Le Lien', // new translation
        totalRepresentativesClicks: 'Nombre Total De Clics Des Représentants', // new translation
        totalVideoViews: 'Nombre Total De Vues Vidéo', // new translation
        totalPostsCount: 'Nombre Total De Publications', // new translation
        totalLikesCount: 'Nombre Total De Likes', // new translation
        totalCommentsCount: 'Nombre Total De Commentaires', // new translation
        showUsers: 'Afficher Les Utilisateurs', // new translation
        dataType: 'Type De Données', // new translation
        value: 'Évaluer', // new translation
        posts: 'Des Postes', // new translation
        likes: 'Aime', // new translation
        comments: 'Commentaires', // new translation
        topPostLikes: 'Top Des Messages Aimés', // new translation
        topPostComments: 'Principaux Commentaires Des Publications', // new translation
        totalVotes: 'Total Des Votes', // new translation
    },
    polls: {
        polls: 'sondage',
        noPolls: "Aucun sondage n'a encore été ajouté.",
        pollProgress: 'Sondage en cours',
        pollClosed: 'Sondage fermé',
        pollVoted: 'participants voted',
    },
    onDemandVideos: {
        sectionTitle: 'SUR DEMANDE',
        noOnDemandVideos: "Aucune vidéo sur demande n'a été ajoutée",
    },
    meetings: {
        meeting: 'RÉUNION',
        createDialog: {
            createTitle: 'Demande de réunion avec',
            inputDate: 'Date',
            inputStart: 'Départs',
            inputEnd: 'Fin',
            inputTimezone: 'Fuseau horaire',
            inputNote: 'Note : Une seule réunion peut être programmée à la fois avec la même personne.',
            meetingError: 'Date ou heure de réunion non valide. Veuillez revérifier!',
            buttonDisabled: 'Vous pouvez planifier une réunion après avoir commencé la conversation!',
        },
        banner: {
            pending: 'Demande de réunion',
            accepted: 'Réunion prévue pour',
            progress: 'Réunion en cours',
            cancelTitle: 'Annuler une réunion ?',
            cancelDescription: 'Vous êtes sûr de vouloir annuler cette réunion ?',
            startCall: "COMMENCER L'APPEL",
            joinCall: 'REJOIGNEZ',
            pendingOwner: 'Demande de réunion en attente pour',
            videoCallStarted: 'La conférence vidéo a commencée',
            videoCallStartedInfo: 'La conférence vidéo fonctionne le mieux avec 10 utilisateurs ou moins',
        },
        title: 'Vos rendez-vous',
        meetingsInfo:
            "Des réunions peuvent être programmées à partir de chats. Veuillez démarrer un chat avec l'utilisateur avec lequel vous souhaitez programmer une réunion.",
    },
    socialMediaShare: {
        event: 'Événement',
        shareLabel: 'Partager',
        dialogTitleGeneral: 'Partager avec votre réseau',
        dialogTitlePost: 'Partager votre publication',
        linkedinLabel: 'LinkedIn',
        facebookLabel: 'Facebook',
        twitterLabel: 'Twitter',
        emailLabel: 'Email',
        clipboardLabel: 'Presse-papiers',
        clipboardSnackbarMessage: 'Lien copié dans le Presse-papiers',
        linkedinButtonTitle: 'Partager sur LinkedIn',
        facebookButtonTitle: 'Partager sur Facebook',
        twitterButtonTitle: 'Partager sur Twitter',
        emailButtonTitle: 'Partager par email',
        clipboardButtonTitle: 'Copier dans le Presse-papiers',
        imAttending: 'Je participe dans le',
        interestedClick: 'Intéressé à participer? Cliquez ici:',
    },
    videoConferences: {
        fullScreen: 'OUVRIR PLEIN ÉCRAN',
        minimizeScreen: "MINIMISER L'ÉCRAN",
        startedVideoChat: 'a commencé(e) un appel vidéo',
        timeAgo: 'il y a',
        videoCallStarted: "L'appel vidéo a commencé",
    },
    sessions: {
        edit: 'ÉDITER',
        speakers: 'CONFÉRENCIER',
        speakerSingular: 'conférencier',
        moderators: 'MODÉRATEURS',
        moderatorSingular: 'modérateur',
        video: 'VIDÉO',
        startStream: 'COMMENCER LE STREAM',
        stopStream: 'STOP STREAM',
        sessionChat: 'CHAT DE SÉANCE',
        speakerUrl: 'URL du conférencier',
        moderatorUrl: 'URL du modérateur',
        copyUrl: 'copier le lien',
        noSpeakersJoined: "Aucun conférencier n'a encore rejoint",
        allFilter: 'tout',
        waitingRoom: "Salle d'attente",
        isReady: 'Est Prêt(e)',
        invitationAccepted: "L'invitation a été acceptée",
        requestPending: 'La demande est en attente',
        gettingReady: 'En préparation',
        inviteToSpeak: 'Inviter à parler',
        fromAuditorium: "depuis l'Auditorium",
        mic: 'Microphone',
        screen: 'Écran',
        rec: 'rec',
        sessionHasEnded: 'La séance est terminée',
        sessionLinks: 'LIENS DE SESSION',
        sessionSettings: 'Paramètres',
        noAvailableChat:
            'Aucune discussion disponible. Pour commencer une discussion, veuillez allouer cette séance à un créneau horaire.',
        startLiveSessionStreamTitle: 'Commencer une séance en directe',
        startLiveSessionDescription:
            "L'enregistrement de la séance commence automatiquement quand vouz commencez la séance.",
        stopLiveSessionStreamTitle: 'Arreter la séance en directe.',
        stopLiveSessionStreamDescription:
            "L'enregistrement de la séance se termine automatiquement quand vous arrêtez la séance. L'enregistrement sera bientôt disponible.",
        thumbnailImage: 'Vignette',
        uploadThumbnail: 'Télécharger une vignette',
        clickToUpload: 'Cliquez ici pour télécharger',
        requestMic: 'DEMANDER LE MICROPHONE',
        acceptInvitationTitle: 'Vous êtes invité(e)',
        acceptInvitationSubTitle: "Le modérateur vous a invité(e) à parler. Êtes vous d'accord?",
        acceptInvitationButton: "Accepter l'invitation",
        requestPendingTitle: 'Votre demande est en attente',
        requestPendingSubTitle: 'Veuillez attendre que le modérateur vous invites à la séance',
        cancelRequest: 'Annuler la demande',
        approvedTitle: 'Veuillez vérifier votre microphone et votre vidéo et cliquez "Je suis prêt."',
        approvedSubTitle:
            "Cette séance est en cours d'enregistrement. En rejoignant cette séance, vous acceptez de partager vos données personnelles.",
        connectedToTheBackstage: "Vous diffusez maintenant en direct et êtes connecté aux coulisses de l'auditorium.",
        removedFromTheSession: 'Vous avez été expulsé de la séance, veuillez contacter un modérateur.',
        imReady: 'Je suis prêt',
        isTryingToRegister: "essaie de s'inscrire en tant que",
        hasLeftTheSession: 'a quitté la session',
        stopVideo: 'Arreter la vidéo',
        startVideo: 'Commencer la vidéo',
        disabledScreenSharing: "Désactivez votre partage d'écran avant de commencer votre vidéo",
        disabledByModerator: 'Désactivé par le modérateur',
        disabledUntilReady: "Désactivé jusqu'à prêt",
        stopShareScreen: "Arreter votre partage d'écran",
        shareScreen: 'Partager votre écran',
        disabledVideoSharing: "Désactivez votre vidéo avant de commencer votre partage d'écran",
        anotherUserIsScreenSharing: "Quelqu'un d'autre partage déjà son écran",
        removeRoleDescription: 'Êtes-vous sûr de vouloir supprimer cela?',
        declinedInvitation: "a refusé l'invitation d'être conferencier",
    },
    gdpr: {
        disclaimerTextFirst:
            "Ce document a été traduit par un logiciel d'intelligence artificielle. En cas d'incohérence, veuillez référer à la version originale du document",
        disclaimerTextSecond: 'IÇI',
        metaTitlePrivacyPolicy: 'Politique de confidentialité',
        metaTitleCookiesPolicy: 'Parametrer les cookies',
        metaTitleTerms: 'Termes et conditions',
        updateCookieConsent: 'Mettre à jour le consentement des cookies',
        changeYourConsent: 'Changer votre consentement',
        withdrawYourConsent: 'Retirer votre consetement',
        withdrawSnackbarText: 'Votre consetement au cookies a été retiré',
    },
    infoMessages: {
        imageExplain420x200: "Rapport d'image recommandé 1,8:1 (orienté paysage, par exemple 420 x 200 pixels)",
        imageExplainTB230x320:
            "Rapport d'image recommandé 1:1,4 (orienté portrait, par exemple 230 x 320 pixels) avec fond transparent",
        imageExplainTB230x875:
            "Rapport d'image recommandé 1:3,8 (orienté portrait, par exemple 230 x 875 pixels) avec fond transparent",
    },
    matching: {
        matchingQuestionnaireTitle: 'Questionnaire de connexion',
        matchingQuestionnaireIntro:
            "Connectez-vous à d'autres profils qui vous correspondent! Les meilleures connexions mènent toujours à des résultats incroyables - remplissez le questionnaire pour commencer.",
        matchingQuestionnaireIntroSkip:
            'En sautant le questionnaire, nous ne pourrons pas vous connecter avec d\\\'autres profils qui vous correspondent. Vous pouvez toujours remplir le questionnaire plus tard dans la section "Mise en réseau". Toutes les réponses sont modifiables dans Mon Compte.',
        doItLaterBtn: 'REMPLIR PLUS TARD',
        nextBtn: 'Prochain',
        backBtn: 'Retour',
        finishBtn: 'Terminer',
        seeMatchesBtn: 'VOIR TOUTES VOS CONNEXIONS',
        tryMatchingBtn: 'ESSAYER CONNEXION INTELLIGENTE',
        optionalQuestion: 'Optionnelle',
        requiredQuestion: 'Obligatoire',
        singleSelect: 'Séléction unique',
        multiSelect: 'Séléction multiple',
        mediumMatch: 'Connexion moyenne',
        highMatch: 'Connexion forte',
        matchLabel: 'Connexion',
        matchesLabel: 'Connexions',
        infoTooltipJump: 'Cliquez ici pour voir vos connexions',
        infoTooltipClickToSee1: 'Cliquez sur',
        infoTooltipClickToSee2: 'pour voir avec qui vous devez vous connecter!',
        loadingAlgoText:
            "L'algorithme de connexion est en marche. Patientez une minute lorsque nous trouvons les profils qui vous correspondent le mieux!",
        matchingDetailsFormTitle: 'Détails de connexions intelligentes',
        tryMatchingBanner: 'Établissez une connexion importante, essayez notre fonction Connexion Intelligente!',
        matchingBannerClosed:
            'Vous pouvez toujours remplir le questionnaire Connexion Intelligente plus tard dans la section "Mise en réseau".',
        resultsTitle: 'Voici les profils qui vous correspondent le mieux',
        resultsNoteLabel: 'NOTE:',
        resultsNoteText: 'Vous pouvez trouver toutes vos connexions dans la section "Mise en réseau".',
        resultsNoteMyAccount: 'Toutes réponses peuvent être modifier dans le menu Mon Compte',
        btwResultsResponsive: 'Vous pouvez trouver toutes vos connexions dans la section "Mise en réseau".',
        noMoreMatches:
            'Oh non! Nous ne trouvons pas de connexions pour vous en ce moment. Revenez voir cette section quand plus de participants ont terminé le questionnaire.',
        noResultsTitle: "Félicitations! Nous cherchons à l'instant les profils qui vous correspondent le mieux.",
        noResults:
            "Merci d'avoir terminé le questionnaire de Connexion Intelligente! Nous cherchons à l'instant les profils qui vous correspondent le mieux.",
        clickStartChat: "Cliquez 'chat' pour commencer une conversation.",
        closeMatchingTitle: 'Fermer le questionnaire Connexion Intelligente?',
        closeMatchingTextA:
            'Si vous fermez le questionnaire avant de le remplir, nous ne garderons aucun détail et les résultats de la Connexio Intelligente ne vous seront pas accessibles.',
        closeMatchingTextB:
            "Votre profil ne fera pas partie de la Connexion Intelligente et vous n'aurez pas accés au profils qui vous correspondent le mieux.",
        matchingTooltip: "Cliquez sur 'Connexions' pour voir les profils qui vous correspondent le mieux!",
        myAccountMatchingSectionTitle: 'Détails de connexions intelligentes',
        matchingRequiredError: 'Vous devez répondre à toutes les questions Obligatoires',
        rejectMatchingTitle: 'Rejeter participation de Connexion Intelligente?',
        rejectMatchingText:
            "Vos réponses seront effacées et vous ne serez pas mis en correspondance avec d'autres profils. Vous pouvez reprendre le questionnaire de Connexion Intelligente à tout moment.",
        rejectMatchingBtn: 'REJETER PARTICIPATION',
    },
    welcomeScreen: {
        goToEvent: "Aller à l'événement",
    },
    emailCodeVerification: {
        codeSent: 'Nous avons envoyé un code de vérification à votre adresse électronique :',
        continueRegistration: "Pour continuer l'enregistrement,",
        enterCodeBelow: 'entrez le code de vérification ci-dessous :',
        codeWillExpire: 'Ce code expirera dans',
        codeExpired: 'Le code a expiré',
        codeNotReceived: "Vous n'avez pas reçu le code ? Avez-vous vérifié le dossier des courriers indésirables ?",
        resendTimer: 'Renvoyez-le dans :',
        resendLabel: 'Renvoyer',
        resendCodeLabel: 'Renvoyer le code',
        codeError: 'Le code saisi est incorrect.',
        codeErrorFix: 'Saisissez à nouveau le code correct depuis votre courriel ou envoyez un nouveau code.',
        congrats: 'Félicitations !',
        emailConfirmed: 'Votre email a été confirmé :',
    },
    privacyPolicyUpdates: {
        learnMore: 'Learn more', // new translation
        lastUpdated: 'Last updated', // new translation
        publishedBy: 'Published by', // new translation
        ppuLinkLabel: 'Updated privacy policy', // new translation
        ppuEventLinkLabel: 'Updated event privacy policy', // new translation
        acceptAllButtonLabel: 'Accept all updates', // new translation
    },
    voting: {
        votingText: 'VOTE',
        enableVoting: 'ACTIVER LE VOTE',
        enableVotingDescription: "Cliquez sur l'icône Actualiser pour obtenir les résultats les plus précis",
        displayVotingNumbersForExhibitors: 'AFFICHER LES NUMÉROS DE VOTE DES EXPOSANTS & AFFICHE/ÉTUDE',
        displayVotingNumbersForExhibitorsDescription: "Sera affiché dans le tableau de bord de l'exposant",
        displayVotingNumbersForParticipants: 'AFFICHER LES NUMEROS DE VOTE DES PARTICIPANTS',
        displayVotingNumbersForParticipantsDescription: 'Sera affiché pour tous les Participants',
        characterError80: 'Vous avez atteint la limite maximale de caractères autorisés (80 caractères)',
        characterDescription:
            'Le texte est limité à 80 symboles. Le texte apparaîtra comme titre principal sur le ruban de vote. Rendez-le significatif pour les participants',
        deleteAllVotes: 'SUPPRIMER TOUS LES VOTES',
        downloadVotingResults: 'TÉLÉCHARGER LES RÉSULTATS DU VOTE',
        voteText: 'Voter',
        votedText: 'Voté',
        votesText: 'Vote',
        participant: 'participant',
        participants: 'participants',
        deleteVotesTextFirst: 'ATTENTION:',
        deleteVotesTextSecond: "c'est une action irréversible.",
        deleteVotesTextThird: "N'oubliez pas de télécharger les résultats du vote avant de les supprimer.",
        checkTheBoxToCastYourVote: 'Cochez la case pour voter',
        toVotePleaseEnableParticipantProfile: 'Pour voter, veuillez activer votre profil Participant',
    },
};

export default fr;
