import React from 'react';
import {useSelector} from 'react-redux';
import {Switch} from '@material-ui/core';
import {Cancel as CancelIcon, CheckCircle as CheckCircleIcon} from '@material-ui/icons';

import Trash from '@material-ui/icons/Delete';
import {useChangePermission} from '../../../../../../services/hooks/sessions/useChangePermission';

import {PARTICIPANT_TYPES, PROFILE_STATUSES} from '../../../../../../Utils/constants/organizer/sessions';
import {getUsers} from '../../../../selectors';

export const PermissionUser = ({user, currentUser, sessionId}) => {
    const {status, name, screenSharing, allowScreenSharing, audio, allowAudio, video, allowVideo, userId, role, connected} = useSelector((state) =>
        getUsers(state)
    ).find(({userId}) => user.userId === userId);
    const sessionEnded = false;

    const {
        handleAdmitUser,
        handleToggleScreenShare,
        handleToggleAudio,
        handleToggleVideo,
        handleKickUser,
        handleToggleScreenShareAuthorization,
        handleToggleAudioAuthorization,
        handleToggleVideoAuthorization
    } = useChangePermission({sessionId, user});

    const toggleAudio = (e) => {
        if(userId === currentUser.userId){
            if(!allowAudio){
                return
            }
            handleToggleAudio();
        } else{
            handleToggleAudioAuthorization();
        }
    }

    const toggleVideo = (e) => {
        if(userId === currentUser.userId){
            if(!allowVideo){
                return
            }
            handleToggleVideo();
        } else{
            handleToggleVideoAuthorization();
        }
    }

    const toggleScreenShare = (e) => {
        if(userId === currentUser.userId){
            if(!allowScreenSharing){
                return
            }
            handleToggleScreenShare();
        } else{
            handleToggleScreenShareAuthorization();
        }
    }

    return (
        <div className="module-row">
            <div className="d-flex">
                <div className="avatar">{name.charAt(0)}</div>
                <span className="avatar-name ellipsis">{name}</span>
            </div>
            {!connected && status === PROFILE_STATUSES.approved && <div>User is offline</div>}
            <div className="d-flex">
                {status === PROFILE_STATUSES.approved &&
                    !!connected &&
                    (currentUser.role !== PARTICIPANT_TYPES.speaker ||
                        (currentUser.role === PARTICIPANT_TYPES.speaker && userId === currentUser.userId)) && (
                        <>
                            <div className="module-action_column">
                                {role !== PARTICIPANT_TYPES.organizer &&
                                    currentUser.role !== PARTICIPANT_TYPES.speaker &&
                                    userId !== currentUser.userId && (
                                        <Trash
                                            style={!sessionEnded ? {cursor: 'pointer'} : {}}
                                            onClick={() => {
                                                handleKickUser();
                                            }}
                                        />
                                    )}
                            </div>
                            <div className="module-action_column" onClick={toggleScreenShare}>
                                {currentUser.role === PARTICIPANT_TYPES.speaker
                                    ?<Switch disabled={sessionEnded || !allowScreenSharing} checked={screenSharing} color="secondary"/>
                                    :<Switch disabled={sessionEnded} checked={allowScreenSharing} className={`${screenSharing ? 'is-sharing' : ''}`} color="secondary"/>
                                }
                            </div>
                            <div className="module-action_column" onClick={toggleAudio}>
                                {currentUser.role === PARTICIPANT_TYPES.speaker
                                    ?<Switch disabled={sessionEnded || !allowAudio} checked={audio} color="secondary"/>
                                    :<Switch disabled={sessionEnded} checked={allowAudio} className={`${audio ? 'is-sharing' : ''}`} color="secondary"/>
                                }
                            </div>
                            <div className="module-action_column" onClick={toggleVideo}>
                                {currentUser.role === PARTICIPANT_TYPES.speaker
                                    ?<Switch disabled={sessionEnded || !allowVideo} checked={video} color="secondary"/>
                                    :<Switch disabled={sessionEnded} checked={allowVideo} className={`${video ? 'is-sharing' : ''}`} color="secondary"/>
                                }
                            </div>
                        </>
                    )}
                {status !== PROFILE_STATUSES.approved && currentUser.role !== PARTICIPANT_TYPES.speaker && (
                    <>
                        {status === PROFILE_STATUSES.pending ? (
                            <div className="">
                                <span>waiting...</span>
                            </div>
                        ) : (
                            <div className="">
                                <button type="button" className="button" onClick={() => handleKickUser()}>
                                    <CancelIcon className="button_icon" />
                                    <span>deny</span>
                                </button>
                            </div>
                        )}
                        <div className="">
                            <button type="button" className="button -colored" onClick={() => handleAdmitUser()}>
                                <CheckCircleIcon className="button_icon" />
                                <span>admit</span>
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
