import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {TextValidator} from 'react-material-ui-form-validator';
import classnames from 'classnames';

import {useRef} from 'react';
import {useSelector} from 'react-redux';

const CheckboxFieldLoginRegistration = ({field, fieldValues, handleChange, isTooltipDisabled = false}) => {
    const inputValidator = useRef(null);

    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const handleInputChange = (e) => {
        let updatedOptions = [...field.value];
        if (e.target.checked) {
            updatedOptions.push(e.target.value);
        } else {
            updatedOptions = updatedOptions.filter((option) => option !== e.target.value);
        }
        handleChange(field.name, updatedOptions);
    };

    const isValid = inputValidator?.current?.isValid();
    const isRequired = field.validators.includes('required');

    return (
        <>
            <TextValidator
                value={field.value}
                validators={field.validators}
                errorMessages={field.errorMessages}
                ref={inputValidator}
                className={`hidden-input-and-only-display-error-message ${field.type}-${field.name}-mo-error`}
            />
            <FormControl className="break-word" component="fieldset" fullWidth={true}>
                {!isTooltipDisabled && <FormLabel component="legend">{field.label}</FormLabel>}

                <FormGroup
                    aria-label={field.label}
                    name={field.name}
                    value={field.value}
                    className={classnames(
                        'registration-radio-group',
                        {'registration-usage': isTooltipDisabled},
                        {
                            'error-border': isValid === false,
                        }
                    )}
                >
                    {' '}
                    {isTooltipDisabled && (
                        <FormLabel className={classnames('break-word', 'registration-usage')} component="legend">
                            {field.label}{' '}
                            {!isRequired
                                ? ` (${
                                      translation.newLoginRegistrations.optional ||
                                      defaultTranslation.newLoginRegistrations.optional
                                  })`
                                : ''}
                        </FormLabel>
                    )}
                    {field.options.map((option) => {
                        let checked = false;
                        if (fieldValues.includes(option)) {
                            checked = true;
                        }
                        return (
                            <div
                                className={classnames(
                                    'input-wrapper',
                                    {
                                        'show-tooltip': option.length > 25,
                                    },
                                    {'extra-padding': isTooltipDisabled}
                                )}
                            >
                                {!isTooltipDisabled && (
                                    <div className="tooltip">
                                        <div>
                                            <p>{option}</p>
                                        </div>
                                    </div>
                                )}
                                <FormControlLabel
                                    value={option}
                                    control={<Checkbox onChange={handleInputChange} color="secondary" />}
                                    label={option}
                                    key={option}
                                    checked={checked}
                                />
                            </div>
                        );
                    })}
                </FormGroup>
            </FormControl>
        </>
    );
};

export default CheckboxFieldLoginRegistration;
