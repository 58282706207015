import React from 'react';
import Button from '@material-ui/core/Button';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {preventDefaultDrag} from '../../../../Utils/utils';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const SinglePreviousEventDetails = (props) => {
    return (
        <>
            <h3>
                <span onDragStart={preventDefaultDrag} draggable="false" className="name previous-event-name">
                    {props.previousEvent.label}
                </span>
                <div onDragStart={preventDefaultDrag} className="actions-container">
                    <Button
                        type="button"
                        onClick={props.handleOpenEditPreviousEventDialog}
                        startIcon={<EditOutlinedIcon />}
                    >
                        Edit
                    </Button>
                    <Button
                        type="button"
                        onClick={props.handleDeletePreviousEvent}
                        startIcon={<DeleteOutlineOutlinedIcon />}
                    >
                        Delete
                    </Button>
                </div>
            </h3>
            <div>
                <p>
                    <span onDragStart={preventDefaultDrag} draggable="false" className="label">
                        URL:
                    </span>{' '}
                    {props.previousEvent.url}
                </p>
            </div>
        </>
    );
};

export default SinglePreviousEventDetails;
