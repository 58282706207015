import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    loading: false,
    addEmailDomainLoading: false,
    hasRestrictedEmailAccess: false,
    emailDomainsWithUsers: [],
    error: '',
    successDelete: '',
}

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.CLEAR_ERROR:
                draft.error = null;
                break;

            case actionTypes.GET_EMAIL_DOMAINS_WITH_USERS_START:
            case actionTypes.CHECK_RESTRICTED_EMAIL_ACCESS_START:
            case actionTypes.UPDATE_EMAIL_DOMAIN_START:
                draft.successDelete = '';
                draft.loading = true;
                break;

            case actionTypes.ADD_EMAIL_DOMAIN_START:
                draft.successDelete = '';
                draft.addEmailDomainLoading = true;
                break;

            case actionTypes.ADD_EMAIL_DOMAIN_FAIL:
                draft.addEmailDomainLoading = false;
                draft.error = action.payload;
                break;

            case actionTypes.DELETE_EMAIL_DOMAIN_FAIL:
            case actionTypes.UPDATE_EMAIL_DOMAIN_FAIL:
            case actionTypes.GET_EMAIL_DOMAINS_WITH_USERS_FAIL:
            case actionTypes.CHECK_RESTRICTED_EMAIL_ACCESS_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;


            case actionTypes.GET_EMAIL_DOMAINS_WITH_USERS_SUCCESS:
                draft.loading = false;
                draft.emailDomainsWithUsers = action.payload;
                break;

            case actionTypes.CHECK_RESTRICTED_EMAIL_ACCESS_SUCCESS:
                draft.loading = false;
                draft.hasRestrictedEmailAccess = action.payload;
                break;

            case actionTypes.ADD_EMAIL_DOMAIN_SUCCESS:
                let newEmailDomain = action.payload;
                draft.emailDomainsWithUsers.push(newEmailDomain);
                draft.addEmailDomainLoading = false;
                draft.error = '';
                break;

            case actionTypes.UPDATE_EMAIL_DOMAIN_SUCCESS:
                const updatedEmailDomain = action.payload;

                const updatedEmailDomainIndex = draft.emailDomainsWithUsers.findIndex(
                    (emailDomain) => emailDomain._id === updatedEmailDomain._id
                );

                if (updatedEmailDomainIndex !== -1) {
                    draft.emailDomainsWithUsers[updatedEmailDomainIndex].isActive = action.payload.isActive
                }
                draft.loading = false;
                break;

            case actionTypes.DELETE_EMAIL_DOMAIN_SUCCESS:
                draft.emailDomainsWithUsers = draft.emailDomainsWithUsers.filter(
                    (emailDomainWithUsers) => emailDomainWithUsers._id !== action.payload
                );
                draft.loading = false;
                draft.successDelete = 'All changes has been saved successfully';
                break;

            case actionTypes.DELETE_EMAIL_DOMAIN_START:
                draft.successDelete = '';
                draft.loading = true;
                break;

            default:
                break;
        }
    });

export default reducer;