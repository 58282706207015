import React, {PureComponent} from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import BrandingBackgroundImage from './LayoutComponents/BrandingBackgroundImage';
import ColorPicker from './LayoutComponents/ColorPicker';
import PredefinedFields from './ParticipantRegistration/PredefinedFields';
import CustomFields from './CustomFields/CustomFields';
import colors from '../../../CSS/_variables.module.scss';
import TranslateIcon from '@material-ui/icons/Translate';
import Confirm from '../../../Dialogs/Confirm';
import {preventDefaultDrag, fireClickEvent} from 'Utils/utils';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import isEqual from 'lodash/isEqual';
import {CircularProgress} from '@material-ui/core';
class BrandingParticipantRegistration extends PureComponent {
    constructor(props) {
        super(props);

        const {availableLanguages} = props;
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            fields: [
                {
                    name: 'participantRegShortDescription',
                    description: 'Description is limited up to 150 characters',
                    adornment: '',
                    value: '',
                    label: 'Short Description',
                    validators: ['maxStringLength: 150'],
                    errorMessages: ['You have reached the maximum limit of characters allowed (150 characters)'],
                },
                {
                    name: 'participantsRegDescription',
                    description: 'Description is limited up to 500 characters',
                    adornment: '',
                    value: '',
                    label: 'Description',
                    multiline: 6,
                    validators: ['maxStringLength: 500'],
                    errorMessages: ['You have reached the maximum limit of characters allowed (500 characters)'],
                },
            ],
            buttonDisabled: true,
            isMultiLanguage: availableLanguages?.length > 1,
            openConfirmUnsavedChanges: false,
            isAnyConfirmUnsavedChangesOpen: false,
            navigationElement: null,
        };
    }
    componentDidMount() {
        this.setEventBranding();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const {brandingLanguage, brandingTranslations, branding} = this.props;
        if (
            !isEqual(prevProps.brandingTranslations, brandingTranslations) ||
            prevProps.brandingLanguage !== brandingLanguage ||
            !isEqual(prevProps.branding, branding)
        ) {
            this.setEventBranding();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setEventBranding = () => {
        const {brandingLanguage, brandingTranslations} = this.props;
        const brandingData = brandingTranslations?.data?.find(
            (translation) => translation.language === brandingLanguage
        );

        if (brandingData) {
            const updatedFields = this.state.fields.map((field, index) => {
                if (index === 0) {
                    return {...field, value: brandingData.participantRegShortDescription};
                } else if (index === 1) {
                    return {...field, value: brandingData.participantsRegDescription};
                }
                return field;
            });

            this.setState({
                fields: updatedFields,
                buttonDisabled: true,
            });
        }
    };

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({buttonDisabled: !isValid});
            });
        });
    };

    saveEventTranslation = () => {
        const {navigationElement} = this.state;

        let data = {};
        this.state.fields.forEach((field) => {
            data[field.name] = field.value;
        });
        this.props.saveEventTranslation(data);
        this.setState({openConfirmUnsavedChanges: false, isAnyConfirmUnsavedChangesOpen: false});

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.setEventBranding();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges, isAnyConfirmUnsavedChangesOpen} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) =>
            pathElem.getAttribute?.('data-is-navigation')
        );

        if (openConfirmUnsavedChanges || isAnyConfirmUnsavedChangesOpen) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (!this.state.buttonDisabled && !(this.state.fields[0].value.length > 500)) {
                this.setState({
                    openConfirmUnsavedChanges: true,
                    isAnyConfirmUnsavedChangesOpen: true,
                    navigationElement: this.getNewNavigationElement(e),
                });
                this.setIsAnyConfirmUnsavedChangesOpen(true);
            }
        }
    }

    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false, isAnyConfirmUnsavedChangesOpen: false});
    };

    setIsAnyConfirmUnsavedChangesOpen = (value) => {
        this.setState({isAnyConfirmUnsavedChangesOpen: value});
    };

    render() {
        const {isMultiLanguage, openConfirmUnsavedChanges, isAnyConfirmUnsavedChangesOpen} = this.state;
        const {branding, loadingSaveEventBranding} = this.props;
        return (
            <>
                <div onDragStart={preventDefaultDrag} className="branding-participant-registration">
                    <h4 className="advanced-options-title">PARTICIPANT SIGN UP INFO</h4>
                    <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                        <div onDragStart={preventDefaultDrag} ref={this.wrapperRef}>
                            {loadingSaveEventBranding ? (
                                <div className="d-flex align-items-center justify-content-center">
                                    <CircularProgress color="primary" />
                                </div>
                            ) : (
                                <ValidatorForm ref="form" onSubmit={this.saveEventTranslation}>
                                    {this.state.fields.map((field, index) => {
                                        return (
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className="options-container"
                                                key={field.name}
                                            >
                                                <div
                                                    onDragStart={preventDefaultDrag}
                                                    className="single-option-container"
                                                >
                                                    <TextValidator
                                                        classes={{root: 'setting-input scroll-helper-gdpr'}}
                                                        label={field.label}
                                                        type="text"
                                                        name={field.name}
                                                        index={index}
                                                        value={field.value}
                                                        onChange={this.handleChange(index)}
                                                        validators={field.validators}
                                                        errorMessages={field.errorMessages}
                                                        multiline={field.multiline > 0}
                                                        rows={field.multiline}
                                                        fullWidth={true}
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment:
                                                                isMultiLanguage && field.adornment !== undefined ? (
                                                                    <TranslateIcon />
                                                                ) : (
                                                                    field.adornment
                                                                ),
                                                        }}
                                                    />
                                                </div>
                                                <div onDragStart={preventDefaultDrag} className="input-description">
                                                    <p>{field.description}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div onDragStart={preventDefaultDrag} className="action-container">
                                        <Button
                                            type="submit"
                                            variant={'contained'}
                                            disabled={
                                                this.state.buttonDisabled || this.state.fields[0].value.length > 500
                                            }
                                            startIcon={<SaveOutlinedIcon />}
                                            disableElevation
                                            color={'secondary'}
                                        >
                                            SAVE
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            )}
                        </div>

                        <p onDragStart={preventDefaultDrag} className="inner-options-title">
                            SIGN UP BACKGROUND IMAGE
                        </p>
                        <div onDragStart={preventDefaultDrag} className="branding-background-image-container">
                            <BrandingBackgroundImage
                                for={'participantsRegBackground'}
                                label={'Background'}
                                image={branding?.data?.participantsRegBackground}
                                filesUrl={branding?.data?.filesUrl}
                                dragDropOrientation={'portrait'}
                                imageRatio={2.84} // Update the imageRatio value
                                openSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                                openErrorSnackbar={this.props.handleOpenErrorSnackbar}
                                isAnyConfirmUnsavedChangesOpen={isAnyConfirmUnsavedChangesOpen}
                                setIsAnyConfirmUnsavedChangesOpen={this.setIsAnyConfirmUnsavedChangesOpen}
                            />
                            {/*   <ColorPicker
                                for={'overlayColorParticipantReg'}
                                color={colors.secondaryOpacity70}
                                saveEventBranding={this.props.saveEventBranding}
                                isAnyConfirmUnsavedChangesOpen={isAnyConfirmUnsavedChangesOpen}
                                setIsAnyConfirmUnsavedChangesOpen={this.setIsAnyConfirmUnsavedChangesOpen}
                                loadingSave={loadingSaveEventBranding}
                            />*/}
                        </div>
                    </div>
                    <PredefinedFields
                        handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                        handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                        isAnyConfirmUnsavedChangesOpen={isAnyConfirmUnsavedChangesOpen}
                        setIsAnyConfirmUnsavedChangesOpen={this.setIsAnyConfirmUnsavedChangesOpen}
                    />
                    <CustomFields
                        handleOpenSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                        handleOpenErrorSnackbar={this.props.handleOpenErrorSnackbar}
                        role="participant"
                        noCustomFieldsMessage="There are currently no custom participant registration fields."
                        addFieldMessage="Add custom participant field"
                    />
                </div>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveEventTranslation}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        brandingLanguage: state.languages.organizerBrandingLanguage,
        branding: state.event.branding,
        brandingTranslations: state.event.brandingTranslations,
    };
};

export default connect(mapStateToProps)(BrandingParticipantRegistration);
