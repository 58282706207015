import React from 'react';
import '../CSS/platformHomepage.scss';
import PlatformFooter from '../SmallLayoutComponents/PlatformFooter';
import 'react-tabs/style/react-tabs.css';
import GreenRectangleText from '../SmallLayoutComponents/PlatformHomepage/GreenRectangleText';
import PurpleRectangleText from '../SmallLayoutComponents/PlatformHomepage/PurpleRectangleText';
import HighlightRectangleText from '../SmallLayoutComponents/HighlightRectangleText';

import TopMainImage from '../Images/pages/platform-homepage/fullscreen-top.jpg';
import TopMainImageMobile from '../Images/pages/platform-homepage/fullscreen-top-mobile.jpg';
import TopMainImageTablet from '../Images/pages/platform-homepage/fullscreen-top-tablet.jpg';
import ImageBlack from '../Images/pages/platform-homepage/footer-black.jpg';
import OnventLookSlider from '../SmallLayoutComponents/PlatformHomepage/OnventLookSlider';
import HomepageVideo from '../SmallLayoutComponents/PlatformHomepage/HomepageVideo';
import OnventTestimonialsSlider from '../SmallLayoutComponents/PlatformHomepage/OnventTestimonialsSlider';
import PlatformCards from '../SmallLayoutComponents/PlatformHomepage/PlatformCards';
import ContactForm from '../SmallLayoutComponents/PlatformHomepage/ContactForm';
import AnimatedTopSection from '../SmallLayoutComponents/PlatformHomepage/AnimatedTopSection';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import {preventDefaultDrag} from '../Utils/utils';

class HomepagePlatform extends React.Component {
    componentDidMount() {
        this.props.onSetPlatformMenu();
    }

    render() {
        return (
            <div onDragStart={preventDefaultDrag} className="platform-homepage">
                <AnimatedTopSection />
                <div onDragStart={preventDefaultDrag} className="sections-wrapper">
                    <section className="top-section">
                        <img
                            src={TopMainImage}
                            srcSet={`${TopMainImage} 1366w, ${TopMainImageTablet} 1024w, ${TopMainImageMobile} 768w`}
                            className="full-image"
                            alt=""
                        />
                        <div onDragStart={preventDefaultDrag} className="image-gradient-overlay-purple"></div>
                        <div onDragStart={preventDefaultDrag} className="section-heading">
                            <h1>
                                <PurpleRectangleText>Next generation</PurpleRectangleText>
                            </h1>
                            <h2>
                                <GreenRectangleText>online events</GreenRectangleText>
                            </h2>
                        </div>
                    </section>

                    <section className="onvent-look">
                        <div onDragStart={preventDefaultDrag} className="container">
                            <h3>
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="before-highlight-text"
                                >
                                    Check out
                                </span>{' '}
                                <HighlightRectangleText>NEW {process.env.REACT_APP_PLATFORM_NAME}</HighlightRectangleText>
                            </h3>
                        </div>
                    </section>
                    <OnventLookSlider />

                    <section className="onvent-video-presentation-title">
                        <div onDragStart={preventDefaultDrag} className="container">
                            <h3>
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="before-highlight-text"
                                >
                                    {process.env.REACT_APP_PLATFORM_NAME} - online events that your
                                </span>{' '}
                                <HighlightRectangleText>participants will love.</HighlightRectangleText> Here is why:
                            </h3>
                        </div>
                    </section>
                    <HomepageVideo />

                    <section className="onvent-testimonials">
                        <div onDragStart={preventDefaultDrag} className="container">
                            <h3>
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="before-highlight-text"
                                >
                                    Online events since 2015 -{' '}
                                </span>
                                <HighlightRectangleText>it's all about people</HighlightRectangleText>...
                            </h3>
                        </div>
                    </section>
                    <OnventTestimonialsSlider />

                    <section className="onvent-cards">
                        <div onDragStart={preventDefaultDrag} className="container">
                            <h3>
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="before-highlight-text"
                                >
                                    {process.env.REACT_APP_PLATFORM_NAME} brings
                                </span>{' '}
                                <HighlightRectangleText>the WOW</HighlightRectangleText> to...
                            </h3>
                            <PlatformCards />
                        </div>
                    </section>

                    <section className="onvent-form">
                        <img
                            draggable="false"
                            onDragStart={preventDefaultDrag}
                            src={ImageBlack}
                            className="full-image"
                            alt=""
                        />
                        <div onDragStart={preventDefaultDrag} className="full-image-overlay" />
                        <div onDragStart={preventDefaultDrag} className="container">
                            <h3>
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="before-highlight-text"
                                >
                                    Interested in organizing an online event?
                                </span>{' '}
                                <HighlightRectangleText>Get in touch</HighlightRectangleText>
                            </h3>
                            <ContactForm />
                            <PlatformFooter />
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetPlatformMenu: () => dispatch(actions.setPlatformMenu()),
    };
};

export default connect(null, mapDispatchToProps)(HomepagePlatform);
