import {Checkbox, Tooltip} from '@material-ui/core';
import {preventDefaultDrag, doesOrganizerHasParticipantProfile} from 'Utils/utils';
import {ReactComponent as ExhibitorNoPhoto} from 'Images/svg/showfloor_icon_rooms.svg';
import colors from 'CSS/_variables.module.scss';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {usePlatformTranslation} from 'services/hooks';
import Spinner from 'SmallLayoutComponents/Spinner';

const SingleExhibitor = ({
    exhibitor,
    showfloorId,
    isActive,
    handleSetActiveExhibitor,
    isVotingEnabled,
    votingPrivalages,
    votingData,
    handleChangeVote,
    voteLoading,
    exhibitorInProgress,
    singleExhibitorsVoteCount,
}) => {
    const [isVoted, setIsVoted] = useState(false);
    //redux
    const voteText = usePlatformTranslation((state) => state?.voting?.voteText);
    const votedText = usePlatformTranslation((state) => state?.voting?.votedText);
    const platformLanguage = useSelector((state) => state.languages?.platformLanguage);
    const userEventProfile = useSelector((state) => state.userEventProfile?.data);
    const checkTheBoxToCastYourVote = usePlatformTranslation((state) => state?.voting?.checkTheBoxToCastYourVote);
    const toVotePleaseEnableParticipantProfile = usePlatformTranslation(
        (state) => state?.voting?.toVotePleaseEnableParticipantProfile
    );

    const getShowfloorVoteData = () => {
        let isUserVoted = votingData?.userVotes?.find((exhibitorVote) => exhibitorVote === exhibitor._id);
        setIsVoted(!!isUserVoted);
    };

    //get the number of votes
    useEffect(() => {
        getShowfloorVoteData();
    }, [showfloorId, exhibitor?._id, votingData]);

    return (
        <div className={`single-exhibitor ${isActive ? 'active' : ''}`} key={exhibitor._id}>
            {isVotingEnabled ? (
                doesOrganizerHasParticipantProfile(userEventProfile) ? (
                    isVoted ? (
                        <div
                            className={`vote-action-single-item ${
                                platformLanguage === 'ar' ? 'vote-action-ar-support' : ''
                            }`}
                        >
                            {voteLoading && exhibitorInProgress === exhibitor?._id && (
                                <div className={`spinner-container-vote vote-banner-top`}>
                                    <Spinner />
                                </div>
                            )}
                            <Checkbox checked={isVoted ?? ''} onClick={handleChangeVote(exhibitor?._id)} />
                            <span
                                className={`vote-text text-uppercase cursor-pointer`}
                                onClick={handleChangeVote(exhibitor?._id)}
                            >
                                {votedText}{' '}
                                {votingPrivalages && singleExhibitorsVoteCount && (
                                    <>
                                        ({(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id]) || 0}
                                        )
                                    </>
                                )}
                            </span>
                        </div>
                    ) : (
                        <Tooltip title={checkTheBoxToCastYourVote} arrow>
                            <div
                                className={`vote-action-single-item ${
                                    platformLanguage === 'ar' ? 'vote-action-ar-support' : ''
                                }`}
                            >
                                {voteLoading && exhibitorInProgress === exhibitor?._id && (
                                    <div className={`spinner-container-vote vote-banner-top`}>
                                        <Spinner />
                                    </div>
                                )}
                                <Checkbox checked={isVoted ?? ''} onClick={handleChangeVote(exhibitor?._id)} />
                                <span
                                    className={`vote-text text-uppercase cursor-pointer`}
                                    onClick={handleChangeVote(exhibitor?._id)}
                                >
                                    {voteText}{' '}
                                    {votingPrivalages && singleExhibitorsVoteCount && (
                                        <>
                                            (
                                            {(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id]) ||
                                                0}
                                            )
                                        </>
                                    )}
                                </span>
                            </div>
                        </Tooltip>
                    )
                ) : (
                    <Tooltip title={toVotePleaseEnableParticipantProfile} arrow>
                        <div className={`vote-action-single-item disabled`}>
                            <Checkbox checked={isVoted ?? ''} disabled onClick={handleChangeVote(exhibitor?._id)} />
                            <span
                                className={`vote-text text-uppercase disabled cursor-pointer`}
                                onClick={handleChangeVote(exhibitor?._id)}
                            >
                                {voteText}{' '}
                                {votingPrivalages && singleExhibitorsVoteCount && (
                                    <>
                                        ({(singleExhibitorsVoteCount && singleExhibitorsVoteCount[exhibitor?._id]) || 0}
                                        )
                                    </>
                                )}
                            </span>
                        </div>
                    </Tooltip>
                )
            ) : null}
            <Tooltip arrow title={exhibitor.company}>
                <div
                    onDragStart={preventDefaultDrag}
                    className="exhibitor-container"
                    onClick={handleSetActiveExhibitor(exhibitor._id)}
                >
                    <div onDragStart={preventDefaultDrag} className="logo-container">
                        {exhibitor.booth.logo ? (
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={exhibitor.filesUrl + exhibitor.booth.logo}
                                alt={exhibitor.company}
                            />
                        ) : (
                            <ExhibitorNoPhoto fill={colors.primary} />
                        )}
                    </div>
                    <p>{exhibitor.company}</p>
                </div>
            </Tooltip>
        </div>
    );
};
export default SingleExhibitor;
