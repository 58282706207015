import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import axios from '../../store/axios-instance';
// import TextField from '../../Components/InputFields/TextField';
import SelectCountryProfile from '../../Components/InputFields/SelectCountryProfile';
import RadioField from '../../Components/InputFields/RadioField';
import SelectFieldProfile from '../../Components/InputFields/SelectFieldProfile';
import CheckboxField from '../../Components/InputFields/CheckboxField';
import Button from '@material-ui/core/Button';
import variables from '../../CSS/_variables.module.scss';
import TextFieldInfoPoint from '../../Components/InputFields/TextFieldInfoPoint';
import {isLinkedinLinkRule, isFacebookLinkRule, isTwitterLinkRule, isLinkRule} from './../../Utils/validationRules';
import './EditInformationStyles.scss';
class EditExhibitorContactPersonInformation extends React.Component {
    state = {
        user: {
            fields: [
                {
                    isEnabled: true,
                    name: 'company',
                    type: 'text',
                    value: '',
                    label: 'Company',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'title',
                    type: 'text',
                    value: '',
                    label: 'Title',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'phone',
                    type: 'text',
                    value: '',
                    label: 'Mobile',
                    multiline: 0,
                    validators: ['matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'],
                    errorMessages: [`${this.props.translation?.errors?.phoneInvalid}`],
                },
                {
                    isEnabled: true,
                    name: 'facebook',
                    type: 'text',
                    value: '',
                    label: 'Facebook profile',
                    multiline: 0,
                    validators: ['isFacebookLink'],
                    errorMessages: [`${this.props.translation?.errors?.facebookLinkInvalid}`],
                },
                {
                    isEnabled: true,
                    name: 'linkedin',
                    type: 'text',
                    value: '',
                    label: 'Your Linkedin profile',
                    multiline: 0,
                    validators: ['isLinkedinLink'],
                    errorMessages: [`${this.props.translation?.errors?.linkedinLinkInvalid}`],
                },
                {
                    isEnabled: true,
                    name: 'twitter',
                    type: 'text',
                    value: '',
                    label: 'Your Twitter profile',
                    multiline: 0,
                    validators: ['isLTwitterLink'],
                    errorMessages: [`${this.props.translation?.errors?.twitterLinkInvalid}`],
                },
                {
                    isEnabled: true,
                    name: 'country',
                    type: 'text',
                    value: '',
                    label: 'Country',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
                {
                    isEnabled: true,
                    name: 'website',
                    type: 'text',
                    value: '',
                    label: 'Website',
                    multiline: 0,
                    validators: [],
                    errorMessages: [],
                },
            ],
        },
        disabled: true,
    };

    componentDidMount() {
        this.addValidationRules();

        // update the required fields with the settings from the current event
        this.setExhibitorFields();
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        this.removeValidationRules();
    }

    setExhibitorFields = () => {
        const {exhibitor, predefinedFields, customFields} = this.props;
        let updatedFields = [...this.state.user.fields];

        updatedFields.forEach((field) => {
            let eventInfoField = predefinedFields[field.name];
            field.label = eventInfoField.label;
            field.isEnabled = eventInfoField.isEnabled;
            field.value = exhibitor[field.name] ? exhibitor[field.name] : '';
            if (eventInfoField.isRequired) {
                field.validators.push('required');
                field.errorMessages.push(this.props.translation?.errors.required);
            }
        });

        customFields.forEach((extraField) => {
            let customField = exhibitor.customFields.find(
                (customField) => customField.customFieldId === extraField._id
            );
            let value = '';
            if (customField?.value && customField.value.length) {
                value = customField.value;
            } else if (customField?.values && customField.values.length) {
                value = customField.values;
            }

            const field = {
                isEnabled: true,
                name: extraField._id,
                type: extraField.type,
                value: value,
                label: extraField.label,
                multiline: 0,
                validators: [],
                errorMessages: [],
                options: extraField.options,
            };
            if (extraField.isRequired) {
                field.validators.push('required');
                field.errorMessages.push(this.props.translation?.errors?.required);
            }
            updatedFields.push(field);
        });

        let enabledUpdatedFields = updatedFields.filter((field) => field.isEnabled);
        this.setState({
            user: {...this.state.user, fields: enabledUpdatedFields},
        });
    };
    handleFieldChangeText = (e) => {
        const {value, name} = e.target;
        let updatedFields = [...this.state.user.fields];
        let updatedFieldIndex = updatedFields.findIndex((field) => field.name === name);

        updatedFields[updatedFieldIndex].value = value;
        this.setState({user: {...this.state.user, fields: updatedFields}});
        this.setState({disabled: false});
    };
    handleFieldChange = (fieldName, fieldValue) => {
        let updatedFields = [...this.state.user.fields];
        let updatedFieldIndex = updatedFields.findIndex((field) => field.name === fieldName);

        updatedFields[updatedFieldIndex].value = fieldValue;
        this.setState({user: {...this.state.user, fields: updatedFields}});
        this.setState({disabled: false});
    };

    handleUpdateExhibitorContactPersonInformation = () => {
        let user = {};
        this.state.user.fields.forEach((field) => {
            user[field.name] = field.value;
        });
        // user['type'] = 'company';

        axios({method: 'put', url: '/exhibitor/' + this.props.exhibitor._id, data: user})
            .then((response) => {
                const {eventId, userId} = this.props;
                this.props.onGetUserEventProfile(eventId, userId);
                this.props.closeDialog();
            })
            .catch((error) => {});
    };

    addValidationRules = () => {
        ValidatorForm.addValidationRule('isFacebookLink', (value) => {
            let rule = isFacebookLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLinkedinLink', (value) => {
            let rule = isLinkedinLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLTwitterLink', (value) => {
            let rule = isTwitterLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLink', (value) => {
            let rule = isLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                this.setState({newLinkError: true});
                return false;
            }
            this.setState({newLinkError: false});
            return true;
        });
    };

    removeValidationRules = () => {
        ValidatorForm.removeValidationRule('isFacebookLink');
        ValidatorForm.removeValidationRule('isLinkedinLink');
        ValidatorForm.removeValidationRule('isLTwitterLink');
        ValidatorForm.removeValidationRule('isLink');
    };

    handleChangeCountry = (e) => {
        this.setState({
            user: {...this.state.user, country: e.target.value},
        });
    };

    getTextWidth = (text) => {
        // re-use canvas object for better performance
        let canvas = (this.getTextWidth.canvas = document.createElement('canvas'));
        let context = canvas.getContext('2d');
        context.font = `normal 16px ${variables.fontFamily}`;
        let metrics = context.measureText(text);
        return Math.ceil(metrics.width);
    };

    render() {
        let {fields} = this.state.user;
        let {isLargeScreen, translation} = this.props;
        return (
            <Dialog
                className="edit-user-information dialog-max-width"
                open={this.props.opened}
                onClose={() => this.props.closeDialog('')}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{translation?.myAccountPage.editExhibitorDialogTitle}</DialogTitle>
                <DialogContent className="user-edit scroll-left-container">
                    <ValidatorForm ref="form" onSubmit={this.handleUpdateExhibitorContactPersonInformation}>
                        <div className="fields-container">
                            {fields.map((field) => {
                                let labelWidth = this.getTextWidth(field.label);
                                if (field.name === 'country') {
                                    return (
                                        <SelectCountryProfile
                                            field={field}
                                            handleChange={this.handleFieldChange}
                                            key={field.name}
                                        />
                                    );
                                }
                                if (field.type === 'select') {
                                    return (
                                        <div className="align-helper select-field">
                                            <SelectFieldProfile
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                            />
                                        </div>
                                    );
                                }
                                if (field.type === 'radio') {
                                    return (
                                        <div className="align-helper legend-width-500 ellipsis">
                                            <RadioField
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                            />
                                        </div>
                                    );
                                }
                                if (field.type === 'checkbox') {
                                    return (
                                        <div className="align-helper legend-width-500 ellipsis">
                                            <CheckboxField
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                                fieldValues={field.value}
                                            />
                                        </div>
                                    );
                                }
                                return labelWidth > 520 ? (
                                    <TextFieldInfoPoint
                                        field={field}
                                        handleChange={this.handleFieldChange}
                                        key={field.name}
                                        disabled={this.state.disabled}
                                        isLargeScreen={isLargeScreen}
                                        placeholderWidthRestrict={520}
                                    />
                                ) : (
                                    <TextValidator
                                        // key={field.name}
                                        label={field.label}
                                        type="text"
                                        name={field.name}
                                        index="0"
                                        value={field.value}
                                        onChange={this.handleFieldChangeText}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={{endAdornment: null}}
                                        validators={field.validators}
                                        errorMessages={field.errorMessages}
                                        multiline={field.multiline > 0}
                                    />
                                );
                            })}
                        </div>
                        <DialogActions className="buttons-actions">
                            <Button type="submit" disabled={this.state.disabled}>
                                {translation?.generalText.update}
                            </Button>
                            <Button type="button" onClick={() => this.props.closeDialog('')}>
                                {translation?.generalText.close}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        predefinedFields: state.event?.registrationFields?.data?.exhibitorPredefinedRegistrationFields,
        customFields: state.event?.registrationFields?.data?.exhibitorRegistrationFields,
        eventId: state.event.eventId,
        isLargeScreen: state.layout.isLargeScreen,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        userId: state.user.data._id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditExhibitorContactPersonInformation);
