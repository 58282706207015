import React, {PureComponent} from 'react';
import {preventDefaultDrag} from '../../../Utils/utils';
import ColorPicker from './LayoutComponents/ColorPicker';
import variables from '../../../CSS/_variables.module.scss'

class HPBackgroundOverlayColor extends PureComponent {
    render() {
        const {saveEventBranding} = this.props;
        return (
            <div>
                <p onDragStart={preventDefaultDrag} className="inner-options-title">
                    Background Overlay Color
                </p>
                <ColorPicker
                    for={'overlayColorHomepage'}
                    color={variables.secondaryOpacity70}
                    description={'Choose the overlay color that will be displayed on top of the main image.'}
                    saveEventBranding={saveEventBranding}
                />
            </div>
        );
    }
}

export default HPBackgroundOverlayColor;
