import axios from '../axios-instance';
import * as actionTypes from './actionTypes';

export const getUsers = (eventId) => {
    return (dispatch) => {
        dispatch(getUsersStart());

        return axios({method: 'get', url: `/event/v2/${eventId}/users`})
            .then((response) => {
                let data = response.data.data;
                dispatch(getUsersSuccess(data));
                dispatch(getOnlineUsers());
            })
            .catch((error) => {
                dispatch(getUsersFail(error));
            });
    };
};

export const getUsersStart = () => {
    return {
        type: actionTypes.GET_USERS_START,
    };
};
export const getUsersSuccess = (data) => {
    return {
        type: actionTypes.GET_USERS_SUCCESS,
        payload: data,
    };
};
export const getUsersFail = () => {
    return {
        type: actionTypes.GET_USERS_FAIL,
    };
};

// function triggered from socket event to add a new participant
export const addEventParticipant = (participant) => {
    return {
        type: actionTypes.ADD_EVENT_PARTICIPANT,
        payload: participant,
    };
};
// function triggered from socket event to add a new exhibitor
export const addEventExhibitor = (exhibitor) => {
    return {
        type: actionTypes.ADD_EVENT_EXHIBITOR,
        payload: exhibitor,
    };
};

// function triggered from socket event to remove a participant
export const removeEventParticipant = (participantId) => {
    return {
        type: actionTypes.REMOVE_EVENT_PARTICIPANT,
        payload: participantId,
    };
};
// function triggered from socket event to remove an exhibitor
export const removeEventExhibitor = (exhibitorId) => {
    return {
        type: actionTypes.REMOVE_EVENT_EXHIBITOR,
        payload: exhibitorId,
    };
};

// function triggered from socket event to update a participant details
export const updateEventParticipant = (participant) => {
    return {
        type: actionTypes.UPDATE_EVENT_PARTICIPANT,
        payload: participant,
    };
};
// function triggered from socket event to update an exhibitor details
export const updateEventExhibitor = (exhibitor) => {
    return {
        type: actionTypes.UPDATE_EVENT_EXHIBITOR,
        payload: exhibitor,
    };
};
// function triggered from socket event to update an organizer details
export const updateEventOrganizer = (organizer) => {
    return {
        type: actionTypes.UPDATE_EVENT_ORGANIZER,
        payload: organizer,
    };
};

export const getOnlineUsers = () => {
    return (dispatch) => {
        dispatch(getOnlineUsersStart());

        axios({method: 'get', url: `/event/get-online-users`})
            .then((response) => {
                let users = response.data.users;
                dispatch(getOnlineUsersSuccess(users));
            })
            .catch((error) => {
                dispatch(getOnlineUsersFail(error));
            });
    };
};

export const getOnlineUsersStart = () => {
    return {
        type: actionTypes.GET_ONLINE_USERS_START,
    };
};
export const getOnlineUsersSuccess = (users) => {
    return {
        type: actionTypes.GET_ONLINE_USERS_SUCCESS,
        payload: users,
    };
};
export const getOnlineUsersFail = () => {
    return {
        type: actionTypes.GET_ONLINE_USERS_FAIL,
    };
};

export const setOnlineUsers = (user, eventId) => {
    return {
        type: actionTypes.SET_EVENT_ONLINE_USER,
        payload: user,
    };
};

// getEventOnlineUsers is not used because I need the online status for all the users on the platform
// not only in the current event, we could have users in chat that are online but not participate at the event
export const getEventOnlineUsers = (eventId) => {
    return (dispatch) => {
        dispatch(getEventOnlineUsersStart());

        return axios({method: 'get', url: `/event/${eventId}/get-online-users`})
            .then((response) => {
                let users = response.data.users;
                dispatch(getEventOnlineUsersSuccess(users));
            })
            .catch((error) => {
                dispatch(getEventOnlineUsersFail(error));
            });
    };
};
export const getEventOnlineUsersStart = () => {
    return {
        type: actionTypes.GET_EVENT_ONLINE_USERS_START,
    };
};
export const getEventOnlineUsersSuccess = (users) => {
    return {
        type: actionTypes.GET_EVENT_ONLINE_USERS_SUCCESS,
        payload: users,
    };
};
export const getEventOnlineUsersFail = () => {
    return {
        type: actionTypes.GET_EVENT_ONLINE_USERS_FAIL,
    };
};

export const setMatchingFormSeen = (eventId) => {
    return (dispatch) => {
        return axios({
            method: 'put',
            url: `/event/${eventId}/matching-form-seen`,
        })
            .then(() => {})
            .catch(() => {});
    };
};
