import React from 'react';
import {connect} from 'react-redux';
import ChatMessage from './ChatMessage';
import {preventDefaultDrag} from '../../../Utils/utils';
// if the user started a video call render the "{user} started a video call" message
// otherwise display the ChatMessage
class RenderChatMessage extends React.Component {
    render() {
        const codedQuotationMark = '&#x27;';
        const {
            message,
            timeString,
            messageData,
            showUserDetails,
            translation,
            defaultTranslation,
            lastChatMessageActive,
            startConference,
        } = this.props;
        const videoCallMessage = `I${codedQuotationMark}ve set up a Video Meeting room. Please click here to join it.`;
        if (message.text === videoCallMessage) {
            return (
                <div className="videocall-message-container">
                    <p
                        onClick={lastChatMessageActive ? startConference : null}
                        className={lastChatMessageActive ? `videocall-message-text` : 'videocall-message-outdated'}
                    >
                        <span className={`ellipsis ellipsis-third-line d-block`}>
                            {messageData?.first} {messageData?.last}{' '}
                        </span>
                        {translation?.videoConferences.startedVideoChat ||
                            defaultTranslation?.videoConferences.startedVideoChat}{' '}
                        {timeString}{' '}
                        {timeString !== 'just now' &&
                            (translation?.videoConferences.timeAgo || defaultTranslation?.videoConferences.timeAgo)}
                    </p>
                </div>
            );
        } else {
            return (
                <>
                    <ChatMessage message={message} userMessageData={messageData} showUserDetails={showUserDetails} />
                    <div onDragStart={preventDefaultDrag} className="time-wrapper">
                        <p onDragStart={preventDefaultDrag} className="message-text">
                            <small>{timeString}</small>
                        </p>
                    </div>
                </>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(RenderChatMessage);
