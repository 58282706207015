import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {ReactComponent as UsernameIcon} from '../../Images/svg/account-input.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RecoverPassword from '../../Dialogs/RecoverPassword';
import * as actions from '../../store/actions';
import {connect} from 'react-redux';
import {checkIfEmailIsValid, preventDefaultDrag} from '../../Utils/utils';

class LoginEventForm extends PureComponent {
    state = {
        email: '',
        password: '',
        disabled: true,
        showRecoverPasswordDialog: false,
        recoverPasswordServerMessage: '',
        rememberMe: false,
        showPassword: false,
        splashScreenState: false,
        errorEmailDomainValid: false,
    };

    emailRef = React.createRef();
    passwordRef = React.createRef();

    componentDidMount() {
        document.addEventListener(
            'invalid',
            (function () {
                return function (e) {
                    //prevent the browser from showing default error bubble / hint
                    e.preventDefault();
                    // optionally fire off some custom validation handler
                    // myValidation();
                };
            })(),
            true
        );
    }

    componentWillUnmount() {
        document.removeEventListener(
            'invalid',
            (function () {
                return function (e) {
                    //prevent the browser from showing default error bubble / hint
                    e.preventDefault();
                    // optionally fire off some custom validation handler
                    // myValidation();
                };
            })(),
            true
        );
    }

    handleRecoverPassword = () => this.setState({showRecoverPasswordDialog: true});

    _handleSeeSignUpTab = () => {
        const {eventSlug, singleLoginEvent} = this.props;
        if (singleLoginEvent) {
            this.props.history.push(`/event/${eventSlug}/register?registerAsParticipant=true`);
        } else {
            this.props.seeSignUpForm();
        }
    };

    handleCloseDialog = (message) =>
        this.setState({showRecoverPasswordDialog: false, recoverPasswordServerMessage: message});

    toggleShowPassword = () =>
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));

    handleChangeCheckbox = () =>
        this.setState((prevState) => ({
            rememberMe: !prevState.rememberMe,
        }));

    handleLogin = (e) => {
        e.preventDefault();
        let loginUserData = {
            email: this.state.email,
            password: this.state.password,
            eventId: this.props.eventId,
        };
        this.props.onLoginUser(loginUserData);
    };

    handleChange = (e) => {
        if (e.target.name === 'password') {
            this.passwordRef.current.validate(e.target.value);
        }
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleValidateEmail = (e) => {
        this.emailRef.current.validate(e.target.value);
        this.checkIfUserCanLogin();
    };

    checkIfUserCanLogin = () => {
        const isValid = checkIfEmailIsValid(this.state.email);
        if (!this.state.email || !isValid) {
            this.setState({
                errorEmailDomainValid: false,
            });
            return null;
        }

        this.props.onCheckIfHasRestrictedEmailAccess(this.props.eventId, this.state.email.trim(), false).then((res) => {
            this.setState({
                disabled: this.props.hasRestrictedEmailAccess,
                errorEmailDomainValid: this.props.hasRestrictedEmailAccess,
            });
        });
    };

    render() {
        const {email, password, showPassword, rememberMe} = this.state;
        const {translation, error, defaultTranslation, restrictAllUsersLogin} = this.props;
        return (
            <>
                <ValidatorForm ref="form" onSubmit={this.handleLogin} instantValidate={false}>
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`email-wrapper ${this.state.errorEmailDomainValid ? 'email-domain-error' : ''}`}
                    >
                        <TextValidator
                            ref={this.emailRef}
                            className="field-container"
                            placeholder={translation?.login.inputEmail}
                            onChange={this.handleChange}
                            onBlur={this.handleValidateEmail}
                            error={this.state.errorEmailDomainValid}
                            helperText={
                                this.state.errorEmailDomainValid
                                    ? `${
                                          translation?.register.emailNotApprovedError ||
                                          defaultTranslation?.register.emailNotApprovedError
                                      }`
                                    : ''
                            }
                            name="email"
                            value={email.trim()}
                            variant="outlined"
                            type="email"
                            fullWidth
                            validators={['required', 'isEmail']}
                            errorMessages={[`${translation?.errors.required}`, `${translation?.errors.emailNotValid}`]}
                            disabled={restrictAllUsersLogin}
                            InputProps={{
                                endAdornment: <UsernameIcon />,
                            }}
                        />
                    </div>
                    <div onDragStart={preventDefaultDrag} className="password-wrapper">
                        <TextValidator
                            ref={this.passwordRef}
                            className="field-container"
                            placeholder={translation?.login.inputPassword}
                            onChange={this.handleChange}
                            name="password"
                            value={password}
                            variant="outlined"
                            type={`${showPassword ? 'text' : 'password'}`}
                            fullWidth
                            validators={['required', 'minStringLength:8']}
                            errorMessages={[
                                `${translation?.errors.required}`,
                                `${translation?.errors.passwordMinLength}`,
                            ]}
                            disabled={restrictAllUsersLogin}
                            InputProps={{
                                endAdornment: (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="show-pass"
                                        onClick={this.toggleShowPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </div>
                                ),
                            }}
                        />
                    </div>
                    <div onDragStart={preventDefaultDrag} className="recover-wrapper">
                        <div>
                            <FormControlLabel
                                className="white-variant"
                                control={
                                    <Checkbox
                                        className="white-variant"
                                        checked={rememberMe}
                                        onChange={this.handleChangeCheckbox}
                                        disabled={restrictAllUsersLogin}
                                    />
                                }
                                label={translation?.login.rememberMeButton}
                            />
                        </div>
                        <div>
                            <button onClick={this.handleRecoverPassword} type="button">
                                {translation?.login.forgotPasswordButton}
                            </button>
                        </div>
                        {this.state.recoverPasswordServerMessage && (
                            <p onDragStart={preventDefaultDrag} className="server-message">
                                {this.state.recoverPasswordServerMessage}
                            </p>
                        )}
                    </div>
                    {error && error === 'Not registered' ? (
                        <p>
                            <span>
                                {translation?.login.errorAccountNotFound ||
                                    defaultTranslation?.login.errorAccountNotFound}
                            </span>
                            <span>
                                {' ' +
                                    (translation?.generalText.please || defaultTranslation?.generalText.please) +
                                    ' '}
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="error-clickable"
                                    onClick={this._handleSeeSignUpTab}
                                >
                                    {translation?.register.signUpTitleLowerCase ||
                                        defaultTranslation?.register.signUpTitleLowerCase}
                                </span>
                            </span>
                        </p>
                    ) : error && error === 'Unable to login' ? (
                        <p>{translation?.login.errorIncorrectDates || defaultTranslation?.login.errorIncorrectDates}</p>
                    ) : error && error === 'email not approved by organizer' ? (
                        <p>
                            {translation?.register.emailNotApprovedError ||
                                defaultTranslation?.register.emailNotApprovedError}
                        </p>
                    ) : null}
                    <button
                        className={
                            'login-submit-button ' +
                            (this.state.disabled || this.props.hasRestrictedEmailAccess ? 'disabled ' : '') +
                            (error &&
                            (error === 'Not registered' ||
                                error === 'Unable to login' ||
                                error === 'email not approved by organizer')
                                ? 'has-error'
                                : '')
                        }
                        type="submit"
                        disabled={restrictAllUsersLogin || this.state.errorEmailDomainValid}
                    >
                        {restrictAllUsersLogin
                            ? translation?.login.loginClosedButton || defaultTranslation?.login.loginClosedButton
                            : translation?.login.loginButton}
                    </button>
                    {this.state.showRecoverPasswordDialog ? (
                        <RecoverPassword
                            opened={this.state.showRecoverPasswordDialog}
                            closeDialog={this.handleCloseDialog}
                        />
                    ) : null}
                </ValidatorForm>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventSlug: state.event.eventSlug,
        eventId: state.event.eventId,
        error: state.user.error,
        restrictAllUsersLogin: state.event.eventInfo?.restrictAllUsersLogin,
        hasRestrictedEmailAccess: state.emailDomains.hasRestrictedEmailAccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoginUser: (user) => dispatch(actions.loginUser(user)),
        seeSignUpForm: () => dispatch(actions.seeSignUpForm()),
        onCheckIfHasRestrictedEmailAccess: (eventId, userEmailDomain, isRegistering) =>
            dispatch(actions.checkIfHasRestrictedEmailAccess(eventId, userEmailDomain, isRegistering)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginEventForm));
