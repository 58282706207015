import React, {useCallback, useMemo} from 'react';
import {TextValidator} from 'react-material-ui-form-validator';

import variables from '../../CSS/_variables.module.scss';
import './TextFieldInfoPointStyles.scss';

const TextFieldInfoPoint = ({
    field,
    handleChange,
    isLargeScreen,
    placeholderWidthRestrict,
    isTooltipDisabled = false,
}) => {
    const getTextWidth = useCallback((text) => {
        // re-use canvas object for better performance
        let canvas = (getTextWidth.canvas = document.createElement('canvas'));
        let context = canvas.getContext('2d');
        context.font = `normal 16px ${variables.fontFamily}`;
        let metrics = context.measureText(text);
        return Math.ceil(metrics.width);
    }, []);

    const handleLongPlaceholderOnMobile = useCallback(
        (e) => {
            let placeholder = e.target.getAttribute('placeholder');
            let placeholderWidth = getTextWidth(placeholder);
            if (placeholderWidth > e.target.clientWidth && !isLargeScreen) {
                e.target.closest('.input-wrapper').classList.add('show-tooltip-mobile');
            } else {
                e.target.closest('.input-wrapper').classList.remove('show-tooltip-mobile');
            }
        },
        [getTextWidth, isLargeScreen]
    );

    const removeAnimationPlaceholderMobile = useCallback((e) => {
        e.target.closest('.input-wrapper').classList.remove('show-tooltip-mobile');
    }, []);

    const placeholderWidth = useMemo(() => {
        return getTextWidth(field.label);
    }, [field.label, getTextWidth]);
    return (
        <div className={`input-wrapper ${placeholderWidth > placeholderWidthRestrict ? 'show-tooltip' : ''}`}>
            {!isTooltipDisabled && (
                <div className="tooltip">
                    <div>
                        <p>{field.label}</p>
                    </div>
                </div>
            )}
            <TextValidator
                label={field.label}
                key={field.name}
                placeholder={field.label}
                type={field.type}
                name={field.name}
                value={field.value}
                variant="outlined"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                validators={field.validators}
                errorMessages={field.errorMessages}
                multiline={field.multiline > 0}
                rows={field.multiline}
                fullWidth={true}
                margin="normal"
                className={`${
                    !isTooltipDisabled ? 'field-container' : 'email-text-field field-container withEndAdornment'
                }`}
                onFocus={handleLongPlaceholderOnMobile}
                onBlur={removeAnimationPlaceholderMobile}
            />
        </div>
    );
};

export default TextFieldInfoPoint;
