import Button from '@material-ui/core/Button';

const Announcement = ({announcement, handleCallToActionButtonClicked}) => {
    const handleButtonClicked = (announcement) => e => {
        handleCallToActionButtonClicked(announcement)
    }
    return (
        <div className="announcement">
            <div className="announcement-content">
                <div className="announcement-title">{announcement.title}</div>
                <div className="announcement-description">{announcement.description}</div>
            </div>

            {announcement?.callToAction && (
                <Button 
                    variant={"outlined"}
                    disableElevation
                    className={"light call-to-action-button"}
                    onClick={handleButtonClicked(announcement)}
                >
                        {announcement.buttonLabel}
                </Button>
            )}
        </div>
    );
};

export default Announcement;
