import ar from './ar';
import zh from './zh';
import de from './de';
import en from './en';
import es from './es';
import fr from './fr';
import no from './no';
import pt from './pt';
import ru from './ru';
import ua from './ua';
import tr from './tr';

const allTranslations = {
    ar: {slug: 'ar', title: 'Arabic', content: ar},
    ch: {slug: 'zh', title: 'Chinese', content: zh},
    de: {slug: 'de', title: 'German', content: de},
    en: {slug: 'en', title: 'English', content: en},
    es: {slug: 'es', title: 'Spanish', content: es},
    fr: {slug: 'fr', title: 'French', content: fr},
    no: {slug: 'no', title: 'Norwegian', content: no},
    pt: {slug: 'pt', title: 'Portuguese', content: pt},
    ru: {slug: 'ru', title: 'Russian', content: ru},
    ua: {slug: 'ua', title: 'Ukrainian', content: ua},
    tr: {slug: 'tr', title: 'Turkish', content: tr},
};

const allTranslationList = Object.values(allTranslations);

export {allTranslations, allTranslationList};
