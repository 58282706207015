import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {preventDefaultDrag} from "../../../../Utils/utils";
import SingleImage from "./SingleImage";

class Pictures extends Component {



    render() {
        const {handleChangeImage, handleOpenSuccessSnackbar, handleOpenErrorSnackbar, handleDiscardChanges, eventWelcomeScreen} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="branding-background-image-container">
                <SingleImage
                    sectionTitle={'DESKTOP IMAGE'}
                    device={'desktop'}
                    for={'desktopImage'}
                    label={'Uploaded'}
                    image={eventWelcomeScreen.data?.desktopImage}
                    openSuccessSnackbar={handleOpenSuccessSnackbar}
                    openErrorSnackbar={handleOpenErrorSnackbar}
                    description={
                        <Fragment>
                            <p>Static and animated images are supported.</p>
                            <p>Recommended image ratio 16:9 (landscape-oriented, e.g. 1920:1080 px).</p>
                            <p>Keep a note: on some devices image can look different, because of vary aspect ratio and pixel density.</p>
                        </Fragment>
                    }
                    imageRatio={0.5625}
                    handleChangeImage={handleChangeImage}
                    handleDiscardChanges={handleDiscardChanges}
                />
                <SingleImage
                    sectionTitle={'MOBILE / TABLET IMAGE'}
                    device={'mobile'}
                    for={'mobileImage'}
                    label={'Uploaded'}
                    image={eventWelcomeScreen.data?.mobileImage}
                    openSuccessSnackbar={handleOpenSuccessSnackbar}
                    openErrorSnackbar={handleOpenErrorSnackbar}
                    description={
                        <Fragment>
                            <p>Both static and animated images are supported.</p>
                            <p>Recommended image ratio 9:16 (portrait-oriented, e.g. 1080:1920 px).</p>
                            <p>Keep a note: on some devices image can look different, because of vary aspect ratio and pixel density.</p>
                            <p>If no picture is added, the desktop image will be displayed</p>
                        </Fragment>
                    }
                    imageRatio={1.777}
                    handleChangeImage={handleChangeImage}
                    handleDiscardChanges={handleDiscardChanges}
                />
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        eventWelcomeScreen: state.eventWelcomeScreen
    };
}

export default connect(
    mapStateToProps,
)(Pictures);
