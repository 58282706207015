import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Chats from '../EventMenuDropdowns/Chats/Chats';
import Contacts from '../EventMenuDropdowns/Contacts';
import MyAccount from '../EventMenuDropdowns/MyAccount';
import MyEvents from '../EventMenuDropdowns/MyEvents';
import Notifications from '../EventMenuDropdowns/Notifications';
import Program from '../EventMenuDropdowns/Program/Program';

import {announcementsUpdated, deregisterAnnouncements} from '../../Api/socketApi';
import colors from '../../CSS/_variables.module.scss';
import '../../CSS/headerMenu.scss';
import '../../CSS/headerMenuMobile.scss';
import DetectOutsideClick from '../../HOC/DetectOutsideClick';
import {ReactComponent as AccountIcon} from '../../Images/svg/account.svg';
import {ReactComponent as AnnouncementsIcon} from '../../Images/svg/announcements.svg';
import {ReactComponent as MenuDownIcon} from '../../Images/svg/arrow_down.svg';
import {ReactComponent as MenuUpIcon} from '../../Images/svg/arrow_up.svg';
import {ReactComponent as ChatsIcon} from '../../Images/svg/chat.svg';
import {ReactComponent as ProgramIcon} from '../../Images/svg/checklist.svg';
import {ReactComponent as LobbyIcon} from '../../Images/svg/lobby-logo.svg';
import {ReactComponent as ContactsIcon} from '../../Images/svg/phone-book.svg';
import {ReactComponent as NotificationsIcon} from '../../Images/svg/ring.svg';
import {ReactComponent as RoomsIcon} from '../../Images/svg/rooms-mobile.svg';
import {isWebview} from '../../Utils/is-webview';
import {checkIfEventHasEnded, preventDefaultDrag} from '../../Utils/utils';
import * as actions from '../../store/actions';
import {setAnnouncementsBannerOpen} from '../../store/actions/announcementsActions';
import AnnouncementsBannerOrIcon from '../AnnouncementsBanner/AnnouncementsBannerOrIcon';
import ProgramSubmenu from './Program/ProgramSubmenu';
import Rooms from './Rooms/Rooms';
class EventMenuMobile extends React.Component {
    state = {
        announcementsCollapsed: true,
        location: 'other',
        userDidLogIn: true,
        eventChanged: true,
        filteredAnnouncements: [],
        announcementsIconVisible: false,
    };

    componentDidMount() {
        this._addOverlayWhenMyEventsIsOpened();
        document.addEventListener('mousedown', this._addOverlayWhenMyEventsIsOpened);

        this.updateLocation();
        announcementsUpdated((data) => {
            const newAnnouncements = data.announcements.payload;
            this.props.onGetRunningAnnouncementsSuccess(newAnnouncements);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {event, eventArchive, seeRooms, topNavigation, eventId} = this.props;
        if (
            prevProps.event.hideAuditoriumDoor !== event.hideAuditoriumDoor &&
            event.hideAuditoriumDoor &&
            event.hideShowfloorDoor &&
            eventArchive &&
            !eventArchive.length > 0 &&
            topNavigation.seeRooms
        ) {
            seeRooms();
            this._addOverlayWhenMyEventsIsOpened();
        }

        if (
            prevProps.event.hideShowfloorDoor !== event.hideShowfloorDoor &&
            event.hideAuditoriumDoor &&
            event.hideShowfloorDoor &&
            eventArchive &&
            !eventArchive.length > 0 &&
            topNavigation.seeRooms
        ) {
            seeRooms();
            this._addOverlayWhenMyEventsIsOpened();
        }

        this.updateLocation(prevProps);

        const userDidLogIn = this.props.loggedIn === true && prevProps.loggedIn === false;
        if (userDidLogIn) {
            this.setState({userDidLogIn: true});
        }
        const eventChanged = this.props.eventId !== prevProps.eventId;
        if (eventChanged) {
            this.setState({eventChanged: true, filteredAnnouncements: [], announcementsIconVisible: false});
        }

        if (this.state.userDidLogIn || this.state.eventChanged) {
            this.getAnnouncements(prevProps, prevState);
        }

        const announcementsChange = this.props.runningAnnouncements !== prevProps.runningAnnouncements;
        if (announcementsChange) {
            this.filterAnnouncements(false);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._addOverlayWhenMyEventsIsOpened);
        clearTimeout(this.timeoutId);
        deregisterAnnouncements();
    }

    getAnnouncements = (prevProps, prevState) => {
        // because eventId changes before all event data, from where we get announcementsEnabled
        const eventDataFinishedLoading = this.props.eventId && this.props.event?._id === this.props.eventId;

        if (eventDataFinishedLoading && this.props.announcementsEnabled) {
            this.props.onGetRunningAnnouncements(this.props.eventId);
            this.setState({eventChanged: false, userDidLogIn: false});
        }
    };

    filterAnnouncements = (locationChanged, location = this.state.location) => {
        if (this.props.announcementsEnabled === false) {
            this.setState({filteredAnnouncements: [], announcementsIconVisible: false});
            return;
        }
        const newFilteredAnnouncements = [];

        this.props.runningAnnouncements?.forEach((announcement) => {
            if (announcement?.areasOfDisplay.includes(location)) {
                newFilteredAnnouncements.push(announcement);
            }
        });
        this.setState({
            filteredAnnouncements: newFilteredAnnouncements,
            announcementsIconVisible: !!newFilteredAnnouncements.length,
        });
        // open banner when there is a new announcement
        if (!locationChanged && newFilteredAnnouncements.length > this.state.filteredAnnouncements.length) {
            this.openBanner();
        }
    };

    handleOpenCloseBanner = () => {
        this.props.onSetAnnouncementsBannerOpen(this.state.announcementsCollapsed);
        this.setState({announcementsCollapsed: !this.state.announcementsCollapsed});
    };

    collapseBanner = () => {
        this.props.onSetAnnouncementsBannerOpen(false);
        this.setState({announcementsCollapsed: true});
    };

    openBanner = () => {
        this.props.onSetAnnouncementsBannerOpen(true);
        this.setState({announcementsCollapsed: false});
    };

    updateLocation = (prevProps = null) => {
        const thisLocation = this.props.location.pathname;
        if (thisLocation !== prevProps?.location?.pathname || prevProps === null) {
            if (thisLocation.includes('dashboard')) {
                this.setState({location: 'other'});
                return;
            }
            if (thisLocation.includes('lobby')) {
                this.setState({location: 'lobby'});
                this.filterAnnouncements(true, 'lobby');
                return;
            }
            if (thisLocation.includes('auditorium')) {
                this.setState({location: 'auditoriums'});
                this.filterAnnouncements(true, 'auditoriums');
                return;
            }
            if (thisLocation.includes('showfloor')) {
                this.setState({location: 'exhibitHalls'});
                this.filterAnnouncements(true, 'exhibitHalls');
                return;
            }
            if (thisLocation.includes('event-archive')) {
                this.setState({location: 'archive'});
                this.filterAnnouncements(true, 'archive');
                return;
            }
            this.setState({location: 'other'});
        }
    };

    _addOverlayWhenMyEventsIsOpened = () => {
        this.timeoutId = setTimeout(function () {
            if (
                document.getElementById('account-drop-mobile') ||
                document.querySelector('.notifications-mobile') ||
                document.querySelector('.my-account-mobile') ||
                document.getElementById('rooms-dropdown') ||
                document.getElementById('submenu-dropdown')
            ) {
                if (document.querySelector('.position-background')) {
                    document.querySelector('.position-background').classList.add('overlay-grey');
                } else if (document.getElementById('my-account-mobile')) {
                    document.getElementById('my-account-mobile').classList.add('overlay-grey');
                } else if (document.querySelector('.showfloor-page')) {
                    document.querySelector('.showfloor-page').classList.add('overlay-grey');
                }
            } else {
                if (document.querySelector('.position-background')) {
                    document.querySelector('.position-background').classList.remove('overlay-grey');
                } else if (document.getElementById('my-account-mobile')) {
                    document.getElementById('my-account-mobile').classList.remove('overlay-grey');
                } else if (document.querySelector('.showfloor-page')) {
                    document.querySelector('.showfloor-page').classList.remove('overlay-grey');
                }
            }
        }, 150);
    };

    redirectToLobby = () => {
        const {eventSlug} = this.props;
        this.props.onCloseTopNavMobile();
        this.props.history.push(`/event/${eventSlug}/lobby`);
    };

    checkIfEventHasEnded = () => {
        // check if event has ended
        // if yes, redirect to the video archive page
        const {event} = this.props;
        if (event) {
            return checkIfEventHasEnded(event, event.timezoneValue);
        }
    };

    render() {
        const {
            refreshPage,
            notificationsNotRead,
            activeMyAccount,
            activeHome,
            topNavigation,
            translation,
            event,
            seeMyEventsMobile,
            serverDown,
            sessionExpired,
            seeMyAccountMobile,
            notReadChatMessages,
            seeNotificationsMobile,
            seeRooms,
            seeContactsMobile,
            seeChatsMobile,
            seeProgramSubmenu,
            eventArchive,
        } = this.props;

        // fix for removing green lobby when one overlay is opened;
        let activeLobby =
            activeHome &&
            !topNavigation.seeRooms &&
            !topNavigation.seeContactsMobile &&
            !topNavigation.seeChatsMobile &&
            !topNavigation.seeProgramMobile &&
            !topNavigation.seeMeetingsMobile &&
            !topNavigation.seeProgramSubmenu;

        return (
            <DetectOutsideClick>
                <div onDragStart={preventDefaultDrag} className="top-container">
                    {serverDown && (
                        <div onDragStart={preventDefaultDrag} className="server-down-info-container">
                            <p>
                                {translation?.eventMenu.connectionProblemTextFirst}
                                <span onDragStart={preventDefaultDrag} draggable="false" onClick={refreshPage}>
                                    {translation?.eventMenu.connectionProblemButton}
                                </span>
                                {translation?.eventMenu.connectionProblemTextSecond}
                            </p>
                        </div>
                    )}
                    {sessionExpired && (
                        <div onDragStart={preventDefaultDrag} className="server-down-info-container">
                            <p>
                                {translation?.eventMenu.sessionProblemTextFirst}
                                <span onDragStart={preventDefaultDrag} draggable="false" onClick={refreshPage}>
                                    {translation?.eventMenu.sessionProblemButton}
                                </span>
                                {translation?.eventMenu.sessionProblemTextSecond}
                            </p>
                        </div>
                    )}
                    <header id="header-mobile" className="header">
                        <div onDragStart={preventDefaultDrag} className="main-menu-container">
                            <div onDragStart={preventDefaultDrag} className="actions-container">
                                <div onDragStart={preventDefaultDrag} className="left-header-wrapper">
                                    <div onDragStart={preventDefaultDrag} id="my-events" onClick={seeMyEventsMobile}>
                                        <p>
                                            <span className="long-event-name">{event.name}</span>
                                        </p>
                                        {topNavigation.seeMyEventsMobile ? (
                                            <MenuUpIcon fill={colors.primary} />
                                        ) : (
                                            <MenuDownIcon fill={colors.grey} />
                                        )}
                                    </div>
                                </div>
                                <div onDragStart={preventDefaultDrag} className="right-header-wrapper">
                                    {this.state.announcementsIconVisible && (
                                        <div
                                            id="announcements"
                                            onDragStart={preventDefaultDrag}
                                            onClick={this.handleOpenCloseBanner}
                                            className={`menu-anncouncements-icon ${
                                                this.state.announcementsCollapsed ? 'purple-icon' : 'gray-icon'
                                            }`}
                                        >
                                            <AnnouncementsIcon />
                                        </div>
                                    )}

                                    <div
                                        onDragStart={preventDefaultDrag}
                                        id="notifications"
                                        onClick={seeNotificationsMobile}
                                    >
                                        <NotificationsIcon
                                            fill={`${
                                                topNavigation.seeNotificationsMobile ? colors.primary : colors.grey
                                            }`}
                                        />
                                        {notificationsNotRead && (
                                            <div
                                                className={`not-read-notifications flex-center ${
                                                    notificationsNotRead.toString().length >= 3 ? 'fs9' : 'fs10'
                                                }`}
                                            >
                                                <span>
                                                    {notificationsNotRead.toString().length >= 3
                                                        ? '99+'
                                                        : notificationsNotRead}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        id="myaccount"
                                        onClick={seeMyAccountMobile}
                                        className={topNavigation.seeMyAccountMobile || activeMyAccount ? 'active' : ''}
                                    >
                                        <AccountIcon
                                            fill={`${
                                                topNavigation.seeMyAccountMobile || activeMyAccount
                                                    ? colors.primary
                                                    : colors.grey
                                            }`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    {!this.state.announcementsCollapsed && (
                        <AnnouncementsBannerOrIcon
                            announcements={this.state.filteredAnnouncements}
                            handleCollapse={this.collapseBanner}
                        />
                    )}

                    {topNavigation.seeContactsMobile && <Contacts mobile />}
                    {(topNavigation.seeProgramMobile || topNavigation.seeMeetingsMobile) && (
                        <Program
                            active={topNavigation.seeProgramSubmenu}
                            mobile
                            isMeetingsActive={topNavigation.seeMeetings}
                        />
                    )}
                    {topNavigation.seeChatsMobile && <Chats />}
                    {topNavigation.seeNotificationsMobile && <Notifications mobile />}
                    {topNavigation.seeMyEventsMobile && <MyEvents mobile />}
                    {topNavigation.seeMyAccountMobile && <MyAccount mobile />}
                    {/*
                    {topNavigation.seeMeetingsMobile && <Meetings active={topNavigation.seeProgramSubmenu} mobile />}
*/}
                </div>
                <div
                    onDragStart={preventDefaultDrag}
                    id="menu-bottom"
                    className={isWebview() ? 'menu-bottom is-webview' : 'menu-bottom'}
                >
                    <div onDragStart={preventDefaultDrag} className="menu-bottom-wrapper">
                        <div onDragStart={preventDefaultDrag} className="main-menu-container">
                            <div onDragStart={preventDefaultDrag} className="main-header-wrapper">
                                <div
                                    className={'home-button ' + (activeLobby ? 'active' : '')}
                                    onClick={this.redirectToLobby}
                                >
                                    <LobbyIcon fill={`${activeLobby ? colors.primary : colors.grey}`} />
                                    <p>{translation?.eventMenu.lobbyButton}</p>
                                </div>
                                {event.hideAuditoriumDoor &&
                                event.hideShowfloorDoor &&
                                eventArchive &&
                                !eventArchive.length > 0 ? null : (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        id="rooms"
                                        onClick={seeRooms}
                                        className={topNavigation.seeRooms ? 'active' : ''}
                                    >
                                        <RoomsIcon
                                            className={`${topNavigation.seeRooms ? 'rotate-90deg' : ''}`}
                                            fill={`${topNavigation.seeRooms ? colors.primary : colors.grey}`}
                                        />
                                        <p>{translation?.eventMenu.roomsButton}</p>
                                    </div>
                                )}
                                <div
                                    id="contacts"
                                    onClick={seeContactsMobile}
                                    className={topNavigation.seeContactsMobile ? 'active' : ''}
                                >
                                    <ContactsIcon
                                        stroke={`${topNavigation.seeContactsMobile ? colors.primary : colors.greyDark}`}
                                        fill={`${topNavigation.seeContactsMobile ? colors.primary : colors.greyDark}`}
                                    />
                                    <p>{translation?.eventMenu.contactsButton}</p>
                                </div>
                                <div
                                    id="chats"
                                    onClick={seeChatsMobile}
                                    className={topNavigation.seeChatsMobile ? 'active' : ''}
                                >
                                    {topNavigation.seeChatsMobile ? (
                                        <span>
                                            <ChatsIcon fill={colors.primary} />
                                            {notReadChatMessages && (
                                                <div
                                                    className={`flex-center ${
                                                        notReadChatMessages.toString().length >= 3
                                                            ? 'fs9'
                                                            : notReadChatMessages.toString().length >= 2
                                                            ? 'fs10'
                                                            : 'fs12'
                                                    }`}
                                                >
                                                    <span>
                                                        {notReadChatMessages.toString().length >= 3
                                                            ? '99+'
                                                            : notReadChatMessages}
                                                    </span>
                                                </div>
                                            )}
                                        </span>
                                    ) : (
                                        <span>
                                            <ChatsIcon fill={colors.grey} />
                                            {notReadChatMessages && (
                                                <div
                                                    className={`flex-center ${
                                                        notReadChatMessages.toString().length >= 3
                                                            ? 'fs9'
                                                            : notReadChatMessages.toString().length >= 2
                                                            ? 'fs10'
                                                            : 'fs12'
                                                    }`}
                                                >
                                                    <span>
                                                        {notReadChatMessages.toString().length >= 3
                                                            ? '99+'
                                                            : notReadChatMessages}
                                                    </span>
                                                </div>
                                            )}
                                        </span>
                                    )}

                                    <p>{translation?.eventMenu.chatsButton}</p>
                                </div>
                                <div
                                    id="program"
                                    onClick={seeProgramSubmenu}
                                    className={
                                        topNavigation.seeProgramSubmenu ||
                                        topNavigation.seeProgramMobile ||
                                        topNavigation.seeMeetingsMobile
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    <ProgramIcon
                                        fill={`${
                                            topNavigation.seeProgramSubmenu ||
                                            topNavigation.seeProgramMobile ||
                                            topNavigation.seeMeetingsMobile
                                                ? colors.primary
                                                : colors.grey
                                        }`}
                                    />
                                    <p>{translation?.eventMenu.programButton}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {topNavigation.seeRooms && <Rooms />}
                    {topNavigation.seeProgramSubmenu && (
                        <DetectOutsideClick>
                            <ProgramSubmenu />
                        </DetectOutsideClick>
                    )}
                </div>
            </DetectOutsideClick>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        event: state.event.data,
        announcementsEnabled: state.event?.data?.announcementsEnabled,
        eventArchive: state.eventArchive.data,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        loggedIn: state.user.loggedIn,
        notReadChatMessages: state.user.notReadChatMessages,
        topNavigation: state.user.topNavigation,
        serverDown: state.user.serverDown,
        sessionExpired: state.user.sessionExpired,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        hasOnDemandVideos: state.onDemandVideos.hasOnDemandVideos,
        runningAnnouncements: state.announcements.runningAnnouncements,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        seeContactsMobile: () => dispatch(actions.topNavSeeContactsMobile()),
        seeProgramMobile: () => dispatch(actions.topNavSeeProgramMobile()),
        seeChatsMobile: () => dispatch(actions.topNavSeeChatsMobile()),
        seeMyEventsMobile: () => dispatch(actions.topNavSeeMyEventsMobile()),
        seeNotificationsMobile: () => dispatch(actions.topNavSeeNotificationsMobile()),
        seeMyAccountMobile: () => dispatch(actions.topNavSeeMyAccountMobile()),
        onSetNotReadChatMessages: (numberOfNotReadMessages) =>
            dispatch(actions.setNotReadChatMessages(numberOfNotReadMessages)),
        onSetEventAccess: (resourcesAccess) => dispatch(actions.setEventAccess(resourcesAccess)),
        seeRooms: () => dispatch(actions.bottomSeeRooms()),
        seeProgramSubmenu: () => dispatch(actions.bottomSeeProgramSubmenu()),
        onCloseTopNavMobile: () => dispatch(actions.topNavCloseMobile()),
        onGetRunningAnnouncements: (eventId) => dispatch(actions.getRunningAnnouncements(eventId)),
        onGetRunningAnnouncementsSuccess: (newAnnouncements) =>
            dispatch(actions.getRunningAnnouncementsSuccess(newAnnouncements)),
        announcementsUpdated: () => dispatch(announcementsUpdated()),
        onSetAnnouncementsBannerOpen: (open) => dispatch(setAnnouncementsBannerOpen(open)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventMenuMobile));
