import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {useSelector} from 'react-redux';

import CloseIcon from '@material-ui/icons/Close';
import './DialogAddToExternalCalendar.scss';
import ExternalCalendarLinks from './ExternalCalendarLinks';
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {Button} from "@material-ui/core";

const DialogAddToExternalCalendar = ({open, onClose, eventCalendarData, tip, title, durationTitle, duration, timeslotSpeakers, timeslotDescripion, timeslotTitle}) => {

    const currentTranslation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const formatSpeakersDescription = () => {
        return timeslotSpeakers ? (<span className='color-black'> - </span>) : null;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            className={'add-to-external-calendar-dialog preserveHeaderDropdown reverse-rtl'}
            onClick={e=>e.stopPropagation()}
        >
            <div className="close-icon">
                <Button
                    className='only-icon-button'
                    onClick={onClose}                >
                    <ClearIcon />
                </Button>
            </div>
            <div className='content-wrapper'>
                <p className={'title'}>{title}</p>

                <div className='calendar-links'>
                    {timeslotTitle &&
                        <p>
                            <span className='event-timeslot-duration'>
                                {timeslotTitle}
                            </span>
                            {timeslotDescripion}{formatSpeakersDescription()}
                            <span className='event-timeslot-duration'>
                                {timeslotSpeakers}</span>
                        </p>
                    }
                    <p><span className='event-timeslot-duration'>{durationTitle}</span>{duration}</p>
                    <ExternalCalendarLinks eventCalendarData={eventCalendarData} />
                </div>
                <div className="tip-container">
                    <p><span>{currentTranslation?.programDropdown.tip || defaultTranslation?.programDropdown.tip}</span>{tip}</p>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogAddToExternalCalendar;


