import React, {PureComponent, createRef} from 'react';
import {preventDefaultDrag} from '../../../../Utils/utils';
import {ValidatorForm} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions';
import Title from './Title';
import DisplayFrequency from './DisplayFrequency';
import DisplaySize from './DisplaySize';
import Type from './Type';
import VideoUrl from './VideoUrl';
import './welcomeScreen.scss';
import Pictures from './Pictures';
import Spinner from '../../../../SmallLayoutComponents/Spinner';
import WelcomeScreenHeader from './WelcomeScreenHeader';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import {isYoutubeOrVimeoLinkRule} from './../../../../Utils/validationRules';
import {fireClickEvent} from 'Utils/utils';
import Confirm from './../../../../Dialogs/Confirm';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

class WelcomeScreen extends PureComponent {
    state = {
        title: this.props.event.name,
        displayFrequency: 'first-time',
        displaySize: 'full-screen-size',
        type: 'video',
        videoUrl: '',
        desktopPicture: '',
        mobilePicture: '',
        submitButtonDisabled: true,
        openConfirmUnsavedChanges: false,
        navigationElement: null,
    };

    wrapperRef = createRef();
    selectDropDownRef = createRef();
    handleClickOutside = this.handleClickOutside.bind(this);

    componentDidMount() {
        const {eventId} = this.props;
        this.props.getEventWelcomeScreen(eventId);
        ValidatorForm.addValidationRule('isYoutubeOrVimeoLink', (value) => {
            let rule = isYoutubeOrVimeoLinkRule;
            let match = rule.test(value);

            if (value?.length === 0) {
                match = true;
            }
            if (!match) {
                this.setState({formError: true});
                return false;
            }
            this.setState({formError: false});
            return true;
        });

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.eventWelcomeScreen !== this.props.eventWelcomeScreen && this.props.eventWelcomeScreen.data) {
            this.updateLocalStateWelcomeScreen();
        }
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isYoutubeOrVimeoLink');

        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    updateLocalStateWelcomeScreen = () => {
        const {eventWelcomeScreen} = this.props;
        if (eventWelcomeScreen) {
            this.setState({
                title: eventWelcomeScreen.data?.title,
                displayFrequency: eventWelcomeScreen.data?.displayFrequency,
                displaySize: eventWelcomeScreen.data?.displaySize,
                type: eventWelcomeScreen.data?.type,
                videoUrl: eventWelcomeScreen.data?.videoUrl,
                desktopPicture: eventWelcomeScreen.data?.desktopImage
            });
        }
    };

    saveWelcomeScreen = () => {
        const {eventId} = this.props;
        const {navigationElement} = this.state;

        const {title, displayFrequency, displaySize, type, videoUrl, desktopPicture} = this.state;
        const data = {
            title: title,
            displayFrequency: displayFrequency,
            displaySize: displaySize,
            type: type,
            videoUrl: videoUrl,
            desktopImage: desktopPicture,
        };

        this.props.updateEventWelcomeScreen(eventId, data).then(() => {
            this.setState({
                submitButtonDisabled: true,
                openConfirmUnsavedChanges: false,
            });

            if (navigationElement) {
                fireClickEvent(navigationElement);
            }
        });
    };

    handleChangeTitle = (e) => {
        const isSubmitButtonDisabled = e.target.value.length === 0 || e.target.value.length > 50;

        this.setState({
            title: e.target.value,
            submitButtonDisabled: isSubmitButtonDisabled,
        });
    };

    handleChangeDisplayFrequency = (e) => {
        this.setState({
            displayFrequency: e.target.value,
            submitButtonDisabled: false,
        });
    };

    handleChangeDisplaySize = (e) => {
        this.setState({
            displaySize: e.target.value,
            submitButtonDisabled: false,
        });
    };

    handleChangeType = (e) => {
        this.setState({
            type: e.target.value,
            submitButtonDisabled: false,
        });
    };

    handleChangeVideoUrl = (e) => {
        const isSubmitButtonDisabled = !e.target.value.match(isYoutubeOrVimeoLinkRule);

        this.setState({
            videoUrl: e.target.value,
            submitButtonDisabled: isSubmitButtonDisabled,
        });
    };

    handleChangeImage = (imageType, imageFile) => (e) => {
        this.setState({
            [imageType]: imageFile,
        });
    };

    handleDiscardImageChanges = () => {
        const {eventWelcomeScreen} = this.props;

        this.closeClickOutside();

        if (eventWelcomeScreen?.data) {
            this.setState({
                desktopPicture: eventWelcomeScreen.data.desktopPicture,
                mobilePicture: eventWelcomeScreen.data.mobilePicture,
            });
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.updateLocalStateWelcomeScreen();
        this.setState({submitButtonDisabled: true});

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (!this.wrapperRef.current?.contains(e.target) && !this.selectDropDownRef.current?.contains(e.target)) {
            if (!this.state.submitButtonDisabled && !this.state.submitButtonDisabled) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    closeClickOutside = () => this.setState({openConfirmUnsavedChanges: false});

    render() {
        const {title, displayFrequency, displaySize, type, videoUrl, submitButtonDisabled, openConfirmUnsavedChanges} =
            this.state;
        const {handleOpenErrorSnackbar, handleOpenSuccessSnackbar, eventWelcomeScreen} = this.props;

        // make sure we have the welcome screen data before rendering anything
        if (!eventWelcomeScreen?.data) return null;

        return (
            <div onDragStart={preventDefaultDrag} className="welcome-screen-dashboard">
                {eventWelcomeScreen.loading && <Spinner />}
                <WelcomeScreenHeader />
                <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                    <div onDragStart={preventDefaultDrag} ref={this.wrapperRef}>
                        <ValidatorForm ref="form" onSubmit={this.saveWelcomeScreen}>
                            <Title title={title} handleChangeTitle={this.handleChangeTitle} />
                            <DisplayFrequency
                                selectDropDownRef={this.selectDropDownRef}
                                displayFrequency={displayFrequency}
                                handleChangeDisplayFrequency={this.handleChangeDisplayFrequency}
                            />
                            <DisplaySize
                                selectDropDownRef={this.selectDropDownRef}
                                displaySize={displaySize}
                                handleChangeDisplaySize={this.handleChangeDisplaySize}
                            />
                            <Type type={type} handleChangeType={this.handleChangeType} />
                            {type === 'video' && (
                                <VideoUrl videoUrl={videoUrl} handleChangeVideoUrl={this.handleChangeVideoUrl} />
                            )}
                            {type === 'picture' && (
                                <Pictures
                                    handleChangeImage={this.handleChangeImage}
                                    handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                                    handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                                    handleDiscardChanges={this.handleDiscardImageChanges}
                                />
                            )}
                            <div onDragStart={preventDefaultDrag} className="action-container">
                                <Button
                                    type="submit"
                                    disabled={submitButtonDisabled}
                                    startIcon={<SaveOutlinedIcon />}
                                    variant="contained"
                                    color={'secondary'}
                                    disableElevation
                                >
                                    Save
                                </Button>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveWelcomeScreen}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        eventWelcomeScreen: state.eventWelcomeScreen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventWelcomeScreen: (eventId) => dispatch(actions.getEventWelcomeScreen(eventId)),
        updateEventWelcomeScreen: (eventId, welcomeScreenData) =>
            dispatch(actions.updateEventWelcomeScreen(eventId, welcomeScreenData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen);
