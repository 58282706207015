import React from 'react';

const EventDailyStat = ({statistics, stat}) => {
    return (
        statistics.map(
            singleStatistic => (
                <p>Date : {singleStatistic.date} - {stat} number : {singleStatistic.count}</p>
            )
        )
    );
};

export default EventDailyStat;