import React, {PureComponent} from 'react';
import {preventDefaultDrag, fireClickEvent} from '../../../Utils/utils';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import BrandingBackgroundImage from './LayoutComponents/BrandingBackgroundImage';
import MovieIcon from '@material-ui/icons/Movie';
import {connect} from 'react-redux';
import Confirm from '../../../Dialogs/Confirm';
import isEqual from 'lodash/isEqual';
import {isYoutubeOrVimeoLinkRule} from 'Utils/validationRules';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {CircularProgress} from "@material-ui/core";

class LobbyInfoScreen extends PureComponent {
    wrapperRefFirst = React.createRef();
    handleClickOutsideFirst = this.handleClickOutsideFirst.bind(this);

    componentDidMount() {
        this.initState();

        ValidatorForm.addValidationRule('isYoutubeOrVimeoLink', (value) => {
            let rule = isYoutubeOrVimeoLinkRule;
            let match = rule.test(value);

            if (value?.length === 0) {
                match = true;
            }
            if (!match) {
                this.setState({formError: true});
                return false;
            }
            this.setState({formError: false});
            return true;
        });
        document.addEventListener('mousedown', this.handleClickOutsideFirst);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.branding?.data, this.props.branding?.data)) {
            this.setEventBranding();
        }
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isYoutubeOrVimeoLink');
        document.removeEventListener('mousedown', this.handleClickOutsideFirst);
    }

    state = {
        fields: [
            {
                name: 'lobbyVideoUrl',
                description: 'Optional field',
                adornment: <MovieIcon/>,
                value: '',
                label: 'YouTube or Vimeo video URL for the lobby video',
                multiline: 0,
                validators: ['isYoutubeOrVimeoLink'],
                errorMessages: [
                    'Please enter a valid YouTube (https://www.youtube.com) or Vimeo (https://vimeo.com/) link',
                ],
            },
        ],
        buttonDisabled: true,
        openConfirmUnsavedChangesFirst: false,
        navigationElement: null,
    };

    initState = () => {
        if (this.props.branding?.data) {
            this.setEventBranding();
        }
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChangesFirst} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChangesFirst) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutsideFirst(e) {
        if (this.wrapperRefFirst && !this.wrapperRefFirst.current.contains(e.target)) {
            if (!this.state.buttonDisabled) {
                this.setState({
                    openConfirmUnsavedChangesFirst: true,
                    navigationElement: this.getNewNavigationElement(e)
                });
            }
        }
    }

    saveEventBranding = () => {
        const {navigationElement} = this.state;

        let data = {};
        this.state.fields.forEach((field) => {
            data[field.name] = field.value;
        });
        this.props.saveEventBranding(data);
        this.setState({openConfirmUnsavedChangesFirst: false, buttonDisabled: true});

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutsideFirst();
        this.initState();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    setEventBranding = () => {
        const {branding} = this.props;
        const brandingData = branding.data;
        this.setState({
            fields: [{...this.state.fields[0], value: brandingData?.lobbyVideoUrl}],
            buttonDisabled: true,
        });
    };

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({buttonDisabled: !isValid});
            });
        });
    };

    closeClickOutsideFirst = () => {
        this.setState({openConfirmUnsavedChangesFirst: false});
    };

    render() {
        const {fields, buttonDisabled, openConfirmUnsavedChangesFirst} = this.state;
        const {handleOpenErrorSnackbar, handleOpenSuccessSnackbar, branding, loadingSaveEventBranding} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="branding-lobby-content">
                <h4 className="advanced-options-title">LOBBY INFO SCREEN</h4>

                <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                    <div onDragStart={preventDefaultDrag} ref={this.wrapperRefFirst}
                         className="lobby-link-container">
                        {loadingSaveEventBranding ?
                            <div
                                className={"d-flex align-items-center justify-content-center"}>
                                <CircularProgress color="primary"/>
                            </div> :
                            <ValidatorForm ref="form" onSubmit={this.saveEventBranding}>
                                {fields.map((field, index) => {
                                    return (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="options-container"
                                            key={field.name}
                                        >
                                            <div onDragStart={preventDefaultDrag} className="single-option-container">
                                                <TextValidator
                                                    className="setting-input"
                                                    label={field.label}
                                                    type="text"
                                                    name={field.name}
                                                    index={index}
                                                    value={field.value}
                                                    onChange={this.handleChange(index)}
                                                    validators={field.validators}
                                                    errorMessages={field.errorMessages}
                                                    multiline={field.multiline > 0}
                                                    rows={field.multiline}
                                                    fullWidth={true}
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: field.adornment,
                                                    }}
                                                />
                                            </div>
                                            <div onDragStart={preventDefaultDrag} className="input-description">
                                                <p>{field.description}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div onDragStart={preventDefaultDrag} className="action-container">
                                    <Button
                                        type="submit"
                                        variant={"contained"}
                                        disabled={buttonDisabled}
                                        startIcon={<SaveOutlinedIcon/>}
                                        disableElevation
                                        color={"secondary"}
                                    >
                                        SAVE
                                    </Button>
                                </div>
                            </ValidatorForm>}
                    </div>

                    <div onDragStart={preventDefaultDrag} className="branding-background-image-container">
                        <BrandingBackgroundImage
                            for={'lobbyVideoImage'}
                            label={'Lobby Info Screen'}
                            description={
                                "The image is displayed on the Lobby info screen in case you haven't added a video link in the above section."
                            }
                            subLabel={'Recommended image ratio 1.82 : 1 (landscape-oriented, e.g. 781 x 429 pixels).'}
                            // imageRatio={1.91}
                            image={branding?.data?.lobbyVideoImage}
                            filesUrl={branding?.data?.filesUrl}
                            openSuccessSnackbar={handleOpenSuccessSnackbar}
                            openErrorSnackbar={handleOpenErrorSnackbar}
                            uploadUrl={'branding/upload-image'}
                            deleteUrl={'branding/delete-image/'}
                        />
                    </div>
                </div>
                {openConfirmUnsavedChangesFirst && (
                    <Confirm
                        open={openConfirmUnsavedChangesFirst}
                        closeConfirm={this.closeClickOutsideFirst}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveEventBranding}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        branding: state.event.branding,
    };
};

export default connect(mapStateToProps, null)(LobbyInfoScreen);
