import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import axios from '../../store/axios-instance';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import {preventDefaultDrag, cleanProhibitedCharactersInFileName, getEventDaysArray} from 'Utils/utils';
import {EXHIBITOR_TYPES} from 'Utils/constants/shared';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import './DownloadPostsStyles.scss';
import Button from '@material-ui/core/Button';
import Spinner from '../../SmallLayoutComponents/Spinner';
import {CircularProgress} from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DownloadPosts extends PureComponent {
    state = {
        canDownload: false,
        actionDownload: false,
        excelFields: [
            {label: 'Post', value: 'post'},
            {label: 'Timestamp', value: 'timestamp'},
            {label: 'First Name', value: 'fname'},
            {label: 'Last Name', value: 'lname'},
            {label: 'User Email', value: 'userEmail'},
            {label: 'User Roles', value: 'userRoles'},
            {label: 'Comments', value: 'commentsNo'},
            {label: 'Likes', value: 'likes'},
            {label: 'Comment', value: 'comment'},
            {label: 'Comment - Timestamp', value: 'commentTimestamp'},
            {label: 'Comment - User', value: 'commentUser'},
            {label: 'Comment - User Email', value: 'commentUserEmail'},
            {label: 'Comment - User Roles', value: 'commentUserRoles'},
        ],
        excelData: [],
        excelName: '',
        numberOfVideoWalls: 0,
        numberOfBoothWalls: 0,
        loading: false,
    };

    componentDidMount() {
        this.checkCanDownloadPosts();
        this.checkIfIsThereAnyWall();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {sideNavigationDetails} = this.props;
        if (prevProps.sideNavigationDetails.activeWallId !== sideNavigationDetails.activeWallId) {
            this.checkCanDownloadPosts();
            this.checkIfIsThereAnyWall();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.downloadPostsTimeoutId);
        clearTimeout(this.downloadPostsTimeoutId2);
    }

    checkCanDownloadPosts = () => {
        const {event, currentUser} = this.props;
        if (event?.owner?._id === currentUser?._id) {
            this.setState({
                canDownload: true,
            });
        }
    };

    generateString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = ' ';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    checkIfIsThereAnyWall = async () => {
        const {eventId, downloadType, wallId} = this.props;

        if (downloadType) {
            if (downloadType === 'videos') {
                const {data} = await axios.get('/event/' + eventId + '/fetch-video-wall-posts-report');

                if (data && data.success) {
                    this.setState({numberOfVideoWalls: data.walls?.length});
                }
            }

            if (downloadType === 'booths') {
                const {data} = await axios.get('/event/' + eventId + '/fetch-booth-wall-posts-report');

                if (data && data.success) {
                    this.setState({numberOfBoothWalls: data.walls?.length});
                }
            }
        }
    };

    handleDownloadPosts = () => {
        this.setState({loading: true});
        const {event, eventId, sideNavigationDetails, eventWallId, downloadType} = this.props;
        let wallId = sideNavigationDetails.activeWallId;
        if (eventWallId) {
            wallId = eventWallId;
        }

        if (downloadType) {
            let apiUrl = '';
            let excelName = '';
            if (downloadType === 'booths') {
                apiUrl = '/fetch-booth-wall-posts-report';
                excelName = `All Booth Wall Posts - ${event?.name}`;
            } else if (downloadType === 'videos') {
                apiUrl = '/fetch-video-wall-posts-report';
                excelName = `All Video Wall Posts - ${event?.name}`;
            }

            axios({method: 'get', url: '/event/' + eventId + apiUrl}).then((res) => {
                const {walls} = res.data;
                let arr = [];

                walls.forEach((wall) => {
                    let sheet = [];
                    wall?.posts.forEach((post, index) => {
                        let el = {};
                        el.post = post?.text
                            .replaceAll(/&#x2F;/g, '/')
                            .replaceAll(/&#x5C;/g, '\\')
                            .replaceAll(/&amp;/g, '&')
                            .replaceAll(/&gt;/g, '>')
                            .replaceAll(/&lt;/g, '<')
                            .replaceAll(/&quot;/g, '"')
                            .replaceAll(/&#x27;/g, `'`)
                            .replaceAll(/&apos;/g, `'`)
                            .replaceAll(/&#96;/g, '`');
                        let datePost = moment(post?.createdAt);
                        el.timestamp = moment(datePost).format('YYYY-MM-DD HH:mm:ss');
                        el.fname = post?.user?.first;

                        el.lname = post?.user?.last;
                        el.userEmail = post?.user?.email;
                        let userEventRoles = post.user.eventRoles.find((eventRole) => eventRole.event === wall.event);
                        let userEventRolesString = userEventRoles?.roles.join(', ');
                        el.userRoles = this.getUserRolesString(userEventRolesString, userEventRoles);
                        el.commentsNo = post?.comments?.length;
                        el.likes = post?.likes.length;
                        sheet.push(el);

                        post.comments.forEach((comment) => {
                            let el = {};
                            el.comment = comment?.text
                                .replaceAll(/&#x2F;/g, '/')
                                .replaceAll(/&#x5C;/g, '\\')
                                .replaceAll(/&amp;/g, '&')
                                .replaceAll(/&gt;/g, '>')
                                .replaceAll(/&lt;/g, '<')
                                .replaceAll(/&quot;/g, '"')
                                .replaceAll(/&#x27;/g, `'`)
                                .replaceAll(/&apos;/g, `'`)
                                .replaceAll(/&#96;/g, '`');

                            let dateComment = moment(comment?.createdAt);
                            el.commentTimestamp = moment(dateComment).format('YYYY-MM-DD HH:mm:ss');
                            el.commentUser = `${comment?.user?.first} ${comment?.user?.last}`;
                            el.commentUserEmail = comment?.user?.email;
                            let commentUserEventRoles = comment.user.eventRoles.find(
                                (eventRole) => eventRole.event === wall.event
                            );
                            let commentUserEventRolesString = commentUserEventRoles?.roles.join(', ');
                            el.commentUserRoles = this.getUserRolesString(
                                commentUserEventRolesString,
                                commentUserEventRoles
                            );
                            sheet.push(el);
                        });
                    });

                    sheet.name =
                        wall?.auditorium?.name + ' - ' + this.generateString(8) ||
                        wall?.exhibitor?.company + ' - ' + this.generateString(8) ||
                        `${wall?.exhibitor?.user?.first} ${wall?.exhibitor?.user?.last}` ||
                        '';
                    sheet.name = cleanProhibitedCharactersInFileName(sheet.name);
                    arr.push(sheet);
                });

                excelName = cleanProhibitedCharactersInFileName(excelName);
                this.setState({
                    excelName: excelName,
                    excelData: arr,
                    multiTabs: true,
                    actionDownload: true,
                });
                // bug re-download
                this.downloadPostsTimeoutId = setTimeout(() => {
                    this.setState({
                        actionDownload: false,
                    });
                }, 1000);
                this.setState({loading: false});
            });
        } else {
            axios({method: 'get', url: `/event/${eventId}/live-wall/${wallId}/download-posts`})
                .then((res) => {
                    const {wall} = res.data;

                    let arr = [];
                    let excelName = '';
                    switch (wall?.type) {
                        case 'videoWall':
                            let timeslotName = '';
                            wall?.auditorium?.dailyProgram.forEach((day) => {
                                let timeslot = day.program.find((program) => program.videoWall === wallId);
                                if (timeslot) {
                                    timeslotName = timeslot.title;
                                }
                            });
                            excelName = `${timeslotName || wall?.auditorium?.name} (Video Wall Posts) - ${event?.name}`;
                            break;
                        case 'boothWall':
                            let boothName = '';
                            if (wall.exhibitor.company) {
                                boothName = wall.exhibitor.company;
                            } else {
                                boothName = `${wall.exhibitor.user.first} ${wall.exhibitor.user.last}`;
                            }
                            excelName = `${boothName} (Booth Wall Posts) - ${event?.name}`;
                            break;
                        default:
                            excelName = `All Live Wall Posts - ${event?.name}`;
                    }

                    wall?.posts.forEach((post) => {
                        let el = {};
                        el.post = post?.text
                            .replaceAll(/&#x2F;/g, '/')
                            .replaceAll(/&#x5C;/g, '\\')
                            .replaceAll(/&amp;/g, '&')
                            .replaceAll(/&gt;/g, '>')
                            .replaceAll(/&lt;/g, '<')
                            .replaceAll(/&quot;/g, '"')
                            .replaceAll(/&#x27;/g, `'`)
                            .replaceAll(/&apos;/g, `'`)
                            .replaceAll(/&#96;/g, '`');
                        let datePost = moment(post?.createdAt);
                        el.timestamp = moment(datePost).format('YYYY-MM-DD HH:mm:ss');
                        el.fname = post?.user?.first;
                        el.lname = post?.user?.last;
                        el.userEmail = post?.user?.email;
                        let userEventRoles = post.user.eventRoles.find((eventRole) => eventRole.event === wall.event);
                        let userEventRolesString = userEventRoles?.roles.join(', ');
                        el.userRoles = this.getUserRolesString(userEventRolesString, userEventRoles);
                        el.commentsNo = post?.comments?.length;
                        el.likes = post?.likes.length;
                        arr.push(el);
                        post.comments.forEach((comment) => {
                            let el = {};
                            el.comment = comment?.text
                                .replaceAll(/&#x2F;/g, '/')
                                .replaceAll(/&#x5C;/g, '\\')
                                .replaceAll(/&amp;/g, '&')
                                .replaceAll(/&gt;/g, '>')
                                .replaceAll(/&lt;/g, '<')
                                .replaceAll(/&quot;/g, '"')
                                .replaceAll(/&#x27;/g, `'`)
                                .replaceAll(/&apos;/g, `'`)
                                .replaceAll(/&#96;/g, '`');
                            let dateComment = moment(comment?.createdAt);
                            el.commentTimestamp = moment(dateComment).format('YYYY-MM-DD HH:mm:ss');
                            el.commentUser = `${comment?.user?.first} ${comment?.user?.last}`;
                            el.commentUserEmail = comment?.user?.email;
                            let commentUserEventRoles = comment.user.eventRoles.find(
                                (eventRole) => eventRole.event === wall.event
                            );
                            let commentUserEventRolesString = commentUserEventRoles?.roles.join(', ');
                            el.commentUserRoles = this.getUserRolesString(
                                commentUserEventRolesString,
                                commentUserEventRoles
                            );
                            arr.push(el);
                        });
                    });

                    excelName = cleanProhibitedCharactersInFileName(excelName);
                    this.setState({
                        excelName: excelName,
                        excelData: arr,
                        actionDownload: true,
                    });
                    // bug re-download
                    this.downloadPostsTimeoutId2 = setTimeout(() => {
                        this.setState({
                            actionDownload: false,
                        });
                    }, 1000);
                    this.setState({loading: false});
                })
                .catch(() => {
                    this.setState({loading: false});
                });
        }
    };

    getUserRolesString = (rolesArray, eventRoles) => {
        let updatedRolesArray = rolesArray;
        updatedRolesArray = updatedRolesArray.replace('organizerRep', 'Receptionist');
        updatedRolesArray = updatedRolesArray.replace('organizer', 'Organizer');
        updatedRolesArray = updatedRolesArray.replace('coOrganizer', 'Co-Organizer');
        updatedRolesArray = updatedRolesArray.replace('participant', 'Participant');
        updatedRolesArray = updatedRolesArray.replace('speaker', 'Speaker');
        if (updatedRolesArray.includes('exhibitorRep')) {
            if (eventRoles?.exhibitorRepresentative[0]?.type === EXHIBITOR_TYPES.scholar) {
                updatedRolesArray = updatedRolesArray.replace('exhibitorRep', 'Poster / Study Representative');
            } else {
                updatedRolesArray = updatedRolesArray.replace('exhibitorRep', 'Exhibitor Representative');
            }
        }
        if (updatedRolesArray.includes('exhibitor')) {
            if (eventRoles?.exhibitor.type === EXHIBITOR_TYPES.scholar) {
                updatedRolesArray = updatedRolesArray.replace('exhibitor', 'Poster / Study');
            } else {
                updatedRolesArray = updatedRolesArray.replace('exhibitor', 'Exhibitor');
            }
        }
        return updatedRolesArray;
    };

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return this.handleDownloadPosts();
    };

    render() {
        const {
            actionDownload,
            excelFields,
            excelData,
            excelName,
            multiTabs,
            numberOfBoothWalls,
            numberOfVideoWalls,
            loading,
        } = this.state;
        const {downloadType} = this.props;

        if (loading) {
            return <CircularProgress size="2rem" />;
        }

        if (this.props.eventRoles.isOrganizer || this.props.eventRoles.isCoOrganizer) {
            return (
                <>
                    {downloadType === 'videos' ? (
                        numberOfVideoWalls > 0 ? (
                            <span onDragStart={preventDefaultDrag} id="download-info">
                                <Button
                                    variant={'text'}
                                    className={'only-icon-button'}
                                    onKeyDown={this.handleEnterKey}
                                    onClick={this.handleDownloadPosts}
                                >
                                    <CloudDownloadIcon />
                                </Button>
                            </span>
                        ) : (
                            <Tooltip arrow title={'There is no registered data to export'}>
                                <span onDragStart={preventDefaultDrag} id="download-info">
                                    <CloudDownloadIcon />
                                </span>
                            </Tooltip>
                        )
                    ) : downloadType === 'booths' ? null : (
                        <span onDragStart={preventDefaultDrag} id="download-info">
                            <Button
                                variant={'text'}
                                className={'only-icon-button'}
                                onKeyDown={this.handleEnterKey}
                                onClick={this.handleDownloadPosts}
                            >
                                <CloudDownloadIcon />
                            </Button>
                        </span>
                    )}
                    {downloadType === 'booths' ? (
                        numberOfBoothWalls > 0 ? (
                            <span onDragStart={preventDefaultDrag} id="download-info">
                                <Button
                                    variant={'text'}
                                    className={'only-icon-button'}
                                    onKeyDown={this.handleEnterKey}
                                    onClick={this.handleDownloadPosts}
                                >
                                    <CloudDownloadIcon />
                                </Button>
                            </span>
                        ) : (
                            <Tooltip arrow title={'There is no registered data to export'}>
                                <span onDragStart={preventDefaultDrag} id="download-info">
                                    <CloudDownloadIcon />
                                </span>
                            </Tooltip>
                        )
                    ) : null}

                    {actionDownload && excelData && (
                        <ExcelFile filename={excelName.substring(0, 200)} hideElement={true}>
                            {multiTabs ? (
                                excelData.map((sheet, index) => {
                                    return (
                                        <ExcelSheet
                                            key={index}
                                            data={sheet}
                                            name={
                                                excelData[index - 1]?.name === sheet.name
                                                    ? sheet.name + '-' + (index + 1)
                                                    : sheet.name
                                            }
                                        >
                                            {excelFields.map((field, index) => {
                                                return (
                                                    <ExcelColumn key={index} label={field.label} value={field.value} />
                                                );
                                            })}
                                        </ExcelSheet>
                                    );
                                })
                            ) : (
                                <ExcelSheet data={excelData} name={excelName}>
                                    {excelFields.map((field, index) => {
                                        return <ExcelColumn key={index} label={field.label} value={field.value} />;
                                    })}
                                </ExcelSheet>
                            )}
                        </ExcelFile>
                    )}
                </>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        currentUser: state.user.data,
        sideNavigationDetails: state.event.sideNavigationDetails,
        eventRoles: state.user.eventRoles,
    };
};

export default withRouter(connect(mapStateToProps)(DownloadPosts));
