import axios from '../axios-instance';
import * as actionTypes from './actionTypes';
import {
    UPDATE_LOBBY_RESOURCE_LINK_FAIL,
    UPDATE_LOBBY_RESOURCE_LINK_START,
    UPDATE_LOBBY_RESOURCE_LINK_SUCCESS
} from "./actionTypes";

// used in both org dashboard and lobby
export const getLobbyResources = (eventId) => async (dispatch) => {
    dispatch({type: actionTypes.GET_LOBBY_RESOURCES_START});
    try {
        const response = await axios.get(`/event-resources/${eventId}`);
        const lobbyResources = response.data.data;
        return dispatch({type: actionTypes.GET_LOBBY_RESOURCES_SUCCESS, payload: lobbyResources});
    } catch (error) {
        return dispatch({type: actionTypes.GET_LOBBY_RESOURCES_FAIL});
    }
}

export const updateLobbyResourceDescription = (eventId, description) => async (dispatch) => {
    dispatch({type: actionTypes.UPDATE_LOBBY_RESOURCE_DESCRIPTION_START});
    try {
        const response = await axios.put(`/event-resources/${eventId}/description`, description);
        const updatedDescription = response.data.data.eventResources.description;
        dispatch({type: actionTypes.UPDATE_LOBBY_RESOURCE_DESCRIPTION_SUCCESS, payload: updatedDescription});
    } catch (error) {
        dispatch({type: actionTypes.UPDATE_LOBBY_RESOURCE_DESCRIPTION_FAIL});
    }
}

export const updateLobbyResourceLinks = (eventId, links) => async (dispatch) => {
    dispatch({type: actionTypes.UPDATE_LOBBY_RESOURCE_LINK_START})
    try {
        const response = await axios.post(`/event-resources/${eventId}/links`, links);
        const updatedLinks = response.data.data.eventResources.links;
        dispatch({type: actionTypes.UPDATE_LOBBY_RESOURCE_LINK_SUCCESS, payload: updatedLinks})
    } catch (error) {
        dispatch({type: actionTypes.UPDATE_LOBBY_RESOURCE_LINK_FAIL});
    }
}

export const addLobbyDocument = (eventId, documents) => async (dispatch) => {
    dispatch({type: actionTypes.ADD_LOBBY_RESOURCE_DOCUMENT_START});
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const response = await axios.post(`/event-resources/${eventId}/document`, documents, config);
        const updatedDocuments = response.data.data.eventResources.documents;
        return dispatch({type: actionTypes.ADD_LOBBY_RESOURCE_DOCUMENT_SUCCESS, payload: updatedDocuments})
    } catch (error) {
        return dispatch({type: actionTypes.ADD_LOBBY_RESOURCE_DOCUMENT_FAIL})
    }
}

export const deleteLobbyDocument = (eventId, documentId) => async (dispatch) => {
    dispatch({type: actionTypes.DELETE_LOBBY_RESOURCE_DOCUMENT_START});
    try {
        const response = await axios.delete(`/event-resources/${eventId}/document/${documentId}`);
        const updatedDocuments = response.data.data.eventResources.documents;
        dispatch({type: actionTypes.DELETE_LOBBY_RESOURCE_DOCUMENT_SUCCESS, payload: updatedDocuments});
    } catch (error) {
        dispatch({type: actionTypes.DELETE_LOBBY_RESOURCE_DOCUMENT_FAIL});
    }
}
