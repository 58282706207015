import React, {Component} from 'react';

export default class ArchiveFilled extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 1H22C22.5523 1 23 1.44772 23 2V13C23 13.5523 22.5523 14 22 14H20V15H22C23.1046 15 24 14.1046 24 13V2C24 0.895431 23.1046 0 22 0H10C8.89543 0 8 0.89543 8 2V4H9V2C9 1.44772 9.44772 1 10 1Z" fill="url(#gradient1)"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M18 5H2C1.44772 5 1 5.44772 1 6V22C1 22.5523 1.44772 23 2 23H18C18.5523 23 19 22.5523 19 22V6C19 5.44772 18.5523 5 18 5ZM2 4C0.895431 4 0 4.89543 0 6V22C0 23.1046 0.895431 24 2 24H18C19.1046 24 20 23.1046 20 22V6C20 4.89543 19.1046 4 18 4H2Z" fill="url(#gradient1)"/>
                <path d="M2 5H18C18.5523 5 19 5.44772 19 6V22C19 22.5523 18.5523 23 18 23H2C1.44772 23 1 22.5523 1 22V6C1 5.44772 1.44772 5 2 5Z" fill="url(#gradient1)"/>
                <path d="M 15.4755 10.5364 L 15.0748 13.9417 L 15.0679 14.0001 L 15.0748 14.0586 L 15.4755 17.4633 L 12.3938 14.8634 L 11.5714 14.1695 V 15.2456 V 16.3572 C 11.5714 16.7553 11.436 17.0824 11.152 17.3663 C 10.8681 17.6503 10.5409 17.7858 10.1428 17.7858 H 5.9286 C 5.5306 17.7858 5.2034 17.6503 4.9194 17.3664 L 11.174 17.337 Z L 11.582 14.163 V 13 V 11.643 C 11.5714 11.2449 11.4359 10.9177 12 13 M 11.174 17.377 C 12.47 15.459 11.878 11.714 11.276 10.735 C 10.868 10.3498 10.5408 10.2144 10.1428 10.2144 H 5.9285 C 5.5306 10.2144 5.2034 10.3498 4.9194 10.6337 L 4.909 17.377 Z" stroke="white"/>
                <defs>
                    <linearGradient id="gradient1" x1="15.52" y1="-10.8929" x2="32.1887" y2="8.66971" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                </defs>
            </svg>

        );
    }
}
