import React from 'react';
import {Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

export default class MatchingDialogConfirm extends React.Component {
    render() {
        const {
            open,
            dialogTitle,
            hasCloseButton,
            dialogDescription,
            cancelBtnLabel,
            confirmBtnLabel,
            closeConfirm,
            handleConfirm,
        } = this.props;        
        return (
            <Dialog
                open={open}
                aria-labelledby="form-dialog-title"
                PaperProps={{classes: {root: 'matching-dialog-paper reverse-rtl'}}}
                // prevents click outside modal close
                onClose={(e, reason) => reason !== 'backdropClick' && closeConfirm}
                // prevents Escape key modal close
                disableEscapeKeyDown
            >
                <div className="matching-form-wrapper">
                    <DialogTitle id="form-dialog-title">
                        {dialogTitle}
                        {hasCloseButton && (
                            <div className='matching-remove'>
                                <IconButton
                                    onClick={closeConfirm}
                                >
                                    <Close/>
                                </IconButton>
                            </div>
                        )}
                    </DialogTitle>
                    <DialogContent id="form-dialog-content">
                        <DialogContentText
                            id="form-dialog-secondary"
                            dangerouslySetInnerHTML={{__html: dialogDescription}}
                        />
                    </DialogContent>
                    <DialogActions id="form-dialog-actions">
                        <Button
                            variant="outlined"
                            onClick={closeConfirm}
                            type="button"
                        >
                            {cancelBtnLabel}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleConfirm}
                            type="button"
                            classes={{root: 'confirm-btn'}}
                        >
                            {confirmBtnLabel}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}
