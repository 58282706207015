import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../store/actions';
import axios from '../store/axios-instance';

class RedirectOnLogin extends React.PureComponent {
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const preselectedPackageId = query.get('packageId');
        if (preselectedPackageId) {
            this.props.onSetPreselectedPackageId(preselectedPackageId);
        }
    }

    componentDidUpdate(prevProps) {
        const {loggedIn, user, eventSlug} = this.props;
        // immediately after the user logged in, we redirect him to the appropriate page
        if (prevProps.loggedIn === false && loggedIn === true) {
            this.handleRedirect();
        }

        if (prevProps.loggedIn === true && loggedIn === false) {
            this.handleLogoutRedirect();
        }

        // if he just registered at a new event (participant / exhibitor / organizer), we redirect him
        if (prevProps.user && user && prevProps.user.eventRoles.length < user.eventRoles.length) {
            this.redirectToLastEvent();
        }

        if (prevProps.eventSlug !== eventSlug && user && loggedIn) {
            this.handleRedirect();
        }

        // on the platform homepage always redirect the loggedIn user to another page
        if (this.props.history.location.pathname === '/' && loggedIn) {
            this.handleRedirect();
        }
    }

    handleRedirect = async () => {
        const {user} = this.props;
        const {eventSlug} = this.props;

        const userHasRoleInEvent = user.eventRoles.some((roles) => roles.event.slug === eventSlug);

        const userIsOrganizer = user.eventRoles
            .find((role) => role.event.slug === eventSlug)
            ?.roles.includes('organizer');
        const userIsCoOrganizer = user.eventRoles
            .find((role) => role.event.slug === eventSlug)
            ?.roles.includes('coOrganizer');

        const url = eventSlug ? `/privacy-policy-updates?eventSlug=${eventSlug}` : '/privacy-policy-updates';
        const unreadPrivacyPolicyUpdates = await axios
            .get(url)
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                console.log(err);
            });

        if (unreadPrivacyPolicyUpdates?.length > 0) {
            // if ALL the PPUs are from the event where the current user is organizer, then we don't redirect him to the consent collection page
            const allPPUsAreFromEventWhereUserIsOrganizer = unreadPrivacyPolicyUpdates.every(
                (ppu) => ppu?.event?.slug === eventSlug && userIsOrganizer
            );
            const isOnEventPrivacyPolicyPage = window.location.pathname.search('/event-privacy-policy') !== -1;
            const isOnPrivacyPolicyPage = window.location.pathname.search('/privacy-policy') !== -1;

            if (
                user?.isMasterAdmin ||
                allPPUsAreFromEventWhereUserIsOrganizer ||
                isOnPrivacyPolicyPage ||
                isOnEventPrivacyPolicyPage
            ) {
                // we don't want to do anything
            } else {
                this.props.history.push('/consent-collection');
            }
        }

        const URL = this.props.location.pathname;

        const query = new URLSearchParams(this.props.location.search);
        const registerAsExhibitor = query.get('registerAsExhibitor') === 'true';
        const registerAsScholar = query.get('registerAsScholar') === 'true';
        const registerAsParticipant = query.get('registerAsParticipant') === 'true';

        // on platform homepage, if he has a role at an event I redirect him to that event based on his role
        // if he doesn't have any role at any event, I redirect him to create event
        if (URL === '/') {
            const totalEventsUserRegistered = user.eventRoles.length;
            if (user.isMasterAdmin) {
                this.props.history.push('/dashboard/organizers');
            } else if (totalEventsUserRegistered === 0) {
                this.props.history.push('create-event');
            } else {
                this.redirectToLastEvent();
            }
        }

        const isPreviewUrl = this.props.location.search.includes('preview=true');
        if ((userIsOrganizer || userIsCoOrganizer) && isPreviewUrl) {
            return null;
        }

        // if he is on an event homepage, and has a role at that event, I redirect him to the event
        // had to take into account that the page can end with '/' or not

        if (URL === `/event/${eventSlug}` || URL === `/event/${eventSlug}/`) {
            if (userHasRoleInEvent) {
                this.redirectToEventByRole(eventSlug);
            }
            // if he doesn't have any role, It means that he is just a user that wants to register at the event, read the presentation etc.
            // I don't redirect him anywhere
        }
        if (
            URL === `/event/${eventSlug}/participant-registration` ||
            URL === `/event/${eventSlug}/participant-registration/` ||
            URL === `/event/${eventSlug}/exhibitor-registration` ||
            URL === `/event/${eventSlug}/exhibitor-registration/` ||
            URL === `/event/${eventSlug}/scholar-registration` ||
            URL === `/event/${eventSlug}/scholar-registration/`
        ) {
            if (userHasRoleInEvent) {
                this.redirectToEventByRole(eventSlug);
            }
            // if he doesn't have any role, It means that he is just a user that wants to finish the registration at the event
            // I don't redirect him anywhere
        }

        // if the user is on a log in page (platform or user level)
        if (URL.includes('/login') || URL.includes('/register')) {
            // he logs in from an event page (/event/eventSlug/login)
            // he creates an account from an event page (/event/eventSlug/register)

            if (eventSlug) {
                const userHasRoleInEvent = user.eventRoles.some((roles) => roles.event.slug === eventSlug);
                if (!userHasRoleInEvent) {
                    // if he doesn't have any role, it means he is trying to register at this event
                    if (registerAsExhibitor) {
                        this.props.history.push(`/event/${eventSlug}/exhibitor-registration`);
                    } else if (registerAsScholar) {
                        this.props.history.push(`/event/${eventSlug}/scholar-registration`);
                    } else if (registerAsParticipant) {
                        this.props.history.push(`/event/${eventSlug}/participant-registration`);
                    } else {
                        const packageIdAndRegisterAsFromLocalStorage = JSON.parse(
                            localStorage.getItem('packageIdAndRegisterAs')
                        );

                        if (packageIdAndRegisterAsFromLocalStorage) {
                            for (const key in packageIdAndRegisterAsFromLocalStorage) {
                                if (packageIdAndRegisterAsFromLocalStorage[key] === true) {
                                    if (key === 'registerAsExhibitor') {
                                        this.props.history.replace(`/event/${eventSlug}/exhibitor-registration`);
                                        /*
                                        window.location.href = `/event/${eventSlug}/exhibitor-registration`;
*/
                                    } else if (key === 'registerAsParticipant') {
                                        /*
                                        window.location.href = `/event/${eventSlug}/participant-registration`;
*/
                                        this.props.history.replace(`/event/${eventSlug}/participant-registration`);
                                    } else if (key === 'registerAsScholar') {
                                        this.props.history.replace(`/event/${eventSlug}/scholar-registration`);
                                    }
                                }
                            }
                        } else {
                            const {eventInfo, history} = this.props;
                            const {
                                restrictParticipantRegistration,
                                restrictExhibitorRegistration,
                                restrictScholarRegistration,
                                companyExhibitorsType,
                                scholarExhibitorsType,
                            } = eventInfo || {};

                            const reDirectArr = {
                                participant: restrictParticipantRegistration,
                                exhibitor: !(!restrictExhibitorRegistration && companyExhibitorsType),
                                scholar: !(!restrictScholarRegistration && scholarExhibitorsType),
                            };

                            const falseCount = Object.values(reDirectArr)?.filter((item) => !item).length;
                            const role = Object.keys(reDirectArr)?.find((key) => reDirectArr[key] === false);

                            const redirectPath =
                                falseCount <= 0
                                    ? role !== undefined
                                        ? `/event/${eventSlug}/${role}-registration`
                                        : `/event/${eventSlug}`
                                    : `/event/${eventSlug}/select-role`;

                            history.push(redirectPath);
                        }
                    }
                } else {
                    this.redirectToEventByRole(eventSlug);
                }
            } else {
                // he logs in at platform level (I don't have an eventSlug in URL);
                // he registers at platform level, it will get redirected to /create-event because he doesn't have any roles;
                const totalEventsUserRegistered = user.eventRoles.length;
                if (user.isMasterAdmin) {
                    this.props.history.push('/dashboard/organizers');
                } else if (totalEventsUserRegistered === 0) {
                    this.props.history.push('/create-event');
                } else {
                    this.redirectToLastEvent();
                }
            }
        }
    };

    handleLogoutRedirect = () => {
        const URL = this.props.location.pathname;
        if (
            URL === '/create-event' ||
            URL === '/create-event/'
            // URL === '/organizer-privacy-policy' ||
            // URL === '/organizer-privacy-policy/' ||
            // URL === '/organizer-cookies' ||
            // URL === '/organizer-cookies/' ||
            // URL === '/organizer-terms-and-conditions' ||
            // URL === '/organizer-terms-and-conditions/'
        ) {
            this.props.history.push('/');
        }
    };

    redirectToLastEvent = () => {
        const {user, isMobile} = this.props;
        const totalEventsUserRegistered = user.eventRoles.length;
        const lastEventRegistered = user.eventRoles[totalEventsUserRegistered - 1];
        const eventRoles = lastEventRegistered.roles;
        const eventSlug = lastEventRegistered.event.slug;
        localStorage.removeItem('anyLogin');

        // all the users that login from mobile, are redirected to lobby
        if (isMobile) {
            const intendedPage = localStorage.getItem('intendedPage');
            if (intendedPage) {
                this.props.history.push(`${intendedPage}`);
                localStorage.removeItem('intendedPage');
            } else {
                this.props.history.push(`/event/${eventSlug}/lobby`);
            }
        } else {
            if (eventRoles.includes('organizer')) {
                this.props.onCloseTopNav();
                this.props.history.push(`/event/${eventSlug}/dashboard/settings/event-core-details`);
                // this.props.history.push(`/event/${eventSlug}/dashboard/settings`);
            } else if (eventRoles.includes('exhibitor')) {
                this.props.history.push(`/event/${eventSlug}/exhibitor-dashboard`);
            } else if (eventRoles.includes('exhibitorRep')) {
                // this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view`);
                this.props.history.push(`/event/${eventSlug}/lobby`);
            } else {
                const intendedPage = localStorage.getItem('intendedPage');
                if (intendedPage) {
                    this.props.history.push(`${intendedPage}`);
                    localStorage.removeItem('intendedPage');
                } else this.props.history.push(`/event/${eventSlug}/lobby`);
            }
        }
    };

    redirectToEventByRole = (eventSlug) => {
        localStorage.removeItem('anyLogin');
        const {user, isMobile} = this.props;
        const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);

        // all the users that login from mobile, are redirected to lobby
        if (isMobile) {
            const intendedPage = localStorage.getItem('intendedPage');

            if (intendedPage) {
                this.props.history.push(`${intendedPage}`);
                localStorage.removeItem('intendedPage');
            } else {
                this.props.history.push(`/event/${eventSlug}/lobby`);
            }
        } else {
            if (userRolesInEvent.roles.includes('organizer')) {
                // this.props.history.push(`/event/${eventSlug}/dashboard/settings/event-core-details`);
                this.props.history.push(`/event/${eventSlug}/dashboard/settings`);
            } else if (userRolesInEvent.roles.includes('exhibitor')) {
                this.props.history.push(`/event/${eventSlug}/exhibitor-dashboard`);
            } else if (userRolesInEvent.roles.includes('exhibitorRep')) {
                // this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view`);
                this.props.history.push(`/event/${eventSlug}/lobby`);
            } else {
                const intendedPage = localStorage.getItem('intendedPage');
                if (intendedPage) {
                    this.props.history.push(`${intendedPage}`);
                    localStorage.removeItem('intendedPage');
                } else this.props.history.push(`/event/${eventSlug}/lobby`);
            }
        }
    };

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        loggedIn: state.user.loggedIn,
        eventSlug: state.event.eventSlug,
        isMobile: state.layout.isMobile,
        eventInfo: state.event.eventInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAutoLoginWithToken: () => dispatch(actions.autoLoginWithToken()),
        onCloseTopNav: () => dispatch(actions.topNavClose()),
        onSetPreselectedPackageId: (packageId) => dispatch(actions.setPreselectedPackageId(packageId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RedirectOnLogin));
