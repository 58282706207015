import React, {Component} from 'react';
import {connect} from 'react-redux';
import {preventDefaultDrag} from "../../../../Utils/utils";
import {TextValidator} from "react-material-ui-form-validator";

class Title extends Component {

    renderEndAdornment = () => {
        const {title} = this.props;
        return <span className='char-count'>
            {title ? title.length : 0}/50
        </span>
    }

    render() {
        const {title, handleChangeTitle} = this.props;
        return (
            <div
                onDragStart={preventDefaultDrag}
                className="options-container"
            >
                <div onDragStart={preventDefaultDrag} className="single-option-container">
                    <TextValidator
                        className="setting-input"
                        color={'primary'}
                        label='Title'
                        type="text"
                        name='title'
                        index={0}
                        value={title}
                        onChange={handleChangeTitle}
                        validators={['minStringLength: 1', 'maxStringLength: 50']}
                        errorMessages={['The title is required', `You have reached the maximum limit of characters allowed (50 characters)`]}
                        fullWidth={true}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            endAdornment: this.renderEndAdornment(),
                        }}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(Title);
