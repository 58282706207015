import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {getLobbyResources} from '../../store/actions/lobbyResourcesActions';
import LinkResource from './LinkResource';
import DocumentResource from './DocumentResource';
import './eventInfoStyles.scss';
import {preventDefaultDrag, updateHyperlinksFromText} from 'Utils/utils';
import {CircularProgress} from '@material-ui/core';
import {usePlatformTranslation} from '../../services/hooks';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarGlobal from '../../SmallLayoutComponents/Snackbars/SnackbarGlobal';

const EventInfo = () => {
    const eventHasResources = useSelector((state) => state.event.data.hasResources);
    const eventId = useSelector((state) => state.event.eventId);
    const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);

    const eventDescriptionText = usePlatformTranslation((state) => state.sideMenu.eventDescription);
    const eventFilesText = usePlatformTranslation((state) => state.sideMenu.eventFiles);
    const eventLinksText = usePlatformTranslation((state) => state.sideMenu.eventLinks);

    const [descriptionWithExternalLinks, setDescriptionWithExternalLinks] = useState('');
    const [errorSnackbarOpened, setErrorSnackbarOpened] = useState(false);

    const dispatch = useDispatch();

    const {description, links, documents, error, loading} = useSelector((state) => state.lobbyResources);

    useEffect(() => {
        if (eventId) {
            dispatch(getLobbyResources(eventId));
        }
    }, [eventId]);

    useEffect(() => {
        updateHyperlinksFromText();
        setDescriptionWithExternalLinks(updateHyperlinksFromText(description));
    }, [description]);

    useEffect(() => {
        if (error) {
            setErrorSnackbarOpened(true);
        }
    }, [error]);

    const handleCloseSnackbar = async () => {
        await setErrorSnackbarOpened((prevState) => !prevState);
        if (errorSnackbarOpened) {
            setTimeout(() => {
                setErrorSnackbarOpened(false);
            }, 5000);
        }
    };

    if (loading) {
        return (
            <div className={'event-info spinner-container'}>
                <CircularProgress />
            </div>
        );
    }

    if (eventHasResources) {
        return (
            <div className={`event-info ${isLargeScreen ? '' : 'mobile'}`}>
                {description && (
                    <div className={'resource-container'}>
                        <h3>{eventDescriptionText}</h3>
                        <div
                            className={'description-resource'}
                            onDragStart={preventDefaultDrag}
                            dangerouslySetInnerHTML={{
                                __html: descriptionWithExternalLinks,
                            }}
                        />
                    </div>
                )}
                {documents.length > 0 && (
                    <div className={'resource-container'}>
                        <h3>{eventFilesText}</h3>
                        {documents.map((document, index) => (
                            <DocumentResource key={`document-${index}`} document={document} />
                        ))}
                    </div>
                )}
                {links.length > 0 && (
                    <div className={'resource-container'}>
                        <h3>{eventLinksText}</h3>
                        {links.map((link, index) => (
                            <LinkResource key={`link-${index}`} link={link} />
                        ))}
                    </div>
                )}

                {error && (
                    <SnackbarGlobal
                        message={error}
                        isError={true}
                        snackbarOpen={errorSnackbarOpened}
                        handleCloseSnackbar={handleCloseSnackbar}
                    />
                )}
            </div>
        );
    }
    return null;
};

export default EventInfo;
