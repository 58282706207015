import RoleItem from './RoleItem';
const Roles = ({availableRoles, handleEventRoleChecked, marginTop, brandingTranslation}) => {
    return (
        <ul className={`roles-container`} style={{marginTop: marginTop}}>
            {availableRoles.map((role) => (
                <RoleItem
                    brandingTranslation={brandingTranslation ?? {}}
                    role={role}
                    handleEventRoleChecked={handleEventRoleChecked}
                />
            ))}
        </ul>
    );
};

export default Roles;
Roles.defaultProps = {
    marginTop: 0,
};
