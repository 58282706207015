import React, {PureComponent} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import TranslateIcon from '@material-ui/icons/Translate';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import Confirm from '../../../Dialogs/Confirm';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, RichUtils, convertToRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {preventDefaultDrag, fireClickEvent} from '../../../Utils/utils';
import {isLinkRule, isYoutubeOrVimeoLinkRule} from './../../../Utils/validationRules';
import {ManualError} from './../../ManualError/ManualError';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import {CircularProgress} from '@material-ui/core';
import * as actions from '../../../store/actions';
import {withRouter} from 'react-router-dom';

let targetBlank = {
    entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
            const data = entity.getData();
            return {
                element: 'a',
                attributes: {
                    href: data.url,
                    target: '_blank',
                },
            };
        }
    },
};

class HpIntroAndProgram extends PureComponent {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.editor = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            fields: [
                {
                    name: 'homepageVideo',
                    description: '',
                    value: '',
                    label: 'YouTube or Vimeo video URL for the homepage video',
                    multiline: 0,
                    validators: ['isYoutubeOrVimeoLink'],
                    errorMessages: [
                        'Please enter a valid YouTube (https://www.youtube.com) or Vimeo (https://vimeo.com/) link',
                    ],
                },
                {
                    name: 'homepageNoProgramText',
                    description:
                        'This is displayed when there is no program added yet for one of the Auditoriums. Please limit field to 50 characters',
                    value: '',
                    label: 'Program text placeholder',
                    multiline: 0,
                    validators: ['maxStringLength: 50'],
                    errorMessages: ['You have reached the maximum limit of characters allowed (50 characters)'],
                },
            ],
            editorState: EditorState.createEmpty(),
            buttonDisabled: true,
            openConfirmUnsavedChanges: false,
            characterCount: 0,
            navigationElement: null,
        };
    }

    componentDidMount() {
        this._setEventIntroAndProgramBranding();
        ValidatorForm.addValidationRule('isYoutubeOrVimeoLink', (value) => {
            let rule = isYoutubeOrVimeoLinkRule;
            let match = rule.test(value);

            if (value?.length === 0) {
                match = true;
            }
            if (!match) {
                this.setState({formError: true});
                return false;
            }
            this.setState({formError: false});
            return true;
        });
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const {event, brandingLanguage} = this.props;
        if (
            prevProps.event !== event ||
            prevProps.brandingLanguage !== brandingLanguage ||
            this.props.brandingTranslations !== prevProps.brandingTranslations
        ) {
            this._setEventIntroAndProgramBranding();
        }
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isYoutubeOrVimeoLink');
        document.removeEventListener('mousedown', this.handleClickOutside);
        clearTimeout(this.timeoutId);
    }

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) =>
            pathElem.getAttribute?.('data-is-navigation')
        );

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        const {buttonDisabled, characterCount} = this.state;
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (!buttonDisabled && !(characterCount > 3000)) {
                this.editor.current.editor.blur();
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
    };

    _setEventIntroAndProgramBranding = () => {
        const {brandingLanguage, brandingTranslations, branding} = this.props;
        let brandingData = brandingTranslations.data.find((translation) => translation.language === brandingLanguage);
        let brandingDataVideo = branding?.data?.homepageVideo;
        if (brandingData) {
            const contentState = stateFromHTML(brandingData.homepageDescription);

            const characterCount = convertToRaw(contentState)
                .blocks.map((obj) => obj.text)
                .join(' ').length;

            this.setState({
                buttonDisabled: true,
                fields: [
                    {...this.state.fields[0], value: brandingDataVideo},
                    {...this.state.fields[1], value: brandingData.homepageNoProgramText},
                ],
                editorState: EditorState.createWithContent(contentState),
                characterCount,
            });
        }
    };

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({buttonDisabled: !isValid});
            });
        });
    };

    onChange = async (editorState) => {
        const {brandingLanguage, brandingTranslations, branding} = this.props;
        let brandingData = brandingTranslations.data.find((translation) => translation.language === brandingLanguage);
        let contentState = stateFromHTML(stateToHTML(editorState.getCurrentContent()));
        let characterCount = convertToRaw(contentState)
            .blocks.map((obj) => obj.text)
            .join(' ').length;

        const contentStateDescription = convertToRaw(contentState)
            .blocks.map((obj) => obj.text)
            .join('')
            .normalize();

        const savedDescription =
            convertToRaw(stateFromHTML(brandingData?.homepageDescription?.normalize()))
                .blocks.map((obj) => obj.text)
                .join('')
                .normalize() ?? '';

        let buttonDisabled =
            contentStateDescription === savedDescription &&
            (brandingData.homepageDescription === '<p><br></p>'
                ? true
                : brandingData?.homepageDescription?.normalize() !==
                  stateToHTML(editorState.getCurrentContent()).normalize()) &&
            this.state.fields[0].value === branding.data.homepageVideo &&
            this.state.fields[1].value === brandingData.homepageNoProgramText;

        await this.refs.form.isFormValid().then((isValid) => {
            if (!isValid) buttonDisabled = false;
        });

        this.setState({
            editorState,
            buttonDisabled,
            characterCount,
        });
    };

    handleReturn = (e) => {
        const {editorState} = this.state;
        if (e.shiftKey) {
            this.setState({editorState: RichUtils.insertSoftNewline(editorState)});
            return 'handled';
        }
        return 'not-handled';
    };

    focusEditor = () => {
        if (this.editor) {
            this.editor.current.focusEditor();
        }
    };

    _saveIntroAndProgram = (e) => {
        e.preventDefault();
        const {fields, navigationElement} = this.state;
        this.setState({buttonDisabled: true, openConfirmUnsavedChanges: false});
        let translationData = {};
        let brandingData = {};
        fields.forEach((field) => {
            if (field.name === 'homepageVideo') {
                brandingData[field.name] = field.value;
            } else {
                translationData[field.name] = field.value;
                translationData['homepageDescription'] = stateToHTML(
                    this.state.editorState.getCurrentContent(),
                    targetBlank
                );
            }
        });
        this.props.saveEventTranslation(translationData);
        this.props.saveEventBranding(brandingData);
        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this._setEventIntroAndProgramBranding();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    wysiwygLinkValidation = (e) => {
        let error = document.createElement('span');
        error.id = 'rdw-link-modal-error';
        error.innerText = 'Please enter a valid link (https://site.com)';
        error.style.cssText = 'position: absolute; bottom: 28%; left: 5.5%; color: red; font-size: 11px;';
        let rule = isLinkRule;
        let errExists = document.getElementById('rdw-link-modal-error');
        if (!rule.test(e.target)) {
            if (!errExists) {
                document.getElementById('linkTarget').style.cssText = 'border: 1px solid red; margin-bottom: 30px;';
                document.getElementById('linkTarget').parentNode.append(error);
            }
            this.timeoutId = setTimeout(() => {
                console.clear();
            }, 50);
            return this.timeoutId;
        } else {
            return {title: e.title, target: e.target, targetOption: e.targetOption};
        }
    };

    render() {
        const {fields, buttonDisabled, openConfirmUnsavedChanges, characterCount} = this.state;
        const {isMultiLanguage} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} ref={this.wrapperRef}>
                <p onDragStart={preventDefaultDrag} className="inner-options-title">
                    Intro and program
                </p>
                <div onDragStart={preventDefaultDrag} className="options-container">
                    <div onDragStart={preventDefaultDrag} className="single-option-container">
                        <div
                            onDragStart={preventDefaultDrag}
                            className={'editor-wrapper multiline-input'}
                            onClick={this.focusEditor}
                        >
                            <Editor
                                ref={this.editor}
                                editorState={this.state.editorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                onEditorStateChange={this.onChange.bind(this)}
                                handleReturn={this.handleReturn}
                                toolbar={{
                                    options: ['link', 'inline', 'list', 'emoji'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    list: {
                                        options: ['unordered', 'ordered'],
                                    },
                                    link: {
                                        linkCallback: this.wysiwygLinkValidation,
                                    },
                                }}
                            />
                            <span className={'char-count'}>{characterCount === 0 ? null : characterCount}</span>
                        </div>
                        <ManualError maxLength={3000} valueLength={characterCount} />
                    </div>
                    <div onDragStart={preventDefaultDrag} className="input-description">
                        <p>Event intro is limited up to 3000 characters to meet better readability.</p>
                    </div>
                </div>
                <ValidatorForm ref="form" onSubmit={this._saveIntroAndProgram}>
                    {fields.map((field, index) => {
                        return (
                            <div onDragStart={preventDefaultDrag} className="options-container" key={field.name}>
                                <div onDragStart={preventDefaultDrag} className="single-option-container">
                                    <TextValidator
                                        className="setting-input"
                                        label={field.label}
                                        type="text"
                                        name={field.name}
                                        index={index}
                                        value={field.value}
                                        onChange={this.handleChange(index)}
                                        validators={field.validators}
                                        errorMessages={field.errorMessages}
                                        multiline={field.multiline > 0}
                                        rows={field.multiline}
                                        fullWidth={true}
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment:
                                                isMultiLanguage && field.adornment !== undefined ? (
                                                    <TranslateIcon />
                                                ) : (
                                                    field.adornment
                                                ),
                                        }}
                                    />
                                </div>
                                <div onDragStart={preventDefaultDrag} className="input-description">
                                    <p>{field.description}</p>
                                </div>
                            </div>
                        );
                    })}
                    <div onDragStart={preventDefaultDrag} className="action-container">
                        <Button
                            type="submit"
                            variant={'contained'}
                            disabled={buttonDisabled || characterCount > 3000}
                            startIcon={<SaveOutlinedIcon />}
                            disableElevation
                            color={'secondary'}
                        >
                            SAVE
                        </Button>
                    </div>
                </ValidatorForm>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this._saveIntroAndProgram}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        brandingLanguage: state.languages.organizerBrandingLanguage,
        brandingTranslations: state.event.brandingTranslations,
        branding: state.event.branding,
        eventId: state.event.eventId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBranding: (eventId) => dispatch(actions.getEventBranding(eventId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HpIntroAndProgram));
