import Layout from '../../components/Layout';
import PageHeadline from '../../components/PageHeadline';
import RegistrationForm from '../../components/Forms/RegistrationForm';
import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

const RegisterEvent = () => {
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const [showEmailValidationSection, setShowEmailValidationSection] = useState(false);
    const [verificationEmail, setVerificationEmail] = useState('');

    const handleEmailValidation = () => {
        setShowEmailValidationSection(true);
    };

    const handleVerificationEmail = (email) => {
        setVerificationEmail(email);
    };

    const pageTitles = [
        {
            title:
                translation.newLoginRegistrations.seemsLikeYouDontHaveMyOnventAccount ||
                defaultTranslation.newLoginRegistrations.seemsLikeYouDontHaveMyOnventAccount,
            subject:
                translation.newLoginRegistrations.createPlatformAccount ||
                defaultTranslation.newLoginRegistrations.createPlatformAccount,
        },
        {
            title:
                translation.newLoginRegistrations.weHaveSentAVerificationCodeToYourEmail ||
                defaultTranslation.newLoginRegistrations.weHaveSentAVerificationCodeToYourEmail,
            subject: '',
        },
    ];

    const isEmailExist = JSON.parse(localStorage.getItem('isEmailExist'));
    if (isEmailExist || isEmailExist === null) {
        //check if event slug exist in the url
        let url = window.location.href;
        const urlArray = url.split('/');
        const eventSlug = urlArray[urlArray.length - 2];

        if (eventSlug) {
            //get everything from the url after the event slug
            const urlAfterEventSlug = urlArray.slice(urlArray.indexOf(eventSlug) + 1).join('/');
            //get packageId parameter from the urlAfterEventSlug
            const packageId = urlAfterEventSlug.includes('packageId')
                ? urlAfterEventSlug.split('packageId=')[1].split('&')[0]
                : null;

            //get if there is registerAsExhibitor,registerAsParticipant or registerAsScholar in the urlAfterEventSlug
            const registerAsExhibitor = urlAfterEventSlug.includes('registerAsExhibitor=true');
            const registerAsParticipant = urlAfterEventSlug.includes('registerAsParticipant=true');
            const registerAsScholar = urlAfterEventSlug.includes('registerAsScholar=true');

            //create an object with the packageId and the registerAsExhibitor,registerAsParticipant or registerAsScholar
            const packageIdAndRegisterAs = {
                registerAsExhibitor: registerAsExhibitor,
                registerAsParticipant: registerAsParticipant,
                registerAsScholar: registerAsScholar,
            };
            if (packageId) {
                packageIdAndRegisterAs.packageId = packageId;
            }
            localStorage.setItem('packageIdAndRegisterAs', JSON.stringify(packageIdAndRegisterAs));
            return <Redirect to={`/event/${eventSlug}/login`} />;
        } else {
            return <Redirect to={`/login`} />;
        }
    }

    return (
        <Layout childrenPadding={`0 11.116%`}>
            <PageHeadline
                containerWidth={`464px`}
                containerMarginTop={`-87px`}
                heading={pageTitles[showEmailValidationSection ? 1 : 0].title}
                subHeading={showEmailValidationSection ? verificationEmail : pageTitles[0].subject}
            />
            <RegistrationForm
                showEmailValidationSection={showEmailValidationSection}
                handleEmailValidation={handleEmailValidation}
                handleVerificationEmail={handleVerificationEmail}
            />
        </Layout>
    );
};

export default RegisterEvent;
