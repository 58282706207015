import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import colors from '../../../CSS/_variables.module.scss';
import OpenChatIcon from '../../../Images/svg/OpenChat';
import {ReactComponent as Connect} from '../../../Images/svg/add-button.svg';
import RequestSent from '../../../Images/svg/RequestSent';
import {ReactComponent as FacebookIcon} from '../../../Images/svg/facebook-logo.svg';
import {ReactComponent as LinkedinIcon} from '../../../Images/svg/linkedin-sign.svg';
import {ReactComponent as TwitterIcon} from '../../../Images/svg/twitter.svg';
import {ReactComponent as GoogleScholarIcon} from '../../../Images/svg/gscho-logo.svg';
import {ReactComponent as ResearchGateIcon} from '../../../Images/svg/rgate-logo.svg';
import {CircularProgress, Tooltip} from '@material-ui/core';
import {getPrivateChatId, preventDefaultDrag} from '../../../Utils/utils';
import {capitalizeFirstLetterOfEachWord} from '../../../Utils/utils';
import InviteToStage from '../../../HOC/VirtualisedNetworking/InviteToStage/InviteToStage';

import './NewUserCardStyles.scss';

class NewUserCard extends React.Component {
    state = {
        loadingFriendRequest: false,
    };

    checkIfIsOnline = (userId) => this.props.onlineUsersObject[userId];

    handleSendFriendConnection = () => {
        this.setState({loadingFriendRequest: true});
        this.props
            .onSendContactRequest(this.props.userId)
            ?.then(() => {
                this.setState({loadingFriendRequest: false});
            })
            .catch(() => {
                this.setState({loadingFriendRequest: false});
            });
    };

    checkIfIsContact = () => {
        const {userContacts, userId} = this.props;
        let isContact = userContacts?.find((contact) => contact.user?._id === userId);
        return isContact !== undefined;
    };

    checkIfIsFriendConnectionSent = () => {
        const {userSentRequests, userId} = this.props;
        let isFriendConnectionSent = userSentRequests?.find(
            (request) => request.user === userId && (request.status === 'pending' || request.status === 'rejected')
        );
        return isFriendConnectionSent !== undefined;
    };

    checkIfIsFriendConnectionReceived = () => {
        const {userReceivedRequests, userId} = this.props;
        let isFriendConnectionReceived = userReceivedRequests?.find(
            (request) => request.user?._id === userId && (request.status === 'pending' || request.status === 'rejected')
        );
        return isFriendConnectionReceived !== undefined ? isFriendConnectionReceived._id : false;
    };

    handleOpenPrivateChat = (restrictedNetwork) => () => {
        const {user, userId, userLoading, privateChats, dialogClose, noDialog} = this.props;
        if (restrictedNetwork) {
            return null;
        }
        const privateChatId = getPrivateChatId(privateChats, userId);

        if (privateChatId) {
            if (!noDialog) dialogClose();
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (userId !== user?._id && !userLoading) {
                if (!noDialog) dialogClose();
                this.props.onCreatePrivateChat(userId);
            }
        }
    };

    checkPlatformLanguageForSmallerLabel = (platformLanguage) => {
        const languagesForSmallerLabel = ['fr', 'ru', 'ua', 'pt', 'es'];
        return languagesForSmallerLabel.includes(platformLanguage);
    };

    setPaddingForSocialMediaContainer = (platformLanguage, matchingLevel) => {
        if (!matchingLevel && !this.props.displayOnly) return '72px';
        if (platformLanguage === 'pt') {
            return '111px';
        } else if (platformLanguage === 'es') {
            return '102px';
        } else if (platformLanguage === 'ru' || platformLanguage === 'ua') {
            return '82px';
        } else {
            return '72px';
        }
    };

    render() {
        const {
            user,
            event,
            eventRoles,
            resourcesAccess,
            userId,
            userData,
            allEventUsersObject,
            translation,
            defaultTranslation,
            displayOnly,
            userLoading,
            matchingLevel,
            dialogCard,
            platformLanguage,
            showInviteToStageButton,
        } = this.props;
        const currentUser = allEventUsersObject[userId];
        let restrictedNetwork = false;
        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess && !resourcesAccess.networking) {
            restrictedNetwork = true;
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess &&
            !resourcesAccess.networking
        ) {
            restrictedNetwork = true;
        }

        const isMyself = currentUser?.user === user?._id;
        const isOnline = this.checkIfIsOnline(userId);
        const isContact = this.checkIfIsContact();
        const isFriendConnectionSent = this.checkIfIsFriendConnectionSent();
        const isFriendConnectionReceived = this.checkIfIsFriendConnectionReceived();
        const hasMatchingEnabled = event?.enableMatching || event?.hasMatching;

        return (
            <div className={`user-main-card ${displayOnly || dialogCard ? 'display-dialog-card' : ''}`}>
                <div
                    onDragStart={preventDefaultDrag}
                    className={`user-container ${isOnline ? 'is-online' : 'is-offline'}`}
                >
                    <div
                        className="participant-avatar"
                        style={{
                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${currentUser?.avatarSmall}')`,
                        }}
                    />
                    {!isMyself && (
                        <div
                            className={`match-level ${matchingLevel === 'medium' ? 'medium' : ''} ${
                                this.checkPlatformLanguageForSmallerLabel(platformLanguage) ? 'smaller-label' : ''
                            }`}
                        >
                            {displayOnly &&
                                (translation?.matching?.highMatch || defaultTranslation?.matching?.highMatch)}
                            {hasMatchingEnabled && (matchingLevel === 'normal' || matchingLevel === undefined) && null}
                            {hasMatchingEnabled &&
                                matchingLevel === 'medium' &&
                                (translation?.matching?.mediumMatch || defaultTranslation?.matching?.mediumMatch)}
                            {hasMatchingEnabled &&
                                matchingLevel === 'high' &&
                                (translation?.matching?.highMatch || defaultTranslation?.matching?.highMatch)}
                        </div>
                    )}
                    <div onDragStart={preventDefaultDrag} className="user-details">
                        <div>
                            <div onDragStart={preventDefaultDrag} className="user-name">
                                <p
                                    onDragStart={preventDefaultDrag}
                                    className="user-name-top ellipsis-third-line  single-user-helper"
                                >
                                    {currentUser?.first} {currentUser?.last}
                                </p>
                                <p onDragStart={preventDefaultDrag} className="user-name-bottom">
                                    {capitalizeFirstLetterOfEachWord(currentUser?.title)}{' '}
                                    {currentUser?.title &&
                                    (capitalizeFirstLetterOfEachWord(currentUser?.company) ||
                                        capitalizeFirstLetterOfEachWord(currentUser?.affiliation))
                                        ? 'at'
                                        : null}{' '}
                                    {capitalizeFirstLetterOfEachWord(currentUser?.company) ||
                                        capitalizeFirstLetterOfEachWord(currentUser?.affiliation)}
                                </p>
                            </div>
                        </div>
                        {displayOnly ? (
                            <div className="participant-button-chat display-only">
                                <div>
                                    <OpenChatIcon primary={colors.primary} secondary={colors.secondary} />
                                    <span>{defaultTranslation?.networking.chat}</span>
                                </div>
                            </div>
                        ) : (
                            !isMyself && (
                                <button
                                    className="participant-button-chat"
                                    onClick={this.handleOpenPrivateChat(restrictedNetwork)}
                                    disabled={userLoading || restrictedNetwork}
                                >
                                    {restrictedNetwork ? (
                                        <Tooltip
                                            arrow
                                            classes={{tooltip: 'ignore-rtl'}}
                                            id="chat-tooltip"
                                            title="Restricted"
                                            enterTouchDelay={1}
                                        >
                                            <div>
                                                <OpenChatIcon primary={colors.primary} secondary={colors.secondary} />
                                                <span>{defaultTranslation?.networking.chat}</span>
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <div>
                                            <OpenChatIcon primary={colors.primary} secondary={colors.secondary} />
                                            <span>{defaultTranslation?.networking.chat}</span>
                                        </div>
                                    )}
                                </button>
                            )
                        )}
                    </div>
                </div>
                <div
                    className={`bottom-row ${showInviteToStageButton && !isMyself ? 'sessions-actions' : ''}`}
                    style={{paddingLeft: `${this.setPaddingForSocialMediaContainer(platformLanguage, matchingLevel)}`}}
                >
                    <div className="social-add">
                        <div className="match-social">
                            {!!currentUser?.facebook && (
                                <div className={`single-social-link`}>
                                    {restrictedNetwork ? (
                                        <Tooltip
                                            arrow
                                            classes={{tooltip: 'ignore-rtl'}}
                                            id="facebook-tooltip"
                                            title="Restricted"
                                            enterTouchDelay={1}
                                        >
                                            <FacebookIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <a
                                            href={currentUser?.facebook}
                                            target="_blank"
                                            className="social-button"
                                            rel="noopener noreferrer"
                                        >
                                            <FacebookIcon width={24} height={24} fill={colors.grey} />
                                        </a>
                                    )}
                                </div>
                            )}
                            {!!currentUser?.googleScholar && (
                                <div className={`single-social-link`}>
                                    {restrictedNetwork ? (
                                        <Tooltip
                                            arrow
                                            classes={{tooltip: 'ignore-rtl'}}
                                            id="scholar-tooltip"
                                            title="Restricted"
                                            enterTouchDelay={1}
                                        >
                                            <GoogleScholarIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <a
                                            href={currentUser?.googleScholar}
                                            target="_blank"
                                            className="social-button"
                                            rel="noopener noreferrer"
                                        >
                                            <GoogleScholarIcon width={24} height={24} fill={colors.grey} />
                                        </a>
                                    )}
                                </div>
                            )}
                            {!!currentUser?.linkedin && (
                                <div className={`single-social-link`}>
                                    {restrictedNetwork ? (
                                        <Tooltip
                                            arrow
                                            classes={{tooltip: 'ignore-rtl'}}
                                            id="linkedin-tooltip"
                                            title="Restricted"
                                            enterTouchDelay={1}
                                        >
                                            <LinkedinIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <a
                                            href={currentUser?.linkedin}
                                            target="_blank"
                                            className="social-button"
                                            rel="noopener noreferrer"
                                        >
                                            <LinkedinIcon width={24} height={24} fill={colors.grey} />
                                        </a>
                                    )}
                                </div>
                            )}
                            {!!currentUser?.researchGate && (
                                <div className={`single-social-link`}>
                                    {restrictedNetwork ? (
                                        <Tooltip
                                            arrow
                                            classes={{tooltip: 'ignore-rtl'}}
                                            id="research-tooltip"
                                            title="Restricted"
                                            enterTouchDelay={1}
                                        >
                                            <ResearchGateIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <a
                                            href={currentUser?.researchGate}
                                            target="_blank"
                                            className="social-button"
                                            rel="noopener noreferrer"
                                        >
                                            <ResearchGateIcon width={24} height={24} fill={colors.grey} />
                                        </a>
                                    )}
                                </div>
                            )}
                            {!!currentUser?.twitter && (
                                <div className={`single-social-link`}>
                                    {restrictedNetwork ? (
                                        <Tooltip
                                            arrow
                                            classes={{tooltip: 'ignore-rtl'}}
                                            id="twitter-tooltip"
                                            title="Restricted"
                                            enterTouchDelay={1}
                                        >
                                            <TwitterIcon width={24} height={24} fill={colors.grey} />
                                        </Tooltip>
                                    ) : (
                                        <a
                                            href={currentUser?.twitter}
                                            target="_blank"
                                            className="social-button"
                                            rel="noopener noreferrer"
                                        >
                                            <TwitterIcon width={24} height={24} fill={colors.grey} />
                                        </a>
                                    )}
                                </div>
                            )}
                        </div>
                        {displayOnly ? (
                            <div className="participant-button-add display-only">
                                <Connect fill={colors.secondary} />
                            </div>
                        ) : (
                            <>
                                {!isMyself && isFriendConnectionSent && (
                                    <div onDragStart={preventDefaultDrag} className="connection">
                                        <button className="participant-button-add" disabled>
                                            <RequestSent fillWarning={colors.warning} />
                                        </button>
                                    </div>
                                )}
                                {!isMyself && !isContact && !isFriendConnectionSent && !isFriendConnectionReceived && (
                                    <div onDragStart={preventDefaultDrag} className="connection">
                                        {this.state.loadingFriendRequest ? (
                                            <CircularProgress />
                                        ) : (
                                            <button
                                                className="participant-button-add"
                                                onClick={this.handleSendFriendConnection}
                                                disabled={restrictedNetwork}
                                            >
                                                {restrictedNetwork ? (
                                                    <Tooltip
                                                        arrow
                                                        classes={{tooltip: 'ignore-rtl'}}
                                                        id="connect-tooltip"
                                                        title="Restricted"
                                                        enterTouchDelay={1}
                                                    >
                                                        <Connect fill={colors.secondary} />
                                                    </Tooltip>
                                                ) : (
                                                    <Connect fill={colors.secondary} />
                                                )}
                                            </button>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {!isMyself && showInviteToStageButton && <InviteToStage user={userData} userIsOnline={isOnline} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userContacts: state.user.data?.contacts,
        userSentRequests: state.user.data?.sentRequests,
        userReceivedRequests: state.user.data?.receivedRequests,
        userLoading: state.user.loading,
        event: state.event.data,
        eventRoles: state.user.eventRoles,
        allEventUsersObject: state.eventUsers.allEventUsersObject,
        onlineUsersObject: state.onlineUsers.onlineUsersObject,
        privateChats: state.user.data.privateChats,
        resourcesAccess: state.user.resourcesAccess,
        platformLanguage: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId) => dispatch(actions.createPrivateChat(targetUserId)),
        onSendContactRequest: (to) => dispatch(actions.sendContactRequest(to)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserCard);
