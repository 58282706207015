import React, {useState} from 'react';

import Button from '@material-ui/core/Button';
import {ReactComponent as Sessions} from './helpers/svgs/session-icon.svg';
import {ReactComponent as Discard} from './helpers/svgs/discard.svg';
import {ReactComponent as Calendar} from './helpers/svgs/calendar.svg';
import {isMobile, isTablet} from 'react-device-detect';
import Accordion from '@material-ui/core/Accordion';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {useSelector} from 'react-redux';
import EventIcon from '@material-ui/icons/Event';
import {usePlatformTranslation} from 'services/hooks';

const SingleSessionItemAgenda = ({
    singleSession,
    deleteFromMyAgenda,
    handleSetCalendarTimeslot,
    handleSetActiveTimeslot,
    activeTimeslot,
}) => {
    const event = useSelector((state) => state?.event?.data);
    const platformLanguage = useSelector((state) => state.languages?.platformLanguage);

    /*translations*/
    const pastTranslation = usePlatformTranslation((state) => state?.agendaTranslations?.past);
    const liveTranslation = usePlatformTranslation((state) => state?.meetingsDropdown?.live);
    const addSlotToCalendarTranslation = usePlatformTranslation((state) => state?.programDropdown.addSlotToCalendar);
    /* end translations*/

    const [isExpanded, setIsExpanded] = useState(false);

    const setExpandedStatus = () => {
        setIsExpanded(!isExpanded);
    };

    const handleActiveTimeslot = (timeslot) => () => {
        handleSetActiveTimeslot(timeslot);
    };

    return (
        <>
            {isMobile ? (
                <div
                    className={`${platformLanguage === 'ar' ? 'lang-ar' : ''} accordion-keeper ${
                        isMobile ? 'mobile-support-session' : ''
                    }`}
                >
                    <Accordion
                        expanded={isExpanded}
                        className={`${platformLanguage === 'ar' && 'lang-ar'} agenda-meeting-requests-mobile  ${
                            isExpanded && 'active-time-slot-agenda-mobile'
                        }`}
                    >
                        <div
                            className={`${platformLanguage === 'ar' ? 'lang-ar' : ''} headline-accordion ${
                                isMobile ? 'mobile-support-session' : ''
                            }`}
                        >
                            <div className="time-and-status-bar">
                                <>
                                    {isTablet ? (
                                        <div
                                            className={`${
                                                platformLanguage === 'ar' ? 'lang-ar' : ''
                                            } single-agenda-item-container  padding-add-action-block ${
                                                platformLanguage === 'ar' ? 'lang-ar' : ''
                                            }`}
                                        >
                                            <div className="time-block">
                                                <span
                                                    className={`action-icon meeting-req  ${
                                                        singleSession?.isPast ? 'pastslot-color' : ''
                                                    }`}
                                                >
                                                    <Sessions />
                                                </span>
                                                <span
                                                    className={`start-time-of-req ${
                                                        singleSession?.isPast ? 'pastslot-color' : ''
                                                    }`}
                                                >
                                                    {singleSession?.startHours}
                                                </span>
                                                <span
                                                    className={`end-time-of-req ${
                                                        singleSession?.isPast ? 'pastslot-color' : ''
                                                    }`}
                                                >
                                                    {singleSession?.endHours}
                                                </span>
                                            </div>
                                            <div
                                                className={`information-block ${
                                                    platformLanguage === 'ar' ? 'line-ar' : ''
                                                }`}
                                            >
                                                <div className={'tablet-headline'}>
                                                    {singleSession?.isLive && (
                                                        <>
                                                            <span className="session-is-live">
                                                                <span className="white-ball"></span> {liveTranslation}
                                                            </span>
                                                        </>
                                                    )}
                                                    <a
                                                        className={'z10relative'}
                                                        href={`/event/${event?.slug}/auditorium/${singleSession?.auditoriumIndex}`}
                                                    >
                                                        <span className="auditorium-name-agenda">
                                                            {singleSession?.name}
                                                        </span>
                                                    </a>
                                                    {singleSession?.isPast && (
                                                        <>
                                                            <span className="session-is-live isPast">
                                                                <span className="white-ball"></span> {pastTranslation}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                                <span
                                                    className={`timeslot-name-agenda ${
                                                        singleSession?.isPast ? 'pastslot-color' : ''
                                                    }`}
                                                >
                                                    {singleSession?.title}
                                                </span>
                                                <span className="speakers-list-agenda">
                                                    {singleSession?.speakers?.split(',').toString()}
                                                </span>
                                            </div>
                                            <div className="action-block session-button-support">
                                                <>
                                                    {singleSession?.isPast ? (
                                                        <>
                                                            <button
                                                                onKeyPress={handleSetCalendarTimeslot(singleSession)}
                                                                onClick={handleSetCalendarTimeslot(singleSession)}
                                                                className="z10relative small-action-buttons pastslot-calender"
                                                            >
                                                                <Calendar />
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button
                                                                onKeyPress={handleSetCalendarTimeslot(singleSession)}
                                                                onClick={handleSetCalendarTimeslot(singleSession)}
                                                                className="z10relative small-action-buttons"
                                                            >
                                                                <Calendar />
                                                            </button>
                                                            <button
                                                                onKeyPress={deleteFromMyAgenda(singleSession)}
                                                                onClick={deleteFromMyAgenda(singleSession)}
                                                                className="z10relative small-action-buttons discard-help tblt"
                                                            >
                                                                <Discard />
                                                            </button>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`${platformLanguage === 'ar' && 'lang-ar'} header-time-status ${
                                                singleSession?.isPast && 'pastslot-color'
                                            }`}
                                        >
                                            <Sessions />
                                            <span
                                                className={`time-of-meeting ${
                                                    singleSession?.isPast ? 'pastslot-color' : ''
                                                }`}
                                            >
                                                {singleSession?.startHours} - {singleSession?.endHours}
                                            </span>
                                            {(singleSession?.isPast || singleSession?.isLive) && (
                                                <span
                                                    className={`status-mobile ${singleSession?.isLive && 'live'}  ${
                                                        singleSession?.isPast && 'past'
                                                    }`}
                                                >
                                                    {singleSession?.isLive && <span className="white-ball"></span>}
                                                    {singleSession?.isPast ? pastTranslation : liveTranslation}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </>
                                {!isTablet && (
                                    <Button
                                        onKeyPress={setExpandedStatus}
                                        onClick={setExpandedStatus}
                                        type="label"
                                        className={'z10relative mobile-support-accordion-button'}
                                        startIcon={<>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>}
                                    ></Button>
                                )}
                            </div>
                            {isTablet ? (
                                <></>
                            ) : (
                                <>
                                    <h4 className="session-name z10relative">
                                        <a href={`/event/${event?.slug}/auditorium/${singleSession?.auditoriumIndex}`}>
                                            {singleSession?.name}
                                        </a>
                                    </h4>
                                    <h5
                                        className={`${platformLanguage === 'ar' ? 'lang-ar' : ''} header-of-meeting ${
                                            singleSession?.isPast ? 'pastslot-color' : ''
                                        }`}
                                    >
                                        {singleSession?.title}
                                        <button
                                            className={`z10relative m-support-btn ${isMobile ? 'color-primary' : ''}`}
                                            onClick={deleteFromMyAgenda(singleSession)}
                                            onKeyPress={deleteFromMyAgenda(singleSession)}
                                        >
                                            <Discard className={'mr-3px'} />
                                        </button>
                                    </h5>
                                </>
                            )}
                        </div>

                        {isExpanded && (
                            <AccordionDetails className={`d-column ${platformLanguage === 'ar' ? 'lang-ar' : ''}`}>
                                {singleSession?.description && (
                                    <p
                                        className="event-desc-agenda-session"
                                        dangerouslySetInnerHTML={{__html: singleSession?.description}}
                                    />
                                )}
                                <div className="add-to-calendar-button-container add-to-calendar-button-container-sm">
                                    <Button
                                        variant="outlined"
                                        size={'small'}
                                        color="primary"
                                        onKeyPress={handleSetCalendarTimeslot(singleSession)}
                                        onClick={handleSetCalendarTimeslot(singleSession)}
                                        startIcon={<EventIcon />}
                                    >
                                        {addSlotToCalendarTranslation}
                                    </Button>
                                </div>
                                {singleSession?.speakers.length > 0 && (
                                    <div className={`speakers-area ${platformLanguage === 'ar' ? 'lang-ar' : ''}`}>
                                        <h3 className={'speakers-title'}>SPEAKERS</h3>
                                        <ul className={'listof-speakers'}>
                                            {singleSession?.speakers?.split(',').map((speaker, index) => (
                                                <li key={index}>{speaker}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </AccordionDetails>
                        )}
                    </Accordion>
                </div>
            ) : (
                <div className="element-control cursor-pointer">
                    <div
                        className={`single-agenda-item-container padding-add-action-block ${
                            activeTimeslot?._id === singleSession?._id ? 'active-slot' : ''
                        } ${platformLanguage === 'ar' ? 'lang-ar' : ''} `}
                    >
                        <div
                            className="transparent-action"
                            onKeyPress={handleActiveTimeslot(singleSession)}
                            onClick={handleActiveTimeslot(singleSession)}
                        ></div>

                        <div className={`time-block ${singleSession?.isPast ? 'pastslot-color' : ''}`}>
                            <span
                                className={`action-icon meeting-req ${singleSession?.isPast ? 'pastslot-color' : ''}`}
                            >
                                <Sessions />
                            </span>
                            <span className={`start-time-of-req ${singleSession?.isPast ? 'pastslot-color' : ''}`}>
                                {singleSession?.startHours}
                            </span>
                            <span className={`end-time-of-req ${singleSession?.isPast ? 'pastslot-color' : ''}`}>
                                {singleSession?.endHours}
                            </span>
                        </div>
                        <div className={`information-block ${platformLanguage === 'ar' ? 'line-ar' : ''}`}>
                            <div className={'head-keeping'}>
                                {singleSession?.isLive && (
                                    <span className="session-is-live">
                                        <span className="white-ball"></span> {liveTranslation}
                                    </span>
                                )}
                                <a
                                    className="z10relative"
                                    href={`/event/${event?.slug}/auditorium/${singleSession?.auditoriumIndex}`}
                                >
                                    <span className="auditorium-name-agenda">{singleSession?.name}</span>
                                </a>
                                {singleSession?.isPast && (
                                    <span className="session-is-live isPast">
                                        <span className="white-ball"></span> {pastTranslation}
                                    </span>
                                )}
                            </div>

                            <div className={'keeper-sub'}>
                                <span
                                    className={`z10relative timeslot-name-agenda ${
                                        singleSession?.isPast ? 'pastslot-color' : ''
                                    }`}
                                    onKeyPress={handleActiveTimeslot(singleSession)}
                                    onClick={handleActiveTimeslot(singleSession)}
                                >
                                    {singleSession?.title}
                                </span>
                                {singleSession?.speakers.length > 0 && (
                                    <span className="speakers-list-agenda">
                                        {singleSession?.speakers?.split(',').toString()}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="action-block session-button-support">
                            <>
                                {singleSession?.isPast ? (
                                    <>
                                        <button
                                            className={
                                                'z10relative x-decline-btn session pastslot-calender small-action-buttons mrM2'
                                            }
                                            onKeyPress={deleteFromMyAgenda(singleSession)}
                                            onClick={deleteFromMyAgenda(singleSession)}
                                        >
                                            <Discard />
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            onKeyPress={handleSetCalendarTimeslot(singleSession)}
                                            onClick={handleSetCalendarTimeslot(singleSession)}
                                            className="small-action-buttons z10relative"
                                        >
                                            <Calendar />
                                        </button>
                                        <button
                                            onKeyPress={deleteFromMyAgenda(singleSession)}
                                            onClick={deleteFromMyAgenda(singleSession)}
                                            className="z10relative small-action-buttons discard-help"
                                        >
                                            <Discard />
                                        </button>
                                    </>
                                )}
                            </>
                        </div>
                    </div>
                    <div className="single-agenda-border-bottom"></div>
                </div>
            )}
        </>
    );
};
//
SingleSessionItemAgenda.defaultProps = {
    isLive: false,
    accepted: false,
};
export default SingleSessionItemAgenda;
