import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

export const AccessDeniedShowfloor = ({title, message}) => {
    const history = useHistory();
    const params = useParams();

    const [dialogState, setDialogState] = useState(true);
    const closeDialog = (_) => {
        setDialogState(false);
        history.push(`/event/${params.eventSlug}/lobby`);
    };

    return (
        <>
            <Dialog open={dialogState} PaperProps={{classes: {root: 'reverse-rtl'}}}>
                <DialogTitle id="form-dialog-title">{title} </DialogTitle>
                <DialogContent>
                    <p>{message}</p>
                </DialogContent>
                <DialogActions className={'buttons-actions'}>
                    <Button onClick={closeDialog}
                            type="button"
                            variant={'outlined'}
                            color={'primary'}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

AccessDeniedShowfloor.defaultProps = {
    title: '',
    message: '',
};
