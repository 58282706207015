import LiveLabel from '../../../ProgramComponents/LiveLabel';
import {ReactComponent as GoToAuditorium} from '../svgs/go-to-auditorium.svg';

import {useSelector} from 'react-redux';
import {Tooltip} from '@material-ui/core';
import {ReactComponent as Discard} from '../../Agenda/helpers/svgs/discard.svg';
import {ReactComponent as AddToMyAgenda} from '../../Agenda/helpers/svgs/add-to-my-agenda.svg';
import EventIcon from '@material-ui/icons/Event';
import {useHistory} from 'react-router-dom';

const SingleCalendarListItem = ({
    handleActiveTimeslot,
    timeslot,
    addDeleteAgenda,
    isElementAddedToTimeslot,
    handleSetCalendarTimeslot,
    activeTimeslotCalendar,
    auditoriumCount,
    stopPropagation,
}) => {
    //redux state
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    return (
        <>
            <li
                className={`${timeslot?.isLive ? 'live' : ''} ${
                    activeTimeslotCalendar?._id === timeslot?._id ? 'active' : ''
                }`}
            >
                <div className={`item-cover`} onClick={handleActiveTimeslot(timeslot)}></div>
                <div className={`date-and-isLive`}>
                    <span>
                        {timeslot.start} - {timeslot.end}
                    </span>
                    {timeslot?.isLive && <LiveLabel />}
                </div>
                <h3 className={`timeslot-title-calendar`}>{timeslot.title}</h3>
                <span className={`timeslot-speakers-calendar`}>{timeslot.speakers}</span>
                <div className={`timeslot-actions`}>
                    <button onClick={stopPropagation(timeslot.auditoriumIndex)} className={`open-auditorium-btn`}>
                        <GoToAuditorium />
                        <span>
                            {translation?.programDropdown.openAuditorium ||
                                defaultTranslation?.programDropdown.openAuditorium}
                        </span>
                    </button>

                    <div className={`action-list-btns`}>
                        <div className={`separator-actions`}></div>
                        <Tooltip arrow title={'Add slot to calendar'} placement="bottom-end">
                            <EventIcon onClick={handleSetCalendarTimeslot(timeslot)} />
                        </Tooltip>
                        <button
                            className={`add-to-my-agenda-btn-calendar ${
                                auditoriumCount <= 1 ? 'single-auditorium-padding' : ''
                            }`}
                        >
                            <Tooltip
                                arrow
                                title={`${
                                    isElementAddedToTimeslot(timeslot)
                                        ? translation?.agendaTranslations?.addedToYourAgenda ||
                                          defaultTranslation.agendaTranslations?.addedToYourAgenda
                                        : translation?.agendaTranslations?.addToMyAgenda ||
                                          defaultTranslation.agendaTranslations?.addToMyAgenda
                                }`}
                                placement="bottom-end"
                            >
                                <button onClick={addDeleteAgenda(timeslot)}>
                                    {isElementAddedToTimeslot(timeslot) ? <Discard /> : <AddToMyAgenda />}
                                </button>
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </li>
        </>
    );
};

export default SingleCalendarListItem;
