import {preventDefaultDrag} from "../../Utils/utils";
import Button from "@material-ui/core/Button";
import React from "react";
import {useSelector} from "react-redux";
import InfoTabButton from "./InfoTabButton";

const SideMenuDrawerButtons = ({handleSeeNetworking, sideNavigation, handleSeeLiveWall}) => {
    const event = useSelector((state) => state.event.data);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const eventHasResources = useSelector((state) => state.event.data.hasResources);

    return <div onDragStart={preventDefaultDrag} className="event-actions-container swipeable-drawer">
        <div onDragStart={preventDefaultDrag} className="drawer-buttons-container">
            {eventHasResources && <InfoTabButton />}
            {!event.hideLobbyNetworking && (
                <Button
                    variant={"contained"}
                    disableElevation
                    color={"secondary"}
                    onClick={handleSeeNetworking}
                    tabIndex={sideNavigation.seeLiveWall || sideNavigation.seeNetworking ? "-1" : "0"}
                >
                    {translation?.sideMenu.networking}
                </Button>
            )}
            <Button
                variant={"contained"}
                disableElevation
                color={"primary"}
                onClick={handleSeeLiveWall}
                tabIndex={sideNavigation.seeLiveWall || sideNavigation.seeNetworking ? "-1" : "0"}
            >
                {translation?.sideMenu.liveWall}
            </Button>

        </div>
    </div>;
}

export default SideMenuDrawerButtons;