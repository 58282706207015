import React from 'react';
import {connect} from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import isEqual from 'lodash/isEqual';

import BrandingDisableLoginInfo from './Branding/BrandingDisableLoginInfo';
import BrandingExhibitorRegistration from './Branding/BrandingExhibitorRegistration';
import BrandingHomepage from './Branding/BrandingHomepage';
import BrandingLobby from './Branding/BrandingLobby';
import BrandingLogin from './Branding/BrandingLogin';
import BrandingParticipantRegistration from './Branding/BrandingParticipantRegistration';
import BrandingRenders from './Branding/BrandingRenders';
import BrandingScholarRegistration from './Branding/BrandingScholarRegistration';
import OrganizerAccordionNavigation from './OrganizerAccordionNavigation/OrganizerAccordionNavigation';
import SelectLanguageDashboard from './SelectLanguageDashboard';
import TranslateIcon from '@material-ui/icons/Translate';
import {getLanguageName, preventDefaultDrag} from 'Utils/utils';
import * as actions from 'store/actions/index';
import axios from 'store/axios-instance';
import Spinner from 'SmallLayoutComponents/Spinner';
import './EventBrandingStyles.scss';
import SnackbarGlobal from 'SmallLayoutComponents/Snackbars/SnackbarGlobal';
import CheckIcon from '@material-ui/icons/Check';

class EventBranding extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabsList: [
                {
                    path: 'homepage',
                    name: 'homepage',
                    label: 'HOMEPAGE',
                    subTabs: [
                        {path: 'description', name: 'description', label: 'Description'},
                        {path: 'speakers', name: 'speakers', label: 'Speakers'},
                        {path: 'sponsors', name: 'sponsors', label: 'Sponsors'},
                        {path: 'partners', name: 'partners', label: 'Partners'},
                        {path: 'exhibitors', name: 'exhibitors', label: 'Exhibitors'},
                        {path: 'banners', name: 'banners', label: 'Banners'},
                        {path: 'previous-events', name: 'previousEvents', label: 'Previous Events'},
                        {path: 'colors', name: 'colors', label: 'Colors'},
                    ],
                },
                {
                    path: 'lobby',
                    name: 'lobbyPage',
                    label: 'LOBBY',
                    subTabs: [
                        {path: 'welcome-screen', name: 'welcomeScreen', label: 'Welcome screen'},
                        {path: 'info-screen', name: 'infoScreen', label: 'Info screen'},
                        {path: 'door-texts', name: 'doorTexts', label: 'Door texts'},
                        {path: 'banner', name: 'bannerLobby', label: 'Banner'},
                        {path: 'logos', name: 'logosLobby', label: 'Logos'},
                        {path: 'closed-door-texts', name: 'closedDoors', label: 'Closed door texts'},
                        {path: 'program', name: 'program', label: 'Program'},
                        {path: 'resources', name: 'resources', label: 'Resources'},
                    ],
                },
                {path: 'login', name: 'loginPage', label: 'LOGIN'},
                {path: 'participant-sign-up', name: 'participantReg', label: 'PARTICIPANT SIGN UP'},
                {path: 'exhibitor-sign-up', name: 'exhibitorReg', label: 'EXHIBITOR SIGN UP'},
                {path: 'poster-study-sign-up', name: 'scholarReg', label: 'POSTER / STUDY SIGN UP'},
                {path: 'renders', name: 'renders', label: 'RENDERS'},
                {path: 'disabled-login-info', name: 'disabledLoginInfo', label: 'DISABLED LOGIN INFO'},
            ],
            successSnackbarOpened: false,
            errorSnackbarOpened: false,
            availableLanguages: [],
            showLanguageList: false,
            loadingSaveEventBranding: false,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        const {event} = this.props;
        this.loadBrandingData();
        this.props.onSetEventProtectedMenu();
        this.props.onSetOrganizerBrandingLanguage(event.language);
        document.addEventListener('click', this.handleClickOutside);
        this.setAvailableLanguages();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.brandingTranslations, this.props.brandingTranslations)) {
            this.setAvailableLanguages();
        }
    }

    componentWillUnmount() {
        // if the organizer sets up a new default language we will get a blank screen error
        // reset the branding language to null
        this.props.onSetOrganizerBrandingLanguage(null);
        document.removeEventListener('click', this.handleClickOutside);
    }

    loadBrandingData = () => {
        const {eventId, registrationFields, branding, brandingTranslations} = this.props;

        // make sure that we only get the data once per app load
        // we don't need to make this api calls every time the organizer navigates through the dashboards
        if (!registrationFields.fetched) {
            this.props.getEventRegistrationFields(eventId);
        }
        if (!branding.fetched) {
            this.props.getEventBranding(eventId);
        }
        if (!brandingTranslations.fetched) {
            this.props.getEventBrandingTranslations(eventId);
        }
    };

    setAvailableLanguages = () => {
        const {brandingTranslations} = this.props;
        let enabledLanguages = [];
        brandingTranslations.data?.forEach((translation) => {
            enabledLanguages.push(getLanguageName(translation.language));
        });
        this.setState({
            availableLanguages: enabledLanguages,
        });
    };

    handleOpenSuccessSnackbar = () => this.setState({successSnackbarOpened: true});
    handleOpenErrorSnackbar = () => this.setState({errorSnackbarOpened: true});
    handleCloseSnackbar = () => this.setState({successSnackbarOpened: false, errorSnackbarOpened: false});

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    toggleShowLanguageList = () =>
        this.setState((prevState) => ({
            showLanguageList: !prevState.showLanguageList,
        }));

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                showLanguageList: false,
            });
        }
    }

    handleChangeBrandingLanguage = (language) => () => {
        this.props.onSetOrganizerBrandingLanguage(language);
        this.setState({
            showLanguageList: false,
        });
    };

    saveEventBranding = (data) => {
        this.setState({loadingSaveEventBranding: true});
        const {eventId} = this.props;
        axios({method: 'post', url: `/event/${eventId}/branding`, data: data})
            .then((response) => {
                const brandingData = response.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.handleOpenSuccessSnackbar();
                this.setState({loadingSaveEventBranding: false});
            })
            .catch(() => {
                this.handleOpenErrorSnackbar();
                this.setState({loadingSaveEventBranding: false});
            });
    };

    saveEventTranslation = (translationData) => {
        this.setState({loadingSaveEventBranding: true});
        const {eventId} = this.props;

        let data = {...translationData};
        data.language = this.props.brandingLanguage;
        this.props
            .updateEventBrandingTranslations(eventId, data)
            .then((response) => {
                this.handleOpenSuccessSnackbar();
                this.setState({loadingSaveEventBranding: false});
            })
            .catch(() => {
                this.handleOpenErrorSnackbar();
                this.setState({loadingSaveEventBranding: false});
            });
    };

    saveClosedEventInfo = (data) => {
        this.setState({loadingSaveEventBranding: true});
        const {eventId} = this.props;
        axios({method: 'post', url: `/event/${eventId}/closed-event-info`, data: data})
            .then(() => {
                this.handleOpenSuccessSnackbar();
                this.props.onGetEvent(eventId);
                this.setState({loadingSaveEventBranding: false});
            })
            .catch(() => {
                this.handleOpenErrorSnackbar();
                this.setState({loadingSaveEventBranding: false});
            });
    };

    saveClosedDoorsInfo = (data) => {
        this.setState({loadingSaveEventBranding: true});
        const {eventId} = this.props;
        this.props
            .saveClosedDoorsInfo(eventId, data)
            .then((response) => {
                this.handleOpenSuccessSnackbar();
                this.setState({loadingSaveEventBranding: false});
            })
            .catch(() => {
                this.handleOpenErrorSnackbar();
                this.setState({loadingSaveEventBranding: false});
            });
    };

    render() {
        const {tabsList, availableLanguages, showLanguageList, loadingSaveEventBranding} = this.state;
        const {brandingLanguage, brandingTranslations, branding, eventSlug} = this.props;

        const sideBarContainer = document.querySelector('.side-navigation');
        const isNotSticky = sideBarContainer?.clientHeight > window.innerHeight - 116;

        if (!brandingLanguage) {
            return null;
        }
        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`organizer-dashboard-page has-side-nav ${isNotSticky ? 'not-sticky' : ''}`}
            >
                <div onDragStart={preventDefaultDrag} className="organizer-dashboard-container">
                    <div onDragStart={preventDefaultDrag} className="dashboard-with-side-nav">
                        <div onDragStart={preventDefaultDrag} className="side-navigation">
                            <div>
                                {brandingTranslations.data?.length > 1 && (
                                    <div onDragStart={preventDefaultDrag} className="select-language-container">
                                        <span>
                                            <Tooltip
                                                arrow
                                                classes={{tooltip: 'ignore-rtl'}}
                                                title={
                                                    <span className="">
                                                        This icon <TranslateIcon height={20} width={20} /> marks fields
                                                        available for multilanguage editing
                                                    </span>
                                                }
                                            >
                                                <InfoIcon color="secondary" />
                                            </Tooltip>
                                            Editing:
                                        </span>
                                        <SelectLanguageDashboard
                                            activeLanguage={brandingLanguage}
                                            languagesTranslations={brandingTranslations.data}
                                            handleChangeLanguage={this.handleChangeBrandingLanguage}
                                            toggleShowLanguageList={this.toggleShowLanguageList}
                                            showLanguageList={showLanguageList}
                                            setWrapperRef={this.setWrapperRef}
                                        />
                                    </div>
                                )}
                                <OrganizerAccordionNavigation tabsList={tabsList} navigationPrefix="branding" />
                            </div>
                        </div>
                        {!brandingTranslations.fetched || !branding.fetched ? (
                            <Spinner />
                        ) : (
                            <div onDragStart={preventDefaultDrag} className="aside-dashboard-container">
                                <Switch>
                                    <Route path="/event/:eventSlug/dashboard/branding/homepage">
                                        <BrandingHomepage
                                            saveEventBranding={this.saveEventBranding}
                                            saveEventTranslation={this.saveEventTranslation}
                                            handleOpenSuccessSnackbar={this.handleOpenSuccessSnackbar}
                                            handleOpenErrorSnackbar={this.handleOpenErrorSnackbar}
                                            eventSlug={eventSlug}
                                            availableLanguages={availableLanguages}
                                            loadingSaveEventBranding={loadingSaveEventBranding}
                                        />
                                    </Route>
                                    <Route path="/event/:eventSlug/dashboard/branding/lobby">
                                        <BrandingLobby
                                            saveClosedDoorsInfo={this.saveClosedDoorsInfo}
                                            saveEventBranding={this.saveEventBranding}
                                            handleOpenSuccessSnackbar={this.handleOpenSuccessSnackbar}
                                            handleOpenErrorSnackbar={this.handleOpenErrorSnackbar}
                                            eventSlug={eventSlug}
                                            loadingSaveEventBranding={loadingSaveEventBranding}
                                        />
                                    </Route>
                                    <Route exact path="/event/:eventSlug/dashboard/branding/login">
                                        <BrandingLogin
                                            saveEventBranding={this.saveEventBranding}
                                            saveEventTranslation={this.saveEventTranslation}
                                            handleOpenSuccessSnackbar={this.handleOpenSuccessSnackbar}
                                            handleOpenErrorSnackbar={this.handleOpenErrorSnackbar}
                                            availableLanguages={availableLanguages}
                                            loadingSaveEventBranding={loadingSaveEventBranding}
                                        />
                                    </Route>
                                    <Route exact path="/event/:eventSlug/dashboard/branding/participant-sign-up">
                                        <BrandingParticipantRegistration
                                            saveEventBranding={this.saveEventBranding}
                                            saveEventTranslation={this.saveEventTranslation}
                                            handleOpenSuccessSnackbar={this.handleOpenSuccessSnackbar}
                                            handleOpenErrorSnackbar={this.handleOpenErrorSnackbar}
                                            availableLanguages={availableLanguages}
                                            loadingSaveEventBranding={loadingSaveEventBranding}
                                        />
                                    </Route>
                                    <Route exact path="/event/:eventSlug/dashboard/branding/exhibitor-sign-up">
                                        <BrandingExhibitorRegistration
                                            saveEventBranding={this.saveEventBranding}
                                            saveEventTranslation={this.saveEventTranslation}
                                            handleOpenSuccessSnackbar={this.handleOpenSuccessSnackbar}
                                            handleOpenErrorSnackbar={this.handleOpenErrorSnackbar}
                                            availableLanguages={availableLanguages}
                                            loadingSaveEventBranding={loadingSaveEventBranding}
                                        />
                                    </Route>
                                    <Route exact path="/event/:eventSlug/dashboard/branding/poster-study-sign-up">
                                        <BrandingScholarRegistration
                                            saveEventBranding={this.saveEventBranding}
                                            saveEventTranslation={this.saveEventTranslation}
                                            handleOpenSuccessSnackbar={this.handleOpenSuccessSnackbar}
                                            handleOpenErrorSnackbar={this.handleOpenErrorSnackbar}
                                            availableLanguages={availableLanguages}
                                            loadingSaveEventBranding={loadingSaveEventBranding}
                                        />
                                    </Route>
                                    <Route exact path="/event/:eventSlug/dashboard/branding/renders">
                                        <BrandingRenders
                                            saveEventBranding={this.saveEventBranding}
                                            handleOpenSuccessSnackbar={this.handleOpenSuccessSnackbar}
                                            handleOpenErrorSnackbar={this.handleOpenErrorSnackbar}
                                        />
                                    </Route>
                                    <Route exact path="/event/:eventSlug/dashboard/branding/disabled-login-info">
                                        <BrandingDisableLoginInfo
                                            saveClosedEventInfo={this.saveClosedEventInfo}
                                            handleOpenSuccessSnackbar={this.handleOpenSuccessSnackbar}
                                            handleOpenErrorSnackbar={this.handleOpenErrorSnackbar}
                                            loadingSaveEventBranding={loadingSaveEventBranding}
                                        />
                                    </Route>
                                    <Redirect to={`/event/${eventSlug}/dashboard/branding/homepage`} />
                                </Switch>
                            </div>
                        )}
                    </div>
                    <SnackbarGlobal
                        snackbarOpen={this.state.successSnackbarOpened}
                        handleCloseSnackbar={this.handleCloseSnackbar}
                        message={`Changes successfully saved.`}
                        icon={<CheckIcon />}
                    />
                    <SnackbarGlobal
                        snackbarOpen={this.state.errorSnackbarOpened}
                        handleCloseSnackbar={this.handleCloseSnackbar}
                        message={`Error updating event branding information. Please try again.`}
                        isError={true}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        eventSlug: state.event.data.slug,
        brandingLanguage: state.languages.organizerBrandingLanguage,
        brandingTranslations: state.event.brandingTranslations,
        branding: state.event.branding,
        registrationFields: state.event.registrationFields,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEvent: (eventId) => dispatch(actions.getEvent(eventId)),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        onSetOrganizerBrandingLanguage: (languageCode) => dispatch(actions.setOrganizerBrandingLanguage(languageCode)),
        getEventRegistrationFields: (eventId) => dispatch(actions.getEventRegistrationFields(eventId)),
        getEventBranding: (eventId) => dispatch(actions.getEventBranding(eventId)),
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
        getEventBrandingTranslations: (eventId) => dispatch(actions.getEventBrandingTranslations(eventId)),
        updateEventBrandingTranslations: (eventId, brandingTranslationsData) =>
            dispatch(actions.updateEventBrandingTranslations(eventId, brandingTranslationsData)),
        saveClosedDoorsInfo: (eventId, brandingData) => dispatch(actions.saveClosedDoorsInfo(eventId, brandingData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventBranding);
