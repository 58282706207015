import React, {Component} from 'react';

export default class NotificationsFilled extends Component {
    render() {
        return (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.1903 15.5077C18.7203 13.037 18.3487 11.7973 18.3487 7.85C18.3487 3.79068 15.0587 0.5 11.0001 0.5C6.94145 0.5 3.65147 3.79072 3.65147 7.85C3.65147 9.97834 3.59479 10.7729 3.30181 11.7483C2.93747 12.9635 2.18951 14.1269 0.809461 15.5077C0.148331 16.1692 0.616647 17.3 1.55184 17.3H7.37827L7.3254 17.825C7.3254 19.8547 8.97041 21.5 10.9997 21.5C13.029 21.5 14.674 19.8547 14.674 17.825L14.6211 17.3H20.4479C21.3835 17.3 21.8518 16.1692 21.1903 15.5077ZM11.0004 20.45C9.55103 20.45 8.37576 19.2744 8.37576 17.825L8.42863 17.3H13.5715L13.6251 17.825C13.6251 19.2744 12.4498 20.45 11.0004 20.45ZM1.55217 16.25C4.70147 13.1 4.70147 11 4.70147 7.85C4.70147 4.37101 7.52141 1.55 11.0001 1.55C14.4787 1.55 17.2987 4.37101 17.299 7.85C17.299 11 17.299 13.1 20.4483 16.25H1.55217Z" fill="url(#gradient5)"/>
                <path d="M1.55217 16.25C4.70147 13.1 4.70147 11 4.70147 7.85C4.70147 4.37101 7.52141 1.55 11.0001 1.55C14.4787 1.55 17.2987 4.37101 17.299 7.85C17.299 11 17.299 13.1 20.4483 16.25H1.55217Z" fill="url(#gradient5)"/>
                <path d="M21.1903 15.5077C18.7203 13.037 18.3487 11.7973 18.3487 7.85C18.3487 3.79068 15.0587 0.5 11.0001 0.5C6.94145 0.5 3.65147 3.79072 3.65147 7.85C3.65147 9.97834 3.59479 10.7729 3.30181 11.7483C2.93747 12.9635 2.18951 14.1269 0.809461 15.5077C0.148331 16.1692 0.616647 17.3 1.55184 17.3H7.37827L7.3254 17.825C7.3254 19.8547 8.97041 21.5 10.9997 21.5C13.029 21.5 14.674 19.8547 14.674 17.825L14.6211 17.3H20.4479C21.3835 17.3 21.8518 16.1692 21.1903 15.5077ZM11.0004 20.45C9.55103 20.45 8.37576 19.2744 8.37576 17.825L8.42863 17.3H13.5715L13.6251 17.825C13.6251 19.2744 12.4498 20.45 11.0004 20.45ZM1.55217 16.25C4.70147 13.1 4.70147 11 4.70147 7.85C4.70147 4.37101 7.52141 1.55 11.0001 1.55C14.4787 1.55 17.2987 4.37101 17.299 7.85C17.299 11 17.299 13.1 20.4483 16.25H1.55217Z" stroke="url(#gradient5)" strokeWidth="0.5"/>
                <path d="M1.55217 16.25C4.70147 13.1 4.70147 11 4.70147 7.85C4.70147 4.37101 7.52141 1.55 11.0001 1.55C14.4787 1.55 17.2987 4.37101 17.299 7.85C17.299 11 17.299 13.1 20.4483 16.25H1.55217Z" stroke="url(#gradient5)" strokeWidth="0.5"/>
                <defs>
                    <linearGradient id="gradient5" x1="10.37" y1="-14.75" x2="33.8983" y2="11.1373" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                </defs>
            </svg>

        );
    }
}
