import axios from 'axios';
import store from './store';
import * as actions from './actions';

const instance = axios.create({
    baseURL: process.env.REACT_APP_REQUESTS_API,
});
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

const sleepRequest = (milliseconds, originalRequest) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(instance(originalRequest)), milliseconds);
    });
};

// setup a retry function for the same request if the server is down, error code 518
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalRequest = error.config;

        // if the error doesn't have a status, it mean we have a network error
        if (error.message === 'Network Error' || error.response?.status === 502) {
            store.dispatch(actions.serverDown());
            return sleepRequest(1500, originalRequest);
        } else if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('sessionToken');
            store.dispatch(actions.sessionExpired());
        } else {
            return Promise.reject(error);
        }
    }
);

(function () {
    let token = localStorage.getItem('token');
    let sessionToken = localStorage.getItem('sessionToken');
    if (token) {
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else if(sessionToken){
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + sessionToken;
    } else {
        delete instance.defaults.headers.common['Authorization'];
    }
})();

export default instance;
