import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {isMobile} from 'react-device-detect';
import {useSelector} from 'react-redux';

const PasswordDialog = ({
    password,
    handleChangePassword,
    dialogTitle,
    dialogContent,
    closeConfirm,
    open,
    handleConfirm,
    passwordError,
    platformLanguage,
}) => {
    //redux
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const [showPassword, setShowPassword] = useState(false);

    const handleConfirmPassword = () => {
        // API call will run and if all is good, the following code will work
        handleConfirm();
    };

    const isButtonDisabled = password?.length < 2;
    const isError = passwordError?.length > 0;

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={closeConfirm}
                className={`password-control-container-width ${isMobile ? 'mobile-support' : ''} `}
            >
                <DialogTitle id="form-dialog-title" className={`password-validation-title`}>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent className={`${platformLanguage === 'ar' ? 'arabic-support' : ''} scroll-left-container`}>
                    <DialogContentText>{dialogContent}</DialogContentText>
                    <ValidatorForm
                        onSubmit={handleConfirmPassword}
                        onError={(errors) => console.log(errors)}
                        className={'control-password-form'}
                        instantValidate={false} // Disable instant validation to show errors only on submit
                    >
                        <TextValidator
                            label={translation.emailUpdate.password || defaultTranslation.emailUpdate.password}
                            type={showPassword ? 'text' : 'password'}
                            fullWidth={true}
                            className={isError ? 'error-text-field' : 'text-field'}
                            variant="outlined"
                            validators={['minStringLength:2']}
                            errorMessages={['Min string length 2']}
                            value={password}
                            onChange={handleChangePassword}
                            name={'password'}
                            color={'secondary'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton size={'small'} onClick={toggleShowPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {isError && <span className={'error-text-span'}>{passwordError}</span>}
                        <DialogActions className={'password-control-actions'}>
                            <Button onClick={closeConfirm} color="primary">
                                {translation.emailUpdate.cancel || defaultTranslation.emailUpdate.cancel}{' '}
                            </Button>
                            <Button disabled={isButtonDisabled || isError} type="submit" color="primary">
                                {translation.emailUpdate.confirm || defaultTranslation.emailUpdate.confirm}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        </div>
    );
};

PasswordDialog.propTypes = {
    password: PropTypes.string.isRequired,
    handleChangePassword: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    dialogContent: PropTypes.string.isRequired,
    closeConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    passwordError: PropTypes.string,
};

export default PasswordDialog;
