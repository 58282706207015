import {preventDefaultDrag} from "../../Utils/utils";
import LinkIcon from '@material-ui/icons/Link';
import React from "react";

const LinkResource = ({link}) => {
    return <div className={'resource'}>
        <a href={link.link} target="_blank" rel="noopener noreferrer">
            <span
                onDragStart={preventDefaultDrag}
                draggable="false"
                className="resource-title ellipsis-second-line break-word"
            >
                {link.displayName}
            </span>
            <LinkIcon />
        </a>
    </div>
}

export default LinkResource;