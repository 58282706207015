import axios from '../axios-instance';
import {
    ADD_EVENT_SPEAKER_REQUEST,
    ADD_EVENT_SPEAKER_SUCCESS,
    ADD_EVENT_SPEAKER_FAIL,
    DELETE_EVENT_SPEAKER_REQUEST,
    DELETE_EVENT_SPEAKER_SUCCESS,
    DELETE_EVENT_SPEAKER_FAIL,
    GET_EVENT_SPEAKERS_REQUEST,
    GET_EVENT_SPEAKERS_SUCCESS,
    GET_EVENT_SPEAKERS_FAIL,
    UPLOAD_EVENT_SPEAKER_IMAGE_REQUEST,
    UPLOAD_EVENT_SPEAKER_IMAGE_SUCCESS,
    UPLOAD_EVENT_SPEAKER_IMAGE_FAIL,
    DELETE_EVENT_SPEAKER_IMAGE_REQUEST,
    DELETE_EVENT_SPEAKER_IMAGE_SUCCESS,
    DELETE_EVENT_SPEAKER_IMAGE_FAIL,
    UPDATE_EVENT_SPEAKER_REQUEST,
    UPDATE_EVENT_SPEAKER_SUCCESS,
    UPDATE_EVENT_SPEAKER_FAIL,
    UPDATE_EVENT_SPEAKER_STATUS_REQUEST,
    UPDATE_EVENT_SPEAKER_STATUS_SUCCESS,
    UPDATE_EVENT_SPEAKER_STATUS_FAIL,
    UPDATE_EVENT_SPEAKER_ORDER_REUQEST,
    UPDATE_EVENT_SPEAKER_ORDER_SUCCESS,
    UPDATE_EVENT_SPEAKER_ORDER_FAIL,
} from './actionTypes';

//get all speakers
export const listSpeakers = (eventId, hidden) => async (dispatch) => {
    try {
        dispatch({type: GET_EVENT_SPEAKERS_REQUEST});
        //POSTMAN url - below
        //{{url}}/v1/event/:eventId/speakers?filter={"hidden": false}
        const urlWithFilter = `/event/${eventId}/speakers?filter={"hidden": ${hidden}}`;
        const urlWithoutFilter = `/event/${eventId}/speakers`;

        const {data} = await axios.get(hidden === false ? urlWithFilter : urlWithoutFilter);
        dispatch({
            type: GET_EVENT_SPEAKERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_EVENT_SPEAKERS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

//add a new speaker
export const addNewSpeaker = (eventId, speaker) => async (dispatch) => {
    try {
        dispatch({type: ADD_EVENT_SPEAKER_REQUEST});
        //POSTMAN url - below
        //{{url}}/v1/event/:eventId/speaker
        const {data} = await axios.post(`/event/${eventId}/speaker`, speaker, {});
        dispatch({
            type: ADD_EVENT_SPEAKER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ADD_EVENT_SPEAKER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

//update a speaker
export const updateSpeaker = (eventId, speakerId, speaker) => async (dispatch) => {

    
    try {
        dispatch({type: UPDATE_EVENT_SPEAKER_REQUEST});
        //POSTMAN url - below
        ///event/:eventId/speaker/:speakerId
        const {data} = await axios.patch(`/event/${eventId}/speaker/${speakerId}`, speaker, {});
        dispatch({
            type: UPDATE_EVENT_SPEAKER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_EVENT_SPEAKER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const updateSpeakerStatus = (eventId, speakerId, status) => async (dispatch) => {
    try {
        dispatch({type: UPDATE_EVENT_SPEAKER_STATUS_REQUEST});
        //POSTMAN url - below
        ///event/:eventId/speaker/:speakerId
        const {data} = await axios.patch(`/event/${eventId}/speaker/${speakerId}`, status, {});
        dispatch({
            type: UPDATE_EVENT_SPEAKER_STATUS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_EVENT_SPEAKER_STATUS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const updateSpeakerOrder = (eventId, speakerId, order) => async (dispatch) => {
    try {
        dispatch({type: UPDATE_EVENT_SPEAKER_ORDER_REUQEST});

        const {data} = await axios.patch(`/event/${eventId}/speaker/${speakerId}`, order, {});
        dispatch({
            type: UPDATE_EVENT_SPEAKER_ORDER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_EVENT_SPEAKER_ORDER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

//delete a speaker
export const deleteSpeaker = (eventId, speakerId) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_EVENT_SPEAKER_REQUEST,
        });
        ///event/:eventId/speaker/:speakerId
        //POSTMAN url - below

        await axios.delete(`/event/${eventId}/speaker/${speakerId}`);
        dispatch({
            type: DELETE_EVENT_SPEAKER_SUCCESS,
            payload: speakerId,
        });
    } catch (error) {
        dispatch({
            type: DELETE_EVENT_SPEAKER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

//add a new IMAGE speaker
export const addNewImageSpeaker = (eventId, speakerId, formData) => async (dispatch) => {
    try {
        dispatch({type: UPLOAD_EVENT_SPEAKER_IMAGE_REQUEST});
        //POSTMAN url - below
        //{{url}}/event/:eventId/speaker/:speakerId/image
        const {data} = await axios.post(`/event/${eventId}/speaker/${speakerId}/image`, formData, {});
        dispatch({
            type: UPLOAD_EVENT_SPEAKER_IMAGE_SUCCESS,
            payload: data.image,
            speaker: speakerId,
        });
    } catch (error) {
        dispatch({
            type: UPLOAD_EVENT_SPEAKER_IMAGE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

// delete IMAGE speaker

export const deleteImageSpeaker = (eventId, speakerId) => async (dispatch) => {
    try {
        dispatch({type: DELETE_EVENT_SPEAKER_IMAGE_REQUEST});
        //POSTMAN url - below
        //{{url}}/ /event/:eventId/speaker/:speakerId/image
        const {data} = await axios.delete(`/event/${eventId}/speaker/${speakerId}/image`, {});
        dispatch({
            type: DELETE_EVENT_SPEAKER_IMAGE_SUCCESS,
            payload: data,
            speaker: speakerId,
        });
    } catch (error) {
        dispatch({
            type: DELETE_EVENT_SPEAKER_IMAGE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};
