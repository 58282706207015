import {preventDefaultDrag} from "../../Utils/utils";
import {LiveWallTabButton} from "./LiveWallTabButton";
import {NetworkingTabButton} from "./NetworkingTabButton";
import React from "react";
import {useSelector} from "react-redux";
import EventInfoTabButton from "./EventInfoTabButton";

export function TabsContainer({
                                  handleSeeLiveWall,
                                  handleSeeVideoWall,
                                  handleEnterKeyWall,
                                  handleSeeNetworking,
                                  hasAccessToNetworking,
                                  handleEnterKeyNetworking,
                                  handleSeeInfoTab,
                                  handleEnterKeyEventInfo
                              }) {
    const sideNavigationDetails = useSelector((state) => state.event.sideNavigationDetails);
    const sideNavigation = useSelector((state) => state.user.sideNavigation);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const event = useSelector((state) => state?.event?.data);
    const eventHasResources = useSelector((state) => state.event.data.hasResources);

    return <div onDragStart={preventDefaultDrag} className="tabs-container">
        {sideNavigationDetails.hasVideoWall ? (
            <>
                {!sideNavigation.seeLiveWall && (
                    <div
                        tabIndex="0"
                        className="live-wall-tab button-tab tab"
                        onClick={handleSeeLiveWall}
                        onKeyDown={handleEnterKeyWall}
                    >
                        {translation?.sideMenu.liveWall}
                    </div>
                )}
                {!sideNavigation.seeVideoWall && (
                    <div
                        className="video-wall-tab button-tab tab"
                        onClick={handleSeeVideoWall}
                    >
                        {translation?.sideMenu.videoWall}
                    </div>
                )}

                {!sideNavigation.seeNetworking && !event.hideLobbyNetworking && (
                    <div
                        tabIndex="0"
                        className="networking-tab button-tab tab "
                        onClick={handleSeeNetworking}
                        onKeyDown={handleEnterKeyNetworking}
                    >
                        {translation?.sideMenu.networking}
                    </div>
                )}
            </>
        ) : (
            <>
                <LiveWallTabButton
                    handleSeeLiveWall={handleSeeLiveWall}
                    handleEnterKeyWall={handleEnterKeyWall}
                />
                {!event.hideLobbyNetworking && (
                    <NetworkingTabButton
                        handleSeeNetworking={handleSeeNetworking}
                        handleEnterKeyNetworking={handleEnterKeyNetworking}
                        hasAccessToNetworking={hasAccessToNetworking}
                    />
                )}
                {eventHasResources && (
                    <EventInfoTabButton
                        handleSeeInfoTab={handleSeeInfoTab}
                        handleEnterKeyEventInfo={handleEnterKeyEventInfo}
                    />
                )}
            </>
        )}
    </div>;
}