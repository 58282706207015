import {preventDefaultDrag} from "Utils/utils";
import {Editor} from "react-draft-wysiwyg";
import React, {useEffect, useState} from "react";
import {stateFromHTML} from "draft-js-import-html";
import {stateToHTML} from "draft-js-export-html";
import {convertToRaw, EditorState, RichUtils} from "draft-js";
import {ManualError} from "../../../ManualError/ManualError";

const LobbyResourcesDescription = ({
                                       description,
                                       setNewDescription,
                                       characterCount,
                                       setCharacterCount,
                                       setIsSaved,
                                   }) => {
    const editor = React.createRef();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    // For the case when description is updated from redux
    useEffect(() => {
        if (description) {
            updateDescription(description);
            setNewDescription(description);
        }
    }, [description]);

    const updateDescription = (newDescription) => {
        setNewDescription(newDescription);
        const contentState = stateFromHTML(newDescription);
        const counter = convertToRaw(contentState)
            .blocks.map((object) => object.text).join(' ').length;
        setEditorState(EditorState.createWithContent(contentState));
        setCharacterCount(counter);
    }

    const focusEditor = () => {
        if (editor) {
            editor.current.focusEditor();
        }
    };

    const onChangeRichTextEditor = (editorState) => {
        let contentState = stateFromHTML(stateToHTML(editorState.getCurrentContent()));
        let characterCount = convertToRaw(contentState)
            .blocks.map((obj) => obj.text)
            .join(' ').length;
        setEditorState(editorState);
        setNewDescription(stateToHTML(editorState.getCurrentContent()));
        setCharacterCount(characterCount);
        setIsSaved(false);
    };

    const handleReturn = (e) => {
        if (e.shiftKey) {
            setEditorState(RichUtils.insertSoftNewline(editorState));
            return 'handled';
        }
        return 'not-handled';
    };

    return (<>
            <p onDragStart={preventDefaultDrag} className="resource-title description">
                DESCRIPTION
            </p>
            <div onDragStart={preventDefaultDrag} className="options-container resource-container">

                <div onDragStart={preventDefaultDrag} className="resource-inputs description-container">
                    <div
                        onDragStart={preventDefaultDrag}
                        className="editor-wrapper multiline-input"
                        onClick={focusEditor}
                    >
                        <Editor
                            ref={editor}
                            editorState={editorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={onChangeRichTextEditor}
                            handleReturn={handleReturn}
                            toolbar={{
                                options: ['link', 'inline', 'list', 'emoji'],
                                inline: {
                                    options: ['bold', 'italic', 'underline'],
                                },
                                list: {
                                    options: ['unordered', 'ordered'],
                                },
                            }}
                        />
                        <span className={'char-count'}>
                        {characterCount === 0 ? null : characterCount}
                    </span>
                    </div>
                    <ManualError maxLength={5000} valueLength={characterCount}/>
                </div>
                <div onDragStart={preventDefaultDrag} className="input-description">
                    <p>Description is limited to 5000 characters</p>
                </div>
            </div>
        </>
    )
}

export default LobbyResourcesDescription;