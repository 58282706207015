import React, {useState} from 'react';
import EventIcon from '@material-ui/icons/Event';
import DialogAddToExternalCalendar from '../DialogAddToExternalCalendar';
import {useSelector} from 'react-redux';
import {
    getEventStartOrEndDate,
    getFormattedMonth,
    getTimeslotDescriptionFormatted,
    getTimeslotDuration,
} from 'Utils/utils';
import Button from '@material-ui/core/Button';
import DisplaySpeakers from './DisplaySpeakers';
import {stopPropagation} from 'Utils/utils';

const DetailedTimeslot = ({activeTimeslot}) => {
    const timeslotDate = new Date(activeTimeslot?.startTimestamp);
    const timeslotMonth = timeslotDate?.toLocaleString('default', {month: 'short'});
    const timeslotDay = timeslotDate?.toLocaleString('default', {day: 'numeric'});

    let speakersArray = [];
    if (activeTimeslot?.speakers) {
        speakersArray = activeTimeslot?.speakers.split(',');
    }

    const event = useSelector((state) => state?.event?.data);
    const user = useSelector((state) => state?.user?.data);
    const languages = useSelector((state) => state?.languages);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const [externalCalendarDialog, setExternalCalendarDialog] = useState(false);
    const [calendarData, setCalendarData] = useState(null);

    const hideAddToExternalCalendarDialog = () => {
        setExternalCalendarDialog(false);
    };

    const createTimeslotCalendarData = () => {
        const timeslotCalendarData = {
            title: `${activeTimeslot.title}`,
            description: getTimeslotDescriptionFormatted(activeTimeslot, translation),
            location: `${window.location.origin}/event/${event.slug}/`,
            start: activeTimeslot.startTimestamp,
            end: activeTimeslot.endTimestamp,
        };
        return timeslotCalendarData;
    };

    const createExternalCalendarData = () => {
        const calendarData = createTimeslotCalendarData();

        setCalendarData(calendarData);
        setExternalCalendarDialog(true);
    };

    return (
        <div className="timeslot-details-container">
            {activeTimeslot && (
                <div className="timeslot-details scroll-left-container">
                    <div>
                        <div className="date-container">
                            <EventIcon />
                            <p className="timespan">
                                {activeTimeslot.start} - {activeTimeslot.end},{' '}
                                {getFormattedMonth(timeslotDate, translation).toUpperCase()} {timeslotDay}
                            </p>
                        </div>
                        <p className="auditorium-name">
                            <a
                                href={`/event/${event.slug}/auditorium/${activeTimeslot.auditoriumIndex}`}
                                onClick={stopPropagation}
                            >
                                {activeTimeslot.auditoriumName || activeTimeslot.name}
                            </a>
                        </p>
                        <h5 className="timeslot-title">{activeTimeslot.title}</h5>
                        <p
                            className="timeslot-description"
                            dangerouslySetInnerHTML={{__html: activeTimeslot.description}}
                        />

                        <div className="add-to-calendar-button-container">
                            <Button
                                variant="outlined"
                                size={'small'}
                                color="primary"
                                onClick={createExternalCalendarData}
                                startIcon={<EventIcon />}
                            >
                                {translation?.programDropdown.addSlotToCalendar ||
                                    defaultTranslation?.programDropdown.addSlotToCalendar}
                            </Button>
                        </div>
                        <DisplaySpeakers
                            speakersArray={speakersArray}
                            translation={translation}
                            defaultTranslation={defaultTranslation}
                        />
                    </div>
                </div>
            )}

            {externalCalendarDialog && (
                <DialogAddToExternalCalendar
                    open={externalCalendarDialog}
                    onClose={hideAddToExternalCalendarDialog}
                    eventCalendarData={calendarData}
                    title={
                        translation?.programDropdown.addTimeslotToYourCalendar ||
                        defaultTranslation?.programDropdown.addTimeslotToYourCalendar
                    }
                    timeslotTitle={
                        translation?.programDropdown.timeslotTitle || defaultTranslation?.programDropdown.timeslotTitle
                    }
                    timeslotDescripion={activeTimeslot.title}
                    timeslotSpeakers={activeTimeslot.speakers}
                    durationTitle={
                        translation?.programDropdown.slotScheduled || defaultTranslation?.programDropdown.slotScheduled
                    }
                    duration={getTimeslotDuration(activeTimeslot, languages, event, user)}
                    tip={
                        translation?.programDropdown.tipTimeslotDescription ||
                        defaultTranslation?.programDropdown.tipTimeslotDescription
                    }
                />
            )}
        </div>
    );
};

export default DetailedTimeslot;
