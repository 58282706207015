import React from 'react';
import moment from 'moment';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import PlatformMenu from '../Components/PlatformMenu';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import BackgroundImage from '../Images/background.jpg';
import Spinner from '../SmallLayoutComponents/Spinner';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ConfirmEvent from '../Dialogs/ConfirmEvent';
import {ReactComponent as RectanglePurple} from '../Images/svg/bck_small.svg';
import RegisterMenuLinks from '../Components/RegisterMenuLinks';
import EventIcon from '@material-ui/icons/Event';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {preventDefaultDrag} from '../Utils/utils';
import {specialCharactersString} from '../Utils/validationRules';
import {atLeastOneLetter} from './../Utils/validationRules';
import {Checkbox} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

export const eventNameMaxCharacters = 100;
export const eventNameMinCharacters = 2;

class CreateEvent extends React.Component {
    state = {
        fields: [
            {
                name: 'name',
                type: 'text',
                value: '',
                label: 'Event Name',
                multiline: 0,
                validators: [
                    'required',
                    'noSpecialChars',
                    `minStringLength:${eventNameMinCharacters}`,
                    `maxStringLength:${eventNameMaxCharacters}`,
                    'isString',
                ],
                errorMessages: [
                    'Field is required',
                    'The following characters aren\'t supported in the event name: ~`!@#$%^_*+=;",.<>?|\\/[](){}',
                    `The name must have at least ${eventNameMinCharacters} characters`,
                    `You have reached the maximum limit of characters allowed (${eventNameMaxCharacters} characters)`,
                    'The name must contain at least one letter',
                ],
            },
        ],
        eventType: 'default',
        eventStartDate: moment(new Date().toISOString()).format('YYYY-MM-DD'),
        eventEndDate: moment(new Date().toISOString()).format('YYYY-MM-DD'),
        invalidStartDate: false,
        invalidEndDate: false,
        invalidName: false,
        noDate: false,
        openConfirmEvent: false,
        nrOfBuildings: 2,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onSetPlatformMenu();

        ValidatorForm.addValidationRule('noSpecialChars', (value) => {
            let rule = specialCharactersString;
            for (let i = 0; i < rule.length; i++) {
                if (value.indexOf(rule[i]) > -1) {
                    return false;
                }
            }
            return true;
        });

        ValidatorForm.addValidationRule('atLeastOneLetter', (value) => {
            const rule = atLeastOneLetter;
            return rule.test(value);
        });
    }

    componentWillUnmount() {
        this.props.onClearError();
        const mobileMenu = document.getElementsByClassName('platform mobile-menu-wrapper hide');
        if (mobileMenu.length !== 0) {
            mobileMenu[0].classList.remove('hide');
        }
        ValidatorForm.removeValidationRule('noSpecialChars');
        ValidatorForm.removeValidationRule('atLeastOneLetter');
    }

    handleCloseDialog = () => this.setState({openConfirmEvent: false});
    handleChangeNoDate = (e) => this.setState({noDate: e.target.checked});
    handleChangeEventType = (e) => this.setState({eventType: e.target.value});
    handleChangeNumberOfBuildings = (e) => this.setState({nrOfBuildings: e.target.value});
    handleStartDate = (date) => this.setState({eventStartDate: moment(date).format('YYYY-MM-DD')});
    handleEndDate = (date) => this.setState({eventEndDate: moment(date).format('YYYY-MM-DD')});
    onErrorStartDate = (error) => this.setState({invalidStartDate: error !== ''});
    onErrorEndDate = (error) => this.setState({invalidEndDate: error !== ''});
    onErrorName = (error) => this.setState({invalidName: error !== ''});

    handleFieldChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        const inputValue = e.target.value.replaceAll(/[\u{1F600}-\u{1F6FF}]/gu, '');
        updatedFields[index].value = updatedFields[index].type === 'number' ? +inputValue : inputValue;
        this.setState({fields: updatedFields});
    };

    handlePaste = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text');
        // Replace emojis followed by spaces
        const sanitizedText = pastedText.replace(/[\p{Emoji}\s]+/gu, '').trim();
        document.execCommand('insertText', false, sanitizedText);
    };
    handleInputFocus = () => {
        const mobileMenu = document.getElementsByClassName('platform mobile-menu-wrapper ');
        if (mobileMenu) {
            mobileMenu[0].classList.add('hide');
        }
    };

    handleInputBlur = () => {
        const mobileMenu = document.getElementsByClassName('platform mobile-menu-wrapper hide');
        if (mobileMenu.length !== 0) {
            mobileMenu[0].classList.remove('hide');
        }
    };

    createEvent = () => {
        this.handleCloseDialog();
        const eventData = this.getCreateEventData();
        this.props.onCreateEvent(eventData).then(() => this.props.refreshUserData());
    };

    handleCreateEvent = () => {
        const {userData} = this.props;

        if (this.state.fields[0].name.trim() === '') {
            return;
        }

        if (userData?.isOrganizer === false || userData?.organizerStatus === 'pending') {
            this.setState({
                openConfirmEvent: true,
            });
        } else {
            this.createEvent();
        }
    };

    getCreateEventData = () => {
        const {fields, noDate, eventType, nrOfBuildings, eventStartDate, eventEndDate} = this.state;
        const data = {};
        fields.forEach((field) => {
            data[field.name] = field.value;
            data['eventStartDate'] = eventStartDate;
            data['eventEndDate'] = eventEndDate;
        });

        // Daylight Saving Time
        let today = new Date();
        // eslint-disable-next-line
        Date.prototype.stdTimezoneOffset = function () {
            let jan = new Date(this.getFullYear(), 0, 1);
            let jul = new Date(this.getFullYear(), 6, 1);
            return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
        };
        // eslint-disable-next-line
        Date.prototype.isDstObserved = function () {
            return this.getTimezoneOffset() < this.stdTimezoneOffset();
        };
        if (today.isDstObserved()) {
            data.dst = 'isDst';
        }

        if (noDate) {
            data.eventStartDate = moment(new Date().toISOString()).format('YYYY-MM-DD');
            data.eventEndDate = moment(new Date().toISOString()).format('YYYY-MM-DD');
        }

        // if the eventType
        if (eventType === 'default') {
            data.hasVillage = false;
        } else {
            data.hasVillage = true;
            data.nrOfBuildings = nrOfBuildings;
        }
        return data;
    };

    render() {
        const {
            fields,
            noDate,
            openConfirmEvent,
            eventStartDate,
            eventEndDate,
            invalidStartDate,
            invalidEndDate,
            invalidName,
        } = this.state;
        const numberOfBuildingsOption = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
        const {loadingEvent, loadingUser, isMobile} = this.props;
        const invalidDate = moment(eventStartDate).isAfter(eventEndDate);

        return (
            <div>
                <PlatformMenu />
                <div onDragStart={preventDefaultDrag} className="register-participant-step-2 create-event">
                    <div onDragStart={preventDefaultDrag} className="full-background-container p-relative d-flex">
                        <img
                            src={BackgroundImage}
                            className="full-background-img img-cover p-absolute w-100 h-100"
                            alt="background"
                        />
                        <div
                            onDragStart={preventDefaultDrag}
                            className="full-background-overlay p-absolute w-100 h-100"
                        />
                        <div onDragStart={preventDefaultDrag} className="header-spacing-container">
                            <div
                                onDragStart={preventDefaultDrag}
                                className="w-100 h-100 d-flex justify-content-center align-items-center"
                            >
                                <div onDragStart={preventDefaultDrag} className="form-container d-flex">
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        className="form-header left-log-in-description is-sign-up"
                                    >
                                        <h1 className="form-title banner-text">
                                            {'Set up your '}
                                            <div>
                                                <RectanglePurple />
                                                <span>event</span>
                                            </div>
                                        </h1>
                                    </div>
                                    <div>
                                        <div onDragStart={preventDefaultDrag} className="left-form">
                                            <div>
                                                <p>
                                                    You will be able to add full event details in your organizer
                                                    dashboard
                                                </p>
                                            </div>
                                        </div>
                                        <div onDragStart={preventDefaultDrag} className="right-form">
                                            <div onDragStart={preventDefaultDrag} className="register-form-wrapper">
                                                <ValidatorForm ref="form" onSubmit={this.handleCreateEvent}>
                                                    {fields.map((field, index) => {
                                                        return (
                                                            <TextValidator
                                                                key={field.name}
                                                                placeholder={field.label}
                                                                type={field.type}
                                                                name={field.name}
                                                                index={index}
                                                                autoFocus={index === 0}
                                                                value={field.value}
                                                                variant="outlined"
                                                                onChange={this.handleFieldChange(index)}
                                                                validators={field.validators}
                                                                onPaste={
                                                                    field.name === 'name' ? this.handlePaste : null
                                                                }
                                                                errorMessages={field.errorMessages}
                                                                onError={this.onErrorName}
                                                                multiline={field.multiline > 0}
                                                                rows={field.multiline}
                                                                fullWidth={true}
                                                                margin="normal"
                                                                className="field-container"
                                                                onFocus={this.handleInputFocus}
                                                                onBlur={this.handleInputBlur}
                                                            />
                                                        );
                                                    })}
                                                    <div className="input-wrapper">
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            hiddenLabel
                                                            inputVariant="outlined"
                                                            showTodayButton
                                                            disabled={noDate}
                                                            format="DD/MM/YYYY"
                                                            onError={this.onErrorStartDate}
                                                            value={eventStartDate || new Date().toISOString()}
                                                            onChange={this.handleStartDate}
                                                            InputProps={{
                                                                classes: {
                                                                    root: 'setting-input',
                                                                },
                                                                style: {
                                                                    WebkitBoxShadow: '0 0 0 1000px white inset',
                                                                    borderRadius: '8px',
                                                                    color: 'grey',
                                                                },
                                                            }}
                                                            DialogProps={{
                                                                PaperProps: {classes: {root: 'reverse-rtl'}},
                                                            }}
                                                            InputLabelProps={{shrink: true}}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            keyboardIcon={<EventIcon classes={{root: 'dark-color'}} />}
                                                            helperText={invalidStartDate ? 'Invalid date format' : null}
                                                        />
                                                    </div>
                                                    <div className="input-wrapper">
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            hiddenLabel
                                                            inputVariant="outlined"
                                                            showTodayButton
                                                            disabled={noDate}
                                                            format="DD/MM/YYYY"
                                                            onError={this.onErrorEndDate}
                                                            value={eventEndDate || new Date().toISOString()}
                                                            onChange={this.handleEndDate}
                                                            InputProps={{
                                                                classes: {
                                                                    root: 'setting-input',
                                                                },
                                                                style: {
                                                                    WebkitBoxShadow: '0 0 0 1000px white inset',
                                                                    borderRadius: '8px',
                                                                },
                                                            }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            DialogProps={{
                                                                PaperProps: {classes: {root: 'reverse-rtl'}},
                                                            }}
                                                            InputLabelProps={{shrink: true}}
                                                            keyboardIcon={<EventIcon classes={{root: 'dark-color'}} />}
                                                            helperText={invalidEndDate ? 'Invalid date format' : null}
                                                        />
                                                        {invalidDate && !noDate && (
                                                            <p onDragStart={preventDefaultDrag} className="error-text">
                                                                End date cannot be before start date
                                                            </p>
                                                        )}
                                                    </div>
                                                    <FormControlLabel
                                                        className="white-variant"
                                                        labelPlacement={'end'}
                                                        control={
                                                            <Checkbox
                                                                checked={noDate}
                                                                onChange={this.handleChangeNoDate}
                                                                name="date"
                                                                className="white-variant"
                                                            />
                                                        }
                                                        label={<span>Don't know the date yet</span>}
                                                    />

                                                    {process.env.REACT_APP_ENABLE_VILLAGE === 'true' && (
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="select-event-layout-container"
                                                        >
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="section-title"
                                                            >
                                                                Event layout
                                                            </span>
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="options-container"
                                                            >
                                                                <div
                                                                    onDragStart={preventDefaultDrag}
                                                                    className="single-option-container event-type-option"
                                                                >
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        fullWidth={true}
                                                                        className="field-container"
                                                                    >
                                                                        <Select
                                                                            onChange={this.handleChangeEventType}
                                                                            value={this.state.eventType}
                                                                            IconComponent={(props) => (
                                                                                <ExpandMoreRoundedIcon
                                                                                    {...props}
                                                                                    className={`material-icons ${props.className}`}
                                                                                />
                                                                            )}
                                                                        >
                                                                            <MenuItem value={'default'}>
                                                                                DEFAULT
                                                                            </MenuItem>
                                                                            <MenuItem value={'village'}>
                                                                                VILLAGE
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                                {this.state.eventType === 'village' && (
                                                                    <div
                                                                        onDragStart={preventDefaultDrag}
                                                                        className="single-option-container buildings-number-option"
                                                                    >
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            fullWidth={true}
                                                                            className="field-container"
                                                                        >
                                                                            <Select
                                                                                onChange={
                                                                                    this.handleChangeNumberOfBuildings
                                                                                }
                                                                                value={this.state.nrOfBuildings}
                                                                                IconComponent={(props) => (
                                                                                    <ExpandMoreRoundedIcon
                                                                                        {...props}
                                                                                        className={`material-icons ${props.className}`}
                                                                                    />
                                                                                )}
                                                                            >
                                                                                {numberOfBuildingsOption.map(
                                                                                    (nrOfBuildings) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                key={nrOfBuildings}
                                                                                                value={nrOfBuildings}
                                                                                            >
                                                                                                {nrOfBuildings}{' '}
                                                                                                Buildings
                                                                                            </MenuItem>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    <button
                                                        disabled={
                                                            (invalidDate && !noDate) ||
                                                            invalidStartDate ||
                                                            invalidEndDate ||
                                                            invalidName
                                                        }
                                                        className={
                                                            'register-button ' +
                                                            ((invalidDate && !noDate) ||
                                                            invalidStartDate ||
                                                            invalidEndDate
                                                                ? 'disabled'
                                                                : '') +
                                                            'create-event-btn'
                                                        }
                                                        type="submit"
                                                    >
                                                        Create event
                                                    </button>
                                                </ValidatorForm>
                                            </div>
                                            {(loadingUser || loadingEvent) && <Spinner />}
                                            {isMobile && <RegisterMenuLinks platform />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmEvent
                        open={openConfirmEvent}
                        closeConfirm={this.handleCloseDialog}
                        handleConfirm={this.createEvent}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        loadingUser: state.user.loading,
        error: state.user.error,
        loadingEvent: state.event.loading,
        userData: state.user.data,
        isMobile: state.layout.isMobile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateEvent: (eventData) => dispatch(actions.createEvent(eventData)),
        onClearError: () => dispatch(actions.clearError()),
        refreshUserData: () => dispatch(actions.autoLoginWithToken()),
        setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
        onSetPlatformMenu: () => dispatch(actions.setPlatformMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
