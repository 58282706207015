import React, {Component} from 'react';
import axios from '../../store/axios-instance';
import {connect} from 'react-redux';

class DownloadRecords extends Component {
    state = {
        recordUrl: '',
    };

    componentDidMount() {
        this.setDownloadRecordUrl();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.liveSession?._id !== this.props.liveSession?._id) {
            this.setDownloadRecordUrl();
        }
    }

    setDownloadRecordUrl = () => {
        const sessionId = this.props.liveSession?._id;
        if (sessionId) {
            this.loadSessionRecords(sessionId).then((records) => {
                // in our Zoom Session case, we will only have 1 session recording
                if (records.length) {
                    const recordUrl = records[0];
                    this.setState({recordUrl: recordUrl});
                }
            });
        }
    };

    loadSessionRecords = async (sessionId) => {
        const result = await axios.get(`/sessions/${sessionId}/records`);
        const records = result.data.recordings.map((r) => r.url);
        return records;
    };

    render() {
        const {recordUrl} = this.state;
        const {translation, defaultTranslation} = this.props;
        if(recordUrl.length === 0){
            return <span>{translation?.sessions?.processingRecord || defaultTranslation?.sessions?.processingRecord}</span>
        }
        return (
            // <div className={'download-record'}>
            <>
                {translation?.sessions?.downloadRecordingInfoText || defaultTranslation?.sessions?.downloadRecordingInfoText}
                {' '}
                <a href={recordUrl} target="_blank" rel="noreferrer">{translation?.generalText?.here || defaultTranslation?.generalText?.here}</a>
            </>
            // </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(DownloadRecords);
