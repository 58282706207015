import React, {useEffect, useState, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import axios from '../../store/axios-instance';
import SinglePollOption from './SinglePollOption';
import {preventDefaultDrag} from '../../Utils/utils';

export const SinglePoll = ({poll, userId, programId, translation}) => {
    const {question, options, finished} = poll;
    const [voteAvailable, setVoteAvailability] = useState(false);
    const [readyToCalcVotes, setReadyToCalcVotes] = useState(false);
    const [voteResults, setVoteResults] = useState(null);
    const [userVoted, setUserVoted] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const checkIfUserVoted = useCallback(() => {
        options.forEach((option) => {
            if (option.votes.includes(userId)) setUserVoted(option._id);
        });
    }, [options, userId]);

    const checkIfPollAvailible = useCallback(() => {
        if (!userVoted && !finished) {
            setVoteAvailability(true);
        } else {
            setReadyToCalcVotes(true);
            setVoteAvailability(false);
        }
    }, [finished, userVoted]);

    const calcVotes = useCallback(() => {
        const totalVotes = options.reduce((acc, option) => {
            return acc + option.votes.length;
        }, 0);
        const votes = options.map((option) => {
            if (totalVotes === 0) return 0;
            return Math.round((option.votes.length * 100) / totalVotes);
        });
        setVoteResults(votes);
    }, [options]);

    const handleSelect = (id) => {
        if (voteAvailable) setSelectedOption(id);
    };

    const submitVote = () => {
        if (!selectedOption || !voteAvailable) return false;
        axios.post(`/polls/${poll._id}/vote/${selectedOption}`, {programId});
    };

    useEffect(() => {
        checkIfUserVoted();
    }, [checkIfUserVoted, poll]);

    useEffect(() => {
        checkIfPollAvailible();
    }, [checkIfPollAvailible, userVoted]);

    useEffect(() => {
        if (voteResults || !readyToCalcVotes) return;
        calcVotes();
    }, [readyToCalcVotes, calcVotes, voteResults]);

    useEffect(() => {
        calcVotes();
    }, [poll]);

    return (
        <div onDragStart={preventDefaultDrag} className={`poll ${voteAvailable ? '-vote-availible' : ''}`}>
            <div onDragStart={preventDefaultDrag} className="poll_header">
                <p onDragStart={preventDefaultDrag} className="poll_title">
                    {question}
                </p>
                <div onDragStart={preventDefaultDrag} className="poll_status">
                    {finished ? (
                        <>
                            <CheckCircleIcon className="poll_status-icon" />
                            <span>{translation?.polls?.pollClosed || 'Poll closed'}</span>
                        </>
                    ) : (
                        userVoted && (
                            <>
                                <PlayArrowIcon className="poll_status-icon primary-color" />
                                <span>{translation?.polls?.pollProgress || 'Poll in progress'}</span>
                            </>
                        )
                    )}
                </div>
            </div>
            <div onDragStart={preventDefaultDrag}>
                {!!options.length &&
                    options.map((option, index) => (
                        <SinglePollOption
                            key={option._id}
                            option={option}
                            handleSelect={handleSelect}
                            index={index}
                            voteResults={voteResults}
                            selectedOption={selectedOption}
                            userVoted={userVoted}
                            finished={finished}
                            translation={translation}
                        />
                    ))}
                {voteAvailable && (
                    <Button 
                        variant={"contained"}
                        disabled={!selectedOption}
                        disableElevation
                        color={"secondary"}
                        size={"large"}
                        onClick={() => submitVote()}
                    >
                            {translation?.generalText.submit ? translation?.generalText.submit : 'submit'}
                    </Button>
                )}
            </div>
        </div>
    );
};
