import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import variables from '../CSS/_variables.module.scss';
import {ReactComponent as TwitterIconFooter} from '../Images/svg/twitter-footer-filled.svg';
import {ReactComponent as FacebookIconFooter} from '../Images/svg/facebook-footer-filled.svg';
import {ReactComponent as LinkedinIconFooter} from '../Images/svg/linkedin-footer-filled.svg';
import HighlightRectangleText from './HighlightRectangleText';
import {preventDefaultDrag} from '../Utils/utils';

class PlatformFooter extends Component {
    render() {
        return (
            <footer className="platform-footer-wrapper">
                <div onDragStart={preventDefaultDrag} className="platform-footer-container">
                    <div onDragStart={preventDefaultDrag} className="links-container">
                        <div onDragStart={preventDefaultDrag} className="social-buttons-wrapper">
                            <ul>
                                <li>
                                    <a
                                        href={`https://twitter.com/${variables.twitterLink}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <TwitterIconFooter fill={variables.secondary} />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={`https://www.facebook.com/${variables.facebookLink}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FacebookIconFooter fill={variables.secondary} />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={`https://www.linkedin.com/${variables.linkedInLink}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <LinkedinIconFooter fill={variables.secondary} />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div onDragStart={preventDefaultDrag} className="inner-links">
                            <ul>
                                <li>
                                    <Link to={{pathname: '/privacy-policy/'}}>Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to={{pathname: '/cookies/'}}>Cookies</Link>
                                </li>
                                <li>
                                    <Link to={{pathname: '/terms-and-conditions/'}}>Terms and Conditions</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p onDragStart={preventDefaultDrag} className="title">
                        <HighlightRectangleText>{process.env.REACT_APP_PLATFORM_NAME} brings the Wow</HighlightRectangleText>
                    </p>
                </div>
            </footer>
        );
    }
}

export default PlatformFooter;
