import React, {Component} from 'react';

export default class ProgramFilled extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7001 1.82817H16.0973C15.5765 1.335 14.8725 1.03125 14.098 1.03125H13.6848C13.3689 0.409781 12.7199 0 12 0C11.28 0 10.6311 0.409781 10.3151 1.03125H9.90092C9.12643 1.03125 8.42247 1.335 7.90166 1.82817H4.29991C3.03175 1.82817 2 2.85346 2 4.1137V21.7145C2 22.9747 3.03175 24 4.29991 24H19.7001C20.9683 24 22 22.9747 22 21.7145V4.1137C22 2.85346 20.9683 1.82817 19.7001 1.82817ZM9.90096 1.96875H10.6362C10.8483 1.96875 11.0344 1.82798 11.0908 1.62478C11.2033 1.22011 11.5772 0.937498 12 0.937498C12.4229 0.937498 12.7967 1.22011 12.9092 1.62478C12.9656 1.82798 13.1517 1.96875 13.3638 1.96875H14.098C15.1219 1.96875 15.9646 2.7539 16.0487 3.75004H7.9503C8.0344 2.7539 8.87719 1.96875 9.90096 1.96875ZM21.0567 21.7145C21.0567 22.4578 20.4481 23.0625 19.7001 23.0625H4.29991C3.55194 23.0625 2.9434 22.4578 2.9434 21.7145V4.1137C2.9434 3.3704 3.55194 2.76567 4.29991 2.76567H7.24081C7.08629 3.11779 7.00001 3.50601 7.00001 3.9141V4.21879C7.00001 4.47768 7.21119 4.68754 7.47171 4.68754H16.5273C16.7879 4.68754 16.999 4.47768 16.999 4.21879V3.9141C16.999 3.50606 16.9128 3.11779 16.7582 2.76567H19.7001C20.4481 2.76567 21.0567 3.3704 21.0567 4.1137V21.7145Z" fill="url(#gradient7)"/>
                <path d="M21.0567 21.7145C21.0567 22.4578 20.4481 23.0625 19.7001 23.0625H4.29991C3.55194 23.0625 2.9434 22.4578 2.9434 21.7145V4.1137C2.9434 3.3704 3.55194 2.76567 4.29991 2.76567H7.24081C7.08629 3.11779 7.00001 3.50601 7.00001 3.9141V4.21879C7.00001 4.47768 7.21119 4.68754 7.47171 4.68754H16.5273C16.7879 4.68754 16.999 4.47768 16.999 4.21879V3.9141C16.999 3.50606 16.9128 3.11779 16.7582 2.76567H19.7001C20.4481 2.76567 21.0567 3.3704 21.0567 4.1137V21.7145Z" fill="url(#gradient7)"/>
                <path d="M18.7926 7.125H12C11.7395 7.125 11.5283 7.33486 11.5283 7.59375C11.5283 7.85264 11.7395 8.0625 12 8.0625H18.7926C19.0531 8.0625 19.2643 7.85264 19.2643 7.59375C19.2643 7.33486 19.0531 7.125 18.7926 7.125Z" fill="white"/>
                <path d="M14.8244 9.375H12C11.7395 9.375 11.5283 9.58486 11.5283 9.84375C11.5283 10.1026 11.7395 10.3125 12 10.3125H14.8244C15.0849 10.3125 15.2961 10.1026 15.2961 9.84375C15.2961 9.58486 15.0849 9.375 14.8244 9.375Z" fill="white"/>
                <path d="M18.7926 12.1875H12C11.7395 12.1875 11.5283 12.3974 11.5283 12.6562C11.5283 12.9151 11.7395 13.125 12 13.125H18.7926C19.0531 13.125 19.2643 12.9151 19.2643 12.6562C19.2643 12.3974 19.0531 12.1875 18.7926 12.1875Z" fill="white"/>
                <path d="M14.8244 14.4375H12C11.7395 14.4375 11.5283 14.6474 11.5283 14.9062C11.5283 15.1651 11.7395 15.375 12 15.375H14.8244C15.0849 15.375 15.2961 15.1651 15.2961 14.9062C15.2961 14.6474 15.0849 14.4375 14.8244 14.4375Z" fill="white"/>
                <path d="M7.93438 18H5.41993C5.188 18 5 18.2985 5 18.6667C5 19.0349 5.188 19.3333 5.41993 19.3333H7.93438C8.16631 19.3333 8.35431 19.0349 8.35431 18.6667C8.35431 18.2985 8.16631 18 7.93438 18Z" fill="white"/>
                <path d="M18.7926 17.25H12C11.7395 17.25 11.5283 17.4599 11.5283 17.7187C11.5283 17.9776 11.7395 18.1875 12 18.1875H18.7926C19.0531 18.1875 19.2643 17.9776 19.2643 17.7187C19.2643 17.4599 19.0531 17.25 18.7926 17.25Z" fill="white"/>
                <path d="M14.8244 19.5H12C11.7395 19.5 11.5283 19.7099 11.5283 19.9688C11.5283 20.2276 11.7395 20.4375 12 20.4375H14.8244C15.0849 20.4375 15.2961 20.2276 15.2961 19.9688C15.2961 19.7099 15.0849 19.5 14.8244 19.5Z" fill="white"/>
                <path d="M9.1108 7.01229C8.9266 6.82924 8.62792 6.82924 8.44372 7.01224L6.42664 9.01675L5.80527 8.39923C5.62107 8.21623 5.32239 8.21623 5.13815 8.39923C4.95395 8.58227 4.95395 8.87908 5.13815 9.06218L6.0931 10.0112C6.18523 10.1027 6.30593 10.1485 6.42664 10.1485C6.54735 10.1485 6.66811 10.1027 6.76018 10.0112L9.1108 7.67524C9.295 7.4922 9.295 7.19538 9.1108 7.01229Z" fill="white"/>
                <path d="M7.73586 12.1875H5.4717C5.21118 12.1875 5 12.3974 5 12.6562V14.9062C5 15.1651 5.21118 15.375 5.4717 15.375H7.73586C7.99638 15.375 8.20756 15.1651 8.20756 14.9062V12.6562C8.20756 12.3974 7.99638 12.1875 7.73586 12.1875ZM7.26416 14.4375H5.9434V13.125H7.26416V14.4375Z" fill="white"/>
                <defs>
                    <linearGradient id="gradient7" x1="11.4" y1="-17.4286" x2="38.311" y2="7.2457" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                </defs>
            </svg>

        );
    }
}
