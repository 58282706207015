import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';
import {ADD_LOBBY_RESOURCE_DOCUMENT_START} from "../actions/actionTypes";

const defaultErrorMessage = 'There seems to be an error. Please try again!';

const initialState = {
    loading: false,
    error: '',
    description: '',
    links: [],
    documents: [],
}

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_LOBBY_RESOURCES_START:
            case actionTypes.UPDATE_LOBBY_RESOURCE_LINK_START:
            case actionTypes.ADD_LOBBY_RESOURCE_DOCUMENT_START:
            case actionTypes.UPDATE_LOBBY_RESOURCE_DESCRIPTION_START:
            case actionTypes.DELETE_LOBBY_RESOURCE_DOCUMENT_START:
                draft.error = '';
                draft.loading = true;
                break;

            case actionTypes.GET_LOBBY_RESOURCES_FAIL:
            case actionTypes.UPDATE_LOBBY_RESOURCE_LINK_FAIL:
            case actionTypes.UPDATE_LOBBY_RESOURCE_DESCRIPTION_FAIL:
            case actionTypes.ADD_LOBBY_RESOURCE_DOCUMENT_FAIL:
            case actionTypes.DELETE_LOBBY_RESOURCE_DOCUMENT_FAIL:
                draft.error = defaultErrorMessage;
                draft.loading = false;
                break;

            case actionTypes.GET_LOBBY_RESOURCES_SUCCESS:
                const {description, documents, links} = action.payload;
                draft.description = description;
                draft.documents = documents;
                draft.links = links;
                draft.loading = false;
                break;
            case actionTypes.UPDATE_LOBBY_RESOURCE_DESCRIPTION_SUCCESS:
                draft.description = action.payload;
                draft.loading = false;
                break;
            case actionTypes.UPDATE_LOBBY_RESOURCE_LINK_SUCCESS:
                draft.links = action.payload;
                draft.loading = false;
                break;
            case actionTypes.ADD_LOBBY_RESOURCE_DOCUMENT_SUCCESS:
                draft.documents = action.payload;
                draft.loading = false;
                break;
            case actionTypes.DELETE_LOBBY_RESOURCE_DOCUMENT_SUCCESS:
                draft.documents = action.payload;
                draft.loading = false;
                break;
            default:
                break;
        }
    })

export default reducer;