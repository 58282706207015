import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as Arrow} from '../../Images/svg/showfloor-arrow-image.svg';
import {preventDefaultDrag} from '../../Utils/utils';

class AuditoriumNavigation extends React.Component {
    getPreviousAuditoriumIndex = () => {
        const {auditoriums, auditoriumRoomIndex} = this.props;
        let previousAuditoriumIndex = auditoriumRoomIndex - 1;
        if (auditoriumRoomIndex === 0) {
            previousAuditoriumIndex = auditoriums.length - 1;
        }
        return previousAuditoriumIndex;
    };

    getNextAuditoriumIndex = () => {
        const {auditoriums, auditoriumRoomIndex} = this.props;
        let nextAuditoriumIndex = auditoriumRoomIndex + 1;
        if (auditoriumRoomIndex === auditoriums.length - 1) {
            nextAuditoriumIndex = 0;
        }
        return nextAuditoriumIndex;
    };

    render() {
        const {timeSlot, setActiveAuditoriumIndex, auditoriums, videoPlayer} = this.props;
        return (
            <div
                className={
                    'auditoriums-navigation ' +
                    (timeSlot?.video ? 'has-booth-wall' : 'no-timeslot') +
                    (videoPlayer ? ' active-on-player' : '')
                }
            >
                <div
                    onDragStart={preventDefaultDrag}
                    className="button previous"
                >
                    <div onDragStart={preventDefaultDrag} className="button-container">
                        <div onDragStart={preventDefaultDrag} className="details" onClick={setActiveAuditoriumIndex(this.getPreviousAuditoriumIndex())}>
                            <Arrow stroke={colors.primary} />
                        </div>
                        <div onDragStart={preventDefaultDrag} className="auditorium-name">
                            <p>{auditoriums[this.getPreviousAuditoriumIndex()].name}</p>
                        </div>
                    </div>
                </div>
                <div
                    onDragStart={preventDefaultDrag}
                    className="button next"
                >
                    <div onDragStart={preventDefaultDrag} className="button-container">
                        <div onDragStart={preventDefaultDrag} className="details" onClick={setActiveAuditoriumIndex(this.getNextAuditoriumIndex())}>
                            <Arrow stroke={colors.primary} />
                        </div>
                        <div onDragStart={preventDefaultDrag} className="auditorium-name">
                            <p>{auditoriums[this.getNextAuditoriumIndex()].name}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditoriumNavigation));
