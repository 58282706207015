import React from 'react';
import CustomField from './CustomField';
import AddFieldDialog from './AddFieldDialog';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ConfirmDelete from 'Dialogs/ConfirmDelete';
import axios from 'store/axios-instance';
import * as actions from 'store/actions';
import {preventDefaultDrag} from 'Utils/utils';
import {DeleteOutlineOutlined} from '@material-ui/icons';
import Spinner from 'SmallLayoutComponents/Spinner';
import {ReactComponent as DragDrop} from '../ParticipantRegistration/dnd-icon.svg';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import classnames from 'classnames';
import './CustomFieldsStyles.scss';
import SnackbarGlobal from '../../../../SmallLayoutComponents/Snackbars/SnackbarGlobal';
import CheckIcon from '@material-ui/icons/Check';

class CustomFields extends React.Component {
    state = {
        currentFieldIndex: null,
        editedFieldId: null,
        openAddFieldDialog: false,
        openDeleteFieldDialog: false,
        roleRegistrationFieldsString: this.props.role + 'RegistrationFields',
        deleteLoading: false,
        fields: [],
        successSnackbarOpened: false,
    };

    componentDidMount() {
        const {registrationFields} = this.props;
        this.setState({
            fields: registrationFields?.data[this.state?.roleRegistrationFieldsString] ?? [],
        });
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.registrationFields.data[this.state.roleRegistrationFieldsString]?.length <
            this.props.registrationFields.data[this.state.roleRegistrationFieldsString]?.length
        ) {
            // we just added a new custom field
            this.setState({
                currentFieldIndex:
                    this.props.registrationFields.data[this.state.roleRegistrationFieldsString]?.length - 1,
            });
        }
        if (
            this.props.registrationFields.data[this.state.roleRegistrationFieldsString] !==
            prevProps.registrationFields.data[this.state.roleRegistrationFieldsString]
        ) {
            this.setState({
                fields: this.props.registrationFields.data[this.state.roleRegistrationFieldsString],
            });
        }
    }

    handleOpenAddFieldDialog = () =>
        this.setState({
            openAddFieldDialog: true,
        });

    handleOpenDeleteFieldDialog = (fieldId) => () =>
        this.setState({
            editedFieldId: fieldId,
            openDeleteFieldDialog: true,
        });

    handleCloseDialogs = () =>
        this.setState({
            editedFieldId: null,
            openAddFieldDialog: false,
            openDeleteFieldDialog: false,
        });

    setFieldIndex = (fieldIndex) => () => {
        let newIndex = this.state.currentFieldIndex === fieldIndex ? null : fieldIndex;
        this.setState({
            currentFieldIndex: newIndex,
        });
    };

    handleDeleteField = () => {
        this.setState({deleteLoading: true});
        const {eventId} = this.props;
        axios({
            method: 'delete',
            url: `/event/v2/${eventId}/${this.props.role}-custom-fields/${this.state.editedFieldId}`,
        })
            .then((response) => {
                let eventRegistrationFields = response.data.data.eventRegistrationFields;
                this.props.getEventRegistrationFieldsSuccess(eventRegistrationFields);
                this.handleCloseDialogs();
                this.props.handleOpenSuccessSnackbar();
                this.setState({deleteLoading: false});
            })
            .catch(() => {
                this.props.handleOpenErrorSnackbar();
                this.setState({deleteLoading: false});
            });
    };

    getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the localSpeakers look a bit nicer
        userSelect: 'none',
        margin: `0 0 25px 0`,
        ...draggableStyle,
    });
    handleCloseSnackbar = () => this.setState({successSnackbarOpened: false});

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    setOrderOfTheField = async (field) => {
        const {eventId} = this.props;
        const order = {
            index: field.order,
            label: field.label,
            type: field.type,
        };
        const {data} = await axios.put(`/event/v2/${eventId}/${this.props.role}-custom-fields/${field._id}`, order);

        if (data?.success) {
            this.props.getEventRegistrationFieldsSuccess(data.data.eventRegistrationFields);
            this.setState({successSnackbarOpened: true});
        }
    };

    onDragEnd = ({destination, source}) => {
        if (!destination) {
            return;
        }

        const localFieldsReordered = this.reorder(this.state.fields, source.index, destination.index);

        let reOrderedList = localFieldsReordered.map((item, index) => {
            return {...item, order: index};
        });

        this.setState(
            {
                fields: reOrderedList,
            },
            () => {
                //find which field was moved
                const field = this.state.fields.find((item, index) => index === destination.index);

                this.setOrderOfTheField(field);
            }
        );
    };

    filterType = (type) => {
        switch (type) {
            case 'text':
                return 'Text input';
            case 'select':
                return 'Dropdown menu';
            case 'radio':
                return 'Radio buttons';
            case 'checkbox':
                return 'Checkboxes';
            default:
                return 'Text';
        }
    };

    render() {
        const {openAddFieldDialog, openDeleteFieldDialog, deleteLoading, fields} = this.state;

        if (deleteLoading) {
            return <Spinner />;
        }

        return (
            <>
                <h4 className="advanced-options-title">
                    CUSTOM FIELDS
                    <Button type="button" onClick={this.handleOpenAddFieldDialog} startIcon={<AddIcon />}>
                        ADD
                    </Button>
                </h4>
                {fields?.length > 0 ? (
                    <>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {fields.map((field, index) => {
                                            const isActiveField = index === this.state.currentFieldIndex;

                                            return (
                                                <Draggable key={field._id} draggableId={field._id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            style={this.getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            {' '}
                                                            <Accordion
                                                                expanded={isActiveField}
                                                                onChange={this.setFieldIndex(index)}
                                                                className={
                                                                    'advanced-options-container field-accordion dnd-support ' +
                                                                    (isActiveField ? 'active' : '')
                                                                }
                                                                key={field._id}
                                                            >
                                                                <AccordionSummary tabIndex="-1" className="summary">
                                                                    <div
                                                                        onDragStart={preventDefaultDrag}
                                                                        className="field-title-details"
                                                                    >
                                                                        {!isActiveField && (
                                                                            <span
                                                                                className={classnames(
                                                                                    'dragDrop-element',
                                                                                    {
                                                                                        disabled:
                                                                                            this.state
                                                                                                .currentFieldIndex !==
                                                                                                null ||
                                                                                            fields.length <= 1,
                                                                                    }
                                                                                )}
                                                                                {...{
                                                                                    ...provided.dragHandleProps,
                                                                                    tabIndex: -1,
                                                                                }}
                                                                            >
                                                                                <DragDrop />
                                                                            </span>
                                                                        )}
                                                                        <p
                                                                            onDragStart={preventDefaultDrag}
                                                                            className={classnames('title', {
                                                                                'dnd-helper': !isActiveField,
                                                                            })}
                                                                        >
                                                                            {field.label}
                                                                        </p>

                                                                        {!isActiveField && (
                                                                            <div className={`type-container-dnd`}>
                                                                                <div className={`type-dnd`}>
                                                                                    <span>Type:</span>
                                                                                    <span>
                                                                                        {this.filterType(field?.type)}
                                                                                    </span>
                                                                                </div>
                                                                                {field?.type !== 'text' && (
                                                                                    <div className={`length-dnd`}>
                                                                                        <span>Options:</span>
                                                                                        <span>
                                                                                            {' '}
                                                                                            {field.options.length}
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                        {isActiveField ? (
                                                                            <div
                                                                                onDragStart={preventDefaultDrag}
                                                                                className="expand-label"
                                                                            >
                                                                                <Button
                                                                                    type="button"
                                                                                    onClick={this.handleOpenDeleteFieldDialog(
                                                                                        field._id
                                                                                    )}
                                                                                    startIcon={
                                                                                        <DeleteOutlineOutlined />
                                                                                    }
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                                <Button
                                                                                    type="label"
                                                                                    startIcon={<ExpandLessIcon />}
                                                                                >
                                                                                    COLLAPSE
                                                                                </Button>
                                                                            </div>
                                                                        ) : (
                                                                            <Button
                                                                                type="label"
                                                                                startIcon={<ExpandMoreIcon />}
                                                                            >
                                                                                EXPAND
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                </AccordionSummary>
                                                                <AccordionDetails style={{padding: 0}}>
                                                                    <div
                                                                        onDragStart={preventDefaultDrag}
                                                                        className="field-wrapper"
                                                                    >
                                                                        <CustomField
                                                                            field={field}
                                                                            fieldIndex={index}
                                                                            handleOpenSuccessSnackbar={
                                                                                this.props.handleOpenSuccessSnackbar
                                                                            }
                                                                            handleOpenErrorSnackbar={
                                                                                this.props.handleOpenErrorSnackbar
                                                                            }
                                                                            role={this.props.role}
                                                                        />
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                ) : (
                    <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                        <p>{this.props.noCustomFieldsMessage}</p>
                    </div>
                )}
                <div onDragStart={preventDefaultDrag} className="actions-container">
                    <Button type="button" onClick={this.handleOpenAddFieldDialog} startIcon={<AddIcon />}>
                        ADD
                    </Button>
                </div>

                {openAddFieldDialog && (
                    <AddFieldDialog
                        open={openAddFieldDialog}
                        closeDialog={this.handleCloseDialogs}
                        role={this.props.role}
                        addFieldMessage={this.props.addFieldMessage}
                    />
                )}
                {openDeleteFieldDialog && (
                    <ConfirmDelete
                        open={openDeleteFieldDialog}
                        closeConfirmDeleteDialog={this.handleCloseDialogs}
                        dialogTitle={'Delete Custom Field'}
                        dialogDescription={'Are you sure you want to delete this custom field?'}
                        dialogConfirmButtonLabel={'Delete'}
                        handleConfirmDelete={this.handleDeleteField}
                        loading={deleteLoading}
                    />
                )}
                <SnackbarGlobal
                    snackbarOpen={this.state.successSnackbarOpened}
                    handleCloseSnackbar={this.handleCloseSnackbar}
                    message={`Changes successfully saved.`}
                    icon={<CheckIcon />}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        registrationFields: state.event.registrationFields,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventRegistrationFieldsSuccess: (registrationFields) =>
            dispatch(actions.getEventRegistrationFieldsSuccess(registrationFields)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);
