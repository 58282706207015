import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getHPBackgroundImageURL, preventDefaultDrag} from '../../Utils/utils';
import BackgroundImage from '../../Images/background.jpg';

class HomepageBackgroundImage extends PureComponent {
    render() {
        const {eventInfo, isLargeScreen, isTablet, isMobile} = this.props;
        let backgroundDesktop = eventInfo.brandingData.homepageBackground;
        let backgroundTablet = eventInfo.brandingData.homepageBackgroundTablet;
        let backgroundMobile = eventInfo.brandingData.homepageBackgroundMobile;

        let overlayColor = {};
        if (eventInfo?.brandingData?.overlayColorHomepage) {
            overlayColor.background = eventInfo.brandingData?.overlayColorHomepage;
        }

        return (
            <div onDragStart={preventDefaultDrag} className="background-container">
                {isLargeScreen && backgroundDesktop ? (
                    <img
                        src={getHPBackgroundImageURL(eventInfo, 'homepageBackground')}
                        className="background"
                        alt="background"
                    />
                ) : (
                    <>
                        {isTablet && backgroundTablet ? (
                            <img
                                src={getHPBackgroundImageURL(eventInfo, 'homepageBackgroundTablet')}
                                className="background"
                                alt="background"
                            />
                        ) : (
                            <>
                                {isMobile && backgroundMobile ? (
                                    <img
                                        src={getHPBackgroundImageURL(eventInfo, 'homepageBackgroundMobile')}
                                        className="background"
                                        alt="background"
                                    />
                                ) : (
                                    <img
                                        draggable="false"
                                        src={BackgroundImage}
                                        className="background"
                                        alt="background"
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
                <div onDragStart={preventDefaultDrag} className="overlay" style={overlayColor} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventInfo: state.event.eventInfo,
        isLargeScreen: state.layout.isLargeScreen,
        isTablet: state.layout.isTablet,
        isMobile: state.layout.isMobile,
    };
};

export default connect(mapStateToProps)(HomepageBackgroundImage);

