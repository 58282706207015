import {useDispatch} from 'react-redux';
import './infoTabButtonStyles.scss';
import React, {useRef} from 'react';
import {sideNavSeeInfoTab} from 'store/actions/userActions';
import {usePlatformTranslation} from "services/hooks";

const InfoTabButton = () => {
    const dispatch = useDispatch();
    const buttonRef = useRef(null);

    const eventInfoText = usePlatformTranslation((state) => state.sideMenu.eventInfo);

    const handleInfoButtonClicked = () => {
        dispatch(sideNavSeeInfoTab());
    };

    return (
        <div
            id="info-tab-button"
            ref={buttonRef}
            className='info-tab-button'
            onClick={handleInfoButtonClicked}
        >
            {eventInfoText}
        </div>
    );
};

export default InfoTabButton;
