import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import '../CSS/networking.scss';
import SingleRepresentative from './SingleRepresentative';
import ColoredScrollbars from '../SmallLayoutComponents/ColoredScrollbars';
import {preventDefaultDrag} from '../Utils/utils';
import EventInfo from "../Components/SideMenu/EventInfo";

class InfoTabMobile extends PureComponent {
    checkIfIsOnline = (eventUser) => {
        const {onlineUsersObject} = this.props;
        return onlineUsersObject[eventUser.user._id];
    };

    render() {
        const {event} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} id="info-tab" className="networking-container">
                <div onDragStart={preventDefaultDrag} className="participants-page">
                    <div>
                        <div onDragStart={preventDefaultDrag} className="custom-scroll-container networking-wrapper info-tab-mobile">
                            <ul className="representative-list">
                                {event.representatives.map((user) => {
                                    const isOnline = this.checkIfIsOnline(user);
                                    return <SingleRepresentative key={user._id} user={user} isOnline={isOnline} />;
                                })}
                            </ul>
                            <EventInfo />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        onlineUsersObject: state.onlineUsers.onlineUsersObject,
    };
};

export default connect(mapStateToProps)(InfoTabMobile);
