import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {Switch} from '@material-ui/core';
import SelectTimezone from '../Topbar/SelectTimezone';
import {isMobile, isTablet, isDesktop, isMobileOnly} from 'react-device-detect';
import {ReactComponent as Filters} from './helpers/svgs/show-filter.svg';
import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {usePlatformTranslation} from 'services/hooks';

const TopBarAgenda = ({
    filterTypes,
    handleChangeFilter,
    pastSlots,
    setPastSlotsState,
    setActiveTimeslot,
    setUserTimezone,
    firstDateMobile,
}) => {
    //LOCAL STATES
    const [isTabsActive, setIsTabsActive] = useState(false);

    //REDUX
    const platformLanguage = useSelector((state) => state.languages?.platformLanguage);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    /*translations*/
    const pastSlotsTranslation = usePlatformTranslation((state) => state?.agendaTranslations?.pastSlots);
    /* end translations*/

    const setFiltersActive = () => {
        setIsTabsActive((prevState) => !prevState);
    };
    const title = useCallback(
        (label) => {
            const {
                all: translationAll = '',
                meetings: translationMeetings = '',
                sessions: translationSessions = '',
            } = translation.agendaTranslations || {};

            const {
                all: defaultAll = '',
                meetings: defaultMeetings = '',
                sessions: defaultSessions = '',
            } = defaultTranslation.agendaTranslations || {};

            switch (label) {
                case 'all':
                    return translationAll || defaultAll;
                case 'meetings':
                    return translationMeetings || defaultMeetings;
                case 'sessions':
                    return translationSessions || defaultSessions;
                default:
                    return '';
            }
        },
        [defaultTranslation.agendaTranslations, translation.agendaTranslations]
    );

    return (
        <div className="top-bar-my-agenda">
            {!isMobile || isTablet ? (
                <div className="radio-groups-my-agenda">
                    <RadioGroup tabIndex="-1" aria-label="role" name="filterRole" onChange={handleChangeFilter}>
                        {filterTypes.map((filterOptions) => {
                            return (
                                <FormControlLabel
                                    tabIndex="0"
                                    name={filterOptions.label}
                                    key={filterOptions.label}
                                    checked={filterOptions.value}
                                    control={<Radio tabIndex="-1" color={'secondary'} />}
                                    label={title(filterOptions.label)}
                                />
                            );
                        })}
                    </RadioGroup>
                </div>
            ) : (
                <div className="mobile-filters-container">
                    <button
                        onClick={setFiltersActive}
                        className={`filter-button-agenda ${isTabsActive ? 'filters-active-agenda' : ''}`}
                    >
                        <Filters />
                    </button>

                    {isTabsActive && (
                        <div className="filters-agenda">
                            <FormControlLabel
                                label={`${pastSlotsTranslation}`}
                                control={
                                    <Switch
                                        tabIndex="-1"
                                        color="secondary"
                                        name="pastEvents"
                                        onChange={setPastSlotsState}
                                        checked={pastSlots}
                                    />
                                }
                            />
                            <div className="radio-group-filters">
                                <div className={`radio-groups-my-agenda ${isMobile ? 'mobile-support-session' : ''}`}>
                                    <RadioGroup
                                        tabIndex="-1"
                                        aria-label="role"
                                        name="filterRole"
                                        onChange={handleChangeFilter}
                                    >
                                        {filterTypes.map((filterOptions) => {
                                            return (
                                                <FormControlLabel
                                                    tabIndex="0"
                                                    name={filterOptions.label}
                                                    key={filterOptions.label}
                                                    checked={filterOptions.value}
                                                    control={<Radio tabIndex="-1" color={'secondary'} />}
                                                    label={filterOptions.label}
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {isMobileOnly && (
                <h3 className={'mobile-first-date'}>
                    {firstDateMobile && firstDateMobile[0]?.toUpperCase() + firstDateMobile?.slice(1)}
                </h3>
            )}
            <div className="past-sessions-and-gmt">
                {(isDesktop || isTablet) && (
                    <FormControlLabel
                        label={`${pastSlotsTranslation}`}
                        className={`${platformLanguage === 'ar' ? 'ar-support' : ''}`}
                        control={
                            <Switch
                                tabIndex="-1"
                                color="secondary"
                                name="pastEvents"
                                onChange={setPastSlotsState}
                                checked={pastSlots}
                            />
                        }
                    />
                )}

                <div className="select-timezone-wrapper">
                    <SelectTimezone setActiveTimeslot={setActiveTimeslot} setUserTimezone={setUserTimezone} />
                </div>
            </div>
        </div>
    );
};

export default TopBarAgenda;
