import React from 'react';
import {preventDefaultDrag} from '../Utils/utils';
import {EXHIBITOR_TYPES} from '../Utils/constants/shared';

class BoothFullPreview extends React.Component {
    getBoothWidthString = () => {
        let heightResolution = window.screen.height * window.devicePixelRatio;
        let widthString = '';
        switch (true) {
            case heightResolution < 769:
                widthString = '_768.jpg';
                break;
            case heightResolution < 901:
                widthString = '_900.jpg';
                break;
            case heightResolution < 1081:
                widthString = '_1080.jpg';
                break;
            case heightResolution < 1201:
                widthString = '_1200.jpg';
                break;
            case heightResolution < 1441:
                widthString = '_1440.jpg';
                break;
            case heightResolution < 2161:
                widthString = '_2160.jpg';
                break;
            default:
                widthString = '.jpg';
        }
        return widthString;
    };

    getCustomBoothWidthString = () => {
        let heightResolution = window.screen.height * window.devicePixelRatio;
        let widthString = '';
        switch (true) {
            case heightResolution < 769:
                widthString = 'resize768';
                break;
            case heightResolution < 901:
                widthString = 'resize900';
                break;
            case heightResolution < 1081:
                widthString = 'resize1080';
                break;
            case heightResolution < 1201:
                widthString = 'resize1200';
                break;
            case heightResolution < 1441:
                widthString = 'resize1440';
                break;
            case heightResolution < 2161:
                widthString = 'resize2160';
                break;
            default:
                widthString = 'original';
        }
        return widthString;
    };

    getRenderImageUrl = () => {
        const {exhibitor} = this.props;
        let imageUrl = '';

        const boothWidthString = this.getBoothWidthString();
        const customBoothWidthString = this.getCustomBoothWidthString();
        const boothType = exhibitor.booth.boothType;

        // There are 6 types of default booths 'v1', 'v2', 'v3', 's1', 's2', 's3'  (v for company Exhibitors,  s for scholar/poster/study exhibitors)
        // There is also 1 BoothNotReady version that has 'c1'

        // if the booth is custom, the img is saved in the exhibitor folder
        // if the booth is one of the defaults, we load it from the renders folder

        // the string names of the custom has 'resize1440' at the end of the string
        // the string names of the default have '_1440' at the end

        // this is why we used 2 different functions getCustomBoothWidthString() and getBoothWidthString
        // note to backend developers, we should standardize this

        if (boothType === 'custom') {
            if (exhibitor.booth.customBoothImages && exhibitor.booth.customBoothImages.original) {
                imageUrl = `${exhibitor.filesUrl}${exhibitor.booth.customBoothImages[customBoothWidthString]}`;
            } else {
                if (exhibitor?.type === EXHIBITOR_TYPES.scholar) {
                    imageUrl = `${process.env.REACT_APP_RENDERS_FOLDER}showfloor/boothFront-c2${boothWidthString}`;
                } else {
                    imageUrl = `${process.env.REACT_APP_RENDERS_FOLDER}showfloor/boothFront-c1${boothWidthString}`;
                }
            }
        } else {
            imageUrl = `${process.env.REACT_APP_RENDERS_FOLDER}showfloor/boothFront-${boothType}${boothWidthString}`;
        }

        return imageUrl;
    };

    render() {
        return (
            <div onDragStart={preventDefaultDrag} className="booth-preview-container">
                <img
                    draggable="false"
                    onDragStart={preventDefaultDrag}
                    src={this.getRenderImageUrl()}
                    alt={'booth render'}
                />
            </div>
        );
    }
}

export default BoothFullPreview;
