import {ReactComponent as Valid} from '../images/counter_checked.svg';
import {ReactComponent as Invalid} from '../images/counter_unchecked.svg';
import {useSelector} from 'react-redux';

const StrongPassword = ({strongPassword}) => {
    //redux
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);

    const strongPasswordLabelList = [
        translation.newLoginRegistrations.minimum8Characters ||
            defaultTranslation.newLoginRegistrations.minimum8Characters,
        translation.newLoginRegistrations.aLowerCaseLetter || defaultTranslation.newLoginRegistrations.aLowerCaseLetter,
        translation.newLoginRegistrations.anUppercaseLetter ||
            defaultTranslation.newLoginRegistrations.anUppercaseLetter,
        translation.newLoginRegistrations.aNumber || defaultTranslation.newLoginRegistrations.aNumber,
        translation.newLoginRegistrations.aSpecialCharacter ||
            defaultTranslation.newLoginRegistrations.aSpecialCharacter,
    ];
    return (
        <>
            <h3 className={`strong-password-title`}>Password must contain:</h3>
            <ul className={`validation-list`}>
                {Object.values(strongPassword).map((value, index) => {
                    return (
                        <li key={index} className={`password-validator ${value ? 'valid' : ''}`}>
                            {value ? <Valid /> : <Invalid />} {strongPasswordLabelList[index]}
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
export default StrongPassword;
