import React, {Component} from 'react';

export default class ChatsFilled extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.6288 1.01405L7.972 0.986084C6.664 0.986084 5.6 2.04871 5.6 3.35503V5.40198L2.372 5.40877C1.064 5.40877 0 6.4714 0 7.77772V16.2232C0 17.5295 1.064 18.5918 2.372 18.5918H4.8V22.5866C4.8 22.7516 4.9016 22.8998 5.056 22.9593C5.1028 22.9773 5.1516 22.9861 5.2 22.9861C5.3104 22.9861 5.4188 22.9401 5.496 22.8555L9.3776 18.591L16.028 18.5638C17.336 18.5638 18.4 17.5012 18.4 16.1952V16.1501L20.504 18.4615C20.5812 18.5462 20.6896 18.5922 20.8 18.5922C20.8484 18.5922 20.8972 18.5834 20.944 18.5654C21.0984 18.5059 21.2 18.3577 21.2 18.1927V14.1978H21.628C22.936 14.1978 24 13.1352 24 11.8293V3.38299C24 2.07708 22.9364 1.01445 21.6288 1.01405ZM17.6 16.1949C17.6 17.0605 16.8948 17.7644 16.0264 17.7644L9.1984 17.7924C9.0864 17.7928 8.9792 17.8403 8.904 17.923L5.6 21.5531V18.1919C5.6 17.9714 5.4212 17.7924 5.2 17.7924H2.372C1.5052 17.7924 0.8 17.0881 0.8 16.2228V7.77732C0.8 6.91163 1.5052 6.20734 2.3728 6.20734L6 6.19975C5.9996 6.19975 6.0004 6.19975 6 6.19975L16.028 6.17898C16.8948 6.17898 17.6 6.88327 17.6 7.74895V15.1158V16.1949ZM23.2 11.8285C23.2 12.6942 22.4948 13.3981 21.628 13.3981H20.8C20.5788 13.3981 20.4 13.577 20.4 13.7975V17.1588L18.4 14.9616V7.74935C18.4 6.44304 17.336 5.38041 16.0272 5.38041L6.4 5.39999V3.35463C6.4 2.48894 7.1052 1.78465 7.9712 1.78465L21.6276 1.81262C21.6272 1.81262 21.628 1.81262 21.6276 1.81262C22.4944 1.81262 23.2004 2.51691 23.2004 3.38259L23.2 11.8285Z" fill="url(#gradient6)"/>
                <path d="M17.6 16.1949C17.6 17.0605 16.8948 17.7644 16.0264 17.7644L9.1984 17.7924C9.0864 17.7928 8.9792 17.8403 8.904 17.923L5.6 21.5531V18.1919C5.6 17.9714 5.4212 17.7924 5.2 17.7924H2.372C1.5052 17.7924 0.8 17.0881 0.8 16.2228V7.77732C0.8 6.91163 1.5052 6.20734 2.3728 6.20734L6 6.19975L16.028 6.17898C16.8948 6.17898 17.6 6.88327 17.6 7.74895V15.1158V16.1949Z" fill="url(#gradient6)"/>
                <path d="M4 13.5C4 13.2239 4.22386 13 4.5 13H13.5C13.7761 13 14 13.2239 14 13.5C14 13.7761 13.7761 14 13.5 14H4.5C4.22386 14 4 13.7761 4 13.5Z" fill="white"/>
                <path d="M4 10.5C4 10.2239 4.22386 10 4.5 10H13.5C13.7761 10 14 10.2239 14 10.5C14 10.7761 13.7761 11 13.5 11H4.5C4.22386 11 4 10.7761 4 10.5Z" fill="white"/>
                <defs>
                    <linearGradient id="gradient6" x1="11.28" y1="-14.9901" x2="35.6343" y2="14.2421" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                </defs>
            </svg>
        );
    }
}
