import React from 'react';
import {
    connectToWall,
    disconnectFromWall,
    postReceived,
    postDeleted,
    commentReceived,
    commentDeleted,
    postLikeReceived,
    postUnlikeReceived,
    liveWallPostsDeleted,
    pinPost,
    wallPostUpdated,
    wallCommentUpdated,
    wallPostApproved,
    wallPostCommentApproved,
    reloadWall,
    eventUsersRemovedParticipant,
    deregisterPostReceived,
    deregisterPostDeleted,
    deregisterCommentReceived,
    deregisterCommentDeleted,
    deregisterPostLikeReceived,
    deregisterPostUnlikeReceived,
    deregisterPinPost,
    deregisterWallPostUpdated,
    deregisterWallCommentUpdated,
    deregisterWallPostApproved,
    deregisterWallPostCommentApproved,
} from '../../Api/socketApi';
import axios from '../../store/axios-instance';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import PostsList from './PostsList';
import DownloadPosts from './DownloadPosts';
import Spinner from '../../SmallLayoutComponents/Spinner';
import {Scrollbars} from 'react-custom-scrollbars';
import PdfPreview from '../../Pages/Showfloor/Booth/PdfPreview';
import {checkBoothOwnerOrRepresentative, preventDefaultDrag} from '../../Utils/utils';
import Confirm from '../../Dialogs/Confirm';
import ConfirmDelete from '../../Dialogs/ConfirmDelete';
import '../../CSS/wall.scss';
import colors from '../../CSS/_variables.module.scss';
import EmptyWall from '../../Images/svg/EmptyWall';
import * as actions from '../../store/actions';
import InfoIcon from '@material-ui/icons/Info';
import {isMobileSafari, isIOS} from 'react-device-detect';
import './WallStyles.scss';
import AddEditPostInput from './AddEditPostInput';
import cloneDeep from 'lodash/cloneDeep';
import Button from "@material-ui/core/Button";

class Wall extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timestamp: 'no timestamp yet',
            posts: [],
            sortedPosts: [],
            showSortedPosts: false,
            editedPostInputValue: '',
            loadingWallPosts: true, // on componentDidMount we always fetch the wall
            currentWallIdForPostDisplayed: '',
            showingPosts: 20,
            openPinDialog: false,
            editedPostID: null,
            showActions: null,
            openDeleteDialog: false,
            deletePostId: null,
            ranGetWall: false,
            isNotAbleToPost: false,
            postEditMode: false,
            isInputFocused: false,
        };
        this.wallScrollbar = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        // connect to wall
        if (this.props.sideNavigationDetails.activeWallId && this.props.userId) {
            connectToWall(this.props.sideNavigationDetails.activeWallId, this.props.userId);
        }

        // get the Wall Posts after 200ms
        // in componentDidUpdate we sometimes allready getWallPosts
        // so we should only run getWallPosts in componentDidMount
        // only if this.state.ranGetWall didn't allready ran in componentDidUpdate
        // this is a "hack to fast solve the problem"
        // we need to implement context
        // or send the activeWallId from each component where we need the Wall displayed
        this.timeoutId = setTimeout(() => {
            if (this.props.sideNavigationDetails.activeWallId && !this.state.ranGetWall) {
                this.onGetWallPosts(this.props.sideNavigationDetails.activeWallId);
            }
        }, 200);

        // 1. the first socket method below works for when you are in the lobby watching the wall as organizer
        // and you unregister your event participant profile from another window (realtime)
        // 2. the second method below that uses the history.push() method with passed boolean variable
        // works for when you're the organizer and you unregister your event participant profile from
        // the same window > My Account and you're redirected to the lobby / wall (redirect)

        // if organizer with a participant profile unregisters from event
        // get users again in order to refresh wall posts and avoid organizer's blank avatar for pinned post and unpinned posts from disappearing
        // https://trello.com/c/H5qqrjAn/5124-bug-there-is-a-white-avatar-and-name-on-the-pinned-post-when-org-is-unregistered
        eventUsersRemovedParticipant((err, data) => {
            // if socket data !== null and removed participant profile isOrganizer -> fetch getUsers
            if (data !== null && data.isOrganizer) {
                this.setState({loadingWallPosts: true});
                this.props.onGetUsers(this.props.eventId).then(() => this.setState({loadingWallPosts: false}));
            }
        });
        // also when unregistering from My Account as organizer, the isUnregisteredOrganizer boolean is sent via history.push()
        // so that I can getUsers when redirected to lobby after event participant profile was deleted
        if (this.props.eventRoles?.isOrganizer && this.props.location?.state?.isUnregisteredOrganizer !== undefined) {
            this.setState({loadingWallPosts: true});
            // clear history boolean state after mount
            this.props.history.replace({state: {}});
            this.props.onGetUsers(this.props.eventId).then(() => this.setState({loadingWallPosts: false}));
        }

        // get edited post from socket, map through current posts and update the array
        // with the newly edited post
        // added the updatedAt key w/ a random value (doesn't matter, I'm just looking for the key to be there)
        // in order to display the • Edited status right away
        // without needing to re-fetch all posts
        wallPostUpdated((err, data) => {
            if(this._isMounted){
                let updatedPosts = cloneDeep(this.state.posts);
                let updatedNewPosts = updatedPosts.map((x) =>
                    x._id === data.postId ? {...x, text: data.post, updatedAt: 'instantEdited'} : x
                );
                this.setState({posts: updatedNewPosts});
            }
        });

        // get edited comment from socket, extract index of post,
        // map through current posts and update the comments array of the post
        // with the newly edited comment
        //
        // added the updatedAt key w/ a random value (doesn't matter, I'm just looking for the key to be there)
        // in order to display the • Edited status right away
        // without needing to re-fetch all posts
        wallCommentUpdated((err, data) => {
            if(this._isMounted){
                let updatedPosts = cloneDeep(this.state.posts);
                let indexOfPost = updatedPosts.findIndex((post) => post._id === data.postId);
                if (indexOfPost !== -1) {
                    let updatedComments = updatedPosts[indexOfPost].comments.map((x) =>
                        x._id === data.commentId ? {...x, text: data.comment, updatedAt: 'instantEdited'} : x
                    );
                    updatedPosts[indexOfPost].comments = updatedComments;
                    this.setState({posts: updatedPosts});
                }
            }
        });

        postReceived((err, data) => {
            if(this._isMounted){
                const updatedPosts = this.state.posts.concat([data.post]);
                const showingPosts = this.state.showingPosts + 1;
                this.setState({posts: updatedPosts, showingPosts: showingPosts});
                if (this.state.showSortedPosts) {
                    this.sortPosts();
                }
            }
        });

        postDeleted((err, data) => {
            if(this._isMounted){
                const updatedPosts = this.state.posts.filter((post) => post._id !== data.postId);
                let showingPosts = this.state.showingPosts - 1;
                this.setState({posts: updatedPosts, showingPosts: showingPosts});
                if (this.state.showSortedPosts) {
                    this.sortPosts();
                }
            }
        });

        commentReceived((err, data) => {
            if(this._isMounted){
                let updatedPosts = cloneDeep(this.state.posts);
                let indexOfCommentedPost = updatedPosts.findIndex((post) => post._id === data.postId);
                if (indexOfCommentedPost !== -1) {
                    updatedPosts[indexOfCommentedPost]?.comments.push(data.comment);
                    this.setState({posts: updatedPosts});
                    if (this.state.showSortedPosts) {
                        this.sortPosts();
                    }
                }
            }

        });

        commentDeleted((err, data) => {
            if(this._isMounted){
                let updatedPosts = cloneDeep(this.state.posts);
                let indexOfPost = updatedPosts.findIndex((post) => post._id === data.postId);

                if (indexOfPost !== -1) {
                    let updatedComments = updatedPosts[indexOfPost].comments.filter(
                        (comment) => comment._id !== data.commentId
                    );
                    updatedPosts[indexOfPost].comments = updatedComments;
                    this.setState({posts: updatedPosts});
                    if (this.state.showSortedPosts) {
                        this.sortPosts();
                    }
                }
            }
        });

        postLikeReceived((err, data) => {
            if(this._isMounted){
                let updatedPosts = cloneDeep(this.state.posts);
                let indexOfCommentedPost = updatedPosts.findIndex((post) => post._id === data.postId);
                if (indexOfCommentedPost !== -1) {
                    updatedPosts[indexOfCommentedPost].likes.push(data.like);
                    this.setState({posts: updatedPosts});
                    if (this.state.showSortedPosts) {
                        this.sortPosts();
                    }
                }
            }
        });

        postUnlikeReceived((err, data) => {
            if(this._isMounted){
                let updatedPosts = cloneDeep(this.state.posts);
                let indexOfCommentedPost = updatedPosts.findIndex((post) => post._id === data.postId);
                if (indexOfCommentedPost !== -1) {
                    let indexOfUnlikedPost = updatedPosts[indexOfCommentedPost].likes.findIndex(
                        (like) => like.user === data.userId
                    );
                    updatedPosts[indexOfCommentedPost].likes.splice(indexOfUnlikedPost, 1);
                    this.setState({
                        posts: updatedPosts,
                    });
                    if (this.state.showSortedPosts) {
                        this.sortPosts();
                    }
                }
            }
        });

        pinPost((err, data) => {
            if(this._isMounted){
                let updatedPosts = cloneDeep(this.state.posts);
                let alreadyPinned = updatedPosts.filter((post) => post.isPinned === true);
                if (alreadyPinned.length > 0) {
                    alreadyPinned[0].isPinned = false;
                }
                if (data.postId !== null) {
                    let selectedElementIndex = updatedPosts.findIndex((post) => post._id === data.postId);
                    let pinnedPost = updatedPosts[selectedElementIndex];
                    if (selectedElementIndex !== -1) {
                        pinnedPost.isPinned = true;
                    }
                }
                this.setState({
                    posts: updatedPosts,
                });
            }
        });

        liveWallPostsDeleted((err, data) => {
            if(this._isMounted){
                let updatedPosts = [];
                let showingPosts = 20;
                this.setState({posts: updatedPosts, showingPosts: showingPosts});
                if (this.state.showSortedPosts) {
                    this.sortPosts();
                }
            }
        });

        wallPostApproved((err, data) => {
            if(this._isMounted){
                let updatedPosts = cloneDeep(this.state.posts);
                if (data.postId !== null) {
                    let selectedElementIndex = updatedPosts.findIndex((post) => post._id === data.postId);
                    let approvedPost = updatedPosts[selectedElementIndex];
                    if (selectedElementIndex !== -1) {
                        approvedPost.approved = true;
                    }
                }
                this.setState({
                    posts: updatedPosts,
                });
            }
        });

        wallPostCommentApproved((err, data) => {
            if(this._isMounted){
                let updatedPosts = cloneDeep(this.state.posts);
                if (data.postId !== null && data.commentId !== null) {
                    let selectedPostIndex = updatedPosts.findIndex((post) => post._id === data.postId);
                    let post = updatedPosts[selectedPostIndex];
                    let selectedPostCommentIndex = post?.comments.findIndex((comment) => comment._id === data.commentId);
                    let approvedComment = post?.comments[selectedPostCommentIndex];
                    if (approvedComment && selectedPostCommentIndex !== -1) {
                        approvedComment.approved = true;
                    }
                }
                this.setState({
                    posts: updatedPosts,
                });
            }
        });

        reloadWall((err, data) => {
            this.onGetWallPosts(data.wallId);
        });

        this.interval = setInterval(() => this.forceUpdate(), 60000);

        if (document.querySelector('.banner-moderation')) {
            const bannerModeration = document.querySelector('.banner-moderation');
            document.documentElement.style.setProperty('--moderation', `${bannerModeration.clientHeight}px`);
        }
    }

    componentDidUpdate(prevProps) {
        let intViewportWidth = window.innerWidth;
        if (intViewportWidth < 1080 && prevProps.exhibitor?._id !== this.props.exhibitor?._id) {
            this.props.setActiveWall({
                wallId: this.props.exhibitor?.booth?.boothWall,
                highlightUsers: [],
            });
        }
        if (prevProps.sideNavigationDetails.activeWallId !== this.props.sideNavigationDetails.activeWallId) {
            // disconnect from Previous Wall
            if (prevProps.sideNavigationDetails.activeWallId && this.props.userId) {
                disconnectFromWall(prevProps.sideNavigationDetails.activeWallId, this.props.userId);
            }

            // connect to wall
            if (this.props.sideNavigationDetails.activeWallId && this.props.userId) {
                connectToWall(this.props.sideNavigationDetails.activeWallId, this.props.userId);
            }

            // get the Wall Posts
            if (this.props.sideNavigationDetails.activeWallId) {
                this.onGetWallPosts(this.props.sideNavigationDetails.activeWallId);
            }

            // exit edit post/comment state when changing event (wallId)
            this.setState({postEditMode: false});
        }

        if (document.querySelector('.banner-moderation')) {
            const bannerModeration = document.querySelector('.banner-moderation');
            document.documentElement.style.setProperty('--moderation', `${bannerModeration.clientHeight}px`);
        }

        // TODO: uncomment if problem persists
        // fix for making sure the current wall posts displayed are for the active wall id
        // if (!this.state.loadingWallPosts) {
        //     if (
        //         this.state.currentWallIdForPostDisplayed !== this.props.sideNavigationDetails.activeWallId ||
        //         this.state.currentWallIdForPostDisplayed !== prevProps.sideNavigationDetails.activeWallId
        //     ) {
        //         this.onGetWallPosts(this.props.sideNavigationDetails.activeWallId);
        //     }
        // }
    }

    componentWillUnmount() {
        this._isMounted = false;
        // cancel get posts api call if the component gets unmounted
        this.source.cancel('Operation canceled by the user.');
        // stop listening to socket events that we don't need
        // if we don't deregister the socket listener
        // a new function will be created each and every time the Wall component is loaded
        deregisterPostReceived();
        deregisterPostDeleted();
        deregisterCommentReceived();
        deregisterCommentDeleted();
        deregisterPostLikeReceived();
        deregisterPostUnlikeReceived();
        deregisterPinPost();
        deregisterWallPostUpdated();
        deregisterWallCommentUpdated();
        deregisterWallPostApproved();
        deregisterWallPostCommentApproved();

        if (this.props.sideNavigationDetails.activeWallId && this.props.userId) {
            disconnectFromWall(this.props.sideNavigationDetails.activeWallId, this.props.userId);
        }

        clearInterval(this.interval);
        const eventPage = document.getElementsByClassName('event-page hide');
        if (eventPage.length !== 0) {
            eventPage[0].classList.remove('hide');
        }

        if (document.querySelector('.banner-moderation')) {
            document.documentElement.style.removeProperty('--moderation');
        }

        clearTimeout(this.timeoutId)
    }

    sortByTime = () => this.setState({showSortedPosts: false});
    getAllPinnedPosts = () => this.state.posts.filter((post) => post.isPinned);
    handleLoadMorePosts = () => this.setState({showingPosts: this.state.showingPosts + 10});
    onExitEditMode = () => this.setState({postEditMode: false, editedPostInputValue: ''});

    handleClosePinDialog = () =>
        this.setState({
            openPinDialog: false,
        });

    handleCloseDeleteDialog = () =>
        this.setState({
            openDeleteDialog: false,
        });

    handleOpenActions = (postId) => () =>
        this.setState({
            showActions: postId,
        });

    handleCloseActions = () =>
        this.setState({
            showActions: null,
        });

    getUserData = (userId) => {
        const {allEventUsersObject} = this.props;
        return allEventUsersObject[userId];
    };

    onApprovePost = (postId) => () => {
        const {eventId, sideNavigationDetails} = this.props;
        axios({
            method: 'post',
            url: `/event/${eventId}/live-wall/${sideNavigationDetails.activeWallId}/post/${postId}/approve`,
            data: postId,
        });
    };

    onAddNewComment = (comment, postId) => {
        const {eventId, sideNavigationDetails} = this.props;
        return axios({
            method: 'post',
            url: `/event/${eventId}/live-wall/${sideNavigationDetails.activeWallId}/post/${postId}/comment`,
            data: {text: comment},
        });
    };

    onApprovePostComment = (postId, commentId) => () => {
        const {eventId, sideNavigationDetails} = this.props;
        axios({
            method: 'post',
            url: `/event/${eventId}/live-wall/${sideNavigationDetails.activeWallId}/post/${postId}/comment/${commentId}/approve`,
            data: commentId,
        });
    };

    enterPostEditMode = (postId, text) => () => {
        if (!this.state.postEditMode) {
            this.setState(
                {
                    postEditMode: true,
                    editedPostID: postId,
                    editedPostInputValue: text,
                },
                () => {
                    this.handleCloseActions();
                }
            );
        }
    };

    onLikePost = (postId) => () => {
        const {eventId, sideNavigationDetails} = this.props;
        axios({
            method: 'post',
            url: `/event/${eventId}/live-wall/${sideNavigationDetails.activeWallId}/post/${postId}/like`,
        }).then(() => {});
    };

    onUnlikePost = (postId) => () => {
        const {eventId, sideNavigationDetails} = this.props;
        axios({
            method: 'delete',
            url: `/event/${eventId}/live-wall/${sideNavigationDetails.activeWallId}/post/${postId}/like`,
        }).then(() => {});
    };

    onPinnedPost = (postId) => () => {
        const {eventId, sideNavigationDetails} = this.props;
        let pin = this.getAllPinnedPosts();
        if (pin.length > 0 && pin[0]?._id !== postId) {
            this.setState({
                openPinDialog: true,
                editedPostID: postId,
            });
        } else {
            axios({
                method: 'post',
                url: `/event/${eventId}/live-wall/${sideNavigationDetails.activeWallId}/post/${postId}/pin`,
            }).then((response) => {
                this.setState({
                    showActions: null,
                });
            });
        }
    };

    handlePinAPost = (postId) => () => {
        const {eventId, sideNavigationDetails} = this.props;
        axios({
            method: 'post',
            url: `/event/${eventId}/live-wall/${sideNavigationDetails.activeWallId}/post/${postId}/pin`,
        }).then(() => {
            this.setState({
                showActions: null,
                openPinDialog: false,
            });
        });
    };

    onUnPinnedPost = (postId) => () => {
        const {eventId, sideNavigationDetails} = this.props;
        axios({
            method: 'delete',
            url: `/event/${eventId}/live-wall/${sideNavigationDetails.activeWallId}/post/${postId}/pin`,
        }).then(() => {
            this.setState({
                showActions: null,
            });
        });
    };

    handleDeletePost = (postId) => () => {
        this.setState({newPost: '', postEditMode: false}, () => {
            this.setState({
                openDeleteDialog: true,
                deletePostId: postId,
            });
        });
    };

    onDeletePost = () => {
        const {deletePostId} = this.state;
        const {eventId, sideNavigationDetails} = this.props;
        return axios({
            method: 'delete',
            url: `/event/${eventId}/live-wall/${sideNavigationDetails.activeWallId}/post/${deletePostId}`,
        })
            .then(() =>
                this.setState({
                    openDeleteDialog: false,
                    deletePostId: null,
                })
            )
            .catch(() => {});
    };

    cancelToken = axios.CancelToken;
    source = this.cancelToken.source();
    onGetWallPosts = (wallId) => {
        this.setState({
            ranGetWall: true,
        });

        const {eventId} = this.props;
        if (eventId && wallId) {
            this.setState({loadingWallPosts: true});
            axios.get(`/event/v2/${eventId}/live-wall/${wallId}`, {cancelToken: this.source.token})
                .then((response) => {
                    if(this._isMounted){
                        const wallData = response.data.data;
                        this.setState({
                            posts: wallData.wall.posts,
                            loadingWallPosts: false,
                            currentWallIdForPostDisplayed: wallId,
                            showSortedPosts: false,
                        });
                    }
                })
                .catch(() => {});
        }
    };

    getAllUnpinnedPosts = () => {
        const {posts, showSortedPosts, sortedPosts} = this.state;
        const {userId} = this.props;
        let allPosts = showSortedPosts ? sortedPosts : posts.slice(0).reverse();

        // we don't need to make a deep copy of allPosts because
        // "slice" methods creates a new array
        let newPostsArray = allPosts.slice(0, this.state.showingPosts);
        let hasNotApprovedRights = this.handleApprovedPostRightsOnWalls();

        if (hasNotApprovedRights) {
            return newPostsArray.filter(
                (post) =>
                    !post.isPinned && (post.approved === true || userId === post.user) && this.getUserData(post.user)
            );
        } else {
            return newPostsArray.filter((post) => !post.isPinned && this.getUserData(post.user));
        }
    };

    handleActiveModeration = () => {
        const {event, isLiveWall, isVideoWall, isBoothWall} = this.props;
        if (isLiveWall) {
            return event.moderateLiveWalls;
        } else if (isVideoWall) {
            return event?.moderateVideoWalls;
        } else if (isBoothWall) {
            return event.moderateBoothWalls;
        }
    };

    // sendStatistics = (actionType) => {
    //     const {eventUsers} = this.props;
    //     const participantId = getParticipantId(eventUsers.participants, this.props.userId);
    //     if (participantId) {
    //         const dataToSend = {participantId: participantId, actionType: actionType};
    //         axios({
    //             method: 'post',
    //             url: '/exhibitor/' + this.props.exhibitor._id + '/booth-statistics',
    //             data: dataToSend,
    //         })
    //             .then((response) => {})
    //             .catch((error) => {});
    //     }
    // };

    handleUpdate = (values) => {
        const {scrollTop, scrollHeight, clientHeight} = values;
        if (this.state.showingPosts < this.state.posts.length && scrollHeight > 600) {
            const pad = 150; // 100px of the bottom
            // t will be greater than 1 if we are about to reach the bottom
            const t = (scrollTop + pad) / (scrollHeight - clientHeight);
            if (t > 1) this.handleLoadMorePosts();
        }
    };

    renderView = ({style, ...props}) => {
        let viewStyle = {
            padding: 10,
        };
        if (this.props.isRtlLanguage) {
            viewStyle = {...viewStyle, marginLeft: style.marginRight, marginRight: 0};
        }
        return <div onDragStart={preventDefaultDrag} className="box" style={{...style, ...viewStyle}} {...props} />;
    };

    renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `rgb(137,137,137)`,
            left: 0,
        };
        return (
            <div
                onDragStart={preventDefaultDrag}
                className="grey-scroll"
                style={{...style, ...thumbStyle}}
                {...props}
            />
        );
    };

    focusInput = (isInputFocused) => {
        this.setState({isInputFocused: isInputFocused});
    };

    handleEnterKey = (tab) => (e) => {
        if (e.key === 'Enter') {
            if (tab === 'popularity') return this.sortPosts();
            if (tab === 'time') return this.sortByTime();
        }
    };

    sortPosts = () => {
        // we apply slice method so that we can make a copy of the original array without mutating it
        const highligtUsers = this.props.sideNavigationDetails.highlightPostsForUsers;
        let sortedPosts = cloneDeep(this.state.posts);

        sortedPosts.sort(function (a, b) {
            let scoreA = a.comments.length + a.likes.length * 2;
            if (highligtUsers.includes(a.user)) {
                scoreA = scoreA + 2;
            }

            let scoreB = b.comments.length + b.likes.length * 2;
            if (highligtUsers.includes(b.user)) {
                scoreB = scoreB + 2;
            }

            return scoreA > scoreB ? -1 : 1;
        });
        if(this._isMounted){
            this.setState({showSortedPosts: true, sortedPosts: sortedPosts});
        }
    };

    handleAccessibilityOnWalls = () => {
        const {userId, exhibitor, event, eventRoles, sideNavigationDetails, isLiveWall, isVideoWall, isBoothWall} =
            this.props;

        let isOrganizer = eventRoles.isOrganizer;
        let isActiveCoOrganizer = eventRoles.isCoOrganizer && eventRoles.isCoOrganizerActive;
        const isSpeaker = sideNavigationDetails.currentTimeSlotVideoWallSpeakers.includes(userId);
        const isRepresentative = checkBoothOwnerOrRepresentative(exhibitor, userId);


        if (isLiveWall) {
            return event.closeLiveWalls && !isOrganizer && !isActiveCoOrganizer;
        } else if (isVideoWall) {
            return event?.closeVideoWalls && !isOrganizer && !isSpeaker && !isActiveCoOrganizer;
        } else if (isBoothWall) {
            return event.closeBoothWalls && !isOrganizer && !isActiveCoOrganizer && !isRepresentative
        }
    };

    handleApprovedPostRightsOnWalls = () => {
        const {userId, exhibitor, event, eventRoles, sideNavigationDetails, isLiveWall, isVideoWall, isBoothWall} =
            this.props;

        let isOrganizer = eventRoles.isOrganizer;
        let isActiveCoOrganizer = eventRoles.isCoOrganizer && eventRoles.isCoOrganizerActive;
        const isSpeaker = sideNavigationDetails.currentTimeSlotVideoWallSpeakers.includes(userId);

        if (isLiveWall) {
            if (event.moderateLiveWalls) {
                return event.moderateLiveWalls && !isOrganizer && !isActiveCoOrganizer;
            } else {
                return !isOrganizer && !isActiveCoOrganizer;
            }
        } else if (isVideoWall) {
            if (event?.moderateVideoWalls) {
                return event?.moderateVideoWalls && !isOrganizer && !isActiveCoOrganizer && !isSpeaker;
            } else {
                return !isOrganizer && !isActiveCoOrganizer && !isSpeaker;
            }
        } else if (isBoothWall) {
            if (exhibitor?.user === userId) {
                // is booth owner
                if (event.moderateBoothWalls) {
                    return (
                        event.moderateBoothWalls &&
                        !isOrganizer &&
                        !isActiveCoOrganizer &&
                        !(eventRoles?.exhibitorId === exhibitor?._id)
                    );
                } else {
                    return !isOrganizer && !isActiveCoOrganizer && !(eventRoles?.exhibitorId === exhibitor?._id);
                }
            } else if (exhibitor?.booth.presentingRepresentative?.user?._id === userId) {
                // is exhibitor representive - PS
                if (event.moderateBoothWalls) {
                    return (
                        event.moderateBoothWalls &&
                        !isOrganizer &&
                        !isActiveCoOrganizer &&
                        !(exhibitor?.booth.presentingRepresentative?.user?._id === userId)
                    );
                } else {
                    return (
                        !isOrganizer &&
                        !isActiveCoOrganizer &&
                        !(exhibitor?.booth.presentingRepresentative?.user?._id === userId)
                    );
                }
            } else if (
                exhibitor?.booth.deskRepresentativeLeft?.user?._id === userId ||
                exhibitor?.booth.deskRepresentativeRight?.user?._id === userId
            ) {
                // is exhibitor representative - Exhibitor company
                if (event.moderateBoothWalls) {
                    return (
                        event.moderateBoothWalls &&
                        !isOrganizer &&
                        !isActiveCoOrganizer &&
                        !(
                            exhibitor?.booth.deskRepresentativeLeft?.user?._id === userId ||
                            exhibitor?.booth.deskRepresentativeRight?.user?._id === userId
                        )
                    );
                } else {
                    return (
                        !isOrganizer &&
                        !isActiveCoOrganizer &&
                        !(
                            exhibitor?.booth.deskRepresentativeLeft?.user?._id === userId ||
                            exhibitor?.booth.deskRepresentativeRight?.user?._id === userId
                        )
                    );
                }
            } else {
                if (event.moderateBoothWalls) {
                    return event.moderateBoothWalls && !isOrganizer && !isActiveCoOrganizer;
                } else {
                    return !isOrganizer && !isActiveCoOrganizer;
                }
            }
        }
    };

    render() {
        const {
            openPinDialog,
            editedPostID,
            showActions,
            openDeleteDialog,
            postEditMode,
            isInputFocused,
            editedPostInputValue,
        } = this.state;
        const {scholar, translation, defaultTranslation, isLargeScreen} = this.props;
        const pinnedPosts = this.getAllPinnedPosts();
        const unPinnedPosts = this.getAllUnpinnedPosts();
        let isActiveModeration = this.handleActiveModeration();
        let isNotAbleToPost = this.handleAccessibilityOnWalls();
        let hasNotApprovedRights = this.handleApprovedPostRightsOnWalls();

        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`wall-container ${isNotAbleToPost ? 'closed-wall' : ''} ${
                    isActiveModeration ? 'wall-moderation' : ''
                } ${postEditMode ? 'edit-mode-active' : ''}`}
            >
                {scholar && !isLargeScreen && (
                    <div onDragStart={preventDefaultDrag} className="exhibitor-mobile-header scholar">
                        <PdfPreview
                            translation={translation}
                            defaultTranslation={defaultTranslation}
                            // sendStatistics={this.sendStatistics}
                        />
                    </div>
                )}
                <div onDragStart={preventDefaultDrag} className="sort-container">
                    <span>{translation?.wall.sortBy}</span>
                    <div onDragStart={preventDefaultDrag} className="sorting-buttons">
                        <Button
                            tabIndex="0"
                            variant={"text"}
                            onClick={this.sortPosts}
                            onKeyDown={this.handleEnterKey('popularity')}
                            size={"small"}
                            className={'button ' + (this.state.showSortedPosts ? 'selected' : '')}
                        >
                            {translation?.wall.popularity}
                        </Button>
                        <Button
                            tabIndex="0"
                            variant={"text"}
                            onClick={() => this.setState({showSortedPosts: false})}
                            onKeyDown={this.handleEnterKey('time')}
                            size={"small"}
                            className={'button ' + (!this.state.showSortedPosts ? 'selected' : '')}
                        >
                            {translation?.wall.time}
                        </Button>
                        {isLargeScreen && <DownloadPosts />}
                    </div>
                </div>
                <div>
                    {!isNotAbleToPost && (
                        <AddEditPostInput
                            onExitEditMode={this.onExitEditMode}
                            focusInput={this.focusInput}
                            postEditMode={postEditMode}
                            editedPostId={editedPostID}
                            editedPostInputValue={editedPostInputValue}
                        />
                    )}
                    {isActiveModeration && (
                        <div className="banner-moderation">
                            <InfoIcon />
                            <p>{translation?.wall.bannerModeration || defaultTranslation?.wall.bannerModeration}</p>
                        </div>
                    )}
                    <div
                        onDragStart={preventDefaultDrag}
                        className={`custom-scroll-container ${scholar ? 'scholar' : ''}`}
                    >
                        {this.state.loadingWallPosts || this.props.loadingEventUsers ? (
                            <Spinner />
                        ) : (
                            <>
                                {this.state.posts.length > 0 ? (
                                    <Scrollbars
                                        ref={this.wallScrollbar}
                                        className="scrollbar"
                                        renderView={this.renderView}
                                        renderThumbVertical={this.renderThumb}
                                        onUpdate={this.handleUpdate}
                                    >
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className={`wall ${
                                                isInputFocused && isMobileSafari && isIOS ? 'input-focused-ios' : ''
                                            }`}
                                            data-empty={this.state.posts.length === 0 ? 'true' : 'false'}
                                        >
                                            <PostsList
                                                posts={pinnedPosts}
                                                pinPost={this.onPinnedPost}
                                                unpinPost={this.onUnPinnedPost}
                                                likePost={this.onLikePost}
                                                unlikePost={this.onUnlikePost}
                                                deletePost={this.handleDeletePost}
                                                addComment={this.onAddNewComment}
                                                showActions={showActions}
                                                handleOpenActions={this.handleOpenActions}
                                                handleCloseActions={this.handleCloseActions}
                                                isNotAbleToPost={isNotAbleToPost}
                                                enterPostEditMode={this.enterPostEditMode}
                                                postEditMode={this.state.postEditMode}
                                                isActiveModeration={isActiveModeration}
                                                hasNotApprovedRights={hasNotApprovedRights}
                                                onApprovePostComment={this.onApprovePostComment}
                                                focusInput={this.focusInput}
                                            />
                                            <PostsList
                                                posts={unPinnedPosts}
                                                pinPost={this.onPinnedPost}
                                                unpinPost={this.onUnPinnedPost}
                                                likePost={this.onLikePost}
                                                unlikePost={this.onUnlikePost}
                                                deletePost={this.handleDeletePost}
                                                addComment={this.onAddNewComment}
                                                showActions={showActions}
                                                handleOpenActions={this.handleOpenActions}
                                                handleCloseActions={this.handleCloseActions}
                                                isNotAbleToPost={isNotAbleToPost}
                                                enterPostEditMode={this.enterPostEditMode}
                                                postEditMode={this.state.postEditMode}
                                                isActiveModeration={isActiveModeration}
                                                hasNotApprovedRights={hasNotApprovedRights}
                                                onApprovePost={this.onApprovePost}
                                                onApprovePostComment={this.onApprovePostComment}
                                                focusInput={this.focusInput}
                                            />
                                        </div>
                                    </Scrollbars>
                                ) : (
                                    <div onDragStart={preventDefaultDrag} className="wall" data-empty={'true'}>
                                        <div className="empty-wall">
                                            <EmptyWall strokefill={colors.primary} fillPrimary={colors.primary} />
                                            <p>{translation?.wall.emptyWallText}</p>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <Confirm
                        open={openPinDialog}
                        closeConfirm={this.handleClosePinDialog}
                        dialogDescription={
                            translation?.wall.pinDialogDescription || defaultTranslation?.wall.pinDialogDescription
                        }
                        dialogConfirmButtonLabel={
                            translation?.generalText.confirm || defaultTranslation?.generalText.confirm
                        }
                        dialogCancelButtonLabel={
                            translation?.generalText.cancel || defaultTranslation?.generalText.cancel
                        }
                        handleConfirm={this.handlePinAPost(editedPostID)}
                    />
                    <ConfirmDelete
                        classes={'delete-post'}
                        open={openDeleteDialog}
                        closeConfirmDeleteDialog={this.handleCloseDeleteDialog}
                        dialogTitle={translation?.wall.deleteDialogTitle || defaultTranslation?.wall.deleteDialogTitle}
                        dialogConfirmButtonLabel={
                            translation?.generalText.delete || defaultTranslation?.generalText.delete
                        }
                        dialogCancelButtonLabel={
                            translation?.generalText.cancel || defaultTranslation?.generalText.cancel
                        }
                        handleConfirmDelete={this.onDeletePost}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.user.data?._id,
        eventId: state.event.eventId,
        event: state.event.data,
        loadingEventUsers: state.eventUsers.loading,
        allEventUsersObject: state.eventUsers.allEventUsersObject,
        eventRoles: state.user.eventRoles,
        exhibitor: state.exhibitor.data,
        sideNavigation: state.user.sideNavigation,
        sideNavigationDetails: state.event.sideNavigationDetails,
        isRtlLanguage: state.languages.isRtlLanguage,
        isLargeScreen: state.layout.isLargeScreen,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onGetUsers: (eventId) => dispatch(actions.getUsers(eventId)),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wall));
