import * as actionTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    loading: false,
    error: null,
    invitationCodes: [],
    isInvitationCodeValid: null,
};

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.API_CALL_INVITATIONS_START:
                draft.loading = true;
                break;
                
            case actionTypes.API_CALL_INVITATIONS_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;
                
            case actionTypes.GET_INVITATION_CODES_SUCCESS:
                draft.loading = false;
                draft.error = null;
                draft.invitationCodes = action.payload;
                break;
                
            case actionTypes.CHECK_INVITATION_CODE_SUCCESS:
                draft.loading = false;
                draft.error = null;
                draft.isInvitationCodeValid = action.payload;
                break;
                
            case actionTypes.CHECK_INVITATION_CODE_FAIL:
                draft.loading = false;
                draft.error = null;
                draft.isInvitationCodeValid = action.payload;
                break;

            default:
                 break;
        }
    })



export default reducer;
