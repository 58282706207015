import Snackbar from '@material-ui/core/Snackbar';
import {Slide} from '@material-ui/core';
import {preventDefaultDrag} from 'Utils/utils';
import {useEffect} from 'react';
import './SnackbarGlobalStyles.scss';
const SnackbarGlobal = ({message, snackbarOpen, handleCloseSnackbar, icon, isError}) => {
    /*  const transitionUp = (props) => {
        let newProps;
        newProps = {...props, appear: snackbarOpen};
        return <Slide {...newProps} direction="up" />;
    };

   */
    const setTransitionCloseEffect = () => {
        //check if there is auditorium text in the url
        const isAuditorium = window.location.href.includes('auditorium' || 'auditorium-archive');
        const positionBg = document.querySelector('.position-background');

        if (isAuditorium) {
            if (snackbarOpen) {
                positionBg?.classList?.add('addZIndex');
            }
        }

        const snackbar = document.getElementById('snackbar-email-domains');
        setTimeout(() => {
            if (snackbar) {
                snackbar.classList?.toggle('snackBarClose');
            }
        }, 500);
        if (positionBg?.classList?.contains('addZIndex')) {
            setTimeout(() => {
                if (positionBg) {
                    positionBg?.classList?.remove('addZIndex');
                }
            }, 5000);
        }
    };

    useEffect(() => {
        setTransitionCloseEffect();
    }, [snackbarOpen]);

    return (
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            key={message}
            open={snackbarOpen}
            autoHideDuration={5000}
            id={`snackbar-email-domains`}
            onClose={handleCloseSnackbar}
            /*
            TransitionComponent={transitionUp}
*/
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            className={`snackbar-email-domains ${isError ? 'snackbar-email-domains-error' : ''} `}
            message={
                <span
                    onDragStart={preventDefaultDrag}
                    draggable="false"
                    id="message-id"
                    className="email-domain-snackbar-message"
                >
                    {icon && <span className={`icon-cover`}>{icon}</span>} {message}
                </span>
            }
        />
    );
};

export default SnackbarGlobal;

SnackbarGlobal.defaultProps = {
    message: 'Success',
    icon: null,
    snackbarOpen: false,
    isError: false,
};
