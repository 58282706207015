import {Tooltip} from "@material-ui/core";
import {preventDefaultDrag} from "../../Utils/utils";
import LockIcon from "@material-ui/icons/Lock";
import React from "react";
import {useSelector} from "react-redux";

export function NetworkingTabButton({
                                        hasAccessToNetworking,
                                        handleSeeNetworking,
                                        handleEnterKeyNetworking,
                                    }) {
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const sideNavigation = useSelector((state) => state.user.sideNavigation);
    const eventHasResources = useSelector((state) => state.event.data.hasResources);

    return <div
        tabIndex="0"
        className={
            "networking-tab button-tab tab " +
            (sideNavigation.seeNetworking ? "active " : " ") +
            (eventHasResources ? "small-tab" : "")
        }
        onClick={handleSeeNetworking}
        onKeyDown={handleEnterKeyNetworking}
    >
        {!hasAccessToNetworking ? (
            <Tooltip arrow title={translation?.sideMenu.networkingTooltip}>
                <div
                    onDragStart={preventDefaultDrag}
                    className="restricted-network"
                >
                    <LockIcon fontSize="small"/>{" "}
                    {translation?.sideMenu.networking}
                </div>
            </Tooltip>
        ) : (
            <>{translation?.sideMenu.networking}</>
        )}
    </div>;
}