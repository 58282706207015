import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Spinner from '../../SmallLayoutComponents/FullPageSpinner';
import {withRouter} from 'react-router-dom';
import NotAvailable from '../../Pages/NotAvailable';
import {isIE, isMobile, isYandex} from 'react-device-detect';
import PlatformNotAvailable from '../../SmallLayoutComponents/PlatformNotAvailable';

class EventPrivateComponent extends React.PureComponent {
    state = {
        eventSlug: this.props.match.params.eventSlug,
        buildingId: this.props.match.params.buildingId,
    };

    componentDidMount() {
        const {eventSlug, event, eventId} = this.props;

        // event && event.slug !== eventSlug  is a fix for navigating between events
        if (this.state.eventSlug !== eventSlug || (event && event.slug !== eventSlug)) {
            this.props.setEventSlug(this.state.eventSlug);
            if(eventSlug){
                this.props.setEventIdBySlug(eventSlug);
            }
        }
        if (eventSlug && !eventId) {
            this.props.setEventIdBySlug(this.state.eventSlug);
        }
        if (!event && eventId) {
            this.props.onGetEvent(eventId);
        }

        if (event && event._id !== eventId) {
            this.props.onGetEvent(eventId);
        }

        // user lands directly on a building page, we must set the buildingId to the global store
        if (this.state.buildingId) {
            this.props.onSetBuildingId(this.state.buildingId);
        }
        document.documentElement.style.removeProperty('--primary-color');
        document.documentElement.style.removeProperty('--secondary-color');
    }

    componentDidUpdate(prevProps) {

        const {eventId, loadingEvent, eventSlug, building, buildingId, loadingBuilding} = this.props;
        if (prevProps.eventSlug !== eventSlug && !loadingEvent) {
            this.props.setEventIdBySlug(eventSlug);
        }
        if (prevProps.eventId !== eventId && !loadingEvent) {
            this.props.onGetEvent(eventId);
        }

        // if the user lands on a buildingPage
        // after we get the eventId and have the buildingId
        // get the building Data
        if (eventId && buildingId && !loadingBuilding && !building) {
            this.props.onGetBuilding(eventId, buildingId);
        }

        // if user navigates to a new building
        // get the new buildingData
        if (eventId && prevProps.buildingId !== buildingId && !loadingBuilding) {
            if (buildingId) {
                this.props.onGetBuilding(eventId, buildingId);
            }
        }
    }

    render() {
        const {event, buildingId, building} = this.props;
        if (isMobile) {
            //return <PlatformNotAvailable message={"Onvent is not currently optimized for mobile usage. Onvent apps coming soon. Please access your onvent using a PC or Mac!"}/>
        }
        if (isIE || isYandex) {
            return (
                <PlatformNotAvailable
                    message={
                        `${process.env.REACT_APP_PLATFORM_NAME} is not currently optimized for the browser you are using. Please access the platform using Google Chrome, Firefox, Safari or Microsoft Edge.`
                    }
                />
            );
        }
        if (!event) {
            return <Spinner />;
        }
        if (event && event.owner.organizerStatus === 'disabled') {
            return <NotAvailable />;
        }

        // event is normal setup (no village) and the user lands on a /building route
        if (event && !event.hasVillage && this.state.buildingId) {
            return <NotAvailable />;
        }

        // event is village setup, user is on a building page but we don't have the building data yet
        if (event.hasVillage && (this.state.buildingId || buildingId) && (!building || building?._id !== buildingId)) {
            return <Spinner />;
        }
        return <>
            {this.props.children}
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        event: state.event.data,
        loadingEvent: state.event.loading,
        buildingId: state.building.buildingId,
        building: state.building.data,
        loadingBuilding: state.building.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEvent: (eventId) => dispatch(actions.getEvent(eventId)),
        setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
        setEventIdBySlug: (eventSlug) => dispatch(actions.setEventIdBySlug(eventSlug)),
        onSetBuildingId: (buildingId) => dispatch(actions.setBuildingId(buildingId)),
        onGetBuilding: (eventId, buildingId) => dispatch(actions.getBuilding(eventId, buildingId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventPrivateComponent));
