import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class InformDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="form-dialog-title"
                className={this.props.dialogClasses}
                PaperProps={{classes: {root: 'reverse-rtl'}}}
            >
                <DialogTitle id="form-dialog-title">{this.props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="form-dialog-secondary">{this.props.dialogDescription}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={this.props.onClose}>
                        {this.props.dialogButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default InformDialog;
