import React from 'react';
import ConfirmDelete from '../../../Dialogs/ConfirmDelete';
import {connect} from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import axios from '../../../store/axios-instance';
import * as actions from '../../../store/actions';
import {preventDefaultDrag} from '../../../Utils/utils';
import AccordionLayout from './LayoutComponents/AccordionLayout';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Button from '@material-ui/core/Button';

class HomepageSponsors extends React.Component {
    state = {
        openAddEditSponsorDialog: false,
        showConfirmDeleteDialog: false,
        editedSponsorId: null,
        currentSponsorIndex: null,
        openAddSponsor: false,
        showConfirmCloseAccordion: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // after we add a new sponsor, open the accordion to him
        if(prevProps.branding?.data?.sponsors?.length < this.props.branding?.data?.sponsors?.length){
            this.setState({
                currentSponsorIndex: this.props.branding?.data?.sponsors?.length - 1
            });
        }
    }

    handleOpenAddSponsorDialog = () =>
        this.setState({
            openAddEditSponsorDialog: true,
            currentSponsorIndex: null,
            openAddSponsor: true,
        });

    handleCloseDialog = () =>
        this.setState({
            openAddEditSponsorDialog: false,
            showConfirmCloseAccordion: false,
            openAddSponsor: false,
        });

    handleAddNewSponsor = () =>
        this.setState((prevState) => ({
            openAddSponsor: !prevState.openAddSponsor,
            currentSponsorIndex: null,
        }));

    handleCloseDeletePopup = () =>
        this.setState({
            showConfirmCloseAccordion: true,
        });

    openConfirmDeleteDialog = (id) => () =>
        this.setState({
            editedSponsorId: id,
            showConfirmDeleteDialog: true,
        });

    closeConfirmDeleteDialog = () =>
        this.setState({
            editedSponsorId: null,
            showConfirmDeleteDialog: false,
            showConfirmCloseAccordion: false,
            openAddSponsor: true,
        });

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return this.handleOpenAddSponsorDialog();
    };

    setSponsorIndex = (sponsorIndex) => (e) => {
        let newIndex = this.state.currentSponsorIndex === sponsorIndex ? null : sponsorIndex;
        this.setState({
            currentSponsorIndex: newIndex,
            openAddSponsor: false,
        });
    };

    handleAddSponsor = (sponsorData) => {
        const {eventId} = this.props;
        axios({method: 'post', url: `/event/${eventId}/branding/add-sponsor`, data: sponsorData})
            .then((response) => {
                this.handleCloseDialog();
                const brandingData = response.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    handleDeleteSponsor = () => {
        const {eventId} = this.props;
        axios({method: 'delete', url: `/event/v2/${eventId}/branding/remove-sponsor/${this.state.editedSponsorId}`})
            .then((response) => {
                this.closeConfirmDeleteDialog();
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    render() {
        const {openAddEditSponsorDialog, openAddSponsor, showConfirmDeleteDialog, showConfirmCloseAccordion} =
            this.state;
        const {handleOpenSuccessSnackbar, handleOpenErrorSnackbar, branding} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="branding-homepage-exhibitors new-accordion-design">
                <h4 className="advanced-options-title">
                    SPONSORS
                    <Button
                        onClick={this.handleOpenAddSponsorDialog}
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD SPONSOR
                    </Button>
                </h4>
                <div onDragStart={preventDefaultDrag} className="list-wrapper">
                    {branding?.data?.sponsors?.length > 0 ? (
                        <div onDragStart={preventDefaultDrag} className="accordion-container new-role-container">
                            {branding.data?.sponsors?.map((sponsor, sponsorIndex) => {
                                const isActiveSponsor = sponsorIndex === this.state.currentSponsorIndex;
                                return (
                                    <AccordionLayout
                                        isActive={isActiveSponsor}
                                        role={sponsor}
                                        roles={branding.data.sponsors}
                                        roleTitle={'sponsor'}
                                        roleIndex={sponsorIndex}
                                        openConfirmDeleteDialog={this.openConfirmDeleteDialog(sponsor._id)}
                                        handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                                        handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                                        handleAddRole={this.handleAddSponsor}
                                        onChange={this.setSponsorIndex(sponsorIndex)}
                                        filesUrl={branding.data.filesUrl}
                                        showSection={true}
                                        key={sponsor._id}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div
                            onDragStart={preventDefaultDrag}
                            className={`advanced-options-container ${openAddEditSponsorDialog ? 'd-none' : ''}`}
                        >
                            <p onDragStart={preventDefaultDrag} className="list-empty-text">
                                You currently don't have any sponsors added
                            </p>
                        </div>
                    )}
                </div>
                {openAddEditSponsorDialog && (
                    <div onDragStart={preventDefaultDrag} className="accordion-container new-role-container">
                        <AccordionLayout
                            isActive={openAddSponsor}
                            roleTitle={'sponsor'}
                            onChange={this.handleAddNewSponsor}
                            openConfirmDeleteDialog={this.handleCloseDeletePopup}
                            handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                            handleOpenErrorSnackbar={handleOpenErrorSnackbar}
                            handleAddRole={this.handleAddSponsor}
                            showSection={false}
                        />
                    </div>
                )}
                <div onDragStart={preventDefaultDrag} className="actions-container">
                    <Button
                        onClick={this.handleOpenAddSponsorDialog}
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD SPONSOR
                    </Button>
                </div>
                {showConfirmDeleteDialog && (
                    <ConfirmDelete
                        open={showConfirmDeleteDialog}
                        closeConfirmDeleteDialog={this.closeConfirmDeleteDialog}
                        dialogTitle={'Delete Sponsor'}
                        dialogDescription={'Are you sure you want to delete this sponsor?'}
                        dialogConfirmButtonLabel={'Delete'}
                        handleConfirmDelete={this.handleDeleteSponsor}
                    />
                )}
                {showConfirmCloseAccordion && (
                    <ConfirmDelete
                        open={showConfirmCloseAccordion}
                        closeConfirmDeleteDialog={this.closeConfirmDeleteDialog}
                        dialogTitle={'Delete Sponsor'}
                        dialogDescription={'Are you sure you want to delete this sponsor?'}
                        dialogConfirmButtonLabel={'Delete'}
                        handleConfirmDelete={this.handleCloseDialog}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        branding: state.event.branding,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageSponsors);
