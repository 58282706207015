import {useSelector} from 'react-redux';
import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {
    getProgramFormattedTitleDate,
    getProgramFormattedTitleDateMobile,
    getTimeslotDescriptionFormatted,
    getTimeslotDuration,
} from 'Utils/utils';
import DialogAddToExternalCalendar from '../DialogAddToExternalCalendar';
import TimeslotAccordionMobile from './TimeslotAccordionMobile';
import TimeslotAccordionTablet from './TimeslotAccordionTablet';

const TimeslotAccordion = ({
    isElementAddedToTimeslot,
    addDeleteAgenda,
    timeslot,
    isNewDay,
    handleSetActiveTimeslot,
    refTimeslotsList,
    updateTimeslotsInViewport,
    displayNumberOfSearchResult,
    activeDays,
    isAddedToAgenda,
    activeTimeslot,
}) => {
    const languages = useSelector((state) => state.languages);
    const refTimeslot = useRef();
    const event = useSelector((state) => state?.event?.data);
    const user = useSelector((state) => state?.user?.data);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const isMobile = useSelector((state) => state.layout.isMobile);
    const isTablet = useSelector((state) => state.layout.isTablet);

    const [externalCalendarDialog, setExternalCalendarDialog] = useState(false);
    const [calendarData, setCalendarData] = useState(null);
    const [expand, setExpand] = useState(false);

    const stopPropagation = (e) => {
        // we don't want to bubble the event until it reaches the timeslot <li> element
        // so that we don't open the timeslot details window
        e.stopPropagation();
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                updateTimeslotsInViewport(entry.target.id, entry.isIntersecting);
            },
            {
                root: refTimeslotsList.current,
                rootMargin: '0px',
                threshold: 1.0,
            }
        );

        observer.observe(refTimeslot.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    const getFormattedIdForDate = (timestamp) => {
        return moment(timestamp).format('l');
    };

    const currentDay = new Date(timeslot.startTimestamp);

    const createTimeslotCalendarData = () => {
        const timeslotCalendarData = {
            title: `${timeslot.title}`,
            description: getTimeslotDescriptionFormatted(timeslot, translation),
            location: `${window.location.origin}/event/${event.slug}/`,
            start: timeslot.startTimestamp,
            end: timeslot.endTimestamp,
        };
        return timeslotCalendarData;
    };

    const createExternalCalendarData = () => {
        const calendarData = createTimeslotCalendarData();
        setCalendarData(calendarData);
        setExternalCalendarDialog(true);
    };

    const hideAddToExternalCalendarDialog = () => {
        setExternalCalendarDialog(false);
    };

    let speakersArray = [];
    if (timeslot?.speakers) {
        speakersArray = timeslot?.speakers.split(',');
    }

    const toggleAccordion = () => {
        setExpand((prev) => !prev);
    };

    const typeOfAccordion = () => {
        if (isMobile) {
            return (
                <TimeslotAccordionMobile
                    key={timeslot._id}
                    timeslot={timeslot}
                    stopPropagation={stopPropagation}
                    createExternalCalendarData={createExternalCalendarData}
                    toggleAccordion={toggleAccordion}
                    refTimeslot={refTimeslot}
                    handleSetActiveTimeslot={handleSetActiveTimeslot}
                    speakersArray={speakersArray}
                    isElementAddedToTimeslot={isElementAddedToTimeslot}
                    addDeleteAgenda={addDeleteAgenda}
                    activeTimeslot={activeTimeslot}
                    expand={expand}
                    isAddedToAgenda={isAddedToAgenda}
                />
            );
        } else if (isTablet) {
            return (
                <TimeslotAccordionTablet
                    key={timeslot._id}
                    timeslot={timeslot}
                    createExternalCalendarData={createExternalCalendarData}
                    toggleAccordion={toggleAccordion}
                    refTimeslot={refTimeslot}
                    handleSetActiveTimeslot={handleSetActiveTimeslot}
                    speakersArray={speakersArray}
                    displayNumberOfSearchResult={displayNumberOfSearchResult}
                    activeDays={activeDays}
                    expand={expand}
                    isAddedToAgenda={isAddedToAgenda}
                    isElementAddedToTimeslot={isElementAddedToTimeslot}
                    addDeleteAgenda={addDeleteAgenda}
                />
            );
        }
    };
    return (
        <>
            {isNewDay && (
                <div
                    className={`date-title ${isTablet && 'date-title-tablet'}`}
                    id={getFormattedIdForDate(timeslot.startTimestamp)}
                >
                    {isMobile
                        ? getProgramFormattedTitleDateMobile(currentDay, languages)
                        : getProgramFormattedTitleDate(currentDay, languages)}
                </div>
            )}
            <div
                className={`accordion-container ${isTablet && 'tblt'} ${isMobile && 'mobile-support'} ${
                    isTablet && !displayNumberOfSearchResult ? ' container-tablet-helper' : ''
                }`}
            >
                {typeOfAccordion()}
                {externalCalendarDialog && (
                    <DialogAddToExternalCalendar
                        open={externalCalendarDialog}
                        onClose={hideAddToExternalCalendarDialog}
                        eventCalendarData={calendarData}
                        title={
                            translation?.programDropdown.addTimeslotToYourCalendar ||
                            defaultTranslation?.programDropdown.addTimeslotToYourCalendar
                        }
                        timeslotTitle={
                            translation?.programDropdown.timeslotTitle ||
                            defaultTranslation?.programDropdown.timeslotTitle
                        }
                        timeslotDescripion={timeslot.title}
                        timeslotSpeakers={timeslot.speakers}
                        durationTitle={
                            translation?.programDropdown.slotScheduled ||
                            defaultTranslation?.programDropdown.slotScheduled
                        }
                        duration={getTimeslotDuration(timeslot, languages, event, user)}
                        tip={
                            translation?.programDropdown.tipTimeslotDescription ||
                            defaultTranslation?.programDropdown.tipTimeslotDescription
                        }
                    />
                )}
            </div>
        </>
    );
};

export default TimeslotAccordion;
