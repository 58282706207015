import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import * as actions from '../../store/actions/index';
import CloseTopNavButton from '../../SmallLayoutComponents/CloseTopNavButton';
import {disconnectFromNotifications} from '../../Api/socketApi';
import colors from '../../CSS/_variables.module.scss';
import '../../CSS/myAccount.scss';
import {ReactComponent as LogoutIcon} from '../../Images/svg/log-out.svg';
import {ReactComponent as LogOutIconMobile} from '../../Images/svg/log-out-mobile.svg';
import {preventDefaultDrag} from '../../Utils/utils';

class MyAccount extends React.Component {
    logoutUser = () => {
        localStorage.clear();
        // if user logged out we should also disconnect him from notifications
        disconnectFromNotifications(this.props.user._id);
        this.props.onLogoutUser();
    };

    handleEnterKey = (tab) => (e) => {
        const {eventSlug} = this.props;
        if (e.key === 'Enter') {
            if (tab === 'profile') return this.props.history.push(`/event/${eventSlug}/my-account`);
            if (tab === 'logout') return this.logoutUser();
        }
    };

    render() {
        const {eventSlug, user, mobile, isTablet, translation} = this.props;
        return (
            <div
                id="account-drop"
                className={`my-account-component dropdown-component ${mobile || isTablet ? 'my-account-mobile' : ''}`}
            >
                <CloseTopNavButton />
                <div onDragStart={preventDefaultDrag} className="page-title">
                    <h3>{translation?.myAccountDropdown.title}</h3>
                </div>
                <div onDragStart={preventDefaultDrag} className="myaccount-container">
                    <div
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey('profile')}
                        onDragStart={preventDefaultDrag}
                        className="row"
                    >
                        <div onDragStart={preventDefaultDrag} className="profile-image-container">
                            <img
                                src={process.env.REACT_APP_AVATAR_FOLDER + user.avatarSmall}
                                alt={user.avatar}
                                className="avatar"
                            />
                        </div>
                        <div>
                            <span>
                                {user.first} {user.last}
                            </span>
                            <Link tabIndex="-1" className="row" to={{pathname: `/event/${eventSlug}/my-account`}}>
                                {translation?.myAccountDropdown.viewProfileButton}
                            </Link>
                        </div>
                    </div>
                    <div
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey('logout')}
                        onDragStart={preventDefaultDrag}
                        className="row logout-row"
                    >
                        {mobile || isTablet ? (
                            <div
                                onDragStart={preventDefaultDrag}
                                onClick={this.logoutUser}
                                className="profile-image-container logout-icon"
                            >
                                <p>{translation?.myAccountDropdown.logoutButton}</p>
                                <LogOutIconMobile fill={colors.secondary} width="24" height="24" />
                            </div>
                        ) : (
                            <>
                                <div
                                    onDragStart={preventDefaultDrag}
                                    onClick={this.logoutUser}
                                    className="profile-image-container logout-icon"
                                >
                                    <LogoutIcon fill={colors.grey} alt={user.avatar} width="24" height="24" />{' '}
                                </div>
                                <div>
                                    <span onDragStart={preventDefaultDrag} draggable="false" onClick={this.logoutUser}>
                                        {translation?.myAccountDropdown.logoutButton}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        loggedIn: state.user.loggedIn,
        isTablet: state.layout.isTablet,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
