import React, {PureComponent} from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import BrandingBackgroundImage from './LayoutComponents/BrandingBackgroundImage';
import ColorPicker from './LayoutComponents/ColorPicker';
import TranslateIcon from '@material-ui/icons/Translate';
import Confirm from '../../../Dialogs/Confirm';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as SaveIcon} from '../../../Images/svg/save.svg';
import isEqual from 'lodash/isEqual';
import {fireClickEvent} from 'Utils/utils';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {CircularProgress} from '@material-ui/core';

class BrandingLogin extends PureComponent {
    constructor(props) {
        super(props);

        const {availableLanguages} = props;
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            fields: [
                {
                    name: 'loginDescription',
                    value: '',
                    description: 'Description is limited up to 500 characters',
                    adornment: '',
                    label: 'Description',
                    multiline: 6,
                    validators: ['maxStringLength: 500'],
                    errorMessages: ['You have reached the maximum limit of characters allowed (500 characters)'],
                },
            ],
            initialDescription: null,
            buttonDisabled: true,
            isMultiLanguage: availableLanguages?.length > 1,
            openConfirmUnsavedChanges: false,
            navigationElement: null,
        };
    }

    componentDidMount() {
        this.initState();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (
            !isEqual(prevProps.brandingTranslations?.data, this.props.brandingTranslations?.data) ||
            prevProps.brandingLanguage !== this.props.brandingLanguage
        ) {
            this.setEventBranding();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    initState = () => {
        const {branding} = this.props;
        if (branding?.data) {
            this.setEventBranding();
        }
    };

    setEventBranding = () => {
        const {brandingTranslations} = this.props;
        let brandingData = brandingTranslations.data.find(
            (translation) => translation.language === this.props.brandingLanguage
        );

        if (brandingData) {
            this.setState({
                fields: [{...this.state.fields[0], value: brandingData.loginDescription}],
                buttonDisabled: true,
                initialDescription: brandingData.loginDescription,
            });
        }
    };

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({
                    buttonDisabled:
                        !isValid || this.state.fields[0].value.trim() === this.state.initialDescription.trim(),
                });
            });
        });
    };

    saveEventTranslation = () => {
        const {navigationElement} = this.state;

        let data = {};
        this.state.fields.forEach((field) => {
            data[field.name] = field.value;
        });
        this.props.saveEventTranslation(data);
        this.setState({openConfirmUnsavedChanges: false});

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.initState();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) =>
            pathElem.getAttribute?.('data-is-navigation')
        );

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current?.contains(e.target)) {
            if (!this.state.buttonDisabled) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }
    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
    };
    render() {
        const {isMultiLanguage, openConfirmUnsavedChanges} = this.state;
        const {branding, loadingSaveEventBranding} = this.props;
        return (
            <>
                <div className="branding-lobby">
                    <h4 className="advanced-options-title">LOGIN PAGE INFO</h4>
                    <div className="advanced-options-container">
                        {/*<div ref={this.wrapperRef}>
                            <ValidatorForm ref="form" onSubmit={this.saveEventTranslation}>
                                {this.state.fields.map((field, index) => {
                                    return (
                                        <div className="options-container" key={field.name}>
                                            {loadingSaveEventBranding ? (
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <CircularProgress color="primary" />
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="single-option-container">
                                                        <TextValidator
                                                            classes={{root: 'setting-input scroll-helper-gdpr'}}
                                                            label={field.label}
                                                            type="text"
                                                            name={field.name}
                                                            index={index}
                                                            value={field.value}
                                                            onChange={this.handleChange(index)}
                                                            validators={field.validators}
                                                            errorMessages={field.errorMessages}
                                                            multiline={field.multiline > 0}
                                                            rows={field.multiline}
                                                            fullWidth={true}
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment:
                                                                    isMultiLanguage && field.adornment !== undefined ? (
                                                                        <TranslateIcon />
                                                                    ) : (
                                                                        field.adornment
                                                                    ),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="input-description">
                                                        <p>{field.description}</p>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                                <div className="action-container">
                                    <Button
                                        type="submit"
                                        variant={'contained'}
                                        disabled={this.state.buttonDisabled || loadingSaveEventBranding}
                                        startIcon={<SaveOutlinedIcon />}
                                        disableElevation
                                        color={'secondary'}
                                    >
                                        SAVE
                                    </Button>
                                </div>
                            </ValidatorForm>
                        </div>*/}

                        <p className="inner-options-title">LOGIN BACKGROUND IMAGE</p>
                        <div className="branding-background-image-container">
                            <BrandingBackgroundImage
                                for={'loginBackground'}
                                label={'Background'}
                                image={branding?.data?.loginBackground}
                                filesUrl={branding?.data?.filesUrl}
                                imageRatio={2.84} // Update the imageRatio value
                                dragDropOrientation="portrait"
                                openSuccessSnackbar={this.props.handleOpenSuccessSnackbar}
                                openErrorSnackbar={this.props.handleOpenErrorSnackbar}
                            />
                            {/*   <ColorPicker
                                for={'overlayColorLogin'}
                                color={colors.secondaryOpacity70}
                                saveEventBranding={this.props.saveEventBranding}
                                loading={loadingSaveEventBranding}
                            />*/}
                        </div>
                    </div>
                </div>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.saveEventTranslation}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        brandingLanguage: state.languages.organizerBrandingLanguage,
        branding: state.event.branding,
        brandingTranslations: state.event.brandingTranslations,
    };
};

export default connect(mapStateToProps)(BrandingLogin);
