import Button from '@material-ui/core/Button';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Confirm from '../../../Dialogs/Confirm';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import * as actions from '../../../store/actions';
import axios from '../../../store/axios-instance';
import {preventDefaultDrag, fireClickEvent} from 'Utils/utils';
import { verifyFileType } from 'Utils/verifyFileType';
import {CircularProgress} from "@material-ui/core";

class LobbyLogos extends PureComponent {
    state = {
        newLogoFile: null,
        imageLogoPreviewUrl: null,
        imageLogoErrorText: '',
        openConfirmUnsavedChangesSecond: false,
        navigationElement: null,
        loading: false,
    };

    wrapperRefSecond = React.createRef();
    handleClickOutsideSecond = this.handleClickOutsideSecond.bind(this);

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideSecond);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideSecond);
    }

    closeClickOutsideSecond = () => this.setState({openConfirmUnsavedChangesSecond: false});

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return document.getElementById('logoUpload').click();
    };

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({buttonDisabled: !isValid});
            });
        });
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChangesSecond} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChangesSecond) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutsideSecond(e) {
        if (this.wrapperRefSecond && !this.wrapperRefSecond.current.contains(e.target)) {
            if (
                this.state.imageLogoPreviewUrl &&
                !this.state.imageLogoErrorText.length > 0 &&
                this.props.image !== null
            ) {
                this.setState({openConfirmUnsavedChangesSecond: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    removeLogo = (logoId) => () => {
        const {eventId} = this.props;
        const data = {
            brandingProperty: 'lobbyLogo',
            imageId: logoId,
        };
        axios({method: 'delete', url: `/event/v2/${eventId}/branding/delete-image/`, data: data})
            .then((response) => {
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    updateLogo = () => {
        this.setState({loading: true});
        const {eventId} = this.props;
        const {navigationElement} = this.state;

        const formData = new FormData();
        formData.append('brandingProperty', 'lobbyLogo');
        formData.append('alt', '');
        formData.append('image', this.state.newLogoFile, this.state.newLogoFile.name, this.state.newLogoFile.type);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios({method: 'post', url: `/event/${eventId}/branding/upload-image/`, data: formData, config})
            .then((response) => {
                const brandingData = response.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.setState({
                    newLogoFile: null,
                    imageLogoPreviewUrl: null,
                    openConfirmUnsavedChangesSecond: false,
                });
                this.props.handleOpenSuccessSnackbar();
                this.setState({loading:false});
                if (navigationElement) {
                    fireClickEvent(navigationElement);
                }
            })
            .catch(() => {
                this.props.handleOpenErrorSnackbar();
                this.setState({loading: false});
            });
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutsideSecond();
        this.setState({
            newLogoFile: null,
            imageLogoPreviewUrl: null,
            imageLogoErrorText: '',
        });

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleLogoChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (!isValid) {
            this.setState({imageLogoErrorText: 'File too large. 2Mb max file size.'});
        }

        const typeValid = verifyFileType(file.type, 'image');
        isValid = typeValid && isValid;
        if (!typeValid) {
            this.setState({
                imageLogoErrorText:
                    'File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.',
            });
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    newLogoFile: file,
                    imageLogoPreviewUrl: reader.result,
                    imageLogoErrorText: '',
                });
            }
        };

        reader.readAsDataURL(file);
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    Image = () => {
        const {loading, imageLogoPreviewUrl} = this.state;

        if(loading){
            return(
                <div className="d-flex align-items-center justify-content-center">
                    <CircularProgress color="primary"/>
                </div>
            )
        }

        if(imageLogoPreviewUrl) {
            return(
                <>
                    <img
                        draggable="false"
                        onDragStart={preventDefaultDrag}
                        src={imageLogoPreviewUrl}
                        alt="preview"
                    />
                    <label
                        tabIndex="0"
                        onKeyDown={this.handleEnterKey}
                        htmlFor="logoUpload"
                        className="edit-label banner-upload"
                    >
                        <EditOutlinedIcon color={'secondary'} />
                    </label>
                </>
            )
        }

        return(
            <label
                tabIndex="0"
                onKeyDown={this.handleEnterKey}
                htmlFor="logoUpload"
                className="upload-label banner-upload"
            >
                <PublishIcon />
                <span>Click here to upload</span>
            </label>
        )
    }

    render() {
        const {newLogoFile, imageLogoErrorText, openConfirmUnsavedChangesSecond} = this.state;
        const {image, branding} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="branding-lobby-content">
                <h4 className="advanced-options-title">LOGOS</h4>
                <div
                    onDragStart={preventDefaultDrag}
                    ref={this.wrapperRefSecond}
                    className="advanced-options-container"
                >
                    <div>
                        <div onDragStart={preventDefaultDrag} className="logos-list-container">
                            {branding?.data?.lobbyLogos?.length > 0 && (
                                <div onDragStart={preventDefaultDrag} className="lobby-logos-container">
                                    {branding.data.lobbyLogos.map((lobbyLogo) => {
                                        return (
                                            <div className="branding-background-image-container" key={lobbyLogo._id}>
                                                <div onDragStart={preventDefaultDrag} className="options-container">
                                                    <div
                                                        onDragStart={preventDefaultDrag}
                                                        className="single-option-container"
                                                    >
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="actions-container-noP"
                                                        >
                                                            <Button
                                                                type="button"
                                                                variant="outlined"
                                                                onClick={this.removeLogo(lobbyLogo._id)}
                                                                startIcon={<ClearOutlinedIcon/>}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </div>
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="manage-partner-logo"
                                                        >
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="logo-container"
                                                            >
                                                                <div
                                                                    onDragStart={preventDefaultDrag}
                                                                    className="image-wrapper"
                                                                >
                                                                    <div
                                                                        onDragStart={preventDefaultDrag}
                                                                        className="current-logo-container cover-img-container-noM"
                                                                    >
                                                                        <img
                                                                            src={`${branding.data.filesUrl}${lobbyLogo.image}`}
                                                                            alt="logo"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onDragStart={preventDefaultDrag} className="input-description">
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div>
                            <p>
                                <span>
                                    {newLogoFile || (imageLogoErrorText.length > 0 && image) ? (
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="form-upload-picture"
                                        >
                                            Logo:{' '}
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="secondary-color"
                                            >
                                                {imageLogoErrorText.length > 0 ? (
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="error-light"
                                                    >
                                                        error
                                                    </span>
                                                ) : (
                                                    newLogoFile.name
                                                )}
                                            </span>
                                        </span>
                                    ) : null}
                                    {!newLogoFile && !image ? (
                                        <>
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="form-upload-picture"
                                            >
                                                Logo:{' '}
                                                {imageLogoErrorText.length > 0 ? (
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="error-light"
                                                    >
                                                        error
                                                    </span>
                                                ) : (
                                                    <span
                                                        onDragStart={preventDefaultDrag}
                                                        draggable="false"
                                                        className="grey-color"
                                                    >
                                                        no image
                                                    </span>
                                                )}
                                            </span>
                                        </>
                                    ) : null}
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="error-message">
                                        {imageLogoErrorText}
                                    </span>
                                </span>
                            </p>
                            <p className="image-explain">
                                Recommended image ratio 1.8:1 (landscape-oriented, e.g. 360 x 200 pixels)
                            </p>
                            <div onDragStart={preventDefaultDrag} className="image-wrapper">
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="current-banner-container cover-img-container"
                                >
                                    {this.Image()}
                                </div>
                            </div>
                            <div onDragStart={preventDefaultDrag} className="action-container">
                                <Button
                                    type="button"
                                    variant="contained"
                                    color={'secondary'}
                                    disableElevation
                                    onClick={this.updateLogo}
                                    disabled={
                                        !this.state.imageLogoPreviewUrl
                                        || this.state.imageLogoErrorText.length > 0
                                        || this.state.loading
                                    }
                                    startIcon={<SaveOutlinedIcon/>}
                                >
                                    Save
                                </Button>
                            </div>
                            <input
                                id="logoUpload"
                                type="file"
                                onChange={this.handleLogoChange}
                                className="upload-image-button d-none"
                            />
                        </div>
                    </div>
                </div>
                {openConfirmUnsavedChangesSecond && (
                    <Confirm
                        open={openConfirmUnsavedChangesSecond}
                        closeConfirm={this.closeClickOutsideSecond}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.updateLogo}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        branding: state.event.branding,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LobbyLogos);
