import './HomepageTab.scss';

const HomepageTab = ({action, classNames = '', text}) => {
    return(
        <div className={`tab-name ${classNames}`} onClick={action}>
            <span>{text}</span>
        </div>
    )
}

export default HomepageTab;