import * as actionTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    capture: null,
    selectedAudioDeviceId: '',
    selectedVideoDeviceId: '',
};

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_USER_MEDIA_STREAM_CAPTURE:
                draft.capture = action.payload;
                break;

            case actionTypes.SET_USER_MEDIA_STREAM_AUDIO_DEVICE_ID:
                draft.selectedAudioDeviceId = action.payload;
                break;
    
            case actionTypes.SET_USER_MEDIA_STREAM_VIDEO_DEVICE_ID:
                draft.selectedVideoDeviceId = action.payload;
                break;

            default:
                break;
        }
    })

export default reducer;