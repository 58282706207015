import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import './EmailCodeValidation.scss';
import AuthCode from 'react-auth-code-input';
import InfoIcon from '@material-ui/icons/Info';
import {ReactComponent as UserCreatedImage} from 'Images/svg/matching-loader.svg';
import {ReactComponent as EmailConfirmedSuccess} from 'Images/svg/email-confirmed-success-arrow.svg';
import Button from '@material-ui/core/Button';
import TextWithTooltipOnOverflow from 'Components/TextWithTooltipOnOverflow/TextWithTooltipOnOverflow';

class EmailCodeValidation extends PureComponent {
    state = {
        codeExpiresTimerId: null, // timerId
        secondsUntilCodeExpires: 900, // number of seconds for 15 minutes
        circleTimer: 120,
        resendButtonTimerId: null, // timerId
        circleTimerId: null, // timerId
        secondsUntilDisplayResendButton: 30, // number of seconds
    };

    componentDidMount() {
        this.startTimers();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.secondsUntilCodeExpires === 0) {
            this.stopCodeExpiresTimer();
        }
        if (this.state.secondsUntilDisplayResendButton === 0) {
            this.stopResendButtonTimer();
        }
        if (this.state.circleTimer === 0) {
            this.stopCircleTimer();
        }
    }

    componentWillUnmount() {
        this.stopTimers();
    }

    startTimers = () => {
        this.startCodeExpiresTimer();
        this.startResendButtonTimer();
        this.startCircleTimer();
    };

    stopTimers = () => {
        this.stopCodeExpiresTimer();
        this.stopResendButtonTimer();
        this.stopCircleTimer();
    };

    startCodeExpiresTimer = () => {
        const codeExpiresTimerId = setInterval(this.tickCodeExpires, 1000);
        this.setState({
            codeExpiresTimerId: codeExpiresTimerId,
        });
    };
    startCircleTimer = () => {
        const circleTimerId = setInterval(this.tickCircleTimer, 300);
        this.setState({
            circleTimerId: circleTimerId,
        });
    };
    stopCodeExpiresTimer = () => {
        if (this.state.codeExpiresTimerId) {
            clearInterval(this.state.codeExpiresTimerId);
            this.setState({
                codeExpiresTimerId: null,
            });
        }
    };

    startResendButtonTimer = () => {
        const resendButtonTimerId = setInterval(this.tickDisplayResendButton, 1000);
        this.setState({
            resendButtonTimerId: resendButtonTimerId,
        });
    };
    stopCircleTimer = () => {
        if (this.state.circleTimerId) {
            clearInterval(this.state.circleTimerId);
            this.setState({
                circleTimerId: null,
            });
        }
    };

    stopResendButtonTimer = () => {
        if (this.state.resendButtonTimerId) {
            clearInterval(this.state.resendButtonTimerId);
            this.setState({
                resendButtonTimerId: null,
            });
        }
    };
    tickCodeExpires = () => {
        this.setState({
            secondsUntilCodeExpires: this.state.secondsUntilCodeExpires - 1,
        });
    };

    tickCircleTimer = () => {
        this.setState({
            circleTimer: this.state.circleTimer - 1,
        });
    };

    tickDisplayResendButton = () => {
        this.setState({
            secondsUntilDisplayResendButton: this.state.secondsUntilDisplayResendButton - 1,
        });
    };

    formatTime = (secs) => {
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        return `${minutes < 10 ? '0' : ''}${minutes === '0' ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${
            seconds === '0' ? '0' : ''
        }${seconds}`;
    };

    resendVerificationCode = () => {
        const {comeFrom, onSubmitEmailChange} = this.props;
        comeFrom === 'emailChange' && onSubmitEmailChange();

        // make api call to resend the code
        this.props.createRegistrationCode();
        // if success, reset both the timers
        this.stopTimers();
        this.props.onRegisterUserFail('');
        this.setState(
            {
                secondsUntilCodeExpires: 900,
                secondsUntilDisplayResendButton: 30,
                circleTimer: 120,
            },
            this.startTimers
        );
        const codeInputButtons = document.getElementsByClassName('single-digit-input');
        if (codeInputButtons) {
            for (let i = 0; i < codeInputButtons?.length; i++) {
                codeInputButtons[i].value = '';
            }
        }
        codeInputButtons[0]?.focus();
    };

    handleInputChange = (code) => {
        if (code.length === 6) {
            this.props.handleRegister(code);
        } else {
            this.props.onRegisterUserFail('');
        }
    };

    render() {
        const {
            translation,
            defaultTranslation,
            displayUserCreatedScreen,
            comeFrom,
            newEmail,
            platformLanguage,
            displayEmailValidation,
            errorFromComponent,
        } = this.props;
        const {secondsUntilCodeExpires, secondsUntilDisplayResendButton, circleTimer} = this.state;
        const codeExpired = secondsUntilCodeExpires === 0;
        const displayResendButton = codeExpired || secondsUntilDisplayResendButton === 0;
        const enteredCodeIsInvalid = 'Entered code is invalid.';
        return (
            <div
                className={`email-code-confirmation ${displayEmailValidation ? 'verify' : ''} ${
                    this.props.error || errorFromComponent ? 'has-error' : ''
                }`}
            >
                {displayUserCreatedScreen ? (
                    <div className="user-created-confirmation-container">
                        {comeFrom === 'emailChange' ? '' : <EmailConfirmedSuccess className={'check-icon'} />}
                        <UserCreatedImage />
                    </div>
                ) : (
                    <>
                        {!codeExpired && (
                            <div className="code-container">
                                {comeFrom === 'emailChange' ? (
                                    <div className={'email-change-validation-head'}>
                                        <p>
                                            {translation?.emailUpdate?.verificationCodeSent ||
                                                defaultTranslation?.emailUpdate?.verificationCodeSent}{' '}
                                            <TextWithTooltipOnOverflow
                                                text={newEmail}
                                                textClasses={`${
                                                    platformLanguage === 'ar' && 'ar-helper'
                                                } user-mail-helper`}
                                            />
                                        </p>
                                    </div>
                                ) : (
                                    <p className={'enter-code-label'}>
                                        <span>
                                            {translation?.emailCodeVerification?.continueRegistration ||
                                                defaultTranslation?.emailCodeVerification?.continueRegistration}
                                        </span>
                                        <span className={'enter-code-text'}>
                                            {translation?.emailCodeVerification?.enterCodeBelow ||
                                                defaultTranslation?.emailCodeVerification?.enterCodeBelow}
                                        </span>
                                    </p>
                                )}

                                <div className="code-input-container">
                                    <AuthCode
                                        characters={6}
                                        onChange={this.handleInputChange}
                                        containerClassName="code-input"
                                        inputClassName="single-digit-input"
                                        allowedCharacters={'^[0-9]'}
                                    />
                                    {/* error when trying to register user/ probably code invalid*/}
                                    {(this.props.error || errorFromComponent) && (
                                        <div className={'error-container'}>
                                            <div className="triangle" />
                                            <InfoIcon />
                                            {this.props.error === enteredCodeIsInvalid ||
                                            errorFromComponent === enteredCodeIsInvalid ? (
                                                <p>
                                                    <span>
                                                        {translation?.emailCodeVerification?.codeError ||
                                                            defaultTranslation?.emailCodeVerification?.codeError}
                                                    </span>
                                                    <span>
                                                        {translation?.emailCodeVerification?.codeErrorFix ||
                                                            defaultTranslation?.emailCodeVerification?.codeErrorFix}
                                                    </span>
                                                </p>
                                            ) : (
                                                <p>{this.props.error || errorFromComponent}</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className={`resend-container ${codeExpired ? 'expired' : ''}`}>
                            {codeExpired ? (
                                <span className={'resend-text'}>
                                    {translation?.emailCodeVerification?.codeExpired ||
                                        defaultTranslation?.emailCodeVerification?.codeExpired}
                                </span>
                            ) : (
                                <span className={'resend-text'}>
                                    {translation?.emailCodeVerification?.codeNotReceived ||
                                        defaultTranslation?.emailCodeVerification?.codeNotReceived}
                                </span>
                            )}

                            <div className="resend-button-container">
                                <Button
                                    type={'button'}
                                    size={'medium'}
                                    disabled={!displayResendButton}
                                    className={`${displayResendButton ? 'active-color' : ''}`}
                                    onClick={this.resendVerificationCode}
                                >
                                    <span>
                                        {codeExpired ? (
                                            <>
                                                {translation?.emailCodeVerification?.resendCodeLabel ||
                                                    defaultTranslation?.emailCodeVerification?.resendCodeLabel}
                                            </>
                                        ) : (
                                            <>
                                                {translation?.emailCodeVerification?.resendLabel ||
                                                    defaultTranslation?.emailCodeVerification?.resendLabel}
                                            </>
                                        )}
                                    </span>
                                </Button>
                            </div>
                            {secondsUntilDisplayResendButton > 0 && (
                                <div className={`circle-main`}>
                                    <div className={'circle-container'}>
                                        <svg
                                            className={`circle-svg`}
                                            width={'34px'}
                                            height={'34px'}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <defs>
                                                <linearGradient id="GradientColor">
                                                    <stop offset="0%" stopColor="#DA22FF" />
                                                    <stop offset="100%" stopColor="#9733EE" />
                                                </linearGradient>
                                            </defs>
                                            <circle
                                                cx="17"
                                                cy="17"
                                                r="16"
                                                className={`count-down`}
                                                strokeDasharray="120"
                                                strokeDashoffset={circleTimer}
                                                strokeLinecap="round"
                                            ></circle>
                                        </svg>
                                        <div className={`circle-bg`}></div>
                                    </div>

                                    <h3 className={`circle-countdown`}>{secondsUntilDisplayResendButton}</h3>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.user.error,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
        platformLanguage: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRegisterUser: (user) => dispatch(actions.registerUser(user)),
        onRegisterUserFail: (message) => dispatch(actions.registerUserFail(message)),
        onClearError: () => dispatch(actions.clearError()),
        seeLogInForm: () => dispatch(actions.seeLogInForm()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCodeValidation);
