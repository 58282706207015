import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Confirm from '../../../Dialogs/Confirm';
import {preventDefaultDrag, fireClickEvent} from '../../../Utils/utils';
import './AddEditHomepagePreviousEventStyles.scss';
import { isLinkRule } from './../../../Utils/validationRules';
import Spinner from "../../../SmallLayoutComponents/Spinner";

class AddEditHomepagePreviousEvent extends React.Component {
    wrapperRef = React.createRef();
    handleClickOutside = this.handleClickOutside.bind(this);
    componentDidMount() {
        if (this.props.previousEventId) {
            this.setPreviousEventData();
        }
        ValidatorForm.addValidationRule('isWebPageUrl', (value) => {
            let rule = isLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isWebPageUrl');
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    state = {
        fields: [
            {
                name: 'label',
                value: '',
                label: 'Label',
                multiline: 0,
                validators: ['required', 'maxStringLength: 200'],
                errorMessages: ['This field is required', 'You have reached the maximum limit of characters allowed (200 characters)'],
            },
            {
                name: 'url',
                value: '',
                label: 'URL',
                multiline: 0,
                validators: ['required', 'isWebPageUrl'],
                errorMessages: ['This field is required', 'Please enter a valid link (https://example.com/)'],
            },
        ],
        buttonDisabled: true,
        openConfirmUnsavedChanges: false,
        navigationElement: null,
    };

    setPreviousEventData = () => {
        const previousEvent = this.props.previousEvents.find(
            (previousEvent) => previousEvent._id === this.props.previousEventId
        );
        this.setState({
            fields: [
                {...this.state.fields[0], value: previousEvent.label},
                {...this.state.fields[1], value: previousEvent.url},
            ],
        });
    };

    handleChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({buttonDisabled: !isValid});
            });
        });
    };
    //First Letter Control - First letter can not be the empty space
    checkFirstLetter = (input) => (e) => {
        if (input.length <= 0 && e.keyCode === 32) {
            e.preventDefault();
        }
        // return;
    };
    //----------------END First Letter Control
    handleAddEditPreviousEvent = () => {
        this.setState({loading: true});
        const {navigationElement} = this.state;

        let data = {};
        this.state.fields.forEach((field) => {
            data[field.name] = field.value;
        });

        if (this.props.previousEventId) {
            this.props.editPreviousEvent(data).then(() => {
                this.setState({loading: false});
            });
        } else {
            this.props.addNewPreviousEvent(data).then(() => {
                this.setState({loading: false});
            });
        }

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.props.handleCloseDialog();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (!this.state.buttonDisabled) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    closeClickOutside = () => {
        this.setState({openConfirmUnsavedChanges: false});
    };

    render() {
        const {open, handleCloseDialog, previousEventId} = this.props;
        let {fields, buttonDisabled, openConfirmUnsavedChanges, loading} = this.state;
        return (
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                PaperProps={{classes: {root: 'reverse-rtl'}}}
            >
                {loading && <Spinner />}
                <div onDragStart={preventDefaultDrag} ref={this.wrapperRef}>
                    <DialogTitle id="form-dialog-title">{previousEventId ? 'Edit' : 'Add'} previous event</DialogTitle>
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.handleAddEditPreviousEvent}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleAddEditPreviousEvent}
                        className="addPreviousEventDialog"
                    >
                        <DialogContent>
                            {/* <DialogContentText id="form-dialog-secondary">
                                Please {previousEventId ? 'edit' : 'add'} ev
                            </DialogContentText> */}
                            <Grid container className="smallContainer" alignItems="center">
                                {fields.map((field, index) => {
                                    return (
                                        <Grid item xs={12} key={field.name}>
                                            <TextValidator
                                                label={field.label}
                                                type="text"
                                                name={field.name}
                                                index={index}
                                                value={field.value}
                                                onKeyDown={this.checkFirstLetter(field.value)}
                                                onChange={this.handleChange(index)}
                                                validators={field.validators}
                                                errorMessages={field.errorMessages}
                                                multiline={field.multiline > 0}
                                                rows={field.multiline}
                                                fullWidth={true}
                                                variant="outlined"
                                                margin="normal"
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </DialogContent>
                        <DialogActions className="justify-content-between padding-LR15">
                            <Button type="button" onClick={handleCloseDialog}>
                                Close
                            </Button>
                            <Button disabled={buttonDisabled} type="submit">
                                Save
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </div>
            </Dialog>
        );
    }
}

export default AddEditHomepagePreviousEvent;
