import React, {Component} from 'react';
import {connect} from 'react-redux';
import {preventDefaultDrag} from "../../../../Utils/utils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

class DisplaySize extends Component {

    state={
        displaySizeOptions: [
            {name: 'full-screen-size', value: 'full-screen-size', label: 'Full-screen size'},
            {name: 'small-size', value: 'small-size', label: 'Small size'},
        ],
    }

    render() {
        const {displaySizeOptions} = this.state;
        const {displaySize, handleChangeDisplaySize, selectDropDownRef} = this.props
        const menuProps = {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "left"
            },
            getContentAnchorEl: null,
            ref: selectDropDownRef,
        };
        return (
            <div onDragStart={preventDefaultDrag} className="options-container">
                <div
                    onDragStart={preventDefaultDrag}
                    className="single-option-container"
                >
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className="setting-input"
                    >
                        <InputLabel>Display size</InputLabel>
                        <Select
                            label={'Display size'}
                            IconComponent={props => (
                                <ExpandMoreRoundedIcon {...props} className={`material-icons ${props.className}`}/>
                            )}
                            onChange={handleChangeDisplaySize}
                            value={displaySize}
                            MenuProps={menuProps}
                        >
                            {displaySizeOptions.map((displaySizeOption, index) => {
                                return (
                                    <MenuItem key={displaySizeOption.label} value={displaySizeOption.value}>
                                        {displaySizeOption.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div onDragStart={preventDefaultDrag} className="input-description">
                    <p>You can select small or full-screen size modals</p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(DisplaySize);
