import axios from "../axios-instance";
import * as actionTypes from "./actionTypes";


export const welcomeScreenApiCallStart = (data) => {
    return {
        type: actionTypes.WELCOME_SCREEN_API_CALL_START,
    };
};

export const welcomeScreenError = (data) => {
    return {
        type: actionTypes.WELCOME_SCREEN_ERROR,
        payload: data
    };
};


export const getEventWelcomeScreen = (eventId) => {
    return (dispatch) => {
        dispatch(welcomeScreenApiCallStart());

        return axios.get(`event/${eventId}/welcome-screen`)
            .then((response) => {
                let welcomeScreen = response.data.data;
                dispatch(getWelcomeScreenSuccess(welcomeScreen));
            })
            .catch((error) => {
                dispatch(welcomeScreenError(error));
            });
    };
};

export const getWelcomeScreenSuccess = (data) => {
    return {
        type: actionTypes.GET_WELCOME_SCREEN_SUCCESS,
        payload: data,
    };
};


export const updateEventWelcomeScreen = (eventId, welcomeScreenData) => {
    return (dispatch) => {
        dispatch(welcomeScreenApiCallStart());

        return axios({method: 'patch', url: `event/${eventId}/welcome-screen/`, data: welcomeScreenData})
            .then((response) => {
                let welcomeScreen = response.data.data;
                dispatch(getWelcomeScreenSuccess(welcomeScreen));
            })
            .catch((error) => {
                dispatch(welcomeScreenError(error));
            });
    };
};


export const saveEventWelcomeScreenImage = (eventId, imageData) => {
    return (dispatch) => {
        dispatch(welcomeScreenApiCallStart());

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios({method: 'post', url: `event/${eventId}/welcome-screen/image`, data: imageData, config})
            .then((response) => {
                let welcomeScreen = response.data.data;
                dispatch(getWelcomeScreenSuccess(welcomeScreen));
            })
            .catch((error) => {
                dispatch(welcomeScreenError(error));
            });
    };
};


export const deleteEventWelcomeScreenImage = (eventId, format) => {
    return (dispatch) => {
        dispatch(welcomeScreenApiCallStart());

        return axios({method: 'delete', url: `event/${eventId}/welcome-screen/image?format=${format}`})
            .then((response) => {
                let welcomeScreen = response.data.data;
                dispatch(getWelcomeScreenSuccess(welcomeScreen));
            })
            .catch((error) => {
                dispatch(welcomeScreenError(error));
            });
    };
};