import React, {useState, useMemo, useEffect} from 'react';
import SingleMeetingItemAgenda from './SingleMeetingItemAgenda';
import {isMobile, isMobileOnly} from 'react-device-detect';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'store/actions/index';
import {d, getAllMonthsTranslated, monthNames, getAllDaysTranslated, dayNames} from '../../../../Utils/utils';
import MeetingRequests from './MeetingRequests';

import {
    acceptSingleMeeting,
    allMeetingsAccept,
    cancelSingleMeeting,
    getMeetingsMyAgenda,
} from 'store/actions/myAgendaActions';
import EmptyList from './EmptyList';

import LoadingTimeslots from '../LoadingTimeslots';
import moment from 'moment';
import {usePlatformTranslation} from 'services/hooks';

const Meetings = ({isComponentActive, meetings: meetingsUser, loading, pastSlots, setDateForMobile}) => {
    const dispatch = useDispatch();

    /*translations*/
    const noMeetingsTranslation = usePlatformTranslation((state) => state?.agendaTranslations?.noMeetings);
    const noUpcomingMeetingsTranslation = usePlatformTranslation(
        (state) => state?.agendaTranslations?.noUpcomingMeetings
    );
    /*end translations*/

    //LOCALSTATES
    const [userMeetings, setUserMeetings] = useState([]);
    const [pendingReqs, setPendingReqs] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState(noMeetingsTranslation);
    const [dateIds, setDateIds] = useState([]);
    const [activeId, setActiveId] = useState();

    //STATES FROM REDUX
    const user = useSelector((state) => state?.user?.data);
    const event = useSelector((state) => state?.event?.data);
    const languages = useSelector((state) => state?.languages);

    //OPEN PRIVATE CHAT FUNCTION
    const openPrivateChatMyAgenda = (privateChatId) => {
        dispatch(actions.openPrivateChat(privateChatId));
    };
    //TIMEZONE VALUE DIFFERENCE
    const getTimezoneDifference = () => {
        if (user?.timezoneValue !== '') {
            return +user?.timezoneValue;
        }
        return event?.timezoneValue;
    };
    //FORMATTED TIMEZONES
    const getFormattedDateByTimezoneDifference = (date) => {
        let monthsInAllLangs = getAllMonthsTranslated(languages);
        let daysInAllLangs = getAllDaysTranslated(languages);
        const timezoneDifference = getTimezoneDifference();
        const timestampWithoutLocalTimezone = moment.utc(date).format('l LT');
        const calculatedTimestampBasedOnTimezoneDifference = moment(timestampWithoutLocalTimezone).add(
            timezoneDifference,
            'hours'
        );
        let day = calculatedTimestampBasedOnTimezoneDifference.format('DD');
        let dayOrder = dayNames.findIndex((el) => el === calculatedTimestampBasedOnTimezoneDifference.format('dddd'));
        let month = monthNames.findIndex((el) => el === calculatedTimestampBasedOnTimezoneDifference.format('MMMM'));
        let newMonth = monthsInAllLangs[month].charAt(0).toUpperCase() + monthsInAllLangs[month].slice(1);
        let year = calculatedTimestampBasedOnTimezoneDifference.format('YYYY');

        if (isMobileOnly) {
            return `${daysInAllLangs[dayOrder]}, ${day} ${newMonth}, ${year} `;
        } else {
            return `${day} ${newMonth}, ${year} `;
        }
    };

    const userMeetingList = useMemo(() => {
        let userMeets = [...meetingsUser];

        let newList = [];
        userMeets.forEach((item) => {
            let end = d(item?.end).getTime();
            let now = d().getTime();
            const itemDate = getFormattedDateByTimezoneDifference(item.start);
            let isObjExist = newList.find((el) => el.date === itemDate);

            if (isObjExist) {
                if ((item?.status === 'pending' && item?.owner?.user?._id === user?._id) || item?.status === 'accepted')
                    if (pastSlots) {
                        isObjExist.items = [...isObjExist.items, item];
                    } else {
                        if (now <= end) isObjExist.items = [...isObjExist.items, item];
                    }
            } else {
                if ((item?.status === 'pending' && item?.owner?.user?._id === user?._id) || item?.status === 'accepted')
                    if (pastSlots) {
                        newList.push({date: itemDate, itemDateAsNumber: d(item.start).getTime(), items: [item]});
                    } else {
                        if (now <= end)
                            newList.push({date: itemDate, itemDateAsNumber: d(item.start).getTime(), items: [item]});
                    }
            }
        });
        newList = newList.sort(function (a, b) {
            return a.itemDateAsNumber - b.itemDateAsNumber;
        });
        setUserMeetings(newList);
        setDateIds(newList.map((day) => day.itemDateAsNumber));
    }, [isComponentActive, meetingsUser]);

    //REQUESTED MEETING
    const userMeetingListRequested = useMemo(() => {
        let meetingsUserReq = [...meetingsUser];
        let reqList = meetingsUserReq.filter((el) => el.status === 'pending' && el?.owner?.user?._id !== user?._id);

        let sorted = reqList.sort(function (a, b) {
            return d(a.start).getTime() - d(b.start).getTime();
        });
        sorted = sorted.filter((el) => d(el.end).getTime() >= d().getTime());

        setPendingReqs(sorted);
    }, [isComponentActive, meetingsUser]);

    //FN : ACCEPTED ALL MEETINGS
    const acceptAllMeetings = () => () => {
        let meetingsUserReq = [...meetingsUser];
        let meetingIds = [];
        let reqList = meetingsUserReq.filter((el) => el.status === 'pending');

        reqList.forEach((item) => {
            meetingIds.push(item._id);
        });

        allMeetingsAccept(meetingIds)(dispatch).then(() =>
            getMeetingsMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch)
        );
    };

    //FN : CANCEL MEETING
    const cancelMeetingRequest = (singleMeeting) => () => {
        let meetingData = {
            meetingId: singleMeeting._id,
            firstUserId: singleMeeting.owner.user._id,
            secondUserId: singleMeeting.partner.user._id,
        };
        cancelSingleMeeting(meetingData)(dispatch).then(() =>
            getMeetingsMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch)
        );
    };

    //FN : ACCEPT MEETING
    const acceptMeetingRequest = (singleMeeting) => () => {
        let meetingData = {
            meetingId: singleMeeting._id,
            firstUserId: singleMeeting.partner?.user?._id,
            secondUserId: singleMeeting.owner?.user?._id,
        };
        acceptSingleMeeting(meetingData)(dispatch).then(() =>
            getMeetingsMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch)
        );
    };

    const setMessage = () => {
        let userMeetingsCopy = [...userMeetings];
        //should return meetings that is not in the past
        userMeetingsCopy = userMeetingsCopy
            ?.map((singleDay) => {
                return singleDay?.items?.filter((item) => !item.isPast);
            })
            .filter((item) => item.length > 0);
        if (userMeetings?.length > 0) {
            if (userMeetingsCopy.length > 0) {
                return;
            } else {
                setEmptyMessage(noUpcomingMeetingsTranslation);
            }
        } else {
            setEmptyMessage(noMeetingsTranslation);
        }
    };

    const isOnlyPast = () => {
        let userMeetingsCopy = [...userMeetings];
        //should return meetings that is not in the past
        userMeetingsCopy = userMeetingsCopy
            ?.map((singleDay) => {
                return singleDay?.items?.filter((item) => !item.isPast);
            })
            .filter((item) => item.length > 0);

        if (userMeetings?.length > 0) {
            if (userMeetingsCopy.length > 0) {
                return false; //there is upcoming meeting
            } else {
                return !pastSlots; //there is only past meeting
            }
        } else {
            return true; //there is no meeting at all
        }
    };

    useEffect(() => {
        setMessage();
    }, [userMeetings]);

    //HEADLINE CHANGES WITH SCROLL MOVES
    const isInViewport = (el) => {
        if (el !== null) {
            let rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    };

    const elementInViewport = () => {
        let newIds = dateIds.filter((id) => isInViewport(document.getElementById(id)));
        setActiveId(newIds[0]);
    };

    let mainContainer = document.querySelector('.items-main-container');

    if (mainContainer !== null && isMobileOnly) {
        mainContainer.addEventListener(
            'scroll',
            () => {
                elementInViewport();
            },
            {
                passive: true,
            }
        );
    }
    //END - HEADLINE CHANGES WITH SCROLL MOVES

    const sortedDays = (day) => {
        return day.items.sort(function (a, b) {
            return d(a.startTimestamp).getTime() - d(b.startTimestamp).getTime();
        });
    };
    return (
        <>
            {isOnlyPast() && pendingReqs.length === 0 ? (
                <EmptyList message={emptyMessage} />
            ) : loading ? (
                <LoadingTimeslots />
            ) : (
                <>
                    {pendingReqs.length > 0 && (
                        <MeetingRequests
                            userMeetingListRequested={userMeetingListRequested}
                            pendingReqs={pendingReqs}
                            acceptAllMeetings={acceptAllMeetings}
                            cancelMeetingRequest={cancelMeetingRequest}
                            acceptMeetingRequest={acceptMeetingRequest}
                        />
                    )}

                    <div className="all-sessions-and-meetings">
                        {/*map according to date*/}
                        {userMeetings.map((day, index) => {
                            sortedDays(day);
                            return (
                                <div
                                    key={day?.itemDateAsNumber}
                                    className={`related-date-items ${isMobile && 'mobile'}`}
                                >
                                    <h3 id={`${day?.itemDateAsNumber}`}>
                                        {day?.date[0]?.toUpperCase() + day?.date?.slice(1)}
                                    </h3>
                                    {index === 0 &&
                                        setDateForMobile(
                                            userMeetings?.find((item) => item?.itemDateAsNumber === activeId)?.date ||
                                                day?.date
                                        )}
                                    <div className="item-list">
                                        {sortedDays(day).map((singleMeeting, index) => {
                                            return (
                                                <SingleMeetingItemAgenda
                                                    openPrivateChatMyAgenda={() =>
                                                        openPrivateChatMyAgenda(singleMeeting?.privateChat)
                                                    }
                                                    singleMeeting={singleMeeting}
                                                    key={singleMeeting._id}
                                                    date={day.date}
                                                    cancelMeetingRequest={cancelMeetingRequest}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};

export default Meetings;
