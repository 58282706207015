import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import MatchingParticipantQuestion from './MatchingParticipantQuestion';
import NewUserCard from './NewUserCard';
import MatchingQuestionsCounter from './MatchingQuestionsCounter';
import {Button, Dialog, DialogTitle, DialogActions, DialogContent, Tooltip} from '@material-ui/core';
import {ValidatorForm} from 'react-material-ui-form-validator';
import colors from '../../../CSS/_variables.module.scss';
import {Close} from '@material-ui/icons';
import {ReactComponent as EmptyResults} from '../../../Images/svg/search_results.svg';
import {ReactComponent as StartChatArrow} from '../../../Images/svg/dir-curve2.svg';
import {sortFn} from '../../../Utils/utils';
import {isMobileSafari, isMobile} from 'react-device-detect';
import {ReactComponent as MatchingLoader} from '../../../Images/svg/matching-loader.svg';
import IconButton from '@material-ui/core/IconButton';
import './MatchingDialog.scss';

class MatchingDialog extends React.Component {
    state = {
        answers: [],
        showFinished: false,
        answered: false,
        startedAnswering: false,
        currentIndex: 0,
    };

    componentDidMount() {
        this.setState({startedAnswering: false});
    }

    handleStartAnswering = () => {
        const {eventMatching} = this.props;
        this.setState({
            startedAnswering: true,
            // if there's only 1 question show Finish button
            showFinished: eventMatching?.questions?.length === 1,
        });
    };

    handleSubmitAnswers = () => {
        const {
            eventId,
            userId,
            eventMatching,
            onSaveUpdateUserAnswers,
            onSetMatchingFormSeen,
            onGetUserEventProfile,
            closeBannerOnFinish,
        } = this.props;
        const {answers} = this.state;
        let answersClone = JSON.parse(JSON.stringify(answers));
        let data = {};
        // filters out empty answers (questions that have empty answerIds arrays)
        data.answers = answersClone.filter((answer) => answer.answerIds.length !== 0);
        onSaveUpdateUserAnswers(eventId, eventMatching?._id, data)
            .then(() =>
                onSetMatchingFormSeen(eventId).then(() => {
                    onGetUserEventProfile(eventId, userId);
                    closeBannerOnFinish();
                    this.handleHideMatchingTooltip();
                })
            )
            .catch(() => {});
    };

    handleHideMatchingTooltip = () => {
        const {eventSlug, user} = this.props;
        let dataToStorage = [];
        const setData = {
            event: eventSlug,
            userId: user?._id,
            tooltipDisplayed: true,
        };
        const matchingTooltipData = localStorage.getItem('matchingTooltipData');
        if (matchingTooltipData !== null && JSON.parse(matchingTooltipData).length > 0) {
            const matchingTooltipDataArray = JSON.parse(matchingTooltipData);
            let spreadArray = matchingTooltipDataArray;
            spreadArray.push(setData);
            dataToStorage = spreadArray;
        } else {
            dataToStorage.push(setData);
        }
        localStorage.setItem('matchingTooltipData', JSON.stringify(dataToStorage));
    };

    onNextClicked = (questionId) => () => {
        const {eventMatching} = this.props;
        const {answers, currentIndex} = this.state;
        let updatedAnswers = [...answers];
        // handle keep answers object in state
        let questionIndex = updatedAnswers.findIndex((answer) => answer.questionId === questionId);
        let qIdx = eventMatching?.questions?.findIndex((question) => question._id === questionId);
        let data = {};
        data.questionId = questionId;
        data.answerIds = [];
        // add question with empty answers to state if skipped
        // and doesn't already exist
        if (questionIndex < 0) updatedAnswers.splice(qIdx, 0, data);
        this.setState({
            // if there's only 1 question don't add to the currentIndex, otherwise you'll
            // get undefined question
            currentIndex: eventMatching?.questions?.length === 1 ? currentIndex : currentIndex + 1,
            showFinished:
                eventMatching?.questions?.length === 1
                    ? currentIndex
                    : currentIndex + 1 === eventMatching?.questions?.length - 1,
            // add question with empty answers to state if skipped
            answers: updatedAnswers,
        });
        document.getElementById('form-dialog-content').scroll(0, 0);
    };

    checkRequiredQuestion = (questionId, isRequired) => {
        const {answers} = this.state;
        const questionIdxInAnswers = answers.map((el) => el.questionId).indexOf(questionId);
        if (isRequired && (questionIdxInAnswers < 0 || answers[questionIdxInAnswers].answerIds.length <= 0))
            return true;
        return false;
    };

    goBackOneQuestion = () => {
        const {currentIndex, showFinished} = this.state;
        if (currentIndex - 1 < 0) return;
        if (showFinished) this.setState({showFinished: false});
        this.setState({currentIndex: currentIndex - 1});
        document.getElementById('form-dialog-content').scroll(0, 0);
    };

    handleAddChangeAnswers = (questionId, type) => (e) => {
        const {eventMatching} = this.props;
        const {answers} = this.state;
        let updatedAnswers = [...answers];
        // handle keep answers object in state
        let questionIndex = updatedAnswers.findIndex((answer) => answer.questionId === questionId);
        let qIdx = eventMatching?.questions?.findIndex((question) => question._id === questionId);
        let aIdx = eventMatching?.questions[qIdx]?.answers?.findIndex((answer) => answer.title === e.target.value);
        let answerId = eventMatching?.questions[qIdx]?.answers[aIdx]?._id;
        let data = {};
        data.questionId = questionId;
        data.answerIds = [];
        if (type === 'checkbox') {
            // if question answer is ALREADY in state then check if option was unchecked and remove
            // from answerIds array, else push the newly checked answer
            if (questionIndex !== -1) {
                let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex((aId) => aId === answerId);
                if (answerIndex !== -1) updatedAnswers[questionIndex].answerIds.splice(answerIndex, 1);
                if (answerIndex === -1) updatedAnswers[questionIndex].answerIds.push(answerId);
            } else {
                data.answerIds.push(answerId);
                updatedAnswers.splice(qIdx, 0, data);
            }
        } else {
            // if question answer is ALREADY in state then check if option was unchecked and remove
            // from answerIds array, else push the newly checked answer
            if (questionIndex !== -1) {
                let answerIndex = updatedAnswers[questionIndex].answerIds.findIndex((aId) => aId === answerId);
                if (answerIndex !== -1) updatedAnswers[questionIndex].answerIds = [];
                if (answerIndex === -1) updatedAnswers[questionIndex].answerIds = [answerId];
            } else {
                data.answerIds.push(answerId);
                updatedAnswers.splice(qIdx, 0, data);
            }
        }

        this.setState({answers: updatedAnswers});
    };

    handleSeeNetworking = () => {
        const {closeNoPrompt, handleSeeNetworking} = this.props;
        handleSeeNetworking();
        closeNoPrompt();
    };

    formDialogTitleValidation = () => {
        const headlines = document.querySelectorAll('#form-dialog-title h2');
        headlines?.forEach((singleHeadline) => {
            if (!singleHeadline.textContent.includes(' ')) {
                singleHeadline.classList.add('break-all');
            }
        });
    };

    render() {
        const {answers, currentIndex, showFinished, startedAnswering} = this.state;
        const {
            open,
            close,
            closeNoPrompt,
            translation,
            defaultTranslation,
            user,
            eventMatching,
            loadingGetMatches,
            noOpenNetworking,
            loadingGetQuestionnaire,
        } = this.props;
        // const loadingGetMatches = true

        const question = !!eventMatching.questions && eventMatching?.questions[currentIndex];
        const nextQuestionDisabled = this.checkRequiredQuestion(question._id, question.isRequired);
        // filter out the matches lower than MEDIUM and deleted / undefined users, slice so that we show max 3 users in the results
        // and sort them by highest score
        const filterSliceSortMatches = user?.matchingUsers
            ?.filter((user) => user.matchingLevel !== 'normal')
            .sort((a, b) => sortFn(Math.floor(b.score), Math.floor(a.score)))
            .slice(0, 3);
        this.formDialogTitleValidation();
        return (
            <Dialog
                open={open}
                aria-labelledby="form-dialog-title"
                PaperProps={{classes: {root: 'matching-dialog-paper'}}}
                // prevents click outside modal close
                onClose={(e, reason) => reason !== 'backdropClick' && close}
                // prevents Escape key modal close
                disableEscapeKeyDown
            >
                <ValidatorForm ref="form" className="matching-form-wrapper" onSubmit={() => {}}>
                    {loadingGetQuestionnaire && (
                        <div className="loading-container">
                            <MatchingLoader />
                        </div>
                    )}
                    <DialogTitle
                        id="form-dialog-title"
                        classes={{
                            root: `${startedAnswering ? 'question-answering' : ''} ${
                                !!user.matchingUsers ? 'received-response' : ''
                            } ${isMobileSafari ? 'mobile-safari' : ''}`,
                            label: '',
                        }}
                    >
                        {!!user.matchingUsers ? (
                            filterSliceSortMatches?.length === 0 ? (
                                <div className="noResultsTitle">
                                    {translation?.matching?.noResultsTitle ||
                                        defaultTranslation?.matching?.noResultsTitle}
                                </div>
                            ) : (
                                translation?.matching?.resultsTitle || defaultTranslation?.matching?.resultsTitle
                            )
                        ) : !!eventMatching ? (
                            eventMatching?.title?.length > 38 && isMobile ? (
                                <Tooltip
                                    arrow
                                    classes={{tooltip: 'ignore-rtl'}}
                                    tabIndex="0"
                                    placement="top"
                                    title={eventMatching?.title}
                                >
                                    <>{eventMatching?.title.substr(0, 37)}...</>
                                </Tooltip>
                            ) : (
                                eventMatching?.title
                            )
                        ) : (
                            'Matching questionnaire'
                        )}
                        <div className="matching-remove">
                            <IconButton
                                // check if user started answering questions in order to display Dialog close prompt
                                // if user startedAnswering but pressed the Finish (answered last question)
                                // and is on the results page, close Dialog without prompt
                                onClick={
                                    startedAnswering && filterSliceSortMatches?.length === undefined
                                        ? close
                                        : closeNoPrompt
                                }
                            >
                                <Close />
                            </IconButton>
                        </div>
                        {startedAnswering && !!!user.matchingUsers && (
                            <>
                                <MatchingQuestionsCounter
                                    len={eventMatching?.questions?.length}
                                    activeIndex={currentIndex + 1}
                                />
                                <div className="required-label">
                                    {question?.isRequired
                                        ? translation?.matching?.requiredQuestion ||
                                          defaultTranslation?.matching?.requiredQuestion
                                        : translation?.matching?.optionalQuestion ||
                                          defaultTranslation?.matching?.optionalQuestion}
                                </div>
                            </>
                        )}
                    </DialogTitle>
                    <DialogContent
                        id="form-dialog-content"
                        classes={{
                            root: `${startedAnswering ? 'content-answering' : ''} ${
                                loadingGetMatches ? 'is-loading' : ''
                            } ${!!user.matchingUsers ? 'show-results' : ''}`,
                        }}
                    >
                        {loadingGetMatches ? (
                            <>
                                <MatchingLoader />
                            </>
                        ) : !!user.matchingUsers ? (
                            <>
                                <div
                                    className={`matches-square ${
                                        filterSliceSortMatches?.length === 0 ? 'centered' : ''
                                    }`}
                                >
                                    {filterSliceSortMatches.map((match) => {
                                        return (
                                            <NewUserCard
                                                dialogCard
                                                key={match.user}
                                                userId={match.user}
                                                matchingLevel={match.matchingLevel}
                                                dialogClose={closeNoPrompt}
                                            />
                                        );
                                    })}
                                    {filterSliceSortMatches?.length === 2 && (
                                        <div className="one-empty">
                                            {translation?.matching?.noMoreMatches ||
                                                defaultTranslation?.matching?.noMoreMatches}
                                        </div>
                                    )}
                                    {filterSliceSortMatches?.length === 1 && (
                                        <div className="two-empty">
                                            {translation?.matching?.noMoreMatches ||
                                                defaultTranslation?.matching?.noMoreMatches}
                                        </div>
                                    )}
                                    {filterSliceSortMatches?.length === 0 && (
                                        <>
                                            <EmptyResults fill={colors.primary} />
                                            <div className="all-empty">
                                                {translation?.matching?.noResults ||
                                                    defaultTranslation?.matching?.noResults}
                                            </div>
                                        </>
                                    )}
                                    {filterSliceSortMatches?.length > 0 && (
                                        <div className="tablet-start-chat">
                                            <StartChatArrow />
                                            {translation?.matching?.clickStartChat ||
                                                defaultTranslation?.matching?.clickStartChat}
                                        </div>
                                    )}
                                </div>
                                {filterSliceSortMatches?.length !== 0 && (
                                    <div className="btw-responsive-text">
                                        {translation?.matching?.btwResultsResponsive ||
                                            defaultTranslation?.matching?.btwResultsResponsive}
                                    </div>
                                )}
                            </>
                        ) : !startedAnswering && !!eventMatching?.questions?.length ? (
                            <>
                                <div className="matching-intro-header">
                                    {!!eventMatching
                                        ? eventMatching?.description
                                        : translation?.matching?.matchingQuestionnaireIntro ||
                                          defaultTranslation?.matching?.matchingQuestionnaireIntro}
                                </div>
                                <div className="matching-user-cards">
                                    <NewUserCard displayOnly userId={user?._id} />
                                    <span className="user-empty-card" />
                                    <span className="user-empty-card" />
                                </div>
                                <div className="matching-intro-skip">
                                    {translation?.matching?.matchingQuestionnaireIntroSkip ||
                                        defaultTranslation?.matching?.matchingQuestionnaireIntroSkip}
                                </div>
                            </>
                        ) : (
                            <MatchingParticipantQuestion
                                answers={answers}
                                question={question}
                                questionIndex={currentIndex}
                                handleChange={this.handleAddChangeAnswers}
                            />
                        )}
                    </DialogContent>
                    <DialogActions
                        id="form-dialog-actions"
                        classes={{
                            root: `${loadingGetMatches ? 'is-loading' : ''} ${
                                !!user.matchingUsers ? 'show-results' : ''
                            }`,
                        }}
                    >
                        {loadingGetMatches ? (
                            <p className={'loading-matches-footer-text'}>
                                {translation?.networking?.matchingAlgo || defaultTranslation?.networking?.matchingAlgo}
                            </p>
                        ) : !!user.matchingUsers ? (
                            <div className={'matching-dialog-action-buttons'}>
                                <Button
                                    variant="outlined"
                                    color={'primary'}
                                    onClick={noOpenNetworking ? closeNoPrompt : this.handleSeeNetworking}
                                    type="button"
                                    classes={{root: 'iam-in-btn'}}
                                >
                                    {translation?.matching?.seeMatchesBtn ||
                                        defaultTranslation?.matching?.seeMatchesBtn}
                                </Button>
                                <span className="matching-note">
                                    <span className="note-secondary-bold">
                                        {translation?.matching?.resultsNoteLabel ||
                                            defaultTranslation?.matching?.resultsNoteLabel}
                                    </span>
                                    &nbsp;
                                    {translation?.matching?.resultsNoteText ||
                                        defaultTranslation?.matching?.resultsNoteText}
                                    <br />
                                    {translation?.matching?.resultsNoteMyAccount ||
                                        defaultTranslation?.matching?.resultsNoteMyAccount}
                                </span>
                            </div>
                        ) : !startedAnswering ? (
                            <div className={'matching-dialog-action-buttons'}>
                                <Button variant="outlined" onClick={closeNoPrompt} type="button">
                                    {translation?.matching?.doItLaterBtn || defaultTranslation?.matching?.doItLaterBtn}
                                </Button>
                                <Button
                                    type="button"
                                    variant={'contained'}
                                    color={'primary'}
                                    disableElevation
                                    onClick={this.handleStartAnswering}
                                >
                                    {translation?.generalText?.start || defaultTranslation?.generalText?.start}
                                </Button>
                            </div>
                        ) : (
                            <div className={'matching-dialog-action-buttons'}>
                                {currentIndex !== 0 && (
                                    <Button variant="outlined" onClick={this.goBackOneQuestion} type="button">
                                        {translation?.matching?.backBtn || defaultTranslation?.matching?.backBtn}
                                    </Button>
                                )}
                                {showFinished ? (
                                    <Button
                                        type="button"
                                        variant={'contained'}
                                        color={'primary'}
                                        disableElevation
                                        classes={{root: 'iam-in-btn'}}
                                        onClick={this.handleSubmitAnswers}
                                        disabled={nextQuestionDisabled}
                                    >
                                        {translation?.matching?.finishBtn || defaultTranslation?.matching?.finishBtn}
                                    </Button>
                                ) : (
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        disableElevation
                                        type="button"
                                        disabled={nextQuestionDisabled}
                                        classes={{root: 'iam-in-btn'}}
                                        onClick={this.onNextClicked(question._id)}
                                    >
                                        {translation?.matching?.nextBtn || defaultTranslation?.matching?.nextBtn}
                                    </Button>
                                )}
                            </div>
                        )}
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userId: state.user.data?._id,
        event: state.event.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        eventUsers: state.eventUsers,
        loadingGetQuestionnaire: state.organizer.loadingForm,
        eventMatching: state.organizer.eventMatching,
        loadingGetMatches: state.user.loadingGetMatches,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onSetMatchingFormSeen: (eventId) => dispatch(actions.setMatchingFormSeen(eventId)),
        onSaveUpdateUserAnswers: (eventId, questionnaireId, data) =>
            dispatch(actions.saveUpdateUserAnswers(eventId, questionnaireId, data)),
        onGetUserEventProfile: (eventId, userId) => dispatch(actions.getUserEventProfile(eventId, userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchingDialog);
