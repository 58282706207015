import './AnnouncementsBanner.scss';
import {useSelector, useDispatch} from 'react-redux';
import AnnouncementsBannerMobile from './AnnouncementsBannerMobile';
import AnnouncementsSliderBannerMobile from './AnnouncementsSliderBannerMobile';
import {useState, useEffect} from 'react';
import {setActiveExhibitorId} from 'store/actions/exhibitorsAction';
import {setActiveShowfloorId} from 'store/actions';
import {useHistory} from 'react-router-dom';
import {increaseAnnouncementClickCount} from 'store/actions/announcementsActions';
import BannerWithTransition from "./BannerWithTransition";

const AnnouncementsBannerOrIcon = ({announcements, isCollapsed, handleOpen, handleCollapse, location}) => {
    const [hasWall, setHasWall] = useState();

    const history = useHistory();
    const dispatch = useDispatch();

    const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);
    const sideNavigation = useSelector((state) => state.user.sideNavigation);
    const eventSlug = useSelector((state) => state.event.eventSlug);
    const eventId = useSelector((state) => state.event.eventId);
    const userId = useSelector((state) => state.user?.data?._id);
    const exhibitors = useSelector((state) => state.exhibitors.approvedExhibitors);

    // set wall state by location
    // needed for banner width
    useEffect(() => {
        if (isLargeScreen) {
            const wallElements = document.getElementsByClassName('booth-wall-container');
            switch (location) {
                case 'lobby':
                    setHasWall(sideNavigation.isOpened);
                    break;
                case 'auditoriums':
                    setHasWall(!!wallElements.length);
                    break;
                case 'exhibitHalls':
                    setHasWall(!!wallElements.length);
                    break;
                case 'archive':
                    setHasWall(false);
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [location, sideNavigation, exhibitors]);

    const handleCallToActionButtonClicked = (announcement) => {
        switch (announcement?.destinationType) {
            case 'externalURL':
                handleCollapse();
                window.open(announcement?.destinationExternalURL);
                break;

            case 'auditorium':
                handleCollapse();
                history.push({pathname: `/event/${eventSlug}/auditorium/${announcement?.destinationAuditorium}`});
                break;

            case 'exhibitBooth':
                setActiveShowfloorId(announcement?.destinationShowFloor);
                setActiveExhibitorId(announcement?.destinationExhibitBooth);
                handleCollapse();
                history.push({pathname: `/event/${eventSlug}/showfloor`});
                break;

            default:
                break;
        }

        // increase click count for statistics
        dispatch(increaseAnnouncementClickCount(eventId, announcement?._id, userId));
    };

    if (location !== 'other' && announcements.length) {

        if (isLargeScreen) {
            return <BannerWithTransition isCollapsed={isCollapsed} handleOpen={handleOpen}
                                         handleCollapse={handleCollapse} hasWall={hasWall}
                                         announcements={announcements}
                                         handleCallToActionButtonClicked={handleCallToActionButtonClicked}/>
        }

        if (announcements.length === 1) {
            return (
                <AnnouncementsBannerMobile
                    announcement={announcements[0]}
                    handleCollapse={handleCollapse}
                    handleCallToActionButtonClicked={handleCallToActionButtonClicked}
                />
            );
        }

        return (
            <AnnouncementsSliderBannerMobile
                announcements={announcements}
                handleCollapse={handleCollapse}
                handleCallToActionButtonClicked={handleCallToActionButtonClicked}
            />
        );
    }
    return null;
};

export default AnnouncementsBannerOrIcon;
