import eventVillageImage768 from '../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImage900 from '../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImage1080 from '../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImage1200 from '../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImage1440 from '../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImageFull from '../Images/backgrounds/village/AIM-Village.jpg';

export const getLobbyBackground = (windowView) => {

    const lobbyImages = [
        {
            resize_768: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/lobby_nl_768.jpg`,
            resize_900: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/lobby_nl_900.jpg`,
            resize_1080: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/lobby_nl_1080.jpg`,
            resize_1200: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/lobby_nl_1200.jpg`,
            resize_1440: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/lobby_nl_1440.jpg`,
            original: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/lobby_nl.jpg`,
        },
        {
            resize_768: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/windows_lobby_768.png`,
            resize_900: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/windows_lobby_900.png`,
            resize_1080: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/windows_lobby_1080.png`,
            resize_1200: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/windows_lobby_1200.png`,
            resize_1440: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/windows_lobby_1440.png`,
            original: `${process.env.REACT_APP_RENDERS_FOLDER}lobby/windows_lobby.png`,
        },
    ];

    let EventLobbyImage;

    let resizedImages = windowView ? lobbyImages[1] : lobbyImages[0];
    let heightResolution = window.screen.height * window.devicePixelRatio;

    switch (true) {
        case heightResolution < 769:
            EventLobbyImage = resizedImages.resize_768;
            break;
        case heightResolution < 901:
            EventLobbyImage = resizedImages.resize_900;
            break;
        case heightResolution < 1081:
            EventLobbyImage = resizedImages.resize_1080;
            break;
        case heightResolution < 1201:
            EventLobbyImage = resizedImages.resize_1200;
            break;
        case heightResolution < 1441:
            EventLobbyImage = resizedImages.resize_1440;
            break;
        default:
            EventLobbyImage = resizedImages.original;
    }
    return EventLobbyImage;
};

export const getVillageBackground = () => {
    let EventVillageImage;
    let heightResolution = window.screen.height * window.devicePixelRatio;
    switch (true) {
        case heightResolution < 769:
            EventVillageImage = eventVillageImage768;
            break;
        case heightResolution < 901:
            EventVillageImage = eventVillageImage900;
            break;
        case heightResolution < 1081:
            EventVillageImage = eventVillageImage1080;
            break;
        case heightResolution < 1201:
            EventVillageImage = eventVillageImage1200;
            break;
        case heightResolution < 1441:
            EventVillageImage = eventVillageImage1440;
            break;
        default:
            EventVillageImage = eventVillageImageFull;
            break;
    }
    return EventVillageImage;
};
