import * as actionTypes from './actionTypes';
import axios from '../axios-instance';
import {sortExhibitors} from '../../Utils/utils';

export const getExhibitors = (eventId) => {
    return (dispatch) => {
        dispatch(getExhibitorsStart());

        axios({method: 'get', url: `/event/${eventId}/exhibitors`})
            .then((response) => {
                let exhibitors = response.data.exhibitors;
                // sort the exhibitors alphabetically before sending them to the reducer;
                let sortedExhibitors = sortExhibitors(exhibitors);

                dispatch(getExhibitorsSuccess(exhibitors));
            })
            .catch((error) => {
                dispatch(getExhibitorsFail());
            });
    };
};

export const getShowfloorExhibitors = (eventId, showfloorId) => {
    return (dispatch) => {
        dispatch(getExhibitorsStart());

        axios({method: 'get', url: `/event/${eventId}/showfloors/${showfloorId}/exhibitors`})
            .then((response) => {
                let exhibitors = response.data.exhibitors;
                // sort the exhibitors alphabetically before sending them to the reducer;
                let sortedExhibitors = sortExhibitors(exhibitors);

                dispatch(getExhibitorsSuccess(exhibitors));
            })
            .catch((error) => {
                dispatch(getExhibitorsFail());
            });
    };
};

export const getBuildingExhibitors = (eventId, buildingId, privateBoothPreview) => (dispatch) => {
    dispatch(getExhibitorsStart());

    axios
        .get(`/event/${eventId}/building/${buildingId}`)
        .then((response) => {
            let exhibitors = response.data.building.exhibitors;
            // sort the exhibitors alphabetically before sending them to the reducer;
            let sortedExhibitors = sortExhibitors(exhibitors);

            dispatch(getExhibitorsSuccess(sortedExhibitors, privateBoothPreview));
        })
        .catch((error) => dispatch(getExhibitorsFail()));
};

export const getExhibitorsStart = () => {
    return {
        type: actionTypes.GET_EXHIBITORS_START,
    };
};
export const getExhibitorsSuccess = (exhibitors) => {
    return {
        type: actionTypes.GET_EXHIBITORS_SUCCESS,
        payload: exhibitors,
    };
};
export const getExhibitorsFail = () => {
    return {
        type: actionTypes.GET_EXHIBITORS_FAIL,
    };
};

export const setActiveExhibitorId = (exhibitorId) => {
    // used when the user refreshes the page to force the load of the same exhibitor
    localStorage.setItem('exhibitorId', exhibitorId);
    return {
        type: actionTypes.SET_ACTIVE_EXHIBITOR_ID,
        payload: exhibitorId,
    };
};
