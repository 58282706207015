import produce from 'immer';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    // editedShowfloorId is only used in the organizer dashboard
    editedShowfloorId: null,
    showfloors: [],
    showfloor: null,
    loading: false,
    error: '',
    activeShowfloorId: null,
    ordersOfShowfloors: [],
    reorderedShowfloors: [],
    message: '',
};

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.API_CALL_SHOWFLOORS_START:
                draft.loading = true;
                draft.error = '';
                break;
            case actionTypes.API_CALL_SHOWFLOORS_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;
            case actionTypes.SET_EDITED_SHOWFLOOR_ID:
                draft.editedShowfloorId = action.payload;
                break;
            case actionTypes.SET_ACTIVE_SHOWFLOOR_ID:
                draft.activeShowfloorId = action.payload;
                break;
            case actionTypes.GET_SHOWFLOORS_SUCCESS:
                draft.loading = false;
                draft.error = '';
                draft.showfloors = action.payload;
                break;
            case actionTypes.GET_SHOWFLOOR_SUCCESS:
                draft.loading = false;
                draft.error = '';
                draft.showfloor = action.payload;
                break;
            case actionTypes.CREATE_SHOWFLOOR_SUCCESS:
                draft.loading = false;
                draft.error = '';
                draft.showfloors.push(action.payload);
                break;
            case actionTypes.UPDATE_SHOWFLOOR_SUCCESS:
                draft.loading = false;
                draft.error = '';
                const updatedShowfloorIndex = draft.showfloors.findIndex(
                    (showfloor) => showfloor._id === action.payload._id
                );
                if (draft.showfloor._id === action.payload._id) {
                    draft.showfloor = action.payload;
                }
                draft.showfloors[updatedShowfloorIndex] = action.payload;
                break;
            case actionTypes.DELETE_SHOWFLOOR_SUCCESS:
                draft.loading = false;
                draft.error = '';
                draft.showfloors = draft.showfloors.filter((showfloor) => showfloor._id !== action.payload);
                break;
            case actionTypes.REORDER_SHOWFLOORS_REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            case actionTypes.REORDER_SHOWFLOORS_SUCCESS:
                draft.loading = false;
                draft.error = '';
                draft.ordersOfShowfloors = action.payload;
                draft.message = action.payload.message;
                break;
            case actionTypes.REORDER_SHOWFLOORS_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;
            case actionTypes.GET_ORDERED_SHOWFLOORS_REQUEST:
                draft.loading = true;
                draft.error = '';
                break;
            case actionTypes.GET_ORDERED_SHOWFLOORS_SUCCESS:
                draft.loading = false;
                draft.error = '';
                draft.reorderedShowfloors = action.payload.data;

                break;
            case actionTypes.GET_ORDERED_SHOWFLOORS_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            default:
                break;
        }
    });

export default reducer;
