import React from 'react';
import './EventWelcomeScreen.scss';
import * as actions from '../../store/actions';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import {isMobile} from 'react-device-detect';
import ShowAgainRadioButton from './ShowAgainRadioButton';
import CloseIcon from '@material-ui/icons/Close';
import WelcomeVideo from './WelcomeVideo';
import WelcomePicture from './WelcomePicture';
import {setUserHasSeenWelcomeScreen} from '../../store/actions/userActions';

class EventWelcomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpened: false,
            fullscreen: false,
        };
    }

    componentDidMount() {
        if (!this.props.eventWelcomeScreen.data) {
            this.getWelcomeScreen();
        }
    }

    componentDidUpdate(prevProps) {
        // whenever the user navigates to another event, get the welcome screen
        if (prevProps.event._id !== this.props.event._id) {
            this.setState(
                {
                    dialogOpened: false,
                },
                this.getWelcomeScreen
            );
        }

        // after we get the welcomeScreen data, check if we need to open it
        if (
            this.props.eventWelcomeScreen.data &&
            (!prevProps.eventWelcomeScreen.data ||
                prevProps.eventWelcomeScreen?.data?._id !== this.props.eventWelcomeScreen?.data?._id)
        ) {
            this.openWelcomeScreen();
        }
    }

    checkIfEventIsOpenForUser = () => {
        const {participantsLogin, exhibitorsLogin, scholarsLogin} = this.props.event;
        const {isOrganizer, isCoOrganizer, isExhibitor, isScholar} = this.props.eventRoles;

        if (isOrganizer || isCoOrganizer) {
            return true;
        }

        if (!isExhibitor && !isScholar) {
            return participantsLogin;
        }

        if (isExhibitor && !isScholar) {
            return exhibitorsLogin;
        }

        if (isScholar && !isExhibitor) {
            return scholarsLogin;
        }

        return false;
    };

    getWelcomeScreen = () => {
        const {event} = this.props;
        const welcomeScreenIgnored = this.checkWelcomeScreenIgnored();
        const isOrganizer = this.checkUserIsOrganizer();

        // don't display welcome screen for organizers
        if (isOrganizer) return;

        // if event doesn't have a welcome screen, don't do anything
        if (!event?.hasWelcomeScreen) return;

        // if the event is closed for the user, don't do anything
        if (!this.checkIfEventIsOpenForUser()) return;

        // if the user decided to ignore the welcome screen, don't do anything
        if (welcomeScreenIgnored) return;

        this.props.getEventWelcomeScreen(event._id);
    };

    checkWelcomeScreenSeen = () => {
        const {user, event} = this.props;
        const eventUserData = user.eventRoles.find((role) => role.event._id === event._id);
        return eventUserData?.hasSeenWelcomeScreen;
    };

    checkWelcomeScreenIgnored = () => {
        const {user, event} = this.props;
        const eventUserData = user.eventRoles.find((role) => role.event._id === event._id);
        return eventUserData?.ignoreWelcomeScreen;
    };

    checkUserIsOrganizer = () => {
        const {user, event} = this.props;
        const eventUserData = user.eventRoles.find((role) => role.event._id === event._id);
        return eventUserData?.roles?.includes('organizer');
    };

    openWelcomeScreen = () => {
        const {eventWelcomeScreen} = this.props;
        if (!eventWelcomeScreen.data) return;

        const welcomeScreenSeen = this.checkWelcomeScreenSeen();
        const welcomeScreenIgnored = this.checkWelcomeScreenIgnored();
        const isOrganizer = this.checkUserIsOrganizer();

        // don't display welcome screen for organizers
        if (isOrganizer) return;

        // if the user has seen the welcomeScreen and the displayFrequency is only once, don't do anything
        if (welcomeScreenSeen && eventWelcomeScreen.data.displayFrequency === 'first-time') return;

        // in theory we will not get to this condition because we don't make the getWelcomeScreen api call if the user chose to ignore it
        // this is just to make sure we never open the dialog if the user choosed to ignore it
        if (welcomeScreenIgnored) return;

        this.setState({
            dialogOpened: true,
        });
    };

    closeDialog = (event, reason) => {
        // when the user closes the dialog, also make the api call to setUserHasSeenWelcomeScreen
        if (reason !== 'backdropClick') {
            this.props.setUserHasSeenWelcomeScreen(this.props.event._id);
            this.setState({
                dialogOpened: false,
            });
        }
    };

    render() {
        const {fullscreen, dialogOpened} = this.state;
        const {translation, defaultTranslation, eventWelcomeScreen} = this.props;
        const fullScreenType = eventWelcomeScreen?.data?.displaySize === 'full-screen-size';
        const isWelcomeVideo = eventWelcomeScreen.data?.type === 'video';
        const isWelcomeImage = eventWelcomeScreen.data?.type === 'picture';
        return (
            <Dialog
                open={dialogOpened}
                onClose={this.closeDialog}
                fullScreen={fullScreenType || isMobile}
                aria-labelledby="form-dialog-title"
                disableEscapeKeyDown={true}
                className={`welcome-screen-dialog ${fullscreen ? 'fullscreen' : ''} ${
                    fullScreenType ? 'type-fullscreen' : ''
                } ${isMobile ? 'is-mobile' : ''} ${isWelcomeVideo ? 'video-type' : ''}`}
            >
                <DialogTitle id="form-dialog-title">
                    {eventWelcomeScreen?.data?.title}
                    <Button className="close-button" type="button" onClick={this.closeDialog}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                {/*{isWelcomeVideo && <WelcomeVideoRefactored videoStreamingUrl={eventWelcomeScreen?.data?.videoUrl}/>}*/}
                {isWelcomeVideo && <WelcomeVideo />}
                {isWelcomeImage && <WelcomePicture />}

                <DialogActions id="dont-show-again">
                    {eventWelcomeScreen?.data?.displayFrequency === 'everytime' && <ShowAgainRadioButton />}
                    <div className="spacer" />
                    <Button
                        type="button"
                        onClick={this.closeDialog}
                        variant={'contained'}
                        color={'secondary'}
                        disableElevation
                    >
                        {translation?.welcomeScreen?.goToEvent || defaultTranslation?.welcomeScreen?.goToEvent}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventRoles: state.user.eventRoles,
        eventWelcomeScreen: state.eventWelcomeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        user: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventWelcomeScreen: (eventId) => dispatch(actions.getEventWelcomeScreen(eventId)),
        setUserHasSeenWelcomeScreen: (eventId) => dispatch(setUserHasSeenWelcomeScreen(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventWelcomeScreen);
