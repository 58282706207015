import React, {useEffect, useState} from 'react';
import { google, yahoo, ics } from "calendar-link";
import {ReactComponent as GoogleCalendarIcon} from '../icons/google.svg';
import {ReactComponent as YahooCalendarIcon} from '../icons/yahoo.svg';
import {ReactComponent as ICSCalendarIcon} from '../icons/ics.svg';
import {stripHTML} from "Utils/utils";


const ExternalCalendarLinks = ({eventCalendarData}) => {

    const [calendarLinks, setCalendarLinks] = useState([]);

    useEffect(()=>{
        if(!eventCalendarData) return

        // for yahoo and iCal we need to replace [VALUE] placeholder with a '.' because we don't have the possibility to add a new line
        const formattedCalendarData = {...eventCalendarData,
            description: stripHTML(eventCalendarData.description).replace('[VALUE]', '.')};

        // for Google calendar we need to replace [VALUE] placeholder with a space because we have new line
        const formattedGoogleCalendarData = {...eventCalendarData,
            description: (eventCalendarData.description).replace('[VALUE]', '')}

        // Yahoo and ICS don't know how to read HTML, so we need to strip the HTML tags for them
        const googleCalendarLink = google(formattedGoogleCalendarData)
        const yahooCalendarLink = yahoo(formattedCalendarData)
        const icsCalendarLink = ics(formattedCalendarData)

        setCalendarLinks([
            {icon: <ICSCalendarIcon />, label: 'iCal', link: icsCalendarLink, target: '_blank'},
            {icon: <GoogleCalendarIcon />, label: 'Google', link: googleCalendarLink, target: '_blank'},
            {icon: <YahooCalendarIcon />, label: 'Yahoo', link: yahooCalendarLink, target: '_blank'},
        ])
    }, [eventCalendarData]);

    return (
        <ul>
            {calendarLinks.map(calendarLink => (
                <li key={calendarLink.label}>
                    <a href={calendarLink.link} target={calendarLink.target}>
                        {calendarLink.icon}
                        <span>{calendarLink.label}</span>
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default ExternalCalendarLinks;