import React, {useState, useEffect, useRef} from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../store/actions/index';
import Button from '@material-ui/core/Button';
import {preventDefaultDrag} from '../../Utils/utils';
import Spinner from '../../SmallLayoutComponents/Spinner';

const EditUserInformationMobile = ({opened, closeDialog, handleImageChange, handleRemoveUserAvatar, imgErrorText}) => {
    const formRef = useRef();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
    });
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Synchronize state with user props
        const updateUserState = () => {
            setUserData({
                firstName: user.first,
                lastName: user.last,
            });
        };

        // Custom name validation logic
        const isValidName = (value) => /^[a-zA-Z][a-zA-Z0-9.' -]*$/.test(value);

        // Add custom validation rule for name field
        const addNameValidationRule = () => {
            ValidatorForm.addValidationRule('isValidName', (value) => {
                const valid = isValidName(value);
                setDisabled(!valid);
                return valid;
            });
        };

        // Execute the above-defined functions
        updateUserState();
        addNameValidationRule();

        // Cleanup
        return () => {
            ValidatorForm.removeValidationRule('isValidName');
        };
    }, [user]);

    const handleChange = (e) => {
        const newUser = {
            ...userData,
            [e.target.name]: e.target.value,
        };
        setUserData(newUser);
        setDisabled(!(newUser.firstName && newUser.lastName));
    };

    const handleUpdateUserInformation = () => {
        setLoading(true);
        dispatch(actions.updateUserInformation({first: userData.firstName, last: userData.lastName}))
            .then(() => {
                dispatch(actions.refreshUserData());
                closeDialog();
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    let {firstName, lastName} = userData;

    return (
        <div
            onDragStart={preventDefaultDrag}
            className={`edit-user-information small-padding-top ${opened ? 'opened' : ''}`}
        >
            <div>
                <div onDragStart={preventDefaultDrag} className="page-title">
                    <p>{translation?.myAccountPage.editUserDialogTitle}</p>
                </div>
                <div onDragStart={preventDefaultDrag} className="content-wrapper">
                    <div onDragStart={preventDefaultDrag} className="form-wrapper">
                        <div>
                            <div onDragStart={preventDefaultDrag} className="image-container">
                                <div onDragStart={preventDefaultDrag} className="avatar">
                                    {user.loading && <Spinner custom={'wall-spinner'} />}
                                    <img
                                        draggable="false"
                                        src={`${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarBig}`}
                                        alt="Profile"
                                    />
                                </div>
                                <div onDragStart={preventDefaultDrag} className="upload-new-image">
                                    <div onDragStart={preventDefaultDrag} className="upload-container">
                                        <label htmlFor="file" className="select-image"></label>
                                        <input
                                            onChange={handleImageChange}
                                            name="file"
                                            type="file"
                                            id="file"
                                            className="d-none"
                                        />
                                        <span>{translation?.myAccountPage.uploadImage}</span>
                                    </div>
                                    <button
                                        className="remove-image"
                                        onClick={handleRemoveUserAvatar}
                                        disabled={user.avatarBig === 'default_avatar.png'}
                                    >
                                        <span>{translation?.myAccountPage.removeImage}</span>
                                    </button>
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="image-error">
                                        {imgErrorText}
                                    </span>
                                </div>
                            </div>
                            <ValidatorForm
                                className={'user-edit-container-form'}
                                onSubmit={handleUpdateUserInformation}
                            >
                                <div onDragStart={preventDefaultDrag} className="validator-input">
                                    <TextValidator
                                        label={translation?.myAccountPage.firstName}
                                        onChange={handleChange}
                                        name="firstName"
                                        value={userData.firstName}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        validators={[
                                            'required',
                                            'minStringLength: 1',
                                            'maxStringLength: 50',
                                            'isValidName',
                                        ]}
                                        errorMessages={[
                                            `${translation?.errors.required}`,
                                            `${translation?.errors.required}`,
                                            `${translation?.errors?.genericTooLongErrorFirst} 50 ${translation?.errors.genericTooLongErrorSecond}`,
                                            `${
                                                translation.newLoginRegistrations.invalidInput ||
                                                defaultTranslation.newLoginRegistrations.invalidInput
                                            }`,
                                        ]}
                                    />
                                    <TextValidator
                                        label={translation?.myAccountPage.lastName}
                                        onChange={handleChange}
                                        name="lastName"
                                        value={userData.lastName}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        validators={[
                                            'required',
                                            'minStringLength: 1',
                                            'maxStringLength: 50',
                                            'isValidName',
                                        ]}
                                        errorMessages={[
                                            `${translation?.errors.required}`,
                                            `${translation?.errors.required}`,
                                            `${translation?.errors?.genericTooLongErrorFirst} 50 ${translation?.errors.genericTooLongErrorSecond}`,
                                            `${
                                                translation.newLoginRegistrations.invalidInput ||
                                                defaultTranslation.newLoginRegistrations.invalidInput
                                            }`,
                                        ]}
                                    />
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="buttons-actions">
                        <Button type="button" onClick={() => handleUpdateUserInformation()}>
                            {translation?.generalText.close}
                        </Button>
                        <Button
                            type="submit"
                            color="secondary"
                            disabled={disabled}
                            onClick={handleUpdateUserInformation}
                        >
                            {translation?.generalText.update}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUserInformationMobile;
