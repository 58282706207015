import React from 'react';
import {connect} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import {getUtcTimestamp, getTimeslotStartTimestamp, getEventDaysArray, preventDefaultDrag} from '../Utils/utils';
import SnackbarGlobal from '../SmallLayoutComponents/Snackbars/SnackbarGlobal';

class NotificationPresentationStarts extends React.Component {
    componentDidMount() {
        this.setNewPresentationTimer();
    }

    componentWillUnmount() {
        this.clearSnackbarCountdown();
    }

    state = {
        numberOfSecondsToShowSnackbarBeforePresentationStarts: 300,
        totalSecondsTillNextProgramTimeSlots: [],
        titlesNextProgramTimeSlots: [],
        snackbarTimerId: null,
        snackbar: {
            open: false,
            message: '',
        },
    };

    componentDidUpdate(prevProps) {
        if (prevProps.event === null && this.props.event !== null) {
            this.setNewPresentationTimer();
        }
        // check for when the user moves from one event to another
        if (prevProps.event && prevProps.event._id !== this.props.event._id) {
            this.clearSnackbarCountdown();
            this.setNewPresentationTimer();
        }
    }

    handleCloseSnackbar = async () => {
        await this.setState({snackbar: {...this.state.snackbar, open: !this.state.snackbar.open, message: ''}});
        if (this.state.snackbar.open) {
            setTimeout(() => {
                this.setState({snackbar: {...this.state.snackbar, open: false}});
            }, 5000);
        }
    };

    setNewPresentationTimer = () => {
        // check if today is actually a day between eventStart and eventEnd
        const eventDays = getEventDaysArray(this.props.event.eventStartDate, this.props.event.eventEndDate);
        let currentTime = new Date();
        const currentDay = moment(currentTime).format('YYYY-MM-DD');

        // get the currentDayIndex if the eventDays;
        const currentDayIndex = eventDays.indexOf(currentDay);
        // if today is not in the program, we don't do anything
        if (currentDayIndex === -1) {
            return null;
        }

        let currentTimeStamp = getUtcTimestamp(currentTime);

        // sanity check to make sure we have the envent data
        if (this.props.event !== null) {
            let programDate = eventDays[currentDayIndex];
            let updatedTotalSecondsTillNextProgramTimeSlots = [];
            let updatedTitlesNextProgramTimeSlots = [];

            this.props.event.auditoriums.forEach((auditorium, index) => {
                if (auditorium.dailyProgram[currentDayIndex]) {
                    let program = auditorium.dailyProgram[currentDayIndex].program;
                    // get the first timeSlot that starts in at least numberOfSecondsToShowSnackbarBeforePresentationStarts
                    let nextTimeSlot = program.find((timeSlot) => {
                        let timeSlotStartTimestamp = getTimeslotStartTimestamp(
                            programDate,
                            timeSlot,
                            this.props.event.timezoneValue
                        );
                        if (
                            currentTimeStamp <
                                timeSlotStartTimestamp -
                                    this.state.numberOfSecondsToShowSnackbarBeforePresentationStarts * 1000 &&
                            timeSlot.video
                        ) {
                            return timeSlot;
                        }
                        return null;
                    });

                    // if we have a timeslot that has a video and starts in at least numberOfSecondsToShowSnackbarBeforePresentationStarts
                    // we save in array the number of seconds untill that presentation starts
                    if (nextTimeSlot) {
                        let nextTimeSlotStartTimestamp = getTimeslotStartTimestamp(
                            programDate,
                            nextTimeSlot,
                            this.props.event.timezoneValue
                        );
                        let milisecondsUntillNextVideo = nextTimeSlotStartTimestamp - currentTimeStamp;
                        let secondsUntillNextVideo = Math.floor(milisecondsUntillNextVideo / 1000);
                        updatedTotalSecondsTillNextProgramTimeSlots[index] = secondsUntillNextVideo;
                        updatedTitlesNextProgramTimeSlots[index] = nextTimeSlot.title;
                    }
                }
            });

            this.setState({
                totalSecondsTillNextProgramTimeSlots: updatedTotalSecondsTillNextProgramTimeSlots,
                titlesNextProgramTimeSlots: updatedTitlesNextProgramTimeSlots,
            });
            this.startSnackbarCountdown();
        }
    };

    startSnackbarCountdown = () => {
        let snackbarTimerId = setInterval(this.snackbarCountdown, 1000);
        this.setState({snackbarTimerId: snackbarTimerId});
    };

    snackbarCountdown = () => {
        let message = '';
        // create the message
        this.state.totalSecondsTillNextProgramTimeSlots.forEach((secondsTillNextVideo, index) => {
            if (secondsTillNextVideo === this.state.numberOfSecondsToShowSnackbarBeforePresentationStarts) {
                if (message.length === 0) {
                    message +=
                        'In ' +
                        this.state.numberOfSecondsToShowSnackbarBeforePresentationStarts / 60 +
                        ' minutes in ' +
                        this.props.event.auditoriums[index].name +
                        ' will start: ' +
                        this.state.titlesNextProgramTimeSlots[index];
                } else {
                    message +=
                        ' & in ' +
                        this.props.event.auditoriums[index].name +
                        ' will start: ' +
                        this.state.titlesNextProgramTimeSlots[index];
                }
            }
        });

        // if we have a message, open the snackbar
        if (message.length) {
            message += '.';
            this.setState({
                snackbar: {open: true, message: message},
            });
        }

        // always remove 1 second after each itteration
        let updatedTotalSecondsTillNextProgramTimeSlots = [...this.state.totalSecondsTillNextProgramTimeSlots];
        updatedTotalSecondsTillNextProgramTimeSlots.forEach((secondsTillNextVideo, index) => {
            if (secondsTillNextVideo) {
                updatedTotalSecondsTillNextProgramTimeSlots[index] =
                    updatedTotalSecondsTillNextProgramTimeSlots[index] - 1;
            }
        });
        this.setState({
            totalSecondsTillNextProgramTimeSlots: updatedTotalSecondsTillNextProgramTimeSlots,
        });

        // after we show the snackbar,
        // we clear the interval because we don't need it anymore
        // we check if we have a next video presentation to set up immediatelly the next timer
        if (message.length) {
            this.clearSnackbarCountdown();
            this.setNewPresentationTimer();
        }
    };

    clearSnackbarCountdown = () => {
        clearInterval(this.state.snackbarTimerId);
    };

    render() {
        return (
            <SnackbarGlobal
                message={this.state.snackbar.message}
                handleCloseSnackbar={this.handleCloseSnackbar}
                snackbarOpen={this.state.snackbar.open}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        user: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPresentationStarts);
