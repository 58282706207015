import {preventDefaultDrag} from "../../../Utils/utils";
import React from "react";
import {ResourceLink} from "./ResourceLink";

export function ExistingResourceLinks({links, handleRemoveLink}) {
    return <div onDragStart={preventDefaultDrag} className="options-container">
        <div onDragStart={preventDefaultDrag} className="single-option-container">
            {links && links?.length > 0 && (
                <ul className={'resources-list'}>
                    {links.map((link, index) =>
                        <ResourceLink
                            key={link._id}
                            link={link}
                            handleRemoveLink={handleRemoveLink(index)}
                        />)
                    }
                </ul>
            )}
        </div>
    </div>;
}