import Button from '@material-ui/core/Button';
import React, {createRef} from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Confirm from '../../../../Dialogs/Confirm';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {preventDefaultDrag, fireClickEvent} from '../../../../Utils/utils';
import { isLinkRule } from 'Utils/validationRules';
import { verifyFileType } from 'Utils/verifyFileType';
import {DeleteOutlineOutlined} from '@material-ui/icons';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

export default class SingleBannerDetails extends React.Component {
    formRef = createRef();
    handleClickOutside = this.handleClickOutside.bind(this);
    state = {
        value: '',
        imageLogoErrorText: '',
        image: null,
        newLogoFile: null,
        imageLogoPreviewUrl: null,
        saveDisabled: true,
        openConfirmUnsavedChanges: false,
        validationErr: false,
        navigationElement: null,
        loading: false,
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        ValidatorForm.addValidationRule('isWebPageUrl', (value) => {
            let rule = isLinkRule;
            let match = rule.test(value);
            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                this.setState({validationErr: true});
                return false;
            }
            this.setState({validationErr: false});
            return true;
        });
        this.initState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.banner.website !== this.props.banner.website)
            return this.setState({value: this.props.banner.website, saveDisabled: true});
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        ValidatorForm.removeValidationRule('isWebPageUrl');
    }

    initState = () => {
        if (this.props.banner._id) {
            const banner = this.props.banners.find((banner) => banner._id === this.props.banner._id);
            this.setState({
                value: banner.website,
                image: banner.image,
            });
        }
    };

    closeClickOutside = () => this.setState({openConfirmUnsavedChanges: false});

    handleEnterKey = (bannerIndex) => (e) => {
        if (e.key === 'Enter') return document.getElementById(`bannerLogoUploadAlt-${bannerIndex}`).click();
    };

    handleChange = (e) =>
        this.setState({value: e.target.value}, () => {
            this.formRef.current.isFormValid().then((isValid) => {
                this.setState({saveDisabled: !isValid});
            });
        });

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.props.bannersRef && !this.props.bannersRef[this.props.banner._id].contains(e.target)) {
            if (!this.state.saveDisabled && !this.props.isConfirmDeleteDialog) {
                if (!this.state.validationErr) {
                    this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
                }
            }
        }
    }

    submitEditWebsite = () => (e) => {
        this.setState({loading: true});
        e.preventDefault();
        const {banner, editBanner} = this.props;
        if (this.state.newLogoFile) {
            this.setState({saveDisabled: true, loading: false});
            editBanner(banner._id, {website: this.state.value, image: this.state.newLogoFile});
        } else {
            this.setState({saveDisabled: true, loading: false});
            editBanner(banner._id, {website: this.state.value});
        }
    };

    submitEditWebsiteUnsaved = () => {
        const {banner, editBanner} = this.props;
        const {navigationElement} = this.state;

        if (this.state.newLogoFile) {
            this.setState({saveDisabled: true, openConfirmUnsavedChanges: false});
            editBanner(banner._id, {website: this.state.value, image: this.state.newLogoFile});
        } else {
            this.setState({saveDisabled: true, openConfirmUnsavedChanges: false});
            editBanner(banner._id, {website: this.state.value});
        }

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.initState();

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleLogoChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        let isValid = true;

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (!isValid) {
            this.setState({imageLogoErrorText: 'File too large. 2Mb max file size.'});
        }

        const typeValid = verifyFileType(file.type, 'image');
        isValid = typeValid && isValid;
        if (!typeValid) {
            this.setState({
                imageLogoErrorText:
                    'File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.',
            });
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    newLogoFile: file,
                    imageLogoPreviewUrl: reader.result,
                    imageLogoErrorText: '',
                    saveDisabled: false,
                });
            }
        };

        reader.readAsDataURL(file);
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    render() {
        const {
            value,
            image,
            saveDisabled,
            imageLogoErrorText,
            newLogoFile,
            imageLogoPreviewUrl,
            openConfirmUnsavedChanges,
        } = this.state;
        const {filesUrl, inputIndex, handleDeleteBanner} = this.props;
        const {
            formRef,
            handleChange,
            submitEditWebsite,
            handleLogoChange,
            closeClickOutside,
            submitEditWebsiteUnsaved,
            handleDiscardChanges,
        } = this;
        return (
            <>
                <ValidatorForm ref={formRef} className="single-banner-form" onSubmit={submitEditWebsite()}>
                    <h3 className="single-banner-h3">
                        <TextValidator
                            label={'Webpage'}
                            type="text"
                            name="website"
                            value={value}
                            onChange={handleChange}
                            validators={['isWebPageUrl']}
                            errorMessages="Please enter a valid link (https://example.com/)"
                            multiline={false}
                            rows={0}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            className="single-banner-input"
                        />
                        <div onDragStart={preventDefaultDrag} className="actions-container">
                            <Button
                                type="button"
                                onClick={handleDeleteBanner}
                                startIcon={<DeleteOutlineOutlined />}
                            >
                                Delete
                            </Button>
                        </div>
                    </h3>
                    <span>
                        {newLogoFile && !saveDisabled && (
                            <span onDragStart={preventDefaultDrag} draggable="false" className="form-upload-picture">
                                Banner:{' '}
                                <span onDragStart={preventDefaultDrag} draggable="false" className="secondary-color">
                                    {imageLogoErrorText.length > 0 || (imageLogoErrorText.length > 0 && image) ? (
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="error-light"
                                        >
                                            error
                                        </span>
                                    ) : newLogoFile.name.length > 20 ? (
                                        newLogoFile.name.substring(0, 17) +
                                        '..' +
                                        newLogoFile.name.substring(newLogoFile.name.length - 4)
                                    ) : (
                                        newLogoFile.name
                                    )}
                                </span>
                            </span>
                        )}
                        {!newLogoFile && !image ? (
                            <>
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="form-upload-picture"
                                >
                                    Banner:{' '}
                                    {imageLogoErrorText.length > 0 ? (
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="error-light"
                                        >
                                            error
                                        </span>
                                    ) : (
                                        <span onDragStart={preventDefaultDrag} draggable="false" className="grey-color">
                                            no image
                                        </span>
                                    )}
                                </span>
                            </>
                        ) : null}
                        <span onDragStart={preventDefaultDrag} draggable="false" className="error-message">
                            {imageLogoErrorText}
                        </span>
                    </span>
                    <div
                        onDragStart={preventDefaultDrag}
                        className="current-logo-container cover-img-container no-bottom-margin"
                    >
                        {imageLogoPreviewUrl ? (
                            <img
                                draggable="false"
                                onDragStart={preventDefaultDrag}
                                src={imageLogoPreviewUrl}
                                alt="preview"
                            />
                        ) : (
                            <img draggable="false" onDragStart={preventDefaultDrag} src={filesUrl + image} alt="logo" />
                        )}
                        <label
                            tabIndex="0"
                            onKeyDown={this.handleEnterKey(inputIndex)}
                            htmlFor={`bannerLogoUploadAlt-${inputIndex}`}
                            className="edit-label banner-upload"
                        >
                            <EditOutlinedIcon color={'secondary'} />
                        </label>
                    </div>
                    <input
                        id={`bannerLogoUploadAlt-${inputIndex}`}
                        type="file"
                        onChange={handleLogoChange}
                        className="upload-image-button d-none"
                    />
                    <Button
                        disabled={saveDisabled}
                        type="submit"
                        startIcon={<SaveOutlinedIcon />}
                        variant="contained"
                        color={'secondary'}
                        disableElevation
                    >
                        Save
                    </Button>
                </ValidatorForm>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={submitEditWebsiteUnsaved}
                        handleDiscardChanges={handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}
