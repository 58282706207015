import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import * as actions from '../../../store/actions/index';
import ColoredScrollbars from '../../../SmallLayoutComponents/ColoredScrollbars';
import VideoMeetingBanner from './VideoMeetingBanner';
import Button from '@material-ui/core/Button';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as MeetingsInfoIcon} from '../../../Images/svg/counter.svg';
import {preventDefaultDrag} from '../../../Utils/utils';
import {isIOS} from 'react-device-detect';
import {debounce} from 'lodash';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import './CreateGroupChatStyles.scss';

class CreateGroupChat extends React.Component {
    state = {
        search: '',
        groupChatName: '',
        users: [],
        participants: [],
        filterUsers: [],
        videoBannerShow: false,
        loading: false,
    };

    componentDidMount() {
        const {allEventUsers, userContacts} = this.props;
        // in users we save all the users that can be found in the group chat to be added
        // it contains all the event users
        // + all the user contacts (as long as they are not already in the event users array)
        // so that they will not be duplicated
        let users = [...allEventUsers, ...this.mapContactsAsEventUsers(userContacts)];
        users = this.removeUserDuplicates(users);
        this.setState({users: users});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            this.newMessageRef.current.focus();
        }
    }

    // because for event users the userId is stored in [user].user
    // and for contacts is stored in [user].user._id
    mapContactsAsEventUsers = (contacts) => {
        return contacts.map((user) => {
            return {...user.user, user: user.user?._id};
        });
    };

    removeUserDuplicates = (users) => {
        return users.reduce((acc, current) => {
            const x = acc.find((item) => item.user === current.user);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
    };

    handleAddUserToGroupChat = (userId) => this.setState({participants: [...this.state.participants, userId]});

    _showInfoBanner = () =>
        this.setState({
            videoBannerShow: true,
        });

    _closeVideoBanner = () =>
        this.setState({
            videoBannerShow: false,
        });

    /*   handleInputFocus = () => {
        const eventPage = document.getElementsByClassName('event-page');
               if (eventPage && !isIOS) {
            eventPage[0].classList.add('hide');
        }
    };

    handleInputBlur = () => {
        const eventPage = document.getElementsByClassName('event-page hide');
        if (eventPage.length !== 0 && !isIOS) {
            eventPage[0].classList.remove('hide');
        }
    };*/

    handleKeyDown = (e) => {
        const {search} = this.state;
        if (e.key === 'Enter' && search.trim()) {
            e.target.blur();
        }
    };

    handleRemoveUserFromGroupChat = (userId) =>
        this.setState({
            participants: this.state.participants.filter(function (participant) {
                return participant !== userId;
            }),
        });

    handleCreateGroupChat = () => {
        this.setState({loading: true});
        let groupChatData = {
            name: this.state.groupChatName,
            event: this.props.event._id,
            users: this.state.participants,
        };

        this.props
            .onCreateGroupChat(groupChatData)
            ?.then(() => {
                this.setState({loading: false});
                this.props.handleCloseCreateGroupChat();
            })
            .catch(() => {
                this.setState({loading: false});
                this.props.handleCloseCreateGroupChat();
            });
    };

    onSearchChange = (e) => {
        this.setState(
            {
                search: e.target.value,
            },
            () => {
                if (this.state.search.trim().length >= 2) {
                    this.debouncedFilterUsers();
                } else {
                    this.setState({filterUsers: []});
                }
            }
        );
    };

    debouncedFilterUsers = debounce(() => {
        this.filterUsers();
    }, 500);

    filterUsers = () => {
        let filterUsers = this.state.users.filter((obj) => {
            let fullName = `${obj.first} ${obj.last}`;
            if (fullName.toLowerCase().includes(this.state.search.toLowerCase())) {
                return obj;
            }
            return null;
        });

        // hide yourself
        filterUsers = filterUsers.filter((obj) => obj.user !== this.props.user._id);

        this.setState({filterUsers: filterUsers});
    };

    changeChatName = (name) => {
        this.setState({groupChatName: name});
    };

    render() {
        const {videoBannerShow, filterUsers, loading} = this.state;
        const {translation, defaultTranslation, currentLang} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="chat-page">
                <div>
                    {loading && <Spinner />}
                    <VideoMeetingBanner
                        videoBannerShow={videoBannerShow}
                        translation={translation}
                        defaultTranslation={defaultTranslation}
                        closeBanner={this._closeVideoBanner}
                    />
                    <div onDragStart={preventDefaultDrag} className="new-chat-wrapper">
                        <div>
                            <div onDragStart={preventDefaultDrag} className="input-container">
                                <p onDragStart={preventDefaultDrag} className="d-none">
                                    {translation?.chatsDropdown.newChatProvideText}
                                </p>
                                <TextField
                                    autoFocus
                                    label={translation?.chatsDropdown.newChatNameInput}
                                    id="name"
                                    type="text"
                                    fullWidth={true}
                                    value={this.state.groupChatName}
                                    onChange={(e) => this.changeChatName(e.target.value)}
                                    variant="outlined"
                                    name={'name'}
                                    className={currentLang === 'ar' && 'ar-lang-helper'}
                                    color={'primary'}
                                />
                                <p className={`${currentLang === 'ar' && 'ar-lang-helper-p'}`}>
                                    {translation?.chatsDropdown.newChatAddMembers}
                                </p>
                                <TextField
                                    id="search"
                                    name={'search'}
                                    label={translation?.chatsDropdown.newChatSearchInput}
                                    type="search"
                                    margin="normal"
                                    className={`${currentLang === 'ar' && 'ar-lang-helper'} m-bottom`}
                                    value={this.state.search}
                                    onChange={this.onSearchChange}
                                    onKeyDown={this.handleKeyDown}
                                    variant="outlined"
                                />
                            </div>
                            {this.props.event ? (
                                <div onDragStart={preventDefaultDrag} className="members-chat-wrapper">
                                    <ColoredScrollbars>
                                        <ul className="members-list-event">
                                            {filterUsers.map((participant, participantIndex) => {
                                                let participantUser = participant;
                                                if (typeof participant.user === 'object') {
                                                    participantUser = participant.user;
                                                }
                                                return (
                                                    <React.Fragment key={participantIndex}>
                                                        <li>
                                                            <div
                                                                className="user-avatar"
                                                                style={{
                                                                    backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${participantUser.avatarSmall}')`,
                                                                }}
                                                            />
                                                            <div
                                                                onDragStart={preventDefaultDrag}
                                                                className="chat-member-details"
                                                            >
                                                                <p>
                                                                    {participantUser.first} {participantUser.last}
                                                                </p>
                                                                {participantUser.company !== '' ? (
                                                                    <span>{participantUser.company}</span>
                                                                ) : null}
                                                            </div>
                                                            {this.state.participants.includes(participant.user) ? (
                                                                <button
                                                                    className="remove-member"
                                                                    onClick={() =>
                                                                        this.handleRemoveUserFromGroupChat(
                                                                            participant.user
                                                                        )
                                                                    }
                                                                >
                                                                    {translation?.generalText.remove}
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="add-member"
                                                                    onClick={() =>
                                                                        this.handleAddUserToGroupChat(participant.user)
                                                                    }
                                                                >
                                                                    {translation?.generalText.add}
                                                                </button>
                                                            )}
                                                        </li>
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="user-wrapper-separator"
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}

                                            {this.state.search.length > 2 && !filterUsers.length && (
                                                <li>{translation?.chatsDropdown.newChatNoUsersMessage}</li>
                                            )}
                                        </ul>
                                    </ColoredScrollbars>
                                </div>
                            ) : null}
                        </div>
                        <div onDragStart={preventDefaultDrag} className="new-chat-buttons-container create-group">
                            <div>
                                <MeetingsInfoIcon
                                    fill={colors.primary}
                                    className="info-button"
                                    onClick={this._showInfoBanner}
                                />
                                <Button type="button" onClick={this.props.handleCloseCreateGroupChat}>
                                    {translation?.chatsDropdown.newChatCancelButton}
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={this.handleCreateGroupChat}
                                    disabled={!this.state.participants.length}
                                >
                                    {translation?.chatsDropdown.newChatCreateButton}
                                </Button>
                                &nbsp;&nbsp;
                            </div>
                            <div onDragStart={preventDefaultDrag} className="video-meetings-info-wrapper">
                                <div>
                                    <MeetingsInfoIcon fill={colors.primary} />
                                    <p>
                                        {translation?.meetings.banner.videoCallStartedInfo ||
                                            defaultTranslation?.meetings.banner.videoCallStartedInfo}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        allEventUsers: state.eventUsers.allEventUsers,
        userContacts: state.user.data?.contacts,
        eventOwner: state.event.data.owner,
        languages: state.languages,
        currentLang: state.languages.platformLanguage,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateGroupChat: (groupChatData) => dispatch(actions.createGroupChat(groupChatData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupChat);
