import React, {PureComponent} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import InformDialog from '../../../Dialogs/InformDialog';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as AuditoriumIcon} from '../../../Images/svg/auditorium_icon.svg';
import {calculatePxByScreenHeight, preventDefaultDrag} from '../../../Utils/utils';

class AuditoriumLink extends PureComponent {
    state = {
        titlePixels: 0,
    };

    componentDidMount() {
        this.handleTitleFontSize();
        window.addEventListener('resize', this.handleTitleFontSize);
    }

    componentWillUnmount() {
        window.addEventListener('resize', this.handleTitleFontSize);
    }

    handleTitleFontSize = () => {
        let titleSize = calculatePxByScreenHeight();
        this.setState({
            titlePixels: titleSize
        });
    };

    render() {
        const {titlePixels} = this.state;
        const {
            classes,
            event,
            eventSlug,
            translation,
            hasAccessToAuditoriums,
            eventHasEnded,
            activeAuditoriumArchive,
            location,
            showNoAuditoriumAccessDialog,
            handleOpenNoAuditoriumDialog,
            handleOpenNoAuditoriumAccess,
            showNoAuditoriumDialog,
            closeDialog,
            lobby,
        } = this.props;

        let activeAuditorium = location.pathname.indexOf(`/${eventSlug}/auditorium`) > -1;
        activeAuditorium = activeAuditorium && !activeAuditoriumArchive;

        return (
            <>
                <div onDragStart={preventDefaultDrag} className={classes}>
                    {event.restrictAuditoriumAccess ? (
                        <>
                            {lobby ? (
                                <div
                                    onDragStart={preventDefaultDrag}
                                    onClick={handleOpenNoAuditoriumDialog}
                                    className="show-info-dialog-button"
                                >
                                    {event.brandingData.auditoriumDoorText ? (
                                        <div className="door-text-wrapper">
                                            <span
                                                style={{
                                                    fontSize: `${titlePixels}px`,
                                                    color: event.brandingData.lobbyDoorsTextColor
                                                        ? event.brandingData.lobbyDoorsTextColor
                                                        : colors.doorColor,
                                                }}
                                            >
                                                {event.brandingData.auditoriumDoorText}
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <>
                                    <AuditoriumIcon fill={colors.greyVariant} />
                                    <p onDragStart={preventDefaultDrag} onClick={handleOpenNoAuditoriumDialog}>
                                        {event.brandingData.auditoriumDoorText
                                            ? event.brandingData.auditoriumDoorText
                                            : translation?.roomsDropdown.auditoriumButton}
                                    </p>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {!hasAccessToAuditoriums ? (
                                <>
                                    {lobby ? (
                                        <div onClick={handleOpenNoAuditoriumAccess} className="show-info-dialog-button">
                                            {event.brandingData.auditoriumDoorText ? (
                                                <div className="door-text-wrapper">
                                                    <span
                                                        style={{
                                                            fontSize: `${titlePixels}px`,
                                                            color: event.brandingData.lobbyDoorsTextColor
                                                                ? event.brandingData.lobbyDoorsTextColor
                                                                : colors.doorColor,
                                                        }}
                                                    >
                                                        {event.brandingData.auditoriumDoorText}
                                                    </span>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <>
                                            <AuditoriumIcon fill={colors.greyVariant} />
                                            <p onDragStart={preventDefaultDrag} onClick={handleOpenNoAuditoriumAccess}>
                                                {event.brandingData.auditoriumDoorText
                                                    ? event.brandingData.auditoriumDoorText
                                                    : translation?.roomsDropdown.auditoriumButton}
                                            </p>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {eventHasEnded ? (
                                        <Link
                                            tabIndex="0"
                                            className={`${activeAuditoriumArchive ? 'active' : ''}`}
                                            to={{pathname: `/event/${eventSlug}/auditorium-archive/`}}
                                        >
                                            {event.brandingData.auditoriumDoorText ? (
                                                <div className="door-text-wrapper">
                                                    <span
                                                        style={{
                                                            fontSize: `${titlePixels}px`,
                                                            color: event.brandingData.lobbyDoorsTextColor
                                                                ? event.brandingData.lobbyDoorsTextColor
                                                                : colors.doorColor,
                                                        }}
                                                    >
                                                        {event.brandingData.auditoriumDoorText}
                                                    </span>
                                                </div>
                                            ) : null}
                                            {!lobby && (
                                                <>
                                                    <AuditoriumIcon fill={colors.greyVariant} />
                                                    <p>
                                                        {event.brandingData.auditoriumDoorText
                                                            ? event.brandingData.auditoriumDoorText
                                                            : translation?.roomsDropdown.auditoriumButton}
                                                    </p>
                                                </>
                                            )}
                                        </Link>
                                    ) : (
                                        <Link
                                            tabIndex="0"
                                            className={`${activeAuditorium ? 'active' : ''}`}
                                            to={{pathname: `/event/${eventSlug}/auditorium/`}}
                                        >
                                            {event.brandingData.auditoriumDoorText ? (
                                                <div className="door-text-wrapper">
                                                    <span
                                                        style={{
                                                            fontSize: `${titlePixels}px`,
                                                            color: event.brandingData.lobbyDoorsTextColor
                                                                ? event.brandingData.lobbyDoorsTextColor
                                                                : colors.doorColor,
                                                        }}
                                                    >
                                                        {event.brandingData.auditoriumDoorText}
                                                    </span>
                                                </div>
                                            ) : null}
                                            {!lobby && (
                                                <>
                                                    <AuditoriumIcon fill={colors.greyVariant} />
                                                    <p>
                                                        {event.brandingData.auditoriumDoorText
                                                            ? event.brandingData.auditoriumDoorText
                                                            : translation?.roomsDropdown.auditoriumButton}
                                                    </p>
                                                </>
                                            )}
                                        </Link>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                {showNoAuditoriumDialog && (
                    <InformDialog
                        open={showNoAuditoriumDialog}
                        onClose={closeDialog}
                        dialogClasses={'closed-doors'}
                        dialogTitle={event.brandingData.closedAuditoriumTitle}
                        dialogDescription={event.brandingData.closedAuditoriumText}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoAuditoriumAccessDialog && (
                    <InformDialog
                        open={showNoAuditoriumAccessDialog}
                        onClose={closeDialog}
                        dialogTitle={`${translation?.closedAuditoriumDialog.title}`}
                        dialogDescription={`${translation?.closedAuditoriumDialog.contentPackage}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        loggedIn: state.user.loggedIn,
        eventArchive: state.eventArchive.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        hasOnDemandVideos: state.onDemandVideos.hasOnDemandVideos,
    };
};

export default withRouter(connect(mapStateToProps)(AuditoriumLink));
