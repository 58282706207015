import React from 'react';
import {Link} from 'react-router-dom';
import MainLogo from '../SmallLayoutComponents/MainLogo';
import {withRouter} from 'react-router-dom';
import '../CSS/headerMenu.scss';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import MainLogoMobile from '../SmallLayoutComponents/MainLogoMobile';
import Button from '@material-ui/core/Button';
import {preventDefaultDrag} from '../Utils/utils';
import Spinner from '../SmallLayoutComponents/Spinner';

class PlatformMenu extends React.Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            mobileMenuVisible: false,
            headerScrolled: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchstart', this.handleClickOutside);
        window.addEventListener('scroll', this.setHeaderScrolled);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {mobileMenuVisible} = this.state;
        if (prevProps.location.pathname !== window.location.pathname && mobileMenuVisible) {
            this.collapseMenu();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);
        window.removeEventListener('scroll', this.setHeaderScrolled);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                mobileMenuVisible: false,
            });
        }
    }

    setHeaderScrolled = () => {
        if (window.scrollY > 0) {
            if (this.state.headerScrolled === false) {
                this.setState({headerScrolled: true});
            }
        } else {
            if (this.state.headerScrolled === true) {
                this.setState({headerScrolled: false});
            }
        }
    };

    collapseMenu = () => {
        this.setState({mobileMenuVisible: false});
    };

    toggleMenu = () => {
        this.setState({mobileMenuVisible: !this.state.mobileMenuVisible});
    };

    onHandleLogout = (e) => {
        e.preventDefault();
        this.props.onLogoutUser();
    };

    render() {
        const intendedPage = localStorage.getItem('intendedPage');
        const {isMobile, registerTabs, loading} = this.props;
        let topLinksContainerStyle = {height: '70px'};
        if (!this.state.mobileMenuVisible) {
            topLinksContainerStyle = {
                height: '0',
            };
        }
        let showMenuBottom = this.props.location.pathname.split('/')[1];

        const delayLoginBtn = () => {
            setTimeout(() => {
                return (
                    <>
                        {this.props.location.pathname === `/login` ? null : intendedPage ? null : (
                            <Link
                                to={{pathname: '/login'}}
                                className={'login ' + (this.props.location.pathname === '/login' ? 'active' : '')}
                            >
                                <span>Log in</span>
                            </Link>
                        )}
                    </>
                );
            }, 1000);
        };

        return (
            <header
                className={
                    'outside-event-header ' +
                    (this.props.className ? this.props.className : '') +
                    (showMenuBottom ? ' hide-bottom-menu' : '')
                }
            >
                <div
                    onDragStart={preventDefaultDrag}
                    className={'platform menu-wrapper ' + (this.state.headerScrolled ? 'scrolled' : '')}
                >
                    <div className="brand-logo" onDragStart={preventDefaultDrag}>
                        <a href="/">
                            {isMobile && showMenuBottom ? (
                                <MainLogoMobile
                                    logInTab={
                                        this.props.location.pathname === `/login` ? false : registerTabs.seeLogInForm
                                    }
                                />
                            ) : (
                                <MainLogo colored={this.state.headerScrolled || this.props.coloredLogo} />
                            )}
                        </a>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="menu-links">
                        {loading && <Spinner />}
                        {this.props.loggedIn ? (
                            <Button
                                variant={'outlined'}
                                className={'light homepage-logout-button'}
                                size={'large'}
                                onClick={this.onHandleLogout}
                            >
                                <span>Log out</span>
                            </Button>
                        ) : (
                            delayLoginBtn()
                        )}
                    </div>
                </div>
                <div
                    className={'platform mobile-menu-wrapper ' + (this.state.mobileMenuVisible ? 'opened' : '')}
                    ref={this.setWrapperRef}
                >
                    <div
                        onDragStart={preventDefaultDrag}
                        className="top-links-container"
                        style={topLinksContainerStyle}
                    >
                        <div onDragStart={preventDefaultDrag} className="menu-links">
                            {loading && <Spinner />}
                            {this.props.loggedIn ? (
                                <Button
                                    variant={'outlined'}
                                    className={'light homepage-logout-button'}
                                    size={'large'}
                                    onClick={this.onHandleLogout}
                                >
                                    Log out
                                </Button>
                            ) : (
                                <Link
                                    to={{pathname: '/login'}}
                                    className={this.props.location.pathname === '/login' ? 'active' : ''}
                                >
                                    <span>Log in</span>
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="bottom-links-container" onDragStart={preventDefaultDrag}>
                        <div className="menu-links" onDragStart={preventDefaultDrag}>
                            {loading && <Spinner />}
                            {this.props.loggedIn ? (
                                <Button
                                    variant={'outlined'}
                                    className={'light homepage-logout-button'}
                                    size={'large'}
                                    onClick={this.onHandleLogout}
                                >
                                    <span>Log out</span>
                                </Button>
                            ) : (
                                <Link
                                    to={{pathname: '/login'}}
                                    className={this.props.location.pathname === '/login' ? 'active' : ''}
                                >
                                    <span>Log in</span>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        loading: state.user.loading,
        user: state.user.data,
        isMobile: state.layout.isMobile,
        registerTabs: state.user.registerTabs,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlatformMenu));
