import React from 'react';
import {ReactComponent as Pulse} from '../images/pulse.svg';
import {ReactComponent as Refresh} from '../images/refresh.svg';
import {ReactComponent as Remove} from '../images/trash.svg';
import {TextValidator} from 'react-material-ui-form-validator';
import {useSelector} from 'react-redux';

const UploadProfilePicture = ({handleImageChange, photoData, handleRemoveImage}) => {
    //redux
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);

    return (
        <>
            <div className={`user-photo-container`}>
                <label htmlFor="file" className={'file '}>
                    <div
                        className={`upload-photo-container`}
                        style={{backgroundImage: `url('${photoData?.imagePreviewUrl}')`}}
                    >
                        {photoData.imagePreviewUrl?.length <= 0 && (
                            <>
                                <div className={`pulse-container`}>
                                    <Pulse />
                                </div>
                                <span>
                                    {translation.newLoginRegistrations.uploadProfilePictureOptional ||
                                        defaultTranslation.newLoginRegistrations.uploadProfilePictureOptional}
                                </span>
                            </>
                        )}
                    </div>
                </label>
                <div className={`keeper-container`}>
                    <TextValidator
                        onChange={handleImageChange}
                        name="file"
                        type="file"
                        id="file"
                        className={`photo-field d-none`}
                    />
                </div>
                {photoData.imagePreviewUrl?.length > 0 && (
                    <div className={`photo-actions`}>
                        <label htmlFor="file" className={'file '}>
                            <Refresh />
                        </label>
                        <Remove onClick={handleRemoveImage} />
                    </div>
                )}
            </div>
            {photoData.imgErrorText !== '' && <h3 className={`error-pass`}>{photoData.imgErrorText}</h3>}
        </>
    );
};

export default UploadProfilePicture;
