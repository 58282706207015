import React, {useEffect} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {SelectValidator} from 'react-material-ui-form-validator';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {changeSelectArrows, countries} from '../../Utils/utils';
import FormControl from '@material-ui/core/FormControl';
import './SelectCountryStyles.scss';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {useSelector} from 'react-redux';

const SelectCountry = (props) => {
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    useEffect(() => {
        changeSelectArrows();
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        props.handleChange(name, value);
    };

    const renderValidatorComponent = () => {
        const {field} = props;
        const isRequired = field.validators.includes('required');

        return (
            <FormControl variant="outlined" fullWidth>
                <SelectValidator
                    className={'scaled-select-arrow'}
                    label={
                        field.label +
                        (!isRequired
                            ? ` (${
                                  translation.newLoginRegistrations.optional ||
                                  defaultTranslation.newLoginRegistrations.optional
                              })`
                            : '')
                    }
                    onChange={handleChange}
                    name={field.name}
                    value={field.value ? field.value : ''}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    variant="outlined"
                    fullWidth
                    IconComponent={(props) => (
                        <ExpandMoreRoundedIcon {...props} className={`material-icons ${props.className}`} />
                    )}
                >
                    {countries.map((country) => (
                        <MenuItem className={'d-block ellipsis'} key={country.code} value={country.name}>
                            {country.name}
                        </MenuItem>
                    ))}
                </SelectValidator>
            </FormControl>
        );
    };

    return renderValidatorComponent();
};

export default SelectCountry;
