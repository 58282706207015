import React from 'react';
import SingleBannerDetails from './LayoutComponents/SingleBannerDetails';
import AddEditHomepageBanner from '../../../Dialogs/Organizer/Branding/AddEditHomepageBanner';
import ConfirmDelete from '../../../Dialogs/ConfirmDelete';
import Switch from '@material-ui/core/Switch';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {connect} from 'react-redux';
import axios from '../../../store/axios-instance';
import * as actions from '../../../store/actions';
import AddIcon from '@material-ui/icons/Add';
import {preventDefaultDrag} from '../../../Utils/utils';
import './HomepageBannersStyles.scss';
import Button from '@material-ui/core/Button';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Spinner from "../../../SmallLayoutComponents/Spinner";

class HomepageBanners extends React.Component {
    refsArray = [];
    state = {
        openAddEditBannerDialog: false,
        showConfirmDeleteDialog: false,
        editedBannerId: null,
        clickedOutsideBanners: false,
    };

    handleOpenAddBannerDialog = () => this.setState({openAddEditBannerDialog: true});
    handleCloseDialog = () => this.setState({editedBannerId: null, openAddEditBannerDialog: false});

    handleOpenEditBannerDialog = (bannerId) => () =>
        this.setState({editedBannerId: bannerId, openAddEditBannerDialog: true});

    openConfirmDeleteDialog = (id) => () =>
        this.setState({
            editedBannerId: id,
            showConfirmDeleteDialog: true,
        });

    closeConfirmDeleteDialog = () =>
        this.setState({
            editedBannerId: null,
            showConfirmDeleteDialog: false,
        });

    handleEnterKey = (tab) => (e) => {
        if (e.key === 'Enter') {
            if (tab === 'add-banner') return this.handleOpenAddBannerDialog();
            if (tab === 'toggle-random') return this.handleRandomBanners();
        }
    };

    handleRandomBanners = () => {
        const {event} = this.props;
        axios({
            method: 'post',
            url: `/event/${event._id}`,
            data: {bannersRandomOrder: event.bannersRandomOrder ? false : true},
        })
            .then((response) => {
                const eventData = response.data.event;
                this.props.getEventSuccess(eventData);
            })
            .catch(() => {});
    };

    handleDeleteBanner = () => {
        const {eventId} = this.props;
        let data = {
            brandingProperty: 'banner',
            imageId: this.state.editedBannerId,
        };
        axios({method: 'delete', url: `/event/v2/${eventId}/branding/delete-image/`, data: data})
            .then((response) => {
                this.closeConfirmDeleteDialog();
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
            })
            .catch(() => this.props.handleOpenErrorSnackbar());
    };

    handleAddBanner = (bannerData) => {
        this.setState({loading: true});
        const {eventId} = this.props;
        const formData = new FormData();
        formData.append('brandingProperty', 'banner');
        if (bannerData.image) {
            formData.append('image', bannerData.image, bannerData.image.name, bannerData.image.type);
        }
        if (bannerData.website) {
            formData.append('website', bannerData.website);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios({method: 'post', url: `/event/${eventId}/branding/upload-image`, data: formData, config})
            .then((response) => {
                this.handleCloseDialog();
                const brandingData = response.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
                this.setState({loading: false});
            })
            .catch(() => {
                this.props.handleOpenErrorSnackbar();
                this.setState({loading: true});
            });
    };

    handleEditBanner = (bannerId, bannerData) => {
        this.setState({loading: true});
        const {eventId} = this.props;
        const formData = new FormData();
        formData.append('brandingProperty', 'banner');
        if (bannerData.image) {
            formData.append('image', bannerData.image, bannerData.image.name, bannerData.image.type);
        }
        formData.append('website', bannerData.website);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios({
            method: 'post',
            url: `/event/v2/${eventId}/branding/edit-image/${this.state.editedBannerId || bannerId}`,
            data: formData,
            config,
        })
            .then((response) => {
                this.handleCloseDialog();
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.props.handleOpenSuccessSnackbar();
                this.setState({loading: false});
            })
            .catch(() => {
                this.props.handleOpenErrorSnackbar()
                this.setState({loading: false});
            });
    };

    render() {
        const {event, branding} = this.props;
        const {openAddEditBannerDialog, editedBannerId, showConfirmDeleteDialog, loading} = this.state;
        const {
            refsArray,
            handleOpenAddBannerDialog,
            handleRandomBanners,
            handleEditBanner,
            handleCloseDialog,
            handleAddBanner,
            handleOpenEditBannerDialog,
            openConfirmDeleteDialog,
            closeConfirmDeleteDialog,
            handleDeleteBanner,
            handleEnterKey,
        } = this;
        return (
            <div onDragStart={preventDefaultDrag} className="branding-homepage-banners">
                {loading && <Spinner />}
                <h4 className="advanced-options-title">
                    BANNERS
                    <Button
                        onClick={handleOpenAddBannerDialog}
                        tabIndex="0"
                        onKeyDown={handleEnterKey('add-banner')}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD BANNER
                    </Button>
                </h4>
                {branding?.data?.banners?.length >= 2 && (
                    <div tabIndex="0" onKeyDown={handleEnterKey('toggle-random')} className="random-banners-container">
                        Random order:
                        <Tooltip arrow classes={{tooltip: 'ignore-rtl'}} tabIndex="0" title="By enabling, the banners will be displayed in a random order">
                            <InfoIcon color="secondary" />
                        </Tooltip>
                        <Switch
                            tabIndex="-1"
                            color="secondary"
                            name="randomBanners"
                            onChange={handleRandomBanners}
                            checked={event.bannersRandomOrder}
                        />
                    </div>
                )}
                <div onDragStart={preventDefaultDrag} className="list-wrapper">
                    {branding?.data?.banners?.length > 0 ? (
                        <div onDragStart={preventDefaultDrag} className="banners-container">
                            {branding.data.banners.map((banner, index) => {
                                return (
                                    <div
                                        onDragStart={preventDefaultDrag}
                                        key={index}
                                        className="advanced-options-container"
                                    >
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="single-banner-container"
                                            key={banner._id}
                                            ref={(ref) => (this.refsArray[banner._id] = ref)}
                                        >
                                            <SingleBannerDetails
                                                event={event}
                                                banner={banner}
                                                bannersRef={refsArray}
                                                inputIndex={index}
                                                banners={branding?.data?.banners}
                                                editBanner={handleEditBanner}
                                                filesUrl={branding?.data?.filesUrl}
                                                handleOpenEditBannerDialog={handleOpenEditBannerDialog(banner._id)}
                                                handleDeleteBanner={openConfirmDeleteDialog(banner._id)}
                                                isConfirmDeleteDialog={showConfirmDeleteDialog}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div onDragStart={preventDefaultDrag} className="advanced-options-container banner-helper">
                            <p onDragStart={preventDefaultDrag} className="list-empty-text">
                                You currently don't have any banners added
                            </p>
                        </div>
                    )}
                </div>
                <div onDragStart={preventDefaultDrag} className="actions-container">
                    <Button
                        onClick={handleOpenAddBannerDialog}
                        tabIndex="0"
                        onKeyDown={handleEnterKey('add-banner')}
                        startIcon={<AddOutlinedIcon />}
                    >
                        ADD BANNER
                    </Button>
                </div>
                {openAddEditBannerDialog && (
                    <AddEditHomepageBanner
                        open={openAddEditBannerDialog}
                        bannerId={editedBannerId}
                        banners={branding?.data?.banners}
                        handleCloseDialog={handleCloseDialog}
                        addNewBanner={handleAddBanner}
                        editBanner={handleEditBanner}
                        filesUrl={branding?.data?.filesUrl}
                    />
                )}
                {showConfirmDeleteDialog && (
                    <ConfirmDelete
                        open={showConfirmDeleteDialog}
                        closeConfirmDeleteDialog={closeConfirmDeleteDialog}
                        dialogTitle={'Delete Banner'}
                        dialogDescription={'Are you sure you want to delete this banner?'}
                        dialogConfirmButtonLabel={'Delete'}
                        handleConfirmDelete={handleDeleteBanner}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        branding: state.event.branding,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
        getEventSuccess: (eventData) => dispatch(actions.getEventSuccess(eventData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageBanners);
