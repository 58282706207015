import React from 'react';
import {Link} from 'react-router-dom';
import MainLogo from '../SmallLayoutComponents/MainLogo';
import {withRouter} from 'react-router-dom';
import '../CSS/headerMenu.scss';
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import PlatformLanguage from './HeaderMenuDropdowns/PlatformLanguage';
import MainLogoMobile from '../SmallLayoutComponents/MainLogoMobile';
import Button from '@material-ui/core/Button';
import {ReactComponent as Share} from 'Images/svg/sm-share.svg';
import SMPopup from '../Dialogs/SMPopup';
import {checkPlatformLanguage, getLanguageFromUrl, preventDefaultDrag} from '../Utils/utils';
import InfoTooltip from '../SmallLayoutComponents/InfoTooltip/InfoTooltip';
import IconButton from '@material-ui/core/IconButton';
import './HeaderMenuStyles.scss';

class HeaderMenu extends React.Component {
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutsideMobile = this.handleClickOutsideMobile.bind(this);

        this.state = {
            mobileMenuVisible: false,
            headerScrolled: false,
            menuLinks: [],
            sharePopup: false,
            topNavigationOverflowInitial: false,
        };
    }

    componentDidMount() {
        const {eventSlug, user} = this.props;

        console.log(user, 'all user data');

        const userHasRoleInEvent = user?.eventRoles?.some((roles) => roles.event.slug === eventSlug);
        const isSocialMediaLogin = localStorage.getItem('isSocialMediaLogin') === 'true';

        if (isSocialMediaLogin && !userHasRoleInEvent) {
            localStorage.removeItem('isSocialMediaLogin');
            this.props.history.push(`/event/${eventSlug}/select-role`);
        }

        document.addEventListener('touchstart', this.handleClickOutsideMobile);
        this.setMenuLinks();
        this.setLanguageFromUrl();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== window.location.pathname) {
            this.collapseMenu();
        }

        if (
            prevProps.location.pathname !== window.location.pathname ||
            prevProps.location.search !== window.location.search ||
            prevProps.loggedIn !== this.props.loggedIn
        ) {
            this.setMenuLinks();
        }
        if (prevState.mobileMenuVisible === true && this.state.mobileMenuVisible === false) {
            this.setState({
                topNavigationOverflowInitial: false,
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.handleClickOutsideMobile);
        window.removeEventListener('scroll', this.setHeaderScrolled);
        clearTimeout(this.timeoutId);
    }

    setMenuLinks = () => {
        const {eventSlug, loggedIn, eventInfo} = this.props;
        let menuLinks = [
            {
                name: 'participantRegistration',
                labelTranslation: 'participantRegistration',
                pathname: `/event/${eventSlug}/register`,
                searchParam: '?registerAsParticipant=true',
                isActive: false,
                isVisible: true,
                class: 'participant',
            },
            {
                name: 'exhibitorRegistration',
                labelTranslation: 'exhibitorRegistration',
                pathname: `/event/${eventSlug}/register`,
                searchParam: '?registerAsExhibitor=true',
                isActive: false,
                isVisible: true,
                class: 'exhibitor',
            },
            {
                name: 'posterRegistration',
                labelTranslation: 'scholarRegistration',
                pathname: `/event/${eventSlug}/register`,
                searchParam: '?registerAsScholar=true',
                isActive: false,
                isVisible: true,
                class: 'poster',
            },
        ];
        if (loggedIn) {
            let posterRegistrationLink = menuLinks.find((pathname) => pathname.name === 'posterRegistration');
            posterRegistrationLink.searchParam = '';
            posterRegistrationLink.pathname = `/event/${eventSlug}/scholar-registration`;

            let exhibitorRegistrationLink = menuLinks.find((pathname) => pathname.name === 'exhibitorRegistration');
            exhibitorRegistrationLink.searchParam = '';
            exhibitorRegistrationLink.pathname = `/event/${eventSlug}/exhibitor-registration`;

            let participantRegistrationLink = menuLinks.find((pathname) => pathname.name === 'participantRegistration');
            participantRegistrationLink.searchParam = '';
            participantRegistrationLink.pathname = `/event/${eventSlug}/participant-registration`;
        }

        menuLinks.forEach((menuLink) => {
            const {location} = this.props;
            // hide the link if you are on that page
            const locationFullUrl = `${location.pathname}${location.search}`;
            const menuLinkFullUrl = `${menuLink.pathname}${menuLink.searchParam}`;
            if (locationFullUrl === menuLinkFullUrl) {
                menuLink.isVisible = false;
            }

            // hide 'join as ...' buttons on 'event-privacy-policy' and 'terms-and-conditions'
            if (
                location.pathname.includes('event-privacy-policy') ||
                location.pathname.includes('event-terms-and-conditions')
            ) {
                menuLink.isVisible = false;
            }
        });

        // let loginLink = menuLinks.find((pathname) => pathname.name === 'login');
        // // we always "hide the loginLink if the user is loggedIn, "show" the loginLink if the user is not loggedIn
        // loginLink.isVisible = !loggedIn;

        if (!eventInfo?.scholarExhibitorsType) {
            let posterRegistrationLink = menuLinks.find((pathname) => pathname.name === 'posterRegistration');
            posterRegistrationLink.isVisible = false;
        }

        if (!eventInfo?.companyExhibitorsType) {
            let exhibitorRegistrationLink = menuLinks.find((pathname) => pathname.name === 'exhibitorRegistration');
            exhibitorRegistrationLink.isVisible = false;
        }

        this.setState({
            menuLinks: menuLinks,
        });
    };

    openSharePopup = () => this.setState({sharePopup: true});
    closeSharePopup = () => this.setState({sharePopup: false});

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutsideMobile(event) {
        const disabledClose = event.target?.classList?.contains('disabled-close-mobile-menu');
        if (
            (this.wrapperRef && !this.wrapperRef.contains(event.target)) ||
            event.target.tagName.toLowerCase() === 'span'
        ) {
            //disabledClose variable prevents the closing of the mobile menu
            if (!disabledClose) {
                this.timeoutId = setTimeout(
                    () =>
                        this.setState({
                            mobileMenuVisible: false,
                        }),
                    200
                );
            }
        }
    }

    setHeaderScrolled = () => {
        if (window.scrollY > 0) {
            if (this.state.headerScrolled === false) {
                this.setState({headerScrolled: true});
            }
        } else {
            if (this.state.headerScrolled === true) {
                this.setState({headerScrolled: false});
            }
        }
    };

    collapseMenu = () => {
        this.setState({mobileMenuVisible: false});
    };

    toggleMenu = () => {
        this.setState({mobileMenuVisible: !this.state.mobileMenuVisible});
    };

    onHandleLogout = (e) => {
        localStorage.clear();
        e.preventDefault();
        this.props.onLogoutUser();
    };

    onHandleLogIn = (e) => {
        e.preventDefault();
        this.props.history.push(`/event/${this.props.eventSlug}/login`);
    };
    setLanguageFromUrl = (e) => {
        const languageCode = getLanguageFromUrl();
        if (languageCode) {
            this.props.onSetEventLanguage(languageCode);
        }
    };

    getTopLinksContainerStyle = () => {
        const {menuLinks} = this.state;

        let topLinksContainerStyle = {
            height: `0`,
        };
        if (this.state.mobileMenuVisible) {
            // we should always substract 1 because we always show the registerAsParticipantLink
            let topMenuLinksLength = menuLinks.filter((menuLink) => menuLink.isVisible).length - 1;

            // we should always add 1 because the logInButton or logoutButton is not in the menuLinks state
            topMenuLinksLength++;

            let height = topMenuLinksLength * 66;
            // in NO, ES, DE, RU, TR the join as p/s button has a longer text and occupies 3 rows
            if (checkPlatformLanguage(this.props.languages.eventLanguage, ['no', 'es', 'de', 'ru', 'tr'])) {
                height += 16;
            }
            topLinksContainerStyle = {
                height: `${height}px`,
                overflow: this.state.topNavigationOverflowInitial ? 'initial' : 'hidden',
            };
        }
        return topLinksContainerStyle;
    };

    getAllMenuLinksItems = () => {
        const {menuLinks} = this.state;
        const {translation} = this.props;
        return menuLinks.map((menuLink) => {
            if (!menuLink.isVisible) {
                return null;
            }
            return (
                <Link
                    key={menuLink.name}
                    to={{
                        pathname: menuLink.pathname,
                        search: menuLink.searchParam,
                    }}
                    className={menuLink.class}
                >
                    <span>{translation?.menu[menuLink.labelTranslation]}</span>
                </Link>
            );
        });
    };

    getMenuLinksWithBurgerMenu = () => {
        const {menuLinks} = this.state;
        const {translation} = this.props;
        return menuLinks.map((menuLink) => {
            if (menuLink.name !== 'participantRegistration') {
                return null;
            }
            return (
                <Link
                    key={menuLink.name}
                    to={{
                        pathname: menuLink.pathname,
                        search: menuLink.searchParam,
                    }}
                    className={menuLink.class}
                >
                    <span>{translation?.menu[menuLink.labelTranslation]}</span>
                </Link>
            );
        });
    };

    checkCanRedirectToLogin = (e) => {
        const {eventInfo, user, allEventUsers} = this.props;
        const userExistInPlatformButNotRegisteredToEvent = allEventUsers?.find(
            (eventUser) => eventUser._id === user?._id
        );
        if (user?._id) {
            if (!userExistInPlatformButNotRegisteredToEvent) {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push(`/event/${eventInfo.slug}/select-role`);
            }
        } else {
            if (eventInfo.restrictAllUsersLogin) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    };

    checkShowLoginRestrictedTooltip = (e) => {
        const {eventInfo} = this.props;
        if (eventInfo.restrictAllUsersLogin) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({
            topNavigationOverflowInitial: !this.state.topNavigationOverflowInitial,
        });
    };

    render() {
        const {
            eventInfo,
            eventSlug,
            loggedIn,
            isMobile,
            registerTabs,
            translation,
            defaultTranslation,
            allEventUsers,
            user,
        } = this.props;
        const {mobileMenuVisible, sharePopup} = this.state;
        const isPreviewMode = this.props.location.search.includes('preview=true');
        let topLinksContainerStyle = this.getTopLinksContainerStyle();
        let showMenuBottom =
            this.props.location.pathname.split('/')[3] !== undefined &&
            this.props.location.pathname.split('/')[3] !== '';
        const urlAfterEventSlug = this.props.location?.pathname?.split(`${eventSlug}/` || eventSlug)[1]?.length > 0;

        const userExistInPlatformButNotRegisteredToEvent = () => {
            return allEventUsers?.find((eventUser) => eventUser?._id === user?._id);
        };

        return (
            <>
                <header
                    className={`outside-event-header ${registerTabs.seeLogInForm ? 'log-in-tab' : 'sign-up-tab'} ${
                        showMenuBottom ? 'hide-bottom-menu' : ''
                    }`}
                >
                    <div
                        onDragStart={preventDefaultDrag}
                        className={'menu-wrapper ' + (this.state.headerScrolled ? 'scrolled' : '')}
                    >
                        <div onDragStart={preventDefaultDrag} className="brand-logo">
                            <Link to={{pathname: `/event/${eventSlug}`}}>
                                {isMobile && showMenuBottom ? (
                                    <MainLogoMobile logInTab={registerTabs.seeLogInForm} />
                                ) : (
                                    <MainLogo colored={this.props.coloredLogo || this.state.headerScrolled} />
                                )}
                            </Link>
                        </div>

                        {!urlAfterEventSlug && process.env.REACT_APP_SM_SHARE === 'true' && (
                            <IconButton onClick={this.openSharePopup}>
                                <Share fill={'#FFFFFF'} className="sm-share-icon" />
                            </IconButton>
                        )}
                        {!urlAfterEventSlug && !isPreviewMode && !this.props.disabledLanguageSelector && (
                            <PlatformLanguage />
                        )}
                        {!isPreviewMode && (
                            <div onDragStart={preventDefaultDrag} className="menu-links">
                                {loggedIn ? (
                                    <>
                                        {!userExistInPlatformButNotRegisteredToEvent() && (
                                            <Link
                                                to={{pathname: `/event/${eventSlug}/login`}}
                                                className={`login-event ${
                                                    eventInfo?.restrictAllUsersLogin ? 'disabled' : ''
                                                }`}
                                                onClick={this.checkCanRedirectToLogin}
                                            >
                                                <span>{translation?.newLoginRegistrations.joinEvent}</span>
                                                {eventInfo?.restrictAllUsersLogin && (
                                                    <InfoTooltip
                                                        message={
                                                            translation?.menu.loginDisabledInfoText ||
                                                            defaultTranslation?.menu.loginDisabledInfoText
                                                        }
                                                        position={'bottom'}
                                                    />
                                                )}
                                            </Link>
                                        )}
                                        <Button
                                            variant={'outlined'}
                                            className={'light homepage-logout-button'}
                                            onClick={this.onHandleLogout}
                                        >
                                            <span> {translation?.menu.logOut}</span>
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        {this.props.location.pathname.split('/')[3] !== 'login' ? (
                                            <>
                                                <Link
                                                    to={{pathname: `/event/${eventSlug}/login`}}
                                                    className={`login-event ${
                                                        eventInfo?.restrictAllUsersLogin ? 'disabled' : ''
                                                    }`}
                                                    onClick={this.checkCanRedirectToLogin}
                                                >
                                                    <span>{translation?.newLoginRegistrations.joinEvent}</span>
                                                    {eventInfo?.restrictAllUsersLogin && (
                                                        <InfoTooltip
                                                            message={
                                                                translation?.menu.loginDisabledInfoText ||
                                                                defaultTranslation?.menu.loginDisabledInfoText
                                                            }
                                                            position={'bottom'}
                                                        />
                                                    )}
                                                </Link>
                                                {/*      <span className="login-divider"></span>*/}
                                            </>
                                        ) : null}
                                    </>
                                )}
                                {/*   {this.getAllMenuLinksItems()}*/}
                            </div>
                        )}
                    </div>
                    {!isPreviewMode && (
                        <div
                            className={`mobile-menu-wrapper ${mobileMenuVisible ? 'opened' : ''}`}
                            ref={this.setWrapperRef}
                        >
                            <div className="top-links-container" style={topLinksContainerStyle}>
                                <div onDragStart={preventDefaultDrag} className="menu-links">
                                    {loggedIn ? (
                                        <Button
                                            variant={'outlined'}
                                            className={'light homepage-logout-button'}
                                            size={'large'}
                                            onClick={this.onHandleLogout}
                                        >
                                            <span>{translation?.menu.logOut}</span>
                                        </Button>
                                    ) : (
                                        <Link
                                            to={{pathname: `/event/${eventSlug}/login`}}
                                            className={`login-event 
                                                ${this.props.location.pathname === '/login' ? 'active' : ''} 
                                                ${eventInfo?.restrictAllUsersLogin ? 'disabled' : ''}
                                                `}
                                            onClick={this.checkShowLoginRestrictedTooltip}
                                        ></Link>
                                    )}
                                </div>
                            </div>
                            <div onDragStart={preventDefaultDrag} className="bottom-links-container">
                                {loggedIn ? (
                                    <div className={`mobile-container-btns`}>
                                        {!userExistInPlatformButNotRegisteredToEvent() && (
                                            <Button
                                                variant={'outlined'}
                                                className={'light homepage-logout-button mobile-white-btn'}
                                                size={'large'}
                                                onClick={this.onHandleLogIn}
                                            >
                                                <span>{translation?.newLoginRegistrations.joinEvent}</span>
                                            </Button>
                                        )}

                                        <Button
                                            variant={'outlined'}
                                            className={'light homepage-logout-button'}
                                            size={'large'}
                                            onClick={this.onHandleLogout}
                                        >
                                            <span>{translation?.menu.logOut}</span>
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        variant={'outlined'}
                                        className={'light homepage-logout-button  mobile-white-btn'}
                                        size={'large'}
                                        onClick={this.onHandleLogIn}
                                    >
                                        <span>{translation?.newLoginRegistrations.joinEvent}</span>
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </header>
                <SMPopup
                    open={sharePopup}
                    closeConfirm={this.closeSharePopup}
                    dialogTitle={
                        translation?.socialMediaShare?.dialogTitleGeneral ||
                        defaultTranslation?.socialMediaShare?.dialogTitleGeneral
                    }
                    eventName={eventInfo?.name}
                    popupLanguageLevel="event"
                    targetURL={window.location.href}
                    facebookQuote={`${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${`"${eventInfo?.name}" ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }.`} ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    }`}
                    twitterMessage={`${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${`"${eventInfo?.name}" ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }.`} ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    }`}
                    emailSubject={`${process.env.REACT_APP_PLATFORM_NAME} - ${eventInfo?.name}`}
                    emailBody={`${
                        translation?.socialMediaShare?.imAttending || defaultTranslation?.socialMediaShare?.imAttending
                    } ${`"${eventInfo?.name}" ${
                        translation?.socialMediaShare?.event || defaultTranslation?.socialMediaShare?.event
                    }.`} ${
                        translation?.socialMediaShare?.interestedClick ||
                        defaultTranslation?.socialMediaShare?.interestedClick
                    } ${window.location.href}`}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        eventSlug: state.event.eventSlug,
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
        eventInfo: state.event.eventInfo,
        isMobile: state.layout.isMobile,
        registerTabs: state.user.registerTabs,
        allEventUsers: state.eventUsers.allEventUsers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
        onSetEventLanguage: (languageCode) => dispatch(actions.setEventLanguage(languageCode)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderMenu));
