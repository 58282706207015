import {preventDefaultDrag} from "../../Utils/utils";
import {Button} from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";
import Spinner from "../../SmallLayoutComponents/Spinner";

const AccountProfilePicture = ({user, handleImageChange, handleRemoveUserAvatar, loading}) => {
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);

    return (
        <div onDragStart={preventDefaultDrag} className="image-container">
            {loading && <Spinner custom={'wall-spinner'} />}
            <div
                className="avatar"
                style={{
                    backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarBig}')`,
                }}
            />
            <label htmlFor="file" className="select-image" />
            <div onDragStart={preventDefaultDrag} className="upload-text-container">
                <span>{translation?.generalText.upload}</span>
            </div>

            <input
                onChange={handleImageChange}
                name="file"
                type="file"
                id="file"
                className="d-none"
            />
            {user.avatarBig !== 'default_avatar.png' && (
                <Button
                    className="remove-avatar"
                    onClick={handleRemoveUserAvatar}
                    disabled={user.avatarBig === 'default_avatar.png' ? true : false}
                    size={'small'}
                    disableElevation
                    disableFocusRipple
                >
                    {translation?.generalText.remove}
                </Button>
            )}
        </div>
    )
}

export default AccountProfilePicture;