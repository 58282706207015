import React, {useCallback, useEffect, useRef, useState} from 'react';
import {fireClickEvent, preventDefaultDrag} from 'Utils/utils';
import {CircularProgress} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import LobbyResourcesDescription from './LobbyResourcesDescription';
import 'Components/EventManagement/CommonComponents/resourcesStyles.scss';
import {ResourceLinks} from '../../CommonComponents/ResourceLinks';
import {ResourceDocuments} from '../../CommonComponents/ResourceDocuments';
import {useDispatch, useSelector} from 'react-redux';
import {
    addLobbyDocument,
    deleteLobbyDocument,
    getLobbyResources,
    updateLobbyResourceDescription,
    updateLobbyResourceLinks,
} from 'store/actions/lobbyResourcesActions';
import {convertToRaw} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {isLinkRule} from '../../../../Utils/validationRules';
import cloneDeep from 'lodash/cloneDeep';
import {verifyFileType} from '../../../../Utils/verifyFileType';
import Confirm from '../../../../Dialogs/Confirm';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarGlobal from '../../../../SmallLayoutComponents/Snackbars/SnackbarGlobal';

const LobbyResources = () => {
    const [navigationElement, setNavigationElement] = useState(null);
    const [openConfirmUnsavedChanges, setOpenConfirmUnsavedChanges] = useState(false);
    const [isDescriptionSaved, setIsDescriptionSaved] = useState(true);
    const [errorSnackbarOpened, setErrorSnackbarOpened] = useState(false);

    const [newDescription, setNewDescription] = useState('');
    const [characterCount, setCharacterCount] = useState(0);

    const [newLink, setNewLink] = useState('');
    const [newLinkLabel, setNewLinkLabel] = useState('');
    const [newLinkError, setNewLinkError] = useState(false);
    const [newLinkLabelError, setNewLinkLabelError] = useState(false);

    const [newDocumentName, setNewDocumentName] = useState('');
    const [fileErrorText, setFileErrorText] = useState('');
    const [newDocumentFile, setNewDocumentFile] = useState(false);

    const wrapperRef = useRef();
    const dispatch = useDispatch();

    const {description, links, documents, error, loading} = useSelector((state) => state.lobbyResources);
    const eventId = useSelector((state) => state.event.eventId);

    const textFieldRef = React.createRef();

    useEffect(() => {
        dispatch(getLobbyResources(eventId));

        ValidatorForm.addValidationRule('isLink', (value) => {
            let rule = isLinkRule;
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                setNewLinkError(true);
                return false;
            }
            setNewLinkError(false);
            return true;
        });

        return () => {
            ValidatorForm.removeValidationRule('isLink');
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isDescriptionSaved,
        description,
        newDescription,
        wrapperRef,
        openConfirmUnsavedChanges,
        newLink,
        newLinkLabel,
        newDocumentFile,
        newDocumentName,
        newLinkError,
        newLinkLabelError,
        fileErrorText,
    ]);

    useEffect(() => {
        if (error) {
            setErrorSnackbarOpened(true);
        }
    }, [error]);

    // This save function saves all the valid changes on the page
    // and can only be called by the unsaved changes dialog save
    const handleSaveChanges = () => {
        if (!getSaveDescriptionDisabled()) {
            saveDescription(true);
        }
        if (isLinkToBeSaved(true)) {
            handleAddLink();
        }
        if (isDocumentToBeSaved()) {
            uploadDocument();
        }
        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    const getSaveDescriptionDisabled = () => {
        const initialDescriptionText = convertToRaw(stateFromHTML(description))
            .blocks.map((object) => object.text)
            .join(' ');
        const updatedDescriptionText = convertToRaw(stateFromHTML(newDescription))
            .blocks.map((object) => object.text)
            .join(' ');

        const descriptionInvalid = updatedDescriptionText.length > 5000;
        const descriptionTextChanged = initialDescriptionText !== updatedDescriptionText;
        const descriptionFormatChanged = description && description !== newDescription;
        const descriptionDidNotChange = !descriptionTextChanged && !descriptionFormatChanged;

        return descriptionInvalid || descriptionDidNotChange;
    };

    // This save function only saves the description
    // and can be called from the save button on page
    const saveDescription = () => {
        const object = {description: characterCount ? newDescription : ''};
        setIsDescriptionSaved(true);
        setOpenConfirmUnsavedChanges(false);
        dispatch(updateLobbyResourceDescription(eventId, object));
    };

    const handleAddLink = () => {
        const newLinkObject = {link: newLink, displayName: newLinkLabel};
        let newLinks = cloneDeep(links);
        newLinks.push(newLinkObject);
        const newLinksObject = {links: newLinks};
        dispatch(updateLobbyResourceLinks(eventId, newLinksObject));
        setNewLink('');
        setNewLinkLabel('');
    };

    const handleRemoveLink = (index) => (e) => {
        const newLinks = cloneDeep(links);
        newLinks.splice(index, 1);
        const newLinksObject = {links: newLinks};

        dispatch(updateLobbyResourceLinks(eventId, newLinksObject));
    };

    const handleChangeLink = (e) => {
        setNewLink(e.target.value);
    };

    const handleChangeLinkLabel = (e) => {
        const label = e.target.value;
        setNewLinkLabel(label);
        setNewLinkLabelError(label.length > 100);
    };

    const uploadDocument = () => {
        const formData = new FormData();
        formData.append('displayName', newDocumentName);
        formData.append('file', newDocumentFile, newDocumentFile.name, newDocumentFile.type);
        dispatch(addLobbyDocument(eventId, formData)).then(() => {
            setNewDocumentFile(false);
            setNewDocumentName('');
        });
    };

    const handleChangeDocumentName = (e) => {
        setNewDocumentName(e.target.value);
    };

    const handleCloseSnackbar = () => {
        setErrorSnackbarOpened(false);
    };

    const getNewNavigationElement = useCallback(
        (e) => {
            const isEventTargetNavigationElement = e.path?.find((pathElem) =>
                pathElem.getAttribute?.('data-is-navigation')
            );

            if (openConfirmUnsavedChanges) {
                return navigationElement;
            }

            if (isEventTargetNavigationElement) {
                return e.target;
            }

            return null;
        },
        [navigationElement, openConfirmUnsavedChanges]
    );

    const isLinkToBeSaved = () => {
        return !!newLink && !!newLinkLabel && !newLinkError && !newLinkLabelError;
    };

    const isDocumentToBeSaved = () => {
        return !!newDocumentName && !!newDocumentFile && !fileErrorText && newDocumentName.length <= 100;
    };

    const handleClickOutside = (e) => {
        const isThereSomethingToSave = !getSaveDescriptionDisabled() || isLinkToBeSaved() || isDocumentToBeSaved();
        const isClickOutsideTab = wrapperRef && !wrapperRef?.current?.contains(e.target);

        if (!openConfirmUnsavedChanges && isThereSomethingToSave && isClickOutsideTab) {
            setOpenConfirmUnsavedChanges(true);
            setNavigationElement(getNewNavigationElement(e));
        }
    };

    const handleDiscardChanges = () => {
        setOpenConfirmUnsavedChanges(false);
        setIsDescriptionSaved(true);
        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    const handleDocumentChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (file) {
            let isValid = true;

            isValid = file.size < 10 * 1024 * 1024 && isValid;

            const typeValid = verifyFileType(file.type, 'pdf word excel powerPoint uncommon');
            isValid = typeValid && isValid;

            reader.onloadend = () => {
                if (isValid) {
                    setNewDocumentFile(file);
                    setFileErrorText('');
                } else {
                    if (file.size > 10 * 1024 * 1024) {
                        setNewDocumentFile(false);
                        setFileErrorText('File too large. 10Mb max file size.');
                    } else {
                        setNewDocumentFile(false);
                        setFileErrorText(
                            'File type not supported. Please use one of the following: .xls,.docx, .xls, .xlsx, .ppt, .pptx, .pdf, .odg, .odp, .ods, .odt.'
                        );
                    }
                    setNewDocumentFile(false);
                }
            };

            reader.readAsDataURL(file);
        }
        // Reset input otherwise second upload of the SAME FILE won't trigger input onChange event
        e.target.value = '';
    };

    const removeDocument = (documentId) => {
        dispatch(deleteLobbyDocument(eventId, documentId));
    };

    return (
        <div onDragStart={preventDefaultDrag} className="branding-lobby-content resources">
            <h4 className="advanced-options-title">RESOURCES (EVENT INFO)</h4>
            {loading ? (
                <div className="d-flex align-items-center justify-content-center">
                    <CircularProgress color="primary" />
                </div>
            ) : (
                <div ref={wrapperRef} onDragStart={preventDefaultDrag} className="advanced-options-container">
                    <LobbyResourcesDescription
                        description={description}
                        newDescription={newDescription}
                        setNewDescription={setNewDescription}
                        characterCount={characterCount}
                        setCharacterCount={setCharacterCount}
                        setIsSaved={setIsDescriptionSaved}
                    />

                    <ResourceLinks
                        handleAddLink={handleAddLink}
                        linkURL={newLink}
                        changeURL={handleChangeLink}
                        linkLabel={newLinkLabel}
                        changeLabel={handleChangeLinkLabel}
                        newLinkError={newLinkError}
                        newLinkLabelError={newLinkLabelError}
                        links={links}
                        handleRemoveLink={handleRemoveLink}
                    />

                    <ResourceDocuments
                        uploadDocument={uploadDocument}
                        textFieldRef={textFieldRef}
                        newDocumentName={newDocumentName}
                        changeDocumentName={handleChangeDocumentName}
                        fileErrorText={fileErrorText}
                        newDocumentFile={newDocumentFile}
                        documents={documents}
                        handleDocumentChange={handleDocumentChange}
                        loading={loading}
                        removeDocument={removeDocument}
                    />

                    {/*save button is only for description*/}
                    <div onDragStart={preventDefaultDrag} className="action-container">
                        <Button
                            type="button"
                            variant="contained"
                            color={'secondary'}
                            disableElevation
                            onClick={saveDescription}
                            disabled={getSaveDescriptionDisabled()}
                            startIcon={<SaveOutlinedIcon />}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            )}

            {openConfirmUnsavedChanges && (
                <Confirm
                    open={openConfirmUnsavedChanges}
                    closeConfirm={() => setOpenConfirmUnsavedChanges(false)}
                    dialogTitle={'Unsaved changes'}
                    dialogDescription={'You have unsaved changes. Do you want to save them?'}
                    dialogConfirmButtonLabel={'Save'}
                    dialogCancelButtonLabel={'Cancel'}
                    handleConfirm={handleSaveChanges}
                    handleDiscardChanges={handleDiscardChanges}
                />
            )}

            {error && (
                <SnackbarGlobal
                    handleCloseSnackbar={handleCloseSnackbar}
                    message={error}
                    snackbarOpen={errorSnackbarOpened}
                    isError={true}
                />
            )}
        </div>
    );
};

export default LobbyResources;
