import React from 'react';
import {Radio} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {preventDefaultDrag} from '../../Utils/utils';

const SinglePollOption = ({
    option,
    handleSelect,
    userVoted,
    selectedOption,
    finished,
    voteResults,
    index,
    translation,
}) => {
    return (
        <div
            className={`poll_option ${option._id === userVoted ? '-is-checked' : ''}`}
            onClick={() => handleSelect(option._id)}
        >
            <div onDragStart={preventDefaultDrag} className="poll_option-container">
                {!userVoted && !finished && (
                    <Radio color="primary" checked={option._id === selectedOption} />
                )}
                {option._id === userVoted && <CheckCircleIcon className="poll_option-icon -colored" />}
                <div>
                    <h1 className="poll_option-title">{option.text}</h1>
                    {(userVoted || finished) && (
                        <p onDragStart={preventDefaultDrag} className="poll_option-text">
                            {voteResults &&
                                `${voteResults[index]}% ${translation?.polls?.pollVoted || 'participants voted'}`}
                        </p>
                    )}
                </div>
            </div>
            {(userVoted || finished) && (
                <div onDragStart={preventDefaultDrag} className="poll_option-progress-wrapper">
                    <div
                        className="poll_option-progress"
                        style={{
                            transform: `translateX(-${100 - (voteResults ? voteResults[index] : 0)}%)`,
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
};

export default SinglePollOption;
