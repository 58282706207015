import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {preventDefaultDrag} from '../../../../Utils/utils';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import RoleTitle from './RoleTitle';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import SingleRoleDetails from './SingleRoleDetails';
import Accordion from '@material-ui/core/Accordion';

class AccordionLayout extends PureComponent {
    render() {
        const {
            key,
            isActive,
            role,
            roles,
            roleTitle,
            openConfirmDeleteDialog,
            handleOpenSuccessSnackbar,
            handleOpenErrorSnackbar,
            handleAddRole,
            onChange,
            showSection,
            filesUrl,
        } = this.props;
        return (
            <Accordion
                expanded={isActive}
                onChange={onChange}
                className={'advanced-options-container accordion-wrapper ' + (isActive ? 'active' : '')}
                key={key}
            >
                <AccordionSummary tabIndex="-1" className="summary">
                    <RoleTitle
                        role={role}
                        isActive={isActive}
                        roleTitle={roleTitle}
                        handleDeleteRoleDialog={openConfirmDeleteDialog}
                    />
                </AccordionSummary>
                <AccordionDetails style={{padding: 0}}>
                    <div onDragStart={preventDefaultDrag} className="accordion-wrapper-details">
                        <SingleRoleDetails
                            role={role}
                            roleTitle={roleTitle}
                            roles={roles}
                            openSuccessSnackbar={handleOpenSuccessSnackbar}
                            openErrorSnackbar={handleOpenErrorSnackbar}
                            addNewRole={handleAddRole}
                            showSection={showSection}
                            filesUrl={filesUrl}
                            onGetEvent={this.props.onGetEvent}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEvent: (eventId) => dispatch(actions.getEvent(eventId)),
    };
};

export default connect(null, mapDispatchToProps)(AccordionLayout);
