import React from 'react';
import {useSelector} from 'react-redux';
import {Accordion, AccordionDetails, AccordionSummary, Tooltip} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import EventIcon from '@material-ui/icons/Event';
import DisplaySpeakers from '../DetailedTimeslot/DisplaySpeakers';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LiveLabel from '../../ProgramComponents/LiveLabel';
import {ReactComponent as Discard} from '../Agenda/helpers/svgs/discard.svg';
import {ReactComponent as AddToMyAgenda} from '../Agenda/helpers/svgs/add-to-my-agenda.svg';

const TimeslotAccordionTablet = ({
    timeslot,
    stopPropagation,
    createExternalCalendarData,
    toggleAccordion,
    expand,
    refTimeslot,
    handleSetActiveTimeslot,
    speakersArray,
    displayNumberOfSearchResult,
    activeDays,
    addDeleteAgenda,
    isElementAddedToTimeslot,
}) => {
    const eventSlug = useSelector((state) => state?.event?.data?.slug);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const handleAddTimeslotToCalendar = (e) => {
        createExternalCalendarData();
        e.stopPropagation();
    };

    return (
        <Accordion
            elevation={0}
            onClick={handleSetActiveTimeslot(timeslot)}
            id={timeslot._id}
            expanded={expand}
            ref={refTimeslot}
        >
            <AccordionSummary className={'accordion-summary'}>
                <div className={'timeslot-time-sm timeslot-time-tablet'}>
                    <p>
                        {timeslot.start} - {timeslot.end}
                    </p>
                    <p className={'auditorium-title auditorium-title-tablet'}>
                        <a
                            href={`/event/${eventSlug}/auditorium/${timeslot.auditoriumIndex}`}
                            onClick={stopPropagation}
                        >
                            {timeslot.auditoriumName}
                        </a>
                    </p>
                </div>
                <div className={`timeslot-details-tablet`}>
                    <div className={'live-label-container-tablet'}>
                        <h5 className={'timeslot-title-sm'}>{timeslot.title}</h5>
                        {timeslot.isLive && <LiveLabel />}
                    </div>
                    <p className={`timeslot-description-sm accordion-timeslot-speakers`}>{timeslot.speakers}</p>
                </div>
                <div className={'actions-container-tablet'}>
                    {timeslot.description && (
                        <p className={'svg-container-mobile'} onClick={toggleAccordion}>
                            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </p>
                    )}
                    {!expand ? (
                        <Tooltip
                            arrow
                            title={`${
                                isElementAddedToTimeslot(timeslot)
                                    ? translation?.agendaTranslations?.addedToYourAgenda ||
                                      defaultTranslation.agendaTranslations?.addedToYourAgenda
                                    : translation?.agendaTranslations?.addToMyAgenda ||
                                      defaultTranslation.agendaTranslations?.addToMyAgenda
                            }`}
                            placement="bottom-end"
                        >
                            <button className="add-to-my-agenda-btn" onClick={addDeleteAgenda(timeslot)}>
                                {isElementAddedToTimeslot(timeslot) ? (
                                    <Discard className={'discard'} />
                                ) : (
                                    <AddToMyAgenda />
                                )}
                            </button>
                        </Tooltip>
                    ) : null}
                </div>
            </AccordionSummary>
            <AccordionDetails className={`accordion-details`}>
                <div className="scroll-left-container d-flex w-100">
                    <div className={'column'}>
                        <p className="timeslot-description" dangerouslySetInnerHTML={{__html: timeslot.description}} />
                        <div className="add-to-calendar-button-container add-to-calendar-button-container-sm">
                            <Button
                                variant="outlined"
                                size={'small'}
                                color="primary"
                                onClick={createExternalCalendarData}
                                startIcon={<EventIcon />}
                            >
                                {translation?.programDropdown.addSlotToCalendar ||
                                    defaultTranslation?.programDropdown.addSlotToCalendar}
                            </Button>
                        </div>
                        <DisplaySpeakers
                            speakersArray={speakersArray}
                            translation={translation}
                            defaultTranslation={defaultTranslation}
                        />
                    </div>
                    <div className={'empty-div'}></div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default TimeslotAccordionTablet;
