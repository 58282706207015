import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import * as actions from '../../../../store/actions';
import BackIcon from '@material-ui/icons/NavigateBeforeOutlined';
import MasterAdminNavigation from '../../MasterAdminNavigation';
import '../../../../CSS/adminDashboard.scss';
import Spinner from "../../../../SmallLayoutComponents/Spinner";
import {preventDefaultDrag} from '../../../../Utils/utils';
import axios from '../../../../store/axios-instance';
import moment from 'moment';
import EventDailyStat from "./EventDailyStat";

class SingleEventStatistics extends React.Component {
    componentDidMount() {
        this.props.onSetAdminMenu();
        this.getEventStatistics();
    }

    state = {
        event: '',
        statistics: '',
        loading: false,
        eventHasEnded: false
    };

    getEventStatistics = () => {
        const {eventId} = this.props.match.params;
        this.setState({loading: true})
        axios({method: 'get', url: `/master-admin/event-statistics/${eventId}`})
            .then((response) => {
                let eventHasEnded = false;
                const now = moment();
                let eventEnd = moment(response.data.statistics?.eventEnd);
                if (now > eventEnd) {
                    eventHasEnded = true;
                }
                this.setState({event: response.data.event, statistics: response.data.statistics, loading: false, eventHasEnded: eventHasEnded});
            })
            .catch((error) => {
                this.setState({loading: false});
            });
    };

    render() {
        const {event, statistics, loading, eventHasEnded} = this.state;
        return (
            <div onDragStart={preventDefaultDrag} className="superadmin-dashboard">
                <MasterAdminNavigation/>
                {loading && <Spinner/>}
                {!loading &&
                <section className="top-section">
                    <div onDragStart={preventDefaultDrag} className="container">
                        <h1>
                            <Link to={{pathname: `/dashboard/organizers/all-events`}}>
                                <BackIcon/>
                            </Link>
                            {event.name ? `${event.name} - Event Statistics` : 'Unable to load event data!'}
                        </h1>
                        {event && (
                            <div onDragStart={preventDefaultDrag} className="organizer-details">
                                <p> Event Start Date: {event?.eventStartDate} </p>
                                <p> Event End Date: {event?.eventEndDate} </p>
                                <p> Event Number of Days: {statistics?.numberOfDays}</p>
                                <p> Number of Auditoriums: {statistics?.auditoriums}</p>
                                <p> Event has Participant Access Management: {statistics?.participantManagementAccess ? 'true' : 'false'}</p>
                                <p> Event has Exhibitor Access Mangagement: {statistics?.exhibitorHasAccessManagement ? 'true' : 'false'}</p>
                                <p> Event has participant payment: {statistics?.participantPayment ? 'true' : 'false'}</p>
                                <p> Event has exhibitor payment: {statistics?.exhibitorPayment ? 'true' : 'false'}</p>
                                <p> Event has matching: {statistics?.hasMatching ? 'true' : 'false'}</p>
                                <p> Number of Auditorium timeslots: {statistics?.auditoriumTimeslots}</p>
                                <p> Number of videos on-demand: {statistics.onDemandVideos}</p>
                                <p> Participants now: {statistics?.participantsNow}</p>
                                <p> Exhibitors now: {statistics?.exhibitorsNow}</p>
                                {
                                    statistics.exhibitHalls && (
                                        <>
                                            <p> Number of Exhibit halls: {statistics.exhibitHalls.length}</p>
                                            {
                                                statistics.exhibitHalls.length > 1 && (
                                                    <>
                                                        {statistics.exhibitHalls.map(
                                                            hall => (
                                                                <p>Exhibit Hall : {hall.name} - Booths number : {hall.count}</p>
                                                            )
                                                        )}
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                                <EventDailyStat statistics={statistics.dailyLikes} stat={'Likes'}/>
                                <EventDailyStat statistics={statistics.dailyPosts} stat={'Posts'}/>
                                <EventDailyStat statistics={statistics.dailyComments} stat={'Comments'}/>
                                <EventDailyStat statistics={statistics.dailyParticipants} stat={'Participants'}/>
                                <EventDailyStat statistics={statistics.dailyExhibitors} stat={'Exhibitors'}/>
                                {
                                    statistics.numberOfDays > 1 ? (
                                        <>
                                            {statistics.dailyFirstAndLastTimeslots.map(
                                                timeslot => (
                                                    <>
                                                        <p>Event First Timeslot Start ({timeslot.date}) : <>{timeslot.firstTimeslot ? timeslot.firstTimeslot : '-'}</></p>
                                                        <p>Event Last Timeslot End ({timeslot.date}) : <>{timeslot.lastTimeslot ? timeslot.lastTimeslot : '-'}</></p>
                                                    </>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <p> Event First Timeslot Start: {moment(statistics?.eventStart).format('YYYY-MM-DD HH:mm:ss')} </p>
                                            <p> Event Last Timeslot End: {moment(statistics?.eventEnd).format('YYYY-MM-DD HH:mm:ss')} </p>
                                        </>

                                    )
                                }
                                {
                                    statistics.dailyTimeslots.map(
                                        timeslot => (
                                            <p>Day of {timeslot.date} - Number of timeslots: {timeslot.count}</p>
                                        )
                                    )
                                }
                                <p> Participants 24h before event start: {statistics?.participants24hBefore} </p>
                                <p> Participants 1h before event start: {statistics?.participants1hBefore} </p>
                                <p> Participants event start: {statistics?.participantsStart} </p>
                                <p> Participants event end: {eventHasEnded ? statistics?.participantsEnd : 'Not available!'} </p>
                                <p> Exhibitors event end: {eventHasEnded ? statistics?.exhibitorsEnd : 'Not available!'} </p>
                                <p> Posts event start: {statistics?.postsStart} </p>
                                <p> Posts event end: {eventHasEnded ? statistics?.postsEnd : 'Not available!'} </p>
                                <p> Comments event start: {statistics?.commentsStart} </p>
                                <p> Comments event end: {eventHasEnded ? statistics?.commentsEnd : 'Not available!'} </p>
                                <p> Likes event start: {statistics?.likesStart} </p>
                                <p> Likes event end: {eventHasEnded ? statistics?.likesEnd : 'Not available!'} </p>
                            </div>
                        )}
                    </div>
                </section>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAdminMenu: () => dispatch(actions.setAdminMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleEventStatistics);
