import React from "react";


const DisplaySpeakers = ({speakersArray, translation, defaultTranslation}) => {
    return (
        <>
        {speakersArray.length > 0 &&
                <div className='speakers-container'>
                    <p className='speakers-title'>{translation?.programDropdown.speakers ||
                        defaultTranslation?.programDropdown.speakers}</p>
                    <ul className='speakers-list'>
                        {speakersArray.map((speaker, index) => {
                            return (
                                <li key={index}>{speaker}</li>
                            )
                        })}
                    </ul>
                </div>
        }
        </>
    )
}

export default DisplaySpeakers;