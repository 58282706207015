const es = {
    generalText: {
        upload: 'Cargar',
        save: 'Guardar',
        add: 'Agregar',
        remove: 'Eliminar',
        close: 'CERRAR',
        update: 'ACTUALIZAR',
        cancel: 'CANCELAR',
        confirm: 'Confirmar',
        download: 'Descargar',
        refresh: 'Actualizar',
        back: 'Atrás',
        submit: 'Enviar',
        send: 'Enviar',
        leave: 'Salir',
        step1: 'Paso 1',
        step2: 'Paso 2',
        or: 'o',
        delete: 'Borrar',
        please: 'Por favor',
        before: 'antes',
        approve: 'Aprobar',
        decline: 'Rechazar',
        start: 'Iniciar ',
        stop: 'Parar ',
        resetFilter: 'Reiniciar el filtro',
        apply: 'Aplicar',
        filters: 'Filtros',
        unsavedChangesTitle: 'Cambios sin guardar',
        unsavedChangesText: 'Tienes cambios sin guardar. ¿Quieres guardalos?',
        selectAll: 'Seleccionar Todos',
        unselectAll: 'Deseleccionar Todos',
    },
    time: {
        justNow: 'justo ahora',
        minute: 'minuto',
        minutes: 'minutos',
        hour: 'hora',
        hours: 'horas',
        day: 'día',
        days: 'días',
        month: 'mes',
        months: 'meses',
        yesterday: 'ayer',
        today: 'hoy',
        tomorrow: 'mañana',
        monday: 'lunes',
        tuesday: 'martes',
        wednesday: 'miércoles',
        thursday: 'jueves',
        friday: 'viernes',
        saturday: 'sábado',
        sunday: 'domingo',
        january: 'enero',
        february: 'febrero',
        march: 'marzo',
        april: 'abril',
        may: 'mayo',
        june: 'junio',
        july: 'julio',
        august: 'agosto',
        september: 'septiembre',
        october: 'octubre',
        november: 'noviembre',
        december: 'diciembre',
        januaryShort: 'ene',
        februaryShort: 'feb',
        marchShort: 'mar',
        aprilShort: 'abr',
        mayShort: 'may',
        juneShort: 'jun',
        julyShort: 'jul',
        augustShort: 'ago',
        septemberShort: 'sep',
        octoberShort: 'oct',
        novemberShort: 'nov',
        decemberShort: 'dic',
    },
    errors: {
        noFile: 'No se ha elegido ningún archivo',
        required: 'El campo es obligatorio',
        emailNotValid: 'El correo electrónico no es válido',
        passwordMinLength: 'La contraseña debe tener un mínimo de 8 caracteres',
        passwordMismatch: 'La contraseña no coincide',
        passwordIsSame: 'Tu nueva contraseña debe ser diferente',
        noInvitationCode: 'Por favor, introduce tu código de invitación',
        imageFileNotSupported: 'Tipo de archivo no soportado. Por favor usar uno de los siguientes:',
        phoneInvalid: 'Por favor introduce un número de teléfono válido. Por ejemplo: +528110654789',
        linkInvalid: 'Por favor introduce un enlace válido (https://site.com)',
        facebookLinkInvalid: 'Por favor, introduce un enlace válido (https://facebook.com/)',
        linkedinLinkInvalid: 'Por favor, introduce un enlace válido (https://linkedin.com/)',
        twitterLinkInvalid: 'Por favor, introduce un enlace válido (https://twitter.com/)',
        youtubeLinkInvalid: 'Por favor introduce un enlace válido (https://youtube.com)',
        youtubeOrVimeoLinkInvalid:
            'Introduce un enlace válido de YouTube (https://www.youtube.com/) o Vimeo (http://vimeo.com/) link',
        googleScholarLinkInvalid: 'Introduce un enlace válido (https://scholar.google.com/)',
        researchGateLinkInvalid: 'Introduce un enlace válido (https://www.researchgate.net/)',
        connectionProblemTextFirst: 'Parece que hay un problema con la conexión. Por favor',
        connectionProblemButton: 'Click',
        connectionProblemTextSecond: 'O refresca la página',
        fileTooLarge: 'El archivo está muy pesado. El tamaño máximo debe ser 2 Mb.',
        fileTooLargeTextFirst: 'El archivo está muy pesado.',
        fileTooLargeTextSecond: 'Tamaño máximo de archivo',
        fileNameTooLongTextFirst: 'Nombre del archivo demasiado largo. El límite es',
        fileNameTooLongTextSecond: 'caracteres.',
        maxWallInputLength: 'Has llegado a 1200, el número máximo de caracteres.',
    },
    footer: {
        cookiesText:
            'Este sitio web usa cookies esenciales, que son necesarias para su funcionamiento. También utiliza cookies de terceros, que son necesarias para el funcionamiento de algunas de nuestras funcionalidades. Si deseas aprender más o retirar tu consentimiento hacia algunas cookies, por favor consulta nuestra',
        cookiesLink: 'política de cookies',
        cookiesButton: 'ACEPTAR',
        privacyPolicy: 'Política de privacidad',
        cookies: 'Cookies',
        termsAndConditions: 'Términos y condiciones',
        socialLinksTitle: 'Síguenos:',
    },
    menu: {
        logIn: 'INICIAR SESIÓN',
        logOut: 'CERRAR SESIÓN',
        scholarRegistration: 'Unirse como colaborador/ estudio',
        exhibitorRegistration: 'Unirse como expositor',
        participantRegistration: 'Unirse como participante',
        createEvent: 'Organiza un evento',
        loginDisabledInfoText: 'El organizador ha cerrado el inicio de sesión. Chequear nuevamente más tarde.',
    },
    homepage: {
        organizedBy: 'ORGANIZADO POR',
        date: 'FECHA',
        location: 'DOMINIO Y LOCALIZACIÓN',
        price: 'PRECIO',
        tabIntro: 'INTRODUCCIÓN',
        tabProgram: 'PROGRAMA',
        tabSponsors: 'PATROCINADORES',
        tabPartners: 'SOCIOS',
        tabExhibitors: 'EXPOSITORES',
        previousEventsSectionTitle: 'EVENTOS ANTERIORES',
    },
    socialButtons: {
        linkedinButton: 'Inicia sesión con LinkedIn',
        loginTabLinkedinButton: 'con LinkedIn',
        facebookButton: 'Inicia sesión con Facebook',
        loginTabFacebookButton: 'con Facebook',
        googleButton: 'Inicia sesión con Google',
        loginTabGoogleButton: 'con Google',
        twitterButton: 'Inicia sesión con Twitter',
        loginTabTwitterButton: 'con Twitter',
        socialButtonsLogInTitle: 'Unirse con su perfil de redes sociales',
        socialButtonsSignUpTitle: 'Registrarse con su perfil de redes sociales',
    },
    login: {
        inputEmail: 'Correo electrónico',
        inputPassword: 'Contraseña',
        rememberMeButton: 'Recuérdame',
        forgotPasswordButton: '¿Olvidaste la contraseña?',
        loginButton: 'INICIAR SESIÓN',
        loginClosedButton: 'Inicio de sesión cerrado',
        loginTitleLowerCase: 'inicar sesión',
        recoverPasswordDialogTitle: 'RECUPERACIÓN DE CONTRASEÑA',
        recoverPasswordDialogRecoverButton: 'RECUPERAR',
        errorIncorrectDates: 'Correo electrónico o contraseña incorrecta',
        orLogInWithEmail: 'o inicia sesión con correo electrónico',
        logInAsParticipantSecondText: 'como participante',
        logInAsExhibitorSecondText: 'como expositor',
        logInAsPosterSecondText: 'como colaborador/estudio',
        errorAccountNotFound: 'No pudimos encontrar tu cuenta myOnvent',
    },
    register: {
        inputFirstName: 'Nombre',
        inputLastName: 'Apellido',
        inputEmail: 'Correo electrónico',
        inputPassword: 'Contraseña',
        inputConfirmPassword: 'Confirma la contraseña',
        gdprTextFirst: 'Al proceder, estás de acuerdo con nuestros',
        gdprTextTerms: 'Términos de uso',
        gdprTextSecond: 'y confirmas que has leído nuestros',
        gdprTextCookies: 'cookies',
        gdprTextPrivacy: 'Política de privacidad',
        gdprTextAgree: 'Por favor, acepta la política de privacidad',
        registerButton: 'Inscríbete',
        signUpTitleLowerCase: 'inscríbete',
        invitationCode: 'código de invitación',
        registerNotAllowed: 'Registro cerrado',
        logInTitleFirst: 'Unirse ',
        logInTitleSecond: 'evento ',
        signUpTitleTextFirst: 'Primera vez en',
        signUpTitleTextSecond: 'myOnvent',
        orFillInTheForm: 'o completa el formulario siguiente',
        uploadProfilePicture: 'Sube una foto de perfil',
        editProfilePicture: 'Edita la foto de perfil',
        errorAlreadyHaveMOAccount: 'Ya tienes una cuenta en myOnvent',
        step1From2Text: 'paso 1 de 2',
        step2From2Text: 'paso 2 de 2',
        metaTitleScholarRegistration: 'Registro de colaborador/estudio',
    },
    newLoginRegistrations: {
        joinEvent: 'UNIRSE AL EVENTO',
        loginWelcome: '¡Bienvenido a myOnvent! Para continuar, elija un método de inicio de sesión',
        continueButton: 'Continuar',
        seemsLikeYouDontHaveMyOnventAccount: 'Parece que no tienes una cuenta de myOnvent',
        uploadProfilePictureOptional: 'Cargar foto de perfil (opcional)',
        createPlatformAccount: 'Crear cuenta de plataforma',
        platformAccount: 'Cuenta de plataforma',
        selectRole: 'Seleccionar rol',
        eventAccount: 'Cuenta del evento',
        password: 'Contraseña',
        confirmPassword: 'Confirmar contraseña',
        email: 'Correo electrónico',
        passwordMustContain: 'La contraseña debe contener:',
        minimum8Characters: 'Mínimo 8 caracteres',
        aLowerCaseLetter: 'Una letra minúscula',
        anUppercaseLetter: 'Una letra mayúscula',
        aNumber: 'Un número',
        aSpecialCharacter: 'Un carácter especial',
        firstName: 'Nombre',
        lastName: 'Apellido',
        weHaveSentAVerificationCodeToYourEmail: 'Hemos enviado un código de verificación a su correo electrónico',
        continueRegistration: 'Para continuar con el registro,',
        enterCodeBelow: 'ingrese el código de verificación a continuación:',
        codeWillExpire: 'Este código expirará en',
        codeExpired: 'El código ha expirado',
        codeNotReceived: '¿No ha recibido el código? ¿Ha revisado su carpeta de spam?',
        resendTimer: 'Reenviar en:',
        resendLabel: 'Reenviar',
        resendCodeLabel: 'Reenviar el código',
        codeError: 'El código ingresado es incorrecto.',
        codeErrorFix: 'Vuelva a ingresar el código correcto de su correo electrónico o vuelva a enviar un nuevo código',
        congrats: '¡Felicitaciones!',
        emailConfirmed: 'Tu correo electrónico ha sido confirmado:',
        imHereAs: 'Estoy aquí como:',
        participant: 'Participante',
        participantText: 'Para ver streaming, comunicarse, visitar expositores y carteles / estudios.',
        exhibitor: 'Expositor',
        exhibitorText: 'Para presentar su empresa y comunicarse con los asistentes al evento',
        poster: 'Cartel / Estudio',
        posterText: 'Para presentar investigación para una conferencia académica o profesional',
        emailIsNotAllowed: 'Este correo electrónico no está permitido para registrarse en este evento',
        didntReceiveEmail: '¿No recibiste el correo electrónico?',
        sendAgain: 'Enviar de nuevo',
        incorrectPassword: 'Contraseña incorrecta',
        fileTypeError:
            'Tipo de archivo no admitido. Utilice uno de los siguientes formatos: jpeg, jpg, jfif, gif o png.',
        fileSizeError: 'Archivo demasiado grande. Tamaño máximo de archivo: 2 MB.',
        pleaseCompleteAllRequiredFields: 'Por favor complete todos los campos obligatorios',
        joinTheEvent: 'Unirse al evento',
        joinTheEventSub: 'Para comenzar su experiencia de evento virtual en línea',
        changeEmail: 'CAMBIAR CORREO ELECTRÓNICO',
        changeRole: 'CAMBIAR ROL',
        logout: 'CERRAR SESIÓN',
        headingUser: 'Bienvenido de nuevo',
        subHeadingUser: 'Parece que ya tienes una cuenta en la plataforma',
        forgotPasswordHeadline: 'Contraseña olvidada',
        forgotPasswordText: 'No te preocupes, te enviaremos más instrucciones',
        checkYourEmail: 'Revisa tu correo electrónico',
        checkYourEmailText: 'Te hemos enviado un enlace para restablecer la contraseña a',
        headingNoUser: 'Iniciar sesión',
        subHeadingNoUser: '¡Bienvenido a myOnvent! Para continuar, selecciona un método de inicio de sesión',
        resetPasswordButton: 'restablecer la contraseña',
        createNewPassword: 'Crear nueva contraseña',
        createNewPasswordText: 'Su nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente',
        passwordReset: 'Restablecimiento de contraseña',
        passwordResetText:
            'Se ha restablecido su contraseña correctamente. Haga clic a continuación para volver a iniciar sesión.',
        registrationIsClosed: 'El registro para todos los roles está cerrado.',
        chooseAnOption: 'Elige una opciónn',
        yourAnswer: 'Tu respuesta',
        firstNameError: 'El nombre es demasiado corto. Longitud mínima: 2',
        lastNameError: 'El apellido es demasiado corto. Longitud mínima: 2',
        recoveryPassword: 'Recuperar contraseña',
        incorrectPasswordError:
            'Contraseña incorrecta. Inténtalo de nuevo o haz clic en "Olvidé mi contraseña" para cambiarla.',
        optional: 'Opcional',
        spamEmailError: 'Ha ocurrido un problema al crear su cuenta. Por favor, vuelva a intentarlo más tarde.',
        invalidInput:
            "Entrada no válida. Use solo letras (A-Z, a-z), números (0-9), punto (.), apóstrofo ('), guión (-) y espacios. El primer carácter debe ser una letra.",
    },
    emailUpdate: {
        changeEmail: 'Cambiar correo electrónico',
        changeEmailDescription:
            'Antes de asignar el nuevo correo electrónico a su cuenta, queremos asegurarnos de que sea un correo electrónico nuevo y que no pertenezca a nadie.',
        emailTakenError: 'Ese correo electrónico ya está en uso. Pruebe con otro.',
        enterTheConfirmationCode: 'Introducir el código de confirmación',
        verificationCodeSent: 'Código de verificación enviado:',
        checkYourSpamFolder: '¿No encuentra el código? Revise su carpeta de correo no deseado',
        emailSuccess: '¡Éxito! Ha cambiado el correo electrónico asociado a su cuenta',
        emailError: 'Error. Inténtelo de nuevo más tarde',
        confirmPassword: 'Confirmar contraseña',
        confirmPasswordDescription: 'Para cambiar el correo electrónico, confirme su contraseña:',
        newEmail: 'Nuevo correo electrónico',
        password: 'Contraseña',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        change: 'Cambiar',
    },

    agendaTranslations: {
        myAgenda: 'MI AGENDA',
        all: 'Todos',
        sessions: 'Sesiones',
        meetings: 'Reuniones',
        pastSlots: 'Ranuras pasadas',
        meetingWith: 'Reunión con',
        pending: 'Pendiente',
        accepted: 'Aceptado',
        requested: 'Solicitado',
        canceled: 'Cancelado',
        now: 'Ahora',
        live: 'En vivo',
        noMeetings: 'Aún no hay reuniones programadas',
        noSessions: 'Aún no hay sesiones en tu agenda.',
        noUpcomingSessions: 'Aún no hay sesiones próximas en tu agenda',
        noUpcomingMeetings: 'Aún no hay reuniones próximas en tu agenda.',
        noUpcomingAgenda: 'Aún no hay nada próximo en tu agenda.',
        noAgendaItem: 'Aún no hay nada en tu agenda.',
        meetingRequests: 'SOLICITUDES DE REUNIÓN',
        finished: 'Terminado',
        past: 'Pasado',
        goToMeeting: 'IR A LA REUNIÓN',
        addToMyAgenda: 'Agregar a mi agenda',
        addedToYourAgenda: 'Agregado a tu agenda',
        acceptedAllMeetings: 'Aceptar todas las reuniones',
    },
    participantRegistration: {
        metaTitle: 'Registro de participante',
        choosePackageTitle: 'ELIGE EL PAQUETE DE ACCESO',
        noPackageText: 'Por favor elige un paquete',
        gdprTextFirst: 'Al proceder, estás de acuerdo con nuestra',
        gdprTextPrivacy: 'Política de privacidad',
        gdprTextSecond: 'y',
        gdprTextTerms: 'Los términos y condiciones del evento',
        gdprTextAgree: 'Por favor, acepta la política de privacidad',
        registerButtonFree: 'Completa tu registro',
        registerButtonPay: 'Procede al pago',
    },
    exhibitorRegistration: {
        metaTitle: 'Registro de expositor',
        choosePackageTitle: 'ELIGE EL PAQUETE DE ACCESO',
        gdprTextFirst: 'Al proceder, estás de acuerdo con nuestra',
        gdprTextPrivacy: 'Política de privacidad de eventos',
        gdprTextSecond: 'y',
        gdprTextTerms: 'Términos y condiciones de eventos',
        gdprTextAgree: 'Por favor, acepta la política de privacidad',
        registerButtonFree: 'Registro completo',
        registerButtonPay: 'Procede al pago',
    },
    stripePayment: {
        cardNumber: 'Número de tarjeta',
        expirationDate: 'Fecha de expiración (MM/AA)',
        cvc: 'CVC',
        disclaimerText:
            'Esta operación de pago está encriptada fin a fin. La seguridad y privacidad están garantizadas por los servicios de nuestra plataforma de pago',
        disclaimerTextLink: 'Haz click aquí para saber más',
        cancelButton: 'CANCELAR',
        payButton: 'Pagar',
    },
    recoverPassword: {
        recoverTextSend: 'El correo electrónico de recuperación de la contraseña se ha enviado correctamente.',
    },
    unsubscribe: {
        unsubscribeTitle: '¿Deseas dejar de estar inscrito?',
        unsubscribeHomepage: 'Ir a la página principal',
    },
    notAvailable: {
        title: 'El evento no se encuentra disponible. ',
        description:
            'El evento que estás buscando no está disponible. Ha sido cerrado por el organizador o desabilitado por un administrador',
        fullOverlayEvents: 'Revisa tus otros eventos',
        fullOverlayNoEvents: 'no se han encontrado otros eventos',
    },
    notFound: {
        notFoundDescription: 'Opps…No hemos encontrado tu evento con esa URL',
        notFoundHomepage: 'Ir a la página principal',
    },
    eventMenu: {
        organizerButton: 'ORGANIZADOR',
        coOrganizerButton: 'ADMINISTRAR',
        exhibitorButton: 'EXPOSITOR',
        posterStudyButton: 'COLABORADOR / ESTUDIO',
        boothButton: 'STAND VIRTUAL',
        boothsButton: 'STANDS VIRTUALES',
        homeButton: 'INICIO',
        contactsButton: 'CONTACTOS',
        meetingsButton: 'REUNIONES',
        chatsButton: 'CHATS',
        programButton: 'PROGRAMA',
        archiveButton: 'MEMORIA DE ARCHIVOS',
        myAccountButton: 'MI CUENTA',
        lobbyButton: 'Lobby',
        roomsButton: 'SALAS',
        connectionProblemTextFirst: 'Parece que hay un problema con la conexión. Por favor da click',
        connectionProblemButton: 'AQUÍ',
        connectionProblemTextSecond: 'O refresca la página',
        sessionProblemTextFirst: 'Tu sesión ha expirado. Por favor da click',
        sessionProblemButton: 'AQUÍ',
        sessionProblemTextSecond: 'Para iniciar sesión de nuevo.',
    },
    myEventsDropdown: {
        title: 'MIS EVENTOS',
        createButtonMobile: 'CREAR',
    },
    myBoothsDropdown: {
        title: 'MIS STANDS VIRTUALES',
        emptyStudyTitle: 'Stand de colaborador/estudio',
    },
    contactsDropdown: {
        title: 'TUS CONTACTOS',
        requestsTitle: 'Solicitudes de contacto',
        rejectButton: 'RECHAZAR',
        acceptButton: 'ACEPTAR',
        pendingButton: 'Pendiente',
        addButton: 'Agregar',
        noContacts:
            'No tienes ningún contacto aún. Por favor visita la sessión de Networking para comenzar a gestionar tus contactos.',
    },
    meetingsDropdown: {
        meetingWith: 'Reunión con',
        pending: 'Pendiente',
        accepted: 'Aceptada',
        canceled: 'Cancelada',
        noMeetings: 'No hay reuniones agendadas todavía',
        finished: 'Terminado',
    },
    programDropdown: {
        stages: 'Etapas',
        with: 'con',
        noProgram: 'No hay entradas de programa disponibles por el momento.',
        fullProgramButton: 'Programa completo', // new translation
        addToCalendarButton: 'Añadir al calendario', // new translation
        addedToYourAgenda: 'AÑADIDO A TU AGENDA',
        addEventButton: 'Añadir evento', // new translation
        addToMyAgenda: 'Añadir a mi agenda', // new translation
        addEventToYourCalendarDialogTitle: 'Añadir evento a tu calendario', // new translation
        addTimeslotToYourCalendar: 'Añadir franja horaria a tu calendario', // new translation
        tip: 'CONSEJO: ', // new translation
        tipDescription:
            'También puedes añadir franjas horarias individuales a tu calendario haciendo clic en el icono del calendario junto a cada franja horaria.', // new translation
        tipTimeslotDescription:
            'También puedes añadir todo el evento haciendo clic en el botón "Añadir al calendario".', // new translation
        durationEventTitle: 'Duración del evento: ', // new translation
        timeslotTitle: 'Título: ', // new translation
        slotScheduled: 'Franja horaria programada: ', // new translation
        addSlotToCalendar: 'Añadir franja horaria al calendario', // new translation
        speakers: 'Oradores', // new translation
        auditoriumName: 'Nombre del auditorio', // new translation
        timezoneTooltipText:
            'El programa se muestra en la zona horaria del organizador. Puedes cambiar la zona horaria de visualización para ver las sesiones en tu zona horaria preferida', // new translation
        liveLabel: 'En vivo', // new translation
        liveLabelTooltipText: 'Esta franja horaria está en vivo en el auditorio', // new translation
        openAuditorium: 'ABRIR AUDITORIO', // new translation
    },

    notificationsDropdown: {
        title: 'Notificaciones',
        contactRequestReceived: 'Has enviado una petición de contacto, ¡revísala!',
        contactRequestAccepted: 'ha aceptado tu solicitud de contacto, ¡comienza a platicar!',
        meetingRequestReceived: 'Te han enviado una petición de reunión, ¡revísala!',
        meetingRequestAccepted: 'Han aceptado la petición de reunión, ¡revísala!',
        meetingRequestCanceled: 'Han cancelado la reunión, ¡revísala!',
        meetingStarting: 'Una reunión comenzarà pronto, ¡revísala!',
        newBoothPost: 'ha posteado en tu stand virtual, ¡revísalo!',
        noNotifications: 'No tienes ninguna notificación todavía.',
        seeAll: 'VER TODO',
        hideAll: 'ESCONDER TODO',
        meetingWillStartTextSecond: 'comenzará pronto',
    },
    chatsDropdown: {
        title: 'CHATS',
        newChatProvideText: 'Por favor nombre tu chat. Podrás editarlo más adelante:',
        noChats: 'Crear al menos un chat para platicar con los participantes del evento',
        members: 'miembros',
        noOpenedChatText: 'Dar click en el chat para continuar la conversación',
        noMessagesInChatTextFirst: 'Rompe el hielo',
        noMessagesInChatTextSecond: 'Sé el primero en abrir la conversación.',
        newChatNameInput: 'Nombre del chat (opcional)',
        newChatAddMembers: 'Agrega contactos y otros participantes a este chat:',
        editChatAddMembers:
            'Por favor busca a los usuarios registrados en este evento que deseas agregar a este chat grupal.',
        newChatSearchInput: 'Buscar usuarios',
        newChatNoUsersMessage: 'No se han encontrado usuarios',
        newChatRemoveUserButton: 'Eliminar',
        newChatCancelButton: 'CERRAR',
        newChatCreateButton: 'CREAR',
        membersLeaveButton: 'Salir',
        membersUpdateButton: 'ACTUALIZAR',
        membersChatButton: 'Chat',
        chatSettingsButton: 'Ajustes',
        newMessageInput: 'Escribe el mensaje aquí',
        newVideoCallTooltipText: 'Haz click aquí para comenzar una videollamada',
        leaveVideoMeetingTitle: 'Salir de la videoconferencia',
        leaveVideoMeetingDescription: 'Estás por salir de la videoconferencia. ¿Estás seguro que quieres continuar?',
        leaveChatConfirmationTitle: 'Salir de la video llamada',
        leaveChatConfirmationText: '¿Estás seguro que quieres salir?',
    },
    myAccountDropdown: {
        title: 'MI CUENTA',
        viewProfileButton: 'Ver perfil',
        logoutButton: 'CERRAR SESIÓN',
    },
    myAccountPage: {
        changePasswordButton: 'CAMBIAR LA CONTRASEÑA',
        deleteAccountButton: 'ELIMINAR MI CUENTA',
        platformLanguage: 'Plataforma de lenguaje:',
        privacyPolicyLink: 'Nota de privacidad / Políticas',
        cookiesLink: 'Cookies',
        termsAndConditionsLink: 'Términos y condiciones',
        eventProfileSectionTitle: 'Perfil de evento',
        eventPackage: 'Paquete de evento',
        unregisterFromEventButton: 'Sal del registro del evento',
        unregisterCurrentEventButton: 'Sal del registro de este evento:',
        unregisterCurrentEventConfirmation: 'Por favor confirma que deseas salir del registro del evento',
        enableParticipantProfile: 'Activa el perfil del participante',
        editUserDialogTitle: 'Editar los detalles del usuario',
        editExhibitorDialogTitle: 'Edita los detalles del contacto de la compañía',
        editScholarDialogTitle: 'Editar los detalles del contacto del colaborador / estudio',
        editParticipantDialogTitle: 'Editar los detalles del participante',
        firstName: 'Nombre',
        lastName: 'Apellido',
        uploadImage: 'Cargar imagen',
        removeImage: 'Eliminar imagen',
        deleteError: 'No puedes borrar la cuenta. Primero borra los eventos creados para borrar la cuenta',
        deleteSuccess: 'Tu cuenta ha sido borrada.',
        reviewEventPrivacyPolicy: 'Revisa nuestra política de privacidad',
        title: 'Cargo',
        company: 'Empresa',
        phone: 'Teléfono',
        platformProfileSectionTitleTextSecond: 'perfil',
        createParticipantProfile: 'Crear perfil del participante',
        reviewPlatformConditions: 'Revisa las condiciones de nuestra plataforma',
        reviewEventConditions: 'Revisa las condiciones de nuestro evento',
    },
    changePassword: {
        title: 'CAMBIAR LA CONTRASEÑA',
        oldPassword: 'Contraseña antigua',
        oldPasswordError: 'La contraseña antigua no coincide',
        inputPassword: 'Nueva contraseña',
        inputConfirmPassword: 'Confirma la contraseña',
    },
    roomsDropdown: {
        auditoriumButton: 'Auditorio',
        archiveButton: 'MEMORIA DE ARCHIVOS',
        onDemandButton: 'A DEMANDA',
    },
    sideMenu: {
        liveWall: 'Chat en vivo',
        networking: 'Red de contactos',
        audience: 'PÚBLICO',
        info: 'INFORMACIÓN',
        videoWall: 'Video',
        booth: 'Stand virtual',
        boothWall: 'Stand',
        networkingTooltip: 'No tienes acceso a las funciones de networking',
        attendees: 'Participantes',
    },
    wall: {
        sortBy: 'Ordenar por:',
        popularity: 'POPULARIDAD',
        time: 'TIEMPO',
        emptyWallText: 'Sé el primero en agregar un post',
        newPostInput: '¿Qué estás pensando?',
        newCommentInput: 'Comentario',
        resourcesVideoDescription: 'Descripción del video',
        resourcesLinks: 'Enlaces',
        resourcesFiles: 'Archivos',
        pinTitle: 'Anclar',
        unPinTitle: 'Desanclar',
        deleteDialogTitle: '¿Borrar el posteo?',
        pinDialogDescription:
            'Ya hay un posteo anclado. Solo se puede anclar un posteo a la vez. Al proceder, el posteo anterior se desanclará automáticamente',
        edited: 'Editado',
        escapeToCancel: 'escape para cancelar',
        enterToSave: 'enter para guardar',
        editingState: 'Editar el mensaje',
        bannerModeration: 'Los posteos y comentarios de este muro  se moderan para asegurar la calidad del contenido',
        pendingApproval: 'Aprobación pendiente',
        approvePost: 'Aprobar posteo',
        approvePostComment: 'Aprobar comentario',
        commentsPendingApproval: 'Comentarios pendientes de aprobación',
    },
    networking: {
        searchInput: 'Buscar por usuario',
        emptyNetworkingSearchResults: 'La persona que estás buscando no se ha registrado para este evento en línea',
        emptyAuditoriumNetworkingSearchResults:
            'La persona que estás buscando todavía no ha asistido presente esta presentación',
        alphabetical: 'abc',
        online: 'en línea',
        matching: 'coincidencias',
        matchingAlgo:
            'Algoritmo de emparejamiento en acción. ¡Aguarda un minuto mientras curamos tus mejores coincidencias!',
        searchFilters: 'Buscar filtros',
        applyFilterTooltip: 'Para poder aplicar filtros selecciona por lo menos una opción',
        chat: 'Chat',
    },
    closedAuditoriumDialog: {
        title: 'Información del auditorio',
        contentPackage: 'Tu paquete  no cuenta con acceso a ningún auditorio',
    },
    closedShowfloorDialog: {
        title: 'Información de la Sala de Exhibición',
        contentPackage: 'Tu paquete de acceso no tiene acceso a ninguna Sala de Exhibición',
        button: 'OK',
    },
    auditorium: {
        willStart: 'La siguiente sesión comenzará en…',
        noAccess: 'Tu paquete  no cuenta con acceso a este auditorio',
        noVideos: 'No hay ninguna sesión programada en este auditorio por el momento',
        stageTab: 'ESCENARIO',
        videoWallButton: 'Muro de video',
        resourcesButton: 'RECURSOS',
        slotEndsTextFirst: 'Esta sesión termina en',
        slotEndsTextSecondUpdated: 'La presentación estará disponible a demanda.',
        slotTitleWith: 'con',
        allAuditoriums: 'Todos los auditorios',
        onDemandButton: 'A DEMANDA',
        hide: 'Esconder  ',
        leavePopupTitle: '¿Salir del escenario?',
        leavePopupDescription: 'Al salir del auditorio, serás eliminado de la sesión',
        leaveSession: 'Salir del escenario',
        cancelDescription: 'Al salir, ya no serás un orador. ¿Deseas proceder?',
        settings: 'Configuraciones',
        minimize: 'Minimizar',
        microphone: 'Micrófono',
        camera: 'Cámara',
        auditoriums: 'Auditorios',
    },
    auditoriumArchive: {
        title: 'Memoria de Archivos del Auditorio',
        titleEvent: 'Memoria de Archivos del Evento',
        with: 'con',
        noVideosAvailable: 'Todas las sesiones terminaron',
    },
    videoPlayer: {
        videoNotPlayingError: '¿Estás experimentando problemas con el video? Por favor refresca la página',
        videoEndedTextFirst: 'El video ha concluido; ¡Muchas gracias por participar!',
        videoEndedTextSecond: 'El muro del video está abierto para Preguntas y Respuestas',
        tooltipPlay: 'Iniciar',
        tooltipPause: 'Pausa',
        tooltipMinimize: 'Minimizar',
        tooltipFullscreen: 'Pantalla completa',
        tooltipMute: 'Silenciar',
        tooltipUnmute: 'Quitar silencio',
        videoStreamNotStarted: 'Pronto salimos en vivo. ¡Permanezca atento!',
    },
    showfloor: {
        allExhibitorsButton: 'TODOS LOS EXPOSITORES',
        featuredExhibitorsText: 'EXPOSITORES',
        boothLinksDialogTitle: 'ENLACES DEL STAND',
        boothFilesDialogTitle: 'ARCHIVOS DEL STAND VIRTUAL',
        boothVideoDialogTitle: 'VIDEO',
        mobileTabChat: 'Chat',
        mobileTabPoster: 'COLABORADOR  ',
        mobileTabFiles: 'Archivos',
        mobileTabLinks: 'Enlaces',
        mobileTabVideo: 'VIDEO',
        noExhibitorsTextFirst: 'LOS EXPOSITORES SE ENCUENTRAN TRABAJANDO EN SUS STANDS VIRTUALES',
        noPresentersTextFirst: 'Los presentadores se encuentra trabajando en sus stands',
        noExhibitorsTextSecond: 'PERMANECE PENDIENTE',
        showShowfloorsButton: 'TODOS LOS SALONES DE EXHIBICIÓN',
        showShowfloorsPosterButton: 'TODOS LOS COLABORADORES/ ESTUDIOS',
        showShowfloorsExhibitorButton: 'TODOS LOS EXPOSITORES',
        searchExhibitors: 'Buscar expositores',
        searchPosters: 'Buscar por título o nombre del autor',
        searchExhibitorsMultiple: 'Buscar por expositor en esta sala de exhibición',
        searchPostersMultiple: 'Buscar por título o nombre del autor en esta salá de exhibición',
        showfloorsTitle: 'Salones de exhibición',
        collapseList: 'Colapsar la lista',
        seeFullList: 'Mira la lista completa',
        emptyExhibitorsResults: 'El stand buscado no se encuentra presente. Pero, los invitaremos la próxima vez.',
        emptyPostersResults: 'El colaborador/ estudio buscado no está presente. Pero, lo invitaremos la próxima vez.',
        posters: 'colaboradores',
        allPostersStudiesTitle: 'Todos los colaboradores/estudios',
        authors: 'autores',
        titleNotAvailable: 'Título no disponible',
        abstractNotAvailable: 'Resumen no disponible',
        authorsNotAvailable: 'Autores no disponibles',
        openPosterFile: 'Abrir el archivo del colaborador',
        fileIsNotAvailable: 'El archivo no está disponible',
        posterBooth: {
            study: 'Colaborador/Estudio',
            authors: 'Autores',
            highlights: 'Resumen  ',
            scrollInfoText: 'DESLIZA HACIA ABAJO PARA LEER EL RESUMEN COMPLETO',
        },
    },
    eventArchive: {
        title: 'Memoria de Archivos de Video',
        searchText: 'Da click aquí para buscar',
        noArchivedEvents: 'No hay videos en el archivo por el momento',
    },
    exhibitorDashboard: {
        event: 'Evento',
        title: 'Configura tu stand virtual',
        visitorsButton: 'Visitantes del stand virtual',
        goToBoothPreview: 'Vista previa del stand',
        previewButton: 'Vista previa  ',
        tabStyle: 'Estilo del stand virtual',
        tabLogo: 'Logotipo',
        tabStudyDetails: 'Colaborador / Estudio',
        tabFiles: 'Archivos',
        tabStudyFile: 'Archivo',
        tabLinks: 'Enlaces',
        tabAvatars: 'Avatares',
        tabStudyAuthors: 'Autores',
        tabVideo: 'Video',
        shareText: 'COPIAR URL',
        theBoothUrlCopiedText: 'La URL del stand se copió con éxito en su portapapeles',
        optionDisabledText: 'Esta opción no está disponible actualmente para este stand',
        boothStyleTab: {
            optionOne: 'Opción 1 - Escandinavo',
            optionTwo: 'Opción 2 - Moderno',
            optionThree: 'Opción 3 - Dinámico',
            optionFour: 'Opción 4 - Personalizado',
            optionScholarOne: 'Opción 1 - Einstein',
            optionScholarTwo: 'Opción 2 - Curie',
            optionScholarThree: 'Opción 3 - da Vinci',
        },
        logoTab: {
            fileUploadButton: 'Haga clic aquí para cargar',
            noFile: 'No se ha elegido ningún archivo',
        },
        posterStudyTab: {
            posterStudyTitle: 'Título  ',
            studyTitleInputLabel: 'Título del colaborador/estudio',
            highlightAbstractTitle: 'Destacado/Resumen',
            studyHighlightsInputLabel: 'Lo más destacado/ resumen de la investigación',
            highlightAbstractError: '¡Haz llegado al límite máximo de caracteres permitidos! (500 caracteres)',
            authorNameLabel: 'Nombre del autor',
            authorAffiliationLabel: 'Afiliación',
        },
        filesTab: {
            title: 'Documentos:',
            fileNameInput: 'Nombre del documento (visible para todos los participantes)',
            chooseFileButton: 'Elige el archivo',
            noDocAdded: 'No se han agregado documentos',
            fileError: 'Por favor, agrega un nombre de documento',
        },
        fileStudyTab: {
            title: 'Sube el archivo pdf',
            description:
                'Sube el cartel: el papel o la presentación que estará disponible en tu stand (tamaño límite del archivo 20 Mb.)',
            uploadPdfInputLabel: 'Sube el archivo PDF',
            uploadNewPdfInputLabel: 'Sube el nuevo archivo PDF',
            uploadedFile: 'Subido',
            dragContainerTitle: 'Simplemente arrastra el archivo hasta aquí',
            dragContainerNewTitle: 'Simplemente arrastra el nuevo archivo hasta aquí',
        },
        linksTab: {
            social: {
                title: 'Enlaces a redes sociales',
                subtitle: '(visible para todos los participantes)',
                facebookInput: 'Inserta tu enlace de Facebook',
                linkedinInput: 'Inserta tu enlace LinkedIn',
                twitterInput: 'Inserta tu enlace de Twitter',
                googleScholarInput: 'Inserta tu enlace de Google Scholar',
                researchGateInput: 'Inserta tu enlace de Research Gate',
            },
            other: {
                linkInput: 'Insertar un nuevo enlace',
                linkLabel: 'Insertar una nueva etiqueta de enlace',
            },
        },
        linksStudyTab: {
            authorProfiles: 'Perfiles de autor',
            additionalLinks: 'Enlaces adicionales',
        },
        avatarsTab: {
            mainRepresentatives: {
                title: 'Representantes del stand virtual (Usuario Expositor)',
                leftRepresentative: 'Usuario Expositor de la izquierda',
                rightRepresentative: 'Usuario Expositor de la derecha',
                addDialog: {
                    title: 'Agregar Avatar',
                    titleScholar: 'Agrega autor',
                    editAvatarsTitle: 'Edita Avatar',
                    editAuthorTitle: 'Edita autor',
                    contentLine1: 'Elige un avatar de la galeria a continuación',
                    contentLine2: 'Sube tu propio avatar personalizado',
                    contentLine3: '¿Necesitas ayuda? Contactácte con ',
                    assignButtonText: 'Asignar',
                    assignText:
                        'Por favor, asigna un participante del evento para que se asocie con el Usuario Expositor.',
                    assignErrorBooth: 'El usuario ha sido agregado como representante de stand',
                    assignErrorAdditional: 'El usuario ha sido agregado como representante adicional',
                    input: 'Busca por nombre aquí',
                },
            },
            additionalRepresentatives: {
                title: 'Usuarios Expositores adicionales',
                subtitle: '(opcional, será mostrado con su fotografía de perfil)',
                addDialog: {
                    title: 'Elegir un Usuario Expositor adicional',
                    titleScholar: 'Elegir coautor',
                    assignText: 'Por favor busca a los participantes para agregarlos como representantes',
                    assignError: 'El usuario ha sido agregado como Usuario Expositor',
                },
            },
            author: {
                title: 'Autor presentador',
                subtitle: 'Agrega solo un autor que se registró al evento. Puedes agregar un autor.',
                mainRepresentative: 'Representate principal',
            },
            coAuthors: {
                title: 'Coautores',
                singularTitle: 'Coautores',
                subtitle: 'Agrega solo coautores que se registraron al evento. Puedes agregar hasta cuatro coautores',
            },
        },
        videoTab: {
            video: {
                title: 'Video de stand',
                subtitle: '(Solamente enlace de video de YouTube)',
                youtubeOrVimeoOnly: '(Solamente enlace de video de YouTube o Vimeo)',
                input: 'Introduce la URL de YouTube',
                inputYoutubeOrVimeo: 'Introduce la URL de YouTube o Vimeo',
            },
            image: {
                subtitle:
                    '(La imagen se exhibe en su Stand en caso que hayas agregado un enlace de video en la sección de arriba)',
            },
        },
    },
    boothDashboard: {
        comments: 'Comentarios',
        currentOnlineVisitors: 'Visitantes en línea actuales',
        dataType: 'Tipo de datos',
        event: 'Evento',
        likes: 'Me gusta',
        manage: 'Administrar',
        posts: 'Publicaciones',
        showUsers: 'Mostrar usuarios',
        statistics: 'Estadísticas',
        title: 'Título',
        topPostComments: 'Comentarios principales de publicaciones',
        topPostLikes: 'Top Post Likes',
        totalCommentsCount: 'Recuento total de comentarios',
        totalDocumentClicks: 'Total de clics en documentos',
        totalLikesCount: 'Recuento total de Me gusta',
        totalLinkClicks: 'Total de clics en enlaces',
        totalNumberOfVisits: 'Número total de visitas',
        totalPostsCount: 'Recuento total de publicaciones',
        totalRepresentativesClicks: 'Total de clics de representantes',
        totalVideoViews: 'Reproducciones totales de video',
        totalVotes: 'Total de votos',
        uniqueVisitors: 'Visitantes Únicos',
        value: 'Valor',
    },
    polls: {
        polls: 'ENCUESTAS',
        noPolls: 'Todavía no se han agregado encuestas.',
        pollProgress: 'Encuesta en progreso',
        pollClosed: 'Encuesta cerrada',
        pollVoted: 'los participantes votaron',
    },
    onDemandVideos: {
        sectionTitle: 'A DEMANDA',
        noOnDemandVideos: 'Todavía no se han agregado videos a demanda',
    },
    meetings: {
        meeting: 'REUNIÓN  ',
        createDialog: {
            createTitle: 'Solicitar reunión con',
            inputDate: 'Fecha',
            inputStart: 'Comienza',
            inputEnd: 'Termina ',
            inputTimezone: 'Zona horaria',
            inputNote: 'Nota: solo se puede agendar una reunión a la vez con la misma persona.',
            meetingError: 'Fecha u hora de la reunión inválida. Por favor, ¡vuelve a revisar!',
            buttonDisabled: '¡Puedes agendar una reunión luego de comenzar tu conversación!',
        },
        banner: {
            pending: 'Solicitud de reunión',
            accepted: 'Reunión agendada para',
            progress: 'Reunión en progreso',
            cancelTitle: '¿Cancelar la reunión?',
            cancelDescription: '¿Estás seguro que quieres cancelar esta reunión?',
            startCall: 'COMENZAR LA LLAMADA',
            joinCall: 'UNIRSE',
            pendingOwner: 'Solicitud de reunión pendiente para',
            videoCallStarted: 'Reunión por video comenzada',
            videoCallStartedInfo: 'La reuniones por video funcionan mejor hasta con diez personas',
        },
        title: 'Tus reuniones',
        meetingsInfo:
            'Las reuniones se pueden programar a través de la función de chat. Inicia un chat con el usuario con el que desea programar una reunión.',
    },
    socialMediaShare: {
        event: 'evento',
        shareLabel: 'Compartir',
        dialogTitleGeneral: 'Compartir con tu red',
        dialogTitlePost: 'Compartir tu posteo',
        linkedinLabel: 'LinkedIn',
        facebookLabel: 'Facebook',
        twitterLabel: 'Twitter',
        emailLabel: 'Correo electrónico',
        clipboardLabel: 'Portapapeles',
        clipboardSnackbarMessage: 'Enlace copiado en el portapapeles',
        linkedinButtonTitle: 'Compartir en LinkedIn',
        facebookButtonTitle: 'Compartir en Facebook',
        twitterButtonTitle: 'Compartir en Twitter',
        emailButtonTitle: 'Compartir en correo electrónico',
        clipboardButtonTitle: 'Compartir en portapapeles',
        imAttending: 'Voy a asistir a la',
        interestedClick: '¿Estás interesado en asistir? Haz clic aquí:',
    },
    videoConferences: {
        fullScreen: 'ABRIR PANTALLA COMPLETA',
        minimizeScreen: 'MINIMIZAR PANTALLA',
        startedVideoChat: 'comenzó una video llamada',
        timeAgo: 'atrás',
        videoCallStarted: 'Video llamada comenzada…',
    },
    sessions: {
        edit: 'EDITAR',
        speakers: 'ORADORES',
        speakerSingular: 'orador ',
        moderators: 'MODERADORES',
        moderatorSingular: 'moderador ',
        video: 'VIDEO',
        startStream: 'INICIAR LA TRANSMISIÓN',
        stopStream: 'FINALIZAR LA TRANSMISIÓN',
        sessionChat: 'SESIÓN DE CHAT',
        speakerUrl: 'URL del orador',
        moderatorUrl: 'URL del moderador',
        copyUrl: 'Copiar la URL',
        noSpeakersJoined: 'Todavía no se ha unido ningún orador',
        allFilter: 'todo',
        waitingRoom: 'Sala de espera',
        isReady: 'Está Listo',
        invitationAccepted: 'Aceptó la invitación',
        requestPending: 'Solicitud pendiente',
        gettingReady: 'Preparándose',
        inviteToSpeak: 'Invitar a hablar',
        fromAuditorium: 'desde el Auditorio',
        mic: 'Micrófono',
        screen: 'Pantalla  ',
        rec: 'Grabar',
        sessionHasEnded: 'La sesión ha finalizado',
        sessionLinks: 'ENLANCES DE SESIÓN',
        sessionSettings: 'Configuraciones',
        noAvailableChat:
            'No hay chats disponibles. Para ver el chat por favor ubica esta sesión en una franja horaria.',
        startLiveSessionStreamTitle: 'Comenzar una sesión de transmisión en vivo',
        startLiveSessionDescription:
            'La grabación de la sesión comenzará automáticamente cuando comiences la transmisión',
        stopLiveSessionStreamTitle: 'Finalizar la sesión de la transmisión en vivo',
        stopLiveSessionStreamDescription:
            'Al detener la sesión de la transmisión en vivo, la grabación también se detendrá automáticamente. Esta sesión de transmisión en vivo no puede volver a comenzar.',
        thumbnailImage: 'Imagen en miniatura',
        uploadThumbnail: 'Subir miniatura',
        clickToUpload: 'Hacer clic aquí para subir',
        requestMic: 'SOLICITAR UN MICRÓFONO',
        acceptInvitationTitle: 'Tienes la invitación',
        acceptInvitationSubTitle: 'El moderador te ha invitado a ser orador. ¿Qué respondes?',
        acceptInvitationButton: 'Aceptar la invitación',
        requestPendingTitle: 'La solicitud se encuentra pendiente…',
        requestPendingSubTitle: 'Por favor aguarda hasta que el moderador te permita entrar en la transmisión',
        cancelRequest: 'Cancelar la solicitud',
        approvedTitle: 'Por favor chequea tu micrófono y video y haz clic en Estoy listo.',
        approvedSubTitle:
            'Esta sesión está siendo grabada. Al entrar en esta sesión estás de acuerdo con el procesamiento de tus datos personales',
        connectedToTheBackstage: 'Ahora estás haciendo transmisión en vivo y estás conectado a los bastidores.',
        removedFromTheSession: 'Haz sido eliminado de la sesión, por favor ponte en contacto con el moderador',
        imReady: 'Estoy listo',
        isTryingToRegister: 'está tratando de registrarse como un',
        hasLeftTheSession: 'ha dejado la sesión',
        stopVideo: 'Detener el video',
        startVideo: 'Comenzar el video',
        disabledScreenSharing: 'Apagar la pantalla compartida antes de comenzar tu video',
        disabledByModerator: 'Deshabilitado por el moderador',
        disabledUntilReady: 'Deshabilitado hasta estar listo',
        stopShareScreen: 'Detener la pantalla compartida',
        shareScreen: 'Compartir la pantalla',
        disabledVideoSharing: 'Apaga tu video antes de compartir tu pantalla',
        anotherUserIsScreenSharing: 'Alguien más ya está compartiendo pantalla',
        removeRoleDescription: '¿Estás seguro que quieres eliminar?',
        declinedInvitation: 'declinar la invitación para ser orador',
    },
    gdpr: {
        disclaimerTextFirst:
            'Este documento ha sido traducido por inteligencia artificial. En caso de inconsistencias, usa como referencia la versión en inglés del documento',
        disclaimerTextSecond: 'AQUÍ',
        metaTitlePrivacyPolicy: 'Política de privacidad',
        metaTitleCookiesPolicy: 'política de cookies',
        metaTitleTerms: 'Términos y condiciones',
        updateCookieConsent: 'Actualizar el consentimiento de las cookies',
        changeYourConsent: 'Cambiar tu consentimiento',
        withdrawYourConsent: 'Retirar tu consentimiento',
        withdrawSnackbarText: 'El consentimiento de las cookies ha sido retirado exitosamente',
    },
    infoMessages: {
        imageExplain420x200:
            'Relación de imagen recomendada 1,8:1 (orientada al paisaje, por ejemplo, 420 x 200 píxeles)',
        imageExplainTB230x320:
            'Relación de imagen recomendada 1:1,4 (orientada al retrato, por ejemplo, 230 x 320 píxeles) con fondo transparente',
        imageExplainTB230x875:
            'Relación de imagen recomendada 1:3,8 (orientada al retrato, por ejemplo, 230 x 875 píxeles) con fondo transparente',
    },
    matching: {
        matchingQuestionnaireTitle: 'Cuestionario de emparejamiento',
        matchingQuestionnaireIntro:
            '¡Ayúdanos a conectarte con las personas más relevantes para ti! Cosas maravillosas ocurren cuando las mentes correctas se encuentran. Completa el cuestionario para comenzar.',
        matchingQuestionnaireIntroSkip:
            'Si te salteas el cuestionario no seremos capaces de emparejarte con el resto de los asistentes en el evento de hoy. Siempre puedes completar el cuestionario más tarde en la sección de Networking. Todas las respuestan se editan desde Mi Cuenta.',
        doItLaterBtn: 'HACERLO MÁS TARDE',
        nextBtn: 'Siguiente',
        backBtn: 'Atrás',
        finishBtn: 'Finalizar',
        seeMatchesBtn: 'VER TODAS LAS CORRESPONDENCIAS',
        tryMatchingBtn: 'PROBAR EL EMPAREJAMIENTO INTELIGENTE',
        optionalQuestion: 'Opcional',
        requiredQuestion: 'Requerido',
        singleSelect: 'Selección única',
        multiSelect: 'Multiselección',
        mediumMatch: 'Emparejamiento medio',
        highMatch: 'Emparejamiento alto',
        matchLabel: 'Emparejar',
        matchesLabel: 'Emparejamientos',
        infoTooltipJump: 'Salte aquí para ver sus correspondencias',
        infoTooltipClickToSee1: 'Clic en',
        infoTooltipClickToSee2: 'para ver con quién debería conectar',
        loadingAlgoText:
            'Algoritmo de emparejamiento en acción. ¡Aguarda un minuto mientras curamos tus mejores coincidencias!',
        matchingDetailsFormTitle: 'Detalles de emparejamiento inteligente',
        tryMatchingBanner: 'Haz conexiones significativas, ¡prueba nuestra función de emparejamiento inteligente!',
        matchingBannerClosed:
            'Siempre puedes completar el formulario de emparejamiento inteligente en Networking bajo Emparejamientos',
        resultsTitle: 'Aquí están tus mejores emparejamientos',
        resultsNoteLabel: 'NOTA:  ',
        resultsNoteText: 'Puedes encontrar todos tus emparejamientos en la sección de Networking.',
        resultsNoteMyAccount: 'Todas las respuestas se pueden editar en el menu Mi Cuenta.',
        btwResultsResponsive: 'Por cierto, puedes encontrar todos tus emparejamientos en NETWORKING',
        noMoreMatches:
            '¡Oh no! No podemos encontrar emparejamientos para ti en este momento. Vuelve a revisar otra vez cuando más participantes se hayan unido',
        noResultsTitle: '¡Felicitaciones, lo has logrado! ¡Estamos buscando los mejores emparejamientos!',
        noResults:
            'Gracias por probar la función de Emparejamiento Inteligente! Estamos buscando los mejores emparejamientos y te emparejaremos tan pronto como más participantes se unan al evento. Por favor, revisa la pestaña de "Emparejamientos" en la sección de "Networking"',
        clickStartChat: 'Haz clic en chat para comenzar la conversación',
        closeMatchingTitle: '¿Cerrar el formulario de emparejamiento?',
        closeMatchingTextA:
            'Si cierra el formulario antes de completarlo, no guardaremos ningún detalle y los resultados del Emparejamiento Inteligente no estarán disponibles para ti.',
        closeMatchingTextB:
            'Tu perfil no será parte del proceso de Emparejamiento Inteligente y no se emparejará a nadie contigo.',
        matchingTooltip: '¡Haz clic en emparejamientos para ver con quién deberías conectar!',
        myAccountMatchingSectionTitle: 'Detalles del emparejamiento inteligente',
        matchingRequiredError: 'Haz completado todas las preguntas solicitadas',
        rejectMatchingTitle: '¿Rechazas la participación de emparejamiento?',
        rejectMatchingText:
            'Tus respuestas serán borradas y no te emparejaremos con otros usuarios. Puedes retomar el cuestionario de emparejamiento en cualquier momento.',
        rejectMatchingBtn: 'RECHAZAR PARTICIPACIÓN',
    },
    welcomeScreen: {
        goToEvent: 'Ir a evento',
    },
    emailCodeVerification: {
        codeSent: 'Hemos enviado un código de verificación a tu correo electrónico:',
        continueRegistration: 'Para continuar con el registro,',
        enterCodeBelow: 'introduce el código de verificación a continuación:',
        codeWillExpire: 'Este código expira en',
        codeExpired: 'El código ha expirado',
        codeNotReceived: '¿No haz recibido un código? ¿Has revisado la carpeta de Spam?',
        resendTimer: 'Reenviar en:',
        resendLabel: 'Reenviar  ',
        resendCodeLabel: 'Reenviar el código',
        codeError: 'El código ingresado es incorrecto',
        codeErrorFix: 'Reingresa el código correcto de tu correo electrónico o reenvía un nuevo código',
        congrats: '¡Felicitaciones!',
        emailConfirmed: 'Tu correo electronico confirmado:',
    },
    privacyPolicyUpdates: {
        learnMore: 'Learn more', // new translation
        lastUpdated: 'Last updated', // new translation
        publishedBy: 'Published by', // new translation
        ppuLinkLabel: 'Updated privacy policy', // new translation
        ppuEventLinkLabel: 'Updated event privacy policy', // new translation
        acceptAllButtonLabel: 'Accept all updates', // new translation
    },
    voting: {
        votingText: 'VOTACIÓN',
        enableVoting: 'HABILITAR VOTACIÓN',
        enableVotingDescription: 'Haga clic en el icono Actualizar para obtener los resultados más precisos',
        displayVotingNumbersForExhibitors: 'MOSTRAR NÚMEROS DE VOTACIÓN PARA EXPOSITORES Y CARTEL/ESTUDIO',
        displayVotingNumbersForExhibitorsDescription: 'Se mostrará en el panel de control del Expositor',
        displayVotingNumbersForParticipants: 'MOSTRAR NÚMEROS DE VOTACIÓN PARA PARTICIPANTES',
        displayVotingNumbersForParticipantsDescription: 'Se mostrará para todos los Participantes',
        characterError80: 'Ha alcanzado el límite máximo de caracteres permitidos (80 caracteres)',
        characterDescription:
            'El texto está limitado a 80 símbolos. El texto aparecerá como título principal en la cinta de votación. Hágalo significativo para los participantes',
        deleteAllVotes: 'BORRAR TODOS LOS VOTOS',
        downloadVotingResults: 'DESCARGAR RESULTADOS DE LA VOTACIÓN',
        voteText: 'Votar',
        votedText: 'votado',
        votesText: 'Votos',
        participant: 'partícipe',
        participants: 'Participantes',
        deleteVotesTextFirst: 'ADVERTENCIA:',
        deleteVotesTextSecond: 'esta es una acción irreversible.',
        deleteVotesTextThird: 'No olvides descargar los resultados de la votación antes de eliminarlos.',
        checkTheBoxToCastYourVote: 'Marca la casilla para emitir tu voto',
        toVotePleaseEnableParticipantProfile: 'Para votar, habilite su perfil de participante',
    },
};

export default es;
