import Layout from '../../components/Layout';
import PageHeadline from '../../components/PageHeadline';
import SocialMedias from '../../components/SocialMedias';
import LoginForm from '../../components/Forms/LoginForm';
import React, {useState} from 'react';
import {loginUserErrorReset} from 'store/actions/userActions';
import {useDispatch, useSelector} from 'react-redux';
import ForgotPassword from '../../components/Forms/ForgotPassword';
const LoginPlatform = () => {
    const dispatch = useDispatch();

    //redux
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);

    //states
    const [showRecoverPasswordDialog, setShowRecoverPasswordDialog] = useState(false);
    const [passwordSent, setPasswordSent] = useState({success: false, email: ''});
    const [userExistButNotLoggedIn, setUserExistButNotLoggedIn] = useState(false);

    const loginScreenStepsTitles = [
        {
            heading:
                translation.newLoginRegistrations.headingNoUser ||
                defaultTranslation.newLoginRegistrations.headingNoUser,
            subHeading:
                translation.newLoginRegistrations.subHeadingNoUser ||
                defaultTranslation.newLoginRegistrations.subHeadingNoUser,
        },
        {
            heading:
                translation.newLoginRegistrations.headingUser || defaultTranslation.newLoginRegistrations.headingUser,
            subHeading:
                translation.newLoginRegistrations.subHeadingUser ||
                defaultTranslation.newLoginRegistrations.subHeadingUser,
        },
    ];
    const forgotPasswordTitlesPlatform = [
        {
            heading:
                translation.newLoginRegistrations.forgotPasswordHeadline ||
                defaultTranslation.newLoginRegistrations.forgotPasswordHeadline,
            subHeading:
                translation.newLoginRegistrations.forgotPasswordText ||
                defaultTranslation.newLoginRegistrations.forgotPasswordText,
        },
        {
            heading:
                translation.newLoginRegistrations.checkYourEmail ||
                defaultTranslation.newLoginRegistrations.checkYourEmail,
            subHeading:
                translation.newLoginRegistrations.checkYourEmailText + ' ' + passwordSent.email ||
                defaultTranslation.newLoginRegistrations.checkYourEmailText + ' ' + passwordSent.email,
        },
    ];

    const isEmailExist = JSON.parse(localStorage.getItem('isEmailExist'));

    let pageTitle = showRecoverPasswordDialog
        ? forgotPasswordTitlesPlatform[passwordSent.success ? 1 : 0]
        : loginScreenStepsTitles[isEmailExist ? 1 : userExistButNotLoggedIn ? 1 : 0];

    const handleDialogToggle = () => {
        localStorage.removeItem('comeFrom');
        setShowRecoverPasswordDialog((prevState) => !prevState);
    };

    const handlePasswordSent = (success, email) => {
        setPasswordSent({success: success, email: email});
    };

    const handleIfUserExistButNotLoggedIn = () => setUserExistButNotLoggedIn(true);
    const handleResetUserExistButNotLoggedIn = () => {
        dispatch(loginUserErrorReset());
        setUserExistButNotLoggedIn(false);
    };

    return (
        <Layout
            childrenWidth={`392px`}
            translation={translation}
            isEventLogin={false}
            defaultTranslation={defaultTranslation}
            userExistButNotLoggedIn={userExistButNotLoggedIn}
            privateFnLogin={handleResetUserExistButNotLoggedIn}
            showRecoverPasswordDialog={showRecoverPasswordDialog}
        >
            <PageHeadline
                heading={pageTitle.heading}
                subHeading={pageTitle.subHeading}
                passwordSent={passwordSent.success}
            />
            {!showRecoverPasswordDialog && (
                <>
                    <SocialMedias />
                    <div className={`or-separator`}>
                        <span className={`left-line`}></span>
                        <span className={`or-text`}>
                            {translation.generalText.or || defaultTranslation.generalText.or}
                        </span>
                        <span className={`right-line`}></span>
                    </div>
                </>
            )}
            {showRecoverPasswordDialog ? (
                <ForgotPassword
                    handleDialogToggle={handleDialogToggle}
                    translation={translation}
                    defaultTranslation={defaultTranslation}
                    handlePasswordSent={handlePasswordSent}
                    passwordSent={passwordSent}
                />
            ) : (
                <LoginForm
                    userExistButNotLoggedIn={userExistButNotLoggedIn}
                    handleDialogToggle={handleDialogToggle}
                    handleIfUserExistButNotLoggedIn={handleIfUserExistButNotLoggedIn}
                />
            )}
        </Layout>
    );
};

export default LoginPlatform;
