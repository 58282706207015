import React from 'react';
import ColoredScrollbars from '../../SmallLayoutComponents/ColoredScrollbars';
import SingleUserContact from '../../HOC/SingleUserContact';
import CloseTopNavButton from '../../SmallLayoutComponents/CloseTopNavButton';
import '../../CSS/contacts.scss';
import {connect} from 'react-redux';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as ContactsGreenIcon} from '../../Images/svg/phone-book.svg';
import {preventDefaultDrag} from '../../Utils/utils';
import isEqual from 'lodash/isEqual';

class Contacts extends React.Component {
    constructor(props) {
        super(props);

        const {userReceivedRequests} = this.props;
        let pendingRequestsArray = userReceivedRequests.filter((request) => request.status === 'pending');

        this.state = {
            pendingRequests: pendingRequestsArray,
            pendingRequestsLength: pendingRequestsArray?.length,
        };
    }

    componentDidMount() {
        const {mobile} = this.props;
        if (mobile) {
            if (document.querySelector('.received-requests-wrapper')) {
                const heightReceivedRequests = document.querySelector('.received-requests-wrapper').clientHeight;
                if (document.querySelector('.already-contacts-wrapper')) {
                    document
                        .querySelector('.already-contacts-wrapper')
                        .setAttribute('style', `height: calc(100vh - 160px - ${heightReceivedRequests}px) !important`);
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {mobile} = this.props;

        if (!isEqual(prevProps?.userReceivedRequests, this.props?.userReceivedRequests)) {
            const {userReceivedRequests} = this.props;
            let pendingRequestsArray = userReceivedRequests.filter((request) => request.status === 'pending');
            this.setState({
                pendingRequests: pendingRequestsArray,
                pendingRequestsLength: pendingRequestsArray?.length,
            });
        }
        if (mobile) {
            if (document.querySelector('.received-requests-wrapper')) {
                const heightReceivedRequests = document.querySelector('.received-requests-wrapper').clientHeight;
                if (document.querySelector('.already-contacts-wrapper')) {
                    document
                        .querySelector('.already-contacts-wrapper')
                        .setAttribute('style', `height: calc(100vh - 160px - ${heightReceivedRequests}px) !important`);
                }
            } else {
                if (document.querySelector('.already-contacts-wrapper')) {
                    document
                        .querySelector('.already-contacts-wrapper')
                        .setAttribute('style', `height: calc(100vh - 160px) !important`);
                }
            }
        }
    }

    checkIfIsOnline = (userId) => {
        return this.props.onlineUsersObject[userId];
    };

    render() {
        const {pendingRequests, pendingRequestsLength} = this.state;
        const {mobile, userContacts, translation} = this.props;
        let orderedContacts = [];
        if (userContacts) {
            // order the contacts alphabetically
            orderedContacts = [...userContacts];
            orderedContacts.sort(function (x, y) {
                if (x.user?.first === y.user?.first) {
                    return x.user?.last < y.user?.last ? -1 : 1;
                }
                return x.user?.first < y.user?.first ? -1 : 1;
            });
        }

        return (
            <div
                onDragStart={preventDefaultDrag}
                className={`contacts-page dropdown-component ${mobile ? 'contacts-mobile' : ''}`}
            >
                <CloseTopNavButton />
                <div onDragStart={preventDefaultDrag} className="page-title">
                    <h3>{translation?.contactsDropdown.title}</h3>
                </div>

                {pendingRequestsLength + orderedContacts.length > 0 ? (
                    <div>
                        <div onDragStart={preventDefaultDrag} className="custom-scroll-container networking-wrapper">
                            {pendingRequestsLength > 0 ? (
                                <h2 className="received-requests-title">
                                    {translation?.contactsDropdown.requestsTitle}
                                </h2>
                            ) : null}

                            {pendingRequestsLength > 0 ? (
                                <div
                                    className="received-requests-wrapper"
                                    data-height={pendingRequestsLength > 3 ? 'true' : 'false'}
                                >
                                    {pendingRequestsLength > 3 ? (
                                        <ColoredScrollbars>
                                            {(pendingRequests || []).map((request) => {
                                                if(!request.user?._id) return null
                                                const isOnline = this.checkIfIsOnline(request.user?._id);
                                                return (
                                                    <div
                                                        onDragStart={preventDefaultDrag}
                                                        className="received-request-container"
                                                        key={request._id}
                                                    >
                                                        <SingleUserContact
                                                            key={request._id}
                                                            id={request._id}
                                                            user={request.user}
                                                            friendConnectionReceived={true}
                                                            requestId={request._id}
                                                            isOnline={isOnline}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </ColoredScrollbars>
                                    ) : (
                                        <div>
                                            {(pendingRequests || []).map((request) => {
                                                if(!request.user?._id) return null
                                                const isOnline = this.checkIfIsOnline(request.user?._id);
                                                return (
                                                    <div
                                                        onDragStart={preventDefaultDrag}
                                                        className="received-request-container"
                                                        key={request._id}
                                                    >
                                                        <SingleUserContact
                                                            key={request._id}
                                                            id={request._id}
                                                            user={request.user}
                                                            friendConnectionReceived={true}
                                                            requestId={request._id}
                                                            isOnline={isOnline}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            ) : null}

                            {orderedContacts.length > 6 ? (
                                <div onDragStart={preventDefaultDrag} className="already-contacts-wrapper">
                                    <ColoredScrollbars>
                                        {orderedContacts.map((contact) => {
                                            if(!contact.user?._id) return null
                                            const isOnline = this.checkIfIsOnline(contact.user?._id);
                                            return (
                                                <SingleUserContact
                                                    key={contact._id}
                                                    id={contact._id}
                                                    user={contact.user}
                                                    isContact={true}
                                                    isOnline={isOnline}
                                                />
                                            );
                                        })}
                                    </ColoredScrollbars>
                                </div>
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="contacts-fixed-members">
                                    {orderedContacts.map((contact) => {
                                        if(!contact.user?._id) return null
                                        const isOnline = this.checkIfIsOnline(contact.user._id);
                                        return (
                                            <SingleUserContact
                                                key={contact._id}
                                                id={contact._id}
                                                user={contact.user}
                                                isContact={true}
                                                isOnline={isOnline}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div onDragStart={preventDefaultDrag} className="empty-contacts">
                        <p>{translation?.contactsDropdown.noContacts}</p>
                        <ContactsGreenIcon stroke={colors.primary} width="48" height="48" alt="Contacts" />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userContacts: state.user.data?.contacts,
        userReceivedRequests: state.user.data?.receivedRequests,
        onlineUsersObject: state.onlineUsers.onlineUsersObject,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(Contacts);
