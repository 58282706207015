import axios from '../axios-instance';
import * as actionTypes from './actionTypes';

const genericErrorText = 'There seems to be an error. Please try again!';

// dashboard
export const getAnnouncements = (eventId) => async (dispatch) => {
    dispatch({type: actionTypes.GET_ANNOUNCEMENTS_START});

    try{
        const response = await axios.get(`/event/${eventId}/announcements`);
        dispatch(getAnnouncementsSuccess(response.data.data));
    } catch (error) {
        const errorMessage = error.response.data.error;
        if(errorMessage === 'announcements are disabled for this event.'){
            dispatch({type: actionTypes.ANNOUNCEMENTS_DISABLED});
            return;
        }
        dispatch({type: actionTypes.GET_ANNOUNCEMENTS_FAIL, error: genericErrorText});
    }
};

export const getAnnouncementsSuccess = (data) => {
    return {
        type: actionTypes.GET_ANNOUNCEMENTS_SUCCESS,
        payload: data,
    };
}

export const getRunningAnnouncements = (eventId) => async (dispatch) => {
    dispatch({type: actionTypes.GET_RUNNING_ANNOUNCEMENTS_START});
    try {
        const response = await axios.get(`/event/${eventId}/running-announcements`);
        dispatch(getRunningAnnouncementsSuccess(response.data.data));
    } catch (error) {
        dispatch({type: actionTypes.GET_ANNOUNCEMENTS_FAIL, error: genericErrorText})
    }
}

export const getRunningAnnouncementsSuccess = (data) => {
    return {
        type: actionTypes.GET_RUNNING_ANNOUNCEMENTS_SUCCESS,
        payload: data,
    };
}

export const addAnnouncement = (data, eventId) => async (dispatch) => {
    dispatch({type: actionTypes.ADD_ANNOUNCEMENT_START});
    try {
        const response = await axios.post(`/event/${eventId}/announcement`, data);
        dispatch({type: actionTypes.ADD_ANNOUNCEMENT_SUCCESS, payload: response.data});
    } catch (error) {
        dispatch({type: actionTypes.ADD_ANNOUNCEMENT_FAIL, error: genericErrorText});
    }
};

export const updateAnnouncement = (data, announcementId, eventId) => async (dispatch) => {
    dispatch({type: actionTypes.UPDATE_ANNOUNCEMENT_START});
    try {
        const response = await axios.patch(`/event/${eventId}/announcement/${announcementId}`, data);
        dispatch({type: actionTypes.UPDATE_ANNOUNCEMENT_SUCCESS, payload: response.data});
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_ANNOUNCEMENT_FAIL,
            error: genericErrorText,
        });
    }
};

export const deleteAnnouncement = (eventId, announcementId) => async (dispatch) => {
    dispatch({type: actionTypes.DELETE_ANNOUNCEMENT_START});
    try {
        await axios.delete(`/event/${eventId}/announcement/${announcementId}`);
        dispatch({type: actionTypes.DELETE_ANNOUNCEMENT_SUCCESS, announcementId: announcementId});
    } catch (error) {
        dispatch({
            type: actionTypes.DELETE_ANNOUNCEMENT_FAIL,
            error: genericErrorText,
        });
    }
};

export const runAnnouncement = (eventId, announcementId, infiniteTime, timeInSeconds) => async (dispatch) => {
    dispatch({type: actionTypes.RUN_ANNOUNCEMENT_START});
    try {
        const response = await axios.get(`/event/${eventId}/announcement/${announcementId}/start`, {
            params: {infiniteTime: infiniteTime, timeInSeconds: timeInSeconds},
        });
        dispatch({
            type: actionTypes.RUN_ANNOUNCEMENT_SUCCESS,
            announcementId: announcementId,
            secondsLeft: response.data.data?.secondsLeft,
            infiniteTime: infiniteTime,
        });
    } catch (error) {
        dispatch({type: actionTypes.RUN_ANNOUNCEMENT_FAIL, error: genericErrorText});
    }
};

export const stopAnnouncement = (eventId, announcementId) => async (dispatch) => {
    dispatch({type: actionTypes.STOP_ANNOUNCEMENT_START});
    try {
        await axios.get(`/event/${eventId}/announcement/${announcementId}/stop`);
        return dispatch(stopAnnouncementSuccess(announcementId))
    } catch (error) {
        dispatch({type: actionTypes.STOP_ANNOUNCEMENT_FAIL, error: genericErrorText});
    }
};

export const stopAnnouncementSuccess = (data) => {
    return {
        type: actionTypes.STOP_ANNOUNCEMENT_SUCCESS,
        payload: data,
    };
}

export const setAnnouncementsBannerOpen = (open) => async (dispatch) => {
    dispatch({type: actionTypes.SET_ANNOUNCEMENTS_BANNER_OPEN, value: open});
}

export const getAuditoriumsAndExhibitBooths = (eventId) => async (dispatch) => {
    dispatch({type: actionTypes.GET_AUDITORIUMS_AND_EXHIBIT_BOOTHS_START});
    try {
        const response = await axios.get(`/event/${eventId}/auditoriums-and-exhibit-booths`);
        dispatch({type: actionTypes.GET_AUDITORIUMS_AND_EXHIBIT_BOOTHS_SUCCESS, payload: response.data});
    } catch (error) {
        dispatch({type: actionTypes.GET_AUDITORIUMS_AND_EXHIBIT_BOOTHS_FAIL, error: genericErrorText});
    }
};

// user
export const increaseAnnouncementClickCount = (eventId, announcementId, userId) => async (dispatch) => {
    dispatch({type: actionTypes.INCREASE_ANNOUNCEMENT_CLICK_COUNT_START});
    try {
        await axios.get(`/event/${eventId}/announcement/${announcementId}/user/${userId}/increase-count`);
    } catch (error) {
        dispatch({type: actionTypes.INCREASE_ANNOUNCEMENT_CLICK_COUNT_FAIL});
    }
};

// statistics
export const getEventAnnouncementStatistics = (eventId) => async (dispatch) => {
    dispatch({type: actionTypes.GET_EVENT_ANNOUNCEMENT_STATISTICS_START});
    try {
        return axios.get(`/event/${eventId}/announcement-statistics`)
            .then((response) => {
                dispatch({type: actionTypes.GET_EVENT_ANNOUNCEMENT_STATISTICS_SUCCESS, payload: response.data});
            })
            .catch(error=>{throw error})
    } catch (error) {
        const errorMessage = error.response.data.error;
        if(errorMessage === 'announcements are disabled for this event.'){
            dispatch({type: actionTypes.ANNOUNCEMENTS_DISABLED});
            return;
        }
        dispatch({
            type: actionTypes.GET_EVENT_ANNOUNCEMENT_STATISTICS_FAIL,
            error: genericErrorText,
        });
    }
};
