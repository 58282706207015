import React from 'react';

import Button from '@material-ui/core/Button';
import HomepageBackground from '../Images/backgrounds/homepage-background.jpg';
import axios from '../store/axios-instance';
import {connect} from 'react-redux';
import {preventDefaultDrag, redirectToPublicPages} from '../Utils/utils';

class NewsletterUnsubscribe extends React.Component {
    state = {
        token: '',
        message: '',
    };

    componentDidMount() {
        // get the token from url, save it to state so that we can send it with the request
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');

        if (!token) {
            this.setState({message: 'We are sorry, something went wrong.'});
        }
        this.setState({token: token});
    }

    handleUnsubscribe = () => {
        axios({method: 'put', url: '/users/newsletter-unsubscribe?token=' + this.state.token})
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        message: response.data.message,
                    });
                }
            })
            .catch((error) => {});
    };

    handleCancel = () => {
        redirectToPublicPages();
    };

    goHome = () => {
        redirectToPublicPages();
    };

    render() {
        const {translation} = this.props;
        return (
            <div>
                <div
                    onDragStart={preventDefaultDrag}
                    className="homepage login-page register-participant password-recovery-page unsubscribe-page"
                >
                    <div
                        onDragStart={preventDefaultDrag}
                        className="event-container"
                        style={{backgroundImage: `url('${HomepageBackground}')`}}
                    >
                        <div onDragStart={preventDefaultDrag} className="homepage-overlay">
                            <div onDragStart={preventDefaultDrag} className="smallContainer">
                                <div onDragStart={preventDefaultDrag} className="single-event-container">
                                    <div onDragStart={preventDefaultDrag} className="right-form">
                                        {this.state.message.length ? (
                                            <>
                                                <p>{this.state.message}</p>
                                                <div onDragStart={preventDefaultDrag} className="register-form-wrapper">
                                                    <Button variant={'text'} onClick={this.goHome}>
                                                        {translation?.unsubscribe.unsubscribeHomepage}
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <p>{translation?.unsubscribe.unsubscribeTitle}</p>
                                                <div onDragStart={preventDefaultDrag} className="register-form-wrapper">
                                                    <Button type="button" onClick={this.handleCancel}>
                                                        {translation?.generalText.cancel}
                                                    </Button>

                                                    <Button type="button" onClick={this.handleUnsubscribe}>
                                                        {translation?.generalText.confirm}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
    };
};

export default connect(mapStateToProps)(NewsletterUnsubscribe);
