import React, {Component} from 'react';
import VideoPlaceholder from '../../Images/pages/platform-homepage/video.jpg';
import {ReactComponent as PlayIcon} from '../../Images/pages/platform-homepage/play-icon.svg';
import RightEffectImage from '../../Images/pages/platform-homepage/animations/video_effects_right_frame.png';
import LeftEffectImage from '../../Images/pages/platform-homepage/animations/video_effects_left_frame.png';
import ReactPlayer from 'react-player';
import {preventDefaultDrag} from '../../Utils/utils';

class HomepageVideo extends Component {
    startVideo = () => {
        this.setState({playing: !this.state.playing});
    };

    state = {
        playing: false,
    };

    render() {
        return (
            <section className={'onvent-video-presentation ' + (this.state.playing ? 'playing' : '')}>
                <div onDragStart={preventDefaultDrag} id="left-effects" className="left-effect">
                    <img draggable="false" onDragStart={preventDefaultDrag} src={LeftEffectImage} alt="" />
                </div>
                <div onDragStart={preventDefaultDrag} className="container">
                    <div onDragStart={preventDefaultDrag} className="video-container">
                        <div onDragStart={preventDefaultDrag} className="effect-image">
                            <div onDragStart={preventDefaultDrag} className="image-container">
                                <img draggable="false" onDragStart={preventDefaultDrag} src={VideoPlaceholder} alt="" />
                            </div>
                        </div>
                        <div onDragStart={preventDefaultDrag} className="main-image">
                            <div onDragStart={preventDefaultDrag} className="image-container">
                                <ReactPlayer
                                    url={'https://youtu.be/1s6u4wQpKwY'}
                                    playing={this.state.playing}
                                    className="react-player"
                                    controls={false}
                                    width="100%"
                                    height="100%"
                                    onContextMenu={(e) => e.preventDefault()}
                                />
                                <img
                                    draggable="false"
                                    onDragStart={preventDefaultDrag}
                                    src={VideoPlaceholder}
                                    onClick={this.startVideo}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div onDragStart={preventDefaultDrag} className="play-icon-container" onClick={this.startVideo}>
                            <PlayIcon />
                        </div>
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} id="right-effects" className="right-effect">
                    <img draggable="false" onDragStart={preventDefaultDrag} src={RightEffectImage} alt="" />
                </div>
            </section>
        );
    }
}

export default HomepageVideo;
