import React, {useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {LinkOutlined as LinkOutlinedIcon} from '@material-ui/icons';
import Button from '@material-ui/core/Button';

const LinksModule = ({routeParams}) => {
    let {registerParam} = routeParams;
    const [loc, setLoc] = useState();
    const [links, setLinks] = useState([]);

    useEffect(() => {
        if (registerParam) return;
        let location = window.location.href;
        if (location.slice(-1) === '/') {
            setLoc(location);
        } else setLoc(location + '/');
        const regLinks = [
            {
                title: 'Speaker URL',
                url: `${loc}speaker-invitation`,
                _id: 'speaker-invitation',
            },
            {
                title: 'Moderator URL',
                url: `${loc}moderator-invitation`,
                _id: 'moderator-invitation',
            },
        ];
        setLinks(regLinks);
    }, [loc, registerParam]);

    return (
        <>
            {!!links.length && (
                <div className="module">
                    <div className="module-container">
                        <div className="module-header">
                            <span>SESSION LINKS</span>
                        </div>
                        <div className="module-body">
                            <div className="module-link_blocks">
                                {links.map((link) => {
                                    return (
                                        <CopyToClipboard
                                            key={link._id}
                                            text={link.url}
                                            onCopy={() => NotificationManager.info('Copied to clipboard')}
                                        >
                                            <div className="module-link_block">
                                                <span>{link.title}</span>
                                                <Button type="button" className="button" startIcon={<LinkOutlinedIcon />}>
                                                    copy url
                                                </Button>
                                            </div>
                                        </CopyToClipboard>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LinksModule;
