import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';


const initialMenu = {
    platformMenu: true,
    eventPublicMenu: false,
    eventProtectedMenu: false,
    adminMenu: false,
    noMenu: false,
    sessionGuestMenu: false,
    currentPage: '',
};

const initialState = {
    menu: {...initialMenu},
    isMobile: false,
    isTablet: false,
    isLargeScreen: true,
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_PLATFORM_MENU:
                draft.menu.platformMenu = true;
                draft.menu.eventPublicMenu = false;
                draft.menu.eventProtectedMenu = false;
                draft.menu.noMenu = false;
                draft.menu.adminMenu = false;
                draft.menu.sessionGuestMenu = false;
                break;

            case actionTypes.SET_EVENT_PUBLIC_MENU:
                draft.menu.platformMenu = false;
                draft.menu.eventPublicMenu = true;
                draft.menu.eventProtectedMenu = false;
                draft.menu.noMenu = false;
                draft.menu.adminMenu = false;
                draft.menu.sessionGuestMenu = false;
                break;

            case actionTypes.SET_EVENT_PROTECTED_MENU:
                draft.menu.platformMenu = false;
                draft.menu.eventPublicMenu = false;
                draft.menu.eventProtectedMenu = true;
                draft.menu.noMenu = false;
                draft.menu.adminMenu = false;
                draft.menu.sessionGuestMenu = false;
                break;

            case actionTypes.SET_ADMIN_MENU:
                draft.menu.platformMenu = false;
                draft.menu.eventPublicMenu = false;
                draft.menu.eventProtectedMenu = false;
                draft.menu.noMenu = false;
                draft.menu.adminMenu = true;
                draft.menu.sessionGuestMenu = false;
                break;

            case actionTypes.SET_SESSION_GUEST_MENU:
                draft.menu.platformMenu = false;
                draft.menu.eventPublicMenu = false;
                draft.menu.eventProtectedMenu = false;
                draft.menu.noMenu = false;
                draft.menu.adminMenu = false;
                draft.menu.sessionGuestMenu = true;
                break;

            case actionTypes.SET_NO_MENU:
                draft.menu.platformMenu = false;
                draft.menu.eventPublicMenu = false;
                draft.menu.eventProtectedMenu = false;
                draft.menu.noMenu = true;
                draft.menu.adminMenu = false;
                draft.menu.sessionGuestMenu = false;
                break;

            case actionTypes.SET_IS_MOBILE:
                draft.isMobile = true;
                draft.isTablet = false;
                draft.isLargeScreen = false;
                break;

            case actionTypes.SET_IS_TABLET:
                draft.isMobile = false;
                draft.isTablet = true;
                draft.isLargeScreen = false;
                break;
                
            case actionTypes.SET_IS_LARGE_SCREEN:
                draft.isMobile = false;
                draft.isTablet = false;
                draft.isLargeScreen = true;
                break;

            default:
                break;
        }
    });

export default reducer;
