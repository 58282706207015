import React, {useEffect, useRef, useState} from 'react';
import {getTimeslotDescriptionFormatted, getTimeslotDuration} from 'Utils/utils';
import {useDispatch, useSelector} from 'react-redux';
import DialogAddToExternalCalendar from '../DialogAddToExternalCalendar';
import Timeslot from './Timeslot';
import {useImmer} from 'use-immer';
import TimeslotAccordion from './TimeslotAccordion';
import {addTimeslotToMyAgenda, deleteATimeslotFromMyAgenda, getMyAgenda} from 'store/actions/myAgendaActions';
import cloneDeep from 'lodash/cloneDeep';
import TimeSlotsListCalendar from './CalendarListStyle/TimeSlotsListCalendar';

const TimeslotsList = ({
    setIsTimeslotInTheFuture,
    timeslots,
    activeTimeslot,
    setActiveTimeslot,
    setTimeslotsInViewport,
    displayNumberOfSearchResult,
    activeDays,
    handleLayoutType,
    layoutType,
    selectedDay,
    searchMode,
    layoutList,
}) => {
    const refTimeslotsList = useRef();
    const event = useSelector((state) => state?.event?.data);
    const eventId = useSelector((state) => state?.event?.eventId);

    const user = useSelector((state) => state?.user?.data);
    const languages = useSelector((state) => state?.languages);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);
    const agenda = useSelector((state) => state?.myAgenda);
    const {agenda: myAgenda} = agenda;

    const [calendarTimeslot, setCalendarTimeslot] = useState(null);
    const [externalCalendarDialog, setExternalCalendarDialog] = useState(false);
    const [calendarData, setCalendarData] = useState(null);
    const dispatch = useDispatch();

    const setInitialTimeslotsInViewport = () => {
        let data = {};
        timeslots.forEach((timeslot) => {
            data[timeslot] = false;
        });
        return data;
    };
    const [timeslotsInViewport, updateTimeslotsInViewport] = useImmer(setInitialTimeslotsInViewport());

    const createTimeslotCalendarData = () => {
        const timeslotCalendarData = {
            title: `${calendarTimeslot.title}`,
            description: getTimeslotDescriptionFormatted(calendarTimeslot, translation),
            location: `${window.location.origin}/event/${event.slug}/`,
            start: calendarTimeslot.startTimestamp,
            end: calendarTimeslot.endTimestamp,
        };
        return timeslotCalendarData;
    };

    useEffect(() => {
        if (calendarTimeslot) {
            const calendarData = createTimeslotCalendarData();
            setCalendarData(calendarData);
            setExternalCalendarDialog(true);
        }
    }, [calendarTimeslot]);

    const hideAddToExternalCalendarDialog = () => {
        setExternalCalendarDialog(false);
    };

    const handleSetCalendarTimeslot = (timeslot) => (e) => {
        setCalendarTimeslot(timeslot);
        setExternalCalendarDialog(true);
    };

    const handleSetActiveTimeslot = (timeslot) => (e) => {
        setActiveTimeslot(timeslot);
    };

    const checkTimeslotIsInANewDay = (timeslotIndex) => {
        if (timeslotIndex === 0) return true;
        const currentDay = new Date(timeslots[timeslotIndex].startTimestamp);
        const previousDay = new Date(timeslots[timeslotIndex - 1].startTimestamp);

        // if the day || month || year is has changed from the previous date
        if (
            currentDay.getDate() !== previousDay.getDate() ||
            currentDay.getMonth() !== previousDay.getMonth() ||
            currentDay.getFullYear() !== previousDay.getFullYear()
        ) {
            return true;
        }
    };

    const handleUpdateTimeslotsInViewport = (timeslotId, inViewport) => {
        updateTimeslotsInViewport((draft) => {
            draft[timeslotId] = inViewport;
        });
    };

    useEffect(() => {
        //create array of timeslots in Viewport
        let timeslotsInView = [];
        Object.keys(timeslotsInViewport).forEach((key) => {
            if (timeslotsInViewport[key]) {
                timeslotsInView.push(key);
            }
        });
        setTimeslotsInViewport(timeslotsInView);
    }, [timeslotsInViewport]);

    useEffect(() => {
        // I used this counter because we want only when you open the program to select the first timeslot in the future
        let counter = 0;
        const handleScroll = () => {
            counter++;
            if (counter > 1) {
                setIsTimeslotInTheFuture(false);
            }
        };
        const timeslotsContainer = document.getElementById('program-timeslots-list');
        timeslotsContainer.addEventListener('scroll', handleScroll);

        return () => {
            timeslotsContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const addToMyAgenda = async (timeslot) => {
        let timeslotCopy = cloneDeep(timeslot);
        let newTimeslot = {
            event: eventId,
            displayProgram: timeslotCopy.displayProgram,
            program: timeslotCopy.program,
            auditoriumIndex: timeslotCopy.auditoriumIndex,
            auditorium: timeslotCopy.auditorium,
        };
        let pastSlots = true;

        // if the auditoriumIndex is 0 you will not send that property
        // this is a bug and this is why I commented out this line of code
        // https://trello.com/c/BA0UP9Lr/7386-bug-mag-program-i-cant-add-a-timeslot-to-my-agenda-if-i-am-in-a-search-state

        // newTimeslot = Object.entries(newTimeslot).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

        dispatch(addTimeslotToMyAgenda(user?._id, newTimeslot)).then(() =>
            getMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch)
        );
    };

    const deleteFromMyAgenda = (timeslot) => {
        let timeslotCopy = {...timeslot};
        let pastSlots = true;

        let newTimeslot = {
            program: timeslotCopy.program,
        };
        deleteATimeslotFromMyAgenda(
            user?._id,
            newTimeslot?.program
        )(dispatch).then(() =>
            getMyAgenda(user?._id, pastSlots, user?.timezoneValue || event?.timezoneValue)(dispatch)
        );
    };

    const isElementAddedToTimeslot = (timeslot) => {
        let sessionsNew = Object.values(myAgenda).filter((el) => el.type === 'session');
        return sessionsNew.find((session) => session?.program === timeslot?._id);
    };

    const addDeleteAgenda = (timeslot) => () => {
        isElementAddedToTimeslot(timeslot) ? deleteFromMyAgenda(timeslot) : addToMyAgenda(timeslot);
    };

    useEffect(() => {
        getMyAgenda(user?._id, true, user?.timezoneValue || event?.timezoneValuee)(dispatch);
    }, []);
    return (
        <div className={'timeslots-list scroll-left-container'} id={'program-timeslots-list'} ref={refTimeslotsList}>
            {layoutType === 'list' || searchMode ? (
                timeslots.map((timeslot, timeslotIndex) => {
                    const isNewDay = checkTimeslotIsInANewDay(timeslotIndex);
                    return isLargeScreen ? (
                        <Timeslot
                            handleLayoutType={handleLayoutType}
                            layoutType={layoutType}
                            key={timeslot._id}
                            timeslot={timeslot}
                            isNewDay={isNewDay}
                            timeslotIndex={timeslotIndex}
                            handleSetActiveTimeslot={handleSetActiveTimeslot}
                            activeTimeslot={activeTimeslot}
                            addDeleteAgenda={addDeleteAgenda}
                            isElementAddedToTimeslot={isElementAddedToTimeslot}
                            handleSetCalendarTimeslot={handleSetCalendarTimeslot}
                            refTimeslotsList={refTimeslotsList}
                            updateTimeslotsInViewport={handleUpdateTimeslotsInViewport}
                        />
                    ) : (
                        <TimeslotAccordion
                            key={timeslot._id}
                            timeslot={timeslot}
                            isNewDay={isNewDay}
                            handleSetActiveTimeslot={handleSetActiveTimeslot}
                            activeTimeslot={activeTimeslot}
                            handleSetCalendarTimeslot={handleSetCalendarTimeslot}
                            refTimeslotsList={refTimeslotsList}
                            updateTimeslotsInViewport={handleUpdateTimeslotsInViewport}
                            displayNumberOfSearchResult={displayNumberOfSearchResult}
                            activeDays={activeDays}
                            addDeleteAgenda={addDeleteAgenda}
                            isElementAddedToTimeslot={isElementAddedToTimeslot}
                        />
                    );
                })
            ) : isLargeScreen ? (
                <TimeSlotsListCalendar
                    timeslots={timeslots}
                    selectedDay={selectedDay}
                    handleLayoutType={handleLayoutType}
                    displayNumberOfSearchResult={displayNumberOfSearchResult}
                    layoutType={layoutType}
                    layoutList={layoutList}
                    addDeleteAgenda={addDeleteAgenda}
                    isElementAddedToTimeslot={isElementAddedToTimeslot}
                />
            ) : (
                timeslots.map((timeslot, timeslotIndex) => {
                    const isNewDay = checkTimeslotIsInANewDay(timeslotIndex);
                    return (
                        <TimeslotAccordion
                            key={timeslot._id}
                            timeslot={timeslot}
                            isNewDay={isNewDay}
                            handleSetActiveTimeslot={handleSetActiveTimeslot}
                            activeTimeslot={activeTimeslot}
                            handleSetCalendarTimeslot={handleSetCalendarTimeslot}
                            refTimeslotsList={refTimeslotsList}
                            updateTimeslotsInViewport={handleUpdateTimeslotsInViewport}
                            displayNumberOfSearchResult={displayNumberOfSearchResult}
                            activeDays={activeDays}
                            addDeleteAgenda={addDeleteAgenda}
                            isElementAddedToTimeslot={isElementAddedToTimeslot}
                        />
                    );
                })
            )}
            {externalCalendarDialog && (
                <DialogAddToExternalCalendar
                    open={externalCalendarDialog}
                    onClose={hideAddToExternalCalendarDialog}
                    eventCalendarData={calendarData}
                    title={
                        translation?.programDropdown.addTimeslotToYourCalendar ||
                        defaultTranslation?.programDropdown.addTimeslotToYourCalendar
                    }
                    timeslotTitle={
                        translation?.programDropdown.timeslotTitle || defaultTranslation?.programDropdown.timeslotTitle
                    }
                    timeslotDescripion={calendarTimeslot.title}
                    timeslotSpeakers={calendarTimeslot.speakers}
                    durationTitle={
                        translation?.programDropdown.slotScheduled || defaultTranslation?.programDropdown.slotScheduled
                    }
                    duration={getTimeslotDuration(calendarTimeslot, languages, event, user)}
                    tip={
                        translation?.programDropdown.tipTimeslotDescription ||
                        defaultTranslation?.programDropdown.tipTimeslotDescription
                    }
                />
            )}
        </div>
    );
};

export default TimeslotsList;
