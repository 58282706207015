import Header from './Header';
import '../css/LayoutStyles.scss';
import PreviousStepButton from './PreviousStepButton';
import {Helmet} from 'react-helmet';
import React from 'react';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import {getHPBackgroundImageURL} from 'Utils/utils';

const Layout = ({
    children,
    childrenWidth,
    childrenMargin,
    privateFnLogin,
    isEventLogin,
    brandingTranslation,
    translation,
    defaultTranslation,
    childrenPadding,
    userExistButNotLoggedIn,
    showRecoverPasswordDialog,
}) => {
    const currentLanguage = useSelector((state) => state.languages.eventLanguage);
    const eventInfo = useSelector((state) => state.event.eventInfo);
    const langArabic = currentLanguage === 'ar';

    const isLoginPage = window.location.pathname.includes('/login');
    const isSelectRolePage = window.location.pathname.includes('/select-role');

    const deathSpaceBg = () => {
        let location = 'loginBackground';

        const currentPageURL = window.location.href;

        if (currentPageURL.includes('participant-registration')) {
            location = 'participantsRegBackground';
        } else if (currentPageURL.includes('exhibitor-registration')) {
            location = 'exhibitorsRegBackground';
        } else if (currentPageURL.includes('scholar-registration')) {
            location = 'scholarsRegBackground';
        }

        if (eventInfo) {
            const backgroundImageURL = getHPBackgroundImageURL(eventInfo, location);
            const customImage = backgroundImageURL?.match(/\.(jpg|png|jpeg)$/i);

            return {
                background: backgroundImageURL && customImage && `url(${backgroundImageURL})`,
            };
        }
    };

    return (
        <div className={`login-registration-container d-flex-row`}>
            {isEventLogin && (
                <Helmet>
                    <title>{`${process.env.REACT_APP_PLATFORM_NAME} - ${
                        brandingTranslation?.homepageEventName
                            ? brandingTranslation?.homepageEventName
                            : eventInfo.title
                    }: ${translation?.login.loginButton || defaultTranslation?.login.loginButton}`}</title>
                    <meta
                        property="og:title"
                        content={`${process.env.REACT_APP_PLATFORM_NAME} - ${brandingTranslation?.homepageEventName}: ${
                            translation?.login.loginButton || defaultTranslation?.login.loginButton
                        }`}
                    />
                </Helmet>
            )}

            <div className={classnames('login-registration-action d-flex-column', {'lang-ar': langArabic})}>
                <Header />
                <PreviousStepButton
                    showRecoverPasswordDialog={showRecoverPasswordDialog}
                    privateFnLogin={privateFnLogin}
                    userExistButNotLoggedIn={userExistButNotLoggedIn}
                />
                <div
                    className={classnames('children-container', {
                        'login-page': isLoginPage,
                        'select-role-page': isSelectRolePage,
                    })}
                    style={{width: childrenWidth, padding: childrenPadding, marginTop: childrenMargin}}
                >
                    {children}
                </div>
            </div>
            <div
                style={deathSpaceBg()}
                className={classnames('login-registration-death-space', {'lang-ar': langArabic})}
            ></div>
        </div>
    );
};

PreviousStepButton.defaultProps = {
    childrenWidth: '100%',
    isEventLogin: false,
    brandingTranslation: {},
};
export default Layout;
