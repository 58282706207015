import React from 'react';
import {Radio} from '@material-ui/core';
import {connect} from 'react-redux';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import StatisticsUsersTable from '../StatisticsUsersTable';
import {Doughnut} from 'react-chartjs-2';
import {preventDefaultDrag} from '../../../../Utils/utils';
import OldStatistics from './OldStatistics';
import Spinner from '../../../../SmallLayoutComponents/Spinner';
import '../../../../CSS/_typography.scss';
import './ExhibitorsEnhancedStyles.scss';
import axios from '../../../../store/axios-instance';

class ExhibitorsEnhanced extends React.Component {
    state = {
        searchValue: '',
        usersList: [],
        downloadUsers: [],
        statistics: [],
        activeStatistic: '',
    };

    componentDidMount() {
        this.setStatisticsData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentOnlineUsers !== this.props.currentOnlineUsers) {
            let updatedStatistics = [...this.state.statistics];
            updatedStatistics[1].value = this.props.currentOnlineUsers.length;
            this.setState({statistics: updatedStatistics});
            if (this.state?.activeStatistic?.name === 'onlineUsers') {
                this.setUsersList();
            }
        }
        if (prevProps.statistics !== this.props.statistics) {
            this.setStatisticsData();
        }
    }

    /*    getTotalVotes = async () => {
        const {eventId, activeShowfloorId} = this.props;
        const {data} = await axios.get(`/event/${eventId}/showfloor/${activeShowfloorId}/voting`, {});
    };*/

    setUsersList = async () => {
        const {activeStatistic, searchValue} = this.state;
        const {eventUsers, statisticsEnhanced, currentOnlineUsers, eventId, exhibitorId} = this.props;
        const {allEventUsers} = eventUsers;
        let usersList, userIds;
        const params = {
            eventId: eventId,
        };
        //come here
        let apiCall = `/exhibitor/${exhibitorId}/booth-statistics`;

        if (activeStatistic) {
            if (activeStatistic.name === 'uniqueVisitors') {
                userIds = [...new Set(statisticsEnhanced.allUsers.users.map((el) => el.userId))];
                usersList = allEventUsers.filter((eventUser) => userIds.includes(eventUser?.user));
                let updatedStatistics = [...this.state.statistics];
                updatedStatistics[0].value = usersList.length;
                this.setState({statistics: updatedStatistics});
            } else if (activeStatistic.name === 'onlineUsers' && currentOnlineUsers) {
                userIds = [...currentOnlineUsers];
            } else if (activeStatistic.name === 'documentClicks') {
                userIds = [...new Set(statisticsEnhanced.allDocuments.users.map((el) => el.userId))];
            } else if (activeStatistic.name.includes('documentXX')) {
                userIds = [...new Set(statisticsEnhanced[activeStatistic.name].users.map((el) => el.userId))];
            } else if (activeStatistic.name === 'linkClicks') {
                userIds = [...new Set(statisticsEnhanced.allLinks.users.map((el) => el.userId))];
            } else if (activeStatistic.name.includes('linkXX')) {
                userIds = [...new Set(statisticsEnhanced[activeStatistic.name].users.map((el) => el.userId))];
            } else if (activeStatistic.name === 'representativeClicks') {
                userIds = [...new Set(statisticsEnhanced.allRepresentatives.users.map((el) => el.userId))];
            } else if (activeStatistic.name.includes('representativeXX')) {
                userIds = [...new Set(statisticsEnhanced[activeStatistic.name].users.map((el) => el.userId))];
            } else if (activeStatistic.name.includes('uniqueVideoVisitors')) {
                userIds = [...new Set(statisticsEnhanced.allVideoUsers.users.map((el) => el.userId))];
            } else if (activeStatistic.name.includes('totalVote')) {
                const {data} = await axios.get(apiCall, {params});
                if (data?.success) {
                    userIds = [...new Set(data?.boothStatistics?.voting?.users)];
                    if (userIds.length > 0) {
                        usersList = allEventUsers.filter((eventUser) => userIds.includes(eventUser?.user));
                    }
                }
            }
        } else {
            userIds = [...new Set(statisticsEnhanced.allUsers.users.map((el) => el.userId))];
            usersList = allEventUsers.filter((eventUser) => userIds.includes(eventUser?.user));
            let updatedStatistics = [...this.state.statistics];
            updatedStatistics[0].value = usersList.length;
            this.setState({statistics: updatedStatistics});
        }
        usersList = allEventUsers.filter((eventUser) => userIds.includes(eventUser?.user));

        if (searchValue) {
            usersList = usersList.filter((userData) => {
                const fullName = `${userData.first.toLowerCase()} ${userData.last.toLowerCase()}`;
                const fullNameBackwards = `${userData.last.toLowerCase()} ${userData.first.toLowerCase()}`;
                return (
                    fullName.includes(searchValue.toLowerCase()) ||
                    fullNameBackwards.includes(searchValue.toLowerCase())
                );
            });
        }
        this.setState({usersList: usersList, downloadUsers: userIds});
    };

    setStatisticsData = () => {
        const {statistics} = this.props;
        let updatedStatistics = [];
        let activeStatisticsArray = [
            'uniqueVisitors',
            'onlineUsers',
            'documentClicks',
            'documentXX',
            'linkClicks',
            'linkXX',
            'representativeClicks',
            'representativeXX',
            'uniqueVideoVisitors',
            'totalVote',
        ];
        statistics.forEach((statistic) => {
            let hasSelect = false;
            activeStatisticsArray.forEach((el) => {
                if (statistic.label.includes(el)) {
                    hasSelect = true;
                }
            });
            let obj = {
                label: statistic.name,
                name: statistic.label,
                value: statistic.value,
                isActive: statistic.label === 'uniqueVisitors',
                hasSelect: hasSelect,
            };
            updatedStatistics.push(obj);
        });
        this.setState({statistics: updatedStatistics}, () => {
            this.setUsersList();
        });
    };

    setActiveStatistic = (index) => () => {
        let updatedStatistics = [...this.state.statistics];
        updatedStatistics.forEach((statistic) => (statistic.isActive = false));
        updatedStatistics[index].isActive = true;
        this.setState(
            {
                statistics: updatedStatistics,
                activeStatistic: updatedStatistics[index],
            },
            this.setUsersList
        );
    };

    handleEnterKey = (statisticIndex) => (e) => {
        if (e.key === 'Enter') return this.setActiveStatistic(statisticIndex)();
    };

    render() {
        const {statistics, activeStatistic, usersList, downloadUsers} = this.state;
        const {
            event,
            statisticsChartData,
            statisticsEnhanced,
            organizerDashboard,
            isNewTrackVersion,
            eventUsersVisitedBooth,
            oldStatistics,
            statisticIndex,
            numberOfOldStatistics,
            loadingData,
            translation,
            defaultTranslation,
        } = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="statistics-part enhanced-statistics">
                <div className="table-statistics-container">
                    <div onDragStart={preventDefaultDrag} className="statistics-table actionable">
                        {!organizerDashboard && (
                            <div className="statistics-header">
                                <span
                                    onDragStart={preventDefaultDrag}
                                    draggable="false"
                                    className="title text-transform-none"
                                >
                                    {translation?.boothDashboard?.dataType ||
                                        defaultTranslation?.boothDashboard?.dataType}
                                </span>
                                <span onDragStart={preventDefaultDrag} draggable="false" className="action">
                                    {translation?.boothDashboard?.showUsers ||
                                        defaultTranslation?.boothDashboard?.showUsers}
                                </span>
                                <span onDragStart={preventDefaultDrag} draggable="false" className="counter">
                                    {translation?.boothDashboard?.value || defaultTranslation?.boothDashboard?.value}
                                </span>
                            </div>
                        )}
                        <div className="list-wrapper">
                            <ul>
                                {loadingData && <Spinner />}
                                {organizerDashboard && (
                                    <li className="statistics-header">
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="title text-transform-none"
                                        >
                                            Data type
                                        </span>
                                        <span onDragStart={preventDefaultDrag} draggable="false" className="action">
                                            Show users
                                        </span>
                                        <span onDragStart={preventDefaultDrag} draggable="false" className="counter">
                                            Value
                                        </span>
                                    </li>
                                )}
                                {statistics.map((statistic, index) => {
                                    let displaySelect = false;
                                    if (statistic.hasSelect && isNewTrackVersion) {
                                        displaySelect = true;
                                    }
                                    if (
                                        statistic.hasSelect &&
                                        (statistic.name === 'uniqueVisitors' || statistic.name === 'onlineUsers')
                                    ) {
                                        displaySelect = true;
                                    }
                                    if (statistic.hasSelect && organizerDashboard) {
                                        displaySelect = true;
                                    }

                                    let statisticLabel = statistic.label.replace('~ ', '• ');
                                    return (
                                        <li
                                            tabIndex={statistic.hasSelect ? '0' : '-1'}
                                            key={'eventStatistic' + index}
                                            className="statistics-wcag-li"
                                            onClick={displaySelect ? this.setActiveStatistic(index) : null}
                                            onKeyDown={
                                                statistic.hasSelect ? this.handleEnterKey(index) : preventDefaultDrag
                                            }
                                        >
                                            <div
                                                onDragStart={preventDefaultDrag}
                                                className={`title attendees ${
                                                    statisticLabel.charAt(0) === '•' ? 'title-item' : ''
                                                }`}
                                            >
                                                <span className="item-content text-transform-none">
                                                    {statisticLabel.charAt(0) === '•' ? (
                                                        <>
                                                            <span>{statisticLabel.charAt(0)}</span>
                                                            {statisticLabel.substring(2)}
                                                        </>
                                                    ) : (
                                                        <>{statisticLabel}</>
                                                    )}
                                                </span>
                                                {statistic.icon && (
                                                    <Tooltip
                                                        arrow
                                                        tabIndex={statistic.hasSelect ? '0' : '-1'}
                                                        classes={{popper: 'attendees-tooltip ignore-rtl'}}
                                                        title={statistic.infoText}
                                                    >
                                                        {statisticLabel}
                                                    </Tooltip>
                                                )}
                                            </div>
                                            {displaySelect ? (
                                                <div onDragStart={preventDefaultDrag} className="action">
                                                    <Radio
                                                        tabIndex="-1"
                                                        color="secondary"
                                                        checked={statistic.isActive}
                                                    />
                                                </div>
                                            ) : null}
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="counter"
                                            >
                                                {statistic.value}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    {numberOfOldStatistics > 0 && organizerDashboard && (
                        <OldStatistics oldStatistics={oldStatistics} statisticIndex={statisticIndex} />
                    )}
                </div>
                <div className="statistics-details">
                    <StatisticsUsersTable
                        usersList={usersList}
                        downloadUsers={downloadUsers}
                        statisticLabel={
                            activeStatistic?.label?.replace('~ ', '') ||
                            translation?.boothDashboard?.uniqueVisitors ||
                            defaultTranslation?.boothDashboard?.uniqueVisitors
                        }
                        slotTitle={`${event.name} - ${statisticsEnhanced?.company} ${
                            activeStatistic?.label || 'Unique Visitors'
                        }`}
                        enhancedStatistics={statisticsEnhanced}
                        statisticName={activeStatistic?.name || 'uniqueVisitors'}
                        isNewTrackVersion={isNewTrackVersion}
                        eventUsersVisitedBooth={eventUsersVisitedBooth}
                    />
                    {statisticsChartData && (
                        <div onDragStart={preventDefaultDrag} className="statistics-chart">
                            <div onDragStart={preventDefaultDrag} className="chart">
                                <Doughnut
                                    data={statisticsChartData}
                                    legend={{
                                        position: 'bottom',
                                    }}
                                    options={{
                                        padding: '16',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventRoles: state.user.eventRoles,
        eventUsers: state.eventUsers,
        eventId: state.event.eventId,
        event: state.event.data,
        exhibitorId: state.exhibitor.exhibitorId,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(ExhibitorsEnhanced);
