import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {ReactComponent as DragDrop} from '../../EventManagement/Branding/Speakers/Helpers/Icons/DragDrop.svg';
import {reOrderShowfloors} from '../../../store/actions';
import * as actions from '../../../store/actions';
import './OrganizerAccordionNavigationStyles.scss';
import {getFormatedDate, preventDefaultDrag} from 'Utils/utils';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Button from '@material-ui/core/Button';
export const isProgramTab = (location) => location.pathname.split('/').includes('program');
export const isProgramOrAuditoriumTab = (location) =>
    location.pathname.split('/').includes('program') || location.pathname.split('/').includes('auditoriums');
class OrganizerAccordionNavigation extends Component {
    state = {
        selectedTab: null,
        selectedSubTab: null,
        showfloorsLocalState: [],
        tabsForOnGoingEvent: [],
    };

    componentDidMount() {
        this.setDataFromUrl();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setDataFromUrl();
            if (this.state.selectedTab === 'showfloors') {
                this.sortShowfloors();
            }
        }

        if (prevProps.tabsList !== this.props.tabsList) {
            this.setDataFromUrl();
        }
        if (prevProps.showfloor?.name !== this.props.showfloor?.name) {
            this.props.onGetShowfloor(this.props.eventId, this.props.showfloor?._id);
            this.sortShowfloors();
        }
    }

    setDataFromUrl = () => {
        const {tabsList, location, navigationPrefix} = this.props;

        const locationPath = location.pathname.split('/');
        const navigationPrefixSubRouteIndex = locationPath.findIndex((subRoute) => subRoute === navigationPrefix);

        const currentTabPath = locationPath[navigationPrefixSubRouteIndex + 1];
        const currentSubTabPath = locationPath?.[navigationPrefixSubRouteIndex + 2];

        const currentTab = tabsList.find((tab) => tab.path === currentTabPath);
        const currentSubTab = currentTab?.subTabs?.find((subTab) => subTab.path === currentSubTabPath);

        this.setState({
            selectedTab: !isProgramTab(location)
                ? currentTab?.name ?? tabsList[0]?.name
                : currentTab?.name ?? 'program',
            selectedSubTab: currentSubTab?.name ?? tabsList[0]?.subTabs?.[0].name,
        });
    };

    handleEnterKey = (subTab) => (e) => {
        if (e.key === 'Enter') return this.selectSubTab(subTab.name)();
    };

    handleTabClick = (tab) => (e, newExpanded) => {
        const {history, navigationPrefix, eventSlug} = this.props;
        this.selectTab(tab, newExpanded);
        if (!tab.subTabs?.length) {
            history.push(`/event/${eventSlug}/dashboard/${navigationPrefix}/${tab.path}`);
        } else {
            const firstSubTabPath = tab.subTabs[0].path;
            history.push(`/event/${eventSlug}/dashboard/${navigationPrefix}/${tab.path}/${firstSubTabPath}`);
        }
    };

    selectTab = (tab, newExpanded) => {
        this.setState({
            selectedTab: newExpanded ? tab.name : null,
            selectedSubTab: newExpanded ? tab?.subTabs?.[0].name : null,
        });
        window.scrollTo(0, 0);
    };

    selectSubTab = (selectedSubTabName) => (e) => {
        this.setState({selectedSubTab: selectedSubTabName});
        window.scrollTo(0, 0);
    };

    checkExpandIcon = (tab) => {
        if (tab.subTabs?.length && this.state.selectedTab !== tab.name) {
            return <ExpandMoreIcon />;
        }
        return null;
    };

    //DRAG-DROP FEAUTURE FOR THE SHOWFLOORS
    getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the localSpeakers look a bit nicer
        userSelect: 'none',
        margin: `0 0 10px 0`,
        ...draggableStyle,
    });
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onDragEnd = (result) => {
        const {eventId} = this.props;
        if (!result.destination) {
            return;
        }
        const localShowfloorsReordered = this.reorder(
            this.state.showfloorsLocalState,
            result.source.index,
            result.destination.index
        );

        let newOrdered = [];
        localShowfloorsReordered.map((listItem, index) => {
            newOrdered.push({id: listItem._id, position: index});
        });

        this.props.reOrderShowfloors(eventId, newOrdered).then(() => this.props.onGetShowfloors(eventId));
        this.setState({showfloorsLocalState: localShowfloorsReordered});
    };

    sortShowfloors = () => {
        const {showfloors} = this.props;
        let readyToSort = [...showfloors];

        if (readyToSort) {
            const sorted = readyToSort.sort(function (a, b) {
                return a.position - b.position;
            });
            if (sorted) this.setState({showfloorsLocalState: sorted});
        } else {
            this.setState({showfloorsLocalState: []});
        }
    };

    tabs = (tab) => {
        const {navigationPrefix, eventSlug, onAddSubTabClick, onAddSubTabKeyDown, addSubTabText} = this.props;
        const {selectedTab, selectedSubTab, showfloorsLocalState} = this.state;

        if (selectedTab === 'showfloors') {
            return (
                <AccordionDetails>
                    <ul className="subtabs showfloor-helper">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {showfloorsLocalState.map((showfloor, index) => (
                                            <Draggable key={showfloor._id} draggableId={showfloor._id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={this.getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <li tabIndex="0" key={showfloor._id}>
                                                            <Link
                                                                to={`/event/${eventSlug}/dashboard/${navigationPrefix}/showfloors/${showfloor._id}`}
                                                                key={`subTab${showfloor._id}`}
                                                                onClick={
                                                                    selectedSubTab !== showfloor._id &&
                                                                    this.selectSubTab(showfloor._id)
                                                                }
                                                                className={`subtab-settings-link ${
                                                                    selectedSubTab === showfloor._id ? 'active' : ''
                                                                }`}
                                                                onKeyDown={this.handleEnterKey(showfloor)}
                                                                data-is-navigation
                                                            >
                                                                <span
                                                                    className="dragDrop-element"
                                                                    {...{
                                                                        ...provided.dragHandleProps,
                                                                        tabIndex: -1,
                                                                    }}
                                                                >
                                                                    <DragDrop />
                                                                </span>
                                                                {showfloor.name}
                                                            </Link>
                                                        </li>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <li>
                            {' '}
                            {onAddSubTabClick && onAddSubTabKeyDown && addSubTabText ? (
                                <Button
                                    onClick={onAddSubTabClick}
                                    onKeyDown={onAddSubTabKeyDown}
                                    startIcon={<AddOutlinedIcon />}
                                    className={'add-sub-tab'}
                                >
                                    {addSubTabText}
                                </Button>
                            ) : null}
                        </li>
                    </ul>
                </AccordionDetails>
            );
        } else {
            return (
                <AccordionDetails>
                    <div className="subtabs">
                        {tab.subTabs.map((subTab) => {
                            return (
                                <>
                                    {subTab.path === 'scholar' && subTab.isDisabled === true ? null : (
                                        <Link
                                            to={`/event/${eventSlug}/dashboard/${navigationPrefix}/${tab.path}/${subTab.path}`}
                                            key={`subTab${subTab.name}`}
                                            onClick={selectedSubTab !== subTab.name && this.selectSubTab(subTab.name)}
                                            className={`subtab-settings-link ${
                                                selectedSubTab === subTab.name ? 'active' : ''
                                            }`}
                                            onKeyDown={this.handleEnterKey(subTab)}
                                            data-is-navigation
                                        >
                                            {selectedSubTab === subTab.name && (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="bullet"
                                                />
                                            )}
                                            {subTab.label}
                                        </Link>
                                    )}
                                </>
                            );
                        })}
                        {onAddSubTabClick && onAddSubTabKeyDown && addSubTabText ? (
                            <Button
                                onClick={onAddSubTabClick}
                                onKeyDown={onAddSubTabKeyDown}
                                startIcon={<AddOutlinedIcon />}
                                className={'add-sub-tab'}
                            >
                                {addSubTabText}
                            </Button>
                        ) : null}
                    </div>
                </AccordionDetails>
            );
        }
    };

    handleProgramSetup = () => {
        const {history, eventSlug} = this.props;
        const {selectedTab} = this.state;
        if (selectedTab !== 'program') {
            this.props.resetSelectedDay();
            this.handleTabClick(null);
            this.setState({selectedTab: null, selectedSubTab: null});
            history.push(`/event/${eventSlug}/dashboard/program/`);
        }
    };

    render() {
        const {tabsList, onAddTabClick, onAddTabKeyDown, addTabText, location, event} = this.props;
        const {selectedTab} = this.state;
        const isAuditoriumsTab = location.pathname.split('/').includes('auditoriums') && event.ongoingEvent;

        const newTabsList = tabsList.map((day, index) => ({
            ...day,
            tabName: `Day ${index + 1} (${getFormatedDate(day.name)})`,
        }));

        const generalTabsList = location.pathname.split('/').includes('program') ? newTabsList : tabsList;

        return (
            <div>
                {isProgramTab(location) && (
                    <Accordion
                        className={`${
                            selectedTab === 'program' ? 'active' : ''
                        } organizer-navigation-accordion accordion program-tab-setup`}
                        onChange={this.handleProgramSetup}
                        expanded={false}
                        data-is-navigation
                        square
                    >
                        <AccordionSummary className={`summary`}>PROGRAM SETUP</AccordionSummary>
                    </Accordion>
                )}

                {generalTabsList?.map((tab) => {
                    return (
                        <>
                            <Accordion
                                expanded={selectedTab === tab?.name}
                                onChange={selectedTab !== tab?.name && this.handleTabClick(tab)}
                                className={`organizer-navigation-accordion accordion  ${
                                    selectedTab === tab?.name ? 'active' : ''
                                }`}
                                key={`tab${tab.name}`}
                                data-is-navigation
                                square
                            >
                                {/*use fix-height class for normal (one row) selected menus to have the same height (active with the rest)*/}
                                <AccordionSummary
                                    className={`summary ${tab.label.length < 24 ? 'fix-height' : ''}`}
                                    expandIcon={this.checkExpandIcon(tab)}
                                >
                                    {tab.isDisabled ? (
                                        <Tooltip arrow classes={{tooltip: 'ignore-rtl'}} title={tab.message}>
                                            <p onDragStart={preventDefaultDrag} className="disabled">
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="help-icon"
                                                >
                                                    <HelpIcon fontSize="inherit" />
                                                </span>
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="label"
                                                >
                                                    {tab.label}
                                                </span>
                                            </p>
                                        </Tooltip>
                                    ) : (
                                        <p className={'space-between'}>
                                            {isAuditoriumsTab || isProgramTab(location) ? tab.tabName : tab.label}
                                            {isAuditoriumsTab &&
                                                selectedTab === tab.name &&
                                                generalTabsList.length > 1 && (
                                                    <DeleteSharpIcon
                                                        className={'delete-day'}
                                                        onClick={this.props.handleOpenDeleteDay}
                                                    />
                                                )}
                                        </p>
                                    )}
                                </AccordionSummary>
                                {tab.subTabs?.length > 0 && this.tabs(tab)}
                            </Accordion>
                        </>
                    );
                })}
                {onAddTabClick && onAddTabKeyDown && addTabText ? (
                    <Button
                        onClick={onAddTabClick}
                        onKeyDown={onAddTabKeyDown}
                        startIcon={<AddOutlinedIcon />}
                        className={'add-tab-button'}
                    >
                        {addTabText}
                    </Button>
                ) : null}
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        eventId: state.event.eventId,
        showfloors: state.showfloors.showfloors,
        eventSlug: state.event.data.slug,
        showfloor: state.showfloors.showfloor,
        event: state.event.data,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onGetShowfloors: (eventId) => dispatch(actions.getShowfloors(eventId)),
        reOrderShowfloors: (eventId, listOrder) => dispatch(reOrderShowfloors(eventId, listOrder)),
        onGetShowfloor: (eventId, showfloorId) => dispatch(actions.getShowfloor(eventId, showfloorId)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizerAccordionNavigation));
