import {preventDefaultDrag} from "../../../Utils/utils";
import Button from "@material-ui/core/Button";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import EllipsisTooltip from "../../../SmallLayoutComponents/EllipsisTextTooltip";
import React from "react";

const UploadedTimeslotDocument = ({newDocumentFile, fileErrorTextLength, handleDocumentChange}) => {
    // bug fix if the user uploads a file, deletes it and immediately tries to upload it again
    // Use onClick event to clear value of target input, each time user clicks on field.
    // This ensures that the onChange event will be triggered for the same file as well.
    const handleDocumentClick = (e) => {
        e.target.value = '';
    };

    return (
        <div onDragStart={preventDefaultDrag} className="action-container mr-auto resources">
            <div onDragStart={preventDefaultDrag} className="new-file-upload-container">
                <Button
                    type="button"
                    component="label"
                    variant={"text"}
                    startIcon={<CloudUploadOutlinedIcon/>}
                >
                    <span className="uploaded-text">UPLOADED:</span>
                    {newDocumentFile || (fileErrorTextLength > 0 && newDocumentFile) ? (
                        <span
                            onDragStart={preventDefaultDrag}
                            draggable="false"
                            id="new-document-file-name"
                            className="ellipsis text-lowercase secondary-color pL0mL0"
                        >
                            <span
                                onDragStart={preventDefaultDrag}
                                draggable="false"
                                className="secondary-color text-lowercase pL0mL0 ellipsis"
                            >
                                {fileErrorTextLength > 0 ? (
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="error-light"
                                    >
                                        error
                                    </span>
                                ) : (
                                    <EllipsisTooltip
                                        title={newDocumentFile.name}
                                        spanClassName="secondary-color text-lowercase pL0mL0"
                                    />
                                )}
                            </span>
                        </span>
                    ) : (
                        <span
                            onDragStart={preventDefaultDrag}
                            draggable="false"
                            className="secondary-color text-lowercase pL0mL0"
                        >
                            no file chosen
                        </span>
                    )}
                    <input
                        type="file"
                        accept=".xls,.docx, .xls, .xlsx, .ppt, .pptx, .pdf, .odg, .odp, .ods, .odt"
                        onChange={handleDocumentChange}
                        className="edit-label"
                        hidden
                    />
                </Button>
                <input
                    type="file"
                    accept=".xls,.docx, .xls, .xlsx, .ppt, .pptx, .pdf, .odg, .odp, .ods, .odt"
                    onChange={handleDocumentChange}
                    onClick={handleDocumentClick}
                    className="upload-image-button d-none"
                />
            </div>
        </div>
    )
}

export default UploadedTimeslotDocument;