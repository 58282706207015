import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReactExport from 'react-export-excel';
import axios from '../../../store/axios-instance';
import Spinner from '../../../SmallLayoutComponents/Spinner';
import * as actions from '../../../store/actions';
import MasterAdminNavigation from '../MasterAdminNavigation';
import AdminAccordionNavigation from '../AdminAccordionNavigation';
import Button from '@material-ui/core/Button';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class AdminStatistics extends React.Component {
    state = {
        adminStatisticsOverall: [],
        adminStatisticsArchived: [],
        loadingAdminStatistics: false,
        errorAdminStatistics: '',
        tabsList: [
            {name: 'organizersList', label: 'Organizers list', link: '/dashboard/organizers'},
            {name: 'statistics', label: 'Statistics', link: '/dashboard/organizers/statistics'},
            {name: 'allEvents', label: 'All events', link: '/dashboard/organizers/all-events'},
            {name: 'eventsToday', label: 'Events today', link: '/dashboard/organizers/events-today'},
        ],
        selectedTab: 'statistics',
    };

    componentDidMount() {
        this.props.onSetAdminMenu();
        this.getAdminStatistics();
    }

    getAdminStatistics = () => {
        this.setState({
            loadingAdminStatistics: true,
        });
        axios({method: 'get', url: `/master-admin/platform-statistics`})
            .then((response) => {
                let report = response.data.report;
                let adminStatisticsOverallValues = [
                    {name: 'Number of events', value: report.events.events},
                    {name: 'Number of published events', value: report.events.publishedEvents},
                    {name: 'Number of Events by Approved Organizers', value: report.events.eventsApprovedOrg},
                    {name: 'Number of finished events', value: report.events.finishedEvents},
                    {name: 'Number of upcoming events', value: report.events.upcomingEvents},
                    {name: 'Number of archived events', value: report.archives.archivedEvents},
                    {name: 'Event Days', value: report.events.eventDays},
                    {name: 'Number of all Organizers', value: (report.users.organizersCount + report.users.disabledOrganizersCount)},
                    {name: 'Number of approved Organizers', value: report.users.organizersCount},
                    {name: 'Number of suspended Organizers', value: report.users.disabledOrganizersCount},
                    {name: 'Number of Users', value: report.users.usersCount},
                    {name: 'Number of Participants', value: report.events.participantsCount},
                    {name: 'Number of Speakers', value: report.auditoriums.speakers},
                    {name: 'Number of Exhibitors', value: report.exhibitors.exhibitorsCount},
                    {name: 'Number of Exhibitor Representatives', value: report.exhibitors.exhibitorsRepresentatives},
                    {name: 'Number of Poster/Study', value: report.exhibitors.postersCount},
                    {name: 'Number of Poster/Study Representatives', value: report.exhibitors.postersRepresentatives},
                    {name: 'Event Representatives', value: report.events.eventRepresentatives},
                    {name: 'Event Representative Chats', value: report.events.eventRepresentativeChats},
                    {name: 'Number of Auditoriums', value: report.auditoriums.auditoriums},
                    {name: 'Number of prerecorded Auditorium Timeslots', value: report.auditoriums.prerecordedPresentations},
                    {name: 'Number of live Auditorium Timeslots', value: report.auditoriums.livePresentations},
                    {name: 'Number of video Resources (links and documents)', value: report.auditoriums.videoResources},
                    {name: 'Number of listed Exhibitor Booths', value: report.exhibitors.exhibitorsListed},
                    {name: 'Number of Exhibitor Booth visitors (before 21.04.2021)', value: report.exhibitors.exhibitorsBoothVisitors},
                    {name: 'Number of Exhibitor Document views (before 21.04.2021)', value: report.exhibitors.exhibitorsDocumentClicks},
                    {name: 'Number of Exhibitor Representative chats', value: report.exhibitors.exhibitorsRepresentativesChats},
                    {name: 'Number of Exhibitor Video views (before 21.04.2021)', value: report.exhibitors.exhibitorsVideoViews},
                    {name: 'Number of Exhibitor Link clicks (before 21.04.2021)', value: report.exhibitors.exhibitorsLinkClicks},
                    {name: 'Enhanced Exhibitor Booth Visitors', value: report.exhibitors.exhibitorsBoothVisitorsEnhanced},
                    {name: 'Enhanced Exhibitor Document Clicks', value: report.exhibitors.exhibitorsDocumentClicksEnhanced},
                    {name: 'Enhanced Exhibitor Link Clicks', value: report.exhibitors.exhibitorsLinkClicksEnhanced},
                    {name: 'Enhanced Exhibitor Video Views', value: report.exhibitors.exhibitorsVideoViewsEnhanced},
                    {name: 'Number of listed Poster/Study Booths', value: report.exhibitors.postersListed},
                    {name: 'Number of Poster/Study Booth visitors (before 21.04.2021)', value: report.exhibitors.postersBoothVisitors},
                    {name: 'Number of Poster/Study Representative chats', value: report.exhibitors.postersRepresentativesChats},
                    {name: 'Number of Poster/Study Document views (before 21.04.2021)', value: report.exhibitors.postersDocumentClicks},
                    {name: 'Number of Poster/Study Video views (before 21.04.2021)', value: report.exhibitors.postersVideoViews},
                    {name: 'Number of Poster/Study Link clicks (before 21.04.2021)', value: report.exhibitors.postersLinkClicks},
                    {name: 'Enhanced Poster Booth Visitors', value: report.exhibitors.postersBoothVisitorsEnhanced},
                    {name: 'Enhanced Poster Document Clicks', value: report.exhibitors.postersDocumentClicksEnhanced},
                    {name: 'Enhanced Poster Link Clicks', value: report.exhibitors.postersLinkClicksEnhanced},
                    {name: 'Enhanced Poster Video Views', value: report.exhibitors.postersVideoViewsEnhanced},
                    {name: 'Number of Live Wall posts', value: report.walls.liveWalls.posts},
                    {name: 'Number of Live Wall pinned posts', value: report.walls.liveWalls.pinnedPosts},
                    {name: 'Number of Live Wall comments', value: report.walls.liveWalls.comments},
                    {name: 'Number of Live Wall likes', value: report.walls.liveWalls.likes},
                    {name: 'Number of Booth Wall posts', value: report.walls.boothWalls.posts},
                    {name: 'Number of Booth Wall pinned posts', value: report.walls.boothWalls.pinnedPosts},
                    {name: 'Number of Booth Wall comments', value: report.walls.boothWalls.comments},
                    {name: 'Number of Booth Wall likes', value: report.walls.boothWalls.likes},
                    {name: 'Number of Video Wall posts', value: report.walls.videoWalls.posts},
                    {name: 'Number of Video Wall pinned posts', value: report.walls.videoWalls.pinnedPosts},
                    {name: 'Number of Video Wall comments', value: report.walls.videoWalls.comments},
                    {name: 'Number of Video Wall likes', value: report.walls.videoWalls.likes},
                    {name: 'Number of Email campaigns created', value: report.emails.emailCampaigns},
                    {name: 'Number of Email campaigns sent', value: report.emails.emailCampaignsSent},
                    {name: 'Number of Email campaign recipients', value: report.emails.emailRecipients},
                    {name: 'Number of Custom email audiences', value: report.emails.customAudiences},
                    {name: 'Number of custom Lobby renders', value: report.events.customLobbyUploads},
                    {name: 'Number of custom Auditorium renders', value: report.events.customAuditoriumUploads},
                    {
                        name: 'Number of custom Booth renders',
                        value: report.exhibitors.exhibitorsCusomtBoothUploads + report.exhibitors.postersCusomtBoothUploads,
                    },
                    {name: 'Number of 1 to 1 chats', value: report.chats.chats},
                    {name: 'Number of messages in 1 to 1 chats', value: report.chats.messages},
                    {name: 'Number of group chats', value: report.gChats.groupChats},
                    {name: 'Number of messages in group chats', value: report.gChats.groupChatMessages},
                    {name: 'Number of Contact requests sent', value: report.users.contactReqSent},
                    {name: 'Number of Contact requests accepted', value: report.users.contactReqAccepted},
                    {name: 'Number of Contact requests rejected', value: report.users.contactReqRejected},
                    {name: 'Number of Contact requests pending', value: report.users.contactReqPending},
                    {name: 'Number of Completed Meetings', value: report.meetings.meetingsCompleted},
                    {name: 'Number of Upcoming Meetings', value: report.meetings.meetingsUpcomming},
                    {name: 'Number of Pending Meetings', value: report.meetings.meetingsPending},
                    {name: 'Number of Canceled Meetings', value: report.meetings.meetingsCanceled},
                    {name: 'Number of Sessions', value: report.sessions.totalSessions},
                    {name: 'Number of Live Sessions', value: report.sessions.liveSessions},
                    {name: 'Number of RTMP Timeslots', value: report.sessions.rtmpSessions},
                    {name: 'Number of Youtube Timeslots', value: report.timeslots.youtubeTimeslots},
                    {name: 'Number of Vimeo Timeslots', value: report.timeslots.vimeoTimeslots},
                    {name: 'Number of Wistia Timeslots', value: report.timeslots.wistiaTimeslots},
                    {name: 'Number of MO Engine Timeslots', value: report.timeslots.moEngineTimeslots},
                    {name: 'Number of Co-Organizers', value: report.coOrganizers},
                    {name: 'Number of Events With Wall Moderation', value: report.wallModeration.anyModeration},
                    {name: 'Number of Events With Live Wall Moderation', value: report.wallModeration.liveWallModeration},
                    {name: 'Number of Events With Video Wall Moderation', value: report.wallModeration.videoWallModeration},
                    {name: 'Number of Events With Booth Wall Moderation', value: report.wallModeration.boothWallModeration},
                    {name: 'Number of Events With Matching', value: report.eventsWithMatching},
                    {name: 'Number of Matching Questions', value: report.matchingQuestions},
                    {name: 'Number of Matching Questionnaires completed', value: report.matchingQuestionnairesCompleted},
                ];

                let adminStatisticsArchivedValues = [
                    {name: 'Archived Events', value: report.archives.archivedEvents},
                    {name: 'Archived Events Days', value: report.archives.archivedEventsDays},
                    {name: 'Archived Events Speakers', value: report.archives.archivedEventsSpeakers},
                    {name: 'Archived Events Videos', value: report.archives.archivedEventsVideos},
                    {name: 'Exhibitors Count', value: report.archives.archivedUsageStatistics.exhibitorsCount},
                    {name: 'Participants Count', value: report.archives.archivedUsageStatistics.participantsCount},
                    {name: 'Representatives Chats Count', value: report.archives.archivedUsageStatistics.repChatsCount},
                    {name: 'Total Booth Visitors', value: report.archives.archivedUsageStatistics.totalBoothVisitors},
                    {name: 'Total Document Views', value: report.archives.archivedUsageStatistics.totalDocumentViews},
                    {name: 'Total Link Clicks', value: report.archives.archivedUsageStatistics.totalLinkClicks},
                    {name: 'Total Video Views', value: report.archives.archivedUsageStatistics.totalVideoViews},
                    {
                        name: 'Booth Walls Top Post Comments Count',
                        value: report.archives.archivedWallsStatistics.boothWallsTopPostCommentsCount,
                    },
                    {
                        name: 'Booth Walls Top Post Likes Count',
                        value: report.archives.archivedWallsStatistics.boothWallsTopPostLikesCount,
                    },
                    {
                        name: 'Booth Walls Total Comments Count',
                        value: report.archives.archivedWallsStatistics.boothWallsTotalCommentsCount,
                    },
                    {
                        name: 'Booth Walls Total Likes Count',
                        value: report.archives.archivedWallsStatistics.boothWallsTotalLikesCount,
                    },
                    {
                        name: 'Booth Walls Total Posts Count',
                        value: report.archives.archivedWallsStatistics.boothWallsTotalPostsCount,
                    },
                    {
                        name: 'Live Wall Top Post Comments Count',
                        value: report.archives.archivedWallsStatistics.liveWallTopPostCommentsCount,
                    },
                    {
                        name: 'Live Wall Top Post Likes Count',
                        value: report.archives.archivedWallsStatistics.liveWallTopPostLikesCount,
                    },
                    {
                        name: 'Live Wall Total Comments Count',
                        value: report.archives.archivedWallsStatistics.liveWallTotalCommentsCount,
                    },
                    {
                        name: 'Live Wall Total Likes Count',
                        value: report.archives.archivedWallsStatistics.liveWallTotalLikesCount,
                    },
                    {
                        name: 'Live Wall Total Posts Count',
                        value: report.archives.archivedWallsStatistics.liveWallTotalPostsCount,
                    },
                    {
                        name: 'Video Walls Top Post Comments Count',
                        value: report.archives.archivedWallsStatistics.videoWallsTopPostCommentsCount,
                    },
                    {
                        name: 'Video Walls Top Post Likes Count',
                        value: report.archives.archivedWallsStatistics.videoWallsTopPostLikesCount,
                    },
                    {
                        name: 'Video Walls Total Comments Count',
                        value: report.archives.archivedWallsStatistics.videoWallsTotalCommentsCount,
                    },
                    {
                        name: 'Video Walls Total Likes Count',
                        value: report.archives.archivedWallsStatistics.videoWallsTotalLikesCount,
                    },
                    {
                        name: 'Video Walls Total Posts Count',
                        value: report.archives.archivedWallsStatistics.videoWallsTotalPostsCount,
                    },
                ];
                this.setState({
                    loadingAdminStatistics: false,
                    errorAdminStatistics: '',
                    adminStatisticsOverall: adminStatisticsOverallValues,
                    adminStatisticsArchived: adminStatisticsArchivedValues,
                });
            })
            .catch((error) => {
                this.setState({loadingAdminStatistics: false, errorAdminStatistics: 'Unable to get statistics'});
            });
    };

    refreshAdminStatistics = () => {
        this.getAdminStatistics();
    };

    displayAdminStatistics = (statistics) => {
        return statistics?.map((statistic, statisticIndex) => {
            return (
                <li key={'adminStatistic' + statisticIndex}>
                    <span onDragStart={(e) => e.preventDefault()} draggable="false" className="title">
                        {statistic.name}
                    </span>
                    <span onDragStart={(e) => e.preventDefault()} draggable="false" className="counter">
                        {statistic.value}
                    </span>
                </li>
            );
        });
    };

    render() {
        const {
            tabsList,
            selectedTab,
            adminStatisticsOverall,
            adminStatisticsArchived,
            loadingAdminStatistics,
        } = this.state;

        return (
            <div onDragStart={(e) => e.preventDefault()} className="superadmin-dashboard">
                <MasterAdminNavigation />
                <section className="top-section">
                    <div onDragStart={(e) => e.preventDefault()} className="admin-dashboard">
                        <div className="admin-sidebar">
                            <AdminAccordionNavigation tabsList={tabsList} selectedTab={selectedTab} />
                        </div>
                        <div className="admin-content-container">
                            <div
                                className={`admin-statistics-container ${
                                    loadingAdminStatistics ? 'loading-section' : ''
                                }`}
                            >
                                {loadingAdminStatistics && <Spinner />}
                                <div className="admin-download-header">
                                    <h1>Overall</h1>
                                    <div onDragStart={(e) => e.preventDefault()} className="d-flex">
                                        <ExcelFile
                                            filename={`${process.env.REACT_APP_PLATFORM_NAME}-Overall-Statistics`}
                                            element={
                                                <Button
                                                    onDragStart={(e) => e.preventDefault()}
                                                    startIcon={<GetAppIcon />}
                                                >
                                                    DOWNLOAD
                                                </Button>
                                            }
                                        >
                                            <ExcelSheet data={adminStatisticsOverall} name="Overall Statistics">
                                                <ExcelColumn label="Statistic Name" value="name" />
                                                <ExcelColumn label="Value" value="value" />
                                            </ExcelSheet>
                                            <ExcelSheet data={adminStatisticsArchived} name="Archived Events">
                                                <ExcelColumn label="Statistic Name" value="name" />
                                                <ExcelColumn label="Value" value="value" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                        <Button
                                            onDragStart={(e) => e.preventDefault()}
                                            onClick={this.refreshAdminStatistics}
                                            startIcon={<RefreshIcon />}
                                        >
                                            REFRESH
                                        </Button>
                                    </div>
                                </div>
                                <div className="table-statistics">
                                    <ul>
                                        <li className="header-statistics">
                                            <span
                                                onDragStart={(e) => e.preventDefault()}
                                                draggable="false"
                                                className="title"
                                            >
                                                Data type
                                            </span>
                                            <span
                                                onDragStart={(e) => e.preventDefault()}
                                                draggable="false"
                                                className="counter"
                                            >
                                                # OF USERS
                                            </span>
                                        </li>
                                        {this.displayAdminStatistics(adminStatisticsOverall)}
                                    </ul>
                                </div>
                                <h2>Archived events</h2>
                                <div className="table-statistics">
                                    <ul>
                                        <li className="header-statistics">
                                            <span
                                                onDragStart={(e) => e.preventDefault()}
                                                draggable="false"
                                                className="title"
                                            >
                                                Data type
                                            </span>
                                            <span
                                                onDragStart={(e) => e.preventDefault()}
                                                draggable="false"
                                                className="counter"
                                            >
                                                # OF USERS
                                            </span>
                                        </li>
                                        {this.displayAdminStatistics(adminStatisticsArchived)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAdminMenu: () => dispatch(actions.setAdminMenu()),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(AdminStatistics));
