import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import {connect} from 'react-redux';
import Confirm from '../../../../Dialogs/Confirm';
import PublishIcon from '@material-ui/icons/Publish';
import * as actions from '../../../../store/actions/index';
import axios from '../../../../store/axios-instance';
import {preventDefaultDrag, fireClickEvent} from 'Utils/utils';
import { verifyFileType } from 'Utils/verifyFileType';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Spinner from "../../../../SmallLayoutComponents/Spinner";

class HPBackgroundImage extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            newLogoFile: null,
            imageLogoPreviewUrl: null,
            imageLogoErrorText: '',
            imageLogoWarningText: '',
            openConfirmUnsavedChanges: false,
            navigationElement: null,
            loading: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    closeClickOutside = () => this.setState({openConfirmUnsavedChanges: false});

    handleEnterKey = (e) => {
        if (e.key === 'Enter') return document.getElementById('upload-image-input').click();
    };

    getNewNavigationElement = (e) => {
        const {navigationElement, openConfirmUnsavedChanges} = this.state;
        const isEventTargetNavigationELement = e.path?.find((pathElem) => pathElem.getAttribute?.('data-is-navigation'));

        if (openConfirmUnsavedChanges) {
            return navigationElement;
        }

        if (isEventTargetNavigationELement) {
            return e.target;
        }

        return null;
    };

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            if (
                this.state.imageLogoPreviewUrl &&
                !this.state.imageLogoErrorText.length > 0 &&
                this.props.image !== null
            ) {
                this.setState({openConfirmUnsavedChanges: true, navigationElement: this.getNewNavigationElement(e)});
            }
        }
    }

    checkBannerDimensions = (e) => {
        if (this.props.imageRatio) {
            const imageWidth = e.target.naturalWidth;
            const imageHeight = e.target.naturalHeight;

            if (
                imageHeight / imageWidth > this.props.imageRatio - 0.01 &&
                imageHeight / imageWidth < this.props.imageRatio + 0.01
            ) {
                this.setState({imageLogoWarningText: ''});
            } else {
                this.setState({imageLogoWarningText: 'Warning: image does not have the recommended ratio.'});
            }
        }
    };

    removeLogo = () => {
        const {eventId} = this.props;
        const data = {
            brandingProperty: this.props.for,
        };

        let deleteUrl = this.props.deleteUrl ? this.props.deleteUrl : 'branding/delete-background-image';

        axios({method: 'delete', url: `/event/v2/${eventId}/${deleteUrl}`, data: data})
            .then((response) => {
                const brandingData = response.data.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.setState({
                    newLogoFile: null,
                    imageLogoPreviewUrl: null,
                    imageLogoWarningText: '',
                    openConfirmUnsavedChanges: false,
                });
                this.props.openSuccessSnackbar();
            })
            .catch(() => this.props.openErrorSnackbar());
    };

    updateLogo = () => {
        this.setState({loading: true});
        const {eventId} = this.props;
        const {navigationElement} = this.state;

        const formData = new FormData();
        formData.append('brandingProperty', this.props.for);
        formData.append('image', this.state.newLogoFile, this.state.newLogoFile.name, this.state.newLogoFile.type);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        let uploadUrl = this.props.uploadUrl ? this.props.uploadUrl : 'branding/upload-background-image';

        axios({method: 'post', url: `/event/${eventId}/${uploadUrl}`, data: formData, config})
            .then((response) => {
                const brandingData = response.data.brandingData;
                this.props.getEventBrandingSuccess(brandingData);
                this.setState({
                    newLogoFile: null,
                    imageLogoPreviewUrl: null,
                    openConfirmUnsavedChanges: false,
                });
                this.props.openSuccessSnackbar();

                if (navigationElement) {
                    fireClickEvent(navigationElement);
                }
                this.setState({loading: false});
            })
            .catch(() => {
                this.props.openErrorSnackbar();
                this.setState({loading: false});
            });
    };

    handleDiscardChanges = () => {
        const {navigationElement} = this.state;

        this.closeClickOutside();
        this.setState({
            newLogoFile: null,
            imageLogoPreviewUrl: null,
            imageLogoErrorText: '',
            imageLogoWarningText: '',
        });

        if (navigationElement) {
            fireClickEvent(navigationElement);
        }
    };

    handleLogoChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;
        let uploadLimit = 2;

        if (!file) {
            return;
        }

        if (this.props.uploadLimit) {
            uploadLimit = this.props.uploadLimit;
        }

        isValid = file.size < uploadLimit * 1024 * 1024 && isValid;
        if (!isValid) {
            this.setState({
                imageLogoPreviewUrl: null,
                imageLogoErrorText: `File too large. ${uploadLimit}Mb max file size.`,
            });
        }

        const typeValid = verifyFileType(file.type, 'image');
        isValid = typeValid && isValid;
        if (!typeValid) {
            this.setState({
                imageLogoPreviewUrl: null,
                imageLogoErrorText:
                    'File type not supported. Please use one of the following: jpeg, jpg, jfif, gif or png.',
            });
        }

        if (isValid && this.props.imageRatio) {
            const scope = this;

            reader.onload = (e) => {
                let img = new Image();
                img.src = e.target.result;
                img.onload = function () {
                    const imageWidth = this.width;
                    const imageHeight = this.height;

                    if (
                        imageHeight / imageWidth > scope.props.imageRatio - 0.01 &&
                        imageHeight / imageWidth < scope.props.imageRatio + 0.01
                    ) {
                        scope.setState({imageLogoWarningText: ''});
                    } else {
                        scope.setState({imageLogoWarningText: 'Warning: image does not have the recommended ratio.'});
                    }
                };
            };
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    newLogoFile: file,
                    imageLogoPreviewUrl: reader.result,
                    imageLogoErrorText: '',
                });
            }
        };

        reader.readAsDataURL(file);
        // Reset input otherwise second upload of the SAME IMAGE won't trigger input onChange event
        e.target.value = '';
    };

    Image = () => {
        let {
            newLogoFile,
            imageLogoPreviewUrl,
            imageLogoErrorText,
            imageLogoWarningText,
            openConfirmUnsavedChanges,
            loading
        } =
            this.state;
        let {image, filesUrl, description, device, resize, infoBulletImage} = this.props;

        if(loading) {
            return (
                <div
                    onDragStart={preventDefaultDrag}
                    className="current-logo-container cover-img-container"
                >
                    <Spinner custom={'wall-spinner'}/>
                </div>
            )
        }

        if (image) {
            return (
                <div
                    onDragStart={preventDefaultDrag}
                    className="current-logo-container cover-img-container"
                >
                    {imageLogoPreviewUrl ? (
                        <img
                            draggable="false"
                            onDragStart={preventDefaultDrag}
                            src={imageLogoPreviewUrl}
                            alt="preview"
                        />
                    ) : (
                        <img
                            draggable="false"
                            onDragStart={preventDefaultDrag}
                            src={filesUrl + (image[resize] ? image[resize] : image)}
                            alt=""
                            onLoad={this.checkBannerDimensions}
                        />
                    )}
                </div>
            )
        }

        return (
            <div
                onDragStart={preventDefaultDrag}
                className="current-banner-container cover-img-container"
            >
                {imageLogoPreviewUrl ? (
                    <img
                        draggable="false"
                        onDragStart={preventDefaultDrag}
                        src={imageLogoPreviewUrl}
                        alt="preview"
                    />
                ) : (
                    <label htmlFor={this.props.for} className="upload-label">
                        <PublishIcon />
                        <span>Click here to upload</span>
                    </label>
                )}
            </div>
        )
    }

    render() {
        let {
            newLogoFile,
            imageLogoPreviewUrl,
            imageLogoErrorText,
            imageLogoWarningText,
            openConfirmUnsavedChanges,
            loading
        } =
            this.state;
        let {image, description, device, infoBulletImage} = this.props;

        return (
            <>
                <h1 className="device-title">{device}</h1>
                <div onDragStart={preventDefaultDrag} ref={this.wrapperRef} className={`options-container ${device}`}>
                    <div onDragStart={preventDefaultDrag} className="single-option-container">
                        <div onDragStart={preventDefaultDrag} className="actions-container">
                            {newLogoFile && (
                                <Button
                                    type="button"
                                    onClick={this.updateLogo}
                                    disabled={loading || !imageLogoPreviewUrl || imageLogoErrorText.length > 0}
                                    startIcon={<SaveOutlinedIcon />}
                                    variant="contained"
                                    color={'secondary'}
                                    disableElevation
                                >
                                    Save
                                </Button>
                            )}

                            {imageLogoPreviewUrl ? (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    component="label"
                                    onKeyDown={this.handleEnterKey}
                                    startIcon={<EditOutlinedIcon />}
                                    disabled={loading}
                                >
                                    Edit
                                    <input
                                        id="upload-image-input"
                                        type="file"
                                        onChange={this.handleLogoChange}
                                        className="upload-image-button d-none"
                                        hidden
                                    />
                                </Button>
                            ) : (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    component="label"
                                    onKeyDown={this.handleEnterKey}
                                    startIcon={<CloudUploadIcon />}
                                    disabled={loading}
                                >
                                    Upload image
                                    <input
                                        id="upload-image-input"
                                        type="file"
                                        onChange={this.handleLogoChange}
                                        className="upload-image-button d-none"
                                        hidden
                                    />
                                </Button>
                            )}

                            {image && !newLogoFile && (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    onClick={this.removeLogo}
                                    disabled={imageLogoPreviewUrl || loading}
                                    startIcon={<ClearOutlinedIcon />}
                                >
                                    Remove
                                </Button>
                            )}
                        </div>
                        <div onDragStart={preventDefaultDrag} className="manage-partner-logo">
                            <div onDragStart={preventDefaultDrag} className="logo-container">
                                <div onDragStart={preventDefaultDrag} className="image-wrapper">
                                    {this.Image()}
                                </div>
                                <Grid item xs={12}>
                                    <input
                                        id={this.props.for}
                                        type="file"
                                        onChange={this.handleLogoChange}
                                        className="upload-image-button d-none"
                                    />
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="input-description">
                        <p onDragStart={preventDefaultDrag} className='input-description-text'>
                            {infoBulletImage && (
                                <Tooltip
                                    arrow
                                    classes={{tooltip: 'ignore-rtl'}}
                                    id="info-img"
                                    placement="bottom"
                                    title={
                                        <div className="img-container-preview">
                                            <img
                                                draggable="false"
                                                onDragStart={preventDefaultDrag}
                                                src={infoBulletImage}
                                                alt="Info desktop"
                                                width={300}
                                            />
                                        </div>
                                    }
                                >
                                    <InfoIcon classes={{root: 'primary-color'}} />
                                </Tooltip>
                            )}
                            {description}
                        </p>
                        <p onDragStart={preventDefaultDrag} className="image-status-container">
                            <span>
                                {newLogoFile || (imageLogoErrorText.length > 0 && image) ? (
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="form-upload-picture"
                                    >
                                        Uploaded:{' '}
                                        <span
                                            onDragStart={preventDefaultDrag}
                                            draggable="false"
                                            className="secondary-color"
                                        >
                                            {imageLogoErrorText.length > 0 ? (
                                                <span
                                                    onDragStart={preventDefaultDrag}
                                                    draggable="false"
                                                    className="error-light"
                                                >
                                                    error
                                                </span>
                                            ) : (
                                                newLogoFile.name
                                            )}
                                        </span>
                                    </span>
                                ) : null}
                                {!newLogoFile && !image ? (
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="form-upload-picture"
                                    >
                                        Uploaded:{' '}
                                        {imageLogoErrorText.length > 0 ? (
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="error-light"
                                            >
                                                error
                                            </span>
                                        ) : (
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="grey-color"
                                            >
                                                no image
                                            </span>
                                        )}
                                    </span>
                                ) : null}
                                <span onDragStart={preventDefaultDrag} draggable="false" className="error-message">
                                    {imageLogoErrorText}
                                </span>
                                <span onDragStart={preventDefaultDrag} draggable="false" className="error-message">
                                    {imageLogoWarningText}
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
                {openConfirmUnsavedChanges && (
                    <Confirm
                        open={openConfirmUnsavedChanges}
                        closeConfirm={this.closeClickOutside}
                        dialogTitle={'Unsaved changes'}
                        dialogDescription={'You have unsaved changes. Do you want to save them?'}
                        dialogConfirmButtonLabel={'Save'}
                        dialogCancelButtonLabel={'Cancel'}
                        handleConfirm={this.updateLogo}
                        handleDiscardChanges={this.handleDiscardChanges}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventBrandingSuccess: (brandingData) => dispatch(actions.getEventBrandingSuccess(brandingData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HPBackgroundImage);
