import React from "react";

const LoadingTimeslots = () => {
  return (
      <div className={'loading-timeslots'}>
          <p className={'placeholder-day'}></p>
          <div className={'placeholder-timeslots-list'}>
              {[...Array(20)].map((_, index) => (
                  <div key={index} className={'placeholder-timeslot'}>
                      <div className='placeholder-left-container'>
                          <p></p>
                          <p></p>
                      </div>
                      <div className='placeholder-right-container'>
                          <div className={'placeholder-timeslot-info'}>
                              <p className='name'></p>
                              <p className='button'></p>
                          </div>
                          <p className={'placeholder-timeslot-content'}></p>
                          <p className={'placeholder-timeslot-footer'}></p>
                      </div>
                  </div>
              ))}
          </div>
      </div>
  )
}

export default LoadingTimeslots;