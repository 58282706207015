import AddEditPartner from './AddPartner/AddEditPartnerForm';
import SinglePartnerLogo from './AddPartner/SinglePartnerLogo';
import AddEditExhibitorForm from './AddExhibitor/AddEditExhibitorForm';
import SingleExhibitorLogo from './AddExhibitor/SingleExhibitorLogo';
import AddEditSponsorForm from './AddSponsor/AddEditSponsorForm';

import SingleSponsorLogo from './AddSponsor/SingleSponsorLogo';

const SingleRoleDetails = (props) => {
    return (
        <>
            {props.roleTitle === 'partner' && (
                <>
                    <AddEditPartner
                        partners={props.roles}
                        partnerId={props.role?._id}
                        addNewPartner={props.addNewRole}
                        openSuccessSnackbar={props.openSuccessSnackbar}
                        openErrorSnackbar={props.openErrorSnackbar}
                        onGetEvent={props.onGetEvent}
                    />
                    {props.showSection ? (
                        <SinglePartnerLogo
                            openSuccessSnackbar={props.openSuccessSnackbar}
                            openErrorSnackbar={props.openErrorSnackbar}
                            partner={props.role}
                            filesUrl={props.filesUrl}
                        />
                    ) : (
                        <p>You can upload a logo after saving the Partner</p>
                    )}
                </>
            )}
            {props.roleTitle === 'exhibitor' && (
                <>
                    <AddEditExhibitorForm
                        exhibitors={props.roles}
                        exhibitorId={props.role?._id}
                        addNewExhibitor={props.addNewRole}
                        openSuccessSnackbar={props.openSuccessSnackbar}
                        openErrorSnackbar={props.openErrorSnackbar}
                        onGetEvent={props.onGetEvent}
                    />
                    {props.showSection ? (
                        <SingleExhibitorLogo
                            openSuccessSnackbar={props.openSuccessSnackbar}
                            openErrorSnackbar={props.openErrorSnackbar}
                            exhibitor={props.role}
                            filesUrl={props.filesUrl}
                        />
                    ) : (
                        <p>You can upload a logo after saving the Exhibitor</p>
                    )}
                </>
            )}
            {props.roleTitle === 'sponsor' && (
                <>
                    <AddEditSponsorForm
                        sponsors={props.roles}
                        sponsorId={props.role?._id}
                        addNewSponsor={props.addNewRole}
                        openSuccessSnackbar={props.openSuccessSnackbar}
                        openErrorSnackbar={props.openErrorSnackbar}
                        onGetEvent={props.onGetEvent}
                    />
                    {props.showSection ? (
                        <SingleSponsorLogo
                            openSuccessSnackbar={props.openSuccessSnackbar}
                            openErrorSnackbar={props.openErrorSnackbar}
                            sponsor={props.role}
                            filesUrl={props.filesUrl}
                        />
                    ) : (
                        <p>You can upload a logo after saving the Sponsor</p>
                    )}
                </>
            )}
        </>
    );
};

export default SingleRoleDetails;
