import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../../../../Utils/utils';
import GetAppIcon from '@material-ui/icons/GetApp';
import colors from '../../../../CSS/_variables.module.scss';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class OldStatistics extends PureComponent {
    render() {
        const {oldStatistics, statisticIndex} = this.props;

        return (
            <div className="statistics old-statistics">
                <div>
                    <div className="d-flex">
                        <p onDragStart={preventDefaultDrag} className="statistics-title">
                            Statistics before 23-04-2021
                            <ExcelFile
                                filename="BoothStatistics"
                                element={
                                    <span onDragStart={preventDefaultDrag} id="download-info" className={'button'}>
                                        <GetAppIcon fill={colors.dark} />
                                    </span>
                                }
                            >
                                <ExcelSheet data={oldStatistics?.[statisticIndex]?.statistics} name="BoothStatistics">
                                    <ExcelColumn label="Statistic Name" value="name" />
                                    <ExcelColumn label="Value" value="value" />
                                </ExcelSheet>
                            </ExcelFile>
                        </p>
                    </div>
                </div>
                <div onDragStart={preventDefaultDrag} className="statistics-part">
                    <div onDragStart={preventDefaultDrag} className="statistics-table actionable">
                        <div className="list-wrapper">
                            <ul>
                                <li className="statistics-header">
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="title">
                                        Data type
                                    </span>
                                    <span onDragStart={preventDefaultDrag} draggable="false" className="counter">
                                        Value
                                    </span>
                                </li>
                                {oldStatistics?.[statisticIndex]?.statistics?.map((statistic, statIndex) => {
                                    return (
                                        <li key={'eventStatistic' + statIndex}>
                                            <div onDragStart={preventDefaultDrag} className="title attendees">
                                                <span>{statistic.name}</span>
                                            </div>
                                            <span
                                                onDragStart={preventDefaultDrag}
                                                draggable="false"
                                                className="counter"
                                            >
                                                {statistic.value}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventRoles: state.user.eventRoles,
        eventUsers: state.eventUsers,
        event: state.event.data,
    };
};

export default connect(mapStateToProps)(OldStatistics);
