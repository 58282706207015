import React, {Component} from 'react';
import Slider from 'react-slick';
import {ReactComponent as SliderArrow} from '../../Images/svg/slider-arrow.svg';
import OnventLookFullScreenSlider from './OnventLookFullScreenSlider';
import Lobby1x from '../../Images/pages/platform-homepage/lookSliderImages/event_lobby_image.jpg';
import Lobby2x from '../../Images/pages/platform-homepage/lookSliderImages/event_lobby_image@2x.jpg';
import Lobby3x from '../../Images/pages/platform-homepage/lookSliderImages/event_lobby_image@3x.jpg';
import Booth1x from '../../Images/pages/platform-homepage/lookSliderImages/booth_image.jpg';
import Booth2x from '../../Images/pages/platform-homepage/lookSliderImages/booth_image@2x.jpg';
import Booth3x from '../../Images/pages/platform-homepage/lookSliderImages/booth_image@3x.jpg';
import Auditorium1x from '../../Images/pages/platform-homepage/lookSliderImages/auditorium_image.jpg';
import Auditorium2x from '../../Images/pages/platform-homepage/lookSliderImages/auditorium_image@2x.jpg';
import Auditorium3x from '../../Images/pages/platform-homepage/lookSliderImages/auditorium_image@3x.jpg';
import Archive1x from '../../Images/pages/platform-homepage/lookSliderImages/archive_image.jpg';
import Archive2x from '../../Images/pages/platform-homepage/lookSliderImages/archive_image@2x.png';
import Archive3x from '../../Images/pages/platform-homepage/lookSliderImages/archive_image@3x.jpg';
import {preventDefaultDrag} from '../../Utils/utils';

class OnventLookSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlideIndex: 0,
            fullScreenSliderOpened: false,
            slider1: null,
            slider2: null,
            slides: [
                {
                    image: Lobby1x,
                    imageSrcSet: `${Lobby1x} 1x, ${Lobby2x} 2x, ${Lobby3x} 3x`,
                    header: 'Lobby with network',
                    text:
                        `Participants access your photorealistic online event from anywhere, anytime. They can watch your program, meet with exhibitors and network effectively with other participants. Networking and community building is key to the ${process.env.REACT_APP_PLATFORM_NAME} experience, and users can make connections both on ${process.env.REACT_APP_PLATFORM_NAME} and on other social media such as LinkedIn, Facebook and Twitter.`,
                },
                {
                    image: Auditorium1x,
                    imageSrcSet: `${Auditorium1x} 1x, ${Auditorium2x} 2x, ${Auditorium3x} 3x`,
                    header: 'No limits - Stage',
                    text:
                        'The auditorium offers state of the art video technology for pre-recorded videos and live streams. The video call makes the presentations more engaging and interesting for your audience.',
                },
                {
                    image: Archive1x,
                    imageSrcSet: `${Archive1x} 1x, ${Archive2x} 2x, ${Archive3x} 3x`,
                    header: 'On-demand – Archive',
                    text:
                        `You can archive presentations and build a library of searchable on-demand content for your ${process.env.REACT_APP_PLATFORM_NAME} community. There is no more missing out on interesting presentations. On-demand: anywhere, anytime!`,
                },
                {
                    image: Booth1x,
                    imageSrcSet: `${Booth1x} 1x, ${Booth2x} 2x, ${Booth3x} 3x`,
                    header: 'Expo - Exhibitors',
                    text:
                        'Your event exhibitors can independently set up their booth in just minutes and can be added to the event at any time. Online exhibition is an effective way to attract booth visitors and leads, to make relevant and targeted connections, to gain followers in social media, and to build brand awareness.',
                },
                // { image: Image, header: 'Organizer dashboard', text: 'From your organizer dashboard you have total control over all of your myOnvent pages. You can set up the event homepage and registration, add content to your program, manage users, send emails, and find relevant event statistics. Your myOnvent team will make sure you become an online event master!' }
            ],
        };
    }

    componentDidMount() {
        this.setState({
            slider1: this.slider1,
            slider2: this.slider2,
        });
    }

    goToPreviousSlide = () => {
        this.slider1.slickPrev();
    };

    goToNextSlide = () => {
        this.slider1.slickNext();
    };

    openFullScreenCarousel = (slideIndex) => (e) => {
        this.setState({
            currentSlideIndex: slideIndex,
            fullScreenSliderOpened: true,
        });
    };

    closeFullScreenSlider = () => {
        this.setState({fullScreenSliderOpened: false});
    };

    render() {
        let sliderSettings = {
            className: 'navigation-slider',
            asNavFor: this.state.slider2,
            dots: false,
            arrows: false,
            autoplay: false,
            infinite: true,
            fade: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        let sliderSettings2 = {
            className: 'navigation-slider',
            asNavFor: this.state.slider1,
            dots: true,
            arrows: false,
            autoplay: false,
            fade: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            // adaptiveHeight: true
        };

        const {slides} = this.state;

        return (
            <div onDragStart={preventDefaultDrag} className="onvent-look-slider">
                <div onDragStart={preventDefaultDrag} className="onvent-look-slider-wrapper">
                    <div onDragStart={preventDefaultDrag} className="arrow previous" onClick={this.goToPreviousSlide}>
                        <SliderArrow />
                    </div>
                    <div onDragStart={preventDefaultDrag} className="slider">
                        <Slider
                            {...sliderSettings}
                            asNavFor={this.state.slider2}
                            ref={(slider1) => (this.slider1 = slider1)}
                        >
                            {slides.map((slide, index) => {
                                return (
                                    <div onDragStart={preventDefaultDrag} key={slide.image}>
                                        <div onDragStart={preventDefaultDrag} className="slider-image-container">
                                            <div onDragStart={preventDefaultDrag} className="image-container">
                                                <img
                                                    draggable="false"
                                                    src={slide.image}
                                                    srcSet={slide.imageSrcSet}
                                                    alt=""
                                                />
                                                <div onDragStart={preventDefaultDrag} className="image-overlay">
                                                    <button type="button" onClick={this.openFullScreenCarousel(index)}>
                                                        Tap to open
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="arrow next" onClick={this.goToNextSlide}>
                        <SliderArrow />
                    </div>
                </div>
                <Slider {...sliderSettings2} asNavFor={this.state.slider1} ref={(slider2) => (this.slider2 = slider2)}>
                    {slides.map((slide) => {
                        return (
                            <div onDragStart={preventDefaultDrag} key={slide.image}>
                                <div onDragStart={preventDefaultDrag} className="presentation-container">
                                    <h4>{slide.header}</h4>
                                    <p>{slide.text}</p>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
                <OnventLookFullScreenSlider
                    open={this.state.fullScreenSliderOpened}
                    handleClose={this.closeFullScreenSlider}
                    slides={this.state.slides}
                />
            </div>
        );
    }
}

export default OnventLookSlider;
