import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/index';
import '../../CSS/myAccount.scss';
import {preventDefaultDrag} from '../../Utils/utils';
import {EXHIBITOR_TYPES} from '../../Utils/constants/shared';

class MyBooths extends React.Component {
    redirectToBooth = (exhibitorId) => (e) => {
        const {eventSlug} = this.props;
        this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view/${exhibitorId}`);
    };

    handleKeyDown = (exhibitorId) => (e) => {
        const {eventSlug} = this.props;
        if (e.key === 'Enter') {
            this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view/${exhibitorId}`);
        }
    };

    render() {
        const {user, eventId, allEventUsers, translation, defaultTranslation} = this.props;
        const eventExhibitors = allEventUsers.filter(eventUser => eventUser.type === EXHIBITOR_TYPES.company || eventUser.type === EXHIBITOR_TYPES.scholar)
        const userRolesInEvent = user.eventRoles.find((role) => role.event._id === eventId);
        return (
            <div
                id="account-drop"
                className="my-account-component dropdown-component"
                style={{left: document.getElementById('my-booths').offsetLeft}}
            >
                <div onDragStart={preventDefaultDrag} className="page-title">
                    <h3>{translation?.myBoothsDropdown.title}</h3>
                </div>
                <div onDragStart={preventDefaultDrag} className="myaccount-container">
                    <ul className="my-events-list">
                        {userRolesInEvent.exhibitorRepresentative.map((exhibitorId) => {
                            const exhibitorData = eventExhibitors.find((el) => el._id === exhibitorId);
                            if (!exhibitorData) return null;
                            const isActive = this.props.history.location.pathname.includes(exhibitorId);
                            return (
                                <li
                                    tabIndex="0"
                                    className={'row ' + (isActive ? 'active' : '')}
                                    key={exhibitorId}
                                    onClick={this.redirectToBooth(exhibitorId)}
                                    onKeyDown={this.handleKeyDown(exhibitorId)}
                                >
                                    {exhibitorData.type === EXHIBITOR_TYPES.scholar ? (
                                        <>
                                            {exhibitorData?.booth?.studyTitle ? (
                                                <span>{exhibitorData?.booth?.studyTitle}</span>
                                            ) : (
                                                <span>
                                                    {translation?.myBoothsDropdown.emptyStudyTitle ||
                                                        defaultTranslation?.myBoothsDropdown.emptyStudyTitle}
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <span>{exhibitorData.company}</span>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        allEventUsers: state.eventUsers.allEventUsers,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
        seeMyBooths: () => dispatch(actions.topNavSeeMyBooths()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyBooths));
