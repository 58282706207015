import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/index';
import '../../CSS/myAccount.scss';
import {disconnectFromNotifications} from '../../Api/socketApi';
import MyEventsMobile from '../MyEvents/MyEventsMobile';
import ColoredScrollbars from '../../SmallLayoutComponents/ColoredScrollbars';
import {preventDefaultDrag} from '../../Utils/utils';

class MyEvents extends React.Component {
    logoutUser = () => {
        // if user logged out we should also disconnect him from notifications
        disconnectFromNotifications(this.props.user._id);
        this.props.onLogoutUser();
    };

    redirectToEvent = (eventSlug, eventId) => (e) => {
        if (eventSlug !== this.props.eventSlug) {
            const {user, isLargeScreen} = this.props;
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);

            // all the users that are on mobile, are redirected to lobby
            if (isLargeScreen) {
                if (userRolesInEvent.roles.includes('organizer')) {
                    // this.props.history.push(`/event/${eventSlug}/dashboard/settings`);
                    this.props.history.push(`/event/${eventSlug}/dashboard/settings/event-core-details`);
                } else if (userRolesInEvent.roles.includes('exhibitor')) {
                    this.props.history.push(`/event/${eventSlug}/exhibitor-dashboard`);
                } else if (userRolesInEvent.roles.includes('exhibitorRep')) {
                    // this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view`);
                    this.props.history.push(`/event/${eventSlug}/lobby`);
                } else {
                    this.props.history.push(`/event/${eventSlug}/lobby`);
                }
            } else {
                this.props.history.push(`/event/${eventSlug}/lobby`);
            }

            // reset welcome screen to null because redux state might contain
            // the welcome screen from another event
            this.props.onResetWelcomeScreen();

            this.props.setEventSlug(eventSlug);
            this.props.setEventId(eventId);

            this.props.onCloseTopNav();
            // we should use this action when we want to do any kind of logic or
            // update / reset data in the redux store
            this.props.userNavigatedToOtherEvent();
            this.props.onGetShowfloors(eventId);
        }
    };

    redirectToCreateEvent = () => {
        this.props.history.push(`/create-event`);
    };

    handleKeyDown = (eventSlug, eventId) => (e) => {
        if (e.key === 'Enter') {
            this.redirectToEvent(eventSlug, eventId)();
        }
    };

    render() {
        const {user, eventId, isLargeScreen, translation} = this.props;

        if (!isLargeScreen) {
            return <MyEventsMobile redirectToEvent={this.redirectToEvent} user={user} eventId={eventId} />;
        }

        return (
            <div onDragStart={preventDefaultDrag} id="account-drop" className="my-account-component dropdown-component">
                <div onDragStart={preventDefaultDrag} className="page-title">
                    <h3>{translation?.myEventsDropdown.title}</h3>
                </div>
                <div onDragStart={preventDefaultDrag} className="myaccount-container my-events-list-wrapper">
                    <ColoredScrollbars>
                        <ul className="my-events-list">
                            {user.eventRoles
                                .slice(0)
                                .reverse()
                                .map((eventRole) => {
                                    // don't display events where the organizer is "not approved",
                                    // unless the current user is actually the organizer of the event

                                    if (
                                        eventRole.event.owner &&
                                        eventRole.event.owner.organizerStatus !== 'approved' &&
                                        !eventRole.roles.includes('organizer')
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <li
                                            tabIndex="0"
                                            className={'row ' + (eventId === eventRole.event._id ? 'active' : '')}
                                            key={eventRole.event._id}
                                            onClick={this.redirectToEvent(eventRole.event?.slug, eventRole.event?._id)}
                                            onKeyDown={this.handleKeyDown(eventRole.event?.slug, eventRole.event?._id)}
                                        >
                                            <span className="long-event-name">{eventRole.event.name}</span>
                                        </li>
                                    );
                                })}
                        </ul>
                    </ColoredScrollbars>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        isLargeScreen: state.layout.isLargeScreen,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
        onCloseTopNav: () => dispatch(actions.topNavClose()),
        userNavigatedToOtherEvent: () => dispatch(actions.userNavigatedToOtherEvent()),
        onGetShowfloors: (eventId) => dispatch(actions.getShowfloors(eventId)),
        onResetWelcomeScreen: () => dispatch(actions.getWelcomeScreenSuccess(null)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyEvents));
