import React, {Component} from 'react';

export default class AcceptButton extends Component {
    render() {
        return (
            <svg draggable="false" width="173" height="136" viewBox="0 0 173 136" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M89.2298 9.5639V62.9512L68.0024 75.188L60.8177 79.303L33.712 94.7886V41.4013L60.8177 25.8075L68.0024 21.6925L89.2298 9.5639Z"
                    fill="#777081"
                />
                <path
                    opacity="0.5"
                    d="M89.2297 126.734L113.5 136L169.5 104L150.754 84.8536L144.658 83.5541L89.2297 126.734Z"
                    fill={this.props.fillGreyLight}
                />
                <path
                    d="M61.4709 57.32L54.2863 53.205L33.712 41.4013V94.7886L54.2863 106.592L61.4709 110.816L89.2298 126.734V73.2387L61.4709 57.32Z"
                    fill={this.props.fillPrimary}
                />
                <path
                    d="M116.989 25.4826L109.695 21.2593L89.2297 9.5639V62.9512L109.695 74.7548L116.989 78.8699L144.747 94.7886V41.4013L116.989 25.4826Z"
                    fill={this.props.fillGrey}
                />
                <path
                    d="M144.747 41.4013V94.7886L123.52 107.025L116.227 111.14L89.2297 126.734V73.2387L116.227 57.6449L123.52 53.5299L144.747 41.4013Z"
                    fill={this.props.fillPrimaryDark}
                />
                <path
                    d="M143.877 43.2423L90.2095 74.1051C90.1007 74.2134 89.9918 74.1051 89.9918 73.9968C89.9918 73.8885 90.1007 73.6719 90.2095 73.5636L143.877 42.7008C143.986 42.5925 144.094 42.7008 144.094 42.8091C144.094 42.9174 144.094 43.134 143.877 43.2423Z"
                    fill="#8F6E4F"
                />
                <path
                    d="M137.998 52.5552C137.998 52.3387 137.998 52.1221 137.998 52.1221C137.998 52.1221 137.672 52.4469 137.672 52.6635C135.821 53.8547 135.712 55.6957 135.712 55.6957C135.712 55.4791 135.821 55.3708 136.039 55.2625C136.257 55.1542 136.257 55.2625 136.365 55.3708C136.365 55.1542 136.474 55.0459 136.692 54.9376C136.801 54.8293 136.91 54.9376 137.019 55.0459C137.019 54.8293 137.127 54.721 137.345 54.6128C137.454 54.5045 137.563 54.6128 137.672 54.721C137.672 54.6128 137.781 54.5045 137.781 54.3962V56.7786C137.781 56.8869 137.672 57.1034 137.563 57.1034C137.454 57.2117 137.345 57.1034 137.345 56.9951V56.562C137.345 56.4537 137.236 56.4537 137.236 56.4537C137.127 56.4537 137.127 56.6703 137.127 56.7786V57.2117C137.127 57.5366 137.345 57.6449 137.672 57.5366C137.998 57.32 138.216 56.9951 138.216 56.6703V54.2879C138.325 54.2879 138.325 54.2879 138.325 54.3962C138.325 54.1796 138.434 54.0713 138.651 53.963C138.76 53.8547 138.869 53.963 138.978 54.0713C138.978 53.8547 139.087 53.7464 139.305 53.6381C139.413 53.5298 139.522 53.6381 139.631 53.7464C139.631 53.5298 139.74 53.4216 139.958 53.3133C140.067 53.205 140.175 53.3133 140.284 53.4216C140.067 53.3133 139.958 51.5806 137.998 52.5552Z"
                    fill="#7F6B59"
                />
                <path
                    d="M136.91 53.0961C137.018 53.0961 137.127 52.9879 137.236 52.7715C137.345 52.555 137.236 52.2303 137.236 52.2303C137.236 52.2303 136.91 52.555 136.91 52.7715C136.692 52.9879 136.801 53.0961 136.91 53.0961Z"
                    fill="#7F6B59"
                />
                <path
                    d="M137.562 52.337C137.671 52.337 137.779 52.2288 137.888 52.0124C137.997 51.7959 137.888 51.4713 137.888 51.4713C137.888 51.4713 137.562 51.7959 137.562 52.0124C137.453 52.2288 137.453 52.337 137.562 52.337Z"
                    fill="#7F6B59"
                />
                <path
                    d="M139.305 51.0389C139.196 51.2553 139.305 51.3635 139.305 51.3635C139.413 51.3635 139.522 51.2553 139.631 51.0389C139.74 50.8224 139.631 50.4977 139.631 50.4977C139.631 50.4977 139.413 50.8224 139.305 51.0389Z"
                    fill="#7F6B59"
                />
                <path
                    d="M139.414 51.9051C139.305 52.1216 139.414 52.2298 139.414 52.2298C139.522 52.2298 139.631 52.1216 139.74 51.9051C139.849 51.6887 139.74 51.364 139.74 51.364C139.74 51.364 139.522 51.6887 139.414 51.9051Z"
                    fill="#7F6B59"
                />
                <path
                    d="M138.325 51.4718C138.434 51.4718 138.543 51.3636 138.651 51.1471C138.76 50.9307 138.651 50.606 138.651 50.606C138.651 50.606 138.325 50.9307 138.325 51.1471C138.216 51.3636 138.325 51.4718 138.325 51.4718Z"
                    fill="#7F6B59"
                />
                <path
                    d="M138.542 51.7969C138.434 52.0133 138.542 52.1215 138.542 52.1215C138.651 52.1215 138.76 52.0133 138.869 51.7969C138.978 51.5804 138.869 51.2557 138.869 51.2557C138.869 51.2557 138.542 51.5804 138.542 51.7969Z"
                    fill="#7F6B59"
                />
                <path
                    d="M139.196 50.6055C139.304 50.6055 139.413 50.4973 139.522 50.2808C139.631 50.0644 139.522 49.7397 139.522 49.7397C139.522 49.7397 139.196 50.0644 139.196 50.2808C138.978 50.4973 139.087 50.6055 139.196 50.6055Z"
                    fill="#7F6B59"
                />
                <path
                    d="M141.373 56.2371L134.406 60.2439C134.297 60.2439 134.297 60.2439 134.297 60.1356V51.7972C134.297 51.6889 134.297 51.5806 134.406 51.5806L141.373 47.5739C141.482 47.5739 141.482 47.5739 141.482 47.6821V56.0205C141.482 56.1288 141.482 56.2371 141.373 56.2371ZM134.515 59.919L141.264 56.0205V48.007L134.515 51.9055V59.919Z"
                    fill="#7F6B59"
                />
                <path
                    d="M132.882 52.5552L125.915 56.562C125.806 56.562 125.806 56.6703 125.806 56.7786V65.1169C125.806 65.2252 125.806 65.2252 125.915 65.2252L132.882 61.2185C132.991 61.2185 132.991 61.1102 132.991 61.0019V52.6635C132.991 52.5552 132.991 52.5552 132.882 52.5552ZM132.773 60.8936L126.024 64.7921V56.7786L132.773 52.8801V60.8936Z"
                    fill="#7F6B59"
                />
                <path
                    d="M129.181 58.6195V61.0019L128.31 61.8682L129.181 61.3267L129.507 61.1102L130.487 60.5687L129.507 60.677V58.2946C129.943 57.9697 130.378 57.5366 130.705 56.9951C131.031 56.4537 131.249 55.6956 131.249 55.1542L128.854 56.5619L128.636 57.1034H129.072L128.419 58.5112L128.528 57.6449L128.31 57.5366L128.419 56.8868L127.548 57.4283C127.548 58.5112 128.31 59.0526 129.181 58.6195Z"
                    fill="#7F6B59"
                />
                <path
                    d="M129.399 65.7667L128.201 67.2827L129.399 67.391L130.596 65.875L129.399 65.7667Z"
                    fill="#7F6B59"
                />
                <path d="M128.201 68.7969L129.398 68.9051V67.4982L128.201 67.39V68.7969Z" fill="#7F6B59" />
                <path d="M130.704 65.9831L129.507 67.4992V68.9069L130.704 67.3909V65.9831Z" fill="#7F6B59" />
                <path
                    d="M33.712 41.4013L54.504 53.3133L61.6886 57.4283L89.2298 73.2387L64.9586 100.152L9.44092 68.315L33.712 41.4013Z"
                    fill={this.props.fillGreyLight}
                />
                <path
                    d="M89.2297 9.5639L117.022 1.23065L172.539 33.0681L144.747 41.4013L89.2297 9.5639Z"
                    fill={this.props.fillGreyLight}
                />
                <path
                    d="M89.2298 9.56389L55.6393 0.30426L0.121582 32.1417L33.7121 41.4013L89.2298 9.56389Z"
                    fill={this.props.fillGreyLight}
                />
                <path
                    d="M144.747 41.4013L123.956 53.3133L116.771 57.4283L89.2297 73.2387L112.56 101.152L167.969 69.3141L144.747 41.4013Z"
                    fill={this.props.fillGreyLight}
                />
                <path
                    d="M89.2298 62.9512L98.0473 68.0408L89.2298 73.2388L80.4122 68.0408L89.2298 62.9512Z"
                    fill={this.props.fillPrimaryDark}
                />
            </svg>
        );
    }
}
