import React, {useState} from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import Button from '@material-ui/core/Button';
import axios from '../../../store/axios-instance';
import {useSelector} from 'react-redux';
import Spinner from "../../../SmallLayoutComponents/Spinner";


export default function DownloadUsersExcel({
    infoIcon,
    params,
    fileName,
    startDownloading = () => {},
    endDownloading = () => {},
}){
    const eventId = useSelector((state) => state.event.eventId);
    const [isLoading, setIsLoading] = useState(false);

    const getExcelData = async () => {
        const {data} = await axios.post(`/event/${eventId}/users-excel`, params, {responseType: 'blob'});
        downloadFile(data)
    };

    const downloadFile = (data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        const formattedName = formatFileName();
        link.href = url;
        link.setAttribute('download', `${formattedName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const formatFileName = () => {
        let formattedFileName = fileName.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '');
        if (formattedFileName.length > 97) {
            formattedFileName = formattedFileName.substring(0, 97).concat('...');
        }
        return formattedFileName
    }

    const getExcelDataAndSetLoading = () => {
        setIsLoading(true);
        startDownloading();
        getExcelData()
            .then(() => {
                setIsLoading(false);
                endDownloading();
            })
            .catch(() => {
                setIsLoading(false);
                endDownloading();
            });
    }

    return (
        <div className="expand-container">
            {isLoading && <Spinner />}
            {infoIcon && (
                <Tooltip arrow classes={{tooltip: 'ignore-rtl'}} tabIndex="0" id="tooltip-download-list" title={infoIcon}>
                    <InfoIcon classes={{root: 'primary-color'}} />
                </Tooltip>
            )}
            <Button
                disabled={isLoading}
                type="button"
                onClick={getExcelDataAndSetLoading}
                startIcon={<GetAppIcon />}
            >
                DOWNLOAD LIST
            </Button>
        </div>
    );
}
