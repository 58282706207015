import React, {PureComponent} from 'react';
import {preventDefaultDrag} from '../../../Utils/utils';
import ColorPicker from './LayoutComponents/ColorPicker';
import ColorPickerSecondary from './LayoutComponents/ColorPickerSecondary';
import variables from '../../../CSS/_variables.module.scss';

class HPColors extends PureComponent {
    render() {
        const {saveEventBranding, loadingSaveEventBranding} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="branding-colors">
                <h4 className="advanced-options-title">Colors</h4>
                <div onDragStart={preventDefaultDrag} className="advanced-options-container">
                    <div>
                        <p onDragStart={preventDefaultDrag} className="inner-options-title">
                            Primary color
                        </p>
                        <ColorPicker
                            isHPColors={true}
                            for={'primaryColor'}
                            color={variables.primaryColorBranding}
                            description={
                                'Choose the primary color of the visual elements from the Homepage of your event.'
                            }
                            saveEventBranding={saveEventBranding}
                            loading={loadingSaveEventBranding}
                        />
                    </div>
                    <div>
                        <p onDragStart={preventDefaultDrag} className="inner-options-title">
                            Secondary color
                        </p>
                        <ColorPickerSecondary
                            for={'secondaryColor'}
                            color={variables.secondaryColorBranding}
                            description={
                                'Choose the secondary color of the visual elements from the Homepage of your event.'
                            }
                            saveEventBranding={saveEventBranding}
                            loading={loadingSaveEventBranding}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default HPColors;
