import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import axios from '../../store/axios-instance';
import {preventDefaultDrag} from '../../Utils/utils';
import Spinner from "../../SmallLayoutComponents/Spinner";

class ChangePassword extends React.Component {
    state = {
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
        disabled: true,
        error: false,
        loading: false,
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            const {password} = this.state;
            if (value !== password) {
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                error: false,
            },
        );
    };

    handleUpdatePassword = () => {
        this.setState({loading: true});
        const {oldPassword, password} = this.state;
        axios({
            method: 'put',
            url: '/users',
            data: {oldPassword: oldPassword, password: password},
        })
            .then((response) => {
                this.props.onRefreshUserUserData();
                this.props.closeDialog();
                this.setState({loading: false});
            })
            .catch((error) => {
                this.setState({error: true, loading: false});
            });
    };

    checkIsButtonDisabled = () => this.state.oldPassword !== ''
        && this.state.password !== ''
        && this.state.passwordConfirmation !== '';
        
    checkIfPasswordsEqual = () => this.state.password === this.state.oldPassword 
        && this.state.oldPassword !== '' && this.state.password !== '';

    render() {
        const {oldPassword, password, passwordConfirmation, error, loading} = this.state;
        const {opened, closeDialog, translation} = this.props;
        return (
            <Dialog
                open={opened}
                onClose={() => closeDialog('')}
                aria-labelledby="form-dialog-title"
                className="booth-dialog change-password-dialog"
            >
                <DialogContent className="booth-dialog-content booth-video-dialog-container">
                    {loading && <Spinner />}
                    <DialogTitle id="form-dialog-title">{translation?.changePassword.title}</DialogTitle>
                    <ValidatorForm ref="form" onSubmit={this.handleUpdatePassword} className="change-password-form">
                        <div onDragStart={preventDefaultDrag} className="field-container">
                            <TextValidator
                                label={translation?.changePassword.oldPassword}
                                onChange={this.handleChange}
                                name="oldPassword"
                                value={oldPassword}
                                variant="outlined"
                                type="password"
                                fullWidth
                                validators={['required', 'minStringLength:8']}
                                errorMessages={[
                                    `${translation?.errors.required}`,
                                    `${translation?.errors.passwordMinLength}`,
                                ]}
                            />
                        </div>
                        <div onDragStart={preventDefaultDrag} className="field-container">
                            <TextValidator
                                label={translation?.changePassword.inputPassword}
                                onChange={this.handleChange}
                                name="password"
                                value={password}
                                variant="outlined"
                                type="password"
                                fullWidth
                                validators={['required', 'minStringLength:8']}
                                errorMessages={[
                                    `${translation?.errors.required}`,
                                    `${translation?.errors.passwordMinLength}`,
                                ]}
                            />
                        </div>
                        <div onDragStart={preventDefaultDrag} className="field-container">
                            <TextValidator
                                label={translation?.changePassword.inputConfirmPassword}
                                onChange={this.handleChange}
                                name="passwordConfirmation"
                                value={passwordConfirmation}
                                variant="outlined"
                                type="password"
                                fullWidth
                                validators={['isPasswordMatch', 'required', 'minStringLength:8']}
                                errorMessages={[
                                    `${translation?.errors.passwordMismatch}`,
                                    `${translation?.errors.required}`,
                                    `${translation?.errors.passwordMinLength}`,
                                ]}
                            />
                        </div>
                        {error && (
                            <p onDragStart={preventDefaultDrag} className="error-message">
                                {translation?.changePassword.oldPasswordError}
                            </p>
                        )}
                        {this.checkIfPasswordsEqual() && (
                            <p onDragStart={preventDefaultDrag} className="error-message">
                                {translation?.errors.passwordIsSame}
                            </p>
                        )}
                    </ValidatorForm>
                </DialogContent>
                <DialogActions className="buttons-actions">
                    <Button onClick={() => closeDialog('')} type="button">
                        {translation?.generalText.close}
                    </Button>
                    <Button
                        onClick={this.handleUpdatePassword}
                        type="button"
                        disabled={!this.checkIsButtonDisabled()}
                    >
                        {translation?.generalText.update}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefreshUserUserData: () => dispatch(actions.refreshUserData()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
