import React, {Component} from 'react';

export default class HomeFilled extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.8679 10.9512L20.2757 7.63649V2.20612H16.1378V3.81845L11.9998 0L0.132082 10.9512C-0.0350925 11.1058 -0.0450237 11.3661 0.110564 11.5327C0.265738 11.6988 0.527672 11.7082 0.695674 11.554L2.06907 10.2867V24H8.68983H15.3106H21.9313V10.2863L23.3047 11.5536C23.3842 11.6272 23.4856 11.6634 23.5865 11.6634C23.6978 11.6634 23.8083 11.6194 23.8894 11.5323C24.045 11.3661 24.0351 11.1058 23.8679 10.9512ZM16.9654 3.02853H19.4481V6.87289L16.9654 4.58206V3.02853ZM9.51701 23.1776V14.5164C9.51701 14.3038 9.6908 14.1311 9.90474 14.1311H14.0948C14.3088 14.1311 14.4826 14.3038 14.4826 14.5164V24H9.51701V23.1776ZM21.1033 23.1776H15.3102V14.5164C15.3102 13.8502 14.7652 13.3087 14.0948 13.3087H9.90474C9.23438 13.3087 8.68941 13.8502 8.68941 14.5164V23.1776H2.89625V9.52269L11.9998 1.12218L17.9088 6.57476L20.2757 8.75867L21.1033 9.52228V23.1776Z" fill="url(#gradient3)"/>
                <path d="M21.1033 23.1776H15.3102V14.5164C15.3102 13.8502 14.7652 13.3087 14.0948 13.3087H9.90474C9.23438 13.3087 8.68941 13.8502 8.68941 14.5164V23.1776H2.89625V9.52269L11.9998 1.12218L17.9088 6.57476L20.2757 8.75867L21.1033 9.52228V23.1776Z" fill="url(#gradient3)"/>
                <defs>
                    <linearGradient id="gradient3" x1="11.28" y1="-17.4286" x2="38.1695" y2="12.1569" gradientUnits="userSpaceOnUse">
                        <stop stopColor={this.props.gradient1}/>
                        <stop offset="0.503996" stopColor={this.props.gradient2}/>
                        <stop offset="0.983171" stopColor={this.props.gradient3}/>
                    </linearGradient>
                </defs>
            </svg>
        );
    }
}
